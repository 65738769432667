import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, DialogActions, DialogContent, DialogContentText,
  Grid, Typography, CircularProgress,
} from '@material-ui/core';
import { css } from 'emotion';
import NewOrderNextButton from './NewOrderNextButton';
import NewOrderNextButtonClear from './NewOrderNextButtonClear';
import * as colors from '../../styles/colors';

export default function ConfirmationModal(props) {
  const {
    open, message, onProceed, onCancel, proceedLabel,
    cancelLabel, proceedButtonComponent, proceedButtonProps,
    noCancel,
  } = props;

  const [loading, setLoading] = React.useState(false);
  const [messageState, setMessageState] = React.useState(message);
  const ProceedButtonComponent = proceedButtonComponent || NewOrderNextButton;

  React.useEffect(() => {
    if (message) {
      setMessageState(message);
    }
  }, [message]);

  const dialogRoot = css({
    backgroundColor: colors.mediumBlueBackground,
    overflowY: 'initial',
    borderRadius: 10,
  });
  const dialogContentContainer = css({ marginBottom: 'initial' });
  const dialogActionsContainer = css({
    paddingBottom: 24, width: '100%', justifyContent: 'center', flexDirection: 'column',
  });

  const classes = {
    dialogRoot,
    dialogContentContainer,
    dialogActionsContainer,
    dialogContent: css({
      minWidth: 500,
      paddingLeft: 40,
      paddingRight: 40,
    }),
    buttonsContainer: css({
      width: 'fit-content',
    }),
    dialogText: css({
      fontWeight: 500,
      lineHeight: '2em',
    }),
    progress: css({
      marginTop: 20,
    }),
    buttonText: css({
      whiteSpace: 'nowrap',
      padding: '0 10px',
    }),
  };

  const onClick = async () => {
    setLoading(true);
    try {
      await onProceed();
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const dialogContent = (
    <DialogContent className={classes.dialogContent}>
      <DialogContentText
        id="alert-error-dialog"
        component="div"
        classes={{ root: dialogContentContainer }}
      >
        <Grid item container direction="column">
          <Grid item>
            <Typography color="primary" className={classes.dialogText}>
              {messageState}
            </Typography>
          </Grid>
        </Grid>
      </DialogContentText>
    </DialogContent>
  );

  const dialogActions = (
    <DialogActions classes={{ root: dialogActionsContainer }}>
      <Grid
        container
        className={classes.buttonsContainer}
        spacing={2}
        justify="center"
        wrap="nowrap"
      >
        {
        !noCancel
          && (
          <Grid item>
            <NewOrderNextButtonClear disabled={loading} onClick={onCancel}>{cancelLabel || 'No, cancel'}</NewOrderNextButtonClear>
          </Grid>
          )
      }
        <Grid item>
          <ProceedButtonComponent
            className={classes.buttonText}
            disabled={loading}
            onClick={onClick}
            {...proceedButtonProps}
          >
            {proceedLabel || 'Yes, submit'}
          </ProceedButtonComponent>
        </Grid>
      </Grid>
      {loading && <CircularProgress color="secondary" className={classes.progress} />}
    </DialogActions>
  );

  return (
    <Dialog
      open={open}
      onClose={(e, reason) => {
        if (noCancel) return;
        if ((loading) && ['backdropClick', 'escapeKeyDown'].includes(reason)) return;
        onCancel();
      }}
      classes={{ paper: dialogRoot }}
      transitionDuration={{ exit: 0, enter: 100, appear: 100 }}
    >
      {dialogContent}
      {dialogActions}
    </Dialog>
  );
}

ConfirmationModal.propTypes = {
  open: PropTypes.bool,
  message: PropTypes.node,
  onProceed: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  proceedLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  cancelLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  proceedButtonComponent: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  proceedButtonProps: PropTypes.objectOf(PropTypes.any),
  noCancel: PropTypes.bool,
};

ConfirmationModal.defaultProps = {
  open: false,
  message: null,
  proceedLabel: '',
  cancelLabel: '',
  proceedButtonComponent: undefined,
  proceedButtonProps: null,
  noCancel: false,
};
