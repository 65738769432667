import { createMuiTheme } from '@material-ui/core/styles';
import * as colors from './colors';

const theme = {
  spacing: 8,
  palette: {
    primary: {
      main: colors.white,
    },
    secondary: {
      main: colors.primaryBlue,
    },
    tertiary: {
      main: colors.midnightBlue,
    },
    type: 'dark',
  },
  overrides: {
    // Applied to the <ul> element
    MuiMenu: {
      list: {
        backgroundColor: '#626477',
        color: colors.white,
      },
    },
    MuiExpansionPanelSummary: {
      root: {
        width: '100%',
      },
    },
    MuiExpansionPanel: {
      root: {
        width: '100%',
      },
    },
    MuiTableSortLabel: {
      root: {
        color: colors.white,
        '&:focus': {
          color: colors.white,
        },
        '&:hover': {
          color: colors.white,
          '& $icon': {
            color: colors.primaryBlue,
            opacity: 1,
          },
        },
        '&$active': {
          color: colors.white,
          // && instead of & is a workaround for https://github.com/cssinjs/jss/issues/1045
          '&& $icon': {
            opacity: 1,
            color: colors.primaryBlue,
          },
        },
      },
      icon: {
        color: colors.primaryBlue,
      },
    },
    MuiRadio: {
      colorSecondary: {
        '&$checked': {
          color: colors.white,
        },
      },
    },
    MuiInputBase: {
      formControl: {
        color: colors.white,
      },
    },
    MuiIconButton: {
      root: {
        color: colors.white,
      },
    },
    MuiPickersDay: {
      current: {
        color: 'black',
      },
      daySelected: {
        color: colors.primaryBlue,
      },
    },
  },
};

export default createMuiTheme(theme);
