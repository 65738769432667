import React from 'react';
import { Grid } from '@material-ui/core';
import { css } from 'emotion';
import PropTypes from 'prop-types';
import {
  getBillingOptions,
  hasBillOtherCarrier,
  isProviderPayment,
  getTypography,
  isCreditCardPayment,
  toggleStyleDisplay,
} from './util';

import {
  BILLING_TYPE,
  BILLING_BOCA_VALUE,
  ACCOUNT_NUMBER,
  ACCT_NUM_LABEL,
  BILLING_FILEDS,
  PAYMENT_TYPE,
} from './constants';

import { CustomRadio, CustomText } from '../common/InputComponents';
import AddressForm from '../common/AddressForm';

import * as colors from '../../styles/colors';

export default function CustomBilling(props) {
  const {
    billingDetails, accountType, purpose, isCreatedBy,
    selectedBillingOption, classes,formikProps
  } = props;
  const {
    setFieldValue,
  } = formikProps;
  const options = getBillingOptions(isCreatedBy, accountType, purpose);
  const billOtherCarrierFlag = hasBillOtherCarrier(options);

  const isDisabled = isProviderPayment(billingDetails);

  return (
    <Grid item container direction="column" spacing={2}>
      <Grid item>
        {getTypography('billing-header', 'Billing', css`
            color: ${colors.white};
            font-weight: 500
          `)}
      </Grid>
      <Grid item container direction="column">
        <Grid
          item
          className={css`
            padding: 12px 32px 18px !important;
            background-color: ${colors.darkBlueBackground};
          `}
        >
          <CustomRadio
            options={options}
            id={BILLING_TYPE}
            disabled={isDisabled}
            classes={classes}
            callback={(value) => {
              setFieldValue(BILLING_TYPE, value);
            }}
          />
        </Grid>
        {(billOtherCarrierFlag)
          ? (
            <CustomCarrierDetails
              billingDetails={billingDetails}
              classes={classes}
              selectedBillingOption={selectedBillingOption}
            />
          )
          : null}
      </Grid>
    </Grid>
  );
}

CustomBilling.propTypes = {
  formikProps: PropTypes.shape({
    isSubmitting: PropTypes.bool.isRequired,
    setFieldValue: PropTypes.func.isRequired,
  }).isRequired,
  billingDetails: PropTypes.objectOf(PropTypes.any),
  accountType: PropTypes.string.isRequired,
  isCreatedBy: PropTypes.string.isRequired,
  selectedBillingOption: PropTypes.string.isRequired,
  purpose: PropTypes.string.isRequired,
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
};

CustomBilling.defaultProps = {
  billingDetails: null,
};


function CustomCarrierDetails(props) {
  const {
    selectedBillingOption, billingDetails = null, classes,
  } = props;

  const isDisabled = isCreditCardPayment(billingDetails);
  const isShown = selectedBillingOption === BILLING_BOCA_VALUE;

  return (
    <Grid
      item
      style={{ display: toggleStyleDisplay(isShown) }}
      className={css`
          padding: 0px 32px 24px !important;
          background-color: ${colors.darkBlueBackground}
          `}
    >
      <Grid item container direction="column" spacing={2}>
        <Grid item>
          {getTypography('carrier-header', 'Carrier Account', css`
                color: ${colors.white};
                font-weight: 500;
                font-size: 18px;
              `)}
        </Grid>
        <Grid item container direction="column" spacing={2}>
          <Grid item>
            <CustomText
              {...{ classes }}
              name={ACCOUNT_NUMBER}
              label={ACCT_NUM_LABEL}
              disabled={isDisabled}
            />
          </Grid>
          <Grid
            item
            container
            justify="space-between"
            className={css`
                  > div {
                    width: 48.5%;
                  }
                `}
          >
            <AddressForm
              fields={BILLING_FILEDS}
              other={{
                classes,
                ...(isDisabled) && { disabled: BILLING_FILEDS },
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

CustomCarrierDetails.propTypes = {
  selectedBillingOption: PropTypes.string.isRequired,
  billingDetails: PropTypes.shape({
    [PAYMENT_TYPE]: PropTypes.string.isRequired,
  }),
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};
CustomCarrierDetails.defaultProps = {
  billingDetails: null,
};
