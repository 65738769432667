import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import {
  Grid, Typography, InputAdornment, IconButton, Tooltip,
} from '@material-ui/core';
import LookupIcon from '@material-ui/icons/Launch';

import NewOrderCustomTextField from '../../common/NewOrderCustomTextField';
import { FORM_FIELDS, FORM_LABELS } from '../itemDetailsConstants';
import { isFieldEditable } from '../itemDetailsUtil';
import { useItemProductDetailsFormStyles } from '../itemDetailsStyles';

export default function Codes({ editableFields }) {
  const classes = useItemProductDetailsFormStyles();
  return (
    <Grid item container direction="column" spacing={1}>
      <Grid item>
        <Typography color="primary" className={classes.formFieldHeader}>{FORM_LABELS.codes}</Typography>
      </Grid>
      <Grid item container spacing={1}>
        <Grid item xs={6}>
          <Field
            name={FORM_FIELDS.harmonizedCode.key}
            label={FORM_FIELDS.harmonizedCode.label}
            component={NewOrderCustomTextField}
            type="text"
            style={{ width: '100%' }}
            disabled={isFieldEditable(FORM_FIELDS.harmonizedCode.key, editableFields)}
            InputProps={{
              endAdornment:
  <InputAdornment position="end">
    <Tooltip title="Harmonized code lookup" placement="bottom">
      <IconButton
        aria-label="Harmonized code lookup"
        onClick={() => window.open('https://uscensus.prod.3ceonline.com/#!#%2Fp=0', '_blank')}
        edge="end"
      >
        <LookupIcon />
      </IconButton>
    </Tooltip>
  </InputAdornment>,

            }}
          />
        </Grid>

        <Grid item xs={6}>
          <Field
            name={FORM_FIELDS.eccnNo.key}
            label={FORM_FIELDS.eccnNo.label}
            component={NewOrderCustomTextField}
            type="text"
            style={{ width: '100%' }}
            disabled={isFieldEditable(FORM_FIELDS.eccnNo.key, editableFields)}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

Codes.propTypes = {
  editableFields: PropTypes.arrayOf(PropTypes.string).isRequired,
};
