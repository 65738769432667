import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Typography,
  Grid,
  Tooltip,
} from '@material-ui/core';
import {
  getCountryDescription,
} from '../../../context/miscDataContext';
import DeleteIcon from '../../images/DeleteIcon';
import * as colors from '../../../styles/colors';

function AddressBlock({
  classes,
  addressId,
  name,
  addressLine1,
  addressLine2,
  addressLine3,
  city,
  state,
  zip,
  countries,
  country,
  email,
  phone,
  removeAddress,
}) {
  return (
    <Grid
      container
      item
      className={classes.addressList}
      direction="row"
      justify="space-between"
    >
      <Grid item>
        <Typography className={classes.addressBlock}>
          <b
            style={{
              color: 'white',
            }}
          >
            {name}
          </b>
        </Typography>
        <Typography className={classes.addressBlock}>
          {` ${addressLine1}${addressLine2 ? `, ${addressLine2}` : ''}${
            addressLine3 ? `, ${addressLine3}` : ''
          } `}
        </Typography>
        <Typography className={classes.addressBlock}>
          {`${city}, ${state} ${zip} ${getCountryDescription(
            countries,
            country,
          )} `}
        </Typography>
        {email && <Typography className={classes.addressBlock}>{email}</Typography>}

        <Typography className={classes.addressBlock}>{phone || ''}</Typography>
      </Grid>
      <Grid>
        <Tooltip title="Remove" classes={{ tooltip: classes.tooltip }}>
          <Button onClick={() => { removeAddress(addressId); }} className={classes.contactButton}>
            <DeleteIcon color={colors.tableGreyText} />
          </Button>
        </Tooltip>
      </Grid>
    </Grid>
  );
}

AddressBlock.defaultProps = {
  addressLine2: undefined,
  addressLine3: undefined,
  countries: [],
};

AddressBlock.propTypes = {
  classes: PropTypes.shape(PropTypes.object).isRequired,
  addressId: PropTypes.string.isRequired,
  addressLine1: PropTypes.string.isRequired,
  addressLine2: PropTypes.string,
  addressLine3: PropTypes.string,
  city: PropTypes.string.isRequired,
  countries: PropTypes.arrayOf(PropTypes.object),
  country: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  zip: PropTypes.string.isRequired,
  removeAddress: PropTypes.func.isRequired,
};

export default memo(AddressBlock);
