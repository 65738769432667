import shortId from 'shortid';
import { isEmpty } from 'lodash';
import client from './apiClient';
import {
  DUMMY_FETCH_COUNTRIES,
  DUMMY_FETCH_SHIPPING_RATES,
  DUMMY_RATE_RESPONSE,
  DUMMY_FETCH_STATE,
  DUMMY_FETCH_CURRENCIES,
  DUMMY_FETCH_ALL_PACKAGING_DROPDOWN_DATA,
  DUMMY_TIMEZONES,
  DUMMY_FETCH_IOT_VENDORS,
  DUMMY_FETCH_FREIGHT_TYPE_OPTIONS,
  DUMMY_FETCH_SHIPMENT_FREIGHT_TYPE_OPTIONS,
  DUMMY_FETCH_SHIPMENT_PURPOSE_OPTIONS,
  DUMMY_FETCH_LICENSE_PERMIT_TYPES,
  DUMMY_FETCH_EXPORT_CONTROL,
  DUMMY_FETCH_SHIPMENT_TERMS_OF_SALE,
  DUMMY_FETCH_SHIPMENT_PARTY_TERMS,
  DUMMY_FETCH_SERVICES,
  DUMMY_FETCH_PROFILES,
  DUMMY_FETCH_HAZMAT_OPTIONS,
  DUMMY_FETCH_HAZMAT_CLASSES,
  DUMMY_FETCH_LOAD_REFRIGERANT_TYPES,
  DUMMY_FETCH_LOAD_DANGEROUS_GOODS_TYPES,
  DUMMY_FETCH_SERVICE_TYPE,
  DUMMY_FETCH_USER_TYPES,
  DUMMY_FETCH_USER_DATA,
  DUMMY_FETCH_ORDER_PURPOSES,
  DUMMY_FETCH_CARRIERS,
  DUMMY_FETCH_ACCOUNT_NOTIFICATION_TYPES,
  DUMMY_FETCH_PRICING_MODELS,
  DUMMY_FETCH_USER_NOTIFICATION_TYPES,
} from '../dummyData';
import { isAdminOrEHS } from './helpers';

const addIdToRows = (data) => data.map((sender) => ({ ...sender, id: shortId.generate() }));

function loadTimeZonesUserTypes(loggedUserType, fields) {
  const shouldLoadTimeZones = fields.findIndex((field) => field.name === 'timeZone') > -1;
  const shouldLoadUserTypes = fields.findIndex((field) => field.name === 'userType') > -1
    && isAdminOrEHS(loggedUserType);

  return Promise.all([
    shouldLoadTimeZones ? loadTimeZones() : null,
    shouldLoadUserTypes ? loadUserTypes() : null,
  ]);
}

function loadAdditionalServices() {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return Promise.resolve(DUMMY_FETCH_SERVICES);
  }
  return client('/data/master?name=additionalService', { method: 'get' });
}

function loadProfiles() {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return Promise.resolve(DUMMY_FETCH_PROFILES);
  }
  return client('/data/master?name=profile', { method: 'get' });
}

function loadOrderPurposes() {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return Promise.resolve(DUMMY_FETCH_ORDER_PURPOSES);
  }
  return client('/data/master?name=orderPurpose', { method: 'get' });
}

function loadPackageProfiles(freightType) {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return Promise.resolve(DUMMY_FETCH_PROFILES);
  }
  return client(`/data/master?name=packageProfile&freightType=${freightType}`, { method: 'get' });
}

function loadCountries() {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return Promise.resolve(DUMMY_FETCH_COUNTRIES);
  }
  return client('/data/master?name=country', { method: 'get' });
}

function loadFreightTypeOptions() {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return Promise.resolve(DUMMY_FETCH_FREIGHT_TYPE_OPTIONS);
  }
  return client('/data/master?name=orderFreightType', { method: 'get' });
}

function loadHazmatOptions() {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return Promise.resolve(DUMMY_FETCH_HAZMAT_OPTIONS);
  }
  return client('/data/master?name=orderHazmatOption', { method: 'get' });
}

function loadHazmatClassOptions() {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return Promise.resolve(DUMMY_FETCH_HAZMAT_CLASSES);
  }
  return client('/data/master?name=orderHazmatType', { method: 'get' });
}

function loadShipmentFreightTypes() {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return Promise.resolve(DUMMY_FETCH_SHIPMENT_FREIGHT_TYPE_OPTIONS);
    // return new Promise((resolve, reject) => {
    //   setTimeout(() => {
    //     resolve(DUMMY_FETCH_SHIPMENT_FREIGHT_TYPE_OPTIONS);
    //   }, 2000);
    // });
  }
  return client('/data/master?name=shipmentFreightType', { method: 'get' });
}

function loadTermsOfSale() {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return Promise.resolve(DUMMY_FETCH_SHIPMENT_TERMS_OF_SALE);
  }
  return client('/data/master?name=termsOfSale', { method: 'get' });
}

function loadPartyTerms() {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return Promise.resolve(DUMMY_FETCH_SHIPMENT_PARTY_TERMS);
  }
  return client('/data/master?name=partyTerms', { method: 'get' });
}

function loadShipmentPurposeOptions() {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return Promise.resolve(DUMMY_FETCH_SHIPMENT_PURPOSE_OPTIONS);
  }
  return client('/data/master?name=shipmentPurpose', { method: 'get' });
}

function loadLicensePermitTypes() {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return Promise.resolve(DUMMY_FETCH_LICENSE_PERMIT_TYPES);
  }
  return client('/data/master?name=licensePermitType', { method: 'get' });
}
function loadRefrigerantTypes() {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return Promise.resolve(DUMMY_FETCH_LOAD_REFRIGERANT_TYPES);
  }
  return client('/data/master?name=refrigerantType', { method: 'get' });
}
function loadDangerousGoodTypes() {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return Promise.resolve(DUMMY_FETCH_LOAD_DANGEROUS_GOODS_TYPES);
  }
  return client('/data/master?name=dangerousGoodsType', { method: 'get' });
}

function loadUserTypes() {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return Promise.resolve(DUMMY_FETCH_USER_TYPES);
  }

  return client('/data/master?name=userType', { method: 'get' });
}

function loadTimeZones() {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return Promise.resolve({ data: DUMMY_TIMEZONES });
  }
  return client('/data/timezones', { method: 'get' });
}

function loadCountryStates(country) {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return Promise.resolve(DUMMY_FETCH_STATE[country]);
  }
  return client(`/data/master?name=state&country=${country}`, { method: 'get' });
}

function loadCarriers() {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return Promise.resolve(DUMMY_FETCH_CARRIERS);
  }
  return client('/data/master?name=carrier', { method: 'get' });
}

function loadNotificationTypes(type = '') {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    if (isEmpty(type)) return Promise.resolve(DUMMY_FETCH_ACCOUNT_NOTIFICATION_TYPES);

    return Promise.resolve(DUMMY_FETCH_USER_NOTIFICATION_TYPES);
  }

  let url = '/data/master?name=notification';

  if (!isEmpty(type)) url += `&notificationType=${type}`;

  return client(url, { method: 'get' });
}

function loadPricingModels() {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return Promise.resolve(DUMMY_FETCH_PRICING_MODELS);
  }
  return client('/data/master?name=pricingModel', { method: 'get' });
}

function loadCurrencies() {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return Promise.resolve(DUMMY_FETCH_CURRENCIES);
  }
  return Promise.resolve(DUMMY_FETCH_CURRENCIES);
}

function loadCarrierOptions(shipmentId, expectedShipDate) {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(addIdToRows(DUMMY_FETCH_SHIPPING_RATES));
      }, 750);
    });
  }
  // return Promise.resolve(addIdToRows(DUMMY_FETCH_SHIPPING_RATES));
  return client(`/data/carrier/shop/${shipmentId}?expectedShipDate=${expectedShipDate}`, { method: 'get' });
}

function loadPackagingDropdownData(freightType) {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return Promise.resolve(DUMMY_FETCH_ALL_PACKAGING_DROPDOWN_DATA);
  }
  return client(`/data/packaging-dropdown?freightType=${freightType}`, { method: 'get' });
}

function loadIotVendors() {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return Promise.resolve(DUMMY_FETCH_IOT_VENDORS);
  }
  return client('/data/master?name=iotVendorName', { method: 'get' });
}

function loadFinalRates(shipmentId, {
  serviceCode, carrierName, shippingExtras, expectedShipDate,
}) {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return Promise.resolve(DUMMY_RATE_RESPONSE);
  }
  return client(`/data/carrier/rate/${shipmentId}`,
    {
      method: 'post',
      data: {
        serviceCode,
        carrierName,
        shippingExtras,
        expectedShipDate,
      },
    });
}

function loadAboutData() {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return Promise.resolve({ version: '1.1', build: '1.2' });
  }
  return client('/data/about', { method: 'get' });
}
function isAuthenticated() {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return Promise.resolve({ auth: true, profile: DUMMY_FETCH_USER_DATA });
  }
  return client('/isAuthenticated', { method: 'get' });
}
function logout() {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return Promise.resolve();
  }
  return client('/logout', { method: 'get' });
}

function resetUsers() {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return Promise.resolve();
  }
  return client('/login/reset', { method: 'post' });
}

function loadExportControl(shipmentId) {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ data: DUMMY_FETCH_EXPORT_CONTROL });
      }, 0.2000);
    });
  }
  return client(`/data//shipment/exportcontrol/${shipmentId}`, { method: 'get' });
}

function loadServiceTypesDropdown(shippingType) {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return Promise.resolve(DUMMY_FETCH_SERVICE_TYPE);
  }
  let url = '/data/master?name=serviceType';
  if (shippingType) {
    url += `&shippingType=${shippingType}`;
  }

  return client(url, { method: 'get' });
}

export {
  loadTimeZonesUserTypes,
  loadCountries,
  loadCountryStates,
  loadFinalRates,
  loadCarrierOptions,
  loadCurrencies,
  loadPackagingDropdownData,
  loadAboutData,
  isAuthenticated,
  logout,
  resetUsers,
  loadTimeZones,
  loadIotVendors,
  loadFreightTypeOptions,
  loadHazmatOptions,
  loadHazmatClassOptions,
  loadShipmentFreightTypes,
  loadShipmentPurposeOptions,
  loadLicensePermitTypes,
  loadTermsOfSale,
  loadPartyTerms,
  loadExportControl,
  loadAdditionalServices,
  loadProfiles,
  loadPackageProfiles,
  loadRefrigerantTypes,
  loadDangerousGoodTypes,
  loadServiceTypesDropdown,
  loadUserTypes,
  loadOrderPurposes,
  loadCarriers,
  loadNotificationTypes,
  loadPricingModels,
};
