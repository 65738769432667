export const COMPONENT_ID = 'billing-payment-form';

export const PAYMENT_TYPE = 'paymentType';
export const BILLING_TYPE = 'billingType';

export const PAYMENT_COSTCODES_LABEL = 'Cost Codes';
export const PAYMENT_NATIONWIDE_COSTCODES_LABEL = 'Accounting Unit/Activity Code';
const PAYMENT_CREDITCARD_LABEL = 'Pay with credit card';
const PAYMENT_PAYPAL_LABEL = 'Pay with PayPal';
export const PAYMENT_ACH_LABEL = 'Pay via bank transfer';
export const PAYMENT_INTERNAL_LABEL = 'Internal Payment';

export const PAYMENT_COSTCODES_VALUE = 'costCode';
export const PAYMENT_CREDITCARD_VALUE = 'creditCard';
export const PAYMENT_PAYPAL_VALUE = 'payPal';
export const PAYMENT_ACH_VALUE = 'ach';
export const PAYMENT_INTERNAL_VALUE = 'internal';

const BILLING_BDCA_LABEL = 'Bill default carrier account';
export const BILLING_BDCA_VALUE = 'billDefaultAccount';
export const BILLING_BOCA_VALUE = 'billOtherAccount';
const BILLING_BOCA_LABEL = 'Bill 3rd party carrier account';

export const ACCT_NUM_LABEL = 'Account Number';
export const ACCOUNT_NUMBER = 'accountNumber';

export const DEFAULT_COUNTRY = '';
export const COUNTRY = 'country';
export const ZIP = 'zip';
const ADDRESS_LINE2 = 'addressLine2';
const ADDRESS_LINE3 = 'addressLine3';
const ADDRESS_LINE1 = 'addressLine1';
const CITY = 'city';
const STATE = 'state';

export const USE_DEFAULT_ADDRESS = 'useDefaultAddress';

export const PAYMENT_OPTIONS = [
  {
    id: 0,
    label: PAYMENT_COSTCODES_LABEL,
    value: PAYMENT_COSTCODES_VALUE,
    type: ['large', 'individual'],
    purpose: ['work'],
    accountFlag: 'isCostCode',
  },
  {
    id: 1,
    label: PAYMENT_CREDITCARD_LABEL,
    value: PAYMENT_CREDITCARD_VALUE,
    type: ['large', 'individual'],
    purpose: ['work', 'personal'],
    accountFlag: 'isCreditCard',
  },
  {
    id: 2,
    label: PAYMENT_PAYPAL_LABEL,
    value: PAYMENT_PAYPAL_VALUE,
    type: ['large', 'individual'],
    purpose: ['work', 'personal'],
    accountFlag: 'isPayPal',
  },
  {
    id: 3,
    label: PAYMENT_ACH_LABEL,
    value: PAYMENT_ACH_VALUE,
    type: ['large', 'individual'],
    purpose: ['work', 'personal'],
    accountFlag: 'isACH',
  },
  {
    id: 3,
    label: PAYMENT_INTERNAL_LABEL,
    value: PAYMENT_INTERNAL_VALUE,
    type: ['large', 'individual'],
    purpose: ['work', 'personal'],
    accountFlag: 'isInternal',
  },
];

export const PAYMENT_OPTIONS_NATIONWIDE = [
  {
    id: 0,
    label: PAYMENT_NATIONWIDE_COSTCODES_LABEL,
    value: PAYMENT_COSTCODES_VALUE,
    type: ['large', 'individual'],
    purpose: ['work'],
    accountFlag: 'isCostCode',
  },
  {
    id: 1,
    label: PAYMENT_CREDITCARD_LABEL,
    value: PAYMENT_CREDITCARD_VALUE,
    type: ['large', 'individual'],
    purpose: ['work', 'personal'],
    accountFlag: 'isCreditCard',
  },
  {
    id: 2,
    label: PAYMENT_PAYPAL_LABEL,
    value: PAYMENT_PAYPAL_VALUE,
    type: ['large', 'individual'],
    purpose: ['work', 'personal'],
    accountFlag: 'isPayPal',
  },
  {
    id: 3,
    label: PAYMENT_ACH_LABEL,
    value: PAYMENT_ACH_VALUE,
    type: ['large', 'individual'],
    purpose: ['work', 'personal'],
    accountFlag: 'isACH',
  },
  {
    id: 3,
    label: PAYMENT_INTERNAL_LABEL,
    value: PAYMENT_INTERNAL_VALUE,
    type: ['large', 'individual'],
    purpose: ['work', 'personal'],
    accountFlag: 'isInternal',
  },
];

export const CREDIT_CARD_DETAILS = [
  { id: 0, name: 'creditCardDetails.creditCardName', label: 'Full Name' },
  { id: 1, name: 'creditCardDetails.creditCardNumber', label: 'Card Number' },
  { id: 2, name: 'creditCardDetails.creditCardExpiration', label: 'Expiration Date' },
  { id: 3, name: 'creditCardDetails.creditCardCVV', label: 'CVV' },
  { id: 4, name: 'creditCardDetails.creditCardZip', label: 'Zip' },
];

export const CREDIT_CARD_OWNERSHIP_TYPE_OPTIONS = [
  {
    label: 'Corporate',
    value: 'corporate',
    purpose: 'work',
  },
  {
    label: 'Personal',
    value: 'personal',
    purpose: 'personal',
  },
];

export const NEW_CARD_OPTION = {
  id: 'new credit card',
  label: 'New credit card',
  value: '',
};

export const NEW_ACCOUNT_OPTION = {
  id: 'new-bank-account',
  label: 'Add new account',
  value: '',
};

export const ACH_OWNERSHIP_TYPE_OPTIONS = [
  {
    label: 'Business',
    value: 'business',
    purpose: 'work',
  },
  {
    label: 'Personal',
    value: 'personal',
    purpose: 'personal',
  },
];


export const DEFAULT_VALUES = {
  [BILLING_TYPE]: BILLING_BDCA_VALUE,
  [PAYMENT_TYPE]: PAYMENT_COSTCODES_VALUE,
  // [PAYMENT_TYPE]: PAYMENT_COSTCODES_VALUE_2,
};

export const INITIAL_COST_ALLOCATION = {
  billCodes: [],
  instructions: [],
  maxAllocations: 1,
  splitAllocation: false,
  isDescriptionAllowed: true,
};


export const BILLING_OPTIONS = [
  {
    id: 0,
    label: BILLING_BDCA_LABEL,
    value: BILLING_BDCA_VALUE,
    type: ['large', 'individual'],
    purpose: ['work', 'personal'],
  },
  {
    id: 1,
    label: BILLING_BOCA_LABEL,
    value: BILLING_BOCA_VALUE,
    type: ['large', 'individual'],
    purpose: ['work'],
  },
];


export const CREDIT_CARD_FIELDS = [COUNTRY,
  ADDRESS_LINE1, ADDRESS_LINE2, ADDRESS_LINE3,
  CITY, STATE, ZIP,
];

export const ACH_BILLING_FIELDS = [COUNTRY,
  ADDRESS_LINE1, ADDRESS_LINE2, ADDRESS_LINE3,
  CITY, STATE, ZIP,
];

export const BILLING_FILEDS = [COUNTRY, ZIP];

export const EMPTY_BILLING_ADDRESS = {
  country: DEFAULT_COUNTRY,
  city: '',
  state: '',
  zip: '',
  addressLine1: '',
  addressLine2: '',
  addressLine3: '',
  phone: '',
  firstName: '',
  lastName: '',
};

// eslint-disable-next-line max-len
export const ACH_AUTH_TEXT = 'By clicking "SUBMIT PAYMENT", I authorize Braintree, a service of PayPal, on behalf of eShipGlobal (i) to verify my bank account information using bank information and consumer reports and (ii) to debit my bank account.';
