import React from 'react';
import { Field } from 'formik';
import {
  AppBar, CircularProgress, DialogContentText, Grid, RadioGroup, Tab, Tabs, Typography,
} from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import moment from 'moment';
import PropTypes from 'prop-types';
import { css } from 'emotion';
import { NEW_ORDER_MODULE_STYLE } from '../../styles/style';
import { loadFilteredContacts, useContactsState, useContactsDispatch } from '../../context/contactsContext';
import { CustomDatePicker, CustomContactSearch, CustomRecieveComponent } from './InputComponents';
import NewOrderTabPanel from './NewOrderTabPanel';
import ScrollWindow from './ScrollWindow';
import NewOrderAddressListItem from './NewOrderAddressListItem';

import {
  SELECT_FROM_CONTACTS_TAB_VALUE,
  ENTER_EMAIL_TAB_VALUE,
  TASK_ASSIGN_TABS,
} from '../../utils/assignmentUtil';

function CustomAppBar({ selectedTab, isDisabled, classes, onChange }) {

  const {
    appBar,
    appBarIndicator,
    appBarTab,
    appBarTabDisabled,
    appBarTabSelected,
  } = classes;

  return (
    <AppBar position="static" classes={{ root: appBar }}>
      <Tabs
        textColor="primary"
        value={selectedTab}
        onChange={onChange}
        classes={{ indicator: appBarIndicator }}
      >
        {TASK_ASSIGN_TABS.map((tab) => (
          <Tab
            key={`assign-task-tab-${tab.label}`}
            value={tab.value}
            label={tab.label}
            wrapped
            disabled={isDisabled}
            classes={{
              root: appBarTab,
              disabled: appBarTabDisabled,
              selected: appBarTabSelected,
            }}
          />
        ))}
      </Tabs>
    </AppBar>
  );
}
CustomAppBar.propTypes = {
  selectedTab: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    appBar: PropTypes.string.isRequired,
    appBarIndicator: PropTypes.string.isRequired,
    appBarTab: PropTypes.string.isRequired,
    appBarTabDisabled: PropTypes.string.isRequired,
    appBarTabSelected: PropTypes.string.isRequired,
  }).isRequired,
};


function CustomSelectContactTab({ selectedTab, classes }) {

  const [searchCache, setSearchCache] = React.useState([]);

  const contactsDispatch = useContactsDispatch();

  /*          Callbacks           */

  function getFilteredContacts(filter) {
    if (filter.length < 3) return;

    const minChars = filter.substring(0, 3).toLowerCase();

    if (searchCache.includes(minChars)) return;

    searchCache.push(minChars);
    setSearchCache(searchCache);

    loadFilteredContacts({ dispatch: contactsDispatch, filter: minChars });
  }

  /*            Render Components            */

  const searchField = (
    <Grid item>
      <CustomContactSearch
        name="filter"
        label="Search for a teammate"
        classes={classes}
        callback={getFilteredContacts}
      />
    </Grid>
  );

  const resultField = (
    <Grid
      item
      classes={{ root: classes.dialogAssignTaskContentResultsContainer }}
    >
      <CustomRecieveComponent
        component={CustomListField}
        name="selectedContact"
        callback={() => {}}
        classes={classes}
      />
    </Grid>
  );

  return (
    <NewOrderTabPanel
      index={SELECT_FROM_CONTACTS_TAB_VALUE}
      value={selectedTab}
      classes={classes}
    >
      <Grid
        container
        direction="column"
        style={{ marginBottom: '-15px' }}
      >
        {searchField}
        {resultField}
      </Grid>
    </NewOrderTabPanel>
  );
}

CustomSelectContactTab.propTypes = {
  selectedTab: PropTypes.string.isRequired,
  classes: PropTypes.shape({
    dialogAssignTaskContentResultsContainer: PropTypes.string.isRequired,
  }).isRequired,
};

function CustomMessageContent({ inviteEmail, isSubmitting, classes }) {
  const {
    dialogAssignTaskContentMessageContainer,
    dialogAssignTaskLabelErrorMessage,
    dialogAssignTaskIconError,
  } = classes;

  const message = (isSubmitting || inviteEmail) ? (
    <Grid container item direction="column" alignItems="center">

      { inviteEmail
        && (
        <Grid
          item
          container
          wrap="nowrap"
          alignItems="flex-start"
          classes={{ root: dialogAssignTaskContentMessageContainer }}
        >
          <Grid item>
            <ErrorIcon classes={{ root: dialogAssignTaskIconError }} />
          </Grid>
          <Grid item>
            <Typography
              display="inline"
              classes={{ root: dialogAssignTaskLabelErrorMessage }}
            >
              {`This email is not in the eShip system. Only enrolled users can collaborate on shipments.
              Do you want to send an enrollment invitation to this email?`}
            </Typography>
          </Grid>
        </Grid>
        )}
      { isSubmitting && !inviteEmail && (
      <Grid item>
        <CircularProgress color="secondary" />
      </Grid>
      )}
    </Grid>
  ) : null;

  return (
    <Grid container justify="center">
      {message}
    </Grid>
  );
}

CustomMessageContent.propTypes = {
  inviteEmail: PropTypes.string,
  isSubmitting: PropTypes.bool.isRequired,
  classes: PropTypes.shape({
    dialogAssignTaskContentMessageContainer: PropTypes.string.isRequired,
    dialogAssignTaskLabelErrorMessage: PropTypes.string.isRequired,
    dialogAssignTaskIconError: PropTypes.string.isRequired,
  }).isRequired,
};

CustomMessageContent.defaultProps = {
  inviteEmail: null,
};

function CustomListField({
  form, field, callback, classes,
}) {

  const { filter, selectedContact } = form.values;

  const content = (filter.length > 2)
    ? (
      <CustomFilterList
        fieldName={field.name}
        filter={filter}
        selectedContact={selectedContact}
        onChange={(e) => field.onChange(e)}
        callback={callback}
        classes={classes}
        disabled={form.isSubmitting}
      />
    )
    : null;

  return (
    <ScrollWindow>
      {content}
    </ScrollWindow>
  );
}

CustomListField.propTypes = {
  form: PropTypes.shape({
    isSubmitting: PropTypes.bool.isRequired,
    values: PropTypes.shape({
      filter: PropTypes.string,
      selectedContact: PropTypes.string,
    }).isRequired,
  }).isRequired,
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  callback: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    dialogAssignTaskScrollbarInnerRoot: PropTypes.string.isRequired,
    dialogAssignTaskScrollbarInnerVertical: PropTypes.string.isRequired,
  }).isRequired,
};

function CustomFilterList({
  fieldName, filter, selectedContact, onChange, callback, disabled, classes,
}) {

  const contacts = useContactsState().contacts || [];
  const filteredContacts = contacts.filter((contact) => {
    const values = Object.keys(contact).map((key) => {
      const value = contact[key];
      return typeof value === 'string' && value.toLowerCase().includes(filter.toLowerCase());
    });

    return values.includes(true);
  });

  return (
    <RadioGroup onChange={onChange}>
      {(filteredContacts.length === 0)
        ? <Typography classes={{ root: classes.noResults }}>No existing contacts</Typography>
        : buildContactList(fieldName, selectedContact, filteredContacts, disabled, callback)}
    </RadioGroup>
  );
}

CustomFilterList.propTypes = {
  fieldName: PropTypes.string.isRequired,
  filter: PropTypes.string.isRequired,
  selectedContact: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  callback: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  classes: PropTypes.shape({
    noResults: PropTypes.string.isRequired,
  }).isRequired,
};

CustomFilterList.defaultProps = {
  selectedContact: '',
};

function CustomEnterEmailTab({ selectedTab, classes } ) {
  return (
    <NewOrderTabPanel
      index={ENTER_EMAIL_TAB_VALUE}
      value={selectedTab}
      classes={classes}
    >
      <Grid
        container
        direction="column"
      >
        <Grid item>
          <CustomContactSearch
            name="email"
            label="Enter email"
            classes={classes}
            callback={() => {}}
          />
        </Grid>
      </Grid>
    </NewOrderTabPanel>
  );
}

CustomEnterEmailTab.propTypes = {
  selectedTab: PropTypes.string.isRequired,
  classes: PropTypes.objectOf(PropTypes.string.isRequired).isRequired,
};

function CustomDueDate({
  values, isDisabled, classes, minDueDate, maxDueDate, setValues
} ) {

  const {
    dialogAssignTaskContentDueDateContainer,
    dialogAssignTaskLabelDueDate,
  } = classes;
    /*          Callbacks           */

  function onDateChange(e) {
    if (!e) return;

    const year = e.getFullYear();
    const month = e.getMonth() + 1;
    const day = e.getDate();

    const yrMoDay = `${year}${(month < 10 ? `0${month}` : month)}${(day < 10 ? `0${day}` : day)}`;

    setValues({
      ...values,
      dueDate: yrMoDay,
    });
  }

  return (
    <Grid
      container
      direction="column"
      classes={{ root: dialogAssignTaskContentDueDateContainer }}
    >
      <Grid item>
        <Typography classes={{ root: dialogAssignTaskLabelDueDate }}>Due Date</Typography>
      </Grid>
      <Grid item>
        <CustomDatePicker
          name="dueDate"
          disabled={isDisabled}
          onChange={onDateChange}
          value={moment(values.dueDate, 'YYYYMMDD').format('MM/DD/YYYY')}
          classes={classes}
          minDate={minDueDate}
          maxDate={maxDueDate}
        />
      </Grid>
    </Grid>
  );
}

CustomDueDate.propTypes = {
  values: PropTypes.shape({
    dueDate: PropTypes.string.isRequired,
  }).isRequired,
  isDisabled: PropTypes.bool.isRequired,
  setValues: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    dialogAssignTaskContentDueDateContainer: PropTypes.string.isRequired,
    dialogAssignTaskLabelDueDate: PropTypes.string.isRequired,
  }).isRequired,
};


function buildContactList(fieldName, selectedContact, contacts, disabled, callback) {
  return (
    contacts.map((contact) => (
      <Grid key={`contacts-listItem-${contact.addressId}`} item>
        <NewOrderAddressListItem
          fieldName={fieldName}
          id={contact.addressId}
          contact={contact}
          isSelected={(selectedContact === contact.addressId)}
          allowEdit={false}
          disabled={disabled}
          onClick={() => callback(contact)}
        />
      </Grid>
    ))
  );
}

export default function AssignmentComponent({
  maxDueDate, minDueDate, selectedTab, isSubmitting, inviteSent,
  inviteEmail, onTabChange,
}) {
  const classes = NEW_ORDER_MODULE_STYLE();
  classes.dialogAssignTaskContentResultsContainer = css({
    height: 250,
  });
  const {
    dialogAssignTaskContentInviteContainer,
    // dialogAssignTaskLabelAppbarHeader,
    dialogAssignTaskIconSuccess,
    dialogAssignTaskContentSuccessContainer,
    dialogAssignTaskLabelSuccessMessage,
  } = classes;


  return (
    inviteSent
      ? (
        <DialogContentText
          component="div"
        >
          <Grid
            container
            direction="column"
            alignContent="center"
            alignItems="center"
          >
            <Grid item>
              <CheckCircleOutlineIcon classes={{ root: dialogAssignTaskIconSuccess }} />
            </Grid>
            <Grid
              item
              classes={{ root: dialogAssignTaskContentSuccessContainer }}
            >
              <Typography classes={{ root: dialogAssignTaskLabelSuccessMessage }}>
                {`An invitation to enroll in the eShip system has been sent to ${inviteEmail}.
            Once they enroll, they will receive a task assignment notification.`}
              </Typography>
            </Grid>
          </Grid>
        </DialogContentText>
      )
      : (
        <DialogContentText
          component="div"
          classes={{ root: dialogAssignTaskContentInviteContainer }}
        >
          <CustomAppBar
            selectedTab={selectedTab}
            onChange={onTabChange}
            isDisabled={isSubmitting}
            classes={classes}
          />
          <>
            <CustomSelectContactTab
              selectedTab={selectedTab}
              classes={classes}
            />
            <CustomEnterEmailTab
              selectedTab={selectedTab}
              classes={classes}
            />
            <Field>
              {({ form }) => (
                <CustomDueDate
                  values={form.values}
                  isDisabled={isSubmitting}
                  setValues={form.setValues}
                  classes={classes}
                  maxDueDate={maxDueDate && new Date(maxDueDate)}
                  minDueDate={minDueDate && new Date(minDueDate)}
                />
              )}
            </Field>
          </>
          <CustomMessageContent
            inviteEmail={inviteEmail}
            isSubmitting={isSubmitting}
            classes={classes}
          />
        </DialogContentText>
      )
  );
}
