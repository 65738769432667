/* eslint-disable radix */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid, Typography, Dialog, IconButton, CircularProgress,
} from '@material-ui/core';
import { Form, Field, Formik } from 'formik';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { pickBy } from 'lodash';
import * as billingUtil from '../../utils/billingUtil';
import { getEmptyAllocation } from '../BillingPayment/util';
import * as colors from '../../styles/colors';
import NewOrderWideButton from './NewOrderWideButton';
import ScrollWindow from './ScrollWindow';
import {
  CustomText,
  CustomSearchWithDropdown,
  CustomSearch,
} from './InputComponents';
import NewOrderErrorDialog from './NewOrderErrorDialog';
import NewOrderNextButton from './NewOrderNextButton';
import ConfirmationModal from './ConfirmationModal';
import {
  usePaymentDispatch,
  addCostCodeProfile,
  deleteCostCodeProfile,
  usePaymentState,
  makeDefaultCostCodeProfile,
} from '../../context/paymentContext';
import CostCodePanel from './CostCodePanel';

import { loadBillCodes } from '../../utils/paymentClient';

const paymentManagementStyles = {
  header: {
    color: colors.white,
    fontSize: '20px',
    fontWeight: '500',
    letterSpacing: '0.25px',
  },
  root: {
    marginTop: '30px',
  },
  dialogPaper: {
    background: colors.newOrderFormBackground,
    borderRadius: 8,
    overflow: 'hidden',
    maxWidth: 'none',
    zIndex: 3000,
  },
  dialogInnerContainer: {
    height: 880,
    width: 700,
    background: colors.newOrderFormBackground,
    padding: '35px 25px 10px 25px',
    borderRadius: 8,
    position: 'relative',
  },
  closeIcon: {
    position: 'absolute',
    top: 10,
    right: 10,
  },
  container: {
    color: colors.white,
    backgroundColor: colors.darkBlueBackground,
    padding: '12px 18px',
    borderRadius: '8px',
    margin: '8px',
  },
  headerText: {
    fontSize: '13px',
    fontWeight: '500',
    letterSpacing: '0.4px',
    textTransform: 'uppercase',
  },
  tooltip: {
    background: colors.tooltipBackground,
    fontSize: 12,
  },
  bodyText: {
    color: colors.textLightGrey,
    fontSize: 14,
    padding: '2px 0',
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  deleteIcon: {
    fill: 'white',
  },
  editPaymentContainer: {
    padding: '15px 15px',
    background: colors.darkBlueBackground,
  },
  preferredPaymentSelected: {
    color: 'gold',
  },
  preferredPaymentUnselected: {
    color: colors.textGrey,
  },
  preferredPaymentButton: {
    minWidth: 0,
  },
};

const addPaymentStyles = {
  submitPaymentText: {
    fontWeight: '700',
    fontSize: '14px',
    padding: '5px 20px',
    letterSpacing: '1px',
  },
  addPaymentContainer: {
    height: '100%',
  },
  addPaymentHeader: {
    color: colors.white,
    fontSize: 24,
    fontWeight: '500',
  },

  addPaymentInnerContainer: {
    background: colors.darkBlueBackground,
    padding: '25px 25px',
  },
  header: {
    color: colors.white,
    fontSize: '20px',
    fontWeight: '500',
    letterSpacing: '0.25px',
  },
  root: {
    marginTop: '30px',
  },
  dialogPaper: {
    background: colors.newOrderFormBackground,
    borderRadius: 8,
    overflow: 'hidden',
    maxWidth: 'none',
    zIndex: 3000,
  },
  dialogInnerContainer: {
    height: 880,
    width: 700,
    background: colors.newOrderFormBackground,
    padding: '35px 25px 10px 25px',
    borderRadius: 8,
    position: 'relative',
  },
  closeIcon: {
    position: 'absolute',
    top: 10,
    right: 10,
  },
  container: {
    color: colors.white,
    backgroundColor: colors.darkBlueBackground,
    padding: '12px 18px',
    borderRadius: '8px',
    margin: '8px',
  },
  headerText: {
    fontSize: '13px',
    fontWeight: '500',
    letterSpacing: '0.4px',
    textTransform: 'uppercase',
  },
  tooltip: {
    background: colors.tooltipBackground,
    fontSize: 12,
  },
  bodyText: {
    color: colors.textLightGrey,
    fontSize: 14,
    padding: '2px 0',
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  deleteIcon: {
    fill: 'white',
  },
  editPaymentContainer: {
    padding: '15px 15px',
    background: colors.darkBlueBackground,
  },
  preferredPaymentSelected: {
    color: 'gold',
  },
  preferredPaymentUnselected: {
    color: colors.textGrey,
  },
  preferredPaymentButton: {
    minWidth: 0,
  },
};

const usePaymentManagementStyles = makeStyles(paymentManagementStyles);
const useAddPaymentStyles = makeStyles(addPaymentStyles);

function AddCostCodeProfileForm(props) {
  const classes = useAddPaymentStyles();
  const formikRef = React.useRef();
  const dispatch = usePaymentDispatch();
  const [error, setError] = React.useState(null);
  const {
    costAllocation,
    costCodeProfile,
    closeAddCostCodeOption,
    open,
    setOpen,
  } = props;

  async function onSubmit(values, { setSubmitting }) {
    // eslint-disable-next-line no-shadow
    const formattedBillCodes = values.billCodes
      .map((bca) => pickBy(bca, (val, key) => (typeof val === 'string' && key !== 'key') || !!val.code));
    const payload = { ...values, billCodes: formattedBillCodes[0] };
    console.log(payload);
    try {
      await addCostCodeProfile({ dispatch, payload });
    } catch (e) {
      setError(e.message);
      setSubmitting(false);
      return;
    }
    setSubmitting(false);
    closeAddCostCodeOption();
  }

  function getInitialValues(ca) {
    const initialBillCodes = [];

    if (ca && ca.billCodes && initialBillCodes.length <= 0) {
      initialBillCodes.push(getEmptyAllocation(ca.billCodes));
    }

    const initialValues = {
      isDefault: costCodeProfile?.isDefault || false,
      isActive: costCodeProfile?.isActive || true,
      billCodes: initialBillCodes,
      profileName: costCodeProfile?.profileName,
    };
    return initialValues;
  }
  return (

    <Formik
      initialValues={getInitialValues(costAllocation)}
      validationSchema={billingUtil.getCostCodeProfileSchema(costAllocation)}
      ref={formikRef}
      onSubmit={onSubmit}
      validateOnBlur={false}
      render={render}
    />
  );

  function render({ isSubmitting, setFieldValue }) {
    return (
      <>
        <Form>
          <Dialog
            open={open}
            onClose={(e, reason) => {
              if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
                return;
              }
              return closeAddCostCodeOption();
            }}
            color="primary"
            classes={{
              paper: classes.dialogPaper,
              root: classes.root,
            }}
          >
            <Grid
              container
              direction="column"
              className={classes.dialogInnerContainer}
              spacing={1}
            >
              <IconButton
                className={classes.closeIcon}
                onClick={async () => {
                  await setFieldValue(
                    'billCodes[0]',
                    getEmptyAllocation(costAllocation.billCodes),
                  );
                  setOpen(false);
                }}
              >
                <CloseIcon color="primary" />
              </IconButton>
              <Grid
                item
                container
                className={classes.addPaymentContainer}
                direction="column"
                alignItems="center"
              >
                <Grid
                  item
                  container
                  justify="space-between"
                  style={{ marginBottom: 5 }}
                >
                  <Grid item container>
                    <Typography className={classes.addPaymentHeader}>
                      Add Cost Code Profile
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item container style={{ flexGrow: '1' }}>
                  <ScrollWindow>
                    <>
                      <Grid item xs={12} style={{ marginBottom: '8px' }}>
                        <Field
                          component={CustomText}
                          name="profileName"
                          label="Cost Code Profile Name"
                        />
                      </Grid>
                      <Grid container direction="column" spacing={1}>
                        {costAllocation?.billCodes?.map((bc) => (
                          <CustomCostCodeField
                            billCode={bc}
                            allocationNumber={0}
                            isDescriptionAllowed={costAllocation.isDescriptionAllowed}
                          />
                        ))}
                      </Grid>
                    </>
                  </ScrollWindow>
                </Grid>
                <Grid item style={{ padding: '10px 0' }}>
                  <NewOrderNextButton
                    style={{ width: 'fit-content', padding: '0 10px' }}
                    onClick={formikRef.current && formikRef.current.submitForm}
                    disabled={isSubmitting}
                  >
                    Save to your Profile
                  </NewOrderNextButton>
                </Grid>

                {isSubmitting && (
                <Grid item style={{ padding: '10px 0' }}>
                  <CircularProgress color="secondary" />
                </Grid>
                )}

                <NewOrderErrorDialog
                  open={!!error}
                  errorContent={error}
                  onClose={() => setError(null)}
                />
              </Grid>
            </Grid>
          </Dialog>
        </Form>
      </>
    );
  }
}

export default function CostCodeProfileManagement(props) {
  const classes = usePaymentManagementStyles();
  const [open, setOpen] = React.useState(false);
  const { costCodeProfile, costAllocation } = usePaymentState();

  const [deletingCostCodeProfile, setDeletingCostCodeProfile] = React.useState(null);
  const [editing, setEditing] = React.useState(null);
  const paymentDispatch = usePaymentDispatch();
  const [changingDefaultCostCodeProfile, setChangingDefaultCostCodeProfile] = React.useState(null);
  const [error, setError] = React.useState(null);

  const handleAddCostCodeButton = () => {
    setEditing(null);
    setOpen(true);
  };

  const handleCloseAddCostCodeOption = () => {
    setOpen(false);
  };

  async function handleDeleteCostCodeProfile(costCodeId) {
    try {
      await deleteCostCodeProfile({ dispatch: paymentDispatch, costCodeId });
    } catch (e) {
      setError(e.message);
    }
    setDeletingCostCodeProfile(null);
  }

  async function changeDefaultCostCodeProfile(costCodeId) {
    try {
      await makeDefaultCostCodeProfile({ dispatch: paymentDispatch, costCodeId });
    } catch (e) {
      setError(e.message);
    }
    setChangingDefaultCostCodeProfile(null);
  }

  return costAllocation ? (
    <>
      <Form>
        <Grid container spacing={4}>
          <Grid
            xs={12}
            item
            container
            justify="space-between"
            alignItems="center"
            spacing={2}
          >
            <Grid container item direction="row" justify="space-between">
              <Grid item>
                <Typography className={classes.header}>
                  Cost Code Profiles
                </Typography>
              </Grid>
              <Grid item>
                <NewOrderWideButton
                  startIcon={<AddIcon />}
                  style={{ width: '100%' }}
                  onClick={handleAddCostCodeButton}
                >
                  ADD COST CODE PROFILE
                </NewOrderWideButton>
              </Grid>
            </Grid>
            <Grid item container direction="column" spacing={3}>
              {costCodeProfile.length > 0 ? (
                costCodeProfile
                  .sort((a, b) => {
                    if (a.isDefault) return -1;
                    if (b.isDefault) return 1;
                    return 0;
                  })
                  .map((profile, id) => (
                    <CostCodePanel
                      // eslint-disable-next-line react/no-array-index-key
                      key={id}
                      costCodeProfile={profile}
                      costAllocation={costAllocation}
                      editing={editing}
                      setEditing={setEditing}
                      makeDefaultCostCodeProfile={setChangingDefaultCostCodeProfile}
                      deleteCostCodeProfile={setDeletingCostCodeProfile}
                    />
                  ))
              ) : (
                <Typography
                  h4
                  style={{
                    color: colors.lightBackgroundGrey,
                    margin: '5px 5px 5px 15px',
                  }}
                >
                  No Cost code Profile found. Click Add to add a new profile.
                </Typography>
              )}
            </Grid>
          </Grid>

          <AddCostCodeProfileForm
            open={open}
            setOpen={setOpen}
            costCodeProfile={[]}
            costAllocation={costAllocation}
            closeAddCostCodeOption={handleCloseAddCostCodeOption}
          />

          <ConfirmationModal
            open={!!deletingCostCodeProfile}
            onProceed={() => handleDeleteCostCodeProfile(deletingCostCodeProfile)}
            onCancel={() => setDeletingCostCodeProfile(null)}
            message="Are you sure you would like to delete this Cost Code Profile?"
            proceedLabel="Yes, delete"
          />
          <ConfirmationModal
            open={!!changingDefaultCostCodeProfile}
            onProceed={() => changeDefaultCostCodeProfile(changingDefaultCostCodeProfile)}
            onCancel={() => setChangingDefaultCostCodeProfile(null)}
            message="Are you sure you would like to change default cost code profile?"
            proceedLabel="Yes"
          />
          <NewOrderErrorDialog
            errorContent={error}
            onClose={() => setError(null)}
            open={!!error}
          />
        </Grid>
      </Form>
    </>
  ) : (
    <Grid container justify="center" alignItems="center">
      <CircularProgress color="secondary" />
    </Grid>
  );
}
function CustomCostCodeField(props) {
  const { billCode, allocationNumber, isDescriptionAllowed } = props;

  const [cache, setCache] = React.useState({});
  const [filteredCostCodeOptions, setFilteredCostCodeOptions] = React.useState(
    [],
  );

  const localFilter = (text) => {
    const filterRegex = new RegExp(text, 'i');
    return (d) => d.value.match(filterRegex) || d.label.match(filterRegex);
  };

  const updateFilter = (text) => {
    if (!text || text.length < 3) return setFilteredCostCodeOptions([]);
    const cacheKey = billCode.code + text;
    if (cache[cacheKey]) {
      setFilteredCostCodeOptions(cache[cacheKey].filter(localFilter(text)));
      return;
    }
    loadBillCodes(billCode.code, text).then((data) => {
      const dropdownList = data
        ? data.map((o) => ({
          ...o,
          value: o.code,
          label: isDescriptionAllowed
            ? `${o.code} - ${o.description || ''}`
            : o.code,
        }))
        : [];
      setFilteredCostCodeOptions(dropdownList);
      setCache((prev) => ({ ...prev, [text]: dropdownList }));
    });
  };

  const onSelect = (val) => {
    setFilteredCostCodeOptions([]);
  };

  return (
    <Grid item container>
      <Grid
        container
        item
        key={billCode.code}
        justify="space-between"
        spacing={3}
      >
        <Grid item xs={6}>
          <Field
            name={`billCodes[${allocationNumber}].${billCode.code}.code`}
            component={CustomSearchWithDropdown}
            label={`${billCode.description}${
              billCode.required ? '' : ' (optional)'
            }`}
            onSelect={onSelect}
            callback={updateFilter}
            dropdownList={filteredCostCodeOptions}
            dependentFields={
              isDescriptionAllowed
                ? [
                  {
                    name: `billCodes[${allocationNumber}].${billCode.code}.description`,
                    key: 'description',
                  },
                ]
                : []
            }
            inputProps={
              billCode.disabled
                ? { readOnly: true, disableUnderline: true, ...(billCode.length && { maxLength: parseInt(billCode.length) }) }
                : { ...(billCode.length && { maxLength: parseInt(billCode.length) }) }
            }
          />
        </Grid>
        {isDescriptionAllowed && (
          <Grid item xs={6}>
            <Field
              name={`billCodes[${allocationNumber}].${billCode.code}.description`}
              component={CustomSearch}
              inputProps={{ readOnly: true, disableUnderline: true }}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

CustomCostCodeField.propTypes = {
  billCode: PropTypes.objectOf(
    PropTypes.shape({
      code: PropTypes.string.isRequired,
      billCode: PropTypes.objectOf(
        PropTypes.shape({
          code: PropTypes.string.isRequired,
          description: PropTypes.string.isRequired,
        }),
      ),
    }),
  ).isRequired,
  allocationNumber: PropTypes.number.isRequired,
  isDescriptionAllowed: PropTypes.bool.isRequired,
};
