import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Route } from 'react-router-dom';

import { css } from 'emotion';
import * as colors from '../styles/colors';

function NavigateToLoginButton() {
  return (
    <Route render={({ history }) => (
      <Button
        className={css`
          font-weight: bold;
          border-radius: 8px;
          width: 17%;
          margin: auto;
          min-width: 110px;
        `}
        color="secondary"
        variant="contained"
        onClick={() => { history.push('/login'); }}
      >
        Login
      </Button>
    )}
    />
  );
}

const styles = {
  root: {
    height: '100%',
    width: '100%',
  },
  contentContainer: {
    paddingTop: 25,
  },
  formContainer: {
    marginTop: 20,
  },
  title: {
    color: colors.white,
    fontSize: 24,
    fontWeight: 500,
    paddingBottom: 20,
  },
  subHeader: {
    color: 'rgba(255,255,255,0.87)',
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0.15,
    paddingBottom: 29,
  },
  gridItem: {
    zIndex: 200,
  },
};

const useStyles = makeStyles(styles);

export default function ForgotPasswordEmailConfirmation(props) {
  const classes = useStyles();
  const { email } = props;

  return (
    <div className={classes.root}>
      <Grid
        container
        className={classes.contentContainer}
        justify="flex-start"
        direction="column"
      >
        <Grid className={classes.gridItem} item>
          <Typography className={classes.title}>Thank you!</Typography>
        </Grid>
        <Grid className={classes.gridItem} item>
          <Typography className={classes.subHeader}>
            {`If there is an account attached to the email ${email ? `${email}` : 'that you entered'} then you will be receiving an email from us shortly!`}
          </Typography>
        </Grid>
        <Grid item>
          <NavigateToLoginButton />
        </Grid>
      </Grid>
    </div>
  );
}
