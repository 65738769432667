import client from './apiClient';
import { DUMMY_FETCH_ALL_BINS_DATA, DUMMY_FETCH_SINGLE_BIN_DATA, DUMMY_FETCH_USER_BINS_DATA } from '../dummyData';

// eslint-disable-next-line import/prefer-default-export
export function loadBins({
  limit, offset, filters,
}) {
  // TODO remove prod
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    const dummyBins = [...DUMMY_FETCH_ALL_BINS_DATA];

    return Promise.resolve(dummyBins);
  }

  return client('/data/dashboard/bin', {
    method: 'get',
    params: {
      limit,
      offset,
      ...filters,
    },
  });
}

// eslint-disable-next-line import/prefer-default-export
export function loadAllBins() {
  // TODO remove prod
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    const dummyBins = [...DUMMY_FETCH_USER_BINS_DATA];

    return Promise.resolve(dummyBins);
  }

  return client('/data/bins/allbins', {
    method: 'get',
  });
}
// eslint-disable-next-line import/prefer-default-export
export function loadSingleBinDetails({
  packingSlipId,
}) {
  // TODO remove prod
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    const dummyBin = { ...DUMMY_FETCH_SINGLE_BIN_DATA };

    return Promise.resolve(dummyBin);
  }
  const payload = { Payload: { Data: { PackingSlipId: packingSlipId } } };

  return client('/data/bins/packingslip', {
    method: 'post',
    data: payload,

  });
}

// eslint-disable-next-line import/prefer-default-export
export function unScanPackingSlip() {
  // TODO remove prod
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    const dummyBin = { ...DUMMY_FETCH_SINGLE_BIN_DATA };

    return Promise.resolve(dummyBin);
  }
  const payload = {};

  return client('/data/bins/unscanpackingslip', {
    method: 'post',
    data: payload,

  });
}
// eslint-disable-next-line import/prefer-default-export
export function scanBin({
  binName,
}) {
  // TODO remove prod
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    const dummyBin = { ...DUMMY_FETCH_SINGLE_BIN_DATA };

    return Promise.resolve(dummyBin);
  }
  const payload = { binName };

  return client('/data/bins/scanbin', {
    method: 'post',
    data: payload,

  });
}

export function deleteSalesOrder({
  salesOrderId,
}) {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    const dummyBin = { ...DUMMY_FETCH_SINGLE_BIN_DATA };

    return Promise.resolve(dummyBin);
  }
  const payload = { salesOrderId };

  return client('/data/bins/deletesalesorder', {
    method: 'post',
    data: payload,

  });
}
// eslint-disable-next-line import/prefer-default-export
export function scanBinOrPackingSlip({
  input,
}) {
  // TODO remove prod
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    const dummyBin = { ...DUMMY_FETCH_SINGLE_BIN_DATA };

    return Promise.resolve(dummyBin);
  }
  const payload = { input };

  return client('/data/bins/scanbinorpackingslip', {
    method: 'post',
    data: payload,

  });
}
export async function duplicateBin(payload) {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return { bins: [{ binId: 'YALE-10000002-01' }] };
  }
  return client('/data/bin/duplicate', {
    method: 'post',
    data: payload,
  });
}
export async function updateBinStatus(payload) {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return { bins: [{ binId: 'YALE-10000002-01' }] };
  }
  return client('/data/bins/update', {
    method: 'post',
    data: payload,
  });
}
export async function updateBinPriority(payload) {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return { bins: [{ binId: 'YALE-10000002-01' }] };
  }
  return client('/data/bins/updatepriority', {
    method: 'post',
    data: payload,
  });
}
export async function closeBins(payload) {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return { bins: [{ binId: 'YALE-10000002-01' }] };
  }
  return client('/data/bins/close', {
    method: 'put',
    data: payload,
  });
}
export async function resetBins(payload) {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return { bins: [{ binId: 'YALE-10000002-01' }] };
  }
  return client('/data/bins/reset', {
    method: 'put',
    data: payload,
  });
}
