/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Field, Formik } from 'formik';
import {
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Link,
  MenuItem,
  TextField, Button,
} from '@material-ui/core';

import { css } from 'emotion';
import Typography from '@material-ui/core/Typography';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import { useHistory, useParams } from 'react-router-dom';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import BusinessOutlinedIcon from '@material-ui/icons/BusinessOutlined';
import PersonPinOutlinedIcon from '@material-ui/icons/PersonPinOutlined';
import AttachMoneyOutlinedIcon from '@material-ui/icons/AttachMoneyOutlined';
import LibraryAddOutlinedIcon from '@material-ui/icons/LibraryAddOutlined';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';
import AirportShuttleOutlinedIcon from '@material-ui/icons/AirportShuttleOutlined';
import FlightOutlinedIcon from '@material-ui/icons/FlightOutlined';
import LocalHospitalOutlinedIcon from '@material-ui/icons/LocalHospitalOutlined';
import CardTravelOutlinedIcon from '@material-ui/icons/CardTravelOutlined';
import { makeStyles } from '@material-ui/core/styles';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import PublicOutlinedIcon from '@material-ui/icons/PublicOutlined';
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined';
import CheckIcon from '@material-ui/icons/Check';
import * as Yup from 'yup';
import * as _ from 'lodash';
import StyledCheckCircleIcon from './common/StyledCheckCircleIcon';
import {
  useSingleOrderDispatch,
  createSingleOrder,
} from '../context/singleOrderContext';
import * as colors from '../styles/colors';
import CustomSelectTextField from './common/CustomSelectTextField';
import DisclaimerModal from './common/DisclaimerModal';
import NewOrderNextButton from './common/NewOrderNextButton';
import NewOrderNextButtonClear from './common/NewOrderNextButtonClear';
import { CustomCheckbox } from './common/InputComponents';
import {
  loadFreightTypeOptions,
  loadProfiles,
  loadAdditionalServices,
  useMiscDispatch,
  useMiscState,
} from '../context/miscDataContext';
import { NEW_ORDER_PATH } from '../clientConstants';
import { useUserState } from '../context/userContext';

const moment = require('moment');

const ORDER_OPTIONS = {
  work: {
    type: 'work',
    label: 'Business',
  },
  personal: {
    type: 'personal',
    label: 'Personal',
  },
  international: {
    type: 'international',
    label: 'International',
  },
  domestic: {
    type: 'domestic',
    label: 'Domestic',
  },
  freight: {
    type: 'freight',
    label: 'Freight',
  },
  product: {
    type: 'product',
    label: 'Product',
  },
  document: {
    type: 'document',
    label: 'Document',
  },
  heavyItem: {
    type: 'heavyItem',
    label: 'Heavy Item',
  },
  largeItem: {
    type: 'largeItem',
    label: 'Large Item',
  },
  healthCareItem: {
    type: 'healthCareItem',
    label: 'Health Care Item',
  },
  customsBroker: {
    type: 'customsBroker',
    label: 'Customs Broker',
  },
  dutiesTaxes: {
    type: 'dutiesTaxes',
    label: 'Duties & Taxes',
  },
  exportCompliance: {
    type: 'exportCompliance',
    label: 'Export/Import Compliance',
  },
};

const FORM_OPTIONS = {
  purpose: {
    values: [{ ...ORDER_OPTIONS.work }, { ...ORDER_OPTIONS.personal }],
    name: 'purpose',
    label: 'Is this order for work or personal use?',
  },
  shippingType: {
    values: [{ ...ORDER_OPTIONS.international }, { ...ORDER_OPTIONS.domestic }],
    name: 'shippingType',
    label: 'Is this shipment international or domestic?',
  },
  freightType: {
    name: 'freightType',
    label: 'Freight Type',
  },
  orderType: {
    name: 'orderType',
    // eslint-disable-next-line max-len
    label:
      'I hereby acknowledge that the order does not contain any product/article prohibited from shipping',
  },
  additionalServices: {
    values: [],
    name: 'additionalServices',
    label: 'Do you need additional Services?',
  },
};

const Icons = {
  [ORDER_OPTIONS.work.type]: BusinessOutlinedIcon,
  [ORDER_OPTIONS.personal.type]: HomeOutlinedIcon,
  [ORDER_OPTIONS.international.type]: PublicOutlinedIcon,
  [ORDER_OPTIONS.domestic.type]: RoomOutlinedIcon,
  [ORDER_OPTIONS.freight.type]: AirportShuttleOutlinedIcon,
  [ORDER_OPTIONS.product.type]: CardTravelOutlinedIcon,
  [ORDER_OPTIONS.document.type]: DescriptionOutlinedIcon,
  [ORDER_OPTIONS.heavyItem.type]: LocalShippingOutlinedIcon,
  [ORDER_OPTIONS.largeItem.type]: FlightOutlinedIcon,
  [ORDER_OPTIONS.healthCareItem.type]: LocalHospitalOutlinedIcon,
  [ORDER_OPTIONS.customsBroker.type]: PersonPinOutlinedIcon,
  [ORDER_OPTIONS.dutiesTaxes.type]: AttachMoneyOutlinedIcon,
  [ORDER_OPTIONS.exportCompliance.type]: LibraryAddOutlinedIcon,
};

const MODAL_DETAILS = {
  listHeader: 'Prohibited Articles for Shipping',
  leftListItems: [
    'Ammunition (except as expressly provided in the "UPS Tariff/Terms and Conditions of Service - United States" beginning on page 136)',
    'Bank bills, notes, or currency (other than coin)',
    'Corpses, cremated, or disinterred remains',
    'Postage stamps',
  ],
  rightListItems: [
    'Fireworks',
    'Hazardous waste',
    'Ivory',
    'Marijuana, including marijuana intended for medicinal use',
    'Shark fins',
  ],
  title: 'Prohibited Items',
  isWarningIcon: true,
  bodyText: [
    'It is the shipper´s responsibility to comply with US government regulations or laws applicable in each country. Shipments are subject to inspection and possible delay by customs or representatives of other government authorities.',
    'Carriers will not transport any goods which are prohibited by law or regulation of any federal, state or local government in the origin or destination countries or which may breach any applicable export, import or other laws or endanger the safety of our employees, agents and partners or the means of transportation or, which in our opinion, soil, paint or otherwise damage other goods or equipment or which are economically or operationally impractical to transport.',
  ],
  footer: {
    text: 'Ref: ',
    url: 'https://www.ups.com/us/en/help-center/shipping-support/prohibited-items.page',
  },
};

const PROHIBITED_DETAILS = {
  url: 'https://www.ups.com/cw/en/help-center/shipping-support/prohibited-items.page',
  footerText: '*For more information please consult: ',
};

const PROHIBITED_ITEMS = [
  ...MODAL_DETAILS.leftListItems,
  ...MODAL_DETAILS.rightListItems,
];

const styles = {
  root: {},
  expansionPanel: {
    backgroundColor: colors.darkBlueBackground,
    width: '100%',
  },
  expansionPanelChild: {
    backgroundColor: colors.mediumBlueBackground,
    margin: '-4px',
  },
  expansionPanelExpanded: {
    margin: '16px -4px',
  },
  expansionPanelLabel: {
    color: colors.white,
    fontSize: 24,
    fontWeight: 500,
  },
  expansionPanelLabelHead: {
    color: colors.white,
    fontSize: 32,
    fontWeight: 600,
    justifyContent: 'center',
  },
  expansionIcon: {
    color: colors.white,
  },
  expansionPanelHeadingText: {
    color: colors.white,
    fontSize: 24,
    width: 750,
  },
  orderSummaryText: {
    color: colors.white,
    fontSize: 20,
    fontWeight: 500,
    width: 750,
  },
  expansionPanelQuestionText: {
    color: colors.white,
    fontSize: 18,
  },
  expansionPanelIcon: {
    height: 125,
    width: 125,
    color: colors.white,
    float: 'right',
  },
  expansionPanelButton: {
    '&.Mui-disabled': {
      color: 'rgba(0,0,0,.30)',
      border: '1px solid rgba(42, 121, 212, 0.5)',
    },
    minWidth: 85,
    fontWeight: 600,
    marginRight: 20,
    textTransform: 'capitalize',
  },
  expansionSummaryContent: {
    padding: '25px 0',
  },
  informationIcon: {
    color: '#94CCFB',
    height: 18,
    width: 18,
    marginLeft: 5,
  },
  iconContainer: {
    marginLeft: 8,
    marginTop: 'auto',
  },
  summaryContainer: {
    paddingLeft: 20,
  },
  buttonContainer: {
    marginTop: 25,
  },
  detailsContainer: {
    width: '60vw',
  },
  orderDetailsContainer: {
    width: '100%',
  },
  orderBasicsHeaderLabel: {
    fontSize: '19px',
    fontWeight: 500,
  },
  searchFieldRoot: {
    color: colors.white,
  },
  inputRoot: {
    background: colors.mediumBlueBackground,
    color: colors.white,
  },
  searchFieldLabelRoot: {
    color: colors.white,
    fontSize: 16,
    fontWeight: 500,
    background: colors.mediumBlueBackground,
  },
  searchFieldWrap: {
    height: 45,
    borderRadius: 5,
    background: colors.mediumBlueBackground,
    marginLeft: '20px',
    width: 300,
    color: colors.white,
  },
  searchField: {
    width: '40vw',
    background: colors.mediumBlueBackground,
    color: colors.white,
    '& label': {
      color: colors.white,
      fontWeight: 500,
    },
    overflow: 'hidden',
    margin: 0,
    height: '100%',
    borderRadius: 5,
    marginRight: 20,
  },
  expansionPanelInternal: {
    backgroundColor: colors.darkBlueBackground,
    padding: '2rem',
  },
  orderBasicsSubHeaderLabel: {
    fontSize: '19px',
    fontWeight: 500,
    color: 'white',
  },
};

const useStyles = makeStyles(styles);
function SummaryPanel({
  date,
  purpose,
  creator,
  orderId,
  shippingType,
  freightType,
  additionalServices,
}) {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item container xs={12} className={classes.summaryContainer}>
        {orderId && (
          <Grid item>
            <Typography className={classes.orderSummaryText}>
              {`Order ${orderId}`}
            </Typography>
          </Grid>
        )}
        <Grid item>
          <Typography className={classes.orderSummaryText}>
            {`Opened ${date} by ${creator}`}
          </Typography>
        </Grid>
        <Grid
          item
          spacing={3}
          container
          style={{ marginTop: '12px', width: '100%' }}
        >
          {purpose && (
            <Grid item xs={4} style={{ width: 'auto', marginRight: 0 }}>
              <CustomToggleButton
                value={purpose}
                label={ORDER_OPTIONS[purpose] && ORDER_OPTIONS[purpose].label}
                hidden={false}
                selected
              />
            </Grid>
          )}
          {shippingType && (
            <Grid item xs={4} style={{ width: 'auto', marginRight: 0 }}>
              <CustomToggleButton
                value={shippingType}
                label={
                  ORDER_OPTIONS[shippingType]
                  && ORDER_OPTIONS[shippingType].label
                }
                hidden={false}
                selected
              />
            </Grid>
          )}
          {freightType && (
            <Grid item xs={4} style={{ width: 'auto', marginRight: 0 }}>
              <CustomToggleButton
                value={freightType}
                label={
                  ORDER_OPTIONS[freightType] && ORDER_OPTIONS[freightType].label
                }
                hidden={false}
                selected
              />
            </Grid>
          )}
          {(additionalServices || []).map((additionalService) => (
            <Grid item xs={4} style={{ width: 'auto', marginRight: 0 }}>
              <CustomToggleButton
                value={additionalService}
                label={
                    ORDER_OPTIONS[additionalService]
                    && ORDER_OPTIONS[additionalService].label
                  }
                hidden={false}
                selected
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}

SummaryPanel.propTypes = {
  date: PropTypes.string.isRequired,
  orderId: PropTypes.string,
  shippingType: PropTypes.string.isRequired,
  freightType: PropTypes.string.isRequired,
  purpose: PropTypes.string.isRequired,
  additionalServices: PropTypes.arrayOf(PropTypes.string),
  creator: PropTypes.string.isRequired,
};

SummaryPanel.defaultProps = {
  additionalServices: [],
  orderId: '',
};

function formatDateShort(date) {
  const momentDate = moment(date);
  return momentDate.format('DD MMM YYYY');
}
export default function OrderShipBasicsExpandablePane({
  selectedForms,
  accountType = 'large',
  isExistingOrder,
  setSelectedForms,
  existingDate,
  existingOrderType,
  order,
  userFullName,
  packingSlip,
  handlePackingSlipChange,
  getBinData,
  singleBinState,
  searchRef,
}) {
  const userState = useUserState();
  const date = formatDateShort(new Date());
  const creator = userFullName || 'you';
  const signature = userState && _.has(userState, 'signature') ? userState.signature : '';

  const classes = useStyles();
  const formName = 'order basics';
  React.useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    searchRef.current?.focus();
  }, []);
  function handleChange() {
    if (selectedForms.parent === formName) {
      setSelectedForms({ parent: '', child: '' });
    } else {
      setSelectedForms({ parent: formName, child: '' });
    }
  }
  return (
    <div className={classes.root}>

      <ExpansionPanel
        expanded={selectedForms.parent === formName}
        className={classes.expansionPanel}
        onChange={handleChange}
      >
        <ExpansionPanelSummary
          expandIcon={(
            <ExpandMoreIcon
              color="primary"
              classes={{ colorPrimary: classes.expansionIcon }}
            />
          )}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Grid item container>
            <Typography className={classes.expansionPanelLabelHead}>
              New Shipment
            </Typography>
            <Grid item className={classes.iconContainer}>
              {isExistingOrder ? (
                <StyledCheckCircleIcon isValidated isGreenWhenValid />
              ) : null}
            </Grid>
            <Grid item className={classes.detailsSubContainer}>
              <Typography className={classes.detailsLabel} />
            </Grid>
          </Grid>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={{ flexDirection: 'column' }}>
          <ExpansionPanel
            defaultExpanded
            className={classes.expansionPanelChild}
            classes={{ expanded: classes.expansionPanelExpanded }}
            onChange={() => {}}
            TransitionProps={{ unmountOnExit: true }}
          >
            <ExpansionPanelSummary
              expandIcon={(
                <ExpandMoreIcon
                  color="primary"
                  classes={{ colorPrimary: classes.expansionIcon }}
                />
                    )}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Grid container justify="space-between">
                <Grid item>
                  <Typography className={classes.orderBasicsSubHeaderLabel}>
                    Manual Entry
                  </Typography>
                </Grid>
              </Grid>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Grid
                container
                justify="center"
                alignItems="center"
                direction="row"
                className={classes.expansionPanelInternal}
              >
                <Grid
                  item
                >
                  <TextField
                    autoFocus
                    inputRef={searchRef}
                    id="filled-basic"
                    className={classes.searchField}
                    classes={{ root: classes.searchFieldRoot }}
                    label="Bin # / Pack Slip #"
                    margin="normal"
                    onChange={handlePackingSlipChange}
                    InputProps={{
                      disableUnderline: true,
                      classes: {
                        root: classes.inputRoot,
                        focused: classes.inputRoot,
                      },
                    }}
                    InputLabelProps={{
                      root: classes.searchFieldLabelRoot,
                    }}
                    variant="filled"
                    value={packingSlip}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        getBinData(e);
                      }
                    }}
                  />
                </Grid>
                <Grid item>
                  <Button
                    className={css`
                            font-weight: bold;
                            margin: auto;
                            padding: 6px 20px;
                            color: ${colors.white};
                            border-radius: 5px;
                            white-space: nowrap;
                          `}
                    color="secondary"
                    variant="contained"
                    onClick={getBinData}
                  >
                    Get Data
                  </Button>
                </Grid>
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          { singleBinState.shipment && singleBinState.shipment.orderId && isExistingOrder
          && (
          <>
            {isExistingOrder ? (
              <Grid item className={classes.orderSummaryContainer}>

                <SummaryPanel
                  purpose={order.purpose}
                  orderType={isExistingOrder ? existingOrderType : ''}
                  date={isExistingOrder ? existingDate : date}
                  freightType={order.freightType}
                  shippingType={order.shippingType}
                  additionalServices={order.additionalServices?.map(
                  ({ serviceType }) => serviceType
                )}
                  orderId={order.orderId}
                  classes={classes}
                  isExistingOrder={isExistingOrder}
                  creator={order.createdBy.fullName}
                  accountType={accountType}
                />
              </Grid>
            ) : (
              <Grid item className={classes.orderDetailsContainer}>
                <OrderBasics creator={creator} date={date} signature={signature} />
              </Grid>
            )}
          </>
          )}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
}

OrderShipBasicsExpandablePane.propTypes = {
  selectedForms: PropTypes.objectOf(PropTypes.string).isRequired,
  isExistingOrder: PropTypes.bool.isRequired,
  accountType: PropTypes.string,
  setSelectedForms: PropTypes.func.isRequired,
  existingOrderType: PropTypes.string.isRequired,
  existingDate: PropTypes.string.isRequired,
  userFullName: PropTypes.string,
  order: PropTypes.shape({
    purpose: PropTypes.string,
    freightType: PropTypes.string,
    shippingType: PropTypes.string,
    orderId: PropTypes.string,
    createdBy: PropTypes.shape({
      fullName: PropTypes.string,
    }),
  }),
};

OrderShipBasicsExpandablePane.defaultProps = {
  accountType: 'large',
  order: {},
  userFullName: '',
};

const orderBasicsStyles = {
  orderDetailsContainer: {
    background: colors.newOrderFormBackground,
    width: '100%',
    padding: '10px 24px',
  },
  orderSummaryContainer: {
    width: '100%',
    padding: '10px 24px',
  },
  orderBasicsHeaderLabel: {
    fontSize: '19px',
    fontWeight: 500,
  },
  buttonContainer: {
    padding: '20px 0 !important',
  },
  formContainer: {
    background: colors.newOrderFormBackground,
    width: '100%',
    padding: '10px 24px',
  },
  summaryContainer: {
    width: '100%',
  },
};

const useOrderBasicsStyles = makeStyles(orderBasicsStyles);

function OrderBasics({ creator, date }) {
  const singleOrderDispatch = useSingleOrderDispatch();
  const classes = useOrderBasicsStyles();
  const history = useHistory();
  const [showConfirmPage, setShowConfirmPage] = React.useState(false);
  const [showErrors, setShowErrors] = React.useState(false);

  const validationSchema = Yup.object().shape({
    purpose: Yup.string().required('Required'),
    shippingType: Yup.string().required('Required'),
    freightType: Yup.string().required('Required'),
    orderType: Yup.bool().oneOf([true], 'Must select check'),
    prohibitedItems: Yup.string().required(),
  });

  async function onSubmit(values) {
    const requestObj = {
      ...values,
      orderType: values.orderType ? 'NON-RMS' : 'RMS',
    };
    delete requestObj.profile;
    requestObj.additionalServices = (requestObj.additionalServices || []).map(
      (serviceType) => ({ serviceType }),
    );

    const res = await createSingleOrder({
      dispatch: singleOrderDispatch,
      orderBasics: requestObj,
    });
    history.replace(`${NEW_ORDER_PATH}/${res.order.orderId}`, { newOrder: true });
  }

  const { services, profiles } = useMiscState();
  const miscDispatch = useMiscDispatch();
  const defaultProfile = profiles?.find(({ isDefault }) => !!isDefault);

  React.useEffect(() => {
    loadFreightTypeOptions(miscDispatch);
    loadProfiles(miscDispatch);
    loadAdditionalServices(miscDispatch);
  }, [miscDispatch]);

  const getInitialValues = () => ({
    purpose: defaultProfile?.purpose || '',
    shippingType: defaultProfile?.shippingType || '',
    freightType: defaultProfile?.freightType || '',
    orderType: !!defaultProfile?.ackNotProhited,
    additionalServices:
        defaultProfile?.additionalServices?.map(
          ({ serviceType }) => serviceType
        ) || [],
    prohibitedItems: PROHIBITED_ITEMS.join(','),
    profile: defaultProfile?.profileName || 'Select Profile',
  });

  return (
    <Formik
      enableReinitialize
      initialValues={getInitialValues()}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formikProps) => (
        <Grid
          item
          container
          direction="column"
          spacing={1}
          className={
              showConfirmPage ? classes.summaryContainer : classes.formContainer
            }
        >
          {showConfirmPage ? (
            <SummaryPanel
              purpose={formikProps.values.purpose}
              shippingType={formikProps.values.shippingType}
              freightType={formikProps.values.freightType}
              additionalServices={formikProps.values.additionalServices}
              creator={creator}
              date={date}
            />
          ) : (
            <Grid item container spacing={2} direction="column">
              <Grid item>
                <Typography
                  className={classes.orderBasicsHeaderLabel}
                  color="primary"
                >
                  {`Hello, ${creator}. Just a quick question or two to get you started.`}
                </Typography>
              </Grid>
              <Grid item>
                {services && profiles ? (
                  <OrderBasicsForm
                    services={services}
                    profiles={profiles}
                    setFieldValue={formikProps.setFieldValue}
                    values={formikProps.values}
                    disabled={formikProps.isSubmitting}
                    errors={
                        showErrors && formikProps.errors
                          ? formikProps.errors
                          : {}
                      }
                  />
                ) : (
                  <Grid item style={{ margin: 'auto' }}>
                    <CircularProgress color="secondary" />
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}
          <Grid
            item
            container
            justify="center"
            className={classes.buttonContainer}
          >
            <Grid item>
              {!showConfirmPage ? (
                <NewOrderNextButton
                  onClick={() => formikProps.validateForm().then((err) => {
                    if (_.isEmpty(err)) setShowConfirmPage(true);
                    else setShowErrors(true);
                  })}
                  data-testid="submit-button"
                >
                  next
                </NewOrderNextButton>
              ) : (
                <ShowConfirmPageButtons
                  isSubmitting={formikProps.isSubmitting}
                  isValid={formikProps.isValid}
                  submitForm={formikProps.submitForm}
                  setShowConfirmPage={setShowConfirmPage}
                  formikProps={formikProps}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
    </Formik>
  );
}

OrderBasics.propTypes = {
  creator: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
};

function ShowConfirmPageButtons(props) {
  const {
    isSubmitting, submitForm, setShowConfirmPage, formikProps,
  } = props;
  return isSubmitting ? (
    <CircularProgress color="secondary" />
  ) : (
    <Grid item container alignItems="center" spacing={4}>
      <Grid item>
        <NewOrderNextButtonClear
          onClick={() => {
            formikProps.resetForm(formikProps.values);
            setShowConfirmPage(false);
          }}
          data-testid="submit-button"
        >
          Back
        </NewOrderNextButtonClear>
      </Grid>
      <Grid item>
        <NewOrderNextButton onClick={submitForm} data-testid="submit-button">
          Next
        </NewOrderNextButton>
      </Grid>
    </Grid>
  );
}

ShowConfirmPageButtons.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  formikProps: PropTypes.objectOf(PropTypes.any).isRequired,
  submitForm: PropTypes.func.isRequired,
  setShowConfirmPage: PropTypes.func.isRequired,
};

const orderBasicsFormStyles = {
  contentContainer: {
    background: colors.darkBlueBackground,
    height: '100%',
  },
  toggleComponentContainer: {
    padding: '25px',
  },
  prohibitedItemsContainer: {
    padding: '0px 25px 55px 55px',
  },
  icon: {
    height: '40px',
    width: 'auto',
    color: colors.white,
    padding: 0,
  },
  disclaimerText: {
    fontSize: '.9rem',
  },
  disclaimerTextHeader: {
    fontSize: '.9rem',
    fontWeight: 500,
  },
  checkBoxError: {
    color: colors.danger,
    fontSize: 14.75,
    fontWeight: 425,
  },
};
const useOrderBasicsFormStyles = makeStyles(orderBasicsFormStyles);

function OrderBasicsForm({
  setFieldValue,
  values,
  disabled,
  errors,
  services,
  profiles,
}) {
  const { freightTypeOptions } = useMiscState();
  const miscDispatch = useMiscDispatch();

  React.useEffect(() => {
    loadFreightTypeOptions(miscDispatch);
  }, [miscDispatch]);

  const classes = useOrderBasicsFormStyles();

  const purposeError = errors[FORM_OPTIONS.purpose.name];
  const shippingError = errors[FORM_OPTIONS.shippingType.name];
  const freightError = errors[FORM_OPTIONS.freightType.name];
  const orderTypeError = errors[FORM_OPTIONS.orderType.name];
  const additionalServiceError = errors[FORM_OPTIONS.additionalServices.name];

  const getAdditionalServices = () => (
      services?.[values?.purpose]?.[values?.shippingType]?.[values?.freightType]
        ?.additionalServices || []
    )
    .map(({ serviceType }) => ORDER_OPTIONS?.[serviceType])
      ?.filter((s) => !!s);

  const additionalServices = getAdditionalServices();

  const onProfileChange = (name) => {
    const profile = profiles.find(({ profileName }) => profileName === name);

    setFieldValue('purpose', profile?.purpose || '');
    setFieldValue('shippingType', profile?.shippingType || '');
    setFieldValue('freightType', profile?.freightType || '');
    setFieldValue('orderType', !!profile?.ackNotProhited);
    setFieldValue(
      'additionalServices',
      profile?.additionalServices?.map(({ serviceType }) => serviceType) || [],
    );
  };

  return (
    <>
      <Grid
        item
        container
        direction="column"
        className={classes.contentContainer}
        wrap="nowrap"
      >
        <Grid item container className={classes.toggleComponentContainer}>
          <Grid item container direction="column" spacing={1} wrap="nowrap">
            <Grid item>
              <Typography color="primary" className={classes.inputLabelText}>
                Profiles
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Field
                component={CustomSelectTextField}
                style={{ width: '100%' }}
                type="text"
                name="profile"
                label="Select Profile"
                data-testid="profile-input"
                customOnChange={(e) => onProfileChange(e?.target?.value)}
              >
                {profiles.map(({ profileName }) => (
                  <MenuItem key={profileName} value={profileName}>
                    {profileName}
                  </MenuItem>
                ))}
              </Field>
            </Grid>
          </Grid>
        </Grid>
        <Grid item className={classes.toggleComponentContainer}>
          <OrderBasicsFormToggleComponent
            setFieldValue={setFieldValue}
            label={FORM_OPTIONS.purpose.label}
            value={values.purpose}
            disabled={disabled}
            name={FORM_OPTIONS.purpose.name}
            options={FORM_OPTIONS.purpose.values}
            error={purposeError}
            onChange={(value) => {
              const additionalServices = (
                  services?.[value]?.[values?.shippingType]?.[
                    values?.freightType
                  ]?.additionalServices || []
                )
                .map(({ serviceName }) => ORDER_OPTIONS?.[serviceName])
                  ?.map(({ type }) => type) || [];
              setFieldValue('additionalServices', additionalServices || []);
            }}
          />
        </Grid>
        <Grid item className={classes.toggleComponentContainer}>
          <OrderBasicsFormToggleComponent
            setFieldValue={setFieldValue}
            label={FORM_OPTIONS.shippingType.label}
            value={values.shippingType}
            disabled={disabled}
            name={FORM_OPTIONS.shippingType.name}
            options={FORM_OPTIONS.shippingType.values}
            error={shippingError}
            onChange={(value) => {
              const additionalServices = (
                  services?.[values?.purpose]?.[value]?.[values?.freightType]
                    ?.additionalServices || []
                )
                .map(({ serviceName }) => ORDER_OPTIONS?.[serviceName])
                  ?.map(({ type }) => type) || [];
              setFieldValue('additionalServices', additionalServices || []);
            }}
          />
        </Grid>
        <Grid item className={classes.toggleComponentContainer}>
          <OrderBasicsFormToggleComponent
            setFieldValue={setFieldValue}
            label={FORM_OPTIONS.freightType.label}
            value={values.freightType}
            disabled={disabled}
            name={FORM_OPTIONS.freightType.name}
            options={freightTypeOptions}
            error={freightError}
            onChange={(value) => {
              const additionalServices = (
                  services?.[values?.purpose]?.[values?.shippingType]?.[value]
                    ?.additionalServices || []
                )
                .map(({ serviceName }) => ORDER_OPTIONS?.[serviceName])
                  ?.map(({ type }) => type) || [];
              setFieldValue('additionalServices', additionalServices || []);
            }}
          />
        </Grid>
        {additionalServices.length > 0 && (
          <Grid item className={classes.toggleComponentContainer}>
            <OrderBasicsFormToggleComponent
              setFieldValue={setFieldValue}
              label={FORM_OPTIONS.additionalServices.label}
              value={values.additionalServices}
              disabled={disabled}
              name={FORM_OPTIONS.additionalServices.name}
              options={additionalServices}
              error={additionalServiceError}
              multiSelect
            />
          </Grid>
        )}
        <Grid item className={classes.toggleComponentContainer} container>
          <Grid
            item
            style={{ maxWidth: '97%' }}
            data-testid="prohibited-checkbox-container"
          >
            <CustomCheckbox
              name={FORM_OPTIONS.orderType.name}
              label={
                orderTypeError ? (
                  <Typography
                    className={classes.checkBoxError}
                  >
                    {`${FORM_OPTIONS.orderType.label}*`}

                  </Typography>
                ) : (
                  FORM_OPTIONS.orderType.label
                )
              }
            />
          </Grid>
          <Grid item>
            <DisclaimerModal {...MODAL_DETAILS} />
          </Grid>
        </Grid>
        <Grid item className={classes.prohibitedItemsContainer}>
          <ProhibitedLabel />
        </Grid>
      </Grid>
    </>
  );
}

OrderBasicsForm.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.shape({
    purpose: PropTypes.string.isRequired,
    freightType: PropTypes.string.isRequired,
    shippingType: PropTypes.string.isRequired,
    orderType: PropTypes.bool.isRequired,
  }).isRequired,
  disabled: PropTypes.bool.isRequired,
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
};

function ProhibitedLabel(props) {
  const classes = useOrderBasicsFormToggleComponentStyles();
  return (
    <Grid item container direction="column" spacing={1}>
      <Grid item>
        <Typography color="primary" className={classes.inputLabelText}>
          Prohibited Articles for Shipping*:
        </Typography>
      </Grid>
      <Grid item>
        <Typography className={classes.prohibitedItemText}>
          <Grid item container spacing={2} style={{ margin: 0 }}>
            <Grid item xs={6}>
              <ul>
                {MODAL_DETAILS.leftListItems.map((item) => (
                  <li>{item}</li>
                ))}
              </ul>
            </Grid>
            {MODAL_DETAILS.rightListItems && (
              <Grid xs={6} item>
                <ul>
                  {MODAL_DETAILS.rightListItems.map((item) => (
                    <li>{item}</li>
                  ))}
                </ul>
              </Grid>
            )}
          </Grid>
        </Typography>
      </Grid>
      <Grid item>
        <Typography color="primary" className={classes.prohibitedItemText}>
          {`${PROHIBITED_DETAILS.footerText} `}
          {' '}
          <Link
            target="_blank"
            rel="noopener"
            color="secondary"
            href={PROHIBITED_DETAILS.url}
          >
            {PROHIBITED_DETAILS.url}
          </Link>
        </Typography>
      </Grid>
    </Grid>
  );
}

const useOrderBasicsFormToggleComponentStyle = {
  icon: {
    height: '40px',
    width: 'auto',
    color: colors.white,
    padding: 0,
    '&$disabled': {
      color: colors.disabledGrey,
    },
  },
  iconChecked: {
    height: '40px',
    width: 'auto',
    color: colors.lightSkyBlue,
    padding: 0,
  },
  iconDisabled: {
    height: '40px',
    width: 'auto',
    color: colors.disabledGrey,
    padding: 0,
  },
  radio: {
    '&:hover': {
      backgroundColor: 'transparent ',
    },
    paddingLeft: 0,
  },
  radioLabel: {
    color: colors.white,
    width: '100%',
  },
  formControlRoot: {
    alignItems: 'center',
    background: colors.newOrderFormBackground,
    margin: '0px',
    padding: '0px 20px',
    width: '100%',
  },
  inputLabelContainer: {
    paddingBottom: '12px',
  },
  inputLabelText: {
    fontWeight: 500,
  },

  checkedControlRoot: {
    alignItems: 'center',
    background: '#39455C',
    margin: '0px',
    paddingRight: '5px',
    paddingLeft: '20px',
    width: '100%',
  },
  prohibitedItemText: {
    fontSize: 14.75,
    fontWeight: 425,
    color: colors.textLightGrey,
  },
  disabledLabel: {
    color: colors.textLightGrey,
  },
};

const useOrderBasicsFormToggleComponentStyles = makeStyles(
  useOrderBasicsFormToggleComponentStyle,
);

// common component
function OrderBasicsFormToggleComponent({
  setFieldValue,
  label,
  name,
  value,
  options,
  disabled,
  error,
  multiSelect,
  onChange,
}) {
  const classes = useOrderBasicsFormToggleComponentStyles();
  // const [selectedButton, setSelectedButton] = React.useState(value);
  const [showMore, setShowMore] = React.useState(false);

  const isSelected = (option) => {
    if (multiSelect) {
      return (value || []).includes(option);
    }

    return option === value;
  };

  return (
    <Grid container direction="column">
      <Grid item className={classes.inputLabelContainer}>
        <Typography
          color="primary"
          className={classes.inputLabelText}
          style={{ color: error ? colors.danger : colors.white }}
        >
          {`${label}${error ? '*' : ''}`}

        </Typography>
      </Grid>
      <Grid container>
        <FormControl style={{ width: '100%' }}>
          <RadioGroup
            name={name}
            value={value}
            onChange={(e) => {
              let v = e?.target?.value;
              if (multiSelect) {
                if ((value || []).includes(v)) {
                  v = (value || []).filter((s) => s !== v);
                } else {
                  v = (value || []).concat([v]);
                }
              }

              setFieldValue(name, v);
              onChange && onChange(v, name);
            }}
          >
            <Grid item container spacing={2}>
              {options ? (
                options.map((option, index) => {
                  const hidden = !showMore && index > 2;
                  return (
                    <Grid
                      item
                      xs={4}
                      key={option?.type}
                      style={{
                        width: 'auto',
                        marginRight: 0,
                        height: hidden ? 0 : 'inherit',
                      }}
                    >
                      <CustomToggleButton
                        value={option?.type}
                        label={option?.label}
                        Icon={option?.icon}
                        hidden={hidden}
                        disabled={disabled}
                        selected={isSelected(option?.type)}
                      />
                    </Grid>
                  );
                })
              ) : (
                <Grid item style={{ margin: 'auto' }}>
                  <CircularProgress color="secondary" />
                </Grid>
              )}
            </Grid>
          </RadioGroup>
        </FormControl>
      </Grid>
      {options && options.length > 3 && (
        <Grid item container justify="center" style={{ paddingTop: '8px' }}>
          <NewOrderNextButtonClear onClick={() => setShowMore(!showMore)}>
            {showMore ? 'Show Less' : 'Show More'}
          </NewOrderNextButtonClear>
        </Grid>
      )}
    </Grid>
  );
}

OrderBasicsFormToggleComponent.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      icon: PropTypes.element,
    }),
  ),
  disabled: PropTypes.bool.isRequired,
  values: PropTypes.shape({
    purpose: PropTypes.string.isRequired,
    freightType: PropTypes.string.isRequired,
    shippingType: PropTypes.string.isRequired,
    orderType: PropTypes.bool.isRequired,
  }).isRequired,
  error: PropTypes.bool,
  multiSelect: PropTypes.bool,
  onChange: PropTypes.func,
};

OrderBasicsFormToggleComponent.defaultProps = {
  multiSelect: false,
  onChange: null,
  error: false,
  options: [],
};

function CustomToggleButton({
  label, value, selected, hidden, disabled,
}) {
  const classes = useOrderBasicsFormToggleComponentStyles();
  const Icon = Icons[value] || (() => <></>);
  return (
    <FormControlLabel
      style={{
        width: hidden ? 0 : '100%',
        visibility: hidden ? 'hidden' : 'visible',
        height: hidden ? 0 : '100%',
      }}
      control={(
        <Radio
          disableRipple
          icon={
            // eslint-disable-next-line no-nested-ternary
            <Icon className={selected ? classes.iconChecked : classes.icon} />
          }
          checkedIcon={<Icon className={classes.iconChecked} />}
          className={classes.radio}
          disabled={disabled}
          inputProps={{
            'data-testid': `radio-button-${value}`,
          }}
        />
      )}
      label={
        selected ? (
          <Grid
            item
            container
            justify="space-between"
            alignItems="center"
            wrap="nowrap"
            style={{ width: '100%' }}
          >
            <Grid item style={{ paddingRight: 0 }}>
              <Typography
                style={{ color: selected ? colors.lightSkyBlue : colors.white }}
              >
                {label}
              </Typography>
            </Grid>
            <Grid
              item
              style={{
                display: 'flex',
                marginBottom: '25px',
                padding: 0,
              }}
            >
              <CheckIcon
                style={{
                  color: colors.white,
                  height: '30px',
                  width: 'auto',
                  padding: '0',
                  paddingRight: '5px',
                }}
              />
            </Grid>
          </Grid>
        ) : (
          <Grid item container>
            <Typography>{label}</Typography>
          </Grid>
        )
      }
      value={value}
      classes={{
        label: classes.radioLabel,
        root: selected ? classes.checkedControlRoot : classes.formControlRoot,
        icon: classes.contentContainer,
        disabled: classes.disabledLabel,
      }}
    />
  );
}

CustomToggleButton.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  hidden: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};

CustomToggleButton.defaultProps = {
  disabled: false,
};
