import React from 'react';
import * as shipmentClient from '../utils/shipmentClient';
import { useLogger } from '../utils/hooks';

const shipmentState = { shipments: [], shipmentExport: [] };
const ShipmentStateContext = React.createContext();
const ShipmentDispatchContext = React.createContext();

// shipmentReducer Action Types
const LOAD_SHIPMENTS = 'LOAD SHIPMENTS';
const LOAD_SHIPMENTS_EXPORT = 'LOAD SHIPMENTS EXPORT';

function shipmentReducer(state, action) {
  const { shipments, offset } = action;
  const newShipments = action.type === LOAD_SHIPMENTS ? state.shipments.slice(0, offset).concat(shipments) : state.shipmentExport.slice(0, offset).concat(shipments);
  switch (action.type) {
    case LOAD_SHIPMENTS: {
      return {
        ...state,
        shipments: newShipments,
      };
    } case LOAD_SHIPMENTS_EXPORT: {
      return {
        ...state,
        shipmentExport: newShipments,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function ShipmentDataProvider(props) {
  const [state, dispatch] = useLogger(
    React.useReducer(shipmentReducer, shipmentState || { shipments: null }),
  );

  return (
    <ShipmentStateContext.Provider value={state} {...props}>
      <ShipmentDispatchContext.Provider
        value={dispatch}
        {...props}
      />
    </ShipmentStateContext.Provider>
  );
}

// TODO: update the shipmentClient.loadShipments function
// to handle these additional parameters (they will go in the request body)
function loadShipments(dispatch, {
  filter, offset, limit, status, startDate, endDate, purpose,
}) {
  return shipmentClient.loadShipments({
    filter, offset, limit, status, startDate, endDate, purpose,
  }).then((data) => {
    dispatch({ type: LOAD_SHIPMENTS, shipments: data, offset });
    return data;
  });
}

function loadShipmentExport(dispatch, {
  filter, offset, limit, status, startDate, endDate, purpose,
}) {
  return shipmentClient.loadShipmentExport({
    filter, offset, limit, status, startDate, endDate, purpose,
  }).then((data) => {
    dispatch({ type: LOAD_SHIPMENTS_EXPORT, shipments: data, offset });
    return data;
  });
}

function loadRecentShipments(dispatch, filters = {}, offset = 0, limit = 5) {
  return shipmentClient.loadShipments({
    offset, limit, filters,
  }).then((data) => {
    dispatch({ type: LOAD_SHIPMENTS, shipments: data, offset });
    return data;
  });
}

function useShipmentState() {
  const context = React.useContext(ShipmentStateContext);
  if (context === undefined) {
    throw new Error(
      'useShipmentState must be used within a ShipmentDataProvider',
    );
  }
  return context;
}

function useShipmentDispatch() {
  const context = React.useContext(ShipmentDispatchContext);
  if (context === undefined) {
    throw new Error(
      'useShipmentDispatch must be used within a ShipmentDataProvider',
    );
  }
  return context;
}

export {
  ShipmentDataProvider,
  useShipmentState,
  useShipmentDispatch,
  loadShipments,
  loadRecentShipments,
  loadShipmentExport,
};
