import React from 'react';
import axios from "axios";
import { Button } from '@material-ui/core';

export default function HealthCheck() {
  const handleHealthCheckClick = async () => {
      try {
        const options = {
          method: 'get',
          url: '/health',
          mode: 'no-cors'
        };

        const result = await axios(options);
        console.log(result);
      } catch (err) {
        alert(err.message);
      }
  }
  return (
    <div>
      <Button onClick={handleHealthCheckClick}>Health Check</Button>
    </div>
  )
}