import React from 'react';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import RadioGroup from '@material-ui/core/RadioGroup';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Formik, Field } from 'formik';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import { FormControl } from '@material-ui/core';
import Upload from '@material-ui/icons/PublishOutlined';
import * as Yup from 'yup';
import * as pdfjs from 'pdfjs-dist/build/pdf';
import { VerbosityLevel } from 'pdfjs-dist';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import mammoth from 'mammoth';
import * as colors from '../../styles/colors';
import NewOrderNextButton from './NewOrderNextButton';
import NewOrderNextButtonClear from './NewOrderNextButtonClear';
import ScrollWindow from './ScrollWindow';
import ErrorDialog from './NewOrderErrorDialog';
import { useSingleOrderDispatch, uploadShippingDocument } from '../../context/singleOrderContext';
import { arrayBufferToBase64 } from '../../utils/documentsUtil';
import docxImage from '../images/docx.png';
import docImage from '../images/doc.png';
import xlsxImage from '../images/xlsx.png';
import xlsImage from '../images/xls.png';
import csvImage from '../images/csv.png';
import { TaskAssignedToUser } from './TaskAssigned';
import ConfirmationModal from './ConfirmationModal';
import { getPackageNumber } from '../../utils/helpers';
import FileDropZone from './FileDropZone';
import NewOrderCustomTextField from './NewOrderCustomTextField';

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;
const ACCEPTED_FILE_EXTENSIONS = ['.pdf', '.jpg', '.jpeg', '.png', '.doc', '.docx', '.xls', '.xlsx', '.csv'];
const ACCEPTED_FILE_EXTENSIONS_STR = ACCEPTED_FILE_EXTENSIONS.join(',');
const IMAGE_FILE_EXTENSIONS = ['.png', '.jpg', '.jpeg'];
const WORD_FILE_EXTENSIONS = ['.doc', '.docx'];

const extensionImageMap = {
  '.xlsx': xlsxImage,
  '.docx': docxImage,
  '.doc': docImage,
  '.csv': csvImage,
  '.xls': xlsImage,
};

const uploadDocumentStyles = {
  dialogContainer: {
    width: 700,
    height: 700,
    overflow: 'hidden',
    background: 'transparent',
  },
  contentContainer: {
    background: colors.newOrderFormBackground,
    width: '100%',
    padding: '30px 25px',
    borderRadius: 8,
    flexWrap: 'nowrap',
    overflow: 'hidden',
    minHeight: '100%',
  },
  closeIcon: {
    position: 'absolute',
    top: 10,
    right: 10,
    '&:hover': {
      background: 'inherit',
    },
  },
  header: {
    fontSize: 24,
    fontWeight: 700,
  },
  subHeader: {
    fontSize: 20,
    fontWeight: 700,
  },
  subHeaderContainer: {
    padding: '4px 0',
  },
  description: {
    fontSize: 16,
    fontWeight: 500,
  },
  divider: {
    height: '1px',
    width: '100%',
    '&:after': {
      content: '""',
      height: '1px',
      background: colors.textDarkGrey,
      width: '100%',
    },
  },
  radioLabel: {
    color: colors.white,
  },
  radio: {
    color: colors.white,
    padding: 0,
    marginRight: 8,
  },
  labelRoot: {
    marginLeft: 0,
  },
  uploadFileButton: {
    width: '100%',
    height: '50px',
    background: 'rgb(54,57,69)',
    boxShadow: '0 1px 5px -2px black',
  },
  uploadFileButtonLabel: {
    textTransform: 'none',
    color: colors.white,
    fontWeight: 500,
    paddingLeft: '5px',

  },
  uplaodFileButtonIcon: {
    color: 'rgba(255,255,255,0.6)',
    display: 'flex',
  },
  previewContainer: {
    background: colors.darkBlueBackground,
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    maxHeight: '350px',
  },
  previewContainerActive: {
    background: 'transparent',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  submitButtonsContainer: {
    paddingTop: 10,
    justifyContent: 'center',
    height: 62,
  },
  canvas: {
    width: '100%',
  },
  canvasWordDoc: {
    height: '100%',
  },
  thumbVertical: {
    background: 'rgba(0,0,0,0.5) !important',
  },
  inputSectionContainer: {
    height: '320px',
    overflow: 'hidden',
  },
  dropZoneContainer: {
    display: 'flex',
    flexGrow: 1,
    height: '100%',
  },
  hideDropZoneContainer: {
    display: 'none',
  },
};

const useUploadDocumentStyles = makeStyles(uploadDocumentStyles);

function getInitialValues(values = {}, isFreightAdmin) {
  return {
    documentType: '',
    document: '',
    ...(isFreightAdmin && { trackingNumber: '' }),
    ...values,
  };
}

export default function UploadDocument(props) {
  const {
    uploadObject, open, onClose, shipmentId, isFreightAdmin,
  } = props;

  const {
    documentType,
    documentName,
    packageId,
    documentId,
    uploadOptions,
    displayName,
    nonRMSContents,
    documents,
    task,
  } = (uploadObject || {});

  const options = (documentType && !uploadOptions) ? [{ documentType, documentName, displayName }] : uploadOptions;

  const dispatch = useSingleOrderDispatch();
  const fileInputRef = React.useRef(null);
  const canvasRef = React.useRef(null);
  const [error, setError] = React.useState(null);
  const [wordDoc, setWordDoc] = React.useState(null);
  const [submitTask, setSubmitTask] = React.useState(null);
  const [internalOpen, setInternalOpen] = React.useState(false);

  React.useEffect(() => {
    if (uploadObject) {
      setInternalOpen(true);
    }
  }, [uploadObject]);

  const classes = useUploadDocumentStyles();

  async function onSubmit(values, { setSubmitting }) {
    // eslint-disable-next-line no-shadow
    const { document, documentType, trackingNumber } = values;
    const replaceDocument = (documents && !documentId && documentType !== 'other')
      ? documents.find((doc) => doc.documentType === documentType)
      : null;
    const docId = documentId || (replaceDocument
      ? replaceDocument.documentId
      : null);
    try {
      await uploadShippingDocument({
        dispatch, shipmentId, document, documentType, packageId, documentId: docId, trackingNumber,
      });
    } catch (e) {
      setError(JSON.stringify({ error: 'Failed to upload document. Please verify you have a valid file and try again.' }));
    }
    setSubmitting(false);
    handleClose();
  }

  async function handlePreview(file) {
    setWordDoc(null);
    const fileType = file.type;
    const fileExtMatch = file.name.match(/\.\w+$/);
    const fileExt = (fileExtMatch ? fileExtMatch[0] : '').toLowerCase();
    const canvas = canvasRef.current;
    if (!canvas) return;
    const context = canvas.getContext('2d');
    const arrayBuffer = await file.arrayBuffer();
    canvas.style.width = canvas.clientWidth;

    if (fileType === 'application/pdf' || fileExt === '.pdf') {
      const doc = await pdfjs.getDocument({ data: arrayBuffer, verbosity: VerbosityLevel.ERRORS }).promise;
      const page = await doc.getPage(1);
      const scale = 2;
      const viewport = page.getViewport({ scale });

      canvas.height = viewport.height;
      canvas.width = viewport.width;

      // Render PDF page into canvas context
      const renderContext = {
        canvasContext: context,
        viewport,
      };
      const renderTask = page.render(renderContext);
      renderTask.promise.then(() => {
      //
      });
      return;
    }
    if (IMAGE_FILE_EXTENSIONS.includes(fileExt)) {
      const img = new Image();
      const base64Image = arrayBufferToBase64(arrayBuffer);
      img.src = `data:${file.type};base64,${base64Image}`;
      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height / img.width * canvas.width;
        context.drawImage(img, 0, 0, img.width, img.height, 0, 0, canvas.width, canvas.height);
      };
      return;
    }
    if (WORD_FILE_EXTENSIONS.includes(fileExt)) {
      context.clearRect(0, 0, canvas.width, canvas.height);
      const html = await mammoth
        .convertToHtml({ arrayBuffer }, {
          styleMap: [
            'img => !',
          ],
          convertImage: mammoth.images.imgElement((image) => image.read('base64').then((imageBuffer) => ({
            src: `data:${image.contentType};base64,${imageBuffer}`,
            style: `max-width: ${canvas.clientWidth}px;`,
          }))),
        })
        .catch((e) => null);

      if (html) {
        setWordDoc(html.value);
        return;
      }
    }
    if (!ACCEPTED_FILE_EXTENSIONS.includes(fileExt)) {
      throw new Error(`Document type not supported. Please upload one of the following formats: ${ACCEPTED_FILE_EXTENSIONS.join(', ')}`);
    }
    context.clearRect(0, 0, canvas.width, canvas.height);
    const img = new Image();
    const imgScale = 0.7;
    img.src = extensionImageMap[fileExt];

    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height / img.width * canvas.width;
      context.drawImage(img, 0, 0, img.width, img.height, canvas.width * (1 - imgScale) / 2, 0, canvas.width * imgScale, canvas.height * imgScale);
      context.globalCompositeOperation = 'source-in';
      context.fillStyle = colors.white;
      context.fillRect(0, 0, canvas.width, canvas.height);
    };
  }

  const validationSchema = Yup.object().shape({
    documentType: Yup.string().required('Required'),
    document: Yup.mixed()
      .test('testDocumentType', 'Not a valid file', (val) => val instanceof File)
      .required('Required'),
    ...(isFreightAdmin && { trackingNumber: Yup.string().required('Required') }),
  });

  function handleClose() {
    setWordDoc(null);
    setInternalOpen(false);
  }

  return (
    <>
      <Dialog
        open={internalOpen}
        onClose={handleClose}
        classes={{ paper: classes.dialogContainer }}
        disableBackdropClick
        disableEscapeKeyDown
        onExited={() => {
          onClose();
        }}
      >
        <IconButton
          className={classes.closeIcon}
          onClick={handleClose}
          disableFocusRipple
          disableRipple
          disableTouchRipple
        >
          <CloseIcon color="primary" />
        </IconButton>
        <Formik
          initialValues={getInitialValues(documentType && { documentType }, isFreightAdmin)}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {
          (formikProps) => (
            <ScrollWindow classes={{ thumbVertical: classes.thumbVertical }}>
              <Grid container className={classes.contentContainer} direction="column" alignItems="center" spacing={2}>
                <Grid item container direction="column">
                  <Grid item>
                    <Typography color="primary" className={classes.header}>
                      Upload
                      {' '}
                      {!documentType ? 'Shipping Document' : ''}
                    </Typography>
                  </Grid>
                  {(packageId || documentType) && (
                  <Grid item className={classes.subHeaderContainer}>
                    {documentType ? (
                      <Typography color="primary" className={classes.subHeader}>
                        {displayName || documentName}
                      </Typography>
                    ) : (
                      <Typography color="primary" className={classes.subHeader}>
                        Package
                        {' '}
                        {getPackageNumber(packageId)}
                      </Typography>
                    )}

                  </Grid>
                  )}
                  {nonRMSContents
                  && (
                  <Grid item>
                    <Typography color="primary" className={classes.description}>
                      {nonRMSContents || ''}
                    </Typography>
                  </Grid>
                  )}
                </Grid>
                { !documentType && <Grid item container className={classes.divider} />}
                <Grid
                  item
                  container
                  direction="column"
                  alignItems="center"
                  className={classes.inputSectionContainer}
                  style={{ flexGrow: 1 }}
                  spacing={2}
                >
                  { task
                  && (
                    <Grid item container>
                      <TaskAssignedToUser
                        task={task}
                        preReqsComplete
                        TaskIcon={Upload}
                      />
                    </Grid>
                  )}
                  <Grid
                    item
                    container
                    justify="space-between"
                    style={{ flexGrow: 1 }}
                  >
                    {!documentType
                    && (
                    <Grid item xs={6}>
                      <FormControl color="primary">
                        <RadioGroup
                          name="documentType"
                          value={formikProps.values.documentType}
                          onChange={(e) => formikProps.setFieldValue('documentType', e.target.value)}
                        >
                          <Grid item container direction="column" spacing={2}>
                            {options && options.map((docOption) => (
                              <Grid item>
                                <FormControlLabel
                                  control={(
                                    <Radio className={classes.radio} />
                              )}
                                  label={docOption.displayName || docOption.documentName}
                                  value={docOption.documentType}
                                  classes={{ label: classes.radioLabel, root: classes.labelRoot }}
                                />
                              </Grid>
                            ))}
                          </Grid>
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    )}

                    <Grid
                      item
                      container
                      direction="column"
                      xs={documentType ? 12 : 6}
                      spacing={2}
                      wrap="nowrap"
                      // style={{ height: '100%' }}
                      alignItems="center"
                    >
                      <Grid
                        item
                        container
                      >
                        {isFreightAdmin
                         && (
                         <Grid item xs={6} style={{ marginBottom: '1rem' }}>
                           <Field
                             component={NewOrderCustomTextField}
                             type="text"
                             name="trackingNumber"
                             label="Tracking Number"
                             disabled={false}
                             customOnChange={(e) => formikProps.setFieldValue('trackingNumber', e.target.value)}
                           />
                         </Grid>
                         )}
                        <Button
                          className={classes.uploadFileButton}
                          onClick={() => fileInputRef.current && fileInputRef.current.click()}
                        >
                          <Grid container justify="space-between" alignItems="center">
                            <Grid item style={{ maxWidth: 'calc(100% - 2em)' }}>
                              <Typography noWrap className={classes.uploadFileButtonLabel}>
                                {(formikProps.values.document && formikProps.values.document.name) || 'Choose File'}
                              </Typography>
                            </Grid>
                            <Grid item className={classes.uplaodFileButtonIcon}>
                              <SearchIcon />
                            </Grid>
                          </Grid>
                        </Button>
                        <input
                          type="file"
                          name="document"
                          accept={ACCEPTED_FILE_EXTENSIONS_STR}
                          onChange={(e) => {
                            const currFile = e.target.files[0];
                            if (currFile) {
                              handlePreview(currFile)
                                .then(() => {
                                  formikProps.setFieldValue('document', currFile);
                                })
                                .catch((ex) => {
                                  setError(JSON.stringify({ error: ex.message }));
                                  return false;
                                });
                            }
                          }}
                          style={{ display: 'none' }}
                          ref={fileInputRef}
                        />
                      </Grid>
                      <Grid
                        item
                        container
                        style={{ overflow: 'hidden', flexGrow: 1 }}
                        className={wordDoc ? classes.canvasWordDoc : ''}
                        xs={documentType ? 8 : 12}
                      >
                        <Grid item className={`${formikProps.values.document ? classes.previewContainerActive : classes.previewContainer}`}>
                          <Grid
                            item
                            container
                            justify="center"
                            alignItems="center"
                            className={formikProps.values.document ? classes.hideDropZoneContainer : classes.dropZoneContainer}
                          >
                            <Grid item style={{ height: '100%', width: '100%' }}>
                              <FileDropZone
                                setFormikFieldValue={formikProps.setFieldValue}
                                setError={setError}
                                handlePreview={handlePreview}
                                file={formikProps.values.document}
                              />
                            </Grid>
                          </Grid>
                          <ScrollWindow
                            style={{
                              ...(wordDoc ? { display: 'none' } : null),
                            }}
                          >
                            <canvas id="preview-canvas" ref={canvasRef} className={classes.canvas} />
                          </ScrollWindow>
                          {
                          wordDoc && (
                            <ScrollWindow>
                              <iframe
                                title=".docx preview"
                                srcDoc={wordDoc}
                                style={{
                                  width: '100%', background: colors.white, border: 'none',
                                }}
                                onLoad={(e) => resizeIframe(e)}
                              />
                            </ScrollWindow>
                          )
}
                        </Grid>
                      </Grid>

                    </Grid>
                  </Grid>
                  <Grid item container className={classes.submitButtonsContainer} spacing={2}>
                    {formikProps.isSubmitting ? (
                      <Grid container item justify="center" style={{ height: '100%' }}>
                        <CircularProgress
                          color="secondary"
                          size={(uploadDocumentStyles.submitButtonsContainer.height - uploadDocumentStyles.submitButtonsContainer.paddingTop) / 1.414}
                        />
                      </Grid>
                    )
                      : (
                        <>
                          <Grid item>
                            <NewOrderNextButtonClear disabled={formikProps.isSubmitting} onClick={handleClose}>Cancel</NewOrderNextButtonClear>
                          </Grid>
                          <Grid item>
                            <NewOrderNextButton
                              disabled={!formikProps.isValid || formikProps.isSubmitting}
                              onClick={() => {
                                if (task) {
                                  setSubmitTask(true);
                                } else {
                                  formikProps.submitForm();
                                }
                              }}
                            >
                              Upload
                            </NewOrderNextButton>
                          </Grid>
                          <ConfirmationModal
                            onProceed={() => {
                              setSubmitTask(null);
                              formikProps.submitForm();
                            }}
                            onCancel={() => setSubmitTask(null)}
                            message="Please confirm your submission. Once you submit, this action will be returned to the initiator and you will no longer be able to make changes."
                            open={!!submitTask}
                          />
                        </>
                      )}
                  </Grid>
                </Grid>

              </Grid>
            </ScrollWindow>
          )
        }
        </Formik>
      </Dialog>

      <ErrorDialog
        onClose={() => setError(null)}
        open={!!error}
        errorContent={error}
      />
    </>
  );
}

UploadDocument.propTypes = {
  uploadObject: PropTypes.objectOf(PropTypes.any).isRequired,
  open: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.object]),
  onClose: PropTypes.func,
  shipmentId: PropTypes.string.isRequired,
};

UploadDocument.defaultProps = {
  open: false,
  onClose: null,
};

function resizeIframe(e) {
  try {
    e.target.style.height = `${e.target.contentWindow.document.documentElement.scrollHeight}px`;
  } catch (e) {
    //
  }
}
