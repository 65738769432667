import React from 'react';
import { css } from 'emotion';
import AdminLoginForm from '../components/AdminLoginForm';
import EsgVideo from '../assets/login-video-v.mp4';

export default function Login() {
  const classes = {
    video: css`
    position: fixed;
    min-width: 100%;
    min-height: 100%;
    `,
    loginFormContainer: css`
      padding-top: 320px;
      padding-left: 100px;
      width: 620px;
    `,
  };
  return (
    <div className={classes.home}>
      <div className={classes.videoContainer}>
        <video className={classes.video} autoPlay loop muted>
          <source src={EsgVideo} type="video/mp4" />
        </video>
      </div>
      <div className={classes.loginFormContainer}>
        <AdminLoginForm />
      </div>

    </div>
  );
}
