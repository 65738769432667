import React, {
  forwardRef,
  memo, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { Button, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SignatureCanvas from 'react-signature-canvas';
import * as colors from '../../styles/colors';
import SignImage from '../images/16px.svg';

const useStyles = makeStyles(() => ({
  signatureContainer: {
    height: 130,
    background: 'rgba(255, 255, 255, .1)',
    padding: '10px 0',
    position: 'relative',
    borderBottom: `2px solid ${colors.primaryBlue}`,
    borderRadius: '5px 5px 0 0',
  },
  signatureContainerInactive: {
    borderBottom: 'none',
  },
  signatureCanvas: {
    height: '100%',
    width: '100%',
  },
  signatureCanvasActive: {
    cursor: `url(${SignImage}) 4 20, auto`,
  },
  signatureCanvasInactive: {
    '&:hover': {
      cursor: 'default',
    },
  },
  signatureSubtitleContainer: {
    padding: '5px 0px 0px 20px',
  },
  signatureSubtitle: {
    color: colors.linkBlue,
    fontWeight: 700,
    fontSize: 12,
  },
  clearButton: {
    position: 'absolute',
    right: '5px',
    bottom: '5px',
  },
  clearButtonText: {
    fontSize: 14,
    fontWeight: 500,
    color: colors.linkBlue,
  },
}));

const SignDocumentCanvas = forwardRef(({
  userSignature,
  showSignatureSubtitle,
  readOnly,
  onSignatureEnd,
  noShow,
}, ref) => {
  const classes = useStyles();
  const [signatureHasContent, setSignatureHasContent] = useState(false);

  useEffect(() => {
    if (!isEmpty(userSignature)) {
      ref.current.fromDataURL(userSignature);
    }
  }, [userSignature]);

  useEffect(() => {
    if (readOnly) ref.current.off();
    else ref.current.on();
  }, [readOnly]);

  const getCurrentSignature = () => (ref.current
    ? ref.current.getTrimmedCanvas().toDataURL('image/png')
    : ''
  );

  const handleSignatureEnd = () => {
    if (!ref.current.isEmpty()) {
      const currentSignature = getCurrentSignature();

      if (onSignatureEnd) onSignatureEnd(currentSignature);
    }
  };

  return (
    <>
      <Grid
        className={`${classes.signatureContainer} ${readOnly
          ? classes.signatureContainerInactive
          : ''}`}
        item
        container
        style={(noShow ? { visibility: 'hidden', height: '1px', width: '150px' } : { visibility: 'visible' })}
      >
        <SignatureCanvas
          ref={ref}
          canvasProps={{
            className: `${classes.signatureCanvas} ${!readOnly ? classes.signatureCanvasActive : classes.signatureCanvasInactive}`,
            color: colors.white,
          }}
          penColor="white"
          onBegin={() => {
            if (ref.current.isEmpty()) {
              setSignatureHasContent(true);
            }
          }}
          onEnd={handleSignatureEnd}
          clearOnResize={false}
        />
        {signatureHasContent && (
        <Button
          className={classes.clearButton}
          onClick={() => {
            if (ref) ref.current.clear();

            setSignatureHasContent(false);
          }}
          disabled={readOnly}
        >
          <Typography className={classes.clearButtonText}>
            Clear
          </Typography>
        </Button>
        )}
      </Grid>
      {showSignatureSubtitle && (
        <Grid item className={classes.signatureSubtitleContainer}>
          <Typography className={classes.signatureSubtitle}>
            Signature
          </Typography>
        </Grid>
      )}
    </>
  );
});

SignDocumentCanvas.propTypes = {
  userSignature: PropTypes.string,
  showSignatureSubtitle: PropTypes.bool,
  readOnly: PropTypes.bool,
  onSignatureEnd: PropTypes.func,
  noShow: PropTypes.bool,
};

SignDocumentCanvas.defaultProps = {
  userSignature: '',
  showSignatureSubtitle: true,
  readOnly: false,
  onSignatureEnd: null,
  noShow: false,
};

export default memo(SignDocumentCanvas);
