import moment from 'moment';
import * as Yup from 'yup';
import { omitBy, isEmpty } from 'lodash';

export const SELECT_FROM_CONTACTS_TAB_VALUE = 'one';
export const ENTER_EMAIL_TAB_VALUE = 'two';
export const TASK_ASSIGN_TABS = [
  { id: 0, value: 'one', label: 'Select from Contacts' },
  { id: 1, value: 'two', label: 'Enter Email' },
];

export function getMinDueDate(document, taskType) {
  return moment().format('YYYYMMDD');
}

export const formatExpiresAtDateTime = (dueDate) => moment(
  moment(dueDate)
    .format()
    .replace(/^(\d{4}-\d{2}-\d{2}T)(.*)(-\d{2}:\d{2})$/,
      (match, p1, p2, p3) => [p1, '23:59:59', p3]
        .join('')),
).utc()
  .format();

export function internalOnSubmitSFCTab(filter, selectedContact, contacts) {
  if (!filter) return null;

  const filteredContacts = contacts.filter((filteredC) => {
    const contactVals = Object.keys(filteredC).map((key) => {
      const value = filteredC[key];
      return typeof value === 'string' && value.toLowerCase().includes(filter.toLowerCase());
    });

    return (contactVals.includes(true) && filteredC.addressId === selectedContact);
  });

  if (filteredContacts.length !== 1) return null;

  return filteredContacts[0].email;
}

export function getAssignDocSchema(selectedTab) {
  const schemaShape = {};

  if (selectedTab === SELECT_FROM_CONTACTS_TAB_VALUE) {
    schemaShape.selectedContact = Yup.string().required('Required');
  } else if (selectedTab === ENTER_EMAIL_TAB_VALUE) {
    schemaShape.email = Yup.string().email('Invalid email address').required('Required');
  }

  schemaShape.documentTaskType = Yup.string().required('Required');
  return Yup.object().shape(schemaShape);
}

export function sanitizeAssignmentObject(assignmentObject) {
  return omitBy(assignmentObject, (val) => isEmpty(val) && typeof val !== 'boolean');
}
