import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'emotion';
import * as colors from '../../styles/colors';
import statusHelp from '../../utils/status';

export default function StatusChip(props) {
  const { status, type } = props;
  const statusMap = {
    task: {
      Created: 'Not yet accepted',
      'In-Progress': 'In-progress',
      default: '',
    },
    shipment: {
      Created: 'Created',
      'In-Progress': 'In-progress',
      default: '',
    },
    default: {
      default: '',
    },
  };

  const getStatusColor = (type, status) => {
    const statusColorMap = {
      task: {
        Created: colors.secondaryButton,
        'In-Progress': colors.yellow,
      },
      shipment: {
        created: colors.yellow,
        cancelled: colors.errorRed,
        complianceFailure: colors.errorRed,
      },
      default: { default: 'transparent' },
    };

    if (type === 'shipment') {
      if (statusHelp.isComplianceFailure(status)) return colors.errorRed;
      if (statusHelp.isCancelled(status)) return colors.errorRed;
      if (statusHelp.isPickupCancelled(status)) return colors.yellow;
      if (statusHelp.isCompleted(status)) return colors.green;
      return colors.yellow;
    }
    return statusColorMap[type || 'default'][status || 'default'];
  };
  const classes = {
    assignedStatus: css`
      color: black;
      padding: 6px;
      border-radius: calc(.5em + 6px);
      background: ${getStatusColor(type, status)};
      font-weight: 400;
      text-transform: capitalize;
      height: calc(1em + 12px);
      line-height: 1em;
      white-space: nowrap;
    `,
  };

  return (
    <span className={classes.assignedStatus}>
      {statusMap[type || 'default'][status || 'default'] || status}
    </span>
  );
}

StatusChip.propTypes = {
  status: PropTypes.string,
  type: PropTypes.string,
};

StatusChip.defaultProps = {
  status: '',
  type: '',
};
