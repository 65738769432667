import { makeStyles } from '@material-ui/core/styles';
import * as colors from '../../styles/colors';

const itemProductDetailsFormStyles = {
  panelContainer: {
    background: colors.newOrderFormBackground,
  },
  innerFormContainer: {
    padding: '0 15px 15px 15px',
    margin: '10px 0',
    background: colors.darkBlueBackground,
  },
  formFieldHeader: {
    fontWeight: 500,
    fontSize: '14px',
  },
  descriptionField: {
    backgroundColor: colors.inputFieldBackground,
    color: colors.white,
    border: 'none',
    maxWidth: '100%',
    transition: 'background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
    '&:focus': {
      outline: 'none !important',
      borderBottom: '#2A79D4 solid 2px !important',
      backgroundColor: 'rgba(0, 0, 0, 0.13)',
    },
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.13)',
    },
  },
  buttonContainer: {
    margin: '10px 0',
  },
  emptyImageContainerDisabled: {
    backgroundColor: '#181A25',
    border: '1px dashed #5A5D64',
    height: '100px',
    width: '100px',
    marginRight: '8px',
  },
  emptyImageContainerEnabled: {
    backgroundColor: colors.newOrderFormBackground,
    border: '1px dashed #5A5D64',
    height: '100px',
    width: '100px',
    marginRight: '8px',
  },
  emptyImageTextDisabled: {
    padding: '0 4px',
    color: '#5A5D64',
    fontWeight: '500',
    userSelect: 'none',
  },
  emptyImageTextEnabled: {
    padding: '0 4px',
    color: colors.white,
    fontWeight: '500',
    userSelect: 'none',
  },
  iamge: {
    width: '80px',
    paddingRight: '8px',

  },
  productImageContainer: {
    marginBottom: '2px',
  },
};

const baseFieldTextStyles = {
  color: colors.textLightGrey,
  fontSize: '14px',
  fontWeight: 500,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

const displayItemsStyles = {
  itemHeaderText: {
    fontWeight: 500,
    fontSize: '16px',
  },
  deleteIcon: {
    fill: 'white',
  },
  editIcon: {
    fontSize: 'medium',
  },
  fieldText: {
    ...baseFieldTextStyles,
    whiteSpace: 'nowrap',
  },
  fieldLongText: {
    ...baseFieldTextStyles,
    whiteSpace: 'pre-wrap',
  },
};

export const useDisplayItemsStyles = makeStyles(displayItemsStyles);

export const useItemProductDetailsFormStyles = makeStyles(itemProductDetailsFormStyles);
