import React from 'react';
import { Typography, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const styles = {
  labelText: {
    color: 'rgba(255,255,255,0.6)',
    fontSize: 16,
    fontWeight: 500,
  },
  linkText: {
    color: '#6AB4F2',
    textDecoration: 'none',
  },
};

const useStyles = makeStyles(styles);

export default function CheckboxLabelWithLink(props) {
  const classes = useStyles();
  const { labelText, linkText, linkTo } = props;
  return (
    <Typography className={classes.labelText}>
      {`${labelText} `}
      <Link href={linkTo} target="_blank" className={classes.linkText}>{linkText}</Link>
    </Typography>
  );
}
