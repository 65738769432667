import React from 'react';
import { Field } from 'formik';
import {
  FormControlLabel, Grid, Radio, Typography,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import { makeStyles } from '@material-ui/core/styles';
import { useMiscState, getCountryDescription } from '../../context/miscDataContext';
import * as colors from '../../styles/colors';

/**
 * ##################################
 *          GLOBAL VARIABLES
 * ##################################
 */

const styles = {
  container: {
    flexDirection: 'column',
    '&>div:last-child': {
      paddingLeft: 30,
    },
    background: colors.darkBlueBackground,
    padding: '10px 20px',
  },
  labelContainer: {

    height: 40,
  },
  radio: {
    '&$radioDisabled': {
      color: colors.textDarkGrey,
    },
    color: colors.white,
  },
  radioDisabled: {},
  radioLabel: {
    '&>span:last-child': {
      fontSize: 16,
      fontWeight: 425,
      color: colors.white,
    },
    color: colors.white,
    marginRight: 10,
  },
  editIcon: {
    height: 22,
  },
  addressBlock: {
    color: colors.textLightGrey,
    minHeight: 23,
    fontSize: 14,
  },
};
const useStyles = makeStyles(styles);

/**
 * ##################################
 *          CUSTOM COMPONENT
 * ##################################
 */

function CustomRadioField(props) {
  const {
    id, classes, isSelected, ...others
  } = props;

  return (
    <FormControlLabel
      className={classes.radioLabel}
      // for="selected-contact"
      control={(
        <Radio
          classes={{
            root: classes.radio,
            disabled: classes.radioDisabled,
          }}
          id={id}
          color="primary"
          checked={isSelected}
        />
              )}
      {...others}
    />
  );
}

/**
 * #################################
 *          EXPORT FUNCTION
 * #################################
 */


export function NewOrderAddressListItemByEmail(props) {
  const miscState = useMiscState();
  const classes = useStyles();

  const {
    fieldName, contact, isSelected, onClick, disabled = false, allowEdit = true,
  } = props;
  const {
    addressId, name, email, phone, addressLine1, addressLine2, addressLine3, city, state, zip, country,
  } = contact;

  return (
    <Grid container className={classes.container}>
      <Grid item container className={classes.labelContainer}>
        <Grid item>
          <Field
            component={CustomRadioField}
            type="radio"
            id={fieldName}
            label={name}
            isSelected={isSelected}
            classes={classes}
            value={email}
            disabled={disabled}
          />
        </Grid>
        {isSelected && allowEdit ? (
          <Grid item>
            <IconButton onClick={onClick} disabled={disabled}>
              <EditIcon className={classes.editIcon} color="primary" />
            </IconButton>
          </Grid>
        ) : null}
      </Grid>
      <Grid item>
        {addressLine1
          && (
          <Typography className={classes.addressBlock}>
            {`${addressLine1}${addressLine2 ? `, ${addressLine2}` : ''}${addressLine3 ? `, ${addressLine3}` : ''
            }`}
          </Typography>
          )}
        {city
          && (
          <Typography className={classes.addressBlock}>
            {`${city}, ${state} ${zip} ${getCountryDescription(
              miscState.countries,
              country,
            )}`}
          </Typography>
          )}
        <Typography className={classes.addressBlock}>{email}</Typography>
        {phone ? (
          <Typography className={classes.addressBlock}>{phone}</Typography>
        ) : null}
      </Grid>
    </Grid>
  );
}


export default function NewOrderAddressListItem(props) {
  const miscState = useMiscState();
  const classes = useStyles();

  const {
    fieldName, contact, isSelected, onClick, disabled = false, allowEdit = true,
  } = props;
  const {
    addressId, name, email, phone, addressLine1, addressLine2, addressLine3, city, state, zip, country,
  } = contact;

  return (
    <Grid container className={classes.container}>
      <Grid item container className={classes.labelContainer}>
        <Grid item>
          <Field
            component={CustomRadioField}
            type="radio"
            id={fieldName}
            label={name}
            isSelected={isSelected}
            classes={classes}
            value={addressId}
            disabled={disabled}
          />
        </Grid>
        {isSelected && allowEdit ? (
          <Grid item>
            <IconButton onClick={onClick} disabled={disabled}>
              <EditIcon className={classes.editIcon} color="primary" />
            </IconButton>
          </Grid>
        ) : null}
      </Grid>
      <Grid item>
        {addressLine1
          && (
          <Typography className={classes.addressBlock}>
            {`${addressLine1}${addressLine2 ? `, ${addressLine2}` : ''}${addressLine3 ? `, ${addressLine3}` : ''
            }`}
          </Typography>
          )}
        {city
          && (
          <Typography className={classes.addressBlock}>
            {`${city}, ${state} ${zip} ${getCountryDescription(
              miscState.countries,
              country,
            )}`}
          </Typography>
          )}
        {email && <Typography className={classes.addressBlock}>{email}</Typography>}
        {phone ? (
          <Typography className={classes.addressBlock}>{phone}</Typography>
        ) : null}
      </Grid>
    </Grid>
  );
}
