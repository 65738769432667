import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, DialogActions, DialogContent, DialogContentText,
  Grid, Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import NewOrderNextButton from './NewOrderNextButton';
import { SESSION_EXPIRED } from '../../clientConstants';
import * as colors from '../../styles/colors';

/**
 * #########################
 *          UTIL
 * #########################
 */

function buildErrorMessage(errorContent) {
  if (!errorContent) return '';

  let error = null;
  try { error = JSON.parse(errorContent).error; } catch (e) { error = `Unexpected error: ${errorContent}`; }

  // Remove the "Unexpected Error: " prefix from error messages related to authentication
  if (errorContent === SESSION_EXPIRED) {
    error = 'Session expired. Please log back in.';
  }

  if (errorContent) {
    return (typeof error === 'string')
      ? (
        <Grid item>
          <Typography color="error">{error}</Typography>
        </Grid>
      )
      : error.map((errorText) => (
        <Grid item>
          <Typography color="error">{`- ${errorText}`}</Typography>
        </Grid>
      ));
  }
}

/**
 * #################################
 *          EXPORT FUNCTION
 * #################################
 */

const styles = {
  contentContainer: {
    marginBottom: 'initial',
  },
  actionsContainer: {
    paddingBottom: 24,
  },
  root: {
    backgroundColor: colors.mediumBlueBackground,
    overflowY: 'initial',
    borderRadius: 10,
  },
  loadingContainer: {
    display: 'flex',
    paddingTop: 18,
  },
};

const useStyles = makeStyles(styles);

export default function NewOrderErrorDialog(props) {
  const { open, errorContent } = props;
  const { onClose } = props;

  const classes = useStyles();
  const content = buildErrorMessage(errorContent);

  const {
    contentContainer,
    actionsContainer,
    root,
  } = classes;

  const dialogContent = (
    <DialogContent>
      <DialogContentText
        id="alert-error-dialog"
        component="div"
        classes={{ root: contentContainer }}
      >
        <Grid item container spacing={1} direction="column">
          <Grid item>
            <Typography color="primary">
              There was an issue processing your request:
            </Typography>
          </Grid>
          <Grid item container>{content}</Grid>
        </Grid>
      </DialogContentText>
    </DialogContent>
  );

  const dialogActions = (
    <DialogActions classes={{ root: actionsContainer }}>
      <Grid
        container
        justify="center"
      >
        <Grid item>
          <NewOrderNextButton onClick={onClose}>Close</NewOrderNextButton>
        </Grid>
      </Grid>
    </DialogActions>
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{ paper: root }}
    >
      {dialogContent}
      {dialogActions}
    </Dialog>
  );
}

NewOrderErrorDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  errorContent: PropTypes.oneOfType([PropTypes.shape({
    error: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
    ]),
  }), PropTypes.string]),
  onClose: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    dialogRoot: PropTypes.string,
    dialogContentContainer: PropTypes.string,
    dialogActionsContainer: PropTypes.string,
  }),
};

NewOrderErrorDialog.defaultProps = {
  errorContent: null,
  classes: { dialogRoot: null, dialogContentContainer: null, dialogActionsContainer: null },
};
