import React from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton, Typography } from '@material-ui/core';
import LinkIcon from '@material-ui/icons/OpenInNew';
import { Field } from 'formik';
import NewOrderCustomTextField from '../../common/NewOrderCustomTextField';
import dryIceData from '../../../assets/docs/dryice.json';
import { FORM_LABELS, FORM_FIELDS_HAZMAT } from '../itemDetailsConstants';
import { useItemProductDetailsFormStyles } from '../itemDetailsStyles';

export default function PackagingInstructions({ isOtherClassType, openDocumentPreview }) {
  const classes = useItemProductDetailsFormStyles();

  return (
    <Grid item container direction="column" spacing={1}>
      <Grid item container direction="row" alignItems="center">
        <Typography color="primary" className={classes.formFieldHeader}>
          {FORM_LABELS.packagingInstructions}
        </Typography>
        {/* <IconButton
          color="secondary"
          onClick={() => openDocumentPreview(dryIceData.document)}
        >
          <LinkIcon />
        </IconButton> */}
      </Grid>
      <Grid item container spacing={1}>
        <Grid item xs={4}>
          <Field
            name={FORM_FIELDS_HAZMAT.packagingNumber.key}
            label={FORM_FIELDS_HAZMAT.packagingNumber.label}
            component={NewOrderCustomTextField}
            type="text"
            style={{ width: '100%' }}
            disabled={!isOtherClassType}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            name={FORM_FIELDS_HAZMAT.packagingGroup.key}
            label={FORM_FIELDS_HAZMAT.packagingGroup.label}
            component={NewOrderCustomTextField}
            type="text"
            style={{ width: '100%' }}
            disabled={!isOtherClassType}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

PackagingInstructions.propTypes = {
  isOtherClassType: PropTypes.bool.isRequired,
  openDocumentPreview: PropTypes.func.isRequired,
};
