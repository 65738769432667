import styled, { createGlobalStyle } from 'styled-components';
import { Link } from 'react-router-dom';
import * as colors from '../../styles/colors';

export const GlobalStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
    font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu",
      "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
    background-color: ${colors.background};
    color-scheme: dark;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  body {
    height: 100%;
  }

  body, h1, h2, h3, h4, h5, h6, p, ol, ul {
    margin: 0;
    padding: 0;
    font-weight: normal;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  button, input, select, textarea {
    background: transparent;
    font: inherit;
  }

  #root {
    height: 100%;
  }

  .MuiFormControlLabel-label.Mui-disabled {
    color: #8e9095!important;
  }
  input: -webkit-autofill {
    -webkit-box-shadow: 0 0 0 100px transparent inset !important;
`;

export const Page = styled.main`
  position: relative;
  padding-top: 89px;
  width: ${(props) => (props.account !== 'worldemblem' ? '80vw' : '95vw')};
  min-width: 1024px;
  min-height: calc(100% - 64px);
`;

export const Title = styled.h1`
  color: #FFFFFF;
  font-weight: 900;
  display: flex;
  align-items: center;
  font-size: 2.5em;
`;

export const SubTitle = styled.h3`
  color: rgba(255,255,255,0.6);
  font-weight: 500;
  display: flex;
  align-items: center;
  font-size: 16px;
`;

export const NavLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 16px;
  font-size: 1rem;
  text-decoration: none;
`;

export const Icon = styled.img`
  height: 24px;
`;
