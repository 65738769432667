import React from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircleOutlined';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import * as colors from '../../styles/colors';

const styles = {
  validIconBlue: {
    color: colors.primaryBlue,
  },
  validIconGreen: {
    color: colors.checkValidGreen,
  },
  invalidIcon: {
    color: '#979797',
  },
  root: {
    marginRight: 5,
    marginTop: 5,
  },
};

const useStyles = makeStyles(styles);

export default function StyledCheckCircleIcon(props) {
  const {
    isValidated, addMargin, isGreenWhenValid, classes,
  } = props;

  const internalClasses = useStyles();
  const appliedClasses = (!classes)
    ? {
      colorPrimary: internalClasses.invalidIcon,
      colorSecondary: isGreenWhenValid
        ? internalClasses.validIconGreen
        : internalClasses.validIconBlue,
      root: addMargin ? internalClasses.root : '',
    }
    : { root: classes.styledCheckCircle };

  return (
    <CheckCircleIcon
      color={isValidated ? 'secondary' : 'primary'}
      classes={appliedClasses}
    />
  );
}

StyledCheckCircleIcon.propTypes = {
  isValidated: PropTypes.bool.isRequired,
  addMargin: PropTypes.bool,
  isGreenWhenValid: PropTypes.bool,
  classes: PropTypes.objectOf(PropTypes.string),
};

StyledCheckCircleIcon.defaultProps = {
  addMargin: false,
  isGreenWhenValid: false,
  classes: null,
};
