import React, { memo, useEffect, useState } from 'react';
import map from 'lodash/map';
import { makeStyles } from '@material-ui/core/styles';
import {
  InputAdornment, TextField, Typography,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import PeopleAlt from '@material-ui/icons/PeopleAlt';
import SearchIcon from '@material-ui/icons/Search';
import Navbar from '../components/Navbar';
import { Page } from '../components/common';
import * as colors from '../styles/colors';
import UsersTable from '../components/UsersTable';
import UserEditModal from '../components/UserEditModal';
import { loadTrainings } from '../utils/accountClient';
import { DEFAULT_FETCH_OPTIONS, USER_PROFILE_FIELDS } from '../clientConstants';
import { loadUsers } from '../utils/userClient';
import { useUserState } from '../context/userContext';
import { useLoadTimeZonesUserTypes } from '../utils/hooks';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: colors.background,
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  page: {
    padding: '99px 20px 20px 20px',
    height: 'calc(100vh - 64px)',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
  },
  headerLeftContainer: {
    display: 'flex',
    width: 'auto',
    alignItems: 'center',
  },
  headerLeftContainerText: {
    width: 'auto',
    alignItems: 'center',
  },
  headerText: {
    color: colors.white,
    fontSize: 24,
    fontWeight: 500,
    marginLeft: '10px',
  },
  textField: {
    width: 370,
    background: colors.darkInputFieldBackground,
    marginLeft: '1.5rem',
  },
  labelRoot: {
    color: colors.white,
    fontSize: 16,
    fontWeight: 500,
    background: colors.darkInputFieldBackground,
  },
  inputRoot: {
    background: colors.darkInputFieldBackground,
  },
  endAdornmentRoot: {
    margin: 0,
    '&:not(.MuiInputAdornment-hiddenLabel)': {
      margin: '0px !important',
    },
  },
  tableContainer: {
    flexGrow: 2,
    padding: '20px 0',
    overflow: 'hidden',
    position: 'relative',
  },
}));

const ManageUsers = () => {
  const classes = useStyles();
  const loggedUser = useUserState();
  const [filter, setFilter] = useState('');
  const [training, setTraining] = useState(null);
  const [usersState, setUserState] = useState({
    items: [],
    params: {
      ...DEFAULT_FETCH_OPTIONS,
      filter,
    },
    loading: true,
  });
  const [modalState, setModalState] = useState({
    show: false,
    user: null,
  });
  const {
    timeZones,
    userTypes,
  } = useLoadTimeZonesUserTypes(loggedUser.userType, USER_PROFILE_FIELDS);

  useEffect(() => {
    loadTrainings().then((data) => {
      setTraining(data);
    }).catch(() => {
      console.log('There is a problem loading trainings.');
    });
  }, []);

  useEffect(() => {
    if (usersState.items.length > 0 && filter.length !== 0 && filter.length >= 2) {
      fetchUsers({ ...DEFAULT_FETCH_OPTIONS, offset: 0, filter });
    } else fetchUsers({ ...DEFAULT_FETCH_OPTIONS, offset: 0 });
  }, [filter]);

  const handleSearch = (e) => {
    setFilter(e.target.value);
  };

  const handleModal = (user) => {
    setModalState({ show: !modalState.show, user });
  };

  const handleUserUpdate = (newUser) => {
    setUserState(
      {
        ...usersState,
        items: usersState.items.map((item) => (item.userId === newUser.userId
          ? { ...item, user: newUser }
          : item
        )),
      },
    );
  };

  const fetchUsers = (options = DEFAULT_FETCH_OPTIONS) => {
    loadUsers(options).then((data) => {
      if (!data) return;

      setUserState({
        items: options.offset === 0 ? data : usersState.items.concat(data),
        params: {
          ...usersState.params,
          ...options,
          offset: options.offset,
        },
        loading: false,
      });
    }).catch(() => {
      console.log('There is a problem loading users');

      setUserState({
        items: usersState.items,
        loading: false,
      });
    });
  };

  return (
    <div className={classes.root}>
      <Navbar />
      <Page className={classes.page}>
        <Grid className={classes.headerContainer} container justify="space-between">
          <Grid className={classes.headerLeftContainer} item>
            <Grid className={classes.headerLeftContainerText} container item>
              <PeopleAlt color="primary" height="2em" />
              <Typography className={classes.headerText}>Users</Typography>
            </Grid>
            <Grid item>
              <TextField
                id="outlined-basic"
                className={classes.textField}
                classes={{ root: classes.textFieldRoot }}
                label="Search Users"
                margin="normal"
                onChange={handleSearch}
                InputProps={{
                  disableUnderline: true,
                  classes: {
                    root: classes.inputRoot,
                    focused: classes.inputRoot,
                  },
                  endAdornment: (
                    <InputAdornment
                      position="start"
                      classes={{
                        root: classes.endAdornmentRoot,
                        filled: classes.endAdornmentRoot,
                        positionStart: classes.endAdornmentRoot,
                      }}
                    >
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{ classes: { root: classes.labelRoot } }}
                variant="filled"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid className={classes.tableContainer} container item>
          <UsersTable
            users={usersState.items}
            params={usersState.params}
            training={training}
            isLoading={usersState.loading}
            onFetchUsers={fetchUsers}
            onShowModal={handleModal}
          />
        </Grid>
      </Page>
      <UserEditModal
        open={modalState.show}
        loggedUser={loggedUser}
        user={modalState.user}
        training={training}
        timeZones={timeZones}
        userTypes={userTypes}
        onUserUpdate={handleUserUpdate}
        onClose={handleModal}
      />
    </div>
  );
};

export default memo(ManageUsers);
