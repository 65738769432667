import React from 'react';
import * as binClient from '../utils/binClient';
import { useLogger } from '../utils/hooks';

const binState = { bins: [] };
const BinStateContext = React.createContext();
const BinDispatchContext = React.createContext();

// binRducer Action Types
const LOAD_BINS = 'LOAD BINS';
const LOAD_ALL_BINS = 'LOAD_ALL_BINS';
const LOAD_SCAN = 'LOAD_SCAN';

function binReducer(state, action) {
  const { bins, offset } = action;
  const newBins = state.bins.slice(0, offset).concat(bins);
  switch (action.type) {
    case LOAD_BINS: {
      return {
        ...state,
        bins: newBins,
      };
    }
    case LOAD_ALL_BINS: {
      return {
        ...state,
        userBins: bins,
      };
    }
    case LOAD_SCAN: {
      return {
        ...state,
        scannedData: bins,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function BinDataProvider(props) {
  const [state, dispatch] = useLogger(
    React.useReducer(binReducer, binState || { bins: null, userBins: null, scannedData: null }),
  );

  return (
    <BinStateContext.Provider value={state} {...props}>
      <BinDispatchContext.Provider
        value={dispatch}
        {...props}
      />
    </BinStateContext.Provider>
  );
}

// TODO: update the binClient.loadBins function
// to handle these additional parameters (they will go in the request body)
function loadBins(dispatch, {
  filter, offset, limit, status, startDate, endDate, purpose,
}) {
  return binClient.loadBins({
    filter, offset, limit, status, startDate, endDate, purpose,
  }).then((data) => {
    dispatch({ type: LOAD_BINS, bins: data, offset });
    return data;
  });
}

function loadAllBins(dispatch) {
  return binClient.loadAllBins().then((data) => {
    dispatch({ type: LOAD_ALL_BINS, bins: data, offset: 0 });
    return data;
  });
}
function loadRecentBins(dispatch, filters = {}, offset = 0, limit = 5) {
  return binClient.loadBins({
    offset, limit, filters,
  }).then((data) => {
    dispatch({ type: LOAD_BINS, bins: data, offset });
    return data;
  });
}
function loadScan(dispatch, input = []) {
  return binClient.scanBinOrPackingSlip({ input }).then((data) => {
    dispatch({ type: LOAD_SCAN, bins: data });
    return data;
  }).catch((e) => {
    console.log(e);
    return e.message;
  });
}
function updateBinStatus(dispatch, bin = []) {
  return binClient.updateBinStatus(bin).then((data) => data);
}
function closeBins(dispatch, bin = []) {
  return binClient.closeBins(bin).then((data) => data);
}
function resetBins(dispatch, bin = []) {
  return binClient.resetBins(bin).then((data) => data);
}
function updateBinPriorityBulk(dispatch, bins = []) {
  return binClient.updateBinPriority(bins).then((data) => data);
}

function useBinState() {
  const context = React.useContext(BinStateContext);
  if (context === undefined) {
    throw new Error(
      'useBinState must be used within a BinDataProvider',
    );
  }
  return context;
}

function useBinDispatch() {
  const context = React.useContext(BinDispatchContext);
  if (context === undefined) {
    throw new Error(
      'useBinDispatch must be used within a BinDataProvider',
    );
  }
  return context;
}

export {
  BinDataProvider,
  useBinState,
  useBinDispatch,
  loadBins,
  loadAllBins,
  loadRecentBins,
  updateBinStatus,
  updateBinPriorityBulk,
  loadScan,
  closeBins,
  resetBins,
};
