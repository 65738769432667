import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CircularProgress,
  Typography,
  Grid,
  IconButton,
} from '@material-ui/core';
import ReorderIcon from '@material-ui/icons/Reorder';
import EditIcon from '@material-ui/icons/Edit';
import { withStyles } from '@material-ui/core/styles';
import { css } from 'emotion';
import * as colors from '../../styles/colors';
import {
  useBinState,
  useBinDispatch,
  loadAllBins,
  updateBinPriorityBulk,
} from '../../context/binDataContext';
import NewOrderNextButtonClear from './NewOrderNextButtonClear';
import NewOrderNextButton from './NewOrderNextButton';

function BinStatusIcon(props) {
  const { status, colorClass } = props;
  switch (status === undefined ? '' : status.toUpperCase()) {
    case 'OPEN':
      return <div className={colorClass} style={{ background: 'blue' }} />;
    case 'IN-USE':
      return <div className={colorClass} style={{ background: 'yellow' }} />;
    case 'READY':
      return (
        <div className={colorClass} style={{ background: 'lightgreen' }} />
      );
    default:
      return <div className={colorClass} />;
  }
}
BinStatusIcon.propTypes = {
  status: PropTypes.string.isRequired,
  colorClass: PropTypes.string,
};
BinStatusIcon.defaultProps = {
  colorClass: '',
};
/*
Note: this is a working example, but more can be done to improve it.

In particular, on drag, the table cells in the dragged row may collapse and shrink the overall row.

If you wish to preserve their size mid-drag, you can create a custom component that wraps
the material TableCell and saves the pre-drag dimensions (e.g. in a ref or in state).
The component can be passed an 'isDragging' prop (via snapshot.isDragging) and can conditionally
apply pre-drag width/height via styles.

Pre-drag dimensions can be obtained via the new-ish ResizeObserver API. If you are using class
components, the getSnapshotBeforeUpdate() lifecycle method can work with getBoundingClientRect(),
*/

// eslint-disable-next-line import/prefer-default-export
const BinTable = ({
  items, isReadOnly, setIsReadOnly, updateBins,
}) => {
  const classes = {
    header: css`
      color: ${colors.white};
      font-size: 20px;
      font-weight: 500;
      letter-spacing: 0.25px;
    `,
    innerContainer: css`
      background-color: #212435;
      padding: 24px 24px 0px 24px;
      margin-bottom: 32px;
      width: 100%;
      border-radius: 8px;
    `,
    outerContainer: css`
      background-color: #313547;
      padding: 24px;
      width: 100%;
      margin-bottom: 24px;
    `,

    formItem: css`
      margin-bottom: 24px;
    `,
    selectField: css`
      width: 490px;
    `,
    loading: css`
      margin-bottom: 24px;
    `,
    tableRow: css`
      color: white;
    `,
    tableHead: css`
      color: rgba(255, 255, 255, 0.6);
    `,
    round: css`
   width: 1.2rem;
    height: 1.2rem;
    border-radius: 1rem;
    background-color: yellow,
    margin-top: 1.2rem,
   `,
  };
  // cache the items provided via props in state for purposes of this demo
  const [localItems, setLocalItems] = useState(items);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // normally one would commit/save any order changes via an api call here...
  const handleDragEnd = (result, provided) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    setLocalItems((prev) => {
      const temp = [...prev];
      const d = temp[result.destination.index];
      temp[result.destination.index] = temp[result.source.index];
      temp[result.source.index] = d;

      return temp;
    });
  };
  const handleSubmit = () => {
    setIsSubmitting(true);
    updateBins(localItems).then((data) => setIsSubmitting(false));
  };
  return (
    <Grid container spacing={4}>
      <Grid
        xs={12}
        item
        container
        justify="space-between"
        alignItems="center"
        spacing={2}
      >
        <Grid item>
          <Typography className={classes.header}>Bin Priority</Typography>
        </Grid>
        {isReadOnly && (
          <Grid item>
            <IconButton
              onClick={() => setIsReadOnly(false)}
              data-testid="profile-management-edit-button"
            >
              <EditIcon />
            </IconButton>
          </Grid>
        )}
      </Grid>
      <Grid item container direction="column" xs={12} spacing={3}>
        <Grid item style={{ maxHeight: '580px', overflow: 'auto' }}>
          <Grid item container className={classes.innerContainer}>
            <Table>
              <colgroup>
                <col style={{ width: '20%' }} />
                <col style={{ width: '20%' }} />
                <col style={{ width: '60%' }} />
              </colgroup>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableHead} align="left">
                    &nbsp;
                  </TableCell>
                  <TableCell className={classes.tableHead} align="left">
                    Bin #
                  </TableCell>
                  <TableCell className={classes.tableHead} align="left">
                    Status
                  </TableCell>
                </TableRow>
              </TableHead>
              <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="droppable" direction="vertical">
                  {(droppableProvided) => (
                    <TableBody
                      ref={droppableProvided.innerRef}
                      {...droppableProvided.droppableProps}
                    >
                      {localItems.map((item, index) => (
                        <Draggable
                          key={item.binId}
                          draggableId={item.binId}
                          isDragDisabled={isReadOnly}
                          index={index}
                        >
                          {(draggableProvided, snapshot) => (
                            <TableRow
                              className={classes.tableRow}
                              ref={draggableProvided.innerRef}
                              {...draggableProvided.draggableProps}
                              style={{
                                ...draggableProvided.draggableProps.style,
                                background: snapshot.isDragging
                                  ? 'rgba(0,0,0, 0.35)'
                                  : 'none',
                              }}
                            >
                              {/* note: `snapshot.isDragging` is useful to style or modify behaviour of dragged cells */}
                              <TableCell
                                className={classes.tableRow}
                                align="left"
                              >
                                <div {...draggableProvided.dragHandleProps}>
                                  <ReorderIcon
                                    style={{
                                      cursor: 'move',
                                      color: 'rgba(255,255,255,0.6)',
                                    }}
                                  />
                                </div>
                              </TableCell>
                              <TableCell
                                className={classes.tableRow}
                                align="left"
                              >
                                {item.binName}
                              </TableCell>
                              <TableCell
                                className={classes.tableRow}
                                align="left"
                              >
                                <Grid container justify="flex-start">
                                  <Grid xs={2}>
                                    <BinStatusIcon
                                      status={item.status}
                                      colorClass={classes.round}
                                    />
                                  </Grid>
                                  <Grid>
                                    {' '}
                                    {item.status}
                                  </Grid>
                                </Grid>
                              </TableCell>
                            </TableRow>
                          )}
                        </Draggable>
                      ))}
                      {droppableProvided.placeholder}
                    </TableBody>
                  )}
                </Droppable>
              </DragDropContext>
            </Table>
          </Grid>
        </Grid>
        {!isReadOnly && (
          <Grid spacing={4} container justify="center">
            <Grid item>
              <NewOrderNextButtonClear
                onClick={() => setIsReadOnly(true)}
                disabled={isSubmitting}
              >
                Cancel
              </NewOrderNextButtonClear>
            </Grid>
            <Grid item>
              <NewOrderNextButton
                onClick={handleSubmit}
                disabled={isSubmitting}
              >
                Submit
              </NewOrderNextButton>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

BinTable.propTypes = {
  items: PropTypes.func.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  setIsReadOnly: PropTypes.func.isRequired,
  updateBins: PropTypes.func.isRequired,
};

export default function BinPriority(props) {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const binDispatch = useBinDispatch();
  const binState = useBinState();
  const { isReadOnly, setIsReadOnly } = props;

  React.useEffect(() => {
    refreshBins().catch(() => setLoading(false));
  }, []);
  const loadBins = () => refreshBins();

  const refreshBins = () => {
    setLoading(true);
    return loadAllBins(binDispatch).then((data) => {
      setLoading(false);
      setIsReadOnly(true);
    });
  };
  const updateBins = (bins) => {
    const payload = { bins };
    updateBinPriorityBulk(binDispatch, payload).then((data) => {
      refreshBins();
    });
  };

  return (
    <>
      {Array.isArray(binState.userBins || loading) ? (
        <BinTable
          items={binState.userBins}
          updateBins={updateBins}
          isReadOnly={isReadOnly}
          setIsReadOnly={setIsReadOnly}
        />
      ) : (
        <CircularProgress />
      )}
    </>
  );
}

BinPriority.propTypes = {
  isReadOnly: PropTypes.bool.isRequired,
  setIsReadOnly: PropTypes.func.isRequired,
};
