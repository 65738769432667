import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as colors from '../../../styles/colors';

const useStyles = makeStyles(() => ({
  buttonStyle: {
    fontWeight: 'bold',
    margin: '20px 0px 0px 0px',
    minWidth: '98px',
    color: colors.secondaryButton,
    borderRadius: '8px',
    backgroundColor: colors.shipmentCardBackground,
    border: `1px solid ${colors.buttonBackgroundBlue}`,
    boxShadow: 'none',
    ':hover': {
      background: colors.mediumBlueBackground,
    },
  },
}));

function ConfigureIotButton({ initialState, form, field }) {
  const classes = useStyles();
  const { name } = field;
  const { setFieldValue } = form;

  return (
    <Button
      className={classes.buttonStyle}
      variant="contained"
      onClick={() => {
        setFieldValue(name, initialState);
      }}
    >
      Configure IoT Device
    </Button>
  );
}

ConfigureIotButton.propTypes = {
  initialState: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  field: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default memo(ConfigureIotButton);
