import React from 'react';
import PropTypes from 'prop-types';
import NewOrderShipmentModule from '../common/NewOrderShipmentModule';
import ProductPaneTable from '../ProductPaneTable';
import BlockedPanelDisplay from '../common/PanelWarning';

export default function ItemProductDetailsPane({
  shipment,
  isReadyToBeCompleted,
  isComplete,
  isComplianceFailure,
  blockingMessage,
  ...shipmentProps
}) {
  const labelDetails = (isComplete && shipment) ? `${(shipment?.product?.details?.items || []).length} product(s)` : '';

  return (
    <NewOrderShipmentModule
      labelDetails={labelDetails}
      isComplete={isComplete}
      isReadyToBeCompleted={isReadyToBeCompleted}
      isComplianceFailure={isComplianceFailure}
      shipment={shipment}
      blockingMessage={blockingMessage}
      {...shipmentProps}
    >
      {(isReadyToBeCompleted) ? ((renderProps) => (
        <ProductPaneTable
          rows={shipment?.product?.details?.items}
          handleDeleteSalesOrder={() => {}}
          isReadyToBeCompleted={isReadyToBeCompleted}
          {...renderProps}
          isComplete={isComplete}
        />
      )) : (
        <BlockedPanelDisplay message={blockingMessage} />
      )}
    </NewOrderShipmentModule>
  );
}

ItemProductDetailsPane.propTypes = {
  label: PropTypes.string.isRequired,
  parentFormName: PropTypes.string.isRequired,
  formName: PropTypes.string.isRequired,
  selectedForms: PropTypes.objectOf(PropTypes.string).isRequired,
  shipmentId: PropTypes.string.isRequired,
  setSelectedForms: PropTypes.func.isRequired,
};
