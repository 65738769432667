import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import makeAsyncScriptLoader from 'react-async-script';
import PropTypes from 'prop-types';
import {
  CircularProgress, Grid, MenuItem,
} from '@material-ui/core';
import {
  set as setField, cloneDeep, get,
} from 'lodash';
import { useMiscState } from '../../context/miscDataContext';
import { getGoogleMapsApiKey } from '../../utils/apiKeyClient';
import {
  CustomText, CustomAutoComplete,
} from './InputComponents';
import { combineAddress } from '../../utils/helpers';
import { NEW_ORDER_BILLING_PAYMENT_STYLE } from '../../styles/style';
import { PhoneNumberFormik } from './PhoneNumber';
import CustomCountrySelectField from './CustomCountrySelectField';
import CustomStateSelectField from './CustomStateSelectField';

/**
 * ##################################
 *          GLOBAL VARIABLES
 * ##################################
 */

// NOT A COMMENT; REQUIRED
/* global google */

const blockNonAlphaCharacters = (val) => !!(val.match(/[^A-Za-z\s]/));
// const blockNonAlphaNumericCharacters = (val) => !!(val.match(/[^A-Za-z0-9\s]/));

const fieldComponents = {
  name: (props, name = 'name') => TextFieldComponent({
    label: (props.other.inputLabelHeader) ? `${props.other.inputLabelHeader} Name` : 'Name',
    ...props,
    name,
    type: 'text',
    other: {
      ...(props.other || {}),
      blockValue: blockNonAlphaCharacters,
    },
  }),
  companyName: (props, name = 'companyName') => TextFieldComponent({
    label: 'Company / Institution (optional)',
    ...props,
    name,
    type: 'text',
    other: {
      ...(props.other || {}),
      blockValue: blockNonAlphaCharacters,
    },
  }),
  email: (props, name = 'email') => TextFieldComponent({
    label: (props.other.inputLabelHeader) ? `${props.other.inputLabelHeader} Email Address` : 'Email Address',
    ...props,
    name,
    type: 'email',
  }),
  phone: (props = {}, name = 'phone', countryName = 'phoneCountry') => PhoneNumberFormik({
    label: `${(props.other.inputLabelHeader)
      ? `${props.other.inputLabelHeader} `
      : ''}Phone${(props.other.overRideOptional && props.other.overRideOptional.includes('phone'))
      ? ''
      : ' (optional)'}`,
    ...props,
    name,
    countryName,
    type: 'tel',
  }),
  map: (props) => LoadGoogleMapScript(props),
  country: (props, name = 'country') => CustomCountrySelectField({
    label: 'Country',
    name,
    ...props,
  }),
  ccCountry: (props, name = 'ccCountry') => CustomCountrySelectField({
    label: 'Country',
    ...props,
    name,
  }),
  addressLine1: (props, name = 'addressLine1') => LoadReactDependentScript({
    label: 'Street Address, P.O. Box, Company Name, c/o',
    ...props,
    name,
  }),
  addressLine2: (props, name = 'addressLine2') => TextFieldComponent({
    label: 'Apartment, Suite, Unit, Building, Floor',
    ...props,
    name,
    type: 'text',

  }),
  addressLine3: (props, name = 'addressLine3') => TextFieldComponent({
    label: 'Additional Address Information',
    ...props,
    name,
    type: 'text',
  }),
  city: (props, name = 'city') => TextFieldComponent({
    label: 'City',
    ...props,
    name,
    type: 'text',
  }),
  state: (props, name = 'state') => CustomStateSelectField({
    label: 'State, Province, Region',
    ...props,
    selectedCountry: get(props, 'other.country', ''),
    name,
  }),
  zip: (props, name = 'zip') => TextFieldComponent({
    label: 'Zip/Postal Code',
    ...props,
    name,
    type: 'text',
  }),
  ccZip: (props, name = 'ccZip') => TextFieldComponent({
    label: 'Zip',
    ...props,
    name,
    type: 'text',
  }),
  eoriNumber: (props, name = 'eoriNumber') => TextFieldComponent({
    label: 'EORI Number',
    ...props,
    name,
    type: 'text',
  }),
};

/**
 * ##################################
 *          CUSTOM COMPONENT
 * ##################################
 */

function TextFieldComponent(props) {
  const {
    other, name, label, type, ...rest
  } = props;
  const disabled = (other !== undefined
    && other.disabled !== undefined
    && other.disabled.includes(name));
  const { classes, transformValue, blockValue } = other;

  return (
    <Grid item key={`textField-${name}`} style={{ padding: '12px 0' }}>
      <CustomText
        {...rest}
        type={type}
        name={name}
        label={label}
        disabled={disabled}
        classes={(classes) || {}}
        transformValue={transformValue}
        blockValue={blockValue}
      />
    </Grid>
  );
}

TextFieldComponent.propTypes = {
  other: PropTypes.oneOfType([
    PropTypes.object.isRequired,
    PropTypes.string.isRequired,
    PropTypes.array,
    PropTypes.func,
  ]),
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

TextFieldComponent.defaultProps = {
  other: null,
};

function GoogleMap(props) {
  const { formikRef, pickupFrom, classes } = props;

  const [map, setMap] = useState(null);
  const [geocoder, setGeocoder] = useState(null);
  const [marker, setMarker] = useState(null);
  const [getMarker, setGetMarker] = useState(false);
  const mapRef = useRef();

  const { updateMap = false } = (formikRef.current) ? formikRef.current.state.values : {};
  const { address } = pickupFrom;

  useEffect(() => {
    if (map || !mapRef.current) return;

    try {
      setMap(new google.maps.Map(mapRef.current, { center: { lat: 48, lng: 8 }, zoom: 15 }));
      setGeocoder(new google.maps.Geocoder());
    } catch (e) { console.error(e); }
  }, [map, mapRef]);

  useEffect(() => {
    setGetMarker(true);
  }, [address.addressLine1, address.city, address.state, address.country]);

  useEffect(() => {
    if (!formikRef.current || !getMarker || !geocoder) return;

    const {
      addressLine1, city, state, country,
    } = formikRef.current.state.values;
    geocoder.geocode({ address: `${addressLine1}, ${city}, ${state}, ${country}` }, (results, status) => {
      if (status === 'OK') {
        map.setCenter(results[0].geometry.location);
        setGetMarker(false);

        if (marker) marker.setMap(null);

        setMarker(new google.maps.Marker({
          map,
          position: results[0].geometry.location,
        }));
      } else {
        alert(`Geocode was not successfull for the following reason: ${status}`);
      }
    });
  }, [formikRef, geocoder, getMarker, map, marker]);

  useEffect(() => {
    if (!formikRef.current || !updateMap) return;

    setGetMarker(true);

    formikRef.current.setValues({
      ...formikRef.current.state.values,
      updateMap: false,
    });
  }, [formikRef, updateMap]);

  return (
    <Grid
      item
      classes={(!!mapRef.current && mapRef.current.children.length > 0)
        ? { root: classes.baseContentMapContainer }
        : {}}
    >
      <div ref={mapRef} style={{ width: '100%', height: '100%' }} />
    </Grid>
  );
}

GoogleMap.propTypes = {
  formikRef: PropTypes.objectOf(PropTypes.object.isRequired).isRequired,
  pickupFrom: PropTypes.oneOfType([
    PropTypes.object.isRequired,
    PropTypes.string.isRequired,
  ]).isRequired,
  classes: PropTypes.shape({
    baseContentMapContainer: PropTypes.string.isRequired,
  }).isRequired,
};

function TextFieldAutoCompleteComponent(props) {
  const {
    name, label, parentProps, fieldNames, fieldSeparation, callback,
  } = props;

  const {
    formikRef, disabled,
  } = parentProps.other;
  const { values } = formikRef.current.state;
  const country = get(values, fieldNames.country || 'country') || null;
  const classes = NEW_ORDER_BILLING_PAYMENT_STYLE();
  const autocompleteInput = useRef(null);
  const [googleAutocomplete, setGoogleAutocomplete] = React.useState(null);

  const handleAutoComplete = useCallback((result) => {
    const addressComponents = result.getPlace().address_components;
    if (!addressComponents) return;
    function filterComponents(filter) {
      return addressComponents.filter((component) => {
        const type = component.types[0];

        return filter.includes(type);
      });
    }

    function getSingleComponent(key, subKey) {
      const filteredComponent = filterComponents([key]);
      return (filteredComponent.length === 1) ? filteredComponent[0][subKey] : '';
    }

    const addressLine1 = filterComponents(['street_number', 'route'])
      .map((component) => component.long_name)
      .join(' ');

    const city = getSingleComponent('locality', 'long_name')
      || getSingleComponent('postal_town', 'long_name')
      || getSingleComponent('sublocality_level_1', 'long_name');
    const state = getSingleComponent('administrative_area_level_1', 'short_name');

    const zip = getSingleComponent('postal_code', 'long_name');

    const { values } = formikRef.current.state;
    const newValues = {
      addressLine1,
      city,
      state,
      zip,
      ...(values.addressLine2) && { addressLine2: '' },
      ...(values.addressLine3) && { addressLine3: '' },
    };

    const { setValues, setTouched } = formikRef.current;
    let fieldsToSet = {};

    if (['both', 'separate'].includes(fieldSeparation)) {
      fieldsToSet = Object.keys(newValues).reduce((acc, f) => {
        const key = fieldNames[f] || f;
        acc[key] = newValues[f];
        return acc;
      }, {});
    }

    if (['both', 'condensed'].includes(fieldSeparation)) {
      fieldsToSet[name] = combineAddress(newValues);
    }

    const valuesToSet = cloneDeep(values);

    Object.entries(fieldsToSet).forEach(([field, val]) => {
      setField(valuesToSet, field, val);
    });

    setValues({
      ...valuesToSet,
      ...(typeof valuesToSet.updateMap !== 'undefined') && { updateMap: true },
    });
    setTouched({
      ...valuesToSet,
    });
    callback(valuesToSet);
  }, [formikRef]);

  useEffect(() => {
    if (country && googleAutocomplete) {
      try {
        googleAutocomplete.setComponentRestrictions({ country });
        googleAutocomplete.addListener('place_changed', () => handleAutoComplete(googleAutocomplete));
      } catch (e) { console.error(e); }
    }
  }, [country, googleAutocomplete]);

  useEffect(() => {
    const options = {
      types: ['geocode'],
      componentRestrictions: { country },
    };

    if (!googleAutocomplete && country) {
      try {
        setGoogleAutocomplete(new google.maps.places.Autocomplete(
          autocompleteInput.current, options,
        ));
        if (googleAutocomplete) {
          googleAutocomplete.addListener('place_changed', () => handleAutoComplete(googleAutocomplete));
        }
      } catch (e) { console.error(e); }
    }
  }, [country, googleAutocomplete]);

  return (
    <Grid item key={`textField-${name}`} style={{ padding: '12px 0' }}>
      <CustomAutoComplete
        inputRef={autocompleteInput}
        name={name}
        label={label}
        disabled={(disabled !== undefined && disabled.includes(name))}
        classes={(classes) || {}}
      />
    </Grid>
  );
}

TextFieldAutoCompleteComponent.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  parentProps: PropTypes.shape({
    other: PropTypes.shape({
      country: PropTypes.string.isRequired,
      disabled: PropTypes.arrayOf(PropTypes.string.isRequired),
      formikRef: PropTypes.shape({
        current: PropTypes.shape({
          setValues: PropTypes.func.isRequired,
          state: PropTypes.shape({
            values: PropTypes.objectOf(PropTypes.oneOfType([
              PropTypes.string,
              PropTypes.bool,
              PropTypes.number,
              PropTypes.array,
            ]).isRequired).isRequired,
          }).isRequired,
        }).isRequired,
      }).isRequired,
      classes: PropTypes.objectOf(PropTypes.string.isRequired).isRequired,
    }).isRequired,
  }).isRequired,
  fields: PropTypes.arrayOf(PropTypes.string).isRequired,
  fieldNames: PropTypes.objectOf(PropTypes.any).isRequired,
  fieldSeparation: PropTypes.oneOf(['separate', 'condensed', 'both']),
  callback: PropTypes.func,
};

TextFieldAutoCompleteComponent.defaultProps = {
  fieldSeparation: 'separate',
  callback: () => {},
};

/*          UTIL            */

function CustomCircularProgress(props) {
  const { keyName } = props;

  return (
    <MenuItem key={keyName} value="place_holder">
      <CircularProgress color="secondary" />
    </MenuItem>
  );
}

CustomCircularProgress.propTypes = {
  keyName: PropTypes.string.isRequired,
};

function LoadGoogleMapScript(props) {
  const { other } = props;
  const [isLoaded, setIsLoaded] = useState(false);
  const [apiKey, setApiKey] = useState(null);
  const [AsyncScriptComponent, setAsyncScriptComponent] = useState(null);

  const LoadingElement = () => (<CircularProgress color="secondary" />);

  React.useEffect(() => {
    getGoogleMapsApiKey().then((key) => {
      setApiKey(key);
    });
  }, []);

  React.useEffect(() => {
    if (!apiKey) return;

    const URL = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
    setAsyncScriptComponent(makeAsyncScriptLoader(URL, { globalName: 'google' })(LoadingElement));
  }, [apiKey]);

  function onLoad() {
    setIsLoaded(true);
  }

  function loadScript() {
    return (AsyncScriptComponent)
      ? <AsyncScriptComponent key="async-script-component" asyncScriptOnLoad={onLoad} />
      : <CircularProgress key="google-maps-script-loading" color="secondary" />;
  }

  return (isLoaded)
    ? <GoogleMap key="google-map" formikRef={other.formikRef} pickupFrom={other.pickupFrom} classes={other.classes} />
    : loadScript();
}

LoadGoogleMapScript.propTypes = {
  other: PropTypes.shape({
    formikRef: PropTypes.objectOf(PropTypes.object.isRequired).isRequired,
    pickupFrom: PropTypes.oneOfType([
      PropTypes.object.isRequired,
      PropTypes.string.isRequired,
    ]).isRequired,
    classes: PropTypes.objectOf(PropTypes.string.isRequired).isRequired,
  }).isRequired,
};

function LoadReactDependentScript({
  name, label, callback, countryName, ...props
}) {
  const { other, fields, fieldNames } = props;
  const { fieldSeparation } = other;
  const [isloaded, setIsLoaded] = useState(false);
  const [apiKey, setApiKey] = useState(null);
  const RegularTextFieldComponent = () => <TextFieldComponent other={other} name="addressLine1" label="Address Line 1" type="text" />;
  const [AsyncScriptComponent, setAsyncScriptComponent] = useState(null);

  React.useEffect(() => {
    getGoogleMapsApiKey().then((key) => {
      setApiKey(key);
    });
  }, []);

  React.useEffect(() => {
    if (apiKey) {
      const URL = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
      setAsyncScriptComponent(makeAsyncScriptLoader(URL, { globalName: 'google' })(LoadingElement));
    }
  }, [apiKey]);

  const LoadingElement = () => (<CircularProgress color="secondary" />);

  function onLoad() {
    setIsLoaded(true);
  }

  function loadscript() {
    return AsyncScriptComponent
      ? <AsyncScriptComponent asyncScriptOnLoad={onLoad} />
      : <RegularTextFieldComponent asyncScriptOnLoad={onLoad} />;
  }

  return (
    <div key="asyncScriptComponent">
      {isloaded
        ? (
          <TextFieldAutoCompleteComponent
            name={name}
            label={label}
            parentProps={props}
            fieldNames={fieldNames}
            fields={fields}
            fieldSeparation={fieldSeparation}
            callback={callback}
            countryName={countryName}
          />
        )
        : loadscript()}
    </div>
  );
}

LoadReactDependentScript.propTypes = {
  other: PropTypes.objectOf(PropTypes.object).isRequired,
  fields: PropTypes.arrayOf(PropTypes.string).isRequired,
  fieldNames: PropTypes.objectOf(PropTypes.any).isRequired,
  callback: PropTypes.func,
};

LoadReactDependentScript.defaultProps = {
  callback: () => {},
};

/**
 * #################################
 *          EXPORT FUNCTION
 * #################################
 */

export default function AddressForm(props) {
  const {
    fields, fieldNames, fieldLabels, ...other
  } = props;

  const { countries } = useMiscState();

  return (
    <>
      {fields.map((field) => {
        const fieldProps = {
          ...other,
        };

        if (['addressLine1', 'country'].includes(field)) {
          fieldProps.fieldNames = fieldNames;
          fieldProps.fields = fields;
        }

        if (field === 'country') {
          fieldProps.countries = countries;
        }

        if (fieldLabels[field]) {
          fieldProps.label = fieldLabels[field];
        }

        return fieldComponents[field](fieldProps, fieldNames[field]);
      })}
    </>
  );
}

AddressForm.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  fieldNames: PropTypes.objectOf(PropTypes.any),
  fieldLabels: PropTypes.objectOf(PropTypes.any),
};

AddressForm.defaultProps = {
  fieldNames: {},
  fieldLabels: {},
};
