import React from 'react';
import { Route } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { css } from 'emotion';

export default function NavigateToLoginButton() {
  return (
    <Route render={({ history }) => (
      <Button
        className={css`
          font-weight: bold;
          border-radius: 16px;
          width: 17%;
          margin: auto;
          min-width: 110px;
        `}
        color="secondary"
        variant="contained"
        onClick={() => { history.push('/login'); }}
      >
        Login
      </Button>
    )}
    />
  );
}
