import * as Yup from 'yup';
import { format } from 'date-fns';
import {
  get, omit, omitBy, isNil, set, isFinite, has, isEmpty, isNumber, isString, isNaN,
} from 'lodash';
import {
  FREIGHT_TYPES,
  FORM_FIELDS,
  LB,
  KG,
  L,
  KG_FREIGHT_LIMIT,
  LB_FREIGHT_LIMIT,
  DEFAULT_KG_LIMIT,
  DEFAULT_LB_LIMIT,
  L_FREIGHT_LIMIT,
  DEFAULT_L_LIMIT,
  LICENSE_KEYS,
  FORM_FIELDS_HAZMAT,
  ML,
  GRAM,
  OZ,
  FORM_FIELDS_CHEMICAL_HAZMAT,
  FORM_FIELDS_BATTERIES,
  ITEM_WEIGHT_KEY,
  TRANSPORTATION_OPTIONS_NAME_KEY,
  SHIPMENT_QUANTITY_NAME_KEY,
  SHIPMENT_QUANTITY_EXCEPTED_VALUE,
  SHIPMENT_QUANTITY_BULK_VALUE,
  INNER_WEIGHT_KEY,
  TRANSPORTATION_OPTION_GROUND_VALUE,
  PACKING_INSTRUCTIONS_FORBIDDEN,
  TRANSPORTATION_OPTION_CARGO_VALUE,
  DISPLAY_FIELDS,
  DISPLAY_CHEMICAL_HAZMAT_FIELDS,
  DISPLAY_BIOLOGICAL_HAZMAT_FIELDS,
  CHEMICAL_SMALL_MEASUREMENT_UNIT_OPTIONS,
  BIOLOGICAL_HAZMAT_OTHER_CLASS_TYPE,
  BATTERY_SMALL_MEASUREMENT_UNIT_OPTIONS,
  DISPLAY_BATTERY_FIELDS,
  FORM_FIELDS_BATTERIES_CONTAINED_OR_PACKED,
  SHIPPING_LITHIUM_BATTERIES_KEY,
  LITHIUM_BATTERY_PACKED_KEY,
  LITHIUM_BATTERY_CONTAINED_KEY,
  FORM_FIELDS_LITHIUM_BATTERY,
  TRANSPORTATION_OPTION_PASSENGER_CARGO_VALUE,
} from './itemDetailsConstants';
import {
  HAZMAT_BATTERIES, HAZMAT_BATTERIES_CONTAINED_OR_PACKED, HAZMAT_BATTERIES_PACKED, HAZMAT_CHEMICAL, HAZMAT_TOXINS,
} from '../../clientConstants';

export const isFieldEditable = (field, fields) => !fields.find((f) => f === field);

export const sanitizeItemObject = (item) => {
  let itemObj = { ...item };
  itemObj = omitBy(itemObj, (val, key) => {
    if ((key === LITHIUM_BATTERY_CONTAINED_KEY || key === LITHIUM_BATTERY_PACKED_KEY)
    && isLithiumBatteryObjectEmpty(key, itemObj)) return true;

    return (isNil(val) || val === '' || !val);
  });
  delete itemObj.shippingType;
  return itemObj;
};

const validateProductAndLithiumBatteryWeight = (value, context) => {
  const lithiumBattery = get(context, `from[1].value.${LITHIUM_BATTERY_CONTAINED_KEY}`, undefined);
  const lithiumBatteryName = get(context, `from[1].value.${LITHIUM_BATTERY_CONTAINED_KEY}.${FORM_FIELDS_LITHIUM_BATTERY.materialName.key}`, '');

  if (!value || !lithiumBattery || !lithiumBatteryName.toUpperCase().includes('CONTAINED')) return true;

  const { unitOfMeasurement } = context.parent;
  const productNumberOfUnits = get(context, `from[1].value.${FORM_FIELDS.unitValue.key}`, undefined);
  const parsedProductNumberOfUnits = !isEmpty(productNumberOfUnits) ? parseFloat(productNumberOfUnits) : 1;
  const lithiumBatteryWeightUnit = get(context, `from[1].value.${LITHIUM_BATTERY_CONTAINED_KEY}.${FORM_FIELDS_LITHIUM_BATTERY.weightUnit.key}`, '').toUpperCase();
  const productTotalWeight = sumWeightValues(context.parent);
  const convertedProductTotalWeight = unitOfMeasurement === KG ? productTotalWeight : productTotalWeight * 0.45;
  const lithiumBatteryWeight = get(lithiumBattery, FORM_FIELDS_LITHIUM_BATTERY.weight.key, '');
  const parsedLithiumBatteryWeight = !isEmpty(lithiumBatteryWeight)
    ? parseFloat(lithiumBatteryWeight) / parsedProductNumberOfUnits
    : 0;
  const convertedLithiumBatteryWeight = !isEmpty(lithiumBatteryWeightUnit) && lithiumBatteryWeightUnit === KG
    ? parsedLithiumBatteryWeight
    : parsedLithiumBatteryWeight * 0.45;

  if (!isNumber(parsedLithiumBatteryWeight)) return false;

  return convertedProductTotalWeight >= convertedLithiumBatteryWeight;
};

const buildUnitValueSchema = (freightType) => {
  let maxValue = Number.MAX_SAFE_INTEGER;
  if (FREIGHT_TYPES.document === freightType) {
    maxValue = 100;
  }
  if (FREIGHT_TYPES.freight === freightType) {
    maxValue = 50000;
  }
  if (FREIGHT_TYPES.product === freightType) {
    maxValue = 50000;
  }
  return Yup.object().shape({
    amount: Yup.mixed().test('is number', 'Amount must be a number', (l) => !isNaN(Number(l)))
      .test('Greater than minimum amount', 'Amount must be greater than 0', (l) => (!isNaN(Number(l)) ? Number(l) > 0 : true))
      .test('Less than max value', `Amount for a ${freightType} order cannot be greater than ${maxValue}`, (l) => (!isNaN(Number(l)) ? Number(l) <= maxValue : true))
      .test('No more than two decimals', 'Amount must be rounded to 2 decimal points', (a) => !a.match(/\.[0-9]{3,}/))
    // .test('Leading zero', 'Leading zero not allowed', (a) => !a.match(/^0+/))
      .test('Input exists', 'Required', (l) => !!l),
    currency: Yup.string().required('Required'),
  });
};

const getWhenClassTypeOtherKey = () => FORM_FIELDS_HAZMAT.classType.key;

const checkWhenClassTypeOtherBuilder = () => (({
  is: (value) => value === BIOLOGICAL_HAZMAT_OTHER_CLASS_TYPE,
  then: (schema) => schema.required('Required'),
  other: (schema) => schema,
}));

const buildBaseSchema = (freightType, hiddenFields) => Yup.object().shape({
  itemName: Yup.string()
    .trim()
    .required('Required'),
  itemId: Yup.string()
    .matches(/^[a-z0-9]+$/i, 'Must be alphanumeric')
    .max(20, 'Limit 20 characters')
    .test('Is required', 'Required', (value, context) => {
      const classType = get(context, `parent.${getWhenClassTypeOtherKey()}`, '');
      const productName = get(context, `parent.${FORM_FIELDS.itemName.key}`, '');
      const isFreightProduct = freightType === FREIGHT_TYPES.product;

      if (!isEmpty(classType) && classType === BIOLOGICAL_HAZMAT_OTHER_CLASS_TYPE) return true;

      return isFreightProduct ? !(isEmpty(productName) && isEmpty(value)) : true;
    }),
  description: Yup.string()
    .max(200, 'Limit 200 characters'),
  ...(!hiddenFields.includes('international') && {
    harmonizedCode: Yup.string().when(getWhenClassTypeOtherKey(), checkWhenClassTypeOtherBuilder())
      .matches(/^(?!\.)(?!.*\.$)(?!.*?\.\.)[a-zA-Z0-9.]+$/i, 'alphanumeric/dots only (e.g xx.xxx.xxx)')
      .test(
        'valid characters',
        '8-10 alphanumeric characters (dots accepted)',
        (value) => {
          if (!value || value === '') return true;
          const alphanumerics = value.replace(/[.]/g, '');
          return alphanumerics.length >= 8 && alphanumerics.length <= 10;
        },
      ),
    countryOfManufacture: Yup.string()
      .required('Required'),
  }),
  tariffCode: Yup.number()
    .min(0, 'Value must be greater than 0%')
    .max(100, 'Value cannot be greater than 100%')
    .typeError('Number required'),
  taxCode: Yup.number()
    .min(0, 'Value must be greater than 0%')
    .max(100, 'Value cannot be greater than 100%')
    .typeError('Number required'),
});

const buildSmallWeightSchema = () => Yup.number()
  .min(1, 'Value must be 1 or greater')
  .test('no-weight', 'Required',
    function isWeight(value) {
      const { weight } = this.parent;
      if (!weight && !value) return false;
      return true;
    })
  .test('weight limit', 'Exceeds weight limit.',
    function weightLimit(value, context) {
      if (!value) return true;

      const unit = this.parent.unitOfMeasurementSmall;
      let mlLimit = context.from[1].value?.iataDetails?.maxWeight?.milliliters;
      if (!mlLimit) mlLimit = 999;
      let gmLimit = context.from[1].value?.iataDetails?.maxWeight?.grams;
      if (!gmLimit) gmLimit = 999;
      const ozLimit = 15;

      switch (unit) {
        case ML:
          return value > mlLimit
            ? this.createError({
              message: `Exceeds weight limit of ${mlLimit} ${ML}`,
            })
            : true;
        case GRAM:
          return value > gmLimit
            ? this.createError({
              message: `Exceeds weight limit of ${gmLimit} ${GRAM}`,
            })
            : true;
        case OZ:
          return value > ozLimit
            ? this.createError({
              message: `Exceeds weight limit of ${ozLimit} ${OZ}`,
            })
            : true;
        default:
          break;
      }
    })
  .test(
    'lithium-battery-weight',
    'Lithium battery / cell weight cannot be greater than product weight.',
    (value, context) => validateProductAndLithiumBatteryWeight(value, context),
  )
  .nullable(true)
  .typeError('Number required');

const getWeightLimits = (freightType) => ({
  lb: freightType === FREIGHT_TYPES.freight ? LB_FREIGHT_LIMIT : DEFAULT_LB_LIMIT,
  kg: freightType === FREIGHT_TYPES.freight ? KG_FREIGHT_LIMIT : DEFAULT_KG_LIMIT,
  liter: freightType === FREIGHT_TYPES.freight ? L_FREIGHT_LIMIT : DEFAULT_L_LIMIT,
});

const buildProductWeightSchema = (freightType) => {
  const weightLimits = getWeightLimits(freightType);

  return Yup.number()
    .min(0, 'Value must be 0 or greater')
    .test('no-small-weight', 'Required',
      function isWeight(value) {
        const { weightSmall } = this.parent;
        if (!weightSmall && !value) return false;
        return true;
      })
    .test('weight limit', `Exceeds weight limit (${weightLimits.lb}lb or ${weightLimits.kg}kg)`,
      function weightLimit(value) {
        const { weightSmall } = this.parent;
        const remainder = weightSmall ? 1 : 0;
        if (!value) return true;
        const unit = this.parent.unitOfMeasurement;
        switch (unit) {
          case LB:
            return value <= weightLimits.lb - remainder;
          case KG:
            return value <= weightLimits.kg - remainder;
          default:
            break;
        }
      })
    .test(
      'lithium-battery-weight',
      'Lithium battery / cell weight cannot be greater than product weight.',
      (value, context) => validateProductAndLithiumBatteryWeight(value, context),
    )
    .nullable(true)
    .typeError('Number required');
};

const getChemicalHazmatTotalWeight = (key, context) => {
  const weight = get(context, `from[1].value.${key}`, 0);
  const totalWeight = sumWeightValues(weight);

  return totalWeight;
};

const validateChemicalHazmatWeights = (key, context) => {
  const totalInnerWeight = getChemicalHazmatTotalWeight(INNER_WEIGHT_KEY, context);
  const totalItemWeight = getChemicalHazmatTotalWeight(ITEM_WEIGHT_KEY, context);

  if (totalInnerWeight === 0 || totalItemWeight === 0) return true;

  switch (key) {
    case INNER_WEIGHT_KEY:
      return !(totalInnerWeight > totalItemWeight);
    case ITEM_WEIGHT_KEY:
      return !(totalItemWeight < totalInnerWeight);
    default:
      break;
  }
};

const getBiologicalHazmatIataLimits = (context, weightLimits) => {
  const transportationMode = get(context, 'from[1].value.transportationMode', '');
  switch (transportationMode) {
    case TRANSPORTATION_OPTION_CARGO_VALUE:
      return {
        kg: get(context, 'from[1].value.iataDetails.maxWeightCargo.kilograms', weightLimits.kg),
        liter: get(context, 'from[1].value.iataDetails.maxWeightCargo.liters', weightLimits.liter),
        lb: weightLimits.lb,
      };
    case TRANSPORTATION_OPTION_PASSENGER_CARGO_VALUE:
      return {
        kg: get(context, 'from[1].value.iataDetails.maxWeightPassenger.kilograms', weightLimits.kg),
        liter: get(context, 'from[1].value.iataDetails.maxWeightPassenger.liters', weightLimits.liter),
        lb: weightLimits.lb,
      };
    default:
      return {
        kg: get(context, 'from[1].value.iataDetails.maxWeight.kilograms', weightLimits.kg),
        liter: get(context, 'from[1].value.iataDetails.maxWeight.liters', weightLimits.liter),
        lb: weightLimits.lb,
      };
  }
};
const getItemWeightChemicalHazmatIataLimits = (values, weightLimits) => {
  const transportationMode = get(values, TRANSPORTATION_OPTIONS_NAME_KEY, '');

  const maxWeight = transportationMode === SHIPMENT_QUANTITY_EXCEPTED_VALUE
    ? get(values, `${transportationMode}.outerMaxNetQuantity`, 0)
    : get(values, `${transportationMode}.maxNetQuantity`, 0);
  if (!maxWeight) return null;
  return {
    kg: maxWeight,
    liter: maxWeight,
    lb: weightLimits.lb,
  };
};
const getItemWeightBatteryLimits = (values, weightLimits) => {
  const transportationMode = get(values, TRANSPORTATION_OPTIONS_NAME_KEY, '');

  // eslint-disable-next-line no-nested-ternary
  const maxWeight = transportationMode === TRANSPORTATION_OPTION_CARGO_VALUE
    ? (isNaN(parseInt(get(values, 'cargo', '').split(' ')[0], 10))
      ? weightLimits.kg
      : parseInt(get(values, 'cargo', '').split(' ')[0], 10))
    : weightLimits.kg;
  return {
    kg: maxWeight,
    liter: maxWeight,
    lb: weightLimits.lb,
  };
};
const getInnerWeightChemicalHazmatIataLimits = (context, weightLimits) => {
  const innerPackaging = get(context, `from[1].value.${FORM_FIELDS_CHEMICAL_HAZMAT.innerPackaging.key}`, '');
  const transportationMode = get(context, `from[1].value.${TRANSPORTATION_OPTIONS_NAME_KEY}`, '');
  const innerValue = transportationMode === SHIPMENT_QUANTITY_EXCEPTED_VALUE
    ? get(context, `from[1].value.${transportationMode}.innerMaxNetQuantity`, 0)
    : get(context, `from[1].value.${transportationMode}.innerValues`, []).find(
      (v) => v.innerMaterial === innerPackaging,
    );
  const maxWeight = has(innerValue, 'innerQuantity')
    ? get(innerValue, 'innerQuantity', weightLimits.liter)
    : innerValue;

  if (!maxWeight) { return null; }

  return {
    kg: maxWeight,
    liter: maxWeight,
    lb: weightLimits.lb,
  };
};

const buildHazmatWeightSchema = (freightType, isChemicalHazmat, isBattery) => {
  const weightLimits = getWeightLimits(freightType);

  return Yup.number()
    .min(0, 'Value must be 0 or greater')
    .test('no-small-weight', 'Required',
      function isWeight(value) {
        const { weightSmall } = this.parent;
        if (!weightSmall && !value) return false;
        return true;
      })
    .test('weight limit', 'Exceeds weight limit ',
      function weightLimit(value, context) {
        if (!value) return true;

        const weightSmall = this.parent.weightSmall || 0;
        let iataLimits = {
          kg: weightLimits.kg,
          liter: weightLimits.liter,
          lb: weightLimits.lb,
        };

        if (isChemicalHazmat) {
          const { path } = context;
          const isInnerWeight = path.includes(INNER_WEIGHT_KEY);
          const key = isInnerWeight ? INNER_WEIGHT_KEY : ITEM_WEIGHT_KEY;
          const isGround = get(context, `from[1].value.${TRANSPORTATION_OPTIONS_NAME_KEY}`, false) === TRANSPORTATION_OPTION_GROUND_VALUE;

          const isWeightValid = validateChemicalHazmatWeights(key, context);

          if (!isWeightValid) {
            return this.createError({
              message: 'Inner receptable quantity should be less than total quantity in package',
            });
          }

          // If transportation mode is 'Ground only' (ground), only do validations until this point.
          // Ground transporation does not have weight limits.
          if (isGround) return true;

          iataLimits = path.includes(INNER_WEIGHT_KEY)
            ? getInnerWeightChemicalHazmatIataLimits(context, weightLimits)
            : getItemWeightChemicalHazmatIataLimits(context.from[1].value, weightLimits);
          if (!iataLimits) {
            return true;
          }
        } else if (isBattery) {
          iataLimits = getItemWeightBatteryLimits(
            context.from[1].value,
            weightLimits,
          );
        } else iataLimits = getBiologicalHazmatIataLimits(context, weightLimits);

        switch (this.parent.unitOfMeasurement) {
          case KG:
            return (value + weightSmall * 0.001) <= iataLimits.kg
              ? true
              : this.createError({
                message: `Exceeds weight limit of ${iataLimits.kg} KG`,
              });
          case L:
            return (value + weightSmall * 0.001) <= iataLimits.liter
              ? true
              : this.createError({
                message: `Exceeds weight limit of ${iataLimits.liter} L`,
              });
          case LB:
            return (value + weightSmall * 0.0625) <= iataLimits.lb
              ? true
              : this.createError({
                message: `Exceeds weight limit of ${iataLimits.lb} L`,
              });
          default:
            break;
        }
      })
    .nullable(true)
    .typeError('Number required');
};

const buildBaseItemWeightSchema = (freightType) => Yup.object().shape({
  weightSmall: buildSmallWeightSchema(freightType),
  unitOfMeasurement: Yup.string().required('Required'),
});

const buildItemWeightSchema = (freightType, hiddenFields, isChemicalHazmat, isBattery = false) => (
  Yup.object().shape({
    weight: hiddenFields.includes('material')
      ? buildHazmatWeightSchema(freightType, isChemicalHazmat, isBattery)
      : buildProductWeightSchema(freightType),
  }).concat(buildBaseItemWeightSchema())
);

const buildWeightSchema = (freightType, hiddenFields, isChemicalHazmat = false) => Yup.object().shape({
  itemWeight: buildItemWeightSchema(freightType, hiddenFields, isChemicalHazmat),
});

const buildPreservativeSchema = () => Yup.object().shape({
  [FORM_FIELDS.isPreservative.key]: Yup.boolean(),
  [FORM_FIELDS.preservative.key]: Yup.string().test(
    'pres check ',
    'Required',
    function refCheck(value) {
      const { isPreservative } = this.parent;
      if (isPreservative && !value) {
        return false;
      }
      return true;
    },
  ),
  preservativeWeight: Yup.object().when('isPreservative', {
    is: true,
    then: Yup.object().shape({
      weight: Yup.number().typeError('Must be a number greater than 0').test(
        'pres1 check ',
        'Required',
        (value) => {
          if (!value) {
            return false;
          }
          return true;
        },
      )
        .test('weight limit', 'Value must be 0 or greater',
          (value) => {
            const weightLimits = 0;
            if (!value) return true;
            return value >= weightLimits;
          })
        .test('weight limit', 'Exceeds weight limit of 30 ml',
          (value) => {
            const weightLimits = 30;
            if (!value) return true;
            return value <= weightLimits;
          }),
      unitOfMeasurement: Yup.string().test(
        'pres2 check ',
        'Required',
        (value) => {
          if (!value) {
            return false;
          }
          return true;
        },
      ),
    }),
    otherwise: Yup.object().shape({
      weight: Yup.number()
        .nullable()
        .transform((value, o) => (o === '' ? null : +value)),
      unitOfMeasurement: Yup.string().test(
        'pres2 check ',
        'Required',
        (value) => true,
      ),
    }),
  }),
  preservativeOuterWeight: Yup.object().when('isPreservative', {
    is: true,
    then: Yup.object().shape({
      weight: Yup.number().typeError('Must be a number greater than 0').test(
        'pres3 check ',
        'Required',
        (value, context) => {
          if (!value) {
            return false;
          }
          return true;
        },
      )
        .test('weight limit', 'Value must be 0 or greater',
          (value) => {
            const weightLimits = 0;
            if (!value) return true;
            return value >= weightLimits;
          })
        .test('weight limit', 'Exceeds weight limit of 1000 ml',
          (value) => {
            const weightLimits = 1000;
            if (!value) return true;
            return value <= weightLimits;
          }),
      unitOfMeasurement: Yup.string().test(
        'pres4 check ',
        'Required',
        function ref4Check(value) {
          const { isPreservative } = this.parent;
          if (isPreservative && !value) {
            return false;
          }
          return true;
        },
      ),
    }),
    otherwise: Yup.object().shape({
      weight: Yup.number()
        .nullable()
        .transform((value, o) => (o === '' ? null : +value)),
      unitOfMeasurement: Yup.string().test(
        'pres4 check ',
        'Required',
        function ref4Check(value) {
          const { isPreservative } = this.parent;
          if (isPreservative && !value) {
            return false;
          }
          return true;
        },
      ),
    }),
  }),
});

const buildBatteryContainedOrPackedItemSchema = (freightType, isBatteryContained) => {
  const weightLimits = getWeightLimits(freightType);

  return Yup.object()
    .shape({
      [FORM_FIELDS_LITHIUM_BATTERY.itemId.key]: Yup.string()
        .matches(/^[a-z0-9]+$/i, 'Must be alphanumeric')
        .max(20, 'Limit 20 characters'),
      [FORM_FIELDS_LITHIUM_BATTERY.materialName.key]: Yup.string(),
      [FORM_FIELDS_LITHIUM_BATTERY.unNo.key]: Yup.string(),
      [FORM_FIELDS_LITHIUM_BATTERY.properShippingName.key]: Yup.string(),
      [FORM_FIELDS_LITHIUM_BATTERY.classDivision.key]: Yup.string(),
      [FORM_FIELDS_LITHIUM_BATTERY.packingGroup.key]: Yup.string(),
      [FORM_FIELDS_LITHIUM_BATTERY.labels.key]: Yup.string(),
      [FORM_FIELDS_LITHIUM_BATTERY.packingInstructions.key]: Yup.string(),
      [FORM_FIELDS_LITHIUM_BATTERY.authorization.key]: Yup.string(),
      [FORM_FIELDS_LITHIUM_BATTERY.units.key]: Yup.string()
        .matches(/^[0-9]+$/i, 'Must be integer')
        .test(
          'max-value',
          'The maximum additional battery/cell allowed is 2.',
          function (value, context) {
            if (isEmpty(value)) return true;

            const productValues = context.from[1].value;
            const lithiumValues = context.parent;
            const isShippingLithiumBatteries = get(productValues, SHIPPING_LITHIUM_BATTERIES_KEY, false);
            const parsedValue = parseInt(value, 10);
            const itemName = lithiumValues[FORM_FIELDS_LITHIUM_BATTERY.materialName.key];
            const isCell = !isEmpty(itemName) ? itemName.includes('cells') : false;
            const maximumAdditional = isCell ? 4 : 2;

            if (isShippingLithiumBatteries && isBatteryContained && parsedValue > maximumAdditional) {
              return this.createError({
                message: `The maximum additional battery/cell allowed is ${maximumAdditional}.`,
              });
            }

            return true;
          },
        )
        .required('Required'),
      [FORM_FIELDS_LITHIUM_BATTERY.weight.key]: Yup.number()
        .min(0, 'Value must be 0 or greater')
        .test('weight limit', 'Exceeds weight limit ',
          function weightLimit(value) {
            if (isEmpty(value)) return true;

            const parsedValue = parseFloat(value);
            const iataLimits = {
              kg: weightLimits.kg,
              lb: weightLimits.lb,
            };

            switch (this.parent[FORM_FIELDS_LITHIUM_BATTERY.weightUnit.key]) {
              case KG:
                return isNumber(parsedValue) && parsedValue <= iataLimits.kg
                  ? true
                  : this.createError({
                    message: `Exceeds weight limit of ${iataLimits.kg} KG`,
                  });
              case LB:
                return isNumber(parsedValue) && parsedValue
                  ? true
                  : this.createError({
                    message: `Exceeds weight limit of ${iataLimits.lb} L`,
                  });
              default:
                break;
            }
          })
        .nullable(true)
        .typeError('Number required'),
      [FORM_FIELDS_LITHIUM_BATTERY.weightUnit.key]: Yup.string().required('Required'),
    });
};

export const buildProductItemSchema = (freightType, hiddenFields, isBiologicalHazmat, isBatteryContained) => {
  const licenseSchema = !hiddenFields.includes('licenseDetermination') ? Yup.object().shape({
    isLicensePermitRequired: Yup.string().required('Required'),

    licenseType: Yup.string().when('isLicensePermitRequired', {
      is: 'yes',
      then: Yup.string().required('Required'),
    }),
    licenseNumber: Yup.string().when('isLicensePermitRequired', {
      is: 'yes',
      then: Yup.string().required('Required'),
    }),
  }) : null;

  const weightSchema = buildWeightSchema(freightType, hiddenFields);
  const preservativeSchema = isBiologicalHazmat ? buildPreservativeSchema() : null;

  const freightSchema = !hiddenFields.includes('freightOnly') ? Yup.object().shape({
    freightClass: Yup.string().required('Required'),
    nmfcCodeNumber: Yup.string().required('Required'),
  }) : null;

  const biologicalHazmatSchema = isBiologicalHazmat ? Yup.object().shape({
    [FORM_FIELDS_HAZMAT.classTypeName.key]: Yup.string().when(getWhenClassTypeOtherKey(), checkWhenClassTypeOtherBuilder()),
    [FORM_FIELDS_HAZMAT.shipClass.key]: Yup.string().when(getWhenClassTypeOtherKey(), checkWhenClassTypeOtherBuilder()),
    [TRANSPORTATION_OPTIONS_NAME_KEY]: Yup.string().required('Required'),
    [FORM_FIELDS_HAZMAT.iataDetails.key]: Yup.object().when(getWhenClassTypeOtherKey(), {
      is: (value) => value === BIOLOGICAL_HAZMAT_OTHER_CLASS_TYPE,
      then: Yup.object().shape({
        properShippingName: Yup.string().required('Required'),
        unNo: Yup.string().required('Required'),
      }),
      otherwise: Yup.object().shape({
        properShippingName: Yup.string(),
        unNo: Yup.string(),
      }),
    }),
  }) : null;

  return Yup.object().shape({
    sku: Yup.string()
      .matches(/^[a-z0-9]+$/i, 'Must be alphanumeric')
      .max(20, 'Limit 20 characters'),
    binNumber: Yup.string()
      .matches(/^[a-z0-9]+$/i, 'Must be alphanumeric')
      .max(40, 'Limit 40 characters'),
    units: Yup.object().shape({
      unitValue: buildUnitValueSchema(freightType),
      noOfUnits: Yup.number().positive('Range 1-1000').integer('Must be integer')
        .required()
        .typeError('Number required'),
      unitOfMeasurement: hiddenFields.includes('material') ? Yup.string() : Yup.string().required('Required'),
    }),
    ...(!hiddenFields.includes('material') && {
      itemDimensions: Yup.object().shape({
        unitOfMeasurement: Yup.string(),
        length: Yup.lazy((value) => (isString(value) && isEmpty(value) ? Yup.string() : Yup.number().min(0, 'Value must be 0 or greater'))),
        width: Yup.lazy((value) => (isString(value) && isEmpty(value) ? Yup.string() : Yup.number().min(0, 'Value must be 0 or greater'))),
        height: Yup.lazy((value) => (isString(value) && isEmpty(value) ? Yup.string() : Yup.number().min(0, 'Value must be 0 or greater'))),
      }),
      eccn: Yup.string().when(getWhenClassTypeOtherKey(), checkWhenClassTypeOtherBuilder())
        .matches(/^[a-z0-9]+$/i, 'Must be alphanumeric')
        .min(5, '5 characters')
        .max(5, '5 characters'),
    }),
    licenseNumber: Yup.string()
      .matches(/^[a-z0-9]+$/i, 'Must be alphanumeric')
      .max(40, 'Limit 40 characters'),
    nmfcCodeNumber: Yup.string()
      .matches(/^[a-z0-9]+$/i, 'Must be alphanumeric')
      .max(40, 'Limit 40 characters'),
    [LITHIUM_BATTERY_CONTAINED_KEY]: Yup.object().when(SHIPPING_LITHIUM_BATTERIES_KEY, {
      is: (val) => !isBatteryContained && val,
      then: (schema) => schema.concat(buildBatteryContainedOrPackedItemSchema(freightType, isBatteryContained)),
      otherwise: (schema) => schema,
    }),
    [LITHIUM_BATTERY_PACKED_KEY]: Yup.object().when(SHIPPING_LITHIUM_BATTERIES_KEY, {
      is: (val) => isBatteryContained && val,
      then: (schema) => schema.concat(buildBatteryContainedOrPackedItemSchema(freightType, isBatteryContained)),
      otherwise: (schema) => schema,
    }),
  }).concat(buildBaseSchema(freightType, hiddenFields))
    .concat(biologicalHazmatSchema)
    .concat(preservativeSchema)
    .concat(licenseSchema)
    .concat(weightSchema)
    .concat(freightSchema);
};

export const buildChemicalItemSchema = (freightType, hiddenFields) => Yup.object().shape({
  [FORM_FIELDS_CHEMICAL_HAZMAT.unNo.key]: Yup.string(),
  [FORM_FIELDS_CHEMICAL_HAZMAT.classDivision.key]: Yup.string(),
  [FORM_FIELDS_CHEMICAL_HAZMAT.packingGroup.key]: Yup.string(),
  [FORM_FIELDS_CHEMICAL_HAZMAT.properShippingName.key]: Yup.string(),
  [FORM_FIELDS_CHEMICAL_HAZMAT.labels.key]: Yup.string(),
  [FORM_FIELDS_CHEMICAL_HAZMAT.packingInstructions.key]: Yup.string(),
  [FORM_FIELDS_CHEMICAL_HAZMAT.specialProvisions.key]: Yup.string(),
  [FORM_FIELDS_CHEMICAL_HAZMAT.technicalName.key]: Yup.string()
    .when(FORM_FIELDS_CHEMICAL_HAZMAT.technicalNameRequired.key, {
      is: true,
      then: (schema) => schema.required('Required'),
      otherwise: (schema) => schema,
    }),
  [FORM_FIELDS_CHEMICAL_HAZMAT.authorization.key]: Yup.string(),
  [FORM_FIELDS_CHEMICAL_HAZMAT.specialPermits.key]: Yup.string(),
  [FORM_FIELDS_CHEMICAL_HAZMAT.innerPackaging.key]: Yup.string()
    .test(
      'empty-inner-values',
      `Please choose a different shipment quantity / transportation option. This item, with these choices, does not have ${FORM_FIELDS_CHEMICAL_HAZMAT.innerPackaging.label} options.`,
      (value, context) => {
        const values = context.parent;
        const itemId = get(values, FORM_FIELDS_CHEMICAL_HAZMAT.itemId.key);
        const transportationMode = get(values, TRANSPORTATION_OPTIONS_NAME_KEY, '');

        if (transportationMode === SHIPMENT_QUANTITY_EXCEPTED_VALUE
          || transportationMode === SHIPMENT_QUANTITY_BULK_VALUE
          || transportationMode === TRANSPORTATION_OPTION_GROUND_VALUE
          || isEmpty(transportationMode)
          || isEmpty(itemId)
        ) return true;

        const quantity = values[transportationMode];

        return !isEmpty(quantity.innerValues);
      },
    ).required('Required'),
  [TRANSPORTATION_OPTIONS_NAME_KEY]: Yup.string().required('Required'),
  [INNER_WEIGHT_KEY]: buildItemWeightSchema(freightType, hiddenFields, true),
  [ITEM_WEIGHT_KEY]: buildItemWeightSchema(freightType, hiddenFields, true),
  totalValue: buildUnitValueSchema(freightType),
  totalWeightKG: Yup.string(),
  totalWeightLB: Yup.string(),
}).concat(buildBaseSchema(freightType, hiddenFields));

export const buildBatterySchema = (freightType, hiddenFields) => Yup.object()
  .shape({
    [FORM_FIELDS_CHEMICAL_HAZMAT.unNo.key]: Yup.string().required('Required'),
    [FORM_FIELDS_CHEMICAL_HAZMAT.classDivision.key]:
        Yup.string().required('Required'),
    [FORM_FIELDS_CHEMICAL_HAZMAT.packingGroup.key]: Yup.string(),
    [FORM_FIELDS_CHEMICAL_HAZMAT.properShippingName.key]:
        Yup.string().required('Required'),
    [FORM_FIELDS_CHEMICAL_HAZMAT.labels.key]: Yup.string(),
    [FORM_FIELDS_CHEMICAL_HAZMAT.packingInstructions.key]: Yup.string(),
    [FORM_FIELDS_CHEMICAL_HAZMAT.authorization.key]: Yup.string(),
    [TRANSPORTATION_OPTIONS_NAME_KEY]: Yup.string().required('Required'),
    [ITEM_WEIGHT_KEY]: buildItemWeightSchema(
      freightType,
      hiddenFields,
      false,
      true,
    ),
    noOfUnits: Yup.string(),
    totalValue: buildUnitValueSchema(freightType),
    totalWeightKG: Yup.string(),
    totalWeightLB: Yup.string(),
    units: Yup.object().shape({
      noOfUnits: Yup.number()
        .positive('Range 1-1000')
        .integer('Must be integer')
        .required()
        .typeError('Number required'),
      unitOfMeasurement: hiddenFields.includes('material')
        ? Yup.string()
        : Yup.string(),
    }),
  })
  .concat(buildBaseSchema(freightType, hiddenFields));

export const buildItemSchema = (freightType, hiddenFields = [], isChemicalHazmat, isBiologicalHazmat, isBattery, isBatteryContained) => {
  if (isChemicalHazmat) {
    return buildChemicalItemSchema(freightType, hiddenFields);
  }
  if (isBattery) {
    return buildBatterySchema(freightType, hiddenFields);
  }
  return buildProductItemSchema(freightType, hiddenFields, isBiologicalHazmat, isBatteryContained);
};

export const localFilter = (text, key) => {
  const filterRegex = new RegExp(text, 'i');

  return (d) => {
    // When d.label is not an string and is a react element.
    if (!isString(d.label) && !isEmpty(key) && isNumber(parseInt(text, 10))) {
      return d[key].match(filterRegex);
    }

    return d.value.match(filterRegex) || d.label.match(filterRegex);
  };
};

export const isValidNumber = (number) => {
  if (!isFinite) return false;

  return number >= 0;
};

export const isAlphaNumeric = (ch) => ch.match(/^[a-z0-9]+$/i) !== null;

export const blockWeightAndDimensionInputs = (val) => {
  const tooManyDecimalPlaces = (val.match(/\.[0-9]{3,}/));
  return tooManyDecimalPlaces;
};

export function isItemDetailsComplete(shipment) {
  return get(shipment, 'product.details.isProductCompleted', false);
}

export function prepareItemPayload(object, array) {
  const itemImagePath = FORM_FIELDS.itemImage.key;
  const itemImage = get(object, itemImagePath);
  if (isNil(itemImage)) {
    array.push(object);
    return ({ items: array });
  }
  set(object, itemImagePath, true);
  array.push(object);
  return ({
    items: array,
    itemImage,
  });
}

export function getLicenseText(val) {
  let licenses = '';

  LICENSE_KEYS.forEach((licenseKey) => {
    if (has(val, licenseKey) && val[licenseKey]) licenses += `${FORM_FIELDS_HAZMAT[licenseKey].label}\n`;
  });

  return licenses.trim();
}

export function showUserRequiredTrainingWarning(shipClass, training) {
  const today = format(new Date(), 'MM-dd-yyyy');
  const formattedShipClass = !isEmpty(shipClass) ? shipClass.replace('-', '').toLowerCase() : '';
  const userTrainingExpireDate = !isEmpty(formattedShipClass) && has(training, formattedShipClass)
    ? training[formattedShipClass].expiresAt
    : null;

  return (!isEmpty(shipClass) && isEmpty(userTrainingExpireDate)) || today > userTrainingExpireDate;
}
export function showUserRequiredChemicalTrainingWarning(training) {
  const today = format(new Date(), 'MM-dd-yyyy');
  const userTrainingExpireDate = has(training, HAZMAT_CHEMICAL)
    ? training[HAZMAT_CHEMICAL].expiresAt
    : null;

  return (
    (isEmpty(userTrainingExpireDate))
    || today > userTrainingExpireDate
  );
}
export function showUserRequiredBatteryTrainingWarning(training) {
  const today = format(new Date(), 'MM-dd-yyyy');
  const userTrainingExpireDate = has(training, 'battery')
    ? training.battery.expiresAt
    : null;

  return isEmpty(userTrainingExpireDate) || today > userTrainingExpireDate;
}
export function isCargoShipment(iataDetails, weight, smallWeight, unit) {
  const totalWeight = weight + smallWeight * 0.001;
  let maxWeightPassenger = 0;

  if (iataDetails && isEmpty(iataDetails.maxWeightPassenger)) return false;

  switch (unit) {
    case KG:
      maxWeightPassenger = isEmpty(iataDetails.maxWeightPassenger)
        ? DEFAULT_KG_LIMIT
        : iataDetails.maxWeightPassenger.kilograms;

      break;
    case L:
      maxWeightPassenger = isEmpty(iataDetails.maxWeightPassenger)
        ? DEFAULT_L_LIMIT
        : iataDetails.maxWeightPassenger.liters;

      break;
    default:
      break;
  }

  return totalWeight > parseFloat(maxWeightPassenger);
}

export function sumWeightValues(value) {
  if (!value || !value.weight) return 0;

  const weight = parseFloat(value.weight);
  const weightSmall = parseFloat(value.weightSmall);

  return weightSmall > 0 ? weight + weightSmall / 1000 : weight;
}

export const isMaterialForbidden = (values) => {
  if (!values) return false;

  const packingInstructions = get(values, FORM_FIELDS_CHEMICAL_HAZMAT.packingInstructions.key, '');

  return packingInstructions === PACKING_INSTRUCTIONS_FORBIDDEN;
};

export function validateChemicalTransportationQuantity(values, option) {
  const quantity = get(values, option.value, null);

  return isMaterialForbidden(quantity);
}

export function getDefaultChemicalShipmentWeight(selectedItem, values) {
  const totalWeight = get(values, FORM_FIELDS_CHEMICAL_HAZMAT[ITEM_WEIGHT_KEY].key, 0);

  if (!totalWeight || totalWeight === 0 || isEmpty(totalWeight)) {
    let unitOfMeasurement = selectedItem.cargo.maxNetQuantityUnit;
    unitOfMeasurement = !isEmpty(unitOfMeasurement) ? unitOfMeasurement : L;
    const unitOfMeasurementSmallObj = CHEMICAL_SMALL_MEASUREMENT_UNIT_OPTIONS.find((o) => o.unit === unitOfMeasurement);
    const unitOfMeasurementSmall = get(unitOfMeasurementSmallObj, 'weightCode', ML);

    return {
      [INNER_WEIGHT_KEY]: {
        weight: '',
        unitOfMeasurement,
        weightSmall: '',
        unitOfMeasurementSmall,
      },
      [ITEM_WEIGHT_KEY]: {
        weight: '',
        unitOfMeasurement,
        weightSmall: '',
        unitOfMeasurementSmall,
      },
    };
  }
}

export function getDefaultBatteryShipmentWeight(selectedItem, values) {
  const unitOfMeasurement = KG;
  const unitOfMeasurementSmallObj = BATTERY_SMALL_MEASUREMENT_UNIT_OPTIONS.find(
    (o) => o.unit === unitOfMeasurement,
  );
  const unitOfMeasurementSmall = get(
    unitOfMeasurementSmallObj,
    'weightCode',
    GRAM,
  );

  return {
    [ITEM_WEIGHT_KEY]: {
      weight: '',
      unitOfMeasurement,
      weightSmall: '',
      unitOfMeasurementSmall,
    },
  };
}
export function getQuantityInnerValues(transportationMode, values) {
  if (transportationMode === SHIPMENT_QUANTITY_EXCEPTED_VALUE
    || transportationMode === TRANSPORTATION_OPTION_GROUND_VALUE) {
    return [];
  }

  return get(values, `${transportationMode}.innerValues`, []);
}

export function setDefaultChemicalShipmentWeight(selectedItem, values, setFieldValue) {
  const weights = getDefaultChemicalShipmentWeight(selectedItem, values);

  if (weights) {
    setFieldValue(weights[INNER_WEIGHT_KEY]);
    setFieldValue(weights[ITEM_WEIGHT_KEY]);
  }
}

export const getPackingInstructions = (transportationMode, values) => (transportationMode !== TRANSPORTATION_OPTION_GROUND_VALUE
  && transportationMode !== SHIPMENT_QUANTITY_EXCEPTED_VALUE
  ? values[transportationMode][FORM_FIELDS_CHEMICAL_HAZMAT.packingInstructions.key]
  : '');

export const setPackingIntructions = (transportationMode, values, setFieldValue) => {
  if (transportationMode !== SHIPMENT_QUANTITY_BULK_VALUE) {
    setFieldValue(
      FORM_FIELDS_CHEMICAL_HAZMAT.packingInstructions.key,
      getPackingInstructions(transportationMode, values),
    );
  }
};

export const setTransportationMode = (selectedValue, formikProps) => {
  setPackingIntructions(selectedValue, formikProps?.values, formikProps?.setFieldValue);

  formikProps.setFieldValue(
    TRANSPORTATION_OPTIONS_NAME_KEY,
    selectedValue,
  );
};

export const isChemicalProductIneligible = (selectedItem, formValues) => {
  const transportationMode = formValues[TRANSPORTATION_OPTIONS_NAME_KEY];

  if (isEmpty(transportationMode)) return false;
  if (transportationMode === SHIPMENT_QUANTITY_EXCEPTED_VALUE
    && isEmpty(selectedItem[transportationMode])) return true;

  const packingInstructions = getPackingInstructions(transportationMode, selectedItem);
  const isForbidden = isMaterialForbidden({
    ...selectedItem,
    [FORM_FIELDS_CHEMICAL_HAZMAT.packingInstructions.key]: packingInstructions,
  });

  return isForbidden
  && formValues?.disableTransportationOptions
  && transportationMode !== TRANSPORTATION_OPTION_GROUND_VALUE;
};

export const getTransportationWeight = (key, formikProps, option) => {
  const value = key === SHIPMENT_QUANTITY_NAME_KEY && option.value === SHIPMENT_QUANTITY_BULK_VALUE
    ? TRANSPORTATION_OPTION_CARGO_VALUE
    : option.value;
  const shipmentQuantity = get(formikProps.values, value, null);
  const maxNetQuantity = value === SHIPMENT_QUANTITY_EXCEPTED_VALUE
    ? get(shipmentQuantity, 'outerMaxNetQuantity', null)
    : get(shipmentQuantity, 'maxNetQuantity', null);
  const maxNetQuantityUnit = value === SHIPMENT_QUANTITY_EXCEPTED_VALUE
    ? get(shipmentQuantity, 'outerQuantityUnit', null)
    : get(shipmentQuantity, 'maxNetQuantityUnit', null);

  return {
    shipmentQuantity,
    maxNetQuantity: maxNetQuantity ? maxNetQuantity.toString() : null,
    maxNetQuantityUnit,
  };
};

export const getIataMaxWeight = (key, formikProps, option) => {
  const iataDetails = get(formikProps.values, 'iataDetails', null);
  const maxNetQuantity = option.value === TRANSPORTATION_OPTION_CARGO_VALUE
    ? get(iataDetails, 'maxWeightCargo.kilograms', null)
    : get(iataDetails, 'maxWeightPassenger.kilograms', null);
  const maxNetQuantityUnit = get(formikProps.values, 'itemWeight.unitOfMeasurement', null);

  return {
    // shipmentQuantity,
    maxNetQuantity: maxNetQuantity ? maxNetQuantity.toString() : null,
    maxNetQuantityUnit,
  };
};

export const getDisplayFields = (hazmatOption, isHazmat, isInternational, hideDutiesAndTaxes) => {
  let fields = DISPLAY_FIELDS;

  if (isHazmat) {
    if (hazmatOption === HAZMAT_BATTERIES) {
      fields = DISPLAY_BATTERY_FIELDS;
    } else if (hazmatOption === HAZMAT_CHEMICAL || hazmatOption === HAZMAT_TOXINS) {
      fields = DISPLAY_CHEMICAL_HAZMAT_FIELDS;
    } else {
      fields = DISPLAY_BIOLOGICAL_HAZMAT_FIELDS;
    }
  }
  if (!isInternational || hideDutiesAndTaxes) {
    fields = fields.filter((a) => (a.label !== FORM_FIELDS.tariffCode.label && a.label !== FORM_FIELDS.taxCode.label));
  }

  return fields;
};

export const isBiologicalHazmatOtherClassType = (values) => {
  const classType = get(values, FORM_FIELDS_HAZMAT.classType.key, '');

  if (isEmpty(classType)) return false;

  return classType === BIOLOGICAL_HAZMAT_OTHER_CLASS_TYPE;
};

export const getFormFields = (freightType, hazmatOption, isBatteryContained = false) => {
  if (hazmatOption === HAZMAT_CHEMICAL || hazmatOption === HAZMAT_TOXINS) {
    return FORM_FIELDS_CHEMICAL_HAZMAT;
  }
  if (hazmatOption === HAZMAT_BATTERIES) {
    return FORM_FIELDS_BATTERIES;
  }
  if (freightType === FREIGHT_TYPES.product || hazmatOption === HAZMAT_BATTERIES_CONTAINED_OR_PACKED || isBatteryContained) {
    return FORM_FIELDS_BATTERIES_CONTAINED_OR_PACKED;
  }

  return FORM_FIELDS_HAZMAT;
};

export const getClassTypeDetailsHazmatOption = (values, freightType, hazmatOption, isBatteryContained) => {
  const isShippingLithiumBatteries = get(values, SHIPPING_LITHIUM_BATTERIES_KEY, false);
  if (freightType !== FREIGHT_TYPES.product && hazmatOption !== HAZMAT_BATTERIES_CONTAINED_OR_PACKED) return hazmatOption;

  return isBatteryContained && isShippingLithiumBatteries ? HAZMAT_BATTERIES_PACKED : HAZMAT_BATTERIES_CONTAINED_OR_PACKED;
};

export const getLithiumContainedOrPackedKey = (values, isBatteryContained = false) => {
  const hasLithiumBattery = !isLithiumBatteryObjectEmpty(LITHIUM_BATTERY_CONTAINED_KEY, values);
  const isShippingLithiumBatteries = get(values, SHIPPING_LITHIUM_BATTERIES_KEY, false);
  const isBatteryPacked = (hasLithiumBattery && isBatteryContained && isShippingLithiumBatteries);

  return isBatteryPacked ? LITHIUM_BATTERY_PACKED_KEY : LITHIUM_BATTERY_CONTAINED_KEY;
};

export const isBatteryContainedOrPacked = (values) => has(values, LITHIUM_BATTERY_CONTAINED_KEY) || has(values, LITHIUM_BATTERY_PACKED_KEY);

export const doesProductHaveLithiumBattery = (key, values) => values && has(values, key);

export const isLithiumBatteryObjectEmpty = (key, values) => doesProductHaveLithiumBattery(key, values) && isEmpty(values[key][FORM_FIELDS_LITHIUM_BATTERY.unNo.key])
&& isEmpty(values[key][FORM_FIELDS_LITHIUM_BATTERY.itemId.key]);

export const omitLithiumBatteryFields = (lithiumBattery) => omit(lithiumBattery, [
  `${FORM_FIELDS_LITHIUM_BATTERY.cargo.key}`,
  `${FORM_FIELDS_LITHIUM_BATTERY.passengerCargo.key}`,
  `${FORM_FIELDS_LITHIUM_BATTERY.ground.key}`,
  `${FORM_FIELDS_LITHIUM_BATTERY.packingGroup.key}`,
  'groundLabel',
  'isActive',
  'createdAt',
  'createdBy',
  'updatedAt',
  'updatedBy',
]);

export const getConvertedLithiumBatteryWeight = (key, values) => {
  const item = values[key];
  const weightUnit = get(item, FORM_FIELDS_LITHIUM_BATTERY.weightUnit.key, '');
  const weight = parseFloat(get(item, FORM_FIELDS_LITHIUM_BATTERY.weight.key, null));

  if (isEmpty(weightUnit)) return weight;

  return weightUnit.toUpperCase() === KG ? weight : weight * 0.45;
};

export const getLithiumBatterySumWeight = (values) => {
  const convertedContainedWeight = getConvertedLithiumBatteryWeight(LITHIUM_BATTERY_CONTAINED_KEY, values);
  const convertedPackedWeight = getConvertedLithiumBatteryWeight(LITHIUM_BATTERY_PACKED_KEY, values);
  const totalWeight = !isNaN(convertedPackedWeight) ? convertedContainedWeight + convertedPackedWeight : convertedContainedWeight;

  return totalWeight.toFixed(2);
};

export const splitWeightValue = (weight) => parseInt(weight.split(' ')[0], 10);

export const isRestrictedMaterial = (values) => has(values, FORM_FIELDS.isMaterialCheckRequired.key) && values[FORM_FIELDS.isMaterialCheckRequired.key];

export const removeNullValues = (obj) => Object.entries(obj).reduce((o, [key, value]) => (value == null ? o : (o[key] = value, o)), {});
