import React, { memo } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import { Field } from 'formik';
import {
  Grid, Typography, InputAdornment, MenuItem,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { loadHazmatClassTypeDetails, loadMaterials } from '../../../../utils/materialsClient';
import { CustomSearchWithDropdown } from '../../../common/InputComponents';
import CustomSelectTextField from '../../../common/CustomSelectTextField';
import NewOrderCustomTextField from '../../../common/NewOrderCustomTextField';
import {
  FORM_FIELDS, FORM_FIELDS_LITHIUM_BATTERY, FORM_LABELS, LITHIUM_BATTERY_CONTAINED_KEY, LITHIUM_BATTERY_PACKED_KEY,
} from '../../itemDetailsConstants';
import {
  doesProductHaveLithiumBattery, isFieldEditable, isRestrictedMaterial, localFilter,
} from '../../itemDetailsUtil';
import { useItemProductDetailsFormStyles } from '../../itemDetailsStyles';
import ProductImage from './Image';
import { HAZMAT_BATTERIES_CONTAINED_OR_PACKED } from '../../../../clientConstants';
import PanelWarning from '../../../common/PanelWarning';
import { useUserState } from '../../../../context/userContext';
import {
  isECO, isEHS,
} from '../../../../utils/helpers';

function ProductItem({
  formikProps,
  setEditableFields,
  editableFields,
  productCategories,
  customImage,
  setCustomImage,
  itemToEdit,
  itemFromCatalog,
  setItemFromCatalog,
  isCustomItem,
  isBatteryContained,
  setIsBatteryContained,
  setIsCustomItem,
  account,
}) {
  const [filteredItemNameOptions, setFilteredItemNameOptions] = React.useState([]);
  const [filteredIdOptions, setFilteredIdOptions] = React.useState([]);
  const [nameCache, setNameCache] = React.useState({});
  const [idCache, setIdCache] = React.useState({});
  const [originalLithiumBatteryWeight, setOriginalLithiumBatteryWeight] = React.useState(0);
  const [originalLithiumBatteryUnits, setOriginalLithiumBatteryUnits] = React.useState(0);
  const { itemName } = formikProps.values;
  const unitValue = get(formikProps.values, FORM_FIELDS.unitValue.key, '');
  const { userType } = useUserState();
  const eco = isECO(userType);
  const ehs = isEHS(userType);
  React.useEffect(() => {
    if (account === 'worldemblem') {
      updateNameSearchFilter('');
    }
  }, []);
  React.useEffect(() => {
    if (itemToEdit) {
      loadMaterials(itemToEdit.itemId, 'itemId', true).then((data) => {
        if (data && data.length === 1) {
          const item = data[0];

          const doesSearchedProductHasLithiumBattery = doesProductHaveLithiumBattery(LITHIUM_BATTERY_CONTAINED_KEY, item);
          const hasLithiumBattery = doesProductHaveLithiumBattery(LITHIUM_BATTERY_CONTAINED_KEY, itemToEdit);
          const obj = doesSearchedProductHasLithiumBattery ? item : itemToEdit;
          setIsBatteryContained(doesSearchedProductHasLithiumBattery);

          const formWeightKey = FORM_FIELDS_LITHIUM_BATTERY.weight.key;
          const noOfUnitsKey = FORM_FIELDS_LITHIUM_BATTERY.units.key;
          const parsedUnitValue = parseInt(get(itemToEdit, FORM_FIELDS.unitValue.key, ''), 10);

          const lithiumBatteryWeight = parseFloat(obj[LITHIUM_BATTERY_CONTAINED_KEY][formWeightKey]);
          const lithiumBatteryUnits = parseFloat(obj[LITHIUM_BATTERY_CONTAINED_KEY][noOfUnitsKey]);
          const containedWeight = parsedUnitValue * lithiumBatteryWeight;
          const containedUnits = parsedUnitValue + lithiumBatteryUnits;

          const unitsKey = FORM_FIELDS_LITHIUM_BATTERY.units.key;
          const weightKey = FORM_FIELDS_LITHIUM_BATTERY.weight.key;
          const itemToEditWeight = get(itemToEdit, `${LITHIUM_BATTERY_CONTAINED_KEY}.${weightKey}`, 0);
          const itemToEditUnits = get(itemToEdit, `${LITHIUM_BATTERY_CONTAINED_KEY}.${unitsKey}`, 0);

          if (hasLithiumBattery) {
            const containedLithium = itemToEdit[LITHIUM_BATTERY_CONTAINED_KEY];
            const batteryName = containedLithium[FORM_FIELDS_LITHIUM_BATTERY.materialName.key];
            const isPacked = doesSearchedProductHasLithiumBattery && hasLithiumBattery;
            const key = isPacked ? LITHIUM_BATTERY_PACKED_KEY : LITHIUM_BATTERY_CONTAINED_KEY;

            loadHazmatClassTypeDetails(
              HAZMAT_BATTERIES_CONTAINED_OR_PACKED,
              batteryName,
              '',
            ).then((batteryData) => {
              formikProps.setFieldValue(key, key === LITHIUM_BATTERY_CONTAINED_KEY ? {
                ...containedLithium,
                ...batteryData[0],
              } : {
                ...batteryData[0],
                [unitsKey]: (parseInt(itemToEditUnits, 10) - parseInt(containedUnits, 10)).toString(),
                [weightKey]: (parseFloat(itemToEditWeight) - parseFloat(containedWeight)).toFixed(2),
                [FORM_FIELDS_LITHIUM_BATTERY.weightUnit.key]: containedLithium[FORM_FIELDS_LITHIUM_BATTERY.weightUnit.key],
              });
            });
          }

          if (doesSearchedProductHasLithiumBattery) {
            formikProps.setFieldValue(LITHIUM_BATTERY_CONTAINED_KEY, {
              ...obj[LITHIUM_BATTERY_CONTAINED_KEY],
              [formWeightKey]: containedWeight.toString(),
              [noOfUnitsKey]: containedUnits.toString(),
            });
          }

          if (item.itemName === itemToEdit.itemName) {
            const editables = [];
            Object.keys(FORM_FIELDS).forEach(((key) => {
              const formKey = FORM_FIELDS[key].key;
              const value = get(item, FORM_FIELDS[key].key);
              if (!value || value === '' || ehs || eco || !item?.isDatabase || !item?.itemId) editables.push(formKey);
            }));
            setEditableFields(editables);
          }
        } else {
          const editables = [];
          Object.keys(FORM_FIELDS).forEach(((key) => {
            const formKey = FORM_FIELDS[key].key;
            editables.push(formKey);
          }));
          setEditableFields(editables);
        }
      });
    }
  }, [itemToEdit]);

  React.useEffect(() => {
    if (!itemName || itemName === '') {
      setIsCustomItem(false);
      setIsBatteryContained(false);
    }
  }, [itemName]);

  React.useEffect(() => {
    if (itemFromCatalog) {
      setObjectValues(itemFromCatalog);
      checkEmptyFields(itemFromCatalog);
    } else {
      formikProps.resetForm();
    }
  }, [itemFromCatalog]);

  React.useEffect(() => {
    if (isCustomItem && !itemFromCatalog) {
      checkEmptyFields();
    } else if (!isCustomItem && !itemFromCatalog) {
      setEditableFields([]);
    }
  }, [isCustomItem, itemFromCatalog]);

  React.useEffect(() => {
    const weightKey = FORM_FIELDS_LITHIUM_BATTERY.weight.key;
    const noOfUnitsKey = FORM_FIELDS_LITHIUM_BATTERY.units.key;
    const parsedUnitValue = parseInt(unitValue, 10);

    if (!isEmpty(unitValue) && isBatteryContained && isEmpty(itemToEdit)) {
      console.log('SUM OF WEIGHTS');
      const lithiumBatteryWeight = originalLithiumBatteryWeight === 0
        ? parseFloat(formikProps.values[LITHIUM_BATTERY_CONTAINED_KEY][weightKey])
        : originalLithiumBatteryWeight;
      const lithiumBatteryUnits = originalLithiumBatteryUnits === 0
        ? parseFloat(formikProps.values[LITHIUM_BATTERY_CONTAINED_KEY][noOfUnitsKey])
        : originalLithiumBatteryUnits;

      formikProps.setFieldValue(LITHIUM_BATTERY_CONTAINED_KEY, {
        ...formikProps.values[LITHIUM_BATTERY_CONTAINED_KEY],
        [weightKey]: (parsedUnitValue * lithiumBatteryWeight).toString(),
        [noOfUnitsKey]: (parsedUnitValue * lithiumBatteryUnits).toString(),
      });

      setOriginalLithiumBatteryWeight(lithiumBatteryWeight);
      setOriginalLithiumBatteryUnits(lithiumBatteryUnits);
    } else {
      formikProps.setFieldValue(LITHIUM_BATTERY_CONTAINED_KEY, {
        ...formikProps.values[LITHIUM_BATTERY_CONTAINED_KEY],
        [weightKey]: (originalLithiumBatteryWeight).toString(),
        [noOfUnitsKey]: (originalLithiumBatteryUnits).toString(),
      });
    }
  }, [unitValue, itemToEdit]);

  const onNameSearchSelect = async (val) => {
    const hasLithiumBattery = doesProductHaveLithiumBattery(LITHIUM_BATTERY_CONTAINED_KEY, val);
    setIsBatteryContained(hasLithiumBattery);

    if (hasLithiumBattery) {
      formikProps.setFieldValue(LITHIUM_BATTERY_CONTAINED_KEY, val[LITHIUM_BATTERY_CONTAINED_KEY]);
    }
    if (account === 'worldemblem') {
      const obj = filteredItemNameOptions.filter((v) => v.value === val)[0];
      onSelect(obj, setFilteredItemNameOptions);
    } else {
      onSelect(val, setFilteredItemNameOptions);
    }
  };

  const onIDSearchSelect = async (val) => {
    onSelect(val, setFilteredIdOptions);
  };

  const onSelect = async (val, setOptions) => {
    setItemFromCatalog(val);
    setIsCustomItem(false);
    if (account !== 'worldemblem') {
      setOptions([]);
    }
    await formikProps.validateForm();
  };

  const setObjectValues = (val) => {
    Object.keys(FORM_FIELDS).forEach(((key) => {
      const formKey = FORM_FIELDS[key].key;
      const value = get(val, FORM_FIELDS[key].key);

      value && formikProps.setFieldValue(formKey, value);
    }));
  };

  function checkEmptyFields(val) {
    const editables = [];
    Object.keys(FORM_FIELDS).forEach((key) => {
      const formikKey = FORM_FIELDS[key]?.key;
      const isEditable = !!FORM_FIELDS[key]?.editable;
      const value = get(val, formikKey);
      if (isNil(value) || value === '' || isEditable) {
        formikProps.setFieldTouched(formikKey);
        editables.push(formikKey);
      }
    });
    setEditableFields(editables);
  }

  const updateNameSearchFilter = (text) => {
    updateFilter(text, setFilteredItemNameOptions, nameCache, setNameCache, FORM_FIELDS.itemName.key);
  };

  const updateIDSearchFilter = (text) => {
    updateFilter(text, setFilteredIdOptions, idCache, setIdCache, FORM_FIELDS.itemId.key);
  };

  const updateFilter = (text, setOptions, cache, setCache, queryType) => {
    setItemFromCatalog(null);

    if (!isCustomItem && text) setIsCustomItem(true);
    if (account === 'worldemblem') {
      if (cache.worldemblem) {
        setOptions(cache.worldemblem);
        return;
      }
      loadMaterials(text, queryType, true).then((data) => {
        const dropdownList = data ? data.map((o) => ({
          ...o,
          value: o[queryType],
          label: o[queryType],
        })) : [];
        setOptions(dropdownList);
        setCache((prev) => ({ ...prev, worldemblem: dropdownList }));
      });
    } else {
      if (!text || text.length < 3) return setOptions([]);
      const cacheKey = text.slice(0, 3);
      if (cache[cacheKey]) {
        setOptions(cache[cacheKey].filter(localFilter(text)));
        return;
      }

      loadMaterials(text, queryType, true).then((data) => {
        const dropdownList = data ? data.map((o) => ({
          ...o,
          value: o[queryType],
          label: o[queryType],
        })) : [];
        setOptions(dropdownList);
        setCache((prev) => ({ ...prev, [text]: dropdownList }));
      });
    }
  };

  const classes = useItemProductDetailsFormStyles();

  const { itemUrl } = formikProps.values;

  return (
    <Grid item container direction="column" spacing={1}>
      <Grid item>
        <Typography color="primary" className={classes.formFieldHeader}>{FORM_LABELS.product}</Typography>
      </Grid>
      <Grid item container direction="column" spacing={1}>
        <Grid item container spacing={1}>
          <ProductImage
            disabled={!isCustomItem && !itemFromCatalog}
            itemUrl={itemUrl}
            itemFromCatalog={itemFromCatalog}
            formikProps={formikProps}
            customImage={customImage}
            setCustomImage={setCustomImage}
          />
        </Grid>
        <Grid item container spacing={1}>
          <Grid item xs={5}>
            {account === 'worldemblem'
              ? (
                <Field
                  component={CustomSelectTextField}
                  style={{ width: '100%' }}
                  type="text"
                  value={formikProps.values.itemName}
                  name={FORM_FIELDS.itemName.key}
                  label={FORM_FIELDS.itemName.label}
                  className={classes.standardFormTextfield}
                  data-testid="prod_name-input"
                  customOnChange={(e) => {
                    onNameSearchSelect(e.target.value);
                  }}
                >
                  {filteredItemNameOptions.map((type) => (
                    <MenuItem
                      key={type.label}
                      value={type.value}
                      data-testid="prod_name-menu-item"
                    >
                      {type.label}
                    </MenuItem>
                  ))}
                </Field>
              )
              : (
                <Field
                  name={FORM_FIELDS.itemName.key}
                  component={CustomSearchWithDropdown}
                  label={FORM_FIELDS.itemName.label}
                  onSelect={onNameSearchSelect}
                  callback={updateNameSearchFilter}
                  dropdownList={filteredItemNameOptions}
                  inputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon color="primary" />
                      </InputAdornment>
                    ),
                    inputProps: { autocomplete: 'off' },
                  }}
                />
              )}
          </Grid>
          <Grid item xs={5}>
            <Field
              name={FORM_FIELDS.itemId.key}
              label={FORM_FIELDS.itemId.label}
              component={CustomSearchWithDropdown}
              type="text"
              style={{ width: '100%' }}
              onSelect={onIDSearchSelect}
              callback={updateIDSearchFilter}
              dropdownList={filteredIdOptions}
              inputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon color="primary" />
                  </InputAdornment>
                ),
                inputProps: { autocomplete: 'off' },
              }}
              disabled={!!itemToEdit}
            />
          </Grid>
        </Grid>
        <Grid item container spacing={1}>
          <Grid item xs={4}>
            <Field
              name={FORM_FIELDS.itemCategory.key}
              label={FORM_FIELDS.itemCategory.label}
              component={CustomSelectTextField}
              type="text"
              style={{ width: '100%' }}
              disabled={isFieldEditable(FORM_FIELDS.itemCategory.key, editableFields)}
            >
              {isFieldEditable(FORM_FIELDS.itemCategory.key, editableFields) ? (
                <MenuItem
                  key={formikProps.values.itemCategory}
                  value={formikProps.values.itemCategory}
                >
                  {formikProps.values.itemCategory}
                </MenuItem>
              )
                : productCategories.map((option) => (
                  <MenuItem
                    key={option.type}
                    value={option.type}
                  >
                    {option.description}
                  </MenuItem>
                ))}
            </Field>
          </Grid>
          <Grid item xs={4}>
            <Field
              name={FORM_FIELDS.sku.key}
              label={FORM_FIELDS.sku.label}
              component={NewOrderCustomTextField}
              type="text"
              style={{ width: '100%' }}
              disabled={isFieldEditable(FORM_FIELDS.sku.key, editableFields)}
            />
          </Grid>
          <Grid item xs={4}>
            <Field
              name={FORM_FIELDS.binNumber.key}
              label={FORM_FIELDS.binNumber.label}
              component={NewOrderCustomTextField}
              type="text"
              style={{ width: '100%' }}
              disabled={isFieldEditable(FORM_FIELDS.binNumber.key, editableFields)}
            />
          </Grid>
        </Grid>
      </Grid>
      {/* {isRestrictedMaterial(formikProps.values) && (
        <Grid item>
          <PanelWarning message="Restricted material - Requires ECO Approval." />
        </Grid>
      )} */}
    </Grid>
  );
}

ProductItem.propTypes = {
  editableFields: PropTypes.arrayOf(PropTypes.string).isRequired,
  formikProps: PropTypes.objectOf(PropTypes.any).isRequired,
  setEditableFields: PropTypes.func.isRequired,
  productCategories: PropTypes.arrayOf(PropTypes.any).isRequired,
  customImage: PropTypes.string.isRequired,
  setCustomImage: PropTypes.func.isRequired,
  itemFromCatalog: PropTypes.objectOf(PropTypes.any).isRequired,
  setItemFromCatalog: PropTypes.func.isRequired,
  isCustomItem: PropTypes.bool.isRequired,
  setIsCustomItem: PropTypes.func.isRequired,
  setIsBatteryContained: PropTypes.func.isRequired,
  itemToEdit: PropTypes.shape({
    itemId: PropTypes.string,
    itemName: PropTypes.string,
  }),
  account: PropTypes.string.isRequired,
};

ProductItem.defaultProps = {
  itemToEdit: null,
};

export default memo(ProductItem);
