import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
import {
  CircularProgress, MenuItem, Grid, Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Field } from 'formik';
import { PhoneNumberFormik } from './PhoneNumber';
import { CustomCheckbox } from './InputComponents';
import CustomSelectTextField from './CustomSelectTextField';
import NewOrderCustomTextField from './NewOrderCustomTextField';
import * as colors from '../../styles/colors';
import { isAdmin, isECO, isEHS } from '../../utils/helpers';
import {
  ECO,
  EHS,
  ESG_ADMIN,
  FIELD_TYPE_BOOL,
  FIELD_TYPE_DROPDOWN,
  FIELD_TYPE_EMAIL,
  FIELD_TYPE_LINK,
  FIELD_TYPE_PHONE,
  FIELD_TYPE_TEXT,
  USER_PROFILE_GROUP_TYPE_FIELD,
  USER_TYPE_MAPPING,
} from '../../clientConstants';
import { loadGroupTypes } from '../../utils/accountClient';

const useStyles = makeStyles({
  formItem: {
    marginBottom: '24px',
  },
  inputField: {
    width: '100%',
  },
  loading: {
    marginBottom: '24px',
  },
});

const useCustomCheckboxStyles = makeStyles({
  root: {
    '&$focused': {
      color: colors.white,
    },
    '&$disabled': {
      color: colors.textDarkGrey,
    },
    '&$error': {
      color: colors.white,
    },
    color: colors.white,
    fontWeight: 425,
  },
  checkboxLabel: {
    color: colors.textLightGrey,
    fontSize: 14.75,
    fontWeight: 425,
  },
  checkboxPrimary: {
    '&$disabled': {
      color: colors.white,
    },
    color: colors.white,
  },
  disabled: {},
});

const ProfileManagementFormFields = ({
  loggedUserType,
  fields,
  values,
  timeZones,
  userTypes,
  isLoading,
  isSubmitting,
  isUserECOGroup,
  isUserEHSGroup,
}) => {
  const classes = useStyles();
  const customCheckboxStyles = useCustomCheckboxStyles();
  const [groupTypes, setGroupTypes] = useState([]);
  const [hideCheckbox, setHideCheckbox] = useState(false);

  useEffect(() => {
    if ((values.userType === ECO && values.isDefaultECO)
    || (values.userType === EHS && values.isDefaultEHS)) {
      setHideCheckbox(true);
    }
  }, []);

  useEffect(() => {
    if (isAdmin(loggedUserType) && (isEHS(values.userType) || isECO(values.userType))) {
      loadGroupTypes(values.userType).then((response) => {
        if (!isEmpty(response)) setGroupTypes(response);
      }).catch((e) => console.log('Error getting group types', e));
    }
  }, [values.userType]);

  const isBoolFieldType = (type) => type === FIELD_TYPE_BOOL;
  const isPhoneFieldType = (type) => type === FIELD_TYPE_PHONE;
  const isDropdownFieldType = (type) => type === FIELD_TYPE_DROPDOWN;

  const renderMenuItem = (userType) => (
    <MenuItem value={userType.type}>
      {userType.description}
    </MenuItem>
  );

  return (
    <>
      {isLoading
        ? (
          <Grid
            className={classes.loading}
            justify="center"
            alignItems="center"
            container
            item
          >
            <CircularProgress />
          </Grid>
        )
        : fields.map((field) => (
          <Grid className={classes.formItem} item key={`field-grid-${field.name}`}>
            {isBoolFieldType(field.type) && (
              <CustomCheckbox
                name={field.name}
                label={field.label}
                disabled={isSubmitting}
                classes={
                  customCheckboxStyles
                }
              />
            )}
            {isPhoneFieldType(field.type) && (
              <PhoneNumberFormik
                name={field.name}
                label={field.label}
                countryName={field.countryName}
              />
            )}
            {isDropdownFieldType(field.type) && field.name === 'timeZone' && (
              <Field
                component={CustomSelectTextField}
                name={field.name}
                type="text"
                label={field.label}
                disabled={isSubmitting}
                className={classes.inputField}
                InputLabelProps={{ for: field.name }}
                InputProps={{ id: field.name }}
              >
                {
                  !isEmpty(timeZones) && has(values, 'timeZone') && [values.timeZone, ...timeZones].map((timeZone) => (
                    <MenuItem value={timeZone}>
                      {timeZone.text}
                    </MenuItem>
                  ))
                }
              </Field>
            )}
            {isDropdownFieldType(field.type) && field.name === 'userType' && isAdmin(loggedUserType)
              && (
                <Field
                  component={CustomSelectTextField}
                  name={field.name}
                  type="text"
                  label={field.label}
                  disabled={isSubmitting}
                  className={classes.inputField}
                  InputLabelProps={{ for: field.name }}
                  InputProps={{ id: field.name }}
                >
                  {values.userType === ESG_ADMIN
                    ? userTypes.concat(
                      {
                        type: ESG_ADMIN,
                        description: USER_TYPE_MAPPING[ESG_ADMIN],
                      },
                    ).map(renderMenuItem)
                    : userTypes.map(renderMenuItem)}
                </Field>
              )}
            {isDropdownFieldType(field.type) && isAdmin(loggedUserType) && field.name === USER_PROFILE_GROUP_TYPE_FIELD
              && ((isEHS(values.userType) && isUserEHSGroup) || (isECO(values.userType) && isUserECOGroup))
              && (
                <Field
                  component={CustomSelectTextField}
                  name={field.name}
                  type="text"
                  label={field.label}
                  disabled={isSubmitting}
                  className={classes.inputField}
                  InputLabelProps={{ for: field.name }}
                  InputProps={{ id: field.name }}
                >
                  {!isEmpty(groupTypes) && groupTypes.map((groupType) => (
                    <MenuItem value={groupType.type}>
                      {groupType.description}
                    </MenuItem>
                  ))}
                </Field>
              )}
            {!isBoolFieldType(field.type) && !isDropdownFieldType(field.type) && !isPhoneFieldType(field.type) && (
              <Field
                className={classes.inputField}
                disabled={isSubmitting}
                type="text"
                name={field.name}
                label={field.label}
                component={NewOrderCustomTextField}
                InputLabelProps={{ for: field.name }}
                InputProps={{ id: field.name }}
              />
            )}
          </Grid>
        ))}
      {/*
        This field will be automatically shown if userType field is present and when its value
        is ECO or EHS
      */}
      <Grid className={classes.formItem} item>
        {!hideCheckbox && (values.userType === ECO || values.userType === EHS) && (
          <CustomCheckbox
            name={`isDefault${values.userType.toUpperCase()}`}
            label={`Set user as ${values.userType.toUpperCase()} default.`}
            disabled={isSubmitting}
            classes={
                customCheckboxStyles
              }
          />
        )}
        {((values.userType === ECO && values.isDefaultECO) || (values.userType === EHS && values.isDefaultEHS)) && hideCheckbox && (
          <Typography className={customCheckboxStyles.checkboxLabel}>
            This user is default
            {' '}
            {values.userType.toUpperCase()}
          </Typography>
        )}
      </Grid>
    </>
  );
};

ProfileManagementFormFields.defaultProps = {
  timeZones: [],
  userTypes: [],
  isLoading: true,
};

ProfileManagementFormFields.propTypes = {
  loggedUserType: PropTypes.string.isRequired,
  fields: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.oneOf([
      FIELD_TYPE_TEXT,
      FIELD_TYPE_DROPDOWN,
      FIELD_TYPE_BOOL,
      FIELD_TYPE_PHONE,
      FIELD_TYPE_LINK,
      FIELD_TYPE_EMAIL,
    ]),
  })).isRequired,
  values: PropTypes.shape(PropTypes.object).isRequired,
  timeZones: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
  })),
  userTypes: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string,
    description: PropTypes.string,
  })),
  isLoading: PropTypes.bool,
  isSubmitting: PropTypes.bool.isRequired,
  isUserECOGroup: PropTypes.bool.isRequired,
  isUserEHSGroup: PropTypes.bool.isRequired,
};

export default memo(ProfileManagementFormFields);
