import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import {
  Grid, Typography, MenuItem,
} from '@material-ui/core';
import CustomSelectTextField from '../../../common/CustomSelectTextField';
import NewOrderCustomTextField from '../../../common/NewOrderCustomTextField';
import { FORM_FIELDS, FORM_LABELS } from '../../itemDetailsConstants';
import { isFieldEditable } from '../../itemDetailsUtil';
import { useItemProductDetailsFormStyles } from '../../itemDetailsStyles';

export default function LicensePermitNumber({
  editableFields, licensePermitTypes,
}) {
  const classes = useItemProductDetailsFormStyles();
  return (
    <Grid item container direction="column" spacing={1}>
      <Grid item>
        <Typography color="primary" className={classes.formFieldHeader}>
          {FORM_LABELS.licencePermitNumber}
        </Typography>
      </Grid>
      <Grid item container spacing={1}>

        <Grid item xs={5}>
          <Field
            name={FORM_FIELDS.licenseType.key}
            label={FORM_FIELDS.licenseType.label}
            component={CustomSelectTextField}
            type="text"
            style={{ width: '100%' }}
            disabled={isFieldEditable(FORM_FIELDS.licenseType.key, editableFields)}
          >
            {
                (Array.isArray(licensePermitTypes) && licensePermitTypes.length > 0)
                  ? licensePermitTypes.map((x) => (
                    <MenuItem
                      key={`license-type-menu-item-${x.value}`}
                      value={x.value}
                    >
                      {x.label}
                    </MenuItem>
                  ))
                  : (
                    <MenuItem
                      key="license-type-menu-item-no-options"
                      value=""
                    >
                      No options available
                    </MenuItem>
                  )
              }
          </Field>
        </Grid>

        <Grid item xs={5}>
          <Field
            name={FORM_FIELDS.licenseNumber.key}
            label={FORM_FIELDS.licenseNumber.label}
            component={NewOrderCustomTextField}
            type="text"
            style={{ width: '100%' }}
            disabled={isFieldEditable(FORM_FIELDS.licenseNumber.key, editableFields)}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

LicensePermitNumber.propTypes = {
  editableFields: PropTypes.arrayOf(PropTypes.string).isRequired,
  licensePermitTypes: PropTypes.arrayOf(PropTypes.any).isRequired,
};
