// Gets the application configuration
const getAppConfig = () => {
  window.BrowserPrint.getApplicationConfiguration((success) => {
    console.log(success);
  }, (err) => {
    console.log(err);
  });
};

// Discover all of the devices that are available to the host system
const getDeviceList = () => {
  window.BrowserPrint.getLocalDevices((deviceList) => {
    console.log('Devices present in your network are: ', deviceList);
    return deviceList;
  }, (err) => {
    console.log(err);
  });
};

// Gets the user specified default device by type; to avoid having to run a full discovery on every page load
const defaultDevices = () => {
  window.BrowserPrint.getDefaultDevice('printer',
    (device) => { console.log(device); },
    (error) => { console.log(error); });
};
const getConfig = (zebraPrinter) => {
  zebraPrinter.getConfiguration((response) => {
    console.log('Configs are: ', response);
  }, (error) => {
    console.error('***Error**** ', error);
  });
};
const getStatus = (zebraPrinter) => {
  zebraPrinter.getConfiguration((status) => {
    console.log(status.getMessage());
  }, (error) => {
    console.error('***Error**** ', error);
  });
};
// eslint-disable-next-line no-underscore-dangle
const print = (device, data = '~wc') => {
  device.send(data,
    (success) => { console.log('Sent to printer'); },
    (error) => { console.error(`Error:${error}`); });
  /*  var zebraPrinter = new Zebra.Printer(device);
     zebraPrinter.isPrinterReady().then(function () {
       return zebraPrinter.write("~wc");
     }).catch(function (error) {
       console.error(error);
     }) */
};

const printUtil = {
  getAppConfig,
  getDevices: getDeviceList,
  defaultDevices,
  getConfig,
  getStatus,
  print,
};
export default printUtil;
