import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, DialogActions, DialogContent, DialogContentText,
  Grid, Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as colors from '../../styles/colors';
import NewOrderNextButtonClear from '../common/NewOrderNextButtonClear';
import NewOrderNextButton from '../common/NewOrderNextButton';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: colors.mediumBlueBackground,
    overflowY: 'initial',
    borderRadius: 10,
  },
  dialogContentText: {
    color: colors.white,
  },
  actionsContainer: {
    paddingBottom: 24,
  },
  clearButton: {
    marginRight: 10,
  },
}));

const CargoDialog = ({
  open, classType, maxWeightPassenger, onContinue, onCancel,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      classes={{ paper: classes.root }}
      open={open}
      onClose={onCancel}
    >
      <DialogContent>
        <DialogContentText component="div">
          <Grid item container spacing={1} direction="column">
            <Grid item>
              <Typography className={classes.dialogContentText}>
                Item class
                {' '}
                {classType}
                {' '}
                you are about to ship has a limitation of
                {' '}
                {maxWeightPassenger}
                {' '}
                L. Do you want to ship as cargo instead?
              </Typography>
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions classes={{ root: classes.actionsContainer }}>
        <Grid container justify="flex-end">
          <Grid item>
            <NewOrderNextButtonClear className={classes.clearButton} onClick={onCancel}>Cancel</NewOrderNextButtonClear>
            <NewOrderNextButton onClick={onContinue}>Continue</NewOrderNextButton>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

CargoDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  classType: PropTypes.string.isRequired,
  maxWeightPassenger: PropTypes.string.isRequired,
  onContinue: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default memo(CargoDialog);
