import axios from 'axios';
import { SESSION_EXPIRED } from '../clientConstants';

function client(endpoint, customConfig = {}) {
  const headers = {
    'content-type': 'application/json',
  };
  const config = {
    url: endpoint,
    method: customConfig.method
      || customConfig.data ? 'post' : 'get',
    mode: 'no-cors',
    headers: {
      ...headers,
      ...customConfig.headers,
    },
    ...customConfig,
  };

  return axios(config)
    .then((r) => r.data)
    .catch((e) => {
      if (['development', 'test'].includes(process.env.NODE_ENV)) {
        console.log(`Error making call to ${endpoint}`);
      }

      // Throw customized error message when 401
      if (e.response && e.response.status === 401) {
        throw new Error(SESSION_EXPIRED);
      }

      const { data } = e.response;

      const errorToThrow = (typeof data === 'string')
        ? new Error(JSON.stringify({ error: 'No connection to internal server' }))
        : new Error(JSON.stringify(e.response.data));

      throw errorToThrow;
    });
}

export default client;
