import React, { memo } from 'react';
import PropTypes from 'prop-types';
import RadioGroup from '@material-ui/core/RadioGroup';

function ControlledRadioGroup(props) {
  const { field, form, ...other } = props;
  const { name, value } = field;
  const { setFieldValue } = form;

  return (
    <RadioGroup
      name={name}
      value={value}
      onChange={(e, v) => {
        setFieldValue(name, v);
      }}
      {...other}
    />
  );
}

ControlledRadioGroup.propTypes = {
  field: PropTypes.objectOf(PropTypes.any).isRequired,
  form: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default memo(ControlledRadioGroup);
