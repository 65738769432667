import React, { memo } from 'react';
import PropTypes from 'prop-types';
import has from 'lodash/has';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core/';
import IconButton from '@material-ui/core/IconButton';
import ViewIcon from '@material-ui/icons/BallotRounded';
import AcceptIcon from '@material-ui/icons/CheckRounded';
import RejectIcon from '@material-ui/icons/CloseRounded';
import TakeoverIcon from '@material-ui/icons/ThreeSixtyRounded';

import Tooltip from '@material-ui/core/Tooltip';
import { Route } from 'react-router-dom';
import moment from 'moment';
import CircularProgress from '@material-ui/core/CircularProgress';
import { formatDateLong, formatDateShort } from '../utils/helpers';
import * as colors from '../styles/colors';
import taskClient from '../utils/taskClient';
import { EHS, EXPORT_COMPLIANCE } from '../clientConstants';

const ACCEPT_ACTION_KEY = 'accept';
const REJECT_ACTION_KEY = 'reject';
const DOCUMENT_TASK_TYPE = 'document';
const SMALL_TYPE = 'sm';
const LARGE_TYPE = 'lg';

const useStyles = makeStyles((theme) => ({
  tile: {
    width: '100%',
    backgroundColor: colors.shipmentCardBackground,
    padding: '13px 15px 8px 15px',
    borderRadius: '0 10px 10px 0',
    minHeight: '96px',
    marginBottom: '10px',
    position: 'relative',
  },
  tileInner: {
    paddingLeft: '7px',
  },
  tileHeading: {
    color: '#B5B9CC',
    fontSize: '12px',
    fontWeight: 500,
  },
  tileHeaderSpacing: {
    letterSpacing: '1px',
  },
  statusHeading: {
    fontSize: '12px',
    textAlign: 'right',
    fontWeight: 500,
    letterSpacing: 1.4,
    textTransform: 'uppercase',
  },
  statusWrap: {
    whiteSpace: 'noWrap',
  },
  middleHeading: {
    color: 'white',
    fontSize: '16px',
    fontWeight: 500,
    textTransform: 'capitalize',
  },
  bottomHeading: {
    color: '#B5B9CC',
    fontSize: '14px',
  },
  columnText: {
    color: 'white',
    fontSize: '16px',
    fontWeight: 400,
  },
  newBorder: {
    borderColor: ' #4da8f7',
  },
  inProgressBorder: {
    borderColor: ' #eef4a7',
  },
  completedBorder: {
    borderColor: colors.lightBackgroundGrey,
  },
  tileBorder: {
    borderWidth: '0 0 0 5px',
    borderStyle: 'solid',
  },
  inProgress: {
    color: '#eef4a7',
  },
  new: {
    color: '#4da8f7',
  },
  completed: {
    color: colors.lightBackgroundGrey,
  },
  taskButton: {
    padding: '10px 10px',
  },
  borderedTaskButton: {
    fontWeight: 'bold',
    padding: '10px 10px',
    color: colors.secondaryButton,
    borderRadius: '8px',
    backgroundColor: colors.shipmentCardBackground,
    border: `1px solid ${colors.buttonBackgroundBlue}`,
    boxShadow: 'none',
    marginLeft: '10px',
    '&:hover': {
      backgroundColor: colors.mediumBlueBackground,
    },
  },
  taskButtonText: {
    color: colors.secondaryButton,
    fontWeight: 700,
    fontSize: '14px',
    letterSpacing: 1.1,
  },
  dueDate: {
    fontWeight: 700,
  },
  reason: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    padding: '2px 4px',
    color: colors.errorRed,
  },
  reasonSmall: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    padding: '1px 2px',
    color: colors.errorRed,
    fontSize: '12px',
  },
}));

const getStatusClass = (status) => {
  const statusMap = {
    'IN-PROGRESS': {
      borderClass: 'inProgressBorder',
      statusClass: 'inProgress',
      viewButtonText: 'view task',
      dueDate: true,
    },
    CREATED: {
      borderClass: 'newBorder', statusClass: 'new', dueDate: true, statusText: 'new',
    },
    COMPLETED: {
      borderClass: 'completedBorder',
      statusClass: 'completed',
      viewButtonText: 'view shipment',
    },
  };

  return statusMap[status.toUpperCase()] || {};
};

const TaskCard = ({
  type, task, lastCardRef, onRefreshTasks, userType, onError,
}) => {
  const classes = useStyles();
  const [acceptingRejecting, setAcceptingRejecting] = React.useState(false);
  const statusObject = getStatusClass(task.status);
  const statusClass = [
    classes.statusHeading,
    classes[statusObject.statusClass],
  ].join(' ');
  const tileClass = [
    classes.tile,
    classes.tileBorder,
    classes[statusObject.borderClass],
  ].join(' ');
  const dueDateClass = [
    classes.dueDate,
    classes[statusObject.statusClass],
  ].join(' ');
  const isTaskExpired = moment(task.expiresAt).isBefore(Date.now());
  const isSmallType = type === SMALL_TYPE;

  const setError = (err) => {
    if (has(err, 'error') || has(err, 'message')) {
      onError(err.error || err.message);
    }
  };

  const acceptOrRejectTask = async (actionKey, takeover = false) => {
    const {
      shipmentId, taskType, documentType, packageId, documentTaskType, documentId,
    } = task;
    const payload = taskType === DOCUMENT_TASK_TYPE ? {
      shipmentId, taskType, documentType, packageId, documentTaskType, documentId,
    } : { shipmentId, taskType };

    try {
      setAcceptingRejecting(true);
      if (takeover) {
        payload.isTakeover = true;
      }

      const { tasks, error } = actionKey === ACCEPT_ACTION_KEY
        ? await taskClient.acceptTask(payload)
        : await taskClient.rejectTask(payload);

      setAcceptingRejecting(false);

      if (error) {
        setError({ error });
      }

      if (tasks) onRefreshTasks(tasks);
    } catch (err) {
      console.log(err);

      setError(err);
    }
  };

  const viewTask = (history, status) => {
    if (status.toUpperCase() === 'COMPLETED') {
      history.push(`/order/${task.shipmentId}`);
    } else {
      history.push(`/order/${task.shipmentId}?panel=${task.taskType}`);
    }
  };

  const displayTaskTitle = (t) => `${t.taskName || t.taskType || ''}`;
  const getReason = (reason) => {
    if (reason?.length > 0) {
      return (
        <>
          {isSmallType && (
          <Typography
            className={`${classes.tileHeading} ${classes.tileHeaderSpacing}`}
          >
            Reason:
          </Typography>
          )}
          {reason.map((r) => (
            <Typography
              className={isSmallType ? classes.reasonSmall : classes.reason}
              key={r}
            >
              {r}
            </Typography>
          ))}
        </>
      );
    }
    return '';
  };
  return (
    <div className={tileClass} ref={lastCardRef}>
      <Grid container className={classes.taskWrap} direction="column">
        <Grid item container className={classes.tileInner} spacing={2}>
          <Grid item xs={isSmallType ? 12 : 4}>
            <Grid item xs={12} container justify="space-between">
              <Typography className={classes.tileHeading}>
                {`${task.shipmentId || task.orderId}`}
              </Typography>
              {isSmallType && (
                <Grid item className={classes.statusWrap}>
                  <Typography className={statusClass}>
                    {statusObject.statusText || task.status}
                  </Typography>
                </Grid>
              )}
            </Grid>
            {isSmallType && (
              <Grid item xs={12}>
                <Typography className={classes.middleHeading}>
                  {displayTaskTitle(task)}
                </Typography>
              </Grid>
            )}
            <Grid item container direction="column" xs={12}>
              <Grid item>
                <Typography
                  className={
                    isSmallType ? classes.bottomHeading : classes.columnText
                  }
                >
                  {`Assigned by ${task.createdBy} on ${formatDateLong(
                    task.createdAt,
                  )}`}
                </Typography>
              </Grid>
              {statusObject.dueDate && (
                <Grid item>
                  <Typography
                    className={
                      isSmallType ? classes.bottomHeading : classes.columnText
                    }
                  >
                    {'Due:'}
                    {' '}
                    <span className={dueDateClass}>
                      {`${formatDateShort(task.expiresAt)} (${moment(
                        task.expiresAt,
                      ).fromNow(!isTaskExpired)}${
                        isTaskExpired ? '' : ' from now'
                      })`}
                    </span>
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
          {!isSmallType && (
            <Grid item xs={isSmallType ? 12 : 2}>
              <Typography
                className={`${classes.tileHeading} ${classes.tileHeaderSpacing}`}
              >
                TASK TYPE
              </Typography>
              <Typography className={classes.columnText}>
                {task.taskName}
              </Typography>
            </Grid>
          )}
          {!isSmallType && (
            <Grid item xs={isSmallType ? 12 : 1}>
              <Typography
                className={`${classes.tileHeading} ${classes.tileHeaderSpacing}`}
              >
                STATUS
              </Typography>
              <Typography className={classes.columnText}>
                {task.status}
              </Typography>
            </Grid>
          )}
          {
            <Grid item xs={isSmallType ? 12 : 3}>
              {!isSmallType && (
                <Typography
                  className={`${classes.tileHeading} ${classes.tileHeaderSpacing}`}
                >
                  { task?.reason ? 'REASON' : ''}
                </Typography>
              )}
              <Typography
                className={
                  isSmallType ? classes.bottomHeading : classes.columnText
                }
              >
                {getReason(task?.reason)}
              </Typography>
            </Grid>
          }
          <Grid item xs={isSmallType ? 12 : 2}>
            {task.status.toUpperCase() === 'CREATED' && (
              <Grid
                item
                container
                alignItems={isSmallType ? 'left' : 'center'}
                style={{ height: '100%' }}
              >
                {acceptingRejecting ? (
                  <Grid
                    item
                    container
                    alignItems="center"
                    justify="center"
                    style={{ width: '100%', marginTop: 8 }}
                  >
                    <CircularProgress color="secondary" />
                  </Grid>
                ) : (
                  <Grid item container>
                    <Tooltip title="Accept Task" placement="top">
                      <IconButton
                        className={
                          isSmallType
                            ? classes.taskButton
                            : classes.borderedTaskButton
                        }
                        onClick={() => acceptOrRejectTask(ACCEPT_ACTION_KEY)}
                      >
                        <AcceptIcon />
                        {/* <Typography className={classes.taskButtonText}>ACCEPT</Typography> */}
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Reject Task" placement="top">
                      <IconButton
                        className={
                          isSmallType
                            ? classes.taskButton
                            : classes.borderedTaskButton
                        }
                        onClick={() => acceptOrRejectTask(REJECT_ACTION_KEY)}
                      >
                        <RejectIcon />
                        {/* <Typography className={classes.taskButtonText}>REJECT</Typography> */}
                      </IconButton>
                    </Tooltip>
                    {userType === EHS && task.taskType === EXPORT_COMPLIANCE && (
                      <Tooltip title="Takeover Shipment" placement="top">
                        <IconButton
                          className={
                            isSmallType
                              ? classes.taskButton
                              : classes.borderedTaskButton
                          }
                          onClick={() => acceptOrRejectTask(ACCEPT_ACTION_KEY, true)}
                        >
                          <TakeoverIcon />
                          {/* <Typography className={classes.taskButtonText}>{statusObject.viewButtonText}</Typography> */}
                        </IconButton>
                      </Tooltip>
                    )}
                  </Grid>
                )}
              </Grid>
            )}
            {statusObject.viewButtonText && (
              <Grid
                item
                container
                alignItems={isSmallType ? 'left' : 'center'}
                style={{ height: '100%' }}
              >
                <Route
                  render={({ history }) => (
                    <Tooltip
                      title={
                        task.status.toUpperCase() === 'COMPLETED'
                          ? 'View Shipment'
                          : 'View Task'
                      }
                      placement="top"
                    >
                      <IconButton
                        className={
                          isSmallType
                            ? classes.taskButton
                            : classes.borderedTaskButton
                        }
                        onClick={() => viewTask(history, task.status)}
                      >
                        <ViewIcon />
                        {/* <Typography className={classes.taskButtonText}>{statusObject.viewButtonText}</Typography> */}
                      </IconButton>
                    </Tooltip>
                  )}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

TaskCard.defaultProps = {
  type: SMALL_TYPE,
  lastCardRef: null,
};

TaskCard.propTypes = {
  type: PropTypes.oneOf([SMALL_TYPE, LARGE_TYPE]),
  task: PropTypes.shape({
    createdAt: PropTypes.string,
    orderId: PropTypes.string,
    createdBy: PropTypes.string,
    status: PropTypes.string,
    shipmentId: PropTypes.string,
    taskType: PropTypes.string,
    taskName: PropTypes.string,
    documentType: PropTypes.string,
    packageId: PropTypes.string,
    documentTaskType: PropTypes.string,
    documentId: PropTypes.string,
    expiresAt: PropTypes.string,
  }).isRequired,
  lastCardRef: PropTypes.objectOf(PropTypes.any),
  onRefreshTasks: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  userType: PropTypes.func.isRequired,
};

export default memo(TaskCard);
