import React from 'react';
import PropTypes from 'prop-types';
import SenderTabs from './SenderTabs';
import NewOrderShipmentModule from '../common/NewOrderShipmentModule';
import BlockedPanelDisplay from '../common/PanelWarning';

const getLabelDetails = (shipment) => {
  const address = shipment?.origin?.address;
  if (!address) return '';
  const {
    name, addressLine1, city, state, zip, country,
  } = address;
  return `${name} | ${addressLine1} | ${city} | ${state} | ${zip} | ${country}`;
};

const SenderPane = ({
  shipment,
  isReadyToBeCompleted,
  blockingMessage,
  ...shipmentProps
}) => (
  <NewOrderShipmentModule
    labelDetails={getLabelDetails(shipment)}
    shipment={shipment}
    preReqsComplete={isReadyToBeCompleted}
    blockingMessage={blockingMessage}
    {...shipmentProps}
  >
    {(isReadyToBeCompleted) ? ((renderProps) => (
      <SenderTabs
        shipment={shipment}
        isReadyToBeCompleted={isReadyToBeCompleted}
        {...shipmentProps}
        {...renderProps}
      />
    )) : (
      <BlockedPanelDisplay message={blockingMessage} />
    )}
  </NewOrderShipmentModule>
);

SenderPane.propTypes = {
  shipment: PropTypes.shape().isRequired,
  isReadyToBeCompleted: PropTypes.bool.isRequired,
};

export default SenderPane;
