import React from 'react';
import {
  SvgIcon,
} from '@material-ui/core';

const RadioactiveIcon = (props) => (
  <SvgIcon {...props}>
    <path
      d="m 11.477625,12.34408 c 1.328209,0 2.407708,-1.077114 2.407708,-2.40611 0,-1.3287314 -1.079499,-2.4058458 -2.407708,-2.4058458 -1.328209,0 -2.4050623,1.0771144 -2.4050623,2.4058458 0,1.328996 1.0768533,2.40611 2.4050623,2.40611"

    />
    <path
      d="m 11.477625,14.14853 c -0.767292,0 -1.4843122,-0.208226 -2.1034379,-0.566735 l -3.632729,6.294145 c 1.6880422,0.977102 3.6459583,1.53881 5.7361669,1.53881 2.090209,0 4.050771,-0.561708 5.738812,-1.53881 l -3.635374,-6.294145 c -0.619126,0.358509 -1.336146,0.566735 -2.103438,0.566735"

    />
    <path
      d="M 15.689792,9.93797 H 22.95525 C 22.95525,5.6906328 20.645438,1.9846305 17.216437,0 l -3.635374,6.2944093 c 1.259416,0.7281297 2.108729,2.085436 2.108729,3.6435607"

    />
    <path
      d="M 9.3741871,6.2944093 5.7414581,0 C 2.3098123,1.9846305 0,5.6906328 0,9.93797 h 7.268104 c 0,-1.5581247 0.8493127,-2.915431 2.1060831,-3.6435607"

    />
  </SvgIcon>
);

export default RadioactiveIcon;
