import React, { useState, useRef } from 'react';
import { css } from 'emotion';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import parsePhoneNumber from 'libphonenumber-js';
import * as Yup from 'yup';
import { useLocation } from 'react-router-dom';
import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
import { forEach } from 'lodash';
import { Icon, Typography } from '@material-ui/core';
import { CheckCircle, ErrorOutlineRounded, WarningOutlined } from '@material-ui/icons';
import { NEW_ORDER_PACKAGING_STYLE } from '../styles/style';
import NewOrderErrorDialog from '../components/common/NewOrderErrorDialog';
import Navbar from '../components/Navbar';
import { Page } from '../components/common';
import ProfileManagementReadOnlyForm from '../components/ProfileManagementReadOnlyForm';
import ProfileManagementForm from '../components/common/ProfileManagementForm';
import PaymentManagementForm from '../components/common/PaymentManagementForm';
import CostCodeProfileManagement from '../components/common/CostCodeProfileManagement';
import * as colors from '../styles/colors';
import {
  useUserState,
  useUserDispatch,
  loadUser,
  updateUser,
  removeProfilePicture,
  setProfilePictureError,
  uploadProfilePicture,
} from '../context/userContext';
import BinPriorityForm from '../components/common/BinPriorityForm';
import {
  FIELD_TYPE_EMAIL, FIELD_TYPE_LINK, FIELD_TYPE_PHONE, SCROLL_TO_SIGNATURE, SIGNATURE_SECTION_ID, USER_PROFILE_FIELDS, USER_PROFILE_GROUP_TYPE_FIELD, USER_TYPE_MAPPING,
} from '../clientConstants';
import UserResetForm from '../components/common/UserResetForm';
import SignatureManagement from '../components/common/SignatureManagement';
import ProfileTrainingTable from '../components/common/ProfileTrainingTable';
import { loadTrainings } from '../utils/accountClient';
import AvatarHeader from '../components/common/AvatarHeader';
import { isECO, isEHS } from '../utils/helpers';
import Notifications from './AccountManagement/Notifications';
import { NOTIFICATIONS_KEY } from './AccountManagement/constants';
import { loadNotificationTypes } from '../utils/miscClient';
import ProfileDefaultAddress from '../components/common/ProfileDefaultAddress';
import UserProhibitedAckDialog from '../components/UserProhibitedAckDialog';
import NewOrderNextButton from '../components/common/NewOrderNextButton';

// Will dictate fields in Contact Info section (form & read-only)
const CONTACT_INFORMATION_FIELDS = [
  {
    name: 'email',
    label: 'Email',
    type: FIELD_TYPE_EMAIL,
    isRequired: true,
  },
  {
    name: 'mobilePhone',
    countryName: 'mobilePhoneCountry',
    label: 'Mobile Phone',
    readOnlyLabel: 'mobile',
    isRequired: true,
    validation: 'phone',
    type: FIELD_TYPE_PHONE,
  },
  {
    name: 'workPhone',
    countryName: 'workPhoneCountry',
    label: 'Work Phone',
    readOnlyLabel: 'work',
    isRequired: false,
    type: FIELD_TYPE_PHONE,
  },
  {
    name: 'twitter',
    type: FIELD_TYPE_LINK,
    label: 'Twitter',
    isRequired: false,
  },
  {
    name: 'facebook',
    label: 'Facebook',
    type: FIELD_TYPE_LINK,
    isRequired: false,
  },
  {
    name: 'linkedin',
    label: 'LinkedIn',
    type: FIELD_TYPE_LINK,
    isRequired: false,
  },
];

export default function ProfileManagement() {
  const location = useLocation();
  const userState = useUserState();
  const userDispatch = useUserDispatch();
  const [notificationTypes, setNotificationTypes] = useState([]);
  const [ackOpened, setAckOpened] = useState(false);

  const [isProfileFormReadOnly, setIsProfileFormReadOnly] = useState(true);
  const [
    isContactInformationFormReadOnly,
    setIsContactInformationFormReadOnly,
  ] = useState(true);
  const [
    isBinPriorityReadOnly,
    setIsBinPriorityReadOnly,
  ] = React.useState(true);
  const [
    isNotificationEditing,
    setIsNotificationEditing,
  ] = React.useState(false);
  const [training, setTraining] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorContent, setErrorContent] = useState(null);
  const [isError, setIsError] = useState(false);
  const sigRef = useRef(null);
  const hasUserBeenRedirected = location.state && location.state[SCROLL_TO_SIGNATURE];

  const classes = {
    pageContainer: css`
      background-color: #212435;
      padding: 67px 20px;
      margin-top: 42px;
      width: 63%;
    `,
    root: css`
      background-color: ${colors.background};
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
    `,
    innerContainer: css`
      background-color: #313547;
      padding: 24px;
      margin-bottom: 24px;
      border-radius: 4;
      box-shadow: 0 1px 1px 0 rgba(0,0,0,0.14);
    `,
    header: css`
      color: ${colors.white};
      font-size: 20px;
      font-weight: 500;
      letter-spacing: 0.25px;
    `,
  };
  const loadData = async () => {
    await Promise.all([loadTrainings(), loadNotificationTypes('user'), loadUser(userDispatch)]).then((values) => {
      setTraining(values[0]);

      if (values[1]) setNotificationTypes(values[1]);
    }).catch((err) => {
      setIsLoading(false);
      setIsError(true);
      setErrorContent(err);
    });
  };
  React.useLayoutEffect(() => {
    loadData();
    /*
      When user click on NEW SHIPPING ORDER BUTTON (RedirectToNewOrderButton.js), if user doesn't have a signature,
      we will show a modal, this will redirect the user to the user profile and will automatically scroll to the signature section.
    */

    if (hasUserBeenRedirected) {
      setTimeout(() => window.scrollTo({
        top: Number(sigRef.current.offsetTop),
        behavior: 'smooth',
      }), 1000);
    }
  }, []);

  // Post updated User Profile and display error modal if error
  // Set loading icon and displays read-only fields
  function handleSubmit(values, setSubmitting, setIsReadOnly) {
    setIsLoading(true);

    updateUser({
      dispatch: userDispatch,
      currentUserId: userState.userId,
      updatedUserData: { userId: userState.userId, ...values },
    })
      .then((data) => {
        setIsLoading(false);
        if (setIsReadOnly) setIsReadOnly(true);
      }).catch((err) => {
        setIsLoading(false);
        if (setIsReadOnly) setIsReadOnly(true);
        setIsError(true);
        setErrorContent(err);
      });

    if (setSubmitting) setSubmitting(false);
  }

  function isLoadingUserState() {
    return !(Object.entries(userState).length);
  }

  function handleProfilePictureError(newErrorState) {
    if (newErrorState !== userState.isProfilePictureError) {
      setProfilePictureError({ dispatch: userDispatch, isError: newErrorState });
    }
  }

  function handleRemoveProfilePicture() {
    return removeProfilePicture(userDispatch);
  }

  function handleUploadProfilePicture(document) {
    return uploadProfilePicture({ dispatch: userDispatch, document });
  }

  return (
    <div className={classes.root}>
      <Navbar />
      <Page>
        <Grid container justify="center">
          {isLoadingUserState() || isLoading ? (
            <Grid item>
              <CircularProgress />
            </Grid>
          ) : (
            <Grid item container className={classes.pageContainer}>
              <AvatarHeader
                fullName={userState?.name}
                username={userState?.userName}
                imageURL={userState?.imageOriginalUrl}
                roles={userState?.roles}
                isProfilePictureError={userState?.isProfilePictureError}
                onUploadProfilePicture={handleUploadProfilePicture}
                onRemoveProfilePicture={handleRemoveProfilePicture}
                onProfilePictureError={handleProfilePictureError}
              />
              {isProfileFormReadOnly ? (
                <Grid item container className={classes.innerContainer}>
                  <ProfileManagementReadOnlyForm
                    userState={{
                      ...userState,
                      timeZone: userState.timeZone
                        ? userState.timeZone.text
                        : '',
                      userType: userState.userType
                        ? USER_TYPE_MAPPING[userState.userType]
                        : '',
                    }}
                    setIsReadOnly={setIsProfileFormReadOnly}
                    title="Profile"
                    fields={USER_PROFILE_FIELDS.filter((field) => {
                      if (field.name !== USER_PROFILE_GROUP_TYPE_FIELD) return true;

                      return isEHS(userState.userType) || isECO(userState.userType);
                    })}
                    numFieldsPerColumn={2}
                    showTimeZoneNewLine={false}
                  />
                </Grid>
              ) : (
                <ProfileManagementForm
                  title="Profile"
                  isReadOnly={isProfileFormReadOnly}
                  setIsReadOnly={setIsProfileFormReadOnly}
                  setIsError={setIsError}
                  setErrorContent={setErrorContent}
                  handleSubmit={handleSubmit}
                  loggedUserType={userState.userType || ''}
                  fields={USER_PROFILE_FIELDS}
                  initialValues={{
                    name: userState.name || '',
                    department: userState.department || '',
                    userType: userState.userType || '',
                    timeZone: userState.timeZone || '',
                    groupType: userState.groupType || '',
                  }}
                  validationSchema={Yup.object().shape({
                    name: Yup.string().required('Required'),
                    department: Yup.string(),
                    userType: Yup.string().required('Required'),
                    timeZone: Yup.object().required('Required'),
                  })}
                />
              )}
              {isContactInformationFormReadOnly ? (
                <Grid item container className={classes.innerContainer}>
                  <ProfileManagementReadOnlyForm
                    userState={userState}
                    setIsReadOnly={setIsContactInformationFormReadOnly}
                    title="Contact Information"
                    fields={CONTACT_INFORMATION_FIELDS}
                    numFieldsPerColumn={3}
                  />
                </Grid>
              ) : (
                <ProfileManagementForm
                  title="Contact Information"
                  fields={CONTACT_INFORMATION_FIELDS}
                  isReadOnly={isContactInformationFormReadOnly}
                  handleSubmit={handleSubmit}
                  setIsReadOnly={setIsContactInformationFormReadOnly}
                  initialValues={{
                    email: userState.email || '',
                    mobilePhone: userState.mobilePhone || '',
                    workPhone: userState.workPhone || '',
                    linkedin: userState.linkedin || '',
                    facebook: userState.facebook || '',
                    twitter: userState.twitter || '',
                    smsNotifications: userState.smsNotifications || false,
                    emailNotifications: userState.emailNotifications || false,
                  }}
                  validationSchema={Yup.object().shape({
                    email: Yup.string()
                      .email('Invalid email')
                      .required('Required'),
                    mobilePhone: Yup.string()
                      .test({
                        name: 'is-valid-phone',
                        message: 'Invalid phone number',
                        test(value) {
                          const parsedPhoneNumber = parsePhoneNumber(
                            value || '',
                          );
                          return (
                            parsedPhoneNumber && parsedPhoneNumber.isValid()
                          );
                        },
                      })
                      .required('Required'),
                    mobilePhoneCountry: Yup.string().required('Required'),
                    workPhone: Yup.string().test({
                      name: 'is-valid-phone',
                      message: 'Invalid phone number',
                      test(value) {
                        if (!value || value === '+') return true;
                        const parsedPhoneNumber = parsePhoneNumber(value || '');
                        return parsedPhoneNumber && parsedPhoneNumber.isValid();
                      },
                    }),
                    workPhoneCountry: Yup.string().nullable(true),
                    twitter: Yup.string(),
                    facebook: Yup.string(),
                    linkedin: Yup.string(),
                    smsNotifications: Yup.bool(),
                    emailNotifications: Yup.bool(),
                  })}
                />
              )}
              <Grid
                item
                container
                className={classes.innerContainer}
                style={{ paddingBottom: 0 }}
              >
                <ProfileDefaultAddress
                  user={userState}
                  isReadOnly
                  isEditable
                  onSubmit={handleSubmit}
                />
              </Grid>
              <Grid
                item
                container
                className={classes.innerContainer}
                style={{ paddingBottom: 0 }}
              >
                <ProfileTrainingTable
                  user={userState}
                  training={training}
                  isReadOnly
                  removeEmptyExpiryDate
                  onSubmit={handleSubmit}
                />
              </Grid>
              {userState.account === 'worldemblem' && (
                <Grid item container className={classes.innerContainer}>
                  <BinPriorityForm
                    isReadOnly={isBinPriorityReadOnly}
                    setIsReadOnly={setIsBinPriorityReadOnly}
                  />
                </Grid>
              )}
              {userState.account === 'worldemblem'
                && userState.userType === 'esgAdmin' && (
                  <Grid item container className={classes.innerContainer}>
                    <UserResetForm />
                  </Grid>
              )}
              {userState.paymentOptions
              && (userState.paymentOptions?.isACH
                || userState.paymentOptions?.isCreditCard
                || userState.paymentOptions?.isPayPal)
                && (
                <Grid item container className={classes.innerContainer}>
                  <PaymentManagementForm
                    paymentOptions={userState && has(userState, 'paymentOptions')
                      ? userState.paymentOptions
                      : null}
                  />
                </Grid>
                )}
              {userState && has(userState, 'paymentOptions') && has(userState, 'paymentOptions.isCostCode')
              && userState.paymentOptions.isCostCode && (
              <Grid item container className={classes.innerContainer}>
                <CostCodeProfileManagement />
              </Grid>
              )}
              <Grid item container className={classes.innerContainer}>
                <Notifications
                  itemKey={NOTIFICATIONS_KEY}
                  notificationTypes={notificationTypes}
                  values={userState ? userState[NOTIFICATIONS_KEY] : null}
                  isEditing={isNotificationEditing}
                  onEdit={() => setIsNotificationEditing(!isNotificationEditing)}
                  onSubmit={(values, formikProps, itemKey) => handleSubmit({ [itemKey]: values }, formikProps.setSubmitting, null)}
                />
              </Grid>
              <Grid item container className={classes.innerContainer}>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                  <Grid item>
                    <NewOrderNextButton onClick={() => setAckOpened(true)}>Open Shipping Acknowledgement</NewOrderNextButton>
                  </Grid>
                  <Grid item>
                    {userState.isAckProhibited ? (
                      <Grid container item spacing={1} direction="row" justifyContent="space-between" alignItems="center">
                        <Grid item style={{ marginTop: '5px' }}>
                          <Icon>
                            <CheckCircle />
                          </Icon>
                        </Grid>
                        <Grid item>
                          <Typography>
                            Accepted
                          </Typography>
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid container item spacing={1} direction="row" justifyContent="space-between" alignItems="center">
                        <Grid item style={{ marginTop: '5px' }}>
                          <Icon color="primary">
                            <ErrorOutlineRounded />
                          </Icon>
                        </Grid>
                        <Grid item>
                          <Typography>
                            Not Accepted
                          </Typography>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid ref={sigRef} item container className={classes.innerContainer}>
                <SignatureManagement
                  signature={userState.signature}
                  readOnly={!hasUserBeenRedirected}
                  showButtons
                  onSubmit={handleSubmit}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
        <NewOrderErrorDialog
          open={isError}
          errorContent={errorContent}
          classes={NEW_ORDER_PACKAGING_STYLE()}
          onClose={() => {
            setIsError(false);
            setErrorContent(null);
          }}
        />
        <UserProhibitedAckDialog
          isOpened={ackOpened}
          userProfile
          setOpened={setAckOpened}
        />
      </Page>
    </div>
  );
}
