import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import {
  CircularProgress, Grid, IconButton, InputAdornment,
} from '@material-ui/core';
import ArrowDown from '@material-ui/icons/KeyboardArrowDown';
import { Field } from 'formik';
import { DebounceInput } from 'react-debounce-input';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import { CustomSearchWithDropdown } from './InputComponents';

const useStyles = makeStyles({
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
});
const CustomCountrySelectField = ({
  name, label, countries, disabled,
}) => {
  const [filteredCountries, setFilteredCountries] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const classes = useStyles();

  const getDropdownLabel = (country) => (
    <Grid item container alignItems="center">
      <div style={{ marginRight: '5px' }}>{country.emoji}</div>
      {country.name}
    </Grid>
  );

  const setFilteredCountriesData = (items) => {
    setFilteredCountries(items.map((c) => ({
      ...c,
      value: c.countryCode,
      label: getDropdownLabel(c),
    })));
  };

  const handleCountrySearch = (e) => {
    const value = e?.target?.value;
    let data = countries;

    if (!isEmpty(value)) {
      const lowerCaseValue = value.toLowerCase();
      data = data.filter((c) => c.countryCode.toLowerCase().startsWith(lowerCaseValue)
          || c.name.toLowerCase().startsWith(lowerCaseValue));
    }

    setFilteredCountriesData(data);
  };

  const handleFocus = () => {
    if (!isEmpty(countries)) {
      setFilteredCountriesData(countries);
    }
  };

  useEffect(() => {
    if (!isEmpty(countries)) {
      setIsLoading(false);
    }
  }, [countries]);

  return (
    <Field>
      {({ form }) => {
        const { setFieldValue } = form;
        const selected = get(form, `values.${name}`, '');
        const selectedCountry = !isEmpty(selected)
          ? countries.find((c) => c.countryCode === selected)
          : '';
        const selectedCountryDescription = get(selectedCountry, 'description', '');

        return (
          <Grid item key={`selectField-${name}`} style={{ padding: '12px 0' }}>
            <DebounceInput
              name={name}
              label={label}
              value={selectedCountryDescription}
              minLength={2}
              debounceTimeout={500}
              form={form}
              field={{ name }}
              inputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {isLoading
                      ? <CircularProgress style={{ color: 'white', width: '20px', height: '20px' }} />
                      : <ArrowDown color="primary" style={{ pointerEvents: 'none' }} />}
                  </InputAdornment>
                ),
                inputProps: { autocomplete: 'off' },
              }}
              element={CustomSearchWithDropdown}
              dropdownList={filteredCountries}
              onSelect={({ value }) => {
                setFieldValue(name, ''); // force component to update even when selecting the same country
                setFieldValue(name, value);
                setFilteredCountries([]);
              }}
              onChange={(e) => { setFieldValue(name, ''); handleCountrySearch(e); }}
              onFocus={handleFocus}
              onClickOutside={() => {
                setFilteredCountries([]);
              }}
              disabled={disabled}
            />
            {/* <Autocomplete
              id="country-select-demo"
              options={filteredCountries}
              classes={{
                option: classes.option,
              }}
              autoHighlight
              getOptionLabel={(option) => option.label}
              renderOption={(option) => (
                <>

                  {option.label}

                </>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name={name}
                  label={label}
                  value={selectedCountryDescription}
                  minLength={2}
                  form={form}
                  field={{ name }}
                />
              )}
            /> */}
          </Grid>
        );
      }}
    </Field>
  );
};

CustomCountrySelectField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  countries: PropTypes.array,
  disabled: PropTypes.bool,
};

CustomCountrySelectField.defaultProps = {
  countries: [],
  disabled: false,
};

export default CustomCountrySelectField;
