/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography, Grid,
} from '@material-ui/core';
import { Formik, Form } from 'formik';
import * as colors from '../../styles/colors';
import { useLoadTimeZonesUserTypes } from '../../utils/hooks';
import NewOrderNextButton from './NewOrderNextButton';
import NewOrderNextButtonClear from './NewOrderNextButtonClear';
import ProfileManagementFormFields from './ProfileManagementFormFields';

const useStyles = makeStyles({
  innerContainer: {
    width: '538px',
    backgroundColor: colors.darkBlueBackground,
    padding: '24px 24px 0px 24px',
    marginTop: '26px',
    marginBottom: '32px',
  },
  outerContainer: {
    width: '100%',
    backgroundColor: '#313547',
    padding: '24px',
    marginBottom: '24px',
  },
  header: {
    fontSize: '20px',
    fontWeight: 500,
    color: colors.white,
    letterSpacing: '0.25px',
  },
});

export default function ProfileManagementForm({
  loggedUserType,
  initialValues,
  handleSubmit,
  setIsReadOnly,
  fields,
  validationSchema,
  title,
  setIsError,
  setErrorContent,
}) {
  const classes = useStyles();
  const {
    isLoading,
    timeZones,
    userTypes,
  } = useLoadTimeZonesUserTypes(loggedUserType, fields, setIsError, setErrorContent);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
        handleSubmit(values, setSubmitting, setIsReadOnly);
      }}
    >
      {({ values, isSubmitting }) => (
        <Form className={classes.outerContainer}>
          <Typography className={classes.header}>{title}</Typography>
          <Grid
            className={classes.innerContainer}
            direction="column"
            justify="center"
            container
          >
            <ProfileManagementFormFields
              loggedUserType={loggedUserType}
              fields={fields}
              values={values}
              timeZones={timeZones}
              userTypes={userTypes}
              isLoading={isLoading}
              isSubmitting={isSubmitting}
            />
          </Grid>
          <Grid spacing={4} container justify="center">
            <Grid item>
              <NewOrderNextButtonClear onClick={() => setIsReadOnly(true)} disabled={isSubmitting}>
                Cancel
              </NewOrderNextButtonClear>
            </Grid>
            <Grid item>
              <NewOrderNextButton type="submit" disabled={isSubmitting}>
                Submit
              </NewOrderNextButton>
            </Grid>
          </Grid>

        </Form>
      )}
    </Formik>
  );
}

ProfileManagementForm.defaultProps = {
  loggedUserType: '',
};

ProfileManagementForm.propTypes = {
  title: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.objectOf(PropTypes.string).isRequired,
  loggedUserType: PropTypes.string,
  fields: PropTypes.arrayOf(PropTypes.shape({
    isRequired: PropTypes.bool,
    name: PropTypes.string,
    label: PropTypes.string,
  })).isRequired,
};
