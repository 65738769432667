import React from 'react';
import shortId from 'shortid';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  CircularProgress,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography,
  TextField,
  Button,
} from '@material-ui/core';

import Grid from '@material-ui/core/Grid';

import { css } from 'emotion';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { isNull } from 'lodash';
import Navbar from '../components/Navbar';
import ErrorDialog from '../components/common/NewOrderErrorDialog';

import { Page } from '../components/common';
import * as colors from '../styles/colors';
import {
  useSingleBinDispatch,
  useSingleBinState,
  loadSingleBin,
  scanBin,
  deleteSalesOrder,
  clearBinState,
  unScanPackingSlip,
} from '../context/singleBinContext';
import {
  useContactsDispatch,
  loadDefaultSender,
} from '../context/contactsContext';
import { inDevelopment } from '../clientConstants';
import { formatDateShort, reverseTaskTypeToFormName } from '../utils/helpers';
import {
  loadCountries,
  loadCountryStates,
  useMiscDispatch,
} from '../context/miscDataContext';
import {
  useUserDispatch,
  useUserState,
  loadUser,
} from '../context/userContext';
import ConfirmationModalWE from '../components/common/ConfirmationModalWE';
import FedexLogo from '../components/images/FEDEX_white.png';
import UPSLogo from '../components/images/UPS_transparent.png';
import MNXLogo from '../components/images/MNX.png';
import USPSLogo from '../components/images/USPS.png';
import DHLLogo from '../components/images/DHL.png';
import SalesOrderDetailsTable from '../components/SalesOrderDetailsTable';
import BinScanModal from '../components/common/BinScanModal';
import ErrorModalWE from '../components/common/ErrorModalWE';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: colors.background,
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  tabs: {
    // borderRight: `1px solid ${theme.palette.divider}`,
  },
  formContainer: {
    marginTop: 12,
    paddingLeft: 24,
  },
  progress: {
    margin: 'auto',
  },
  expansionPanel: {
    backgroundColor: colors.darkBlueBackground,
  },
  expansionPanelInternal: {
    backgroundColor: colors.darkBlueBackground,
    padding: '1.5rem',
  },
  expansionPanelChild: {
    backgroundColor: colors.mediumBlueBackground,
  },
  expansionPanelLabel: {
    color: colors.white,
    fontSize: 18,
    fontWeight: 500,
  },
  expansionPanelLabelHead: {
    color: colors.white,
    fontSize: 32,
    fontWeight: 600,
    justifyContent: 'center',
  },
  expansionPanelLabelEDI: {
    color: colors.red,
    fontSize: 24,
    fontWeight: 600,
    marginLeft: '3rem',
  },
  shipmentBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  expansionIcon: {
    color: colors.white,
  },
  searchFieldRoot: {
    color: colors.white,
  },
  inputRoot: {
    background: colors.mediumBlueBackground,
    color: colors.white,
  },
  searchFieldLabelRoot: {
    color: colors.white,
    fontSize: 16,
    fontWeight: 500,
    background: colors.mediumBlueBackground,
  },
  searchFieldWrap: {
    height: 45,
    borderRadius: 5,
    background: colors.mediumBlueBackground,
    marginLeft: '20px',
    width: 300,
    color: colors.white,
  },
  searchField: {
    width: '40vw',
    background: colors.mediumBlueBackground,
    color: colors.white,
    '& label': {
      color: colors.white,
      fontWeight: 500,
    },
    overflow: 'hidden',
    margin: 0,
    height: '100%',
    borderRadius: 5,
    marginRight: 20,
  },
  binName: {
    fontSize: '8rem',
    fontWeight: 'bold',
    height: '200px',
  },
  panelContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: '1rem',
  },
  green: {
    color: '#00f200',
  },
  orange: {
    color: '#ff9100',
  },
  purple: {
    color: '#8561c5',
  },
  blue: {
    color: '#2979ff',
  },
  noResultsText: {
    color: colors.tableGreyText,
    fontSize: '30px',
  },
}));

// UTIL FUNCTIONS
function isObjectAndIsNotEmpty(obj) {
  return !!(Object.entries(obj).length && obj.constructor === Object);
}

function parseOrderAndShipmentId(id) {
  const idMatch = id && id.match(/^([^-]+)-([0-9]+)-*([0-9]*)$/);
  const orderId = idMatch && idMatch[1] && idMatch[2] ? `${idMatch[1]}-${idMatch[2]}` : '';
  const urlShipmentId = orderId && idMatch[3] ? `${idMatch[1]}-${idMatch[2]}-${idMatch[3]}` : '';
  return { orderId, urlShipmentId };
}

function parsePanel(search) {
  return search.split('?').reduce((p, query) => {
    const splitQuery = query.split('=');
    if (splitQuery[0] === 'panel') {
      return (
        reverseTaskTypeToFormName[splitQuery[1].toLowerCase()]
        || reverseTaskTypeToFormName[splitQuery[1]]
        || ''
      );
    }
    return p;
  }, '');
}

export default function BinGroup(props) {
  const classes = useStyles();
  const { history, match } = props;
  const id = match.params.id || '';

  React.useEffect(() => {
    inDevelopment()
      && console.log('calling loadDefaultSender from NewOrder.js');
    loadUser(userDispatch);
  }, [contactsDispatch, miscDispatch, userDispatch]);
  React.useEffect(() => {
    clearBinState({ dispatch: singleBinDispatch });
  }, []);
  const [packingSlip, setPackingSlip] = React.useState(null);
  const [binPackingSlip, setBinPackingSlip] = React.useState(null);

  const [lastBin, setLastBin] = React.useState(false);
  const [lastItemBin, setLastItemBin] = React.useState(false);
  const inputRef = React.useRef();
  const contactsDispatch = useContactsDispatch();
  const singleBinState = useSingleBinState();
  const singleBinDispatch = useSingleBinDispatch();
  const [errorContent, setErrorContent] = React.useState(null);
  const [cancelModalState, setCancelModalState] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [keepOrderOpen, setKeepOrderOpen] = React.useState(null);
  const miscDispatch = useMiscDispatch();
  const userDispatch = useUserDispatch();
  const [close, setClose] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [errorValue, setErrorValue] = React.useState(null);

  const [binModal, setBinModal] = React.useState(null);
  const [binMessage, setBinMessage] = React.useState(null);
  const [message, setMessage] = React.useState(null);
  const [multiMessage, setMultiMessage] = React.useState(null);

  const confirmCloseOpen = close === 'confirm';

  const handleCloseButtonClick = () => {
    setClose(null);
    history.push(`/new-shiporder/${singleBinState.shipment.orderId}`, { newOrder: false });
    clearBinState({ dispatch: singleBinDispatch });
  };
  const handleBinModalCloseClick = () => {
    setBinModal(null);
  };
  React.useEffect(() => {
    console.log(singleBinState);
  }, [singleBinState]);

  const handlePackingSlipChange = (e) => {
    setPackingSlip(e.target.value);
  };

  const getBinDetails = (packingSlipId) => {
    setLoading(true);

    return loadSingleBin({ dispatch: singleBinDispatch, packingSlipId }).then(
      (data) => {
        setBinPackingSlip(packingSlip);
        console.log(`response: ${JSON.stringify(data)}`);
        setLoading(false);
        if (data.lastBin) {
          setLastBin(true);
        } else {
          setLastBin(false);
        }
        if (data.shipment === undefined) {
          const errorText = JSON.parse(data).error;
          const sentenceArray = errorText.replace(/([.?!])\s*(?=[A-Z])/g, '$1|').split('|');
          if (sentenceArray[0] === 'You have another grouped SalesOrder scanned.') {
            setError(sentenceArray[0] + sentenceArray[1]);
            setErrorValue(sentenceArray[2]);
          } else {
            setError(errorText);
          }
        } else if (data?.shipment?.binId === null || data?.shipment?.binId === '' || data?.shipment?.binId === undefined) {
          setMessage('Ready to Ship , Click Ok to go to Shipping Screen');
          setClose('confirm');
        } else if (data?.shipment?.binId !== null && !data.isBinned) {
          const totalOrders = data?.shipment?.salesOrders?.length;
          let isMultiLine = false;
          const totalBinned = data?.shipment?.salesOrders.filter((s) => s.status.toUpperCase() === 'BINNED').length;
          if (totalOrders - totalBinned === 1) {
            const scannedSalesOrder = data?.shipment?.salesOrders.filter((s) => s.status.toUpperCase() === 'SCANNED')[0];
            if (scannedSalesOrder?.packSlip.length - scannedSalesOrder?.scannedPackSlip.length > 0) {
              isMultiLine = true;
            }
            const otherSalesOrders = data?.shipment?.salesOrders.filter((s) => s.status.toUpperCase() !== 'SCANNED');
            otherSalesOrders.forEach((element) => {
              if (element?.packSlip.length - element?.scannedPackSlip.length > 0) {
                isMultiLine = true;
              }
            });

            if (
              !isMultiLine
              && data?.shipment?.salesOrders.filter(
                (s) => s.isComplete === true,
              ).length === data?.shipment?.salesOrders.length
            ) {
              setBinMessage('Last Item in the Bin');
              setLastItemBin(true);
            }
          } else {
            setBinMessage(null);
          }
          setBinModal(true);
        }
        if (data?.isMultiplePackingSlips) {
          setMultiMessage(data?.info);
        }
        setPackingSlip('');
      },
    );
  };
  const handleBinModalProceedClick = async (binName) => {
    try {
      const data = await scanBin({ dispatch: singleBinDispatch, binName });

      console.log(`response: ${JSON.stringify(data)}`);
      if (data?.alert) {
        setMessage(data.message);
        setClose('confirm');
        setBinModal(false);
        inputRef.current.focus();
      } else if (data.shipment === undefined) {
        setError(JSON.parse(data).error);
        throw new Error('Incorrect bin');
        //        setBinModal(false);
      } else {
        setBinModal(false);
        inputRef.current.focus();
      }
    } catch (e) {
      throw new Error(e.message);
    }
  };
  const handleDeleteSalesOrderClick = (row) => {
    deleteSalesOrder({ dispatch: singleBinDispatch, salesOrderId: row.salesOrderId }).then(
      (data) => {
        console.log(`response: ${JSON.stringify(data)}`);
        loadSingleBin({ dispatch: singleBinDispatch, binPackingSlip }).then((d) => { console.log(d); });
        if (JSON.parse(data).error) {
          setError(JSON.parse(data).error);
        }
      },
    );
  };
  const getBinData = (e) => {
    getBinDetails(packingSlip);
  };
  const handleUnscanClick = () => {
    unScanPackingSlip().then((data) => {
      setError(null);
      setErrorValue(null);
    });
  };
  const getCarrierLogo = (carrierName) => {
    const logos = {
      FEDEX: FedexLogo,
      UPS: UPSLogo,
      MNX: MNXLogo,
      NGL: MNXLogo,
      USPS: USPSLogo,
      DHL: DHLLogo,
    };
    if (carrierName in logos) {
      return <img style={{ height: '150px', padding: '0 25px' }} src={logos[carrierName]} alt="" />;
    }
    return <span style={{ color: 'white', fontSize: '8rem' }}>{carrierName}</span>;
  };
  const getBinId = (binId, salesOrders) => {
    let showOrange = false;
    let showPurple = false;
    let showBlue = false;
    const scannedSalesOrder = salesOrders?.find((s) => s?.status?.toUpperCase() === 'SCANNED');
    if (salesOrders) {
      showOrange = !!salesOrders.filter((s) => s?.status?.toUpperCase() === 'SCANNED').length;
      showPurple = showOrange && (salesOrders.filter((s) => s?.status?.toUpperCase() === 'BINNED').length > 0 || (scannedSalesOrder?.scannedPackSlip?.length > 1 && scannedSalesOrder?.packSlip?.length > scannedSalesOrder?.scannedPackSlip?.length));
      showBlue = (showPurple || (salesOrders?.length === 1 && showOrange)) && lastItemBin
             && salesOrders.filter(
               (s) => s?.isComplete === true
             ).length === salesOrders.length;
    }
    return (
      <Typography
        className={`${classes.binName} ${
          // eslint-disable-next-line no-nested-ternary
          showBlue ? classes.blue : showPurple ? classes.purple : showOrange ? classes.orange : classes.green
        }`}
      >
        {binId}
      </Typography>
    );
  };
  return (
    <div className={classes.root}>
      <Navbar />
      <Page account={useUserState().account}>
        <Grid container justify="space-between">
          <Grid
            container
            direction="column"
            spacing={1}
            item
            className={classes.formContainer}
          >
            <Grid
              container
              direction="column"
              spacing={1}
              item
              className={classes.formContainer}
            >
              <Grid
                key="order-basics-expandable-pane"
                item
                style={{ width: '100%', padding: '1.5rem' }}
                className={classes.expansionPanel}
              >
                <Grid container justify="space-between">
                  <Grid item className={classes.shipmentBox}>
                    <Typography className={classes.expansionPanelLabelHead}>
                      Group Order
                    </Typography>
                  </Grid>
                </Grid>
                <ExpansionPanel
                  defaultExpanded
                  className={classes.expansionPanelChild}
                  onChange={() => {}}
                  TransitionProps={{ unmountOnExit: true }}
                >
                  <ExpansionPanelSummary
                    expandIcon={(
                      <ExpandMore
                        color="primary"
                        classes={{ colorPrimary: classes.expansionIcon }}
                      />
                    )}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Grid container justify="space-between">
                      <Grid item>
                        <Typography className={classes.expansionPanelLabel}>
                          Manual Entry
                        </Typography>
                      </Grid>
                    </Grid>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails
                    className={classes.expansionPanelChild}
                  >
                    <Grid
                      container
                      justify="center"
                      alignItems="center"
                      direction="row"
                      className={classes.expansionPanelInternal}
                    >
                      <Grid
                        item
                        //    className={classes.searchFieldWrap}
                      >
                        <TextField
                          autoFocus
                          inputRef={inputRef}
                          id="filled-basic"
                          className={classes.searchField}
                          classes={{ root: classes.searchFieldRoot }}
                          label="Pack Slip #"
                          margin="normal"
                          onChange={handlePackingSlipChange}
                          InputProps={{
                            disableUnderline: true,
                            classes: {
                              root: classes.inputRoot,
                              focused: classes.inputRoot,
                            },
                          }}
                          InputLabelProps={{
                            root: classes.searchFieldLabelRoot,
                          }}
                          variant="filled"
                          value={packingSlip}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              if (!loading) { getBinData(e); }
                            }
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <Button
                          className={css`
                            font-weight: bold;
                            margin: auto;
                            padding: 6px 20px;
                            color: ${colors.white};
                            border-radius: 5px;
                            white-space: nowrap;
                          `}
                          color="secondary"
                          variant="contained"
                          onClick={(e) => { if (!loading) { getBinData(e); } }}
                        >
                          Get Data
                        </Button>
                      </Grid>
                    </Grid>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </Grid>
              {singleBinState?.shipment?.salesOrders?.length && !loading ? (
                <Grid
                  key="order-basics-expandable-pane"
                  item
                  style={{ width: '100%', padding: '1.5rem' }}
                  className={classes.expansionPanel}
                >
                  <Grid container direction="row">
                    <Grid item xs={6}>
                      <ExpansionPanel
                        defaultExpanded
                        className={classes.expansionPanelChild}
                        onChange={() => {}}
                        TransitionProps={{ unmountOnExit: true }}
                      >
                        <ExpansionPanelSummary
                          expandIcon={(
                            <ExpandMore
                              color="primary"
                              classes={{ colorPrimary: classes.expansionIcon }}
                            />
                          )}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Grid container justify="space-between">
                            <Grid item>
                              <Typography
                                className={classes.expansionPanelLabel}
                              >
                                Carrier Details
                              </Typography>
                            </Grid>
                          </Grid>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails
                          className={classes.expansionPanelChild}
                        >
                          <Grid
                            item
                            xs={12}
                            className={classes.expansionPanel}
                            alignItems="center"
                          >
                            <div
                              className={classes.panelContainer}
                              style={{ height: '232px' }}
                            >
                              {getCarrierLogo(
                                singleBinState.shipment.carrier.details.carrierName.toUpperCase(),
                              )}
                            </div>
                          </Grid>
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                    </Grid>
                    <Grid item xs={6}>
                      <ExpansionPanel
                        defaultExpanded
                        className={classes.expansionPanelChild}
                        onChange={() => {}}
                        TransitionProps={{ unmountOnExit: true }}
                      >
                        <ExpansionPanelSummary
                          expandIcon={(
                            <ExpandMore
                              color="primary"
                              classes={{ colorPrimary: classes.expansionIcon }}
                            />
                          )}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Grid container justify="space-between">
                            <Grid item>
                              <Typography
                                className={classes.expansionPanelLabel}
                              >
                                {`Bin Details ${lastBin ? '- Last Bin' : ''}`}
                              </Typography>
                            </Grid>
                          </Grid>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails
                          className={classes.expansionPanelChild}
                        >
                          <Grid item xs={12} className={classes.expansionPanel}>
                            <div className={classes.panelContainer}>
                              {getBinId(
                                singleBinState.shipment.binName,
                                singleBinState.shipment.salesOrders,
                              )}
                            </div>
                          </Grid>
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                    </Grid>
                  </Grid>
                  <ExpansionPanel
                    defaultExpanded
                    className={classes.expansionPanelChild}
                    onChange={() => {}}
                    TransitionProps={{ unmountOnExit: true }}
                  >
                    <ExpansionPanelSummary
                      expandIcon={(
                        <ExpandMore
                          color="primary"
                          classes={{ colorPrimary: classes.expansionIcon }}
                        />
                      )}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Grid container justify="space-between">
                        <Grid item>
                          <Typography className={classes.expansionPanelLabel}>
                            Items
                          </Typography>
                        </Grid>
                      </Grid>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails
                      className={classes.expansionPanelChild}
                    >
                      <Grid item xs={12} className={classes.expansionPanel}>
                        <SalesOrderDetailsTable
                          rows={singleBinState.shipment.salesOrders}
                          onDeleteClick={handleDeleteSalesOrderClick}
                        />
                      </Grid>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </Grid>
              ) : (
                !loading
                && singleBinState?.salesOrders?.length === 0 && (
                  <Grid
                    item
                    container
                    direction="column"
                    alignItems="center"
                    className={classes.noResults}
                  >
                    <Grid item className={classes.noResultsIconContainer} />
                    <Grid item className={classes.noResultsTextContainer}>
                      <Typography className={classes.noResultsText}>
                        No Orders found!
                      </Typography>
                    </Grid>
                  </Grid>
                )
              )}
              {loading && <CircularProgress className={classes.progress} />}
            </Grid>
          </Grid>
        </Grid>
        <ConfirmationModalWE
          open={confirmCloseOpen}
          onProceed={() => handleCloseButtonClick('binId')}
          onCancel={() => {
            setClose(null);
            clearBinState({ dispatch: singleBinDispatch });
            inputRef.current.focus();
          }}
          cancelLabel="Cancel"
          proceedLabel="OK"
          message={<span>{message}</span>}
        />
        <BinScanModal
          open={binModal}
          binMessage={binMessage}
          packingSlip={binPackingSlip}
          onProceed={handleBinModalProceedClick}
          onCancel={() => setBinModal(null)}
          carrier={getCarrierLogo(
            singleBinState?.shipment?.carrier?.details?.carrierName.toUpperCase(),
          )}
          bin={getBinId(
            singleBinState?.shipment?.binName,
            singleBinState?.shipment?.salesOrders,
          )}
          noCancel
          isEdi={singleBinState?.shipment?.isEdi}
          proceedLabel="Cancel"
          message={<span>Scan Bin</span>}
        />
        <ErrorModalWE
          open={!!error}
          onProceed={() => {
            setError(null);
            setErrorValue(null);
            setPackingSlip('');
            inputRef.current.focus();
          }}
          noCancel
          proceedLabel="OK"
          message={<span>{error}</span>}
          strongMessage={errorValue}
          onActionClick={handleUnscanClick}
        />
        <ErrorModalWE
          open={!!multiMessage}
          onProceed={() => {
            setMultiMessage(null);
          }}
          noCancel
          proceedLabel="OK"
          message={<span>{multiMessage}</span>}
          strongMessage={errorValue}
          onActionClick={handleUnscanClick}
        />
      </Page>
    </div>
  );
}

BinGroup.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};
