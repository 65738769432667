import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { getIn } from 'formik';
import { Grid, Typography } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import * as colors from '../../../styles/colors';
import CustomSelectTextField from '../../common/CustomSelectTextField';
import NewOrderCustomTextField from '../../common/NewOrderCustomTextField';
import { initialTriggerState } from '../../../utils/packagingUtil';
import { singlePackagePropTypes, stripPrefixFromFieldName } from '../utils';
import ConditionalField from './ConditionalField';
import ConfigureIotButton from './ConfigureIotButton';
import ConfigureIotForm from './ConfigureIotForm';

const useStyles = makeStyles(() => ({
  formSectionHeader: {
    paddingTop: 24,
    fontSize: 14,
    color: colors.white,
  },
  standardFormTextfield: {
    width: 415,
  },
}));

function IotForm({
  fieldNames, currentPackage, formikRef, iotVendors,
}) {
  const classes = useStyles();

  return (
    <Grid item container direction="column" spacing={2}>
      <Grid item>
        <Typography className={classes.formSectionHeader}>IoT Device Information</Typography>
      </Grid>
      <Grid item container spacing={2}>
        <Grid item xs={6}>
          <ConditionalField
            component={NewOrderCustomTextField}
            type="text"
            name={fieldNames.iotDeviceId}
            label="IoT Device ID"
            inputProps={{ maxLength: 25 }}
            style={{ width: '100%' }}
            currentPackage={currentPackage}
          />
        </Grid>
        <Grid item xs={6}>
          <ConditionalField
            style={{ width: '100%' }}
            component={CustomSelectTextField}
            type="text"
            name={fieldNames.iotVendor}
            label="IoT Vendor"
            className={classes.standardFormTextfield}
            currentPackage={currentPackage}
          >
            {iotVendors.map((vendor) => (
              <MenuItem key={vendor.vendorName} value={vendor.vendorName}>
                {vendor.displayName}
              </MenuItem>
            ))}
          </ConditionalField>
        </Grid>
        {getIn(currentPackage, stripPrefixFromFieldName(fieldNames.iotTriggers, /packages\[\d\]\./))
          ? (
            <Grid item xs={12}>
              <ConfigureIotForm
                formPrefix=""
                fieldNames={fieldNames}
                formikRef={formikRef}
              />
            </Grid>
          ) : (
            <ConditionalField
              name={fieldNames.iotTriggers}
              currentPackage={currentPackage}
              initialState={initialTriggerState}
              label="Configure IoT"
              component={ConfigureIotButton}
            />
          )}
      </Grid>
    </Grid>
  );
}

IotForm.propTypes = {
  fieldNames: PropTypes.objectOf(PropTypes.any).isRequired,
  currentPackage: singlePackagePropTypes.isRequired,
  formikRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]).isRequired,
  iotVendors: PropTypes.arrayOf(PropTypes.shape({
    displayName: PropTypes.string.isRequired,
    vendorName: PropTypes.string.isRequired,
  })).isRequired,
};

export default memo(IotForm);
