import React from 'react';
import * as materialClient from '../utils/materialsClient';
import { useLogger } from '../utils/hooks';

const materialsState = { materials: null };
const MaterialsContext = React.createContext();
const MaterialsDispatchContext = React.createContext();

const LOAD_ITEM_OPTIONS = 'LOAD ITEM OPTIONS';

function materialsReducer(state, action) {
  const { data, type } = action;
  switch (type) {
    case LOAD_ITEM_OPTIONS: {
      return { ...state, ...data };
    }
    default:
      throw new Error(`Unhandled action type: ${type}`);
  }
}

function MaterialsDataProvider(props) {
  const [state, dispatch] = useLogger(
    React.useReducer(materialsReducer, {}),
  );

  React.useEffect(() => {
    Promise.all([
      materialClient.loadProductCategories(),
      materialClient.loadDimensionUnits(),
      materialClient.loadWeightUnits(),
      materialClient.loadSmallWeightUnits(),
      materialClient.loadProductUnits(),
      materialClient.loadCurrencyUnits(),
      materialClient.loadFreightClasses(),
      materialClient.loadHazmatWeightUnits(),
      materialClient.loadHazmatSmallWeightUnits(),
      materialClient.loadPreservatives(),
    ]).then(
      ([
        productCategories,
        dimensionUnits,
        weightUnits,
        smallWeightUnits,
        productUnits,
        currencyUnits,
        freightClasses,
        hazmatWeightUnits,
        hazmatSmallWeightUnits,
        preservatives,
      ]) => {
        dispatch({
          type: LOAD_ITEM_OPTIONS,
          data: {
            productCategories,
            dimensionUnits,
            weightUnits,
            smallWeightUnits,
            productUnits,
            currencyUnits,
            freightClasses,
            hazmatWeightUnits,
            hazmatSmallWeightUnits,
            preservatives,
          },
        });
      },
    );
  }, []);

  return (
    <MaterialsContext.Provider value={state} {...props}>
      <MaterialsDispatchContext.Provider value={dispatch} {...props} />
    </MaterialsContext.Provider>
  );
}

function useMaterialsState() {
  const context = React.useContext(MaterialsContext);
  if (context === undefined) {
    throw new Error('useMaterialsState must be used within a MaterialsDataProvider');
  }
  return context;
}

function useMaterialsDispatch() {
  const context = React.useContext(MaterialsDispatchContext);
  if (context === undefined) {
    throw new Error('useMaterialsDispatch must be used within a MaterialsDataProvider');
  }
  return context;
}

export {
  MaterialsDataProvider,
  useMaterialsState,
  useMaterialsDispatch,
};
