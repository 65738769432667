import client from './apiClient';
import { DUMMY_FETCH_TRACKING_HISTORY, DUMMY_FETCH_DOCUMENT_TRACKING_HISTORY, DUMMY_FETCH_IOT_TRACKING_HISTORY } from '../dummyData';

export function loadTrackingInfo(shipmentId) {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(DUMMY_FETCH_TRACKING_HISTORY);
      }, 1200);
    });
  }
  return client(`/data/dashboard/track/${shipmentId}`, { method: 'get' });
}

export function loadTrackInfoWithTrackNo(shipmentId, trackingNumber) {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(DUMMY_FETCH_DOCUMENT_TRACKING_HISTORY);
      }, 1200);
    });
  }
  return client(`/data/dashboard/track/${shipmentId}`, {
    method: 'get',
    data: { trackingNumber },
  });
}

export function loadShippingOrIotTrackingInfo({ shipmentId, trackingNumber, iot }) {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(iot ? DUMMY_FETCH_IOT_TRACKING_HISTORY : DUMMY_FETCH_DOCUMENT_TRACKING_HISTORY);
      }, 1200);
    });
  }

  const data = iot ? { iotTrackingNumber: trackingNumber, iotVendor: 'tive' } : { trackingNumber };
  return client(`/data/dashboard/track/${shipmentId}`, {
    method: 'get',
    params: data,
  });
}
