import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import {
  CircularProgress, Divider, FormControlLabel, Grid, Radio, RadioGroup, Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddressForm from '../common/AddressForm';
import NewOrderNextButton from '../common/NewOrderNextButton';
import * as colors from '../../styles/colors';
import { NEW_ORDER_SENDER_RECIPIENT_STYLE } from '../../styles/style';
/**
 * ##################################
 *          GLOBAL VARIABLES
 * ##################################
 */
const NONE = '';
const SENDER = 'sender';
const OTHER = 'other';

const DEFAULT_FORM_VALUES = {
  type: '',
  addressLine1: '',
  addressLine2: '',
  addressLine3: '',
  city: '',
  state: '',
  zip: '',
  country: '',
};

const useStyles = makeStyles({
  pickupOptions: {
    marginTop: 8,
    marginLeft: 0,
  },
  radioOptionsContainer: {
    paddingBottom: 12,
  },
  divider: {
    marginTop: 18,
    backgroundColor: colors.darkBlueBackground,
  },
  radioLabel: {
    color: colors.textLightGrey,
    '&>span:last-child': {
      fontSize: 14,
      fontWeight: 425,
      color: colors.textLightGrey,
    },
    marginRight: 10,
    height: 37,
  },
  radio: {
    '&$radioDisabled': {
      color: colors.textDarkGrey,
    },
    color: colors.white,
  },
  radioDisabled: {},
  addressBlock: {
    '&> :nth-of-type(2)': {
      paddingTop: '0px',
    },
  },
  sectionHeader: {
    textTransform: 'capitalize',
    color: colors.white,
    fontWeight: 500,
    paddingTop: 15,
  },
});

/**
 * ##################################
 *          CUSTOM COMPONENT
 * ##################################
 */

function CustomOptions(props) {
  const {
    form, field, formikRef, country, disabled, classes, altClasses, freightType,
  } = props;
  const { onChange } = props;

  const { value } = field;
  const { values, setValues } = form;

  const { radioLabel, radio, radioDisabled } = classes;

  const radioOptions = freightType === 'hazmat'
    ? [
      {
        name: 'pickupDropoff',
        id: 'pickup-sender',
        value: SENDER,
        label: "Pick up shipment from sender's address",
      },
      {
        name: 'pickupDropoff',
        id: 'pickup-other',
        value: OTHER,
        label: 'Pick up shipment from another location',
      },
    ]
    : [
      {
        name: 'pickupDropoff',
        id: 'drop-off',
        value: NONE,
        label: 'I will drop off package(s) at a shipping station',
      },
      {
        name: 'pickupDropoff',
        id: 'pickup-sender',
        value: SENDER,
        label: "Pick up shipment from sender's address",
      },
      {
        name: 'pickupDropoff',
        id: 'pickup-other',
        value: OTHER,
        label: 'Pick up shipment from another location',
      },
    ];
  const addressBlockClasses = { ...classes, ...altClasses };

  return (
    <>
      <RadioGroup data-testid="sender-pickup-options-radio-group" value={value} onChange={(e) => onChange(e.target.value)}>
        {radioOptions.map((radioOption) => (
          <FormControlLabel
            key={`pickupOption-${radioOption.id}`}
            className={radioLabel}
            value={radioOption.value}
            control={(
              <Radio
                data-testid="sender-pickup-options-radio-button"
                name={radioOption.name}
                id={radioOption.id}
                color="primary"
                disabled={disabled}
                classes={{
                  root: radio,
                  disabled: radioDisabled,
                }}
              />
            )}
            label={radioOption.label}
          />
        ))}
      </RadioGroup>
      {(value === OTHER)
        ? (
          <Grid style={{ padding: '10px 20px 10px 20px', background: colors.darkBlueBackground, margin: '10px 0' }}>
            <CustomAddressBlock
              classes={addressBlockClasses}
              formikRef={formikRef}
              country={country}
              disabled={disabled}
              values={values}
              setValues={setValues}
            />
          </Grid>

        )
        : null}
    </>
  );
}

CustomOptions.propTypes = {
  formikRef: PropTypes.objectOf(PropTypes.object).isRequired,
  field: PropTypes.shape({ value: PropTypes.string.isRequired }).isRequired,
  form: PropTypes.shape({
    values: PropTypes.objectOf(PropTypes.any).isRequired,
    setValues: PropTypes.func.isRequired,
  }).isRequired,
  country: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    radioLabel: PropTypes.string.isRequired,
    radio: PropTypes.string.isRequired,
    radioDisabled: PropTypes.string.isRequired,
  }).isRequired,
  altClasses: PropTypes.objectOf(PropTypes.string.isRequired).isRequired,
};

function CustomAddressBlock(props) {
  const { disabled, classes, ...other } = props;

  const { addressBlock, sectionHeader } = classes;

  const fields = ['addressLine1', 'addressLine2', 'addressLine3', 'city', 'state', 'zip'];
  if (disabled) other.disabled = fields;

  return (
    <Grid
      container
      direction="column"
      className={addressBlock}
    >
      <Grid item>
        <Typography className={sectionHeader}>Enter Address</Typography>
      </Grid>
      <AddressForm
        fields={['country', 'addressLine1', 'addressLine2', 'addressLine3', 'city']}
        other={{
          ...other,
          classes,
        }}
      />
      <Grid item container spacing={3}>
        <Grid item xs={6}>
          <AddressForm
            fields={['state']}
            other={{
              ...other,
              classes,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <AddressForm
            fields={['zip']}
            other={{
              ...other,
              classes,
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

CustomAddressBlock.propTypes = {
  disabled: PropTypes.bool.isRequired,
  classes: PropTypes.shape({
    addressBlock: PropTypes.string.isRequired,
    sectionHeader: PropTypes.string.isRequired,
  }).isRequired,
};

/**
 * ######################
 *          UTIL
 * ######################
 */

function fieldValidation(value, type) {
  if (type !== 'other') return true;

  return (typeof value !== 'undefined');
}

/**
 * #################################
 *          EXPORT FUNCTION
 * #################################
 */
export default function SenderPickupOptions(props) {
  const {
    selectedTab, pickupOptions, shipmentOrigin = null, shipmentPickupFrom = null, freightType,
  } = props;
  const { onSetPickupDropoff } = props;

  const classes = useStyles();
  const altClasses = NEW_ORDER_SENDER_RECIPIENT_STYLE();

  const display = (selectedTab === pickupOptions) ? 'initial' : null;
  const formikRef = useRef(null);

  useEffect(() => {
    if (!formikRef.current
      || !shipmentPickupFrom
      || formikRef.current.state.values.type === shipmentPickupFrom.type) return;
    formikRef.current.resetForm({
      ...DEFAULT_FORM_VALUES,
      ...(shipmentPickupFrom && { type: shipmentPickupFrom.type }),
      ...(freightType === 'hazmat' && {
        type: shipmentPickupFrom.type === NONE || !shipmentPickupFrom.type ? SENDER : shipmentPickupFrom.type,
      }),
      ...(shipmentPickupFrom
        && shipmentPickupFrom.type === 'other' && {
        addressLine1: shipmentPickupFrom.address.addressLine1,
        addressLine2: shipmentPickupFrom.address.addressLine2 || '',
        addressLine3: shipmentPickupFrom.address.addressLine3 || '',
        city: shipmentPickupFrom.address.city,
        state: shipmentPickupFrom.address.state,
        zip: shipmentPickupFrom.address.zip,
        country: shipmentPickupFrom.address.country,
      }),
    });
  }, [shipmentPickupFrom, formikRef.current]);

  /*          FORMIK PROPS            */

  const validateOnBlur = false;
  const validationSchema = Yup.object().shape({
    // eslint-disable-next-line func-names
    addressLine1: Yup.mixed().test('Success', 'Required', function (value) {
      // eslint-disable-next-line react/no-this-in-sfc
      return (fieldValidation(value, this.parent.type));
    }),
    // eslint-disable-next-line func-names
    city: Yup.mixed().test('Success', 'Required', function (value) {
      // eslint-disable-next-line react/no-this-in-sfc
      return (fieldValidation(value, this.parent.type));
    }),
    // eslint-disable-next-line func-names
    state: Yup.mixed().test('Success', 'Required', function (value) {
      // eslint-disable-next-line react/no-this-in-sfc
      return (fieldValidation(value, this.parent.type));
    }),
    // eslint-disable-next-line func-names
    zip: Yup.mixed().test('Success', 'Required', function (value) {
      // eslint-disable-next-line react/no-this-in-sfc
      return (fieldValidation(value, this.parent.type));
    }),
  });

  function getInitialValues() {
    return {
      ...DEFAULT_FORM_VALUES,
      ...(shipmentOrigin && { country: shipmentOrigin.country }),
      ...(freightType === 'hazmat' && { type: SENDER }),
    };
  }

  async function onSubmit(values, { setSubmitting, resetForm }) {
    await onSetPickupDropoff({ ...values });
    setSubmitting(false);

    resetForm({ ...DEFAULT_FORM_VALUES, ...values });
  }

  function render(renderProps) {
    const { values, isSubmitting } = renderProps;
    const { setValues, submitForm } = renderProps;

    const { country = '' } = values;

    return (
      display && (
      <div style={{ display: 'initial' }}>
        <Divider className={classes.divider} />
        <Form>
          <Grid
            container
            direction="column"
            className={classes.pickupOptions}
          >
            <Grid item className={classes.radioOptionsContainer}>
              <Field
                component={CustomOptions}
                name="type"
                formikRef={formikRef}
                onChange={(type) => setValues({ ...values, type })}
                country={country}
                disabled={isSubmitting}
                classes={classes}
                altClasses={altClasses}
                freightType={freightType}
              />
            </Grid>
            <Grid item>
              <NewOrderNextButton
                disabled={isSubmitting}
                onClick={submitForm}
                data-testid="sender-next-button"
              >
                Next
              </NewOrderNextButton>
            </Grid>
            {(isSubmitting)
              ? (
                <Grid item classes={{ root: altClasses.miscLoadingContainer }}>
                  <CircularProgress color="secondary" />
                </Grid>
              )
              : null}
          </Grid>
        </Form>
      </div>
      )
    );
  }

  return (
    <Formik
      ref={formikRef}
      validateOnBlur={validateOnBlur}
      validationSchema={validationSchema}
      initialValues={getInitialValues()}
      onSubmit={onSubmit}
      render={render}
    />
  );
}

SenderPickupOptions.propTypes = {
  selectedTab: PropTypes.string.isRequired,
  pickupOptions: PropTypes.string.isRequired,
  shipmentOrigin: PropTypes.shape({
    address: PropTypes.shape({
      country: PropTypes.string.isRequired,
    }).isRequired,
  }),
  shipmentPickupFrom: PropTypes.shape({
    type: PropTypes.string.isRequired,
    address: PropTypes.shape({
      addressLine1: PropTypes.string.isRequired,
      addressLine2: PropTypes.string,
      addressLine3: PropTypes.string,
      city: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired,
      zip: PropTypes.string.isRequired,
      country: PropTypes.string.isRequired,
    }).isRequired,
  }),
  onSetPickupDropoff: PropTypes.func.isRequired,
};

SenderPickupOptions.defaultProps = {
  shipmentOrigin: null,
  shipmentPickupFrom: null,
};
