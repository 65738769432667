export const base = 'white';
export const white = '#FFFFFF';
export const red = '#B00020';
export const primaryBlue = '#3395ED';
export const text = '#434449';
export const textGrey = '#979797';
export const indigo = '#3f51b5';
export const indigoDarken10 = '#364495';
export const indigoLighten80 = '#b7c1f8';
export const yellow = '#FED599';
export const warningYellow = '#FFCC00';
export const warningYellowMedium = '#f4f484';
export const warningYellowLight = '#fce799';
export const green = '#4caf50';
export const danger = '#ef5350';
export const logoGrey = 'rgba(255,255,255,0.54)';
export const background = '#151721';
export const midnightBlue = '#020511';
export const linkBlue = '#4CA8F7';
export const darkBackgroundGrey = '#383940';
export const lightBackgroundGrey = '#6A6B73';
export const newOrderFormBackground = '#3B3F53';
export const textLightGrey = '#B5B9CC';
export const textDarkGrey = '#5A5D65';
export const disabledGrey = '#8e9095';
export const inputFieldBackground = '#323546';
export const inputFieldBackgroundAlt = '#2d303e';
export const selectBackgroundAlt = '#4B4E5F';
export const buttonBackgroundBlue = '#2668C2';
export const darkBlueBackground = '#212435';
export const darkBlueBackgroundRGB = '33,36,53';
export const lightBlue = '#4f99ee';
export const checkValidGreen = '#84e17e';
export const textGreen = '#56F06F';
export const mediumBlueBackground = '#3B3F51';
export const newOrderModuleContentBackGround = '#1E202E';
export const newOrderError = '#FF073D';
export const badgeBubbleRed = '#FD1E4F';
export const shipmentCardBackground = '#2A2C3B';
export const secondaryButton = '#94CCFB';
export const dropDownList = '#464b62';
export const actionButtonText = '#3395ed';
export const notificationPanelBackground = '#323543';
export const errorRed = '#f06a6e';
export const failedRed = '#e0666b';
export const limeGreem = '#A7F4B4';
export const darkInputFieldBackground = '#1F2230';
export const newContactButtonBackground = '#2463BA';
export const tableBackground = '#202335';
export const tableGreyText = '#B5B8CC';
export const tableGreyBorder = '#3C3E4E';
export const tooltipBackground = '#2A2C3D';
export const disabledButton = '#767681';
export const lightSkyBlue = '#85CAFB';
