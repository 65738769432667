import React from 'react';
import { Grid, Typography, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import * as colors from '../../styles/colors';
import ScrollWindow from './ScrollWindow';
import { formatDateLong } from '../../utils/helpers';
import { loadDocumentHistory } from '../../utils/documentClient';
import NewOrderErrorDialog from './NewOrderErrorDialog';
import CustomModal from './CustomModal';


const DATE_TIME_LABEL = 'Date/Time';
const EVENT_LABEL = 'Event';

const documentHistoryStyles = {
  documentHistoryContainer: {
    background: colors.darkBlueBackground,
    width: '100%',
    padding: '15px 15px',
    flexWrap: 'nowrap',
    minHeight: '100%',
    height: '300px',
  },
  headerContainer: {
    paddingBottom: '15px',
  },
  headerLabel: {
    fontWeight: 500,
  },
  bodyContainer: {

  },
  eventContainer: {
    padding: '10px 0',
    borderTop: `1px solid ${colors.tableGreyBorder}`,
  },
  customModal: {
    height: 'auto',
    width: 650,
    maxWidth: 'none',
  },
};

const useDocumentHistoryStyles = makeStyles(documentHistoryStyles);

export default function DocumentHistory(props) {
  const {
    documentId, open, subtitle, onClose,
  } = props;
  const classes = useDocumentHistoryStyles();
  const [history, setHistory] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState(null);

  React.useEffect(() => {
    setIsLoading(true);
    loadDocumentHistory(documentId).then((docHist) => {
      setHistory(docHist.reverse());
    }).catch((err) => {
      setError(JSON.stringify({ error: 'Failed to load document history' }));
    }).finally(() => { setIsLoading(false); });
  }, [documentId]);


  return (
    <CustomModal
      open={open}
      title="History"
      subtitle={subtitle}
      onClose={onClose}
      classes={{ root: classes.customModal }}
      enableBackdropClick
      enableEscapeKeyDown
    >
      <Grid item container direction="column" alignItems="center" className={classes.documentHistoryContainer}>

        <Grid item container className={classes.headerContainer}>
          <Grid item xs={5}><Typography color="primary" className={classes.headerLabel}>{DATE_TIME_LABEL}</Typography></Grid>
          <Grid item xs={7}><Typography color="primary" className={classes.headerLabel}>{EVENT_LABEL}</Typography></Grid>
        </Grid>
        {isLoading ? (
          <Grid item container justify="center" alignItems="center" style={{ height: '100%' }}>
            <Grid item>
              <CircularProgress color="secondary" />
            </Grid>
          </Grid>
        ) : (
          <ScrollWindow>
            <Grid item container direction="column" className={classes.bodyContainer}>
              {history && history.map((historyEvent) => (
                <Grid item container className={classes.eventContainer}>
                  <Grid item xs={5}><Typography color="primary" className={classes.eventText}>{formatDateLong(historyEvent.updatedAt)}</Typography></Grid>
                  <Grid item xs={7}><Typography color="primary" className={classes.eventText}>{formatHistoryDescription(historyEvent.description, historyEvent.event)}</Typography></Grid>
                </Grid>
              ))}
            </Grid>
          </ScrollWindow>
        )}
      </Grid>
      <NewOrderErrorDialog
        open={!!error}
        errorContent={error}
        onClose={() => {
          setError(null);
          onClose();
        }}
      />
    </CustomModal>
  );
}

DocumentHistory.propTypes = {
  documentId: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  subtitle: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

function formatHistoryDescription(desc, event) {
  const eventRegExp = new RegExp(event, 'i');
  const match = desc.match(eventRegExp);
  if (match) {
    const startIndex = match.index;
    const endIndex = startIndex + event.length;


    const startDesc = desc.slice(0, startIndex);
    const eventKeyWord = desc.slice(startIndex, endIndex);
    const endDesc = desc.slice(endIndex);

    return (
      <span>
        {startDesc && <span>{startDesc}</span>}
        {eventKeyWord && <span style={{ color: colors.yellow }}>{eventKeyWord}</span>}
        {endDesc && <span>{endDesc}</span>}
      </span>
    );
  }
}
