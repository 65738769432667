import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as colors from '../../styles/colors';

const useStyles = makeStyles(() => ({
  imgContainer: {
    height: 25,
    boxSizing: 'content-box',
  },
  img: {
    height: '25px',
    maxWidth: 'none',
  },
  text: {
    fontSize: '14px',
    fontWeight: '500',
    color: colors.textLightGrey,
    paddingLeft: 2,
  },
}));

const CardSummary = ({ creditCardDetails }) => {
  const classes = useStyles();

  return (
    <Grid item container spacing={1} wrap="nowrap">
      <Grid className={classes.imgContainer} item>
        <img className={classes.img} src={creditCardDetails.imageUrl} alt="" />
      </Grid>
      <Grid item container alignItems="center">
        <Typography className={classes.text}>
          {creditCardDetails.imageUrl ? 'ending in' : 'Credit Card ending in'}
          {' '}
          {creditCardDetails.lastFour}
        </Typography>
      </Grid>
    </Grid>
  );
};

CardSummary.propTypes = {
  creditCardDetails: PropTypes.shape({
    lastFour: PropTypes.string,
    imageUrl: PropTypes.string,
    creditCardNumber: PropTypes.string,
    creditCardCVV: PropTypes.string,
    creditCardExpiration: PropTypes.string,
    creditCardZip: PropTypes.string,
  }).isRequired,
};

export default memo(CardSummary);
