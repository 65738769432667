import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { Grid, Typography } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import { NEW_ORDER_SENDER_RECIPIENT_STYLE } from '../../../styles/style';
import AddressForm from '../../common/AddressForm';
import CustomSelectTextField from '../../common/CustomSelectTextField';
import { triggerRanges } from '../../../utils/packagingUtil';
import ControlledSlider from './ControlledSlider';
import ControlledCheckbox from '../ControlledCheckbox';
import IotHeader from './IotHeader';
import ControlledRadioGroup from './ControlledRadioGroup';
import ControlledRadio from './ControlledRadio';
import ControlledValueDisplay from './ControlledValueDisplay';

const useStyles = makeStyles(() => ({
  standardFormTextfield: {
    width: 415,
  },
  eventCheckboxLabel: {
    fontSize: '14px',
    color: 'white',
    whiteSpace: 'nowrap',
  },
  eventHeader: {
    fontSize: '14px',
    color: 'white',
  },
  checkboxTriggerContainer: {
    margin: '10px 0 0 0',
    flexWrap: 'nowrap',
  },
  shipmentStoppedSelect: {
    width: 'auto',
    minWidth: '200px',
  },
}));

function ConfigureIotForm({
  fieldNames,
  formikRef,
}) {
  const classes = useStyles();
  const addressFormClasses = NEW_ORDER_SENDER_RECIPIENT_STYLE();

  const iotConnectivityOptions = [0, 2, 3, 4, 5]
    .map((n) => ({
      key: `connectivity-${n}`,
      value: !n ? n : `${n}`,
      text: n === 0 ? 'Do not alert' : `${n} Intervals`,
    }));

  const displayTimeFromHours = (hours) => {
    if (hours < 24) {
      if (hours === 1) {
        return `${hours} hour`;
      }
      return `${hours} hours`;
    }
    if (hours >= 168) {
      if (hours / 168 === 1) {
        return '1 week';
      }
      return `${(hours / 168).toFixed(0)} weeks`;
    }
    if (hours === 24) {
      return '1 day ';
    }

    return `${(hours / 24).toFixed(0)} days`;
  };

  const iotShipmentStoppedOptions = [0, 6, 12, 24, 48, 72, 96, 120, 144, 168]
    .map((n) => ({
      key: `shipment-stopped-${n}`,
      value: !n ? n : `${n}`,
      text: n === 0 ? 'Select time' : `${displayTimeFromHours(n)}`,
    }));

  const iotPerimeterOptions = [
    {
      key: 'perimeter-trigger-disabled',
      value: '',
      text: 'Do not alert',
    },
    {
      key: 'inside',
      value: 'VALUE_LESS_THAN',
      text: 'Inside',
    },
    {
      key: 'outside',
      value: 'VALUE_GREATER_THAN',
      text: 'Outside',
    },
  ];

  const [perimeterSliderValue, setPerimeterSliderValue] = React.useState(0);

  return (
    <Grid style={{ margin: '20px 0 0 0' }}>
      <IotHeader text="Configure IoT Device" />
      <Typography
        color="primary"
        className={classes.eventHeader}
        style={{ height: '1em', margin: '20px 0 20px 0' }}
      >
        Shock Events
      </Typography>
      <Grid item xs={6}>
        <Field
          component={ControlledCheckbox}
          name={fieldNames.iotShockTrigger}
          label={(
            <Typography color="primary" style={{}}>
              Alert if tracker experiences a shock event
            </Typography>
            )}
        />
      </Grid>

      <Typography
        color="primary"
        className={classes.eventHeader}
        style={{ height: '1em', margin: '20px 0 20px 0' }}
      >
        Lost/Recovered Connection
      </Typography>
      <Grid item xs={12} container direction="row" alignItems="center" style={{ margin: '0 0 0 0' }}>
        <Typography color="primary" className={classes.eventHeader} style={{ height: '1em' }}>
          Alert if transmission is missed for
        </Typography>
        <Grid item xs={6} style={{ margin: '0 0 0 10px' }}>
          <Field
            component={CustomSelectTextField}
            name={fieldNames.iotConnectivityTrigger}
            label=""
            style={{ width: '100%' }}
            className={classes.standardFormTextfield}
          >
            {iotConnectivityOptions.map((option) => (
              <MenuItem key={option.key} value={option.value}>
                {option.text}
              </MenuItem>
            ))}
          </Field>
        </Grid>
      </Grid>
      <Grid item xs={12} container direction="column" alignItems="left">
        <Typography
          color="primary"
          className={classes.eventHeader}
          style={{ height: '1em', margin: '30px 0 40px 0' }}
        >
          Battery Power
        </Typography>
        <Field
          component={ControlledSlider}
          name={fieldNames.iotBatteryPowerTrigger}
          style={{ width: '100%' }}
          valueLabelFormat={(v) => `${v}%`}
          min={triggerRanges.batteryPower.min}
          max={triggerRanges.batteryPower.max}
        />
      </Grid>
      <Grid item xs={12} container direction="column" alignItems="left">
        <Typography
          color="primary"
          className={classes.eventHeader}
          style={{ height: '1em', margin: '20px 0 40px 0' }}
        >
          Temperature
        </Typography>
        <Field
          component={ControlledSlider}
          name={fieldNames.iotTemperatureTrigger}
          style={{ width: '100%' }}
          valueLabelFormat={(v) => `${v}° F`}
          min={triggerRanges.temperature.min}
          max={triggerRanges.temperature.max}
        />
      </Grid>
      <Grid item xs={12} container direction="column" alignItems="left">
        <Typography
          color="primary"
          className={classes.eventHeader}
          style={{ height: '1em', margin: '20px 0 40px 0' }}
        >
          Humidity
        </Typography>
        <Field
          component={ControlledSlider}
          name={fieldNames.iotHumidityTrigger}
          style={{ width: '100%' }}
          valueLabelFormat={(v) => `${v}%`}
          min={triggerRanges.humidity.min}
          max={triggerRanges.humidity.max}
        />
      </Grid>
      <Typography
        color="primary"
        className={classes.eventHeader}
        style={{ height: '1em', margin: '20px 0 20px 0' }}
      >
        Shipment Alerts
      </Typography>
      <Grid item xs={8} className={classes.checkboxTriggerContainer}>
        <Field
          component={ControlledCheckbox}
          name={fieldNames.iotDepartureTrigger}
          label={(
            <Typography color="primary" className={classes.eventCheckboxLabel}>
              Notify when the package departs the origin address
            </Typography>
            )}
        />
      </Grid>
      <Grid item xs={8} className={classes.checkboxTriggerContainer}>
        <Field
          component={ControlledCheckbox}
          name={fieldNames.iotArrivalTrigger}
          label={(
            <Typography color="primary" className={classes.eventCheckboxLabel}>
              Notify when the package arrives at the final destination
            </Typography>
            )}
        />
      </Grid>
      <Grid item xs={9} className={classes.checkboxTriggerContainer} container direction="row">
        <Field
          component={ControlledCheckbox}
          name={fieldNames.iotShipmentStoppedTriggerEnabled}
          label={(
            <Typography color="primary" className={classes.eventCheckboxLabel}>
              Notify when the shipment stops for more than
            </Typography>
            )}
        />
          &nbsp;
        <Grid item>
          <Field
            component={CustomSelectTextField}
            name={fieldNames.iotShipmentStoppedTriggerValue}
            className={`${classes.standardFormTextfield} ${classes.shipmentStoppedSelect}`}
            InputProps={{
              style: {
                padding: '0 0',
                height: '45px',
              },
            }}
            SelectProps={{ displayEmpty: true }}
          >
            {iotShipmentStoppedOptions.map((option) => (
              <MenuItem key={option.key} value={option.value}>
                {option.text}
              </MenuItem>
            )) }
          </Field>
        </Grid>
      </Grid>
      <br />
      <IotHeader text="Create GeoFence" />
      <Grid item xs={6} container direction="column" alignItems="left" style={{ margin: '20px 0 0 0' }}>
        <Typography color="primary" className={classes.eventHeader} style={{ height: '1em' }}>
          Alert when
        </Typography>
        <Grid item xs={12} style={{ margin: '20px 0 0 0' }}>
          <Field
            component={CustomSelectTextField}
            name={fieldNames.iotPerimeterOperator}
            label=""
            style={{ width: '100%' }}
            className={classes.standardFormTextfield}
            SelectProps={{ displayEmpty: true }}
          >
            {iotPerimeterOptions.map((option) => (
              <MenuItem key={option.key} value={option.value}>
                {option.text}
              </MenuItem>
            ))}
          </Field>
        </Grid>
      </Grid>
      <Grid item xs={9} container direction="column" alignItems="left" style={{ margin: '20px 0 0 0' }}>
        <Typography
          color="primary"
          className={classes.eventHeader}
          style={{ height: '1em', margin: '0 0 10px 0' }}
        >
          Location Name
        </Typography>
        <AddressForm
          fieldNames={{
            name: fieldNames.iotPerimeterAddressName,
          }}
          fields={['name']}
          fieldLabels={{ name: 'Location name' }}
          other={{
            classes: addressFormClasses,
          }}
        />
      </Grid>
      <Grid item xs={9} container direction="column" alignItems="left" style={{ margin: '20px 0 0 0' }}>
        <Typography
          color="primary"
          className={classes.eventHeader}
          style={{ height: '1em', margin: '0 0 10px 0' }}
        >
          Address
        </Typography>
        <AddressForm
          fieldNames={{
            addressLine1: fieldNames.iotPerimeterAddressLine1,
            country: fieldNames.iotPerimeterAddressCountry,
          }}
          fields={['addressLine1']}
          fieldLabels={{ addressLine1: 'Address' }}
          other={{
            classes: addressFormClasses, formikRef, fieldSeparation: 'both',
          }}
        />
      </Grid>
      <Grid item xs={6} container direction="column" alignItems="left">
        <Grid item xs={6} container direction="column" alignItems="left" style={{ margin: '20px 0 0 0 ' }}>
          <Typography color="primary" className={classes.eventHeader} style={{ height: '1em' }}>
            GeoFence Type
          </Typography>
          <Grid item xs={12} style={{ margin: '10px 0 0 0' }}>
            <Field
              component={ControlledRadioGroup}
              name={fieldNames.iotPerimeterShape}
            >
              <ControlledRadio
                label={(
                  <Typography className={classes.eventCheckboxLabel}>
                    Simple Radius
                  </Typography>
                )}
                value="radius"
              />
            </Field>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} container direction="column" alignItems="left" style={{ margin: '20px 0 0 0 ' }}>
        <Typography color="primary" className={classes.eventHeader} style={{ height: '1em' }}>
          Tolerance
        </Typography>
        <Grid item xs={12} container direction="row" alignItems="center" style={{ margin: '20px 0 0 0 ' }}>
          <Grid item xs={5} style={{ margin: '20px 0 0 0' }}>
            <Field
              component={ControlledSlider}
              name={fieldNames.iotPerimeterRadius}
              style={{ width: '100%' }}
              valueLabelFormat={(v) => `${v}km`}
              step={0.1}
              min={0}
              max={50}
              onChange={(e, v) => setPerimeterSliderValue(v)}
            />
          </Grid>
          <Grid item xs={7} container direction="row" style={{ padding: '0 0 0 20px' }}>
            <ControlledValueDisplay
              style={{ fontSize: 14 }}
              transform={(v) => `${(v / 1.609).toFixed(2)} Miles (mi)`}
              value={perimeterSliderValue}
            />
              &nbsp;
              &nbsp;
              &nbsp;
            <ControlledValueDisplay
              style={{ fontSize: 14 }}
              transform={(v) => `${(v * 1000).toFixed(0)} Meters (m)`}
              value={perimeterSliderValue}
            />
          </Grid>

        </Grid>
      </Grid>
    </Grid>
  );
}

ConfigureIotForm.propTypes = {
  fieldNames: PropTypes.objectOf(PropTypes.any).isRequired,
  formikRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]).isRequired,
};

export default memo(ConfigureIotForm);
