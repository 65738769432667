/* eslint-disable react/prop-types */
import React, { useContext } from 'react';
import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  Typography,
  ExpansionPanelDetails,
  DialogContent,
  DialogContentText,
  CircularProgress,
  IconButton,
  Backdrop,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import OutlinedTruckIcon from '@material-ui/icons/LocalShippingOutlined';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useHotkeys } from 'react-hotkeys-hook';
import { useHistory } from 'react-router-dom';
import { NEW_ORDER_PANE_STYLE, NEW_ORDER_CONFIRMATION_STYLE } from '../styles/style';
import NewOrderExpansionPaneLabel from './common/NewOrderExpansionPaneLabel';
import {
  SingleOrderStateContext,
  useSingleOrderState,
  useSingleOrderDispatch,
  updateConfirmationForShipment,
  updateConfirmationForShipmentIpd,
} from '../context/singleOrderContext';
import * as colors from '../styles/colors';
import NewOrderAlertDialog from './common/NewOrderAlertDialog';
import NewOrderErrorDialog from './common/NewOrderErrorDialog';
import BlockedPanelDisplay from './common/PanelWarning';
import AddressRatesTable from './AddressRatesTable';
import TrackingModal from './TrackingModal';
import IpdAlertDialog from './IpdAlertDialog';
import { SHIPMENT_TRACKING_TAB } from '../clientConstants';

const styles = {
  innerContainer: {
    backgroundColor: colors.darkBlueBackground,
    padding: '15px',
  },
  innerContainerTitle: {
    fontSize: '18px',
    color: colors.white,
  },
  innerContainerPackageType: {
    fontSize: '14px',
    color: '#B5B8CC',
  },
  trackShipment: {
    color: colors.secondaryButton,
    fontSize: '14px',
    fontWeight: '500',
    textTransform: 'none',
    marginRight: '5px',
  },
  trackingIcon: {
    fontSize: '1rem',
    color: '#94CCFB',
  },
  confirmationButton: {
    '&.Mui-disabled': {
      backgroundColor: colors.buttonBackgroundBlue,
    },
  },
  confirmationText: {
    fontSize: 14,
    color: '#B5B9CC',
  },
  panelContent: {
    padding: '10px 0 0 10px',
  },
  buttonContainer: {
    marginTop: 20,
  },
  progress: {
    marginTop: 20,
  },
};
const delay = (ms) => new Promise((res) => setTimeout(res, ms));

const useStyles = makeStyles((theme) => ({
  ...styles,
  backdrop: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const ConfirmationPane = ({
  label,
  shipmentId,
  shipment,
  isComplete,
  isSelected,
  readOnly,
  isReadyToBeCompleted,
  isComplianceFailure,
  isDisabled,
  openNextPanel,
  onChange,
  blockingMessage,
  packFormRef,
  ...shipmentProps
}) => {
  const classes = NEW_ORDER_PANE_STYLE();
  const subClasses = NEW_ORDER_CONFIRMATION_STYLE();
  const localClasses = useStyles();

  const [isError, setIsError] = React.useState(false);
  const [errorContent, setErrorContent] = React.useState(null);
  const [isAlert, setIsAlert] = React.useState(false);
  const [ipdAlert, setIpdAlert] = React.useState(false);

  const [loading, setLoading] = React.useState(false);
  const [loader, setLoader] = React.useState(false);

  const [currentTrackingTab, setCurrentTrackingTab] = React.useState(
    SHIPMENT_TRACKING_TAB,
  );

  const [trackingModalState, setTrackingModalState] = React.useState({
    anchorEl: null,
    shipmentId: null,
    trackingNumber: null,
    iotTrackingNumber: null,
    openTab: SHIPMENT_TRACKING_TAB,
  });
  const history = useHistory();
  const singleOrderDispatch = useSingleOrderDispatch();
  const context = useContext(SingleOrderStateContext);
  const packaging = isComplete ? shipment.packaging : null;
  const isMultiAddressAndCompleted = isComplete && has(shipment, 'carrier.details.results') && has(shipment, 'destination.addresses');
  const successResults = isMultiAddressAndCompleted ? shipment.carrier.details.results.filter((result) => result.isSuccess) : [];
  const failedResults = isMultiAddressAndCompleted ? shipment.carrier.details.results.filter((result) => !result.isSuccess) : [];

  useHotkeys(
    'f2',
    async () => {
      if (
        shipment.status === 'Created'
        && shipment?.account === 'worldemblem'
      ) {
        if (!loading) {
          setLoader(true);
          await handleSubmit();
        }
      }
    },
    { enableOnTags: ['INPUT', 'TEXTAREA', 'SELECT'] },
  );

  const handleIpdSubmit = async (expectedShipDate) => {
    setLoading(true);
    try {
      setIpdAlert(false);
      await updateConfirmationForShipmentIpd({
        dispatch: singleOrderDispatch,
        shipmentId,
        expectedShipDate,
      });
      setLoading(false);
      openNextPanel();
      if (shipment?.account === 'worldemblem') {
        setTimeout(() => { history.goBack(); }, 2000);
      }
    } catch (e) {
      setLoading(false);
      setErrorContent(e.message);
      setIsError(true);
    }
  };
  const handleSubmit = async () => {
    setLoading(true);
    try {
      if (shipment?.account === 'worldemblem') {
        if (packFormRef.current) {
          await packFormRef.current.setFieldValue('notShowNext', true);
          if (!packFormRef.current.state.values.packages[0].referenceNumber1) {
            await packFormRef.current.setFieldValue(
              'packages[0].referenceNumber1',
              '123',
            );
          }
          await packFormRef.current.handleSubmit();
        }
        await delay(2500);
      }
      const updateResponse = await updateConfirmationForShipment({
        dispatch: singleOrderDispatch,
        shipmentId,
      });
      if (shipment?.account === 'worldemblem') {
        if (updateResponse?.shipment?.carrier?.details?.ipd && has(updateResponse?.shipment, 'isCompleteShipment') && updateResponse?.shipment?.isCompleteShipment === false) {
          setIpdAlert(true);
          return;
        }
      }
      setLoading(false);
      if (shipment?.account !== 'worldemblem') { setIsAlert(true); }
      openNextPanel();
      if (shipment?.account === 'worldemblem') {
        setTimeout(() => { setLoader(false); history.goBack(); }, 2000);
      }
    } catch (e) {
      setLoader(false);
      setLoading(false);
      setErrorContent(e.message);
      setIsError(true);
    }
  };
  const expandIcon = (
    <ExpandMoreIcon
      color="primary"
      classes={{ colorPrimary: classes.expansionIcon }}
    />
  );

  const alertContent = (
    <DialogContent>
      <DialogContentText
        id="alert-dialog-shipment-confirmed"
        component="div"
        classes={{ root: subClasses.dialogContentContainer }}
      >
        <Typography color="primary">
          Successfully submitted shipment
        </Typography>
      </DialogContentText>

    </DialogContent>
  );
  const handleTrackingClose = () => {
    setTrackingModalState({
      shipmentId: null,
      trackingNumber: null,
      anchorEl: null,
      iotTrackingNumber: null,
      openTab: SHIPMENT_TRACKING_TAB,
    });
  };

  const openTrackingModal = ({
    // eslint-disable-next-line no-shadow
    shipmentId,
    trackingNumber,
    anchorEl,
    iotTrackingNumber,
    openTab,
  }) => {
    setTrackingModalState({
      shipmentId,
      trackingNumber,
      anchorEl,
      iotTrackingNumber,
      openTab,
    });
    setCurrentTrackingTab(openTab);
  };

  const getPanelDetailsLabel = () => {
    if (!isComplete) return null;

    const defaultText = 'Shipment request is successfully processed.';
    const successText = successResults.length > 0 ? `Success: ${successResults.length}. ` : '';
    const failedText = failedResults.length > 0 ? `Failure: ${failedResults.length}` : '';

    return !isEmpty(successText) || !isEmpty(failedText)
      ? `${defaultText} (${successText}${failedText})`
      : defaultText;
  };

  return (
    <>
      <Backdrop className={classes.backdrop} open={shipment?.account === 'worldemblem' && loader}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <TrackingModal
        handleClose={handleTrackingClose}
        id={`tracking-${trackingModalState.shipmentId || ''}`}
        trackingModalState={trackingModalState}
        currentTab={currentTrackingTab}
        setCurrentTab={setCurrentTrackingTab}
      />
      <div className={classes.root}>
        <ExpansionPanel
          expanded={isSelected}
          className={classes.expansionPanel}
          onChange={onChange}
          disabled={isDisabled}
        >
          <ExpansionPanelSummary
            className={classes.expansionSummaryHeader}
            expandIcon={expandIcon}
            aria-controls="panel1a-content"
            id="panel1a-header"
            data-testid="confirmation-expansion-panel-summary"
          >
            <NewOrderExpansionPaneLabel
              label={label}
              complete={isComplete}
              details={getPanelDetailsLabel()}
            />
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.expansionPanelDetails}>
            <Grid container className={localClasses.panelContent}>
              {!isReadyToBeCompleted ? (
                <BlockedPanelDisplay message={blockingMessage} />
              ) : (
                <>
                  {!isComplete && !readOnly && (
                    <>
                      <Grid item xs={12}>
                        <Typography className={localClasses.confirmationText}>
                          Please confirm that all the information above is
                          correct before submitting.
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        container
                        direction="column"
                        alignItems="center"
                      >
                        <Grid
                          container
                          direction="row"
                          justify="center"
                          className={localClasses.buttonContainer}
                        >
                          <Button
                            color="secondary"
                            variant="contained"
                            disabled={
                              loading
                              || !isReadyToBeCompleted
                              || !(
                                shipment
                                && (shipment.isCreatedBy
                                  || shipment.isEHS
                                  || shipment.isECO)
                              )
                            }
                            className={localClasses.confirmationButton}
                            onClick={() => handleSubmit()}
                          >
                            COMPLETE SHIPMENT
                          </Button>
                        </Grid>
                        {loading && (
                          <Grid item className={localClasses.progress}>
                            <CircularProgress color="secondary" />
                          </Grid>
                        )}
                      </Grid>
                    </>
                  )}
                  {isComplete && !isMultiAddressAndCompleted && (
                    <Grid className={localClasses.innerContainer} item container>
                      {packaging && packaging.details.packages.map((p, idx) => (
                        <>
                          <Grid item xs={12}>
                            <Typography className={localClasses.innerContainerTitle}>
                              Package
                              {' '}
                              {idx + 1}
                              {' '}
                              of
                              {' '}
                              {packaging.details.packages.length}
                            </Typography>
                          </Grid>
                          <Grid container justifyContent="space-between">
                            <Grid item>
                              <Typography className={localClasses.innerContainerPackageType}>
                                {p.packagingType}
                                {' '}
                                {p.packagingName}
                                {' '}
                                -
                                {' '}
                                {p.carrierLabel}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Button
                                onClick={(e) => {
                                  openTrackingModal({
                                    shipmentId,
                                    trackingNumber: p.trackingNumber,
                                    anchorEl: e.currentTarget,
                                    iotTrackingNumber: false,
                                    openTab: SHIPMENT_TRACKING_TAB,
                                  });
                                }}
                                classes={{ root: localClasses.trackingButton }}
                              >
                                <Typography className={localClasses.trackShipment}>
                                  {p.trackingNumber}
                                </Typography>
                                <OutlinedTruckIcon className={localClasses.trackingIcon} />
                              </Button>
                            </Grid>
                          </Grid>
                        </>
                      ))}
                    </Grid>
                  )}
                  {isComplete
                    && readOnly
                     && shipment?.isDocumentsGenerated
                      && shipment?.carrier?.details
                        ?.results?.length > 0 && (
                        <>
                          <Grid item xs={12}>
                            <AddressRatesTable
                              rows={shipment?.carrier?.details?.results}
                              confirmed
                              openTrackingModal={openTrackingModal}
                              shipmentId={shipmentId}
                            />
                          </Grid>
                        </>
                  )}
                </>
              )}
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
      <NewOrderErrorDialog
        open={isError}
        errorContent={errorContent}
        classes={subClasses}
        onClose={() => {
          setIsError(false);
          setErrorContent(null);
        }}
      />
      {/* <NewOrderAlertDialog
        open={isAlert}
        alertContent={alertContent}
        classes={subClasses}
        onClose={() => {
          setIsAlert(false);
          openNextPanel();
        }}
      /> */}
      <IpdAlertDialog
        open={ipdAlert}
        classes={subClasses}
        onSubmit={handleIpdSubmit}
        onClose={() => {
          setIpdAlert(false);
          setLoading(false);
        }}
      />
    </>
  );
};

ConfirmationPane.propTypes = {
  label: PropTypes.string.isRequired,
  formName: PropTypes.string.isRequired,
  parentFormName: PropTypes.string.isRequired,
  shipmentId: PropTypes.string.isRequired,
  setSelectedForms: PropTypes.func.isRequired,
  // eslint-disable-next-line react/require-default-props
  selectedForms: PropTypes.shape({
    child: PropTypes.string,
    parent: PropTypes.string.isRequired,
  }),
};

ConfirmationPane.defaultProps = {};

export default ConfirmationPane;
