import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import { FORM_FIELDS, FORM_LABELS } from '../itemDetailsConstants';
import { isFieldEditable } from '../itemDetailsUtil';
import { useItemProductDetailsFormStyles } from '../itemDetailsStyles';
import CustomCountrySelectField from '../../common/CustomCountrySelectField';

export default function CountryOfManufacture({
  editableFields, countries, hiddenFields = [],
}) {
  const classes = useItemProductDetailsFormStyles();

  return !hiddenFields.includes(FORM_FIELDS.countryOfManufacture.key) ? (
    <Grid item container direction="column" spacing={1}>
      <Grid item>
        <Typography color="primary" className={classes.formFieldHeader}>
          {FORM_LABELS.countryOfManufacture}
        </Typography>
      </Grid>
      <Grid item xs={5}>
        <CustomCountrySelectField
          name={FORM_FIELDS.countryOfManufacture.key}
          label={FORM_FIELDS.countryOfManufacture.label}
          countries={countries}
          disabled={isFieldEditable(FORM_FIELDS.countryOfManufacture.key, editableFields)}
        />
      </Grid>
    </Grid>
  ) : (<></>);
}

CountryOfManufacture.propTypes = {
  editableFields: PropTypes.arrayOf(PropTypes.string).isRequired,
  countries: PropTypes.arrayOf(PropTypes.any).isRequired,
  hiddenFields: PropTypes.arrayOf(PropTypes.string).isRequired,
};
