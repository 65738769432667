import client from './apiClient';
import { DUMMY_DOC_HISTORY } from '../dummyData';

function loadDocumentHistory(documentId) {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    // return Promise.resolve(DUMMY_DOC_HISTORY);
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(DUMMY_DOC_HISTORY);
        // reject(new Error('failed'));
      }, 1000);
    });
  }
  return client(`/data/document/history/${documentId}`, { method: 'get' });
}

export { loadDocumentHistory };
