import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Divider from '@material-ui/core/Divider';
import * as colors from '../../styles/colors';
import ReadOnlyIcon from './ReadOnlyIcon';

const useStyles = makeStyles(() => ({
  container: {
    paddingTop: '18px',
  },
  secondaryLabel: {
    color: 'rgba(255, 255, 255, 0.6)',
    fontSize: '12px',
    fontWeight: '500px',
    letterSpacing: '0.4px',
    paddingLeft: '8px',
  },
  fieldContent: {
    color: colors.white,
    fontSize: '14px',
    letterSpacing: '0.25px',
  },
  iconContainer: {
    paddingRight: '24px',
  },
}));

function getSocialMediaLink(platform, username) {
  switch (platform) {
    case 'linkedin': return `https://linkedin.com/in/${username}`;
    case 'facebook': return `https://facebook.com/${username}`;
    case 'twitter': return `https://twitter.com/${username}`;
    default: return `https://${platform}.com/${username}`;
  }
}

const StyledDivider = withStyles({
  root: {
    backgroundColor: 'rgba(255, 255, 255, 0.12)',
    height: 1,
    marginTop: 14,
  },
})(Divider);

function ReadOnlyTextField({
  type, name, label, secondaryLabel, value, iconName, icon,
}) {
  const classes = useStyles();

  const fieldNotComplete = () => !value;

  const fieldHasSecondaryLabel = () => !!secondaryLabel;

  return (
    <Grid container className={classes.container}>
      <Grid xs={12} container item alignItems="center">
        <Grid
          item
          className={classes.iconContainer}
        >
          <ReadOnlyIcon iconName={iconName} icon={icon} />
        </Grid>
        <Grid item>
          <Typography className={classes.fieldContent}>
            {type === 'link'
              ? <Link href={getSocialMediaLink(name, value)}>{value}</Link>
              : value}
          </Typography>
        </Grid>
        {(fieldNotComplete() || fieldHasSecondaryLabel())
          && (
            <Grid item>
              <Typography className={classes.secondaryLabel}>
                {!value ? label : secondaryLabel}
              </Typography>
            </Grid>
          )}
      </Grid>
      <Grid item xs={12}>
        <StyledDivider />
      </Grid>
    </Grid>
  );
}

ReadOnlyTextField.defaultProps = {
  type: null,
  label: null,
  secondaryLabel: null,
  icon: null,
};

ReadOnlyTextField.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  secondaryLabel: PropTypes.string,
  value: PropTypes.string.isRequired,
  iconName: PropTypes.string.isRequired,
  icon: PropTypes.element,
};

export default memo(ReadOnlyTextField);
