import React from 'react';
import * as braintree from 'braintree-web';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

function PaypalDropin(props) {
  const {
    submit, setFieldValue, amount, currencyCode, clientInstance,
  } = props;

  React.useEffect(() => {
    if (!clientInstance) return;
    braintree.paypalCheckout.create({
      client: clientInstance,
    }).then(async (paypalCheckoutInstance) => {
      await paypalCheckoutInstance.loadPayPalSDK({
        intent: 'authorize',
        currency: currencyCode, // Make sure this value matches the value in createPayment
        'disable-funding': 'card,credit',
      });
      window.paypal.Buttons({
        createOrder: () => paypalCheckoutInstance.createPayment({
          flow: 'checkout',
          currency: currencyCode,
          amount,
          intent: 'authorize',
          enableShippingAddress: false,
          shippingAddressEditable: false,
        }),
        onApprove: async (data, actions) => {
          const payload = await paypalCheckoutInstance.tokenizePayment(data);
          setFieldValue('paymentNonce', payload.nonce);
          submit();
        },
        onError: (err) => {
          console.log(err);
        },
      }).render('#paypal-button');
    }).catch((err) => {
      console.log(err);
    });
  }, [clientInstance]);
  return (
    <Grid container>
      <div id="paypal-button" />
    </Grid>
  );
}

PaypalDropin.propTypes = {
  setFieldValue: PropTypes.func,
  submit: PropTypes.func,
  amount: PropTypes.string,
  currencyCode: PropTypes.string,
  clientInstance: PropTypes.objectOf(PropTypes.any),
};

PaypalDropin.defaultProps = {
  amount: '',
  setFieldValue: () => {},
  submit: () => {},
  currencyCode: 'USD',
  clientInstance: null,
};
export default PaypalDropin;
