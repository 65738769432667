import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid, IconButton, Typography,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { makeStyles } from '@material-ui/core/styles';
import * as colors from '../../styles/colors';
import DefaultAddress from '../DefaultAddress';
import { edit } from '../../utils/addressBookClient';
import { sanitizeAddress } from '../../utils/helpers';
import {
  useUserDispatch,
  loadUser,
} from '../../context/userContext';

const COMMON_BUTTON_STYLES = {
  minWidth: 114,
  height: 36,
  fontWeight: 'bold',
  borderRadius: 8,
};

const useStyles = makeStyles(() => ({
  card: {
    width: '100%',
    backgroundColor: colors.darkBlueBackground,
    padding: '20px 15px 0',
    marginTop: '1rem',
  },
  header: {
    fontSize: 20,
    fontWeight: 500,
    color: colors.white,
    letterSpacing: '0.25px',
  },
  innerContainer: {
    backgroundColor: colors.darkBlueBackground,
    margin: '2rem 0',
    width: '100%',
    borderRadius: '8px',
  },
  tableContainer: {
    overflow: 'hidden',
    position: 'relative',
  },
  row: {
    display: 'flex',
    justifyContent: 'center',
  },
  table: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 'auto',
    flexGrow: '2',
    overflow: 'hidden',
  },
  tableBody: {
    position: 'relative',
    height: '175px',
  },
  cell: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    border: 'none',
    borderBottom: `1px solid ${colors.tableGreyBorder}`,
  },
  emptyTraining: {
    justifyContent: 'center',
  },
  tableCellHeader: {
    color: colors.tableGreyText,
  },
  tableCellBody: {
    fontSize: 14,
    color: colors.white,
  },
  buttonsContainer: {
    width: 270,
    marginBottom: '20px',
  },
  primaryButton: {
    ...COMMON_BUTTON_STYLES,
    backgroundColor: colors.buttonBackgroundBlue,
    color: colors.white,
  },
  secondaryButton: {
    ...COMMON_BUTTON_STYLES,
    border: `1px solid ${colors.buttonBackgroundBlue}`,
    color: colors.secondaryButton,
  },
  progress: {
    marginTop: '15px',
  },
  addressDetails: {
    fontSize: '14px',
    color: colors.textLightGrey,
  },
}));

const ProfileDefaultAddress = ({
  user, isReadOnly, isEditable, onSubmit,
}) => {
  const classes = useStyles();
  const [readOnly, setReadOnly] = useState(isReadOnly);
  const userDispatch = useUserDispatch();

  useEffect(() => {
    setReadOnly(isReadOnly);
  }, [isReadOnly]);

  const clearedModalState = {
    type: 'EDIT',
    addressId: '',
    values: '',
  };
  const [modalState, setModalState] = React.useState(clearedModalState);
  useEffect(() => {
    setModalState({ type: 'EDIT', addressId: user?.defaultAddress?.addressId, values: user?.defaultAddress || '' });
  }, [user?.defaultAddress]);

  const updateDefaultAddress = async (contact) => {
    const sanitizedAddress = sanitizeAddress({ ...contact, addressId: modalState.addressId });
    const updatedContacts = await edit(sanitizedAddress);
    if (updatedContacts) {
      loadUser(userDispatch);
      setReadOnly(true);
    }
  };
  return (
    <>
      <Grid xs={12} container item justify={isEditable ? 'space-between' : 'flex-start'} alignItems="center">
        <Grid item>
          <Typography className={classes.header}>Default Address</Typography>
        </Grid>
        {isEditable && (
          <Grid item>
            <IconButton onClick={() => setReadOnly(false)}>
              <EditIcon />
            </IconButton>
          </Grid>
        )}
      </Grid>
      <Grid container item className={`${classes.innerContainer} ${classes.tableContainer}`}>
        {readOnly
          ? (
            <Grid item container direction="column" style={{ marginBottom: 5, marginTop: 10, marginLeft: 10 }}>
              <Grid item>
                <Typography className={classes.addressDetails}>
                  {user.defaultAddress?.name}
                </Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.addressDetails}>
                  {user.defaultAddress?.addressLine1}
                </Typography>
              </Grid>
              {user.defaultAddress?.addressLine2 && (
              <Grid item>
                <Typography className={classes.addressDetails}>
                  {user.defaultAddress?.addressLine2}
                </Typography>
              </Grid>
              )}
              {user.defaultAddress?.addressLine3 && (
              <Grid item>
                <Typography className={classes.addressDetails}>
                  {user.defaultAddress?.addressLine3}
                </Typography>
              </Grid>
              )}
              <Grid item>
                <Typography className={classes.addressDetails}>
                  {user.defaultAddress?.city}
                  {', '}
                  {user.defaultAddress?.state}
                  {' '}
                  {user.defaultAddress?.zip}
                </Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.addressDetails}>
                  { user.defaultAddress?.country}
                </Typography>
              </Grid>
            </Grid>
          )
          : (
            <DefaultAddress
              type={modalState.type}
              updateDefaultAddress={updateDefaultAddress}
              addressId={modalState.addressId}
              values={modalState.values}
              setReadOnly={setReadOnly}
            />
          )}
      </Grid>
    </>
  );
};

ProfileDefaultAddress.defaultProps = {
  user: null,
  isReadOnly: false,
  isEditable: false,
  onSubmit: null,
};

ProfileDefaultAddress.propTypes = {
  user: PropTypes.shape(PropTypes.object),
  isReadOnly: PropTypes.bool,
  isEditable: PropTypes.bool,
  onSubmit: PropTypes.func,
};

export default memo(ProfileDefaultAddress);
