import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { CircularProgress, Grid, InputAdornment } from '@material-ui/core';
import ArrowDown from '@material-ui/icons/KeyboardArrowDown';
import { Field } from 'formik';
import { DebounceInput } from 'react-debounce-input';
import { CustomSearchWithDropdown } from './InputComponents';
import { loadCountryStates } from '../../utils/miscClient';

const EMPTY_DATA = {
  items: [],
  filtered: [],
};

const CustomStateSelectField = ({
  name, label, selectedCountry, disabled,
}) => {
  const [statesData, setStatesData] = useState(EMPTY_DATA);
  const [isLoading, setIsLoading] = useState(false);

  const getFormattedFilteredData = (items) => items.map((s) => ({
    ...s,
    value: s.stateCode,
    label: `${s.description} ${s.stateCode === '' ? '' : `(${s.stateCode})`}`,
  }));

  const handleStateSearch = (e) => {
    const { value } = e.target;
    let data = statesData.items;

    if (!isEmpty(value)) {
      const lowerCaseValue = value.toLowerCase();
      data = data.filter((s) => s.stateCode.toLowerCase().startsWith(lowerCaseValue)
          || s.description.toLowerCase().startsWith(lowerCaseValue));
    }

    setStatesData({
      items: statesData.items,
      filtered: getFormattedFilteredData(data),
    });
  };

  const handleFocus = () => {
    if (!isEmpty(statesData.items)) {
      setStatesData({
        items: statesData.items,
        filtered: getFormattedFilteredData(statesData.items),
      });
    }
  };

  useEffect(() => {
    if (!isEmpty(selectedCountry)) {
      setIsLoading(true);

      loadCountryStates(selectedCountry).then((data) => {
        setStatesData({
          items: data,
          filtered: [],
        });
        setIsLoading(false);
      }).catch(() => {
        setStatesData(EMPTY_DATA);
        setIsLoading(false);
      });
    }
  }, [selectedCountry]);

  return (
    <Field>
      {({ form }) => {
        const { setFieldValue } = form;
        const selected = get(form, `values.${name}`, '');
        const selectedState = !isEmpty(selected)
          ? statesData.items.find((s) => s.stateCode === selected)
          : '';

        return (
          <Grid item key={`selectField-${name}`} style={{ padding: '12px 0' }}>
            <DebounceInput
              name={name}
              label={label}
              value={`${get(selectedState, 'description', '')} ${get(selectedState, 'stateCode', '') === '' ? '' : `(${get(selectedState, 'stateCode', '')})`}`}
              minLength={2}
              debounceTimeout={500}
              form={form}
              field={{ name }}
              inputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {isLoading
                      ? <CircularProgress style={{ color: 'white', width: '20px', height: '20px' }} />
                      : <ArrowDown color="primary" style={{ pointerEvents: 'none' }} />}
                  </InputAdornment>
                ),
                inputProps: { autocomplete: 'off' },
              }}
              element={CustomSearchWithDropdown}
              dropdownList={statesData.filtered}
              onSelect={({ value }) => {
                setFieldValue(name, value);
                setStatesData({
                  items: statesData.items,
                  filtered: [],
                });
              }}
              onChange={handleStateSearch}
              onFocus={handleFocus}
              onClickOutside={() => {
                setStatesData({
                  items: statesData.items,
                  filtered: [],
                });
              }}
              disabled={isEmpty(selectedCountry) || disabled}
            />
          </Grid>
        );
      }}
    </Field>
  );
};

CustomStateSelectField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  selectedCountry: PropTypes.string,
  disabled: PropTypes.bool,
};

CustomStateSelectField.defaultProps = {
  selectedCountry: '',
  disabled: false,
};

export default CustomStateSelectField;
