/* eslint-disable no-eval */
/* eslint-disable react/prop-types */
import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
import { Field } from 'formik';
import {
  Grid, InputAdornment, MenuItem,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import { DebounceInput } from 'react-debounce-input';
import { CustomSearchWithDropdown } from '../../../../common/InputComponents';
import CustomSelectTextField from '../../../../common/CustomSelectTextField';
import NewOrderCustomTextField from '../../../../common/NewOrderCustomTextField';
import ProductWeight from '../../Weight';
import {
  BATTERY_MEASUREMENT_UNIT_OPTIONS,
  BATTERY_SMALL_MEASUREMENT_UNIT_OPTIONS,
  EMPTY_BATTERY_ITEM_OBJECT,
  FORM_FIELDS_BATTERIES,
  FORM_LABELS,
  ITEM_WEIGHT_KEY,
  SEARCH_INPUT_MIN_LENGTH,
  TRANSPORTATION_OPTIONS_BATTERY,
  TRANSPORTATION_OPTIONS_NAME_KEY,
  TRANSPORTATION_OPTION_CARGO_VALUE,
} from '../../../itemDetailsConstants';
import TransportationOptions from './TransportationOptions';
import Description from '../../Description';
import {
  getDefaultBatteryShipmentWeight,
} from '../../../itemDetailsUtil';

const useStyles = makeStyles(() => ({
  productWeightContainer: {
    marginTop: '1rem',
  },
  netValue: {
    marginTop: '1.5rem',
  },
}));

function HazmatBatteries({
  formikProps,
  fields,
  editableFields,
  filteredItemNameOptions,
  currencyUnits,
  onSelect,
  onSearch,
  searchResult,
}) {
  const classes = useStyles();
  const [batteryWeightUnits, setBatteryWeightUnits] = useState({
    units: BATTERY_MEASUREMENT_UNIT_OPTIONS,
    smallUnits: BATTERY_SMALL_MEASUREMENT_UNIT_OPTIONS,
  });
  const disableTransportationOptions = get(
    formikProps?.values,
    'disableTransportationOptions',
    false,
  );

  const handleProductChange = (item) => {
    const { key } = fields.materialName;
    const weightObj = getDefaultBatteryShipmentWeight(
      item,
      formikProps.values,
    );
    const initialValues = { ...EMPTY_BATTERY_ITEM_OBJECT, ...weightObj };
    const transportationMode = has(formikProps?.values, TRANSPORTATION_OPTIONS_NAME_KEY)
      && !isEmpty(formikProps?.values[TRANSPORTATION_OPTIONS_NAME_KEY])
      ? formikProps?.values[TRANSPORTATION_OPTIONS_NAME_KEY]
      : TRANSPORTATION_OPTION_CARGO_VALUE;

    if (disableTransportationOptions) { initialValues[TRANSPORTATION_OPTIONS_NAME_KEY] = transportationMode; }

    formikProps.resetForm(initialValues);
    formikProps.setFieldValue(key, item[key]);

    if (!disableTransportationOptions) {
      formikProps.setFieldValue(
        TRANSPORTATION_OPTIONS_NAME_KEY,
        TRANSPORTATION_OPTION_CARGO_VALUE,
      );
    }
    onSelect(item);
  };

  const handleUnitsInput = (e) => {
    const currentUnitValue = e.target.value;
    if (currentUnitValue) {
      // eslint-disable-next-line no-eval
      if (formikProps?.values?.noOfUnits && formikProps?.values?.noOfUnits !== 'No Limits') {
        if (!eval(`${currentUnitValue} ${formikProps?.values?.noOfUnits}`)) {
          const updatedProduct = searchResult.find(
            (a) => a.itemName === formikProps?.values?.itemName
              && eval(`${currentUnitValue} ${a?.noOfUnits}`),
          );
          if (updatedProduct) {
            handleProductChange(updatedProduct);
          }
        }
      }
    }
  };

  return (
    <>
      <Grid item container spacing={1}>
        <Grid item xs={12}>
          <DebounceInput
            name={fields.materialName.key}
            label={fields.materialName.label}
            value={formikProps?.values[fields.materialName.key]}
            minLength={SEARCH_INPUT_MIN_LENGTH}
            debounceTimeout={300}
            form={formikProps}
            field={{ name: fields.materialName.key }}
            dropdownList={filteredItemNameOptions}
            inputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon color="primary" />
                </InputAdornment>
              ),
              inputProps: { autocomplete: 'off' },
            }}
            element={CustomSearchWithDropdown}
            onSelect={handleProductChange}
            onChange={onSearch}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            name={fields.units.key}
            label={fields.units.label}
            component={NewOrderCustomTextField}
            type="text"
            style={{ width: '100%' }}
            disabled={!formikProps.values.itemName}
            customOnChange={handleUnitsInput}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            name={fields.unNo.key}
            label={fields.unNo.label}
            component={NewOrderCustomTextField}
            type="text"
            style={{ width: '100%' }}
            disabled
          />
        </Grid>
        <Grid item xs={4}>
          <Field
            name={fields.classDivision.key}
            label={fields.classDivision.label}
            component={NewOrderCustomTextField}
            type="text"
            style={{ width: '100%' }}
            disabled
          />
        </Grid>
        <Grid item xs={4}>
          <Field
            name={fields.packingGroup.key}
            label={fields.packingGroup.label}
            component={NewOrderCustomTextField}
            type="text"
            style={{ width: '100%' }}
            disabled
          />
        </Grid>
        <Grid item xs={4}>
          <Field
            name={fields.packingInstructions.key}
            label={fields.packingInstructions.label}
            component={NewOrderCustomTextField}
            type="text"
            style={{ width: '100%' }}
            disabled
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            name={fields.properShippingName.key}
            label={fields.properShippingName.label}
            component={NewOrderCustomTextField}
            type="text"
            style={{ width: '100%' }}
            disabled
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            name={fields.labels.key}
            label={fields.labels.label}
            component={NewOrderCustomTextField}
            type="text"
            style={{ width: '100%' }}
            disabled
          />
        </Grid>
        <Description editableFields={editableFields} />
        <Grid item xs={6}>
          <Field
            name={fields.authorization.key}
            label={fields.authorization.label}
            component={NewOrderCustomTextField}
            type="text"
            style={{ width: '100%' }}
          />
        </Grid>
        <Grid className={classes.productWeightContainer} item xs={12}>
          <ProductWeight
            label={FORM_LABELS.totalQuantity}
            formikProps={formikProps}
            editableFields={editableFields}
            weightUnits={batteryWeightUnits.units}
            smallWeightUnits={batteryWeightUnits.smallUnits}
            weight={FORM_FIELDS_BATTERIES[ITEM_WEIGHT_KEY]}
            weightUnit={FORM_FIELDS_BATTERIES.totalWeightUnit}
            smallWeight={FORM_FIELDS_BATTERIES.totalSmallWeight}
            smallWeightUnit={FORM_FIELDS_BATTERIES.totalSmallWeightUnit}
          />
        </Grid>
        <Grid className={classes.netValue} container item xs={12}>
          <Grid item xs={6}>
            <Field
              style={{ width: '100%' }}
              name={fields.netValue.key}
              label={fields.netValue.label}
              component={NewOrderCustomTextField}
              type="text"
            />
          </Grid>
          <Grid item xs={2}>
            <Field
              style={{ width: '100%', paddingLeft: '8px' }}
              name={FORM_FIELDS_BATTERIES.totalValueUnit.key}
              label={FORM_FIELDS_BATTERIES.totalValueUnit.label}
              component={CustomSelectTextField}
              type="text"
            >
              {currencyUnits.map((option) => (
                <MenuItem key={option.currencyCode} value={option.currencyCode}>
                  {option.currencyCode}
                </MenuItem>
              ))}
            </Field>
          </Grid>
        </Grid>
        <Grid className={classes.productWeightContainer} item xs={12}>
          <TransportationOptions
            itemKey={TRANSPORTATION_OPTIONS_NAME_KEY}
            formikProps={formikProps}
            options={TRANSPORTATION_OPTIONS_BATTERY}
            disabled={disableTransportationOptions}
          />
        </Grid>
      </Grid>
    </>
  );
}

HazmatBatteries.defaultProps = {
  filteredItemNameOptions: [],
  currencyUnits: [],
};

HazmatBatteries.propTypes = {
  formikProps: PropTypes.objectOf(PropTypes.any).isRequired,
  fields: PropTypes.shape(PropTypes.object).isRequired,
  editableFields: PropTypes.arrayOf(PropTypes.string).isRequired,
  filteredItemNameOptions: PropTypes.arrayOf(PropTypes.any),
  currencyUnits: PropTypes.arrayOf(
    PropTypes.shape({
      currencyCode: PropTypes.string,
    }),
  ),
  onSelect: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
};

export default memo(HazmatBatteries);
