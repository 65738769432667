import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  header: {
    fontSize: '18px',
    color: 'white',
    fontWeight: '500',
  },
}));

function IotHeader({ text }) {
  const classes = useStyles();

  return (
    <Typography className={classes.header}>
      {text}
    </Typography>
  );
}

IotHeader.propTypes = {
  text: PropTypes.string.isRequired,
};

export default memo(IotHeader);
