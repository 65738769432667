/* eslint-disable no-nested-ternary */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { Formik, Form } from 'formik';
import * as colors from '../../styles/colors';
import NewOrderNextButton from '../../components/common/NewOrderNextButton';
import NewOrderNextButtonClear from '../../components/common/NewOrderNextButtonClear';
import FormFields from './FormFields';
import { getFormFields } from './util';

const useStyles = makeStyles({
  innerContainer: {
    width: '100%',
    backgroundColor: colors.darkBlueBackground,
    padding: '24px 24px 0px 24px',
    marginTop: '26px',
    marginBottom: '32px',
  },
  outerContainer: {
    width: '100%',
    backgroundColor: '#313547',
    padding: '24px',
    marginBottom: '24px',
  },
  header: {
    fontSize: '20px',
    fontWeight: 500,
    color: colors.white,
    letterSpacing: '0.25px',
  },
});

const AccountManagementForm = React.forwardRef(({
  itemKey,
  initialValues,
  fields,
  validationSchema,
  countries,
  states,
  notificationTypes,
  carriers,
  pricingModels,
  currencyUnits,
  onEdit,
  onSubmit,
}, ref) => {
  const classes = useStyles();

  return (
    <Formik
      ref={ref}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, formikProps) => {
        formikProps.setSubmitting(true);
        onSubmit(values, formikProps, itemKey);
      }}
    >
      {({
        values, dirty, isSubmitting, setFieldValue, setFieldTouched,
      }) => (
        <Form className={classes.outerContainer}>
          <Grid
            className={classes.innerContainer}
            justify="center"
            container
          >
            <FormFields
              fields={getFormFields(itemKey, fields, values)}
              values={values}
              countries={countries}
              states={states}
              notificationTypes={notificationTypes}
              carriers={carriers}
              pricingModels={pricingModels}
              currencyUnits={currencyUnits}
              isSubmitting={isSubmitting}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
            />
          </Grid>
          <Grid spacing={4} container justify="center">
            <Grid item>
              <NewOrderNextButtonClear
                onClick={() => {
                  if (onEdit) onEdit(itemKey, dirty);
                }}
                disabled={isSubmitting}
              >
                Cancel
              </NewOrderNextButtonClear>
            </Grid>
            <Grid item>
              <NewOrderNextButton type="submit" disabled={isSubmitting}>
                Submit
              </NewOrderNextButton>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
});

AccountManagementForm.defaultProps = {
  countries: [],
  states: null,
  notificationTypes: null,
  carriers: [],
  pricingModels: [],
  currencyUnits: [],
};

AccountManagementForm.propTypes = {
  itemKey: PropTypes.string.isRequired,
  initialValues: PropTypes.objectOf(PropTypes.string).isRequired,
  validationSchema: PropTypes.shape(PropTypes.object).isRequired,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,
  countries: PropTypes.arrayOf(PropTypes.shape(PropTypes.object)),
  states: PropTypes.shape(PropTypes.object),
  notificationTypes: PropTypes.shape({
    email: PropTypes.arrayOf(PropTypes.shape({
      type: PropTypes.string,
      description: PropTypes.string,
    })),
    sms: PropTypes.arrayOf(PropTypes.shape({
      type: PropTypes.string,
      description: PropTypes.string,
    })),
  }),
  carriers: PropTypes.arrayOf(
    PropTypes.shape({
      carrierCode: PropTypes.string.isRequired,
      displayName: PropTypes.string.isRequired,
      isDomestic: PropTypes.bool.isRequired,
      isInternational: PropTypes.bool.isRequired,
      isHazmat: PropTypes.bool.isRequired,
    }),
  ),
  pricingModels: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string,
    description: PropTypes.string,
  })),
  currencyUnits: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string,
    description: PropTypes.string,
  })),
  onEdit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default memo(AccountManagementForm);
