import React from 'react';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import * as Yup from 'yup';
import * as colors from '../../styles/colors';
import NewOrderNextButton from './NewOrderNextButton';
import NewOrderNextButtonClear from './NewOrderNextButtonClear';
import FileDropZone from './FileDropZone';
import ScrollWindow from './ScrollWindow';
import UserAvatar from './UserAvatar';
import ErrorDialog from './NewOrderErrorDialog';
import { arrayBufferToBase64 } from '../../utils/documentsUtil';
import docxImage from '../images/docx.png';
import docImage from '../images/doc.png';
import xlsxImage from '../images/xlsx.png';
import xlsImage from '../images/xls.png';
import csvImage from '../images/csv.png';
import UploadInput from './UploadInput';

const ACCEPTED_FILE_EXTENSIONS = ['.jpg', '.jpeg', '.png'];
const ACCEPTED_FILE_EXTENSIONS_STR = ACCEPTED_FILE_EXTENSIONS.join(',');
const IMAGE_FILE_EXTENSIONS = ['.png', '.jpg', '.jpeg'];

const extensionImageMap = {
  '.xlsx': xlsxImage,
  '.docx': docxImage,
  '.doc': docImage,
  '.csv': csvImage,
  '.xls': xlsImage,
};

const uploadImageStyles = {
  dialogContainer: {
    width: 700,
    height: 700,
    overflow: 'hidden',
    background: 'transparent',
  },
  contentContainer: {
    background: colors.newOrderFormBackground,
    width: '100%',
    padding: '30px 25px',
    borderRadius: 8,
    flexWrap: 'nowrap',
    overflow: 'hidden',
    minHeight: '100%',
  },
  closeIcon: {
    position: 'absolute',
    top: 10,
    right: 10,
    '&:hover': {
      background: 'inherit',
    },
  },
  header: {
    fontSize: 24,
    fontWeight: 700,
  },
  subHeader: {
    fontSize: 20,
    fontWeight: 700,
  },
  subHeaderContainer: {
    padding: '4px 0',
  },
  description: {
    fontSize: 16,
    fontWeight: 500,
  },
  divider: {
    height: '1px',
    width: '100%',
    '&:after': {
      content: '""',
      height: '1px',
      background: colors.textDarkGrey,
      width: '100%',
    },
  },
  previewContainer: {
    width: '100%',
    height: '100%',
    maxHeight: '350px',
  },
  previewContainerActive: {
    background: 'transparent',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  dropZoneContainer: {
    display: 'flex',
    flexGrow: 1,
    height: '100%',
  },
  hideDropZoneContainer: {
    display: 'none',
  },
  submitButtonsContainer: {
    paddingTop: 10,
    justifyContent: 'center',
    height: 62,
  },
  canvas: {
    width: '100%',
  },
  thumbVertical: {
    background: 'rgba(0,0,0,0.5) !important',
  },
  inputSectionContainer: {
    height: '320px',
    overflow: 'hidden',
  },
};

const useUploadImageStyles = makeStyles(uploadImageStyles);

function getInitialValues(values = {}) {
  return {
    document: '',
    ...values,
  };
}

export default function UploadImage(props) {
  const {
    open, onClose, previewSquare, setBase64Image, setFileImage, displayRemoveButton,
    currentImageUrl, onRemoveProfilePicture, onUploadProfilePicture,
  } = props;

  const [isSubmittingManual, setIsSubmittingManual] = React.useState(false);
  const canvasRef = React.useRef(null);
  const [error, setError] = React.useState(null);

  const classes = useUploadImageStyles();

  async function onRemove() {
    setIsSubmittingManual(true);
    if (onRemoveProfilePicture) await onRemoveProfilePicture();
    onClose();
    setIsSubmittingManual(false);
  }

  async function onSubmit(values, { setSubmitting }) {
    if (setBase64Image && setFileImage) {
      setFileImage(values.document);
      const fileBuffer = await values.document.arrayBuffer();
      const base64Image = arrayBufferToBase64(fileBuffer);
      setBase64Image(`data:image;base64, ${base64Image}`);
      handleClose();
      return;
    }
    // eslint-disable-next-line no-shadow
    try {
      if (onUploadProfilePicture) await onUploadProfilePicture(values.document);
    } catch (e) {
      setError(JSON.stringify(
        { error: 'Failed to upload image. Please verify you have a valid file and try again.' },
      ));
    }
    setSubmitting(false);
    handleClose();
  }

  async function handlePreview(file) {
    const fileExtMatch = file.name.match(/\.\w+$/);
    const fileExt = (fileExtMatch ? fileExtMatch[0] : '').toLowerCase();
    const canvas = canvasRef.current;
    if (!canvas) return;
    const context = canvas.getContext('2d');
    canvas.style.width = canvas.clientWidth;

    if (IMAGE_FILE_EXTENSIONS.includes(fileExt)) {
      const img = new Image();
      img.onload = () => {
        // use min size so we get a square
        const size = Math.min(img.naturalWidth, img.naturalHeight);

        // let's update the canvas size
        canvas.width = size;
        canvas.height = size;
        context.imageSmoothingEnabled = true;

        // draw image to canvas
        context.drawImage(img, 0, 0);

        // only draw image where mask is
        context.globalCompositeOperation = 'destination-in';

        // draw circle mask
        if (!previewSquare) {
          context.fillStyle = '#000';
          context.beginPath();
          context.arc(
            size * 0.5, // x
            size * 0.5, // y
            size * 0.5, // radius
            0, // start angle
            2 * Math.PI, // end angle
          );
          context.fill();
        }
      };
      img.src = URL.createObjectURL(file);
      return;
    }
    if (!ACCEPTED_FILE_EXTENSIONS.includes(fileExt)) {
      throw new Error(`Image type not supported. Please upload one of the following formats: ${ACCEPTED_FILE_EXTENSIONS.join(', ')}`);
    }
    context.clearRect(0, 0, canvas.width, canvas.height);
    const img = new Image();
    const imgScale = 0.7;
    img.src = extensionImageMap[fileExt];

    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height / img.width * canvas.width;
      context.drawImage(img, 0, 0, img.width, img.height, canvas.width * (1 - imgScale) / 2, 0, canvas.width * imgScale, canvas.height * imgScale);
      context.globalCompositeOperation = 'source-in';
      context.fillStyle = colors.white;
      context.fillRect(0, 0, canvas.width, canvas.height);
    };
  }

  const validationSchema = Yup.object().shape({
    document: Yup.mixed()
      .test('testDocumentType', 'Not a valid file', (val) => val instanceof File)
      .required('Required'),
  });

  function handleClose() {
    onClose();
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        classes={{ paper: classes.dialogContainer }}
        disableBackdropClick
        disableEscapeKeyDown
        onExited={() => {
          onClose();
        }}
      >
        <IconButton
          className={classes.closeIcon}
          onClick={handleClose}
          disableFocusRipple
          disableRipple
          disableTouchRipple
        >
          <CloseIcon color="primary" />
        </IconButton>
        <Formik
          initialValues={getInitialValues()}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {
          (formikProps) => (
            <ScrollWindow classes={{ thumbVertical: classes.thumbVertical }}>
              <Grid
                container
                className={classes.contentContainer}
                direction="column"
                alignItems="center"
                spacing={2}
              >
                <Grid item container direction="column">
                  <Grid item>
                    <Typography color="primary" className={classes.header}>
                      Upload
                      {' Profile Photo'}
                    </Typography>
                  </Grid>
                </Grid>
                { !document && <Grid item container className={classes.divider} />}
                <Grid
                  item
                  container
                  direction="column"
                  alignItems="center"
                  className={classes.inputSectionContainer}
                  style={{ flexGrow: 1 }}
                  spacing={2}
                >
                  <Grid
                    item
                    container
                    justify="space-between"
                    style={{ flexGrow: 1 }}
                  >
                    <Grid
                      item
                      container
                      direction="column"
                      spacing={2}
                      wrap="nowrap"
                      alignItems="center"
                    >
                      <UploadInput
                        name="document"
                        acceptedFiles={ACCEPTED_FILE_EXTENSIONS_STR}
                        onChange={(file) => {
                          if (file) {
                            handlePreview(file)
                              .then(() => {
                                formikProps.setFieldValue('document', file);
                              })
                              .catch((ex) => {
                                setError(JSON.stringify({ error: ex.message }));
                                return false;
                              });
                          }
                        }}
                      />
                      <Grid
                        item
                        container
                        style={{ overflow: 'hidden', flexGrow: 1 }}
                        xs={8}
                      >
                        <Grid container direction="column" item className={formikProps.values.document ? classes.previewContainerActive : classes.previewContainer}>
                          <>
                            <Grid
                              item
                              container
                              justify="center"
                              alignItems="center"
                              className={formikProps.values.document ? classes.hideDropZoneContainer : classes.dropZoneContainer}
                            >
                              {currentImageUrl
                                ? (
                                  <Grid item>
                                    <UserAvatar
                                      src={currentImageUrl}
                                      alt="User's profile picture"
                                      size="xxl"
                                    />
                                  </Grid>
                                )
                                : (
                                  <Grid item style={{ height: '100%', width: '100%' }}>
                                    <FileDropZone
                                      setFormikFieldValue={formikProps.setFieldValue}
                                      setError={setError}
                                      handlePreview={handlePreview}
                                      file={formikProps.values.document}
                                    />
                                  </Grid>
                                )}
                            </Grid>
                            <ScrollWindow
                              style={{
                                ...(null),
                              }}
                            >
                              <canvas id="preview-canvas" ref={canvasRef} className={classes.canvas} />
                            </ScrollWindow>
                          </>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item container className={classes.submitButtonsContainer} spacing={2}>
                    {formikProps.isSubmitting || isSubmittingManual ? (
                      <Grid container item justify="center" style={{ height: '100%' }}>
                        <CircularProgress
                          color="secondary"
                          size={
                            (uploadImageStyles.submitButtonsContainer.height
                              - uploadImageStyles.submitButtonsContainer.paddingTop)
                            / 1.414
                          }
                        />
                      </Grid>
                    )
                      : (
                        <>
                          {(displayRemoveButton && !formikProps.values.document)
                          && (
                          <Grid item style={{ marginRight: '24%' }}>
                            <NewOrderNextButtonClear
                              disabled={formikProps.isSubmitting}
                              onClick={onRemove}
                            >
                              Remove
                            </NewOrderNextButtonClear>
                          </Grid>
                          )}
                          <Grid item>
                            <NewOrderNextButtonClear
                              disabled={formikProps.isSubmitting}
                              onClick={handleClose}
                            >
                              Cancel
                            </NewOrderNextButtonClear>
                          </Grid>
                          <Grid item>
                            <NewOrderNextButton
                              disabled={!formikProps.isValid || formikProps.isSubmitting}
                              onClick={(val) => {
                                formikProps.submitForm();
                              }}
                            >
                              Upload
                            </NewOrderNextButton>
                          </Grid>
                        </>
                      )}
                  </Grid>
                </Grid>

              </Grid>
            </ScrollWindow>
          )
        }
        </Formik>
      </Dialog>

      <ErrorDialog
        onClose={() => setError(null)}
        open={!!error}
        errorContent={error}
      />
    </>
  );
}

UploadImage.propTypes = {
  open: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.object]),
  onClose: PropTypes.func,
  previewSquare: PropTypes.bool,
  setBase64Image: PropTypes.func,
  setFileImage: PropTypes.func,
  displayRemoveButton: PropTypes.bool,
  currentImageUrl: PropTypes.string,
  onUploadProfilePicture: PropTypes.func,
  onRemoveProfilePicture: PropTypes.func,
};

UploadImage.defaultProps = {
  open: false,
  onClose: null,
  previewSquare: false,
  setBase64Image: null,
  setFileImage: null,
  displayRemoveButton: false,
  currentImageUrl: '',
  onUploadProfilePicture: null,
  onRemoveProfilePicture: null,
};
