import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { css } from 'emotion';
import isEmpty from 'lodash/isEmpty';
import { Redirect, useHistory } from 'react-router-dom';
import {
  AppBar, Badge, IconButton, Grid, Menu, MenuItem, Toolbar,
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import { useHotkeys } from 'react-hotkeys-hook';
import {
  SESSION_EXPIRED,
  SHIPMENT_TRACKING_TAB,
  MY_TASKS_PATH,
  MY_TASKS_LABEL,
  MY_SHIPMENTS_PATH,
  MY_SHIPMENTS_LABEL,
  ADDRESS_BOOK_PATH,
  ADDRESS_BOOK_LABEL,
  ADMINISTRATION_PATH,
  ADMINISTRATION_LABEL,
  HAZMAT_PATH,
  HAZMAT_LABEL,
  INTERNATIONAL_PATH,
  INTERNATIONAL_LABEL,
  MATERIAL_DASHBOARD_PATH,
  MATERIAL_DASHBOARD_LABEL,
  MANAGE_USERS_LABEL,
  MANAGE_USERS_PATH,
  BIOLOGICAL_DASHBOARD_PATH,
  BIOLOGICAL_DASHBOARD_LABEL,
  ACCOUNT_MANAGEMENT_LABEL,
  ACCOUNT_MANAGEMENT_PATH,
  ALL_PATH,
  ALL_LABEL,
} from '../clientConstants';
import NewOrderErrorDialog from './common/NewOrderErrorDialog';
import AppBarButton from './common/AppBarButton';
import EsgLogoButton from './common/EsgLogoButton';
import NotificationPanel from './NotificationPanel';
import AccountPanelButton from './AccountPanelButton';
import { useAuth } from '../context/authContext';
import TrackingModal from './TrackingModal';
import * as colors from '../styles/colors';
import {
  useNotificationState,
  useNotificationDispatch,
  loadNotifications,
  clearAllNotifications,
  clearSingleNotification,
} from '../context/notificationContext';
import { useUserState } from '../context/userContext';
import {
  isAdmin, isAdminOrECO, isAdminOrEHS, isECO, isEHS, isESGAdmin,
} from '../utils/helpers';

const useStyles = makeStyles((theme) => ({
  rootAppBar: {
    width: '100%',
    position: 'fixed',
    top: 0,
    zIndex: 1500,
  },
  appbarPaddingMinWidth: {
    padding: '0 10px',
  },
  appbarPaddingGreaterThanMinWidth: {
    padding: '0 calc(100%/2 - 80vw/2)',
  },
  appbarPaddingWidth: {
    padding: '0 0',
  },
  appbar: {
    boxSizing: 'border-box',
    width: '100%',
    backgroundColor: colors.midnightBlue,
    color: colors.white,
  },
  title: {
    flexGrow: 1,
  },
  logo: {
    width: 160,
    height: 30,
    verticalAlign: 'bottom',
  },
  link: {
    color: colors.white,
  },
  toolbar: {
    padding: 0,
  },
}));

const StyledBadge = withStyles({
  colorPrimary: {
    backgroundColor: colors.badgeBubbleRed,
    color: colors.white,
  },
})(Badge);

function RedirectToLogin() {
  return <Redirect to="/login" />;
}

function isSessionExpired(err) {
  if (err.message === SESSION_EXPIRED) {
    return true;
  }

  return false;
}

export default function Navbar() {
  const classes = useStyles();
  const matches = useMediaQuery('(min-width:1200px)');
  const [error, setError] = React.useState('');
  const [notificationPanelAnchorEl, setNotificationPanelAnchorEl] = React.useState(null);
  const [trackingModalAnchorEl, setTrackingModalAnchorEl] = React.useState(null);
  const { notifications } = useNotificationState();
  const notificationDispatch = useNotificationDispatch();
  const { user, logout } = useAuth();
  const [currentTrackingTab, setCurrentTrackingTab] = React.useState(SHIPMENT_TRACKING_TAB);
  const { account, userType } = useUserState();
  const adminOrECO = isAdminOrECO(userType);
  const esgAdmin = isESGAdmin(userType);
  const eco = isECO(userType);
  const ehs = isEHS(userType);
  const adminOrEHS = isAdminOrEHS(userType);
  const admin = isAdmin(userType);
  const [anchorEl, setAnchorEl] = React.useState(null);

  React.useEffect(() => {
    // Try to load notifications.
    // If not logged in. Logs user out.
    loadNotifications(notificationDispatch, logout)
      .catch((e) => {
        if (isSessionExpired(e)) {
          setError(SESSION_EXPIRED);
        }
      });
  }, []);

  // Tracking Modal
  const [trackingModalState, setTrackingModalState] = React.useState({
    anchorEl: null,
    shipmentId: null,
    trackingNumber: null,
    iotTrackingNumber: null,
  });
  const history = useHistory();

  useHotkeys(
    'f4',
    async () => {
      if (account === 'worldemblem') {
        history.push('/bin-group');
      }
    },
    { enableOnTags: ['INPUT', 'TEXTAREA', 'SELECT'] },
  );
  useHotkeys(
    'f8',
    async () => {
      if (account === 'worldemblem') {
        history.push('/new-shiporder');
      }
    },
    { enableOnTags: ['INPUT', 'TEXTAREA', 'SELECT'] },
  );
  const handleTrackingModalOpen = ({
    shipmentId, trackingNumber, anchorEl, iotTrackingNumber, openTab,
  }) => {
    setTrackingModalState({
      shipmentId, trackingNumber, anchorEl: trackingModalAnchorEl, iotTrackingNumber,
    });
    setCurrentTrackingTab(openTab);
  };

  const handleTrackingModalClose = () => {
    setTrackingModalState({
      shipmentId: null, trackingNumber: null, anchorEl: null, iotTrackingNumber: null,
    });
    setCurrentTrackingTab(SHIPMENT_TRACKING_TAB);
  };

  // Notification Panel
  const handleNotificationIconClick = (event) => {
    setNotificationPanelAnchorEl(event.currentTarget);
    setTrackingModalAnchorEl(event.currentTarget);
  };

  const handleNotificationPanelClose = () => {
    setNotificationPanelAnchorEl(null);
  };

  const handleMarkAllAsReadClick = () => {
    clearAllNotifications(notificationDispatch)
      .catch((err) => alert(err));
  };

  const handleMarkAsReadClick = (notificationId) => {
    clearSingleNotification(notificationDispatch, notificationId)
      .catch((err) => alert(err));
  };

  const handleCloseMenu = (path) => {
    if (!isEmpty(path)) history.push(path);
    setAnchorEl(null);
  };

  return (
    <div className={classes.rootAppBar}>
      {!user.isAuthenticated && <RedirectToLogin />}
      <AppBar
        className={`${classes.appbar} ${matches && account !== 'worldemblem' ? classes.appbarPaddingGreaterThanMinWidth : classes.appbarPaddingMinWidth}`}
        position="static"
      >
        <Toolbar className={classes.toolbar}>
          <div className={classes.title}>
            <Grid container spacing={1}>
              <Grid item>
                <EsgLogoButton />
              </Grid>
              {account !== 'worldemblem' ? (
                <>
                  <Grid item>
                    <AppBarButton path={MY_SHIPMENTS_PATH} label={MY_SHIPMENTS_LABEL} />
                  </Grid>
                  <Grid item>
                    <AppBarButton path={MY_TASKS_PATH} label={MY_TASKS_LABEL} />
                  </Grid>
                  <Grid item>
                    <AppBarButton path={ADDRESS_BOOK_PATH} label={ADDRESS_BOOK_LABEL} />
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item>
                    <AppBarButton path="/new-shiporder" label="NEW SHIPMENT" />
                  </Grid>
                  <Grid item>
                    <AppBarButton path={MY_SHIPMENTS_PATH} label={MY_SHIPMENTS_LABEL} />
                  </Grid>
                  <Grid item>
                    <AppBarButton path="/shipping-manifest" label="MANIFEST" />
                  </Grid>
                  <Grid item xs={1}>
                    <AppBarButton path="/ipd-shipment" label="IPD" />
                  </Grid>
                  <Grid item xs={1}>
                    <AppBarButton path="/bin-details" label="ORDERS" />
                  </Grid>
                  <Grid item xs={1}>
                    <AppBarButton path="/bin-group" label="GROUP" />
                  </Grid>
                  <Grid item>
                    <AppBarButton path={ADDRESS_BOOK_PATH} label={ADDRESS_BOOK_LABEL} />
                  </Grid>
                  <Grid item>
                    <AppBarButton path={MY_TASKS_PATH} label={MY_TASKS_LABEL} />
                  </Grid>
                </>
              )}
              {(adminOrECO || eco || adminOrEHS) && (
                <>
                  <Grid item>
                    <AppBarButton
                      path={ADMINISTRATION_PATH}
                      label={ADMINISTRATION_LABEL}
                      onClick={(e) => {
                        setAnchorEl(e.currentTarget);
                      }}
                    />
                  </Grid>
                  <Menu
                    id="admin-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                    MenuListProps={{
                      style: {
                        marginTop: '10px',
                      },
                    }}
                    onClose={handleCloseMenu}
                    keepMounted
                  >
                    {admin && <MenuItem onClick={() => handleCloseMenu(ACCOUNT_MANAGEMENT_PATH)}>{ACCOUNT_MANAGEMENT_LABEL}</MenuItem>}
                    {adminOrEHS && <MenuItem onClick={() => handleCloseMenu(MANAGE_USERS_PATH)}>{MANAGE_USERS_LABEL}</MenuItem>}
                    {(esgAdmin || eco || ehs) && <MenuItem onClick={() => handleCloseMenu(ALL_PATH)}>{ALL_LABEL}</MenuItem>}
                    {adminOrECO && <MenuItem onClick={() => handleCloseMenu(INTERNATIONAL_PATH)}>{INTERNATIONAL_LABEL}</MenuItem>}
                    {adminOrEHS && <MenuItem onClick={() => handleCloseMenu(HAZMAT_PATH)}>{HAZMAT_LABEL}</MenuItem>}
                    {adminOrECO && <MenuItem onClick={() => handleCloseMenu(MATERIAL_DASHBOARD_PATH)}>{MATERIAL_DASHBOARD_LABEL}</MenuItem>}
                    {(adminOrEHS || adminOrECO) && <MenuItem onClick={() => handleCloseMenu(BIOLOGICAL_DASHBOARD_PATH)}>{BIOLOGICAL_DASHBOARD_LABEL}</MenuItem>}
                  </Menu>
                </>
              )}
            </Grid>
          </div>
          <div className={classes.sectionDesktop}>
            <IconButton
              aria-label="show new notifications"
              color="inherit"
              onClick={handleNotificationIconClick}
            >
              <StyledBadge badgeContent={notifications.length} color="primary">
                <NotificationsIcon />
              </StyledBadge>
            </IconButton>

            <AccountPanelButton />
          </div>
        </Toolbar>
      </AppBar>
      <NotificationPanel
        notifications={notifications}
        isOpen={Boolean(notificationPanelAnchorEl)}
        handleClose={handleNotificationPanelClose}
        anchorEl={notificationPanelAnchorEl}
        handleMarkAllAsReadClick={handleMarkAllAsReadClick}
        handleMarkAsReadClick={handleMarkAsReadClick}
        handleTrackingModalOpen={handleTrackingModalOpen}
      />
      <TrackingModal
        handleClose={handleTrackingModalClose}
        id={`tracking-${trackingModalState.shipmentId || ''}`}
        trackingModalState={trackingModalState}
        currentTab={currentTrackingTab}
        setCurrentTab={setCurrentTrackingTab}
      />
      <NewOrderErrorDialog
        open={!!error}
        errorContent={error}
        classes={{
          dialogRoot: css`
            background-color: ${colors.mediumBlueBackground};
            overflow-y: 'initial';
            border-radius: 10px
          `,
        }}
        onClose={async () => {
          setError('');
          await logout();
        }}
      />
    </div>
  );
}
