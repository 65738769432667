import * as Yup from 'yup';
import * as _ from 'lodash';
import { sanitizeAddress } from './helpers';

export const emptySchema = Yup.object();

export const commonSavedPaymentSchema = {
  paymentType: Yup.string().oneOf(['creditCard', 'ach']).required(),
  isDefault: Yup.bool(),
};

export const creditCardSchemaPreTokenization = Yup.object().shape({
  billingAddress: Yup.object().shape({
    city: Yup.string().required('Required'),
    state: Yup.string().required('Required'),
    country: Yup.string().required('Required'),
    addressLine1: Yup.string().required('Required'),
    addressLine2: Yup.string(),
    addressLine3: Yup.string(),
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    zip: Yup.string().required('Required'),
  }).required('Required'),
  creditCardDetails: Yup.object().shape({
    ownerShipType: Yup.string().required('Required'),
  }),
});

export const creditCardSchemaPostTokenization = Yup.object().shape({
  billingAddress: Yup.object().shape({
    addressLine1: Yup.string().required('Required'),
    addressLine2: Yup.string(),
    addressLine3: Yup.string(),
    city: Yup.string().required('Required'),
    state: Yup.string().required('Required'),
    zip: Yup.string().required('Required'),
    country: Yup.string().required('Required'),
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
  }).required('Required'),
  ownerShipType: Yup.string().required('Required'),
  paymentNonce: Yup.string().required('Required'),
});

export const achSchemaPreTokenization = Yup.object().shape({
  billingAddress: Yup.object().shape({
    city: Yup.string().required('Required'),
    state: Yup.string().required('Required'),
    country: Yup.string().required('Required'),
    zip: Yup.string().required('Required'),
    addressLine1: Yup.string().required('Required'),
    addressLine2: Yup.string(),
    addressLine3: Yup.string(),
  }).default('Required'),
  bankAccountDetails: Yup.object().shape({
    accountNumber: Yup.string().required('Required'),
    routingNumber: Yup.string().required('Required'),
    accountType: Yup.string().required('Required'),
    ownerShipType: Yup.string().required('Required'),
    firstName: Yup.mixed().when('ownerShipType', (ownerShipType) => {
      if (ownerShipType === 'personal') {
        return Yup.string().required('Required');
      }
      return Yup.mixed().nullable(true);
    }),
    lastName: Yup.mixed().when('ownerShipType', (ownerShipType) => {
      if (ownerShipType === 'personal') {
        return Yup.string().required('Required');
      }
      return Yup.mixed().nullable(true);
    }),
    businessName: Yup.mixed().when('ownerShipType', (ownerShipType) => {
      if (ownerShipType === 'business') {
        return Yup.string().required('Required');
      }
      return Yup.mixed().nullable(true);
    }),
  }).required('Required'),
});

export const editAchSchemaPreTokenization = Yup.object().shape({
  billingAddress: Yup.object().shape({
    city: Yup.string().required('Required'),
    state: Yup.string().required('Required'),
    country: Yup.string().required('Required'),
    zip: Yup.string().required('Required'),
    addressLine1: Yup.string().required('Required'),
    addressLine2: Yup.string(),
    addressLine3: Yup.string(),
  }).default('Required'),
  bankAccountDetails: Yup.object().shape({
    accountNumber: Yup.string().required('Required'),
    routingNumber: Yup.string().required('Required'),
    accountType: Yup.string().required('Required'),
    ownerShipType: Yup.string().required('Required'),
    firstName: Yup.mixed().when('ownerShipType', (ownerShipType) => {
      if (ownerShipType === 'personal') {
        return Yup.string().required('Required');
      }
      return Yup.mixed().nullable(true);
    }),
    lastName: Yup.mixed().when('ownerShipType', (ownerShipType) => {
      if (ownerShipType === 'personal') {
        return Yup.string().required('Required');
      }
      return Yup.mixed().nullable(true);
    }),
    businessName: Yup.mixed().when('ownerShipType', (ownerShipType) => {
      if (ownerShipType === 'business') {
        return Yup.string().required('Required');
      }
      return Yup.mixed().nullable(true);
    }),
  }).required('Required'),
});

export const achSchemaPostTokenization = Yup.object().shape({
  billingAddress: Yup.object(({
    city: Yup.string().required('Required'),
    state: Yup.string().required('Required'),
    country: Yup.string().required('Required'),
    addressLine1: Yup.string().required('Required'),
    addressLine2: Yup.string(),
    addressLine3: Yup.string(),
    zip: Yup.string().required('Required'),
  }))
    .when('ownerShipType', (val, schema) => {
      if (val === 'business') {
        return schema.shape({
          companyName: Yup.string().required('Required'),
        });
      }
      if (val === 'personal') {
        return schema.shape({
          firstName: Yup.string().required('Required'),
          lastName: Yup.string().required('Required'),
        });
      }
    })
    .required('Required'),
  ownerShipType: Yup.string().required('Required'),
  paymentNonce: Yup.string().required('Required'),
});

export const addPaymentSchemaPreTokenization = Yup.object(commonSavedPaymentSchema)
  .when('.paymentType', (paymentType, schema) => {
    switch (paymentType) {
      case 'creditCard':
        return schema.shape(creditCardSchemaPreTokenization.fields);
      case 'ach':
        return schema.shape(achSchemaPreTokenization.fields);
      default:
        return schema;
    }
  });

export function formatPaymentForSubmission(paymentDetails) {
  const formattedPaymentDetails = _.cloneDeep(paymentDetails);
  formattedPaymentDetails.billingAddress = sanitizeAddress(formattedPaymentDetails.billingAddress);

  if (formattedPaymentDetails.paymentType === 'creditCard') {
    const { creditCardDetails = {} } = formattedPaymentDetails;
    formattedPaymentDetails.ownerShipType = creditCardDetails.ownerShipType;
  }

  if (formattedPaymentDetails.paymentType === 'ach') {
    const { bankAccountDetails = {} } = formattedPaymentDetails;
    if (bankAccountDetails.ownerShipType === 'business') {
      formattedPaymentDetails.billingAddress.companyName = bankAccountDetails.businessName;
    }
    if (bankAccountDetails.ownerShipType === 'personal') {
      formattedPaymentDetails.billingAddress.firstName = bankAccountDetails.firstName;
      formattedPaymentDetails.billingAddress.lastName = bankAccountDetails.lastName;
    }
    formattedPaymentDetails.ownerShipType = bankAccountDetails.ownerShipType;
  }

  return formattedPaymentDetails;
}
