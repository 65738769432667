import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import has from 'lodash/has';
import map from 'lodash/map';
import remove from 'lodash/remove';
import {
  Chip, Grid, Popover, Typography, Button, MenuItem,
} from '@material-ui/core';
import FilterIcon from '@material-ui/icons/FilterList';
import { makeStyles } from '@material-ui/core/styles';
import { Field, Formik } from 'formik';
import StatusUtils from '../utils/status';
import * as colors from '../styles/colors';
import FilterCheckbox from './common/FilterCheckbox';
import CustomSelectTextField from './common/CustomSelectTextField';

const BASE_BUTTON_STYLES = {
  fontWeight: 'bold',
  padding: '6px 16px',
  borderRadius: '5px',
};

const INITIAL_VALUES = {
  taskType: '',
  status: [],
};

const VALIDATION_SCHEMA = Yup.object().shape({
  taskTyoe: Yup.string(),
  status: Yup.string(),
});

const useStyles = makeStyles(() => ({
  paper: {
    background: colors.mediumBlueBackground,
    borderRadius: 8,
  },
  activeFilterChip: {
    background: colors.background,
    height: 'auto',
    padding: 5,
    color: colors.white,
    border: `1px solid ${colors.darkBackgroundGrey}`,
    borderRadius: 5,
    fontSize: 15,
    margin: '5px 15px 0px 0px',
  },
  activeFilterChipText: {
    color: colors.white,
    fontSize: 15,
    textTransform: 'capitalize',
  },
  activeFiltersWrap: {
    alignItems: 'center',
    height: '100%',
    maxWidth: 'calc(100% - 64px)',
  },
  filterButtonContainer: {
    justifySelf: 'flex-end',
    display: 'flex',
    alignSelf: 'center',
  },
  filterIcon: {
    fontSize: '30px',
    color: 'white',
  },
  filterModal: {
    background: colors.mediumBlueBackground,
    width: 450,
    padding: '20px 25px',
    color: colors.white,
    borderRadius: '8px',
  },
  modalHeaderSection: {
    fontWeight: '500',
    padding: '20px 0 12px 0',
    width: '100%',
  },
  modalHeader: {
    fontSize: 18,
  },
  filterModalSection: {
    fontSize: 16,
    width: '100%',
    padding: '10px 0',
  },
  filterModalContent: {
    background: colors.darkBlueBackground,
    padding: '10px 20px',
  },
  sectionHeader: {
    padding: '0 0 5px 0',
    fontSize: 16,
    fontWeight: '500',
  },
  header: {
    fontWeight: 500,
  },
  sectionSubmit: {
    marginTop: '1rem',
  },
  button: {
    ...BASE_BUTTON_STYLES,
    color: colors.white,
    whiteSpace: 'nowrap',
    '&:disabled': {
      color: colors.mediumBlueBackground,
    },
  },
  secondaryButton: {
    ...BASE_BUTTON_STYLES,
    border: `1px solid ${colors.buttonBackgroundBlue}`,
    color: colors.secondaryButton,
    marginLeft: '1rem',
  },
}));

const FilterTask = ({
  taskTypes, filters, onChipDelete, onFilterClear, onSubmit,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const initialValues = { ...INITIAL_VALUES, status: filters.status };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSubmit = (values) => {
    onSubmit(values);
    handleClose();
  };

  const formatFilterChipText = (filterType, filterValues) => (filterValues instanceof Array
    ? `${filterType}: ${filterValues.join(', ')}`
    : `${filterType}: ${filterValues}`);

  const isChecked = (value, statusValues) => (has(filters, 'status')
    ? filters.status.indexOf(value) > -1
    : statusValues.indexOf(value) > -1);

  return (
    <>
      <Grid item container className={classes.activeFiltersWrap}>
        {filters && Object.entries(filters).map(([filterType, filterValues]) => filterType !== 'filter' && (
          <Grid
            item
            className={classes.activeFilterChip}
            key={`active-filter-ship-grid-${filterType}`}
          >
            <Typography
              key={`active-filter-ship-typography-${filterType}`}
              className={classes.activeFilterChipText}
            >
              <Chip
                key={`active-filter-ship-typography-${filterType}`}
                variant="outlined"
                className={classes.activeFilterChipText}
                color="primary"
                label={formatFilterChipText(filterType, filterValues)}
                onDelete={() => onChipDelete(filterType)}
              />
            </Typography>
          </Grid>
        ))}
      </Grid>
      <Grid className={classes.filterButtonContainer} item>
        <Button onClick={(e) => setAnchorEl(e.currentTarget)}>
          <FilterIcon className={classes.filterIcon} />
        </Button>
      </Grid>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        classes={{ paper: classes.paper }}
        data-testid="filter-tasks-popover"
      >
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={VALIDATION_SCHEMA}
          onSubmit={handleSubmit}
        >
          {(formikProps) => (
            <Grid container className={classes.filterModal} direction="column">
              <Grid item container className={`${classes.modalHeaderSection}`}>
                <Typography className={`${classes.modalHeader}`}>Filter Tasks</Typography>
              </Grid>
              <Grid item container className={classes.filterModalContent}>
                <Grid className={`${classes.filterModalSection} ${classes.sectionType}`} item container direction="column" justify="flex-start" wrap="nowrap">
                  <Grid item>
                    <Typography className={`${classes.sectionHeader}`}>Task Type</Typography>
                  </Grid>
                  <Grid className={`${classes.filterModalSection} ${classes.sectionType}`} item container direction="column" justify="flex-start" wrap="nowrap">
                    <Grid item xs={12}>
                      <Field
                        component={CustomSelectTextField}
                        style={{ width: '100%' }}
                        type="text"
                        name="taskType"
                        label="Select type ..."
                        value={filters ? filters.taskType : ''}
                        data-testid="task-type-input"
                      >
                        {taskTypes.map((item) => (
                          <MenuItem
                            key={item.taskType}
                            value={item.taskType}
                          >
                            {item.description}
                          </MenuItem>
                        ))}
                      </Field>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid className={`${classes.filterModalSection} ${classes.sectionType}`} item container direction="column" justify="flex-start" wrap="nowrap">
                  <Grid item>
                    <Typography className={`${classes.sectionHeader}`}>Status</Typography>
                  </Grid>
                  <Grid item container direction="row" alignItems="center" justify="flex-start" spacing={2}>
                    {map(StatusUtils.taskStatusMap, (value, key) => (
                      <Grid item key={`status-filter-grid-${key}`}>
                        <FilterCheckbox
                          label={value}
                          value={value}
                          checked={isChecked(value, formikProps.values.status)}
                          onClick={(v, checked) => {
                            const statuses = formikProps.values.status;

                            if (checked) statuses.push(v);
                            else remove(statuses, (status) => (status === v));

                            formikProps.setFieldValue('status', statuses);
                          }}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
              <Grid className={` ${classes.sectionSubmit}`} container>
                <Grid container justify="center" alignContent="center">
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="secondary"
                    onClick={() => handleSubmit(formikProps.values)}
                  >
                    Filter
                  </Button>
                  <Button
                    className={classes.secondaryButton}
                    onClick={() => {
                      formikProps.setValues({
                        taskType: '',
                        status: [],
                      }, false);

                      onFilterClear();
                    }}
                  >
                    Clear
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Formik>
      </Popover>
    </>
  );
};

FilterTask.defaultProps = {
  taskTypes: [],
  filters: null,
};

FilterTask.propTypes = {
  taskTypes: PropTypes.arrayOf(PropTypes.shape({
    taskType: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  })),
  filters: PropTypes.shape({
    taskType: PropTypes.string,
    status: PropTypes.arrayOf(PropTypes.string),
  }),
  onChipDelete: PropTypes.func.isRequired,
  onFilterClear: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default memo(FilterTask);
