import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PrintIcon from '@material-ui/icons/PrintOutlined';
import NewOrderShipmentModule from './common/NewOrderShipmentModule';
import BlockedPanelDisplay from './common/PanelWarning';
import ShippingDocuments from './ShippingDocuments';
import { combineDocuments, printThermal, isModuleComplete } from '../utils/documentsUtil';

const styles = {
  tooltip: {
    background: '#292D3D',
    boxShadow: '0px 1px 5px -2px black',
  },
  tooltipTitle: {
    fontSize: 12,
    fontWeight: 500,
  },
};

/**
 * #################################
 *          EXPORT FUNCTION
 * #################################
 */

const useStyles = makeStyles(styles);

export default function ShippingDocumentsPane({
  shipment,
  isComplete,
  isReadyToBeCompleted,
  blockingMessage,
  ...shipmentProps
}) {
  const classes = useStyles();
  const [devices, setDevices] = React.useState([]);
  const [printer, setPrinter] = React.useState(null);
  React.useEffect(() => {
    window.BrowserPrint.getLocalDevices((deviceList) => {
      setDevices(deviceList.printer);
    }, (err) => {
      console.log(err);
    });
    window.BrowserPrint.getDefaultDevice('printer', (device) => {
      setPrinter(device);
    });
  }, []);
  const handlePrint = (e) => {
    e.stopPropagation();
    const checkListDocument = (shipment?.documentation?.checkList?.document) ? [{ document: shipment.documentation.checkList.document }] : [];
    const shippingDocuments = shipment?.documentation?.documents || [];
    const packageDocuments = shipment?.documentation?.packages ? shipment?.documentation?.packages?.reduce((docs, pkg) => docs.concat(pkg?.documents), []) : [];
    const allDocuments = checkListDocument.concat(shippingDocuments).concat(packageDocuments);
    const thermalDocuments = allDocuments.filter((document) => document.isThermalLabel === true);
    thermalDocuments.forEach((doc) => {
      const printdoc = Buffer.from(doc.document, 'base64').toString();
      console.log(printdoc);
      printThermal(printer, printdoc);
    });
    const otherDocuments = allDocuments.filter((document) => document?.isThermalLabel !== true);
    if (otherDocuments.length) {
      combineDocuments(otherDocuments);
    }
  };

  const labelDetails = (
    <Tooltip
      title={(
        <Typography color="primary" className={classes.tooltipTitle}>
          Print All
        </Typography>
      )}
      classes={{ tooltip: classes.tooltip }}
      placement="left"
      enterDelay="200"
    >
      <IconButton onClick={handlePrint} style={{ padding: '0' }}>
        <PrintIcon />
      </IconButton>
    </Tooltip>
  );

  return (
    <>
      <NewOrderShipmentModule
        info={isReadyToBeCompleted && labelDetails}
        isComplete={isComplete}
        shipment={shipment}
        blockingMessage={blockingMessage}
        {...shipmentProps}
      >
        {(isReadyToBeCompleted) ? ((renderProps) => (
          <ShippingDocuments
            shipment={shipment}
            isComplete={isComplete}
            isReadyToBeCompleted={isReadyToBeCompleted}
            {...shipmentProps}
            {...renderProps}
          />
        )) : (
          <BlockedPanelDisplay message={blockingMessage} />
        )}
      </NewOrderShipmentModule>
      <div id="iFrameContainer" style={{ height: 0, overflow: 'hidden' }} />
    </>
  );
}

ShippingDocumentsPane.propTypes = {
  label: PropTypes.string.isRequired,
  shipmentId: PropTypes.string.isRequired,
  selectedForms: PropTypes.objectOf(PropTypes.string.isRequired).isRequired,
  setSelectedForms: PropTypes.func.isRequired,
  formName: PropTypes.string.isRequired,
  parentFormName: PropTypes.string.isRequired,
};
