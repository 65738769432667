import get from 'lodash/get';
import map from 'lodash/map';
import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import {
  AUTO_ASSIGNMENT_ECO_EXPIRE_DAYS_FIELD_NAME,
  AUTO_ASSIGNMENT_ECO_USER_GROUP_FIELD_NAME,
  AUTO_ASSIGNMENT_EHS_EXPIRE_DAYS_FIELD_NAME,
  AUTO_ASSIGNMENT_EHS_USER_GROUP_FIELD_NAME,
  AUTO_ASSIGNMENT_FIELD_NAME,
  COST_ALLOCATION_KEY,
  MAX_ALLOCATION_FIELD_NAME,
  MISCELLANEOUS_KEY,
  SPLIT_ALLOCATION_FIELD_NAME,
} from './constants';

export function showMaxAllocationField(fieldName, values) {
  return fieldName === MAX_ALLOCATION_FIELD_NAME && get(values, SPLIT_ALLOCATION_FIELD_NAME, false);
}

export function isAutoAssignmentExtraField(fieldName) {
  return [
    AUTO_ASSIGNMENT_ECO_EXPIRE_DAYS_FIELD_NAME,
    AUTO_ASSIGNMENT_ECO_USER_GROUP_FIELD_NAME,
    AUTO_ASSIGNMENT_EHS_EXPIRE_DAYS_FIELD_NAME,
    AUTO_ASSIGNMENT_EHS_USER_GROUP_FIELD_NAME,
  ].includes(fieldName);
}

export function hideAutoAssignmentExtraFields(values) {
  if (!values) return true;

  return !values[AUTO_ASSIGNMENT_FIELD_NAME];
}

export function getFormFields(itemKey, fields, values) {
  if (itemKey === COST_ALLOCATION_KEY && !showMaxAllocationField(MAX_ALLOCATION_FIELD_NAME, values)) {
    return fields.filter((field) => field.name !== MAX_ALLOCATION_FIELD_NAME);
  }

  if (itemKey === MISCELLANEOUS_KEY && hideAutoAssignmentExtraFields(values)) {
    return fields.filter((field) => !isAutoAssignmentExtraField(field.name));
  }

  return fields;
}

export function formatAvailableCarriers(accountAvailableCarriers, carriers) {
  if (isEmpty(accountAvailableCarriers) || isEmpty(carriers)) return null;

  const obj = omit(carriers[0], ['carrierCode', 'displayName']);

  let newAvailableCarriers = null;

  for (const [key] of Object.entries(obj)) {
    const newKey = key.substring(2, key.length).toLowerCase();

    newAvailableCarriers = {
      ...newAvailableCarriers,
      [newKey]: [],
    };
  }

  const availableOptions = map(newAvailableCarriers, ((value, key) => key));

  availableOptions.forEach((option) => {
    const accountCarrier = get(accountAvailableCarriers, option, []);
    const capitalizeValue = option.charAt(0).toUpperCase() + option.slice(1);
    const key = `is${capitalizeValue}`;

    carriers.forEach((carrier) => {
      const item = {
        type: carrier.carrierCode,
        description: carrier.displayName,
        checked: false,
      };

      item.key = `${option}-${carrier.carrierCode}`;

      if (has(carrier, key) && carrier[key]) {
        item.checked = accountCarrier.includes(carrier.carrierCode);

        newAvailableCarriers[option].push(item);
      }
    });
  });

  return newAvailableCarriers;
}
