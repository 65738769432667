import React from 'react';
import {
  Avatar,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const styles = {
  avatarRoot: {
    height: '100%',
    width: '100%',
    borderRadius: '0px',
  },
  avatarColorDefault: {
    background: 'transparent',
  },
};
const useStyles = makeStyles(styles);

export function getPackageImage(code) {
  try {
    // eslint-disable-next-line global-require, import/no-dynamic-require
    return require(`../images/packageTypes/${code.split('T')[1]}.png`);
  } catch (error) {
    console.log(error);
    return null;
  }
}

export default function PackagingImage({ packagingName, packagingTypeCode }) {
  const classes = useStyles();
  return (
    <Avatar
      classes={{ root: classes.avatarRoot, colorDefault: classes.avatarColorDefault }}
      alt={packagingName}
      src={getPackageImage(packagingTypeCode)}
      variant="square"
    />
  );
}

PackagingImage.propTypes = {
  packagingName: PropTypes.string.isRequired,
  packagingTypeCode: PropTypes.string.isRequired,
};
