import React from 'react';
import PropTypes from 'prop-types';
import BillingPaymentForm from './BillingPayment/BillingPaymentForm';
import NewOrderShipmentModule from './common/NewOrderShipmentModule';
import BlockedPanelDisplay from './common/PanelWarning';
import { useSingleOrderState } from '../context/singleOrderContext';
import { usePaymentState } from '../context/paymentContext';
import { useUserState } from '../context/userContext';
import { formatMonetaryAmount } from '../utils/helpers';

const PAYMENT_CREDITCARD_VALUE = 'creditCard';
const PAYMENT_COST_CODE = 'costCode';
const PAYMENT_PAYPAL = 'payPal';
const BILLING_BDCA_VALUE = 'billDefaultAccount';
const BILLING_BOCA_VALUE = 'billOtherAccount';
const PAYMENT_CREDITCARD_LABEL = 'Credit Card';
const PAYMENT_PAYPAL_LABEL = 'PayPal';
const PAYMENT_ACH = 'ach';

const getLabelDetails = (shipment, key, savedPaymentMethods) => {
  const details = shipment?.[key]?.details;
  if (!details) return '';
  const { paymentType } = details;

  if (paymentType === PAYMENT_CREDITCARD_VALUE) return getCreditCardLabelDetails(details, savedPaymentMethods);
  if (paymentType === PAYMENT_COST_CODE) return getCostCodesLabelDetails(shipment.carrier, details);
  if (paymentType === PAYMENT_PAYPAL) return getPayPalLabelDetails(shipment.carrier, details);
  if (paymentType === PAYMENT_ACH) return getAchLabelDetails(shipment.carrier, details, savedPaymentMethods);

  return '';
};

const getCreditCardLabelDetails = ({
  amount, transaction, creditCardDetails, paymentToken,
}, savedPaymentMethods) => {
  const { lastFour } = savedPaymentMethods?.creditCards?.find((c) => c.paymentToken === paymentToken)?.creditCardDetails || creditCardDetails || {};

  return `Shipping ${transaction ? 'paid' : 'authorized for'} ${formatMonetaryAmount(transaction && transaction.currencyCode, amount)} with ${PAYMENT_CREDITCARD_LABEL}${lastFour ? ` | *${lastFour}` : ''}`;
};

const getCostCodesLabelDetails = (carrier, details) => {
  const currencyCode = carrier?.details?.currencyCode;
  const { amount, billingType } = details;

  let summaryDetails = '';

  if (billingType === BILLING_BDCA_VALUE) summaryDetails = `${formatMonetaryAmount(currencyCode, amount)} billed to your Carrier Account`;
  if (billingType === BILLING_BOCA_VALUE) summaryDetails = 'Billed to 3rd Party Carrier Account';

  return summaryDetails;
};

const getPayPalLabelDetails = (carrier, details) => {
  const currencyCode = carrier?.details?.currencyCode;
  const { amount } = details;

  return `Shipping paid ${formatMonetaryAmount(currencyCode, amount)} with ${PAYMENT_PAYPAL_LABEL}`;
};

const getAchLabelDetails = (carrier, details, savedPaymentMethods) => {
  const currencyCode = carrier?.details?.currencyCode;
  const { amount, bankAccountDetails, paymentToken } = details || {};

  const { lastFour, bankName } = savedPaymentMethods?.bankAccounts?.find((c) => c.paymentToken === paymentToken)?.bankAccountDetails || bankAccountDetails || {};
  const description = (bankName ? `${bankName} account ending in ${lastFour}` : lastFour);

  return `Shipping paid ${formatMonetaryAmount(currencyCode, amount)}${description ? ` | ${description}` : ''}`;
};

const BillingPaymentPane = ({
  shipment,
  formName,
  isComplete,
  isReadyToBeCompleted,
  blockingMessage,
  ...shipmentProps
}) => {
  const singleOrderState = useSingleOrderState();
  const { savedPaymentMethods } = usePaymentState();
  const { order: { accountType, purpose } } = singleOrderState;
  const { isDelayedPayment } = useUserState();
  const labelDetails = getLabelDetails(shipment, formName, savedPaymentMethods);

  return (
    <NewOrderShipmentModule
      labelDetails={labelDetails}
      isComplete={isComplete}
      formName={formName}
      shipment={shipment}
      blockingMessage={blockingMessage}
      {...shipmentProps}
    >
      {(isReadyToBeCompleted) ? ((renderProps) => (
        <BillingPaymentForm
          accountType={accountType}
          purpose={purpose}
          formName={formName}
          shipment={shipment}
          isComplete={isComplete}
          isReadyToBeCompleted={isReadyToBeCompleted}
          isDelayedPayment={isDelayedPayment}
          {...shipmentProps}
          {...renderProps}
        />
      )) : (
        <BlockedPanelDisplay message={blockingMessage} />
      )}
    </NewOrderShipmentModule>
  );
};

// BillingPaymentPane.propTypes = {
//   label: PropTypes.string.isRequired,
//   parentFormName: PropTypes.string.isRequired,
//   formName: PropTypes.string.isRequired,
//   formTabKey: PropTypes.string.isRequired,
//   billingSection: PropTypes.bool.isRequired,
//   nextPanel: PropTypes.func.isRequired,
//   selectedForms: PropTypes.objectOf(PropTypes.string).isRequired,
//   shipmentId: PropTypes.string.isRequired,
//   setSelectedForms: PropTypes.func.isRequired,
// };
export default BillingPaymentPane;
