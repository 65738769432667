import { createMuiTheme } from '@material-ui/core/styles';
import * as colors from './colors';
import esgMainTheme from './theme';

export const loginTheme = createMuiTheme({

});

const inputRootBase = {
  '&$focused': {
    color: colors.white,
  },
  '&$disabled': {
    color: colors.white,
    backgroundColor: 'rgba(0, 0, 0, 0.30)',
  },
  '&:before': {
    borderBottom: 0,
  },
  '&:after': {
    borderBottom: `2px solid ${colors.primaryBlue}`,
  },
  '&&&&:hover:before': {
    borderBottom: 0,
  },
  '&>div:nth-of-type(2)': {
    '&>button:first-of-type': {
      color: colors.textLightGrey,
    },
  },
  '& .MuiInputBase-root.Mui-disabled': {
    color: 'rgba(255, 255, 255, 0.6)', // (default alpha is 0.38)
  },
  height: 56,
  color: colors.white,
  background: colors.inputFieldBackground,
  width: '100%',
};
const inputRootMultiline = {
  ...inputRootBase,
  height: 'auto',
  lineHeight: '25px',
};

const input = {
  root: {
    ...inputRootBase,
  },
  searchField: {
    ...inputRootBase,
  },
  percentField: {
    ...inputRootBase,
    width: 80,
    height: 40,
  },
  datePicker: {
    ...inputRootBase,
    '&:hover': {
      '&$disabled': {
        background: 'rgba(0, 0, 0, 0.30)',
      },
      background: '#333748',
      transition: '0.15s',
    },
    paddingLeft: 10,
    width: 205,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  datePickerOpen: {
    ...inputRootBase,
    '&$disabled': {
      backgroundColor: '#333748',
      color: colors.white,
    },
    '&:hover': {
      '&$disabled': {
        background: 'rgba(0, 0, 0, 0.10)',
      },
      background: '#333748',
      transition: '0.15s',
    },
    paddingLeft: 10,
    width: 205,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },

  assignTaskDatePicker: {
    ...inputRootBase,
    '&:hover': {
      '&$disabled': {
        background: 'rgba(0, 0, 0, 0.30)',
      },
      background: '#333748',
      transition: '0.15s',
    },
    paddingLeft: 10,
    width: 190,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    backgroundColor: colors.inputFieldBackgroundAlt,
  },
  timeSelect: {
    ...inputRootBase,
    width: 150,
    minHeight: 56,
  },
  textArea: {
    ...inputRootBase,
    width: '100%',
    minHeight: 85,
    alignItems: 'initial',
    paddingTop: 15,
  },
  noLabel: {
    '&>div>div': {
      paddingTop: 10,
    },
    height: 56,
  },
  dateContainer: {
    '&>div:first-of-type': {
      '&>p': {
        whiteSpace: 'nowrap',
        width: 205,
      },
      margin: 0,
    },
    height: 71,
  },
  expandMoreIcon: {
    root: {
      color: colors.white,
    },
    disabled: {
      color: colors.textLightGrey,
    },
  },
  focused: {},
  disabled: {},
  selected: {},
  error: {},
  input: {},
};
const multiline = {
  ...input,
  root: {
    ...inputRootMultiline,
  },
};
const inputLabel = {
  root: {
    '&$focused': {
      color: colors.white,
    },
    '&$disabled': {
      color: colors.textDarkGrey,
    },
    '&$error': {
      color: colors.white,
    },
    color: colors.white,
    fontWeight: 425,
  },
  checkboxLabel: {
    color: colors.textLightGrey,
    fontSize: 14.75,
    fontWeight: 425,
  },
  checkboxPrimary: {
    '&$disabled': {
      color: colors.white,
    },
    color: colors.white,
  },
};

const dialogRootBase = {
  backgroundColor: colors.mediumBlueBackground,
  overflowY: 'initial',
  borderRadius: 10,
};

const dialogAssignTaskScrollbarBase = {
  '&>div:first-of-type': {
    overflowX: 'hidden !important',
  },
  height: '90vh',
  width: '600px !important',
};

const dialog = {
  root: dialogRootBase,
  content: {
    container: {
      marginBottom: 'initial',
    },
  },
  actions: {
    container: {
      paddingBottom: 24,
    },
  },
  assignTask: {
    content: {
      root: {
        ...dialogRootBase,
        width: 600,
      },
      messageContainer: {
        paddingTop: 10,
      },
      successOuterContainer: {
        ...dialogAssignTaskScrollbarBase,
        maxHeight: 355,
      },
      successContainer: {
        width: 350,
        paddingTop: 20,
      },
      resultsContainer: {
        height: 300,
        padding: '5px 0',
      },
      dueDateContainer: {
        paddingTop: 24,
      },
      outerContainer: {
        overflowY: 'none',
      },
      inviteContainer: {
        marginBottom: 'initial',
      },
      headerContainer: {
        paddingBottom: 24,
      },
      actionsContainer: {
        paddingBottom: 24,
      },
      tabPanelContainer: {
        padding: '15px 15px 15px 15px',
        backgroundColor: colors.darkBlueBackground,
      },
    },
    label: {
      errorMessage: {
        color: colors.white,
        fontWeight: 500,
      },
      successMessage: {
        color: colors.white,
        fontWeight: 500,
        letterSpacing: 1.2,
      },
      dueDate: {
        color: colors.white,
        fontSize: 16,
        fontWeight: 500,
      },
      appbarHeader: {
        fontWeight: 500,
        color: colors.white,
      },
    },
    icon: {
      error: {
        paddingRight: 10,
        color: colors.danger,
        height: 40,
        width: 48,
      },
      success: {
        color: colors.limeGreem,
        height: 100,
        width: '100%',
      },
    },
    scrollbar: {
      outer: {
        root: {
          ...dialogAssignTaskScrollbarBase,
          maxHeight: 750,
        },
        invite: {
          ...dialogAssignTaskScrollbarBase,
          maxHeight: 832,
        },
        loading: {
          ...dialogAssignTaskScrollbarBase,
          maxHeight: 800,
        },
        rootAlt: {
          ...dialogAssignTaskScrollbarBase,
          maxHeight: 446,
        },
        inviteAlt: {
          ...dialogAssignTaskScrollbarBase,
          maxHeight: 508,
        },
        loadingAlt: {
          ...dialogAssignTaskScrollbarBase,
          maxHeight: 475,
        },
        vertical: {
          width: 4,
          borderRadius: 25,
          backgroundColor: colors.textLightGrey,
        },
      },
      inner: {
        root: {
          height: 300,
        },
        vertical: {
          width: 4,
          borderRadius: 25,
          backgroundColor: colors.inputFieldBackground,
        },
      },
    },
  },
  loading: {
    container: {
      display: 'flex',
      paddingTop: 18,
    },
  },
};

const misc = {
  loadingContainer: {
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingTop: 24,
  },
  breakline: {
    backgroundColor: colors.darkBlueBackground,
  },
  scrollbar: {
    verticalScroll: {
      width: 4,
      borderRadius: 25,
      backgroundColor: colors.inputFieldBackground,
    },
  },
  colors: {
    white: {
      color: colors.white,
    },
  },
  hyphen: {
    color: colors.textLightGrey,
  },
  noResults: {
    color: colors.white,
    letterSpacing: '.03em',
    fontStyle: 'italic',
  },
  empty: {},
};

export const newOrderTheme = createMuiTheme({
  ...esgMainTheme,
  shipmentModule: {
    expansionPanel: {
      root: {
        background: colors.mediumBlueBackground,
      },
      summary: {
        root: {
          '&$expansionPSExpanded': {
            minHeight: 'initial',
          },
        },
        expanded: {},
        titleContainer: {
          '&>:last-child': {
            marginLeft: 'auto',
          },
          alignItems: 'center',
        },
        title: {
          color: colors.white,
          fontSize: 19,
          fontWeight: 500,
        },
        subTitle: {
          color: colors.textLightGrey,
          fontSize: 14.5,
          fontWeight: 500,
        },
        content: {
          '&$expansionPSExpanded': {
            margin: '12px 0',
          },
        },
        task: {
          button: {
            color: colors.actionButtonText,
            borderRadius: 8,
            letterSpacing: 1.2,
            fontWeight: 500,
            fontSize: 14,
          },
          text: {
            fontWeight: 700,
            color: colors.actionButtonText,
            letterSpacing: 1.2,
            fontSize: 14,
            marginLeft: 5,
          },
        },
      },
      details: {
        root: {
          paddingTop: 0,
        },
      },
    },
    appBar: {
      root: {
        boxShadow: 'none',
        backgroundColor: colors.mediumBlueBackground,
      },
      indicator: {
        backgroundColor: colors.lightBlue,
      },
      tab: {
        '&$appBarTabDisabled': {
          '&$appBarTabSelected': {
            color: colors.white,
          },
          color: colors.textLightGrey,
        },
        color: colors.textLightGrey,
        fontSize: 14.6,
        padding: '0px 18px',
        borderBottom: `1px solid ${colors.inputFieldBackground}`,
      },
    },
    disabledModule: {
      color: colors.white,
      letterSpacing: '.03em',
      fontStyle: 'italic',
    },
    senderRecipient: {
      scrollbars: {
        root: {
          height: 390,
        },
      },
      contentOuterContainer: {
        // paddingTop: 8,
      },
      content: {
        container: {
          width: '100%',
          paddingTop: 15,
          background: colors.darkBlueBackground,
        },
        search: {
          padding: '0px 15px 0px',
        },
        results: {
          paddingLeft: 15,
          marginRight: 15,
        },
      },
      results: {
        noResults: {
          color: colors.white,
          letterSpacing: '.03em',
          fontStyle: 'italic',
          padding: 24,
        },
      },
      buttonContainer: {
        margin: '20px 0px 6px',
      },
      enterAddress: {
        header: {
          '&$addressHeaderItem': {
            paddingTop: 24,
          },
        },
        item: {},
        sectionHeader: {
          textTransform: 'capitalize',
          color: colors.white,
          fontWeight: 500,
          paddingTop: 15,
          fontSize: 17,
        },
        title: {
          textTransform: 'capitalize',
          color: colors.white,
          fontSize: 17,
          fontWeight: 500,
          paddingBottom: 5,
        },
        helpText: {
          color: colors.white,
          fontSize: 14.75,
          fontWeight: 425,
          fontStyle: 'italic',
        },
      },
    },
    billingPayment: {
      form: {
        width: '100%',
        padding: 24,
        backgroundColor: colors.darkBlueBackground,
      },
      submitted: {
        text: {
          color: colors.white,
          fontSize: 17,
        },
      },
      checkIcon: {
        color: colors.textGreen,
        width: '1.3em',
        height: '1.3em',
      },
      radio: {
        label: {
          '&>span:last-child': {
            color: colors.textLightGrey,
            fontSize: 16,
            fontWeight: 425,
          },
          color: colors.textLightGrey,
          marginRight: 10,
        },
        primary: {
          '&$disabled': {
            color: colors.textDarkGrey,
          },
          color: colors.white,
        },
      },
      base: {
        content: {
          headerContainer: {
            '&>:last-child': {
              paddingBottom: 24,
            },
            paddingTop: 8,
          },
          footerContainer: {
            paddingTop: 25,
          },
          popperContainer: {
            transform: 'translate3d(170px, 590px, 0px) !important',
          },
          toolTipContainer: {
            backgroundColor: colors.inputFieldBackground,
            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
            maxWidth: 470,
            fontSize: 14,
            padding: '24px 24px',
            fontWeight: 400,
            borderRadius: 8,
          },
        },
        label: {
          headerText: {
            color: colors.white,
            fontWeight: 500,
          },
          priceText: {
            color: colors.lightBlue,
            fontSize: 24,
            fontWeight: 500,
          },
        },
        item: {},
      },
      creditCardPayment: {
        content: {
          processedContainer: {
            '&$baseItem': {
              paddingTop: 50,
            },
            justifyContent: 'center',
          },
          processingContainer: {
            paddingBottom: 35,
            alignItems: 'center',
          },
          loadingContainer: {
            marginLeft: 'auto',
            marginRight: 'auto',
            paddingTop: 24,
          },
          defaultAddressContainer: {
            '&$miscEmpty': {
              paddingBottom: 0,
            },
          },
          noDefaultSenderContainer: {
            paddingTop: 12,
          },
          addressFormContainer: {
            '&$miscEmpty': {
              paddingTop: 0,
              paddingBottom: 0,
            },
          },
        },
        label: {
          processingText: {
            color: colors.white,
          },
          noDefaultSender: {
            color: colors.white,
            fontSize: 14.75,
            fontStyle: 'italic',
          },
        },
        dropInUI: {
          paddingBottom: 15,
        },
        address: {
          '&>:nth-of-type(2)': {
            paddingTop: 0,
          },
        },
        sectionHeader: {
          textTransform: 'capitalize',
          color: colors.white,
          fontWeight: 500,
          paddingTop: 15,
          fontSize: 17,
        },
      },
      billDefaultCarrier: {
        content: {
          container: {
            paddingTop: 24,
          },
          summary: {
            header: {
              percentContainer: {
                '&$baseItem': {
                  display: 'inline-grid',
                  alignContent: 'center',
                  padding: 0,
                  paddingLeft: 38,
                },
              },
            },
            detail: {
              container: {
                paddingBottom: 12,
              },
              titleContainer: {
                maxWidth: 'max-content',
              },
              descriptionContainer: {
                minWidth: 'max-content',
              },
            },
          },
        },
        label: {
          header: {
            textTransform: 'capitalize',
            color: colors.white,
            fontWeight: 500,
            fontSize: 17,
          },
          sectionHeader: {
            textTransform: 'capitalize',
            color: colors.white,
          },
          summaryPercent: {
            padding: '5px 16px',
            color: colors.white,
            background: colors.inputFieldBackground,
          },
          summaryTitle: {
            color: colors.textLightGrey,
            fontSize: 14.75,
            minHeight: 24,
          },
          dropDownListItem: {
            color: colors.white,
            whiteSpace: 'noWrap',
            fontSize: 15,
          },
        },
        input: {
          container: {
            root: {
              '&$baseItem': {
                flexGrow: 1,
              },
            },
            half: {
              '&$baseItem': {
                width: '50.8%',
              },
            },
            dropDown: {
              position: 'relative',
              display: 'inline-block',
              width: '100%',
            },
            dropDownList: {
              position: 'absolute',
              backgroundColor: colors.dropDownList,
              boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)',
              zIndex: 2,
              width: '100%',
            },
            dropDownListItem: {
              width: '100%',
              justifyContent: 'left',
            },
            percent: {
              paddingTop: 10,
              paddingLeft: 16,
            },
          },
        },
        icon: {
          edit: {
            container: {
              padding: '6px 12px 6px 0px',
            },
            disabled: {
              color: colors.textDarkGrey,
            },
          },
          delete: {
            container: {
              padding: '0px 12px 6px 0px',
            },
          },
        },
        scrollbar: {
          trackHorizontal: {
            right: 2,
            bottom: 2,
            left: 2,
            borderRadius: 3,
          },
        },
      },
      billOtherCarrier: {
        content: {
          container: {
            paddingTop: 24,
          },
        },
        label: {
          header: {
            textTransform: 'capitalize',
            color: colors.white,
            fontWeight: 500,
            fontSize: 17,
          },
        },
        input: {
          container: {
            root: {
              '&$baseItem': {
                flexGrow: 1,
              },
            },
          },
        },
      },
    },
    shippingDocuments: {
      base: {
        content: {
          documentsContainer: {
            margin: 'initial',
          },
          documentContainer: {
            '&$miscEmpty': {
              paddingBottom: 0,
            },
            width: 'min-content',
          },
          imageContainer: {
            width: 150,
          },
          trackingContainer: {
            padding: 0,
          },
        },
        label: {
          tooltip: {
            fontSize: 14,
            maxWidth: 'none',
            borderRadius: 8,
            backgroundColor: colors.inputFieldBackground,
            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
          },
          documentTitle: {
            color: colors.textLightGrey,
            textTransform: 'capitalize',
            width: 140,
          },
          noDocuments: {
            color: colors.white,
            letterSpacing: '.03em',
            fontStyle: 'italic',
          },
        },
        icon: {
          download: {
            '&$miscEmpty': {
              color: colors.textLightGrey,
            },
            padding: '5px 5px',
          },
        },
      },
    },
    schedulePickup: {
      base: {
        content: {
          formContainer: {
            backgroundColor: colors.darkBlueBackground,
            padding: 24,
          },
          dateTimeContainer: {
            marginBottom: 13,
          },
          mapContainer: {
            width: '100%',
            height: 415,
            paddingBottom: 24,
          },
        },
        label: {
          sectionHeader: {
            textTransform: 'capitalize',
            color: colors.white,
            fontWeight: 450,
          },
          sectionSubHeader: {
            textTransform: 'capitalize',
            color: colors.white,
            fontWeight: 450,
            paddingTop: 20,

          },
        },
        icon: {
          clockIcon: {
            color: colors.lightBlue,
          },
        },
      },
      schedule: {
        content: {
          processingContainer: {
            '&$miscEmpty': {
              paddingTop: 25,
            },
          },
          loadingContainer: {
            marginLeft: 'auto',
            marginRight: 'auto',
          },
        },
      },
      scheduled: {
        label: {
          message: {
            color: colors.white,
            fontSize: 17,
          },
        },
        icon: {
          alarm: {
            color: colors.textGreen,
            width: '1.3em',
            height: '1.3em',
          },
        },
      },
      scheduleCancelled: {
        content: {
          footerContainer: {
            padding: '25px 0px 0px',
          },
          messageContainer: {
            width: 'initial',
          },
        },
        label: {
          message: {
            color: colors.white,
            fontSize: 17,
          },
        },
        icon: {
          error: {
            color: colors.newOrderError,
            width: '1.3em',
            height: '1.3em',
          },
        },
      },
    },
  },
  navigationBar: {
    base: {
      button: {
        '&$miscEmpty': {
          border: `1px solid ${colors.textGrey}`,
          color: colors.textGrey,
        },
        float: 'left',
        fontWeight: 'bold',
        border: '1px solid #2A79D4',
        color: colors.textGrey,
        borderRadius: 8,
        width: '100%',
        height: 36,
      },
    },
    scrollbar: {
      root: {
        '&>div:first-of-type': {
          // maxHeight: '55vh',
          position: 'relative !important',
          overflowX: 'hidden !important',
        },
      },
      trackHorizontal: {
        display: 'none',
      },
    },
  },
  input,
  multiline,
  inputLabel,
  dialog,
  misc,
});
