import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Slide,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Field, Form, Formik } from 'formik';
import * as colors from '../styles/colors';
import ScrollWindow from './common/ScrollWindow';
import NewOrderCustomTextField from './common/NewOrderCustomTextField';
import { CustomCheckbox } from './common/InputComponents';
import {
  FORM_FIELDS, FORM_LABELS, LB, OZ,
} from './ItemProductDetails/itemDetailsConstants';
import Codes from './ItemProductDetails/Item/Codes';
import Description from './ItemProductDetails/Item/Description';
import CustomSelectTextField from './common/CustomSelectTextField';
import TariffCode from './ItemProductDetails/Item/TariffCode';
import TaxCode from './ItemProductDetails/Item/TaxCode';
import CountryOfManufacture from './ItemProductDetails/Item/CountryOfManufacture';
import ProductDimensions from './ItemProductDetails/Item/Dimensions';
import ProductWeight from './ItemProductDetails/Item/Weight';
import ProductUnitCost from './ItemProductDetails/Item/UnitCost';
import { useItemProductDetailsFormStyles } from './ItemProductDetails/itemDetailsStyles';
import { addMaterial, editMaterial } from '../utils/materialsClient';
import ProductImage from './ItemProductDetails/Item/Product/Image';

const ACTIVE_FIELD = {
  key: 'isActive',
  label: 'Active',
};

const COMMON_BUTTON_STYLES = {
  minWidth: 114,
  height: 36,
  fontWeight: 'bold',
  borderRadius: 8,
};

const useStyles = makeStyles(() => ({
  dialogTitle: {
    paddingLeft: 30,
    marginBottom: 15,
  },
  paperFullScreen: {
    width: '40%',
    height: '92%',
    position: 'absolute',
    right: 0,
    backgroundColor: '#1E202E',
    marginTop: '90px',
  },
  dialogTitleText: {
    fontSize: 25,
    color: colors.white,
    fontWeight: 500,
  },
  dialogContent: {
    padding: '0 0',
    overflow: 'hidden',
    height: 'inherit',
  },
  dialogContentCard: {
    backgroundColor: colors.darkBlueBackground,
    padding: '20px 15px',
  },
  innerDialogContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '0 30px',
  },
  profileManagementFormOuterContainer: {
    backgroundColor: 'transparent !important',
    padding: '0 !important',
  },
  profileManagementFormInnerContainer: {
    padding: '0 !important',
  },
  header: {
    fontSize: 20,
    fontWeight: 500,
    color: colors.white,
    letterSpacing: '0.25px',
    marginBottom: '1rem',
  },
  fieldContainer: {
    marginBottom: '12px',
  },
  buttonsContainer: {
    width: 270,
    margin: '30px 0px',
  },
  primaryButton: {
    ...COMMON_BUTTON_STYLES,
    backgroundColor: colors.buttonBackgroundBlue,
    color: colors.white,
  },
  secondaryButton: {
    ...COMMON_BUTTON_STYLES,
    border: `1px solid ${colors.buttonBackgroundBlue}`,
    color: colors.secondaryButton,
  },
  progress: {
    marginTop: '15px',
  },
}));

const EDITABLE_FIELDS = [
  FORM_FIELDS.dimensionsUnit.key,
  FORM_FIELDS.dimensionsLength.key,
  FORM_FIELDS.dimensionsWidth.key,
  FORM_FIELDS.dimensionsHeight.key,
  FORM_FIELDS.weight.key,
  FORM_FIELDS.weightUnit.key,
  FORM_FIELDS.smallWeight.key,
  FORM_FIELDS.smallWeightUnit.key,
  FORM_FIELDS.merchantCost.key,
  FORM_FIELDS.currencyCode.key,
  FORM_FIELDS.harmonizedCode.key,
  FORM_FIELDS.eccnNo.key,
  FORM_FIELDS.description.key,
  FORM_FIELDS.countryOfManufacture.key,
  FORM_FIELDS.tariffCode.key,
  FORM_FIELDS.taxCode.key,
];

const Transition = React.forwardRef((props, ref) => <Slide direction="left" ref={ref} {...props} />);

const MaterialModal = ({
  open,
  material,
  categories,
  dimensionUnits,
  weightUnits,
  smallWeightUnits,
  currencyUnits,
  countries,
  onSubmit,
  onClose,
}) => {
  const classes = useStyles();
  const productDetailsClasses = useItemProductDetailsFormStyles();
  const [customImage, setCustomImage] = React.useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState('');

  const handleClose = () => {
    setError('');
    onClose(-1, null);
  };

  const materialAction = () => (material ? editMaterial : addMaterial);

  const handleSubmit = (values) => {
    setIsSaving(true);
    setError('');
    const isNewMaterial = !material;
    const payload = isNewMaterial ? values : { ...material, ...values };
    const fn = materialAction();

    fn(payload, customImage)
      .then((response) => {
        setIsSaving(false);

        onSubmit(response, isNewMaterial);

        handleClose();
      }).catch((e) => {
        let errorMessage = null;

        try {
          errorMessage = JSON.parse(e.message).error;
        } catch (err) {
          errorMessage = `Unexpected error: ${e}`;
        }

        setIsSaving(false);
        setError(errorMessage);
      });
  };

  return (
    <Dialog
      open={open}
      classes={{ paperFullScreen: classes.paperFullScreen }}
      TransitionComponent={Transition}
      scroll="paper"
      fullScreen
      disablePortal
      onClose={handleClose}
    >
      <DialogTitle className={classes.dialogTitle}>
        <Typography className={classes.dialogTitleText}>
          {isEmpty(material) ? 'Add' : 'Edit' }
          {' '}
          Material
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <ScrollWindow>
          <div
            direction="column"
            className={classes.innerDialogContentContainer}
          >
            <Formik
              validationSchema={Yup.object().shape({
                [FORM_FIELDS.itemName.key]: Yup.string().required('Required'),
                [FORM_FIELDS.harmonizedCode.key]: Yup.string().test(
                  'is-required',
                  'Required',
                  (value, context) => !isEmpty(context.parent[FORM_FIELDS.eccnNo.key]) || !isEmpty(value),
                ),
                [FORM_FIELDS.eccnNo.key]: Yup.string().test(
                  'is-required',
                  'Required',
                  (value, context) => !isEmpty(context.parent[FORM_FIELDS.harmonizedCode.key]) || !isEmpty(value),
                ),
              })}
              initialValues={{
                [FORM_FIELDS.itemId.key]: get(material, FORM_FIELDS.itemId.key, ''),
                [FORM_FIELDS.itemName.key]: get(material, FORM_FIELDS.itemName.key, ''),
                [FORM_FIELDS.itemCategory.key]: get(material, FORM_FIELDS.itemCategory.key, ''),
                [FORM_FIELDS.sku.key]: get(material, FORM_FIELDS.sku.key, ''),
                [FORM_FIELDS.binNumber.key]: get(material, FORM_FIELDS.binNumber.key, ''),
                itemDimensions: {
                  unitOfMeasurement: get(material, FORM_FIELDS.dimensionsUnit.key, 'IN'),
                  length: get(material, FORM_FIELDS.dimensionsLength.key, ''),
                  width: get(material, FORM_FIELDS.dimensionsWidth.key, ''),
                  height: get(material, FORM_FIELDS.dimensionsHeight.key, ''),
                },
                itemWeight: {
                  weight: get(material, FORM_FIELDS.weight.key, ''),
                  unitOfMeasurement: get(material, FORM_FIELDS.weightUnit.key, LB),
                  smallWeight: get(material, FORM_FIELDS.smallWeight.key, ''),
                  unitOfMeasurementSmall: get(material, FORM_FIELDS.smallWeightUnit.key, OZ),
                },
                units: {
                  unitValue: {
                    currency: get(material, FORM_FIELDS.currencyCode.key, 'USD'),
                    amount: get(material, FORM_FIELDS.merchantCost.key, ''),
                  },
                },
                [FORM_FIELDS.harmonizedCode.key]: get(material, FORM_FIELDS.harmonizedCode.key, ''),
                [FORM_FIELDS.eccnNo.key]: get(material, FORM_FIELDS.eccnNo.key, ''),
                [FORM_FIELDS.description.key]: get(material, FORM_FIELDS.description.key, ''),
                [FORM_FIELDS.countryOfManufacture.key]: get(material, FORM_FIELDS.countryOfManufacture.key, ''),
                [FORM_FIELDS.tariffCode.key]: get(material, FORM_FIELDS.tariffCode.key, ''),
                [FORM_FIELDS.taxCode.key]: get(material, FORM_FIELDS.taxCode.key, ''),
                [ACTIVE_FIELD.key]: get(material, ACTIVE_FIELD.key, true),
                [FORM_FIELDS.isMaterialCheckRequired.key]: get(material, FORM_FIELDS.isMaterialCheckRequired.key, false),
              }}
              onSubmit={handleSubmit}
            >
              {(formikProps) => (
                <>
                  <Form className={classes.dialogContentCard}>
                    <Grid className={classes.fieldContainer} item>
                      <ProductImage
                        itemUrl={get(material, FORM_FIELDS.itemUrl.key, '')}
                        formikProps={formikProps}
                        customImage={customImage}
                        setCustomImage={setCustomImage}
                      />
                    </Grid>
                    <Grid className={classes.fieldContainer} item>
                      <Field
                        type="text"
                        style={{ width: '100%' }}
                        name={FORM_FIELDS.itemId.key}
                        label={FORM_FIELDS.itemId.label}
                        component={NewOrderCustomTextField}
                        disabled
                      />
                    </Grid>
                    <Grid className={classes.fieldContainer} item>
                      <Field
                        type="text"
                        style={{ width: '100%' }}
                        name={FORM_FIELDS.itemName.key}
                        label={FORM_FIELDS.itemName.label}
                        component={NewOrderCustomTextField}
                      />
                    </Grid>
                    <Grid className={classes.fieldContainer} item>
                      <Field
                        type="text"
                        style={{ width: '100%' }}
                        name={FORM_FIELDS.itemCategory.key}
                        label={FORM_FIELDS.itemCategory.label}
                        component={CustomSelectTextField}
                      >
                        {categories && categories.map((option) => (
                          <MenuItem
                            key={option.type}
                            value={option.type}
                          >
                            {option.description}
                          </MenuItem>
                        ))}
                      </Field>
                    </Grid>
                    <Grid className={classes.fieldContainer} item container spacing={1}>
                      <Grid item xs={6}>
                        <Field
                          type="text"
                          style={{ width: '100%' }}
                          name={FORM_FIELDS.sku.key}
                          label={FORM_FIELDS.sku.label}
                          component={NewOrderCustomTextField}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          type="text"
                          style={{ width: '100%' }}
                          name={FORM_FIELDS.binNumber.key}
                          label={FORM_FIELDS.binNumber.label}
                          component={NewOrderCustomTextField}
                        />
                      </Grid>
                    </Grid>
                    <Grid className={classes.fieldContainer} item>
                      <ProductDimensions
                        formikProps={formikProps}
                        editableFields={EDITABLE_FIELDS}
                        dimensionUnits={dimensionUnits}
                      />
                    </Grid>
                    <Grid className={classes.fieldContainer} item>
                      <ProductWeight
                        formikProps={formikProps}
                        editableFields={EDITABLE_FIELDS}
                        weightUnits={weightUnits}
                        smallWeightUnits={smallWeightUnits}
                        weight={FORM_FIELDS.weight}
                        weightUnit={FORM_FIELDS.weightUnit}
                        smallWeight={FORM_FIELDS.smallWeight}
                        smallWeightUnit={FORM_FIELDS.smallWeightUnit}
                      />
                    </Grid>
                    <Grid item>
                      <Typography color="primary" className={productDetailsClasses.formFieldHeader}>
                        {FORM_LABELS.productMeasurements}
                      </Typography>
                    </Grid>
                    <Grid className={classes.fieldContainer} container item spacing={1}>
                      <ProductUnitCost
                        formikProps={formikProps}
                        editableFields={EDITABLE_FIELDS}
                        currencyUnits={currencyUnits}
                        columnsWidth={{
                          unitValue: 4,
                          currencyCode: 2,
                        }}
                      />
                    </Grid>
                    <Grid className={classes.fieldContainer} item>
                      <Codes
                        editableFields={EDITABLE_FIELDS}
                      />
                    </Grid>
                    <Grid className={classes.fieldContainer} item>
                      <Description editableFields={EDITABLE_FIELDS} />
                    </Grid>
                    <Grid className={classes.fieldContainer} item>
                      <CountryOfManufacture
                        editableFields={EDITABLE_FIELDS}
                        hiddenFields={[]}
                        countries={countries}
                      />
                    </Grid>
                    <Grid className={classes.fieldContainer} item container spacing={1}>
                      <Grid item>
                        <TariffCode editableFields={EDITABLE_FIELDS} />
                      </Grid>
                      <Grid item>
                        <TaxCode editableFields={EDITABLE_FIELDS} />
                      </Grid>
                    </Grid>
                    <Grid className={classes.fieldContainer} container item alignItems="center">
                      <CustomCheckbox
                        name={ACTIVE_FIELD.key}
                        label={(
                          <Typography color="primary" className={classes.eventCheckboxLabel}>
                            Set as active
                          </Typography>
                        )}
                      />
                    </Grid>
                    <Grid className={classes.fieldContainer} container item alignItems="center">
                      <CustomCheckbox
                        name={FORM_FIELDS.isMaterialCheckRequired.key}
                        label={(
                          <Typography color="primary" className={classes.eventCheckboxLabel}>
                            Requires ECO Approval
                          </Typography>
                        )}
                      />
                    </Grid>
                    {!isEmpty(error) && (
                      <Grid className={classes.fieldContainer} container item>
                        <Typography color="error">{error}</Typography>
                      </Grid>
                    )}
                  </Form>
                  <Grid container justify="center">
                    {isSaving ? <CircularProgress color="secondary" className={classes.progress} /> : (
                      <Grid item container justify="space-between" className={classes.buttonsContainer}>
                        <Button
                          className={classes.secondaryButton}
                          onClick={handleClose}
                        >
                          Cancel
                        </Button>
                        <Button
                          className={classes.primaryButton}
                          onClick={() => formikProps.submitForm()}
                        >
                          Submit
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </>
              )}
            </Formik>
          </div>
        </ScrollWindow>
      </DialogContent>
    </Dialog>
  );
};

MaterialModal.defaultProps = {
  open: false,
  material: null,
  categories: [],
  dimensionUnits: [],
  weightUnits: [],
  smallWeightUnits: [],
  currencyUnits: [],
  countries: [],
};

MaterialModal.propTypes = {
  open: PropTypes.bool,
  material: PropTypes.object,
  categories: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string,
    description: PropTypes.string,
  })),
  dimensionUnits: PropTypes.arrayOf(PropTypes.shape({
    dimensionCode: PropTypes.string,
    description: PropTypes.string,
  })),
  weightUnits: PropTypes.arrayOf(PropTypes.shape({
    weightCode: PropTypes.string,
    description: PropTypes.string,
  })),
  smallWeightUnits: PropTypes.arrayOf(PropTypes.shape({
    weightCode: PropTypes.string,
    description: PropTypes.string,
  })),
  currencyUnits: PropTypes.arrayOf(PropTypes.shape({
    countryCode: PropTypes.string,
    currencyCode: PropTypes.string,
    description: PropTypes.string,
  })),
  countries: PropTypes.arrayOf(PropTypes.object),
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default memo(MaterialModal);
