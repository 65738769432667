import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import * as colors from '../../../styles/colors';

const useStyles = makeStyles(() => ({
  display: {
    color: colors.white,
    fontSize: 12,
    whiteSpace: 'nowrap',
  },
}));

function ControlledValueDisplay(props) {
  const classes = useStyles();
  const { field, transform, style } = props;
  const { value } = field || props;

  return (
    <Typography className={classes.display} style={style}>
      {transform(value)}
    </Typography>
  );
}

ControlledValueDisplay.defaultProps = {
  transform: () => {},
  style: {},
};

ControlledValueDisplay.propTypes = {
  field: PropTypes.objectOf(PropTypes.any).isRequired,
  transform: PropTypes.func,
  style: PropTypes.objectOf(PropTypes.any),
};

export default memo(ControlledValueDisplay);
