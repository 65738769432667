import React from 'react';
import {
  SvgIcon,
} from '@material-ui/core';

const Class8Icon = (props) => (
  <SvgIcon {...props}>
    <path
      d="M 11,0 C 10.681982,0 10.365035,0.12120962 10.12197,0.36352202 L 0.36299044,10.122407 c -0.48398725,0.484732 -0.48398725,1.270454 0,1.755186 L 10.12197,21.636478 C 10.365035,21.87879 10.681982,22 11,22 c 0.318018,0 0.634965,-0.12121 0.87803,-0.363522 l 9.75898,-9.758885 c 0.483987,-0.484732 0.483987,-1.270454 0,-1.755186 L 11.87803,0.36352202 C 11.634965,0.12120962 11.318018,0 11,0 Z M 11,1.2878 20.711866,11 11,20.712307 1.2870632,11 11,1.2878"

    />
    <path
      d="M 16.950258,12.30247 14.456439,9.808461 c -0.15419,-0.1541891 -0.403679,-0.1541891 -0.55787,0 v 0 c -0.15419,0.154082 -0.15419,0.403997 0,0.558079 l 1.06006,1.060585 -1.339531,0.17175 c 0.0043,0.02506 0.0075,0.05097 0.0075,0.07742 0,0.223467 -0.180959,0.404532 -0.40475,0.404532 -0.183101,0 -0.337292,-0.121638 -0.387618,-0.288462 -0.04176,0.102792 -0.142412,0.175283 -0.260197,0.175283 -0.133845,0 -0.245205,-0.09283 -0.275187,-0.217471 h -1.746423 c -0.152049,0 -0.276258,0.123672 -0.276258,0.276148 0,0.152583 0.124209,0.276149 0.276258,0.276149 h 2.296798 v 0.188025 h -2.578409 c -0.153121,0 -0.2762587,0.123672 -0.2762587,0.276148 v 0 c 0,0.152476 0.1231377,0.276148 0.2762587,0.276148 h 2.578409 v 0.188026 h -2.296798 c -0.152049,0 -0.276258,0.123565 -0.276258,0.276148 0,0.152476 0.124209,0.276148 0.276258,0.276148 h 2.296798 v 0.188025 h -1.661832 c -0.15312,0 -0.276258,0.123566 -0.276258,0.276042 v 0 c 0,0.152583 0.123138,0.276148 0.276258,0.276148 h 5.10328 l 2.220773,-2.220858 h -1.56118"

    />
    <path
      d="m 7.976151,12.892992 c -0.1070766,0.150442 -0.2998148,0.221861 -0.4829162,0.168323 -0.066388,0.159758 -0.2237906,0.272294 -0.4068919,0.272294 -0.1777475,0 -0.3308675,-0.105577 -0.4004673,-0.257303 -0.1638275,0.0197 -0.3212303,-0.05407 -0.4122458,-0.183314 H 4.0785556 l 1.6489827,1.648539 H 8.9366299 V 12.892992 H 7.976151"

    />
    <path
      d="M 7.2983549,11.887123 7.085272,11.421236 6.8721892,11.887123 c -0.064246,0.141018 -0.1060061,0.251092 -0.1060061,0.373052 0,0.14573 0.09744,0.333112 0.3190889,0.333112 0.221649,0 0.3201598,-0.187382 0.3201598,-0.333112 0,-0.12196 -0.042831,-0.232034 -0.1070769,-0.373052"

    />
    <path
      d="M 6.8047308,10.406586 6.5916482,9.9405923 6.3785653,10.406586 c -0.064246,0.140912 -0.106006,0.250986 -0.106006,0.372945 0,0.145837 0.09744,0.33322 0.3190889,0.33322 0.2216486,0 0.3201594,-0.187383 0.3201594,-0.33322 0,-0.121959 -0.042831,-0.232033 -0.1070768,-0.372945"

    />
    <path
      d="M 12.859924,8.7824611 12.646841,8.3164673 12.433758,8.7824611 c -0.06425,0.1409115 -0.106006,0.2509855 -0.106006,0.3729448 0,0.1457299 0.09744,0.3332196 0.319089,0.3332196 0.221649,0 0.32016,-0.1874897 0.32016,-0.3332196 0,-0.1219593 -0.04283,-0.2320333 -0.107077,-0.3729448"

    />
    <path
      d="M 13.626594,7.063895 13.413511,6.5979013 13.200428,7.063895 c -0.06425,0.1409118 -0.106006,0.2509855 -0.106006,0.3729448 0,0.1458374 0.09744,0.33322 0.319089,0.33322 0.221649,0 0.32016,-0.1873826 0.32016,-0.33322 0,-0.1219593 -0.04283,-0.232033 -0.107077,-0.3729448"

    />
    <path
      d="M 12.996982,6.2424098 12.774263,5.9873553 c -0.0182,-0.021629 -0.04497,-0.034264 -0.07281,-0.036085 v 0 L 9.0576297,5.6814399 C 8.9130761,5.6707316 8.9002269,5.4597931 9.0437097,5.4354867 c 0.00106,-3.338e-4 0.00321,-5.463e-4 0.00428,-7.588e-4 L 12.502288,4.8744005 c 0.03641,-0.00589 0.06746,-0.030088 0.08138,-0.063924 l 0.130634,-0.31234 0.15419,-0.024949 0.282683,1.7442643 z m 0.380123,0.045828 -0.316947,-1.9538116 c -0.0043,-0.027839 -0.01927,-0.052681 -0.04176,-0.069171 -0.02356,-0.01649 -0.0514,-0.023128 -0.07924,-0.01863 l -0.316947,0.051397 c -0.03641,0.00589 -0.06746,0.030088 -0.08138,0.063924 L 12.410197,4.6741793 9.0137212,5.2250842 C 8.8145586,5.2575283 8.6389525,5.3658889 8.5200973,5.5302502 8.4023127,5.6946115 8.3541282,5.8951642 8.387322,6.0950749 c 0.059963,0.371874 0.3822642,0.6371005 0.747396,0.6371005 0.040689,0 0.081378,-0.00321 0.1220677,-0.00985 l 3.3964763,-0.5510117 0.22272,0.2551613 c 0.02356,0.027627 0.05996,0.040903 0.09637,0.035014 l 0.316947,-0.051397 c 0.02784,-0.0045 0.05354,-0.019917 0.0696,-0.042723 0.01606,-0.022807 0.02356,-0.051289 0.0182,-0.079129"

    />
    <path
      d="m 6.9739121,9.2603401 0.221649,-0.2550546 c 0.019274,-0.021629 0.046043,-0.034264 0.073883,-0.036084 V 8.9690939 L 10.912197,8.6993701 c 0.145625,-0.010708 0.157403,-0.2216468 0.01499,-0.2459528 -0.0022,-3.339e-4 -0.0032,-5.463e-4 -0.0043,-7.588e-4 L 7.4675363,7.8923402 c -0.035335,-0.00589 -0.066388,-0.030088 -0.080307,-0.064031 L 7.2565956,7.516076 7.102405,7.4911265 6.8197221,9.2353906 Z M 6.5927187,9.3061685 6.9096663,7.3522501 c 0.00535,-0.027733 0.020345,-0.052574 0.042831,-0.069064 0.023556,-0.01649 0.051397,-0.023235 0.079237,-0.01874 l 0.3169476,0.051503 c 0.036406,0.00589 0.067458,0.030088 0.081378,0.063924 L 7.5606936,7.6921075 10.956099,8.243012 c 0.200233,0.032444 0.375839,0.1408047 0.494694,0.305166 0.117785,0.1642542 0.164899,0.3649143 0.132776,0.5648247 -0.05996,0.371874 -0.383335,0.6371008 -0.748467,0.6369937 -0.03962,0 -0.08031,-0.00321 -0.120997,-0.00985 L 7.3176288,9.1892419 7.094909,9.4444033 C 7.071352,9.4720299 7.033875,9.4853062 6.99854,9.479417 L 6.680522,9.4280207 C 6.652683,9.4235225 6.628054,9.4081036 6.611993,9.3852976 6.594862,9.362491 6.588436,9.3340082 6.592719,9.3061685"

    />
  </SvgIcon>
);

export default Class8Icon;
