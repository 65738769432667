import React from 'react';
import {
  SvgIcon,
} from '@material-ui/core';

const Class7Icon = (props) => (
  <SvgIcon {...props}>
    <path
      d="M 11,0 C 10.681982,0 10.365035,0.12120962 10.12197,0.36352202 L 0.36299044,10.122407 c -0.48398725,0.484732 -0.48398725,1.270454 0,1.755186 L 10.12197,21.636478 C 10.365035,21.87879 10.681982,22 11,22 c 0.318018,0 0.634965,-0.12121 0.87803,-0.363522 l 9.75898,-9.758885 c 0.483987,-0.484732 0.483987,-1.270454 0,-1.755186 L 11.87803,0.36352202 C 11.634965,0.12120962 11.318018,0 11,0 Z M 11,1.2878 20.711866,11 11,20.712307 1.2870632,11 11,1.2878"

    />
    <path
      d="m 11.090127,11.874297 c 0.578608,0 1.048871,-0.502977 1.048871,-1.123576 0,-0.620475 -0.470263,-1.1234527 -1.048871,-1.1234527 -0.578609,0 -1.047719,0.5029777 -1.047719,1.1234527 0,0.620599 0.46911,1.123576 1.047719,1.123576"

    />
    <path
      d="m 11.090127,12.716918 c -0.334256,0 -0.646613,-0.09723 -0.916322,-0.264647 l -1.582526,2.939164 c 0.7353625,0.456275 1.588289,0.718575 2.498848,0.718575 0.910558,0 1.764639,-0.2623 2.5,-0.718575 l -1.583679,-2.939164 c -0.269709,0.167412 -0.582066,0.264647 -0.916321,0.264647"

    />
    <path
      d="m 12.925075,10.750721 h 3.165053 c 0,-1.9833699 -1.006224,-3.7139535 -2.500001,-4.6407116 l -1.583679,2.9392857 c 0.54864,0.3400131 0.918627,0.9738309 0.918627,1.7014259"

    />
    <path
      d="M 10.173805,9.0492951 8.591279,6.1100094 C 7.0963513,7.0367675 6.0901274,8.7673511 6.0901274,10.750721 h 3.166204 c 0,-0.727595 0.3699866,-1.3614128 0.9174736,-1.7014259"

    />
  </SvgIcon>
);

export default Class7Icon;
