/* eslint-disable react/prop-types */
import React from 'react';
import PackagingForm from './Form';
import NewOrderShipmentModule from '../common/NewOrderShipmentModule';
import BlockedPanelDisplay from '../common/PanelWarning';
import {
  loadShipmentPurposeOptions,
  loadShipmentFreightTypes,
  useMiscState, useMiscDispatch,
} from '../../context/miscDataContext';

const formatPackagingLabel = (packages) => `${packages?.numberOfPackages} package${
    packages?.numberOfPackages > 1 ? 's' : ''
}`;

export default function PackagingPane({
  shipment,
  isComplete,
  isReadyToBeCompleted,
  blockingMessage,
  packFormRef,
  ...shipmentProps
}) {
  const {
    shipmentFreightTypes,
    shipmentPurposeOptions,
  } = useMiscState();

  const miscDispatch = useMiscDispatch();

  React.useEffect(() => {
    loadShipmentFreightTypes(miscDispatch);
    loadShipmentPurposeOptions(miscDispatch);
  }, [miscDispatch]);

  const labelDetails = isComplete ? formatPackagingLabel(shipment?.packaging?.details) : '';

  return (
    <NewOrderShipmentModule
      labelDetails={labelDetails}
      isComplete={isComplete}
      isReadyToBeCompleted={isReadyToBeCompleted}
      shipment={shipment}
      blockingMessage={blockingMessage}
      {...shipmentProps}
    >
      {(isReadyToBeCompleted) ? ((renderProps) => (
        <PackagingForm
          shipmentPurposeOptions={shipmentPurposeOptions}
          shipmentFreightTypes={shipmentFreightTypes}
          shipment={shipment}
          isComplete={isComplete}
          isReadyToBeCompleted={isReadyToBeCompleted}
          packFormRef={packFormRef}
          {...shipmentProps}
          {...renderProps}
        />
      )) : (
        <BlockedPanelDisplay message={blockingMessage} />
      )}

    </NewOrderShipmentModule>
  );
}

PackagingPane.propTypes = {
  // label: PropTypes.string.isRequired,
  // formName: PropTypes.string.isRequired,
  // parentFormName: PropTypes.string.isRequired,
  // shipmentId: PropTypes.string.isRequired,
  // setSelectedForms: PropTypes.func.isRequired,
  // selectedForms: PropTypes.shape({
  //   child: PropTypes.string,
  //   parent: PropTypes.string.isRequired,
  // }),
};

PackagingPane.defaultProps = {};
