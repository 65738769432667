import React, { memo } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import has from 'lodash/has';
import get from 'lodash/get';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ProductWeight from '../../Weight';
import {
  BATTERY_MEASUREMENT_UNIT_OPTIONS,
  BATTERY_SMALL_MEASUREMENT_UNIT_OPTIONS,
  FORM_FIELDS_LITHIUM_BATTERY,
  FREIGHT_TYPES,
  LITHIUM_BATTERY_CONTAINED_KEY,
  SHIPPING_LITHIUM_BATTERIES_KEY,
  TRANSPORTATION_OPTIONS_BATTERY_CONTAINED_OR_PACKED,
  TRANSPORTATION_OPTIONS_NAME_KEY,
} from '../../../itemDetailsConstants';
import TransportationOptions from './TransportationOptions';
import DisplayItems from '../../../DisplayItems';
import { HAZMAT_BATTERIES_CONTAINED_OR_PACKED } from '../../../../../clientConstants';
import BatteryContainedWarning from './Warning';

const useStyles = makeStyles(() => ({
  productWeightContainer: {
    marginTop: '1rem',
  },
  netValue: {
    marginTop: '1.5rem',
  },
}));

function HazmatBatteriesContained({
  formikProps,
  editableFields,
}) {
  const classes = useStyles();
  const lithiumBattery = formikProps?.values[LITHIUM_BATTERY_CONTAINED_KEY];
  const batteryWeightUnits = {
    units: BATTERY_MEASUREMENT_UNIT_OPTIONS,
    smallUnits: BATTERY_SMALL_MEASUREMENT_UNIT_OPTIONS,
  };
  const weightKey = `${LITHIUM_BATTERY_CONTAINED_KEY}.${FORM_FIELDS_LITHIUM_BATTERY.weight.key}`;
  const weightUnitKey = `${LITHIUM_BATTERY_CONTAINED_KEY}.${FORM_FIELDS_LITHIUM_BATTERY.weightUnit.key}`;
  const newEditableFields = [...editableFields, weightKey, weightUnitKey];

  return (
    <>
      <Grid container item>
        <BatteryContainedWarning values={formikProps.values} />
      </Grid>
      <DisplayItems
        items={!isEmpty(lithiumBattery) ? [lithiumBattery] : []}
        freightType={FREIGHT_TYPES.hazmat}
        hazmatOption={HAZMAT_BATTERIES_CONTAINED_OR_PACKED}
        training={null}
        showLithiumBatteryBanner={false}
        showOnlyLithiumBattery
        handleItemDelete={null}
        handleItemEdit={null}
      />
      <Grid item container spacing={1}>
        {!has(formikProps.values, `${LITHIUM_BATTERY_CONTAINED_KEY}.weight`) && (
          <Grid className={classes.productWeightContainer} item xs={12}>
            <ProductWeight
              label="Weight"
              formikProps={formikProps}
              editableFields={newEditableFields}
              weightUnits={batteryWeightUnits.units}
              weight={{
                label: FORM_FIELDS_LITHIUM_BATTERY.weight.label,
                key: weightKey,
              }}
              weightUnit={{
                label: FORM_FIELDS_LITHIUM_BATTERY.weightUnit.label,
                key: weightUnitKey,
              }}
              showSmallWeightFields={false}
            />
          </Grid>
        )}
        {has(formikProps.values, SHIPPING_LITHIUM_BATTERIES_KEY) && !formikProps.values[SHIPPING_LITHIUM_BATTERIES_KEY] && (
          <Grid className={classes.productWeightContainer} item xs={12}>
            <TransportationOptions
              itemKey={TRANSPORTATION_OPTIONS_NAME_KEY}
              formikProps={formikProps}
              options={TRANSPORTATION_OPTIONS_BATTERY_CONTAINED_OR_PACKED}
              isBatteryContained
            />
          </Grid>
        )}
      </Grid>
    </>
  );
}

HazmatBatteriesContained.propTypes = {
  formikProps: PropTypes.objectOf(PropTypes.any).isRequired,
  editableFields: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default memo(HazmatBatteriesContained);
