import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import Typography from '@material-ui/core/Typography';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Tabs from '@material-ui/core/Tabs';
import Button from '@material-ui/core/Button';
import Tab from '@material-ui/core/Tab';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import * as colors from '../styles/colors';
import StyledCheckCircleIcon from './common/StyledCheckCircleIcon';
import statusHelp from '../utils/status';
import { useSingleOrderState } from '../context/singleOrderContext';
import { NEW_ORDER_NAVIGATION_BAR_STYLE } from '../styles/style';
import ScrollWindow from './common/ScrollWindow';
import {
  getBillingPaymentLabel,
} from '../utils/helpers';


const getLabelIcon = (isComplete) => {
  return (isComplete)
    ? <Grid item><StyledCheckCircleIcon isValidated isGreenWhenValid /></Grid>
    : <Grid item><RadioButtonUncheckedIcon color="primary" style={{ color: colors.textGrey }} /></Grid>;
};

const a11yProps = (index) => {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };;
}

const getLabelText = (label, className, tabKey, shipment) => {
  const isBillingPayment = ['billing', 'billingTaxes', 'billingCustoms'].includes(tabKey);
  return (
    <Grid item>
      <Typography className={className} style={{whiteSpace: 'nowrap'}}>
        {isBillingPayment ? getBillingPaymentLabel(label, shipment?.[tabKey]?.details?.billingCharges) : label}
      </Typography>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: colors.background,
    display: 'flex',
  },
  tabs: {},
  tab: {
    color: colors.white,
    textTransform: 'capitalize',
  },
  alignment: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginBottom: 6,
  },
  labelIcon: {
    minHeight: 0,
    marginBottom: 0,
    padding: 0,
  },
  heading: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightRegular,
  },
  expansionPanel: {
    backgroundColor: colors.background,
    boxShadow: 'unset',
  },
  expansionPanelLabel: {
    color: colors.white,
    fontSize: 16,
    fontWeight: 500,
    textTransform: 'capitalize',
  },
  expansionChildLabel: {
    color: colors.textLightGrey,
    fontSize: 14,
  },
  expansionIcon: {
    color: colors.textGrey,
  },
  navigationContainer: {
    top: '5px',
    width: '100%',
  },
  shipmentsContainer: {
    height: 400,
    overflowY: 'auto',
  },
  tabIndicator: {
    display: 'none',
  },
  expansionPanelSummary: {
    '&.Mui-expanded': {
      margin: 'initial',
    },
    marginTop: 0,
    marginBottom: 0,
  },
  buttonContainer: {
    minHeight: 'initial',
    padding: '0 0',
    width: '100%',
  },
  shipmentExpansionPanelRoot: {
    '&.Mui-expanded': {
      minHeight: 'initial',
    },
    padding: '0px 0px',
    width: '95%',
    fontSize: 14,
  },
  shipmentExpansionPanelDetailsRoot: {
    padding: '0 0',
  },
}));

function NewOrderFormTabs({
  shipmentId, handleSelect, selectedForms,
}) {
  const classes = useStyles();
  const [value, setValue] = React.useState(false);
  const parentForm = `${shipmentId}`;

  const shipment = useSingleOrderState().shipments?.[shipmentId];

  const panels = (shipment?.navigation.panels || shipment?.navigation || []).map(({name, ...other}) => {
    const nameModified = (name === 'origin') ? 'sender' : (name === 'destination') ? 'recipient' : name;
    return {
      name: nameModified,
      ...other,
    }
  }).filter((p) => !!p);


  React.useEffect(() => {
    if (selectedForms.parent !== parentForm) {
      setValue(false);
    } else {
      const index = panels.findIndex(({name}) => name === selectedForms.child);
      setValue(index === -1 ? false : index);
    }
  }, [selectedForms, parentForm]);

  const handleChange = (event, newValue) => {
    const selectedName = panels[newValue].name;
    handleSelect({
      parent: `${shipmentId}`,
      child: `${selectedName}`,
    });
    setValue(newValue);
  }

  return (
    <div className={classes.root}>
      <Tabs
        orientation="vertical"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        className={classes.tabs}
        textColor="primary"
        indicatorColor="secondary"
      >
        {
          panels.map(({
            name, label, 
            isCompleted,
          }, index) => (
            <Tab
              classes={{
                wrapper: classes.alignment,
                labelIcon: classes.labelIcon,
              }}
              className={classes.tab}
              key={name}
              id={name}
              label={(
                <Grid container direction="row" spacing={1} alignItems="center" wrap="nowrap" data-testid={`nav-tab-${name}`}>
                  {getLabelIcon(isCompleted)}
                  <Grid item>
                    <Typography className={classes.expansionChildLabel} style={{whiteSpace: 'nowrap'}}>
                      {label}
                    </Typography>
                  </Grid>
                </Grid>
                )}
              {...a11yProps(index)}
            />
          ))
}
      </Tabs>
    </div>
  );
}

NewOrderFormTabs.propTypes = {
  shipmentNumber: PropTypes.number.isRequired,
  handleSelect: PropTypes.func.isRequired,
  selectedForms: PropTypes.shape({
    child: PropTypes.string.isRequired,
    parent: PropTypes.string.isRequired,
  }).isRequired,
};

function OrderBasicsNavigationBar(props) {
  const { isComplete, handleSelect } = props;
  const classes = useStyles();
  const ORDER_BASICS = 'order basics';

  function onClick() {
    handleSelect({
      parent: ORDER_BASICS,
      child: '',
    });
  }

  return (
    <Tabs
      orientation="vertical"
      onClick={onClick}
      textColor="primary"
      classes={{
        root: classes.buttonContainer,
        indicator: classes.tabIndicator,
      }}
      value="none"
    >
      <Tab
        value="none"
        label={(
          <Grid container direction="row" spacing={1} alignItems="center">
            {getLabelIcon(isComplete)}
            {getLabelText('Order Basics', classes.expansionPanelLabel)}
          </Grid>
        )}
        classes={{ root: classes.buttonContainer }}
      />
    </Tabs>
  );
}

OrderBasicsNavigationBar.propTypes = {
  isComplete: PropTypes.number.isRequired,
  handleSelect: PropTypes.func.isRequired,
};

function SingleShipmentNavigationBar({
  shipmentNumber, shipmentId,
  handleSelect, selectedForms,
  panels,
}) {
  const classes = useStyles();
  const [isExpanded, setIsExpanded] = React.useState(false);
  const expansionPanelName = `${shipmentId}`;

  const shipment = useSingleOrderState().shipments[shipmentId];
  const { status = '' } = (shipment) || {};

  React.useEffect(() => {
    if (selectedForms.parent !== expansionPanelName) {
      setIsExpanded(false);
    } else {
      setIsExpanded(true);
    }
  }, [selectedForms, setIsExpanded, expansionPanelName]);

  function handleChange() {
    setIsExpanded(!isExpanded);
  }

  return (
    <div>
      <ExpansionPanel
        expanded={isExpanded}
        className={classes.expansionPanel}
        onChange={handleChange}
      >
        <ExpansionPanelSummary
          classes={{
            root: classes.shipmentExpansionPanelRoot,
            content: classes.expansionPanelSummary,
          }}
          expandIcon={(
            <ExpandMoreIcon
              color="primary"
              classes={{ colorPrimary: classes.expansionIcon }}
            />
          )}
          aria-controls="panel1a-content"
          id="panel1a-header"
          IconButtonProps={{
            disableRipple: true,
            disableFocusRipple: true,
          }}
        >
          <Grid container direction="row" spacing={1}>
            {getLabelIcon(statusHelp.isCompleted(status))}
            {getLabelText(`Shipment ${shipmentNumber}`, classes.expansionPanelLabel)}
          </Grid>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails
          classes={{ root: classes.shipmentExpansionPanelDetailsRoot }}
        >
          <NewOrderFormTabs
            handleSelect={handleSelect}
            shipmentNumber={shipmentNumber}
            shipmentId={shipmentId}
            selectedForms={selectedForms}
          />
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
}

SingleShipmentNavigationBar.propTypes = {
  shipmentNumber: PropTypes.number.isRequired,
  handleSelect: PropTypes.func.isRequired,
  selectedForms: PropTypes.shape({
    child: PropTypes.string.isRequired,
    parent: PropTypes.string.isRequired,
  }).isRequired,
};

export default function NewOrderNavigationBar({
  orderId,
  shipments, selectedForms, isChangingShipments,
  handleSelect, addShipment,
}) {
  const classes = useStyles();
  const subClasses = NEW_ORDER_NAVIGATION_BAR_STYLE();

  const getIsCreatedBy = (shipmentsObject) => {
    const keys = Object.keys(shipmentsObject);
    return orderId && !!(!keys.length || shipmentsObject[keys[0]].isCreatedBy);
  };

  const {
    baseButton,
    miscEmpty,
    scrollbarsRoot,
  } = subClasses;

  const shipmentsList = Object.keys(shipments).map((shipmentId, index) => (
    <Grid key={`new-order-nav-bar-grid-${shipmentId}`} item>
      <SingleShipmentNavigationBar
        key={`single-shipment-nav-bar-${shipmentId}`}
        shipmentId={shipmentId}
        shipmentNumber={index + 1}
        handleSelect={handleSelect}
        selectedForms={selectedForms}
      />
    </Grid>
  ));

  return (
    <Grid container direction="column" className={classes.navigationContainer}>
      <Grid item container>
        <ScrollWindow
          classes={{ root: scrollbarsRoot }}
        >
          {shipmentsList}
        </ScrollWindow>
        <Grid item style={{ width: '100%', paddingTop: 10 }}>
          {getIsCreatedBy(shipments) && (
            <Button
              color="primary"
              variant="outlined"
              disabled={isChangingShipments}
              onClick={addShipment}
              classes={{
                root: baseButton,
                disabled: miscEmpty,
              }}
            >
              Add a shipment
            </Button>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

NewOrderNavigationBar.propTypes = {
  orderId: PropTypes.string,
  handleSelect: PropTypes.func.isRequired,
  addShipment: PropTypes.func.isRequired,
  selectedForms: PropTypes.shape({
    child: PropTypes.string.isRequired,
    parent: PropTypes.string.isRequired,
  }).isRequired,
  isChangingShipments: PropTypes.bool.isRequired,
};

NewOrderNavigationBar.defaultProps = {
  orderId: null,
}
