import React, { memo } from 'react';
import PropTypes from 'prop-types';
import format from 'date-fns/format';
import has from 'lodash/has';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as colors from '../../styles/colors';
import { formatMonetaryAmount } from '../../utils/helpers';

const COLUMNS = [
  {
    key: 'serviceName',
    header: 'Service Name',
    width: '40%',
  },
  {
    key: 'listPrice',
    header: 'Actual Price',
    width: '20%',
    render: ({ listPrice, currencyCode }) => formatMonetaryAmount(currencyCode, listPrice),
  },
  {
    key: 'myPrice',
    header: 'Markup Price',
    width: '20%',
    render: ({ myPrice, currencyCode }) => formatMonetaryAmount(currencyCode, myPrice),
  },
  {
    key: 'expectedDeliveryDateTime',
    header: 'Expected',
    width: '20%',
    render: ({ expectedDeliveryDateTime }) => format(new Date(expectedDeliveryDateTime), 'dd MMM yyyy'),
  },
];

const useStyles = makeStyles(() => ({
  innerContainer: {
    backgroundColor: colors.darkBlueBackground,
    margin: '2rem 0',
    width: '100%',
    borderRadius: '8px',
  },
  tableContainer: {
    overflow: 'hidden',
    position: 'relative',
  },
  row: {
    display: 'flex',
    justifyContent: 'center',
  },
  table: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 'auto',
    flexGrow: '2',
    overflow: 'hidden',
  },
  tableBody: {
    position: 'relative',
    height: '100%',
  },
  cell: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    border: 'none',
    borderBottom: `1px solid ${colors.tableGreyBorder}`,
  },
  tableCellHeader: {
    color: colors.tableGreyText,
  },
  tableCellBody: {
    fontSize: 14,
    color: colors.white,
  },
}));

const CustomFreightSummary = ({ items }) => {
  const classes = useStyles();

  return (
    <Grid container item className={`${classes.innerContainer} ${classes.tableContainer}`}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow className={classes.row}>
            {COLUMNS.map((col) => (
              <TableCell
                className={`${classes.cell} ${classes.tableCellHeader}`}
                style={{ width: col.width }}
              >
                <Typography>{col.header}</Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody className={classes.tableBody}>
          {items.map((row) => (
            <TableRow className={classes.row} key={row.key}>
              {COLUMNS.map((col, idx) => (
                <TableCell className={classes.cell} key={`${row.serviceCode}-${col.key}`} style={{ width: col.width }}>
                  <Typography className={classes.tableCellBody}>
                    {has(col, 'render') ? col.render(row) : row[col.key]}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Grid>
  );
};

CustomFreightSummary.defaultProps = {
  items: [],
};

CustomFreightSummary.propTypes = {
  items: PropTypes.shape(PropTypes.object),
};

export default memo(CustomFreightSummary);
