/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { CSVLink } from 'react-csv';
import isEmpty from 'lodash/isEmpty';
import { makeStyles } from '@material-ui/core/styles';
import { Route, useHistory } from 'react-router-dom';
import { css } from 'emotion';
import {
  Grid,
  Typography,
  Button,
  IconButton,
  Tooltip,
  CircularProgress,
  Link as MuiLink,
} from '@material-ui/core/';
import DownloadIcon from '@material-ui/icons/GetApp';

import * as colors from '../styles/colors';
import ConfirmationModal from './common/ConfirmationModal';

import ErrorDialog from './common/NewOrderErrorDialog';
import {
  determineStatusClass,

} from '../utils/helpers';

const useStyles = makeStyles((theme) => ({
  centeredContainer: {
    alignItems: 'center',
  },
  tile: {
    backgroundColor: colors.shipmentCardBackground,
    padding: '8px',
    borderRadius: '10px',
    minHeight: '96px',
    marginBottom: '10px',
    width: '100%',
    position: 'relative',
  },
  shipmentHeading: {
    color: '#B5B9CC',
    fontSize: '12px',
    fontWeight: 500,
    marginTop: '10px',
    marginBottom: '7px',
  },
  shipmentIdHeader: {
    color: 'rgba(255,255,255,0.6)',
    letterSpacing: 0.4,
  },
  shipmentInfoHeader: {
    letterSpacing: 2,
  },
  link: {
    textDecoration: 'none',
    '&:visited': {
      textDecoration: 'none',
    },
  },
  trackShipment: {
    color: colors.secondaryButton,
    fontSize: '14px',
    fontWeight: '500',
    textTransform: 'none',
  },
  trackingButton: {
    padding: '0px 0px 0px 0px',
    color: '#94CCFB',
  },
  statusMessage: {
    paddingLeft: 8,
  },
  shipmentInformation: {
    color: 'white',
    fontSize: '14px',
  },
  allCapsWide: {
    textTransform: 'uppercase',
    letterSpacing: '1.2',
  },
  shipmentButton: {
    width: '137px',
    borderRadius: '5px',
  },
  boldHeading: {
    // fontWeight: 600,
  },
  onTimeHeading: {
    color: '#A7F4B4',
  },
  delayedHeading: {
    color: '#FED599',
  },
  incompleteHeading: {
    color: '#FF9FB4',
  },
  deliveredHeading: {
    color: '#B5B9CC',
  },
  buttonDiv: {
    alignItems: 'center',
    display: 'inherit',
  },
  onTimeBorder: {
    borderLeft: '3px solid #A7F4B4',
  },
  delayedBorder: {
    borderLeft: '3px solid #FED599',
  },
  incompleteBorder: {
    borderLeft: '3px solid #FF9FB4',
  },
  shipmentCardColumn: {
    padding: '0 2px',
    height: '100%',
  },
  shipmentCardButton: {
    alignItems: 'center',
    textAlign: 'center',
    height: '100%',
  },
  duplicateIconContainer: {
    alignSelf: 'flex-start',
  },
  duplicateIcon: {
    // position: 'relative',
    // top: '8px',
    // right: '8px',
    transform: 'rotate(180deg)',
    padding: '0',
  },
  tooltip: {
    background: 'transparent',
    fontSize: 12,
  },
}));

const CSV_COLUMNS_HEADER = [
  {
    key: 'shipmentId',
    label: 'Invoice number',
  },
  {
    key: 'quantity',
    label: 'Quantity',
  },
  {
    key: 'htsUS',
    label: 'US Harmonized Tariff Code',
  },
  {
    key: 'htsMX',
    label: 'MX Harmonized Tariff Code',
  },
  {
    key: 'comCode',
    label: 'Com. Code',
  },
  {
    key: 'satCode',
    label: 'Sat Code',
  },
  {
    key: 'description',
    label: 'Description',
  },
  {
    key: 'materialUnitCost',
    label: 'Matl Unit Cost',
  },
  {
    key: 'addtionalUnitCost',
    label: 'Additional Unit Cost',
  },
  {
    key: 'unitPrice',
    label: 'Unit price',
  },
  {
    key: 'subTotal',
    label: 'Subtotal (USD)',
  },
];
// Util functions
function CloseMasterButton(props) {
  const {
    label, shipmentId, status, handleCloseClick, ipd,
  } = props;

  const hovered = css`
  background:${colors.mediumBlueBackground}
  `;
  const disabled = css({ color: '#3B3F51' });
  const buttonStyle = css({
    fontWeight: 'bold',
    margin: 'auto',
    maxWidth: ' 148px',
    color: `${colors.secondaryButton}`,
    borderRadius: '8px',
    backgroundColor: `${colors.shipmentCardBackground}`,
    border: `1px solid ${colors.buttonBackgroundBlue}`,
    boxShadow: 'none',
    ':hover': hovered,
    ':disabled': disabled,
    width: '100%',
  });
  return (
    <Route
      render={({ history }) => (
        <Button
          className={buttonStyle}
          variant="contained"
          disabled={!ipd.isActive}
          onClick={() => handleCloseClick(shipmentId)}
        >
          {label}
        </Button>
      )}
    />
  );
}

CloseMasterButton.propTypes = {
  label: PropTypes.string.isRequired,
  shipmentId: PropTypes.string.isRequired,
};

function DownloadCSVButton(props) {
  const {
    shipmentId, handleDownloadClick, csvLinkButton, csvShipmentsState,
  } = props;

  const hovered = css`
  background:${colors.mediumBlueBackground}
  `;
  const disabled = css({ color: '#3B3F51' });
  const buttonStyle = css({
    fontWeight: 'bold',
    margin: 'auto',
    color: `${colors.secondaryButton}`,
    borderRadius: '8px',
    backgroundColor: `${colors.shipmentCardBackground}`,
    border: `1px solid ${colors.buttonBackgroundBlue}`,
    boxShadow: 'none',
    ':hover': hovered,
    ':disabled': disabled,
  });
  const tooltipStyle = css({
    background: colors.tooltipBackground,
    fontSize: 12,
  });
  return (
    <>
      <Tooltip title="Download CSV" classes={{ tooltip: tooltipStyle }}>
        <IconButton
          className={buttonStyle}
          onClick={() => handleDownloadClick(shipmentId)}
        >
          { csvShipmentsState?.loading ? <CircularProgress style={{ width: 25, height: 25 }} /> : <DownloadIcon color="primary" />}
        </IconButton>
      </Tooltip>
      <CSVLink
        ref={csvLinkButton}
        headers={CSV_COLUMNS_HEADER}
        data={csvShipmentsState.data}
        filename={`ipd-shipments-${shipmentId}-${new Date().toISOString()}.csv`}
        enclosingCharacter=""
      />

    </>
  );
}

DownloadCSVButton.propTypes = {
  handleDownloadClick: PropTypes.func.isRequired,
  shipmentId: PropTypes.string.isRequired,
};

export default function IpdShipmentCard(props) {
  const {
    shipment, openIpdMasterModal, lastCardRef, handleCloseClick, handleDownloadClick,
  } = props;
  const classes = useStyles();
  const {
    carrier,
    city,
    company,
    deliveryNo,
    receiptContactName,
    service,
    state,
    status,
    isExport,
    shipmentId,
    ipd,
  } = shipment;

  const [error, setError] = React.useState(null);
  const history = useHistory();
  const closeMasterLabel = 'close master';
  const [close, setClose] = React.useState(null);
  const confirmCloseOpen = close === 'confirm';
  const [csvShipmentsState, setCSVShipmentsState] = React.useState({
    data: [],
    loading: false,
  });

  const csvLinkButton = React.useRef(null);
  React.useEffect(() => {
    if (!isEmpty(csvShipmentsState.data) && csvLinkButton && csvLinkButton.current) {
      csvLinkButton.current.link.click();
    }
  }, [csvShipmentsState.data]);
  // Adds dynamic css for class heading
  const statusColorClass = determineStatusClass(shipment);
  const statusHeadingClass = [
    classes.shipmentInformation,
    classes.boldHeading,
    classes[statusColorClass.statusClass],
  ].join(' ');
  const recipientHeadingClass = [
    classes.shipmentInformation,
    classes.boldHeading,
  ].join(' ');

  const handleCloseButtonClick = () => {
    handleCloseClick(shipmentId);
    setClose(null);
  };

  const handleDownloadButtonClick = async () => {
    setCSVShipmentsState({
      data: [],
      loading: true,
    });
    const response = await handleDownloadClick(shipmentId);
    if (response) {
      const mergedData = response.map((d) => ({ ...d, description: Array.isArray(d.description) ? d.description.join(' ') : d.description }));

      setCSVShipmentsState(
        {
          data: mergedData,
          loading: false,
        },
      );
    } else {
      setCSVShipmentsState(
        {
          data: [],
          loading: false,
        },
      );
    }
  };

  return (
    <Grid
      container
      className={classes.tile}
      justify="space-between"
      ref={lastCardRef || null}
      data-testid="shipmentcard"
    >

      <Grid item xs={4}>
        <Grid container style={{ height: '100%' }}>
          <Grid item xs={3} className={classes.shipmentCardColumn}>
            <Grid item>
              <Typography
                className={`${classes.shipmentHeading} ${classes.shipmentInfoHeader}`}

              >
                STATUS
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={statusHeadingClass}>
                {status}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={4} className={classes.shipmentCardColumn}>
            <Grid item>
              <Typography
                className={`${classes.shipmentHeading} ${classes.shipmentInfoHeader}`}
              >
                DELIVERY/SHIPMENT#
              </Typography>
            </Grid>
            <Grid container item>

              <Grid item xs={12}>
                <Typography className={classes.shipmentInformation}>
                  <MuiLink
                    component="button"
                    variant="body2"
                    style={{ color: colors.secondaryButton }}
                    onClick={() => {
                      history.push(`/order/${shipmentId}`, { shipment: shipmentId });
                    }}
                  >
                    {deliveryNo}
                  </MuiLink>

                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={5} className={classes.shipmentCardColumn}>
            <Grid item>
              <Typography
                className={`${classes.shipmentHeading} ${classes.shipmentInfoHeader}`}
              >
                COMPANY
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={recipientHeadingClass}>
                {company}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={2}>
        <Grid container style={{ height: '100%' }}>
          <Grid item xs={6} className={classes.shipmentCardColumn}>
            <Grid item>
              <Typography
                className={`${classes.shipmentHeading} ${classes.shipmentInfoHeader}`}
              >
                CONTACT
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                className={`${classes.shipmentInformation} ${classes.allCapsWide}`}
              >
                {receiptContactName}
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={6} className={classes.shipmentCardColumn}>
            <Grid item>
              <Typography
                className={`${classes.shipmentHeading} ${classes.shipmentInfoHeader}`}
              >
                CITY
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                className={`${classes.shipmentInformation} ${classes.allCapsWide}`}
              >
                {city}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={2}>
        <Grid container style={{ height: '100%' }}>
          <Grid item xs={4} className={classes.shipmentCardColumn}>
            <Grid item>
              <Typography
                className={`${classes.shipmentHeading} ${classes.shipmentInfoHeader}`}
              >
                STATE
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                className={`${classes.shipmentInformation} ${classes.allCapsWide}`}
              >
                {state}
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={8} className={classes.shipmentCardColumn}>
            <Grid item>
              <Typography
                className={`${classes.shipmentHeading} ${classes.shipmentInfoHeader}`}
              >
                TRACKING/PRO#
              </Typography>
            </Grid>
            <Grid item>
              <MuiLink
                component="button"
                variant="body2"
                style={{ color: colors.secondaryButton }}
                onClick={(e) => {
                  openIpdMasterModal({
                    shipmentId,
                    trackingNumber: ipd?.tracking,
                    anchorEl: e.currentTarget,
                  });
                }}
              >
                {ipd?.tracking}
              </MuiLink>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <Grid container style={{ height: '100%' }}>
          <Grid item xs={3} className={classes.shipmentCardColumn}>
            <Grid item>
              <Typography
                className={`${classes.shipmentHeading} ${classes.shipmentInfoHeader}`}
              >
                CARRIER
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                className={`${classes.shipmentInformation} ${classes.allCapsWide}`}
              >
                {carrier}
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={4} className={classes.shipmentCardColumn}>
            <Grid item>
              <Typography
                className={`${classes.shipmentHeading} ${classes.shipmentInfoHeader}`}
              >
                SERVICE
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                className={`${classes.shipmentInformation} ${classes.allCapsWide}`}
              >
                {service}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            xs={4}
            className={`${classes.buttonDiv} ${classes.shipmentCardButton}`}
            spacing={1}
          >
            <Grid item xs={12}>
              {ipd?.isActive
              && (
              <CloseMasterButton
                label={closeMasterLabel}
                shipmentId={shipmentId}
                status={status}
                handleCloseClick={() => setClose('confirm')}
                ipd={ipd}
              />
              )}
            </Grid>

          </Grid>

          <Grid
            item
            xs={1}
            className={`${classes.buttonDiv} ${classes.shipmentCardButton}`}
            spacing={1}
          >
            <Grid item xs={12}>
              {isExport
              && (
              <DownloadCSVButton
                shipmentId={shipmentId}
                handleDownloadClick={handleDownloadButtonClick}
                csvLinkButton={csvLinkButton}
                csvShipmentsState={csvShipmentsState}
              />
              )}
            </Grid>

          </Grid>
        </Grid>
      </Grid>
      <ConfirmationModal
        open={confirmCloseOpen}
        onProceed={() => handleCloseButtonClick(shipmentId)}
        onCancel={() => setClose(null)}
        proceedLabel="Yes, Close"
        message={(
          <span>
            Are you sure you would like to close the Master Shipment
            {' '}
            {`${shipmentId}?`}
          </span>
        )}
      />
      <ErrorDialog
        open={!!error}
        onClose={() => setError(null)}
        errorContent={error}
      />
    </Grid>
  );
}

IpdShipmentCard.propTypes = {
  shipment: PropTypes.shape({
    status: PropTypes.string,
    shipmentId: PropTypes.string,
    recipient: PropTypes.string,
    carrier: PropTypes.string,
    updated: PropTypes.string,
    assignedTo: PropTypes.string,
    taskType: PropTypes.string,
    expectedDeliveryDateTime: PropTypes.string,
    actualDeliveryDateTime: PropTypes.string,
    actualShipDateTime: PropTypes.string,
    expectedPickupDateTime: PropTypes.string,
    iot: PropTypes.bool,
    iotTrackingNumber: PropTypes.string,
    orderId: PropTypes.string,
    iconType: PropTypes.string,
    currencyCode: PropTypes.string,
    amount: PropTypes.string,
    collaborators: PropTypes.arrayOf(
      PropTypes.shape({
        imageUrl: PropTypes.string.isRequired,
        alt: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ),
    isPendingTask: PropTypes.bool,
  }).isRequired,
  openTrackingModal: PropTypes.func.isRequired,
  lastCardRef: PropTypes.objectOf(PropTypes.any),
};

IpdShipmentCard.defaultProps = {
  lastCardRef: null,
};
