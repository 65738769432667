import React from 'react';
import { css } from 'emotion';
import { Route } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import Popper from '@material-ui/core/Popper';
import Grow from '@material-ui/core/Grow';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MenuItem from '@material-ui/core/MenuItem';
import { useAuth } from '../context/authContext';
import { useUserState, useUserDispatch, setProfilePictureError } from '../context/userContext';
import AboutModal from './AboutModal';
import UserAvatar from './common/UserAvatar';
import * as colors from '../styles/colors';

function navigateToProfileManagement(history) {
  history.push('/profile-management');
}

function navigateToLogout(history, logout) {
  logout();
  history.push('/login');
}

export default function AccountPanelButton(props) {
  const [open, setOpen] = React.useState(false);
  const [aboutModalOpen, setAboutModalOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const userDispatch = useUserDispatch();

  const { logout } = useAuth();
  const {
    imageUrl, name, isProfilePictureError,
  } = useUserState();
  const userInitials = name ? name.split(' ').map((str) => (str[0])).join('') : '';

  const classes = {
    popper: css` 
      z-index: 100;
      width: 128px;
      `,
    menu: css` 
      background-color: #626479;
      color: ${colors.white}
      `,
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleProfilePictureError = (newState) => {
    setProfilePictureError({ dispatch: userDispatch, isError: newState });
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleAboutModalClose = () => {
    setAboutModalOpen(false);
  };

  const handleAboutButtonClick = () => {
    setOpen(false);
    setAboutModalOpen(true);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }
  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <IconButton
        edge="end"
        aria-label="account of current user"
        color="inherit"
        ref={anchorRef}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <UserAvatar src={`${imageUrl}?${new Date()}`} alt={userInitials} isError={isProfilePictureError} setIsError={handleProfilePictureError} hasControlledErrorState />
      </IconButton>
      <Route render={({ history }) => (
        <Popper className={classes.popper} open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper className={classes.menu}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                    <MenuItem onClick={() => navigateToProfileManagement(history)}>Profile</MenuItem>
                    <MenuItem onClick={handleAboutButtonClick}>About</MenuItem>
                    <MenuItem onClick={() => navigateToLogout(history, logout)}>Logout</MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      )}
      />

      <AboutModal
        open={aboutModalOpen}
        handleClose={handleAboutModalClose}
      />
    </>
  );
}
