import React from 'react';
import shortId from 'shortid';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';

import Grid from '@material-ui/core/Grid';

import { css } from 'emotion';
import Navbar from '../components/Navbar';
import Breadcrumbs from '../components/Breadcrumbs';
import ShipmentFormExpandablePane from '../components/ShipmentFormExpandablePane';
import OrderBasicsExpandablePane from '../components/OrderBasicsExpandablePane';
import NewOrderNavigationBar from '../components/NewOrderNavigationBar';
import NewOrderErrorDialog from '../components/common/NewOrderErrorDialog';
import { Page } from '../components/common';
import * as colors from '../styles/colors';
import {
  useSingleOrderDispatch,
  useSingleOrderState,
  loadSingleOrder,
  addShipmentToOrder,
  cancelShipmentFromOrder,
} from '../context/singleOrderContext';
import { useContactsDispatch, loadDefaultSender } from '../context/contactsContext';
import { inDevelopment } from '../clientConstants';
import { formatDateShort, reverseTaskTypeToFormName } from '../utils/helpers';
import {
  loadCountries,
  loadCountryStates,
  useMiscDispatch,
} from '../context/miscDataContext';
import { useUserDispatch, useUserState, loadUser } from '../context/userContext';
import ConfirmationModal from '../components/common/ConfirmationModal';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: colors.background,
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  tabs: {
    // borderRight: `1px solid ${theme.palette.divider}`,
  },
  formContainer: {
    marginTop: 12,
    paddingLeft: 24,
  },
  progress: {
    margin: 'auto',
  },
}));

// UTIL FUNCTIONS
function isObjectAndIsNotEmpty(obj) {
  return !!(Object.entries(obj).length
    && obj.constructor === Object);
}

function parseOrderAndShipmentId(id) {
  const idMatch = id && id.match(/^([^-]+)-([0-9]+)-*([0-9]*)$/);
  const orderId = (idMatch && idMatch[1] && idMatch[2]) ? `${idMatch[1]}-${idMatch[2]}` : '';
  const urlShipmentId = orderId && idMatch[3] ? `${idMatch[1]}-${idMatch[2]}-${idMatch[3]}` : '';
  return { orderId, urlShipmentId };
}

function parsePanel(search) {
  return search.split('?').reduce((p, query) => {
    const splitQuery = query.split('=');
    if (splitQuery[0] === 'panel') {
      return reverseTaskTypeToFormName[splitQuery[1].toLowerCase()] || reverseTaskTypeToFormName[splitQuery[1]] || '';
    }
    return p;
  }, '');
}

export default function NewOrder(props) {
  const classes = useStyles();
  const { history, match } = props;
  const id = match.params.id || '';
  const { orderId, urlShipmentId } = parseOrderAndShipmentId(id);
  const panel = parsePanel(window.location.search);
  const [scrollToShipment, setScrollToShipment] = React.useState(null);
  const shipmentRef = React.useCallback((node) => {
    if (node) {
      window.scrollTo({
        top: node.offsetTop,
        behavior: 'auto',
      });
      setScrollToShipment(null);
    }
  });

  const [selectedForms, setSelectedForms] = React.useState(orderId ? {
    parent: urlShipmentId,
    child: panel,
  } : { parent: 'order basics', child: '' });

  const [isChangingShipments, setIsChangingShipments] = React.useState(false);
  const contactsDispatch = useContactsDispatch();
  const singleOrderState = useSingleOrderState();
  const singleOrderDispatch = useSingleOrderDispatch();
  const [errorContent, setErrorContent] = React.useState(null);
  const [cancelModalState, setCancelModalState] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [keepOrderOpen, setKeepOrderOpen] = React.useState(null);
  const DEFAULT_COUNTRY = '';
  const miscDispatch = useMiscDispatch();
  const userDispatch = useUserDispatch();
  const { account } = useUserState();

  function justCompletedOrderInit() {
    return (props.location.state && props.location.state.newOrder);
  }

  function getInitialFormState() {
    return {
      parent: `${orderId}-01`,
      child: account === 'worldemblem' ? 'recipient' : 'sender',
    };
  }

  React.useEffect(() => {
    const scrollToShipmentId = history
      && history.location
      && history.location.state
      && history.location.state.shipment;
    if (scrollToShipmentId) {
      setScrollToShipment(scrollToShipmentId);
      history.replace(`${history.location.pathname}`, {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (justCompletedOrderInit()) {
      // Remove newOrder field from state, so that on refresh the screen will re-fetch order data
      setSelectedForms(getInitialFormState());
      props.history.replace(`${window.location.pathname}`, {});
    } else {
      inDevelopment() && console.log('calling loadSingleOrder from NewOrder.js');
      setLoading(true);
      loadSingleOrder({ dispatch: singleOrderDispatch, orderId }).catch((e) => {
        setErrorContent(e.error || e.message || e);
        setKeepOrderOpen(false);
        setLoading(false);
      }).then(() => setLoading(false));
    }
  }, [orderId, singleOrderDispatch]);

  React.useEffect(() => {
    inDevelopment() && console.log('calling loadDefaultSender from NewOrder.js');
    loadDefaultSender(contactsDispatch);
    loadCountries(miscDispatch);
    loadCountryStates(miscDispatch, DEFAULT_COUNTRY);
    loadUser(userDispatch);
  }, [contactsDispatch, miscDispatch, userDispatch]);

  const changeShipments = async (type, shipmentId = null) => {
    switch (type) {
      case 'ADD':
        try {
          const addedShipment = await addShipmentToOrder({ dispatch: singleOrderDispatch, orderId })
            .then((data) => (data.shipment && data.shipment.shipmentId) || data.shipmentId);
          if (addedShipment)setSelectedForms({ parent: addedShipment, child: 'sender' });
        } catch (e) {
          setErrorContent(e.message);
          setKeepOrderOpen(true);
        }
        setIsChangingShipments(false);
        break;
      case 'CANCEL':
        try {
          await cancelShipmentFromOrder({ dispatch: singleOrderDispatch, orderId, shipmentId });
        } catch (e) {
          setErrorContent(e.message);
          setKeepOrderOpen(true);
        }
        setCancelModalState(null);
        break;
      default:
        console.log('Unknown type for command "change shipments"');
        break;
    }
  };

  return (
    <div
      className={classes.root}
    >
      <Navbar />
      <Page account={account}>
        <Breadcrumbs
          pages={[
            { title: 'My Orders', url: '/my-orders', id: shortId.generate() },
            { title: 'New Shipping Order', id: shortId.generate() },
          ]}
        />
        <Grid container justify="space-between">
          <Grid item style={{ width: '232px', marginTop: 12 }}>
            {!loading && (
            <NewOrderNavigationBar
              handleSelect={setSelectedForms}
              selectedForms={selectedForms}
              addShipment={() => {
                setIsChangingShipments(true);
                changeShipments('ADD');
              }}
              isChangingShipments={isChangingShipments}
              shipments={singleOrderState.shipments || []}
              orderId={orderId}
            />
            )}
          </Grid>
          <Grid
            style={{ width: 'calc(100% - 232px)' }}
            container
            direction="column"
            spacing={1}
            item
            className={classes.formContainer}
          >
            <Grid
              container
              direction="column"
              spacing={1}
              item
              className={classes.formContainer}
            >
              <Grid
                key="order-basics-expandable-pane"
                item
                style={{ width: '100%' }}
              >
                <OrderBasicsExpandablePane
                  order={singleOrderState.order ? singleOrderState.order : null}
                  isExistingOrder={singleOrderState.shipments
                    ? (isObjectAndIsNotEmpty(singleOrderState.shipments))
                    : false}
                  selectedForms={selectedForms}
                  accountType={useUserState().accountType}
                  setSelectedForms={setSelectedForms}
                  existingDate={(singleOrderState.order && singleOrderState.order.createdAt)
                    ? formatDateShort(singleOrderState.order.createdAt)
                    : ''}
                  existingOrderType={singleOrderState.order ? singleOrderState.order.orderType : ''}
                  existingPurpose={singleOrderState.order ? singleOrderState.order.purpose : ''}
                  existingCreator={(singleOrderState.order
                      && singleOrderState.order.createdBy)
                    ? singleOrderState.order.createdBy.fullName
                    : 'unknown'}
                  userFullName={useUserState().name || null}
                />
              </Grid>
              {(singleOrderState.shipments && !loading) ? (
                Array(
                  // eslint-disable-next-line no-shadow
                  Object.keys(singleOrderState.shipments).map((id, index) => (
                    <Grid
                      key={`shipment-form-expandable-pane-grid-${id}`}
                      item
                      style={{ width: '100%' }}
                    >
                      <ShipmentFormExpandablePane
                        key={`shipment-form-expandable-pane-${id}`}
                        shipmentData={
                        singleOrderState.shipments
                          ? singleOrderState.shipments[id]
                          : {}
                      }
                        shipmentNumber={index + 1}
                        selectedForms={selectedForms}
                        setSelectedForms={setSelectedForms}
                        cancelShipment={(shipmentId) => setCancelModalState(shipmentId)}
                        shipmentRef={id === scrollToShipment ? shipmentRef : undefined}
                      />
                    </Grid>
                  )),
                )
              ) : (
                loading && <CircularProgress className={classes.progress} />
              )}
            </Grid>
          </Grid>
        </Grid>
        <NewOrderErrorDialog
          open={!!errorContent}
          errorContent={errorContent}
          classes={{
            dialogRoot: css({
              backgroundColor: colors.mediumBlueBackground,
              overflowY: 'initial',
              borderRadius: 10,
            }),
            dialogContentContainer: css({ marginBottom: 'initial' }),
            dialogActionsContainer: css({
              paddingBottom: 24, width: '100%', justifyContent: 'center', flexDirection: 'column',
            }),
          }}
          onClose={() => {
            setErrorContent(null);

            if (keepOrderOpen === null) return;

            setKeepOrderOpen(null);
            if (!keepOrderOpen) props.history.replace('/', {});
          }}
        />
        <ConfirmationModal
          open={!!cancelModalState}
          message={cancelModalState && `Are you sure you would like to cancel shipment ${cancelModalState}?`}
          onProceed={() => cancelModalState && changeShipments('CANCEL', cancelModalState)}
          onCancel={() => setCancelModalState(null)}
          proceedLabel="YES, CANCEL"
          cancelLabel="NO, GO BACK"
        />
      </Page>
    </div>
  );
}

NewOrder.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};
