import React, {
  memo, useContext, useEffect, useRef, useState,
} from 'react';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import pick from 'lodash/pick';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { CircularProgress } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import * as colors from '../../styles/colors';
import Navbar from '../../components/Navbar';
import { Page } from '../../components/common';
import AvatarHeader from '../../components/common/AvatarHeader';
import AccountContext from '../../context/accountContext';
import AccountAddress from './Address';
import EmergencyContact from './EmergencyContact';
import Payment from './Payment';
import CostAllocation from './CostAllocation';
import Trainings from './Trainings';
import Notifications from './Notifications';
import Compliance from './Compliance';
import Miscellaneous from './Miscellaneous';
import { loadPaymentOptions } from '../../utils/accountClient';
import {
  ACCOUNT_ADDRESS_KEY,
  ACCOUNT_MANAGEMENT,
  COST_ALLOCATION_KEY,
  EMERGENCY_CONTACT_KEY,
  NOTIFICATIONS_KEY,
  MISCELLANEOUS_KEY,
  MISCELLANEOUS_FIELD_NAMES,
  PAYMENT_KEY,
  COMPLIANCE_KEY,
  COMPLIANCE_FIELD_NAMES,
  PRICING_LIST_KEY,
  CARRIERS_KEY,
} from './constants';
import NewOrderErrorDialog from '../../components/common/NewOrderErrorDialog';
import { getDialogStyle } from '../../styles/style';
import { useMiscState } from '../../context/miscDataContext';
import { loadCarriers, loadNotificationTypes, loadPricingModels } from '../../utils/miscClient';
import Carriers from './Carriers';
import { useMaterialsState } from '../../context/materialsContext';
import PendingChangesDialog from './PendingChangesDialog';

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    backgroundColor: colors.darkBlueBackground,
    padding: '67px 20px',
    marginTop: '42px',
    width: '63%',
  },
  root: {
    backgroundColor: colors.background,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  header: {
    color: colors.white,
    fontSize: '20px',
    fontWeight: '500',
    letterSpacing: '0.25px',
  },
  dialog: {
    ...getDialogStyle(theme),
  },
}));

const createEditingObject = (object) => Object.keys(object).reduce((acc, k) => {
  acc[k] = false;

  return acc;
}, {});

const AccountManagement = () => {
  const account = useContext(AccountContext);
  const history = useHistory();
  const location = useLocation();
  const misc = useMiscState();
  const classes = useStyles();
  const formikRef = useRef(null);
  const [currentKey, setCurrentKey] = useState('');
  const [pendingChanges, setPendingChanges] = useState(false);
  const [paymentOptions, setPaymentOptions] = useState([]);
  const [carriers, setCarriers] = useState([]);
  const [notificationTypes, setNotificationTypes] = useState([]);
  const [pricingModels, setPricingModels] = useState([]);
  const {
    currencyUnits,
  } = useMaterialsState();
  const [editing, setEditing] = useState(createEditingObject(ACCOUNT_MANAGEMENT));
  const [error, setError] = useState('');

  useEffect(() => {
    Promise.all([
      loadPaymentOptions(),
      loadCarriers(),
      loadNotificationTypes(),
      loadPricingModels(),
    ]).then((responses) => {
      if (responses[0]) setPaymentOptions(responses[0]);
      if (responses[1]) setCarriers(responses[1]);
      if (responses[2]) {
        setNotificationTypes({
          sms: responses[2],
          email: responses[2],
        });
      }
      if (responses[3]) setPricingModels(responses[3]);
    }).catch((e) => setError('Error fetching account data', e));
  }, []);

  useEffect(() => {
    const unblock = history.block(() => {
      if (formikRef.current && !isEmpty(formikRef.current.state.touched)) {
        setPendingChanges(true);

        return false;
      }

      return true;
    });

    return () => {
      unblock();
    };
  }, [location]);

  const handleRemoveAvatar = () => {
    console.log('NOT IMPLEMENTED YET!');
    // return removeProfilePicture(userDispatch);
  };

  const handleUploadAvatar = (document) => {
    console.log('NOT IMPLEMENTED YET!', document);
    // return uploadProfilePicture({ dispatch: userDispatch, document });
  };

  const handleEdit = (key, dirty) => {
    const pending = key !== currentKey && !isEmpty(currentKey) && formikRef.current
      ? !isEmpty(formikRef.current.state.touched)
      : dirty;

    setPendingChanges(pending);
    setCurrentKey(key);

    if (!pending) {
      setEditing({
        ...createEditingObject(editing),
        [key]: !editing[key],
      });
    }
  };

  const handleSubmit = (values, { setSubmitting }, key) => {
    const obj = get(account.info, key, null);
    let updatedInfo;

    if (obj && key !== PAYMENT_KEY) updatedInfo = { [key]: values };
    else updatedInfo = values;

    account.update(account, key, updatedInfo).catch((e) => {
      setError('Error updating account info', e);
    });

    setSubmitting(false);
    handleEdit(key);
  };

  return (
    <div className={classes.root}>
      <Navbar />
      <Page>
        <Grid container justify="center">
          {account.isLoading ? (
            <Grid item>
              <CircularProgress />
            </Grid>
          ) : (
            <Grid item container className={classes.pageContainer}>
              {/* TO DO - Complete functionality to be able to change account avatar. */}
              {/* <AvatarHeader
                fullName={account.info?.accountName}
                imageURL={account.info?.imageOriginalUrl || ''}
                showTitle={false}
                onUploadProfilePicture={handleUploadAvatar}
                onRemoveProfilePicture={handleRemoveAvatar}
              /> */}
              <AccountAddress
                formikRef={formikRef}
                itemKey={ACCOUNT_ADDRESS_KEY}
                values={account.info ? account.info[ACCOUNT_ADDRESS_KEY] : null}
                countries={misc.countries}
                states={misc.states}
                isEditing={editing[ACCOUNT_ADDRESS_KEY]}
                onEdit={handleEdit}
                onSubmit={handleSubmit}
              />
              <EmergencyContact
                formikRef={formikRef}
                itemKey={EMERGENCY_CONTACT_KEY}
                values={account.info ? account.info[EMERGENCY_CONTACT_KEY] : null}
                isEditing={editing[EMERGENCY_CONTACT_KEY]}
                onEdit={handleEdit}
                onSubmit={handleSubmit}
              />
              <Payment
                formikRef={formikRef}
                itemKey={PAYMENT_KEY}
                values={account.info ? pick(account.info, [PAYMENT_KEY, PRICING_LIST_KEY]) : null}
                paymentOptions={paymentOptions}
                dropdownOptions={{
                  pricingModels,
                  currencyUnits: currencyUnits && currencyUnits.map((c) => ({
                    type: c.currencyCode,
                    description: c.currencyCode,
                  })),
                }}
                isEditing={editing[PAYMENT_KEY]}
                onEdit={handleEdit}
                onSubmit={handleSubmit}
              />
              <CostAllocation
                formikRef={formikRef}
                itemKey={COST_ALLOCATION_KEY}
                values={account.info ? account.info[COST_ALLOCATION_KEY] : null}
                isEditing={editing[COST_ALLOCATION_KEY]}
                onEdit={handleEdit}
                onSubmit={handleSubmit}
              />
              {/* <Trainings
                itemKey={TRAINING_KEY}
                values={account.info ? account.info[TRAINING_KEY] : null}
                isEditing={editing[TRAINING_KEY]}
                onEdit={handleEdit}
                onSubmit={handleSubmit}
              /> */}
              <Carriers
                formikRef={formikRef}
                itemKey={CARRIERS_KEY}
                carriers={carriers}
                values={account.info ? account.info[CARRIERS_KEY] : null}
                isEditing={editing[CARRIERS_KEY]}
                onEdit={handleEdit}
                onSubmit={handleSubmit}
              />
              <Notifications
                formikRef={formikRef}
                itemKey={NOTIFICATIONS_KEY}
                notificationTypes={notificationTypes}
                values={account.info ? account.info[NOTIFICATIONS_KEY] : null}
                isEditing={editing[NOTIFICATIONS_KEY]}
                onEdit={handleEdit}
                onSubmit={handleSubmit}
              />
              <Compliance
                formikRef={formikRef}
                itemKey={COMPLIANCE_KEY}
                values={account.info ? pick(account.info, COMPLIANCE_FIELD_NAMES) : null}
                isEditing={editing[COMPLIANCE_KEY]}
                onEdit={handleEdit}
                onSubmit={handleSubmit}
              />
              <Miscellaneous
                formikRef={formikRef}
                itemKey={MISCELLANEOUS_KEY}
                values={account.info ? pick(account.info, MISCELLANEOUS_FIELD_NAMES) : null}
                isEditing={editing[MISCELLANEOUS_KEY]}
                onEdit={handleEdit}
                onSubmit={handleSubmit}
              />
            </Grid>
          )}
        </Grid>
        <PendingChangesDialog
          open={pendingChanges}
          onConfirm={() => {
            if (formikRef && formikRef.current) {
              formikRef.current.submitForm();
            }
          }}
          onCancel={() => handleEdit(currentKey, false)}
        />
        <NewOrderErrorDialog
          classes={classes.dialog}
          open={!isEmpty(error)}
          errorContent={error}
          onClose={() => {
            setError('');
          }}
        />
      </Page>
    </div>
  );
};

export default memo(AccountManagement);
