import React from 'react';
import {
  SvgIcon,
} from '@material-ui/core';

const HazmatIcon = (props) => (
  <SvgIcon {...props}>
    <path d="M23 16.06c0 .23 0 .44-.04.64c-.18-2.56-2.32-4.59-4.96-4.59c-.37 0-.73.05-1.08.12c.04.27.08.5.08.77c0 2.35-1.69 4.32-3.93 4.81c.35 2.24 2.24 3.98 4.58 4.15c-.22.04-.43.04-.65.04c-2.08 0-3.93-1.06-5-2.66A6.005 6.005 0 0 1 7 22c-.22 0-.43 0-.65-.04c2.34-.17 4.22-1.9 4.58-4.15C8.68 17.32 7 15.35 7 13c0-.27.04-.5.07-.77c-.34-.07-.7-.12-1.07-.12a4.97 4.97 0 0 0-4.97 4.59C1 16.5 1 16.29 1 16.06c0-3.21 2.59-5.82 5.81-5.92A5.877 5.877 0 0 1 9.06 2C7.81 2.9 7 4.34 7 6c0 1.35.56 2.59 1.47 3.5c.91-.91 2.15-1.46 3.53-1.46c1.37 0 2.62.55 3.5 1.46c.93-.91 1.5-2.15 1.5-3.5c0-1.66-.82-3.1-2.06-4a5.877 5.877 0 0 1 2.25 8.14c3.23.1 5.81 2.71 5.81 5.92M9.27 10.11a5.003 5.003 0 0 0 5.46 0C14 9.45 13.06 9.03 12 9.03s-2 .42-2.73 1.08M12 14.47c.82 0 1.5-.67 1.5-1.47a1.5 1.5 0 0 0-1.5-1.5a1.5 1.5 0 0 0-1.5 1.5c0 .8.67 1.47 1.5 1.47m-1.03 2.32c-.1-1.89-1.26-3.5-2.92-4.24c-.02.15-.05.29-.05.45c0 1.82 1.27 3.34 2.97 3.79m4.99-4.24A4.982 4.982 0 0 0 13 16.79c1.73-.45 3-1.97 3-3.79c0-.16-.03-.3-.04-.45z" />
  </SvgIcon>
);

export default HazmatIcon;
