/* eslint-disable no-return-await */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-plusplus */
/* eslint-disable react/prop-types */

import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
// RESOURCES:
// https://web.dev/usb/

export const USBContext = createContext({
  canUseUSB: false,
  device: null,
  hasTriedAutoconnect: false,
  connect: () => Promise.resolve(false),
  disconnect: () => {},
  portState: 'closed',
  getWeight: () => {},
});

export const useUSB = () => useContext(USBContext);

const USBProvider = ({
  children,
}) => {
  const [canUseUSB] = useState(() => 'usb' in navigator);

  const [portState, setPortState] = useState('closed');
  const [device, setDevice] = useState(null);
  const [statusMessage, setStatusMessage] = useState('');
  const [deviceFound, setDeviceFound] = useState(false);
  const [interfaceNumber, setInterfaceNumber] = useState(null);
  const [endpointOut, setEndpointOut] = useState(null);
  const [endpointIn, setEndpointIn] = useState(null);

  const [hasTriedAutoconnect, setHasTriedAutoconnect] = useState(false);
  const [lastInput, setLastInput] = useState(0);

  const [connected, setConnected] = useState();
  const inputRef = useRef(0);
  const dataRef = useRef(null);
  const encoder = new TextEncoder();
  const decoder = new TextDecoder();

  const encode = (data) => encoder.encode(data);

  const decode = (data) => decoder.decode(data);
  const manualConnectUSB = async () => {
    const devices = await navigator.hid.getDevices();
    if (devices.length > 0) {
      let device1 = devices[0];
      try {
        device1 = await connectUSB(device1);
        setDevice(device1);
        setStatusMessage('Device connected');
        setDeviceFound(true);
      } catch (error) {
        console.log(error);
        setStatusMessage(error.toString());
      }
    } else {
      const filters = [{ vendorId: 0x0EB8 }, { vendorId: 0x0B67 }]; // Fairbanks vendor id, Metler Vendor id
      let device1 = await navigator.hid.requestDevice({ filters });
      setDeviceFound(true);
      try {
        device1 = await connectUSB(device1);
        setDevice(device1);
        setStatusMessage('Device connected');
        setDeviceFound(true);
      } catch (error) {
        console.log(error);
        setStatusMessage(error.toString());
      }
    }
  };

  const connectUSB = async (dev) => {
    try {
      if (!dev.opened) {
        await dev.open();
      }
      // Subscribe to scale data inputs at a regular interval.
      dev.addEventListener('inputreport', (event) => {
        handleInputReport(event);
      });
      return dev;
    } catch (e) {
      console.log(e);
    }
  };
  const handleInputReport = (e) => {
    const i3 = e.data.getUint8(3);
    const i4 = e.data.getUint8(4);

    const input = i3 + (i4 * 256);

    // eslint-disable-next-line eqeqeq
    if (input != inputRef.current) {
      console.log(input);
      inputRef.current = input;
      dataRef.current = e.data;
    }
  };

  const getWeight = () => {
    let weight = inputRef.current;
    const inData = dataRef.current;
    const returnValue = { lbs: 0, oz: 0, kg: 0 };
    const decimaliseWeight = false;

    const weightMeasurementUnits = 'lbs';
    weight = ((inData.getUint8(3))
    + (inData.getUint8(4)) * 256) / 100;

    switch (inData.getUint8(1)) {
      case 3: // Kilos
        weight *= 2.2;
        break;
      case 11: // Ounces
        weight *= 0.625;
        break;
      case 12: // Pounds
        // already in pounds, do nothing
        break;
      default:
        weight = 0;
        break;
    }
    const isStable = inData.getUint8(0) === 4;
    if (weightMeasurementUnits === 'lbs') {
      // Pounds and Ounces
      // console.log(decimaliseWeight)
      if (!decimaliseWeight) {
        const lbs = parseFloat(weight).toFixed(2);
        const oz = 0;
        returnValue.lbs = lbs;
        returnValue.oz = oz;
      } else {
        returnValue.lbs = weight;
      }
    } else {
      // Killograms
      weight /= 1000;
      returnValue.kg = weight;
    }
    return returnValue;
  };
  const disconnect = async () => await device.close();
  return (
    <USBContext.Provider
      value={{
        canUseUSB,
        device,
        getWeight,
        connect: manualConnectUSB,
        disconnect,
        encode,
        decode,
      }}
    >
      {children}
    </USBContext.Provider>
  );
};

export default USBProvider;
