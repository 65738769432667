import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import StyledCheckCircleIcon from './common/StyledCheckCircleIcon';
import * as colors from '../styles/colors';

const styles = {
  validIcon: {
    color: colors.primaryBlue,
  },
  invalidIcon: {
    color: '#979797',
  },
  label: {
    color: '#979797',
  },
};
const useStyles = makeStyles(styles);

export default function ValidationCheckboxWithLabel(props) {
  const classes = useStyles();
  const { labelText, isValidated, isGreenWhenValid } = props;
  return (
    <Grid container spacing={2} direction="row" alignItems="center">
      <Grid item>
        <StyledCheckCircleIcon isValidated={isValidated} isGreenWhenValid={isGreenWhenValid} />
      </Grid>
      <Grid>
        <Typography className={classes.label}>
          {labelText}
        </Typography>
      </Grid>
    </Grid>
  );
}

ValidationCheckboxWithLabel.propTypes = {
  labelText: PropTypes.string.isRequired,
  isValidated: PropTypes.bool.isRequired,
  isGreenWhenValid: PropTypes.bool,
};

ValidationCheckboxWithLabel.defaultProps = {
  isGreenWhenValid: false,
};
