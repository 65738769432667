import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { Route } from 'react-router-dom';
import { css } from 'emotion';
import Button from '@material-ui/core/Button';
import * as colors from '../../styles/colors';

export default function RedirectButton(props) {
  const {
    label, startIcon, url, style, onClick,
  } = props;
  return (
    <Route render={({ history }) => (
      <Button
        className={css`
          font-weight: bold;
          margin: auto;
          padding: 6px 16px;
          color: ${colors.white};
          border-radius: 8px;
          white-space: nowrap;
        `}
        color="secondary"
        variant="contained"
        startIcon={startIcon}
        onClick={() => {
          if (!isEmpty(url)) history.push(url);

          if (onClick) onClick();
        }}
        style={style}
      >
        {label}
      </Button>
    )}
    />
  );
}

RedirectButton.propTypes = {
  label: PropTypes.string.isRequired,
  startIcon: PropTypes.node,
  url: PropTypes.string.isRequired,
  style: PropTypes.objectOf(PropTypes.any),
  onClick: PropTypes.func,
};

RedirectButton.defaultProps = {
  startIcon: null,
  style: {},
  onClick: null,
};
