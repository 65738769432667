import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, DialogActions, DialogContent, DialogContentText,
  Grid, Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as colors from '../../styles/colors';
import NewOrderNextButtonClear from '../../components/common/NewOrderNextButtonClear';
import NewOrderNextButton from '../../components/common/NewOrderNextButton';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: colors.mediumBlueBackground,
    overflowY: 'initial',
    borderRadius: 10,
  },
  dialogContentText: {
    color: colors.white,
  },
  actionsContainer: {
    paddingBottom: 24,
  },
  clearButton: {
    marginRight: 10,
  },
}));

const PnedingChangesDialog = ({
  open, onConfirm, onCancel,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      classes={{ paper: classes.root }}
      open={open}
      onClose={onCancel}
    >
      <DialogContent>
        <DialogContentText component="div">
          <Grid item container spacing={1} direction="column">
            <Grid item>
              <Typography className={classes.dialogContentText}>
                There are unsaved changes pending. Do you want to save them?
              </Typography>
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions classes={{ root: classes.actionsContainer }}>
        <Grid container justify="flex-end">
          <Grid item>
            <NewOrderNextButtonClear className={classes.clearButton} onClick={onCancel}>
              Cancel
            </NewOrderNextButtonClear>
            <NewOrderNextButton onClick={onConfirm}>Confirm</NewOrderNextButton>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

PnedingChangesDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default memo(PnedingChangesDialog);
