import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { CircularProgress, Grid } from '@material-ui/core';
import NewOrderAddressListItem from './common/NewOrderAddressListItem';
import NewOrderNextButton from './common/NewOrderNextButton';

/**
 * #################################
 *          EXPORT FUNCTION
 * #################################
 */

export default function DefaultSenderTab({
  defaultSender, handleSubmit, handleEdit, pickupOptions, tabValue, classes,
}) {
  const hideSetSenderBtn = (pickupOptions === tabValue);

  const onEdit = () => {
    handleEdit(defaultSender);
  };

  const validateOnBlur = false;

  const onSubmit = async (values, { errors, setSubmitting }) => {
    await handleSubmit(defaultSender);
    setSubmitting(false);
  }

  function render(renderProps) {
    const { isSubmitting } = renderProps;
    const { submitForm } = renderProps;

    return (
      <Form>
        <Grid container direction="column">
          <Grid item style={{ padding: '0 0 10px 0' }}>
            <NewOrderAddressListItem
              fieldName="defaultSender"
              contact={defaultSender}
              isSelected
              onClick={onEdit}
              disabled={isSubmitting}
            />
          </Grid>
          <Grid item container spacing={2}>
            <Grid item>
              <NewOrderNextButton
                disabled={(hideSetSenderBtn || isSubmitting)}
                onClick={submitForm}
                data-testid="add-default-sender-button"
              >
                Set Sender
              </NewOrderNextButton>
            </Grid>
          </Grid>
          {(isSubmitting)
            ? (
              <Grid item classes={{ root: classes.miscLoadingContainer }}>
                <CircularProgress color="secondary" />
              </Grid>
            )
            : null}
        </Grid>
      </Form>
    );
  }

  return (
    <Formik
      validateOnBlur={validateOnBlur}
      initialValues={{}}
      onSubmit={onSubmit}
      render={render}
    />
  );
}

DefaultSenderTab.propTypes = {
  defaultSender: PropTypes.objectOf(PropTypes.any).isRequired,
  pickupOptions: PropTypes.string.isRequired,
  tabValue: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    miscLoadingContainer: PropTypes.string.isRequired,
  }).isRequired,
};
