import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { InputLabel, Typography, Checkbox } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import * as colors from '../../styles/colors';

const muiCheckboxStyles = {
  root: {
    color: colors.white,
    fontSize: 12,
    '&$checked': {
      color: colors.secondaryButton,
    },
  },
  checked: {
    color: colors.secondaryButton,
  },
};

const checkboxStyles = makeStyles(() => ({
  labelText: { color: colors.white, fontSize: 14 },
}));

const StyledCheckbox = withStyles(muiCheckboxStyles)(Checkbox);

const FilterCheckbox = (props) => {
  const classes = checkboxStyles();
  const {
    label, value, checked, onClick,
  } = props;
  return (
    <InputLabel>
      <StyledCheckbox checked={checked} onClick={() => onClick(value, !checked)} />
      <Typography className={classes.labelText} display="inline">
        {label}
      </Typography>
    </InputLabel>
  );
};

FilterCheckbox.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default memo(FilterCheckbox);
