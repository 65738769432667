import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import React from 'react';
import axios from 'axios';
import { Redirect } from 'react-router-dom';
import { Button, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ValidationCheckboxWithLabel from '../components/ValidationCheckboxWithLabel';
import { Title, SubTitle } from '../components/common';
import Logo from '../assets/verizeal-logo';
import CustomPasswordField from '../components/CustomPasswordField';

const styles = {
  root: {
    flexGrow: 1,
    backgroundColor: '#151721',
    height: '100%',
    width: '100%',
  },
  formRoot: {
    flexGrow: 1,
    backgroundColor: '#151721',
    height: 'auto',
    width: '100%',
  },
  contentContainer: {
    paddingTop: 200,
    textAlign: 'center',
    width: '100%',
  },
  logo: {
    height: 20,
    width: 200,
  },
  button: {
    float: 'left',
    fontWeight: 'bold',
    borderRadius: 16,
    backgroundColor: '#2A79D4',
    width: 170,
    margin: 'auto',
  },
  formContainer: {
    marginTop: 20,
  },
  textField: {
    margin: 'auto',
    width: '37%',
    maxWidth: 380,
    minWidth: 270,
    minHeight: 76,
  },
  checkboxContainer: {
    marginTop: 20,
    marginLeft: '10%',
    textAlign: 'center',
    justifyContent: 'center',
  },
};

const useStyles = makeStyles(styles);

export default function CreateNewPassword() {
  const classes = useStyles();

  const [hasValidCharCount, setHasValidCharCount] = React.useState(false);
  const [hasNumber, setHasNumber] = React.useState(false);
  const [hasUppercaseChar, setHasUppercaseChar] = React.useState(false);
  const [hasLowercaseChar, setHasLowercaseChar] = React.useState(false);
  const [redirectToConfirmationScreen, setRedirectToConfirmationScreen] = React.useState(false);
  const [hasSpecialChar, setHasSpecialChar] = React.useState(false);

  function validatePassword(password) {
    const hasLowercaseRegex = /[a-z]/gm;
    const hasUppercaseRegex = /[A-Z]/gm;
    const hasNumberRegex = /[0-9]/gm;
    const hasSpecialCharRegex = /[!@#$%^&*(),.?":{}|<>]/gm;
    password.length >= 8 ? setHasValidCharCount(true) : setHasValidCharCount(false);
    hasLowercaseRegex.test(password) ? setHasLowercaseChar(true) : setHasLowercaseChar(false);
    hasUppercaseRegex.test(password) ? setHasUppercaseChar(true) : setHasUppercaseChar(false);
    hasNumberRegex.test(password) ? setHasNumber(true) : setHasNumber(false);
    hasSpecialCharRegex.test(password) ? setHasSpecialChar(true) : setHasSpecialChar(false);
  }

  async function handleSubmit(values) {
    try {
      const options = {
        method: 'post',
        url: '/reset_password/submit',
        data: {
          newPassword: values.password,
        },
        mode: 'no-cors',
      };
      await axios(options);
      setRedirectToConfirmationScreen(true);
    } catch (err) {
      console.log(err);
      alert(err.response ? err.response.data : err);
    }
  }

  return (
    <div className={classes.root}>
      {
        redirectToConfirmationScreen && <Redirect push to="/reset-password-confirmation" />
      }
      <Grid
        container
        className={classes.contentContainer}
        justify="flex-start"
        alignItems="center"
        direction="column"
      >
        <Grid item md={4} sm={6} xs={10}>
          <Logo className={classes.logo} />
        </Grid>
        <Grid item md={4} sm={6} xs={10}>
          <Title>Create new password</Title>
        </Grid>
        <Grid item md={4} sm={6} xs={10}>
          <SubTitle>
            Enter a new password below.
          </SubTitle>
        </Grid>
        <Grid container item md={3} sm={4} xs={10} className={classes.checkboxContainer}>
          <Grid item xs={12}>
            <ValidationCheckboxWithLabel isValidated={hasValidCharCount} labelText="8 or more characters" />
          </Grid>
          <Grid item xs={12}>
            <ValidationCheckboxWithLabel isValidated={hasUppercaseChar} labelText="Uppercase" />
          </Grid>
          <Grid item xs={12}>
            <ValidationCheckboxWithLabel isValidated={hasNumber} labelText="At least 1 number" />
          </Grid>
          <Grid item xs={12}>
            <ValidationCheckboxWithLabel isValidated={hasLowercaseChar} labelText="Lowercase" />
          </Grid>
          <Grid item sm={12}>
            <ValidationCheckboxWithLabel isValidated={hasSpecialChar} labelText="At least 1 special character" />
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          className={classes.formContainer}
          justify="flex-start"
          alignItems="center"
          direction="column"
        >
          <Formik
            validateOnChange
            validateOnBlur={false}
            initialValues={{
              password: '',
              confirmPassword: '',
            }}
            onSubmit={(values, { setSubmitting }) => {
              handleSubmit(values);
              setSubmitting(false);
            }}
            validationSchema={Yup.object().shape({
              password: Yup.string()
                .matches(/^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*(),.?":{}|<>]).{8,}/, { message: 'Must fulfill security criteria listed above' })
                .required('Required'),
              confirmPassword: Yup.string()
                .oneOf([Yup.ref('password'), null], 'Passwords must match')
                .required('Please confirm your password'),
            })}
            render={({ submitForm, isSubmitting }) => (
              <div className={classes.formRoot}>
                <Form>
                  <Grid container item xs={12} spacing={2}>
                    <Grid container item xs={12}>
                      <Field
                        className={classes.textField}
                        name="password"
                        type="password"
                        variant="outlined"
                        label="Password"
                        component={CustomPasswordField}
                        fullWidth
                        validate={(value) => validatePassword(value)}
                      />
                    </Grid>
                    <Grid container item xs={12}>
                      <Field
                        className={classes.textField}
                        name="confirmPassword"
                        type="password"
                        variant="outlined"
                        label="Confirm Password"
                        component={CustomPasswordField}
                        fullWidth
                      />
                    </Grid>
                    <Grid container item xs={12}>
                      <Button
                        className={classes.button}
                        color="secondary"
                        variant="contained"
                        disabled={isSubmitting}
                        onClick={submitForm}
                      >
                        Reset Password
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              </div>
            )}
          />
        </Grid>
      </Grid>
    </div>
  );
}
