import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import { css } from 'emotion';
import * as colors from '../../styles/colors';

export default function PanelInfo({ message }) {
  const classes = {
    container: css`
        border: 1px solid ${colors.lightBackgroundGrey};
        border-radius: 5px;
        background: ${colors.darkBlueBackground};
        margin-top:10px;
        color: ${colors.white};
        padding: 15px 30px;
        flex-shrink: 0;
    `,
    text: css`
      font-size:14px;
      color:${colors.white}
    `,
    iconRoot: css`
      color: ${colors.white}
    `,
    icon: css`
      margin-right: 20px
    `,
  };

  return (
    <Grid item container className={classes.container} alignItems="center">
      <InfoOutlined classes={{ root: classes.iconRoot }} className={classes.icon} />
      <Typography className={classes.text}>{message}</Typography>
    </Grid>
  );
}

PanelInfo.propTypes = {
  message: PropTypes.string.isRequired,
};
