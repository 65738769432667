import React, { useEffect } from 'react';
import _ from 'lodash';
import * as miscClient from '../utils/miscClient';

const INITIAL_AUTHENTICATED_STATE = {
  profile: null,
  isAuthenticated: false,
  hasLoaded: false,
  loading: true,
};

function isTestOrDevMode() {
  return ['development', 'test'].includes(process.env.NODE_ENV);
}

const AuthContext = React.createContext();

function AuthProvider(props) {
  const [user, setUser] = React.useState(INITIAL_AUTHENTICATED_STATE);

  const login = (data) => {
    setUser({
      profile: data.profile,
      isAuthenticated: data.auth,
      hasLoaded: true,
      loading: false,
    });
    isUserAuthenticated();
  };

  const logout = async () => {
    await miscClient.logout();
    setUser({ ...INITIAL_AUTHENTICATED_STATE, loading: false });
  };

  const isUserAuthenticated = async () => {
    const devOrTestMode = isTestOrDevMode();

    if (devOrTestMode) {
      // eslint-disable-next-line no-console
      console.log(
        'App in development or test mode. User automatically authenticated. Update in client/src/context/authContext.js',
      );
    }

    try {
      const response = await miscClient.isAuthenticated();

      if (response.auth) {
        if (_.isEmpty(response.profile.user?.defaultAddress) || response.profile.user?.defaultAddress?.info) {
          setUser({
            profile: response.profile.user,
            // When in development, you can update this value to false to be able to work on authenticated pages
            isAuthenticated: response.auth,
            hasLoaded: true,
            loading: false,
            isEmptyDefaultAddress: !!response.profile.user?.useDefaultAddress,
          });
        } else {
          setUser({
            profile: response.profile.user,
            // When in development, you can update this value to false to be able to work on authenticated pages
            isAuthenticated: response.auth,
            hasLoaded: true,
            loading: false,
            isEmptyDefaultAddress: false,
          });
        }
      } else logout();
    } catch (e) {
      throw new Error('Error when calling isAuthenticated', e);
    }
  };

  useEffect(() => {
    isUserAuthenticated();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        login,
        logout,
        isUserAuthenticated,
      }}
      {...props}
    />
  );
}

function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}

export { AuthProvider, AuthContext, useAuth };
