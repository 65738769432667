import React from 'react';
import PropTypes from 'prop-types';
import { Field, Formik, ErrorMessage } from 'formik';
import { css } from 'emotion';
import * as Yup from 'yup';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import { CircularProgress } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import * as miscClient from '../utils/miscClient';
import * as colors from '../styles/colors';
import NewOrderNextButton from './common/NewOrderNextButton';
import NewOrderNextButtonClear from './common/NewOrderNextButtonClear';

const BASE_BUTTON_STYLES = {
  fontWeight: 'bold',
  padding: '6px 16px',
  borderRadius: '5px',
};

const useStyles = makeStyles(() => ({
  text: {
    color: '#FFFFFF',
    fontSize: '1.5em',
    fontWeight: '400',
    lineHeight: '1.2em',
    textAlign: 'left',
  },
  empty: {
    height: '30px',
  },
  sectionSubmit: {
    padding: '10px 0',
  },
  button: {
    ...BASE_BUTTON_STYLES,
    border: `1px solid ${colors.buttonBackgroundBlue}`,
    color: colors.white,
    marginLeft: '1rem',
  },
  modalContent: {
    background: colors.darkBlueBackground,
    padding: '10px 20px',
  },
}));

const StyledDialog = withStyles({
  paper: {
    color: colors.white,
    background: colors.newOrderFormBackground,
    width: 650,
    padding: '20px 0',
  },
})(Dialog);

export default function IpdAlertDialog(props) {
  const {
    open, onClose, onSubmit,
  } = props;

  const classes = useStyles();
  const headerText = ' There is no Active Master Shipment currently OPEN . Please specify a ship date below when you want to open a master for this shipment: ';

  const getInitialValues = () => ({
    expectedShipDate: null,
  });
  const validationSchema = Yup.object().shape({
    expectedShipDate: Yup.date().required('Invalid Date').typeError('Invalid Date'),
  });

  async function onSubmitForm(values) {
    const formattedDate = moment(values.expectedShipDate).format('YYYY-MM-DD');
    const dateToSet = (formattedDate !== 'Invalid date' && formattedDate) || null;
    onSubmit(dateToSet);
  }
  return (
    <StyledDialog open={open} onClose={onClose} aria-labelledby="about-dialog-title">
      <Grid container style={{ padding: '10px' }}>
        <Grid container direction="row" justifyContent="space-between">
          <Grid item>
            <WarningIcon style={{ fontSize: '70px', color: '#FF8503' }} />
            {/* <Typography
              variant="h5"
              style={{
                fontWeight: 500,
                lineHeight: '1em',
                fontSize: '2em',
              }}
            >
              {' '}
              Alert

            </Typography> */}
          </Grid>
          <Grid item />
        </Grid>
        <Formik
          enableReinitialize
          initialValues={getInitialValues()}
          validationSchema={validationSchema}
          onSubmit={onSubmitForm}
        >
          {(formikProps) => (
            <>
              <Grid
                container
                direction="column"
                className={classes.modalContent}
              >
                <Grid item>
                  <Typography className={classes.text}>
                    {headerText}
                  </Typography>
                </Grid>
                <Grid item className={classes.empty}>
                  <Typography className={classes.text} />
                </Grid>
                <Grid item>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid item container>
                      <KeyboardDatePicker
                        autoOk
                        variant="inline"
                        inputVariant="outlined"
                        label="Expected Ship Date"
                        format="dd MMM yyyy"
                        minDate={moment().toDate()}
                        value={formikProps.values.expectedShipDate && moment(formikProps.values.expectedShipDate).toDate()}
                        onChange={(d) => {
                          formikProps.setFieldValue('expectedShipDate', d);
                        }}
                        InputAdornmentProps={{
                          position: 'start',
                          classes: {
                            root: classes.inputAdornmentRoot,
                          },
                        }}
                        className={classes.datePicker}
                        classes={{
                          root: classes.datePickerRoot,
                        }}
                        InputProps={{
                          classes: {
                            root: classes.inputRootLeft,
                          },
                        }}
                        rightArrowButtonProps={{ classes: { root: classes.arrow } }}
                        leftArrowButtonProps={{ classes: { root: classes.arrow } }}
                      />

                    </Grid>

                  </MuiPickersUtilsProvider>
                  <ErrorMessage style={{ color: 'red' }} name="expectedShipDate" component="div" />
                </Grid>
                <Grid item className={classes.empty}>
                  <Typography className={classes.text} />
                </Grid>

              </Grid>
              <Grid className={classes.sectionSubmit} container>
                <Grid container justifyContent="center" alignContent="center">

                  <Grid item>
                    <NewOrderNextButtonClear onClick={onClose}>cancel</NewOrderNextButtonClear>

                  </Grid>
                  <Grid item>
                    <NewOrderNextButton onClick={formikProps.handleSubmit} className={classes.button}>
                      <Typography className={classes.submitButtonText}>
                        Submit
                      </Typography>
                    </NewOrderNextButton>
                  </Grid>
                </Grid>
              </Grid>

            </>
          )}
        </Formik>
      </Grid>
    </StyledDialog>
  );
}

IpdAlertDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,

};
