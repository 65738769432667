import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';
import has from 'lodash/has';
import map from 'lodash/map';
import omit from 'lodash/omit';
import {
  Button,
  CircularProgress,
  Grid,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as colors from '../../../styles/colors';
import { Page } from '../../common';
import UploadInput from '../../common/UploadInput';
import { addBulk } from '../../../utils/addressBookClient';
import SampleCSV from '../../../assets/address-list-sample.csv';
import { NEW_ORDER_MODULE_STYLE } from '../../../styles/style';
import NewOrderErrorDialog from '../../common/NewOrderErrorDialog';
import PanelWarning from '../../common/PanelWarning';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  page: {
    padding: '25px',
    height: '80vh',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
  },
  innerContainer: {
    background: colors.tableBackground,
    overflow: 'auto',
    height: '100%',
    padding: '20px',
    marginTop: '20px',
  },
  headerText: {
    color: colors.white,
    fontSize: 24,
    fontWeight: 500,
  },
  listItemHeader: {
    marginTop: '16px',
  },
  listItem: {
    paddingInlineStart: '25px',
    fontSize: '14px',
  },
  link: {
    color: colors.primaryBlue,
  },
  uploadInputInnerContainer: {
    width: '50%',
    marginTop: '40px',
  },
  errorContainer: {
    marginTop: '20px',
  },
  buttonContainer: {
    marginTop: '20px',
  },
  newContactButton: {
    width: 200,
    background: colors.newContactButtonBackground,
    color: colors.white,
    height: 40,
    borderRadius: 10,
    padding: '0 20px',
  },
}));

function UploadAddresses({
  setSelectedAddress,
  toggleOpen,
}) {
  const classes = useStyles();
  const errorClasses = NEW_ORDER_MODULE_STYLE();
  const [file, setFile] = useState(null);
  const [errorContent, setErrorContent] = useState(null);
  const [isProcessingFile, setProcessingFile] = useState(false);

  const handleUploadFile = () => {
    setProcessingFile(true);

    addBulk(file).then((response) => {
      setSelectedAddress(response.map((address) => ({ ...omit(address, 'addressHash') })));
      setProcessingFile(false);
      toggleOpen();
    }).catch((e) => {
      const parsedError = JSON.parse(e.message);

      setProcessingFile(false);

      setErrorContent(parsedError);
    });
  };

  const getFormattedLineError = (key, value) => {
    const numberIdx = key.indexOf('e') + 1;
    const formattedKey = `${key.substring(0, numberIdx)} ${key.substring(numberIdx, key.length)}`;
    const capitalizedKey = formattedKey.charAt(0).toUpperCase() + formattedKey.slice(1);

    let message = '';

    value.forEach((reason, idx) => {
      if (idx === 0) {
        message = `${reason}.`;

        return;
      }

      message = `${message} ${reason}.`;
    });

    return {
      key: capitalizedKey,
      message,
    };
  };

  return (
    <>
      <div className={classes.root}>
        <Page className={classes.page}>
          <Grid
            container
            item
          >
            <Typography className={classes.headerText}>
              Upload a File
            </Typography>
          </Grid>
          <Grid className={classes.innerContainer} container item>
            <Grid
              direction="column"
              container
              item
            >
              <Typography>
                Import address from
                {' '}
                <b><i>CSV file</i></b>
                {' '}
                using the form below.
              </Typography>
              <Typography style={{ marginTop: '16px' }}>
                <b>
                  Import Address From CSV file
                </b>
              </Typography>

              <Typography className={classes.listItemHeader} component="ul">
                <b>
                  Step 1
                </b>
              </Typography>
              <Typography className={classes.listItem} component="li">
                The Comma separated (CSV) file should comply with following order of columns.
              </Typography>
              <Typography className={classes.listItem} component="li">
                Name, Address Line 1, Address Line 2, City, State, Zip, Phone, Phone Country, Country, Email, Company Name.
              </Typography>
              <Typography className={classes.listItem} component="li">
                Please ensure that the Phone number and Zipcode fields are in Text Format to avoid truncation of
                leading zeros when importing.
              </Typography>
              <Typography className={classes.listItem} component="li">
                Country and State fields must be 2 character codes.
              </Typography>
              <Typography className={classes.listItem} component="li">
                <a className={classes.link} href={SampleCSV}>Sample Comma separated (csv) file.</a>
              </Typography>

              <Typography className={classes.listItemHeader} component="ul">
                <b>
                  Step 2
                </b>
              </Typography>
              <Typography className={classes.listItem} component="li">
                Click &quot;Choose File&quot;, select the csv file and click &quot;Done&quot; to process the
                file.
              </Typography>

              <Grid container item justifyContent="center">
                <Grid className={classes.uploadInputInnerContainer} item>
                  <UploadInput
                    name="document"
                    acceptedFiles=".csv"
                    showIcon={false}
                    onChange={(currentFile) => {
                      setFile(currentFile);

                      if (has(errorContent, 'error')) {
                        setErrorContent(null);
                      }
                    }}
                  />
                </Grid>
              </Grid>

              <Grid className={classes.errorsContainer} container item>
                {has(errorContent, 'error') && isObject(errorContent.error)
                && map(errorContent.error, (value, key) => {
                  const formattedLine = getFormattedLineError(key, value);

                  return <PanelWarning message={`${formattedLine.key}: ${formattedLine.message}`} />;
                })}
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            container
            direction="row"
            className={classes.buttonContainer}
            justify="flex-end"
            alignItems="flex-end"
          >
            <Grid item>
              <Button
                className={classes.newContactButton}
                disabled={isProcessingFile || !file}
                onClick={() => handleUploadFile(file)}
              >
                {isProcessingFile ? (
                  <CircularProgress size="2rem" color="white" />
                ) : 'Save'}
              </Button>
            </Grid>
          </Grid>
        </Page>
      </div>
      <NewOrderErrorDialog
        open={has(errorContent, 'error') && isString(errorContent.error)}
        errorContent={has(errorContent, 'error') ? errorContent.error : ''}
        classes={errorClasses}
        onClose={() => {
          setErrorContent(null);
        }}
      />
    </>
  );
}

UploadAddresses.propTypes = {
  setSelectedAddress: PropTypes.func.isRequired,
  toggleOpen: PropTypes.func.isRequired,
};

export default memo(UploadAddresses);
