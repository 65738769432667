import React, { useState } from 'react';
import PropTypes from 'prop-types';
import isArray from 'lodash/isArray';
import { Grid, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as colors from '../../styles/colors';

const ITEMS_LIMIT = 5;

const useStyles = makeStyles(() => ({
  button: {
    fontSize: '14px',
    fontWeight: 500,
    borderRadius: '8px',
    letterSpacing: '1.2px',
    color: colors.actionButtonText,
    margin: '20px 0',
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

const ShowMore = ({
  limit, buttonGridWidth, buttonGridJustify, children,
}) => {
  const classes = useStyles();
  const [showAllItems, setShowMore] = useState(false);

  const handleShowMoreClick = () => {
    setShowMore(!showAllItems);
  };

  return (
    <Grid container>
      {!showAllItems && isArray(children) && limit > 0 ? children.slice(0, limit) : children}
      {isArray(children) && children.length > limit && (
        <Grid container item xs={buttonGridWidth} justifyContent={buttonGridJustify}>
          <Button
            className={classes.button}
            disableRipple
            onClick={handleShowMoreClick}
          >
            Show
            {' '}
            {showAllItems ? 'Less' : 'More'}
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

ShowMore.defaultProps = {
  limit: ITEMS_LIMIT,
  buttonGridWidth: 12,
  buttonGridJustify: 'flex-end',
};

ShowMore.propTypes = {
  limit: PropTypes.number,
  buttonGridWidth: PropTypes.number,
  buttonGridJustify: PropTypes.oneOf(['flex-start', 'center', 'flex-end']),
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
};

export default ShowMore;
