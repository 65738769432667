import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import {
  Grid, Typography, MenuItem,
} from '@material-ui/core';
import CustomSelectTextField from '../../common/CustomSelectTextField';
import NewOrderCustomTextField from '../../common/NewOrderCustomTextField';

const PurposeOfShipment = ({
  classes,
  shipmentPurposeOptions,
  purpose,
}) => (
  <Grid item container direction="column" spacing={1}>
    <Grid item>
      <Typography color="primary" className={classes?.formFieldHeader}>Purpose of Shipment</Typography>
    </Grid>
    <Grid item container spacing={3}>
      <Grid item xs={8}>
        <Field
          component={CustomSelectTextField}
          style={{ width: '100%' }}
          type="text"
          name="purpose"
          label="Select Package Purpose"
          data-testid="purpose-input"
        >
          {shipmentPurposeOptions && shipmentPurposeOptions.map((type) => (
            <MenuItem
              key={type.value}
              value={type.value}
              data-testid="purpose-menu-item"
            >
              {type.label}
            </MenuItem>
          ))}
        </Field>
      </Grid>
      {purpose?.toLowerCase() === 'other' && (
        <Grid item xs={6}>
          <Field
            component={NewOrderCustomTextField}
            type="text"
            style={{ width: '100%' }}
            name="otherPurpose"
            label="Enter Other Purpose"
          />
        </Grid>
            )}
    </Grid>
  </Grid>
);

PurposeOfShipment.defaultProps = {
  purpose: '',
  shipmentPurposeOptions: [],
};

PurposeOfShipment.propTypes = {
  classes: PropTypes.objectOf(PropTypes.object).isRequired,
  purpose: PropTypes.string,
  shipmentPurposeOptions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  })),
};

export default memo(PurposeOfShipment);
