import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, DialogActions, DialogContent, DialogContentText,
  Grid, Typography, CircularProgress, TextField, Button,
} from '@material-ui/core';
import { css } from 'emotion';
import WarningIcon from '@material-ui/icons/Warning';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
import NewOrderNextButton from './NewOrderNextButton';
import NewOrderNextButtonClear from './NewOrderNextButtonClear';
import * as colors from '../../styles/colors';

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}
export default function BinScanModal(props) {
  const {
    open, message, onProceed, onCancel, proceedLabel,
    cancelLabel, proceedButtonComponent, proceedButtonProps,
    noCancel, binMessage, carrier, bin, packingSlip, isEdi,
  } = props;

  const [loading, setLoading] = React.useState(false);
  const [messageState, setMessageState] = React.useState(message);
  const [binName, setBinName] = React.useState(null);
  const binNameRef = React.useRef(null);

  const ProceedButtonComponent = proceedButtonComponent || NewOrderNextButton;

  React.useEffect(() => {
    if (message) {
      setMessageState(message);
    }
  }, [message]);
  const scrollContainer = css({
    alignItems: 'flex-start',
  });
  const dialogRoot = css({
    backgroundColor: colors.mediumBlueBackground,
    overflowY: 'initial',
    borderRadius: 10,
    maxWidth: '80%',
  });
  const dialogContainer = css({ algnItems: 'flex-start' });
  const dialogCustomizedWidth = css({ width: '80%' });
  const dialogContentContainer = css({ marginBottom: 'initial' });
  const dialogActionsContainer = css({
    paddingBottom: 24, width: '100%', justifyContent: 'center', flexDirection: 'column',
  });
  const topPaperScrollBody = css({ verticalAlign: 'top' });
  const classes = {
    dialogRoot,
    scrollContainer,
    dialogContentContainer,
    dialogActionsContainer,
    dialogContent: css({
      minWidth: 800,
      paddingLeft: 40,
      paddingRight: 40,
    }),
    buttonsContainer: css({
      width: 'fit-content',
    }),
    dialogText: css({
      fontWeight: 500,
      lineHeight: '1em',
      fontSize: '3em',
    }),
    dialogMessage: css({
      fontWeight: 400,
      lineHeight: '1em',
      fontSize: '2em',
    }),
    ediMessage: css({
      fontWeight: 400,
      lineHeight: '1em',
      fontSize: '2em',
      color: colors.red,
    }),
    progress: css({
      marginTop: 20,
    }),
    buttonText: css({
      whiteSpace: 'nowrap',
      padding: '0 10px',
    }),
    contentGrid: css({
      padding: '30px 10px',
      background: colors.darkBlueBackground,
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
    }),
    searchFieldRoot: {
      color: colors.white,
    },
    inputRoot: {
      background: colors.mediumBlueBackground,
      color: colors.white,
    },
    searchFieldLabelRoot: {
      color: colors.white,
      fontSize: 16,
      fontWeight: 500,
      background: colors.mediumBlueBackground,
    },
    expansionPanelInternal: {
      backgroundColor: colors.darkBlueBackground,
      padding: '1.5rem',
    },
    searchFieldWrap: {
      height: 45,
      borderRadius: 5,
      background: colors.mediumBlueBackground,
      marginLeft: '20px',
      width: 100,
      color: colors.white,
    },
    searchField: {
      width: '40vw',
      background: colors.mediumBlueBackground,
      color: colors.white,
      '& label': {
        color: colors.white,
        fontWeight: 500,
        fontSize: 18,
      },
      overflow: 'hidden',
      margin: 0,
      fontSize: 24,
      height: '100%',
      borderRadius: 5,
      marginRight: 20,
    },
  };

  const onClick = async () => {
    onCancel();
  };
  const handleBinNameChange = (e) => {
    setBinName(e.target.value);
  };
  const handleSubmit = async () => {
    setLoading(true);
    try {
      await onProceed(binName.toUpperCase());
      setLoading(false);
      setBinName(null);
    } catch (err) {
      setLoading(false);
      setBinName('');
      binNameRef.current.focus();
    }
  };
  const dialogContent = (
    <DialogContent className={classes.dialogContent}>
      <DialogContentText
        id="alert-error-dialog"
        component="div"
        classes={{ root: dialogContentContainer }}
      >
        <Grid item container direction="column" justify="center">
          <Grid
            item
            style={{
              display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
            }}
          >
            <WarningIcon style={{ fontSize: '70px', color: '#FF8503' }} />
            {isEdi && (
              <Typography color="primary" className={classes.ediMessage}>
                EDI Shipment
              </Typography>
            )}
          </Grid>
          <Grid item className={classes.contentGrid}>
            <Typography color="primary" className={classes.dialogMessage}>
              {`Packing Slip :  ${packingSlip}`}
            </Typography>
            <Typography color="primary" className={classes.dialogText}>
              {messageState}
            </Typography>

            <Typography color="primary" className={classes.dialogMessage}>
              {binMessage}
            </Typography>

            <Grid
              container
              justify="center"
              alignItems="center"
              direction="row"
              className={classes.expansionPanelInternal}
            >
              <Grid item>
                <TextField
                  autoFocus
                  id="filled-basic"
                  inputRef={binNameRef}
                  className={classes.searchField}
                  classes={{ root: classes.searchFieldRoot }}
                  label="Bin #"
                  margin="normal"
                  onChange={handleBinNameChange}
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      root: classes.inputRoot,
                      focused: classes.inputRoot,
                      filled: classes.inputRoot,
                    },
                  }}
                  InputLabelProps={{
                    style: { color: '#fff' },
                    classes: {
                      root: classes.searchFieldLabelRoot,
                      filled: classes.searchFieldLabelRoot,
                    },
                  }}
                  variant="filled"
                  value={binName}
                  onKeyDown={async (e) => {
                    if (e.key === 'Enter') {
                      await handleSubmit();
                    }
                  }}
                />
              </Grid>
              <Grid item>
                <Button
                  className={css`
                    font-weight: bold;
                    margin: auto;
                    padding: 6px 20px;
                    color: ${colors.white};
                    border-radius: 5px;
                    white-space: nowrap;
                  `}
                  color="secondary"
                  variant="contained"
                  onClick={handleSubmit}
                >
                  Proceed
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContentText>
      <Grid container justify="space-between" style={{ marginTop: '3rem' }}>
        <Grid item>{carrier}</Grid>
        <Grid item>{bin}</Grid>
      </Grid>
    </DialogContent>
  );

  const dialogActions = (
    <DialogActions classes={{ root: dialogActionsContainer }}>
      <Grid
        container
        className={classes.buttonsContainer}
        spacing={2}
        justify="center"
        wrap="nowrap"
      >
        {
        !noCancel
          && (
          <Grid item>
            <NewOrderNextButtonClear disabled={loading} onClick={onCancel}>{cancelLabel || 'No, cancel'}</NewOrderNextButtonClear>
          </Grid>
          )
      }
        <Grid item>
          <ProceedButtonComponent
            className={classes.buttonText}
            disabled={loading}
            onClick={onClick}
            {...proceedButtonProps}
          >
            {proceedLabel || 'Yes, submit'}
          </ProceedButtonComponent>
        </Grid>
      </Grid>
      {loading && <CircularProgress color="secondary" className={classes.progress} />}
    </DialogActions>
  );

  return (
    <Dialog
      open={open}
      onClose={(e, reason) => {
        if (noCancel) return;
        if ((loading) && ['backdropClick', 'escapeKeyDown'].includes(reason)) return;
        onCancel();
      }}
      fullWidth
      PaperComponent={PaperComponent}
      scroll="paper"
      classes={{
        scrollPaper: scrollContainer, paperScrollBody: topPaperScrollBody, paper: dialogRoot, container: dialogContainer, paperFullWidth: dialogCustomizedWidth,
      }}
      transitionDuration={{ exit: 0, enter: 100, appear: 100 }}
    >
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        {' '}
      </DialogTitle>
      {dialogContent}
      {dialogActions}
    </Dialog>
  );
}

BinScanModal.propTypes = {
  open: PropTypes.bool,
  message: PropTypes.node,
  onProceed: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  proceedLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  cancelLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  proceedButtonComponent: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  proceedButtonProps: PropTypes.objectOf(PropTypes.any),
  noCancel: PropTypes.bool,
  binMessage: PropTypes.string,
  carrier: PropTypes.node.isRequired,
  bin: PropTypes.node.isRequired,

};

BinScanModal.defaultProps = {
  open: false,
  message: null,
  proceedLabel: '',
  cancelLabel: '',
  proceedButtonComponent: undefined,
  proceedButtonProps: null,
  noCancel: false,
  binMessage: null,
};
