import React, { memo } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { Grid } from '@material-ui/core';
import ReadOnlyContainer from '../../components/common/ReadOnlyContainer';
import { ACCOUNT_MANAGEMENT } from './constants';
import ReadOnlyTextField from '../../components/common/ReadOnlyTextField';
import Form from './Form';

const EmergencyContact = ({
  formikRef, itemKey, values, isEditing, onEdit, onSubmit,
}) => {
  const obj = ACCOUNT_MANAGEMENT[itemKey];

  return (
    <ReadOnlyContainer title={obj.title} setIsReadOnly={() => onEdit(itemKey)}>
      {isEditing ? (
        <Form
          ref={formikRef}
          itemKey={itemKey}
          initialValues={values}
          validationSchema={obj.validationSchema}
          fields={obj.fields}
          onEdit={onEdit}
          onSubmit={onSubmit}
        />
      ) : obj.fields.map((field) => (
        <Grid key={field.name} item xs={6}>
          <ReadOnlyTextField
            type={field.type}
            iconName={field.iconName}
            name={field.name}
            label={field.label}
            value={get(values, field.name, '')}
          />
        </Grid>
      ))}
    </ReadOnlyContainer>
  );
};

EmergencyContact.defaultProps = {
  values: null,
  isEditing: false,
};

EmergencyContact.propTypes = {
  formikRef: PropTypes.shape(PropTypes.object).isRequired,
  itemKey: PropTypes.string.isRequired,
  values: PropTypes.shape({
    name: PropTypes.string,
    phone: PropTypes.string,
    emergencyInformation: PropTypes.string,
    phoneExtension: PropTypes.string,
  }),
  isEditing: PropTypes.bool,
  onEdit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default memo(EmergencyContact);
