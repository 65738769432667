/* eslint-disable max-len */
import React, { useState } from 'react';
import { Field, Form, Formik } from 'formik';
import {
  FormControlLabel, Grid,
  MenuItem, Typography,
  CircularProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import * as colors from '../styles/colors';
import { NEW_ORDER_MODULE_STYLE } from '../styles/style';

import { DueDatePicker } from './common/InputComponents';
import CustomSelectTextField from './common/CustomSelectTextField';
import { useSingleOrderState } from '../context/singleOrderContext';
import NewOrderNextButton from './common/NewOrderNextButton';
import { disableSubmitOnEnterKey, isCustomsBrokerAvailable, isInternational } from '../utils/helpers';
import StyledCheckbox from './common/StyledCheckbox';

const dataPickerStyles = {
  datePicker: {
    backgroundColor: colors.inputFieldBackground,
    paddingLeft: 10,
    height: 56,
    // '&$disabled': {
    //   color: 'white',
    // },
    // '&>div:nth-of-type(2)': {
    //   '&>button:first-of-type': {
    //     color: colors.textLightGrey,
    //   },
    // },
  }, 
}

const useDataPickerStyles = makeStyles(dataPickerStyles);

const regulationAndComplianceFormStyles = {
  panelContainer: {
    background: colors.newOrderFormBackground,
  },
  outerFormConatiner: {
    width: '100%',
  },
  innerFormContainer: {
    padding: '0 15px 15px 15px',
    margin: '10px 0',
    background: colors.darkBlueBackground,
  },
  formFieldHeader: {
    fontWeight: 500,
    fontSize: '14px',
  },
  formFieldSubHeader: {
    fontSize: '14px',
    fontWeight: 500,
  },
  miscLoadingContainer: {
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingTop: 24,
  },
};

const useRegulationAndComplianceFormStyles = makeStyles(regulationAndComplianceFormStyles);


const PartyTerms = (props) => {
  const { name, label, terms, disabled = false } = props;
  return (
    <Field
      component={CustomSelectTextField}
      style={{ width: '100%' }}
      type="text"
      name={name}
      label={label}
      disabled={disabled}
    >
      {
        terms && terms.map((term) => (
          <MenuItem
            key={term.value}
            value={term.value}
          >
            {term.label}
          </MenuItem>
        ))
      }
    </Field>
  );
};

const formatExpiresAtDateTime = (dueDate) => moment(
  moment(dueDate)
    .format()
    .replace(/^(\d{4}-\d{2}-\d{2}T)(.*)(-\d{2}:\d{2})$/,
      (match, p1, p2, p3) => [p1, '23:59:59', p3]
        .join('')),
).utc()
  .format();

const DueDate = ({
  disabled, name, form,
}) => {
  const classes = useDataPickerStyles();
  const onDateChange = (e) => {
    if (!e) return;
    form.setFieldValue(name, formatExpiresAtDateTime(e));
  };
  return (
    <Grid item xs={6}>
      <DueDatePicker
        name={name}
        disabled={disabled}
        disablePast={true}
        value={moment(form.values[name]).format('MM/DD/YYYY')}
        classes={classes}
        onChange={onDateChange}
        style={{ marginTop: '0', marginLeft: '10px', marginBottom: '0' }}
      />
    </Grid>
  );
}

const today = () => {
  const d = new Date();
  d.setHours(0, 0, 0, 0);
  return d;
} 

export default function RegulationAndComplianceForm({
  openNextPanel, hideFields,
  submitFormToApi, shipmentId,
  shipmentTermsOfSale, shipmentPartyTerms,
}) {
  const hiddenFields = (hideFields || []).map(({fieldName}) => fieldName);

  const genericClasses = NEW_ORDER_MODULE_STYLE();

  const orderState = useSingleOrderState();
  const shipment = orderState.shipments?.[shipmentId];

  const shipmentPartyTermsValues = [
    ...shipmentPartyTerms,
    ...(isCustomsBrokerAvailable(shipment) ? [
      {
        value: 'Customs Broker',
        label: 'Customs Broker',
      }
    ] : []),
  ];
  
  const {
    termsOfSale,
  } = shipment?.compliance?.details || {};

  const termsOfSaleValue = termsOfSale?.type || 'FOB';

  const { terms = {} } = shipmentTermsOfSale.find( ({ value }) => value === termsOfSaleValue ) || {};
  
  const classes = useRegulationAndComplianceFormStyles();

  const now = formatExpiresAtDateTime(new Date());

  const [skipTermsOfSale, setSkipTermsOfSale] = useState(!!termsOfSale?.isOverride);

  const getInitialValues = () => {
    return {
      termsOfSale: termsOfSaleValue,

      billing: termsOfSale?.billing?.termsOfSaleUserType || terms.billing,
      billingDueDate: termsOfSale?.billing?.expiresAt || now,

      billingCustoms: termsOfSale?.billingCustoms?.termsOfSaleUserType || terms.billingCustoms,
      billingCustomsDueDate: termsOfSale?.billingCustoms?.expiresAt || now,

      billingTaxes: termsOfSale?.billingTaxes?.termsOfSaleUserType || terms.billingTaxes,
      billingTaxesDueDate: termsOfSale?.billingTaxes?.expiresAt || now,
    };
  };

  const sanitizeComplianceObject = (compliance) => {
    const complianceObj = { ...compliance };
    const { label } = shipmentTermsOfSale.find( ({ value }) => value === complianceObj.termsOfSale );

    complianceObj.termsOfSale = {
      type: complianceObj.termsOfSale,
      description: label,
      ...(!hiddenFields.includes('dutiesTaxes') && {
        isOverride: skipTermsOfSale,
        billing: {
            termsOfSaleUserType: complianceObj.billing,
            ...(showDatePicker(complianceObj.billing) && {
            expiresAt: complianceObj.billingDueDate,
          }),
        },
      

        billingCustoms: {
            termsOfSaleUserType: complianceObj.billingCustoms,
            ...(!equal({values: compliance}) && showDatePicker(complianceObj.billingCustoms) && {
            expiresAt: complianceObj.billingCustomsDueDate,
          }),
        },



        billingTaxes: {
            termsOfSaleUserType: complianceObj.billingTaxes,
            ...(!equal({values: compliance}) && showDatePicker(complianceObj.billingTaxes) && {
            expiresAt: complianceObj.billingTaxesDueDate,
          }),
        },
      }),
    };
  
    for (const v in { ...complianceObj }) {
      if(v.startsWith('billing')){
        delete complianceObj[v]
      }
    }

    return complianceObj;
  };

  const onSubmit = async (values, { setSubmitting }) => {
    const complianceObj = sanitizeComplianceObject(values);
    try {
      await submitFormToApi(
        complianceObj,
      );
      openNextPanel();
    } catch (e) {
      //
    }
    setSubmitting(false);
  }

  const validationSchema = Yup.object().shape({
    termsOfSale: Yup.string().when('shippingType', {
      is: 'international',
      then: Yup.string().required('Required'),
    }),
    billingDueDate: Yup.date().test('Success', 'Field is required', function (date) {
      return !hiddenFields.includes('dutiesTaxes') && showDatePicker(this.parent.billing) ? moment(date).isSameOrAfter(moment()) : true;
    }),
    billingCustomsDueDate: Yup.date().test('Success', 'Field is required', function (date) {
      return !hiddenFields.includes('dutiesTaxes') && !equal({ values: {...this.parent} }) && showDatePicker(this.parent.billingCustoms) ? moment(date).isSameOrAfter(moment()) : true;
    }),
    billingTaxesDueDate: Yup.date().test('Success', 'Field is required', function (date) {
      return !hiddenFields.includes('dutiesTaxes') && !equal({ values: {...this.parent} }) && showDatePicker(this.parent.billingTaxes) ? moment(date).isSameOrAfter(moment()) : true;
    }),
  });

  const onTermsOfSaleChange = (v, form) => {
    const { terms = {} } = shipmentTermsOfSale.find( ({ value }) => value === v.value );
    for (const term in terms) {
      form.setFieldValue(term, terms[term]);
    }
  }

  const onSkipTermsOfSaleChange = (v, form) => {
    const { termsOfSale } = form?.values || {}
    const { terms = {} } = shipmentTermsOfSale.find( ({ value }) => value === termsOfSale );
    for (const term in terms) {
      form.setFieldValue(term, terms[term]);
    }

    setSkipTermsOfSale(!v);
  }

  const showDatePicker = (v) => {
    return ['Recipient', 'Customs Broker'].includes(v);
  }

  const equal = (form) => {
    return Object.keys(terms).map((k) => form.values[k]).every( (val, i, arr) => val === arr[0] ) 
  }

  return (
    <Formik
      initialValues={getInitialValues()}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formikProps) => {
        return (
        <Grid container className={classes.panelContainer} spacing={3}>
          <Grid item style={{ width: '100%' }}>
            <Form style={{ width: '100%' }} onKeyDown={disableSubmitOnEnterKey}>
              <Grid item container direction="column" spacing={2}>
                <Grid item container className={classes.outerFormConatiner}>
                  <Grid item container direction="column" className={classes.innerFormContainer} spacing={4}>
                        <Grid item container direction="column" spacing={1} wrap="nowrap">
                          <Grid item>
                            <Typography color="primary" className={classes.formFieldHeader}>Terms of Sale</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Field
                              component={CustomSelectTextField}
                              style={{ width: '100%' }}
                              type="text"
                              name="termsOfSale"
                              label="Select Incoterm"
                              data-testid="termsOfSale-input"
                              customOnChange={(e) => onTermsOfSaleChange(e.target, formikProps)}
                            >
                              {
                                shipmentTermsOfSale && shipmentTermsOfSale.map((type) => (
                                  <MenuItem
                                    key={type.value}
                                    value={type.value}
                                  >
                                    {type.label}
                                  </MenuItem>
                                ))
                              }
                            </Field>
                          </Grid>
                          {!hiddenFields.includes('dutiesTaxes') && (
                            <>
                              <Grid item xs={6}>
                                <FormControlLabel
                                  control={(
                                    <StyledCheckbox
                                      color="primary"
                                      checked={skipTermsOfSale}
                                      onChange={() => onSkipTermsOfSaleChange(skipTermsOfSale, formikProps)}
                                      value={true}
                                      disabled={false}
                                    />
                                  )}
                                  label={(
                                    <Typography
                                      color="primary"
                                      style={{
                                        fontSize: '14px',
                                        color: 'white',
                                        whiteSpace: 'nowrap',
                                      }}
                                    >
                                      Skip Terms of sale and assign financial responsibility manually.
                                    </Typography>
                                  )}
                                />
                              </Grid>

                              <Grid item container xs={12}>
                                <Grid item xs={6}>
                                  <PartyTerms 
                                    name="billing"
                                    disabled={!skipTermsOfSale}
                                    label="Freight Charges"
                                    terms={shipmentPartyTermsValues}
                                  />
                                </Grid>
                                {
                                  (showDatePicker(formikProps.values.billing)) && (
                                    <DueDate
                                      name="billingDueDate"
                                      disabled={false}
                                      form={formikProps}
                                      classes={genericClasses}
                                    />
                                  )
                                }
                              </Grid>
                              
                              <Grid item container xs={12}>
                                <Grid item xs={6}>
                                  <PartyTerms 
                                    name="billingCustoms"
                                    disabled={!skipTermsOfSale}
                                    label="Customs Duties Charges"
                                    terms={shipmentPartyTermsValues}
                                  />
                                </Grid>
                                {
                                  (!equal(formikProps) && showDatePicker(formikProps.values.billingCustoms)) && (
                                    <DueDate
                                      name="billingCustomsDueDate"
                                      disabled={false}
                                      form={formikProps}
                                      classes={genericClasses}
                                    />
                                  )
                                }
                              </Grid>
                              
                              <Grid item container xs={12}>
                              
                              <Grid item xs={6}>
                                <PartyTerms 
                                  name="billingTaxes"
                                  disabled={!skipTermsOfSale}
                                  label="Taxes Charges"
                                  terms={shipmentPartyTermsValues}
                                />
                              </Grid>
                              
                              {
                                (!equal(formikProps) && showDatePicker(formikProps.values.billingTaxes)) && (
                                  <DueDate
                                    name="billingTaxesDueDate"
                                    disabled={false}
                                    form={formikProps}
                                    classes={genericClasses}
                                  />
                                )
                              }
                              </Grid>
                            </>

                          )}
                        </Grid>
                  </Grid>
                </Grid>
                <Grid item container>
                  <Grid item>
                    <NewOrderNextButton
                      disabled={formikProps.isSubmitting}
                      onClick={() => formikProps.submitForm()}
                    >
                      Next
                    </NewOrderNextButton>
                  </Grid>
                </Grid>
                {formikProps.isSubmitting && (
                  <Grid item container justify="center" classes={{ root: classes.miscLoadingContainer }}>
                    <CircularProgress color="secondary" />
                  </Grid>
                )}
              </Grid>
            </Form>
          </Grid>
        </Grid>
      )}}
    </Formik>
  );
}

RegulationAndComplianceForm.propTypes = {
  shipmentId: PropTypes.string.isRequired,
  setSelectedForms: PropTypes.func.isRequired,
  submitFormToApi: PropTypes.func.isRequired,
  shipmentTermsOfSale: PropTypes.arrayOf(PropTypes.object).isRequired,
  shipmentPartyTerms: PropTypes.arrayOf(PropTypes.object).isRequired,
  licensePermitTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
};
