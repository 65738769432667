import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import * as colors from '../styles/colors';

const styles = {
  breadcrumbs: {
    paddingTop: 8,
    textAlign: 'left',
    fontSize: 16.19,
    color: colors.linkBlue,
  },
  link: {
    color: colors.linkBlue,
    fontWeight: 600,
    letterSpacing: 0.15,
  },
};

const useStyles = makeStyles(styles);

export default function Breadcrumbs(props) {
  const classes = useStyles();
  const { pages } = props;
  return (
    <Typography component="div" className={classes.breadcrumbs}>
      {pages.map((page, i) => {
        if (i < pages.length - 1) {
          return (
            <span key={`span-${page.id}`}>
              <Link
                key={`link-${page.id}`}
                className={classes.link}
                to={page.url}
              >
                {page.title}
              </Link>
               /
            </span>
          );
        }
        return <span key={`span-${page.id}`}>{page.title}</span>;
      })}
    </Typography>
  );
}

Breadcrumbs.propTypes = {
  pages: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    url: PropTypes.string,
    title: PropTypes.string.isRequiredc,
  })).isRequired,
};
