import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import { css } from 'emotion';
import * as colors from '../../styles/colors';

export default function PanelWarning({ message }) {
  const classes = {
    taskCannotBeCompletedWrap: css`
        border: 1px solid ${colors.lightBackgroundGrey};
        border-radius: 5px;
        background: ${colors.darkBlueBackground};
        margin-top:10px;
        color: ${colors.white};
        padding: 15px 30px;
        flex-shrink: 0;
    `,
    taskCannotBeCompletedWrapText: css`
      font-size:14px;
      color:${colors.white}
    `,
    errorIconRoot: css`
      color: ${colors.errorRed}
    `,
    errorIcon: css`
      margin-right: 20px
    `,
  };

  return (
    <Grid item container className={classes.taskCannotBeCompletedWrap} justify="flex-start" alignItems="center">
      <ErrorIcon classes={{ root: classes.errorIconRoot }} className={classes.errorIcon} />
      <Typography className={classes.taskCannotBeCompletedWrapText}>{message || 'This panel is not ready to be completed'}</Typography>
    </Grid>
  );
}
