import React from 'react';
import {
  Grid, Popover, Typography, Table, TableRow, TableCell, TableHead, TableBody, LinearProgress, Tab, Tabs,
  Box,
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import OutlinedTruckIcon from '@material-ui/icons/LocalShippingOutlined';
import iotIcon from './images/rss.svg';
import * as colors from '../styles/colors';
import { loadTrackingInfo, loadTrackInfoWithTrackNo, loadShippingOrIotTrackingInfo } from '../utils/trackingClient';
import { formatDateLong } from '../utils/helpers';
import { SHIPMENT_TRACKING_TAB, IOT_TRACKING_TAB } from '../clientConstants';
import SalesOrderDetailsTable from './SalesOrderDetailsTable';

const useStyles = makeStyles(() => ({
  trackingModal: {
    background: '#393c4b',
    width: '100%',
    minHeight: 100,
    padding: '10px 20px',
    color: colors.white,
    borderRadius: '5px',
    border: `1px solid ${colors.newOrderFormBackground}`,
  },
  disclaimerText: {
    paddingBottom: 16,
    fontStyle: 'italic',
    letterSpacing: 0.15,
  },
  trackingModalPaper: {
    background: colors.newOrderFormBackground,
    borderRadius: '5px',
  },
  trackingModalHeader: {
    padding: '5px 0 15px 0',
  },
  trackingHistory: {
    background: colors.shipmentCardBackground,
    width: '100%',
    color: colors.white,
    padding: '0 20px',
  },
  table: {
    color: colors.white,
  },
  tableHeaderCell: {
    color: colors.white,
    fontWeight: 500,
    fontSize: 16,
    borderBottom: '1px solid #444655',
    padding: '15px 0',
  },
  tableBodyCell: {
    color: colors.white,
    borderBottom: '1px solid #444655',
    padding: '10px 0',
  },
  special: {
    color: colors.textLightGrey,
    fontWeight: 500,
  },
  header: {
    fontWeight: 500,
  },
  tableDateDisplay: {
    color: colors.white,
  },
  updatedAt: {
    fontSize: 12,
    fontWeight: 500,
    paddingBottom: 15,
  },
  noShippingHistory: {
    color: colors.white,
  },
  linearProgressWrap: {
    width: '100%',
  },
}));

function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const StyledTabs = withStyles({
  indicator: {
    backgroundColor: colors.lightSkyBlue,
  },
})(Tabs);

const StyledTab = withStyles({
  root: {
    color: '#afb0b5',
    fontWeight: 500,
    fontSize: 16,
    lineSpacing: 0.15,
    textTransform: 'none',
  },
  selected: {
    color: colors.white,
  },
  disabled: {
    color: colors.white,
  },
})(Tab);

export default function SalesOrderModal(props) {
  const classes = useStyles();
  const {
    id, handleClose, salesOrderModalState, setCurrentTab, currentTab, shippingDocumentsPanel, onDeleteClick,
  } = props;
  const {
    anchorEl, binId, salesOrders, type = null,
  } = salesOrderModalState;

  const [trackingHistory, setTrackingHistory] = React.useState([]);
  const [iotTrackingHistory, setIotTrackingHistory] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [verticalPosition, setVerticalPosition] = React.useState(0);
  const [horizontalPosition, setHorizontalPosition] = React.useState(0);
  const modalRef = React.useRef();
  const popoverRef = React.useRef();

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const handleCloseAndRemoveData = () => {
    setIotTrackingHistory([]);
    setTrackingHistory([]);
    handleClose();
  };

  React.useEffect(() => {
    if (anchorEl) {
      getVerticalPosition(anchorEl, modalRef.current && modalRef.current.clientHeight);
      getHorizontalPosition(anchorEl);
    }
  }, [anchorEl]);

  React.useEffect(() => {
    const adjustment = (-(modalRef.current && modalRef.current.clientHeight) || 0);
    if (verticalPosition > (window.innerHeight + adjustment)) {
      setVerticalPosition((prev) => prev + adjustment);
    }
  }, [trackingHistory, iotTrackingHistory]);

  React.useEffect(() => {
    // Workaround for problem with tab indicator and resizing
    // For more info, see here: https://github.com/mui-org/material-ui/issues/9337
    window.dispatchEvent(new CustomEvent('resize'));
    // if (popoverRef.current) popoverRef.current.updatePosition();
  }, [verticalPosition]);

  const getVerticalPosition = (anchorEl, clientHeight = 0) => {
    const {
      offsetTop, parentElement, scrollTop, parentOffset,
    } = anchorEl;
    let totalOffsetTop = offsetTop;
    let totalScrollTop = scrollTop;
    let parent = parentElement;
    while (parent) {
      totalScrollTop += (parent.scrollTop || 0);
      parent = parent.parentElement;
    }
    let offsetParent = parentOffset;
    while (offsetParent) {
      totalOffsetTop += offsetParent.offsetTop || 0;
      offsetParent = offsetParent.parentOffset;
    }
    let totalVerticalOffset = totalOffsetTop - totalScrollTop + 80;
    if (totalVerticalOffset > window.innerHeight - clientHeight) {
      totalVerticalOffset += 1000;
    }
    setVerticalPosition(() => totalVerticalOffset);
  };
  const getHorizontalPosition = (anchorEl) => {
    const { offsetLeft, offsetParent, clientWidth } = anchorEl;
    let totalOffsetLeft = offsetLeft;
    let parent = offsetParent;
    while (parent) {
      totalOffsetLeft += parent.offsetLeft || 0;
      parent = parent.offsetParent;
    }
    totalOffsetLeft += clientWidth;
    setHorizontalPosition(totalOffsetLeft);
  };

  return (
    <Popover
      id={id}
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={handleCloseAndRemoveData}
      anchorReference="anchorPosition"
      anchorPosition={{
        top: verticalPosition,
        left: horizontalPosition,
      }}
      action={popoverRef}
      classes={{ paper: classes.trackingModalPaper }}
    >
      {console.log(salesOrders)}
      <Grid container className={classes.trackingModal} direction="column" ref={modalRef}>
        <Grid container item className={classes.trackingModalHeader} justify="space-between" alignItems="flex-end">
          <Grid item container style={{ width: 'auto' }}>
            <Typography>{binId}</Typography>

            {
          loading ? (
            <LinearProgress color="secondary" />
          ) : (
            <Grid item className={classes.trackingHistory} container alignItems="center" direction="column">
              <SalesOrderDetailsTable
                rows={salesOrders}
                onDeleteClick={onDeleteClick}
              />
            </Grid>
          )
        }
          </Grid>
        </Grid>
      </Grid>
    </Popover>
  );
}
