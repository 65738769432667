import React, { memo } from 'react';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import * as colors from '../../styles/colors';

const useStyles = makeStyles(() => ({
  innerContainer: {
    backgroundColor: '#313547',
    padding: '24px',
    marginBottom: '24px',
    borderRadius: '4',
    boxShadow: '0 1px 1px 0 rgba(0,0,0,0.14)',
  },
  header: {
    color: colors.white,
    fontSize: '20px',
    fontWeight: 500,
    letterSpacing: '0.25px',
  },
}));

const ReadOnlyContainer = ({
  title, children, isEditable, setIsReadOnly,
}) => {
  const classes = useStyles();

  return (
    <Grid item container className={classes.innerContainer}>
      <Grid container spacing={2}>
        <Grid xs={12} item container justify="space-between" alignItems="center">
          <Grid item>
            <Typography className={classes.header}>{title}</Typography>
          </Grid>
          {isEditable && (
            <Grid item>
              <IconButton onClick={() => {
                if (setIsReadOnly) setIsReadOnly(false);
              }}
              >
                <EditIcon />
              </IconButton>
            </Grid>
          )}
        </Grid>
        {children}
      </Grid>
    </Grid>
  );
};

ReadOnlyContainer.defaultProps = {
  children: null,
  isEditable: true,
  setIsReadOnly: null,
};

ReadOnlyContainer.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  isEditable: PropTypes.bool,
  setIsReadOnly: PropTypes.func,
};

export default memo(ReadOnlyContainer);
