import React from 'react';
import PropTypes from 'prop-types';
import has from 'lodash/has';
import {
  ExpansionPanel, ExpansionPanelDetails,
  ExpansionPanelSummary, Grid,
  Typography, Button,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { css } from 'emotion';
import { Route } from 'react-router-dom';
import shortid from 'shortid';
import { makeStyles } from '@material-ui/core/styles';
import StyledCheckCircleIcon from './StyledCheckCircleIcon';
import StyledWarningIcon from './StyledWarningIcon';
import { NEW_ORDER_MODULE_STYLE } from '../../styles/style';
import PaneDetailsSummary from './PaneDetailsSummary';
import AssignmentModal from './AssignmentModal';
import * as colors from '../../styles/colors';
import { TaskAssignedToUser, TaskAssignedByUser } from './TaskAssigned';
import taskStatusHelp from '../../utils/taskStatus';
import * as taskContext from '../../context/taskDataContext';
import {
  useSingleOrderDispatch, internalUpdateShipment,
} from '../../context/singleOrderContext';
import NewOrderErrorDialog from './NewOrderErrorDialog';
import NewOrderComplianceErrorDialog from './NewOrderComplianceErrorDialog';

import ConfirmationModal from './ConfirmationModal';
import CancelCopyShipmentModal from './CancelCopyShipmentModal';
import StatusChip from './StatusChip';
import * as shipmentClient from '../../utils/shipmentClient';
import { formNameMap } from '../../utils/helpers';
/**
 * ##################################
 *          CUSTOM COMPONENT
 * ##################################
 */

const expandIcon = (
  <ExpandMoreIcon color="primary" />
);

function AssignedLabel(props) {
  const { task } = props;

  const classes = {
    assignedLabel: css`
      color: ${colors.textLightGrey};
      font-size:14px;
    `,
    assignedToName: css`
      color: ${colors.white};
      font-weight: 500
    `,
    buttonLabel: css`
      height:1em
    `,
  };

  return (
    <span className={classes.assignedLabel}>
      Assigned to
      &nbsp;
      <span className={classes.assignedToName}>{task.assignedTo.fullName}</span>
      &nbsp;
      &nbsp;
      <StatusChip status={task.status} type="task" />
    </span>
  );
}

function CustomLabel({
  label, isComplete, isComplianceFailure, classes, labelDetails, formName,
  setAssignmentModalOpen, isAssignTaskAvailable, task, expanded,
  info,
}) {
  const styles = {
    failure: {
      fontSize: 14,
      color: '#EF6A6E',
      fontWeight: 500,
      lineHeight: '24px',
      letterSpacing: 0.1,
    },
  };

  const useStyles = makeStyles(styles);

  const customClasses = useStyles();

  const assignTaskButton = (
    <Button
      className={classes.taskButton}
      onClick={(e) => {
        e.stopPropagation();
        setAssignmentModalOpen(true);
      }}
      classes={{ label: classes.buttonLabel }}
    >
      <AssignmentIcon />
      {' '}
      <Typography className={classes.taskButtonText}>Assign Task</Typography>
    </Button>
  );

  const collaboration = () => {
    if (isAssignTaskAvailable) {
      return assignTaskButton;
    } if (!expanded && isComplete) {
      return labelDetails;
    }

    return (null);
  };

  return (
    <Grid
      container
      direction="row"
      spacing={1}
      classes={{
        root: classes.expansionPSTitleContainer,
      }}
      wrap="nowrap"
    >

      <Grid item>
        <Typography
          classes={{
            root: classes.expansionPSTitle,
          }}
        >
          {label}
          {' '}
          {task && <AssignedLabel task={task} />}
        </Typography>
      </Grid>
      <Grid item>
        <CustomCompleteIcon isComplete={isComplete} isComplianceFailure={isComplianceFailure} />
      </Grid>
      {
        isComplianceFailure && (
          <Grid item>
            <Typography className={customClasses.failure}>Compliance Failure</Typography>
          </Grid>
        )
      }
      <Grid item>
        <Typography
          classes={{
            root: classes.expansionPSSubTitle,
          }}
        >
          {
            collaboration()
          }
          {
            info
          }
        </Typography>
      </Grid>
    </Grid>
  );
}

CustomLabel.propTypes = {
  label: PropTypes.string.isRequired,
  labelDetails: PropTypes.string.isRequired,
  info: PropTypes.node,
  isComplete: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.array,
    PropTypes.object,
  ]).isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

const CustomCompleteIcon = ({ isComplete, isComplianceFailure }) => {
  if (isComplianceFailure) {
    return (<StyledWarningIcon />);
  }

  return (isComplete)
    ? <StyledCheckCircleIcon isValidated isGreenWhenValid />
    : null;
};

CustomCompleteIcon.propTypes = {
  isComplianceFailure: PropTypes.bool,
  isComplete: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.array,
    PropTypes.object,
  ]).isRequired,
};

CustomCompleteIcon.defaultProps = {
  isComplianceFailure: false,
};

/**
 * #################################
 *          EXPORT FUNCTION
 * #################################
 */
export const shouldFormBeReadOnlyGivenShipmentStatus = (status, formName) => {
  const readOnlyStatuses = {
    completed: {
      default: true,
      shippingDocuments: false,
      pickupfrom: false,
    },
    cancelled: {
      default: true,
      shippingDocuments: true,
      pickupfrom: true,
    },
    'scheduled for pickup': {
      default: true,
      shippingDocuments: false,
      pickupfrom: false,
    },
    'in-transit': {
      default: true,
      shippingDocuments: false,
      pickupfrom: false,
    },
    delivered: {
      default: true,
      shippingDocuments: false,
      pickupfrom: false,
    },
    'completed. pickup cancelled': {
      default: true,
      shippingDocuments: false,
      pickupfrom: false,
    },
    created: {
      shippingDocuments: true,
      pickupfrom: true,
      default: false,
    },
    default: {
      shippingDocuments: true,
      pickupfrom: true,
      default: false,
    },
  };
  const statusMapped = readOnlyStatuses[status.toLowerCase()] || readOnlyStatuses.default;
  return formName in statusMapped ? statusMapped[formName] : statusMapped.default;
};

export const doesShipmentExist = (shipment) => !!shipment;

export default function NewOrderShipmentModule({
  updateFn,
  label,
  name,
  labelDetails,
  info,
  isComplete,
  isComplianceFailure,
  isReadOnly,
  isDisabled,
  children,
  isSelected,
  onChange,
  formName,
  shipment,
  preReqsComplete,
  isAssignTaskAvailable,
  panels,
  blockingMessage,
}) {
  const classes = NEW_ORDER_MODULE_STYLE();
  const task = taskStatusHelp.formNameMap[formName] && taskStatusHelp.getAssignedTaskFromForm(shipment, formName);
  const [assignmentModalOpen, setAssignmentModalOpen] = React.useState(false);

  const [errorContent, setErrorContent] = React.useState(null);
  const [cancelCopyShipment, setCancelCopyShipment] = React.useState(null);
  const [redirect, setRedirect] = React.useState(null);
  const [isError, setIsError] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isComplianceError, setIsComplianceError] = React.useState(false);

  const [isSubmittingPanel, setIsSubmittingPanel] = React.useState(null);
  const shipmentDispatch = useSingleOrderDispatch();
  const [remount, setRemount] = React.useState(shortid());

  const revokeTask = async () => {
    const revokeResponse = await taskContext.revokeTask({
      shipmentId: shipment.shipmentId,
      taskType: taskStatusHelp.formNameMap[formName],
      dispatch: shipmentDispatch,
    }).catch((e) => {
      setErrorContent(e.message);
      setIsError(true);
    });

    if (has(revokeResponse, 'error')) {
      setErrorContent(revokeResponse.error);
      setIsError(true);
    }

    return revokeResponse;
  };

  const assignTask = async ({ ...data }) => {
    const assignResponse = await taskContext.assignTask({
      shipmentId: shipment.shipmentId,
      taskType: taskStatusHelp.formNameMap[formName],
      dispatch: shipmentDispatch,
      ...data,
    }).catch((e) => {
      setErrorContent(e.message);
      setIsError(true);
    });
    return assignResponse;
  };

  const onInviteSent = (data) => {
    internalUpdateShipment(data, shipmentDispatch, shipment.shipmentId);
  };

  const isActiveTaskAssignedByUser = () => !isReadOnly
            && task
            && shipment
            && !task.isAssignedTo
            && shipment.isCreatedBy
    && task.status.toLowerCase() !== 'revoked';

  const isFailureWithNoTask = () => !isReadOnly
     && !task
     && shipment
     && shipment.isCreatedBy
     && formName === 'exportCompliance'
     && !shipment[formName]?.details?.success;

  const isRerunEnabled = formName === 'exportCompliance' && isActiveTaskAssignedByUser() && (task.status.toLowerCase() === 'created' || task.status.toLowerCase() === 'rejected');

  const isActiveTaskAssignedToUser = () => !isReadOnly
  && task
  && task.isAssignedTo;

  function renderFormContent() {
    if (formName === 'exportCompliance' && isReadOnly) {
      // eslint-disable-next-line no-nested-ternary
      return isComplete ? (
        typeof children === 'function' ? (
          children({ submitFormToApi, readOnly: isReadOnly, isReadOnly })
        ) : (
          children
        )
      ) : null;
    }

    if (isReadOnly) {
      return isComplete
        && (
        <PaneDetailsSummary
          formName={formName}
          shipment={shipment}
        />
        );
    }
    if (isActiveTaskAssignedToUser()) {
      return (
        <Grid container direction="column">
          <TaskAssignedToUser
            task={task}
            preReqsComplete={preReqsComplete}
            blockingMessage={blockingMessage}
          />
          {task.status.toLowerCase() !== 'created'
          && preReqsComplete
          && (
            (typeof children === 'function')
              ? children({ submitFormToApi })
              : children)}
        </Grid>
      );
    }
    if (isActiveTaskAssignedByUser()) {
      return (
        <Grid container direction="column">
          <TaskAssignedByUser
            task={task}
            revokeTask={revokeTask}
            rerunCompliance={submitFormToApi}
            disableRerun={!isRerunEnabled}
          />
          {formName === 'exportCompliance' && typeof children === 'function'
            ? children({
              submitFormToApi,
              readOnly: isReadOnly,
              isReadOnly,
              noShowNext: true,
            })
            : children}
        </Grid>
      );
    }
    return (
      (typeof children === 'function')
        ? <>{children({ submitFormToApi, isFailureWithNoTask, loading: isLoading })}</>
        : children);
  }
  const formContent = renderFormContent();

  async function submitFormToApi(details, bypass) {
    const isCompletingTask = taskStatusHelp.isAssignedTo(shipment, formName);
    const isExportComplainceWithTakeover = ('exportCompliance' in details) && shipment?.isTakeover;

    if (isCompletingTask && !bypass && !isExportComplainceWithTakeover) {
      setIsSubmittingPanel(details);
      return null;
    }
    try {
      const submitResponse = await updateFn({
        dispatch: shipmentDispatch,
        shipmentId: shipment.shipmentId,
        details,
      });
      if (isSubmittingPanel) {
        setIsSubmittingPanel(false);
      }
      return submitResponse;
    } catch (e) {
      if (e.message === 'SHIPMENT RESET FAILED') {
        setCancelCopyShipment(details);
        throw e;
      }
      if (formName === 'exportCompliance' && !shipment[formName]?.details?.success) {
        setIsComplianceError(true);
      } else {
        setIsError(true);
      }
      setErrorContent(e.message);
      if (isSubmittingPanel) setIsSubmittingPanel(false);
      throw e;
    }
  }

  async function handleCancelCopyShipment() {
    try {
      const newOrder = await shipmentClient
        .duplicateShipment({
          shipmentId: shipment.shipmentId,
          [formNameMap[formName]]: cancelCopyShipment,
          isCancelShipment: true,
        });
      const newShipmentId = newOrder.shipments[0].shipmentId;
      const nextPanelIndex = panels.find(({ name }) => name === formName).index + 1;
      const nextPanel = panels[nextPanelIndex].name;
      setRedirect(`/order/${newShipmentId}?panel=${nextPanel}`);
    } catch (e) {
      setErrorContent(JSON.stringify({ error: 'Error duplicating shipment. Please try again' }));
      setIsError(true);
    }
    setCancelCopyShipment(null);
  }

  const onSubmit = async () => {
    setIsLoading(true);
    const payload = {
      type: 'load',
    };

    try {
      await submitFormToApi(payload);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };
  return (
    <>
      {isAssignTaskAvailable
      && (
      <AssignmentModal
        open={assignmentModalOpen}
        label={label}
        panelName={name}
        shipmentId={shipment?.shipmentId}
        onClose={() => setAssignmentModalOpen(false)}
        assignTask={assignTask}
        onInviteSent={onInviteSent}
        classes={classes}
        minDueDate={taskStatusHelp.getMinDueDate(shipment, formName)}
        maxDueDate={taskStatusHelp.getMaxDueDate(shipment, formName)}
      />
      )}
      <ExpansionPanel
        classes={{
          root: classes.expansionPanel,
        }}
        expanded={isSelected}
        onChange={onChange}
        disabled={isDisabled}
        data-testid={`${formName}-expansion-panel`}
        TransitionProps={formName === 'billing' ? {} : { unmountOnExit: true }}
      >
        <ExpansionPanelSummary
          classes={{
            root: classes.expansionPanelSummary,
            expanded: classes.expansionPSExpanded,
            content: classes.expansionPSContent,
          }}
          expandIcon={expandIcon}
          data-testid={`${formName}-expansion-panel-summary`}
        >
          <CustomLabel
            label={label}
            labelDetails={labelDetails}
            info={info}
            isComplete={isComplete}
            isComplianceFailure={isComplianceFailure}
            classes={classes}
            formName={formName}
            setAssignmentModalOpen={setAssignmentModalOpen}
            isAssignTaskAvailable={isAssignTaskAvailable}
            task={task}
            expanded={isSelected}
          />
        </ExpansionPanelSummary>
        {(formContent)
          ? (
            <ExpansionPanelDetails
              classes={{ root: classes.expansionPanelDetails }}
              data-testid={`${formName}-expansion-panel-details`}
              key={remount}
            >
              {formContent}
            </ExpansionPanelDetails>
          )
          : null}
      </ExpansionPanel>
      <NewOrderErrorDialog
        open={isError}
        errorContent={errorContent}
        classes={classes}
        onClose={() => {
          setIsError(false);
          setErrorContent(null);
        }}
      />
      <NewOrderComplianceErrorDialog
        open={isComplianceError}
        errorContent={errorContent}
        classes={classes}
        onRerun={() => {
          onSubmit();
          setIsComplianceError(false);
          setErrorContent(null);
        }}
        onClose={() => {
          setIsComplianceError(false);
          setErrorContent(null);
        }}
      />
      <ConfirmationModal
        open={!!isSubmittingPanel}
        onCancel={() => {
          setIsSubmittingPanel(null);
        }}
        onProceed={() => submitFormToApi(isSubmittingPanel, true)}
        message="Please confirm your submission. Once you submit, this form will be sent back to the initiator and you will no longer be able to edit."
      />
      <CancelCopyShipmentModal
        open={!!cancelCopyShipment}
        onCancel={() => {
          setRemount(shortid());
          setCancelCopyShipment(null);
        }}
        onProceed={() => handleCancelCopyShipment()}
        message="You have already paid for this shipment. This change to your shipment changes its cost."
      />
      {redirect && <RedirectToDifferentOrder url={redirect} />}
    </>
  );
}

NewOrderShipmentModule.propTypes = {
  label: PropTypes.string.isRequired,
  labelDetails: PropTypes.string.isRequired,
  info: PropTypes.node,
  formName: PropTypes.string.isRequired,
  isComplete: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.array,
    PropTypes.object,
  ]),
  isComplianceFailure: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.shape({
      props: PropTypes.shape({
        shipmentId: PropTypes.string,
        setSelectedForms: PropTypes.func,
      }).isRequired,
    }),
    PropTypes.func,
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
  preReqsComplete: PropTypes.bool,
  shipment: PropTypes.shape({
    status: PropTypes.string,
    shipmentId: PropTypes.string,
    isCreatedBy: PropTypes.bool,
  }).isRequired,
};

NewOrderShipmentModule.defaultProps = {
  info: null,
  isComplianceFailure: false,
  isComplete: false,
  preReqsComplete: true,
};

function RedirectToDifferentOrder(props) {
  const { url } = props;

  return (
    <Route render={({ history }) => <CustomRedirect history={history} url={url} />} />
  );
}

function CustomRedirect(props) {
  const { url, history } = props;
  const [refreshed, setRefreshed] = React.useState(false);
  React.useEffect(() => {
    history.push(url);
    setRefreshed(true);
  }, []);

  React.useEffect(() => {
    if (refreshed) {
      window.location.reload();
    }
  }, [refreshed]);
  return null;
}
