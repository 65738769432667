import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import { makeStyles } from '@material-ui/core/styles';
import { FormControlLabel, Grid, Typography } from '@material-ui/core';
import { ErrorMessage, FieldArray } from 'formik';
import * as colors from '../../../styles/colors';
import ReadOnlyCheckbox from '../../../components/common/ReadOnlyCheckbox';
import StyledCheckbox from '../../../components/common/StyledCheckbox';
import { AVAILABLE_CARRIERS_FIELD_NAME } from '../constants';
import { formatAvailableCarriers } from '../util';

const useStyles = makeStyles(() => ({
  header: {
    color: colors.white,
    fontSize: '16px',
    fontWeight: 500,
    letterSpacing: '0.25px',
    textTransform: 'capitalize',
    margin: '32px 0',
  },
  base: {
    borderRadius: '8px',
    padding: '10px 20px',
  },
  darkBlue: {
    backgroundColor: colors.darkBlueBackground,
  },
  lightBlue: {
    backgroundColor: colors.background,
  },
  checkboxLabel: {
    fontSize: '14.75px',
    fontWeight: 425,
    color: colors.textLightGrey,
  },
  error: {
    color: colors.danger,
  },
}));

const CarrierTypes = ({
  carriers, accountAvailableCarriers, isEditing,
}) => {
  const classes = useStyles();
  const [availableCarriers, setAvailableCarriers] = useState(null);

  useEffect(() => {
    if (isEmpty(availableCarriers)) {
      setAvailableCarriers(formatAvailableCarriers(accountAvailableCarriers, carriers));
    }
  }, [accountAvailableCarriers, carriers]);

  const handleAvaiableCarriersChange = (key, index, checked) => {
    const newArray = availableCarriers[key];
    newArray[index] = {
      ...newArray[index],
      checked,
    };

    setAvailableCarriers({
      ...availableCarriers,
      [key]: newArray,
    });
  };

  return (
    <Grid container>
      {!isEmpty(availableCarriers) && map(availableCarriers, (value, key) => (
        <>
          <Grid container item xs={12}>
            <Typography className={classes.header}>{key}</Typography>
          </Grid>
          <Grid key={key} className={`${classes.base} ${isEditing ? classes.lightBlue : classes.darkBlue}`} container>
            <FieldArray name={`${AVAILABLE_CARRIERS_FIELD_NAME}.${key}`}>
              {(arrayHelpers) => (
                <>
                  {!isEmpty(value) && value.map((option, index) => (
                    // eslint-disable-next-line
                    <Grid key={`${option.type}-${key}`} item xs={4}>
                      {isEditing ? (
                        <FormControlLabel
                          className={classes.checkboxLabel}
                          control={(
                            <StyledCheckbox
                              name={option.type}
                              color="default"
                              checked={option.checked}
                              onChange={(e) => {
                                const n = option.type;

                                if (e.target.checked) {
                                  arrayHelpers.push(n);

                                  handleAvaiableCarriersChange(key, index, true);
                                } else {
                                  const idx = accountAvailableCarriers[key].findIndex((v) => v === n);

                                  if (idx > -1) arrayHelpers.remove(idx);

                                  handleAvaiableCarriersChange(key, index, false);
                                }
                              }}
                            />
                          )}
                          label={option.description}
                        />
                      ) : (
                        <ReadOnlyCheckbox
                          name={option.type}
                          label={option.description}
                          value={option.checked}
                        />
                      )}
                    </Grid>
                  ))}
                </>
              )}
            </FieldArray>
            <ErrorMessage name={`${AVAILABLE_CARRIERS_FIELD_NAME}.${key}`} render={(msg) => <Typography className={classes.error}>{msg}</Typography>} />
          </Grid>
        </>
      ))}
    </Grid>
  );
};

CarrierTypes.defaultProps = {
  carriers: [],
  accountAvailableCarriers: [],
  isEditing: false,
};

CarrierTypes.propTypes = {
  carriers: PropTypes.arrayOf(
    PropTypes.shape({
      carrierCode: PropTypes.string.isRequired,
      displayName: PropTypes.string.isRequired,
      isDomestic: PropTypes.bool.isRequired,
      isInternational: PropTypes.bool.isRequired,
      isHazmat: PropTypes.bool.isRequired,
    }),
  ),
  accountAvailableCarriers: PropTypes.shape({
    domestic: PropTypes.arrayOf(PropTypes.string),
    international: PropTypes.arrayOf(PropTypes.string),
    hazmat: PropTypes.arrayOf(PropTypes.string),
  }),
  isEditing: PropTypes.bool,
};

export default memo(CarrierTypes);
