import 'date-fns';
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import isString from 'lodash/isString';
import isArray from 'lodash/isArray';
import get from 'lodash/get';
import {
  CircularProgress, Grid, Typography, Divider, TextField, Dialog, IconButton, DialogTitle, DialogContent, DialogActions,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import NewOrderNextButton from './common/NewOrderNextButton';
import * as colors from '../styles/colors';
import { useSingleOrderState } from '../context/singleOrderContext';
import { NEW_ORDER_SHIPPING_DETAILS_STYLE } from '../styles/style';
import { loadCarrierOptions, loadFinalRates } from '../utils/miscClient';
import CarrierRatesTable from './CarrierRatesTable';
import NewOrderErrorDialog from './common/NewOrderErrorDialog';
import { formatMonetaryAmount, isESGAdmin } from '../utils/helpers';
import StyledCheckbox from './common/StyledCheckbox';
import PanelWarning from './common/PanelWarning';
import AddressRatesTable from './AddressRatesTable';
import ShowMore from './common/ShowMore';
import CustomFreightOptions from './CustomFreightOptions';
import { FREIGHT_TYPES } from './ItemProductDetails/itemDetailsConstants';
import { useUserState } from '../context/userContext';
import { CUSTOM_FREIGHT_OPTION } from '../clientConstants';

/**
 * ##################################
 *          GLOBAL VARIABLES
 * ##################################
 */

const styles = {
  formContainer: {
    padding: '0px 8px',
  },
  datePicker: {
    color: colors.white,
  },
  root: {
    color: colors.white,
    width: '100%',
  },
  formHeader: {
    fontSize: 18,
    letterSpacing: 0.23,
    color: colors.white,
  },
  priceBreakdownContainer: {
    padding: '4px',
  },
  priceBreakdownAmount: {
    fontWeight: 900,
    letterSpacing: 0.5,
    color: colors.white,
    fontSize: 16,
    textAlign: 'right',
  },
  priceBreakdownLabel: {
    color: colors.white,
    fontWeight: 500,
    fontSize: 14,
    letterSpacing: 0.44,
  },
  priceBreakdownTotalLabel: {
    color: colors.white,
    fontWeight: 500,
    fontSize: 14,
    letterSpacing: 0.44,
    paddingTop: 4,
  },
  priceBreakdownDivider: {
    width: '40%',
  },
  totalPriceAmount: {
    color: '#4CA8F7',
    fontSize: 24,
    letterSpacing: 0.3,
    fontWeight: 500,
    textAlign: 'right',
  },
  shippingExtraCostLabel: {
    fontSize: 16,
    letterSpacing: 0.5,
    textAlign: 'right',
    color: colors.white,
    textDecoration: 'line-through',
  },
  shippingExtraDescriptionLabel: {
    color: '#B5B9CC',
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.44,
    paddingTop: 2,
    textTransform: 'capitalize',
  },
  shippingExtraSavingsLabel: {
    color: colors.textGreen,
    fontSize: 16,
    letterSpacing: 0.38,
    fontWeight: 900,
  },
  labelContainer: {
    width: 350,
  },
  specialInstructionsField: {},
  arrow: {
    color: colors.lightBackgroundGrey,
  },
  datePickerTextFieldRoot: {
    color: 'white',
    '&$disabled': {
      color: 'white',
    },
    '&>div:nth-of-type(2)': {
      '&>button:first-of-type': {
        color: colors.textLightGrey,
      },
    },
  },
  miscLoadingContainer: {
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingTop: 24,
  },
  dialogRoot: {
    marginTop: '30px',
  },
  dialogPaper: {
    background: colors.newOrderFormBackground,
    borderRadius: 8,
    overflow: 'hidden',
    maxWidth: 'none',
    zIndex: 3000,
  },
  dialogInnerContainer: {
    minHeight: '20vh',
    maxHeight: '90vh',
    width: '50vw',
    background: colors.newOrderFormBackground,
    padding: '5px 25px 35px 25px',
    borderRadius: 8,
    position: 'relative',
  },
  closeIcon: {
    position: 'absolute',
    top: 10,
    right: 10,
  },
  buttonsContainer: {
    width: 'calc(50% + 57px)',
    margin: '0px 0px',
  },
};

const useStyles = makeStyles(styles);

function hasValidSender(order, shipmentId) {
  return (
    order.shipments[shipmentId]
          && order.shipments[shipmentId].origin
          && order.shipments[shipmentId].origin.address
  );
}

function hasValidRecipient(order, shipmentId) {
  return (
    (order.shipments[shipmentId]
      && order.shipments[shipmentId].destination
      && (order.shipments[shipmentId].destination.address
    || (order.shipments[shipmentId].destination.addresses && order.shipments[shipmentId].destination.addresses.length > 0)))
  );
}

function hasValidPackaging(order, shipmentId) {
  return (
    order.shipments[shipmentId]
          && order.shipments[shipmentId].packaging
          && order.shipments[shipmentId].packaging.details
          && order.shipments[shipmentId].packaging.details.packages
  );
}
const getCarrierDiscrepency = (rate = {}) => rate.isMismatchedCarrierPackaging;
/**
 * ##################################
 *          CUSTOM COMPONENTS
 * ##################################
 */

const StyledRadio = withStyles({
  root: {
    '&$disabled': {
      color: colors.textDarkGrey,
    },
    color: colors.white,
  },
  disabled: {},
})(Radio);

const StyledDivider = withStyles({
  root: {
    backgroundColor: '#212435',
    height: 1,
  },
})(Divider);
const StyledKeyboardDatePicker = withStyles({
  root: {
    color: colors.white,
    '&$InputBase': { color: colors.white },
  },

})(KeyboardDatePicker);

function ShippingExtras(props) {
  const classes = useStyles();
  const {
    shippingExtras,
    setSelectedShippingExtras,
    selectedShippingExtras,
    selectedRate,
    currentRate,
    isCustomFreight,
    esgAdmin,
    carrierTaskStatus,
    disabled,
  } = props;
  // When the user selects a different option from the
  // carrier rates table, reset.
  const specialShippingExtras = {
    signatureRequired: {
      options: ['direct', 'indirect', 'adult'],
      defaultOption: 'direct',
    },
  };

  const handleChange = (selectedCode, selectedValue) => {
    setSelectedShippingExtras((currState) => {
      const newValue = selectedValue
      || currState[selectedCode]
      || (specialShippingExtras[selectedCode] && specialShippingExtras[selectedCode].defaultOption)
      || true;
      const newSelectedShippingExtras = { ...currState };

      if (newValue === currState[selectedCode]) {
        delete newSelectedShippingExtras[selectedCode];
        return newSelectedShippingExtras;
      }
      newSelectedShippingExtras[selectedCode] = newValue;
      return newSelectedShippingExtras;
    });
    // Make call to load final rate breakdown
  };

  const isSelected = (shippingExtraCode) => {
    if (isCustomFreight && carrierTaskStatus === 'Completed') {
      return shippingExtras.findIndex((item) => item.code === shippingExtraCode) > -1;
    }

    return !!selectedShippingExtras[shippingExtraCode];
  };

  const isHoldAtLocation = (shippingExtra) => shippingExtra === 'holdAtLocation';

  const getMonetaryAmount = (currencyCode, price) => {
    if (selectedRate.carrierName === CUSTOM_FREIGHT_OPTION && price && isString(price)
    && parseInt(price, 10) === 0) return '';

    return formatMonetaryAmount(currencyCode, price);
  };

  return (
    <FormControl className={classes.root}>
      <FormLabel className={classes.formHeader}>Shipping Extras</FormLabel>
      {shippingExtras && isArray(shippingExtras) && shippingExtras.map((shippingExtra, i) => {
        if (isHoldAtLocation(shippingExtra.code)) {
          // This component and data is not ready yet
          // TODO: Return a select component with possible locations
        } else {
          return (
            <Grid container spacing={3} alignItems="center" key={i}>
              <Grid item>
                <FormGroup>
                  <FormControlLabel
                    key={shippingExtra.key}
                    control={(
                      <StyledCheckbox
                        disabled={disabled}
                        checked={isSelected(shippingExtra.code)}
                        onChange={() => handleChange(shippingExtra.code)}
                        value={shippingExtra}
                      />
                )}
                    label={(
                      <Grid
                        className={classes.labelContainer}
                        spacing={3}
                        container
                      >
                        <Grid item xs={6}>
                          <Typography
                            className={classes.shippingExtraDescriptionLabel}
                          >
                            {shippingExtra.description}
                          </Typography>
                        </Grid>
                        {/* <Grid item xs={3}>
                          <Typography className={classes.shippingExtraCostLabel}>
                            {getMonetaryAmount(shippingExtra.currency, shippingExtra.listPrice)}
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography
                            className={classes.shippingExtraSavingsLabel}
                          >
                            {getMonetaryAmount(shippingExtra.currency,
                              Number(shippingExtra.myPrice || shippingExtra.listPrice).toFixed(2))}
                          </Typography>
                        </Grid> */}
                      </Grid>
                )}
                  />
                </FormGroup>
              </Grid>
              <Grid item>
                {specialShippingExtras[shippingExtra.code]
                  && selectedShippingExtras[shippingExtra.code]
                  && (
                  <RadioGroup
                    aria-label={shippingExtra.code}
                    name={shippingExtra.code}
                    value={selectedShippingExtras[shippingExtra.code]}
                    onClick={(e) => handleChange(shippingExtra.code, e.target.value)}
                    row
                  >
                    {
                      specialShippingExtras[shippingExtra.code].options.map((option, index) => (
                        <FormControlLabel
                          className={classes.shippingExtraDescriptionLabel}
                          value={option}
                          control={<StyledRadio disabled={disabled} />}
                          key={index}
                          label={(
                            <Typography className={classes.shippingExtraDescriptionLabel}>
                              {option}
                            </Typography>
                          )}
                        />
                      ))
                    }
                  </RadioGroup>
                  )}
              </Grid>
            </Grid>
          );
        }
      })}
    </FormControl>
  );
}

ShippingExtras.propTypes = {
  setSelectedShippingExtras: PropTypes.func.isRequired,
  selectedRate: PropTypes.shape({
    carrierName: PropTypes.string,
    serviceName: PropTypes.string,
    serviceCode: PropTypes.string,
    listPrice: PropTypes.string,
    myPrice: PropTypes.string,
    currencyCode: PropTypes.string,
    itemizedCharges: PropTypes.array,
    shippingExtras: PropTypes.array,
  }).isRequired,
  shippingExtras: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.exact({
      code: PropTypes.string,
      myPrice: PropTypes.string,
      listPrice: PropTypes.string,
      currency: PropTypes.string,
      description: PropTypes.string,
    }),
  ]).isRequired,
  disabled: PropTypes.bool.isRequired,
};
const isCurrenRateCustomFreight = (currentRate, shipment) => currentRate && shipment.carrier && shipment.freightType === FREIGHT_TYPES.freight
  && currentRate.carrierName === CUSTOM_FREIGHT_OPTION;

const isSelectedRateCustomFreight = (selectedRate) => selectedRate
&& selectedRate.carrierName === CUSTOM_FREIGHT_OPTION && selectedRate.serviceCode === 'ST01201';

/**
 * #################################
 *          EXPORT FUNCTION
 * #################################
 */

export default function ShippingDetailsForm({
  openNextPanel,
  shipmentId, submitFormToApi,
}) {
  const classes = useStyles();
  const orderState = useSingleOrderState();
  const shipment = (orderState.shipments && orderState.shipments[shipmentId]);
  const currentRate = (
    shipment
    && shipment.carrier
    && shipment.carrier.details
  ) || {};

  function findRowMatch(rowArray, rate) {
    if (!rate) return null;
    const match = rowArray.find((row) => row.carrierName === rate.carrierName
       && row.serviceCode === rate.serviceCode);
    return match ? match.id : null;
  }

  const [isSubmitting, setSubmitting] = React.useState(false);
  const [rates, setRates] = React.useState([]);
  const [selectedRate, setSelectedRate] = React.useState({});
  const [selectedShippingExtras, setSelectedShippingExtras] = React.useState(currentRate.shippingExtras || {});
  const [finalRate, setFinalRate] = React.useState('');
  const [expectedShipDate, setExpectedShipDate] = React.useState(new Date());
  const [shippingExtras, setShippingExtras] = React.useState(null);
  const [customFreightServices, setCustomFreightServices] = React.useState([]);
  const [isError, setIsError] = React.useState(false);
  const [errorContent, setErrorContent] = React.useState(null);
  const [ratesLoading, setRatesLoading] = React.useState(false);
  const [finalPriceLoading, setFinalPriceLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const user = useUserState();
  const esgAdmin = isESGAdmin(user.userType);
  const carrierTaskStatus = get(shipment, 'carrier.task.status', '');
  const isCustomFreight = isCurrenRateCustomFreight(currentRate, shipment) || isSelectedRateCustomFreight(selectedRate);
  const showCustomFreightOptions = isCustomFreight && shipment.carrier
  && carrierTaskStatus === 'In-Progress' && currentRate.serviceCode === 'ST01201'
  && esgAdmin;

  const changeSelectedRate = (rate) => {
    setSelectedRate(rate);
    setShippingExtras(rate.shippingExtras || null);
    setSelectedShippingExtras({});
    if (!rate || !Object.keys(rate).length) {
      setFinalRate('');
    }
  };
  const toggleOpen = () => {
    setOpen(!open);
  };
  React.useEffect(() => {
    if (
      hasValidSender(orderState, shipmentId)
          && hasValidRecipient(orderState, shipmentId)
          && hasValidPackaging(orderState, shipmentId)
    ) {
      setRatesLoading(true);
      loadCarrierOptions(shipmentId, moment(expectedShipDate)
        .format('YYYY-MM-DD'))
        .then(
          (fetchedRates) => {
            setRates(fetchedRates);
            setRatesLoading(false);
            const rateMatchId = findRowMatch(fetchedRates, currentRate);
            const rateMatch = fetchedRates.find((r) => r.id === rateMatchId) || {};
            const newShippingExtras = rateMatch.shippingExtras;
            setShippingExtras(newShippingExtras);
            setSelectedRate(rateMatch);
            if (fetchedRates[0]?.results?.filter((r) => r?.isSuccess === false)?.length > 0) {
              toggleOpen();
            }
          },
        ).catch((err) => {
          setIsError(true);
          setRatesLoading(false);
          setErrorContent(err.message);
        });
    }
  }, [expectedShipDate, orderState, shipmentId]);

  React.useEffect(() => {
    const { serviceCode } = selectedRate;
    const { carrierName } = selectedRate;
    if (serviceCode && carrierName) {
      setFinalPriceLoading(true);
      loadFinalRates(shipmentId, {
        serviceCode,
        carrierName,
        shippingExtras: selectedShippingExtras,
        expectedShipDate: moment(expectedShipDate).format('YYYY-MM-DD'),
      })
        .then(setFinalRate)
        .catch((err) => {
          setIsError(true);
          setErrorContent(err.message);
        }).finally(() => {
          setFinalPriceLoading(false);
        });
    }
  }, [expectedShipDate, selectedRate, selectedShippingExtras, shipmentId]);

  const handleCustomFreightChange = (values, hasError) => {
    if (!hasError) { setCustomFreightServices(values); }
  };

  async function handleSubmit() {
    setSubmitting(true);

    if (!selectedRate) {
      return alert('Must select a shipping rate to continue');
    }

    let carrierDetails;

    if (showCustomFreightOptions) {
      carrierDetails = {
        carrierName: selectedRate.carrierName,
        freightServices: customFreightServices,
      };
    } else {
      carrierDetails = {
        serviceCode: selectedRate.serviceCode,
        carrierName: selectedRate.carrierName,
        expectedShipDate: moment(expectedShipDate)
          .format('YYYY-MM-DD'),
        myPrice: selectedRate.myPrice,
        shippingExtras: {
          residentialDelivery: false,
          pickup: false,
          holdAtLocation: false,
          ...selectedShippingExtras,
        },
      };
    }
    try {
      await submitFormToApi({
        details: carrierDetails,
      });

      if (!isCustomFreight || (isCustomFreight && carrierTaskStatus === 'Completed')) openNextPanel();
    } catch (e) {
      console.error(e);
    }
    setSubmitting(false);
    return null;
  }

  return (
    <>
      <Grid
        container
        direction="column"
        spacing={2}
        className={classes.formContainer}
      >
        <Grid container direction="column" item spacing={1} style={{ padding: 0 }}>
          {showCustomFreightOptions && (
          <Grid item>
            <CustomFreightOptions onChange={handleCustomFreightChange} />
          </Grid>
          )}
          <Grid item>
            <Typography className={classes.formHeader}>
              Expected Ship Date
            </Typography>
          </Grid>
          <Grid item>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <StyledKeyboardDatePicker
                disableToolbar
                variant="inline"
                margin="normal"
                disabled={isSubmitting}
                value={expectedShipDate}
                onChange={(date) => {
                  setSelectedRate({});
                  setExpectedShipDate(date);
                }}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                disablePast="true"
                minDateMessage="Selected date cannot be before today's date"
                rightArrowButtonProps={{ classes: { root: classes.arrow } }}
                leftArrowButtonProps={{ classes: { root: classes.arrow } }}
                format="dd MMM yyyy"
                autoOk
                TextFieldComponent={(tfProps) => (
                  <TextField
                    {...tfProps}
                    disabled
                    InputProps={{
                      ...(tfProps.InputProps || {}),
                      classes: {
                        root: classes.datePickerTextFieldRoot,
                        disabled: classes.datePickerTextFieldRoot,
                      },
                    }}
                  />
                )}
                data-testid="expected-ship-date-picker"
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item>
            <Typography className={classes.formHeader}>
              Shipping Rates
            </Typography>
          </Grid>
          <Grid item>
            <CarrierRatesTable
              rows={rates}
              changeSelectedRate={changeSelectedRate}
              currentRate={currentRate}
              disabled={isSubmitting}
              ratesLoading={ratesLoading}
            />
          </Grid>
        </Grid>
        <Grid item>
          {shippingExtras && (
            <ShippingExtras
              shippingExtras={shippingExtras}
              setSelectedShippingExtras={setSelectedShippingExtras}
              selectedShippingExtras={selectedShippingExtras}
              selectedRate={selectedRate}
              isCustomFreight={isCustomFreight}
              esgAdmin={esgAdmin}
              carrierTaskStatus={carrierTaskStatus}
              disabled={isSubmitting}
            />
          )}
        </Grid>
        {finalPriceLoading && (
          <CircularProgress style={{ margin: '10px 0' }} color="secondary" />
        )}
        {!!Object.keys(selectedRate).length
          && finalRate
          && finalRate.itemizedCharges
          && !finalPriceLoading && (
            <Grid item container direction="column" spacing={1}>
              <Grid item>
                <StyledDivider />
              </Grid>
              <Grid item>
                <Typography className={classes.formHeader}>
                  Your Price
                </Typography>
              </Grid>
              <ShowMore buttonGridWidth={4}>
                {finalRate.itemizedCharges.map((charge) => (
                  <Grid className={classes.priceBreakdownContainer} item container key={`${charge.description}-container`}>
                    <Grid item xs={2}>
                      <Typography className={classes.priceBreakdownLabel}>
                        {`${charge.description}`}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography className={classes.priceBreakdownAmount}>
                        {`${formatMonetaryAmount(
                          charge.currencyCode,
                          charge.price,
                        )}`}
                      </Typography>
                    </Grid>
                  </Grid>
                ))}
              </ShowMore>
              <Grid item container direction="column">
                <Grid item>
                  <StyledDivider className={classes.priceBreakdownDivider} />
                </Grid>
                <Grid item container>
                  <Grid item xs={2}>
                    <Typography className={classes.priceBreakdownTotalLabel}>
                      Estimated Total
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography className={classes.totalPriceAmount}>
                      {`${formatMonetaryAmount(
                        finalRate.currencyCode,
                        finalRate.myPrice || finalRate.listPrice,
                      )}`}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
        )}
        {getCarrierDiscrepency(finalRate || {}) && (
          <Grid item>
            <PanelWarning message="Shipping selection does not match packaging provider selected in the Packaging/Contents panel" />
          </Grid>
        )}
        <Grid item>
          <NewOrderNextButton
            onClick={() => handleSubmit({ selectedRate, selectedShippingExtras })}
            disabled={isSubmitting || finalPriceLoading || isEmpty(selectedRate) || (showCustomFreightOptions && isEmpty(customFreightServices))}
          >
            Next
          </NewOrderNextButton>
        </Grid>
        {isSubmitting ? (
          <Grid item classes={{ root: classes.miscLoadingContainer }}>
            <CircularProgress color="secondary" />
          </Grid>
        ) : null}
      </Grid>
      <Dialog
        open={open}
        onClose={(e, reason) => {
          toggleOpen();
        }}
        color="primary"
        classes={{
          paper: classes.dialogPaper,
          root: classes.dialogRoot,
        }}
      >
        <DialogTitle> Alert</DialogTitle>
        <IconButton
          className={classes.closeIcon}
          onClick={() => setOpen(false)}
        >
          <CloseIcon color="primary" />
        </IconButton>
        <DialogContent>
          <Grid
            container
            direction="column"
            className={classes.dialogInnerContainer}
            spacing={1}
          >
            <Typography>
              {`Following ${
                rates[0]?.results?.filter((r) => r?.isSuccess === false).length
              } of ${
                rates[0]?.results?.length
              } addresses failed to validate with the carrier and chosen
              service and the package will not be shipped to these addresses. Please press
              OK to acknowledge`}
            </Typography>

            <AddressRatesTable
              rows={rates[0]?.results?.filter((r) => r?.isSuccess === false)}
              changeSelectedRate={changeSelectedRate}
              currentRate={currentRate}
              disabled={isSubmitting}
              ratesLoading={ratesLoading}
            />
          </Grid>
          <DialogActions>
            <Grid
              item
              container
              justify="space-between"
              className={classes.buttonsContainer}
            >
              <NewOrderNextButton
                disabled={isSubmitting}
                className={classes.submitButton}
                onClick={toggleOpen}
              >
                OK
              </NewOrderNextButton>
            </Grid>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <NewOrderErrorDialog
        open={isError}
        errorContent={errorContent}
        classes={NEW_ORDER_SHIPPING_DETAILS_STYLE()}
        onClose={() => {
          setIsError(false);
          setErrorContent(null);
        }}
      />
    </>
  );
}

ShippingDetailsForm.propTypes = {
  shipmentId: PropTypes.string.isRequired,
};
