import React from 'react';
import has from 'lodash/has';
import taskClient from '../utils/taskClient';
import { useLogger } from '../utils/hooks';
import { internalUpdateShipment } from './singleOrderContext';

const taskState = { tasks: null };
const TaskStateContext = React.createContext();
const TaskDispatchContext = React.createContext();

// taskReducer Action Types
const LOAD_TASKS = 'LOAD TASKS';
const UPDATE_TASKS = 'UPDATE TASKS';
const REFRESH_TASKS = 'REFRESH TASKS';

function taskReducer(state, action) {
  switch (action.type) {
    case LOAD_TASKS: {
      return {
        ...state,
        tasks: action.options && state.tasks && action.isScrolling
          ? state.tasks.concat(action.tasks)
          : action.tasks,
      };
    }
    case UPDATE_TASKS: {
      return {
        ...state,
        tasks: action.tasks,
      };
    }
    case REFRESH_TASKS: {
      return {
        ...state,
        tasks: action.tasks,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function TaskDataProvider(props) {
  const [state, dispatch] = useLogger(
    React.useReducer(taskReducer, taskState || { tasks: null }),
  );

  return (
    <TaskStateContext.Provider value={state} {...props}>
      <TaskDispatchContext.Provider
        value={dispatch}
        {...props}
      />
    </TaskStateContext.Provider>
  );
}

function loadTasks({ options, dispatch }) {
  return taskClient.loadAllTasks(options).then((data) => {
    dispatch({
      type: LOAD_TASKS, tasks: data, options,
    });
    return data;
  });
}
function updateTasks({ options, dispatch }) {
  return taskClient.loadAllTasks(options).then((data) => {
    dispatch({
      type: UPDATE_TASKS, tasks: data, options,
    });
    return data;
  });
}

function useTaskState() {
  const context = React.useContext(TaskStateContext);
  if (context === undefined) {
    throw new Error('useTaskState must be used within a TaskDataProvider');
  }
  return context;
}

function useTaskDispatch() {
  const context = React.useContext(TaskDispatchContext);
  if (context === undefined) {
    throw new Error('useTaskDispatch must be used within a TaskDataProvider');
  }
  return context;
}

function refreshTasks({ tasks, dispatch }) {
  dispatch({ type: REFRESH_TASKS, tasks });

  return tasks;
}

async function revokeTask({ shipmentId, taskType, dispatch }) {
  const revokeResponse = await taskClient.revokeTask({ shipmentId, taskType });

  try {
    if (!has(revokeResponse, 'error')) {
      internalUpdateShipment(revokeResponse, dispatch, shipmentId);
    } else throw new Error(revokeResponse.error);
  } catch (err) {
    console.log(err.message || err.error || err);
  }
  return revokeResponse;
}

async function assignTask({
  dispatch, shipmentId, taskType, expiresAt, email, sendInvite,
}) {
  try {
    const assignResponse = await taskClient.assignTask({
      shipmentId, taskType, expiresAt, email, sendInvite,
    });
    try {
      if (!assignResponse.sendInvite && !sendInvite) {
        internalUpdateShipment(assignResponse, dispatch, shipmentId);
      }
    } catch (err) {
      throw new Error(err.message || err);
    }
    return assignResponse;
  } catch (err) {
    throw new Error(err.error || err.message || err);
  }
}

async function assignDocTask({ dispatch, ...data }) {
  try {
    const assignResponse = await taskClient.assignDocTask({ ...data });
    try {
      if (!assignResponse.sendInvite) {
        internalUpdateShipment(assignResponse, dispatch, data.shipmentId);
      }
    } catch (err) {
      throw new Error(err.message || err);
    }
    return assignResponse;
  } catch (err) {
    throw new Error(err.error || err.message || err);
  }
}

async function revokeDocTask({ dispatch, ...data }) {
  try {
    const assignResponse = await taskClient.revokeDocTask({ ...data });
    try {
      internalUpdateShipment(assignResponse, dispatch, data.shipmentId);
    } catch (err) {
      throw new Error(err.message || err);
    }
    return assignResponse;
  } catch (err) {
    throw new Error(err.error || err.message || err);
  }
}

export {
  TaskDataProvider,
  useTaskState,
  useTaskDispatch,
  loadTasks,
  updateTasks,
  refreshTasks,
  revokeTask,
  assignTask,
  assignDocTask,
  revokeDocTask,
};
