import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Grid, Slider, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { css } from 'emotion';
import * as colors from '../../../styles/colors';

const useStyles = makeStyles(() => ({
  markLabel: {
    color: colors.white,
  },
  markLabelActive: {
    color: colors.white,
  },
  thumb: {
    backgroundColor: '#62A8F1',
    boxShadow: '0px 0px 5px 0px #000',
    ':focus': {
      boxShadow: '0px 0px 5px 0px #000',
    },
    ':hover': {
      boxShadow: '0px 0px 5px 0px #000',
    },
    ':active': {
      boxShadow: '0px 0px 5px 0px #000',
    },
    border: `1px solid ${colors.white}`,
    height: 18,
    width: 18,
    marginTop: -7,
    marginLeft: -9,
  },
  valueLabel: {
    left: 'calc(-50%)',
    top: -25,
    whiteSpace: 'nowrap',
    '& *': {
      background: 'transparent',
      color: colors.white,
      fontWeight: 700,
      fontSize: 14,
    },
  },
  track: {
    height: 3,
    backgroundColor: '#94CCFA',
    border: `1px solid ${colors.white}`,
  },
  rail: {
    height: 3,
    opacity: 1,
    backgroundColor: '#B5B9CC',
    border: `1px solid ${colors.white}`,
    borderRadius: 4,
  },
  mark: {
    backgroundColor: '#bfbfbf',
    height: 8,
    width: 1,
    marginTop: -3,
    display: 'none',
  },
  markActive: {
    opacity: 1,
    backgroundColor: 'currentColor',
    display: 'none',
  },
  trackerLabel: {
    color: colors.white,
    fontSize: 12,
    position: 'relative',
  },
  trackerLabelLeft: {
    left: -5,
  },
  trackerLabelRight: {
    right: -5,
  },
  trackerWrap: {
    padding: '0 5px',
  },
  limitMarks: {
    margin: '5px 0 0 0',
  },
}));

function ControlledSlider(props) {
  const classes = useStyles();
  const {
    field, form, min = 0, max = 100,
    valueLabelFormat, storeValueAsString = true,
    changeValueOnCommit = true,
    onChange,
  } = props;
  const { setFieldValue, setTouched } = form;
  const { name, value } = field;

  const sliderClasses = {
    markLabel: css({
      color: colors.white,
    }),
    markLabelActive: css({
      color: colors.white,
    }),
    thumb: css({
      backgroundColor: '#62A8F1',
      boxShadow: '0px 0px 5px 0px #000',
      ':focus': {
        boxShadow: '0px 0px 5px 0px #000',
      },
      ':hover': {
        boxShadow: '0px 0px 5px 0px #000',
      },
      ':active': {
        boxShadow: '0px 0px 5px 0px #000',
      },
      border: `1px solid ${colors.white}`,
      height: 18,
      width: 18,
      marginTop: -7,
      marginLeft: -9,
    }),
    active: css({}),
    valueLabel: css({
      left: 'calc(-50%)',
      top: -25,
      whiteSpace: 'nowrap',
      '& *': {
        background: 'transparent',
        color: colors.white,
        fontWeight: 700,
        fontSize: 14,
      },
    }),
    track: css({
      height: 3,
      backgroundColor: '#94CCFA',
      border: `1px solid ${colors.white}`,
    }),
    rail: css({
      height: 3,
      opacity: 1,
      backgroundColor: '#B5B9CC',
      border: `1px solid ${colors.white}`,
      borderRadius: 4,
    }),
    mark: css({
      backgroundColor: '#bfbfbf',
      height: 8,
      width: 1,
      marginTop: -3,
      display: 'none',
    }),
    markActive: css({
      opacity: 1,
      backgroundColor: 'currentColor',
      display: 'none',
    }),
  };

  React.useEffect(() => {
    onChange(null, value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isRange = Array.isArray(value);

  const localOnChange = (e, v) => {
    if (storeValueAsString) {
      if (isRange) {
        setFieldValue(name, v.map((el, i) => `${el}`));
        return;
      }
      setFieldValue(name, `${v}`);
      return;
    }
    setFieldValue(name, v);
  };

  return (
    <Grid container direction="column" className={classes.trackerWrap}>
      <Slider
        classes={sliderClasses}
        color="primary"
        onChangeCommitted={changeValueOnCommit ? localOnChange : undefined}
        defaultValue={value}
        onBlur={(e) => setTouched(e)}
        aria-labelledby="range-slider"
        valueLabelDisplay="on"
        {...props}
        onChange={changeValueOnCommit ? onChange : localOnChange}
        valueLabelFormat={(v) => {
          if (isRange) {
            if (v < min) {
              return '';
            }
            if (v > max) {
              return '';
            }
          }

          if (typeof valueLabelFormat === 'function') {
            return valueLabelFormat(v);
          }
          return v;
        }}
        min={isRange ? min - 1 : min}
        max={isRange ? max + 1 : max}
      />
      <Grid item container direction="row" justify="space-between" className={classes.limitMarks}>
        <Typography className={`${classes.trackerLabel} ${classes.trackerLabelLeft}`}>
          {isRange
            ? 'No min'
            : valueLabelFormat(min)}
        </Typography>
        <Typography className={`${classes.trackerLabel} ${classes.trackerLabelRight}`}>
          {isRange
            ? 'No max'
            : valueLabelFormat(max)}
        </Typography>
      </Grid>
    </Grid>
  );
}

ControlledSlider.defaultProps = {
  storeValueAsString: true,
  changeValueOnCommit: true,
  valueLabelFormat: () => {},
  min: 0,
  max: 100,
  onChange: () => {},
};

ControlledSlider.propTypes = {
  field: PropTypes.objectOf(PropTypes.any).isRequired,
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  storeValueAsString: PropTypes.bool,
  changeValueOnCommit: PropTypes.bool,
  valueLabelFormat: PropTypes.func,
  min: PropTypes.number,
  max: PropTypes.number,
  onChange: PropTypes.func,
};

export default memo(ControlledSlider);
