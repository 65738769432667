import React from 'react';
import {
  Grid, Popover, Typography, Table, TableRow, TableCell, TableHead, TableBody, LinearProgress, Tab, Tabs,
  Box,
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import OutlinedTruckIcon from '@material-ui/icons/LocalShippingOutlined';
import iotIcon from './images/rss.svg';
import * as colors from '../styles/colors';
import { loadTrackingInfo, loadTrackInfoWithTrackNo, loadShippingOrIotTrackingInfo } from '../utils/trackingClient';
import { formatDateLong } from '../utils/helpers';
import { SHIPMENT_TRACKING_TAB, IOT_TRACKING_TAB } from '../clientConstants';

const useStyles = makeStyles(() => ({
  trackingModal: {
    background: '#393c4b',
    width: 800,
    minHeight: 100,
    padding: '10px 20px',
    color: colors.white,
    borderRadius: '5px',
    border: `1px solid ${colors.newOrderFormBackground}`,
  },
  disclaimerText: {
    paddingBottom: 16,
    fontStyle: 'italic',
    letterSpacing: 0.15,
  },
  trackingModalPaper: {
    background: colors.newOrderFormBackground,
    borderRadius: '5px',
  },
  trackingModalHeader: {
    padding: '5px 0 15px 0',
  },
  trackingHistory: {
    background: colors.shipmentCardBackground,
    width: '100%',
    color: colors.white,
    padding: '0 20px',
  },
  table: {
    color: colors.white,
  },
  tableHeaderCell: {
    color: colors.white,
    fontWeight: 500,
    fontSize: 16,
    borderBottom: '1px solid #444655',
    padding: '15px 0',
  },
  tableBodyCell: {
    color: colors.white,
    borderBottom: '1px solid #444655',
    padding: '10px 0',
  },
  special: {
    color: colors.textLightGrey,
    fontWeight: 500,
  },
  header: {
    fontWeight: 500,
  },
  tableDateDisplay: {
    color: colors.white,
  },
  updatedAt: {
    fontSize: 12,
    fontWeight: 500,
    paddingBottom: 15,
  },
  noShippingHistory: {
    color: colors.white,
  },
  linearProgressWrap: {
    width: '100%',
  },
}));

function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const StyledTabs = withStyles({
  indicator: {
    backgroundColor: colors.lightSkyBlue,
  },
})(Tabs);

const StyledTab = withStyles({
  root: {
    color: '#afb0b5',
    fontWeight: 500,
    fontSize: 16,
    lineSpacing: 0.15,
    textTransform: 'none',
  },
  selected: {
    color: colors.white,
  },
  disabled: {
    color: colors.white,
  },
})(Tab);

export default function TrackingModal(props) {
  const classes = useStyles();
  const {
    id, handleClose, trackingModalState, setCurrentTab, currentTab, shippingDocumentsPanel,
  } = props;
  const {
    anchorEl, trackingNumber, shipmentId, type = null, iotTrackingNumber,
  } = trackingModalState;
  const columns = [
    {
      label: 'Status', id: 'activity', headerClass: 'tableHeaderCell', bodyClass: ['tableBodyCell'], width: '45%',
    },
    {
      label: 'Date/Time', id: 'dateTime', headerClass: 'tableHeaderCell', bodyClass: ['tableDateDisplay', 'tableBodyCell'], width: '32%', transform: (dt) => (dt ? formatDateLong(dt) : ''),
    },
    {
      label: 'Location', id: 'location', headerClass: 'tableHeaderCell', bodyClass: ['tableBodyCell'], width: '23%',
    }];

  const [trackingHistory, setTrackingHistory] = React.useState([]);
  const [iotTrackingHistory, setIotTrackingHistory] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [verticalPosition, setVerticalPosition] = React.useState(0);
  const [horizontalPosition, setHorizontalPosition] = React.useState(0);
  const modalRef = React.useRef();
  const popoverRef = React.useRef();

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const handleCloseAndRemoveData = () => {
    setIotTrackingHistory([]);
    setTrackingHistory([]);
    handleClose();
  };

  async function loadTrackingHistoryByType() {
    setLoading(true);

    const apiSelector = {
      document: loadTrackInfoWithTrackNo,
    };

    let res = null;
    try {
      res = await apiSelector[type](shipmentId, trackingNumber);
    } catch (e) {
      console.log('Error loading tracking information');
      console.error(e);
    }

    if (!res
      || !('packages' in res) || (Array.isArray(res.packages) && res.packages.length < 1)) {
      setLoading(false);
      return setTrackingHistory([]);
    }

    const formattedHistory = res.packages[0].tracking.map((historyItem, idx) => {
      const historyItemCopy = { ...historyItem };

      if (historyItemCopy.location) {
        historyItemCopy.location = [
          historyItemCopy.location.city,
          historyItemCopy.location.state,
          historyItemCopy.location.country]
          .filter((l) => l).join(', ');
      }

      historyItemCopy.key = `${res.shipmentTrackingNumber}-${idx}`;
      return historyItemCopy;
    });

    setLoading(false);
    setTrackingHistory(formattedHistory);
  }

  function concatenateCityStateCountry(city, state, country) {
    return [
      city,
      state,
      country,
    ].filter((l) => l).join(', ');
  }

  function formatIotTrackingHistory(unformattedIotTrackingHistory) {
    if (!unformattedIotTrackingHistory.tracking) {
      return [];
    }
    return unformattedIotTrackingHistory.tracking.map((historyItem, idx) => {
      const formattedLocation = (historyItem.location && historyItem.location.address)
        ? historyItem.location.address
        : concatenateCityStateCountry(historyItem.location.city, historyItem.location.state, historyItem.location.country);
      return {
        ...historyItem,
        location: formattedLocation,
        key: `${historyItem.dateTime}-${idx}`,
      };
    });
  }

  async function loadIotTrackingHistory(currentShipmentId, currentIotTrackingNumber) {
    setLoading(() => true);
    const trackingApiResponse = await loadShippingOrIotTrackingInfo(
      {
        shipmentId: currentShipmentId,
        trackingNumber: currentIotTrackingNumber,
        iot: true,
      },
    )
      .then((res) => {
        try {
          const packageTrackingInfo = res.packages[0];
          return packageTrackingInfo;
        } catch (err) {
          return null;
        }
      })
      .catch((err) => {
        console.log('Error loading IoT tracking information');
        return null;
      });

    const formattedHistory = formatIotTrackingHistory(trackingApiResponse);

    setLoading(() => false);
    return setIotTrackingHistory(formattedHistory);
  }

  async function loadTrackingHistory(id) {
    setLoading(() => true);
    const trackingApiResponse = await loadTrackingInfo(id)
      .then((res) => {
        try {
          const packageTrackingInfo = res.packages[0];
          return packageTrackingInfo;
        } catch (err) {
          return null;
        }
      })
      .catch((err) => {
        console.log('Error loading tracking information');
        return null;
      });

    const formattedHistory = trackingApiResponse
      ? trackingApiResponse.tracking
        .map((historyItem, idx) => {
          const historyItemCopy = { ...historyItem };
          if (historyItemCopy.location) {
            historyItemCopy.location = concatenateCityStateCountry(
              historyItemCopy.location.city,
              historyItemCopy.location.state,
              historyItemCopy.location.country,
            );
          }
          historyItemCopy.key = `${trackingApiResponse.shipmentTrackingNumber}-${idx}`;
          return historyItemCopy;
        })
      : [];

    setLoading(() => false);
    return setTrackingHistory(formattedHistory);
  }

  React.useEffect(() => {
    if (shipmentId && type) {
      loadTrackingHistoryByType();
      return;
    }
    if (shipmentId && trackingNumber && currentTab === SHIPMENT_TRACKING_TAB && !trackingHistory.length) {
      loadTrackingHistory(shipmentId);
    }
    if (shipmentId && iotTrackingNumber && currentTab === IOT_TRACKING_TAB && !iotTrackingHistory.length) {
      loadIotTrackingHistory(shipmentId, iotTrackingNumber);
    }
  }, [shipmentId, currentTab]);

  React.useEffect(() => {
    if (anchorEl) {
      getVerticalPosition(anchorEl, modalRef.current && modalRef.current.clientHeight);
      getHorizontalPosition(anchorEl);
    }
  }, [anchorEl]);

  React.useEffect(() => {
    const adjustment = (-(modalRef.current && modalRef.current.clientHeight) || 0);
    if (verticalPosition > (window.innerHeight + adjustment)) {
      setVerticalPosition((prev) => prev + adjustment);
    }
  }, [trackingHistory, iotTrackingHistory]);

  React.useEffect(() => {
    // Workaround for problem with tab indicator and resizing
    // For more info, see here: https://github.com/mui-org/material-ui/issues/9337
    window.dispatchEvent(new CustomEvent('resize'));
    // if (popoverRef.current) popoverRef.current.updatePosition();
  }, [verticalPosition]);

  const getVerticalPosition = (anchorEl, clientHeight = 0) => {
    const {
      offsetTop, parentElement, scrollTop, parentOffset,
    } = anchorEl;
    let totalOffsetTop = offsetTop;
    let totalScrollTop = scrollTop;
    let parent = parentElement;
    while (parent) {
      totalScrollTop += (parent.scrollTop || 0);
      parent = parent.parentElement;
    }
    let offsetParent = parentOffset;
    while (offsetParent) {
      totalOffsetTop += offsetParent.offsetTop || 0;
      offsetParent = offsetParent.parentOffset;
    }
    let totalVerticalOffset = totalOffsetTop - totalScrollTop + 64;
    if (totalVerticalOffset > window.innerHeight - clientHeight) {
      totalVerticalOffset += 1000;
    }
    setVerticalPosition(() => totalVerticalOffset);
  };
  const getHorizontalPosition = (anchorEl) => {
    const { offsetLeft, offsetParent, clientWidth } = anchorEl;
    let totalOffsetLeft = offsetLeft;
    let parent = offsetParent;
    while (parent) {
      totalOffsetLeft += parent.offsetLeft || 0;
      parent = parent.offsetParent;
    }
    totalOffsetLeft += clientWidth;
    setHorizontalPosition(totalOffsetLeft);
  };

  const getTrackingHistoryDisplay = (history) => {
    if (history.length) {
      return (
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  style={{ width: column.width }}
                  className={classes[column.headerClass]}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {history.map((item) => (
              <TableRow key={item.key}>
                {columns.map((column) => (
                  <TableCell className={column.bodyClass.map((bc) => classes[bc]).join(' ')} key={`${item.key}-${column.id}`}>
                    {column.transform ? column.transform(item[column.id]) : item[column.id]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      );
    }
    return (
      <Typography className={classes.noShippingHistory}>
        No shipping events to display
      </Typography>
    );
  };

  return (
    <Popover
      id={id}
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={handleCloseAndRemoveData}
      anchorReference="anchorPosition"
      anchorPosition={{
        top: verticalPosition,
        left: horizontalPosition,
      }}
      action={popoverRef}
      classes={{ paper: classes.trackingModalPaper }}
    >
      <Grid container className={classes.trackingModal} direction="column" ref={modalRef}>
        <Grid container item className={classes.trackingModalHeader} justify="space-between" alignItems="flex-end">
          <Grid item container style={{ width: 'auto' }}>
            <StyledTabs
              value={currentTab}
              onChange={handleTabChange}
              aria-label="shipment tracking tabs"
            >
              <StyledTab
                label={(
                  <div>
                    <OutlinedTruckIcon style={{ color: '#94CCFB', verticalAlign: 'middle', marginRight: 8 }} />
                    Shipping History
                  </div>
              )}
                disabled={!trackingNumber}
              />
              {iotTrackingNumber
              && (
              <StyledTab label={(
                <div>
                  <img
                    src={iotIcon}
                    style={{
                      width: '1.3rem', height: '1.3rem', color: '#94CCFB', verticalAlign: 'middle', marginRight: 8,
                    }}
                  />
                  IoT Tracking History
                </div>
              )}
              />
              )}
            </StyledTabs>
          </Grid>
          <Grid item>
            <Typography className={classes.updatedAt}>
              { currentTab === SHIPMENT_TRACKING_TAB
                ? (trackingHistory.length > 0 && trackingHistory[0].dateTime) && `Updated: ${formatDateLong(trackingHistory[0].dateTime)}`
                : (iotTrackingHistory.length > 0 && iotTrackingHistory[0].dateTime) && `Updated: ${formatDateLong(iotTrackingHistory[0].dateTime)}`}
            </Typography>
          </Grid>
        </Grid>
        { !shippingDocumentsPanel
        && (
        <Grid item>
          <Typography className={classes.disclaimerText}>
            View shipment documents panel in shipment details to view all package tracking history.
          </Typography>
        </Grid>
        )}
        <Grid item>
          <Typography style={{ fontWeight: 500, paddingBottom: 16 }}>
            {currentTab === SHIPMENT_TRACKING_TAB
              ? `Shipment Tracking Number: ${trackingNumber}`
              : `IoT Tracking Number: ${iotTrackingNumber}`}
          </Typography>
        </Grid>
        {
          loading ? (
            <LinearProgress color="secondary" />
          ) : (
            <Grid item className={classes.trackingHistory} container alignItems="center" direction="column">
              {getTrackingHistoryDisplay(currentTab === SHIPMENT_TRACKING_TAB ? trackingHistory : iotTrackingHistory)}
            </Grid>
          )
        }
      </Grid>

    </Popover>
  );
}
