import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import {
  Tabs,
  Tab,
} from '@material-ui/core/';
import { makeStyles } from '@material-ui/styles';
import {
  RECIPIENT_FORM_TABS_WITH_IDS,
  RECIPIENT_FORM_TABS_MULTIPLE_WITH_IDS,
  SHIPPING_TYPE_DOMESTIC,
  FREIGHT_TYPE_PRODUCT,
  FREIGHT_TYPE_DOCUMENT,
} from '../clientConstants';
import NewOrderTabPanel from './common/NewOrderTabPanel';
import NewOrderSenderRecipientAddress from './SenderAndRecipientPanes/NewOrderSenderRecipientAddress';
import NewOrderSenderRecipientContacts from './SenderAndRecipientPanes/NewOrderSenderRecipientContacts';
import NewOrderErrorDialog from './common/NewOrderErrorDialog';
import NewOrderMultipleAddress from './SenderAndRecipientPanes/NewOrderMultipleAddress';
import {
  useContactsDispatch,
  updateContactsState,
} from '../context/contactsContext';
import { useSingleOrderState } from '../context/singleOrderContext';

import {
  NEW_ORDER_SENDER_RECIPIENT_STYLE,
  NEW_ORDER_TABS_STYLE,
} from '../styles/style';
import { sanitizeAddress, sanitizeAddresses } from '../utils/helpers';
import * as colors from '../styles/colors';

const SELECT_FROM_CONTACTS_TAB_VALUE = 'one';
const ENTER_ADDRESS_TAB_VALUE = 'two';
const MULTIPLE_ADDRESS = 'three';

function CustomAppBar(props) {
  const {
    selectedTab, classes, selectedAddress, isMultiple,
  } = props;
  const { onChange } = props;
  const tabs = isMultiple
    ? RECIPIENT_FORM_TABS_MULTIPLE_WITH_IDS
    : RECIPIENT_FORM_TABS_WITH_IDS;

  return (
    <AppBar position="static" classes={{ root: classes.appBar }}>
      <Tabs
        textColor="primary"
        value={selectedTab}
        onChange={onChange}
        classes={{ indicator: classes.appBarIndicator }}
      >
        {tabs.map((tab) => (
          <Tab
            key={`recipient-tabs-${tab.name}`}
            value={tab.value}
            label={tab.label}
            disabled={selectedAddress.length > 0 && tab.value !== MULTIPLE_ADDRESS}
            wrapped
            classes={{
              root: classes.appBarTab,
              disabled: classes.disabled,
              selected: classes.selected,
            }}
          />
        ))}
      </Tabs>
    </AppBar>
  );
}

CustomAppBar.propTypes = {
  selectedTab: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    appBar: PropTypes.string.isRequired,
    appBarIndicator: PropTypes.string.isRequired,
    appBarTab: PropTypes.string.isRequired,
    disabled: PropTypes.string.isRequired,
    selected: PropTypes.string.isRequired,
  }).isRequired,
};

function CustomContacts(props) {
  const { selectedTab, shipmentDestination, ...other } = props;
  const { onSetRecipient, onEditContact } = props;
  const { classes } = other;

  return (
    <NewOrderTabPanel
      index={SELECT_FROM_CONTACTS_TAB_VALUE}
      value={selectedTab}
      classes={classes}
    >
      <NewOrderSenderRecipientContacts
        {...other}
        contact={shipmentDestination}
        tabValue={SELECT_FROM_CONTACTS_TAB_VALUE}
        handleSubmit={onSetRecipient}
        handleEdit={onEditContact}
      />
    </NewOrderTabPanel>
  );
}

CustomContacts.propTypes = {
  selectedTab: PropTypes.string.isRequired,
  shipmentDestination: PropTypes.objectOf(PropTypes.any).isRequired,
  onSetRecipient: PropTypes.func.isRequired,
  onEditContact: PropTypes.func.isRequired,
};

function CustomEnterAddress(props) {
  const { selectedTab, shipmentDestination, ...other } = props;
  const { onSetRecipient } = props;
  const { classes } = other;

  return (
    <NewOrderTabPanel
      index={ENTER_ADDRESS_TAB_VALUE}
      value={selectedTab}
      classes={classes}
    >
      <NewOrderSenderRecipientAddress
        {...other}
        contact={shipmentDestination}
        tabValue={ENTER_ADDRESS_TAB_VALUE}
        isRecipient
        handleSubmit={onSetRecipient}
      />
    </NewOrderTabPanel>
  );
}

CustomEnterAddress.propTypes = {
  selectedTab: PropTypes.string.isRequired,
  shipmentDestination: PropTypes.objectOf(PropTypes.any).isRequired,
  onSetRecipient: PropTypes.func.isRequired,
};

const multiAddressStyle = {
  root: {
    marginTop: '30px',
  },
  dialogPaper: {
    background: colors.newOrderFormBackground,
    borderRadius: 8,
    overflow: 'hidden',
    maxWidth: 'none',
    zIndex: 3000,
  },
  dialogInnerContainer: {
    height: '90vh',
    width: '83vw',
    background: colors.newOrderFormBackground,
    padding: '35px 25px 35px 25px',
    borderRadius: 8,
    position: 'relative',
  },
  typography: {
    color: 'white',
    paddingBottom: '1em',
  },
  closeIcon: {
    position: 'absolute',
    top: 10,
    right: 10,
  },
  addressBlock: {
    color: colors.textLightGrey,
    minHeight: 23,
    fontSize: 14,
  },
  container: {
    flexDirection: 'column',
    background: colors.darkBlueBackground,
    padding: '10px 20px',
    maxHeight: '800px',
    overflowY: 'scroll',
  },
  labelContainer: {
    height: 40,
  },
  addressList: {
    paddingTop: '10px',
    paddingBottom: '5px',
    borderBottom: '1px solid #3b3f51',
  },
  noResults: {
    color: colors.white,
    letterSpacing: '.03em',
    fontStyle: 'italic',
    padding: 24,
  },
  buttonContainer: {
    margin: '20px 0px 6px',
  },
  miscLoadingContainer: {
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingTop: 24,
  },
  tooltip: { background: colors.tooltipBackground, fontSize: 12 },
  contactButton: { minWidth: 30, paddingLeft: 5, paddingRight: 5 },
};

const useMultiAddressStyle = makeStyles(multiAddressStyle);

function CustomMultipleAddress(props) {
  const {
    selectedTab, shipmentDestination, selectedAddress, setSelectedAddress, origin, shippingType, ...other
  } = props;
  const { onSetRecipient } = props;
  const [open, setOpen] = useState(false);
  const classes = useMultiAddressStyle();

  useEffect(() => {
    if (selectedTab === 'three' && selectedAddress.length === 0) {
      setOpen(true);
    }
  }, [selectedTab]);

  const toggleOpen = () => {
    setOpen(!open);
  };
  return (
    <NewOrderTabPanel
      index={MULTIPLE_ADDRESS}
      value={selectedTab}
      classes={classes}
    >
      <NewOrderMultipleAddress
        selectedTab={selectedTab}
        open={open}
        setOpen={setOpen}
        selectedAddress={selectedAddress}
        setSelectedAddress={setSelectedAddress}
        classes={classes}
        contact={shipmentDestination}
        toggleOpen={toggleOpen}
        handleSubmit={onSetRecipient}
        origin={origin}
        shippingType={shippingType}
      />
    </NewOrderTabPanel>
  );
}
CustomMultipleAddress.propTypes = {
  selectedTab: PropTypes.string.isRequired,
  shipmentDestination: PropTypes.objectOf(PropTypes.any).isRequired,
};
export default function RecipientTabs({
  selectedForms,
  shipmentId,
  submitFormToApi,
  openNextPanel,
}) {
  const [selectedTab, setSelectedTab] = useState(
    SELECT_FROM_CONTACTS_TAB_VALUE,
  );
  const [editAddress, setEditAddress] = React.useState({});
  const [selectedAddress, setSelectedAddress] = useState([]);

  const [resetSearchCache, setResetSearchCache] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorContent, setErrorContent] = useState(null);

  const contactsDispatch = useContactsDispatch();
  const shipment = useSingleOrderState().shipments[shipmentId];

  const shipmentDestination = shipment?.destination;
  const shipmentOrigin = shipment?.origin;
  const shippingType = shipment?.shippingType;
  const freightType = shipment?.freightType;
  const isMultiple = shippingType === SHIPPING_TYPE_DOMESTIC && [FREIGHT_TYPE_PRODUCT, FREIGHT_TYPE_DOCUMENT].includes(freightType);

  const classes = NEW_ORDER_TABS_STYLE();
  const childClasses = NEW_ORDER_SENDER_RECIPIENT_STYLE();

  const commonProps = {
    shipmentDestination,
    selectedTab,
    onSetRecipient,
    onEditContact,
  };

  useEffect(() => {
    if (!shipmentDestination) return;

    setSelectedTab(
      // eslint-disable-next-line no-nested-ternary
      shipmentDestination?.addresses ? MULTIPLE_ADDRESS : (
      shipmentDestination?.address?.addressId
        ? SELECT_FROM_CONTACTS_TAB_VALUE
        : ENTER_ADDRESS_TAB_VALUE),
    );
  }, [shipmentDestination]);

  /*          CALLBACKS           */

  function onTabChange(e, newValue) {
    setSelectedTab(newValue);
  }

  function onEditContact(address) {
    setEditAddress({ ...address, updateContacts: true });
    setSelectedTab(ENTER_ADDRESS_TAB_VALUE);
  }

  async function onSetRecipient(recipient) {
    let destinationDetails = {};

    if (Array.isArray(recipient)) {
      const addresses = sanitizeAddresses(recipient);

      destinationDetails = {
        addresses,
      };

      try {
        await submitFormToApi(destinationDetails);
      } catch (e) {
        return;
      }
    } else {
      const { updateContacts, addressId } = recipient;
      const address = sanitizeAddress(recipient);

      destinationDetails = {
        updateContacts: updateContacts || false,
        address,
      };

      try {
        await submitFormToApi(destinationDetails);
      } catch (e) {
        return;
      }
      if (!updateContacts) {
        openNextPanel();
        return;
      }

      const { isDefault } = recipient;
      if (addressId) {
        try {
          updateContactsState({
            dispatch: contactsDispatch,
            contact: {
              ...address,
              ...(isDefault && { isDefault }),
            },
          });
        } catch (e) {
          setErrorContent(e.message);
          setIsError(true);
        }
      } else {
        setResetSearchCache(true);
      }
    }

    openNextPanel();
  }

  return (
    <>
      <div className={classes.root}>
        <CustomAppBar
          selectedTab={selectedTab}
          selectedAddress={selectedAddress}
          onChange={onTabChange}
          classes={childClasses}
          isMultiple={isMultiple}
        />
        <CustomContacts
          {...commonProps}
          selectedForms={selectedForms}
          resetSearchCache={resetSearchCache}
          setResetSearchCache={setResetSearchCache}
          classes={childClasses}
        />
        <CustomEnterAddress
          {...commonProps}
          selectedForms={selectedForms}
          editAddress={editAddress}
          classes={childClasses}
        />
        <CustomMultipleAddress
          {...commonProps}
          selectedForms={selectedForms}
          selectedAddress={selectedAddress}
          setSelectedAddress={setSelectedAddress}
          editAddress={editAddress}
          classes={childClasses}
          origin={shipmentOrigin}
          shippingType={shippingType}
        />
      </div>
      <NewOrderErrorDialog
        open={isError}
        errorContent={errorContent}
        classes={childClasses}
        onClose={() => {
          setIsError(false);
          setErrorContent(null);
        }}
      />
    </>
  );
}

RecipientTabs.propTypes = {
  shipmentId: PropTypes.string.isRequired,
  selectedForms: PropTypes.objectOf(PropTypes.string.isRequired).isRequired,
  submitFormToApi: PropTypes.func.isRequired,
};
