import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import has from 'lodash/has';
import remove from 'lodash/remove';
import { format } from 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  Button,
  CircularProgress,
  Grid, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography,
} from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import EditIcon from '@material-ui/icons/Edit';
import Event from '@material-ui/icons/Event';
import { makeStyles } from '@material-ui/core/styles';
import * as colors from '../../styles/colors';
import ScrollWindow from './ScrollWindow';

const EXPIRES = 'expires';
const EMPTY_TRAINING_KEY = 'noItem';
const EMPTY_TRAINING = 'No trainings available.';
const DATE_FORMAT = 'MM/dd/yyyy';

const COMMON_BUTTON_STYLES = {
  minWidth: 114,
  height: 36,
  fontWeight: 'bold',
  borderRadius: 8,
};

const COLUMNS = [
  {
    key: 'description',
    header: 'Training Name',
    width: '50%',
  },
  {
    key: EXPIRES,
    header: 'Expiry Date',
    width: '50%',
  },
];

const useStyles = makeStyles(() => ({
  card: {
    width: '100%',
    backgroundColor: colors.darkBlueBackground,
    padding: '20px 15px 0',
    marginTop: '1rem',
  },
  header: {
    fontSize: 20,
    fontWeight: 500,
    color: colors.white,
    letterSpacing: '0.25px',
  },
  innerContainer: {
    backgroundColor: colors.darkBlueBackground,
    margin: '2rem 0',
    width: '100%',
    borderRadius: '8px',
  },
  tableContainer: {
    overflow: 'hidden',
    position: 'relative',
  },
  row: {
    display: 'flex',
    justifyContent: 'center',
  },
  table: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 'auto',
    flexGrow: '2',
    overflow: 'hidden',
  },
  tableBody: {
    position: 'relative',
    height: '175px',
  },
  cell: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    border: 'none',
    borderBottom: `1px solid ${colors.tableGreyBorder}`,
  },
  emptyTraining: {
    justifyContent: 'center',
  },
  tableCellHeader: {
    color: colors.tableGreyText,
  },
  tableCellBody: {
    fontSize: 14,
    color: colors.white,
  },
  buttonsContainer: {
    width: 270,
    marginBottom: '20px',
  },
  primaryButton: {
    ...COMMON_BUTTON_STYLES,
    backgroundColor: colors.buttonBackgroundBlue,
    color: colors.white,
  },
  secondaryButton: {
    ...COMMON_BUTTON_STYLES,
    border: `1px solid ${colors.buttonBackgroundBlue}`,
    color: colors.secondaryButton,
  },
  progress: {
    marginTop: '15px',
  },
}));

const formatSubmitTrainingValues = (userTrainings) => userTrainings.reduce((t, current) => ({
  ...t,
  [current.key]: {
    expiresAt: current.expiresAt,
  },
}), {});

const formatUserTrainings = (training, userTraining) => map(training, (item, key) => {
  const currentUserTraining = has(userTraining, key) ? userTraining[key] : null;
  const baseTrainingItem = { key, ...item };

  return currentUserTraining
    ? { ...baseTrainingItem, ...currentUserTraining }
    : { ...baseTrainingItem, expiresAt: null };
});

const filteredUserTrainings = (trainings) => remove(trainings, (training) => (training.expiresAt !== null && format(new Date(), DATE_FORMAT) < training.expiresAt));

const ProfileTrainingTable = ({
  user, training, isReadOnly, isEditable, removeEmptyExpiryDate, onDateChange, onSubmit,
}) => {
  const classes = useStyles();
  const [userTrainings, setUserTrainings] = useState([]);
  const [readOnly, setReadOnly] = useState(false);

  useEffect(() => {
    if (!isEmpty(training) && isEmpty(userTrainings)) {
      const formattedUserTrainings = formatUserTrainings(training, user.training);
      const filtered = removeEmptyExpiryDate
        ? filteredUserTrainings(formattedUserTrainings)
        : formattedUserTrainings;

      setUserTrainings(filtered);
    }
  }, [training, user.training]);

  useEffect(() => {
    setReadOnly(isReadOnly);
  }, [isReadOnly]);

  const handleDateChange = (value, key) => {
    const idx = userTrainings.findIndex((t) => t.key === key);
    const hasTraining = idx > -1;
    const isDateValid = Date.parse(value);
    const baseTraining = { key, description: training[key].description };
    const newTraining = isDateValid
      ? { ...baseTraining, expiresAt: format(value, DATE_FORMAT) }
      : '';
    let newUserTrainings = [...userTrainings];

    if (isDateValid && !hasTraining) {
      newUserTrainings = [...userTrainings, newTraining];
    } else if (isDateValid && hasTraining) {
      newUserTrainings[idx] = newTraining;
    } else if (!isDateValid && hasTraining) {
      newUserTrainings[idx] = { ...baseTraining, expiresAt: null };
    }

    setUserTrainings(newUserTrainings);

    if (onDateChange) onDateChange(formatSubmitTrainingValues(newUserTrainings));
  };

  return (
    <>
      <Grid xs={12} container item justify={isEditable ? 'space-between' : 'flex-start'} alignItems="center">
        <Grid item>
          <Typography className={classes.header}>Trainings</Typography>
        </Grid>
        {isEditable && (
          <Grid item>
            <IconButton onClick={() => setReadOnly(false)}>
              <EditIcon />
            </IconButton>
          </Grid>
        )}
      </Grid>
      <Grid container item className={`${classes.innerContainer} ${classes.tableContainer}`}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow className={classes.row}>
              {COLUMNS.map((col) => (
                <TableCell
                  className={`${classes.cell} ${classes.tableCellHeader}`}
                  style={{ width: col.width }}
                >
                  <Typography>{col.header}</Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className={classes.tableBody}>
            {!isEmpty(userTrainings) ? (
              <ScrollWindow autoHide={false}>
                {userTrainings.map((row) => (
                  <TableRow className={classes.row} key={row.key}>
                    {COLUMNS.map((col) => (
                      <TableCell className={classes.cell} key={`${row.key}-${col.key}`} style={{ width: col.width }}>
                        {col.key === EMPTY_TRAINING_KEY && (
                        <Typography className={classes.tableCellBody}>
                          {row[col.key]}
                        </Typography>
                        )}
                        {col.key === EXPIRES && col.key !== EMPTY_TRAINING_KEY && (
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            style={{ border: 'none' }}
                            id={`date-picker-${row.key}`}
                            variant="inline"
                            format={DATE_FORMAT}
                            value={row.expiresAt ? row.expiresAt : null}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                            InputProps={{
                              disableUnderline: true,
                              classes: {
                                root: classes.tableCellBody,
                              },
                            }}
                            keyboardIcon={!readOnly ? <Event /> : null}
                            minDateMessage=""
                            onChange={(e) => handleDateChange(e, row.key)}
                            readOnly={readOnly}
                            disableToolbar
                            disablePast
                            autoOk
                          />
                        </MuiPickersUtilsProvider>
                        )}
                        {col.key !== EXPIRES && col.key !== EMPTY_TRAINING_KEY && (
                        <Typography className={classes.tableCellBody}>
                          {row[col.key]}
                        </Typography>
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </ScrollWindow>
            ) : (
              <TableRow className={classes.row}>
                <TableCell className={`${classes.cell} ${classes.emptyTraining}`}>
                  <Typography className={classes.tableCellBody}>
                    {EMPTY_TRAINING}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Grid>
      {isEditable && !readOnly && (
        <Grid container justify="center">
          {readOnly ? <CircularProgress color="secondary" className={classes.progress} /> : (
            <Grid item container justify="space-between" className={classes.buttonsContainer}>
              <Button
                className={classes.secondaryButton}
                onClick={() => setReadOnly(true)}
              >
                Cancel
              </Button>
              <Button
                className={classes.primaryButton}
                onClick={() => {
                  if (onSubmit) {
                    onSubmit({ training: formatSubmitTrainingValues(userTrainings) },
                      null,
                      setReadOnly);
                  }
                }}
              >
                Submit
              </Button>
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
};

ProfileTrainingTable.defaultProps = {
  user: null,
  training: null,
  isReadOnly: false,
  isEditable: false,
  removeEmptyExpiryDate: false,
  onDateChange: null,
  onSubmit: null,
};

ProfileTrainingTable.propTypes = {
  user: PropTypes.shape(PropTypes.object),
  training: PropTypes.shape(PropTypes.object),
  isReadOnly: PropTypes.bool,
  isEditable: PropTypes.bool,
  removeEmptyExpiryDate: PropTypes.bool,
  onDateChange: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default memo(ProfileTrainingTable);
