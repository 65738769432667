import React from 'react';
import PropTypes from 'prop-types';
import {
  get,
} from 'lodash';
import {
  Grid, Typography, FormControl, FormLabel, Radio, RadioGroup, FormControlLabel,
} from '@material-ui/core';

import * as colors from '../../../styles/colors';
import { useItemProductDetailsFormStyles } from '../itemDetailsStyles';

export default function ExportControl({
  formikProps, account,
}) {
  const classes = useItemProductDetailsFormStyles();

  const options = [
    {
      value: 'yes',
      label: 'Yes',
    },
    {
      value: 'no',
      label: 'No',
    },
  ];

  return (
    <Grid item container direction="column" spacing={1}>
      <Grid item>
        <FormControl component="fieldset">
          <FormLabel component="legend">
            <Grid item container alignItems="center" spacing={2}>
              <Grid item>
                <Typography color="primary" className={classes.formFieldSubHeader}>
                  Does the shipment involve any of the following: export-controlled technology,
                  a defense service or potential military use, propriety items, software, or
                  technology, novel compounds, a third-party country transfer, hazardous
                  materials, microorganisms, animal or plant pathogens, explosive materials
                  including precursor chemicals, weapons, nuclear material?
                  {' '}

                </Typography>
              </Grid>
              <Grid style={{
                display: 'flex',
                flexDirection: 'row',
                paddingTop: '10px',
                paddingLeft: '10px',
                paddingBottom: '10px',
              }}
              >
                <Typography style={{ fontSize: '14px' }}>
                  If you are uncertain if your shipment meets any of the criteria in this
                  question, contact the Export Controls and International Compliance team
                  (
                  <a
                    href="mailto:exportcontrols@northwestern.edu"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    exportcontrols@northwestern.edu
                  </a>
                  )
                </Typography>
              </Grid>
            </Grid>
          </FormLabel>
          <RadioGroup
            aria-label="isMaterialCheckRequired"
            name="isMaterialCheckRequired"
            value={get(formikProps, 'values.isMaterialCheckRequired', false) ? 'yes' : 'no'}
            row
            onChange={(e) => {
              const value = e.target.value === 'yes';
              formikProps.setFieldValue('isMaterialCheckRequired', value);
            }}
          >
            {options.map((option) => (
              <FormControlLabel
                key={option.value}
                style={{ padding: '0 10px' }}
                value={option.value}
                control={<Radio style={{ color: colors.white }} />}
                label={<Typography color="primary">{option.label}</Typography>}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Grid>
    </Grid>
  );
}

ExportControl.propTypes = {
  formikProps: PropTypes.objectOf(PropTypes.any).isRequired,
  account: PropTypes.string.isRequired,
};
