import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, DialogActions, DialogContent, DialogContentText,
  Grid, Typography, CircularProgress, Button,
} from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import { css } from 'emotion';
import NewOrderNextButton from './NewOrderNextButton';
import NewOrderNextButtonClear from './NewOrderNextButtonClear';
import * as colors from '../../styles/colors';

function ActionButton(props) {
  const {
    label, onClick,
  } = props;

  const hovered = css`
  background:${colors.mediumBlueBackground}
  `;
  const disabled = css({ color: '#3B3F51' });
  const buttonStyle = css({
    fontWeight: 'bold',
    marginLeft: '1em',
    maxWidth: ' 148px',
    color: `${colors.secondaryButton}`,
    //  borderRadius: '8px',
    //   backgroundColor: `${colors.shipmentCardBackground}`,
    //  border: `1px solid ${colors.buttonBackgroundBlue}`,
    boxShadow: 'none',
    ':hover': hovered,
    ':disabled': disabled,
    width: '100%',
  });
  return (
    <Button
      className={buttonStyle}
      variant="text"
      onClick={onClick}
    >
      {label}
    </Button>

  );
}

ActionButton.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};
export default function ErrorModalWE(props) {
  const {
    open, message, onProceed, onCancel, proceedLabel,
    cancelLabel, proceedButtonComponent, proceedButtonProps,
    noCancel, strongMessage, actionMessage, onActionClick,
  } = props;

  const [loading, setLoading] = React.useState(false);
  const [messageState, setMessageState] = React.useState(message);
  const ProceedButtonComponent = proceedButtonComponent || NewOrderNextButton;

  React.useEffect(() => {
    if (message) {
      setMessageState(message);
    }
  }, [message]);

  const dialogRoot = css({
    backgroundColor: colors.mediumBlueBackground,
    overflowY: 'initial',
    borderRadius: 10,
    maxWidth: '50%',
  });
  const dialogCustomizedWidth = css({ width: '80%' });
  const dialogContentContainer = css({ marginBottom: 'initial', justifyContent: 'center' });
  const dialogActionsContainer = css({
    paddingBottom: 24, width: '100%', justifyContent: 'center', flexDirection: 'column',
  });

  const classes = {
    dialogRoot,
    dialogContentContainer,
    dialogActionsContainer,
    dialogContent: css({
      // minWidth: 800
      paddingLeft: 40,
      paddingRight: 40,
    }),
    buttonsContainer: css({
      width: 'fit-content',
    }),
    dialogText: css({
      fontWeight: 400,
      lineHeight: '1em',
      fontSize: '3rem',
      color: '#f50057',
    }),
    progress: css({
      marginTop: 20,
    }),
    buttonText: css({
      whiteSpace: 'nowrap',
      padding: '0 10px',
    }),
    contentGrid: css({
      padding: '30px 10px',
      background: colors.darkBlueBackground,
    }),
  };

  const onClick = async () => {
    setLoading(true);
    try {
      await onProceed();
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const dialogContent = (
    <DialogContent className={classes.dialogContent}>
      <DialogContentText
        id="alert-error-dialog"
        component="div"
        classes={{ root: dialogContentContainer }}
      >
        <Grid item container direction="column" justify="center">
          <Grid item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <ErrorIcon style={{ fontSize: '70px', color: '#FF8503' }} />
          </Grid>
          <Grid item className={classes.contentGrid}>
            <Typography color="primary" className={classes.dialogText}>
              {messageState}
              {strongMessage && (
              <p>
                <b>
                  {strongMessage}
                </b>
                <ActionButton label="Unscan" onClick={onActionClick} />
              </p>
              )}
              {actionMessage && (
              <b>
                {actionMessage}
              </b>
              )}

            </Typography>
          </Grid>
        </Grid>
      </DialogContentText>
    </DialogContent>
  );

  const dialogActions = (
    <DialogActions classes={{ root: dialogActionsContainer }}>
      <Grid
        container
        className={classes.buttonsContainer}
        spacing={2}
        justify="center"
        wrap="nowrap"
      >
        {
        !noCancel
          && (
          <Grid item>
            <NewOrderNextButtonClear disabled={loading} onClick={onCancel}>{cancelLabel || 'No, cancel'}</NewOrderNextButtonClear>
          </Grid>
          )
      }
        <Grid item>
          <ProceedButtonComponent
            className={classes.buttonText}
            disabled={loading}
            onClick={onClick}
            {...proceedButtonProps}
          >
            {proceedLabel || 'Yes, submit'}
          </ProceedButtonComponent>
        </Grid>
      </Grid>
      {loading && <CircularProgress color="secondary" className={classes.progress} />}
    </DialogActions>
  );

  return (
    <Dialog
      onKeyUp={(e) => {
        const ENTER = 13;
        if (e.keyCode === ENTER) {
          onClick();
        }
      }}
      fullWidth
      open={open}
      onClose={(e, reason) => {
        if (noCancel) return;
        if ((loading) && ['backdropClick', 'escapeKeyDown'].includes(reason)) return;
        onCancel();
      }}
      classes={{ paper: dialogRoot , paperFullWidth: dialogCustomizedWidth}}
      transitionDuration={{ exit: 0, enter: 100, appear: 100 }}
    >
      {dialogContent}
      {dialogActions}
    </Dialog>
  );
}

ErrorModalWE.propTypes = {
  open: PropTypes.bool,
  message: PropTypes.node,
  onProceed: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  proceedLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  cancelLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  proceedButtonComponent: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  proceedButtonProps: PropTypes.objectOf(PropTypes.any),
  noCancel: PropTypes.bool,
  strongMessage: PropTypes.node,
  actionMessage: PropTypes.node,
  onActionClick: PropTypes.func,
};

ErrorModalWE.defaultProps = {
  open: false,
  message: null,
  proceedLabel: '',
  cancelLabel: '',
  proceedButtonComponent: undefined,
  proceedButtonProps: null,
  noCancel: false,
  strongMessage: undefined,
  actionMessage: null,
  onActionClick: null,
};
