import React, { useEffect, useRef, useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  CircularProgress,
  DialogContent, DialogContentText,
  Grid, Typography, MenuItem,
} from '@material-ui/core';
import {
  Schedule as ScheduleIcon,
  ErrorOutline as ErrorOutlineIcon,
  Alarm as AlarmIcon,
} from '@material-ui/icons';
import parsePhoneNumber from 'libphonenumber-js';
import NewOrderConfirmDialog from './common/NewOrderConfirmDialog';
import NewOrderErrorDialog from './common/NewOrderErrorDialog';
import NewOrderWideButton from './common/NewOrderWideButton';
import AddressForm from './common/AddressForm';
import { CustomDatePicker, CustomTimeSelect, CustomTextArea } from './common/InputComponents';
import {
  useSingleOrderState, useSingleOrderDispatch,
  updateSchedulePickup, cancelSchedulePickup, reschedulePickup,
} from '../context/singleOrderContext';
import { NEW_ORDER_SCHEDULE_PICKUP_STYLE } from '../styles/style';
import statusHelp from '../utils/status';

/**
 * ##################################
 *          GLOBAL VARIABLES
 * ##################################
 */

const DEFAULT_FORM_VALUES = {
  packageLocation: '',
  country: '',
  name: '',
  addressLine1: '',
  addressLine2: '',
  addressLine3: '',
  city: '',
  state: '',
  zip: '',
  phone: '',
  updateMap: false,
};
const DATE_FORMAT = 'YYYYMMDD';

/**
 * ##################################
 *          CUSTOM COMPONENT
 * ##################################
 */

function CustomSchedulePickup(props) {
  const { status, pickupFrom, classes } = props;
  const { onSubmit, onCancel } = props;

  const formikRef = useRef();

  const { country = '' } = (formikRef.current) ? formikRef.current.state.values : {};

  const {
    miscEmpty,
    baseContentFormContainer,
    dialogContentContainer,
    scheduleContentProcessingContainer,
  } = classes;

  useEffect(() => {
    if (!pickupFrom || !formikRef.current) return;

    const { values } = formikRef.current.state;
    const { address, pickupDateInfo } = pickupFrom;

    formikRef.current.resetForm({
      ...values,
      ...{
        country: address.country,
        name: address.name,
        phone: address.phone,
        addressLine1: address.addressLine1,
        city: address.city,
        state: address.state,
        zip: address.zip,
      },
      ...(address.addressLine2) && { addressLine2: address.addressLine2 },
      ...(address.addressLine3) && { addressLine3: address.addressLine3 },
      ...(pickupDateInfo && Object.keys(pickupDateInfo).length > 0) && {
        pickupDate: pickupDateInfo.pickupDate,
        readyTime: pickupDateInfo.readyTime,
        closeTime: pickupDateInfo.closeTime,
        packageLocation: pickupDateInfo.packageLocation,
      },
    });
  }, [formikRef, pickupFrom]);

  const [open, setOpen] = useState(false);
  const [disableDialog, setDisableDialog] = useState(false);

  function onOpen() {
    if (formikRef.current) {
      formikRef.current.setSubmitting(false);
    }
    setOpen(true);
  }

  /*          FORMIK PROPS            */

  const validateOnBlur = false;
  const btnLabel = statusHelp.hasBeenScheduled(status) ? 'Cancel Pickup' : 'Schedule Pickup';
  const disabled = statusHelp.hasBeenScheduled(status);

  const validationSchema = Yup.object().shape({
    country: Yup.string().required('Required'),
    name: Yup.string().required('Required'),
    phone: Yup.string()
      .test({
        name: 'is-valid-phone',
        message: 'Invalid phone number',
        test(value) {
          const parsedPhoneNumber = parsePhoneNumber(value || '');
          return parsedPhoneNumber && parsedPhoneNumber.isValid();
        },
      })
      .required('Required'),
    phoneCountry: Yup.string().required('Required'),
    addressLine1: Yup.string().required('Required'),
    city: Yup.string().required('Required'),
    state: Yup.string().required('Required'),
    zip: Yup.string()
    // .matches(/^[0-9]{5}$/g, { message: 'Invalid Zip Code'})
      .required('Required'),
    // eslint-disable-next-line func-names
    closeTime: Yup.mixed().test('Success', 'Invalid Time', function (value) {
      // eslint-disable-next-line react/no-this-in-sfc
      return (parseInt(value, 10) >= parseInt(this.parent.readyTime, 10));
    }),
  });

  function getInitialValues() {
    const date = moment();
    const initDateStr = moment(date).format(DATE_FORMAT);
    let modifier = moment(date).format('mm') % 15;

    if (modifier === 0) modifier = 15;

    const advTimeDate = moment(date).add(45 - modifier, 'minutes');
    const advTimeDateStr = moment(advTimeDate).format(DATE_FORMAT);
    const advReadyStr = moment(advTimeDate).format('HHmm');

    let pickupDate = null;
    let readyTime = '0800';
    const closeTime = '1700';

    if (advTimeDateStr > initDateStr || advReadyStr >= closeTime) pickupDate = moment(date).add(1, 'day');
    else if (advReadyStr > readyTime) readyTime = advReadyStr;

    return {
      readyTime,
      closeTime,
      ...DEFAULT_FORM_VALUES,
      pickupDate: moment(pickupDate || date).format(DATE_FORMAT),
    };
  }

  /*            Render Components            */

  const dialogContent = (
    <DialogContent>
      <DialogContentText
        id="alert-dialog-cancel"
        component="div"
        classes={{ root: dialogContentContainer }}
      >
        <Typography color="primary">
          Are you sure you want to cancel this shipment pickup?
        </Typography>
        <Typography color="primary">
          You will need to drop the package off yourself or
        </Typography>
        <Typography color="primary">
          schedule a new pickup.
        </Typography>
      </DialogContentText>
    </DialogContent>
  );

  function render(renderProps) {
    const { isSubmitting, values } = renderProps;
    return (
      <Form className={baseContentFormContainer}>
        <Grid
          container
          direction="column"
          spacing={2}
        >
          <Grid
            item
            container
            spacing={2}
          >
            <CustomDateTime
              formikRef={formikRef}
              pickupDate={renderProps.values.pickupDate}
              disabled={(disabled || isSubmitting)}
              classes={classes}
            />
            <CustomAddressBlock
              formikRef={formikRef}
              pickupFrom={pickupFrom}
              country={values.country}
              disabled={(disabled || isSubmitting)}
              classes={classes}
            />
          </Grid>
          <Grid
            item
            container
            spacing={4}
            justify="center"
            classes={{
              root: scheduleContentProcessingContainer,
              item: miscEmpty,
            }}
          >
            <CustomSubmitted
              pickupFrom={pickupFrom}
              status={status}
              classes={classes}
            />
            <CustomProcessing
              formikRef={formikRef}
              status={status}
              classes={classes}
            />
            <Grid item>
              <NewOrderWideButton
                disabled={isSubmitting}
                onClick={renderProps.submitForm}
                data-testid="schedule-pickup-button"
              >
                {btnLabel}
              </NewOrderWideButton>
            </Grid>
          </Grid>
        </Grid>
      </Form>
    );
  }

  return (
    <>
      <Formik
        ref={formikRef}
        validateOnBlur={validateOnBlur}
        initialValues={getInitialValues()}
        validationSchema={validationSchema}
        onSubmit={statusHelp.hasBeenScheduled(status) ? onOpen : onSubmit}
        render={render}
      />
      <NewOrderConfirmDialog
        open={open}
        cancelText="Yes, Cancel"
        dialogContent={dialogContent}
        classes={classes}
        onClose={() => {
          setOpen(false);
          formikRef.current.setSubmitting(false);
        }}
        onConfirm={() => {
          onCancel(formikRef.current.state.values, formikRef.current, setDisableDialog);
        }}
        disabled={disableDialog}
      />
    </>
  );
}

CustomSchedulePickup.propTypes = {
  status: PropTypes.string.isRequired,
  pickupFrom: PropTypes.shape({
    address: PropTypes.object.isRequired,
    pickupDateInfo: PropTypes.object,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    miscEmpty: PropTypes.string.isRequired,
    baseContentFormContainer: PropTypes.string.isRequired,
    dialogContentContainer: PropTypes.string.isRequired,
    scheduleContentProcessingContainer: PropTypes.string.isRequired,
  }).isRequired,
};

function CustomCancel(props) {
  const { classes } = props;
  const { onClick } = props;

  const {
    scheduleCancelledContentFooterContainer,
    scheduleCancelledContentMessageContainer,
    scheduleCancelledLabelMessage,
    scheduleCancelledIconError,
  } = classes;

  const messageContent = (
    <>
      <Grid item>
        <Typography classes={{ root: scheduleCancelledLabelMessage }}>
          Your pickup has been
          {' '}
          <b>canceled</b>
          .
        </Typography>
      </Grid>
      <Grid item>
        <Typography classes={{ root: scheduleCancelledLabelMessage }}>
          Please drop off the package or
        </Typography>
      </Grid>
      <Grid item>
        <Typography classes={{ root: scheduleCancelledLabelMessage }}>
          schedule a new pickup.
        </Typography>
      </Grid>
    </>
  );

  const button = (
    <NewOrderWideButton onClick={onClick}>
      Reschedule Pickup
    </NewOrderWideButton>
  );

  return (
    <Grid
      container
      direction="column"
      spacing={4}
      alignItems="center"
      classes={{
        root: scheduleCancelledContentFooterContainer,
      }}
    >
      <Grid
        item
        container
        spacing={2}
        wrap="nowrap"
        justify="center"
        alignItems="center"
      >
        <Grid item>
          <ErrorOutlineIcon classes={{ root: scheduleCancelledIconError }} />
        </Grid>
        <Grid
          item
          container
          direction="column"
          classes={{ root: scheduleCancelledContentMessageContainer }}
        >
          {messageContent}
        </Grid>
      </Grid>
      <Grid item>{button}</Grid>
    </Grid>
  );
}

CustomCancel.propTypes = {
  classes: PropTypes.shape({
    scheduleCancelledContentFooterContainer: PropTypes.string.isRequired,
    scheduleCancelledContentMessageContainer: PropTypes.string.isRequired,
    scheduleCancelledLabelMessage: PropTypes.string.isRequired,
    scheduleCancelledIconError: PropTypes.string.isRequired,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
};

function CustomDateTime(props) {
  const {
    formikRef, pickupDate, disabled, classes,
  } = props;

  const [timeIntervals] = useState(getTimeIntervals());

  const {
    baseContentDateTimeContainer,
    baseLabelSectionHeader,
    baseIconClockIcon,
    miscHyphen,
    dateContainer,
    datePickerOpen,
  } = classes;

  function onDateChange(e) {
    const { values } = formikRef.current.state;

    const year = e.getFullYear();
    const month = e.getMonth() + 1;
    const day = e.getDate();

    const yrMoDay = `${year}${(month < 10 ? `0${month}` : month)}${(day < 10 ? `0${day}` : day)}`;

    formikRef.current.resetForm({
      ...values,
      pickupDate: yrMoDay,
    });
  }

  function getTimeMenuItems(pos) {
    return timeIntervals.map((interval) => (
      <MenuItem value={interval.value} key={`${pos}-${interval.id}`}>
        {interval.displayTime}
      </MenuItem>
    ));
  }

  return (
    <Grid item container direction="column" spacing={2}>
      <Grid item>
        <Typography classes={{ root: baseLabelSectionHeader }}>Date and Time*</Typography>
      </Grid>
      <Grid
        item
        container
        alignItems="center"
        spacing={2}
        classes={{ root: baseContentDateTimeContainer }}
      >
        <Grid item classes={{ root: dateContainer }}>
          <CustomDatePicker
            name="pickupDate"
            value={`${pickupDate.substring(4, 6)}/${pickupDate.substring(6)}/${pickupDate.substring(0, 4)}`}
            disabled={disabled}
            onChange={onDateChange}
            classes={{
              ...classes,
              ...(!disabled && formikRef.current && !formikRef.current.state.isSubmitting)
                && { datePicker: datePickerOpen },
            }}
          />
        </Grid>
        <Grid item>
          <ScheduleIcon classes={{ root: baseIconClockIcon }} />
        </Grid>
        <Grid item>
          <CustomTimeSelect
            name="readyTime"
            disabled={disabled}
            classes={classes}
          >
            {getTimeMenuItems('ready')}
          </CustomTimeSelect>
        </Grid>
        <Grid item><Typography classes={{ root: miscHyphen }}>ー</Typography></Grid>
        <Grid item>
          <CustomTimeSelect
            name="closeTime"
            disabled={disabled}
            classes={classes}
          >
            {getTimeMenuItems('close')}
          </CustomTimeSelect>
        </Grid>
      </Grid>
    </Grid>
  );
}

CustomDateTime.propTypes = {
  formikRef: PropTypes.shape({
    current: PropTypes.object,
  }).isRequired,
  pickupDate: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  classes: PropTypes.shape({
    baseContentDateTimeContainer: PropTypes.string.isRequired,
    baseLabelSectionHeader: PropTypes.string.isRequired,
    baseIconClockIcon: PropTypes.string.isRequired,
    miscHyphen: PropTypes.string.isRequired,
    dateContainer: PropTypes.string.isRequired,
    datePickerOpen: PropTypes.string.isRequired,
  }).isRequired,
};

function CustomAddressBlock(props) {
  const {
    formikRef, pickupFrom, country, disabled, classes,
  } = props;

  const fields = ['country', 'name', 'phone', 'addressLine1', 'addressLine2', 'addressLine3', 'city', 'state', 'zip'];
  const other = {
    formikRef,
    country,
    classes,
    ...(disabled) && { disabled: fields },
    overRideOptional: ['phone'],
  };

  const { baseLabelSectionSubHeader } = classes;

  return (
    <Grid item container direction="column">
      <AddressForm
        fields={['map', 'name', 'phone']}
        other={{
          ...other,
          ...{ pickupFrom },
        }}
      />
      <Grid item container direction="column" spacing={1}>
        <Grid item>
          <Typography classes={{ root: baseLabelSectionSubHeader }}>Address*</Typography>
        </Grid>
        <Grid item>
          <AddressForm
            fields={['country', 'addressLine1']}
            other={other}
          />
        </Grid>
      </Grid>
      <AddressForm
        fields={['addressLine2', 'addressLine3', 'city']}
        other={other}
      />
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <AddressForm
            fields={['state']}
            other={other}
          />
        </Grid>
        <Grid item xs={6}>
          <AddressForm
            fields={['zip']}
            other={other}
          />
        </Grid>
      </Grid>
      <Grid item container direction="column" spacing={2}>
        <Grid item>
          <Typography classes={{ root: baseLabelSectionSubHeader }}>
            Special Instructions
          </Typography>
        </Grid>
        <Grid item>
          <CustomTextArea
            name="packageLocation"
            placeholder="Leave special instructions for the driver picking up the shipment (e.g. please ring the buzzer for unit C)"
            disabled={disabled
            || (formikRef.current !== undefined && formikRef.current.state.isSubmitting)}
            classes={classes}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

CustomAddressBlock.propTypes = {
  formikRef: PropTypes.shape({
    current: PropTypes.object,
  }).isRequired,
  pickupFrom: PropTypes.objectOf(PropTypes.any).isRequired,
  country: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  classes: PropTypes.shape({
    baseLabelSectionSubHeader: PropTypes.string.isRequired,
  }).isRequired,
};

function CustomSubmitted(props) {
  const { pickupFrom, status, classes } = props;
  const { pickupConfirmationNumber = '' } = pickupFrom;

  const {
    scheduledLabelMessage,
    scheduledIconAlarm,
  } = classes;

  return (
    <Grid
      item
      container
      spacing={2}
      wrap="nowrap"
      justify="center"
      alignItems="center"
      style={{
        display: (statusHelp.hasBeenScheduled(status)) ? 'inherit' : 'none',
      }}
    >
      <Grid item>
        <AlarmIcon classes={{ root: scheduledIconAlarm }} />
      </Grid>
      <Grid item>
        <Typography
          component="div"
          classes={{ root: scheduledLabelMessage }}
        >
          Your pickup has been scheduled.
        </Typography>
        <Typography
          component="div"
          classes={{ root: scheduledLabelMessage }}
        >
          Reference ID:
          {' '}
          <b>{pickupConfirmationNumber}</b>
        </Typography>
      </Grid>
    </Grid>
  );
}

CustomSubmitted.propTypes = {
  pickupFrom: PropTypes.shape({
    pickupConfirmationNumber: PropTypes.string,
  }).isRequired,
  status: PropTypes.string.isRequired,
  classes: PropTypes.shape({
    scheduledLabelMessage: PropTypes.string.isRequired,
    scheduledIconAlarm: PropTypes.string.isRequired,
  }).isRequired,
};

function CustomProcessing(props) {
  const { formikRef, status, classes } = props;

  const isSubmitting = (formikRef.current !== undefined && status !== 'SCHEDULED') ? formikRef.current.state.isSubmitting : false;
  const {
    miscColorsWhite,
    scheduleContentLoadingContainer,
  } = classes;

  return (
    <Grid
      item
      container
      direction="column"
      alignContent="center"
      style={{
        display: (isSubmitting) ? 'inherit' : 'none',
      }}
    >
      <Grid
        item
        classes={{ root: scheduleContentLoadingContainer }}
      >
        <CircularProgress color="secondary" />
      </Grid>
      <Grid item>
        <Typography classes={{ root: miscColorsWhite }}>
          We are scheduling your shipment pickup.
        </Typography>
      </Grid>
      <Grid>
        <Typography classes={{ root: miscColorsWhite }}>
          Please do not close or refresh this window.
        </Typography>
      </Grid>
    </Grid>
  );
}

CustomProcessing.propTypes = {
  formikRef: PropTypes.shape({
    current: PropTypes.object,
  }).isRequired,
  status: PropTypes.string.isRequired,
  classes: PropTypes.shape({
    miscColorsWhite: PropTypes.string.isRequired,
    scheduleContentLoadingContainer: PropTypes.string.isRequired,
  }).isRequired,
};

/**
 * ######################
 *          UTIL
 * ######################
 */

function getTimeIntervals() {
  const timesIntervals = [];

  for (let i = 0, end = 24; i < end; i += 1) {
    for (let j = 0, endj = 60; j < endj; j += 15) {
      const hrsModifier = (i > 12) ? `${i - 12}` : `${i}`;
      const hrsStr = `${(hrsModifier < 10) ? '0' : ''}${hrsModifier}`;

      const minsStr = `${(j < 10) ? '0' : ''}${j}`;

      const meridiem = (i < 12) ? 'AM' : 'PM';

      const item = {
        displayTime: `${(hrsStr === '00') ? '12' : hrsStr}:${minsStr} ${meridiem}`,
        value: `${(i < 10) ? `0${i}` : `${i}`}${minsStr}`,
        id: `time-interval-${(i < 12) ? `0${i}` : `${i}`}:${minsStr}`,
      };

      timesIntervals.push(item);
    }
  }

  return timesIntervals;
}

/**
 * #################################
 *          EXPORT FUNCTION
 * #################################
 */

export default function SchedulePickupForm({
  shipmentId, submitFormToApi,
  openNextPanel,
}) {

  const [isError, setIsError] = useState(false);
  const [errorContent, setErrorContent] = useState(null);

  const singleOrderDispatch = useSingleOrderDispatch();
  const classes = NEW_ORDER_SCHEDULE_PICKUP_STYLE();

  const shipment = useSingleOrderState().shipments[shipmentId];
  // Assuming shipment object has 'status' and 'pickupFrom' keys to load component.
  const { status, pickupFrom } = shipment;

  /*          UTIL            */

  async function onSubmit(values, { setSubmitting }) {
    const {
      readyTime, closeTime, pickupDate, packageLocation,
    } = values;
    const {
      country, name, phone, addressLine1, addressLine2, addressLine3, city, state, zip, phoneCountry,
    } = values;

    const payload = {
      pickupDateInfo: {
        readyTime, closeTime, pickupDate, packageLocation,
      },
      pickupFrom: {
        address: {
          country,
          name,
          phone,
          phoneCountry,
          addressLine1,
          city,
          state,
          zip,
          ...(addressLine2) && { addressLine2 },
          ...(addressLine3) && { addressLine3 },
        },
      },
    };

    try {
      // await updateSchedulePickup({
      //   shipmentId,
      //   payload,
      //   dispatch: singleOrderDispatch,
      // });
      await submitFormToApi(payload, false);
      openNextPanel();
    } catch (e) {
      setErrorContent(e.message);
      setIsError(true);
    }

    setSubmitting(false);
  }

  async function onCancel(values, { setSubmitting }, setDisableDialog) {
    setDisableDialog(true);

    try {
      await cancelSchedulePickup({
        shipmentId,
        dispatch: singleOrderDispatch,
      });
    } catch (e) {
      setErrorContent(e.message);
      setIsError(true);
    }

    setDisableDialog(false);
    setSubmitting(false);
  }

  function onReschedulePickup() {
    reschedulePickup({
      shipmentId,
      dispatch: singleOrderDispatch,
      shipment: {
        ...shipment,
        status: 'Completed',
      },
    });
  }

  return (
    <>
      {!statusHelp.isPickupCancelled(status)
        ? (
          <CustomSchedulePickup
            status={status}
            pickupFrom={pickupFrom}
            classes={classes}
            onSubmit={onSubmit}
            onCancel={onCancel}
          />
        )
        : (
          <CustomCancel
            classes={classes}
            onClick={onReschedulePickup}
          />
        )}
      <NewOrderErrorDialog
        open={isError}
        errorContent={errorContent}
        classes={classes}
        onClose={() => {
          setIsError(false);
          setErrorContent(null);
        }}
      />
    </>
  );
}

SchedulePickupForm.propTypes = {
  shipmentId: PropTypes.string.isRequired,
  selectedForms: PropTypes.objectOf(PropTypes.string.isRequired).isRequired,
  submitFormToApi: PropTypes.func.isRequired,
};
