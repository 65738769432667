import { omitBy, isEmpty, isNil } from 'lodash';
import client from './apiClient';
import {
  DUMMY_FETCH_ALL_ORDERS_DATA,
  FORMATTED_DUMMY_ORDER_DATA,
  DUMMY_INIT_ORDER_RESPONSE,
  DUMMY_TASK_ASSIGNED_BY_USER_OBJECT,
  DUMMY_TASK_ASSIGNED_TO_USER_OBJECT,
  DUMMY_FETCH_EXPORT_CONTROL,
  DUMMY_UPDATE_EXPORT_CONTROL,
  DUMMY_UPDATE_CUSTOMS_BROKER,
  FORMATTED_DUMMY_CHEMICAL_ORDER_DATA,
  FORMATTED_DUMMY_HAZMAT_ORDER_DATA,
  FORMATTED_DUMMY_BATTERY_ORDER_DATA,
  FORMATTED_DUMMY_BATTERY_PACKED_ORDER_DATA,
  FORMATTED_DUMMY_FREIGHT_ORDER_DATA,
} from '../dummyData';

import {
  HAZMAT_BATTERIES, HAZMAT_BATTERIES_CONTAINED_OR_PACKED, HAZMAT_CHEMICAL, NEW_ORDER_TAB_STRINGS,
} from '../clientConstants';

const {
  sender, recipient,
  regulationAndCompliance,
  billingAndPayment,
  packagingAndContents, itemAndProductDetails, shippingDetails, aes,
  shippingDocuments, exportCompliance, customsBroker,
} = NEW_ORDER_TAB_STRINGS;

const dummyOrderConfig = {
  // Change Freight and HazmatOption to be able to see different product forms (Product, Hazmat Biological, Hazmat Chemical)
  freightType: 'freight',
  // hazmatOption: 'batteriesWithEquipment',
  incomplete: [
    customsBroker,
    // Uncomment the panes that you want to display as incomplete
    // sender,
    // recipient,
    // exportCompliance,
    // regulationAndCompliance,
    // aes,
    // packagingAndContents,
    // itemAndProductDetails,
    // shippingDetails,
    // 'confirm',
    // billingAndPayment,
  ],
  assignedByUser: [
    // Uncomment the panes to appear as if they've been assigned by the user
    // sender,
    // recipient,
    // regulationAndCompliance,
    // packagingAndContents,
    // itemAndProductDetails,
    // aes,
    // exportCompliance,
  ],
  assignedToUser: [
    // Uncomment to display as if current user has been assigned
    // sender,
    // recipient,
    // regulationAndCompliance,
    // packagingAndContents,
    // itemAndProductDetails,
    // aes,
    // exportCompliance,
  ],
  numberOfShipments: 1,
  status: '',
};

export const dummySingleOrder = getModifiedOrder(dummyOrderConfig);

export function getModifiedOrder({
  freightType, hazmatOption, incomplete, assignedByUser, assignedToUser, status,
}) {
  let orderData = FORMATTED_DUMMY_ORDER_DATA;

  if (freightType === 'freight') orderData = FORMATTED_DUMMY_FREIGHT_ORDER_DATA;

  if (freightType === 'hazmat') {
    if (hazmatOption && hazmatOption === HAZMAT_CHEMICAL) {
      orderData = FORMATTED_DUMMY_CHEMICAL_ORDER_DATA;
    } else if (hazmatOption && hazmatOption === HAZMAT_BATTERIES) {
      orderData = FORMATTED_DUMMY_BATTERY_ORDER_DATA;
    } else if (hazmatOption && hazmatOption === HAZMAT_BATTERIES_CONTAINED_OR_PACKED) {
      orderData = FORMATTED_DUMMY_BATTERY_PACKED_ORDER_DATA;
    } else { orderData = FORMATTED_DUMMY_HAZMAT_ORDER_DATA; }
  }

  const completedCopy = {
    ...orderData,
    shipments: { ...(orderData.shipments) },
  };

  Object.keys(completedCopy.shipments).forEach((shipmentId) => {
    completedCopy.shipments[shipmentId] = { ...orderData.shipments[shipmentId] };
  });

  const shipmentIds = Object.keys(completedCopy.shipments);
  const shipmentId = shipmentIds.length ? shipmentIds[0] : null;
  // Names of the panels and their dependencies
  const panels = {
    [sender]: ['pickupDropoff', 'pickupFrom', packagingAndContents, shippingDetails,
      billingAndPayment, recipient, itemAndProductDetails],
    [recipient]: [packagingAndContents, shippingDetails, billingAndPayment],
    [packagingAndContents]: [shippingDetails, billingAndPayment],
    [shippingDetails]: [billingAndPayment],
    [billingAndPayment]: [],
    confirm: [],
    [itemAndProductDetails]: [],
    [regulationAndCompliance]: [],
    [aes]: [],
    [exportCompliance]: [],
    [customsBroker]: [],
  };

  if (!shipmentId) return orderData;

  if (incomplete && incomplete.length) {
    completedCopy.shipments[shipmentId].status = 'Created';
    for (const panel of incomplete) {
      delete completedCopy.shipments[shipmentId][panel];
      for (const dependent of panels[panel]) {
        delete completedCopy.shipments[shipmentId][dependent];
      }
    }
  }

  if (assignedByUser && assignedByUser.length) {
    completedCopy.shipments[shipmentId].isCreatedBy = true;
    for (const panel of assignedByUser) {
      if (!completedCopy.shipments[shipmentId][panel]) {
        completedCopy.shipments[shipmentId][panel] = {};
      }
      completedCopy.shipments[shipmentId][panel].task = DUMMY_TASK_ASSIGNED_BY_USER_OBJECT;
    }
  } else if (assignedToUser && assignedToUser.length) {
    completedCopy.shipments[shipmentId].isCreatedBy = false;
    for (const panel of assignedToUser) {
      if (!completedCopy.shipments[shipmentId][panel]) {
        completedCopy.shipments[shipmentId][panel] = {};
      }
      completedCopy.shipments[shipmentId][panel].task = DUMMY_TASK_ASSIGNED_TO_USER_OBJECT;
    }
  }
  const orderId = shipmentId.split('-').slice(0, 2).join('-');
  for (let i = 2; i < dummyOrderConfig.numberOfShipments + 1; i += 1) {
    const additionalShipmentId = `${orderId}-0${i}`;
    completedCopy.shipments[additionalShipmentId] = {
      ...completedCopy.shipments[shipmentId],
      shipmentId: additionalShipmentId,
    };
  }
  if (status) {
    completedCopy.shipments[shipmentId].status = status;
  }

  return completedCopy;
}

function loadAllOrders() {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return Promise.resolve(DUMMY_FETCH_ALL_ORDERS_DATA);
  }
  return client('/data/order', { method: 'get' });
}

function loadSingleOrderDetails(orderId) {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return Promise.resolve(dummySingleOrder);
    // return Promise.reject(new Error('User does not have permissions on the order'));
  }
  return client(`/data/order/${orderId}`, {
    method: 'get',
  });
}

function createOrder(details) {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    const data = details && details.hazmatOption === HAZMAT_CHEMICAL
      ? FORMATTED_DUMMY_CHEMICAL_ORDER_DATA
      : FORMATTED_DUMMY_ORDER_DATA;

    return Promise.resolve(data);
  }
  // if (['development', 'test'].includes(process.env.NODE_ENV)) {
  //   return Promise.resolve({
  //     shipment: {
  //       ...DUMMY_INIT_ORDER_RESPONSE.shipments['YALE-10000120-01'],
  //     },
  //   });
  // }

  return client('/data/order', {
    method: 'post',
    data: {
      totalShipments: 1,
      ...details,
      // orderType: 'NON-RMS',
      // purpose: 'Personal',
    },
  });
}

function fetchOrder({ orderId }) {
  return client(`/data/order/${orderId}`, {
    method: 'get',
  });
}

function submitShipment(shipmentId) {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          shipment: {
            ...dummySingleOrder.shipments[shipmentId],
            ...DUMMY_UPDATE_EXPORT_CONTROL,
            ...DUMMY_UPDATE_CUSTOMS_BROKER,
            status: 'Completed',
          },
        });
      }, 1000);
    });
  }
  return client(`/data/shipment/submit/${shipmentId}`, { method: 'put' });
}
function submitShipmentIpd(shipmentId, expectedShipDate) {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          shipment: {
            ...dummySingleOrder.shipments[shipmentId],
            ...DUMMY_UPDATE_EXPORT_CONTROL,
            ...DUMMY_UPDATE_CUSTOMS_BROKER,
            status: 'Completed',
          },
        });
      }, 1000);
    });
  }
  return client(`/data/shipment/submit/${shipmentId}`, { method: 'put', data: { expectedShipDate } });
}
function addShipmentToOrder(orderId) {
  const addShipmentResponse = {
    info: `Shipment ${orderId}-02 saved successfully`,
    shipment: {
      orderId,
      docType: 'SHIPMENT',
      account: 'account1',
      status: 'Created',
      createdBy: {
        userId: '1000000094',
        email: '6d112da618c38738fb7aab8d04f1c1262e3e09c302c081138e84e41c679a48b3',
        userType: 'shipper',
        account: 'account1',
      },
      createdAt: '2019-10-15T14:16:22Z',
      auditTrail: {
        description: 'createShipment',
        updatedBy: {
          userId: '1000000094',
          email: '6d112da618c38738fb7aab8d04f1c1262e3e09c302c081138e84e41c679a48b3',
          userType: 'shipper',
          account: 'account1',
        },
        updatedAt: '2019-10-15T14:16:22Z',
      },
      isCreatedBy: true,
      shipmentId: `${orderId}-02`,
    },
  };
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return Promise.resolve(addShipmentResponse).then((res) => res.shipment);
  }
  return client('/data/shipment/add', { method: 'put', data: { orderId } }).then((res) => res.shipment);
}
async function cancelShipmentFromOrder(orderId, shipmentId) {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return { msg: `Successfully cancelled shipment ${shipmentId}` };
  }
  return client('/data/shipment/cancel', { method: 'put', data: { shipmentId } });
}

function addSender({ shipmentId, payload }) {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    const dummyOrderShipmentId = Object.keys(dummySingleOrder.shipments)[0];
    return Promise.resolve({
      shipment: {
        ...dummySingleOrder.shipments[dummyOrderShipmentId],
        ...payload,
      },
      // isShipmentResetAvailable: false,
      // resetMessage: 'Shipment cannot be changed as payment has been completed',
    });
  }
  return client('/data/shipment/sender', {
    method: 'put',
    data: {
      shipmentId,
      payload,
    },
  });
}

function addPickupDrop({ shipmentId, payload }) {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return Promise.resolve({
      shipment: {
        ...dummySingleOrder.shipments[shipmentId],
        ...payload,
      },
    });
  }
  return client('/data/shipment/pickupdropoff', {
    method: 'put',
    data: {
      shipmentId,
      payload,
    },
  });
}

function addAES({ shipmentId, payload }) {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return Promise.resolve({
      shipment: {
        ...dummySingleOrder.shipments[shipmentId],
        ...payload,
      },
    });
  }
  return client('/data/shipment/aes', {
    method: 'put',
    data: {
      shipmentId,
      payload,
    },
  });
}

async function addRecipient({ shipmentId, details }) {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return Promise.resolve({
      shipment: {
        ...dummySingleOrder.shipments[shipmentId],
        destination: details,
      },
      // isShipmentResetAvailable: false,
      // resetMessage: 'Shipment cannot be changed as payment has been completed',
    });
  }
  return client('/data/shipment/recipient', {
    method: 'put',
    data: {
      shipmentId,
      destinationDetails: details,
    },
  });
}

async function addCustomsBroker({ shipmentId, details }) {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          shipment: {
            ...dummySingleOrder.shipments[shipmentId],
            customsBroker: details,
          },
        });
      }, 1000);
    });
  }
  return client('/data/shipment/customsbroker', {
    method: 'put',
    data: {
      shipmentId,
      customsBroker: details,
    },
  });
}

async function updateRegulationAndCompliance({ shipmentId, compliance }) {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          shipment: {
            ...dummySingleOrder.shipments[shipmentId],
            compliance,
          },
        });
      }, 1000);
    });
  }
  return client('/data/shipment/compliance', {
    method: 'put',
    data: {
      shipmentId,
      compliance,
    },
  });
}

async function updateItemProductDetails(data) {
  const formData = new FormData();
  formData.append('shipment', JSON.stringify(data.shipment));
  if (!isNil(data.itemImage)) formData.append('itemImage', data.itemImage);
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    const shipment = {
      shipment: {
        ...dummySingleOrder.shipments[data.shipment.shipmentId],
        product: data.shipment.product,
      },
    };
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(shipment);
      }, 1000);
    });
  }
  return client('/data/shipment/product', {
    method: 'put',
    data: formData,
    headers: {
      'content-type': 'form-data',
    },
  });
}

async function updatePackagingDetails({ shipmentId, packaging }) {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          // shipment: {
          //   ...dummySingleOrder.shipments[shipmentId],
          //   packaging,
          // },
          isShipmentResetAvailable: false,
          resetMessage: 'Shipment cannot be changed as payment has been completed',

        });
      }, 1000);
    });
  }
  return client('/data/shipment/packaging', {
    method: 'put',
    data: {
      shipmentId,
      packaging,
    },
  });
}

async function updateCarrierDetails({ shipmentId, carrier }) {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          shipment: {
            ...dummySingleOrder.shipments[shipmentId],
            carrier,
          },
          // isShipmentResetAvailable: false,
          // resetMessage: 'Shipment cannot be changed as payment has been completed',
        });
      }, 1000);
    });
  }
  return client('/data/shipment/carrier', {
    method: 'put',
    data: {
      shipmentId,
      carrier,
    },
  });
}

function updateBillingPayment({ shipmentId, payload }) {
  const { billingKey = 'billing' } = payload || {};
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          shipment: {
            ...dummySingleOrder.shipments?.[shipmentId],
            [billingKey]: dummySingleOrder.shipments?.[shipmentId]?.[billingKey],
          },
        });
      }, 1000);
    });
  }
  return client('/data/shipment/billingpayment', {
    method: 'put',
    data: {
      shipmentId,
      billingKey,
      payload,
    },
  });
}

function uploadDocument({
  shipmentId, document, documentType, packageId, documentId, trackingNumber,
}) {
  const formData = new FormData();
  formData.append('shipmentId', shipmentId);
  formData.append('documentType', documentType);
  formData.append('document', document);
  if (packageId) formData.append('packageId', packageId);
  if (documentId) formData.append('documentId', documentId);
  if (trackingNumber) formData.append('trackingNumber', trackingNumber);

  const options = {
    method: 'post',
    data: formData,
    headers: {
      'content-type': 'form-data',
    },
  };

  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          shipment: {
            ...dummySingleOrder.shipments[shipmentId],
          },
        });
      }, 1000);
    });
  }

  return client('/data/shipment/document', options);
}

function deleteDocument(payload) {
  const data = omitBy(payload, isEmpty);

  const options = {
    method: 'delete',
    data,
  };

  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          shipment: {
            ...dummySingleOrder.shipments[payload.shipmentId],
          },
        });
      }, 1000);
    });
  }

  return client('/data/shipment/document', options);
}

function signDocument(payload) {
  const data = omitBy(payload, isEmpty);

  const options = {
    method: 'post',
    data,
  };

  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          shipment: {
            ...dummySingleOrder.shipments[payload.shipmentId],
          },
        });
      }, 1000);
    });
  }

  return client('/data/shipment/document/sign', options);
}

function submitDocuments({ shipmentId }) {
  const options = {
    method: 'put',
  };
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          shipment: {
            ...dummySingleOrder.shipments[shipmentId],
            documentation: {
              ...dummySingleOrder.shipments[shipmentId].documentation,
              isDocumentsSubmitted: true,
            },
          },
        });
      }, 1000);
    });
  }

  return client(`/data/shipment/compliance/${shipmentId}`, options);
}

async function updateCustomsClearance({ shipmentId, customsClearance }) {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          shipment: {
            ...dummySingleOrder.shipments[shipmentId],
            customsClearance,
          },
        });
      }, 1000);
    });
  }
  return client('/data/shipment/customsclearance', {
    method: 'put',
    data: {
      shipmentId,
      customsClearance,
    },
  });
}

function updateSchedulePickup({ shipmentId, payload }) {
  return client('/data/shipment/pickup', {
    method: 'post',
    data: {
      shipmentId, payload,
    },
  });
}

function updateAESDetails({ shipmentId, payload }) {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          shipment: {
            ...dummySingleOrder.shipments[shipmentId],
            ...payload,
          },
        });
      }, 1000);
    });
  }
  return client('/data/shipment/aes', {
    method: 'put',
    data: {
      shipmentId,
      payload,
    },
  });
}

function updateExportControl({ shipmentId, payload }) {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          shipment: {
            ...dummySingleOrder.shipments[shipmentId],
            ...(payload && payload.type == 'approve' && payload),
            ...(payload && payload.type == 'load' && DUMMY_FETCH_EXPORT_CONTROL),
            ...(payload && payload.type == 'update' && DUMMY_UPDATE_EXPORT_CONTROL),
          },
        });
      }, 1000);
    });
  }

  if (payload && payload.type == 'update') {
    return client('/data/shipment/exportcontrol', {
      method: 'put',
      data: {
        shipmentId,
        payload,
      },
    });
  } if (payload && payload.type == 'approve') {
    return client('/data/shipment/exportcontrol', {
      method: 'put',
      data: {
        shipmentId,
        payload,
      },
    });
  }
  return client(`/data/shipment/exportcontrol/${shipmentId}`, { method: 'get' });
}

function cancelSchedulePickup({ shipmentId }) {
  return client('/data/shipment/pickup/cancel', {
    method: 'post',
    data: { shipmentId },
  });
}

export {
  loadAllOrders,
  loadSingleOrderDetails,
  fetchOrder,
  addSender,
  addPickupDrop,
  addAES,
  createOrder,
  addRecipient,
  addCustomsBroker,
  updateCustomsClearance,
  updatePackagingDetails,
  updateRegulationAndCompliance,
  updateItemProductDetails,
  updateCarrierDetails,
  updateBillingPayment,
  updateAESDetails,
  updateExportControl,
  submitShipment,
  submitShipmentIpd,
  addShipmentToOrder,
  cancelShipmentFromOrder,
  updateSchedulePickup,
  cancelSchedulePickup,
  uploadDocument,
  deleteDocument,
  signDocument,
  submitDocuments,
};
