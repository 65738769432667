import React, {
  memo, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  CircularProgress, Grid, IconButton, Typography,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { makeStyles } from '@material-ui/core/styles';
import * as colors from '../../styles/colors';
import SignDocumentCanvas from './SignDocumentCanvas';
import NewOrderNextButtonClear from './NewOrderNextButtonClear';
import NewOrderNextButton from './NewOrderNextButton';
import { SIGNATURE_SECTION_ID } from '../../clientConstants';

const useStyles = makeStyles(() => ({
  card: {
    width: '100%',
    backgroundColor: colors.darkBlueBackground,
    padding: '20px 15px 0',
    marginTop: '1rem',
  },
  header: {
    fontSize: 20,
    fontWeight: 500,
    color: colors.white,
    letterSpacing: '0.25px',
  },
  innerContainer: {
    margin: '2rem 0',
    width: '100%',
  },
  tableContainer: {
    flexGrow: 2,
    overflow: 'hidden',
    position: 'relative',
  },
  buttonsContainer: {
    width: 270,
    marginBottom: '20px',
  },
  progress: {
    marginTop: '15px',
  },
}));

const SignatureManagement = ({
  signature, readOnly, showButtons, onSubmit,
}) => {
  const classes = useStyles();
  const signatureRef = useRef(null);
  const [currentSignature, setCurrentSignature] = useState('');
  const [isReadOnly, setIsReadOnly] = useState(readOnly);

  const handleSignatureEnd = (current) => {
    setCurrentSignature(current);
  };

  const handleEdit = () => {
    setIsReadOnly(false);

    if (!signatureRef.current.isEmpty()) {
      signatureRef.current.clear();
    }
  };

  return (
    <Grid id={SIGNATURE_SECTION_ID} xs={12} container item justify="space-between" alignItems="center">
      <Grid item>
        <Typography className={classes.header}>Signature</Typography>
      </Grid>
      <Grid item>
        <IconButton onClick={handleEdit}>
          <EditIcon />
        </IconButton>
      </Grid>
      <Grid container item className={classes.innerContainer}>
        <SignDocumentCanvas
          ref={signatureRef}
          userSignature={signature}
          showSignatureSubtitle={false}
          readOnly={isReadOnly}
          onSignatureEnd={handleSignatureEnd}
        />
      </Grid>
      {showButtons && !isReadOnly && (
        <Grid container justify="center">
          {isReadOnly ? <CircularProgress color="secondary" className={classes.progress} /> : (
            <Grid item container justify="space-between" className={classes.buttonsContainer}>
              <NewOrderNextButtonClear
                disabled={isReadOnly}
                onClick={() => setIsReadOnly(true)}
              >
                Cancel
              </NewOrderNextButtonClear>
              <NewOrderNextButton
                className={classes.primaryButton}
                onClick={() => {
                  if (onSubmit) {
                    onSubmit(
                      {
                        signature: signatureRef.current
                          .getTrimmedCanvas()
                          .toDataURL('image/png'),
                      },
                      null,
                      setIsReadOnly,
                    );
                  }
                }}
              >
                Submit
              </NewOrderNextButton>
            </Grid>
          )}
        </Grid>
      )}
    </Grid>
  );
};

SignatureManagement.defaultProps = {
  signature: '',
  readOnly: true,
  showButtons: true,
  onSubmit: null,
};

SignatureManagement.propTypes = {
  signature: PropTypes.string,
  readOnly: PropTypes.bool,
  showButtons: PropTypes.bool,
  onSubmit: PropTypes.func,
};

export default memo(SignatureManagement);
