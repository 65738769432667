import React from 'react';
import {
  SvgIcon,
} from '@material-ui/core';

const ChemicalIcon = (props) => (
  <SvgIcon {...props}>
    <path
      d="M 1.9826018,21.6 Q 0.72258834,21.6 0.19758274,20.43 -0.32742284,19.26 0.54258641,18.3 L 7.9826655,9.9 V 1.8 H 6.4226489 Q 6.0326448,1.8 5.7776421,1.545 5.5226394,1.29 5.5226394,0.9 q 0,-0.39 0.2550027,-0.645 Q 6.0326448,0 6.4226489,0 h 9.6001021 q 0.390004,0 0.645007,0.255 0.255003,0.255 0.255003,0.645 0,0.39 -0.255003,0.645 Q 16.412755,1.8 16.022751,1.8 h -1.560017 v 8.1 l 7.44008,8.4 q 0.870009,0.96 0.345003,2.13 -0.525005,1.17 -1.785019,1.17 z M 1.6225979,19.8 H 20.822802 L 12.662715,10.56 V 1.8 H 9.7826847 v 8.76 z m 9.5401011,-9 z"
    />
  </SvgIcon>
);

export default ChemicalIcon;
