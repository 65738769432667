/* eslint-disable react/prop-types */
import React, { memo, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';
import has from 'lodash/has';
import map from 'lodash/map';
import omit from 'lodash/omit';
import {
  Button,
  CircularProgress,
  Grid,
  Typography,
  DialogContentText,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/CheckCircleOutlineSharp';
import { green } from '@material-ui/core/colors';

import * as colors from '../styles/colors';
import { Page } from './common';
import UploadInput from './common/UploadInput';
import { addBulk } from '../utils/addressBookClient';
import SampleCSV from '../assets/address-list-sample.csv';
import { NEW_ORDER_MODULE_STYLE } from '../styles/style';
import NewOrderErrorDialog from './common/NewOrderErrorDialog';
import NewOrderAlertDialog from './common/NewOrderAlertDialog';

import PanelWarning from './common/PanelWarning';
import NewOrderNextButtonClear from './common/NewOrderNextButtonClear';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  page: {
    padding: '25px',
    height: '80vh',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
  },
  innerContainer: {
    background: colors.tableBackground,
    overflow: 'auto',
    height: '100%',
    padding: '20px',
    marginTop: '20px',
  },
  headerText: {
    color: colors.white,
    fontSize: 24,
    fontWeight: 500,
  },
  listItemHeader: {
    marginTop: '16px',
  },
  listItem: {
    paddingInlineStart: '25px',
    fontSize: '14px',
  },
  errorHeaderText: {
    fontSize: '14px',
    marginTop: '10px',
  },
  link: {
    color: colors.primaryBlue,
  },
  uploadInputInnerContainer: {
    width: '50%',
    marginTop: '20px',
  },
  errorContainer: {
    marginTop: '20px',
  },
  buttonContainer: {
    marginTop: '10px',
    marginBottom: '10px',

  },
  newContactButton: {
    width: 150,
    background: colors.newContactButtonBackground,
    color: colors.white,
    height: 40,
    borderRadius: 10,
    padding: '0 20px',
  },
}));

function UploadAddress({
  refreshContacts,
  closeModal,
}) {
  const uploadRef = useRef();

  const classes = useStyles();
  const errorClasses = NEW_ORDER_MODULE_STYLE();
  const [file, setFile] = useState(null);
  const [errorContent, setErrorContent] = useState(null);
  const [successContent, setSuccessContent] = useState(null);

  const [isProcessingFile, setProcessingFile] = useState(false);

  const handleUploadFile = () => {
    setProcessingFile(true);

    addBulk(file, true).then((response) => {
      setProcessingFile(false);
      refreshContacts();
      setFile(null);
      // eslint-disable-next-line no-unused-expressions
      uploadRef.current?.setFileName('');
      setSuccessContent('Address Uploaded Successfully');
    }).catch((e) => {
      const parsedError = JSON.parse(e.message);
      setErrorContent(parsedError);
      setProcessingFile(false);

      // setErrorContent(parsedError);
    });
  };

  const getFormattedLineError = (key, value) => {
    const numberIdx = key.indexOf('e') + 1;
    const formattedKey = `${key.substring(0, numberIdx)} ${key.substring(numberIdx, key.length)}`;
    const capitalizedKey = formattedKey.charAt(0).toUpperCase() + formattedKey.slice(1);

    let message = '';

    if (Array.isArray(value)) {
      value.forEach((reason, idx) => {
        if (idx === 0) {
          message = `${reason}.`;

          return;
        }

        message = `${message} ${reason}.`;
      });
    } else {
      message = value;
    }

    return {
      key: capitalizedKey,
      message,
    };
  };

  return (
    <>
      <div className={classes.root}>
        <Grid className={classes.innerContainer} container item>
          <Grid
            direction="column"
            container
            item
          >
            <Typography>
              Import address from
              {' '}
              <b><i>CSV file</i></b>
              {' '}
              using the form below.
            </Typography>
            <Typography style={{ marginTop: '16px' }}>
              <b>
                Import Address From CSV file
              </b>
            </Typography>

            <Typography className={classes.listItemHeader} component="ul">
              <b>
                Step 1
              </b>
            </Typography>
            <Typography className={classes.listItem} component="li">
              The Comma separated (CSV) file should comply with following order of columns.
            </Typography>
            <Typography className={classes.listItem} component="li">
              Name, Address Line 1, Address Line 2, City, State, Zip, Phone, Phone Country, Country, Email, Company Name.
            </Typography>
            <Typography className={classes.listItem} component="li">
              Please ensure that the Phone number and Zipcode fields are in Text Format to avoid truncation of
              leading zeros when importing.
            </Typography>
            <Typography className={classes.listItem} component="li">
              Phone Country, Country and State fields must be 2 character codes.
            </Typography>
            <Typography className={classes.listItem} component="li">
              <a className={classes.link} href={SampleCSV}>Sample Comma separated (csv) file.</a>
            </Typography>

            <Typography className={classes.listItemHeader} component="ul">
              <b>
                Step 2
              </b>
            </Typography>
            <Typography className={classes.listItem} component="li">
              Click &quot;Choose File&quot;, select the csv file and click &quot;Save&quot; to process the
              file.
            </Typography>

            <Grid container item justifyContent="center">
              <Grid className={classes.uploadInputInnerContainer} item>
                <UploadInput
                  name="document"
                  acceptedFiles=".csv"
                  showIcon={false}
                  ref={uploadRef}
                  onChange={(currentFile) => {
                    setFile(currentFile);

                    if (has(errorContent, 'error')) {
                      setErrorContent(null);
                    }
                  }}
                />
              </Grid>
            </Grid>

            <Grid className={classes.errorsContainer} container item>
              {has(errorContent, 'error') && isObject(errorContent.error)
                && map(errorContent.error, (value, key) => {
                  const formattedLine = getFormattedLineError(key, value);

                  return (
                    <>
                      <Typography className={classes.errorHeaderText}>The address(s) were not imported to the Address Book. Please correct the errors below and try again:</Typography>
                      <PanelWarning message={`${formattedLine.key}: ${formattedLine.message}`} />
                    </>
                  );
                })}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          container
          direction="row"
          className={classes.buttonContainer}
          justifyContent="space-around"
          alignItems="center"
        >
          <Grid
            item
          >
            <NewOrderNextButtonClear
              disabled={isProcessingFile}
              className={classes.submitButton}
              onClick={closeModal}
            >
              Cancel
            </NewOrderNextButtonClear>

          </Grid>
          <Grid item>
            <Button
              className={classes.newContactButton}
              disabled={isProcessingFile || !file}
              onClick={() => handleUploadFile(file)}
            >
              {isProcessingFile ? (
                <CircularProgress size="2rem" color="white" />
              ) : 'Save'}
            </Button>
          </Grid>
        </Grid>
      </div>
      <NewOrderAlertDialog
        open={isString(successContent)}
        alertContent={(
          <DialogContentText
            id="alert-confirm-dialog"
            component="div"
            style={{ paddingLeft: '10px', paddingRight: '10px' }}
          >
            <Grid
              item
              container
              spacing={2}
              direction="column"
              style={{
                paddingTop: '20px', display: 'flex', alignItems: 'center',
              }}
            >
              <CheckIcon fontSize="inherit" style={{ color: green[500], fontSize: 45 }} />

              <Grid item container>
                {successContent}

              </Grid>
            </Grid>
          </DialogContentText>
)}
        classes={errorClasses}
        onClose={() => {
          setSuccessContent(null);
        }}
      />
      <NewOrderErrorDialog
        open={has(errorContent, 'error') && isString(errorContent.error)}
        errorContent={has(errorContent, 'error') ? errorContent.error : ''}
        classes={errorClasses}
        onClose={() => {
          setErrorContent(null);
        }}
      />
    </>
  );
}

export default memo(UploadAddress);
