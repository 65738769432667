import React from 'react';
import PropTypes from 'prop-types';
import isNil from 'lodash/isNil';
import { Grid, Typography } from '@material-ui/core';
import { FORM_FIELDS } from '../../itemDetailsConstants';
import { useItemProductDetailsFormStyles } from '../../itemDetailsStyles';
import NewOrderNextButtonClear from '../../../common/NewOrderNextButtonClear';
import UploadImage from '../../../common/UploadImage';

export default function ProductImage({
  disabled,
  itemUrl,
  itemFromCatalog,
  formikProps,
  customImage,
  setCustomImage,
}) {
  const [isImageUploadModalOpen, setIsImageUploadModalOpen] = React.useState(false);
  const [customImageBase64, setCustomImageBase64] = React.useState(null);

  const src = customImageBase64 || itemUrl;

  React.useEffect(() => {
    setCustomImage(null);
  }, [itemFromCatalog]);

  React.useEffect(() => {
    formikProps.setFieldValue(FORM_FIELDS.itemImage.key, customImage);
    if (isNil(customImage)) setCustomImageBase64(null);
  }, [customImage]);

  const classes = useItemProductDetailsFormStyles();
  return (
    <Grid item container className={classes.productImageContainer} alignItems="flex-end">
      {src
        ? (
          <img src={src} className={classes.iamge} alt="" />
        )
        : (
          <Grid
            container
            item
            className={disabled ? classes.emptyImageContainerDisabled : classes.emptyImageContainerEnabled}
            justify="center"
            alignItems="center"
          >
            <Grid item>
              <Typography
                className={disabled ? classes.emptyImageTextDisabled : classes.emptyImageTextEnabled}
              >
                No Image
              </Typography>
            </Grid>
          </Grid>
        )}
      <Grid item container spacing={1} style={{ width: 'auto' }}>
        <Grid item>
          <NewOrderNextButtonClear disabled={disabled} style={{ width: '125px' }} onClick={() => setIsImageUploadModalOpen(true)}>
            Upload Image
          </NewOrderNextButtonClear>
        </Grid>
        {customImage && (
        <Grid item>
          <NewOrderNextButtonClear style={{ width: '125px' }} onClick={() => setCustomImage(null)}>
            Delete Image
          </NewOrderNextButtonClear>
        </Grid>
        )}
      </Grid>
      <UploadImage
        open={isImageUploadModalOpen}
        onClose={() => setIsImageUploadModalOpen(false)}
        previewSquare
        setBase64Image={setCustomImageBase64}
        setFileImage={setCustomImage}
      />
    </Grid>
  );
}

ProductImage.propTypes = {
  disabled: PropTypes.bool.isRequired,
  itemUrl: PropTypes.string.isRequired,
  formikProps: PropTypes.objectOf(PropTypes.any).isRequired,
  itemFromCatalog: PropTypes.objectOf(PropTypes.any),
  customImage: PropTypes.string.isRequired,
  setCustomImage: PropTypes.func.isRequired,
};

ProductImage.defaultProps = {
  itemFromCatalog: null,
};
