import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Title, SubTitle } from '../components/common';
import Logo from '../assets/verizeal-logo';
import NavigateToLoginButton from '../components/NavigateToLoginButton';

const styles = {
  root: {
    flexGrow: 1,
    backgroundColor: '#151721',
    height: '100%',
    width: '100%',
    overflowX: 'hidden',
  },
  contentContainer: {
    paddingTop: 200,
    textAlign: 'center',
  },
  logo: {
    height: 20,
    width: 200,
  },
  formContainer: {
    marginTop: 20,
  },
};

const useStyles = makeStyles(styles);

export default function AccountSetupConfirmation() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid
        container
        className={classes.contentContainer}
        justify="flex-start"
        alignItems="center"
        direction="column"
        spacing={2}
      >
        <Grid item md={4} sm={6} xs={10}>
          <Logo className={classes.logo} />
        </Grid>
        <Grid item md={4} sm={6} xs={10}>
          <Title>Welcome!</Title>
        </Grid>
        <Grid item md={4} sm={6} xs={10}>
          <SubTitle>
            Your account is all set up and ready to go.
          </SubTitle>
        </Grid>
        <Grid item md={4} sm={6} xs={10}>
          <NavigateToLoginButton />
        </Grid>
      </Grid>
    </div>
  );
}
