import React from 'react';
import * as shipmentClient from '../utils/shipmentClient';
import { useLogger } from '../utils/hooks';

const shipmentManifestState = { shipments: [], allShipments: [] };
const ShipmentManifestContext = React.createContext();
const ShipmentManifestDispatchContext = React.createContext();

// shipmentReducer Action Types
const LOAD_SHIPMENTS = 'LOAD SHIPMENT MANIFEST';
const LOAD_ALL_SHIPMENTS = 'LOAD ALL SHIPMENT MANIFEST';

function shipmentManifestReducer(state, action) {
  const { shipments, offset } = action;
  // eslint-disable-next-line max-len
  const newShipments = action.type === LOAD_SHIPMENTS ? state.shipments.slice(0, offset).concat(shipments) : shipments;
  switch (action.type) {
    case LOAD_SHIPMENTS: {
      return {
        ...state,
        shipments: newShipments,
      };
    }
    case LOAD_ALL_SHIPMENTS: {
      return {
        ...state,
        allShipments: newShipments,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function ShipmentManifestProvider(props) {
  const [state, dispatch] = useLogger(
    React.useReducer(shipmentManifestReducer, shipmentManifestState || { shipments: null }),
  );

  return (
    <ShipmentManifestContext.Provider value={state} {...props}>
      <ShipmentManifestDispatchContext.Provider
        value={dispatch}
        {...props}
      />
    </ShipmentManifestContext.Provider>
  );
}

// TODO: update the shipmentClient.loadShipments function
// to handle these additional parameters (they will go in the request body)
function getShipmentManifest(dispatch, {
  ...filters
}, offset, limit) {
  return shipmentClient.loadShipmentManifest({
    limit,
    offset,
    filters,
  }).then((data) => {
    dispatch({ type: LOAD_SHIPMENTS, shipments: data, offset });
    return data;
  });
}
function getAllShipments(dispatch, filters) {
  return shipmentClient
    .loadShipmentManifest({
      limit: null,
      offset: 0,
      filters,
    })
    .then((data) => {
      dispatch({ type: LOAD_ALL_SHIPMENTS, shipments: data, offset: 0 });
      return data;
    });
}

function loadRecentShipments(dispatch, filters = {}, offset = 0, limit = 5) {
  return shipmentClient.loadShipments({
    offset, limit, filters,
  }).then((data) => {
    dispatch({ type: LOAD_SHIPMENTS, shipments: data, offset });
    return data;
  });
}

function useShipmentManifestState() {
  const context = React.useContext(ShipmentManifestContext);
  if (context === undefined) {
    throw new Error(
      'useShipmentManifest must be used within a ShipmentManifestProvider',
    );
  }
  return context;
}

function useShipmentManifestDispatch() {
  const context = React.useContext(ShipmentManifestDispatchContext);
  if (context === undefined) {
    throw new Error(
      'useShipmentManifestDispatch must be used within a ShipmentManifestProvider',
    );
  }
  return context;
}

export {
  ShipmentManifestProvider,
  useShipmentManifestState,
  useShipmentManifestDispatch,
  getShipmentManifest,
  loadRecentShipments,
  getAllShipments,
};
