/* eslint-disable max-len */
import React from 'react';
import { Link } from 'react-router-dom';
import { Field, Form, Formik } from 'formik';
import get from 'lodash/get';
import CloseIcon from '@material-ui/icons/Close';
import {
  FormControl, FormControlLabel, FormLabel, Grid,
  CircularProgress, Radio, RadioGroup, Typography,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText, Tabs, Tab, Box, AppBar,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { NEW_ORDER_CONFIRMATION_STYLE } from '../styles/style';
import * as colors from '../styles/colors';
import NewOrderCustomTextField from './common/NewOrderCustomTextField';
import { useUserState } from '../context/userContext';
import NewOrderNextButton from './common/NewOrderNextButton';
import { disableSubmitOnEnterKey } from '../utils/helpers';
import AESFillingFormFields from './AESFillingFormFields';
import UPSForm from '../assets/docs/UpsEei.pdf';
import UPSData from '../assets/docs/upseeidata.pdf';
import FedExForm from '../assets/docs/FedExFile.pdf';

function TabPanel(props) {
  const {
    // eslint-disable-next-line react/prop-types
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}
const styles = {
  panelContainer: {
    width: '100%',
    background: colors.newOrderFormBackground,
  },
  innerFormContainer: {
    padding: 15,
    margin: '10px 0',
    background: colors.darkBlueBackground,
  },
  formFieldHeader: {
    fontWeight: 500,
    fontSize: '14px',
  },
  formFieldSubHeader: {
    fontSize: '14px',
    fontWeight: 500,
  },
  alertModal: {
    height: 'auto',
    width: 650,
    maxWidth: 'none',
    background: colors.newOrderFormBackground,
    padding: '35px 25px 10px 25px',
    borderRadius: 8,
    position: 'relative',
  },
  closeIcon: {
    position: 'absolute',
    top: 10,
    right: 10,
  },
  dialogActionsContainer: {
    paddingBottom: 24,
    width: '100%',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  fileFormButton: {
    display: 'none',
  },
  miscLoadingContainer: {
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingTop: 24,
  },
};

const useStyles = makeStyles(styles);

const radioOptions = [
  {
    value: 'itnNumber',
    label: 'ITN#',
  },
  {
    value: 'ftsrCode',
    label: 'FTSR Exemption Code',
  },
  {
    value: 'file',
    label: 'I do not have an ITN# or Exemption Code',
  },
];

const dhs = 'https://ace.cbp.dhs.gov';
// eslint-disable-next-line react/prop-types
const ButtonMailto = ({ mailto, label }) => (
  <Link
    to="#"
    onClick={(e) => {
      window.location.href = mailto;
      e.preventDefault();
    }}
  >
    {label}
  </Link>
);

export default function AESFillingForm({
  submitFormToApi, shipmentId, shipment, openNextPanel,
}) {
  const user = useUserState();
  const existingValues = get(shipment, 'aesFiling.details', {});

  const subClasses = NEW_ORDER_CONFIRMATION_STYLE();
  const [loginAlert, setLoginAlert] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const fileBtnRef = React.useRef(null);
  const [tabValue, setTabValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const classes = useStyles();

  const init = () => ({
    aesFillingRequired: 'no',
    itnNumber: '',
    ftsrCode: '',
    ...existingValues,
    ...(existingValues.itnNumber && { aesFillingRequired: radioOptions[0].value }),
    ...(existingValues.ftsrCode && { aesFillingRequired: radioOptions[1].value }),
  });

  const onSubmit = async (values, { setSubmitting }) => {
    const payload = {
      aesFiling: {
        details: {
          itnNumber: get(values, 'itnNumber'),
          ftsrCode: get(values, 'ftsrCode'),
        },
      },
    };

    try {
      setIsLoading(true);
      await submitFormToApi(payload);
      setIsLoading(false);
      openNextPanel();
    } catch (e) {
      setIsLoading(false);
    }
    setSubmitting(false);
  };

  const validateItnNumber = (itnNumber, values) => {
    const ftsrCode = !!get(values, 'ftsrCode', null);
    const isValid = /^X[0-9]{14,14}$/;
    if (itnNumber && !isValid.test(itnNumber)) {
      return 'Must be valid ITN#';
    }
    return ftsrCode || itnNumber ? undefined : 'Required';
  };

  const validateFtsrCode = (ftsrCode, values) => {
    const itnNumber = !!get(values, 'itnNumber', null);
    const isValid = /^NO EEI [a-z\0-9]{1,13}$/;
    if (ftsrCode && !isValid.test(ftsrCode)) {
      return 'Must be valid FTSR Exemption Code';
    }
    return itnNumber || ftsrCode ? undefined : 'Required';
  };

  const validationSchema = Yup.object().shape({
    aesFillingRequired: Yup.string().required('Required'),
    itnNumber: Yup.string(),
    ftsrCode: Yup.string(),
  });

  return (
    <>
      <AESFillingFormFields shipmentId={shipmentId} dhs={dhs} ref={fileBtnRef} />
      <Formik
        initialValues={init()}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(formikProps) => (
          <Grid container className={classes.panelContainer} spacing={3}>
            <Grid item style={{ width: '100%' }}>
              <Form style={{ width: '100%' }} onKeyDown={disableSubmitOnEnterKey}>
                <Grid item container direction="column" spacing={2} className={classes.innerFormContainer}>
                  <Grid item>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">
                        <Grid item container alignItems="center" spacing={2}>
                          <Grid item>
                            <Typography color="primary" className={classes.formFieldHeader}>Please select from the following:</Typography>
                          </Grid>
                        </Grid>
                      </FormLabel>
                      <RadioGroup
                        aria-label="aesFillingRequired"
                        name="aesFillingRequired"
                        value={formikProps.values.aesFillingRequired}
                        onChange={(e) => formikProps.setFieldValue('aesFillingRequired', e.target.value)}
                      >
                        {radioOptions.map((option) => (
                          <FormControlLabel
                            key={option.value}
                            style={{ padding: '0 10px' }}
                            value={option.value}
                            control={<Radio style={{ color: colors.white }} />}
                            label={<Typography color="primary">{option.label}</Typography>}
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  {(formikProps.values.aesFillingRequired === radioOptions[0].value)
                    && (
                    <Grid item xs={6}>
                      <Field
                        component={NewOrderCustomTextField}
                        type="text"
                        style={{ width: '100%' }}
                        name="itnNumber"
                        label="Enter ITN#"
                        validate={(value) => validateItnNumber(value, formikProps.values)}
                      />
                    </Grid>
                    )}
                  {(formikProps.values.aesFillingRequired === radioOptions[1].value)
                    && (
                    <Grid item xs={6}>
                      <Field
                        component={NewOrderCustomTextField}
                        type="text"
                        style={{ width: '100%' }}
                        name="ftsrCode"
                        label="Enter FTSR"
                        validate={(value) => validateFtsrCode(value, formikProps.values)}
                      />
                    </Grid>
                    )}
                  {(formikProps.values.aesFillingRequired === radioOptions[2].value)
                    && (
                    <>
                      <AppBar position="static" color="default">
                        <Tabs
                          value={tabValue}
                          onChange={handleChange}
                          indicatorColor="primary"
                          textColor="primary"
                          variant="scrollable"
                          scrollButtons="auto"
                          aria-label="scrollable auto tabs example"
                        >
                          <Tab label="Automatic" {...a11yProps(0)} />
                          <Tab label="Manual" {...a11yProps(1)} />

                        </Tabs>
                      </AppBar>
                      <TabPanel value={tabValue} index={0}>

                        <Grid item container spacing={2}>
                          <Grid item container direction="column" spacing={2}>
                            <Grid item>
                              <Typography color="primary" className={classes.formFieldHeader}>Step 1: Login to ACE Portal</Typography>
                            </Grid>
                            <Grid item>
                              <NewOrderNextButton
                                disabled={false}
                                onClick={() => setLoginAlert(true)}
                              >
                                Login
                              </NewOrderNextButton>
                              <Dialog
                                open={loginAlert}
                                onClose={() => setLoginAlert(false)}
                                classes={{ paper: classes.alertModal }}
                              >
                                <Grid container direction="column" spacing={1}>
                                  <IconButton className={classes.closeIcon} onClick={() => setLoginAlert(false)}>
                                    <CloseIcon color="primary" />
                                  </IconButton>
                                  <DialogContent>
                                    <DialogContentText
                                      id="login-alert-dialog"
                                      component="div"
                                      classes={{ root: subClasses.dialogContentContainer }}
                                    >
                                      <Typography color="primary">
                                        You are being redirected to
                                        {' '}
                                        {dhs}
                                      </Typography>
                                    </DialogContentText>
                                  </DialogContent>
                                  <DialogActions classes={{ root: classes.dialogActionsContainer }}>
                                    <Grid
                                      container
                                      spacing={4}
                                      justify="center"
                                    >
                                      <Grid item>
                                        <NewOrderNextButton onClick={() => {
                                          setLoginAlert(false);
                                          window.open(dhs, '_blank');
                                        }}
                                        >
                                          Continue

                                        </NewOrderNextButton>
                                      </Grid>
                                    </Grid>
                                  </DialogActions>
                                </Grid>
                              </Dialog>
                            </Grid>
                          </Grid>
                          <Grid item container direction="column" spacing={2}>
                            <Grid item>
                              <Typography color="primary" className={classes.formFieldHeader}>Step 2: File your AES form</Typography>
                            </Grid>
                            <Grid item>
                              <NewOrderNextButton onClick={() => fileBtnRef.current.click()}>
                                File
                              </NewOrderNextButton>
                            </Grid>
                          </Grid>
                          <Grid item container spacing={2}>
                            <Grid item>
                              <Typography color="primary" className={classes.formFieldHeader}>Step 3: Please check your email for your ITN# or FTSR exemption code to complete</Typography>
                            </Grid>
                            <Grid item container direction="column" spacing={2}>
                              <Grid item xs={6}>
                                <Field
                                  component={NewOrderCustomTextField}
                                  type="text"
                                  style={{ width: '100%' }}
                                  name="itnNumber"
                                  label="Enter ITN#"
                                  validate={(value) => validateItnNumber(value, formikProps.values)}
                                />
                              </Grid>
                            </Grid>

                            <Grid item container direction="column" spacing={2}>
                              <Grid item xs={6}>
                                <Field
                                  component={NewOrderCustomTextField}
                                  type="text"
                                  style={{ width: '100%' }}
                                  name="ftsrCode"
                                  label="Enter FTSR Exemption Code"
                                  validate={(value) => validateFtsrCode(value, formikProps.values)}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </TabPanel>
                      <TabPanel value={tabValue} index={1}>
                        <Typography style={{ fontWeight: 'bold' }}>When the declared value of a shipment is over $2,500, an ITN (Internal Transaction Number) is required. This information needs to be entered on the Shipment Information section on the next step. Do not proceed without this detail.</Typography>
                        <br />
                        <Typography> Please follow the instructions below:</Typography>
                        <Typography>1) To download the forms:</Typography>
                        <Typography>
                          {' '}
                          - Federal Express:
                          {' '}
                          <a
                            href={FedExForm}
                            target="_blank"
                            rel="noreferrer"
                          >
                            click here
                          </a>
                          {' '}

                        </Typography>
                        <Typography>
                          {' '}
                          - UPS:
                          {' '}
                          <a
                            href={UPSForm}
                            target="_blank"
                            rel="noreferrer"
                          >
                            click here
                          </a>
                          {' '}
                          (please refer to this
                          {' '}
                          <a
                            href={UPSData}
                            target="_blank"
                            rel="noreferrer"
                          >
                            form
                          </a>
                          {' '}
                          as reference)

                        </Typography>
                        <Typography>2) Complete the form and email the carrier directly.</Typography>
                        <Typography>
                          - Federal Express email:
                          {' '}
                          <ButtonMailto label="eeioperations@fedex.com" mailto="mailto:eeioperations@fedex.com" />
                          {' '}
                        </Typography>
                        <Typography>
                          - UPS email:
                          <ButtonMailto label="InternationalDocs.Us@Ups.com" mailto="mailto:InternationalDocs.Us@Ups.com" />

                        </Typography>
                        <Typography>
                          -
                          {' '}
                          {'Please include "Request for ITN" on your email subject line for faster processing.'}
                        </Typography>
                        <br />
                        <Typography />
                        <Typography>
                          <span style={{ fontWeight: 'bold' }}>Note: </span>
                          {' '}
                          Kindly complete the entire form to the best of your knowledge. If you have any questions, submit the form with these minimum requirements and the carriers will help you determine the missing details:
                        </Typography>
                        <Typography>- Shipper Information.</Typography>
                        <Typography> - Consignee Information.</Typography>
                        <Typography>- Item Details (description, Schedule B Number, ECCN Number, value of the item).</Typography>
                        <br />
                        <Typography>3) You will receive an email with the ITN information usually within 24 hours.</Typography>
                        <Typography> 4) Update the ITN details below in the AES Panel</Typography>
                        <Typography>5) You will be able to complete the order and print the required paperwork.</Typography>

                        <Typography>
                          Important:
                          {' '}
                          <span style={{ fontWeight: 'bold' }}>*** Make sure you ship on the same day you receive your ITN information. ***</span>
                        </Typography>
                        <br />
                        <Grid item container spacing={2}>
                          <Grid item>
                            <Typography color="primary" className={classes.formFieldHeader}>Please check your email for your ITN# or FTSR exemption code to complete</Typography>
                          </Grid>
                          <Grid item container direction="column" spacing={2}>
                            <Grid item xs={6}>
                              <Field
                                component={NewOrderCustomTextField}
                                type="text"
                                style={{ width: '100%' }}
                                name="itnNumber"
                                label="Enter ITN#"
                                validate={(value) => validateItnNumber(value, formikProps.values)}
                              />
                            </Grid>
                          </Grid>

                          <Grid item container direction="column" spacing={2}>
                            <Grid item xs={6}>
                              <Field
                                component={NewOrderCustomTextField}
                                type="text"
                                style={{ width: '100%' }}
                                name="ftsrCode"
                                label="Enter FTSR Exemption Code"
                                validate={(value) => validateFtsrCode(value, formikProps.values)}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </TabPanel>
                    </>
                    )}
                </Grid>

                <Grid item container>
                  <Grid item>
                    <NewOrderNextButton
                      disabled={isLoading}
                      onClick={() => {
                        formikProps.submitForm();
                      }}
                    >
                      Next
                    </NewOrderNextButton>
                  </Grid>
                </Grid>
                {isLoading && (
                  <Grid item container justify="center" classes={{ root: classes.miscLoadingContainer }}>
                    <CircularProgress color="secondary" />
                  </Grid>
                )}
              </Form>
            </Grid>
          </Grid>
        )}
      </Formik>
    </>
  );
}

AESFillingForm.propTypes = {
  shipmentId: PropTypes.string.isRequired,
  setSelectedForms: PropTypes.func.isRequired,
  submitFormToApi: PropTypes.func.isRequired,
};
