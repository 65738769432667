/* eslint-disable react/prop-types */
import React from 'react';
import { css } from 'emotion';
import {
  Grid,
  Typography,
  Button,
  TextField,
  InputAdornment,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
} from '@material-ui/core';
import isString from 'lodash/isString';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';
import UploadIcon from '@material-ui/icons/Publish';
import CheckIcon from '@material-ui/icons/CheckCircleOutlineSharp';

import StarIcon from '@material-ui/icons/Star';
import CreateIcon from '@material-ui/icons/Create';
import Slide from '@material-ui/core/Slide';
import { green } from '@material-ui/core/colors';

import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as PostalCodes from 'postal-codes-js';
import parsePhoneNumber from 'libphonenumber-js';
import Navbar from '../components/Navbar';
import { Page } from '../components/common';
import * as colors from '../styles/colors';
import {
  load, edit, add, remove,
} from '../utils/addressBookClient';
import {
  NEW_ORDER_SENDER_RECIPIENT_STYLE,
  NEW_ORDER_MODULE_STYLE,
} from '../styles/style';
import { EURO_COUNTRIES } from '../clientConstants';
import AddressForm from '../components/common/AddressForm';
import NewOrderNextButton from '../components/common/NewOrderNextButton';
import NewOrderNextButtonClear from '../components/common/NewOrderNextButtonClear';
import { CustomCheckbox } from '../components/common/InputComponents';
import { loadCountries, useMiscDispatch } from '../context/miscDataContext';
import NewOrderErrorDialog from '../components/common/NewOrderErrorDialog';
import NewOrderAlertDialog from '../components/common/NewOrderAlertDialog';

import DeleteIcon from '../components/images/DeleteIcon';
import ConfirmationModal from '../components/common/ConfirmationModal';
import ScrollWindow from '../components/common/ScrollWindow';
import { PromiseOverwriter, sanitizeAddress } from '../utils/helpers';
import UploadAddress from '../components/UploadAddress';

const DEFAULT_FETCH_CONTACTS_OPTIONS = {
  limit: 50,
  offset: 0,
  filter: '',
  allLoaded: false,
};
// Used so multiple calls to the same API do not resolve out of sync.
// Only most recent call can resolve.
const loadContactsPromiseManager = new PromiseOverwriter(load);

const toCSS = (classSet) => Object.entries(classSet).reduce(
  (set, keyVal) => ({
    ...set,
    [keyVal[0]]: css(keyVal[1]),
  }),
  {},
);

const formatContactData = (contactData) => {
  if (Array.isArray(contactData)) {
    return contactData.map((contact) => ({
      ...contact,
      address: `${contact.addressLine1}
    \n
    ${[contact.city, contact.state, contact.zip, contact.country].join(', ')}`,
    }));
  }
  return (
    contactData && {
      ...contactData,
      address: `${contactData.addressLine1}
  \n
  ${[
      contactData.city,
      contactData.state,
      contactData.zip,
      contactData.country,
    ].join(', ')}`,
    }
  );
};

const isTableScrolledToBottom = (table, cardRef) => {
  const tableHeight = table ? table.clientHeight : 0;
  const tableScrollTop = table ? table.scrollTop : 0;
  const tableScroll = tableScrollTop + tableHeight;
  const lastContactOffset = cardRef && cardRef.current
    ? cardRef.current.offsetTop + cardRef.current.clientHeight
    : Infinity;

  const buffer = 0;
  const returnVal = tableScroll >= lastContactOffset - buffer;
  return returnVal;
};

const ContactButton = (props) => {
  const { title, icon, ...other } = props;
  const classes = {
    contactButton: css({
      minWidth: 30,
      paddingLeft: 5,
      paddingRight: 5,
    }),
    tooltip: css({
      background: colors.tooltipBackground,
      fontSize: 12,
    }),
  };
  return other.disabled ? (
    <Button className={`${classes.contactButton}`} {...other}>
      {icon}
    </Button>
  ) : (
    <Tooltip title={title} classes={{ tooltip: classes.tooltip }}>
      <Button className={`${classes.contactButton}`} {...other}>
        {icon}
      </Button>
    </Tooltip>
  );
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  email: Yup.string().email('Invalid email address'),
  phone: Yup.string()
    .test({
      name: 'is-valid-phone',
      message: 'Invalid phone number',
      test(value) {
        const parsedPhoneNumber = parsePhoneNumber(value || '');
        return parsedPhoneNumber && parsedPhoneNumber.isValid();
      },
    })
    .required('Required'),
  phoneCountry: Yup.string().required('Required'),
  addressLine1: Yup.string().required('Required'),
  city: Yup.string().required('Required'),
  state: Yup.string().required('Required'),
  zip: Yup.string()
    .test({
      name: 'is-valid-zip',
      message: 'Invalid postal code',
      test(value) {
        const { parent } = this;
        return PostalCodes.validate(parent.country, value) === true;
      },
    })
    .required('Required'),
  country: Yup.string().required('Required'),
  eoriNumber: Yup.string()
    .test({
      name: 'is-required',
      message: 'Required',
      test(value) {
        const { parent } = this;
        // return !(EURO_COUNTRIES.includes(parent.country) && !value);
        return true;
      },
    })
    .test({
      name: 'is-valid',
      message: 'Invalid',
      test(value) {
        let match = true;
        const { parent } = this;
        if (EURO_COUNTRIES.includes(parent.country)) {
          match = /^[A-Za-z]{2}[a-zA-Z0-9]+$/.test(value);
        }
        return match;
      },
    }),
});

const addressBookClasses = {
  root: {
    backgroundColor: colors.background,
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  page: {
    padding: '99px 20px 20px 20px',
    height: 'calc(100vh - 64px)',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
  },
  headerContainer: {
    width: '100%',
    flexShrink: '0',
  },
  headerLeftContainer: {
    display: 'flex',
    width: 'auto',
    alignItems: 'center',
  },
  headerLeftContainerText: {
    width: 'auto',
    alignItems: 'center',
  },
  headerText: {
    color: colors.white,
    fontSize: 24,
    fontWeight: 500,
  },
  textField: {
    width: 370,
    background: colors.darkInputFieldBackground,
  },

  labelRoot: {
    color: colors.white,
    fontSize: 16,
    fontWeight: 500,
    background: colors.darkInputFieldBackground,
  },
  inputRoot: {
    background: colors.darkInputFieldBackground,
  },
  endAdornmentRoot: {
    margin: 0,
    '&:not(.MuiInputAdornment-hiddenLabel)': {
      margin: '0px !important',
    },
  },
  newContactButton: {
    width: 200,
    background: colors.newContactButtonBackground,
    height: 40,
    borderRadius: 10,
    padding: '0 20px',
  },

  uploadButton: {
    width: 150,
    height: 40,
    borderRadius: 10,
    padding: '0 20px',
    marginRight: '10px',
  },
  newContactButtonText: {
    fontWeight: 700,
    color: colors.white,
    fontSize: 14,
    letterSpacing: 1.2,
  },
  tableContainer: {
    flexGrow: 2,
    padding: '20px 0',
    overflow: 'hidden',
    position: 'relative',
  },
  table: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 'auto',
    flexGrow: '2',
    background: colors.tableBackground,
    overflow: 'hidden',
  },
  row: {
    display: 'flex',
    justifyContent: 'center',
  },
  cell: {
    flexGrow: '1',
    border: 'none',
    borderBottom: `1px solid ${colors.tableGreyBorder}`,
  },
  cellText: {
    color: colors.tableGreyText,
    fontSize: 12,
  },
  tableHeaderCellText: {
    textTransform: 'uppercase',
    letterSpacing: 1.3,
    fontSize: 12,
    fontWeight: 500,
  },
  tabelHeaderCell: {
    padding: '20px 0 20px 10px',
  },
  tableBodyCellText: {
    fontSize: 14,
  },
  tableBodyCell: {
    padding: '20px 0 20px 10px',
    border: 'none',
  },
  tableHead: {
    width: '100%',
    background: colors.tableBackground,
    padding: '0 20px',
  },
  tableBody: {
    position: 'relative',
    height: '100%',
  },
  tableScrollView: {
    padding: '0 20px',
  },
};

const ContactActions = (props) => {
  const {
    onClickEdit,
    isDefaultSender,
    onClickDefault,
    deleteContact,
    isAccountAddress,
  } = props;
  const iconHeight = 18;
  const classes = {
    icon: css({
      width: iconHeight,
      height: iconHeight,
      color: colors.tableGreyText,
    }),
    starIconSelected: css({
      width: iconHeight,
      height: iconHeight,
      color: 'gold',
    }),
    starIconUnselected: css({
      width: iconHeight,
      height: iconHeight,
      color: 'rgba(0,0,0,0)',
    }),
    contactButton: css({
      minWidth: 30,
      paddingLeft: 5,
      paddingRight: 5,
    }),
    tooltip: css({
      background: colors.tooltipBackground,
      fontSize: 12,
    }),
    disabledContactButton: css({
      color: colors.disabledButton,
    }),
  };

  return (
    <Grid container justify="flex-end">
      {isDefaultSender ? (
        <Tooltip title="Default sender" classes={{ tooltip: classes.tooltip }}>
          <div>
            <Button
              onClick={onClickDefault || (() => {})}
              className={classes.contactButton}
              disabled
            >
              <StarIcon
                color="primary"
                classes={{
                  root: classes[
                    isDefaultSender ? 'starIconSelected' : 'starIconUnselected'
                  ],
                }}
              />
            </Button>
          </div>
        </Tooltip>
      ) : (
        <Button
          onClick={onClickDefault || (() => {})}
          className={classes.contactButton}
          disabled
        >
          <StarIcon
            color="primary"
            classes={{
              root: classes[
                isDefaultSender ? 'starIconSelected' : 'starIconUnselected'
              ],
            }}
          />
        </Button>
      )}

      <ContactButton
        title="Delete"
        disabled={isAccountAddress}
        onClick={deleteContact || (() => {})}
        icon={(
          <DeleteIcon
            style={{ height: iconHeight }}
            color={
              isAccountAddress ? colors.disabledButton : colors.tableGreyText
            }
          />
        )}
      />
      <ContactButton
        title="Edit"
        onClick={onClickEdit || (() => {})}
        disabled={isAccountAddress}
        icon={(
          <CreateIcon
            color="primary"
            classes={{
              root: `${classes.icon} ${
                isAccountAddress ? classes.disabledContactButton : ''
              }`,
            }}
          />
        )}
      />
    </Grid>
  );
};

function ContactForm(props) {
  const classes = NEW_ORDER_SENDER_RECIPIENT_STYLE();
  const layoutClasses = {
    addressHeader: css({
      marginTop: 10,
    }),
    addressHeaderText: css({
      color: 'white',
      fontWeight: 500,
    }),
    inputsContainer: css({
      background: colors.newOrderModuleContentBackGround,
      padding: '10px 20px',
    }),
  };
  const { country } = props;
  // eslint-disable-next-line react/prop-types
  const isEuro = EURO_COUNTRIES.includes(country);
  const { ...other } = props;
  return (
    <Grid
      container
      direction="column"
      className={layoutClasses.inputsContainer}
    >
      <Grid>
        <AddressForm
          fields={['name', 'companyName', 'email', 'phone']}
          other={{
            ...other,
            classes,
            overRideOptional: ['phone'],
          }}
        />
      </Grid>
      <Grid className={layoutClasses.addressHeader}>
        <Typography className={layoutClasses.addressHeaderText}>
          Address
        </Typography>
      </Grid>
      <Grid>
        <AddressForm
          fields={[
            'country',
            'addressLine1',
            'addressLine2',
            'addressLine3',
            'city',
          ]}
          other={{
            ...other,
            classes,
          }}
        />

        <Grid container spacing={3}>
          <Grid item xs={6}>
            <AddressForm
              fields={['state']}
              other={{
                ...other,
                classes,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <AddressForm
              fields={['zip']}
              other={{
                ...other,
                classes,
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      {isEuro && (
        <AddressForm
          fields={['eoriNumber']}
          other={{
            ...other,
            classes,
          }}
        />
      )}
      <Grid item container direction="column">
        <Grid item>
          <CustomCheckbox
            name="residentialDelivery"
            label="This is a residence"
            classes={classes}
          />
        </Grid>
        <Grid item>
          <CustomCheckbox
            name="isDefault"
            label="Set as default sender"
            classes={classes}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

const Transition = React.forwardRef((props, ref) => <Slide direction="left" ref={ref} {...props} />);

function EditContactModal(props) {
  const {
    addressId, open, editContact, onClose, values, addContact, type, refreshContacts,
  } = props;

  const formikRef = React.useRef(null);

  const onContactFormScroll = (e) => {
    const pacs = document.getElementsByClassName('pac-container');
    if (pacs.length) {
      for (let i = 0; i < pacs.length; i += 1) {
        pacs[i].setAttribute('style', 'display:none');
      }
    }
  };

  const closeModal = (e) => {
    const pacs = document.getElementsByClassName('pac-container');
    for (let i = 0; i < pacs.length; i += 1) {
      pacs[i].remove();
    }
    onClose(e);
  };

  const DEFAULT_FORM_VALUES = {
    name: '',
    companyName: '',
    email: '',
    phone: '',
    country: 'US',
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    city: '',
    state: '',
    zip: '',
    eoriNumber: '',
    isDefault: false,
    residentialDelivery: false,
  };

  const classes = {
    dialogTitle: css({
      padding: '0 0',
    }),
    dialogPaper: css({
      background: colors.newOrderFormBackground,
      width: '30rem',
      padding: '20px 30px',
      height: 'calc(100% - 0px)',
      zIndex: 1000,
    }),
    dialogTitleText: css({
      fontSize: 25,
      color: colors.white,
      fontWeight: 500,
      marginLeft: '1.5rem',
      marginTop: '0.5rem',
    }),
    dialogContent: css({
      padding: '0 0',
      overflow: 'hidden',
      height: 'inherit',
    }),
    submitButton: css({}),
    buttonsContainer: css({
      width: 270,
      margin: '20px 0px',
    }),
    innerDialogContentContainer: css({
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
      height: '100%',
      width: '100%',
    }),
    form: css({
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '100%',
      overflow: 'hidden',
    }),
    formInnerContainer: css({
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '100%',
      overflow: 'hidden',
      flexWrap: 'nowrap',
      alignItems: 'center',
    }),
    contactFormInnerContainer: css({
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      // overflow: 'scroll',
      height: '100%',
    }),
    paperFullScreen: css({
      width: '40rem',
      height: '88%',
      position: 'absolute',
      right: 0,
      backgroundColor: '#1E202E',
      marginTop: '96px',
    }),
    progress: css({
      marginTop: '15px',
    }),
  };

  async function onSubmit(contact, { setSubmitting, resetForm }) {
    setSubmitting(true);
    if (addressId) {
      await editContact(addressId, contact);
    } else {
      await addContact(contact);
      resetForm();
    }
    setSubmitting(false);
  }

  function render(renderProps) {
    const { values, submitForm, isSubmitting } = renderProps;
    return (
      <Form className={classes.form}>
        <Grid
          container
          direction="column"
          alignItems="center"
          className={classes.formInnerContainer}
        >
          <Grid item container className={classes.contactFormInnerContainer}>
            <ScrollWindow onScroll={onContactFormScroll}>
              <ContactForm
                country={values.country}
                disabled={
                  isSubmitting
                    ? [
                      'name',
                      'companyName',
                      'email',
                      'phone',
                      'country',
                      'addressLine1',
                      'addressLine2',
                      'addressLine3',
                      'city',
                      'state',
                      'zip',
                      'residentialDelivery',
                      'eoriNumber',
                      'isDefault',
                    ]
                    : []
                }
                formikRef={formikRef}
              />
            </ScrollWindow>
          </Grid>
          {isSubmitting ? (
            <CircularProgress color="secondary" className={classes.progress} />
          ) : (
            <Grid
              item
              container
              justify="space-between"
              className={classes.buttonsContainer}
            >
              <NewOrderNextButtonClear
                disabled={isSubmitting}
                className={classes.submitButton}
                onClick={closeModal}
              >
                Cancel
              </NewOrderNextButtonClear>
              <NewOrderNextButton
                disabled={isSubmitting}
                className={classes.submitButton}
                onClick={submitForm}
              >
                {type === 'EDIT' ? 'Save' : 'Submit'}
              </NewOrderNextButton>
            </Grid>
          )}
        </Grid>
      </Form>
    );
  }
  const getTitle = (dialogType) => {
    let title = '';
    switch (dialogType) {
      case 'EDIT':
        title = 'Edit Contact';
        break;
      case 'ADD':
        title = 'Add New Contact';
        break;
      case 'UPLOAD':
        title = 'Upload Contacts';
        break;
      default:
        title = 'Add New Contact';
    }
    return title;
  };
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      fullScreen
      disablePortal
      scroll="paper"
      onClose={closeModal}
      aria-describedby="alert-dialog-slide-description"
      classes={{
        paperFullScreen: classes.paperFullScreen,
      }}
    >
      <DialogTitle className={classes.dialogTitle}>
        <Typography className={classes.dialogTitleText}>
          {getTitle(type)}
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <div direction="column" className={classes.innerDialogContentContainer}>
          { type === 'UPLOAD'
            ? <UploadAddress closeModal={closeModal} refreshContacts={refreshContacts} />

            : (
              <Formik
                render={render}
                validateOnBlur={false}
                initialValues={values || DEFAULT_FORM_VALUES}
                onSubmit={onSubmit}
                ref={formikRef}
                validationSchema={validationSchema}
              />
            )}
        </div>
      </DialogContent>
    </Dialog>
  );
}

export function AddressBookTable(props) {
  const {
    contacts,
    openEditModal,
    deleteContact,
    lastContactRef,
    loadingContacts,
    onTableScroll,
  } = props;
  const classes = toCSS(addressBookClasses);

  const columnHeadersObj = {
    name: {
      label: 'Name',
    },
    address: {
      label: 'Address',
    },
    email: {
      label: 'Email',
    },
    phone: {
      label: 'Phone',
    },
    actions: {
      label: '',
    },
  };

  const columnOrder = [
    {
      key: 'name',
      cellStyle: { width: '22%', flexGrow: '0' },
      textStyle: { color: colors.white, fontWeight: 500 },
    },
    {
      key: 'address',
      cellStyle: { width: '24%', flexGrow: '0' },
    },
    {
      key: 'email',
      cellStyle: { width: '24%', flexGrow: '0' },
    },
    {
      key: 'phone',
      cellStyle: { width: '18%', flexGrow: '0' },
    },
    {
      key: 'actions',
      cellStyle: { width: '12%', flexGrow: '0' },
    },
  ];

  return (
    <Table className={classes.table}>
      <TableHead className={classes.tableHead}>
        <TableRow className={`${classes.row} ${classes.tableHeaderRow}`}>
          {columnOrder.map(
            (col, idx, arr) => columnHeadersObj[col.key] && (
            <TableCell
              className={`${classes.cell} ${classes.tabelHeaderCell}`}
              align="left"
              key={columnHeadersObj[col.key].label}
              style={col.cellStyle}
            >
              <Typography
                className={`${classes.cellText} ${classes.tableHeaderCellText}`}
              >
                {columnHeadersObj[col.key].label}
              </Typography>
            </TableCell>
            ),
          )}
        </TableRow>
      </TableHead>
      <TableBody className={classes.tableBody}>
        <ScrollWindow
          classes={{
            view: classes.tableScrollView,
          }}
          onScroll={onTableScroll}
        >
          {contacts.map((row, idx, arr) => (
            <TableRow
              className={`${classes.row} ${classes.tableBodyRow}`}
              key={row.addressId}
              data-testid="contact-row"
              ref={idx >= arr.length - 1 ? lastContactRef : undefined}
            >
              {columnOrder
                .filter((col) => col.key !== 'actions')
                .map((col, _, arr) => (
                  <TableCell
                    className={`${classes.cell} ${classes.tableBodyCell}`}
                    style={col.cellStyle}
                    key={`${row.addressId} - ${col.key}`}
                  >
                    {row[col.key]
                      ? row[col.key].split('\n').map((line) => (
                        <Typography
                          className={`${classes.cellText} ${classes.tableBodyCellText}`}
                          style={col.textStyle || {}}
                          key={`${row.addressId} - ${col.key} - ${line}`}
                        >
                          {line}
                        </Typography>
                      ))
                      : col.render
                        && col.render({
                          onClick: () => openEditModal(row.addressId),
                        })}
                  </TableCell>
                ))}
              <TableCell className={`${classes.cell} ${classes.tableBodyCell}`}>
                <ContactActions
                  onClickEdit={
                    row.addressId && (() => openEditModal(row.addressId))
                  }
                  isDefaultSender={row.isDefault}
                  onClickDefault={() => {}}
                  deleteContact={() => deleteContact({ addressId: row.addressId, name: row.name })}
                  isAccountAddress={row.isAccountAddress}
                />
              </TableCell>
            </TableRow>
          ))}
          {loadingContacts && (
            <TableRow className={`${classes.row} ${classes.tableBodyRow}`}>
              <TableCell style={{ border: 'none' }}>
                <CircularProgress
                  color="secondary"
                  className={classes.progress}
                />
              </TableCell>
            </TableRow>
          )}
        </ScrollWindow>
      </TableBody>
    </Table>
  );
}

export default function AddressBook() {
  const DEFAULT_COUNTRY = '';
  const miscDispatch = useMiscDispatch();
  const classes = toCSS(addressBookClasses);
  const errorClasses = NEW_ORDER_MODULE_STYLE();
  const clearedModalState = {
    type: '',
    addressId: '',
    values: '',
  };
  const [contactsState, setContactsState] = React.useState({
    ...DEFAULT_FETCH_CONTACTS_OPTIONS,
    contacts: [],
  });
  const [loadingContacts, setLoadingContacts] = React.useState(false);
  const [modalState, setModalState] = React.useState(clearedModalState);
  const [errorContent, setErrorContent] = React.useState(null);
  const [successContent, setSuccessContent] = React.useState(null);

  const [deleteModalState, setDeleteModalState] = React.useState(null);
  const lastContactRef = React.useRef(null);

  const onTableScroll = (e) => {
    const shouldLoadMoreContacts = isTableScrolledToBottom(
      e.target,
      lastContactRef,
    );
    if (shouldLoadMoreContacts && !loadingContacts) {
      lastContactRef.current = null;
      loadMoreContacts();
    }
  };

  function fetchAndSetContacts(options = {}) {
    setLoadingContacts(true);
    loadContactsPromiseManager(options)
      .then((data) => {
        if (!data) return;
        setContactsState((prev) => ({
          ...prev,
          ...options,
          contacts: [
            ...prev.contacts
              .slice(0, options.offset)
              .concat(formatContactData(data)),
          ],
          allLoaded: !data.length,
          offset: options.limit + options.offset,
        }));
      })
      .catch((err) => {
        console.log('There is a problem loading contacts');
        setLoadingContacts(false);
      });
  }

  function loadMoreContacts(options = {}) {
    const { limit, offset, filter } = contactsState;
    return fetchAndSetContacts({
      limit,
      offset,
      filter,
      ...options,
    });
  }

  React.useEffect(() => {
    loadCountries(miscDispatch);
  }, [miscDispatch]);

  React.useEffect(() => {
    loadMoreContacts({ ...DEFAULT_FETCH_CONTACTS_OPTIONS });
  }, []);

  React.useEffect(() => {
    setLoadingContacts(false);
  }, [contactsState.contacts]);

  const openEditModal = (addressId) => {
    setModalState(() => ({
      ...clearedModalState,
      type: 'EDIT',
      addressId,
      values: contactsState.contacts.find((el) => el.addressId === addressId),
    }));
  };

  const openAddModal = () => {
    setModalState(() => ({
      ...clearedModalState,
      type: 'ADD',
    }));
  };

  const openUploadAddressModal = () => {
    setModalState(() => ({
      ...clearedModalState,
      type: 'UPLOAD',
    }));
  };

  const refreshContacts = () => {
    loadMoreContacts({ ...DEFAULT_FETCH_CONTACTS_OPTIONS });
  };

  const editContact = async (addressId, contact) => {
    const sanitizedAddress = sanitizeAddress({ ...contact, addressId });
    const updatedContacts = await edit(sanitizedAddress).catch((e) => {
      setErrorContent(e.message);
    });
    if (updatedContacts) {
      setModalState(clearedModalState);
      setContactsState((prev) => {
        const newContactList = prev.contacts.map(
          (c) => formatContactData(
            updatedContacts.find((uc) => uc.addressId === c.addressId),
          )
            || (c.isDefault && updatedContacts.find((uc) => uc.isDefault)
              ? { ...c, isDefault: false }
              : c),
        );
        return {
          ...prev,
          contacts: newContactList,
        };
      });
    }
  };

  const addContact = async (contact) => {
    const newContactsData = await add(contact).catch((e) => {
      setErrorContent(e.message);
    });
    if (newContactsData) {
      // setModalState(clearedModalState);
      setContactsState((prev) => ({
        ...prev,
        contacts: formatContactData(newContactsData),
      }));
      setSuccessContent('Address Uploaded Successfully');
    }
  };

  const deleteContact = async (addressId) => {
    const updatedContacts = await remove(addressId).catch((e) => {
      setErrorContent(e.message);
    });
    if (updatedContacts) {
      setModalState(clearedModalState);
      setContactsState((prev) => {
        const newContactList = prev.contacts.filter(
          (c) => !updatedContacts.find((uc) => uc.addressId === c.addressId),
        );
        return {
          ...prev,
          contacts: newContactList,
        };
      });
    }
    setDeleteModalState(null);
  };

  const filterContacts = (filter) => {
    if (filter.length > 2) {
      loadMoreContacts({ ...DEFAULT_FETCH_CONTACTS_OPTIONS, filter });
    } else {
      loadMoreContacts({ ...DEFAULT_FETCH_CONTACTS_OPTIONS });
    }
  };

  return (
    <div className={classes.root}>
      <Navbar />
      <EditContactModal
        open={!!modalState.type}
        type={modalState.type}
        editContact={editContact}
        addressId={modalState.addressId}
        onClose={() => setModalState(clearedModalState)}
        values={modalState.values}
        addContact={addContact}
        refreshContacts={refreshContacts}
      />
      <Page className={classes.page}>
        <Grid
          container
          className={classes.headerContainer}
          justify="space-between"
          alignItems="center"
        >
          <Grid item className={classes.headerLeftContainer}>
            <Grid item container className={classes.headerLeftContainerText}>
              <AccountCircleIcon color="primary" height="1em" />
              &nbsp; &nbsp;
              <Typography className={classes.headerText}>
                Address Book
              </Typography>
            </Grid>
            &nbsp; &nbsp; &nbsp; &nbsp;
            <Grid item>
              <TextField
                id="outlined-basic"
                className={classes.textField}
                classes={{ root: classes.textFieldRoot }}
                label="Search Contacts"
                margin="normal"
                onChange={(e) => {
                  filterContacts(e.target.value);
                }}
                InputProps={{
                  disableUnderline: true,
                  classes: {
                    root: classes.inputRoot,
                    focused: classes.inputRoot,
                  },
                  endAdornment: (
                    <InputAdornment
                      position="start"
                      classes={{
                        root: classes.endAdornmentRoot,
                        filled: classes.endAdornmentRoot,
                        positionStart: classes.endAdornmentRoot,
                      }}
                    >
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{ classes: { root: classes.labelRoot } }}
                variant="filled"
              />
            </Grid>
          </Grid>
          <Grid item className={classes.headerRightContainer}>
            <Button className={classes.newContactButton} style={{ marginRight: '0.5rem' }} onClick={openUploadAddressModal}>
              <Grid container justify="space-around" alignItems="center">
                <UploadIcon color="primary" />
                <Typography
                  className={classes.newContactButtonText}
                  display="inline"
                >
                  Upload
                </Typography>
              </Grid>
            </Button>
            <Button className={classes.newContactButton} onClick={openAddModal}>
              <Grid container justify="space-around" alignItems="center">
                <AddIcon color="primary" />
                <Typography
                  className={classes.newContactButtonText}
                  display="inline"
                >
                  New Contact
                </Typography>
              </Grid>
            </Button>
          </Grid>
        </Grid>
        <Grid item container className={classes.tableContainer}>
          <AddressBookTable
            contacts={contactsState.contacts}
            openEditModal={openEditModal}
            deleteContact={(addressId) => setDeleteModalState(addressId)}
            lastContactRef={
              contactsState.allLoaded || contactsState.filter
                ? undefined
                : lastContactRef
            }
            loadingContacts={loadingContacts}
            onTableScroll={onTableScroll}
          />
        </Grid>
      </Page>
      <NewOrderAlertDialog
        open={isString(successContent)}
        alertContent={(
          <DialogContentText
            id="alert-confirm-dialog"
            component="div"
            style={{
              paddingLeft: '10px', paddingRight: '10px',
            }}
          >
            <Grid
              item
              container
              spacing={2}
              direction="column"
              style={{
                paddingTop: '20px', display: 'flex', alignItems: 'center',
              }}
            >
              <CheckIcon fontSize="inherit" style={{ color: green[500], fontSize: 45 }} />
              <Grid item>
                <Typography color="primary" />
              </Grid>
              <Grid item container>
                {successContent}

              </Grid>
            </Grid>
          </DialogContentText>
)}
        classes={errorClasses}
        onClose={() => {
          setSuccessContent(null);
        }}
      />
      <NewOrderErrorDialog
        open={!!errorContent}
        errorContent={errorContent}
        classes={errorClasses}
        onClose={() => {
          setErrorContent(null);
        }}
      />
      <ConfirmationModal
        open={!!deleteModalState}
        message={
          deleteModalState
          && `Are you sure you would like to delete contact ${deleteModalState.name}?`
        }
        onProceed={() => deleteModalState && deleteContact(deleteModalState.addressId)}
        onCancel={() => setDeleteModalState(null)}
        proceedLabel="YES, DELETE"
      />
    </div>
  );
}

ContactActions.propTypes = {
  onClickEdit: PropTypes.func,
  onClickDefault: PropTypes.func.isRequired,
  isDefaultSender: PropTypes.bool,
};

ContactActions.defaultProps = {
  onClickEdit: () => {
    console.log('No AddressId found for this contact');
  },
  isDefaultSender: false,
};
