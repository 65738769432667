import React, { memo } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { IconButton } from '@material-ui/core';
import * as colors from '../../styles/colors';
import UploadImage from './UploadImage';
import UserAvatar from './UserAvatar';

const useStyles = makeStyles(() => ({
  title: {
    color: colors.white,
    fontFamily: 'Roboto',
    fontSize: '24px',
    fontWeight: '500',
    lineHeight: '28px',
    textAlign: 'center',
  },
  subTitle: {
    color: colors.textLightGrey,
    fontFamily: 'Roboto',
    fontSize: '14px',
    letterSpacing: '0.25px',
    lineHeight: '20px',
    textAlign: 'center',
  },
  container: {
    marginBottom: '24px',
  },
  avatarGridItem: {
    alignSelf: 'center',
  },
  avatar: {
    backgroundColor: '#313547',
    color: '#B4B9CE',
    width: '150px',
    height: '150px',
    fontSize: '72px',
    fontWeight: '500',
    letterSpacing: '2.63px',
    marginLeft: '24px',
  },
}));

function AvatarHeader({
  fullName,
  username,
  imageURL,
  roles,
  isProfilePictureError,
  showTitle,
  onUploadProfilePicture,
  onRemoveProfilePicture,
  onProfilePictureError,
}) {
  const [isUploadAvatarModalOpen, setIsUploadAvatarModalOpen] = React.useState(false);
  const classes = useStyles();
  const date = new Date();
  const userInitials = fullName.split(' ').map((n) => (n[0])).join('').toUpperCase();

  return (
    <>
      <Grid direction="column" container spacing={2} alignitems="center" className={classes.container}>
        <Grid container item justify="center" className={classes.avatarGridItem}>
          <Grid
            item
            style={{
              marginLeft: 24,
            }}
          >
            <UserAvatar
              src={!isProfilePictureError && (`${imageURL}?${date}`)}
              alt={userInitials}
              size="xl"
            />
          </Grid>
          <Grid item>
            <IconButton
              style={{ padding: 0 }}
              onClick={() => setIsUploadAvatarModalOpen(true)}
              data-testid="upload-profile-photo-button"
              aria-label="upload profile photo"
            >
              <EditIcon />
            </IconButton>
          </Grid>
        </Grid>
        {showTitle && (
          <Grid item>
            <Typography className={classes.title}>{`Hello, ${fullName}`}</Typography>
          </Grid>
        )}
        {!isEmpty(username) && !isEmpty(roles) && (
          <Grid item>
            <Typography className={classes.subTitle}>{username}</Typography>
            <Typography className={classes.subTitle}>{roles.join(', ')}</Typography>
          </Grid>
        )}
      </Grid>
      <UploadImage
        open={isUploadAvatarModalOpen}
        onClose={() => {
          setIsUploadAvatarModalOpen(false);

          if (onProfilePictureError) onProfilePictureError(false);
        }}
        currentImageUrl={!isProfilePictureError && (`${imageURL}?${date}`)}
        displayRemoveButton={!!(imageURL)}
        onUploadProfilePicture={onUploadProfilePicture}
        onRemoveProfilePicture={onRemoveProfilePicture}
      />
    </>
  );
}

AvatarHeader.defaultProps = {
  fullName: '',
  username: '',
  imageURL: '',
  roles: [],
  isProfilePictureError: false,
  showTitle: true,
  onUploadProfilePicture: null,
  onRemoveProfilePicture: null,
  onProfilePictureError: null,
};

AvatarHeader.propTypes = {
  fullName: PropTypes.string,
  username: PropTypes.string,
  imageURL: PropTypes.string,
  roles: PropTypes.arrayOf(PropTypes.shape(PropTypes.object)),
  isProfilePictureError: PropTypes.bool,
  showTitle: PropTypes.bool,
  onUploadProfilePicture: PropTypes.func,
  onRemoveProfilePicture: PropTypes.func,
  onProfilePictureError: PropTypes.func,
};

export default memo(AvatarHeader);
