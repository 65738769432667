import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import isString from 'lodash/isString';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Grid from '@material-ui/core/Grid';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import { CircularProgress } from '@material-ui/core';
import * as colors from '../styles/colors';
import FedexLogo from './images/FEDEX_trimmed.png';
import UPSLogo from './images/UPS_transparent.png';
import MNXLogo from './images/MNX.png';
import USPSLogo from './images/USPS.png';
import DHLLogo from './images/DHL.png';
import { formatMonetaryAmount } from '../utils/helpers';
import { CUSTOM_FREIGHT_OPTION } from '../clientConstants';

function formatDateString(date) {
  return date
    ? moment.utc(date).format('ddd, MMM DD, h A')
    : 'N/A';
}

function findRateById(rates, id) {
  const ratesArr = rates.filter((rate) => rate.id === id);
  return ratesArr[0];
}

function setDefaultRowsPerPage(numberOfRows) {
  if (numberOfRows < 25) return numberOfRows;
  return 25;
}

const getCarrierLogo = (carrierName) => {
  const logos = {
    FEDEX: FedexLogo,
    UPS: UPSLogo,
    MNX: MNXLogo,
    NGL: MNXLogo,
    USPS: USPSLogo,
    DHL: DHLLogo,
  };
  if (carrierName in logos) { return (props) => <img {...props} src={logos[carrierName]} alt="" />; }
  return (props) => <span {...props}>{carrierName}</span>;
};

const StyledRadio = withStyles({
  root: {
    '&$disabled': {
      color: colors.textDarkGrey,
    },
    color: colors.white,
  },
  colorSecondary: {
    color: colors.white,
    '&$checked': {
      color: colors.white,
    },
  },
  disabled: {},
})(Radio);

const StyledTableCell = withStyles({
  root: {
    color: colors.white,
    fontSize: 16,
    letterSpacing: 0.5,
    border: 'none',
  },
})(TableCell);

const StyledTablePagination = withStyles({
  root: {
    color: colors.white,
    backgroundColor: colors.darkBlueBackground,
  },
  selectIcon: {
    color: colors.white,
    backgroundColor: colors.darkBlueBackground,
  },
})(TablePagination);

function desc(a, b, orderBy) {
  if (!a[orderBy]) return -1;
  if (!b[orderBy]) return 1;
  if ((Number(b[orderBy]) || b[orderBy]) < (Number(a[orderBy]) || a[orderBy])) {
    return -1;
  }
  if ((Number(b[orderBy]) || b[orderBy]) > (Number(a[orderBy]) || a[orderBy])) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc'
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

const headCells = [
  {
    id: 'carrierName', numeric: false, disablePadding: true, label: 'Carrier',
  },
  {
    id: 'serviceName',
    numeric: false,
    disablePadding: false,
    label: 'Service',
  },
  {
    id: 'expectedDeliveryDateTime',
    numeric: true,
    disablePadding: false,
    label: 'Actual Delivery',
  },
  {
    id: 'listPrice',
    numeric: true,
    disablePadding: false,
    label: 'List Price',
  },
  {
    id: 'myPrice', numeric: true, disablePadding: false, label: 'Your Price',
  },
];

const isDateHeader = (headerId) => headerId === 'expectedDeliveryDateTime';

function CarrierRatesTableHead(props) {
  const {
    classes, order, orderBy, onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <StyledTableCell padding="checkbox" />
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align={
              isDateHeader(headCell.id)
                ? 'left'
                : headCell.numeric
                  ? 'right'
                  : 'left'
            }
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              className={classes.columnTitle}
              active={orderBy === headCell.id}
              direction={order}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

CarrierRatesTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    backgroundColor: colors.darkBlueBackground,
    color: colors.white,
    flexDirection: 'column',
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  listPriceCellLineDecoration: {
    textDecoration: 'line-through',
  },
  listPriceCellNoDecoration: {
    textDecoration: 'none',
  },
  yourPriceCell: {
    fontWeight: 900,
    color: colors.textGreen,
  },
  carrierImage: {
    height: 'auto',
    width: 'auto',
    maxHeight: '25px',
    maxWidth: '100px',
    margin: '2px 0',
  },
  progress: {
    width: '100%',
  },
  progressWrap: {
    display: 'flex',
    width: '100%',
    flexGrow: '1',
    justifyContent: 'center',
    alignItems: 'flex-start',
    backgroundColor: colors.darkBlueBackground,
    padding: '80px 0',
    minHeight: '250px',
  },
  bareCell: {
    border: 'none',
    width: '100%',
  },
}));

export default function CarrierRatesTable(props) {
  const classes = useStyles();
  const {
    rows,
    currentRate,
    changeSelectedRate,
    disabled,
    ratesLoading,
  } = props;

  const StyledRow = {
    tableRowDark: withStyles({
      root: {
        backgroundColor: 'rgba(0,0,0,.24)',
        '&:hover': {
          backgroundColor: 'rgba(0,0,0,.35)',
        },
      },
      selected: {
        backgroundColor: 'rgba(0,0,0,.35) !important',
      },
    })(TableRow),
    tableRowLight: withStyles({
      root: {
        backgroundColor: 'rgba(0,0,0,.12)',
        '&:hover': {
          backgroundColor: 'rgba(0,0,0,.35)',
        },
      },
      selected: {
        backgroundColor: 'rgba(0,0,0,.35) !important',
      },
    })(TableRow),
  };
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('carrier');
  const [selected, setSelected] = React.useState(null);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(setDefaultRowsPerPage(rows?.length));

  function findRowMatch(rowArray, rate) {
    if (!rate) return null;
    const match = rowArray.find((row) => row.carrierName === rate.carrierName
      && row.serviceCode === rate.serviceCode);
    return match ? match.id : null;
  }

  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }

  // If the rows change, set the number of rows to display the max amount
  React.useEffect(() => {
    setRowsPerPage(setDefaultRowsPerPage(rows?.length));
  }, [rows]);

  // If the rate in context, or the rows change, set selected to the matching row
  React.useEffect(() => {
    const matchId = findRowMatch(rows, currentRate);
    setSelected(matchId);
  }, [rows]);

  // Handle changing selected on click
  function handleClick(_, id) {
    if (disabled) return;

    if (id !== selected) {
      setSelected(id);
      const newSelectedRate = findRateById(rows, id) || {};
      changeSelectedRate(newSelectedRate);
    } else {
      setSelected(null);
      changeSelectedRate({});
    }
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }

  function customFreightPrice(currencyCode, price) {
    if (price && isString(price) && parseInt(price, 10) === 0) return 'N/A';

    return formatMonetaryAmount(currencyCode, price);
  }

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <div className={classes.tableWrapper}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="small"
          >
            <CarrierRatesTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              numSelected={selected ? 1 : 0}
            />
            {ratesLoading ? (
              null
            ) : (
              <TableBody>
                {stableSort(rows, getSorting(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    const CarrierImage = getCarrierLogo(
                      row.carrierName.toUpperCase(),
                    );
                    const Row = StyledRow[index % 2 ? 'tableRowLight' : 'tableRowDark'];

                    return (
                      <Row
                        onClick={(event) => handleClick(event, row.id)}
                        role="checkbox"
                        aria-checked={row.id === selected}
                        tabIndex={-1}
                        key={row.id}
                        selected={row.id === selected}
                        classes={{
                          root:
                              classes[index % 2 ? 'tableRowLight' : 'tableRowDark'],
                        }}
                        data-testid="carrier-rate"
                      >
                        <StyledTableCell padding="checkbox">
                          <StyledRadio
                            disabled={disabled}
                            checked={row.id === selected}
                            inputProps={{ 'aria-labelledby': labelId }}
                          />
                        </StyledTableCell>
                        <StyledTableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          <CarrierImage className={classes.carrierImage} />
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          <Grid container direction="column">
                            <Grid item>
                              <img src={require(`./images/modeOfTransitIcons/${row.iconType || 'truck'}.svg`)} />
                          &nbsp;
                              {row.serviceName}
                            </Grid>
                            {/* <Grid item container spacing={1}>
                              <Grid item>
                                <div className="ratings">
                                  <div className="empty-stars" />
                                  <div className="full-stars" style={{ width: `${Number(row.rating) / 5 * 100}%` }} />
                                </div>
                              </Grid>
                              <Grid item>
                                <Typography>{row.reviews && `(${row.reviews})`}</Typography>
                              </Grid>
                            </Grid> */}
                          </Grid>
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {formatDateString(row.expectedDeliveryDateTime)}
                        </StyledTableCell>
                        <StyledTableCell
                          className={row.listPrice === row.myPrice ? classes.listPriceCellNoDecoration : classes.listPriceCellLineDecoration}
                          align="right"
                        >
                          {row.carrierName === CUSTOM_FREIGHT_OPTION ? customFreightPrice(row.currencyCode, row.listPrice) : formatMonetaryAmount(row.currencyCode, row.listPrice)}
                        </StyledTableCell>
                        <StyledTableCell
                          className={classes.yourPriceCell}
                          align="right"
                        >
                          {row.carrierName === CUSTOM_FREIGHT_OPTION ? customFreightPrice(row.currencyCode, row.myPrice) : formatMonetaryAmount(row.currencyCode, row.myPrice)}
                        </StyledTableCell>
                      </Row>
                    );
                  })}
                {emptyRows > 0 && (
                <TableRow style={{ height: 33.0167 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
                )}
              </TableBody>
            )}
          </Table>
          {ratesLoading && (
            <Grid container className={classes.progressWrap}>
              <CircularProgress className={classes.progress} color="secondary" />
            </Grid>
          )}
        </div>
        <StyledTablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'previous page',
          }}
          nextIconButtonProps={{
            'aria-label': 'next page',
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          SelectProps={{ SelectDisplayProps: { 'data-testid': 'carrier-rates-pagination' } }}
        />
      </Paper>
    </div>
  );
}

CarrierRatesTable.propTypes = {
  rows: PropTypes.array.isRequired,
  disabled: PropTypes.bool.isRequired,
};
