import React from 'react';
import PropTypes from 'prop-types';
import AESFillingForm from './AESFillingForm';
import NewOrderShipmentModule from './common/NewOrderShipmentModule';
import BlockedPanelDisplay from './common/PanelWarning';

const AESFillingPanel = ({
  name,
  shipment,
  isComplete,
  isSelected,
  isReadyToBeCompleted,
  isComplianceFailure,
  blockingMessage,
  ...shipmentProps

}) => {
  const itnNumber = shipment?.[name]?.details?.itnNumber || '';
  const ftsrCode = shipment?.[name]?.details?.ftsrCode || '';
  const labelDetails = itnNumber || ftsrCode;

  return (
    <NewOrderShipmentModule
      labelDetails={labelDetails}
      isComplete={isComplete}
      isSelected={isSelected}
      shipment={shipment}
      blockingMessage={blockingMessage}
      {...shipmentProps}
    >
      {(isReadyToBeCompleted) ? ((renderProps) => (
          <AESFillingForm
            shipment={shipment}
            name={name}
            isComplete={isComplete}
            isReadyToBeCompleted={isReadyToBeCompleted}
            isSelected={isSelected}
            isComplianceFailure={isComplianceFailure}
            {...shipmentProps}
            {...renderProps}
          />
        )): (
          <BlockedPanelDisplay message={blockingMessage} />
      )}
    </NewOrderShipmentModule>
  );
};

AESFillingPanel.propTypes = {

};

export default AESFillingPanel;
