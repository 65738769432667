import React, {
  memo, useCallback, useEffect, useState,
} from 'react';
import isEmpty from 'lodash/isEmpty';
import { makeStyles } from '@material-ui/core/styles';
import {
  CircularProgress, InputAdornment, TextField, Typography,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import AssignmentIcon from '@material-ui/icons/AssignmentOutlined';
import SearchIcon from '@material-ui/icons/Search';
import * as colors from '../styles/colors';
import { DEFAULT_FETCH_OPTIONS } from '../clientConstants';
import {
  loadTasks, refreshTasks, useTaskDispatch, useTaskState,
} from '../context/taskDataContext';
import Navbar from '../components/Navbar';
import { Page } from '../components/common';
import TaskCard from '../components/TaskCard';
import FilterTask from '../components/FilterTask';
import taskClient from '../utils/taskClient';
import { useUserState } from '../context/userContext';
import NewOrderErrorDialog from '../components/common/NewOrderErrorDialog';

const DEFAULT_FETCH = {
  ...DEFAULT_FETCH_OPTIONS,
  filters: { status: ['In-Progress', 'Created'] },
};

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: colors.background,
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  headerContainer: {
    paddingBottom: '10px',
    borderBottom: '1px solid rgba(255,255,255,.2)',
  },
  page: {
    padding: '99px 20px 20px 20px',
    height: 'calc(100vh - 64px)',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
  },
  headerLeftContainer: {
    display: 'flex',
    width: 'auto',
    alignItems: 'center',
  },
  headerLeftContainerText: {
    width: 'auto',
    alignItems: 'center',
  },
  headerText: {
    color: colors.white,
    fontSize: 24,
    fontWeight: 500,
    marginLeft: '10px',
  },
  textField: {
    width: 370,
    background: colors.darkInputFieldBackground,
    marginLeft: '1.5rem',
  },
  labelRoot: {
    color: colors.white,
    fontSize: 16,
    fontWeight: 500,
    background: colors.darkInputFieldBackground,
  },
  inputRoot: {
    background: colors.darkInputFieldBackground,
  },
  endAdornmentRoot: {
    margin: 0,
    '&:not(.MuiInputAdornment-hiddenLabel)': {
      margin: '0px !important',
    },
  },
  filterBar: {
    padding: '5px 0',
  },
  tableContainer: {
    flexGrow: 2,
    overflow: 'hidden',
    position: 'relative',
  },
  noResultsIconContainer: {
    fontSize: 160,
    display: 'flex',
    background: colors.newOrderFormBackground,
    borderRadius: 130,
    height: 260,
    width: 260,
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 50,
  },
  noResultsIconRoot: {
    color: '#80808C',
  },
  noResultsText: {
    color: colors.tableGreyText,
    fontSize: 14,
  },
  noResultsTextContainer: {
    marginTop: 20,
  },
}));

const MyTasks = () => {
  const classes = useStyles();
  const lastCardRef = React.useRef(null);
  const taskDispatch = useTaskDispatch();
  const taskState = useTaskState();
  const [search, setSearch] = useState('');
  const [taskTypes, setTaskTypes] = useState([]);
  const [state, setTaskState] = useState({
    params: {
      ...DEFAULT_FETCH,
    },
    loading: true,
  });
  const { userType } = useUserState();
  const [error, setError] = useState(null);

  useEffect(() => {
    Promise.all([getTasks(), taskClient.loadAllTaskTypes()]).then((values) => {
      setTaskTypes(values[1]);
    });
  }, []);

  useEffect(() => {
    if (taskState.tasks && taskState.tasks.length > 0 && search.length !== 0 && search.length >= 2) {
      getTasks({ ...DEFAULT_FETCH, offet: 0, filters: { ...state.params.filters, filter: search } });
    }
  }, [search]);

  useEffect(() => {
    window.addEventListener('scroll', onWindowScroll);

    return () => {
      window.removeEventListener('scroll', onWindowScroll);
    };
  });

  const getTasks = (options = DEFAULT_FETCH) => {
    setTaskState({
      ...state,
      loading: true,
    });

    const newOptions = {
      ...state.params,
      ...options,
      offset: options.offset,
    };

    return loadTasks({ options: newOptions, dispatch: taskDispatch }).then((data) => {
      setTaskState({
        params: newOptions,
        loading: false,
      });

      return data;
    });
  };

  const onWindowScroll = useCallback(() => {
    const windowScroll = window.scrollY + window.innerHeight;
    const elementLocation = (lastCardRef.current
      && (lastCardRef.current.offsetTop + lastCardRef.current.clientHeight + 64)) || Infinity;

    if ((windowScroll >= elementLocation) && !state.loading) {
      lastCardRef.current = null;

      getTasks({
        ...state.params,
      });
    }
  }, [state.loading]);

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const handleRefreshTasks = (tasks) => {
    refreshTasks({ tasks, dispatch: taskDispatch });
  };

  const handleChipDelete = (key) => {
    const newFilters = state.params.filters;

    delete newFilters[key];

    getTasks({
      ...state.params,
      filters: newFilters,
    });
  };

  const handleSubmit = (values) => {
    getTasks({
      ...state.params,
      filters: {
        ...state.params.filters,
        // eslint-disable-next-line
        ...Object.entries(values).reduce((obj, [key, value]) => (value && !isEmpty(value) ? (obj[key] = value, obj) : obj), {}),
      },
    });
  };

  const handleFilterClear = () => {
    getTasks({
      ...state.params,
      filters: {
        filter: search,
      },
    });
  };

  return (
    <div className={classes.root}>
      <Navbar />
      <Page>
        <Grid className={classes.headerContainer} container justify="space-between" alignItems="center">
          <Grid className={classes.headerLeftContainer} item>
            <Grid className={classes.headerLeftContainerText} container item>
              <AssignmentIcon color="primary" height="2em" />
              <Typography className={classes.headerText}>My Tasks</Typography>
            </Grid>
            <Grid item>
              <TextField
                id="search-my-task"
                className={classes.textField}
                classes={{ root: classes.textFieldRoot }}
                label="Search Tasks"
                margin="normal"
                onChange={handleSearch}
                InputProps={{
                  disableUnderline: true,
                  classes: {
                    root: classes.inputRoot,
                    focused: classes.inputRoot,
                  },
                  endAdornment: (
                    <InputAdornment
                      position="start"
                      classes={{
                        root: classes.endAdornmentRoot,
                        filled: classes.endAdornmentRoot,
                        positionStart: classes.endAdornmentRoot,
                      }}
                    >
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{ classes: { root: classes.labelRoot } }}
                variant="filled"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item container className={classes.filterBar} justify="space-between">
          <FilterTask
            taskTypes={taskTypes}
            filters={state.params.filters}
            onChipDelete={handleChipDelete}
            onFilterClear={handleFilterClear}
            onSubmit={handleSubmit}
          />
        </Grid>
        <Grid className={classes.tableContainer} container item justify="center">
          {taskState
              && !isEmpty(taskState.tasks)
            ? (taskState.tasks.map((task, idx, arr) => (
              <TaskCard
                // eslint-disable-next-line
                key={`task-tile-${task.taskType}-${task.shipmentId}-${idx}`}
                task={task}
                type="lg"
                userType={userType}
                classes={classes}
                lastCardRef={idx >= arr.length - 1 ? lastCardRef : undefined}
                onRefreshTasks={handleRefreshTasks}
                onError={setError}
              />
            )))
            : (
              !state.loading
                && (
                <Grid item container direction="column" alignItems="center">
                  <Grid item className={classes.noResultsIconContainer}>
                    <AssignmentIcon fontSize="inherit" classes={{ root: classes.noResultsIconRoot }} />
                  </Grid>
                  <Grid item className={classes.noResultsTextContainer}>
                    <Typography className={classes.noResultsText}>
                      No tasks found!
                    </Typography>
                  </Grid>
                </Grid>
                )
            )}
          {state.loading && <CircularProgress />}
        </Grid>
      </Page>
      <NewOrderErrorDialog
        open={!!error}
        errorContent={error}
        onClose={() => setError(null)}
      />
    </div>
  );
};

export default memo(MyTasks);
