import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { getName } from 'country-list';
import * as colors from '../../styles/colors';

const useStyles = makeStyles(() => ({
  savedCardDetails: {
    fontSize: '14px',
    color: colors.textLightGrey,
  },
}));

const BillingAddessSummary = ({ billingAddress }) => {
  const customCreditCardClasses = useStyles();

  return (
    <Grid item container direction="column" style={{ marginBottom: 5, marginTop: 10 }}>
      <Typography color="primary" style={{ fontWeight: '500', fontSize: '16px' }}>
        Billing Address
      </Typography>
      <Grid item>
        <Typography className={customCreditCardClasses.savedCardDetails}>
          {billingAddress.firstName}
          {' '}
          {billingAddress.lastName}
        </Typography>
      </Grid>
      <Grid item>
        <Typography className={customCreditCardClasses.savedCardDetails}>
          {billingAddress.addressLine1}
        </Typography>
      </Grid>
      {billingAddress.addressLine2 && (
        <Grid item>
          <Typography className={customCreditCardClasses.savedCardDetails}>
            {billingAddress.addressLine2}
          </Typography>
        </Grid>
      )}
      {billingAddress.addressLine3 && (
        <Grid item>
          <Typography className={customCreditCardClasses.savedCardDetails}>
            {billingAddress.addressLine3}
          </Typography>
        </Grid>
      )}
      <Grid item>
        <Typography className={customCreditCardClasses.savedCardDetails}>
          {billingAddress.city}
          {', '}
          {billingAddress.state}
          {' '}
          {billingAddress.zip}
        </Typography>
      </Grid>
      <Grid item>
        <Typography className={customCreditCardClasses.savedCardDetails}>
          {getName(billingAddress.country) || billingAddress.country}
        </Typography>
      </Grid>
      {billingAddress.phone && (
        <Grid item>
          <Typography className={customCreditCardClasses.savedCardDetails}>
            {billingAddress.phone}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

BillingAddessSummary.propTypes = {
  billingAddress: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    addressLine1: PropTypes.string,
    addressLine2: PropTypes.string,
    addressLine3: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    country: PropTypes.string,
    zip: PropTypes.string,
    phone: PropTypes.string,
  }).isRequired,
};

export default memo(BillingAddessSummary);
