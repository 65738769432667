import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { Grid, Typography, MenuItem } from '@material-ui/core';
import { Field } from 'formik';
import NewOrderCustomTextField from '../../common/NewOrderCustomTextField';
import CustomSelectTextField from '../../common/CustomSelectTextField';
import {
  FORM_FIELDS,
  FORM_LABELS,
} from '../itemDetailsConstants';
import { useItemProductDetailsFormStyles } from '../itemDetailsStyles';
import { isFieldEditable } from '../itemDetailsUtil';
import ProductUnitCost from './UnitCost';

export default function ProductMeasurements({
  editableFields,
  formikProps,
  productUnits,
  currencyUnits,
  hiddenFields,
}) {
  const classes = useItemProductDetailsFormStyles();
  const unitUnit = get(formikProps.values, FORM_FIELDS.unitUnit.key);

  return (
    <Grid item container direction="column" spacing={1}>
      <Grid item>
        <Typography color="primary" className={classes.formFieldHeader}>
          {FORM_LABELS.productMeasurements}
        </Typography>
      </Grid>
      <Grid item container spacing={1}>
        <Grid item xs={3}>
          <Field
            name={FORM_FIELDS.unitValue.key}
            label={FORM_FIELDS.unitValue.label}
            component={NewOrderCustomTextField}
            type="text"
            style={{ width: '100%' }}
            disabled={hiddenFields.includes('material') || isFieldEditable(FORM_FIELDS.unitValue.key, editableFields)}
          />
        </Grid>
        <Grid item xs={4}>
          <Field
            name={FORM_FIELDS.unitUnit.key}
            label={FORM_FIELDS.unitUnit.label}
            component={CustomSelectTextField}
            type="text"
            style={{ width: '100%' }}
            disabled={hiddenFields.includes('material') || isFieldEditable(FORM_FIELDS.unitUnit.key, editableFields)}
          >
            {isFieldEditable(FORM_FIELDS.unitUnit.key, editableFields) ? (
              <MenuItem
                key={unitUnit}
                value={unitUnit}
              >
                {unitUnit}
              </MenuItem>
            )
              : productUnits.map((option) => (
                <MenuItem
                  key={option.type}
                  value={option.type}
                >
                  {option.description}
                </MenuItem>
              ))}
          </Field>
        </Grid>
        <ProductUnitCost
          editableFields={editableFields}
          formikProps={formikProps}
          currencyUnits={currencyUnits}
        />
      </Grid>
    </Grid>
  );
}
ProductMeasurements.propTypes = {
  hiddenFields: PropTypes.arrayOf(PropTypes.string).isRequired,
  editableFields: PropTypes.arrayOf(PropTypes.string).isRequired,
  formikProps: PropTypes.objectOf(PropTypes.any).isRequired,
  productUnits: PropTypes.arrayOf(PropTypes.any).isRequired,
  currencyUnits: PropTypes.arrayOf(PropTypes.any).isRequired,
};
