import React from 'react';
import * as shipmentClient from '../utils/shipmentClient';
import { useLogger } from '../utils/hooks';

const ipdShipmentState = { shipments: [] };
const IpdShipmentContext = React.createContext();
const IpdShipmentDispatchContext = React.createContext();

// shipmentReducer Action Types
const LOAD_SHIPMENTS = 'LOAD IPD SHIPMENT';

function ipdShipmentReducer(state, action) {
  const { shipments, offset } = action;
  const newShipments = shipments;
  switch (action.type) {
    case LOAD_SHIPMENTS: {
      return {
        ...state,
        shipments: newShipments,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function IpdShipmentProvider(props) {
  const [state, dispatch] = useLogger(
    React.useReducer(ipdShipmentReducer, ipdShipmentState || { shipments: null }),
  );

  return (
    <IpdShipmentContext.Provider value={state} {...props}>
      <IpdShipmentDispatchContext.Provider
        value={dispatch}
        {...props}
      />
    </IpdShipmentContext.Provider>
  );
}

// TODO: update the shipmentClient.loadShipments function
// to handle these additional parameters (they will go in the request body)
function loadIpdShipment(dispatch, {
  ...filters
}, offset, limit) {
  return shipmentClient.loadIpdShipment({
    limit,
    offset,
    filters,
  }).then((data) => {
    dispatch({ type: LOAD_SHIPMENTS, shipments: data, offset });
    return data;
  });
}

function loadMasterTrackingInfo(trackingNumber) {
  return shipmentClient.loadMasterIpdTracking({
    trackingNumber,
  }).then((data) => data);
}
function loadRecentShipments(dispatch, filters = {}, offset = 0, limit = 5) {
  return shipmentClient.loadShipments({
    offset, limit, filters,
  }).then((data) => {
    dispatch({ type: LOAD_SHIPMENTS, shipments: data, offset });
    return data;
  });
}
function confirmIpdMasterShipment(dispatch, shipmentId) {
  return shipmentClient.confirmIpdMasterShipment(shipmentId).then((data) => data);
}
function useIpdShipmentState() {
  const context = React.useContext(IpdShipmentContext);
  if (context === undefined) {
    throw new Error(
      'useIpdShipmentState must be used within a IpdShipmentProvider',
    );
  }
  return context;
}

function useIpdShipmentDispatch() {
  const context = React.useContext(IpdShipmentDispatchContext);
  if (context === undefined) {
    throw new Error(
      'useIpdShipmentDispatch must be used within a IpdShipmentProvider',
    );
  }
  return context;
}

export {
  IpdShipmentProvider,
  useIpdShipmentState,
  useIpdShipmentDispatch,
  loadIpdShipment,
  loadRecentShipments,
  loadMasterTrackingInfo,
  confirmIpdMasterShipment,
};
