import React from 'react';
import MuiSelect from '@material-ui/core/Select';
import { Input } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { fieldToSelect } from 'formik-material-ui';
import { withStyles } from '@material-ui/core/styles';
import * as colors from '../../styles/colors';

export const StyledSelect = withStyles({
  select: {
    backgroundColor: colors.selectBackgroundAlt,
  },
  root: {
    color: colors.white,
  },
  icon: {
    color: colors.white,
  },
})(MuiSelect);

export const StyledInput = withStyles({
  root: {
    height: 56,
    backgroundColor: colors.selectBackgroundAlt,
    paddingLeft: 12,
    margin: 'auto',
  },
  underline: {
    '&:after': {
      borderBottom: `2px solid ${colors.primaryBlue}`,
    },
  },
})(Input);

export default function CustomSelectField(props) {
  return (
    <StyledSelect
      {...fieldToSelect(props)}
      IconComponent={ExpandMoreIcon}
      input={<StyledInput />}
    />
  );
}
