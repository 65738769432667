import React from 'react';
import PropTypes from 'prop-types';
import ShippingDetailsForm from './ShippingDetailsForm';
import NewOrderShipmentModule from './common/NewOrderShipmentModule';
import BlockedPanelDisplay from './common/PanelWarning';
import {
  formatDateLong, formatMonetaryAmount,
} from '../utils/helpers';

function formatLabel(shipmentDetails) {
  const carrierDetails = shipmentDetails.carrier.details;
  const { account } = shipmentDetails;
  const { expectedDeliveryDateTime } = carrierDetails;
  const formattedExpectedShipDate = formatDateLong(expectedDeliveryDateTime);
  return `${
    carrierDetails.carrierName ? carrierDetails.carrierName.toUpperCase() : ''
  } 
    ${
  carrierDetails.serviceName ? ` - ${carrierDetails.serviceName.toUpperCase()}` : ''
}
,
  ${
  account !== 'worldemblem'
    ? `${formatMonetaryAmount(
      carrierDetails.currencyCode,
      carrierDetails.myPrice,
    )},`
    : ''
}
  ${formattedExpectedShipDate}`;
}

export default function ShippingDetailsPane({
  shipment,
  isComplete,
  isReadyToBeCompleted,
  blockingMessage,
  ...shipmentProps
}) {
  const labelDetails = isComplete ? formatLabel(shipment) : '';
  return (
    <NewOrderShipmentModule
      labelDetails={labelDetails}
      isComplete={isComplete}
      isReadyToBeCompleted={isReadyToBeCompleted}
      shipment={shipment}
      blockingMessage={blockingMessage}
      {...shipmentProps}
    >
      {(isReadyToBeCompleted) ? ((renderProps) => (
        <ShippingDetailsForm
          shipment={shipment}
          isComplete={isComplete}
          isReadyToBeCompleted={isReadyToBeCompleted}
          {...shipmentProps}
          {...renderProps}
        />
      )) : (
        <BlockedPanelDisplay message={blockingMessage} />
      )}
    </NewOrderShipmentModule>
  );
}
