import { withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import * as colors from '../../styles/colors';

export default withStyles({
  root: {
    width: 122,
    maxWidth: 130,
    height: 36,
    border: `1px solid ${colors.buttonBackgroundBlue}`,
    fontWeight: 'bold',
    color: colors.secondaryButton,
    borderRadius: 8,
  },
})(Button);
