import React, { memo } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { Grid } from '@material-ui/core';
import ReadOnlyContainer from '../../../components/common/ReadOnlyContainer';
import {
  ACCOUNT_MANAGEMENT,
  AVAILABLE_CARRIERS_FIELD_NAME,
  AVAILABLE_CARRIERS_FIELD_TYPE,
} from '../constants';
import Form from '../Form';
import Types from './Types';

const Carriers = ({
  formikRef, itemKey, carriers, values, isEditing, onEdit, onSubmit,
}) => {
  const obj = ACCOUNT_MANAGEMENT[itemKey];

  return (
    <ReadOnlyContainer title={obj.title} setIsReadOnly={() => onEdit(itemKey)}>
      {isEditing ? (
        <Form
          ref={formikRef}
          itemKey={itemKey}
          initialValues={values}
          validationSchema={obj.validationSchema}
          fields={obj.fields}
          carriers={carriers}
          onEdit={onEdit}
          onSubmit={onSubmit}
        />
      )
        : obj.fields.map((field) => (
          <Grid key={field.name} item xs={field?.fullWidth ? 12 : 6}>
            {field.type === AVAILABLE_CARRIERS_FIELD_TYPE && (
              <Types
                carriers={carriers}
                accountAvailableCarriers={get(values, AVAILABLE_CARRIERS_FIELD_NAME, null)}
                isEditing={isEditing}
              />
            )}
          </Grid>
        ))}
    </ReadOnlyContainer>
  );
};

Carriers.defaultProps = {
  carriers: [],
  values: null,
  isEditing: false,
};

Carriers.propTypes = {
  formikRef: PropTypes.shape(PropTypes.object).isRequired,
  itemKey: PropTypes.string.isRequired,
  carriers: PropTypes.arrayOf(
    PropTypes.shape({
      carrierCode: PropTypes.string.isRequired,
      displayName: PropTypes.string.isRequired,
      isDomestic: PropTypes.bool.isRequired,
      isInternational: PropTypes.bool.isRequired,
      isHazmat: PropTypes.bool.isRequired,
    }),
  ),
  values: PropTypes.shape({
    availableCarriers: PropTypes.shape({
      domestic: PropTypes.arrayOf(PropTypes.string),
      international: PropTypes.arrayOf(PropTypes.string),
      hazmat: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
  }),
  isEditing: PropTypes.bool,
  onEdit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default memo(Carriers);
