import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { has } from 'lodash';
import * as colors from '../../../../../styles/colors';
import {
  FORM_FIELDS_LITHIUM_BATTERY,
  FORM_LABELS,
  LITHIUM_BATTERY_CONTAINED_KEY,
  LITHIUM_BATTERY_PACKED_KEY,
  SHIPMENT_QUANTITY_NAME_KEY,
  TRANSPORTATION_OPTIONS_NAME_KEY,
  TRANSPORTATION_OPTION_CARGO_VALUE,
  TRANSPORTATION_OPTION_GROUND_VALUE,
  TRANSPORTATION_OPTION_PASSENGER_CARGO_VALUE,
} from '../../../itemDetailsConstants';
import { useItemProductDetailsFormStyles } from '../../../itemDetailsStyles';
import {
  getConvertedLithiumBatteryWeight,
  getLithiumContainedOrPackedKey, splitWeightValue,
} from '../../../itemDetailsUtil';

const useStyles = makeStyles(() => ({
  formControlContainer: {
    marginTop: '1rem',
    marginBottom: '1rem',
  },
  formControl: {
    width: '100%',
  },
  radio: {
    color: colors.white,
    '&:hover': {
      backgroundColor: 'transparent ',
    },
    '&.Mui-disabled': {
      color: colors.disabledGrey,
    },
    paddingLeft: 0,
  },
  radioLabel: {
    color: colors.white,
    marginLeft: 0,
    marginRight: '5px',
  },
  maxQuantityText: {
    color: colors.white,
    fontSize: '0.875rem',
    '&.disabled': {
      color: colors.disabledGrey,
    },
  },
}));

function getTransportationMode(key, values) {
  const item = values[key];
  let convertedWeight = getConvertedLithiumBatteryWeight(key, values);
  let transportationMode = get(values, `${LITHIUM_BATTERY_CONTAINED_KEY}.${TRANSPORTATION_OPTIONS_NAME_KEY}`, TRANSPORTATION_OPTION_GROUND_VALUE);

  if (key === LITHIUM_BATTERY_PACKED_KEY && isNumber(convertedWeight)) {
    const containedLithiumWeight = getConvertedLithiumBatteryWeight(LITHIUM_BATTERY_CONTAINED_KEY, values);
    convertedWeight += containedLithiumWeight;
  }

  if (!isNumber(convertedWeight)) return transportationMode;

  if (has(item, TRANSPORTATION_OPTION_PASSENGER_CARGO_VALUE) && convertedWeight <= parseFloat(item[TRANSPORTATION_OPTION_PASSENGER_CARGO_VALUE])) {
    transportationMode = TRANSPORTATION_OPTION_PASSENGER_CARGO_VALUE;
  } else if (has(item, TRANSPORTATION_OPTION_CARGO_VALUE) && convertedWeight <= parseFloat(item[TRANSPORTATION_OPTION_CARGO_VALUE])) {
    transportationMode = TRANSPORTATION_OPTION_CARGO_VALUE;
  } else transportationMode = TRANSPORTATION_OPTION_GROUND_VALUE;

  return transportationMode;
}

function TransportationOptions({
  itemKey,
  formikProps,
  options,
  isBatteryContained,
}) {
  const formClasses = useItemProductDetailsFormStyles();
  const classes = useStyles();
  const key = getLithiumContainedOrPackedKey(formikProps.values, isBatteryContained);
  const weight = get(formikProps.values, `${key}.${FORM_FIELDS_LITHIUM_BATTERY.weight.key}`, null);
  const packingInstructionsKey = FORM_FIELDS_LITHIUM_BATTERY.packingInstructions.key;
  const packingInstructions = get(formikProps?.values, `${key}.${packingInstructionsKey}`, '');
  const packingInstructionsLabel = !isEmpty(packingInstructions) && itemKey === TRANSPORTATION_OPTIONS_NAME_KEY
    ? `(Packing Instructions: ${packingInstructions})`
    : '';

  useEffect(() => {
    if (!isEmpty(weight)) {
      formikProps.setFieldValue(
        TRANSPORTATION_OPTIONS_NAME_KEY,
        getTransportationMode(key, formikProps.values),
      );
    }
  }, [weight]);

  return (
    <Grid item direction="column" spacing={1}>
      <Grid item>
        <Typography
          className={formClasses.formFieldHeader}
          color="primary"
        >
          {FORM_LABELS[itemKey]}
          {' '}
          {packingInstructionsLabel}
        </Typography>
      </Grid>
      <Grid className={classes.formControlContainer} item>
        <FormControl className={classes.formControl}>
          <RadioGroup
            name={TRANSPORTATION_OPTIONS_NAME_KEY}
            value={formikProps?.values[TRANSPORTATION_OPTIONS_NAME_KEY]}
            onChange={(e) => {
              const value = e?.target?.value;

              formikProps.setFieldValue(
                TRANSPORTATION_OPTIONS_NAME_KEY,
                value,
              );
            }}
          >
            {options.map((option) => {
              const obj = get(formikProps?.values, `${key}.${option.value}`, undefined);
              const showQuantity = option.value !== TRANSPORTATION_OPTION_GROUND_VALUE && !isEmpty(obj);
              const transportationModeWeight = !isEmpty(obj) ? splitWeightValue(obj) : null;
              const parsedWeight = parseFloat(weight);
              const isDisabled = isNumber(parsedWeight) && isNumber(transportationModeWeight) && parsedWeight > transportationModeWeight;

              return (
                <Grid key={option.value} container item alignItems="center">
                  <FormControlLabel
                    className={classes.radioLabel}
                    label={option.label}
                    value={option.value}
                    control={(
                      <Radio
                        className={classes.radio}
                        checked={
                          option.value
                          === formikProps?.values[TRANSPORTATION_OPTIONS_NAME_KEY]
                        }
                        disabled={isDisabled}
                        disableRipple
                      />
                    )}
                  />
                  {showQuantity && (
                    <Typography className={`${classes.maxQuantityText} ${isDisabled ? 'disabled' : ''}`}>
                      (Max net Qnty:
                      {' '}
                      {obj}
                      )
                    </Typography>
                  )}
                </Grid>
              );
            })}
          </RadioGroup>
        </FormControl>
      </Grid>
    </Grid>
  );
}

TransportationOptions.propTypes = {
  itemKey: PropTypes.oneOf([TRANSPORTATION_OPTIONS_NAME_KEY, SHIPMENT_QUANTITY_NAME_KEY]).isRequired,
  formikProps: PropTypes.objectOf(PropTypes.any).isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
  isBatteryContained: PropTypes.bool.isRequired,
};

export default memo(TransportationOptions);
