import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { Route } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import { Typography, Select, MenuItem } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import AssignmentIcon from '@material-ui/icons/AssignmentOutlined';
import ShipmentCard from '../components/ShipmentCard';
import TaskCard from '../components/TaskCard';
import { Page } from '../components/common';
import RedirectToNewOrderButton from '../components/common/RedirectToNewOrderButton';
import { SHIPMENT_TRACKING_TAB } from '../clientConstants';
import * as colors from '../styles/colors';
import TrackingModal from '../components/TrackingModal';
import {
  useTaskState,
  useTaskDispatch,
  loadTasks,
  updateTasks,
} from '../context/taskDataContext';
import {
  useShipmentState,
  useShipmentDispatch,
  loadRecentShipments,
} from '../context/shipmentDataContext';
import { useUserDispatch, useUserState, loadUser } from '../context/userContext';
import Navbar from '../components/Navbar';
import ScrollWindow from '../components/common/ScrollWindow';
import { loadOrderPurposes, useMiscDispatch, useMiscState } from '../context/miscDataContext';
import NewOrderErrorDialog from '../components/common/NewOrderErrorDialog';

const ACCOUNT_TYPE_LARGE = 'large';
const ACCOUNT_TYPE_INDIVIDUAL = 'individual';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: colors.background,
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  iconContainer: {
    marginRight: '5px',
    color: 'white',
    paddingTop: 7,
  },
  listHeadingDiv: {
    marginTop: '10px',
    height: '47px',
    borderBottom: '1px solid rgba(255,255,255,.2)',
    marginBottom: '10px',
    flexShrink: '0',
  },
  shipmentsHeading: {
    fontSize: 24,
    color: colors.white,
  },
  centeredContainer: {
    alignItems: 'center',
  },
  viewAllButton: {
    fontSize: '14px',
    color: '#94CCFB',
    letterSpacing: 1.25,
  },
  pageDiv: {
    height: '100%',
    backgroundColor: '#151721',
  },
  select: {
    color: colors.secondaryButton,
    marginLeft: '15px',
    fontSize: '24px',
    border: 'none',
    letterSpacing: 1.2,
    fontWeight: 500,
  },
  taskContainer: {
    border: '1px solid red',
  },
}));

const defaultPurposeFilter = 'work';

const ViewAllButton = (props) => (
  <Route render={({ history }) => (
    <Button className={props.className} onClick={() => history.push('/my-shipments')}>
      VIEW ALL
    </Button>
  )}
  />
);

ViewAllButton.propTypes = {
  className: PropTypes.string.isRequired,
};

function WorkspaceColumnHeader({
  classes, type, icon, header, signature, isAckProhibited, useProfileSignature, ...props
}) {
  return (
    <div className={classes.listHeadingDiv}>
      <Grid container justify="space-between" alignItems="center">
        <Grid item className={classes.centeredContainer}>
          <Grid container>
            <div className={classes.iconContainer}>
              {icon}
            </div>
            {header}
          </Grid>
        </Grid>
        {type === 'shipments' && (
          <Grid item>
            <RedirectToNewOrderButton
              label="New Shipping Order"
              userHasSignature={!useProfileSignature || !isEmpty(signature)}
              isAckProhibited={isAckProhibited}
            />
            <ViewAllButton className={classes.viewAllButton} />
          </Grid>
        )}

      </Grid>
    </div>
  );
}

WorkspaceColumnHeader.defaultProps = {
  signature: '',
  isAckProhibited: false,
  useProfileSignature: false,
};

WorkspaceColumnHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  header: PropTypes.node.isRequired,
  signature: PropTypes.string,
  isAckProhibited: PropTypes.bool,
  useProfileSignature: PropTypes.bool,
};

export function LandingPage(props) {
  const { state, history } = props;
  const classes = useStyles();

  return (
    <Grid container spacing={1} className={classes.pageDiv} data-testid="homepage">
      <Grid item xs={9}>
        <MyShipments state={state} history={history} />
      </Grid>
      <Grid item xs={3} style={{ maxHeight: '80vh' }}>
        <MyTasks state={state} />
      </Grid>
    </Grid>
  );
}

LandingPage.propTypes = {
  state: PropTypes.object.isRequired,
  history: PropTypes.shape({
    location: PropTypes.shape({
      state: PropTypes.string,
    }),
  }).isRequired,
};

export function MyShipments(props) {
  const { state, history } = props;
  const [purposeFilter, setPurposeFilter] = React.useState([]);

  const classes = useStyles();
  const shipmentState = useShipmentState();
  const shipmentDispatch = useShipmentDispatch();
  const userDispatch = useUserDispatch();
  const { orderPurposes } = useMiscState();
  const miscDispatch = useMiscDispatch();
  const [trackingModalState, setTrackingModalState] = React.useState({
    anchorEl: null,
    shipmentId: null,
    trackingNumber: null,
    iotTrackingNumber: null,
  });

  const [currentTrackingTab, setCurrentTrackingTab] = React.useState(SHIPMENT_TRACKING_TAB);
  const {
    accountType = ACCOUNT_TYPE_LARGE, signature, isAckProhibited, useProfileSignature,
  } = useUserState();

  const openTrackingModal = ({
    shipmentId, trackingNumber, anchorEl, iotTrackingNumber, openTab,
  }) => {
    setTrackingModalState({
      shipmentId, trackingNumber, anchorEl, iotTrackingNumber,
    });
    setCurrentTrackingTab(openTab);
  };
  const handleTrackingClose = () => {
    setTrackingModalState({
      shipmentId: null, trackingNumber: null, anchorEl: null, iotTrackingNumber: null,
    });
    setCurrentTrackingTab(SHIPMENT_TRACKING_TAB);
  };

  React.useEffect(() => {
    loadRecentShipments(shipmentDispatch, { purpose: purposeFilter });
    loadUser(userDispatch);
  }, [shipmentDispatch, purposeFilter, userDispatch]);

  React.useEffect(() => {
    if (accountType === ACCOUNT_TYPE_LARGE) return;

    if (accountType === ACCOUNT_TYPE_INDIVIDUAL) changePurposeFilter('personal');
  }, [accountType]);

  React.useEffect(() => {
    if (orderPurposes && orderPurposes.length > 1) setPurposeFilter([orderPurposes[0].type]);
  }, [orderPurposes]);

  React.useEffect(() => {
    if (!orderPurposes) loadOrderPurposes(miscDispatch);
  }, []);

  const changePurposeFilter = (purpose) => {
    history.replace('/home', { purpose: [purpose] });
    setPurposeFilter([purpose]);
  };

  const getButtonText = (status, collab) => {
    if (status === 'Created' && collab?.length > 1) {
      return 'Edit';
    }
    return null;
  };

  return (
    <div style={{ height: '100%' }}>
      <TrackingModal
        handleClose={handleTrackingClose}
        id={`tracking-${trackingModalState.shipmentId || ''}`}
        trackingModalState={trackingModalState}
        currentTab={currentTrackingTab}
        setCurrentTab={setCurrentTrackingTab}
      />
      <WorkspaceColumnHeader
        classes={classes}
        type="shipments"
        icon={<LocalShippingIcon />}
        header={(
          <Grid item>
            <Grid container>
              <Typography className={classes.shipmentsHeading}>
                Recent Shipments
                {' '}
                {!isEmpty(purposeFilter) ? 'for' : ''}
              </Typography>
              {!isEmpty(purposeFilter) && (
                <Select
                  value={purposeFilter}
                  onChange={(e) => changePurposeFilter(e.target.value)}
                  classes={{ root: classes.select, icon: classes.select }}
                  disableUnderline
                >
                  {orderPurposes && orderPurposes.map((purpose) => (
                    <MenuItem key={purpose.type} value={purpose.type}>{purpose.description}</MenuItem>
                  ))}
                </Select>
              )}
            </Grid>
          </Grid>
          )}
        signature={signature}
        isAckProhibited={isAckProhibited}
        useProfileSignature={useProfileSignature}
      />
      {!state.isLoading
        && !(
          shipmentState
          && shipmentState.shipments
          && shipmentState.shipments[0]
        ) && (
          <Typography style={{ color: colors.white }}>
            You haven&apos;t shipped anything yet! Let&apos;s get started.
          </Typography>
      )}
      {shipmentState.shipments
        && shipmentState.shipments.map((shipment) => (
          <ShipmentCard
            key={`shipment-tile-${shipment.shipmentId}`}
            shipment={shipment}
            classes={classes}
            buttonText={getButtonText(shipment.status, shipment.collaborators)}
            openTrackingModal={openTrackingModal}
            setTrackingModalTab={setCurrentTrackingTab}
          />

        ))}
    </div>
  );
}

MyShipments.propTypes = {
  state: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export function MyTasks(props) {
  const { state } = props;
  const classes = useStyles();

  const taskState = useTaskState();
  const taskDispatch = useTaskDispatch();
  const { userType } = useUserState();

  const [error, setError] = React.useState(null);

  React.useEffect(() => {
    loadTasks({ options: { limit: 5, offset: 0, filters: { status: ['In-Progress', 'Created'] } }, dispatch: taskDispatch });
  }, [taskDispatch]);

  const handleRefreshTasks = (tasks) => {
    updateTasks({ options: { limit: 5, offset: 0, filters: { status: ['In-Progress', 'Created'] } }, dispatch: taskDispatch });
    // refreshTasks({ tasks, dispatch: taskDispatch });
  };

  return (
    <>
      <WorkspaceColumnHeader
        icon={<AssignmentIcon />}
        classes={classes}
        type="tasks"
        header={(
          <Typography className={classes.shipmentsHeading}>
            {`My Tasks (${
              (taskState && taskState.tasks && taskState.tasks.length) || 0
            })`}
          </Typography>
        )}
      />
      {!state.isLoading
        && !(taskState && taskState.tasks && taskState.tasks[0]) && (
          <Typography style={{ color: colors.white }}>
            You have no tasks. Any tasks assigned to you will appear here.
          </Typography>
      )}
      <ScrollWindow>
        {taskState
          && taskState.tasks
          && taskState.tasks.map((task, idx) => (
            <TaskCard
              // eslint-disable-next-line
              key={`workspace-task-tile-${task.taskType}-${task.shipmentId}-${idx}`}
              task={task}
              classes={classes}
              userType={userType}
              onRefreshTasks={handleRefreshTasks}
              onError={setError}
            />
          ))}
      </ScrollWindow>
      <NewOrderErrorDialog
        open={!!error}
        errorContent={error}
        onClose={() => setError(null)}
      />
    </>
  );
}

MyTasks.propTypes = {
  state: PropTypes.object.isRequired,
};

export default function ProtectedPage(props) {
  const { history } = props;
  const classes = useStyles();
  const { account } = useUserState();

  return (
    <div
      className={classes.root}
    >
      <Navbar />
      <Page account={account}>
        <LandingPage state={{}} history={history} />
      </Page>
    </div>
  );
}

ProtectedPage.propTypes = {
  history: PropTypes.object.isRequired,
};
