import { parsePhoneNumber } from 'libphonenumber-js';
import * as Yup from 'yup';
import {
  FIELD_TYPE_BOOL,
  FIELD_TYPE_DROPDOWN,
  FIELD_TYPE_EMAIL,
  FIELD_TYPE_PHONE,
  FIELD_TYPE_TEXT,
  ICON_DEPARTMENT,
  ICON_EMAIL,
  ICON_MOBILE_PHONE,
  ICON_NAME,
} from '../../clientConstants';

export const ACCOUNT_ADDRESS_KEY = 'accountAddress';
export const EMERGENCY_CONTACT_KEY = 'emergencyContact';
export const PAYMENT_KEY = 'payment';
export const PRICING_LIST_KEY = 'pricingList';
export const COST_ALLOCATION_KEY = 'costAllocation';
export const TRAINING_KEY = 'training';
export const CARRIERS_KEY = 'carriers';
export const NOTIFICATIONS_KEY = 'notification';
export const COMPLIANCE_KEY = 'compliance';
export const MISCELLANEOUS_KEY = 'miscellaneous';

// PAYMENT FIELDS
export const DELAYED_PAYMENT_FIELD_NAME = `${PAYMENT_KEY}.isDelayedPayment`;
export const DISPLAY_TRANSACTION_FEE_FIELD_NAME = `${PAYMENT_KEY}.displayTransactionFee`;
export const DISPLAY_CREDIT_CARD_PROCESSING_FEE_FIELD_NAME = `${PAYMENT_KEY}.displayCreditCardProcessingFee`;
export const CREDIT_CARD_FEE_FIELD_NAME = `${PAYMENT_KEY}.creditCardFeePercentage`;
export const PRICING_MODEL_FIELD_NAME = `${PAYMENT_KEY}.pricingModel`;
export const PRICING_MODEL_DOMESTIC_FEE_FIELD_NAME = `${PRICING_LIST_KEY}.domestic.fee`;
export const PRICING_MODEL_DOMESTIC_CURRENCY_FIELD_NAME = `${PRICING_LIST_KEY}.domestic.currency`;
export const PRICING_MODEL_INTERNATIONAL_FEE_FIELD_NAME = `${PRICING_LIST_KEY}.international.fee`;
export const PRICING_MODEL_INTERNATIONAL_CURRENCY_FIELD_NAME = `${PRICING_LIST_KEY}.international.currency`;
export const PRICING_MODEL_HAZMAT_DOMESTIC_FEE_FIELD_NAME = `${PRICING_LIST_KEY}.domestic.hazmat.fee`;
export const PRICING_MODEL_HAZMAT_DOMESTIC_CURRENCY_FIELD_NAME = `${PRICING_LIST_KEY}.domestic.hazmat.currency`;
export const PRICING_MODEL_HAZMAT_INTERNATIONAL_FEE_FIELD_NAME = `${PRICING_LIST_KEY}.international.hazmat.fee`;
export const PRICING_MODEL_HAZMAT_INTERNATIONAL_CURRENCY_FIELD_NAME = `${PRICING_LIST_KEY}.international.hazmat.currency`;

// COST ALLOCATION FIELDS
export const SPLIT_ALLOCATION_FIELD_NAME = 'splitAllocation';
export const MAX_ALLOCATION_FIELD_NAME = 'maxAllocations';

// NOTIFICATIONS FIELDS
export const EMAIL_ENABLED_FIELD_NAME = 'enabled';
export const SMS_ENABLED_FIELD_NAME = 'enabled';
export const NOTIFICATION_TYPE_FIELD_NAME = 'notificationType';

// COMPLIANCE FIELDS
export const OVERRIDE_EXPORT_COMPLIANCE_FIELD_NAME = 'overrideExportCompliance';
export const AUTO_ASSIGNMENT_FIELD_NAME = 'isAutoAssignment';

// CARRIER FIELDS
export const AVAILABLE_CARRIERS_FIELD_TYPE = 'availableCarriers';
export const AVAILABLE_CARRIERS_FIELD_NAME = 'availableCarriers';

// MISCELLANEOUS FIELDS
export const USE_PROFILE_SIGNATURE_FIELD_NAME = 'useProfileSignature';
export const AUTO_ASSIGNMENT_ECO_EXPIRE_DAYS_FIELD_NAME = 'eco.expiryDays';
export const AUTO_ASSIGNMENT_EHS_EXPIRE_DAYS_FIELD_NAME = 'ehs.expiryDays';
export const AUTO_ASSIGNMENT_ECO_USER_GROUP_FIELD_NAME = 'eco.isUserGroup';
export const AUTO_ASSIGNMENT_EHS_USER_GROUP_FIELD_NAME = 'ehs.isUserGroup';

export const ACCOUNT_MANAGEMENT = {
  [ACCOUNT_ADDRESS_KEY]: {
    key: ACCOUNT_ADDRESS_KEY,
    title: 'Account Address',
    fields: [
      {
        type: FIELD_TYPE_TEXT,
        name: 'name',
        label: 'Name',
        iconName: ICON_NAME,
      },
      {
        type: FIELD_TYPE_TEXT,
        name: 'companyName',
        label: 'Company Name',
        iconName: ICON_DEPARTMENT,
      },
      {
        type: FIELD_TYPE_EMAIL,
        name: 'email',
        label: 'Email',
        iconName: ICON_EMAIL,
      },
      {
        type: FIELD_TYPE_PHONE,
        name: 'phone',
        label: 'Phone',
        countryName: 'phoneCountry',
        iconName: ICON_MOBILE_PHONE,
      },
      {
        type: FIELD_TYPE_TEXT,
        name: 'addressLine1',
        label: 'Address Line 1',
        fullWidth: true,
        iconName: ICON_EMAIL,
      },
      {
        type: FIELD_TYPE_TEXT,
        name: 'addressLine2',
        label: 'Address Line 2',
        fullWidth: true,
        iconName: ICON_EMAIL,
      },
      {
        type: FIELD_TYPE_DROPDOWN,
        name: 'country',
        label: 'Country',
        iconName: ICON_EMAIL,
      },
      {
        type: FIELD_TYPE_DROPDOWN,
        name: 'state',
        label: 'State',
        iconName: ICON_EMAIL,
      },
      {
        type: FIELD_TYPE_TEXT,
        name: 'city',
        label: 'City',
        iconName: ICON_EMAIL,
      },
      {
        type: FIELD_TYPE_TEXT,
        name: 'zip',
        label: 'Zip',
        iconName: ICON_EMAIL,
      },
    ],
    validationSchema: Yup.object().shape({
      name: Yup.string(),
      companyName: Yup.string(),
      email: Yup.string().email('Invalid email'),
      phone: Yup.string()
        .test({
          name: 'is-valid-phone',
          message: 'Invalid phone number',
          test(value) {
            const parsedPhoneNumber = parsePhoneNumber(
              value || '',
            );
            return (
              parsedPhoneNumber && parsedPhoneNumber.isValid()
            );
          },
        })
        .required('Required'),
      addressLine1: Yup.string(),
      addressLine2: Yup.string(),
      country: Yup.string(),
      state: Yup.string().when('country', {
        is: true,
        then: (schema) => schema.required('This field is required.'),
        otherwise: (schema) => schema,
      }),
      city: Yup.string().when('state', {
        is: true,
        then: (schema) => schema.required('This field is required.'),
        otherwise: (schema) => schema,
      }),
      zip: Yup.string().when('city', {
        is: true,
        then: (schema) => schema.required('This field is required.'),
        otherwise: (schema) => schema,
      }),
    }),
  },
  [EMERGENCY_CONTACT_KEY]: {
    key: EMERGENCY_CONTACT_KEY,
    title: 'Emergency Contact Information',
    fields: [
      {
        type: FIELD_TYPE_TEXT,
        name: 'name',
        label: 'Name',
        iconName: ICON_NAME,
      },
      {
        type: FIELD_TYPE_PHONE,
        name: 'phone',
        label: 'Phone Number',
        countryName: 'phoneCountry',
        iconName: 'mobilePhone',
      },
      {
        type: FIELD_TYPE_TEXT,
        name: 'emergencyInformation',
        label: 'Emergency Information',
        iconName: ICON_EMAIL,
      },
      {
        type: FIELD_TYPE_TEXT,
        name: 'phoneExtension',
        label: 'Phone Extension',
        iconName: ICON_EMAIL,
      },
    ],
    validationSchema: Yup.object().shape({
      name: Yup.string(),
      phone: Yup.string()
        .test({
          name: 'is-valid-phone',
          message: 'Invalid phone number',
          test(value) {
            const parsedPhoneNumber = parsePhoneNumber(
              value || '',
            );
            return (
              parsedPhoneNumber && parsedPhoneNumber.isValid()
            );
          },
        })
        .required('Required'),
      emergencyInformation: Yup.string(),
      phoneExtension: Yup.string(),
    }),
  },
  [PAYMENT_KEY]: {
    key: PAYMENT_KEY,
    title: 'Payment',
    fields: [
      {
        type: FIELD_TYPE_BOOL,
        name: DELAYED_PAYMENT_FIELD_NAME,
        label: 'Delayed Payment',
      },
      {
        type: FIELD_TYPE_BOOL,
        name: DISPLAY_TRANSACTION_FEE_FIELD_NAME,
        label: 'Display transaction fee',
      },
      {
        type: FIELD_TYPE_BOOL,
        name: DISPLAY_CREDIT_CARD_PROCESSING_FEE_FIELD_NAME,
        label: 'Display credit card processing fee',
        fullWidth: true,
      },
      {
        type: FIELD_TYPE_TEXT,
        name: CREDIT_CARD_FEE_FIELD_NAME,
        label: 'Credit card fee (%)',
        iconName: '',
      },
      {
        type: '',
        name: '',
        label: 'Payment Options',
        fullWidth: true,
      },
      {
        type: '',
        name: '',
        label: 'Account Pricing',
        fullWidth: true,
      },
      {
        type: FIELD_TYPE_DROPDOWN,
        name: PRICING_MODEL_FIELD_NAME,
        label: 'Pricing model',
        iconName: '',
        dropdownKey: 'pricingModels',
        fullWidth: true,
      },
      {
        type: FIELD_TYPE_TEXT,
        name: PRICING_MODEL_DOMESTIC_FEE_FIELD_NAME,
        label: 'Domestic',
      },
      {
        type: FIELD_TYPE_DROPDOWN,
        name: PRICING_MODEL_DOMESTIC_CURRENCY_FIELD_NAME,
        dropdownKey: 'currencyUnits',
        label: '',
      },
      {
        type: FIELD_TYPE_TEXT,
        name: PRICING_MODEL_INTERNATIONAL_FEE_FIELD_NAME,
        label: 'International',
      },
      {
        type: FIELD_TYPE_DROPDOWN,
        name: PRICING_MODEL_INTERNATIONAL_CURRENCY_FIELD_NAME,
        dropdownKey: 'currencyUnits',
        label: '',
      },
      {
        type: FIELD_TYPE_TEXT,
        name: PRICING_MODEL_HAZMAT_DOMESTIC_FEE_FIELD_NAME,
        label: 'Domestic hazmat',
      },
      {
        type: FIELD_TYPE_DROPDOWN,
        name: PRICING_MODEL_HAZMAT_DOMESTIC_CURRENCY_FIELD_NAME,
        dropdownKey: 'currencyUnits',
        label: '',
      },
      {
        type: FIELD_TYPE_TEXT,
        name: PRICING_MODEL_HAZMAT_INTERNATIONAL_FEE_FIELD_NAME,
        label: 'International hazmat',
      },
      {
        type: FIELD_TYPE_DROPDOWN,
        name: PRICING_MODEL_HAZMAT_INTERNATIONAL_CURRENCY_FIELD_NAME,
        dropdownKey: 'currencyUnits',
        label: '',
      },
    ],
    validationSchema: Yup.object().shape({
      [DELAYED_PAYMENT_FIELD_NAME]: Yup.boolean(),
      [DISPLAY_TRANSACTION_FEE_FIELD_NAME]: Yup.boolean(),
      [DISPLAY_CREDIT_CARD_PROCESSING_FEE_FIELD_NAME]: Yup.boolean(),
      [CREDIT_CARD_FEE_FIELD_NAME]: Yup.number().min(0, 'Value can not be less than 0.').positive(),
      paymentOptions: Yup.object().shape({
        isACH: Yup.boolean(),
        isCostCode: Yup.boolean(),
        isCreditCard: Yup.boolean(),
        isPayPal: Yup.boolean(),
      }),
      [PRICING_MODEL_FIELD_NAME]: Yup.string(),
      [PRICING_MODEL_DOMESTIC_FEE_FIELD_NAME]: Yup.number().min(0, 'Value can not be less than 0.').positive(),
      [PRICING_MODEL_DOMESTIC_CURRENCY_FIELD_NAME]: Yup.string(),
      [PRICING_MODEL_HAZMAT_INTERNATIONAL_FEE_FIELD_NAME]: Yup.number().min(0, 'Value can not be less than 0.').positive(),
      [PRICING_MODEL_HAZMAT_INTERNATIONAL_CURRENCY_FIELD_NAME]: Yup.string(),
      [PRICING_MODEL_HAZMAT_DOMESTIC_FEE_FIELD_NAME]: Yup.number().min(0, 'Value can not be less than 0.').positive(),
      [PRICING_MODEL_HAZMAT_DOMESTIC_CURRENCY_FIELD_NAME]: Yup.string(),
      [PRICING_MODEL_HAZMAT_INTERNATIONAL_FEE_FIELD_NAME]: Yup.number().min(0, 'Value can not be less than 0.').positive(),
      [PRICING_MODEL_HAZMAT_INTERNATIONAL_CURRENCY_FIELD_NAME]: Yup.string(),

    }),
  },
  [COST_ALLOCATION_KEY]: {
    key: COST_ALLOCATION_KEY,
    title: 'Cost Allocations',
    fields: [
      // TO DO - Need to think how to handle this field. Cost allocation instructions is an array of strings.
      // {
      //   type: FIELD_TYPE_TEXTAREA,
      //   name: 'instructions',
      //   label: 'Instructions',
      //   fullWidth: true,
      //   iconName: '',
      //   icon: null,
      // },
      {
        type: FIELD_TYPE_BOOL,
        name: SPLIT_ALLOCATION_FIELD_NAME,
        label: 'Split Allocation',
      },
      {
        type: FIELD_TYPE_TEXT,
        name: MAX_ALLOCATION_FIELD_NAME,
        label: 'Max Allocations',
      },
      {
        type: FIELD_TYPE_BOOL,
        name: 'isDescriptionAllowed',
        label: 'Description Allowed',
      },
    ],
    validationSchema: Yup.object().shape({
      // instructions: Yup.string(),
      maxAllocations: Yup.number().when(SPLIT_ALLOCATION_FIELD_NAME, {
        is: true,
        then: (schema) => schema.required('This field is required.'),
        otherwise: (schema) => schema,
      }),
      splitAllocation: Yup.boolean(),
      isDescriptionAllowed: Yup.boolean(),
    }),
  },
  [TRAINING_KEY]: {
    key: TRAINING_KEY,
    title: 'Trainings',
    validationSchema: null,
  },
  [CARRIERS_KEY]: {
    key: CARRIERS_KEY,
    title: 'Available Carriers',
    fields: [
      {
        type: AVAILABLE_CARRIERS_FIELD_TYPE,
        name: AVAILABLE_CARRIERS_FIELD_NAME,
        label: '',
        fullWidth: true,
      },
    ],
    validationSchema: Yup.object().shape({
      [AVAILABLE_CARRIERS_FIELD_NAME]: Yup.object().shape({
        domestic: Yup.array().of(Yup.string()).min(1, 'At least one carrier should be checked.'),
        hazmat: Yup.array().of(Yup.string()).min(1, 'At least one carrier should be checked.'),
        international: Yup.array().of(Yup.string()).min(1, 'At least one carrier should be checked.'),
        freight: Yup.array().of(Yup.string()).min(1, 'At least one carrier should be checked.'),
      }),
    }),
  },
  [NOTIFICATIONS_KEY]: {
    key: NOTIFICATIONS_KEY,
    title: 'Notifications',
    fields: [
      {
        type: FIELD_TYPE_BOOL,
        name: `email.${EMAIL_ENABLED_FIELD_NAME}`,
        label: 'Email Notification',
        fullWidth: true,
      },
      {
        type: NOTIFICATION_TYPE_FIELD_NAME,
        name: `email.${NOTIFICATION_TYPE_FIELD_NAME}`,
        label: '',
        fullWidth: true,
      },
      {
        type: FIELD_TYPE_BOOL,
        name: `sms.${SMS_ENABLED_FIELD_NAME}`,
        label: 'SMS Notification',
        fullWidth: true,
      },
      {
        type: NOTIFICATION_TYPE_FIELD_NAME,
        name: `sms.${NOTIFICATION_TYPE_FIELD_NAME}`,
        label: '',
        fullWidth: true,
      },
    ],
    validationSchema: Yup.object().shape({
      email: Yup.object().shape({
        [EMAIL_ENABLED_FIELD_NAME]: Yup.boolean(),
        [NOTIFICATION_TYPE_FIELD_NAME]: Yup.array().of(Yup.string()),

      }),
      sms: Yup.object().shape({
        [SMS_ENABLED_FIELD_NAME]: Yup.boolean(),
        [NOTIFICATION_TYPE_FIELD_NAME]: Yup.array().of(Yup.string()),
      }),
    }),
  },
  [COMPLIANCE_KEY]: {
    key: COMPLIANCE_KEY,
    title: 'Compliance',
    fields: [
      {
        type: FIELD_TYPE_BOOL,
        name: OVERRIDE_EXPORT_COMPLIANCE_FIELD_NAME,
        label: 'Override Export Compliance',
      },
      {
        type: FIELD_TYPE_BOOL,
        name: AUTO_ASSIGNMENT_FIELD_NAME,
        label: 'Auto Assignment Allowed',
      },
    ],
    validationSchema: Yup.object().shape({
      [OVERRIDE_EXPORT_COMPLIANCE_FIELD_NAME]: Yup.boolean(),
      [AUTO_ASSIGNMENT_FIELD_NAME]: Yup.boolean(),
    }),
  },
  [MISCELLANEOUS_KEY]: {
    key: MISCELLANEOUS_KEY,
    title: 'Miscellaneous',
    fields: [
      {
        type: FIELD_TYPE_BOOL,
        name: USE_PROFILE_SIGNATURE_FIELD_NAME,
        label: 'Allow user profile signature document signing',
        fullWidth: true,
      },
    ],
    validationSchema: Yup.object().shape({
      [USE_PROFILE_SIGNATURE_FIELD_NAME]: Yup.boolean(),
    }),
  },
};

export const COMPLIANCE_FIELD_NAMES = ACCOUNT_MANAGEMENT[COMPLIANCE_KEY].fields.map(
  (field) => field.name,
);

export const MISCELLANEOUS_FIELD_NAMES = ACCOUNT_MANAGEMENT[MISCELLANEOUS_KEY].fields.map(
  (field) => field.name,
);
