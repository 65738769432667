import React from 'react';

import { GET_PARAM_DETAILS } from '../clientConstants';

import useGetConstraintState from '../hooks/useGetConstraintState';
import UserProfileDialog from './UserProfileDialog';
import UserProhibitedAckDialog from './UserProhibitedAckDialog';

const constraints = {
  [GET_PARAM_DETAILS.constraint.userProfile]: UserProfileDialog,
  [GET_PARAM_DETAILS.constraint.ackProhibited]: UserProhibitedAckDialog,
};

const GetParameterConstraints = () => {
  const { mountedConstraint, isOpened } = useGetConstraintState();
  const Component = constraints[mountedConstraint];
  if (!Component) {
    return null;
  }

  return <Component isOpened={isOpened} />;
};

export default GetParameterConstraints;
