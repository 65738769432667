import React, { memo } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { makeStyles } from '@material-ui/core/styles';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import AccountCircle from '@material-ui/icons/AccountCircle';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import CallOutlinedIcon from '@material-ui/icons/CallOutlined';
import BusinessOutlinedIcon from '@material-ui/icons/BusinessOutlined';
import LanguageOutlinedIcon from '@material-ui/icons/LanguageOutlined';
import LinkIcon from '@material-ui/icons/Link';
import * as colors from '../../styles/colors';
import {
  ICON_DEPARTMENT, ICON_EMAIL, ICON_FACEBOOK, ICON_LANGUAGE, ICON_LINK, ICON_LINKEDIN, ICON_MOBILE_PHONE, ICON_NAME, ICON_TIMEZONE, ICON_TITLE, ICON_TWITTER, ICON_WORK_PHONE,
} from '../../clientConstants';

const useStyles = makeStyles(() => ({
  root: {
    color: colors.white,
  },
}));

function getIcon(iconName, icon, className) {
  if (isEmpty(iconName) && !icon) return null;

  let IconComponent = AccountCircle;

  switch (iconName) {
    case ICON_EMAIL: {
      IconComponent = AccountCircle;
      break;
    }
    case ICON_WORK_PHONE: {
      IconComponent = CallOutlinedIcon;
      break;
    }
    case ICON_MOBILE_PHONE: {
      IconComponent = CallOutlinedIcon;
      break;
    }
    case ICON_LINKEDIN: {
      IconComponent = LinkedInIcon;
      break;
    }
    case ICON_TWITTER: {
      IconComponent = TwitterIcon;
      break;
    }
    case ICON_FACEBOOK: {
      IconComponent = FacebookIcon;
      break;
    }
    case ICON_DEPARTMENT: {
      IconComponent = BusinessOutlinedIcon;
      break;
    }
    case ICON_NAME: {
      IconComponent = PersonOutlineIcon;
      break;
    }
    case ICON_LANGUAGE: {
      IconComponent = LanguageOutlinedIcon;
      break;
    }
    case ICON_TITLE: {
      IconComponent = AccountBoxIcon;
      break;
    }
    case ICON_TIMEZONE: {
      IconComponent = AccessTimeIcon;
      break;
    }
    case ICON_LINK: {
      IconComponent = LinkIcon;
      break;
    }
    default: IconComponent = AccountCircle;
  }

  return <IconComponent className={className} />;
}

const ReadOnlyIcon = ({
  iconName, icon,
}) => {
  const classes = useStyles();

  return getIcon(iconName, icon, classes.root);
};

ReadOnlyIcon.defaultProps = {
  iconName: '',
  icon: null,
};

ReadOnlyIcon.propTypes = {
  iconName: PropTypes.string,
  icon: PropTypes.element,
};

export default memo(ReadOnlyIcon);
