import React from 'react';
import MuiTextField from '@material-ui/core/TextField';
import { fieldToTextField } from 'formik-material-ui';
import PropTypes from 'prop-types';
import { NEW_ORDER_INPUT_LABEL_PROPS, NEW_ORDER_INPUT_PROPS } from '../../styles/style';

const removeUnneededFields = (formikProps) => {
  const { blockValue, customOnChange, ...everythingElse } = formikProps;
  return everythingElse;
};
export default function CustomTextField(props) {
  const propsWithFormik = fieldToTextField(props);
  const { blockValue, customOnChange } = props;
  const inputStyle = {
    height: propsWithFormik.multiline ? 'auto' : undefined,
    paddingTop: !propsWithFormik.label ? '8px' : undefined,
  };

  return (
    <MuiTextField
      {...removeUnneededFields(propsWithFormik)}
      onChange={(e) => {
        if (propsWithFormik.value !== e.target.value
          && (!blockValue || !blockValue(e.target.value))) {
          if (propsWithFormik.overrideOnChange) {
            propsWithFormik.overrideOnChange(e);
            return;
          }
          if (customOnChange) { customOnChange(e); }
          propsWithFormik.onChange(e);
        }
      }}
      variant="filled"
      InputLabelProps={{
        ...(propsWithFormik.InputLabelProps || {}),
        classes: NEW_ORDER_INPUT_LABEL_PROPS(),
      }}
      InputProps={{
        ...(propsWithFormik.InputProps || {}),
        fullWidth: true,
        classes: NEW_ORDER_INPUT_PROPS(),
        style: inputStyle,
      }}
      // onBlur={(e) => {
      //   console.log('BLUR', e);
      // }}
      autoComplete={props.autoComplete}
    />
  );
}

CustomTextField.propTypes = {
  customOnChange: PropTypes.func,
  blockValue: PropTypes.func,
  key: PropTypes.string,
  autoComplete: PropTypes.string,
};

CustomTextField.defaultProps = {
  customOnChange: undefined,
  blockValue: undefined,
  key: undefined,
  autoComplete: '',
};
