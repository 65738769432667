import React from 'react';
import axios from 'axios';
import { css } from 'emotion';
import styled from '@emotion/styled';
import { Formik, Field, Form } from 'formik';
import { Route, Redirect } from 'react-router-dom';
import { CheckboxWithLabel } from 'formik-material-ui';
import { makeStyles } from '@material-ui/core/styles';
import * as Yup from 'yup';
import { Button, Grid, Typography } from '@material-ui/core';
import ValidationCheckboxWithLabel from './ValidationCheckboxWithLabel';
import LoginTextField from './LoginTextField';
import LoginPasswordField from './LoginPasswordField';
import * as colors from '../styles/colors';
import CheckboxLabelWithLink from './CheckboxLabelWithLink';

const styles = {
  textField: {
    color: '#FFFFFF',
    minHeight: 76,
    paddingBottom: 5,
  },
  button: {
    float: 'left',
    fontWeight: 'bold',
    width: 100,
  },
  checkbox: {
    color: 'white',
  },
  invisibleButton: {
    float: 'left',
    fontWeight: 'bold',
    borderColor: '#2A79D4',
    color: '#6AB4F2',
    borderRadius: 8,
    backgroundColor: 'rgba(255, 255, 255, 0.12)',
    width: 100,
    height: 36,
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.30)',
    },
  },
  loginContainer: {
    alignItems: 'center',
  },
  title: {
    color: colors.white,
    fontSize: 24,
    fontWeight: 500,
    paddingBottom: 22,
  },
  gridItem: {
    zIndex: 200,
  },
  validationContainer: {
    paddingBottom: 20,
  },
  checkboxesContainer: {
    paddingBottom: 20,
  },
};

const ErrorMessage = function ({ message }) {
  return (
    <Typography
      className={css`
        color: ${colors.danger};
        font-size: .75rem;
        margin-left: 12px;
        line-height: 1em;
        height: 1em;
      `}
    >
      {message}

    </Typography>
  );
};

const ErrorMessageDiv = styled.div`
  height: 1em;
`;

function NavigateToLoginButton() {
  const classes = useStyles();
  return (
    <Route render={({ history }) => (
      <Button
        className={classes.invisibleButton}
        color="primary"
        variant="contained"
        onClick={() => { history.push('/login'); }}
      >
        Login
      </Button>
    )}
    />
  );
}

const useStyles = makeStyles(styles);

export default function SignUpForm(props) {
  const classes = useStyles();

  const [hasValidCharCount, setHasValidCharCount] = React.useState(false);
  const [hasNumber, setHasNumber] = React.useState(false);
  const [hasUppercaseChar, setHasUppercaseChar] = React.useState(false);
  const [hasLowercaseChar, setHasLowercaseChar] = React.useState(false);
  const [hasSpecialChar, setHasSpecialChar] = React.useState(false);
  const [redirectToLogin, setRedirectToLogin] = React.useState(false);

  function validatePassword(password) {
    const hasLowercaseRegex = /[a-z]/gm;
    const hasUppercaseRegex = /[A-Z]/gm;
    const hasNumberRegex = /[0-9]/gm;
    const hasSpecialCharRegex = /[!@#$%^&*(),.?":{}|<>]/gm;
    password.length >= 8 ? setHasValidCharCount(true) : setHasValidCharCount(false);
    hasLowercaseRegex.test(password) ? setHasLowercaseChar(true) : setHasLowercaseChar(false);
    hasUppercaseRegex.test(password) ? setHasUppercaseChar(true) : setHasUppercaseChar(false);
    hasNumberRegex.test(password) ? setHasNumber(true) : setHasNumber(false);
    hasSpecialCharRegex.test(password) ? setHasSpecialChar(true) : setHasSpecialChar(false);
  }

  const handleSubmit = async (values) => {
    try {
      const options = {
        method: 'post',
        url: '/sign_up',
        data: {
          email: values.email,
          password: values.password,
          confirmed_password: values.confirmPassword,
          firstName: values.name.split(' ')[0] || values.name,
          lastName: values.name.split(' ').slice(1).join(' ') || '',
          language: 'en',
        },
        mode: 'no-cors',
      };

      const res = await axios(options);
      alert(res.data);
      setRedirectToLogin(true);
    } catch (err) {
      alert(err.response.data);
    }
  };

  const wasSubmitAttempted = function (form) {
    return form.submitCount > 0;
  };

  return (
    <Formik
      validateOnBlur={false}
      initialValues={{
        name: '',
        email: '',
        password: '',
        confirmPassword: '',
        termsAndConditions: false,
        privacyPolicy: false,
      }}
      onSubmit={(values, { setSubmitting }) => {
        handleSubmit(values);
        setSubmitting(false);
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required('Required'),
        email: Yup.string()
          .email('Invalid email')
          .required('Required'),
        password: Yup.string()
          .matches(/^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*(),.?":{}|<>]).{8,}/, { message: 'Must fulfill security criteria listed above' })
          .required('Required'),
        confirmPassword: Yup.string()
          .oneOf([Yup.ref('password'), null], 'Passwords must match')
          .required('Please confirm your password'),
        termsAndConditions: Yup.mixed().oneOf([true], 'Must agree to proceed'),
        privacyPolicy: Yup.mixed().oneOf([true], 'Must agree to proceed'),
      })}
      render={(props) => (
        <div className={classes.loginContainer}>
          {
            redirectToLogin && <Redirect push to="/login" />
          }
          <Form>
            <Grid
              item
              container
              direction="column"
              justify="flex-start"
            >
              <Grid item className={classes.gridItem}>
                <Typography className={classes.title}>Let&apos;s get started!</Typography>
              </Grid>
              <Grid item>
                <Field
                  className={classes.textField}
                  name="name"
                  type="name"
                  variant="outlined"
                  label="Name"
                  component={LoginTextField}
                  fullWidth
                />
              </Grid>
              <Grid item>
                <Field
                  className={classes.textField}
                  name="email"
                  type="email"
                  variant="outlined"
                  label="Email"
                  component={LoginTextField}
                  fullWidth
                />
              </Grid>
              <Grid container className={classes.validationContainer} justify="space-between" item>
                <Grid xs={6} item className={classes.gridItem}>
                  <ValidationCheckboxWithLabel isGreenWhenValid isValidated={hasValidCharCount} labelText="8 or more characters" />
                </Grid>
                <Grid xs={6} item className={classes.gridItem}>
                  <ValidationCheckboxWithLabel isGreenWhenValid isValidated={hasUppercaseChar} labelText="Uppercase" />
                </Grid>
                <Grid xs={6} item className={classes.gridItem}>
                  <ValidationCheckboxWithLabel isGreenWhenValid isValidated={hasNumber} labelText="At least 1 number" />
                </Grid>
                <Grid xs={6} item className={classes.gridItem}>
                  <ValidationCheckboxWithLabel isGreenWhenValid isValidated={hasLowercaseChar} labelText="Lowercase" />
                </Grid>
                <Grid xs={6} item className={classes.gridItem}>
                  <ValidationCheckboxWithLabel isGreenWhenValid isValidated={hasSpecialChar} labelText="At least 1 special character" />
                </Grid>
              </Grid>
              <Grid item>
                <Field
                  className={classes.textField}
                  name="password"
                  type="password"
                  variant="outlined"
                  label="Password"
                  component={LoginPasswordField}
                  {...{
                    label: 'Password',
                  }}
                  fullWidth
                  validate={(value) => validatePassword(value)}
                />
              </Grid>
              <Grid item>
                <Field
                  className={`${classes.textField}`}
                  name="confirmPassword"
                  type="password"
                  variant="outlined"
                  label="Confirm Password"
                  component={LoginPasswordField}
                  {...{
                    label: 'Confirm Password',
                  }}
                  fullWidth
                />
              </Grid>
              <Grid container item className={`${classes.gridItem} ${classes.checkboxesContainer}`}>
                <Grid item xs={7}>
                  <Field
                    className={classes.checkbox}
                    name="termsAndConditions"
                    Label={{ label: <CheckboxLabelWithLink labelText="I agree to" linkText="Terms and Conditions" linkTo="https://study.eshipglobal.com/TermsofService_eShipGlobal.pdf" /> }}
                    component={CheckboxWithLabel}
                  />
                </Grid>
                <Grid item container alignItems="center" xs={5}>
                  <ErrorMessageDiv>
                    {(props.errors.termsAndConditions
                  && wasSubmitAttempted(props))
                && <ErrorMessage message={props.errors.termsAndConditions} />}
                  </ErrorMessageDiv>
                </Grid>
                <Grid item xs={7}>
                  <Field
                    className={classes.checkbox}
                    name="privacyPolicy"
                    Label={{ label: <CheckboxLabelWithLink labelText="I agree to" linkText="Privacy Policy" linkTo="https://study.eshipglobal.com/help/default.asp?page=8" /> }}
                    component={CheckboxWithLabel}
                  />
                </Grid>
                <Grid item container alignItems="center" xs={5}>
                  <ErrorMessageDiv>
                    {(props.errors.privacyPolicy && wasSubmitAttempted(props))
                  && <ErrorMessage message={props.errors.privacyPolicy} />}
                  </ErrorMessageDiv>
                </Grid>
              </Grid>
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={4} sm={3} md={3}>
                  <Button
                    className={classes.button}
                    color="secondary"
                    variant="contained"
                    disabled={props.isSubmitting}
                    onClick={props.submitForm}
                  >
                    Sign Up
                  </Button>
                </Grid>
                <Grid item xs={4} sm={3}>
                  <NavigateToLoginButton />
                </Grid>
              </Grid>
            </Grid>
          </Form>
        </div>
      )}
    />
  );
}
