import React, {
  memo, useRef, useState, useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import has from 'lodash/has';
import { Button, Grid, Typography } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';
import * as colors from '../../styles/colors';

const useStyles = makeStyles(() => ({
  uploadFileButton: {
    width: '70%',
    height: '50px',
    background: 'rgb(54,57,69)',
    boxShadow: '0 1px 5px -2px black',
    margin: 'auto',
  },
  uploadFileButtonLabel: {
    textTransform: 'none',
    color: colors.white,
    fontWeight: 500,
    paddingLeft: '5px',
  },
  uploadFileButtonIcon: {
    color: 'rgba(255,255,255,0.6)',
    display: 'flex',
  },
}));

const UploadInput = React.forwardRef(({
  name, acceptedFiles, onChange, showIcon,
}, ref) => {
  const classes = useStyles();
  const fileInputRef = useRef(null);
  const [fileName, setFileName] = useState('');

  useImperativeHandle(ref, () => ({
    setFileName,
  }));

  return (
    <Grid
      item
      container
    >
      <Button
        className={classes.uploadFileButton}
        onClick={() => fileInputRef.current && fileInputRef.current.click()}
      >
        <Grid container justify="space-between" alignItems="center">
          <Grid item style={{ maxWidth: 'calc(100% - 2em)' }}>
            <Typography noWrap className={classes.uploadFileButtonLabel}>
              {!isEmpty(fileName)
                ? fileName
                : 'Choose File'}
            </Typography>
          </Grid>
          {showIcon && (
          <Grid item className={classes.uploadFileButtonIcon}>
            <SearchIcon />
          </Grid>
          )}
        </Grid>
      </Button>
      <input
        style={{ display: 'none' }}
        ref={fileInputRef}
        type="file"
        name={name}
        accept={acceptedFiles}
        onChange={(e) => {
          const currentFile = e.target.files[0];

          setFileName(currentFile.name);
          onChange(currentFile);
        }}
        onClick={(e) => {
          e.target.value = null;
        }}
      />
    </Grid>
  );
});

UploadInput.defaultProps = {
  showIcon: true,
};

UploadInput.propTypes = {
  name: PropTypes.string.isRequired,
  acceptedFiles: PropTypes.string.isRequired,
  showIcon: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default memo(UploadInput);
