import React, { useState, useRef, useEffect } from 'react';
import {
  Form, Formik,
} from 'formik';
import PropTypes from 'prop-types';
import {
  CircularProgress, Divider, Grid, Typography, Button, IconButton,
} from '@material-ui/core';
import { css } from 'emotion';
import shortid from 'shortid';
import PrintIcon from '@material-ui/icons/PrintOutlined';
import { CustomCheckbox } from '../common/InputComponents';
import NewOrderNextButton from '../common/NewOrderNextButton';
import NewOrderWideButton from '../common/NewOrderWideButton';
import StyledCheckCircleIcon from '../common/StyledCheckCircleIcon';
import { useContactsState } from '../../context/contactsContext';

import {
  formatMonetaryAmount,
} from '../../utils/helpers';
import { NEW_ORDER_BILLING_PAYMENT_STYLE } from '../../styles/style';
import * as colors from '../../styles/colors';
import * as billingUtil from '../../utils/billingUtil';
import '../../styles/braintreeDropIn.css';
import NewOrderErrorDialog from '../common/NewOrderErrorDialog';
import CustomPayment from './CustomPayment';
import CustomBilling from './CustomBilling';
import BillingCharges from './BillingCharges';
import {
  usePaymentDispatch, usePaymentState, loadSavedPaymentMethods,
} from '../../context/paymentContext';
import { getPaymentReceipt } from '../../utils/paymentClient';
import { printPDF, base64ToArrayBuffer } from '../../utils/documentsUtil';

import {
  getTypography,
  isProviderPayment,
  getEmptyAllocation,
  getPaymentToken,
} from './util';

import {
  BILLING_TYPE,
  PAYMENT_TYPE,
  DEFAULT_COUNTRY,
  BILLING_BOCA_VALUE,
  ACCOUNT_NUMBER,
  COUNTRY,
  ZIP,
  PAYMENT_COSTCODES_VALUE,
  PAYMENT_CREDITCARD_VALUE,
  PAYMENT_PAYPAL_VALUE,
  PAYMENT_ACH_VALUE,
  CREDIT_CARD_OWNERSHIP_TYPE_OPTIONS,
  ACH_OWNERSHIP_TYPE_OPTIONS,
  DEFAULT_VALUES,
  INITIAL_COST_ALLOCATION,
  ACH_AUTH_TEXT,
} from './constants';
import DocumentPreview from '../common/DocumentPreview';

/**
 * ##################################
 *          CUSTOM COMPONENT
 * ##################################
 */

function CustomHeader({ carrierDetails, billingDetails }) {
  const { myPrice, currencyCode } = carrierDetails;

  let displayPrice = myPrice;
  if (billingDetails) displayPrice = billingDetails.amount;
  const formatDispPrice = formatMonetaryAmount(currencyCode, displayPrice || '0.00');

  return (
    <Grid item container direction="column" spacing={2}>
      <Grid item>
        {getTypography('total-cost-header', 'Total Shipping Cost', css`
          color: ${colors.white};
          font-weight: 500;
        `)}
      </Grid>
      <Grid item>
        {getTypography('total-cost-price', formatDispPrice, css`
          color: ${colors.lightBlue};
          font-size: 24px;
          font-weight: 500;
        `)}
      </Grid>
    </Grid>
  );
}

CustomHeader.propTypes = {
  carrierDetails: PropTypes.shape({
    myPrice: PropTypes.string.isRequired,
    currencyCode: PropTypes.string.isRequired,
  }).isRequired,
  billingDetails: PropTypes.shape({ amount: PropTypes.string.isRequired }),
};
CustomHeader.defaultProps = {
  billingDetails: null,
};

function CustomFooter({
  values = {}, billingDetails = null,
  isSubmitting, classes, shipmentId,
  submitForm, onShowNextPanel, isDelayedPayment,
  billingTask = null,
}) {
  const [preview, setPreview] = React.useState(null);

  const lockPayment = isProviderPayment(billingDetails);
  const submitButtonLabel = isDelayedPayment ? 'Authorize Payment' : 'Submit Payment';
  const { paymentType } = values;

  const transactionId = billingDetails?.transactions && billingDetails?.transactions.length > 0 ? billingDetails?.transactions[0].transactionId : shipmentId;
  const onPreview = async () => {
    try {
      const receipt = await getPaymentReceipt(shipmentId, transactionId);
      if (!receipt) return;
      setPreview(receipt);
    } catch (error) {
      console.error(error);
    }
  };

  const onPrint = async () => {
    try {
      const receipt = await getPaymentReceipt(shipmentId, transactionId);
      if (!receipt) return;
      const arrayBuffer = base64ToArrayBuffer(receipt);
      printPDF(arrayBuffer);
    } catch (error) {
      console.error(error);
    }
  };

  const isSubmitButtonDisabled = () => {
    const { allocations } = values;

    return (paymentType === PAYMENT_COSTCODES_VALUE
      && allocations
      && (allocations.length === 0 || hasEditingAllocation(allocations)));
  };
  const isDisabled = (isSubmitting || isSubmitButtonDisabled());

  const getSubmitButton = () => {
    if (paymentType === PAYMENT_PAYPAL_VALUE) return;
    return (
      <NewOrderWideButton disabled={isDisabled} onClick={submitForm}>
        {submitButtonLabel}
      </NewOrderWideButton>
    );
  };

  const getNextButton = () => (
    <NewOrderNextButton
      disabled={isDisabled}
      onClick={onShowNextPanel}
    >
      Next
    </NewOrderNextButton>
  );

  const hasBillingChange = () => {
    const accountNumberBD = billingDetails?.[ACCOUNT_NUMBER];
    const countryBD = billingDetails?.[COUNTRY];
    const zipBD = billingDetails?.[ZIP];
    const { accountNumber, country, zip } = values;

    return (accountNumberBD !== accountNumber || countryBD !== country || zipBD !== zip);
  };

  const hasCostCodeChange = () => {
    const billCodesBD = billingDetails?.billCodes;
    const { billCodes } = values;

    if (!billCodes) return true;

    if (billCodesBD.length !== billCodes.length) return true;

    if (billingTask?.status === 'In-Progress') {
      return true;
    }

    for (let i = 0, end = billCodes.length; i < end; i += 1) {
      const billCodeBDSet = billCodesBD[i];
      const billCodeSet = billCodes[i];

      const keysToTest = Object.keys(billCodeBDSet);
      for (let j = 0, endj = keysToTest.length; j < endj; j += 1) {
        const key = keysToTest[j];

        if (!(key in billCodeSet)) return true;

        if (typeof billCodeBDSet[key] === 'string'
        && billCodeBDSet[key] !== billCodeSet[key]) return true;

        if (typeof billCodeBDSet[key] === 'object') {
          const subKeys = Object.keys(billCodeBDSet[key]);

          if (subKeys.filter((subKey) => !(subKey in billCodeSet[key])
          || billCodeBDSet[key][subKey] !== billCodeSet[key][subKey]).length > 0) {
            return true;
          }
        }
      }
    }

    return false;
  };

  /*        RENDER PROPS            */

  const paymentPosted = (isProviderPayment(billingDetails))
    ? (
      <>
        <Grid
          item
          className={css`
            padding-right: 10px;
            display: flex;
            align-items: center;
          `}
        >
          <StyledCheckCircleIcon
            isValidated
            isGreenWhenValid
            classes={classes}
          />
        </Grid>
        <Grid item>
          {getTypography('footer-successfull',
            transactionId ? 'Your payment has been successfully processed.' : 'Your payment has been successfully authorized and will be processed when shipment completes',
            css``)}
          {
            transactionId && (
              <Grid item container alignItems="center" spacing={1}>
                <Grid item>
                  <Typography>
                    Transaction ID:
                  </Typography>
                </Grid>
                <Grid item>
                  <Button
                    style={{
                      padding: 0,
                      color: colors.lightBlue,
                      fontSize: 'inherit',
                      fontWeight: 'inherit',
                      textDecoration: 'underline',
                      textTransform: 'none',
                    }}
                    onClick={onPreview}
                  >
                    <b>{transactionId}</b>
                  </Button>
                </Grid>
                <Grid item>
                  <IconButton
                    style={{ padding: 0, color: colors.lightBlue }}
                    onClick={onPrint}
                  >
                    <PrintIcon style={{ height: '.875em' }} />
                  </IconButton>
                </Grid>
              </Grid>
            )
          }
          <DocumentPreview
            open={!!preview}
            onClose={() => setPreview(null)}
            doc={preview}
          />
        </Grid>
      </>
    )
    : null;

  function getButton() {
    if (!billingDetails) return getSubmitButton();

    const billingTypeBD = billingDetails[BILLING_TYPE];
    const paymentTypeBD = billingDetails[PAYMENT_TYPE];
    const { billingType } = values;

    if (billingTypeBD !== billingType || paymentTypeBD !== paymentType) {
      return getSubmitButton();
    }

    if (billingTypeBD === BILLING_BOCA_VALUE && hasBillingChange()) return getSubmitButton();

    if (paymentTypeBD === PAYMENT_COSTCODES_VALUE && hasCostCodeChange()) return getSubmitButton();

    return getNextButton();
  }

  function getSubmittingFooter() {
    return (
      <>
        <Grid
          item
          className={css`
            margin-left: auto;
            margin-right: auto;
            padding-top: 36px;
            padding-bottom: 12px;
          `}
        >
          <CircularProgress color="secondary" />
        </Grid>
        {(isProviderPayment(values))
          ? (
            <Grid
              item
              direction="column"
              alignContent="center"
              className={css`
                text-align: center;
                color: ${colors.white};
              `}
            >
              {getTypography('footer-processing', 'We are processing your payment.', css``)}
              {getTypography('footer-do-not-refresh',
                'Please do not close or refresh this window.',
                css``)}
            </Grid>
          )
          : null}
      </>
    );
  }

  return (
    <Grid item container direction="column">
      <Grid
        item
        container
        justify="center"
        className={(isProviderPayment(billingDetails))
          ? css`
              color: ${colors.white};
              padding-top: 36px;
            `
          : css``}
      >
        {paymentPosted}
      </Grid>
      {(paymentType === PAYMENT_ACH_VALUE && !lockPayment)
        ? (
          <Grid
            item
            className={css`
          padding-top: 12px;
        `}>
            <CustomCheckbox
              name="bankAccountDetails.achMandate"
              classes={{
                root: css`
                  align-self: flex-start;
                  padding-top: 0px;
                  `,
              }}
              label={ACH_AUTH_TEXT}
              disabled={isDisabled}
            />
          </Grid>
        ) : null}
      <Grid
        item
        className={css`
          padding-top: 12px;
        `}
      >
        {getButton()}
      </Grid>
      {(isSubmitting)
        ? getSubmittingFooter()
        : null}
    </Grid>
  );
}

CustomFooter.propTypes = {
  values: PropTypes.shape({
    billingType: PropTypes.string.isRequired,
    billCodes: PropTypes.arrayOf(PropTypes.object),
    paymentType: PropTypes.string.isRequired,
    allocations: PropTypes.arrayOf(PropTypes.object),
    accountNumber: PropTypes.string,
    country: PropTypes.string,
    zip: PropTypes.string,
  }).isRequired,
  billingDetails: PropTypes.shape({
    [BILLING_TYPE]: PropTypes.string.isRequired,
    [PAYMENT_TYPE]: PropTypes.string.isRequired,
    [ACCOUNT_NUMBER]: PropTypes.string,
    [COUNTRY]: PropTypes.string,
    [ZIP]: PropTypes.string,
    transaction: PropTypes.shape({
      transactionId: PropTypes.string.isRequired,
    }),
  }),
  isSubmitting: PropTypes.bool.isRequired,
  submitForm: PropTypes.func.isRequired,
  onShowNextPanel: PropTypes.func.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  shipmentId: PropTypes.string.isRequired,
};
CustomFooter.defaultProps = {
  billingDetails: null,
};

/**
 * ######################
 *          UTIL
 * ######################
 */

function hasEditingAllocation(allocations) {
  return allocations.filter((allocation) => ('isEditing' in allocation)).length > 0;
}

function getHiddenCreditCardFields(lastFour) {
  return {
    creditCardName: 'Hidden for security reasons',
    creditCardNumber: `**** **** **** ${lastFour || ''}`,
    creditCardExpiration: '**/**',
    creditCardCVV: '***',
    creditCardZip: '*****',
  };
}

async function getAchPayload(values, usBankAccountInstance) {
  if (!usBankAccountInstance) {
    throw new Error('Unable to connect to payment provider');
  }
  const { bankAccountDetails, billingAddress } = values;
  const streetAddress = billingAddress.addressLine1;
  const extendedAddress = [billingAddress.addressLine2, billingAddress.addressLine3]
    .filter((al) => !!al)
    .join(', ');
  const locality = billingAddress.city;
  const postalCode = billingAddress.zip;
  const region = billingAddress.state;
  const ownershipType = bankAccountDetails.ownerShipType;
  const payload = await usBankAccountInstance.tokenize({
    bankDetails: {
      ...bankAccountDetails,
      billingAddress: {
        streetAddress,
        extendedAddress,
        locality,
        region,
        postalCode,
      },
      ownershipType,
    },
    // eslint-disable-next-line max-len
    mandateText: ACH_AUTH_TEXT,
  });
  const { description, nonce } = payload;

  return {
    paymentNonce: nonce,
    bankAccountDetails: { ...bankAccountDetails, lastFour: description },
  };
}

/**
 * #################################
 *          EXPORT FUNCTION
 * #################################
 */

const BillingPaymentForm = ({
  shipment, accountType, purpose, selectedForms,
  formName,
  openNextPanel, submitFormToApi, isDelayedPayment,
}) => {
  const [hostedFieldsInstance, setHostedFieldsInstance] = useState(null);
  const [usBankAccountInstance, setUSBankAccountInstance] = useState(null);
  const [error, setError] = React.useState(null);
  const dispatch = usePaymentDispatch();

  const carrierDetails = shipment?.carrier?.details;
  const billingDetails = shipment?.[formName]?.details;
  const billingTask = shipment?.[formName]?.task;

  const { isCreatedBy } = shipment;

  const { defaultSender } = useContactsState();
  const { costAllocation, savedPaymentMethods, costCodeProfile } = usePaymentState();

  const classes = NEW_ORDER_BILLING_PAYMENT_STYLE();

  const getSelectedBillingOption = () => formikRef?.current?.state?.values[BILLING_TYPE];

  const getSavedAllocations = () => {
    if (!(formikRef && formikRef.current)) return [];

    const { values } = formikRef.current.state;
    const allocSummary = {};
    Object.keys(values)
      .filter((key) => key.includes('allocation'))
      .forEach((allocKey) => { allocSummary[allocKey] = values[allocKey]; });

    return allocSummary;
  };

  const onShowNextPanel = () => openNextPanel();

  /*          FORMIK PROPS            */

  const formikRef = useRef();
  const validateOnBlur = false;

  useEffect(() => {
    const { paymentToken, paymentTokenDetails } = billingDetails;
    if (formikRef && formikRef.current && (paymentToken || paymentTokenDetails?.paymentMethodToken)) {
      formikRef.current.setFieldValue('paymentToken', getPaymentToken(billingDetails, null));
    }
  }, []);

  function getInitialValues(ca) {
    const initialBillCodes = ((billingDetails && billingDetails.billCodes)
      ? [...billingDetails.billCodes]
      : []).map((bc) => ({ ...bc, key: shortid.generate() }));
    let costCodeProfileName = '';
    const defaultCreditCardOwnershipType = CREDIT_CARD_OWNERSHIP_TYPE_OPTIONS
      .find((cto) => cto.purpose === purpose);
    const defaultAchOwnershipType = ACH_OWNERSHIP_TYPE_OPTIONS.find((cto) => cto.purpose === purpose);

    if (ca && ca.billCodes && initialBillCodes.length <= 0) {
      initialBillCodes.push(getEmptyAllocation(ca.billCodes));
      if (costCodeProfile.find((a) => a.isDefault === true)) {
        const { billCodes, profileName } = costCodeProfile.find((a) => a.isDefault === true);
        initialBillCodes[0] = { ...billCodes, allocation: initialBillCodes[0].allocation };
        costCodeProfileName = profileName;
      }
    }

    const defaultCreditCard = savedPaymentMethods.creditCards.find((paymentMethod) => paymentMethod.isDefault);
    const defaultBankAccount = savedPaymentMethods.bankAccounts.find((paymentMethod) => paymentMethod.isDefault);
    const defaultPayment = defaultCreditCard || defaultBankAccount;

    const paymentType = (billingDetails && billingDetails.paymentType) || (defaultCreditCard ? PAYMENT_CREDITCARD_VALUE : null) || (defaultBankAccount ? PAYMENT_ACH_VALUE : null) || DEFAULT_VALUES.paymentType;

    const paymentToken = getPaymentToken(billingDetails, defaultPayment?.paymentToken);

    const billingAddress = (billingDetails && billingDetails.billingAddress)
      ? { ...billingDetails.billingAddress }
      : { ...defaultSender };

    const isSavePayment = billingDetails && billingDetails.isSavePayment ? billingDetails.isSavePayment : false;

    const creditCardDetails = (billingDetails && billingDetails.creditCardDetails)
      ? {
        ...billingDetails.creditCardDetails,
        ...getHiddenCreditCardFields(billingDetails.creditCardDetails.lastFour),
      }
      : { ownerShipType: defaultCreditCardOwnershipType ? defaultCreditCardOwnershipType.value : null };

    const bankAccountDetails = (billingDetails && billingDetails.bankAccountDetails) ? {
      ...billingDetails.bankAccountDetails,
    }
      : { ownerShipType: defaultAchOwnershipType ? defaultAchOwnershipType.value : '' };

    const initialValues = {
      ...DEFAULT_VALUES,
      amount: carrierDetails.myPrice,
      currencyCode: carrierDetails.currencyCode,
      profileName: costCodeProfileName,
      billCodes: initialBillCodes,
      ...(billingDetails.billingType && {
        billingType: billingDetails.billingType,
      }),
      ...(billingDetails.accountNumber && {
        accountNumber: billingDetails.accountNumber,
      }),
      ...(billingDetails.country && {
        country: billingDetails.country,
      }),
      ...(billingDetails.zip && {
        zip: billingDetails.zip,
      }),
      billingAddress: {
        country: DEFAULT_COUNTRY,
        city: '',
        state: '',
        zip: '',
        addressLine1: '',
        addressLine2: '',
        addressLine3: '',
        phone: '',
        firstName: '',
        lastName: '',
        ...billingAddress,
      },
      useDefaultAddress: !!defaultSender,
      creditCardDetails,
      paymentToken,
      paymentType,
      isSavePayment,
      bankAccountDetails: {
        achMandate: true,
        businessName: '',
        accountType: '',
        routingNumber: '',
        accountNumber: '',
        firstName: '',
        lastName: '',
        ownerShipType: '',
        ...bankAccountDetails,
      },
    };
    return initialValues;
  }

  async function getCreditCardPayload(values, currentSavedPayments) {
    if (!hostedFieldsInstance) {
      throw new Error('Unable to connect to Credit Card payment provider');
    }

    const creditCard = values.creditCardDetails;

    const payload = await hostedFieldsInstance.tokenize();

    creditCard.lastFour = payload.details.lastFour;
    creditCard.paymentNonce = payload.nonce;

    return creditCard;
  }

  async function onSubmit(values, { setSubmitting, setFieldValue, resetForm }) {
    const { paymentType, paymentToken, bankAccountDetails } = values;
    const { achMandate } = bankAccountDetails;
    let paymentPayload = {};
    let currentSavedPayments;
    const getBillingSchemaOptions = {
      postPayment: true,
    };

    if (paymentType === PAYMENT_ACH_VALUE && !achMandate) {
      setError('Unable to complete transaction, you must agree to authorize bank transfer');
      setSubmitting(false);
      return;
    }

    if (!paymentToken) {
      try {
        if (paymentType === PAYMENT_CREDITCARD_VALUE) {
          paymentPayload = await getCreditCardPayload(values, currentSavedPayments);
        }
        if (paymentType === PAYMENT_ACH_VALUE) {
          paymentPayload = await getAchPayload(values, usBankAccountInstance);
        }
      } catch (err) {
        setError('Unable to complete your transaction. Please check your payment details.');
        setSubmitting(false);
        return;
      }
    }

    const formattedPayload = billingUtil.formatBillingForSubmission({ ...values, ...paymentPayload });
    let strippedPayload;
    try {
      const schema = billingUtil.getBillingSchema(costAllocation, getBillingSchemaOptions);
      strippedPayload = await schema.validate(formattedPayload, { stripUnknown: true });
    } catch (ex) {
      setError('Something went wrong. Please check your inputs');
      setSubmitting(false);
      return;
    }
    strippedPayload.billingKey = formName;

    if (paymentType === PAYMENT_CREDITCARD_VALUE && !strippedPayload.creditCardDetails && savedPaymentMethods?.creditCards) {
      const cardMatch = savedPaymentMethods.creditCards.find(({ paymentToken }) => paymentToken === strippedPayload.paymentToken);
      if (cardMatch) {
        strippedPayload.creditCardDetails = {
          lastFour: cardMatch.creditCardDetails?.lastFour,
          ownerShipType: cardMatch.ownerShipType,
        };
        strippedPayload.billingAddress = {
          ...cardMatch.billingAddress,
        };
      }
    }

    if (paymentType === PAYMENT_ACH_VALUE && !strippedPayload.bankAccountDetails && savedPaymentMethods?.bankAccounts) {
      const cardMatch = savedPaymentMethods.bankAccounts.find(({ paymentToken }) => paymentToken === strippedPayload.paymentToken);
      if (cardMatch) {
        strippedPayload.bankAccountDetails = {
          achMandate: true,
          lastFour: cardMatch.bankAccountDetails?.lastFour,
        };
      }
    }

    if (values.isSavePayment) strippedPayload.isSavePayment = values.isSavePayment;

    try {
      await submitFormToApi(strippedPayload);
      // resetForm();
    } catch (e) {
      //
    }

    if (isDelayedPayment || values.isSavePayment) loadSavedPaymentMethods(dispatch);
    setSubmitting(false);
  }

  function render({
    values, isSubmitting,
    submitForm, setFieldValue,
  }) {
    const { billingType, paymentType, useDefaultAddress } = values;

    return (
      <>
        <Form>
          <Grid container className={css`padding-top: 6px;`}>
            <BillingCharges
              charges={shipment?.[formName]?.details?.billingCharges}
              total={shipment?.[formName]?.details?.amount}
              currencyCode={carrierDetails?.currencyCode}
            />
            {/* <CustomHeader {...{ carrierDetails, billingDetails }} /> */}
            <Divider className={css`
              width: 100%;
              background-color: inherit;
              margin-top: 12px;
              margin-bottom: 12px;
            `}
            />
            <CustomBilling
              classes={classes}
              billingDetails={billingDetails}
              purpose={purpose}
              accountType={accountType}
              isCreatedBy={isCreatedBy}
              formikProps={{
                isSubmitting,
                setFieldValue,
              }}
              getSelectedBillingOption={getSelectedBillingOption}
              selectedBillingOption={billingType}
            />
            <CustomPayment
              setHostedFieldsInstance={setHostedFieldsInstance}
              setUSBankAccountInstance={setUSBankAccountInstance}
              formikProps={{
                isSubmitting,
                setFieldValue,
              }}
              selectedPaymentOption={paymentType}
              costAllocation={costAllocation || INITIAL_COST_ALLOCATION}
              formikRef={formikRef}
              accountType={accountType}
              purpose={purpose}
              billingDetails={billingDetails}
              getSavedAllocations={getSavedAllocations}
              classes={classes}
              useDefaultAddress={useDefaultAddress}
              costCodeProfile={costCodeProfile}
              accountName={shipment?.account}
            />
            <CustomFooter {...{
              values,
              billingDetails,
              isSubmitting,
              submitForm,
              isDelayedPayment,
              onShowNextPanel,
              classes,
              shipmentId: shipment.shipmentId,
              billingTask,
            }}
            />
          </Grid>
        </Form>
        <NewOrderErrorDialog
          open={!!error}
          errorContent={error}
          onClose={() => setError(null)}
        />
      </>
    );
  }

  return costAllocation ? (
    <Formik
      ref={formikRef}
      validateOnBlur={validateOnBlur}
      initialValues={getInitialValues(costAllocation)}
      validationSchema={billingUtil.getBillingSchema(costAllocation)}
      onSubmit={onSubmit}
      render={render}
     // enableReinitialize
    />
  )
    : (
      <Grid container justify="center" alignItems="center">
        <CircularProgress color="secondary" />
      </Grid>
    );
};

// BillingPaymentForm.propTypes = {
//   shipment: PropTypes.shape({
//     carrier: PropTypes.shape({
//       details: PropTypes.objectOf(PropTypes.any).isRequired,
//     }).isRequired,
//     billing: PropTypes.shape({
//       details: PropTypes.objectOf(PropTypes.any).isRequired,
//       task: PropTypes.objectOf(PropTypes.any),
//     }),
//     isCreatedBy: PropTypes.bool.isRequired,
//     shipmentId: PropTypes.string.isRequired,
//   }).isRequired,
//   nextPanel: PropTypes.func.isRequired,
//   accountType: PropTypes.string.isRequired,
//   purpose: PropTypes.string.isRequired,
//   selectedForms: PropTypes.objectOf(PropTypes.string.isRequired).isRequired,
//   submitFormToApi: PropTypes.func.isRequired,
//   setSelectedForms: PropTypes.func.isRequired,
// };

export default BillingPaymentForm;
