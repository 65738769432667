import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import {
  Grid, Typography, MenuItem,
} from '@material-ui/core';
import ControlledCheckbox from '../../PackagingPane/ControlledCheckbox';
import NewOrderCustomTextField from '../../common/NewOrderCustomTextField';
import CustomSelectTextField from '../../common/CustomSelectTextField';
import { FORM_FIELDS, FORM_LABELS } from '../itemDetailsConstants';
import { isFieldEditable } from '../itemDetailsUtil';
import { useItemProductDetailsFormStyles } from '../itemDetailsStyles';

export default function Preservatives({
  editableFields, formikProps, preservativeTypes, weightUnits,
}) {
  const classes = useItemProductDetailsFormStyles();
  React.useEffect(() => {
    if (!formikProps.values.isPreservative) {
      formikProps.setFieldValue(FORM_FIELDS.preservative.key, '');
      formikProps.setFieldValue(FORM_FIELDS.preservativeWeight.key, '');
      formikProps.setFieldValue(FORM_FIELDS.preservativeOuterWeight.key, '');
    }
  }, [formikProps.values.isPreservative]);

  return (
    <Grid item container direction="column" spacing={1}>
      <Grid item>
        <Grid item>
          <Field
            component={ControlledCheckbox}
            name="isPreservative"
            label={(
              <Typography
                color="primary"
                style={{
                  fontSize: '14px',
                  color: 'white',
                  whiteSpace: 'nowrap',
                }}
              >
                Material contains any chemical preservative?
              </Typography>
                        )}
          />
        </Grid>
      </Grid>
      {formikProps.values?.isPreservative && (
        <>
          <Grid item container spacing={2} direction="row">
            <Grid item xs={4}>
              <Field
                component={CustomSelectTextField}
                style={{ width: '100%' }}
                type="text"
                name={FORM_FIELDS.preservative.key}
                label={FORM_FIELDS.preservative.label}
                className={classes.standardFormTextfield}
                data-testid="preservativeType-type-input"
              >
                {preservativeTypes.map((type) => (
                  <MenuItem
                    key={type.name}
                    value={type.name}
                    data-testid="preservativeType-type-menu-item"
                  >
                    {type.description}
                  </MenuItem>
                ))}
              </Field>
            </Grid>
          </Grid>
          <Grid item container spacing={2} direction="row">

            <Grid item xs={5}>
              <Field
                component={NewOrderCustomTextField}
                type="text"
                name={FORM_FIELDS.preservativeWeight.key}
                label="Max Primary Container Volume"
                inputProps={{ maxLength: 7 }}
               // disabled={isFieldEditable(FORM_FIELDS.preservativeInnerWeight.key, editableFields)}
                style={{ width: '100%' }}
              />
              <Typography paragraph style={{ paddingLeft: '10px', fontStyle: 'italic', fontSize: '12px' }}> Max: 30 ml</Typography>
            </Grid>
            <Grid
              item
              xs={4}
              className={classes.smallSelectFieldContainer}
            >
              <Field
                component={CustomSelectTextField}
                type="text"
                name={FORM_FIELDS.preservativeWeightUnit.key}
                label="Unit"
                className={classes.smallSelectField}
              //  disabled={isFieldEditable(FORM_FIELDS.preservativeInnerWeightUnit.key, editableFields)}
              >
                {weightUnits?.map((type) => (
                  <MenuItem key={type.id} value={type.weightCode}>
                    {type.weightCode}
                  </MenuItem>
                ))}
              </Field>

            </Grid>
          </Grid>
          <Grid item container spacing={2} direction="row">

            <Grid item xs={5}>
              <Field
                component={NewOrderCustomTextField}
                type="text"
                name={FORM_FIELDS.preservativeOuterWeight.key}
                label="Max Outer Container Volume"
                inputProps={{ maxLength: 7 }}
              //  disabled={isFieldEditable(FORM_FIELDS.preservativeOuterWeight.key, editableFields)}
                style={{ width: '100%' }}
              />
              <Typography paragraph style={{ paddingLeft: '10px', fontStyle: 'italic', fontSize: '12px' }}> Max: 1000 ml</Typography>
            </Grid>
            <Grid
              item
              xs={4}
              className={classes.smallSelectFieldContainer}
            >
              <Field
                component={CustomSelectTextField}
                type="text"
                name={FORM_FIELDS.preservativeOuterWeightUnit.key}
                label="Unit"
                className={classes.smallSelectField}
               // disabled={isFieldEditable(FORM_FIELDS.preservativeOuterWeightUnit.key, editableFields)}
              >
                {weightUnits?.map((type) => (
                  <MenuItem key={type.id} value={type.weightCode}>
                    {type.weightCode}
                  </MenuItem>
                ))}
              </Field>

            </Grid>
          </Grid>
        </>
)}

    </Grid>
  );
}

Preservatives.propTypes = {
  editableFields: PropTypes.arrayOf(PropTypes.string).isRequired,
  formikProps: PropTypes.objectOf(PropTypes.any).isRequired,
  preservativeTypes: PropTypes.arrayOf(PropTypes.any).isRequired,
  weightUnits: PropTypes.arrayOf(PropTypes.any).isRequired,

};
