/* eslint-disable dot-notation */
import React from 'react';
import isEmpty from 'lodash/isEmpty';
import {
  Grid, ClickAwayListener, Tooltip, Typography, IconButton,
} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';

import { Delete24 as DeleteIcon, Information16 as InfoIcon } from '@carbon/icons-react';
import { Edit as EditIcon } from '@material-ui/icons/';
import { css } from 'emotion';
import PropTypes from 'prop-types';
import { FieldArray, Field } from 'formik';
import NewOrderWideButton from '../common/NewOrderWideButton';
import {
  CustomText, CustomSearchWithDropdown, CustomSearch, CustomTextPercent,
} from '../common/InputComponents';
import CustomSelectTextField from '../common/CustomSelectTextField';

import ConfirmationModal from '../common/ConfirmationModal';
import { loadBillCodes } from '../../utils/paymentClient';
import { NEW_ORDER_BILLING_PAYMENT_STYLE } from '../../styles/style';

import * as colors from '../../styles/colors';

import { getEmptyAllocation, getTypography } from './util';
import { COMPONENT_ID } from './constants';
import NewOrderNextButtonClear from '../common/NewOrderNextButtonClear';

export default function CustomCostCodesDev(props) {
  const {
    costAllocation, hasBilling, costCodeProfile, updateFormState, accountName,
  } = props;
  const {
    billCodes, maxAllocations, instructions, isDescriptionAllowed,
  } = costAllocation;
  const [editingIdx, setEditingIdx] = React.useState(hasBilling ? null : 0);
  const [tooltipOpen, setTooltipOpen] = React.useState(false);
  const [openConfirmDelete, setOpenConfirmDelete] = React.useState(null);
  const onEdit = (val) => {
    setEditingIdx(val);
  };

  const changeCostCodeProfile = (profile) => {
    updateFormState(profile, editingIdx);
  };

  function getToolTip() {
    return instructions && instructions.length > 0 && (
    <Grid
      item
      className={css`
            padding-left: 8px !important;
            margin-top: auto;
          `}
    >
      <ClickAwayListener onClickAway={() => setTooltipOpen(false)}>
        <div>
          <Tooltip
            title={(
              <Grid container direction="column">
                {instructions.map((inst) => <Grid item key={`cost-code-tool-tip-${inst.substr(0, 5)}`}>{`- ${inst}`}</Grid>)}
              </Grid>
                )}
            PopperProps={{
              disablePortal: true,
            }}
            onClose={() => setTooltipOpen(false)}
            open={tooltipOpen}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            placement="bottom-start"
            classes={{
              popper: css({
                transform: 'translate3d(170px, 590px, 0px) !important',
              }),
              tooltip: css({
                backgroundColor: colors.inputFieldBackground,
                boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                maxWidth: 470,
                fontSize: 14,
                padding: '24px 24px',
                fontWeight: 400,
                borderRadius: 8,
              }),
            }}
          >
            <InfoIcon onClick={() => setTooltipOpen(true)} className={css`fill: white;`} />
          </Tooltip>
        </div>
      </ClickAwayListener>
    </Grid>
    );
  }

  return (
    <Grid item container direction="column" spacing={1}>
      <Grid item container spacing={1} alignItems="center">
        <Grid item>
          <Typography className={css`
            color: ${colors.white};
            font-weight: 500;
            font-size: 18px;
          `}
          >
            Cost Allocation
          </Typography>
        </Grid>
        {getToolTip()}
      </Grid>
      <Grid item>
        <FieldArray
          name="billCodes"
          render={({ form, ...arrayMethods }) => {
            const onSave = () => {
              const { billCodes: billCodeErrors } = form.errors;

              if (isEmpty(billCodeErrors) || billCodeErrors <= 0) {
                setEditingIdx(null);
              } else {
                form.setTouched({
                  billCodes: billCodeErrors.map((bce, idx) => (idx === editingIdx ? bce : {})),
                });
              }
            };

            const onAddAllocation = () => {
              const len = form.values.billCodes.length;
              arrayMethods.push(getEmptyAllocation(billCodes));
              setEditingIdx(len);
            };

            const onDeleteAllocation = (idx) => {
              setEditingIdx(null);
              arrayMethods.remove(idx);
              setOpenConfirmDelete(null);
            };
            const clear = (idx) => {
              updateFormState(null, idx);
            };

            return (
              <>
                {form.values.billCodes.map((abc, idx) => (editingIdx === idx ? (
                  <Grid container direction="column" key={`${abc.key}-edit`}>
                    <Grid container item direction="row" alignItems="center">
                      <Typography
                        className={css`
                            color: ${colors.white};
                          `}
                      >
                        {`Allocation ${idx + 1}`}
                      </Typography>
                        &nbsp; &nbsp;
                      <Grid
                        item
                        className={css`
                            padding-top: 0px !important;
                          `}
                      >
                        <IconButton
                          onClick={() => setOpenConfirmDelete(idx)}
                          disabled={
                              form.values.billCodes.length <= 1
                              || form.isSubmitting
                            }
                        >
                          <DeleteIcon
                            className={css`
                                fill: white;
                              `}
                          />
                        </IconButton>
                      </Grid>
                    </Grid>
                    <Grid style={{ marginBottom: '8px' }} spacing={1}>
                      {costCodeProfile.length ? (
                        <Field
                          component={CustomSelectTextField}
                          style={{ width: '100%' }}
                          type="text"
                          name="profileName"
                          label="Cost Code Profiles"
                          data-testid="packaging-type-input"
                          customOnChange={(e) => {
                            changeCostCodeProfile(e.target.value);
                          }}
                        >
                          {costCodeProfile?.map((costCode) => (
                            <MenuItem
                              key={costCode.costCodeId}
                              value={costCode.profileName}
                              data-testid="package-type-menu-item"
                            >
                              {costCode.profileName}
                            </MenuItem>
                          ))}
                        </Field>
                      ) : (
                        <Grid container direction="row" alignItems="center">
                          <Grid item>
                            <InfoIcon
                              className={css`
                              fill: white;
                              margin-right: 4px;
                            `}
                            />
                          </Grid>
                          {(accountName === 'umass' || accountName === 'umasschan') && (
                          <Grid item>
                            <Typography
                              alignLeft
                              style={{
                                color: colors.white,
                                fontStyle: 'italic',
                                fontSize: '14px',
                                marginBottom: '8px',
                              }}
                            >
                              Please note that Speed Types are identifed by #, Description and Departmental Speed Type Approver. Please select the appropriate option below.
                            </Typography>
                          </Grid>
                          )}
                          {(accountName !== 'umass' && accountName !== 'umasschan') && (
                            <Grid item>
                              <Typography
                                alignLeft
                                style={{
                                  color: colors.white,
                                  fontStyle: 'italic',
                                  fontSize: '14px',
                                  marginBottom: '8px',
                                }}
                              >
                                No Cost Code Profiles found . Add/Edit
                                cost code profiles in your profile section.
                              </Typography>
                            </Grid>
                          )}
                        </Grid>
                      )}
                    </Grid>
                    {costCodeProfile.length > 0
                      && (
                      <Grid container direction="row" alignItems="center">
                        <Grid item>
                          <InfoIcon
                            className={css`
                              fill: white;
                              margin-right: 4px;
                            `}
                          />
                        </Grid>
                        <Grid item>
                          <Typography
                            alignLeft
                            style={{
                              color: colors.white,
                              fontStyle: 'italic',
                              fontSize: '12px',
                              marginBottom: '8px',
                            }}
                          >
                            Choose a cost code profile for quick entry. Add/Edit
                            cost code profiles in your profile section.
                          </Typography>
                        </Grid>
                      </Grid>
                      )}
                    <Grid container direction="column" spacing={1}>
                      {billCodes.map((bc) => (
                        <CustomCostCodeField
                          billCode={bc}
                          allocationNumber={idx}
                          isDescriptionAllowed={isDescriptionAllowed}
                        />
                      ))}
                      <Grid item xs={6}>
                        <Field
                          component={CustomText}
                          name={`billCodes[${idx}.allocation]`}
                          label="% Allocation"
                        />
                      </Grid>
                      <Grid
                        container
                        direction="row"
                        justify="space-between"
                        style={{ marginTop: '1rem' }}
                      >
                        <Grid item>
                          <NewOrderWideButton
                            onClick={() => onSave(idx)}
                            disabled={form.isSubmitting}
                          >
                            Save Cost Allocation
                          </NewOrderWideButton>
                        </Grid>

                        <Grid item>
                          <NewOrderNextButtonClear
                            onClick={() => clear(idx)}
                            disabled={form.isSubmitting}
                          >
                            Clear
                          </NewOrderNextButtonClear>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : (
                  <CustomAllocationSummary
                    key={`${abc.key}-summary`}
                    allocationSet={abc}
                    billCodes={billCodes}
                    index={idx}
                    instructions={instructions}
                    onEdit={() => onEdit(idx)}
                    editDisabled={editingIdx !== null || form.isSubmitting}
                    isDescriptionAllowed={isDescriptionAllowed}
                  />
                )))}
                {editingIdx === null && maxAllocations > 1 && (
                  <NewOrderWideButton
                    onClick={onAddAllocation}
                    disabled={
                      form.values.billCodes.length >= maxAllocations
                      || form.isSubmitting
                    }
                  >
                    Add Cost Allocation
                  </NewOrderWideButton>
                )}
                <ConfirmationModal
                  open={openConfirmDelete !== null}
                  message="Are you sure you would like to delete this allocation?"
                  onProceed={() => onDeleteAllocation(openConfirmDelete)}
                  proceedLabel="Yes, delete"
                  cancelLabel="No, cancel"
                  onCancel={() => setOpenConfirmDelete(null)}
                />
              </>
            );
          }}
        />
      </Grid>

    </Grid>
  );
}

CustomCostCodesDev.propTypes = {
  hasBilling: PropTypes.bool.isRequired,
  costAllocation: PropTypes.shape({
    billCodes: PropTypes.arrayOf(PropTypes.any).isRequired,
    instructions: PropTypes.arrayOf(PropTypes.string).isRequired,
    maxAllocations: PropTypes.number.isRequired,
    isDescriptionAllowed: PropTypes.bool.isRequired,
  }).isRequired,
};

function CustomCostCodeField(props) {
  const { billCode, allocationNumber, isDescriptionAllowed } = props;

  const [cache, setCache] = React.useState({});
  const [filteredCostCodeOptions, setFilteredCostCodeOptions] = React.useState([]);

  const localFilter = (text) => {
    const filterRegex = new RegExp(text, 'i');
    return (d) => d.value.match(filterRegex) || d.label.match(filterRegex);
  };

  const updateFilter = (text) => {
    if (!text || text.length < 3) return setFilteredCostCodeOptions([]);
    const cacheKey = text.slice(0, 3);
    if (cache[cacheKey]) {
      setFilteredCostCodeOptions(cache[cacheKey].filter(localFilter(text)));
      return;
    }
    loadBillCodes(billCode.code, text).then((data) => {
      const dropdownList = data ? data.map((o) => ({
        ...o,
        value: o.code,
        label: isDescriptionAllowed ? `${o.code} - ${o.description || ''}` : o.code,
      })) : [];
      setFilteredCostCodeOptions(dropdownList);
      setCache((prev) => ({ ...prev, [text]: dropdownList }));
    });
  };

  const onSelect = (val) => {
    setFilteredCostCodeOptions([]);
  };

  return (
    <Grid item container>
      <Grid container item key={billCode.code} justify="space-between" spacing={3}>
        {console.log(billCode)}
        <Grid item xs={10}>
          <Field
            name={`billCodes[${allocationNumber}].${billCode.code}.code`}
            component={CustomSearchWithDropdown}
            label={`${billCode.description}${billCode.required ? '' : ' (optional)'}`}
            onSelect={onSelect}
            callback={updateFilter}
            dropdownList={filteredCostCodeOptions}
            dependentFields={isDescriptionAllowed ? [{ name: `billCodes[${allocationNumber}].${billCode.code}.description`, key: 'description' }] : []}
            inputProps={billCode.disabled
              ? { readOnly: true, disableUnderline: true, ...(billCode['length'] && { maxLength: parseInt(billCode['length'], 10) }) }
              : { ...(billCode['length'] && { maxLength: parseInt(billCode['length'], 10) }) }}
          />
        </Grid>
        {isDescriptionAllowed && (
          <Grid item xs={10}>
            <Field
              name={`billCodes[${allocationNumber}].${billCode.code}.description`}
              component={CustomSearch}
              inputProps={{ readOnly: true, disableUnderline: true }}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

CustomCostCodeField.propTypes = {
  billCode: PropTypes.objectOf(PropTypes.shape({
    code: PropTypes.string.isRequired,
    billCode: PropTypes.objectOf(PropTypes.shape({
      code: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    })),
  })).isRequired,
  allocationNumber: PropTypes.number.isRequired,
  isDescriptionAllowed: PropTypes.bool.isRequired,
};

function CustomAllocationSummary(props) {
  const {
    allocationSet, index, billCodes, isDescriptionAllowed, onEdit, editDisabled,
  } = props;

  const id = index + 1;

  return (
    <Grid item container wrap="nowrap" className={css`padding-bottom: 20px; margin-top: 15px`}>
      <Grid
        item
        container
        direction="column"
        className={css`
          width: max-content;
          white-space: nowrap;
          padding-right: 22px;
        `}
      >
        <Grid
          item
          className={css`
              height: 64px;
              padding-top: 23px;
              color: ${colors.white};
              align-items: center
            `}
        >
          <Typography id={`${COMPONENT_ID}-cost-allocation-summary-${id}`}>{`Allocation ${id}`}</Typography>
        </Grid>
        {billCodes.map(({ code, description }) => (
          <Grid item key={`allocation-summary-field-${id}-${code}`}>
            {getTypography(`allocation-summary-${id}-${code}`, description, css`
              color: ${colors.textLightGrey};
              font-size: 15px;
            `)}
          </Grid>
        ))}
      </Grid>
      <Grid item container direction="column">
        <Grid item container className={css`height: 64px; width: 200px; position: relative; align-items: center`}>
          <Grid item>
            <CustomTextPercent
              name={`billCodes[${index}].allocation`}
              classes={NEW_ORDER_BILLING_PAYMENT_STYLE()}
            />
          </Grid>
          <Grid item className={css`position: absolute; right: 40px;`}>
            <IconButton
              onClick={onEdit}
              disabled={editDisabled}
            >
              <EditIcon
                color="primary"
              />
            </IconButton>
          </Grid>
        </Grid>
        {billCodes.map(({ code }) => {
          const content = (code in allocationSet && allocationSet[code].code)
            ? `${allocationSet[code].code}${(isDescriptionAllowed) ? ` - ${allocationSet[code].description}` : ''}`
            : '';
          return (
            <Grid
              key={`allocation-summary-description-${id}-${code}`}
              item
              className={css`
                  color: ${colors.textLightGrey};
                `}
            >
              <Typography className={css`font-size: 15px`}>{content || <i>not specified</i>}</Typography>
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
}

CustomAllocationSummary.propTypes = {
  allocationSet: PropTypes.objectOf(PropTypes.string.isRequired).isRequired,
  index: PropTypes.number.isRequired,
  billCodes: PropTypes.arrayOf(PropTypes.shape({
    code: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }).isRequired).isRequired,
  isDescriptionAllowed: PropTypes.bool,
  onEdit: PropTypes.func.isRequired,
  editDisabled: PropTypes.bool,
};

CustomAllocationSummary.defaultProps = {
  isDescriptionAllowed: true,
  editDisabled: false,
};
