import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import get from 'lodash/get';
import { makeStyles } from '@material-ui/core/styles';
import { FormControlLabel, Grid, Typography } from '@material-ui/core';
import { FieldArray } from 'formik';
import * as colors from '../../../styles/colors';
import ReadOnlyCheckbox from '../../../components/common/ReadOnlyCheckbox';
import StyledCheckbox from '../../../components/common/StyledCheckbox';
import { EMAIL_ENABLED_FIELD_NAME, NOTIFICATION_TYPE_FIELD_NAME } from '../constants';

const useStyles = makeStyles(() => ({
  base: {
    borderRadius: '8px',
    padding: '10px 20px 20px',
    marginBottom: '16px',
  },
  header: {
    color: colors.white,
    fontSize: '16px',
    fontWeight: 500,
    letterSpacing: '0.25px',
    textTransform: 'capitalize',
    margin: '16px 0 32px',
  },
  darkBlue: {
    backgroundColor: colors.darkBlueBackground,
  },
  lightBlue: {
    backgroundColor: colors.background,
  },
  checkboxLabel: {
    fontSize: '14.75px',
    fontWeight: 425,
    color: colors.textLightGrey,
  },
}));

const NotificationTypes = ({
  notificationKey, notificationTypes, values, isEditing, setFieldTouched,
}) => {
  const classes = useStyles();
  const isParentNotificationChecked = get(values, notificationKey.replace(NOTIFICATION_TYPE_FIELD_NAME, EMAIL_ENABLED_FIELD_NAME), false);
  const [groupedNotificationTypes, setGroupNotificationTypes] = useState(null);
  const notificationTypesValues = get(values, notificationKey, []);

  useEffect(() => {
    if (!isEmpty(notificationTypes) && isEmpty(groupedNotificationTypes)) {
      const notificationType = notificationKey.replace(`.${NOTIFICATION_TYPE_FIELD_NAME}`, '');
      setGroupNotificationTypes(groupBy(notificationTypes[notificationType], 'groupType'));
    }
  }, [notificationTypes]);

  return isParentNotificationChecked ? (
    <Grid container>
      {!isEmpty(groupedNotificationTypes) && map(groupedNotificationTypes, (value, key) => (
        <Grid className={`${classes.base} ${isEditing ? classes.lightBlue : classes.darkBlue}`} container>
          <Grid item xs={12}>
            <Typography className={classes.header}>{key}</Typography>
          </Grid>
          <FieldArray name={notificationKey}>
            {(arrayHelpers) => (
              <>
                {value.map((notification) => {
                  const includesValue = notificationTypesValues.includes(notification.type);

                  return (
                    <Grid key={notification.type} item xs={4}>
                      {isEditing ? (
                        <FormControlLabel
                          className={classes.checkboxLabel}
                          control={(
                            <StyledCheckbox
                              name={notification.type}
                              color="default"
                              checked={includesValue}
                              onChange={(e) => {
                                const { name } = e.target;
                                if (e.target.checked) {
                                  arrayHelpers.push(name);

                                  if (setFieldTouched) setFieldTouched(notificationKey, true);
                                } else {
                                  const idx = notificationTypesValues.findIndex((n) => n === name);
                                  arrayHelpers.remove(idx);
                                }
                              }}
                            />
                          )}
                          label={notification.description}
                        />
                      ) : (
                        <ReadOnlyCheckbox
                          name={notification.type}
                          label={notification.description}
                          value={includesValue}
                        />
                      )}
                    </Grid>
                  );
                })}
              </>
            )}
          </FieldArray>
        </Grid>
      ))}
    </Grid>
  )
    : null;
};

NotificationTypes.defaultProps = {
  notificationTypes: null,
  values: [],
  isEditing: false,
  setFieldTouched: null,
};

NotificationTypes.propTypes = {
  notificationKey: PropTypes.string.isRequired,
  notificationTypes: PropTypes.shape({
    email: PropTypes.arrayOf(PropTypes.shape({
      type: PropTypes.string,
      description: PropTypes.string,
    })),
    sms: PropTypes.arrayOf(PropTypes.shape({
      type: PropTypes.string,
      description: PropTypes.string,
    })),
  }),
  values: PropTypes.arrayOf(PropTypes.string),
  isEditing: PropTypes.bool,
  setFieldTouched: PropTypes.func,
};

export default memo(NotificationTypes);
