import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Title, SubTitle } from '../components/common';
import Logo from '../assets/verizeal-logo';
import NavigationButton from '../components/common/NavigationButton';

const styles = {
  root: {
    flexGrow: 1,
    backgroundColor: '#151721',
    height: '100%',
    width: '100%',
    overflowX: 'hidden',
  },
  contentContainer: {
    paddingTop: 200,
    textAlign: 'center',
  },
  logo: {
    height: 20,
    width: 200,
  },
  formContainer: {
    marginTop: 20,
  },
};

const useStyles = makeStyles(styles);

export default function ResetPasswordError(props) {
  const classes = useStyles();
  const message = 'This link has expired, please resubmit your request.';

  return (
    <div className={classes.root}>
      <Grid
        container
        className={classes.contentContainer}
        justify="flex-start"
        alignItems="center"
        direction="column"
        spacing={2}
      >
        <Grid item md={4} sm={6} xs={10}>
          <Logo className={classes.logo} />
        </Grid>
        <Grid item md={4} sm={6} xs={10}>
          <Title>We&#39;re sorry!</Title>
        </Grid>
        <Grid item md={4} sm={6} xs={10}>
          <SubTitle>
            {message}
          </SubTitle>
        </Grid>
        <Grid item md={4} sm={6} xs={10}>
          <NavigationButton
            label="Forgot Password"
            path="/login/forgot-password"
          />
        </Grid>
      </Grid>
    </div>
  );
}
