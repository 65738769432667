import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid, Typography, IconButton, Link,
} from '@material-ui/core';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import * as colors from '../../styles/colors';
import CustomModal from './CustomModal';

const StyledWarningIcon = withStyles({
  root: { color: colors.warningYellow },
})(ReportProblemOutlinedIcon);

const StyledInformationIcon = withStyles({
  root: {
    color: colors.secondaryButton,
    height: 18,
    width: 18,
  },
})(InfoOutlinedIcon);
const modalStyles = {
  disclaimerText: {
    fontSize: '.9rem',
  },
  disclaimerTextHeader: {
    fontSize: '.9rem',
    fontWeight: 500,
  },
  customModal: {
    height: 'auto',
  },
};

const isArrayWithItems = (array) => Array.isArray(array) && array.length;

const useStyles = makeStyles(modalStyles);
export default function DisclaimerModal(props) {
  const {
    listHeader, leftListItems, rightListItems, title, isWarningIcon, bodyText, footer,
  } = props;
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const classes = useStyles();
  return (
    <>
      <IconButton
        style={{ padding: '5px' }}
        onClick={() => setIsModalOpen(true)}
        disableRipple
      >
        <StyledInformationIcon />
      </IconButton>
      <CustomModal
        title={title}
        TitleIcon={isWarningIcon ? StyledWarningIcon : null}
        subtitle={(
          <Grid item container spacing={2} alignItems="center">
            {isArrayWithItems(bodyText) && bodyText.map((paragraph) => (
              <Grid key={paragraph} item>
                <Typography className={classes.disclaimerText}>{paragraph}</Typography>
              </Grid>
            ))}
            <Grid item>
              <Typography className={classes.disclaimerTextHeader}>{listHeader}</Typography>
            </Grid>
            <Grid item style={{ paddingLeft: 24 }}>
              <Typography className={classes.disclaimerText}>
                <Grid item container spacing={2}>
                  <Grid item xs={6}>
                    <ul>
                      {leftListItems.map((item) => (
                        // eslint-disable-next-line
                        <li key={item}>{item}</li>
                      ))}
                    </ul>
                  </Grid>
                  {isArrayWithItems(rightListItems) && (
                    <Grid xs={6} item>
                      <ul>
                        {rightListItems.map((item) => (
                          // eslint-disable-next-line
                          <li key={item}>{item}</li>
                        ))}
                      </ul>
                    </Grid>
                  )}
                </Grid>
              </Typography>
            </Grid>
            {footer
              && (
              <Grid item>
                <Typography className={classes.disclaimerText}>
                  {footer.text}
                  <Link
                    target="_blank"
                    rel="noopener"
                    color="secondary"
                    href={footer.url}
                  >
                    {footer.url}

                  </Link>
                </Typography>
              </Grid>
              )}
          </Grid>
      )}
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        classes={{ root: classes.customModal }}
        enableBackdropClick
        enableEscapeKeyDown
      />
    </>
  );
}

DisclaimerModal.propTypes = {
  listHeader: PropTypes.string.isRequired,
  leftListItems: PropTypes.arrayOf(PropTypes.string).isRequired,
  rightListItems: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
  isWarningIcon: PropTypes.bool,
  bodyText: PropTypes.arrayOf(PropTypes.string).isRequired,
  footer: PropTypes.shape({
    text: PropTypes.string,
    url: PropTypes.string,
  }),
};

DisclaimerModal.defaultProps = {
  rightListItems: [],
  isWarningIcon: false,
  footer: null,
};
