/* eslint-disable react/prop-types */
import React from 'react';
import { Grid, Link } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import * as colors from '../styles/colors';
import { CustomCheckbox } from './common/InputComponents';
import DisclaimerModal from './common/DisclaimerModal';

const PROHIBITED_DETAILS = {
  url: 'https://www.ups.com/cw/en/help-center/shipping-support/prohibited-items.page',
  footerText: '*For more information please consult: ',
};
const MODAL_DETAILS = {
  listHeader: 'Prohibited Articles for Shipping',
  leftListItems: [
    'Ammunition (except as expressly provided in the "UPS Tariff/Terms and Conditions of Service - United States" beginning on page 136)',
    'Bank bills, notes, or currency (other than coin)',
    'Corpses, cremated, or disinterred remains',
    'Postage stamps',
  ],
  rightListItems: [
    'Fireworks',
    'Hazardous waste',
    'Ivory',
    'Marijuana, including marijuana intended for medicinal use',
    'Shark fins',
  ],
  title: 'Prohibited Items',
  isWarningIcon: true,
  bodyText: [
    'It is the shipper´s responsibility to comply with US government regulations or laws applicable in each country. Shipments are subject to inspection and possible delay by customs or representatives of other government authorities.',
    'Carriers will not transport any goods which are prohibited by law or regulation of any federal, state or local government in the origin or destination countries or which may breach any applicable export, import or other laws or endanger the safety of our employees, agents and partners or the means of transportation or, which in our opinion, soil, paint or otherwise damage other goods or equipment or which are economically or operationally impractical to transport.',
  ],
  footer: {
    text: 'Ref: ',
    url: 'https://www.ups.com/us/en/help-center/shipping-support/prohibited-items.page',
  },
};
const useProhibitedLabelComponentStyle = {
  icon: {
    height: '60px',
    width: 'auto',
    color: colors.white,
    padding: 0,
  },
  iconChecked: {
    height: '60px',
    width: 'auto',
    color: colors.lightSkyBlue,
    padding: 0,
  },
  radio: {
    '&:hover': {
      backgroundColor: 'transparent ',
    },
    paddingLeft: 0,
  },
  radioLabel: {
    color: colors.white,
    width: '100%',
  },
  formControlRoot: {
    alignItems: 'center',
    background: colors.newOrderFormBackground,
    margin: '0px',
    padding: '0px 20px',
    width: '100%',
  },
  inputLabelContainer: {
    paddingBottom: '12px',
  },
  inputLabelText: {
    fontWeight: 500,
  },

  checkedControlRoot: {
    alignItems: 'center',
    background: '#39455C',
    margin: '0px',
    paddingRight: '5px',
    paddingLeft: '20px',
    width: '100%',
  },
  prohibitedItemText: {
    fontSize: 14.75,
    fontWeight: 425,
    color: colors.textLightGrey,
  },
  hazmatItemText: {
    fontSize: 14.75,
    fontWeight: 400,
    color: colors.textLightGrey,
  },
  hazmatHeadText: {
    fontSize: 15,
    fontWeight: 500,
    color: colors.textLightGrey,
  },
};

const orderBasicsFormStyles = {
  contentContainer: {
    background: colors.darkBlueBackground,
    height: '100%',
  },
  toggleComponentContainer: {
    paddingLeft: '25px',
    paddingRight: '25px',
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  prohibitedItemsContainer: {
    padding: '0px 25px 55px 55px',
  },
  icon: {
    height: '40px',
    width: 'auto',
    color: colors.white,
    padding: 0,
  },
  disclaimerText: {
    fontSize: '.9rem',
  },
  disclaimerTextHeader: {
    fontSize: '.9rem',
    fontWeight: 500,
  },
  checkBoxError: {
    color: colors.danger,
    fontSize: 14.75,
    fontWeight: 425,
  },
};
const useOrderBasicsFormStyles = makeStyles(orderBasicsFormStyles);

const FORM_OPTIONS = {

  orderType: {
    name: 'isAckProhibited',
    // eslint-disable-next-line max-len
    label:
      'I hereby acknowledge that the shipment will not contain any product/article prohibited from shipping',
  },

};

export default function ProhibitedAck(props) {
  const { orderTypeError, isAckProhibited } = props;

  const classes = useOrderBasicsFormStyles();
  return (
    <Grid>
      <Grid item className={classes.toggleComponentContainer} container spacing={1}>
        <Grid
          item
          style={{
            maxWidth: '97%',
          }}
          data-testid="prohibited-checkbox-container"
        >
          <Typography paragraph>
            {`${FORM_OPTIONS.orderType.label}`}
          </Typography>
        </Grid>
        <Grid item>
          <DisclaimerModal {...MODAL_DETAILS} />
        </Grid>
      </Grid>
      <Grid item className={classes.prohibitedItemsContainer}>
        <ProhibitedLabel />
      </Grid>
    </Grid>
  );
}

function ProhibitedLabel(props) {
  const classes = makeStyles(useProhibitedLabelComponentStyle)();
  return (
    <Grid item container direction="column" spacing={1}>
      <Grid item>
        <Typography color="primary" className={classes.inputLabelText}>Prohibited Articles for Shipping*:</Typography>
      </Grid>
      <Grid item>
        <Typography className={classes.prohibitedItemText}>
          <Grid item container spacing={2} style={{ margin: 0 }}>
            <Grid item xs={6}>
              <ul>
                {MODAL_DETAILS.leftListItems.map((item) => (
                  <li key={item}>{item}</li>
                ))}
              </ul>
            </Grid>
            {MODAL_DETAILS.rightListItems && (
              <Grid xs={6} item>
                <ul>
                  {MODAL_DETAILS.rightListItems.map((item) => (
                    <li key={item}>{item}</li>
                  ))}
                </ul>
              </Grid>
            )}
          </Grid>
        </Typography>
      </Grid>
      {/* <Grid item>
        <Typography color="primary" className={classes.prohibitedItemText}>
          {`${PROHIBITED_DETAILS.footerText} `}
          {' '}
          <Link
            target="_blank"
            rel="noopener"
            color="secondary"
            href={PROHIBITED_DETAILS.url}
          >
            {PROHIBITED_DETAILS.url}
          </Link>
        </Typography>
      </Grid> */}
    </Grid>
  );
}
