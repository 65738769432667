/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { css } from 'emotion';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { ReportProblemOutlined, Warning } from '@material-ui/icons';
import {
  NEW_ORDER_MODULE_STYLE,
} from '../styles/style';
import NewOrderNextButton from './common/NewOrderNextButton';
import NewOrderNextButtonClear from './common/NewOrderNextButtonClear';
import { SCROLL_TO_SIGNATURE } from '../clientConstants';

import {
  useUserState,
  useUserDispatch,
  loadUser,
  updateUser,
} from '../context/userContext';
import NewOrderErrorDialog from './common/NewOrderErrorDialog';
import * as colors from '../styles/colors';

import ProhibitedAck from './ProhibitedAck';

const StyledWarningIcon = withStyles({
  root: { color: colors.warningYellow },
})(ReportProblemOutlined);

const UserProhibitedAckDialog = ({
  portal, isOpened, isShipping, setOpened, userProfile, isOrderBasics, signature,
}) => {
  const history = useHistory();
  const userDispatch = useUserDispatch();
  const userState = useUserState();
  const errorClasses = NEW_ORDER_MODULE_STYLE();

  const classes = {

    form: css({
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '100%',
      overflow: 'hidden',
    }),

    progress: css({
      marginTop: '15px',
    }),
    paperFullScreen: css({
      width: '100%',
      maxWidth: '65%',
      height: '60%',
      position: 'absolute',
      backgroundColor: '#1E202E',
      marginTop: '90px',
      padding: '2em',
    }),
    paperSmallScreen: css({
      width: '100%',
      maxWidth: '45%',
      height: '35%',
      position: 'absolute',
      backgroundColor: '#1E202E',
      marginTop: '90px',
      padding: '2em',
    }),
    dialogTitleText: css({
      fontSize: 18,
      color: 'white',
      fontWeight: 500,
    }),
    dialogText: css({
      fontSize: 16,
      color: 'white',
      fontWeight: 400,
    }),
  };

  const [isLoading, setIsLoading] = useState(false);
  const [errorContent, setErrorContent] = useState(null);
  const [isError, setIsError] = useState(false);

  function handleSubmit(values, setSubmitting) {
    setIsLoading(true);

    updateUser({
      dispatch: userDispatch,
      currentUserId: userState.userId,
      updatedUserData: { userId: userState.userId, ...values },
    })
      .then((data) => {
        loadUser(userDispatch);
        setIsLoading(false);
        if (userProfile || isShipping) setOpened(false);
        if (isShipping && values.isAckProhibited) {
          history.push('/new-order');
        }
      }).catch((err) => {
        loadUser(userDispatch);
        setIsLoading(false);
        setIsError(true);
        setErrorContent(err);
      });

    if (setSubmitting) setSubmitting(false);
  }

  const goToUserProfile = () => {
    history.push('/profile-management', {
      [SCROLL_TO_SIGNATURE]: true,
    });
  };

  return (
    <div className={classes.root}>
      <Dialog
        disablePortal={!portal}
        scroll="paper"
        disableBackdropClick
        disableEscapeKeyDown
        onClose={history.goBack}
        open={isOpened}
        classes={{
          paper: isOrderBasics ? classes.paperSmallScreen : classes.paperFullScreen,
        }}
      >
        {isOrderBasics ? (
          <Grid container className={classes.outerContainer}>
            <DialogTitle id="alert-dialog-title">
              <Grid item container direction="row" spacing={1}>
                <Grid item><StyledWarningIcon /></Grid>
                <Grid item><Typography>Alert</Typography></Grid>
              </Grid>
            </DialogTitle>

            <Typography className={classes.dialogTitleText}>
              { signature
                ? 'Please add a signature in your User Profile to create a new Shipment.'
                : 'Please Review and accept the shipping acknowledgement in your User Profile in order to create a new Shipment.'}
            </Typography>
            <Grid item style={{ marginTop: '100px' }}>
              <NewOrderNextButton
                className={classes.submitButton}
                onClick={goToUserProfile}
              >
                Go to User Profile
              </NewOrderNextButton>
            </Grid>
          </Grid>
        )
          : (
            <Formik
              initialValues={{
                isAckProhibited: userState.isAckProhibited || false,
              }}
              validationSchema={Yup.object().shape({
                isAckProhibited: isShipping ? Yup.bool().required('*Required') : Yup.bool(),
              })}
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);
                handleSubmit(values, setSubmitting);
              }}
            >
              {({
                errors, values, isSubmitting, submitForm, setFieldValue,
              }) => (
                <Form className={classes.outerContainer}>
                  <Grid container justifyContent="space-between" direction="row" alignItems="center">
                    <Grid item>
                      <Typography className={classes.dialogTitleText}>
                        Please Review and acknowledge the prohibited items in shipping.
                      </Typography>
                    </Grid>
                    <Grid item justifyContent="flex-end">
                      <IconButton
                        onClick={async () => {
                          if (!isShipping && !userProfile) {
                            await setFieldValue('isAckProhibited', false);
                            return setTimeout(() => submitForm(), 200);
                          }
                          setOpened(false);
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                  <ProhibitedAck orderTypeError={errors.isAckProhibited} isAckProhibited={userState.isAckProhibited} />
                  <Grid
                    item
                    container
                    justify="space-between"
                    className={classes.buttonsContainer}
                  >

                    <NewOrderNextButtonClear
                      disabled={isSubmitting || userState.isAckProhibited}
                      className={classes.submitButton}
                      onClick={() => submitForm()}
                    >
                      Reject
                    </NewOrderNextButtonClear>

                    <NewOrderNextButton
                      disabled={isSubmitting || userState.isAckProhibited}
                      className={classes.submitButton}
                      onClick={async () => { setIsLoading(true); await setFieldValue('isAckProhibited', true); return setTimeout(() => submitForm(), 200); }}
                    >
                      Accept
                    </NewOrderNextButton>
                  </Grid>
                </Form>
              )}
            </Formik>
          )}
      </Dialog>
      <NewOrderErrorDialog
        open={!!errorContent}
        errorContent={errorContent}
        classes={errorClasses}
        onClose={() => {
          setErrorContent(null);
        }}
      />
    </div>
  );
};

export default UserProhibitedAckDialog;
