import React, { useEffect, useState } from 'react';
import * as braintree from 'braintree-web';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import has from 'lodash/has';
import {
  Grid, Typography, Dialog, IconButton, AppBar, Tabs, Tab, MenuItem, Tooltip, Button, CircularProgress,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import StarIcon from '@material-ui/icons/Star';
import EditIcon from '@material-ui/icons/Edit';
import { Delete24 as DeleteIcon } from '@carbon/icons-react';
import { makeStyles } from '@material-ui/core/styles';
import { getName } from 'country-list';
import CloseIcon from '@material-ui/icons/Close';
import { get, omitBy, pickBy } from 'lodash';
import { Field, Formik } from 'formik';
import { getBraintreeApiKey } from '../../utils/apiKeyClient';
import * as colors from '../../styles/colors';
import NewOrderWideButton from './NewOrderWideButton';
import CreditCardHostedForm from './CreditCardHostedForm';
import ScrollWindow from './ScrollWindow';
import { CustomText, CustomCheckbox } from './InputComponents';
import AddressForm from './AddressForm';
import NewOrderErrorDialog from './NewOrderErrorDialog';
import { useContactsState, useContactsDispatch, loadDefaultSender } from '../../context/contactsContext';
import {
  EMPTY_BILLING_ADDRESS,
  CREDIT_CARD_OWNERSHIP_TYPE_OPTIONS,
  ACH_AUTH_TEXT,
} from '../BillingPayment/constants';
import { formatDefaultSender } from '../BillingPayment/util';
import {
  creditCardSchemaPostTokenization,
  achSchemaPostTokenization,
  formatPaymentForSubmission,
  commonSavedPaymentSchema,
  addPaymentSchemaPreTokenization,
  emptySchema,
  editAchSchemaPreTokenization,
} from '../../utils/paymentProfileUtil';
import CustomSelectTextField from './CustomSelectTextField';
import ACHForm from './ACHForm';
import NewOrderNextButton from './NewOrderNextButton';
import NewOrderNextButtonClear from './NewOrderNextButtonClear';
import ConfirmationModal from './ConfirmationModal';
import {
  usePaymentDispatch, updatePaymentMethod, addPaymentMethod, deletePaymentMethod, usePaymentState, setDefaultPayment,
} from '../../context/paymentContext';

const IS_CREDIT_CARD = 'isCreditCard';
const IS_ACH = 'isACH';

const INITIAL_VALUES = {
  useDefaultAddress: true,
  billingAddress: {
    firstName: '',
    lastName: '',
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    city: '',
    state: '',
    zip: '',
    country: '',
  },
  bankAccountDetails: {
    ownerShipType: '',
    accountType: '',
    accountNumber: '',
    routingNumber: '',
  },
  creditCardDetails: {
    ownerShipType: '',
  },
};

const paymentManagementStyles = {
  header: {
    color: colors.white,
    fontSize: '20px',
    fontWeight: '500',
    letterSpacing: '0.25px',
  },
  dialogPaper: {
    background: colors.newOrderFormBackground,
    borderRadius: 8,
    overflow: 'hidden',
    maxWidth: 'none',
  },
  dialogInnerContainer: {
    height: 700,
    width: 600,
    background: colors.newOrderFormBackground,
    padding: '35px 25px 10px 25px',
    borderRadius: 8,
    position: 'relative',
  },
  closeIcon: {
    position: 'absolute',
    top: 10,
    right: 10,
  },
};

const addPaymentStyles = {
  submitPaymentText: {
    fontWeight: '700',
    fontSize: '14px',
    padding: '5px 20px',
    letterSpacing: '1px',
  },
  addPaymentContainer: {
    height: '100%',
  },
  addPaymentHeader: {
    color: colors.white,
    fontSize: 24,
    fontWeight: '500',
  },

  addPaymentInnerContainer: {
    background: colors.darkBlueBackground,
    padding: '25px 25px',
  },
};

const paymentMethodPanelStyles = {
  container: {
    color: colors.white,
    backgroundColor: colors.darkBlueBackground,
    height: '200px',
    padding: '12px 18px',
    borderRadius: '8px',
  },
  headerText: {
    fontSize: '13px',
    fontWeight: '500',
    letterSpacing: '0.4px',
    textTransform: 'uppercase',
  },
  tooltip: {
    background: colors.tooltipBackground,
    fontSize: 12,
  },
  bodyText: {
    color: colors.textLightGrey,
    fontSize: 14,
    padding: '2px 0',
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  deleteIcon: {
    fill: 'white',
  },
  editPaymentContainer: {
    padding: '15px 15px',
    background: colors.darkBlueBackground,
  },
  preferredPaymentSelected: {
    color: 'gold',
  },
  preferredPaymentUnselected: {
    color: colors.textGrey,
  },
  preferredPaymentButton: {
    minWidth: 0,
  },
};

const usePaymentManagementStyles = makeStyles(paymentManagementStyles);
const usePaymentMethodPanelStyles = makeStyles(paymentMethodPanelStyles);
const useAddPaymentStyles = makeStyles(addPaymentStyles);

const billingDetailsStyles = {
  billingAddressDetails: {
    color: colors.textLightGrey,
    fontSize: 14,
    padding: '2px 0',
  },
};
const useBillingDetailsStyles = makeStyles(billingDetailsStyles);

function BillingAddressDisplay(props) {
  const { billingAddress } = props;
  const classes = useBillingDetailsStyles();
  return (
    <Grid item container direction="column" style={{ marginBottom: 5 }}>
      <Grid item>
        <Typography className={classes.billingAddressDetails}>
          {billingAddress.firstName}
          {' '}
          {billingAddress.lastName}
        </Typography>
      </Grid>
      <Grid item>
        <Typography className={classes.billingAddressDetails}>
          {billingAddress.addressLine1}
        </Typography>
      </Grid>
      {billingAddress.addressLine2 && (
      <Grid item>
        <Typography className={classes.billingAddressDetails}>
          {billingAddress.addressLine2}
        </Typography>
      </Grid>
      )}
      {billingAddress.addressLine3 && (
      <Grid item>
        <Typography className={classes.billingAddressDetails}>
          {billingAddress.addressLine3}
        </Typography>
      </Grid>
      )}
      <Grid item>
        <Typography noWrap className={classes.billingAddressDetails}>
          {billingAddress.city}
          {', '}
          {billingAddress.state}
          {' '}
          {billingAddress.zip}
        </Typography>
      </Grid>
      <Grid item>
        <Typography className={classes.billingAddressDetails}>
          {getName(billingAddress.country) || billingAddress.country}
        </Typography>
      </Grid>
      {billingAddress.phone && (
      <Grid item>
        <Typography className={classes.billingAddressDetails}>
          {billingAddress.phone}
        </Typography>
      </Grid>
      )}
    </Grid>
  );
}

BillingAddressDisplay.propTypes = {
  billingAddress: PropTypes.objectOf(PropTypes.any).isRequired,

};

function generateErrorMessageForUser(braintreeError) {
  const invalidFieldKeys = braintreeError?.details?.invalidFieldKeys;
  if (!invalidFieldKeys) {
    return 'Unable to verify payment method. Please check your details and try again.';
  }

  return `Invalid or missing information. Please review the following fields to make sure that they're filled in correctly: ${invalidFieldKeys.map((key) => fields[key]?.internalLabel).join(', ')}`;
}

const fields = {
  number: {
    selector: '#card-number',
    placeholder: '',
    internalLabel: 'Card Number',
  },
  cvv: {
    selector: '#card-cvv',
    placeholder: '',
    internalLabel: 'CVV',
  },
  expirationDate: {
    selector: '#card-exp-date',
    placeholder: '',
    internalLabel: 'Exp Date',
  },
  cardholderName: {
    selector: '#card-name',
    placeholder: '',
    internalLabel: 'Cardholder Name',
  },
  postalCode: {
    selector: '#card-postal',
    placeholder: '',
    internalLabel: 'Postal Code',
  },
};
function CreditCardPanel(props) {
  const {
    paymentMethod, defaultSender,
    setHostedFieldsInstance,
    clientInstance,
    hostedFieldsInstance,
    // eslint-disable-next-line no-shadow
    deletePaymentMethod,
    editing,
    setEditing,
    makePreferredPayment,
  } = props;
  const {
    billingAddress, ownerShipType, paymentToken, isDefault,
  } = paymentMethod;
  const { creditCardDetails } = paymentMethod;

  const [error, setError] = React.useState(null);
  const formikRef = React.useRef();

  const classes = usePaymentMethodPanelStyles();

  const dispatch = usePaymentDispatch();

  async function onSubmit(values, { setSubmitting }) {
    // eslint-disable-next-line no-shadow
    const { paymentToken } = values;
    let unformattedPayload = { ...values };
    const schema = getPostTokenizationSchema(IS_CREDIT_CARD);
    try {
      const paymentPayload = await getCreditCardPayload(values, hostedFieldsInstance);
      unformattedPayload = { ...unformattedPayload, ...paymentPayload };
    } catch (e) {
      const errorMessage = generateErrorMessageForUser(e);
      setError(JSON.stringify({ error: errorMessage }));
      setSubmitting(false);
      return;
    }
    const formattedPayload = formatPaymentForSubmission(unformattedPayload);
    let payload;
    try {
      payload = await schema.validate(formattedPayload, { stripUnknown: true });
    } catch (e) {
      setError(JSON.stringify({ error: 'Error verifying payment. Please try again later.' }));
      setSubmitting(false);
      return;
    }
    try {
      await updatePaymentMethod({ dispatch, paymentToken, payload });
    } catch (e) {
      setError(e.message);
      setSubmitting(false);
      return;
    }
    setSubmitting(false);
    setEditing(null);
  }

  return (
    <>
      {
        editing !== paymentToken
      && (
      <Grid
        container
        item
        direction="column"
        justify="flex-start"
        className={classes.container}
        wrap="nowrap"
      >
        <Grid item container justify="space-between">
          <Grid item container direction="column" xs={3}>
            <Grid item>
              {(paymentMethod.creditCardDetails && <img src={creditCardDetails.imageUrl} style={{ height: '40px', paddingTop: 'calc(29px / 2 - 1em / 2)' }} alt="" />)}
            </Grid>
            <Grid item>
              <Typography noWrap className={classes.bodyText}>
                {`Ending in ${creditCardDetails.lastFour}`}
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.bodyText}>
                {`Exp ${creditCardDetails.expirationDate}`}
              </Typography>
            </Grid>

          </Grid>
          <Grid item container xs={9}>
            <Grid item container direction="column" xs={4}>
              {billingAddress
              && (
              <>
                <Grid item className={classes.headerContainer} style={{ height: '29px' }}>
                  <Typography className={classes.headerText}>Billing Address</Typography>
                </Grid>
                <Grid item container justify="space-between" wrap="nowrap">
                  <Grid item container direction="column" wrap="nowrap">
                    <BillingAddressDisplay
                      billingAddress={billingAddress}
                    />
                  </Grid>
                </Grid>
              </>
              )}
            </Grid>

            <Grid item container xs={8} alignItems="flex-start" justify="flex-end">
              <Grid item container style={{ width: 'auto' }} spacing={2} wrap="nowrap">
                {(paymentMethod.isDefault ? (
                  <Grid item container spacing={2} wrap="nowrap">
                    <Grid item>
                      <Tooltip title="Preferred payment" classes={{ tooltip: classes.tooltip }}>
                        <StarIcon style={{ color: 'gold' }} />
                      </Tooltip>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid item container spacing={2} wrap="nowrap">
                    <Grid item>
                      <Tooltip title="Make preferred payment" classes={{ tooltip: classes.tooltip }}>
                        <IconButton
                          style={{ padding: 0 }}
                          onClick={() => {
                            makePreferredPayment(paymentToken);
                          }}
                        >
                          <StarIcon style={{ color: 'rgba(255, 255, 255, 0.2)' }} />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                ))}

                <Grid item>
                  <IconButton
                    style={{ padding: 0 }}
                    disableFocusRipple
                    onClick={() => setEditing((prev) => paymentToken)}
                  >
                    <EditIcon />
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton
                    style={{ padding: 0 }}
                    disableFocusRipple
                    onClick={() => deletePaymentMethod(paymentToken)}
                  >
                    <DeleteIcon className={classes.deleteIcon} />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

      </Grid>
      )
      }
      {
        editing === paymentToken && (
          <Grid container direction="column" item>
            <Formik
              ref={formikRef}
              initialValues={{
                ...INITIAL_VALUES,
                billingAddress: {
                  ...INITIAL_VALUES.billingAddress,
                  ...omitBy(billingAddress, (val) => val === null),
                },
                creditCardDetails: {
                  ownerShipType,
                },
                useDefaultAddress: false,
                paymentType: IS_CREDIT_CARD,
                paymentToken,
                isDefault,
              }}
              onSubmit={onSubmit}
            >
              {
                (formikProps) => (
                  <>
                    <Grid
                      container
                      item
                      className={classes.editPaymentContainer}
                      direction="column"
                      alignItems="center"
                    >
                      <EditCreditCardForm
                        defaultSender={defaultSender}
                        setHostedFieldsInstance={setHostedFieldsInstance}
                        clientInstance={clientInstance}
                        formikRef={formikRef}
                        paymentMethod={paymentMethod}
                      />
                      <Grid container item style={{ width: '250px', marginTop: 10 }} justify="space-between">
                        <Grid item>
                          <NewOrderNextButtonClear
                            disabled={formikProps.isSubmitting}
                            onClick={() => setEditing(null)}
                          >
                            Cancel
                          </NewOrderNextButtonClear>
                        </Grid>
                        <Grid item>
                          <NewOrderNextButton disabled={formikProps.isSubmitting} onClick={formikProps.submitForm}>
                            Submit
                          </NewOrderNextButton>
                        </Grid>
                      </Grid>
                      {(formikProps.isSubmitting
                  && (
                  <Grid item style={{ padding: '10px 0' }}>
                    <CircularProgress color="secondary" />
                  </Grid>
                  )
                  )}
                    </Grid>

                  </>
                )
              }
            </Formik>
          </Grid>
        )
      }
      <NewOrderErrorDialog
        open={!!error}
        errorContent={error}
        onClose={() => setError(null)}
      />
    </>
  );
}

CreditCardPanel.propTypes = {
  paymentMethod: PropTypes.objectOf(PropTypes.any).isRequired,
  defaultSender: PropTypes.objectOf(PropTypes.any),
  clientInstance: PropTypes.objectOf(PropTypes.any),
  hostedFieldsInstance: PropTypes.objectOf(PropTypes.any),
  setHostedFieldsInstance: PropTypes.func.isRequired,
  deletePaymentMethod: PropTypes.func.isRequired,
  makePreferredPayment: PropTypes.func.isRequired,
  editing: PropTypes.string,
  setEditing: PropTypes.func.isRequired,
};

CreditCardPanel.defaultProps = {
  defaultSender: null,
  clientInstance: null,
  hostedFieldsInstance: null,
  editing: null,
};

function BankAccountPanel(props) {
  const {
    paymentMethod, clientInstance,
    usBankAccountInstance, setUSBankAccountInstance,
    defaultSender,
    // eslint-disable-next-line no-shadow
    deletePaymentMethod,
    editing, setEditing,
    makePreferredPayment,
  } = props;
  const {
    billingAddress, ownerShipType, paymentToken, bankAccountDetails, isDefault,
  } = paymentMethod;
  const [error, setError] = React.useState(null);
  const formikRef = React.useRef(null);
  const dispatch = usePaymentDispatch();

  const classes = usePaymentMethodPanelStyles();

  async function onSubmit(values, { setSubmitting }) {
    // eslint-disable-next-line no-shadow
    const { paymentToken } = values;
    let unformattedPayload = { ...values };
    const schema = getPostTokenizationSchema(IS_ACH);
    try {
      const paymentPayload = await getAchPayload(values, usBankAccountInstance);
      unformattedPayload = { ...unformattedPayload, ...paymentPayload };
    } catch (e) {
      setError(JSON.stringify({
        error: 'Unable to verify payment method. Please check your details and try again.',
      }));
      setSubmitting(false);
      return;
    }
    const formattedPayload = formatPaymentForSubmission(unformattedPayload);
    let payload;
    try {
      payload = await schema.validate(formattedPayload, { stripUnknown: true });
    } catch (e) {
      setError(JSON.stringify({ error: 'Error verifying payment. Please try again later.' }));
      setSubmitting(false);
      return;
    }
    try {
      await updatePaymentMethod({ dispatch, paymentToken, payload });
    } catch (e) {
      setError(e.message);
      setSubmitting(false);
      return;
    }
    setSubmitting(false);
    setEditing(null);
  }

  return (
    <>
      {
        editing !== paymentToken && (
        <Grid
          container
          item
          direction="column"
          justify="flex-start"
          className={classes.container}
          wrap="nowrap"
        >
          <Grid item container justify="space-between">

            <Grid item container className={classes.headerContainer} alignItems="flex-start" xs={3}>
              <Typography className={classes.headerText}>{bankAccountDetails.bankName}</Typography>
            </Grid>

            <Grid item className={classes.headerContainer} xs={3}>
              {billingAddress && <Typography className={classes.headerText}>Billing Address</Typography>}
            </Grid>

            <Grid item container xs={6} alignItems="center" justify="flex-end">
              <Grid item container style={{ width: 'auto' }} spacing={2} wrap="nowrap">
                {(paymentMethod.isDefault ? (
                  <Grid item container spacing={2} wrap="nowrap">
                    <Grid item>
                      <Tooltip title="Preferred payment" classes={{ tooltip: classes.tooltip }}>
                        <StarIcon style={{ color: 'gold' }} />
                      </Tooltip>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid item container spacing={2} wrap="nowrap">
                    <Grid item>
                      <Tooltip title="Make preferred payment" classes={{ tooltip: classes.tooltip }}>
                        <IconButton
                          style={{ padding: 0 }}
                          onClick={() => {
                            makePreferredPayment(paymentToken);
                          }}
                        >
                          <StarIcon style={{ color: 'rgba(255, 255, 255, 0.1)' }} />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                ))}
                <Grid item>
                  <IconButton
                    style={{ padding: 0 }}
                    disableFocusRipple
                    onClick={() => setEditing((prev) => paymentToken)}
                  >
                    <EditIcon />
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton
                    style={{ padding: 0 }}
                    disableFocusRipple
                    onClick={() => deletePaymentMethod(paymentToken)}
                  >
                    <DeleteIcon className={classes.deleteIcon} />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item container justify="space-between" wrap="nowrap">
            <Grid item container xs={3}>
              <Typography className={classes.bodyText}>
                {`Ending in ${bankAccountDetails.lastFour}`}
              </Typography>
            </Grid>
            <Grid item container direction="column" wrap="nowrap" xs={3}>
              {billingAddress
              && (
              <BillingAddressDisplay
                billingAddress={billingAddress}
              />
              )}
            </Grid>
            <Grid item xs={6} />
          </Grid>
        </Grid>
        )
      }
      {
        editing === paymentToken && (
          <Grid
            container
            item
            direction="column"
            justify="flex-start"
            className={classes.editPaymentContainer}
            wrap="nowrap"
          >
            <Formik
              initialValues={{
                ...INITIAL_VALUES,
                billingAddress: {
                  ...INITIAL_VALUES.billingAddress,
                  ...omitBy(billingAddress, (val) => val === null),
                },
                bankAccountDetails: {
                  ownerShipType,
                  accountNumber: '',
                  ...bankAccountDetails,
                },
                useDefaultAddress: false,
                paymentType: IS_ACH,
                paymentToken,
                isDefault,
              }}
              ref={formikRef}
              onSubmit={onSubmit}
              validationSchema={editAchSchemaPreTokenization}
            >
              {(formikProps) => (
                <Grid item container direction="column" alignItems="center" spacing={2}>
                  <Grid item container justify="space-between" noWrap alignItems="center" style={{ height: 50, paddingBottom: 15 }}>
                    <Grid item>
                      <Typography color="primary" style={{ fontSize: 18, fontWeight: '500' }}>
                        Bank Account Details
                      </Typography>
                    </Grid>
                    <Grid item style={{ alignSelf: 'center' }}>
                      <Field name="isDefault">
                        {({ field, form }) => (field.value ? (
                          <Grid item container wrap="nowrap" alignItems="center" justify="flex-end" spacing={2}>
                            <Grid item className={classes.headerContainer}>
                              <Typography
                                noWrap
                                className={`${classes.headerText} ${classes.preferredPaymentSelected}`}
                                align="right"
                                color="primary"
                              >
                                Preferred Payment
                              </Typography>
                            </Grid>
                            <Button
                              disableRipple
                              onClick={() => {
                                form.setFieldValue(field.name, !field.value);
                              }}
                              disabled={form.isSubmitting}
                              className={classes.preferredPaymentButton}
                            >
                              <Grid item alignItems="center" style={{ display: 'flex' }}>
                                <StarIcon className={classes.preferredPaymentSelected} />
                              </Grid>
                            </Button>
                          </Grid>
                        ) : (
                          <Grid item container wrap="nowrap" alignItems="center" justify="flex-end" spacing={2}>
                            <Grid item className={classes.headerContainer}>
                              <Typography
                                noWrap
                                className={`${classes.headerText} ${classes.preferredPaymentUnselected}`}
                                align="right"
                                color="primary"
                              >
                                Make Preferred Payment
                              </Typography>
                            </Grid>
                            <Button
                              disableRipple
                              onClick={() => {
                                form.setFieldValue(field.name, !field.value);
                              }}
                              disabled={form.isSubmitting}
                              className={classes.preferredPaymentButton}
                            >
                              <Grid item alignItems="center" style={{ display: 'flex' }}>
                                <StarIcon className={classes.preferredPaymentUnselected} />
                              </Grid>
                            </Button>
                          </Grid>
                        ))}
                      </Field>
                    </Grid>
                  </Grid>
                  <Grid item container>
                    <ACHForm
                      clientInstance={clientInstance}
                      setUSBankAccountInstance={setUSBankAccountInstance}
                      // updateExistingPayment
                    />
                  </Grid>
                  <Grid container item>
                    <PaymentBillingAddressForm
                      formikRef={formikRef}
                      defaultSender={defaultSender}
                      paymentType={IS_ACH}
                    />
                  </Grid>
                  <Grid container item style={{ width: '250px', marginTop: 10 }} justify="space-between">
                    <Grid item>
                      <NewOrderNextButtonClear onClick={() => setEditing(null)} disabled={formikProps.isSubmitting}>
                        Cancel
                      </NewOrderNextButtonClear>
                    </Grid>
                    <Grid item>
                      <NewOrderNextButton onClick={formikProps.submitForm} disabled={formikProps.isSubmitting}>
                        Submit
                      </NewOrderNextButton>
                    </Grid>

                  </Grid>
                  {(formikProps.isSubmitting
                  && (
                  <Grid item style={{ padding: '10px 0' }}>
                    <CircularProgress color="secondary" />
                  </Grid>
                  )
                  )}
                </Grid>
              )}
            </Formik>
          </Grid>

        )
      }
      <NewOrderErrorDialog
        open={!!error}
        errorContent={error}
        onClose={() => setError(null)}
      />
    </>
  );
}

BankAccountPanel.propTypes = {
  paymentMethod: PropTypes.objectOf(PropTypes.any).isRequired,
  defaultSender: PropTypes.objectOf(PropTypes.any),
  clientInstance: PropTypes.objectOf(PropTypes.any),
  usBankAccountInstance: PropTypes.objectOf(PropTypes.any),
  setUSBankAccountInstance: PropTypes.func.isRequired,
  deletePaymentMethod: PropTypes.func.isRequired,
  makePreferredPayment: PropTypes.func.isRequired,
  editing: PropTypes.string,
  setEditing: PropTypes.func.isRequired,
};

BankAccountPanel.defaultProps = {
  defaultSender: null,
  clientInstance: null,
  usBankAccountInstance: null,
  editing: null,
};

const appBarStyles = {
  appBar: {
    boxShadow: 'none',
    backgroundColor: colors.mediumBlueBackground,
  },
  appBarIndicator: {
    backgroundColor: colors.lightBlue,
  },
  appBarTab: {
    '&$appBarTabDisabled': {
      '&$appBarTabSelected': {
        color: colors.white,
      },
      color: colors.textLightGrey,
    },
    color: colors.textLightGrey,
    fontSize: 14.6,
    padding: '0px 18px',
    borderBottom: `1px solid ${colors.inputFieldBackground}`,
  },
  appBarTabDisabled: {},
  appBarTabSelected: {},
};

const useAppBarStyles = makeStyles(appBarStyles);

function CustomAppBar(props) {
  const {
    paymentOptions, selectedTab, isDisabled, onChange,
  } = props;

  const {
    appBar,
    appBarIndicator,
    appBarTab,
    appBarTabDisabled,
    appBarTabSelected,
  } = useAppBarStyles();

  const ADD_PAYMENT_TABS = [
    {
      label: 'Add Credit Card',
      value: IS_CREDIT_CARD,
    },
    {
      label: 'Add Bank Account',
      value: IS_ACH,
    },
  ];

  const [tabs, setTabs] = useState(ADD_PAYMENT_TABS);

  useEffect(() => {
    if (!isEmpty(paymentOptions)) {
      setTabs(tabs.filter((tab) => has(paymentOptions, tab.value) && paymentOptions[tab.value]));
    }
  }, [paymentOptions]);

  return (
    <AppBar position="static" classes={{ root: appBar }}>
      <Tabs
        textColor="primary"
        value={selectedTab}
        onChange={onChange}
        classes={{ indicator: appBarIndicator }}
      >
        {tabs.map((tab) => (
          <Tab
            key={`assign-task-tab-${tab.label}`}
            value={tab.value}
            label={tab.label}
            wrapped
            disabled={isDisabled}
            classes={{
              root: appBarTab,
              disabled: appBarTabDisabled,
              selected: appBarTabSelected,
            }}
          />
        ))}
      </Tabs>
    </AppBar>
  );
}

CustomAppBar.propTypes = {
  selectedTab: PropTypes.string.isRequired,
  paymentOptions: PropTypes.object.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

function PaymentBillingAddressForm(props) {
  const { defaultSender, formikRef, paymentType } = props;
  const USE_DEFAULT_ADDRESS = 'useDefaultAddress';
  const CREDIT_CARD_FIELDS = ['addressLine1', 'addressLine2', 'addressLine3', 'city', 'state', 'zip', 'country'];
  function onChangeUseDefaultSender(checked) {
    if (!formikRef.current) return;
    const { setFieldValue } = formikRef.current;
    if (!checked && defaultSender) {
      setFieldValue('billingAddress', {
        ...EMPTY_BILLING_ADDRESS,
        ...formatDefaultSender(defaultSender),
      });
    }
  }
  React.useEffect(() => {
    if (!defaultSender || !formikRef.current || !formikRef.current.state) return;
    const { useDefaultAddress } = formikRef.current.state.values;
    const { setFieldValue } = formikRef.current;

    if (useDefaultAddress && formikRef.current) {
      setFieldValue('billingAddress', {
        ...EMPTY_BILLING_ADDRESS,
        ...formatDefaultSender(defaultSender),
      });
    }
  }, [formikRef.current]);
  return (
    <Grid item container direction="column" spacing={1}>
      <Grid item>
        <Typography
          color="primary"
          style={{
            fontWeight: '500',
            fontSize: '18px',
            paddingTop: '24px',
          }}
        >
          Billing Address
        </Typography>
      </Grid>
      <Grid item>
        {(defaultSender)
          ? (
            <CustomCheckbox
              name={USE_DEFAULT_ADDRESS}
              label={'Use my profile\'s default address'}
              callback={onChangeUseDefaultSender}
            />
          )
          : null}

      </Grid>
      <Grid item container direction="column">
        <Field>
          {(formikProps) => {
            const { form } = formikProps;
            const { setFieldValue, values, isSubmitting } = form;
            const changeUseDefaultAddress = () => {
              if (values[USE_DEFAULT_ADDRESS]) {
                setFieldValue(USE_DEFAULT_ADDRESS, false);
              }
            };

            const upperFields = CREDIT_CARD_FIELDS.filter((field) => !['state', 'zip'].includes(field));

            return (
              <>
                {paymentType === IS_CREDIT_CARD && (
                <Grid container spacing={2} style={{ paddingBottom: '12px' }}>
                  <Grid item xs={6}>
                    <CustomText
                      name="billingAddress.firstName"
                      label="First name"
                      blockValue={(val) => val.match(/[0-9]/)}
                      disabled={isSubmitting}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <CustomText
                      name="billingAddress.lastName"
                      label="Last name"
                      blockValue={(val) => val.match(/[0-9]/)}
                      disabled={isSubmitting}
                    />
                  </Grid>
                </Grid>
                )}
                <AddressForm
                  fields={upperFields}
                  fieldNames={CREDIT_CARD_FIELDS
                    .reduce((acc, field) => ({ ...acc, [field]: `billingAddress.${field}` }), {})}
                  callback={changeUseDefaultAddress}
                  other={{
                    formikRef,
                    ...(isSubmitting && { disabled: upperFields.map((field) => `billingAddress.${field}`) }),
                  }}
                />
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <AddressForm
                      fields={['state']}
                      fieldNames={{ state: 'billingAddress.state' }}
                      other={{
                        formikRef,
                        ...(isSubmitting && { disabled: ['billingAddress.state'] }),
                        country: get(form.values, 'billingAddress.country'),
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <AddressForm
                      fields={['zip']}
                      fieldNames={{ zip: 'billingAddress.zip' }}
                      other={{
                        formikRef,
                        ...(isSubmitting && { disabled: ['billingAddress.zip'] }),
                      }}
                    />
                  </Grid>
                </Grid>
              </>
            );
          }}
        </Field>
      </Grid>
    </Grid>
  );
}
PaymentBillingAddressForm.propTypes = {
  defaultSender: PropTypes.objectOf(PropTypes.any),
  formikRef: PropTypes.objectOf({ current: PropTypes.any }),
  paymentType: PropTypes.string.isRequired,
};

PaymentBillingAddressForm.defaultProps = {
  defaultSender: {},
  formikRef: { current: {} },
};

async function getCreditCardPayload(values, hostedFieldsInstance, onError) {
  if (!hostedFieldsInstance) {
    throw new Error('Unable to connect to Credit Card payment provider');
  }

  const payload = await hostedFieldsInstance.tokenize();

  const { nonce } = payload;

  return {
    paymentNonce: nonce,
  };
}

async function getAchPayload(values, usBankAccountInstance) {
  if (!usBankAccountInstance) {
    throw new Error('Unable to connect to payment provider');
  }
  const { bankAccountDetails, billingAddress } = values;
  const streetAddress = billingAddress.addressLine1;
  const extendedAddress = [billingAddress.addressLine2, billingAddress.addressLine3]
    .filter((al) => !!al)
    .join(', ');
  const locality = billingAddress.city;
  const postalCode = billingAddress.zip;
  const region = billingAddress.state;
  const ownershipType = bankAccountDetails.ownerShipType;
  const bankAccountDetailsForTokenization = pickBy(bankAccountDetails, (val, key) => [
    'accountNumber',
    'routingNumber',
    'firstName',
    'lastName',
    'businessName',
    'accountType',
  ].includes(key) && !!val);
  const payload = await usBankAccountInstance.tokenize({
    bankDetails: {
      ...bankAccountDetailsForTokenization,
      billingAddress: {
        streetAddress,
        extendedAddress,
        locality,
        region,
        postalCode,
      },
      ownershipType,
    },
    mandateText: ACH_AUTH_TEXT,
  });
  const { nonce } = payload;

  return {
    paymentNonce: nonce,
  };
}

function getPostTokenizationSchema(paymentType) {
  if (paymentType === IS_CREDIT_CARD) {
    return creditCardSchemaPostTokenization.shape(commonSavedPaymentSchema);
  }
  if (paymentType === IS_ACH) {
    return achSchemaPostTokenization.shape(commonSavedPaymentSchema);
  }
  return emptySchema.shape(commonSavedPaymentSchema);
}

function EditCreditCardForm(props) {
  const {
    formikRef, defaultSender, clientInstance, setHostedFieldsInstance, paymentMethod,
  } = props;
  const { creditCardDetails } = paymentMethod;
  const {
    cardholderName, lastFour, cardType, expirationDate,
  } = creditCardDetails;
  const cardNumberDisplay = `${cardType} ending in ${lastFour}`;

  const classes = usePaymentMethodPanelStyles();

  return (
    <Grid
      container
      direction="column"
      spacing={2}
      item
    >
      <Grid item container justify="space-between" noWrap alignItems="center" style={{ height: 50, paddingBottom: 15 }}>
        <Grid item>
          <Typography color="primary" style={{ fontSize: 18, fontWeight: '500' }}>
            Credit Card Details
          </Typography>
        </Grid>
        <Grid item style={{ alignSelf: 'center' }}>
          <Field name="isDefault">
            {({ field, form }) => (field.value ? (
              <Grid item container wrap="nowrap" alignItems="center" justify="flex-end" spacing={2}>
                <Grid item className={classes.headerContainer}>
                  <Typography
                    noWrap
                    className={`${classes.headerText} ${classes.preferredPaymentSelected}`}
                    align="right"
                    color="primary"

                  >
                    Preferred Payment
                  </Typography>
                </Grid>
                <Button
                  disableRipple
                  disabled={form.isSubmitting}
                  onClick={() => {
                    form.setFieldValue(field.name, !field.value);
                  }}
                  className={classes.preferredPaymentButton}
                >
                  <Grid item alignItems="center" style={{ display: 'flex' }}>
                    <StarIcon className={classes.preferredPaymentSelected} />
                  </Grid>
                </Button>
              </Grid>
            ) : (
              <Grid item container wrap="nowrap" alignItems="center" justify="flex-end" spacing={2}>
                <Grid item className={classes.headerContainer}>
                  <Typography
                    noWrap
                    className={`${classes.headerText} ${classes.preferredPaymentUnselected}`}
                    align="right"
                    color="primary"
                  >
                    Make Preferred Payment
                  </Typography>
                </Grid>
                <Button
                  disableRipple
                  disabled={form.isSubmitting}
                  onClick={() => {
                    form.setFieldValue(field.name, !field.value);
                  }}
                  className={classes.preferredPaymentButton}
                >
                  <Grid item alignItems="center" style={{ display: 'flex' }}>
                    <StarIcon className={classes.preferredPaymentUnselected} />
                  </Grid>
                </Button>
              </Grid>
            ))}
          </Field>

        </Grid>
      </Grid>
      <Grid item container direction="column" spacing={2}>

        <Grid item>
          <Field>
            {({ form }) => (
              <CreditCardHostedForm
                clientInstance={clientInstance}
                setHostedFieldsInstance={setHostedFieldsInstance}
                updateExistingPayment
                disabled={form.isSubmitting}
                fieldOptions={{
                  expirationDate: {
                    prefill: expirationDate,
                  },
                  number: {
                    prefill: cardNumberDisplay,
                  },
                  cardholderName: {
                    prefill: cardholderName,
                  },
                }}
              />
            )}
          </Field>
        </Grid>

      </Grid>

      <Grid container spacing={2} item>
        <Grid item xs={5}>
          <Field
            component={CustomSelectTextField}
            type="text"
            name="creditCardDetails.ownerShipType"
            label="Ownership Type"
            style={{ width: '100%' }}
          >
            {CREDIT_CARD_OWNERSHIP_TYPE_OPTIONS.map((type) => (
              <MenuItem
                key={type.label}
                value={type.value}
              >
                {type.label}
              </MenuItem>
            ))}
          </Field>
        </Grid>
      </Grid>
      <Grid container item>
        <PaymentBillingAddressForm
          formikRef={formikRef}
          defaultSender={defaultSender}
          paymentType={IS_CREDIT_CARD}
        />
      </Grid>
    </Grid>
  );
}

EditCreditCardForm.propTypes = {
  formikRef: PropTypes.objectOf({
    current: PropTypes.objectOf(PropTypes.any),
  }),
  defaultSender: PropTypes.objectOf(PropTypes.any),
  clientInstance: PropTypes.objectOf(PropTypes.any),
  setHostedFieldsInstance: PropTypes.func.isRequired,
  paymentMethod: PropTypes.objectOf({
    isDefault: PropTypes.bool.isRequired,
    creditCardDetails: PropTypes.objectOf({
      lastFour: PropTypes.string.isRequired,
      cardholderName: PropTypes.string.isRequired,
      cardType: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

EditCreditCardForm.defaultProps = {
  formikRef: { current: null },
  defaultSender: null,
  clientInstance: null,
};

function CreditCardForm(props) {
  const {
    formikRef, defaultSender, clientInstance, setHostedFieldsInstance,
  } = props;

  return (
    <Grid
      container
      direction="column"
      spacing={2}
      item
    >
      <Grid item container>
        <Field>
          {({ form }) => (
            <CreditCardHostedForm
              clientInstance={clientInstance}
              setHostedFieldsInstance={setHostedFieldsInstance}
              disabled={form.isSubmitting}
            />
          )}
        </Field>
      </Grid>

      <Grid container spacing={2} item>
        <Grid item xs={5}>
          <Field
            component={CustomSelectTextField}
            type="text"
            name="creditCardDetails.ownerShipType"
            label="Ownership Type"
            style={{ width: '100%' }}
          >
            {CREDIT_CARD_OWNERSHIP_TYPE_OPTIONS.map((type) => (
              <MenuItem
                key={type.label}
                value={type.value}
              >
                {type.label}
              </MenuItem>
            ))}
          </Field>
        </Grid>
      </Grid>
      <Grid container item>
        <PaymentBillingAddressForm
          formikRef={formikRef}
          defaultSender={defaultSender}
          paymentType={IS_CREDIT_CARD}
        />
      </Grid>
    </Grid>
  );
}

CreditCardForm.propTypes = {
  formikRef: PropTypes.objectOf({
    current: PropTypes.objectOf(PropTypes.any),
  }),
  defaultSender: PropTypes.objectOf(PropTypes.any),
  clientInstance: PropTypes.objectOf(PropTypes.any),
  setHostedFieldsInstance: PropTypes.func.isRequired,
};

CreditCardForm.defaultProps = {
  formikRef: { current: null },
  defaultSender: null,
  clientInstance: null,
};
function AddPaymentForm(props) {
  const classes = useAddPaymentStyles();
  const formikRef = React.useRef();
  const [selectedTab, setSelectedTab] = React.useState(IS_CREDIT_CARD);
  const dispatch = usePaymentDispatch();
  const [error, setError] = React.useState(null);
  const {
    defaultSender, paymentOptions,
    hostedFieldsInstance, usBankAccountInstance, clientInstance,
    setHostedFieldsInstance, setUSBankAccountInstance, closeAddPaymentOption,
  } = props;

  async function onSubmit(values, { setSubmitting }) {
    let paymentPayload = {};
    const schema = getPostTokenizationSchema(selectedTab);
    try {
      if (selectedTab === IS_CREDIT_CARD) {
        paymentPayload = await getCreditCardPayload(values, hostedFieldsInstance);
      }
      if (selectedTab === IS_ACH) {
        paymentPayload = await getAchPayload(values, usBankAccountInstance);
      }
    } catch (e) {
      setError(JSON.stringify({ error: 'Unable to verify payment method. Please check your details and try again.' }));
      setSubmitting(false);
      return;
    }

    const payload = formatPaymentForSubmission({ ...values, ...paymentPayload });

    let strippedPayload;
    try {
      strippedPayload = await schema.validate(payload, { stripUnknown: true });
      await addPaymentMethod({ dispatch, payload: strippedPayload });
    } catch (e) {
      setError(JSON.stringify({ error: 'Error verifying payment. Please try again later.' }));
      setSubmitting(false);
      return;
    }
    setSubmitting(false);
    closeAddPaymentOption();
  }

  React.useEffect(() => {
    if (!formikRef.current) return;
    const { setFieldValue } = formikRef.current;

    let paymentType = '';

    if (selectedTab === IS_CREDIT_CARD) paymentType = 'creditCard';
    else if (selectedTab === IS_ACH) paymentType = 'ach';

    setFieldValue('paymentType', paymentType);
  }, [formikRef.current, selectedTab]);

  return (
    <Grid
      item
      container
      className={classes.addPaymentContainer}
      direction="column"
      alignItems="center"
    >
      <Formik
        initialValues={INITIAL_VALUES}
        validationSchema={addPaymentSchemaPreTokenization}
        ref={formikRef}
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => (
          <>
            <Grid item container justify="space-between" style={{ marginBottom: 5 }}>
              <Grid item container>
                <Typography className={classes.addPaymentHeader}>
                  Add Payment Method
                </Typography>
              </Grid>
            </Grid>
            <Grid item container>
              <CustomAppBar
                isDisabled={isSubmitting}
                selectedTab={selectedTab}
                paymentOptions={paymentOptions}
                onChange={(e, val) => {
                  setSelectedTab(val);
                }}
              />
            </Grid>
            <Grid item container style={{ flexGrow: '1' }}>
              <ScrollWindow>
                <>
                  {
            (selectedTab === 'isCreditCard') && (
              <Grid
                className={classes.addPaymentInnerContainer}
                container
              >
                <CreditCardForm
                  defaultSender={defaultSender}
                  setHostedFieldsInstance={setHostedFieldsInstance}
                  clientInstance={clientInstance}
                  formikRef={formikRef}
                />
              </Grid>

            )
            }
                  {
              (selectedTab === 'isACH') && (
              <Grid
                container
                className={classes.addPaymentInnerContainer}
                direction="column"
                spacing={2}
                item
              >
                <Grid item container>
                  <ACHForm
                    clientInstance={clientInstance}
                    setUSBankAccountInstance={setUSBankAccountInstance}
                  />
                </Grid>
                <Grid container item>
                  <PaymentBillingAddressForm
                    formikRef={formikRef}
                    defaultSender={defaultSender}
                    paymentType={selectedTab}
                  />
                </Grid>
              </Grid>
              )
            }
                </>
              </ScrollWindow>
            </Grid>
            <Grid item style={{ padding: '10px 0' }}>
              <NewOrderNextButton
                style={{ width: 'fit-content', padding: '0 10px' }}
                onClick={formikRef.current && formikRef.current.submitForm}
                disabled={isSubmitting}
              >
                Save to Payment Methods

              </NewOrderNextButton>
            </Grid>

            {(isSubmitting
              && (
              <Grid item style={{ padding: '10px 0' }}>
                <CircularProgress color="secondary" />
              </Grid>
              )
              )}

            <NewOrderErrorDialog
              open={!!error}
              errorContent={error}
              onClose={() => setError(null)}
            />
          </>
        )}
      </Formik>
    </Grid>
  );
}

AddPaymentForm.propTypes = {
  defaultSender: PropTypes.objectOf(PropTypes.any),
  hostedFieldsInstance: PropTypes.func,
  usBankAccountInstance: PropTypes.objectOf(PropTypes.any),
  clientInstance: PropTypes.objectOf(PropTypes.any),
  paymentOptions: PropTypes.object.isRequired,
  setHostedFieldsInstance: PropTypes.func.isRequired,
  setUSBankAccountInstance: PropTypes.func.isRequired,
  closeAddPaymentOption: PropTypes.func.isRequired,
};

AddPaymentForm.defaultProps = {
  defaultSender: null,
  clientInstance: null,
  usBankAccountInstance: null,
  hostedFieldsInstance: null,
};

export default function PaymentManagementForm({ paymentOptions }) {
  const classes = usePaymentManagementStyles();
  const [open, setOpen] = React.useState(false);
  const { savedPaymentMethods } = usePaymentState();
  const [clientInstance, setClientInstance] = React.useState(null);
  const [hostedFieldsInstance, setHostedFieldsInstance] = React.useState(null);
  const [usBankAccountInstance, setUSBankAccountInstance] = React.useState(null);
  const { defaultSender } = useContactsState();
  const contactsDispatch = useContactsDispatch();
  const [deletingPaymentMethod, setDeletingPaymentMethod] = React.useState(null);
  const [editing, setEditing] = React.useState(null);
  const paymentDispatch = usePaymentDispatch();
  const [changingDefaultPayment, setChangingDefaultPayment] = React.useState(null);
  const [error, setError] = React.useState(null);

  React.useEffect(() => {
    loadDefaultSender(contactsDispatch);
  }, [contactsDispatch]);

  React.useEffect(() => {
    getBraintreeApiKey().then(async (key) => {
      const instance = await braintree.client.create({
        authorization: key,
      }).catch(() => null);
      setClientInstance(instance);
    });
  }, []);

  const handleAddPaymentButton = () => {
    setEditing(null);
    setOpen(true);
  };

  const handleCloseAddPaymentOption = () => {
    setOpen(false);
  };

  async function handleDeletePaymentMethod(paymentToken) {
    try {
      await deletePaymentMethod({ dispatch: paymentDispatch, paymentToken });
    } catch (e) {
      setError(e.message);
    }
    setDeletingPaymentMethod(null);
  }

  async function makePreferredPayment(paymentToken) {
    try {
      await setDefaultPayment({ dispatch: paymentDispatch, paymentToken });
    } catch (e) {
      setError(e.message);
    }
    setChangingDefaultPayment(null);
  }

  return (
    <Grid container spacing={4}>
      <Grid xs={12} item container justify="space-between" alignItems="center" spacing={2}>
        <Grid container item justify="space-between">
          <Grid item>
            <Typography className={classes.header}>Payment Methods</Typography>
          </Grid>
          <Grid item>
            <NewOrderWideButton
              startIcon={<AddIcon />}
              style={{ width: '100%' }}
              onClick={handleAddPaymentButton}
            >
              ADD PAYMENT METHOD
            </NewOrderWideButton>
          </Grid>
        </Grid>
        <Grid item container direction="column" spacing={3}>
          {savedPaymentMethods && [...savedPaymentMethods.creditCards, ...savedPaymentMethods.bankAccounts]
            .sort((a, b) => {
              if (a.isDefault) return -1;
              if (b.isDefault) return 1;
              return 0;
            })
            .map((paymentMethod) => {
              if (paymentMethod.creditCardDetails) {
                return (
                  <Grid item key={paymentMethod.paymentToken}>
                    <CreditCardPanel
                      paymentMethod={paymentMethod}
                      defaultSender={defaultSender}
                      setHostedFieldsInstance={setHostedFieldsInstance}
                      hostedFieldsInstance={hostedFieldsInstance}
                      clientInstance={clientInstance}
                      deletePaymentMethod={setDeletingPaymentMethod}
                      makePreferredPayment={setChangingDefaultPayment}
                      editing={editing}
                      setEditing={setEditing}
                    />
                  </Grid>
                );
              }
              if (paymentMethod.bankAccountDetails) {
                return (
                  <Grid item key={paymentMethod.paymentToken}>
                    <BankAccountPanel
                      paymentMethod={paymentMethod}
                      defaultSender={defaultSender}
                      setUSBankAccountInstance={setUSBankAccountInstance}
                      usBankAccountInstance={usBankAccountInstance}
                      clientInstance={clientInstance}
                      deletePaymentMethod={setDeletingPaymentMethod}
                      makePreferredPayment={setChangingDefaultPayment}
                      editing={editing}
                      setEditing={setEditing}
                    />
                  </Grid>
                );
              }
              return null;
            })}

        </Grid>
      </Grid>
      <Dialog
        open={open}
        onClose={(e, reason) => {
          if (reason === 'backdropClick' || reason === 'escapeKeyDown') return;
          return handleCloseAddPaymentOption();
        }}
        color="primary"
        classes={{
          paper: classes.dialogPaper,
        }}
      >
        <Grid container direction="column" className={classes.dialogInnerContainer} spacing={1}>
          <IconButton className={classes.closeIcon} onClick={() => setOpen(false)}>
            <CloseIcon color="primary" />
          </IconButton>
          <AddPaymentForm
            defaultSender={defaultSender}
            setHostedFieldsInstance={setHostedFieldsInstance}
            setUSBankAccountInstance={setUSBankAccountInstance}
            hostedFieldsInstance={hostedFieldsInstance}
            usBankAccountInstance={usBankAccountInstance}
            clientInstance={clientInstance}
            closeAddPaymentOption={handleCloseAddPaymentOption}
            paymentOptions={paymentOptions}
          />
        </Grid>
      </Dialog>
      <ConfirmationModal
        open={!!deletingPaymentMethod}
        onProceed={() => handleDeletePaymentMethod(deletingPaymentMethod)}
        onCancel={() => setDeletingPaymentMethod(null)}
        message="Are you sure you would like to delete this payment method?"
        proceedLabel="Yes, delete"
      />
      <ConfirmationModal
        open={!!changingDefaultPayment}
        onProceed={() => makePreferredPayment(changingDefaultPayment)}
        onCancel={() => setChangingDefaultPayment(null)}
        message="Are you sure you would like to change your preferred payment?"
        proceedLabel="Yes"

      />
      <NewOrderErrorDialog
        errorContent={error}
        onClose={() => setError(null)}
        open={!!error}
      />
    </Grid>
  );
}
