import client from './apiClient';
import {
  DUMMY_FETCH_NOTIFICATIONS,
} from '../dummyData';

export function fetchUnreadNotifications() {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return Promise.resolve(DUMMY_FETCH_NOTIFICATIONS);
  }
  return client('/data/notifications/unread', { method: 'get' });
}

export function fetchAllNotifications() {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return Promise.resolve(DUMMY_FETCH_NOTIFICATIONS);
  }
  return client('/data/notifications', { method: 'get' });
}

export function markSingleNotificationAsRead(notificationId) {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return Promise.resolve([]);
  }
  return client('/data/notifications/read/one', {
    method: 'put',
    data: {
      notificationId,
    },
  });
}

export function markAllNotificationsAsRead() {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return Promise.resolve([]);
  }
  return client('/data/notifications/read/all', {
    method: 'put',
  });
}
