import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogActions, Grid } from '@material-ui/core';
import NewOrderNextButton from './NewOrderNextButton';

export default function NewOrderAlertDialog(props) {
  const { open, alertContent, classes } = props;
  const { onClose } = props;

  const { dialogActionsContainer, dialogRoot } = classes;

  const dialogActions = (
    <DialogActions classes={{ root: dialogActionsContainer }}>
      <Grid
        container
        spacing={4}
        justify="center"
      >
        <Grid item>
          <NewOrderNextButton onClick={onClose}>Ok</NewOrderNextButton>
        </Grid>
      </Grid>
    </DialogActions>
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{ paper: dialogRoot }}
    >
      {alertContent}
      {dialogActions}
    </Dialog>
  );
}

NewOrderAlertDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  alertContent: PropTypes.objectOf(PropTypes.any).isRequired,
  onClose: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    dialogRoot: PropTypes.string.isRequired,
    dialogActionsContainer: PropTypes.string.isRequired,
  }).isRequired,
};
