import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel } from '@material-ui/core';
import { fieldToCheckbox } from 'formik-material-ui';
import StyledCheckbox from '../common/StyledCheckbox';

function ControlledCheckbox(props) {
  const { label } = props;
  const formikProps = fieldToCheckbox(props);
  return (
    <FormControlLabel
      label={label}
      control={(
        <StyledCheckbox
          {...formikProps}
        />
  )}
    />
  );
}

ControlledCheckbox.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

export default memo(ControlledCheckbox);
