import React from 'react';
import { Grid, MenuItem, Typography } from '@material-ui/core';
import { Field } from 'formik';
import { css } from 'emotion';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { CustomText, CustomRadio, CustomCheckbox } from '../common/InputComponents';
import CreditCardHostedForm from '../common/CreditCardHostedForm';
import CustomSelectTextField from '../common/CustomSelectTextField';
import AddressForm from '../common/AddressForm';
import * as colors from '../../styles/colors';

import { getTypography } from './util';

import {
  USE_DEFAULT_ADDRESS,
  CREDIT_CARD_FIELDS,
  CREDIT_CARD_DETAILS,
  CREDIT_CARD_OWNERSHIP_TYPE_OPTIONS,
  NEW_CARD_OPTION,
} from './constants';
import BillingAddressSummary from './BillingAddressSummary';
import CardSummary from './CardSummary';

export default function CustomCreditCard(props) {
  const {
    formikRef, defaultSender = null, billingDetails, lockPayment,
    classes, isSubmitting, clientInstance, setHostedFieldsInstance, savedCards, isDelayedPayment,
  } = props;

  const creditCardHeader = (!!billingDetails && lockPayment)
    ? (
      <Grid item container direction="column" spacing={2}>
        <Grid item container direction="column" spacing={3}>
          {CREDIT_CARD_DETAILS.map((details) => {
            const { id, name, label } = details;

            return (
              <Grid item key={`credit-card-deatils-${id}`}>
                <CustomText
                  {...{ name, label, classes }}
                  type="text"
                  disabled
                />
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    )
    : (
      <Grid container spacing={2} direction="column">
        <Grid item>
          <CreditCardHostedForm
            clientInstance={clientInstance}
            setHostedFieldsInstance={setHostedFieldsInstance}
            disabled={isSubmitting}
          />
        </Grid>
        <Grid container item spacing={2}>
          <Grid item xs={5}>
            <Field
              component={CustomSelectTextField}
              type="text"
              name="creditCardDetails.ownerShipType"
              label="Ownership Type"
              style={{ width: '100%' }}
            >
              {CREDIT_CARD_OWNERSHIP_TYPE_OPTIONS.map((type) => (
                <MenuItem
                  key={type.label}
                  value={type.value}
                >
                  {type.label}
                </MenuItem>
              ))}
            </Field>
          </Grid>
        </Grid>
      </Grid>
    );

  function getSavedCardDisplay(card, selectedPaymentToken) {
    return (
      <Grid container direction="column" style={{ marginTop: 9 }}>
        <CardSummary creditCardDetails={card.creditCardDetails} />
        {selectedPaymentToken === card.paymentToken && card.billingAddress
          ? <BillingAddressSummary billingAddress={card.billingAddress} />
          : null}
      </Grid>
    );
  }

  const savedCardsPanel = (savedCards && savedCards.length > 0)
    ? (
      <Grid item container direction="column">
        <Typography
          id="saved-credit-cards"
          className={css`
              color: ${colors.white};
              font-weight: 500;
              font-size: 18px;
              margin-bottom: 15px;
          `}
        >
          Saved cards
        </Typography>
        <Field>
          {({ form }) => (
            <CustomRadio
              id="paymentToken"
              disabled={lockPayment}
              classes={{
                radioLabel: {
                  root: css`
                align-items: flex-start
              `,
                },
              }}
              options={(savedCards.map((card) => (
                {
                  id: card.paymentToken,
                  label: getSavedCardDisplay(card, form.values.paymentToken),
                  value: card.paymentToken,
                  classes,
                }
              )))
                .concat([{
                  id: NEW_CARD_OPTION.id,
                  label: <Grid style={{ marginTop: 9 }}>{NEW_CARD_OPTION.label}</Grid>,
                  value: NEW_CARD_OPTION.value,
                }])}
              callback={(value) => {
                form.setFieldValue('paymentToken', value);
              }}
            />
          )}
        </Field>
      </Grid>
    )
    : (
      <Grid item container direction="column">
        <Typography
          id="saved-credit-cards"
          className={css`
          color: ${colors.white};
          font-weight: 500;
          font-size: 18px;
          margin-bottom: 15px;
      `}
        >
          Card
        </Typography>
        <Field>
          {({ form }) => (
            <CustomRadio
              id="paymentToken"
              disabled={lockPayment}
              classes={{
                radioLabel: {
                  root: css`
            align-items: flex-start
          `,
                },
              }}
              options={[]
                .concat([{
                  id: NEW_CARD_OPTION.id,
                  label: <Grid style={{ marginTop: 9 }}>{NEW_CARD_OPTION.label}</Grid>,
                  value: NEW_CARD_OPTION.value,
                }])}
              callback={(value) => {
                form.setFieldValue('paymentToken', value);
              }}
            />
          )}
        </Field>
      </Grid>
    );

  return (
    <Grid item container direction="column" spacing={2}>
      {savedCardsPanel}
      <Field>
        {({ form }) => (
          <>
            {form.values.paymentToken === NEW_CARD_OPTION.value && !billingDetails?.billingAddress && (
              <>
                <Grid item>
                  {getTypography('credit-card-details', 'Credit Card Details', css`
              color: ${colors.white};
              font-weight: 500;
              font-size: 18px;
              margin-bottom: 15px;
            `)}
                </Grid>
                <Grid item>{creditCardHeader}</Grid>
                <Grid item container direction="column" spacing={1}>
                  <Grid item>
                    {getTypography('billing-address-header', 'Billing Address', css`
              color: ${colors.white};
              font-weight: 500;
              font-size: 18px;
              padding-top: 24px;
            `)}
                  </Grid>
                  <Grid item>
                    {(defaultSender)
                      ? (
                        <CustomCheckbox
                          name={USE_DEFAULT_ADDRESS}
                          label={'Use my profile\'s default address'}
                          disabled={lockPayment}
                        />
                      )
                      : null}

                  </Grid>
                  <Grid container spacing={2} style={{ paddingBottom: '12px' }}>
                    <Grid item xs={6}>
                      <CustomText
                        name="billingAddress.firstName"
                        label="First name"
                        disabled={lockPayment}
                        blockValue={(val) => val.match(/[0-9]/)}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <CustomText
                        name="billingAddress.lastName"
                        label="Last name"
                        disabled={lockPayment}
                        blockValue={(val) => val.match(/[0-9]/)}
                      />
                    </Grid>
                  </Grid>
                  <Field>
                    {() => {
                      const { setFieldValue, values } = form;
                      const changeUseDefaultAddress = () => {
                        if (values[USE_DEFAULT_ADDRESS]) {
                          setFieldValue(USE_DEFAULT_ADDRESS, false);
                        }
                      };
                      const upperFields = CREDIT_CARD_FIELDS.filter((field) => !['state', 'zip'].includes(field));
                      return (
                        <>
                          <AddressForm
                            fields={upperFields}
                            fieldNames={CREDIT_CARD_FIELDS
                              .reduce((acc, field) => ({ ...acc, [field]: `billingAddress.${field}` }), {})}
                            callback={changeUseDefaultAddress}
                            other={{
                              formikRef,
                              classes,
                              ...(((!!billingDetails && lockPayment) || isSubmitting) && { disabled: upperFields.map((field) => `billingAddress.${field}`) }),
                            }}
                          />
                          <Grid container spacing={3}>
                            <Grid item xs={6}>
                              <AddressForm
                                fields={['state']}
                                fieldNames={{ state: 'billingAddress.state' }}
                                other={{
                                  formikRef,
                                  classes,
                                  ...(((!!billingDetails && lockPayment) || isSubmitting) && { disabled: ['billingAddress.state'] }),
                                  country: get(form.values, 'billingAddress.country'),
                                }}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <AddressForm
                                fields={['zip']}
                                fieldNames={{ zip: 'billingAddress.zip' }}
                                other={{
                                  formikRef,
                                  classes,
                                  ...(((!!billingDetails && lockPayment) || isSubmitting) && { disabled: ['billingAddress.zip'] }),
                                }}
                              />
                            </Grid>
                          </Grid>
                        </>
                      );
                    }}
                  </Field>
                  {!isDelayedPayment && (
                  <Grid item>
                    <CustomCheckbox
                      name="isSavePayment"
                      label="Save card to profile"
                      disabled={lockPayment}
                    />
                  </Grid>
                  )}
                </Grid>
              </>
            )}
            {form.values.paymentToken === NEW_CARD_OPTION.value && billingDetails?.billingAddress && (
            <Grid container direction="column" style={{ paddingLeft: '2rem' }}>
              <CardSummary creditCardDetails={billingDetails.creditCardDetails} />
              <Grid style={{ paddingLeft: '1rem' }}>
                <BillingAddressSummary billingAddress={form.values.billingAddress} />
              </Grid>
            </Grid>
            )}
          </>
        )}
      </Field>
    </Grid>
  );
}

CustomCreditCard.propTypes = {
  values: PropTypes.shape({
    ccCountry: PropTypes.string.isRequired,
  }).isRequired,
  defaultSender: PropTypes.objectOf(PropTypes.any),
  billingDetails: PropTypes.objectOf(PropTypes.any).isRequired,
  lockPayment: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  setValues: PropTypes.func.isRequired,
  setHostedFieldsInstance: PropTypes.func.isRequired,
  classes: PropTypes.objectOf(PropTypes.string.isRequired).isRequired,
  formikRef: PropTypes.objectOf(PropTypes.any).isRequired,
  clientInstance: PropTypes.objectOf(PropTypes.any).isRequired,
  savedCards: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  isDelayedPayment: PropTypes.bool.isRequired,
};

CustomCreditCard.defaultProps = {
  defaultSender: null,
  savedCards: null,
};
