import client from './apiClient';
import { inDevelopmentOrTest } from '../clientConstants';

const getBraintreeApiKey = inDevelopmentOrTest()
  ? () => Promise.resolve(process.env.REACT_APP_BRAINTREE_API_KEY)
  : () => client('/data/billing/api-key');

const getGoogleMapsApiKey = inDevelopmentOrTest()
  ? () => Promise.resolve(process.env.REACT_APP_GOOGLE_MAPS_API_KEY)
  : () => client('/data/google-maps/api-key');

const getSsoEnabled = inDevelopmentOrTest()
  ? () => Promise.resolve(process.env.SINGLE_SIGNON_ENABLED)
  : () => client('/data/sso/enabled');

export {
  getBraintreeApiKey,
  getGoogleMapsApiKey,
  getSsoEnabled,
};
