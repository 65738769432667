import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import omit from 'lodash/omit';
import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
import { Field } from 'formik';
import {
  Grid, InputAdornment,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import { DebounceInput } from 'react-debounce-input';
import {
  BATTERY_CONTAINED_OR_PACKED_MEASUREMENT_UNIT_OPTIONS,
  EMPTY_LITHIUM_BATTERY_CONTAINED_OR_PACKED_ITEM_OBJECT,
  FORM_FIELDS,
  FORM_FIELDS_LITHIUM_BATTERY,
  LITHIUM_BATTERY_CONTAINED_KEY,
  SEARCH_INPUT_MIN_LENGTH,
  SHIPPING_LITHIUM_BATTERIES_KEY,
  TRANSPORTATION_OPTIONS_BATTERY_CONTAINED_OR_PACKED,
  TRANSPORTATION_OPTIONS_NAME_KEY,
} from '../../../itemDetailsConstants';
import { CustomSearchWithDropdown } from '../../../../common/InputComponents';
import NewOrderCustomTextField from '../../../../common/NewOrderCustomTextField';
import ProductWeight from '../../Weight';

import TransportationOptions from './TransportationOptions';
import { blockWeightAndDimensionInputs, getLithiumContainedOrPackedKey } from '../../../itemDetailsUtil';
import { loadMaterials } from '../../../../../utils/materialsClient';

const useStyles = makeStyles(() => ({
  productWeightContainer: {
    marginTop: '1rem',
  },
  netValue: {
    marginTop: '1.5rem',
  },
}));

function HazmatBatteriesContainedOrPacked({
  formikProps,
  fields,
  editableFields,
  filteredItemNameOptions,
  itemToEdit,
  isBatteryContained,
  setIsBatteryContained,
  setFilteredItemNameOptions,
  onSearch,
}) {
  const classes = useStyles();
  const batteryWeightUnits = {
    units: BATTERY_CONTAINED_OR_PACKED_MEASUREMENT_UNIT_OPTIONS,
  };
  const key = getLithiumContainedOrPackedKey(formikProps.values, isBatteryContained);
  const weightKey = `${key}.${FORM_FIELDS_LITHIUM_BATTERY.weight.key}`;
  const weightUnitKey = `${key}.${FORM_FIELDS_LITHIUM_BATTERY.weightUnit.key}`;
  const newEditableFields = [...editableFields, weightKey, weightUnitKey];

  const handleProductChange = (item) => {
    formikProps.setFieldValue(key, EMPTY_LITHIUM_BATTERY_CONTAINED_OR_PACKED_ITEM_OBJECT);

    formikProps.setFieldValue(key, {
      ...EMPTY_LITHIUM_BATTERY_CONTAINED_OR_PACKED_ITEM_OBJECT,
      ...omit(item, ['label', 'value']),
    });

    setFilteredItemNameOptions([]);
  };

  const getLithiumBatteryFieldKey = (fieldKey) => `${key}.${fieldKey}`;

  useEffect(() => {
    if (!isEmpty(itemToEdit) && has(formikProps.values, SHIPPING_LITHIUM_BATTERIES_KEY) && !formikProps.values[SHIPPING_LITHIUM_BATTERIES_KEY]) {
      const productNameKey = FORM_FIELDS.itemName.key;
      const search = itemToEdit[productNameKey];

      loadMaterials(search, productNameKey, true).then((data) => {
        if (has(data, `[0].${LITHIUM_BATTERY_CONTAINED_KEY}`)) {
          const item = get(data, `[0].${LITHIUM_BATTERY_CONTAINED_KEY}`);
          const formWeightKey = FORM_FIELDS_LITHIUM_BATTERY.weight.key;
          const noOfUnitsKey = FORM_FIELDS_LITHIUM_BATTERY.units.key;
          const parsedUnitValue = parseInt(get(formikProps.values, FORM_FIELDS.unitValue.key, ''), 10);

          const lithiumBatteryWeight = parseFloat(item[formWeightKey]);
          const lithiumBatteryUnits = parseFloat(item[noOfUnitsKey]);

          formikProps.setFieldValue(LITHIUM_BATTERY_CONTAINED_KEY, {
            ...data[0][LITHIUM_BATTERY_CONTAINED_KEY],
            [formWeightKey]: (parsedUnitValue * lithiumBatteryWeight).toString(),
            [noOfUnitsKey]: (parsedUnitValue + lithiumBatteryUnits).toString(),
          });

          setIsBatteryContained(true);
        } else {
          formikProps.setFieldValue(LITHIUM_BATTERY_CONTAINED_KEY, EMPTY_LITHIUM_BATTERY_CONTAINED_OR_PACKED_ITEM_OBJECT);

          setIsBatteryContained(false);
        }
      });
    }
  }, [formikProps.values[SHIPPING_LITHIUM_BATTERIES_KEY]]);

  return (
    <>
      <Grid item container spacing={1}>
        <Grid item xs={12}>
          <DebounceInput
            name={getLithiumBatteryFieldKey(fields[key].materialName.key)}
            label={fields[key].materialName.label}
            value={get(formikProps?.values, getLithiumBatteryFieldKey(fields[key].materialName.key), '')}
            minLength={SEARCH_INPUT_MIN_LENGTH}
            debounceTimeout={300}
            form={formikProps}
            field={{ name: getLithiumBatteryFieldKey(fields[key].materialName.key) }}
            dropdownList={filteredItemNameOptions}
            inputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon color="primary" />
                </InputAdornment>
              ),
              inputProps: { autocomplete: 'off' },
            }}
            element={CustomSearchWithDropdown}
            onSelect={handleProductChange}
            onChange={onSearch}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            name={getLithiumBatteryFieldKey(fields[key].units.key)}
            label={fields[key].units.label}
            component={NewOrderCustomTextField}
            blockValue={blockWeightAndDimensionInputs}
            type="text"
            style={{ width: '100%' }}
            disabled={!get(formikProps?.values, getLithiumBatteryFieldKey(fields[key].materialName.key), undefined)}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            name={getLithiumBatteryFieldKey(fields[key].unNo.key)}
            label={fields[key].unNo.label}
            component={NewOrderCustomTextField}
            type="text"
            style={{ width: '100%' }}
            disabled
          />
        </Grid>
        <Grid item xs={4}>
          <Field
            name={getLithiumBatteryFieldKey(fields[key].classDivision.key)}
            label={fields[key].classDivision.label}
            component={NewOrderCustomTextField}
            type="text"
            style={{ width: '100%' }}
            disabled
          />
        </Grid>
        <Grid item xs={4}>
          <Field
            name={getLithiumBatteryFieldKey(fields[key].packingGroup.key)}
            label={fields[key].packingGroup.label}
            component={NewOrderCustomTextField}
            type="text"
            style={{ width: '100%' }}
            disabled
          />
        </Grid>
        <Grid item xs={4}>
          <Field
            name={getLithiumBatteryFieldKey(fields[key].packingInstructions.key)}
            label={fields[key].packingInstructions.label}
            component={NewOrderCustomTextField}
            type="text"
            style={{ width: '100%' }}
            disabled
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            name={getLithiumBatteryFieldKey(fields[key].properShippingName.key)}
            label={fields[key].properShippingName.label}
            component={NewOrderCustomTextField}
            type="text"
            style={{ width: '100%' }}
            disabled
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            name={getLithiumBatteryFieldKey(fields[key].labels.key)}
            label={fields[key].labels.label}
            component={NewOrderCustomTextField}
            type="text"
            style={{ width: '100%' }}
            disabled
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            name={getLithiumBatteryFieldKey(fields[key].authorization.key)}
            label={fields[key].authorization.label}
            component={NewOrderCustomTextField}
            type="text"
            style={{ width: '100%' }}
            disabled
          />
        </Grid>
        <Grid className={classes.productWeightContainer} item xs={12}>
          <ProductWeight
            label="Weight"
            formikProps={formikProps}
            editableFields={newEditableFields}
            weightUnits={batteryWeightUnits.units}
            weight={{
              label: FORM_FIELDS_LITHIUM_BATTERY.weight.label,
              key: weightKey,
            }}
            weightUnit={{
              label: FORM_FIELDS_LITHIUM_BATTERY.weightUnit.label,
              key: weightUnitKey,
            }}
            showSmallWeightFields={false}
          />
        </Grid>
        <Grid className={classes.productWeightContainer} item xs={12}>
          <TransportationOptions
            itemKey={TRANSPORTATION_OPTIONS_NAME_KEY}
            formikProps={formikProps}
            options={TRANSPORTATION_OPTIONS_BATTERY_CONTAINED_OR_PACKED}
            isBatteryContained={isBatteryContained}
          />
        </Grid>
      </Grid>
    </>
  );
}

HazmatBatteriesContainedOrPacked.defaultProps = {
  filteredItemNameOptions: [],
  itemToEdit: null,
  isBatteryContained: false,
};

HazmatBatteriesContainedOrPacked.propTypes = {
  formikProps: PropTypes.objectOf(PropTypes.any).isRequired,
  fields: PropTypes.shape(PropTypes.object).isRequired,
  editableFields: PropTypes.arrayOf(PropTypes.string).isRequired,
  filteredItemNameOptions: PropTypes.arrayOf(PropTypes.any),
  itemToEdit: PropTypes.shape(PropTypes.object),
  isBatteryContained: PropTypes.bool,
  setIsBatteryContained: PropTypes.func.isRequired,
  setFilteredItemNameOptions: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
};

export default memo(HazmatBatteriesContainedOrPacked);
