import React from 'react';
import { useHistory } from 'react-router-dom';
import { css } from 'emotion';
import Dialog from '@material-ui/core/Dialog';
import PropTypes from 'prop-types';
import {
  add, setDefault,
} from '../utils/addressBookClient';
import {
  NEW_ORDER_MODULE_STYLE,
} from '../styles/style';
import { loadCountries, useMiscDispatch } from '../context/miscDataContext';
import {
  useUserState,
  useUserDispatch,
  loadUser,
} from '../context/userContext';
import { useAuth } from '../context/authContext';
import NewOrderErrorDialog from './common/NewOrderErrorDialog';

import DefaultAddress from './DefaultAddress';

const UserProfileDialog = ({ isOpened }) => {
  const history = useHistory();
  const [errorContent, setErrorContent] = React.useState(null);
  const userDispatch = useUserDispatch();
  const userState = useUserState();
  const { logout, isUserAuthenticated } = useAuth();
  const errorClasses = NEW_ORDER_MODULE_STYLE();

  const classes = {

    form: css({
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '100%',
      overflow: 'hidden',
    }),

    progress: css({
      marginTop: '15px',
    }),
    paperFullScreen: css({
      width: '100%',
      height: '85%',
      position: 'absolute',
      backgroundColor: '#1E202E',
      marginTop: '90px',
      padding: '2em',
    }),
  };
  const miscDispatch = useMiscDispatch();

  const clearedModalState = {
    type: '',
    addressId: '',
    values: '',
  };
  const [modalState, setModalState] = React.useState(clearedModalState);
  React.useEffect(() => {
    loadCountries(miscDispatch);
  }, [miscDispatch]);
  React.useEffect(() => {
    const newState = { type: '', addressId: userState.accountAddress?.addressId, values: userState.accountAddress };
    setModalState(() => ({
      ...newState,
      type: 'ADD',
    }));
  }, [userState]);
  const addDefaultAddress = async (contact) => {
    const payload = { ...contact };
    delete payload?.addressId;
    delete payload?.id;
    delete payload?.idNumberType;
    try {
      const newContactsData = await add(payload);
      let addressID;
      if (Array.isArray(newContactsData)) {
        addressID = newContactsData[newContactsData.length - 1].addressId;
      } else {
        addressID = newContactsData.addressId;
      }

      if (addressID) {
        await setDefault(addressID);
        loadUser(userDispatch);
        isUserAuthenticated();
      }
    } catch (e) {
      if (!e.message.includes('already exists')) {
        setErrorContent(e.message);
      } else {
        const addressId = JSON.parse(e.message)?.error.replace(/\D+/g, '');
        await setDefault(addressId);
        loadUser(userDispatch);
        isUserAuthenticated();
      }
    }
  };
  const handleLogout = () => {
    logout();
    history.push('/login');
  };

  return (
    <div className={classes.root}>
      <Dialog
        disablePortal
        scroll="paper"
        disableBackdropClick
        disableEscapeKeyDown
        onClose={history.goBack}
        open={isOpened}
        classes={{
          paper: classes.paperFullScreen,
        }}
      >
        <DefaultAddress
          type={modalState.type}
          addDefaultAddress={addDefaultAddress}
          onClickLogout={handleLogout}
          addressId={modalState.addressId}
          values={modalState.values}
        />
      </Dialog>
      <NewOrderErrorDialog
        open={!!errorContent}
        errorContent={errorContent}
        classes={errorClasses}
        onClose={() => {
          setErrorContent(null);
        }}
      />
    </div>
  );
};

export default UserProfileDialog;
