import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { Grid, Typography, MenuItem } from '@material-ui/core';
import { Field } from 'formik';
import NewOrderCustomTextField from '../../common/NewOrderCustomTextField';
import CustomSelectTextField from '../../common/CustomSelectTextField';
import {
  FORM_FIELDS,
  FORM_LABELS,
  WEIGHT_SYSTEM_MAPPING,
  IMPERIAL,
  METRIC,
  LB,
  KG,
  OZ,
  GRAM,
  LITERS,
  L,
  ML,
} from '../itemDetailsConstants';
import { useItemProductDetailsFormStyles } from '../itemDetailsStyles';
import { blockWeightAndDimensionInputs, isFieldEditable } from '../itemDetailsUtil';

const DEFAULT_WEIGHT_OBJ = FORM_FIELDS.weight;
const DEFAULT_WEIGHT_UNIT_OBJ = FORM_FIELDS.weightUnit;
const DEFAULT_SMALL_WEIGHT_OBJ = FORM_FIELDS.smallWeight;
const DEFAULT_SMALL_WEIGHT_UNIT_OBJ = FORM_FIELDS.weightUnitSmall;

export default function ProductWeight({
  label,
  editableFields,
  formikProps,
  weightUnits,
  smallWeightUnits,
  weight,
  weightUnit,
  smallWeight,
  smallWeightUnit,
  showSmallWeightFields,
  isNewChemical,
}) {
  const classes = useItemProductDetailsFormStyles();

  const weightObj = weight || DEFAULT_WEIGHT_OBJ;
  weightObj.value = get(formikProps.values, weightObj.key);

  const weightUnitObj = weightUnit || DEFAULT_WEIGHT_UNIT_OBJ;
  weightUnitObj.value = get(formikProps.values, weightUnitObj.key);

  const smallWeightObj = smallWeight || DEFAULT_SMALL_WEIGHT_OBJ;
  smallWeightObj.value = get(formikProps.values, smallWeightObj.key);

  const smallWeightUnitObj = showSmallWeightFields ? smallWeightUnit || DEFAULT_SMALL_WEIGHT_UNIT_OBJ : [];
  smallWeightUnitObj.value = get(formikProps.values, smallWeightUnitObj.key);

  const [weightSystem, setWeightSystem] = React.useState(WEIGHT_SYSTEM_MAPPING[weightUnitObj.value]);

  React.useEffect(() => {
    if (smallWeightObj.value === '' && showSmallWeightFields) {
      formikProps.setFieldValue(smallWeightObj.key, null);
    }
    if (weightObj.value === '') {
      formikProps.setFieldValue(weightObj.key, null);
    }
  }, [smallWeightObj.value, weightObj.value]);

  React.useEffect(() => {
    const newWeightSystem = WEIGHT_SYSTEM_MAPPING[weightUnitObj.value];
    const newSmallWeightSystem = WEIGHT_SYSTEM_MAPPING[smallWeightUnitObj.value];

    if (newWeightSystem !== weightSystem) {
      setWeightSystem(WEIGHT_SYSTEM_MAPPING[weightUnitObj.value]);
    }
    if (newSmallWeightSystem !== weightSystem) {
      setWeightSystem(WEIGHT_SYSTEM_MAPPING[smallWeightUnitObj.value]);
    }
  }, [weightUnitObj.value, smallWeightUnitObj.value]);

  React.useEffect(() => {
    const unitKey = weightUnitObj.key;
    const smallUnitKey = smallWeightUnitObj.key;

    switch (weightSystem) {
      case IMPERIAL:
        formikProps.setFieldValue(unitKey, LB);
        if (showSmallWeightFields) formikProps.setFieldValue(smallUnitKey, OZ);
        break;
      case METRIC:
        formikProps.setFieldValue(unitKey, KG);
        if (showSmallWeightFields) formikProps.setFieldValue(smallUnitKey, GRAM);
        break;
      case LITERS:
        formikProps.setFieldValue(unitKey, L);
        if (showSmallWeightFields) formikProps.setFieldValue(smallUnitKey, ML);
        break;
      default:
        break;
    }
  }, [weightSystem]);

  return (
    <Grid item container direction="column" spacing={1}>
      <Grid item>
        <Typography color="primary" className={classes.formFieldHeader}>
          {label || FORM_LABELS.productWeight}
        </Typography>
      </Grid>
      <Grid item container spacing={1}>
        <Grid item xs={4}>
          <Field
            name={weightObj.key}
            label={weightObj.label}
            component={NewOrderCustomTextField}
            blockValue={blockWeightAndDimensionInputs}
            type="text"
            style={{ width: '100%' }}
            autoComplete="off"
            disabled={!isNewChemical && isFieldEditable(weightObj.key, editableFields)}
          />
        </Grid>
        <Grid item xs={2}>
          <Field
            name={weightUnitObj.key}
            label={!isEmpty(weightUnitObj.value) ? '' : weightUnitObj.label}
            component={CustomSelectTextField}
            type="text"
            style={{ width: '100%' }}
            disabled={!isNewChemical && isFieldEditable(weightUnitObj.key, editableFields)}
          >
            {weightUnits.map((option) => (
              <MenuItem
                key={option.weightCode}
                value={option.weightCode}
              >
                {option.weightCode}
              </MenuItem>
            ))}
          </Field>
        </Grid>
        {showSmallWeightFields && (
          <>
            <Grid item xs={4}>
              <Field
                name={smallWeightObj.key}
                label={smallWeightObj.label}
                component={NewOrderCustomTextField}
                blockValue={blockWeightAndDimensionInputs}
                type="text"
                style={{ width: '100%' }}
                autoComplete="off"
                disabled={!isNewChemical && isFieldEditable(smallWeightObj.key, editableFields)}
              />
            </Grid>
            <Grid item xs={2}>
              <Field
                name={smallWeightUnitObj.key}
                label={!isEmpty(weightUnitObj.value) ? '' : smallWeightUnitObj.label}
                component={CustomSelectTextField}
                type="text"
                style={{ width: '100%' }}
                disabled={!isNewChemical && isFieldEditable(smallWeightUnitObj.key, editableFields)}
              >
                {smallWeightUnits.map((option) => (
                  <MenuItem
                    key={option.weightCode}
                    value={option.weightCode}
                  >
                    {option.weightCode}
                  </MenuItem>
                ))}
              </Field>
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
}

ProductWeight.defaultProps = {
  label: '',
  weight: null,
  weightUnit: null,
  smallWeight: null,
  smallWeightUnit: null,
  showSmallWeightFields: true,
  isNewChemical: false,
};

ProductWeight.propTypes = {
  label: PropTypes.string,
  editableFields: PropTypes.arrayOf(PropTypes.string).isRequired,
  formikProps: PropTypes.objectOf(PropTypes.any).isRequired,
  weightUnits: PropTypes.arrayOf(PropTypes.any).isRequired,
  smallWeightUnits: PropTypes.arrayOf(PropTypes.any).isRequired,
  weight: PropTypes.shape({
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }),
  weightUnit: PropTypes.shape({
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }),
  smallWeight: PropTypes.shape({
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }),
  smallWeightUnit: PropTypes.shape({
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }),
  showSmallWeightFields: PropTypes.bool,
  isNewChemical: PropTypes.bool,
};
