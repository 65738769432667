import React from 'react';
import isArray from 'lodash/isArray';
import isNaN from 'lodash/isNaN';
import get from 'lodash/get';
import {
  Grid,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  IconButton,
} from '@material-ui/core';
import moment from 'moment';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import PrintIcon from '@material-ui/icons/PrintOutlined';
import DisplayItems from '../ItemProductDetails/DisplayItems';
import BillingCharges from '../BillingPayment/BillingCharges';
import * as colors from '../../styles/colors';
import {
  formatDateLong,
  formatMonetaryAmount,
  isESGAdmin,
} from '../../utils/helpers';
import { usePaymentState } from '../../context/paymentContext';
import { getPaymentReceipt } from '../../utils/paymentClient';
import { printPDF, base64ToArrayBuffer } from '../../utils/documentsUtil';
import DocumentPreview from './DocumentPreview';
import { useSingleOrderState } from '../../context/singleOrderContext';
import {
  loadShipmentFreightTypes,
  loadTermsOfSale,
  loadPartyTerms,
  loadShipmentPurposeOptions,
  loadLicensePermitTypes,
  useMiscDispatch,
  useMiscState,
} from '../../context/miscDataContext';
import ProductPaneTable from '../ProductPaneTable';
import { useUserState } from '../../context/userContext';
import { HAZMAT } from '../../clientConstants';
import CustomFreightSummary from '../CustomFreightOptions/Summary';
import ShowMore from './ShowMore';

const iotIcon = require('../images/iot.svg');

const styles = makeStyles((theme) => ({
  tableWrap: {
    width: '100%',
  },
  table: {
    border: 'none',
  },
  tableCell: {
    border: 'none',
    padding: '0 0 0 0 ',
    width: 'auto',
  },
  tableCellHeaderFooter: {
    border: 'none',
    padding: '0 0 0 0 ',
    width: '100%',
    display: 'flex',
  },
  tableTrans: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 'auto',
    flexGrow: '2',
    overflow: 'hidden',
  },
  tableCellText: {
    color: '#b5b8cc',
    fontSize: 14,
  },
  tableHead: {
    width: '100%',
    display: 'flex',
  },
  tableHeadText: {
    color: colors.white,
    fontWeight: 500,
    fontSize: 16,
    width: '100%',
  },
  tableRow: {
    width: '100%',
  },
  row: {
    display: 'flex',
    justifyContent: 'center',
    height: 40,
  },
  addressBlock: {
    color: colors.textLightGrey,
    minHeight: 23,
    fontSize: 14,
  },
  tableBody: {
    position: 'relative',
    height: '175px',
  },
  cell: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    padding: '0px',
    border: 'none',
    // borderBottom: `1px solid ${colors.tableGreyBorder}`,
  },
  addressList: {
    paddingTop: '10px',
    paddingBottom: '5px',
    borderBottom: '1px solid #3b3f51',
  },
}));

const transactionColumns = [
  {
    key: 'transactionDate',
    header: 'Transaction Date',
    width: '40%',
  },
  {
    key: 'transactionAmount',
    header: 'Amount',
    width: '30%',
  },
  {
    key: 'paymentReceipt',
    header: 'Transaction ID',
    width: '30%',
  },
];
const getValFromPath = (obj, keys) =>
  typeof keys === 'object'
    ? keys.map((path) =>
        path
          .split('.')
          .reduce((curObj, key) => (curObj ? curObj[key] : curObj), obj),
      )
    : keys
        .split('.')
        .reduce((curObj, key) => (curObj ? curObj[key] : curObj), obj);

const BillingAddressDisplay = ({ paymentToken, billingAddress }) => {
  const { savedPaymentMethods } = usePaymentState();
  const [billingAddressDisplay, setBillingAddressDisplay] =
    React.useState(null);

  React.useEffect(() => {
    try {
      const creditBillingAddress =
        savedPaymentMethods?.creditCards?.find(
          (c) => c.paymentToken === paymentToken,
        )?.billingAddress || billingAddress;
      if (creditBillingAddress) {
        setBillingAddressDisplay(creditBillingAddress);
        return;
      }

      const bankBillingAddress =
        savedPaymentMethods?.bankAccounts?.find(
          (c) => c.paymentToken === paymentToken,
        )?.billingAddress || billingAddress;
      if (bankBillingAddress) {
        setBillingAddressDisplay(bankBillingAddress);
        return;
      }

      if (billingAddress) {
        setBillingAddressDisplay(billingAddress);
      }
    } catch (ex) {
      console.log(ex);
    }
  }, [paymentToken, savedPaymentMethods]);

  return billingAddressDisplay ? (
    <div>
      <div style={{color:'white',fontSize:16 , paddingBottom:5,fontWeight:500}}> Billing Address</div>
      <div>{billingAddressDisplay.addressLine1}</div>
      <div>
        {[
          billingAddressDisplay.city,
          billingAddressDisplay.state,
          billingAddressDisplay.zip,
          billingAddressDisplay.country,
        ]
          .filter((val) => val)
          .join(', ')}
      </div>
    </div>
  ) : null;
};

BillingAddressDisplay.propTypes = {
  paymentToken: PropTypes.string,
  billingAddress: PropTypes.objectOf(PropTypes.any),
};

BillingAddressDisplay.defaultProps = {
  paymentToken: null,
  billingAddress: null,
};

const PaymentDetailsDisplay = ({
  paymentToken,
  creditCardDetails,
  bankAccountDetails,
}) => {
  const { savedPaymentMethods } = usePaymentState();
  const [lastFour, setLastFour] = React.useState(null);

  React.useEffect(() => {
    try {
      const { lastFour: creditCardLastFour } =
        savedPaymentMethods?.creditCards?.find(
          (c) => c.paymentToken === paymentToken,
        )?.creditCardDetails ||
        creditCardDetails ||
        {};
      if (lastFour) {
        setLastFour(`Card ending in - ${creditCardLastFour}`);
        return;
      }

      const { bankName, lastFour: bankLastFour } =
        savedPaymentMethods?.bankAccounts?.find(
          (c) => c.paymentToken === paymentToken,
        )?.bankAccountDetails ||
        bankAccountDetails ||
        {};
      if (bankName && bankLastFour) {
        setLastFour(`${bankName} account ending in ${bankLastFour}`);
        return;
      }

      setLastFour(bankLastFour);
    } catch (ex) {
      console.log(ex);
    }
  }, [paymentToken, savedPaymentMethods]);

  return lastFour ? (
    <span style={{ textTransform: 'none' }}>{lastFour}</span>
  ) : null;
};

PaymentDetailsDisplay.propTypes = {
  paymentToken: PropTypes.string,
  creditCardDetails: PropTypes.objectOf(PropTypes.any),
  bankAccountDetails: PropTypes.objectOf(PropTypes.any),
};

PaymentDetailsDisplay.defaultProps = {
  paymentToken: null,
  creditCardDetails: null,
  bankAccountDetails: null,
};

export default function PaneDetailsSummary(props) {
  const { shipment, formName } = props;
  const packagingLength = getValFromPath(
    shipment,
    'packaging.details.packages.length',
  );
  const billCodes = getValFromPath(shipment, 'billing.details.billCodes') || [];
  const classes = styles();

  const panel = (
    shipment?.navigation?.panels ||
    shipment?.navigation ||
    []
  ).find(({ name }) => name === formName);
  const hiddenFields = (panel?.hideFields || []).map(
    ({ fieldName }) => fieldName,
  );

  const { userType, training } = useUserState();
  const { order } = useSingleOrderState();
  const {
    shipmentFreightTypes,
    shipmentTermsOfSale,
    shipmentPurposeOptions,
    licensePermitTypes,
  } = useMiscState();
  const miscDispatch = useMiscDispatch();
  const orderFreightType = order.freightType;
  const orderHazmatOption =
    orderFreightType === HAZMAT ? order.hazmatOption : '';

  React.useEffect(() => {
    orderFreightType === 'freight' && loadShipmentFreightTypes(miscDispatch);
    loadShipmentPurposeOptions(miscDispatch);
    loadLicensePermitTypes(miscDispatch);
    loadTermsOfSale(miscDispatch);
    loadPartyTerms(miscDispatch);
  }, [miscDispatch]);

  const getComplianceLabel = (type, options) => {
    const label = (options || []).find((obj) => obj.value === type);
    return label ? label.label : type;
  };
  function AddressBlock(props) {
    const {
      classes,
      name,
      addressLine1,
      addressLine2,
      addressLine3,
      city,
      state,
      zip,
      country,
      email,
      phone,
    } = props;
    return (
      <Grid
        container
        item
        className={classes.addressList}
        direction='row'
        justify='space-between'>
        <Grid item>
          <Typography className={classes.addressBlock}>
            <b
              style={{
                color: 'white',
              }}>
              {name}
            </b>
          </Typography>
          <Typography className={classes.addressBlock}>
            {` ${addressLine1}${addressLine2 ? `, ${addressLine2}` : ''}${
              addressLine3 ? `, ${addressLine3}` : ''
            } `}
          </Typography>
          <Typography className={classes.addressBlock}>
            {`${city}, ${state} ${zip} ${country} `}
          </Typography>
        </Grid>
      </Grid>
    );
  }
  const AddressSummary = (addresses) => (
    <Grid className={classes.container}>
      <ShowMore buttonGridJustify='flex-start'>
        {addresses &&
          addresses.length > 0 &&
          addresses.map(
            ({
              addressId,
              name,
              email,
              phone,
              addressLine1,
              addressLine2,
              addressLine3,
              city,
              state,
              zip,
              country,
            }) => (
              <>
                <AddressBlock
                  key={addressId}
                  addressId={addressId}
                  classes={classes}
                  name={name}
                  email={email}
                  phone={phone}
                  addressLine1={addressLine1}
                  addressLine2={addressLine2}
                  addressLine3={addressLine3}
                  city={city}
                  state={state}
                  zip={zip}
                  country={country}
                />
              </>
            ),
          )}
      </ShowMore>
    </Grid>
  );
  const formNameToDetails = (account) => ({
    sender: [
      {
        path: 'origin.address',
        details: [
          {
            keys: ['addressLine1', 'addressLine2', 'addressLine3'],
            transform: (addressLine1, addressLine2, addressLine3) =>
              [addressLine1, addressLine2, addressLine3]
                .filter((val) => val)
                .join(', '),
          },
          {
            keys: ['city', 'state', 'zip', 'country'],
            transform: (city, state, zip, country) =>
              [city, state, zip, country].filter((val) => val).join(', '),
          },
        ],
        tableHead: {
          keys: ['name'],
        },
      },
      {
        path: '',
        details: [],
        tableHead: {
          keys: ['pickupDropoff', 'pickupFrom'],
          transform: (pd, pf) => {
            let header;
            if (pd === 'pickup') {
              if (pf.type === 'sender') {
                header = "Pickup package(s) from sender's address";
              } else {
                header = 'Pickup package(s) from alternate location';
              }
            } else {
              header = 'Package(s) will be dropped off at a shipping station';
            }
            return <span style={{ fontSize: 14 }}>{header}</span>;
          },
        },
        tableStyles: {
          marginTop: 10,
        },
      },
      {
        path: 'youve.got.a.friend.in',
        details: [
          {
            keys: ['me'],
            transform: (me) => me || null,
          },
        ],
      },
    ],
    recipient: [
      {
        path: 'destination.address',
        details: [
          {
            keys: ['addressLine1', 'addressLine2', 'addressLine3'],
            transform: (addressLine1, addressLine2, addressLine3) =>
              [addressLine1, addressLine2, addressLine3]
                .filter((val) => val)
                .join(', '),
          },
          {
            keys: ['city', 'state', 'zip', 'country'],
            transform: (city, state, zip, country) =>
              [city, state, zip, country].filter((val) => val).join(', '),
          },
        ],
        tableHead: {
          keys: ['name'],
        },
      },
      {
        path: 'destination',
        details: [
          {
            keys: ['addresses'],
            transform: AddressSummary,
          },
        ],
        tableHead: {
          keys: ['name'],
        },
      },
    ],
    customsBroker: [
      {
        path: 'customsBroker',
        details: [
          {
            keys: ['isCustomsBrokerAvailable'],
            transform: (isCustomsBrokerAvailable) => {
              switch (isCustomsBrokerAvailable) {
                case true:
                  return 'Yes';
                case false:
                  return 'No';
                default:
                  return '';
              }
            },
          },
        ],
        tableHead: {
          keys: ['isCustomsBrokerAvailable'],
          transform: (isCustomsBrokerAvailable) => 'Customs Broker Available',
        },
      },
      {
        path: 'customsBroker.address',
        details: [
          {
            keys: ['isCustomsBrokerAvailable'],
            transform: (isCustomsBrokerAvailable) => {
              switch (isCustomsBrokerAvailable) {
                case true:
                  return 'Yes';
                case false:
                  return 'No';
                default:
                  return '';
              }
            },
          },
          {
            keys: ['addressLine1', 'addressLine2', 'addressLine3'],
            transform: (addressLine1, addressLine2, addressLine3) =>
              [addressLine1, addressLine2, addressLine3]
                .filter((val) => val)
                .join(', '),
          },
          {
            keys: ['city', 'state', 'zip', 'country'],
            transform: (city, state, zip, country) =>
              [city, state, zip, country].filter((val) => val).join(', '),
          },
        ],
        tableHead: {
          keys: ['name'],
        },
      },
    ],
    product:
      account === 'worldemblem'
        ? [
            {
              path: 'product.details',
              details: [
                {
                  keys: ['items'],
                  transform: (items) => (
                    <ProductPaneTable
                      rows={items}
                      hiddenFields={hiddenFields}
                    />
                  ),
                },
              ],
            },
          ]
        : [
            {
              path: 'product.details',
              details: [
                {
                  keys: ['items'],
                  transform: (items) => (
                    <DisplayItems
                      items={items}
                      freightType={orderFreightType}
                      hazmatOption={orderHazmatOption}
                      training={training}
                      hiddenFields={hiddenFields}
                    />
                  ),
                },
              ],
            },
          ],
    aesFiling: [
      {
        path: 'aesFiling.details',
        details: [
          {
            keys: ['itnNumber'],
            transform: (itnNumber) => itnNumber,
          },
        ],
        tableHead: {
          keys: ['itnNumber'],
          transform: (itnNumber) => (itnNumber ? 'ITN#' : ''),
        },
      },
      {
        path: 'aesFiling.details',
        details: [
          {
            keys: ['ftsrCode'],
            transform: (ftsrCode) => ftsrCode,
          },
        ],
        tableHead: {
          keys: ['ftsrCode'],
          transform: (ftsrCode) => (ftsrCode ? 'FTSR Exemption Code' : ''),
        },
      },
    ],
    exportCompliance: [
      {
        path: 'exportCompliance.details',
        details: [
          {
            keys: ['trainingCheck'],
            transform: ({
              // eslint-disable-next-line react/prop-types
              products,
            }) => {
              const description = ({ name, label }) => (
                <Grid key={name} container item spacing={1}>
                  <Grid item>
                    <Typography
                      style={{ color: colors.failedRed }}
                      className={classes.tableCellText}>
                      {name}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      style={{ color: colors.failedRed }}
                      className={classes.tableCellText}>
                      {label}
                    </Typography>
                  </Grid>
                </Grid>
              );

              return (
                <Grid container direction='column'>
                  {products.map((val, i) =>
                    description({
                      name: val.shipClass,
                      label: val.notes.join('. '),
                    }),
                  )}
                </Grid>
              );
            },
          },
        ],
        tableHead: {
          keys: ['trainingCheck'],
          transform: (trainingCheck) =>
            trainingCheck ? 'Required Training Determination' : '',
        },
      },
    ],
    compliance: [
      // {
      //   path: 'compliance.details',
      //   details: [
      //     {
      //       keys: ['licenseType', 'specialCriteria'],
      //       transform: (licenseType, specialCriteria) => ((specialCriteria || licenseType) ? `${specialCriteria || getComplianceLabel(licenseType, licensePermitTypes)}` : ''),
      //     },
      //   ],
      //   tableHead: {

      //     keys: ['licenseType', 'specialCriteria'],
      //     transform: (licenseType, specialCriteria) => ((specialCriteria || licenseType) ? 'License Type' : ''),

      //   },
      // },
      {
        path: 'compliance.details',
        details: [
          {
            keys: ['termsOfSale'],
            transform: ({
              type,
              description,
              billing,
              billingCustoms,
              billingTaxes,
            }) => {
              const charge = ({ label, termsOfSaleUserType, expiresAt }) =>
                termsOfSaleUserType && (
                  <Grid container item spacing={1}>
                    <Grid item>
                      <Typography className={classes.tableCellText}>
                        {label}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography className={classes.tableCellText}>
                        {termsOfSaleUserType}
                      </Typography>
                    </Grid>
                    {expiresAt && (
                      <>
                        <Grid item>
                          <Typography className={classes.tableCellText}>
                            Due Date:
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography className={classes.tableCellText}>
                            {moment(expiresAt).format('YYYY-MM-DD')}
                          </Typography>
                        </Grid>
                      </>
                    )}
                  </Grid>
                );

              return (
                <Grid container direction='column'>
                  <Grid item>
                    <Typography className={classes.tableCellText}>
                      {description || type}
                    </Typography>
                  </Grid>
                  {billing && charge({ label: 'Freight Charges:', ...billing })}
                  {billingCustoms &&
                    charge({ label: 'Customs Duties:', ...billingCustoms })}
                  {billingTaxes && charge({ label: 'Taxes:', ...billingTaxes })}
                </Grid>
              );
            },
          },
        ],
        tableHead: {
          keys: ['termsOfSale'],
          transform: (termsOfSale) => (termsOfSale ? 'Terms of Sale' : ''),
        },
      },
    ],
    packaging: packagingLength
      ? new Array(packagingLength)
          .fill(null)
          .map((_, idx) => ({
            path: `packaging.details.packages.${idx}`,
            details: [
              {
                keys: ['items'],
                transform: (items = []) => (
                  <Grid item container direction='column'>
                    {items.map((item) => (
                      <Grid item>
                        {item?.units?.noOfUnits} {item?.itemName}
                      </Grid>
                    ))}
                  </Grid>
                ),
              },
              {
                keys: [
                  'packagingType',
                  'dimensions.height',
                  'dimensions.width',
                  'dimensions.length',
                  'dimensions.unitOfMeasurement',
                ],
                transform: (type, height, width, length, uom) =>
                  height && width && length
                    ? `${type}: ${length} x ${width} x ${height} ${uom}`
                    : 'N/A',
              },

              {
                keys: [
                  'packageWeight.weight',
                  'packageWeight.unitOfMeasurement',
                ],
                transform: (weight, unit) =>
                  weight ? `Package Weight: ${weight} ${unit}` : 'Package Weight: N/A',
              },
              {
                keys: [
                  'actualWeight.weight',
                  'actualWeight.unitOfMeasurement',
                ],
                transform: (weight, unit) =>
                  weight ? `Actual Weight: ${weight} ${unit}` : '',
              },
              ...(!hiddenFields.includes('declaredValue')
                ? [
                    {
                      keys: ['declaredValue.amount', 'declaredValue.currency'],
                      transform: (amount, currency) =>
                        `Declared value: ${formatMonetaryAmount(
                          currency,
                          amount,
                        )}`,
                    },
                  ]
                : []),
              ...(!hiddenFields.includes('insuredValue')
                ? [
                    {
                      keys: ['insuredValue.amount', 'insuredValue.currency'],
                      transform: (amount, currency) =>
                        `Insurance value: ${formatMonetaryAmount(
                          currency,
                          amount,
                        )}`,
                    },
                  ]
                : []),
              ...(!hiddenFields.includes('customsValue')
                ? [
                    {
                      keys: ['customsValue.amount', 'customsValue.currency'],
                      transform: (amount, currency) =>
                        `Customs value: ${formatMonetaryAmount(
                          currency,
                          amount,
                        )}`,
                    },
                  ]
                : []),
              {
                keys: ['isRefrigerant', 'refrigerantType', 'dryIceWeight'],
                transform: (isRefrigerant, refrigerantType, dryIceWeight) =>
                  isRefrigerant
                    ? `Refrigerant: ${refrigerantType}, ${dryIceWeight.weight} ${dryIceWeight.unitOfMeasurement}`
                    : '',
              },
              {
                keys: ['isDangerousGoods', 'dangerousGoodsType'],
                transform: (isDangerousGoods, dangerousGoodsType) =>
                  isDangerousGoods
                    ? `${dangerousGoodsType} Dangerous Good`
                    : '',
              },
              {
                keys: ['nonRMSContents'],
              },
              {
                keys: ['isOverpack'],
                transform: (isOverpack) => (isOverpack ? 'Overpack' : ''),
              },
            ],
            tableHead: {
              keys: ['iot'],
              transform: (iot) => (
                <span
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}>
                  Package {idx + 1}
                  &nbsp; &nbsp;
                  {iot && iot.deviceId && iot.vendorName && (
                    <img src={iotIcon} style={{ height: '1em' }} />
                  )}
                </span>
              ),
            },
          }))
          .concat([
            {
              path: 'packaging.details',
              details: [
                {
                  keys: ['purpose', 'otherPurpose'],
                  transform: (purpose, otherPurpose) =>
                    otherPurpose ||
                    getComplianceLabel(purpose, shipmentPurposeOptions),
                },
              ],
              tableHead: {
                keys: ['purpose', 'otherPurpose'],
                transform: (purpose, otherPurpose) =>
                  otherPurpose || purpose ? 'Purpose' : '',
              },
            },
            ...(!hiddenFields.includes('shipmentFreightType')
              ? [
                  {
                    path: 'packaging.details',
                    details: [
                      {
                        keys: ['shipmentFreightType'],
                        transform: (shipmentFreightType) =>
                          shipmentFreightType
                            ? `${getComplianceLabel(
                                shipmentFreightType,
                                shipmentFreightTypes,
                              )}`
                            : '',
                      },
                    ],
                    tableHead: {
                      keys: ['shipmentFreightType'],
                      transform: (shipmentFreightType) =>
                        shipmentFreightType ? 'Freight Type' : '',
                    },
                  },
                ]
              : []),
            {
              path: 'packaging.details',
              details: [],
              tableHead: {
                keys: ['applyToAllPackages'],
                transform: (applyToAllPackages) =>
                  applyToAllPackages ? (
                    <span style={{ fontSize: 14 }}>
                      All shipments in this order will consist of identical sets
                      of packages
                    </span>
                  ) : null,
              },
              tableStyles: { marginTop: 10 },
            },
          ])
      : [],
    carrier: [
      {
        path: 'carrier.details',
        tableHead: {
          keys: ['carrierName', 'serviceName'],
          transform: (carrierName, serviceName) =>
            `${(carrierName || '').toUpperCase()} ${serviceName || ''}`,
        },
        tableFooter: {
          keys: ['expectedDeliveryDateTime'],
          transform: (expectedDeliveryDateTime) =>
            !isNaN(Date.parse(expectedDeliveryDateTime))
              ? formatDateLong(expectedDeliveryDateTime)
              : null,
        },
        details: [],
        tableStyles: {
          marginTop: 20,
        },
      },
      {
        path: '',
        details: [
          {
            keys: ['carrier.details', 'carrier.task'],
            transform: (details, task) => {
              const services = get(details, 'freightServices', []);
              const esgAdmin = isESGAdmin(userType);
              const taskStatus = get(task, 'status', '');

              return services && esgAdmin && taskStatus === 'Completed' ? (
                <CustomFreightSummary
                  items={isArray(services) ? services : [services]}
                />
              ) : null;
            },
          },
        ],
      },
      {
        path: 'carrier.details',
        details: [
          {
            keys: ['itemizedCharges', 'currencyCode'],
            transform: (charges, currencyCode) => (
              <Grid style={{ width: '40%' }} container>
                <ShowMore>
                  {charges?.map((charge) => (
                    <Grid
                      key={`itimized-charge-${charge.currencyCode}-${charge.description}`}
                      style={{
                        justifyContent: 'space-between',
                        padding: '4px 0',
                      }}
                      item
                      container>
                      <span>{charge.description}</span>
                      <span>
                        {formatMonetaryAmount(currencyCode, charge.price)}
                      </span>
                    </Grid>
                  ))}
                </ShowMore>
              </Grid>
            ),
          },
        ],
        tableHead: 'Cost',
        tableFooter: {
          keys: ['myPrice', 'currencyCode'],
          transform: (myPrice, currencyCode) => (
            <div
              style={{
                width: '40%',
                display: 'flex',
                justifyContent: 'space-between',
                padding: '4px 0',
                borderTop: '1px solid #202335',
              }}>
              <span>Estimated Total</span>
              <span>{formatMonetaryAmount(currencyCode, myPrice)}</span>
            </div>
          ),
        },
        tableStyles: {
          marginTop: 40,
        },
      },
    ],
    billing: [
      {
        path: 'billing.details',
        details: [
          {
            keys: ['amount', 'billingCharges', 'transaction.currencyCode'],
            transform: (amount, billingCharges, currencyCode) => (
              <BillingCharges
                charges={billingCharges}
                total={amount}
                currencyCode={currencyCode}
              />
            ),
          },
        ],
        tableStyles: {
          marginTop: 20,
        },
      },
      {
        path: '',
        tableHead: {
          keys: ['billing.details.transactions'],
          transform: (transactions) =>
            transactions?.length > 0 && 'Transaction Details',
        },
        details: [
          {
            keys: ['billing.details.transactions', 'shipmentId'],
            transform: (transactions, shipmentId) => {
              // eslint-disable-next-line no-shadow
              function TransactionIdDisplay(props) {
                // eslint-disable-next-line react/prop-types, no-shadow
                const { transactions, shipmentId } = props;
                const [preview, setPreview] = React.useState(null);

                return transactions?.length > 0 ? (
                  <>
                    <Grid
                      container
                      item
                      style={{
                        marginTop: 20,
                        paddingLeft: 36,
                        paddingRight: 36,
                        backgroundColor: colors.darkBlueBackground,
                      }}
                      className={`${classes.innerContainer} ${classes.tableContainer}`}>
                      <Table className={classes.table}>
                        <TableHead>
                          <TableRow className={classes.row}>
                            {transactionColumns.map((col) => (
                              <TableCell
                                className={`${classes.cell} ${classes.tableCellHeader}`}
                                style={{
                                  width: col.width,
                                  borderBottom: `1px solid ${colors.textLightGrey}`,
                                }}>
                                <Typography
                                  style={{ color: colors.textLightGrey }}>
                                  {col.header}
                                </Typography>
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody className={classes.tableBody}>
                          {transactions.map((row) => (
                            <TableRow className={classes.row} key={row.key}>
                              {transactionColumns.map((col) => {
                                return col.key !== 'paymentReceipt' ? (
                                  <TableCell
                                    className={classes.cell}
                                    style={{ width: col.width }}>
                                    <Typography
                                      className={classes.tableCellBody}
                                      style={{ fontSize: 14 }}
                                      >
                                      {col.key === 'transactionDate'
                                        ? formatDateLong(row[col.key])
                                        : col.key === 'transactionAmount'
                                        ? `$${row[col.key]}`
                                        : row[col.key]}
                                    </Typography>
                                  </TableCell>
                                ) : (
                                  <TableCell
                                    className={classes.cell}
                                    style={{ width: col.width }}>
                                    <Grid container direction='row'>
                                      <Grid item>
                                        <Button
                                          style={{
                                            padding: 0,
                                            color: colors.lightBlue,
                                          }}
                                          onClick={async () => {
                                            try {
                                              const receipt =
                                                await getPaymentReceipt(
                                                  shipmentId,
                                                  row.transactionId,
                                                );
                                              if (!receipt) return;
                                              setPreview(receipt);
                                            } catch (error) {
                                              console.error(error);
                                            }
                                          }}
                                          disableFocusRipple
                                          disableRipple
                                          disableTouchRipple>
                                          <span
                                            style={{
                                              fontWeight: '500',
                                              textDecoration: 'underline',
                                              textTransform: 'none ',
                                            }}>
                                            {`# ${row.transactionId}`}
                                          </span>
                                        </Button>
                                      </Grid>
                                      <Grid item>
                                        <IconButton
                                          style={{
                                            padding: 0,
                                            color: colors.lightBlue,
                                          }}
                                          onClick={async () => {
                                            try {
                                              const receipt =
                                                await getPaymentReceipt(
                                                  shipmentId,
                                                  row.transactionId,
                                                );
                                              if (!receipt) return;
                                              const arrayBuffer =
                                                base64ToArrayBuffer(receipt);
                                              printPDF(arrayBuffer);
                                            } catch (error) {
                                              console.error(error);
                                            }
                                          }}>
                                          <PrintIcon
                                            style={{ height: '.875em' }}
                                          />
                                        </IconButton>
                                      </Grid>
                                    </Grid>
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          ))}
                          <TableRow
                          className={classes.row} 
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-start',
                            }}>
                            <TableCell
                              className={classes.cell}
                              style={{ width: '40%' }}>
                              <Typography
                                className={classes.tableCellBody}
                                style={{
                                  fontSize: 18,
                                  fontWeight: 400,
                                  color: 'white',
                                  paddingTop: 36,
                                  paddingBottom: 5,
                                }}>
                               { `Total Charged : $${transactions
                                  .reduce(
                                    (sum, val) =>
                                      sum + parseFloat(val.transactionAmount),
                                    0.0,
                                  )
                                  .toFixed(2)}`}
                              </Typography>
                            </TableCell>
                            <TableCell
                              className={classes.cell}
                              style={{ width: '30%' }}>
                              <Typography
                                className={classes.tableCellBody}
                                style={{
                                  fontSize: 18,
                                  fontWeight: 400,
                                  color: 'white',
                                  paddingTop: 15,
                                  paddingBottom: 5,
                                }}>
                               
                              </Typography>
                            </TableCell>
                            <TableCell
                              className={classes.cell}
                              style={{ width: '30%' }}>
                              <Typography
                                className={classes.tableCellBody}
                                style={{
                                  fontSize: 18,
                                  fontWeight: 400,
                                  color: 'white',
                                  paddingLeft: 20,
                                  paddingTop: 5,
                                  paddingBottom: 5,
                                }}>
                                
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Grid>
                    <DocumentPreview
                      open={!!preview}
                      doc={preview}
                      onClose={() => setPreview(null)}
                    />
                  </>
                ) : null;
              }

              return (
                <TransactionIdDisplay
                  transactions={transactions}
                  shipmentId={shipmentId}
                />
              );
            },
          },
          {
            keys: [
              'billing.details.creditCardDetails',
              'billing.details.paymentToken',
              'billing.details.bankAccountDetails',
            ],
            transform: (
              creditCardDetails,
              paymentToken,
              bankAccountDetails,
            ) => (
              <PaymentDetailsDisplay
                bankAccountDetails={bankAccountDetails}
                creditCardDetails={creditCardDetails}
                paymentToken={paymentToken}
              />
            ),
          },
        ],
        tableStyles: {
          marginTop: 20,
        },
      },

      {
        path: '',
        tableHead: {
          keys: ['billing.details.transaction'],
          transform: (transaction) => transaction && 'Transaction Details',
        },
        details: [
          {
            keys: ['billing.details.transaction.transactionId', 'shipmentId'],
            transform: (transactionId, shipmentId) => {
              // eslint-disable-next-line no-shadow
              function TransactionIdDisplay(props) {
                // eslint-disable-next-line react/prop-types, no-shadow
                const { transactionId, shipmentId } = props;
                const [preview, setPreview] = React.useState(null);

                return transactionId ? (
                  <>
                    <Grid container spacing={1} alignItems='center'>
                      <Grid item>
                        <Button
                          style={{ padding: 0, color: colors.lightBlue }}
                          onClick={async () => {
                            try {
                              const receipt = await getPaymentReceipt(
                                shipmentId,
                                transactionId,
                              );
                              if (!receipt) return;
                              setPreview(receipt);
                            } catch (error) {
                              console.error(error);
                            }
                          }}
                          disableFocusRipple
                          disableRipple
                          disableTouchRipple>
                          <span
                            style={{
                              fontWeight: '500',
                              textDecoration: 'underline',
                              textTransform: 'none ',
                            }}>
                            {`# ${transactionId}`}
                          </span>
                        </Button>
                      </Grid>
                      <Grid item>
                        <IconButton
                          style={{ padding: 0, color: colors.lightBlue }}
                          onClick={async () => {
                            try {
                              const receipt = await getPaymentReceipt(
                                shipmentId,
                                transactionId,
                              );
                              if (!receipt) return;
                              const arrayBuffer = base64ToArrayBuffer(receipt);
                              printPDF(arrayBuffer);
                            } catch (error) {
                              console.error(error);
                            }
                          }}>
                          <PrintIcon style={{ height: '.875em' }} />
                        </IconButton>
                      </Grid>
                    </Grid>
                    <DocumentPreview
                      open={!!preview}
                      doc={preview}
                      onClose={() => setPreview(null)}
                    />
                  </>
                ) : null;
              }

              return (
                <TransactionIdDisplay
                  transactionId={transactionId}
                  shipmentId={shipmentId}
                />
              );
            },
          },
          {
            keys: ['billing.details.transaction.transactionDate'],
            transform: (transactionDate) =>
              transactionDate ? formatDateLong(transactionDate) : null,
          },
          {
            keys: [
              'billing.details.creditCardDetails',
              'billing.details.paymentToken',
              'billing.details.bankAccountDetails',
            ],
            transform: (
              creditCardDetails,
              paymentToken,
              bankAccountDetails,
            ) => (
              <PaymentDetailsDisplay
                bankAccountDetails={bankAccountDetails}
                creditCardDetails={creditCardDetails}
                paymentToken={paymentToken}
              />
            ),
          },
          {
            keys: [
              'billing.details.billingAddress',
              'billing.details.paymentToken',
            ],
            transform: (billingAddress, paymentToken) => (
              <BillingAddressDisplay
                billingAddress={billingAddress}
                paymentToken={paymentToken}
              />
            ),
          },
        ],
        tableStyles: {
          marginTop: 20,
        },
      },
      ...billCodes.map((billCodeSet, idx) => ({
        path: `billing.details.billCodes.${idx}`,
        details: [
          {
            name: (
              <span className={classes.tableHeadText}>
                {`Allocation ${idx + 1}`}
              </span>
            ),
            keys: ['allocation'],
            transform: (allocation) => (
              <span className={classes.tableHeadText}>{`${allocation}%`}</span>
            ),
          },
          ...Object.keys(billCodeSet)
            .map((code) => {
              const isAllocation = code === 'allocation';

              const obj = {
                name: camelToCapital(code),
                keys: isAllocation
                  ? ['allocation']
                  : [`${code}.code`, `${code}.description`],
                transform: isAllocation
                  ? (allocation) => `${allocation}%`
                  : (c, d) => `${c}${d ? ` - ${d}` : ''}`,
              };
              return obj;
            })
            .sort(sortBillingCodes),
        ],
        leftCellStyles: {
          width: 220,
        },
        tableStyles: {
          marginTop: 20,
        },
      })),
    ],
    billingCustoms: [
      {
        path: 'billingCustoms.details',
        details: [
          {
            keys: ['amount', 'billingCharges', 'transaction.currencyCode'],
            transform: (amount, billingCharges, currencyCode) => (
              <BillingCharges
                charges={billingCharges}
                total={amount}
                currencyCode={currencyCode}
              />
            ),
          },
        ],
        tableStyles: {
          marginTop: 20,
        },
      },
      {
        path: '',
        tableHead: {
          keys: ['billingCustoms.details.transaction'],
          transform: (transaction) => transaction && 'Transaction Details',
        },
        details: [
          {
            keys: [
              'billingCustoms.details.transaction.transactionId',
              'shipmentId',
            ],
            transform: (transactionId, shipmentId) => {
              // eslint-disable-next-line no-shadow
              function TransactionIdDisplay(props) {
                // eslint-disable-next-line react/prop-types, no-shadow
                const { transactionId, shipmentId } = props;
                const [preview, setPreview] = React.useState(null);

                return transactionId ? (
                  <>
                    <Grid container spacing={1} alignItems='center'>
                      <Grid item>
                        <Button
                          style={{ padding: 0, color: colors.lightBlue }}
                          onClick={async () => {
                            try {
                              const receipt = await getPaymentReceipt(
                                shipmentId,
                                transactionId,
                              );
                              if (!receipt) return;
                              setPreview(receipt);
                            } catch (error) {
                              console.error(error);
                            }
                          }}
                          disableFocusRipple
                          disableRipple
                          disableTouchRipple>
                          <span
                            style={{
                              fontWeight: '500',
                              textDecoration: 'underline',
                              textTransform: 'none ',
                            }}>
                            {`# ${transactionId}`}
                          </span>
                        </Button>
                      </Grid>
                      <Grid item>
                        <IconButton
                          style={{ padding: 0, color: colors.lightBlue }}
                          onClick={async () => {
                            try {
                              const receipt = await getPaymentReceipt(
                                shipmentId,
                                transactionId,
                              );
                              if (!receipt) return;
                              const arrayBuffer = base64ToArrayBuffer(receipt);
                              printPDF(arrayBuffer);
                            } catch (error) {
                              console.error(error);
                            }
                          }}>
                          <PrintIcon style={{ height: '.875em' }} />
                        </IconButton>
                      </Grid>
                    </Grid>
                    <DocumentPreview
                      open={!!preview}
                      doc={preview}
                      onClose={() => setPreview(null)}
                    />
                  </>
                ) : null;
              }

              return (
                <TransactionIdDisplay
                  transactionId={transactionId}
                  shipmentId={shipmentId}
                />
              );
            },
          },
          {
            keys: ['billingCustoms.details.transaction.transactionDate'],
            transform: (transactionDate) =>
              transactionDate ? formatDateLong(transactionDate) : null,
          },
          {
            keys: [
              'billingCustoms.details.creditCardDetails',
              'billingCustoms.details.paymentToken',
              'billingCustoms.details.bankAccountDetails',
            ],
            transform: (
              creditCardDetails,
              paymentToken,
              bankAccountDetails,
            ) => (
              <PaymentDetailsDisplay
                bankAccountDetails={bankAccountDetails}
                creditCardDetails={creditCardDetails}
                paymentToken={paymentToken}
              />
            ),
          },
          {
            keys: [
              'billingCustoms.details.billingAddress',
              'billingCustoms.details.paymentToken',
            ],
            transform: (billingAddress, paymentToken) => (
              <BillingAddressDisplay
                billingAddress={billingAddress}
                paymentToken={paymentToken}
              />
            ),
          },
        ],
        tableStyles: {
          marginTop: 20,
        },
      },
      ...billCodes.map((billCodeSet, idx) => ({
        path: `billingCustoms.details.billCodes.${idx}`,
        details: [
          {
            name: (
              <span className={classes.tableHeadText}>
                {`Allocation ${idx + 1}`}
              </span>
            ),
            keys: ['allocation'],
            transform: (allocation) => (
              <span className={classes.tableHeadText}>{`${allocation}%`}</span>
            ),
          },
          ...Object.keys(billCodeSet)
            .map((code) => {
              const isAllocation = code === 'allocation';

              const obj = {
                name: camelToCapital(code),
                keys: isAllocation
                  ? ['allocation']
                  : [`${code}.code`, `${code}.description`],
                transform: isAllocation
                  ? (allocation) => `${allocation}%`
                  : (c, d) => `${c}${d ? ` - ${d}` : ''}`,
              };
              return obj;
            })
            .sort(sortBillingCodes),
        ],
        leftCellStyles: {
          width: 220,
        },
        tableStyles: {
          marginTop: 20,
        },
      })),
    ],
    billingTaxes: [
      {
        path: 'billingTaxes.details',
        details: [
          {
            keys: ['amount', 'billingCharges', 'transaction.currencyCode'],
            transform: (amount, billingCharges, currencyCode) => (
              <BillingCharges
                charges={billingCharges}
                total={amount}
                currencyCode={currencyCode}
              />
            ),
          },
        ],
        tableStyles: {
          marginTop: 20,
        },
      },
      {
        path: '',
        tableHead: {
          keys: ['billingTaxes.details.transaction'],
          transform: (transaction) => transaction && 'Transaction Details',
        },
        details: [
          {
            keys: [
              'billingTaxes.details.transaction.transactionId',
              'shipmentId',
            ],
            transform: (transactionId, shipmentId) => {
              // eslint-disable-next-line no-shadow
              function TransactionIdDisplay(props) {
                // eslint-disable-next-line react/prop-types, no-shadow
                const { transactionId, shipmentId } = props;
                const [preview, setPreview] = React.useState(null);

                return transactionId ? (
                  <>
                    <Grid container spacing={1} alignItems='center'>
                      <Grid item>
                        <Button
                          style={{ padding: 0, color: colors.lightBlue }}
                          onClick={async () => {
                            try {
                              const receipt = await getPaymentReceipt(
                                shipmentId,
                                transactionId,
                              );
                              if (!receipt) return;
                              setPreview(receipt);
                            } catch (error) {
                              console.error(error);
                            }
                          }}
                          disableFocusRipple
                          disableRipple
                          disableTouchRipple>
                          <span
                            style={{
                              fontWeight: '500',
                              textDecoration: 'underline',
                              textTransform: 'none ',
                            }}>
                            {`# ${transactionId}`}
                          </span>
                        </Button>
                      </Grid>
                      <Grid item>
                        <IconButton
                          style={{ padding: 0, color: colors.lightBlue }}
                          onClick={async () => {
                            try {
                              const receipt = await getPaymentReceipt(
                                shipmentId,
                                transactionId,
                              );
                              if (!receipt) return;
                              const arrayBuffer = base64ToArrayBuffer(receipt);
                              printPDF(arrayBuffer);
                            } catch (error) {
                              console.error(error);
                            }
                          }}>
                          <PrintIcon style={{ height: '.875em' }} />
                        </IconButton>
                      </Grid>
                    </Grid>
                    <DocumentPreview
                      open={!!preview}
                      doc={preview}
                      onClose={() => setPreview(null)}
                    />
                  </>
                ) : null;
              }

              return (
                <TransactionIdDisplay
                  transactionId={transactionId}
                  shipmentId={shipmentId}
                />
              );
            },
          },
          {
            keys: ['billingTaxes.details.transaction.transactionDate'],
            transform: formatDateLong,
          },
          {
            keys: [
              'billingTaxes.details.creditCardDetails',
              'billingTaxes.details.paymentToken',
              'billingTaxes.details.bankAccountDetails',
            ],
            transform: (
              creditCardDetails,
              paymentToken,
              bankAccountDetails,
            ) => (
              <PaymentDetailsDisplay
                bankAccountDetails={bankAccountDetails}
                creditCardDetails={creditCardDetails}
                paymentToken={paymentToken}
              />
            ),
          },
          {
            keys: [
              'billingTaxes.details.billingAddress',
              'billingTaxes.details.paymentToken',
            ],
            transform: (billingAddress, paymentToken) => (
              <BillingAddressDisplay
                billingAddress={billingAddress}
                paymentToken={paymentToken}
              />
            ),
          },
        ],
        tableStyles: {
          marginTop: 20,
        },
      },
      ...billCodes.map((billCodeSet, idx) => ({
        path: `billingTaxes.details.billCodes.${idx}`,
        details: [
          {
            name: (
              <span className={classes.tableHeadText}>
                {`Allocation ${idx + 1}`}
              </span>
            ),
            keys: ['allocation'],
            transform: (allocation) => (
              <span className={classes.tableHeadText}>{`${allocation}%`}</span>
            ),
          },
          ...Object.keys(billCodeSet)
            .map((code) => {
              const isAllocation = code === 'allocation';

              const obj = {
                name: camelToCapital(code),
                keys: isAllocation
                  ? ['allocation']
                  : [`${code}.code`, `${code}.description`],
                transform: isAllocation
                  ? (allocation) => `${allocation}%`
                  : (c, d) => `${c}${d ? ` - ${d}` : ''}`,
              };
              return obj;
            })
            .sort(sortBillingCodes),
        ],
        leftCellStyles: {
          width: 220,
        },
        tableStyles: {
          marginTop: 20,
        },
      })),
    ],
  });

  function camelToCapital(camel) {
    const exceptions = {
      payPal: 'PayPal',
      ach: 'Bank Transfer',
    };
    if (exceptions[camel]) return exceptions[camel];
    return (
      camel &&
      camel
        .replace(/([a-z])([A-Z])/g, (match, p1, p2) => `${p1} ${p2}`)
        .replace(/\b([a-z])/g, (match, p1) => p1.toUpperCase())
    );
  }

  function sortBillingCodes(a, b) {
    if (a.name === 'Company') return -1;
    if (b.name === 'Company') return 1;
    if (a.name < b.name) return -1;
    return 1;
  }

  const getDetails = (baseObj, formName, map) => {
    if (!baseObj || !formName || !map[formName])
      return [{ tableHead: '', tableRows: [] }];
    const tableDetailsArray = map[formName];
    return tableDetailsArray.map((detailsSet) => {
      const tableHeadVals =
        typeof detailsSet.tableHead === 'object'
          ? getValFromPath(
              baseObj,
              detailsSet.tableHead.keys.map((k) =>
                [detailsSet.path, k].filter((path) => path).join('.'),
              ),
            )
          : [detailsSet.tableHead];
      const tableFooterVals =
        typeof detailsSet.tableFooter === 'object'
          ? getValFromPath(
              baseObj,
              detailsSet.tableFooter.keys.map((k) =>
                [detailsSet.path, k].filter((path) => path).join('.'),
              ),
            )
          : [detailsSet.tableFooter];
      return {
        ...detailsSet,
        tableHead:
          detailsSet.tableHead &&
          (detailsSet.tableHead.transform
            ? detailsSet.tableHead.transform(...tableHeadVals)
            : `${tableHeadVals.join(' ')}`),
        tableRows: detailsSet.details
          .map((spec) => {
            const startingObj = detailsSet.path
              ? getValFromPath(baseObj, detailsSet.path)
              : baseObj;
            const displayObj = getValFromPath(startingObj, spec.keys);
            return displayObj.length
              ? [
                  spec.name,
                  spec.transform
                    ? spec.transform(...displayObj)
                    : `${displayObj.join(' ')}`,
                ]
              : null;
          })
          .filter((pair) => pair),
        tableFooter:
          detailsSet.tableFooter &&
          (detailsSet.tableFooter.transform
            ? detailsSet.tableFooter.transform(...tableFooterVals)
            : `${tableFooterVals.join(' ')}`),
      };
    });
  };
  return (
    <Grid
      container
      direction='column'
      className={classes.tableWrap}
      wrap='nowrap'>
      {shipment &&
        formName &&
        getDetails(
          shipment,
          formName,
          formNameToDetails(shipment?.account),
        ).map(
          (tableDataSet) =>
            (tableDataSet.tableHead ||
              tableDataSet.tableRows.length ||
              tableDataSet.tableFooter) && (
              <Grid
                key={`panel-summary-header-${tableDataSet.tableHead}`}
                item
                style={tableDataSet.tableStyles}>
                <Table className={classes.table}>
                  {tableDataSet.tableHead && (
                    <TableHead classes={{ root: classes.tableHead }}>
                      <TableRow classes={{ root: classes.tableRow }}>
                        <TableCell className={classes.tableCellHeaderFooter}>
                          <Typography
                            variant='body1'
                            className={classes.tableHeadText}>
                            {tableDataSet.tableHead}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                  )}
                  <TableBody>
                    {tableDataSet.tableRows.map(
                      (dataPair) =>
                        (dataPair[0] || dataPair[1]) && (
                          <TableRow
                            key={`panel-summary-details-${
                              dataPair[0] ? dataPair[0] : ''
                            }-${dataPair[1] ? dataPair[1] : ''}`}
                            style={tableDataSet.tableRowStyles}>
                            {dataPair[0] && (
                              <TableCell
                                className={`${classes.tableCell}`}
                                style={tableDataSet.leftCellStyles}>
                                <Typography
                                  variant='body1'
                                  style={{ textTransform: 'capitalize' }}
                                  color='primary'
                                  className={classes.tableCellText}>
                                  {dataPair[0]}
                                </Typography>
                              </TableCell>
                            )}
                            {dataPair[1] && (
                              <TableCell className={classes.tableCell}>
                                <Typography
                                  component='div'
                                  variant='body1'
                                  style={{ textTransform: 'capitalize' }}
                                  className={classes.tableCellText}>
                                  {dataPair[1]}
                                </Typography>
                              </TableCell>
                            )}
                          </TableRow>
                        ),
                    )}
                  </TableBody>
                </Table>
                {tableDataSet.tableFooter && (
                  <Table>
                    <TableHead classes={{ root: classes.tableHead }}>
                      <TableRow classes={{ root: classes.tableRow }}>
                        <TableCell className={classes.tableCellHeaderFooter}>
                          <Typography
                            component='div'
                            variant='body1'
                            className={classes.tableHeadText}>
                            {tableDataSet.tableFooter}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                  </Table>
                )}
              </Grid>
            ),
        )}
    </Grid>
  );
}

PaneDetailsSummary.propTypes = {
  shipment: PropTypes.objectOf(PropTypes.any).isRequired,
  formName: PropTypes.string.isRequired,
};
