import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { CheckboxWithLabel, fieldToTextField, fieldToCheckbox } from 'formik-material-ui';
import DateFnsUtils from '@date-io/date-fns';
import {
  Button, FormControlLabel, InputAdornment, Radio, RadioGroup, Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MuiTextField from '@material-ui/core/TextField';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import Grid from '@material-ui/core/Grid';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';
import { css } from 'emotion';
import { get } from 'lodash';
import CustomScrollbar from './CustomScrollbar';
import * as colors from '../../styles/colors';
import { NEW_ORDER_BILLING_PAYMENT_STYLE } from '../../styles/style';

/**
 * ##################################
 *          CUSTOM COMPONENT
 * ##################################
 */

const customTextUseStyles = makeStyles({
  inputLabelPropsRoot: {
    '&$focused': {
      color: colors.white,
    },
    '&$disabled': {
      color: colors.textDarkGrey,
    },
    '&$error': {
      color: colors.white,
    },
    '&.MuiFormLabel-root.Mui-disabled': {
    // cost code left side disabled label color
      color: colors.textDarkGrey,
    },
    '&.MuiFormLabel-root.Mui-focused': {
      // cost code left side disabled label color
      color: colors.white,
    },
    color: colors.white,
    fontWeight: 425,
  },
  inputPropsRoot: {
    '&$focused': {
      color: colors.white,
    },
    '&$disabled': {
      color: colors.white,
      backgroundColor: 'rgba(0, 0, 0, 0.30)',
    },
    '&:before': {
      borderBottom: 0,
    },
    '&:after': {
      borderBottom: `2px solid ${colors.primaryBlue}`,
    },
    '&&&&:hover:before': {
      borderBottom: 0,
    },
    '&>div:nth-of-type(2)': {
      '&>button:first-of-type': {
        color: colors.textLightGrey,
      },
    },
    height: 56,
    color: colors.white,
    background: colors.inputFieldBackground,
    width: '100%',
  },
  inputPropsDisabled: {
    // cost code left side disabled input color
    color: colors.white,
    backgroundColor: 'rgba(0, 0, 0, 0.30) !important',
    '&$focused': {
      backgroundColor: 'rgba(0, 0, 0, 0.30)',
    },
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.30)',
    },
    '&:focus': {
      backgroundColor: 'rgba(0, 0, 0, 0.30)',
    },
    '&.Mui-focused': {
      // cost code left side disabled label color
      backgroundColor: 'rgba(0, 0, 0, 0.30)',
    },
  },
  inputLabelPropsDisabled: {
    color: colors.white,
    fontWeight: 425,
  },
});

const customTestAreaStyle = makeStyles({
  disabledInput: {
    '& .MuiInputBase-root.Mui-disabled': {
      color: 'rgba(255,255,255,0.6)',
      cursor: 'default',
    },
  },
});

export function CustomTextAreaField(props) {
  const { inputLabelProps, inputProps, ...other } = props;
  const classes = customTestAreaStyle();
  return (
    <MuiTextField
        // eslint-disable-next-line react/jsx-props-no-spreading
      {...fieldToTextField(other)}
      variant="filled"
      InputLabelProps={inputLabelProps}
      InputProps={inputProps}
      multiline
      rowsMax="5"
      className={classes.disabledInput}
    />
  );
}

CustomTextAreaField.propTypes = {
  inputProps: PropTypes.objectOf(PropTypes.any).isRequired,
  inputLabelProps: PropTypes.objectOf(PropTypes.any).isRequired,
};

function CustomTextPerecentField(props) {
  const {
    inputLabelProps, inputProps, inputPropsAttr, formHelperTextProps, ...other
  } = props;

  return (
    <MuiTextField
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...fieldToTextField(other)}
      variant="filled"
      InputLabelProps={inputLabelProps}
      FormHelperTextProps={formHelperTextProps}
      InputProps={inputProps}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      inputProps={inputPropsAttr}
    />
  );
}

CustomTextPerecentField.propTypes = {
  inputLabelProps: PropTypes.objectOf(PropTypes.any).isRequired,
  inputProps: PropTypes.objectOf(PropTypes.any).isRequired,
  formHelperTextProps: PropTypes.objectOf(PropTypes.any).isRequired,
  inputPropsAttr: PropTypes.objectOf(PropTypes.any).isRequired,
};

function CustomSelectTextField(props) {
  const {
    inputProps, inputLabelProps, expandMoreIcon, callback, disabled, options, ...other
  } = props;
  const formattedProps = fieldToTextField(other);

  return (
    <MuiTextField
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...formattedProps}
      select
      variant="filled"
      InputLabelProps={inputLabelProps}
      InputProps={inputProps}
      SelectProps={{
        IconComponent: (iconProps) => <ExpandMoreIcon {...iconProps} color="primary" />,
        classes: { icon: css({ color: 'white' }) },
        renderValue: options ? (v) => {
          const match = options.find((s) => s.value === v);
          if (match) return match.description;
          return v;
        } : undefined,
      }}
      onChange={(e) => {
        formattedProps.onChange(e);
        callback && callback(e.target.value);
      }}
      disabled={disabled || formattedProps.disabled}
    />
  );
}

CustomSelectTextField.propTypes = {
  inputProps: PropTypes.objectOf(PropTypes.any).isRequired,
  inputLabelProps: PropTypes.objectOf(PropTypes.any).isRequired,
  expandMoreIcon: PropTypes.objectOf(PropTypes.string.isRequired).isRequired,
  callback: PropTypes.func,
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.objectOf({
    value: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  })),
};

CustomSelectTextField.defaultProps = {
  callback: () => {},
  disabled: false,
  options: null,
};

function CustomRadioField(props) {
  const {
    form, field, id, disabled, options, radioClasses, radioLabelClasses, callback,
  } = props;

  return (
    <RadioGroup
      value={form.values[id]}
      onChange={(e) => {
        callback(e && e.target && e.target.value);
        field.onChange(e);
      }}
    >
      {options.map((option) => (
        <FormControlLabel
          key={`${id}-${option.id}`}
          label={option.label}
          disabled={disabled || (form && form.isSubmitting)}
          control={(
            <Radio
              id={id}
              value={option.value}
              color="primary"
              classes={radioClasses}
            />
          )}
          classes={radioLabelClasses}
        />
      ))}
    </RadioGroup>
  );
}

CustomRadioField.propTypes = {
  id: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  form: PropTypes.shape({
    values: PropTypes.object.isRequired,
  }).isRequired,
  field: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired).isRequired,
  radioClasses: PropTypes.objectOf(PropTypes.string.isRequired).isRequired,
  radioLabelClasses: PropTypes.objectOf(PropTypes.string.isRequired).isRequired,

  callback: PropTypes.func,
};
CustomRadioField.defaultProps = {
  callback: () => {},
};

function CustomSearchField(props) {
  const {
    inputLabelProps, inputProps, callback, transformValue, blockValue, disabled, ...other
  } = props;
  const formikProps = fieldToTextField(other);

  return (
    <MuiTextField
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...formikProps}
      variant="filled"
      InputLabelProps={inputLabelProps}
      InputProps={inputProps}
      inputProps={inputProps}
      onChange={(e) => {
        const { value } = e.target;
        if (formikProps.value !== value && (!blockValue(value))) {
          e.target.value = transformValue(e.target.value);
          formikProps.onChange(e);
          callback(value);
        }
      }}
      disabled={disabled || formikProps.disabled}
    />
  );
}

CustomSearchField.propTypes = {
  inputProps: PropTypes.objectOf(PropTypes.any).isRequired,
  inputLabelProps: PropTypes.objectOf(PropTypes.any).isRequired,
  callback: PropTypes.func,
  transformValue: PropTypes.func,
  blockValue: PropTypes.func,
  disabled: PropTypes.bool,
};
CustomSearchField.defaultProps = {
  callback: () => {},
  transformValue: (val) => val,
  blockValue: (val) => false,
  disabled: false,
};

export function CustomReadOnlyTextField(props) {
  const {
    inputLabelProps = {},
    inputProps = { readOnly: true },
    disabled,
    ...other
  } = props;

  const classes = customTextUseStyles();

  let spreadProps;

  try {
    spreadProps = fieldToTextField(other);
  } catch (e) {
    spreadProps = { ...other };
  }

  const inputLabelClasses = {
    disabled: classes.inputLabelPropsDisabled,
    root: inputProps.readOnly
      ? classes.inputLabelPropsDisabled
      : classes.inputLabelPropsRoot,
    focused: classes.inputLabelPropsRoot,
  };
  const inputClasses = {
    root: inputProps.readOnly
      ? classes.inputPropsDisabled
      : classes.inputPropsRoot,
    disabled: classes.inputPropsDisabled,
    // ...classes,
  };

  return (
    <MuiTextField
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...spreadProps}
      variant="filled"
      disabled={disabled}
      InputLabelProps={{
        fullWidth: true,
        classes: inputLabelClasses,
        htmlFor: 'contact-search',
        ...inputLabelProps,
      }}
      InputProps={{
        fullWidth: true,
        classes: inputClasses,
        disableUnderline: true,
        ...inputProps,
      }}
      fullWidth
    />
  );
}

CustomReadOnlyTextField.propTypes = {
  inputProps: PropTypes.objectOf(PropTypes.any).isRequired,
  inputLabelProps: PropTypes.objectOf(PropTypes.any).isRequired,
  callback: PropTypes.func,
  transformValue: PropTypes.func,
  blockValue: PropTypes.func,
  disabled: PropTypes.bool,
};
CustomReadOnlyTextField.defaultProps = {
  callback: () => {},
  transformValue: (val) => val,
  blockValue: (val) => false,
  disabled: false,
};

function CustomAutoCompleteField(props) {
  const {
    inputRef, inputProps, inputLabelProps, disabled, ...other
  } = props;

  const formikProps = fieldToTextField(other);

  return (
    <MuiTextField
        // eslint-disable-next-line react/jsx-props-no-spreading
      {...formikProps}
      inputRef={inputRef}
      variant="filled"
      InputLabelProps={inputLabelProps}
      InputProps={inputProps}
      disabled={disabled || formikProps.disabled}
    />
  );
}

CustomAutoCompleteField.propTypes = {
  inputRef: PropTypes.shape({
    current: PropTypes.object,
  }).isRequired,
  inputProps: PropTypes.objectOf(PropTypes.any).isRequired,
  inputLabelProps: PropTypes.objectOf(PropTypes.any).isRequired,
  disabled: PropTypes.bool,
};

CustomAutoCompleteField.defaultProps = {
  disabled: false,
};

/**
 * ######################
 *          UTIL
 * ######################
 */

function getInputLabelProps(classes) {
  return {
    classes: {
      root: classes.inputLabelRoot,
      focused: classes.focused,
      disabled: classes.disabled,
      error: classes.error,
    },
    for: 'contact-search',
  };
}

function getFormHelperTextProps() {
  return {
    style: { textWrap: 'nowrap' },
  };
}
function getInputProps(root, classes) {
  const { focused, disabled } = classes;

  return {
    fullWidth: true,
    classes: {
      root: classes[root],
      focused,
      disabled,
    },
  };
}

function getPercentInputProps(root, classes) {
  const {
    focused, disabled, input,
  } = classes;

  return {
    fullWidth: true,
    classes: {
      root: classes[root],
      focused,
      disabled,
      input,
    },
  };
}

function getExpandMoreIconStyling(classes) {
  return {
    colorPrimary: classes.expandMoreIconPrimary,
    colorDisabled: classes.expandMoreIconDisabled,
  };
}

/**
 * #################################
 *          EXPORT FUNCTIONS
 * #################################
 */

export function CustomText(props) {
  const {
    type = 'text', name, label, disabled, classes, inputProps, ...other
  } = props;

  const styles = customTextUseStyles();

  return (
    <Field
      component={CustomSearchField}
      type={type}
      name={name}
      label={label}
      disabled={disabled}
      inputLabelProps={{
        fullWidth: true,
        style: { color: '#fff' },
        classes: {
          root: styles.inputLabelPropsRoot,
          disabled: styles.inputLabelPropsDisabled,
          // ...classes,
        },
        htmlFor: 'contact-search',
      }}
      inputProps={{
        fullWidth: true,
        classes: {
          root: styles.inputPropsRoot,
          disabled: styles.inputPropsDisabled,
          // ...classes,
        },
        ...inputProps,
      }}
      style={{ width: '100%' }}
      {...other}
    />
  );
}

CustomText.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  classes: PropTypes.objectOf(PropTypes.string.isRequired).isRequired,
  inputProps: PropTypes.objectOf(PropTypes.any),
};
CustomText.defaultProps = {
  type: 'text',
  inputProps: {},
};

const customTextWithDropdownStyles = makeStyles({
  dropdownButtonRoot: {
    width: '100%',
    justifyContent: 'left',
  },
  dropdownTextRoot: {
    color: colors.white,
    whiteSpace: 'noWrap',
    textTransform: 'none',
    fontSize: 15,
    height: '100%',
  },
  dropdownContainer: {
    position: 'absolute',
    width: '100%',
    top: 56,
    backgroundColor: colors.dropDownList,
    boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)',
    zIndex: 2,
    height: 'auto',
  },
});

export function CustomSearchWithDropdown(props) {
  const {
    label, disabled, onSelect, onClickOutside, callback, dropdownList, dependentFields, inputProps, ...other
  } = props;
  const { form, field } = other;
  const { name } = field;
  const { setFieldValue, values } = form;

  const dropdownClasses = customTextWithDropdownStyles();
  const textFieldClasses = customTextUseStyles();

  const updateFilteredList = (text) => {
    dependentFields.forEach((df) => {
      if (get(values, df.name)) {
        setFieldValue(df.name, '');
      }
    });
    callback(text);
  };

  const internalOnSelect = (selection, e) => {
    dependentFields.forEach((df) => {
      setFieldValue(df.name, selection[df.key]);
    });
    setFieldValue(name, selection.value);
    onSelect(selection);
  };

  // Register a click away
  React.useEffect(() => {
    if (dropdownList.length <= 0) return;
    function onMousedown(e) {
      callback('');

      if (e.target.id !== `custom-search-${name}`) {
        if (onClickOutside) onClickOutside();
      }
    }

    window.addEventListener('click', onMousedown);
    return () => window.removeEventListener('click', onMousedown);
  }, [dropdownList]);

  return (
    <Grid container style={{ position: 'relative' }}>
      <CustomSearchField
        id={`custom-search-${name}`}
        name={name}
        label={label}
        disabled={disabled}
        type="text"
        callback={updateFilteredList}
        inputLabelProps={{
          fullWidth: true,
          style: { color: '#fff' },
          classes: {
            disabled: textFieldClasses.inputLabelPropsDisabled,
            root: inputProps.readOnly
              ? textFieldClasses.inputLabelPropsDisabled
              : textFieldClasses.inputLabelPropsRoot,
            focused: textFieldClasses.inputLabelPropsRoot,
            // ...classes,
          },
          htmlFor: 'contact-search',
        }}
        inputProps={{
          fullWidth: true,
          classes: {
            root: inputProps.readOnly
              ? textFieldClasses.inputPropsDisabled
              : textFieldClasses.inputPropsRoot,
            disabled: textFieldClasses.inputPropsDisabled,
            // ...classes,
          },
          ...inputProps,
        }}
        style={{ width: '100%' }}
        {...other}
      />
      <Grid
        id={`dropdown-${name}`}
        className={dropdownClasses.dropdownContainer}
        container
        direction="column"
      >
        {(dropdownList.length > 0)
          ? (
            <CustomScrollbar
              dropDownList={dropdownList.map((d) => (
                <Button
                  key={d.value}
                  onClick={(e) => internalOnSelect(d, e)}
                  classes={{ root: dropdownClasses.dropdownButtonRoot }}
                >
                  <Typography classes={{ root: dropdownClasses.dropdownTextRoot }}>
                    {d.label}
                  </Typography>
                </Button>
              ))}
            />
          )
          : null}
      </Grid>
    </Grid>
  );
}
CustomSearchWithDropdown.propTypes = {
  dropdownList: PropTypes.arrayOf(PropTypes.any),
  callback: PropTypes.func,
  dependentFields: PropTypes.arrayOf(PropTypes.any),
  inputProps: PropTypes.objectOf(PropTypes.any),
};

CustomSearchWithDropdown.defaultProps = {
  callback: () => {},
  dropdownList: [],
  dependentFields: [],
  inputProps: {},
};

export function CustomSearch(props) {
  const {
    label, disabled, inputProps, ...other
  } = props;
  const { field } = other;
  const { name } = field;

  const textFieldClasses = customTextUseStyles();

  return (
    <CustomSearchField
      name={name}
      label={label}
      disabled={disabled}
      type="text"
      inputLabelProps={{
        fullWidth: true,
        classes: {
          root: inputProps.readOnly
            ? textFieldClasses.inputLabelPropsDisabled
            : textFieldClasses.inputLabelPropsRoot,
          disabled: textFieldClasses.inputLabelPropsDisabled,
          // ...classes,
        },
        htmlFor: 'contact-search',
      }}
      inputProps={{
        fullWidth: true,
        classes: {
          root: inputProps.readOnly
            ? textFieldClasses.inputPropsDisabled
            : textFieldClasses.inputPropsRoot,
          disabled: textFieldClasses.inputPropsDisabled,
          // ...classes,
        },
        ...inputProps,
      }}
      style={{ width: '100%' }}
      {...other}
    />
  );
}

CustomSearch.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  field: PropTypes.objectOf(PropTypes.any).isRequired,
  inputProps: PropTypes.objectOf(PropTypes.any),
};

CustomSearch.defaultProps = {
  inputProps: {},
};

export function CustomTextPercent(props) {
  const { name, classes } = props;

  return (
    <Field
      component={CustomTextPerecentField}
      type="text"
      name={name}
      inputLabelProps={getInputLabelProps(classes)}
      formHelperTextProps={getFormHelperTextProps()}
      inputProps={{
        ...getPercentInputProps('percentField', classes),
        endAdornment: <InputAdornment position="end"><i className="material-icons">%</i></InputAdornment>,
      }}
      inputPropsAttr={{ maxLength: 3 }}
      style={{ width: '100%' }}
    />
  );
}

CustomTextPercent.propTypes = {
  name: PropTypes.string.isRequired,
  classes: PropTypes.shape({

  }).isRequired,
};

export function CustomTextArea(props) {
  const {
    name, placeholder, disabled, classes = {}, inputClass, inputLabelClasses, ...others
  } = props;

  return (
    <Field
      {...others}
      component={CustomTextAreaField}
      type="text"
      name={name}
      placeholder={placeholder}
      disabled={disabled}
      inputLabelProps={{ classes: inputLabelClasses }}
      inputProps={{ classes: inputClass || getInputProps('textArea', classes) }}
      style={{ width: '100%' }}
    />
  );
}

CustomTextArea.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  classes: PropTypes.objectOf(PropTypes.string.isRequired).isRequired,
};

export function CustomSelect(props) {
  const {
    name, label, disabled, children, callback, options,
  } = props;

  const classes = NEW_ORDER_BILLING_PAYMENT_STYLE();

  return (
    <Field
      component={CustomSelectTextField}
      type="text"
      name={name}
      label={label}
      disabled={disabled}
      inputLabelProps={{
        ...getInputLabelProps(classes),
        ...(name === 'country') && { shrink: true }, // Work-around for label bug
      }}
      inputProps={getInputProps('inputRoot', classes)}
      expandMoreIcon={getExpandMoreIconStyling(classes)}
      style={{ width: '100%' }}
      callback={callback}
      options={options}
    >
      {children}
    </Field>
  );
}

CustomSelect.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]).isRequired,
  callback: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.objectOf({
    value: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  })),
};

CustomSelect.defaultProps = {
  callback: () => {},
  options: null,
};

export function CustomRadio(props) {
  const {
    id, disabled, options, classes, callback,
  } = props;

  const defaultClasses = NEW_ORDER_BILLING_PAYMENT_STYLE();

  return (
    <Field
      component={CustomRadioField}
      type="radio"
      id={id}
      disabled={disabled}
      options={options}
      radioClasses={{
        colorPrimary: defaultClasses.radioPrimary,
        disabled: defaultClasses.disabled,
      }}
      radioLabelClasses={{
        root: `${defaultClasses.radioLabel} ${classes.radioLabel.root || ''}`,
      }}
      callback={callback}
    />
  );
}

CustomRadio.propTypes = {
  id: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  options: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  classes: PropTypes.shape({
    radioLabel: PropTypes.objectOf(PropTypes.string).isRequired,
    radioPrimary: PropTypes.objectOf(PropTypes.string).isRequired,
    disabled: PropTypes.objectOf(PropTypes.string).isRequired,
  }),
  callback: PropTypes.func,
};

CustomRadio.defaultProps = {
  classes: {
    radioLabel: {},
    radioPrimary: {},
    disabled: {},
  },
  callback: () => {},
};

export function CustomCheckbox(props) {
  const {
    name, label, disabled, classes, callback,
  } = props;

  const defaultClasses = NEW_ORDER_BILLING_PAYMENT_STYLE();

  return (
    <Field
      name={name}
    >
      {(formikProps) => {
        const checkboxFormikProps = fieldToCheckbox(formikProps);
        const { field } = formikProps;
        return (
          <CheckboxWithLabel
            {...formikProps}
            field={{
              ...field,
              onChange: (e) => {
                callback(e.target.value);
                checkboxFormikProps.onChange(e);
              },
            }}
            color="primary"
            classes={{
              colorPrimary: defaultClasses.checkboxPrimary,
              disabled: defaultClasses.disabled,
              root: `${classes.root || ''}`,
            }}
            // InputLabelProps={{ for: name }}
            // InputProps={{ id: name }}
            Label={{
              label: (
                <Typography classes={{ root: defaultClasses.checkboxLabel }}>{label}</Typography>
              ),
            }}
            disabled={disabled || checkboxFormikProps.disabled}
          />
        );
      }}
    </Field>
  );
}

CustomCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  classes: PropTypes.shape({
    checkboxLabel: PropTypes.string,
    checkboxPrimary: PropTypes.string,
    disabled: PropTypes.string,
    root: PropTypes.object,
  }),
  callback: PropTypes.func,
};

CustomCheckbox.defaultProps = {
  disabled: false,
  classes: {
    root: {},
  },
  callback: () => {},
};

export function CustomContactSearch(props) {
  const {
    name, label, disabled = false, callback, classes,
  } = props;

  return (
    <Field
      component={CustomSearchField}
      type="text"
      disabled={disabled}
      name={name}
      label={label}
      id="contact-search"
      inputLabelProps={getInputLabelProps(classes)}
      inputProps={{
        ...getInputProps('searchField', classes),
        startAdornment: <InputAdornment position="start"><SearchIcon color="primary" /></InputAdornment>,
      }}
      callback={callback}
      style={{ width: '100%' }}
    />
  );
}

CustomContactSearch.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  callback: PropTypes.func.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

CustomContactSearch.defaultProps = {
  disabled: false,
};

export function CustomDatePicker({
  name, value, disabled, classes, minDate, maxDate, style, onChange,
}) {
  const buttonClasses = {
    arrow: css({
      color: colors.lightBackgroundGrey,
    }),
  };
  const TextFieldComponent = (tFCprops) => <MuiTextField {...tFCprops} disabled />;

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        autoOk
        disableToolbar
        disablePast={!disabled}
        variant="inline"
        format="dd MMM yyyy"
        margin="normal"
        name={name}
        value={value}
        disabled={disabled}
        onChange={onChange}
        TextFieldComponent={TextFieldComponent}
        InputProps={getInputProps('datePicker', classes)}
        minDateMessage="Selected date cannot be before today's date"
        rightArrowButtonProps={{ classes: { root: buttonClasses.arrow } }}
        leftArrowButtonProps={{ classes: { root: buttonClasses.arrow } }}
        maxDate={maxDate || undefined}
        minDate={minDate}
        initialFocusedDate={minDate}
        style={style}
      />
    </MuiPickersUtilsProvider>
  );
}

CustomDatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  classes: PropTypes.objectOf(PropTypes.string.isRequired).isRequired,
};

export function DueDatePicker({
  name, value, disabled, disablePast, classes,
  minDate, maxDate, style, onChange,
}) {
  const buttonClasses = {
    arrow: css({
      color: colors.lightBackgroundGrey,
    }),
  };
  const TextFieldComponent = (tFCprops) => <MuiTextField {...tFCprops} />;

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        autoOk
        disableToolbar
        disablePast={disablePast}
        variant="inline"
        format="dd MMM yyyy"
        margin="normal"
        name={name}
        value={value}
        disabled={disabled}
        onChange={onChange}
        TextFieldComponent={TextFieldComponent}
        InputProps={getInputProps('datePicker', classes)}
        minDateMessage="Selected date cannot be before today's date"
        rightArrowButtonProps={{ classes: { root: buttonClasses.arrow } }}
        leftArrowButtonProps={{ classes: { root: buttonClasses.arrow } }}
        maxDate={maxDate || undefined}
        minDate={minDate}
        initialFocusedDate={minDate}
        style={style}
      />
    </MuiPickersUtilsProvider>
  );
}

DueDatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  disablePast: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  classes: PropTypes.objectOf(PropTypes.string.isRequired).isRequired,
};
export function CustomTimeSelect(props) {
  const {
    name, disabled, children, classes,
  } = props;

  return (
    <Field
      component={CustomSelectTextField}
      type="text"
      name={name}
      disabled={disabled}
      classes={{ root: classes.noLabel }}
      inputLabelProps={{}}
      inputProps={getInputProps('timeSelect', classes)}
      expandMoreIcon={getExpandMoreIconStyling(classes)}
    >
      {children}
    </Field>
  );
}

CustomTimeSelect.propTypes = {
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  children: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  classes: PropTypes.shape({
    noLabel: PropTypes.string.isRequired,
  }).isRequired,
};

export function CustomAutoComplete(props) {
  const {
    inputRef, name, label, disabled, classes,
  } = props;

  return (
    <Field
      component={CustomAutoCompleteField}
      inputRef={inputRef}
      type="text"
      name={name}
      label={label}
      placeholder=""
      disabled={disabled}
      inputLabelProps={getInputLabelProps(classes)}
      inputProps={getInputProps('inputRoot', classes)}
      style={{ width: '100%' }}
    />
  );
}

CustomAutoComplete.propTypes = {
  inputRef: PropTypes.shape({
    current: PropTypes.object,
  }).isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  classes: PropTypes.objectOf(PropTypes.string.isRequired).isRequired,
};

export function CustomRecieveComponent(props) {
  const {
    component, name, disabled = false, callback, classes,
  } = props;

  return (
    <Field
      component={component}
      name={name}
      callback={callback}
      classes={classes}
      disabled={disabled}
    />
  );
}

CustomRecieveComponent.propTypes = {
  component: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  callback: PropTypes.func.isRequired,
  classes: PropTypes.objectOf(PropTypes.string.isRequired).isRequired,
};

CustomRecieveComponent.defaultProps = {
  disabled: false,
};
