import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import {
  Dialog, DialogContent, Grid, Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as colors from '../../styles/colors';
import RedirectButton from './RedirectButton';
import { NEW_ORDER_PATH, PROFILE_MANAGEMENT_PATH, SCROLL_TO_SIGNATURE } from '../../clientConstants';
import NewOrderNextButtonClear from './NewOrderNextButtonClear';
import NewOrderNextButton from './NewOrderNextButton';
import UserProhibitedAckDialog from '../UserProhibitedAckDialog';

const useStyles = makeStyles(() => ({
  dialogPaper: {
    background: colors.newOrderFormBackground,
    padding: '20px 20px 0',
    minHeight: 200,
  },
  dialogMessageText: {
    color: colors.white,
  },
  buttonsContainer: {
    margin: '3rem 0 0',
  },
}));

export default function RedirectToNewOrderButton({ label, userHasSignature, isAckProhibited }) {
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [isAckOpen, setIsAckOpen] = useState(false);

  const handleRedirect = () => {
    if (!userHasSignature && !open) setOpen(true);
    else if (!isAckProhibited && userHasSignature && !isAckOpen) setIsAckOpen(true);
    else if (!userHasSignature && open) {
      history.push(PROFILE_MANAGEMENT_PATH,
        {
          [SCROLL_TO_SIGNATURE]: true,
        });
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <RedirectButton
        startIcon={<AddIcon />}
        url={userHasSignature && isAckProhibited ? NEW_ORDER_PATH : ''}
        label={label}
        style={{ letterSpacing: '1.25px' }}
        onClick={handleRedirect}
      />
      {!isAckProhibited && <UserProhibitedAckDialog portal isShipping isOpened={isAckOpen} setOpened={setIsAckOpen} />}

      <Dialog
        open={open}
        onClose={handleClose}
        classes={{ paper: classes.dialogPaper }}
      >
        <DialogContent>
          <Typography className={classes.dialogMessageText}>
            To be able to create a new shipping order, you first need to add your signature in your profile.
            We will redirect you to the user profile page.
          </Typography>
          <Grid className={classes.buttonsContainer} item container justify="flex-end">
            <NewOrderNextButtonClear
              style={{ marginRight: '1rem' }}
              onClick={handleClose}
            >
              Cancel
            </NewOrderNextButtonClear>
            <NewOrderNextButton
              onClick={handleRedirect}
            >
              OK
            </NewOrderNextButton>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}

RedirectToNewOrderButton.defaultProps = {
  userHasSignature: false,
  isAckProhibited: false,
};

RedirectToNewOrderButton.propTypes = {
  label: PropTypes.string.isRequired,
  userHasSignature: PropTypes.bool,
  isAckProhibited: PropTypes.bool,
};
