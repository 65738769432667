import React from 'react';
import PropTypes from 'prop-types';
import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
import {
  Button, Grid, MenuItem, TextField, Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as colors from '../../styles/colors';
import DeleteIcon from '../images/DeleteIcon';
import { NEW_ORDER_INPUT_LABEL_PROPS, NEW_ORDER_INPUT_PROPS } from '../../styles/style';
import { useMaterialsState } from '../../context/materialsContext';

const useStyles = makeStyles(() => ({
  tooltip: {
    background: colors.tooltipBackground,
    fontSize: 12,
  },
  iconButton: {
    minWidth: 30,
    borderRadius: '8px',
    boxShadow: 'none',
    in: 'auto',
    color: `${colors.tableGreyText}`,
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
    padding: 0,
  },
  error: {
    fontSize: '0.75rem',
    margin: '3px 14px 0',
    color: '#f44336',
  },
}));

export const CUSTOM_FREIGHT_FIELDS = {
  serviceName: {
    name: 'serviceName',
    label: 'Service',
  },
  cost: {
    name: 'listPrice',
    label: 'Actual Cost',
  },
  markupCost: {
    name: 'myPrice',
    label: 'Markup Cost',
  },
  transitTimeDays: {
    name: 'transitTimeDays',
    label: 'Transit Day(s)',
  },
  currencyCode: {
    name: 'currencyCode',
    label: 'Currency',
  },
};

const CustomFreightFields = ({
  itemCode, errors, disableDelete, onChange, onRemoveRow,
}) => {
  const classes = useStyles();
  const { currencyUnits } = useMaterialsState();

  const getField = (name, label) => {
    const isCurrencyField = name === CUSTOM_FREIGHT_FIELDS.currencyCode.name;
    const hasError = has(errors, name) && !isEmpty(errors[name]);

    return (
      <>
        <TextField
          variant="filled"
          name={`${name}-${itemCode}`}
          label={label}
          style={{ width: '100%' }}
          select={isCurrencyField}
          error={hasError}
          InputLabelProps={{
            classes: NEW_ORDER_INPUT_LABEL_PROPS(),
          }}
          InputProps={{
            fullWidth: true,
            classes: NEW_ORDER_INPUT_PROPS(),
          }}
          SelectProps={{
            defaultValue: 'USD',
          }}
          onChange={onChange}
          onBlur={onChange}
        >
          {isCurrencyField ? currencyUnits.map((option) => (
            <MenuItem
              key={option.currencyCode}
              value={option.currencyCode}
            >
              {option.currencyCode}
            </MenuItem>
          )) : null}
        </TextField>
        {hasError && <div className={classes.error}>{errors[name]}</div>}
      </>
    );
  };

  return (
    <>
      <Grid item xs={3}>
        {getField(CUSTOM_FREIGHT_FIELDS.serviceName.name, CUSTOM_FREIGHT_FIELDS.serviceName.label)}
      </Grid>
      <Grid item xs={2}>
        {getField(CUSTOM_FREIGHT_FIELDS.transitTimeDays.name, CUSTOM_FREIGHT_FIELDS.transitTimeDays.label)}
      </Grid>
      <Grid item xs={2}>
        {getField(CUSTOM_FREIGHT_FIELDS.cost.name, CUSTOM_FREIGHT_FIELDS.cost.label)}
      </Grid>
      <Grid item xs={2}>
        {getField(CUSTOM_FREIGHT_FIELDS.markupCost.name, CUSTOM_FREIGHT_FIELDS.markupCost.label)}
      </Grid>
      <Grid item xs={2}>
        {getField(CUSTOM_FREIGHT_FIELDS.currencyCode.name, CUSTOM_FREIGHT_FIELDS.currencyCode.label)}
      </Grid>
      <Tooltip title="Delete" classes={{ tooltip: classes.tooltip }}>
        <Button
          className={classes.iconButton}
          disabled={disableDelete}
          onClick={() => onRemoveRow(itemCode)}
        >
          <DeleteIcon style={{ height: 18 }} color={disableDelete ? colors.disabledButton : 'white'} />
        </Button>
      </Tooltip>
    </>
  );
};

CustomFreightFields.propTypes = {
  itemCode: PropTypes.number.isRequired,
  errors: PropTypes.shape(PropTypes.object).isRequired,
  disableDelete: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onRemoveRow: PropTypes.func.isRequired,
};

export default CustomFreightFields;
