import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
import isString from 'lodash/isString';
import client from './apiClient';
import {
  DUMMY_FETCH_ALL_SHIPMENTS_DATA,
  DUMMY_FETCH_ALL_SHIPMENTS_MANIFEST_DATA,
  DUMMY_FETCH_ALL_IPD_SHIPMENTS_DATA,
  DUMMY_FETCH_IPD_MASTER_TRACKING_DATA,
  DUMMY_FETCH_ALL_HAZMAT_SHIPMENTS_DATA,
  DUMMY_FETCH_ALL_INTENATIONAL_SHIPMENTS_DATA,
} from '../dummyData';

export const roundNumber = (value, decimals = 2) => {
  if (!value) return '';

  let n = value;

  if (isString(n)) n = parseFloat(n);

  return n.toFixed(decimals);
};

// eslint-disable-next-line import/prefer-default-export
export function loadShipments({
  limit, offset, filters,
}) {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    const dummyShipments = [...DUMMY_FETCH_ALL_SHIPMENTS_DATA];

    if (limit && offset) {
      for (let i = 0; DUMMY_FETCH_ALL_SHIPMENTS_DATA.length
          && (i < (limit - DUMMY_FETCH_ALL_SHIPMENTS_DATA.length)); i += 1) {
        // eslint-disable-next-line max-len
        const shipmentToAdd = DUMMY_FETCH_ALL_SHIPMENTS_DATA[i % DUMMY_FETCH_ALL_SHIPMENTS_DATA.length];
        dummyShipments.push(
          {
            ...shipmentToAdd,
            shipmentId: `
        ${shipmentToAdd.shipmentId}
        -${Math.floor(i / DUMMY_FETCH_ALL_SHIPMENTS_DATA.length) + 1}
        ${i % DUMMY_FETCH_ALL_SHIPMENTS_DATA.length + 1}`,
          },
        );
      }
    }

    return Promise.resolve(dummyShipments);
  }

  return client('/data/dashboard/shipment', {
    method: 'get',
    params: {
      limit,
      offset,
      ...filters,
    },
  });
}

export function loadShipmentExport({
  limit, offset, filters,
}) {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    const dummyShipments = [...DUMMY_FETCH_ALL_SHIPMENTS_DATA];

    if (limit && offset) {
      for (let i = 0; DUMMY_FETCH_ALL_SHIPMENTS_DATA.length
          && (i < (limit - DUMMY_FETCH_ALL_SHIPMENTS_DATA.length)); i += 1) {
        // eslint-disable-next-line max-len
        const shipmentToAdd = DUMMY_FETCH_ALL_SHIPMENTS_DATA[i % DUMMY_FETCH_ALL_SHIPMENTS_DATA.length];
        dummyShipments.push(
          {
            ...shipmentToAdd,
            shipmentId: `
        ${shipmentToAdd.shipmentId}
        -${Math.floor(i / DUMMY_FETCH_ALL_SHIPMENTS_DATA.length) + 1}
        ${i % DUMMY_FETCH_ALL_SHIPMENTS_DATA.length + 1}`,
          },
        );
      }
    }

    return Promise.resolve(dummyShipments);
  }

  return client('/data/dashboard/shipment/manifest', {
    method: 'get',
    params: {
      limit,
      offset,
      ...filters,
    },
  });
}
export function loadShipmentManifest({
  limit, offset, filters,
}) {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    console.log(filters);

    const dummyShipments = [...DUMMY_FETCH_ALL_SHIPMENTS_MANIFEST_DATA];
    return Promise.resolve(dummyShipments);
  }
  return client('/data/dashboard/shipmentmanifest', {
    method: 'get',
    params: {
      limit,
      offset,
      ...filters,
    },
  });
}

export function loadHazmatShipments(options = null) {
  let params = {};

  if (['test', 'development'].includes(process.env.NODE_ENV)) {
    return new Promise((resolve) => {
      setTimeout(() => {
        if (!options) resolve(DUMMY_FETCH_ALL_HAZMAT_SHIPMENTS_DATA);

        const lowerCaseFilter = has(options.filters, 'filter') && options.filters.filter.length >= 2
          ? options.filters.filter.toLowerCase()
          : '';

        resolve(DUMMY_FETCH_ALL_HAZMAT_SHIPMENTS_DATA
          .filter((item) => item.shipmentId.toLowerCase().includes(lowerCaseFilter))
          .slice(options.offset, options.offset + options.limit));
      }, 500);
    });
  }

  if (has(options, 'limit')) params.limit = options.limit;
  if (has(options, 'offset')) params.offset = options.offset;
  if (has(options, 'type')) params.type = options.type;
  if (has(options, 'filters')) params = { ...params, ...options.filters };
  if (isEmpty(params.filter)) delete params.filter;

  return client('/data/dashboard/hazmat', {
    method: 'get',
    params: params || {},
  });
}

export function loadInternationalShipments(options = null) {
  let params = {};

  if (['test', 'development'].includes(process.env.NODE_ENV)) {
    return new Promise((resolve) => {
      setTimeout(() => {
        if (!options) resolve(DUMMY_FETCH_ALL_INTENATIONAL_SHIPMENTS_DATA);

        const lowerCaseFilter = has(options.filters, 'filter') && options.filters.filter.length >= 2
          ? options.filters.filter.toLowerCase()
          : '';

        resolve(DUMMY_FETCH_ALL_INTENATIONAL_SHIPMENTS_DATA
          .filter((item) => item.shipmentId.toLowerCase().includes(lowerCaseFilter))
          .slice(options.offset, options.offset + options.limit));
      }, 500);
    });
  }

  if (has(options, 'limit')) params.limit = options.limit;
  if (has(options, 'offset')) params.offset = options.offset;
  if (has(options, 'type')) params.type = options.type;
  if (has(options, 'filters')) params = { ...params, ...options.filters };
  if (isEmpty(params.filter)) delete params.filter;

  return client('/data/dashboard/international', {
    method: 'get',
    params: params || {},
  });
}
export function loadAllShipments(options = null) {
  let params = {};

  if (['test', 'development'].includes(process.env.NODE_ENV)) {
    return new Promise((resolve) => {
      setTimeout(() => {
        if (!options) resolve(DUMMY_FETCH_ALL_INTENATIONAL_SHIPMENTS_DATA);

        const lowerCaseFilter = has(options.filters, 'filter') && options.filters.filter.length >= 2
          ? options.filters.filter.toLowerCase()
          : '';

        resolve(DUMMY_FETCH_ALL_INTENATIONAL_SHIPMENTS_DATA
          .filter((item) => item.shipmentId.toLowerCase().includes(lowerCaseFilter))
          .slice(options.offset, options.offset + options.limit));
      }, 500);
    });
  }

  if (has(options, 'limit')) params.limit = options.limit;
  if (has(options, 'offset')) params.offset = options.offset;
  if (has(options, 'type')) params.type = options.type;
  if (has(options, 'filters')) params = { ...params, ...options.filters };
  if (isEmpty(params.filter)) delete params.filter;

  return client('/data/dashboard/all', {
    method: 'get',
    params: params || {},
  });
}

export function loadIpdShipment({
  limit, offset, filters,
}) {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    console.log(filters);

    const dummyShipments = [...DUMMY_FETCH_ALL_IPD_SHIPMENTS_DATA];
    return Promise.resolve(dummyShipments);
  }
  return client('/data/dashboard/shipment/ipd', {
    method: 'get',
    params: {
      limit,
      offset,
      ...filters,
    },
  });
}

export function loadIpdShipmentCSV({
  shipmentId,
}) {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    const dummyShipments = [...DUMMY_FETCH_ALL_IPD_SHIPMENTS_DATA];
    return Promise.resolve(dummyShipments);
  }
  return client('/data/dashboard/shipment/ipd/csv', {
    method: 'post',
    params: {
      shipmentId,
    },
  });
}
export function loadMasterIpdTracking({ trackingNumber }) {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    console.log(trackingNumber);

    const dummyShipments = [...DUMMY_FETCH_IPD_MASTER_TRACKING_DATA];
    return Promise.resolve(dummyShipments);
  }
  return client(`/data/dashboard/shipment/ipd/${trackingNumber}`, {
    method: 'get',
  });
}
export async function duplicateShipment(payload) {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return { shipments: [{ shipmentId: 'YALE-10000002-01' }] };
  }
  return client('/data/shipment/duplicate', {
    method: 'post',
    data: payload,
  });
}

export async function confirmIpdMasterShipment(shipmentId) {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return {
      info: 'IPD Shipment WOEM-10000200-01 confirmed successfully. You will receive a notification after the documents are generated',
      shipment: {},
    };
  }
  return client(`/data/dashboard/shipment/ipd/confirm/${shipmentId}`, {
    method: 'put',
  });
}
