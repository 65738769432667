import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import {
  Grid, Typography,
} from '@material-ui/core';
import NewOrderCustomTextField from '../../common/NewOrderCustomTextField';
import { FORM_FIELDS, FORM_LABELS } from '../itemDetailsConstants';
import { blockWeightAndDimensionInputs, isFieldEditable } from '../itemDetailsUtil';
import { useItemProductDetailsFormStyles } from '../itemDetailsStyles';

export default function TaxCode(props) {
  const { editableFields, isRequired } = props;
  const classes = useItemProductDetailsFormStyles();
  return (
    <Grid item container direction="column" spacing={1}>
      <Grid item>
        <Typography color="primary" className={classes.formFieldHeader}>{FORM_LABELS.taxCode}</Typography>
      </Grid>
      <Grid item container spacing={1}>
        <Grid item>
          <Field
            name={FORM_FIELDS.taxCode.key}
            label={FORM_FIELDS.taxCode.label}
            component={NewOrderCustomTextField}
            blockValue={blockWeightAndDimensionInputs}
            type="text"
            style={{ width: '100%' }}
            disabled={isFieldEditable(FORM_FIELDS.taxCode.key, editableFields)}
            validate={(val) => {
              if (!(val) && isRequired) {
                return 'Required';
              }
              return undefined;
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

TaxCode.propTypes = {
  editableFields: PropTypes.arrayOf(PropTypes.string).isRequired,
  isRequired: PropTypes.bool.isRequired,
};
