import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { Grid, MenuItem, Typography } from '@material-ui/core';
import CustomSelectTextField from '../../common/CustomSelectTextField';

const FreightType = ({
  classes,
  shipmentFreightTypes,
}) => (
  <Grid item container direction="column" spacing={1}>
    <Grid item>
      <Typography color="primary" className={classes.formFieldHeader}>Freight type</Typography>
    </Grid>
    <Grid item xs={8}>
      <Field
        component={CustomSelectTextField}
        style={{ width: '100%' }}
        type="text"
        name="shipmentFreightType"
        label="Select Freight type"
        data-testid="freight-input"
      >
        {shipmentFreightTypes && shipmentFreightTypes.map((type) => (
          <MenuItem
            key={type.value}
            value={type.value}
            data-testid="freight-menu-item"
          >
            {type.label}
          </MenuItem>
        ))}
      </Field>
    </Grid>
  </Grid>
);

FreightType.defaultProps = {
  shipmentFreightTypes: [],
};

FreightType.propTypes = {
  classes: PropTypes.objectOf(PropTypes.object).isRequired,
  shipmentFreightTypes: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  })),
};

export default memo(FreightType);
