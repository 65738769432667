import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import floor from 'lodash/floor';
import divide from 'lodash/divide';
import toNumber from 'lodash/toNumber';
import round from 'lodash/round';
import toString from 'lodash/toString';
import { Field } from 'formik';
import {
  Grid, Typography, MenuItem,
} from '@material-ui/core';
import CustomSelectTextField from '../../common/CustomSelectTextField';
import NewOrderCustomTextField from '../../common/NewOrderCustomTextField';
import {
  FORM_FIELDS, FORM_LABELS, WEIGHT_SYSTEM_MAPPING, SMALL_WEIGHT_LIMIT_MAPPING,
} from '../itemDetailsConstants';
import { useItemProductDetailsFormStyles } from '../itemDetailsStyles';
import { isValidNumber } from '../itemDetailsUtil';

export default function Calculated({
  formikProps,
  currencyUnits,
  weightUnits,
  smallWeightUnits,
}) {
  function getBigWeightToAdd(weight, smallWeight) {
    const weightSystem = WEIGHT_SYSTEM_MAPPING[weightUnit];
    const smallWeightMax = SMALL_WEIGHT_LIMIT_MAPPING[weightSystem];
    if (weight < smallWeightMax) return;

    const weightToAdd = floor(divide(weight, smallWeightMax));

    return Math.max(weightToAdd, 0);
  }

  const formikUnitValue = formikProps?.values?.units?.unitValue?.amount;
  const formikNoOfUnits = formikProps?.values?.units?.noOfUnits;
  const formikWeight = formikProps?.values?.itemWeight?.weight;
  const formikSmallWeight = formikProps?.values?.itemWeight?.weightSmall;
  const currencyCode = get(formikProps.values, FORM_FIELDS.currencyCode.key);
  const weightUnit = get(formikProps.values, FORM_FIELDS.weightUnit.key);
  const smallWeightUnit = get(formikProps.values, FORM_FIELDS.smallWeightUnit.key);

  React.useEffect(() => {
    formikProps.setFieldValue(FORM_FIELDS.totalValueUnit.key, currencyCode);
  }, [currencyCode]);
  React.useEffect(() => {
    formikProps.setFieldValue(FORM_FIELDS.totalWeightUnit.key, weightUnit);
  }, [weightUnit]);
  React.useEffect(() => {
    formikProps.setFieldValue(FORM_FIELDS.totalSmallWeightUnit.key, smallWeightUnit);
  }, [smallWeightUnit]);

  React.useEffect(() => {
    const unitToNumber = toNumber(formikUnitValue);
    const noToNumber = toNumber(formikNoOfUnits);
    const weightToNumber = toNumber(formikWeight);
    const weightSmallToNumber = toNumber(formikSmallWeight);

    if (isValidNumber(unitToNumber) && isValidNumber(noToNumber)) {
      const productValue = round(unitToNumber * noToNumber, 2);
      formikProps.setFieldValue(FORM_FIELDS.productValue.key, toString(productValue));
    } else {
      formikProps.setFieldValue(FORM_FIELDS.productValue.key, '');
    }

    if (isValidNumber(weightToNumber) && isValidNumber(noToNumber)) {
      const totalWeight = round(weightToNumber * noToNumber, 2);
      formikProps.setFieldValue(FORM_FIELDS.totalWeight.key, toString(totalWeight));
    } else {
      formikProps.setFieldValue(FORM_FIELDS.totalWeight.key, '');
    }

    if (isValidNumber(weightSmallToNumber) && (isValidNumber(noToNumber) && noToNumber !== 0)) {
      const weightSystem = WEIGHT_SYSTEM_MAPPING[weightUnit];
      const smallWeightMax = SMALL_WEIGHT_LIMIT_MAPPING[weightSystem];

      const totalWeightSmall = round(weightSmallToNumber * noToNumber, 2);

      const bigWeightToAdd = getBigWeightToAdd(totalWeightSmall, weightSmallToNumber);

      const smallWeightToAdd = weightSmallToNumber !== smallWeightMax ? totalWeightSmall % smallWeightMax : smallWeightMax;
      const totalWeight = round(weightToNumber * noToNumber, 2) || 0;
      bigWeightToAdd && formikProps.setFieldValue(FORM_FIELDS.totalWeight.key, toString(totalWeight + bigWeightToAdd));
      formikProps.setFieldValue(FORM_FIELDS.totalSmallWeight.key, toString(smallWeightToAdd));
    } else {
      formikProps.setFieldValue(FORM_FIELDS.totalSmallWeight.key, '');
    }
  }, [formikUnitValue, formikNoOfUnits, formikWeight, formikSmallWeight, weightUnit, smallWeightUnit]);

  const classes = useItemProductDetailsFormStyles();

  const totalWeightUnit = get(formikProps.values, FORM_FIELDS.totalWeightUnit.key);
  const totalSmallWeightUnit = get(formikProps.values, FORM_FIELDS.totalSmallWeightUnit.key);

  return (
    <Grid item container direction="column" spacing={1}>
      <Grid item>
        <Typography color="primary" className={classes.formFieldHeader}>{FORM_LABELS.calculated}</Typography>
      </Grid>
      <Grid item container spacing={1}>
        <Grid item xs={4}>
          <Field
            name={FORM_FIELDS.productValue.key}
            label={FORM_FIELDS.productValue.label}
            component={NewOrderCustomTextField}
            type="text"
            style={{ width: '100%' }}
            disabled
          />
        </Grid>
        <Grid item xs={2}>
          <Field
            name={FORM_FIELDS.totalValueUnit.key}
            label={currencyCode ? '' : FORM_FIELDS.totalValueUnit.key}
            component={CustomSelectTextField}
            type="text"
            style={{ width: '100%' }}
            disabled
          >
            {currencyUnits.map((option) => (
              <MenuItem
                key={option.currencyCode}
                value={option.currencyCode}
              >
                {option.currencyCode}
              </MenuItem>
            ))}
          </Field>
        </Grid>
      </Grid>
      <Grid item container spacing={1}>
        <Grid item xs={4}>
          <Field
            name={FORM_FIELDS.totalWeight.key}
            label={FORM_FIELDS.totalWeight.label}
            component={NewOrderCustomTextField}
            type="text"
            style={{ width: '100%' }}
            disabled
          />
        </Grid>
        <Grid item xs={2}>
          <Field
            name={FORM_FIELDS.totalWeightUnit.key}
            label={totalWeightUnit ? '' : FORM_FIELDS.totalWeightUnit.label}
            component={CustomSelectTextField}
            type="text"
            style={{ width: '100%' }}
            disabled
          >
            {weightUnits.map((option) => (
              <MenuItem
                key={option.weightCode}
                value={option.weightCode}
              >
                {option.weightCode}
              </MenuItem>
            ))}
          </Field>
        </Grid>

        <Grid item xs={4}>
          <Field
            name={FORM_FIELDS.totalSmallWeight.key}
            label={FORM_FIELDS.totalSmallWeight.label}
            component={NewOrderCustomTextField}
            type="text"
            style={{ width: '100%' }}
            disabled
          />
        </Grid>
        <Grid item xs={2}>
          <Field
            name={FORM_FIELDS.totalSmallWeightUnit.key}
            label={totalSmallWeightUnit ? '' : FORM_FIELDS.totalSmallWeightUnit.label}
            component={CustomSelectTextField}
            type="text"
            style={{ width: '100%' }}
            disabled
          >
            {smallWeightUnits.map((option) => (
              <MenuItem
                key={option.weightCode}
                value={option.weightCode}
              >
                {option.weightCode}
              </MenuItem>
            ))}
          </Field>
        </Grid>
      </Grid>
    </Grid>
  );
}

Calculated.propTypes = {
  formikProps: PropTypes.objectOf(PropTypes.any).isRequired,
  currencyUnits: PropTypes.arrayOf(PropTypes.any).isRequired,
  weightUnits: PropTypes.arrayOf(PropTypes.any).isRequired,
  smallWeightUnits: PropTypes.arrayOf(PropTypes.any).isRequired,
};
