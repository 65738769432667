import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  AppBar, Tabs, Tab,
} from '@material-ui/core/';
import { RECIPIENT_FORM_TABS_WITH_IDS } from '../../clientConstants';
import NewOrderTabPanel from '../common/NewOrderTabPanel';
import NewOrderSenderRecipientAddress from '../SenderAndRecipientPanes/NewOrderSenderRecipientAddress';
import NewOrderSenderRecipientContacts from '../SenderAndRecipientPanes/NewOrderSenderRecipientContacts';
import NewOrderErrorDialog from '../common/NewOrderErrorDialog';
import { useContactsDispatch, updateContactsState } from '../../context/contactsContext';
import { NEW_ORDER_SENDER_RECIPIENT_STYLE, NEW_ORDER_TABS_STYLE } from '../../styles/style';
import { sanitizeAddress } from '../../utils/helpers';


const SELECT_FROM_CONTACTS_TAB_VALUE = 'one';
const ENTER_ADDRESS_TAB_VALUE = 'two';

const CustomAppBar = ({ selectedTab, classes, onChange }) => {
  return (
    <AppBar position="static" classes={{ root: classes.appBar }}>
      <Tabs
        textColor="primary"
        value={selectedTab}
        onChange={onChange}
        classes={{ indicator: classes.appBarIndicator }}
      >
        {
          RECIPIENT_FORM_TABS_WITH_IDS.map((tab) => (
            <Tab
              key={`recipient-tabs-${tab.name}`}
              value={tab.value}
              label={tab.label}
              wrapped
              classes={{
                root: classes.appBarTab,
                disabled: classes.disabled,
                selected: classes.selected,
              }}
            />
          ))
        }
      </Tabs>
    </AppBar>
  );
}

CustomAppBar.propTypes = {
  selectedTab: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    appBar: PropTypes.string.isRequired,
    appBarIndicator: PropTypes.string.isRequired,
    appBarTab: PropTypes.string.isRequired,
    disabled: PropTypes.string.isRequired,
    selected: PropTypes.string.isRequired,
  }).isRequired,
};

const CustomContacts = (props) => {
  const { selectedTab, customsBroker, ...other } = props;
  const { onSetRecipient, onEditContact } = props;
  const { classes } = other;

  return (
    <NewOrderTabPanel
      index={SELECT_FROM_CONTACTS_TAB_VALUE}
      value={selectedTab}
      classes={classes}
    >
      <NewOrderSenderRecipientContacts
        {...other}
        contact={customsBroker}
        tabValue={SELECT_FROM_CONTACTS_TAB_VALUE}
        handleSubmit={onSetRecipient}
        handleEdit={onEditContact}
      />
    </NewOrderTabPanel>
  );
}

CustomContacts.propTypes = {
  selectedTab: PropTypes.string.isRequired,
  customsBroker: PropTypes.objectOf(PropTypes.any).isRequired,
  onSetRecipient: PropTypes.func.isRequired,
  onEditContact: PropTypes.func.isRequired,
};

function CustomEnterAddress(props) {
  const { selectedTab, customsBroker, ...other } = props;
  const { onSetRecipient } = props;
  const { classes } = other;

  return (
    <NewOrderTabPanel
      index={ENTER_ADDRESS_TAB_VALUE}
      value={selectedTab}
      classes={classes}
    >
      <NewOrderSenderRecipientAddress
        {...other}
        contact={customsBroker}
        tabValue={ENTER_ADDRESS_TAB_VALUE}
        handleSubmit={onSetRecipient}
        labelHeader="Customs Broker"
      />
    </NewOrderTabPanel>
  );
}

CustomEnterAddress.propTypes = {
  selectedTab: PropTypes.string.isRequired,
  customsBroker: PropTypes.objectOf(PropTypes.any).isRequired,
  onSetRecipient: PropTypes.func.isRequired,
};
 export default function CustomsBrokerTabs({
  selectedForms, 
  shipment, submitFormToApi, openNextPanel,
}) {
  const [selectedTab, setSelectedTab] = useState(SELECT_FROM_CONTACTS_TAB_VALUE);
  const [editAddress, setEditAddress] = React.useState({});
  const [resetSearchCache, setResetSearchCache] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorContent, setErrorContent] = useState(null);

  const contactsDispatch = useContactsDispatch();

  const customsBroker = shipment?.customsBroker;

  const classes = NEW_ORDER_TABS_STYLE();
  const childClasses = NEW_ORDER_SENDER_RECIPIENT_STYLE();

  const commonProps = {
    customsBroker,
    selectedTab,
    onSetRecipient,
    onEditContact,
  };

  useEffect(() => {
    if (!customsBroker) return;

    setSelectedTab((customsBroker?.address?.addressId)
      ? SELECT_FROM_CONTACTS_TAB_VALUE
      : ENTER_ADDRESS_TAB_VALUE);
  }, [customsBroker]);

  /*          CALLBACKS           */

  function onTabChange(e, newValue) {
    setSelectedTab(newValue);
  }

  function onEditContact(address) {
    setEditAddress({ ...address, updateContacts: true });
    setSelectedTab(ENTER_ADDRESS_TAB_VALUE);
  }

  async function onSetRecipient(recipient) {
  
    const {
      updateContacts, addressId,
    } = recipient;
    const address = sanitizeAddress(recipient);

    const customsBrokerDetails = {
      isCustomsBrokerAvailable: true,
      updateContacts: updateContacts || false,
      address,
    };

    try {
      await submitFormToApi(customsBrokerDetails);
    } catch (e) {
      console.error(e);
      return;
    }

    if (!updateContacts) {
      openNextPanel();
      return;
    }

    const { isDefault } = recipient;
    if (addressId) {
      try {
        updateContactsState({
          dispatch: contactsDispatch,
          contact: {
            ...address,
            ...(isDefault) && { isDefault },
          },
        });
      } catch (e) {
        setErrorContent(e.message);
        setIsError(true);
      }
    } else {
      setResetSearchCache(true);
    }

    openNextPanel();
  }

  return (
    <>
      <div className={classes.root}>
        <CustomAppBar
          selectedTab={selectedTab}
          onChange={onTabChange}
          classes={childClasses}
        />
        <CustomContacts
          {...commonProps}
          selectedForms={selectedForms}
          resetSearchCache={resetSearchCache}
          setResetSearchCache={setResetSearchCache}
          classes={childClasses}
        />
        <CustomEnterAddress
          {...commonProps}
          selectedForms={selectedForms}
          editAddress={editAddress}
          classes={childClasses}
        />
      </div>
      <NewOrderErrorDialog
        open={isError}
        errorContent={errorContent}
        classes={childClasses}
        onClose={() => {
          setIsError(false);
          setErrorContent(null);
        }}
      />
    </>
  );
}

CustomsBrokerTabs.propTypes = {
  shipmentId: PropTypes.string.isRequired,
  selectedForms: PropTypes.objectOf(PropTypes.string.isRequired).isRequired,
  setSelectedForms: PropTypes.func.isRequired,
  submitFormToApi: PropTypes.func.isRequired,
};


