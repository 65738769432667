/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'emotion';

import DoneIcon from '@material-ui/icons/Done';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Divider from '@material-ui/core/Divider';
import Popover from '@material-ui/core/Popover';
import AssignmentIcon from '@material-ui/icons/AssignmentTurnedIn';
import NotificationsNoneOutlinedIcon from '@material-ui/icons/NotificationsNoneOutlined';
import { withStyles } from '@material-ui/core/styles';
import iotIcon from './images/rss-white.svg';
import { formatDateLong } from '../utils/helpers';
import * as colors from '../styles/colors';
import ScrollWindow from './common/ScrollWindow';
import { SHIPMENT_TRACKING_TAB, IOT_TRACKING_TAB } from '../clientConstants';

const StyledPopover = withStyles({
  paper: {
    marginTop: '20px',
    width: 320,
    height: 444,
    backgroundColor: colors.notificationPanelBackground,
    display: 'flex',
    flexDirection: 'column',
  },
})(Popover);

const StyledDivider = withStyles({
  root: {
    color: '#323543',
    height: 2,
  },
})(Divider);

const StyledListItemText = withStyles({
  primary: {
    color: colors.white,
    fontSize: 14,
    fontWeight: 'bold',
    letterSpacing: 0.25,
  },
  secondary: {
    color: 'rgba(255,255,255,0.6)',
    fontSize: 12,
    fontWeight: 500,
    letterSpacing: 0.4,
  },
})(ListItemText);

function isShipmentRelatedNotification(notificationType) {
  return (notificationType === 'shipment' || notificationType === 'iot');
}

function isIotRelatedNotification(notificationType) {
  return notificationType === 'iot';
}

function NoNotifications(props) {
  const classes = {
    container: css`
      width: 100%;
      height: calc(100% - 50px);
    `,
    icon: css`
      color: #888991;
      height: 96.62px;
      width: 97.23px;
    `,
    avatar: css`
      background-color: #212435;
      height: 157px;
      width: 158px;
    `,
    message: css`
      color: #B5B9CC;
      font-size: 14px;
      letter-spacing: .1px;
    `,
    messageContainer: css`
      padding-top: 30px;
    `,
  };

  return (
    <Grid
      className={classes.container}
      container
      direction="column"
      justify="center"
      alignItems="center"
    >
      <Grid item>
        <Avatar className={classes.avatar}>
          <NotificationsNoneOutlinedIcon className={classes.icon} />
        </Avatar>
      </Grid>
      <Grid item className={classes.messageContainer}>
        <Typography className={classes.message}>You&apos;re all caught up</Typography>
      </Grid>
    </Grid>
  );
}

function TrackingButton(props) {
  const {
    handleTrackingModalOpen,
    notificationId,
    shipmentId,
    shipmentTrackingNumber,
    handleNotificationPanelClose,
    isIotRelated,
    iotTrackingNumber,
  } = props;
  const classes = {
    button: css`
      padding: 0px;
      text-align: left;
      color: ${colors.secondaryButton};
      font-size: 14px;
      font-weight: 500px;
      text-transform: none;
    `,
  };

  return (
    <Button
      className={classes.button}
      id={`tracking-button-${notificationId}`}
      onClick={(e) => {
        handleNotificationPanelClose();
        handleTrackingModalOpen({
          shipmentId,
          trackingNumber: isIotRelated ? null : shipmentTrackingNumber,
          openTab: isIotRelated ? IOT_TRACKING_TAB : SHIPMENT_TRACKING_TAB,
          iotTrackingNumber: isIotRelated ? iotTrackingNumber : null,
        });
      }}
    >
      Track shipment
    </Button>
  );
}

TrackingButton.propTypes = {
  shipmentId: PropTypes.string.isRequired,
  notificationId: PropTypes.string.isRequired,
  shipmentTrackingNumber: PropTypes.string,
  handleTrackingModalOpen: PropTypes.func.isRequired,
  handleNotificationPanelClose: PropTypes.func.isRequired,
  isIotRelated: PropTypes.bool.isRequired,
  iotTrackingNumber: PropTypes.string,
};

TrackingButton.defaultProps = {
  shipmentTrackingNumber: '',
  iotTrackingNumber: '',
};

function Notification(props) {
  const {
    dateCreated,
    message,
    orderId,
    shipmentId,
    notificationId,
    handleClick,
    handleTrackingModalOpen,
    shipmentTrackingNumber,
    handleNotificationPanelClose,
    isShipmentRelatedNotification,
    isIotRelated,
    iotTrackingNumber,
  } = props;

  const classes = {
    icon: css`
      color: ${colors.white};
    `,
    readIcon: css`
      color: ${colors.white};
      height: 14px;
      width: 14px;
    `,
    readIconAvatar: css`
      background-color: #5B5D68;
      height: 14px;
      width: 14px;
    `,
    readButton: css`
      padding: 0px;
    `,
    secondaryAction: css`
      top: 20%;
    `,
    list: css`
     list-style: none;
    `,
  };

  return (
    <div
      data-testid="notification-panel-item"
    >
      <ListItem classes={{ container: classes.list }}>
        <ListItemIcon>
          { isShipmentRelatedNotification
            ? isIotRelated
              ? <img src={iotIcon} className={classes.icon} style={{ width: '1.5rem' }} />
              : <LocalShippingIcon className={classes.icon} />
            : <AssignmentIcon className={classes.icon} />}
        </ListItemIcon>
        <StyledListItemText
          primary={(
            <>
              {message}
              {isShipmentRelatedNotification ? <br /> : ' '}
              {shipmentId}
            </>
          )}
          secondary={(
            <>
              {formatDateLong(dateCreated)}
              {isShipmentRelatedNotification
              && (
              <TrackingButton
                id={`tracking-button-${notificationId}`}
                notificationId={notificationId}
                handleTrackingModalOpen={handleTrackingModalOpen}
                shipmentId={shipmentId}
                shipmentTrackingNumber={shipmentTrackingNumber}
                handleNotificationPanelClose={handleNotificationPanelClose}
                isIotRelated={isIotRelated}
                iotTrackingNumber={iotTrackingNumber}
              />
              )}
            </>
          )}
        />
        <ListItemSecondaryAction className={classes.secondaryAction}>
          <Tooltip placement="right" title="Mark as read">
            <IconButton
              aria-label="mark as read"
              size="small"
              className={classes.readButton}
              onClick={() => handleClick(notificationId)}
            >
              <Avatar className={classes.readIconAvatar}>
                <DoneIcon className={classes.readIcon} />
              </Avatar>
            </IconButton>
          </Tooltip>
        </ListItemSecondaryAction>
      </ListItem>
      <StyledDivider component="li" />
    </div>
  );
}

Notification.propTypes = {
  dateCreated: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  orderId: PropTypes.string,
  shipmentId: PropTypes.string.isRequired,
  notificationId: PropTypes.string.isRequired,
};

Notification.defaultProps = {
  orderId: null,
};

function NotificationPanelHeader(props) {
  const { handleClick } = props;
  const classes = {
    container: css`
      width: 100%;
      color: ${colors.white};
      padding: 12px;
    `,
    text: css`
      color: rgba(255,255,255,0.6);
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 1.25px;
    `,
    icon: css`
      color: #93C9F6;
    `,
    iconButton: css`
      padding: 0px;
    `,
  };
  return (

    <Grid container className={classes.container} justify="space-between">
      <Grid item>
        <Typography className={classes.text}>NOTIFICATIONS</Typography>
      </Grid>
      <Grid item>
        <Tooltip placement="right" title="Mark all as read">
          <IconButton
            aria-label="Mark all as read"
            className={classes.iconButton}
            onClick={handleClick}
          >
            <DoneAllIcon className={classes.icon} />
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  );
}

NotificationPanelHeader.propTypes = {
  handleClick: PropTypes.func.isRequired,
};

export default function NotificationPanel(props) {
  const {
    isOpen, handleClose, anchorEl, notifications,
    handleMarkAllAsReadClick,
    handleMarkAsReadClick,
    handleTrackingModalOpen,
  } = props;

  const classes = {
    divider: css`
      width: 100%;
    `,
    notificationsContainer: css`
      flex-grow: 1
    `,
  };

  return (
    <StyledPopover
      id="notifications-panel"
      data-testid="notifications-panel"
      open={isOpen}
      onClose={handleClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <NotificationPanelHeader
        handleClick={handleMarkAllAsReadClick}
      />
      <StyledDivider className={classes.divider} />
      <Grid item container className={classes.notificationsContainer}>
        {
        notifications.length
          ? (
            <ScrollWindow>
              {notifications && notifications.map((notification) => (
                <Notification
                  key={`notification-${notification.notificationId}`}
                  id={`notification-${notification.notificationId}`}
                  handleClick={handleMarkAsReadClick}
                  notificationId={notification.notificationId}
                  shipmentTrackingNumber={
                    isShipmentRelatedNotification(notification.notificationType)
                      ? notification.trackingNumber
                      : null
                  }
                  isShipmentRelatedNotification={isShipmentRelatedNotification(notification.notificationType)}
                  isIotRelated={isIotRelatedNotification(notification.notificationType)}
                  message={notification.info}
                  orderId={notification.orderId}
                  shipmentId={notification.shipmentId}
                  dateCreated={notification.notificationDateTime}
                  handleTrackingModalOpen={handleTrackingModalOpen}
                  handleNotificationPanelClose={handleClose}
                  iotTrackingNumber={notification.iotTrackingNumber}
                />
              ))}
            </ScrollWindow>
          )
          : <NoNotifications />
        }
      </Grid>

    </StyledPopover>
  );
}

NotificationPanel.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleMarkAsReadClick: PropTypes.func.isRequired,
  handleTrackingModalOpen: PropTypes.func.isRequired,
  handleMarkAllAsReadClick: PropTypes.func.isRequired,
  notifications: PropTypes.array.isRequired,
  anchorEl: PropTypes.instanceOf(Element),
};
