/* eslint-disable no-multi-assign */
/* eslint-disable no-underscore-dangle */
import * as pdfjs from 'pdfjs';
import statusHelp from './status';
import printUtil from './printUtil';

export function combineDocuments(documents) {
  const input = [];
  const inputData = documents
    .filter((doc) => doc.document)
    .map((doc) => ({
      documentName: doc.documentName,
      data: Buffer.from(doc.document, 'base64'),
    }));
  for (let i = 0; i < inputData.length; i += 1) {
    input.push(inputData[i].data);
    if (inputData[i].documentName === 'Commercial Invoice') {
      input.push(inputData[i].data);
      input.push(inputData[i].data);
    }
  }
  if (input.length <= 0) {
    return;
  }

  const combinedDoc = new pdfjs.Document();

  for (const doc of input) {
    combinedDoc.addPagesOf(new pdfjs.ExternalDocument(doc));
  }
  combinedDoc.asBuffer().then(printPDF);
}

export function printPDFFireFox(buffer) {
  const iFrameContainer = document.getElementById('iFrameContainer');
  const newBlob = new Blob([buffer], { type: 'application/pdf' });
  const url = URL.createObjectURL(newBlob);
  let iframe = iFrameContainer._printIframe;
  if (!iFrameContainer._printIframe) {
    iframe = iFrameContainer._printIframe = document.createElement('iframe');
  }
  iFrameContainer.appendChild(iframe);

  iframe.style.height = 0;
  iframe.style.overflow = 'hidden';
  iframe.onload = function () {
    setTimeout(() => {
      iframe.focus();
      iframe.contentWindow.print();
    }, 1000);
  };
  iframe.src = url;
}

export function printPDF(buffer) {
  const isFireFox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
  if (isFireFox) {
    printPDFFireFox(buffer);
    return;
  }

  const iFrameContainer = document.getElementById('iFrameContainer');
  const newBlob = new Blob([buffer], { type: 'application/pdf' });

  const url = URL.createObjectURL(newBlob);
  const oldIFrame = document.getElementById('iframepdf');
  if (oldIFrame) oldIFrame.remove();
  const newIFrame = document.createElement('iframe');
  newIFrame.id = 'iframepdf';
  newIFrame.src = url;

  newIFrame.onload = (e) => {
    const { contentWindow } = e.target;
    try {
      contentWindow.print();
      return;
    } catch (err) {
      console.error(err);
    }

    window.open(url);
  };
  iFrameContainer.appendChild(newIFrame);
}
export function printThermal(device, data) {
  try {
    printUtil.print(device, data);
    return;
  } catch (err) {
    console.error(err);
  }
}

export function createAndPrintPDF(base64Data) {
  const arrBuffer = base64ToArrayBuffer(base64Data);
  printPDF(arrBuffer);
}

export function arrayBufferToURL(arrayBuffer) {
  const newBlob = new Blob([arrayBuffer], { type: 'application/pdf' });

  return URL.createObjectURL(newBlob);
}

export function base64ToURL(base64Data) {
  const buffer = base64ToArrayBuffer(base64Data);
  return arrayBufferToURL(buffer);
}

export function base64ToArrayBuffer(data) {
  const binaryString = window.atob(data);
  const binaryLen = binaryString.length;
  const bytes = new Uint8Array(binaryLen);
  for (let i = 0; i < binaryLen; i += 1) {
    const ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes;
}

export function arrayBufferToBase64(arrayBuffer) {
  let binary = '';
  const bytes = new Uint8Array(arrayBuffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i += 1) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

export function sortDocuments(a, b) {
  let val = 0;
  if (a.documentName < b.documentName) {
    val = -1;
  }
  if (b.documentName < a.documentName) {
    val = 1;
  }
  const aDeleted = a?.documentName?.match(/\(deleted\)/i);
  const bDeleted = b?.documentName?.match(/\(deleted\)/i);
  if (aDeleted && !bDeleted) {
    val = 1;
  }
  if (bDeleted && !aDeleted) {
    val = -1;
  }
  return val;
}

const STATUS = 'status';
const CARRIER = 'carrier';
const DOCUMENTATION = 'documentation';
const DOCUMENTATION_COMPLETE = 'isDocumentsSubmitted';

export function isModuleComplete(shipment) {
  return (
    shipment
    && CARRIER in shipment
    && shipment[CARRIER].details
    && shipment[DOCUMENTATION_COMPLETE]
    && isShipmentConfirmed(shipment)
  );
}

function isShipmentConfirmed(shipment) {
  return (
    shipment && STATUS in shipment && statusHelp.isCompleted(shipment[STATUS])
  );
}
