/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
import React, { useState } from 'react';
import get from 'lodash/get';
import has from 'lodash/has';
import pick from 'lodash/pick';
import map from 'lodash/map';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import {
  Divider, Grid, Typography, FormControlLabel,
  CircularProgress,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { fieldToCheckbox } from 'formik-material-ui';
import uniqBy from 'lodash/uniqBy';
import { CheckCircleOutline, RemoveCircleOutline } from '@material-ui/icons';
import * as colors from '../styles/colors';
import { useSingleOrderState } from '../context/singleOrderContext';
import { useUserState } from '../context/userContext';
import StyledCheckbox from './common/StyledCheckbox';
import UserAvatar from './common/UserAvatar';
import { disableSubmitOnEnterKey } from '../utils/helpers';
import NewOrderNextButton from './common/NewOrderNextButton';
import { CustomTextArea } from './common/InputComponents';
import { NEW_ORDER_SCHEDULE_PICKUP_STYLE } from '../styles/style';
import { LICENSES_MAPPING, LICENSE_KEYS } from './ItemProductDetails/itemDetailsConstants';
import { RerunComplianceButton } from './RerunComplianceButton';

const styles = {
  innerFormContainer: {
    padding: 15,
    margin: '10px 0',
    background: colors.darkBlueBackground,
  },
  label: {
    color: colors.white,
    fontSize: 16,
    fontWeight: 'normal',
    lineHeight: 1.75,
    letterSpacing: 0.5,
  },
  labelResult: {
    color: colors.limeGreem,
  },
  labelResultFailed: {
    color: colors.failedRed,
  },
  description: {
    fontSize: 14,
    color: colors.textLightGrey,
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: 0.1,
  },

  labelECCN: {
    fontSize: 14,
    color: colors.white,
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: 0.1,
    textTransform: 'uppercase',
  },

  noteContainer: {
    height: '116px',
    overflowY: 'scroll',
    scrollbarColor: '#313547 #151722',
  },
  note: {
    fontSize: 14,
    color: colors.textLightGrey,
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: 0.1,
  },

  formFieldSubHeader: {
    fontSize: '14px',
    fontWeight: 500,
  },
  alertModal: {
    height: 'auto',
    width: 650,
    maxWidth: 'none',
    background: colors.newOrderFormBackground,
    padding: '35px 25px 10px 25px',
    borderRadius: 8,
    position: 'relative',
  },
  checkIcon: {
    color: colors.checkValidGreen,
    width: 23,
  },
  closeIcon: {
    color: colors.failedRed,
    width: 23,
  },
  button: {
    marginTop: 15,
    marginBottom: 15,
    paddingLeft: '50px',
    paddingRight: '50px',
  },
  miscLoadingContainer: {
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingTop: 24,
  },
  rejectButton: {
    background: colors.errorRed,
  },
  bottomPanel: {
    paddingTop: 20,
  },
};

const useStyles = makeStyles(styles);

const StyledDivider = withStyles({
  root: {
    color: '#313547',
    height: 1,
  },
})(Divider);

const ListItem = ({
  label, status, success, eccn, notes = [], training, material, permit, keyword, user, isApproved,
}) => {
  const classes = useStyles();

  return (

    <Grid
      item
      container
      justify="flex-start"
      spacing={1}
      className={classes.innerFormContainer}
    >
      <Grid item xs={1}>
        {success ? (
          <CheckIcon className={classes.checkIcon} />
        ) : (
          <CloseIcon className={classes.closeIcon} />
        )}
      </Grid>
      <Grid item container xs={11} spacing={1}>
        <Grid item>
          <Typography className={classes.label}>{label}</Typography>
        </Grid>
        <Grid item>
          <Typography
            className={`${classes.label} ${success && classes.labelResult} ${
              !success && classes.labelResultFailed
            }`}
          >
            {success ? 'Success' : 'Failed'}
          </Typography>
        </Grid>
        <Grid item />
        <Grid xs={12} item>
          <StyledDivider component="hr" />
        </Grid>
        <Grid xs={12} item>
          <Typography className={classes.description}>{status}</Typography>
        </Grid>
        {notes && notes.length > 0 && (
          <Grid xs={12} item container className={classes.noteContainer}>
            {notes.map(({ title, subtitle, note }, i) => (
              <Grid xs={12} item key={i}>
                <Typography className={classes.note}>
                  {`${title ? `${title}-` : ''}${
                    subtitle ? `${subtitle}-` : ''
                  }${note}`}
                </Typography>
              </Grid>
            ))}
          </Grid>
        )}

        {eccn && eccn.length > 0 && (
          <Grid xs={12} item container>
            <Grid item container>
              <Grid item xs={4}>
                <Typography className={classes.labelECCN}>ECCN</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography className={classes.labelECCN}>
                  Destination
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography className={classes.labelECCN}>Status</Typography>
              </Grid>
            </Grid>
            {eccn.map(
              ({
                success: s, eccn: e, destination: d, status: st,
              }, i) => (
                <Grid item container key={i}>
                  <Grid item xs={4}>
                    <Typography
                      className={`${classes.label} ${
                        s && classes.labelResult
                      } ${!s && classes.labelResultFailed}`}
                    >
                      {e}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      className={`${classes.label} ${
                        s && classes.labelResult
                      } ${!s && classes.labelResultFailed}`}
                    >
                      {d}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      className={`${classes.label} ${
                        s && classes.labelResult
                      } ${!s && classes.labelResultFailed}`}
                    >
                      {st}
                    </Typography>
                  </Grid>
                </Grid>
              ),
            )}
          </Grid>
        )}
        {training && training.length > 0 && (
          <Grid xs={12} item container>
            <Grid item container justifyContent="space-between">
              <Grid item xs={4}>
                <Typography className={classes.labelECCN}>
                  {training[0].shipClass ? 'Shipping Class' : 'Product Name'}
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography className={classes.labelECCN}>Status</Typography>
              </Grid>
            </Grid>
            {uniqBy(training, (e) => e.notes[0] + e.shipClass).map(
              ({ notes: n, shipClass: s, itemName: it }, i) => (
                <Grid item container key={i} justifyContent="space-between">
                  <Grid item xs={4}>
                    <Typography
                      className={`${classes.label} ${classes.labelResultFailed}`}
                    >
                      {s || it}
                    </Typography>
                  </Grid>

                  <Grid item xs={7}>
                    <Typography
                      className={`${classes.label}  ${classes.labelResultFailed}`}
                    >
                      {n.join(',')}
                    </Typography>
                  </Grid>
                </Grid>
              ),
            )}
          </Grid>
        )}
        {material && material.length > 0 && (
          <Grid xs={12} item container>
            <Grid item container>
              <Grid item xs={4}>
                <Typography className={classes.labelECCN}>
                  Product Name
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography className={classes.labelECCN}>Status</Typography>
              </Grid>
            </Grid>
            {uniqBy(material, (m) => m.itemName).map((m, index) => (
              <Grid item container key={index}>
                <Grid item xs={4}>
                  <Typography
                    className={`${classes.label} ${classes.labelResultFailed}`}
                  >
                    {m.itemName || ''}
                  </Typography>
                </Grid>

                <Grid item xs={8}>
                  <Typography
                    className={`${classes.label}  ${classes.labelResultFailed}`}
                  >
                    {m.notes.join(',')}
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </Grid>
        )}
        {permit && permit.length > 0 && (
          <Grid xs={12} item container>
            <Grid item container>
              <Grid item xs={5}>
                <Typography className={classes.labelECCN}>Permit</Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography className={classes.labelECCN}>Status</Typography>
              </Grid>
            </Grid>
            {uniqBy(permit, (p) => p.label).map((license, index) => (
              <Grid item container key={index}>
                <Grid item xs={5}>
                  <Typography
                    className={`${classes.label} ${classes.labelResultFailed}`}
                  >
                    {license.label}
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography
                    className={`${classes.label}  ${classes.labelResultFailed}`}
                  >
                    {license.note || ''}
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </Grid>
        )}
        {keyword && keyword.length > 0 && (
          <Grid xs={12} item container>
            <Grid item container>
              <Grid item xs={3}>
                <Typography className={classes.labelECCN}>Keyword</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography className={classes.labelECCN}>Item Name</Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography className={classes.labelECCN}>Status</Typography>
              </Grid>
            </Grid>
            {uniqBy(keyword, (p) => p.keyword).map((k, index) => (
              <Grid item container key={index}>
                <Grid item xs={3}>
                  <Typography
                    className={`${classes.label} ${classes.labelResultFailed}`}
                  >
                    {k.keyword}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    className={`${classes.label} ${classes.labelResultFailed}`}
                  >
                    {k.itemName}
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography
                    className={`${classes.label}  ${classes.labelResultFailed}`}
                  >
                    {k.notes.join(',')}
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </Grid>
        )}
        <Grid>
          {user
          && (
          <Typography
            className={`${classes.label} ${isApproved && classes.labelResult} ${
              !isApproved && classes.labelResultFailed
            }`}
          >
            {isApproved ? (
              <Grid container direction="row" className={classes.bottomPanel} spacing={1}>
                <Grid item>
                  <CheckCircleOutline className={classes.checkIcon} />
                </Grid>

                <Grid item>
                  {` Approved by ${user?.fullName}`}
                </Grid>
                <Grid item>
                  <UserAvatar
                    alt={
                          user.userId
                        }
                    src={user.imageUrl}
                    title={user.userType}
                    size="small"
                  />
                </Grid>
              </Grid>
            )
              : (
                <Grid container direction="row" className={classes.bottomPanel} spacing={1}>
                  <Grid item>
                    <RemoveCircleOutline className={classes.closeIcon} />
                  </Grid>
                  <Grid item>
                    {` Rejected by ${user?.fullName}`}
                  </Grid>
                  <Grid item>
                    <UserAvatar
                      alt={
                          user.userId
                        }
                      src={user.imageUrl}
                      title={user?.userType}
                      size="small"
                    />
                  </Grid>
                </Grid>
              )}
          </Typography>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

ListItem.propTypes = {
  label: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  success: PropTypes.bool.isRequired,
  eccn: PropTypes.array.isRequired,
  notes: PropTypes.array,
  training: PropTypes.array,
  material: PropTypes.array,
  permit: PropTypes.array,
  keyword: PropTypes.array,
  user: PropTypes.object,
  isApproved: PropTypes.bool,
};

ListItem.defaultProps = {
  notes: [],
  training: [],
  material: [],
  permit: [],
  keyword: [],
  user: null,
  isApproved: false,
};

const ControlledCheckbox = (props) => {
  const { label } = props;
  const formikProps = fieldToCheckbox(props);
  return (
    <FormControlLabel
      label={label}
      control={(
        <StyledCheckbox
          disableRipple
          color="default"
          {...formikProps}
        />
      )}
    />

  );
};

ControlledCheckbox.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

const ComplianceCheckResults = ({
  submitFormToApi, shipmentId, openNextPanel, readOnly, noShowNext, isFailureWithNoTask, account,
}) => {
  const c = NEW_ORDER_SCHEDULE_PICKUP_STYLE();
  const [isLoading, setIsLoading] = useState(false);
  const [isECOApproved, setIsECOApproved] = useState(false);
  const [isEHSApproved, setIsEHSApproved] = useState(false);

  const classes = useStyles();
  const { overrideExportCompliance: userOverrideExportCompliance } = useUserState();

  const orderState = useSingleOrderState();
  const shipment = get(orderState, `shipments[${shipmentId}]`, {});

  const {
    countryCheck, nameCheck, companyCheck, eccnCheck, trainingCheck, materialApprovalCheck, permitCheck,
    materialResearchCheck, materialNewCheck,
    keywordCheck, overrideExportCompliance, ecoComments, ehsComments, success,
  } = get(shipment, 'exportCompliance.details', {});

  const hasECOApproved = has(shipment, 'exportCompliance.details.isECOApproved');
  const hasECOComments = has(shipment, 'exportCompliance.details.ecoComments');
  const hasEHSApproved = has(shipment, 'exportCompliance.details.isEHSApproved');
  const hasEHSComments = has(shipment, 'exportCompliance.details.ehsComments');
  const isECO = !!get(shipment, 'isECO');
  const isEHS = !!get(shipment, 'isEHS');

  const isAssignedTo = !!get(shipment, 'exportCompliance.task.isAssignedTo');
  const isCreatedBy = !!get(shipment, 'isCreatedBy');
  const isTakeover = !!get(shipment, 'isTakeover');

  const init = () => ({
    overrideExportCompliance: !!overrideExportCompliance,
    ecoComments,
    ehsComments,
  });

  const validationSchema = Yup.object().shape({
    // overrideExportCompliance: Yup.bool().test('Success', 'Must select', function (overrideExportCompliance) {
    //   return userOverrideExportCompliance && !isAssignedTo ? (overrideExportCompliance || hasECOApproved) : true;
    // }),
    ecoComments: Yup.string().test('Success', 'Field is required', (ecoComments) => (isAssignedTo && isECO ? !!ecoComments : true)),
    ehsComments: Yup.string().test('Success', 'Field is required', (ehsComments) => (isAssignedTo && isEHS ? !!ehsComments : true)),
  });

  const onSubmit = async (values, { setSubmitting }) => {
    const payload = {
      type: isAssignedTo ? 'approve' : 'update',
      exportCompliance: {
        details: {
          ...!isAssignedTo && {
            overrideExportCompliance: userOverrideExportCompliance ? get(values, 'overrideExportCompliance') : false,
          },
          ...(isAssignedTo && isECO) && {
            isECOApproved,
            ecoComments: get(values, 'ecoComments', ''),
          },
          ...(isAssignedTo && isEHS) && {
            isEHSApproved,
            ehsComments: get(values, 'ehsComments', ''),
          },
        },
      },
    };

    try {
      setIsLoading(true);
      await submitFormToApi(payload);
      setIsLoading(false);
      openNextPanel();
    } catch (e) {
      setIsLoading(false);
    }
    setSubmitting(false);
  };

  const getFormattedPermitsCheck = () => {
    if (!permitCheck || (!permitCheck.products && permitCheck.products.length === 0)) return permitCheck;
    let licenses = [];

    permitCheck.products.forEach((p) => {
      const licensesKey = pick(p, LICENSE_KEYS);

      licenses = licenses.concat(
        map(licensesKey, (value, key) => ({
          note: p.notes[0],
          label: LICENSES_MAPPING[key],
          value,
        })),
      );
    });

    return licenses;
  };

  return (
    <Formik
      initialValues={init()}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formikProps) => (
        <Form style={{ width: '100%' }} onKeyDown={disableSubmitOnEnterKey}>
          <Grid container>
            {countryCheck && (
              <ListItem
                label="Embargoed Countries Check:"
                success={countryCheck.success}
                status={countryCheck.status}
                user={countryCheck?.user}
                isApproved={countryCheck?.isApproved}
              />
            )}

            {nameCheck && (
              <ListItem
                label="Restricted Party Screening - Name:"
                success={nameCheck.success}
                status={nameCheck.status}
                notes={(nameCheck.notes || []).map(
                  ({ country, riskPartyNotes }) => ({
                    title: country,
                    note: riskPartyNotes,
                  }),
                )}
                user={nameCheck?.user}
                isApproved={nameCheck?.isApproved}

              />
            )}

            {companyCheck && (
              <ListItem
                label="Restricted Party Screening - Company:"
                success={companyCheck.success}
                status={companyCheck.status}
                notes={(companyCheck.notes || []).map(
                  ({ country, company, riskCompanyNotes }) => ({
                    title: country,
                    subtitle: company,
                    note: riskCompanyNotes,
                  }),
                )}
                user={companyCheck?.user}
                isApproved={companyCheck?.isApproved}

              />
            )}

            {eccnCheck && (
              <ListItem
                label="License Determination for Destination"
                success={eccnCheck.success}
                status={eccnCheck.status}
                eccn={eccnCheck.products || []}
                user={eccnCheck?.user}
                isApproved={eccnCheck?.isApproved}

              />
            )}
            {trainingCheck && (
              <ListItem
                label="Required Training Determination"
                success={trainingCheck.success}
                status={trainingCheck.status}
                training={trainingCheck.products || []}
                user={trainingCheck?.user}
                isApproved={trainingCheck?.isApproved}

              />
            )}
            {permitCheck && (
              <ListItem
                label="Required Permits Determination"
                success={permitCheck.success}
                status={permitCheck.status}
                permit={getFormattedPermitsCheck()}
                user={permitCheck?.user}
                isApproved={permitCheck?.isApproved}

              />
            )}
            {materialApprovalCheck && (
              <ListItem
                label="Restricted Material Check"
                success={materialApprovalCheck.success}
                status={materialApprovalCheck.status}
                material={materialApprovalCheck.products || []}
                user={materialApprovalCheck?.user}
                isApproved={materialApprovalCheck?.isApproved}

              />
            )}
            {materialResearchCheck && (
              <ListItem
                label={materialResearchCheck.label || "Research MTA Check"}
                success={materialResearchCheck.success}
                status={materialResearchCheck.status}
                material={materialResearchCheck.products || []}
                user={materialResearchCheck?.user}
                isApproved={materialResearchCheck?.isApproved}

              />
            )}
            {materialNewCheck && (
              <ListItem
                label="New Material Check"
                success={materialNewCheck.success}
                status={materialNewCheck.status}
                material={materialNewCheck.products || []}
                user={materialNewCheck?.user}
                isApproved={materialNewCheck?.isApproved}

              />
            )}
            {keywordCheck && (
              <ListItem
                label={account === 'clemson' ? 'Compliance item' : 'Keyword Check'}
                success={keywordCheck.success}
                status={keywordCheck.status}
                keyword={keywordCheck.products || []}
                user={keywordCheck?.user}
                isApproved={keywordCheck?.isApproved}

              />
            )}
            {(hasECOComments || hasEHSComments || isAssignedTo) && (
              <>
                <Grid
                  style={{ paddingTop: 10, paddingBottom: 10 }}
                  container
                  xs={12}
                  direction="column"
                >
                  <Grid style={{ paddingBottom: 4 }} item>
                    <Typography
                      style={{ size: 16, color: 'white', fontWeight: 500 }}
                    >
                      ECO Comments:
                    </Typography>
                  </Grid>
                  <Grid item>
                    <CustomTextArea
                      disabled={!isAssignedTo || !isECO}
                      name="ecoComments"
                      placeholder=""
                      classes={c}
                    />
                  </Grid>
                  <Grid style={{ paddingTop: 10, paddingBottom: 10 }} item>
                    <Typography
                      style={{ size: 16, color: 'white', fontWeight: 500 }}
                    >
                      EHS Comments:
                    </Typography>
                  </Grid>
                  <Grid item>
                    <CustomTextArea
                      disabled={!isAssignedTo || !isEHS}
                      name="ehsComments"
                      placeholder=""
                      classes={c}
                    />
                  </Grid>
                </Grid>
                {isAssignedTo && isECO && (
                  <Grid
                    style={{ paddingTop: 10, paddingBottom: 10 }}
                    container
                    justify="flex-start"
                    spacing={4}
                  >
                    <Grid item>
                      <NewOrderNextButton
                        disabled={isLoading}
                        onClick={() => {
                          setIsECOApproved(true);
                          formikProps.submitForm();
                        }}
                      >
                        Accept
                      </NewOrderNextButton>
                    </Grid>
                    <Grid item>
                      <NewOrderNextButton
                        disabled={isLoading}
                        className={classes.rejectButton}
                        onClick={() => {
                          setIsECOApproved(false);
                          formikProps.submitForm();
                        }}
                      >
                        Reject
                      </NewOrderNextButton>
                    </Grid>
                  </Grid>
                )}
                {isAssignedTo && isEHS && (
                  <Grid
                    style={{ paddingTop: 10, paddingBottom: 10 }}
                    container
                    justify="flex-start"
                    spacing={4}
                  >
                    <Grid item>
                      <NewOrderNextButton
                        disabled={isLoading}
                        onClick={() => {
                          setIsEHSApproved(true);
                          formikProps.submitForm();
                        }}
                      >
                        Accept
                      </NewOrderNextButton>
                    </Grid>
                    <Grid item>
                      <NewOrderNextButton
                        disabled={isLoading}
                        className={classes.rejectButton}
                        onClick={() => {
                          setIsEHSApproved(false);
                          formikProps.submitForm();
                        }}
                      >
                        Reject
                      </NewOrderNextButton>
                    </Grid>
                  </Grid>
                )}
              </>
            )}
            {!isAssignedTo
              && userOverrideExportCompliance
              && (!shipment?.exportCompliance?.details?.isECOApproved
                || !shipment?.exportCompliance?.details?.isEHSApproved)
              && !shipment?.exportCompliance?.details?.success && (
                <Grid item container>
                  <Grid item container>
                    <Field
                      disabled={readOnly}
                      component={ControlledCheckbox}
                      name="overrideExportCompliance"
                      label={(
                        <Typography
                          color="primary"
                          style={{
                            fontSize: '14px',
                            color: 'white',
                          }}
                        >
                          Proceed without addressing the compliance issues. I
                          acknowledge that I bare the sole responsibility of
                          proceeding without addressing the compliance issues.
                        </Typography>
                      )}
                    />
                  </Grid>
                </Grid>
            )}
            {isFailureWithNoTask && !success && !(hasECOApproved && hasEHSApproved) && (isCreatedBy || isTakeover) && <RerunComplianceButton rerunCompliance={submitFormToApi} />}

            {!isAssignedTo && !readOnly && !noShowNext && (
              <Grid item container justify="flex-start" direction="column">
                <Grid item spacing={4} style={{ paddingTop: 12 }}>
                  <NewOrderNextButton
                    disabled={isLoading}
                    onClick={() => {
                      formikProps.submitForm();
                    }}
                  >
                    Next
                  </NewOrderNextButton>
                </Grid>
              </Grid>
            )}
            {isLoading && (
              <Grid
                item
                container
                justify="center"
                classes={{ root: classes.miscLoadingContainer }}
              >
                <CircularProgress color="secondary" />
              </Grid>
            )}
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

ComplianceCheckResults.propTypes = {
  shipmentId: PropTypes.string.isRequired,
  setSelectedForms: PropTypes.func.isRequired,
  submitFormToApi: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  noShowNext: PropTypes.bool,
};

ComplianceCheckResults.defaultProps = {
  readOnly: false,
  noShowNext: false,
};

export default ComplianceCheckResults;
