import React from 'react';
import {
  SvgIcon,
} from '@material-ui/core';

const Class1Icon = (props) => (
  <SvgIcon {...props}>
    <path
      d="M 11.000134,0 C 10.682112,0 10.365161,0.12120962 10.122093,0.36352202 L 0.36299351,10.122407 c -0.48399135,0.484732 -0.48399135,1.270454 0,1.755186 L 10.122093,21.636478 C 10.365162,21.878792 10.682112,22 11.000134,22 c 0.31695,0 0.634972,-0.121208 0.87697,-0.363522 l 9.759099,-9.758885 c 0.485063,-0.484732 0.485063,-1.270454 0,-1.755186 L 11.877104,0.36352202 C 11.635106,0.12120962 11.317084,0 11.000134,0 Z m 0,1.2878 L 20.712117,11 11.000134,20.712306 1.2870769,11 11.000134,1.2878"

    />
    <path
      d="M 7.9002217,12.666741 7.8627451,12.531612 H 6.9397301 L 7.3048688,12.937 7.9002217,12.666741"

    />
    <path
      d="m 6.5906572,13.045682 0.032122,0.232141 0.4936314,-0.317266 -0.3790585,-0.02356 -0.1466953,0.108682"

    />
    <path
      d="m 8.2824916,12.646183 -2.3792748,1.189826 -0.2291488,-0.07056 0.091016,-0.657981 -0.5054083,-0.193914 -0.4850628,0.308485 0.4850628,0.202695 -0.2345,0.354099 0.9347918,0.93445 2.3225241,-2.067097"

    />
    <path
      d="m 15.183676,12.100203 3.171654,0.297457 0.721706,-0.721691 z"

    />
    <path
      d="m 17.052188,8.7200357 c 0,0 -0.06211,0.3040952 -0.07067,0.3260459 -0.0086,0.022057 0.348001,0.024306 0.348001,0.024306 l 0.215229,-0.1791376 0.0771,0.2830013 -3.270167,1.1557757 0.197024,-0.254305 V 9.6116557 L 14.361318,9.3589567 13.972626,9.623327 13.80237,9.5705385 V 9.4671034 l 2.929657,-1.488353 -0.710997,-0.7105549 -2.401763,1.804762 -0.310525,-0.1193894 -0.177749,0.4861239 -1.517297,1.1395 1.595461,-0.8105633 0.316954,0.4097793 0.458295,-0.143803 0.188454,0.358597 -3.734881,1.320137 -0.236643,-0.297992 H 9.8897326 L 9.6049045,11.87952 9.2429802,11.703274 9.0491711,11.271758 l 0.4176036,-0.320585 0.1820321,0.264371 0.4904172,-0.170358 -0.0439,-0.569857 c 0,0 -0.223795,-0.208905 -0.295537,-0.276256 L 11.667229,2.9142809 11.000134,2.247092 10.925177,2.3216166 9.7451771,10.237406 9.5610021,10.575123 9.3554132,10.522335 9.0491711,10.745588 8.8125282,10.364505 c -0.014994,0.0077 -0.028896,0.01553 -0.043902,0.02356 L 7.6410934,5.6060581 7.2984431,5.9487006 8.7172294,10.41815 c -0.4486576,0.273364 -0.7623968,0.747924 -0.8148647,1.298722 l 0.2066604,0.238993 -0.2088034,0.04958 c 0.00428,0.05825 0.011777,0.115535 0.021429,0.172071 l -4.6600395,-0.16222 0.6060614,0.606156 4.0678979,-0.375086 c 0.00965,0.04272 0.019274,0.08512 0.032122,0.12667 H 8.491304 l 0.064248,0.334826 -0.2505628,0.281395 c 0.3137392,0.367056 0.7795279,0.599946 1.2999275,0.599946 0.236643,0 0.4625773,-0.04829 0.6670983,-0.135344 l 3.08706,3.939853 0.362996,-0.362666 -3.301218,-3.649035 c 0.215226,-0.116927 0.401544,-0.278504 0.548239,-0.472311 l -0.245209,-0.219398 -0.210943,0.252591 -0.323376,-0.387721 0.416535,-0.205692 -0.451872,-0.352494 0.273051,-0.198626 0.409038,-0.04904 -0.117787,0.599089 0.434738,-0.08705 0.222723,-0.282037 -0.132778,-0.278719 8.22574,-0.984882 -2.18332,-2.1835965 -0.233432,0.1879179"

    />
    <path
      d="m 14.99843,12.315747 -0.29982,-0.25998 -0.572867,0.03962 -0.233429,0.127849 0.180961,0.149799 0.409038,0.118961 0.516117,-0.176247"

    />
    <path
      d="m 14.84852,13.783113 v -0.507539 l 2.049476,0.579494 0.578222,-0.578637 -4.308824,-0.577995 0.13599,-0.296065 -0.387624,-0.387721 -0.569653,-0.08812 -0.511834,0.421879 h -0.458295 l -0.04176,0.293388 0.329798,0.259979 0.722778,0.0999 0.498985,0.53463 0.511831,-0.217364 -0.323376,-0.417168 0.0514,-0.113179 0.362992,0.102579 0.707789,0.645132 -0.598567,0.323797 -0.580365,-0.228928 0.627479,0.356562 0.599638,0.588488 0.02571,-0.233211 1.364176,0.775657 0.325519,-0.32551 -1.667206,-0.658623 0.0096,-0.09144 0.546096,-0.25998"

    />
    <path
      d="m 14.223184,16.215446 0.254846,-0.312875 -1.031162,-0.819558 -0.770962,-0.171857 -0.110291,0.268761 0.595353,0.735931 0.633904,0.198304 0.07067,0.773516 0.490417,-0.490621 -0.132776,-0.181601"

    />
    <path
      d="m 11.007628,12.887639 3.861237,2.996086 0.415464,-0.41556 z"

    />
    <path
      d="m 11.47342,14.250178 0.369418,-0.339324 -0.233431,-0.436226 -0.483992,-0.127741 -0.207732,0.242312 0.220581,0.304095 0.241997,-0.04668 -0.04604,0.26255 0.139201,0.141019"

    />
    <path
      d="M 6.8529969,11.435584 2.8932494,10.353905 2.4081866,10.83853 Z"

    />
    <path
      d="m 10.639281,11.080414 0.02783,-0.271544 4.961998,-3.9326787 -0.693866,-0.6940652 -4.595791,4.8865099 0.299819,0.01178"

    />
    <path
      d="M 12.917903,7.7491799 12.607375,7.9635455 12.759427,8.2368029 13.038901,8.0311104 12.917903,7.7491799"

    />
    <path
      d="m 13.770244,6.8031655 h -0.311596 l -0.261271,0.2761484 0.0878,0.2350312 0.27626,-0.099901 0.208803,-0.4112781"

    />
    <path
      d="M 9.3425648,10.35262 9.5031799,10.134507 H 9.0845049 Z"

    />
    <path
      d="M 10.547193,10.015653 10.201332,9.7761241 10.118882,10.196182 10.40371,10.492889 10.941241,10.36654 11.226069,9.5734295 11.114708,9.3211592 14.082913,5.3294817 13.006777,4.2534774 10.547194,10.015653"

    />
    <path
      d="M 4.7307106,10.614848 4.9073884,10.381316 4.6578971,10.22006 4.5219072,10.368039 4.7307106,10.614848"

    />
    <path
      d="m 7.5457946,11.474131 0.2345,-0.235031 -0.1766779,-0.334933 -0.3223047,0.176247 -0.164901,0.35517 0.4293836,0.531524 v -0.492977"

    />
    <path
      d="M 9.8019279,8.7009764 9.4164467,8.577518 9.163744,8.9212312 9.4164467,9.0123527 9.1284073,9.4030721 9.2943797,9.7761241 9.7044891,9.4559675 9.8019279,8.7009764"

    />
    <path
      d="m 7.8413281,14.117404 -1.0033195,1.473469 0.1691807,0.169715 z"

    />
    <path
      d="m 8.0854682,13.171068 -1.7089685,1.958844 0.1948835,0.194342 z"

    />
  </SvgIcon>
);

export default Class1Icon;
