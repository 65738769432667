import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import {
  Grid, InputAdornment, MenuItem, Select, TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DownArrow from '@material-ui/icons/KeyboardArrowDown';
import { Field } from 'formik';
import { fieldToTextField } from 'formik-material-ui';
import parsePhoneNumber, { AsYouType, getCountryCallingCode as getCountryCallingCodeLib } from 'libphonenumber-js';
import Flags from 'country-flag-icons/react/3x2';
import * as colors from '../../styles/colors';
import { useMiscState } from '../../context/miscDataContext';

const styles = {
  input: {
    background: 'transparent',
    color: colors.white,
    borderRadius: '5px 5px 0 0px',
  },
  inputRoot: {
    transition: 'background-color .1s',
    '&:after': {
      borderColor: '#2A79D4',
    },
    '&:before': {
      border: 'none !important',
    },
    '&:hover': {
      backgroundColor: '#1E202E',
      transition: 'background-color .1s',
    },
    background: colors.inputFieldBackground,
  },
  labelRoot: {
    color: `${colors.white} !important`,
    fontWeight: '500',
  },
  labelFocused: {
    color: `${colors.white} !important`,
  },
  inputDisabled: {
    backgroundColor: '#151721',
    color: `${colors.white} !important`,
  },
  adornment: {
    paddingRight: 10,
    width: 55,
    flexShrink: '0',
  },
};

const useStyles = makeStyles(styles);

const dropdownStyles = {
  root: {
    background: 'transparent !important',
    '&:hover': {
      background: 'transparent !important',
    },
    '&:after': {
      border: 'none !important',
    },
    '&:before': {
      border: 'none !important',
    },
    color: colors.white,
    fontSize: 20,
  },
  icon: {
    color: colors.white,
  },
  iconError: {
    color: '#F44335',
  },
};

const useDropdownStyles = makeStyles(dropdownStyles);

function DropdownCountries(props) {
  const { value, onChange, countries } = props;

  const getFlag = (cc) => {
    if (!cc) return () => <></>;
    if (cc.toUpperCase() === 'UM') return Flags.US;
    if (cc.toUpperCase() === 'AN') return Flags.NL;
    const Flag = cc && Flags[cc.toUpperCase()];
    if (Flag) return Flag;
    return () => <></>;
  };

  const classes = useDropdownStyles();
  return (
    <Select
      className={classes.root}
      classes={{ icon: classes.icon }}
      value={value}
      variant="standard"
      inputProps={{
        tabIndex: -1,
      }}
      onChange={onChange}
      IconComponent={DownArrow}
      renderValue={(val) => {
        const Flag = val && getFlag(value);
        if (Flag) return <Flag style={{ width: 24 }} />;
      }}
    >
      {countries.map((cc) => {
        const Flag = getFlag(cc.countryCode);
        return (
          <MenuItem
            value={cc.countryCode}
            key={cc.name}
            id={cc.name}
            aria-label={cc.name}
          >
            <Grid container direction="row-reverse" justify="flex-end" spacing={2} alignItems="center">
              <Grid item>
                {`${cc.name}`}
              </Grid>
              <Grid
                container
                item
                zeroMinWidth
                style={{ width: 'auto' }}
              >
                <Flag style={{ width: 24 }} />
              </Grid>
            </Grid>
          </MenuItem>
        );
      })}
    </Select>

  );
}

DropdownCountries.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  countries: PropTypes.array.isRequired,
};

DropdownCountries.defaultProps = {
  onChange: () => {},
};
const asYouType = new AsYouType();

export function PhoneNumber(props) {
  const classes = useStyles();
  const {
    disabled, value, onBlur, onFocus, onChange, country, onCountryChange,
  } = props;

  const [val, setVal] = React.useState(value);
  const [focused, setFocused] = React.useState(false);

  const miscState = useMiscState();
  const countries = miscState ? miscState.countries : [];

  function getCountryCallingCode(cc) {
    let callingCode;
    try {
      callingCode = getCountryCallingCodeLib(cc);
    } catch (e) {
      const countryMatch = countries.find((c) => cc === c.countryCode);
      if (!countryMatch) throw new Error('Country not found');
      callingCode = countryMatch.phoneCode;
    }
    return callingCode;
  }
  React.useEffect(() => {
    if (country === '') {
      setCountry('US');
    }
  }, []);

  React.useEffect(() => {
    let v = value || '';
    asYouType.reset();

    if (!isEmpty(v)) {
      if (v.indexOf('+') === -1) v = `+${v.substring(0, v.length)}`;
      if (v.length === 1 && v.indexOf('+') === 0) v = '';
    }

    const ayt = asYouType.input(v);

    onCountryChange(asYouType.country);

    setVal(ayt);
  }, [value]);

  function changeCountry(cntry) {
    const newCallingCode = cntry && getCountryCallingCode(cntry);
    const oldCallingCode = country && getCountryCallingCode(country);
    const removeCallingCodeRegex = oldCallingCode ? new RegExp(`\\+${oldCallingCode || ''}\\s*`) : /^/;
    const newVal = val.replace(removeCallingCodeRegex, `${newCallingCode ? `+${newCallingCode} ` : ''}`);
    asYouType.reset();
    asYouType.country = cntry;
    const ayt = asYouType.input(`${newVal || ''}`);
    setVal(ayt);
    onChange({ target: { value: ayt } });
    setTimeout(() => onCountryChange(cntry), 100);
  }
  function setCountry(cntry) {
    const newCallingCode = cntry && getCountryCallingCode(cntry);
    const newVal = `${newCallingCode ? `+${newCallingCode} ` : ''}`;
    asYouType.reset();
    asYouType.country = cntry;
    const ayt = asYouType.input(`${newVal || ''}`);
    setVal(ayt);
    onChange({ target: { value: ayt } });
    setTimeout(() => onCountryChange(cntry), 100);
  }
  function moveTextCursorEnd(e) {
    if (e.currentTarget) e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length);
  }

  return (
    <TextField
      {...props}
      value={val}
      color="primary"
      variant="filled"
      inputClass={classes.input}
      InputProps={{
        classes: { root: classes.inputRoot, disabled: classes.inputDisabled },
        startAdornment: (
          <InputAdornment
            className={classes.adornment}
          >
            <DropdownCountries
              onChange={(e) => changeCountry(e.target.value)}
              value={country}
              countries={countries}
            />
          </InputAdornment>
        ),
      }}
      InputLabelProps={{
        classes: { root: classes.labelRoot, focused: classes.labelRoot },
        shrink: !!val || focused,
        style: { left: 65 },
      }}
      fullWidth
      onFocus={(e) => {
        moveTextCursorEnd(e);
        setFocused(true);
        onFocus(e);
      }}
      onBlur={(e) => {
        setFocused(false);
        onBlur(e);
      }}
      disabled={disabled}
      onChange={(e) => {
        onChange(e);
      }}
    />
  );
}

PhoneNumber.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
  onCountryChange: PropTypes.func,
  country: PropTypes.string,
};

PhoneNumber.defaultProps = {
  disabled: false,
  onBlur: () => {},
  onFocus: () => {},
  onChange: () => {},
  onCountryChange: () => {},
  country: null,
};

export function PhoneNumberFormik(props) {
  const { name, countryName } = props;

  return (
    <Grid item key={`textField-${name}`} style={{ padding: '12px 0' }}>
      <Field name={name}>
        {(formikProps) => (
          <Field name={countryName}>
            {(countryProps) => (
              <PhoneNumber
                {...props}
                {...fieldToTextField(formikProps)}
                onChange={(e) => {
                  const pointer = e.target.selectionStart;
                  const element = e.target;
                  const val = e.target.value;
                  const cleanedVal = val ? val.trim().replace(/([-()\s])/g, '') : '';
                  formikProps.form.setFieldValue(name, cleanedVal);
                  window.requestAnimationFrame(() => {
                    element.selectionStart = pointer + 1;
                    element.selectionEnd = pointer + 1;
                  });
                }}
                value={formikProps.field.value || null}
                onCountryChange={(val) => {
                  countryProps.form.setFieldValue(countryName, val);
                }}
                country={countryProps.field.value || ''}
              />
            )}
          </Field>
        )}
      </Field>
    </Grid>
  );
}

PhoneNumberFormik.propTypes = {
  name: PropTypes.string.isRequired,
  countryName: PropTypes.string.isRequired,
};
