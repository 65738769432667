import React, { useState } from 'react';
import axios from 'axios';
import { Formik, Field, Form } from 'formik';
import { Redirect, Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import * as Yup from 'yup';
import { Button, Grid, Typography } from '@material-ui/core';
import * as colors from '../styles/colors';
import LoginTextField from './LoginTextField';
import LoginPasswordField from './LoginPasswordField';
import { useAuth } from '../context/authContext';
import { getSsoEnabled } from '../utils/apiKeyClient';

const styles = {
  queryContainer: {
    flexDirection: 'row',
  },
  topTextField: {
    minHeight: 76,
  },
  button: {
    float: 'left',
    fontWeight: 'bold',
    backgroundColor: '#2A79D4',
    borderRadius: 8,
    width: 100,
    height: 36,
  },
  infoIcon: {
    color: '#979797',
  },
  createAccountContainer: {
    marginTop: '3.91%',
    color: 'rgba(255,255,255,0.6)',
    fontSize: 16,
    fontWeight: 500,
    zIndex: 200,
  },
  infoButton: {
    paddingTop: 6,
    paddingBottom: 0,
  },
  createAccountLink: {
    color: colors.white,
    textDecoration: 'none',
    paddingLeft: 5,
  },
  ssoContainer: {
    marginTop: 20,
    height: 36,
  },
  ssoButton: {
    float: 'left',
    fontWeight: 'bold',
    borderColor: '#2A79D4',
    color: '#6AB4F2',
    borderRadius: 8,
    backgroundColor: 'rgba(255, 255, 255, 0.12)',
    width: 169,
    height: 36,
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.30)',
    },
  },
  loginContainer: {
    alignItems: 'center',
  },
  forgotPasswordLink: {
    color: 'rgba(255,255,255,0.6)',
    fontWeight: 'bold',
    fontSize: 12,
    textDecoration: 'none',
  },
  forgotPasswordLinkContainer: {
    textAlign: 'right',
    zIndex: 200,
  },
  zIndexContainer: {
    zIndex: 200,
  },
  title: {
    color: colors.white,
    fontSize: 24,
    fontWeight: 500,
  },
};

const useStyles = makeStyles(styles);

const handleSSOClick = async () => {
  try {
    const options = {
      method: 'get',
      url: '/login/sso',
      mode: 'no-cors',
    };

    const result = await axios(options); // get sso redirect url
    window.location = result.data.ssoRedirectUrl;
  } catch (err) {
    alert(err.message);
  }
};

export default function LoginForm(props) {
  const [ssoEnabled, setSsoEnabled] = useState(true);
  getSsoEnabled().then((flag) => {
    setSsoEnabled(flag);
  });

  const { isAuthenticated, login } = useAuth();
  const classes = useStyles();

  async function getIpAddress() {
    const res = await axios.get('https://api.ipify.org?format=json');
    return res.data.ip;
  }
  const handleSubmit = async (values) => {
    let ipAddress;
    try {
      ipAddress = await getIpAddress();
    } catch (e) {
      console.log('ipaddress not avilable');
    }
    try {
      const options = {
        method: 'post',
        url: '/login',
        data: {
          username: values.email,
          password: values.password,
          ipAddress,
        },
        mode: 'no-cors',
      };
      const res = await axios(options);

      if (res.data.auth) {
        login(res.data);
      } else {
        alert('Error authenticating. Ensure email is verified.');
      }
    } catch (err) {
      alert(err?.response?.data?.message || err.message);
    }
  };

  return !ssoEnabled ? (
    <Formik
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={{
        email: '',
        password: '',
      }}
      onSubmit={async (values, { setSubmitting }) => {
        await handleSubmit(values);
        setSubmitting(false);
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().email('Invalid email').required('Required'),
        password: Yup.string().required('Required'),
      })}
      render={({ submitForm, isSubmitting, values }) => (
        <div className={classes.loginContainer} data-testid="login-screen">
          {isAuthenticated && <Redirect push to="/" />}
          <Form>
            <Grid container spacing={2} className={classes.queryContainer}>
              <Grid className={classes.zIndexContainer} item xs={8}>
                <Typography className={classes.title}>
                  Login to your account
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Field
                  className={classes.topTextField}
                  name="email"
                  type="email"
                  variant="outlined"
                  label="Email"
                  component={LoginTextField}
                  fullWidth
                  autoFocus
                />
              </Grid>
              <Grid container item xs={12}>
                <Field
                  name="password"
                  type="password"
                  variant="outlined"
                  label="Password"
                  component={LoginPasswordField}
                  fullWidth
                />
                <Grid
                  className={classes.forgotPasswordLinkContainer}
                  item
                  xs={12}
                >
                  <Link
                    className={classes.forgotPasswordLink}
                    to="/login/forgot-password"
                  >
                    Forgot Password?
                  </Link>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Button
                  className={classes.button}
                  color="secondary"
                  variant="contained"
                  disabled={isSubmitting}
                  type="submit"
                >
                  LOGIN
                </Button>
              </Grid>
            </Grid>
          </Form>
          <Grid
            container
            className={`${classes.ssoContainer} ${classes.queryContainer}`}
          >
            <Grid item xs={12} className={classes.createAccountContainer}>
              <span>
                Don&apos;t have an account?
                <Link
                  className={classes.createAccountLink}
                  to="/login/register"
                >
                  Create an account
                </Link>
              </span>
            </Grid>
          </Grid>
        </div>
      )}
    />
  ) : (
    <Formik
      render={() => (
        <div className={classes.loginContainer} data-testid="login-screen">
          <Grid
            container
            className={`${classes.ssoContainer} ${classes.queryContainer}`}
          >
            <Grid item>
              <Button
                className={classes.ssoButton}
                color="primary"
                variant="outlined"
                onClick={handleSSOClick}
              >
                Login with SSO
              </Button>
            </Grid>
          </Grid>
        </div>
      )}
    />
  );
}
