import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid, Typography, FormControl, FormLabel, Radio, RadioGroup, FormControlLabel,
} from '@material-ui/core';
import * as colors from '../../../../styles/colors';
import DisclaimerModal from '../../../common/DisclaimerModal';
import { useItemProductDetailsFormStyles } from '../../itemDetailsStyles';

export default function LicenseCertification({
  formikProps,
}) {
  const classes = useItemProductDetailsFormStyles();

  const modal = {
    title: 'License and Permit Requirements',
    listHeader: 'Prohibited Articles for Shipping Without Permit or License',
    leftListItems: [
      'Alcoholic Beverages',
      'Animal products, non-domesticated (e.g., mother of pearl inlay, snakeskin watch bands)',
      'Articles of high/unusual value',
      'Biological substances, Category B and exempt human or animal specimens',
      'Dangerous goods',
      'Electronic cigarettes',
    ],
    rightListItems: [
      'Firearms and weapons (including inert and replica explosive weapons)',
      'Furs',
      'Gold or other precious metals',
      'Live animals',
      'Perishables',
      'Personal effects',
      'Plants',
      'Seeds',
      'Tobacco',
    ],
    isWarningIcon: false,
    bodyText: [
      'Selecting a License/Permit will require user to upload a Permits & License document on the shipping documents panel',
      'Not all commodities can be shipped to all countries. Carriers will not transport any goods which are prohibited by law or regulation of any federal, state or local government in the origin or destination countries or which may breach any applicable export, import or other laws or endanger the safety of our employees, agents and partners or the means of transportation or, which in our opinion, soil, paint or otherwise damage other goods or equipment or which are economically or operationally impractical to transport.',
    ],
    footer: {
      text: 'Ref: ',
      url: 'https://www.ups.com/us/en/support/shipping-support/shipping-special-care-regulated-items/prohibited-items.page',
    },
  };

  const options = [
    {
      value: 'yes',
      label: 'Yes',
    },
    {
      value: 'no',
      label: 'No',
    },
    {
      value: 'unknown',
      label: 'Unknown',
    },
  ];

  return (
    <Grid item container direction="column" spacing={1}>
      <Grid item>
        <Typography color="primary" className={classes.formFieldHeader}>License Certification Acknowledgement</Typography>
      </Grid>
      <Grid item>
        <FormControl component="fieldset">
          <FormLabel component="legend">
            <Grid item container alignItems="center" spacing={2}>
              <Grid item>
                <Typography color="primary" className={classes.formFieldSubHeader}>Does this shipment require a Permit or License?</Typography>
              </Grid>
              <Grid item>
                <DisclaimerModal {...modal} />
              </Grid>
            </Grid>
          </FormLabel>
          <RadioGroup
            aria-label="isLicensePermitRequired"
            name="isLicensePermitRequired"
            value={formikProps?.values?.isLicensePermitRequired}
            row
            onChange={(e) => formikProps.setFieldValue('isLicensePermitRequired', e.target.value)}
          >
            {options.map((option) => (
              <FormControlLabel
                key={option.value}
                style={{ padding: '0 10px' }}
                value={option.value}
                control={<Radio style={{ color: colors.white }} />}
                label={<Typography color="primary">{option.label}</Typography>}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Grid>
    </Grid>
  );
}

LicenseCertification.propTypes = {
  formikProps: PropTypes.objectOf(PropTypes.any).isRequired,
};
