import React from 'react';
import * as orderClient from '../utils/orderClient';
import { useLogger } from '../utils/hooks';

const orderState = { order: null, shipments: null };
const SingleOrderStateContext = React.createContext();
const SingleOrderDispatchContext = React.createContext();

// OrderReducer Action Types
const LOAD_SINGLE_ORDER = 'LOAD SINGLE ORDER';
const CREATE_SINGLE_ORDER = 'CREATE SINGLE ORDER';
const UPDATE_SHIPMENT = 'UPDATE SHIPMENT';
const ADD_SHIPMENT = 'ADD SHIPMENT';
const CANCEL_SHIPMENT = 'CANCEL SHIPMENT';
// Reducer
function singleOrderReducer(state, action) {
  switch (action.type) {
    case LOAD_SINGLE_ORDER: {
      return { ...state, ...action.singleOrderData };
    }
    case CREATE_SINGLE_ORDER: {
      return { ...state, ...action.singleOrderData };
    }
    case UPDATE_SHIPMENT: {
      const { shipmentId, update } = action;
      const updatedShipments = { ...state.shipments, [shipmentId]: { ...update } };
      return { ...state, shipments: { ...updatedShipments } };
    }
    case ADD_SHIPMENT: {
      const { newShipment } = action;
      return { ...state, shipments: { ...state.shipments, [newShipment.shipmentId]: newShipment } };
    }
    case CANCEL_SHIPMENT: {
      const { cancelShipment } = action;
      return { ...state, shipments: { ...state.shipments, [cancelShipment?.shipment?.shipmentId]: cancelShipment?.shipment } };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

// Provider
function SingleOrderDataProvider(props) {
  const [state, dispatch] = useLogger(
    React.useReducer(singleOrderReducer, orderState),
  );

  return (
    <SingleOrderStateContext.Provider value={state} {...props}>
      <SingleOrderDispatchContext.Provider
        value={dispatch}
        {...props}
      />
    </SingleOrderStateContext.Provider>
  );
}

// Exported functions
function loadSingleOrder({ dispatch, orderId }) {
  if (!orderId) {
    return Promise.resolve(dispatch({
      type: LOAD_SINGLE_ORDER,
      singleOrderData: { shipments: {} },
    }));
  }

  return orderClient.loadSingleOrderDetails(orderId).then((data) => {
    dispatch({ type: LOAD_SINGLE_ORDER, singleOrderData: data });
    return data;
  });
}

function createSingleOrder({ dispatch, orderBasics }) {
  return orderClient.createOrder(orderBasics).then((data) => {
    dispatch({ type: CREATE_SINGLE_ORDER, singleOrderData: data });
    return data;
  });
}

async function addShipmentToOrder({ dispatch, orderId }) {
  try {
    const data = await orderClient.addShipmentToOrder(orderId);
    dispatch({ type: ADD_SHIPMENT, newShipment: data });
    return data;
  } catch (e) {
    throw new Error(e.message || e);
  }
}
async function cancelShipmentFromOrder({ dispatch, orderId, shipmentId }) {
  try {
    const data = await orderClient.cancelShipmentFromOrder(orderId, shipmentId);
    dispatch({ type: CANCEL_SHIPMENT, cancelShipment: data });
    return data;
  } catch (e) {
    throw new Error(e.message || e);
  }
}

export function internalUpdateShipment(result, dispatch, shipmentId, confirm = false) {
  if (result.isShipmentResetAvailable === false) {
    throw new Error('SHIPMENT RESET FAILED');
  }
  dispatch({
    type: UPDATE_SHIPMENT,
    shipmentId,
    update: { ...(result.shipment), confirmed: confirm },
  });
}
export function updateShipmentConfirm(shipment, dispatch, shipmentId, confirm = false) {
  dispatch({
    type: UPDATE_SHIPMENT,
    shipmentId,
    update: { ...shipment, confirmed: confirm },
  });
}
async function updateShipmentOrigin({ dispatch, shipmentId, details }) {
  try {
    internalUpdateShipment(
      await orderClient.addSender({ shipmentId, payload: details }),
      dispatch,
      shipmentId,
    );
  } catch (e) {
    throw new Error(e.message || e);
  }
}

async function updateShipmentDestination({ dispatch, shipmentId, details }) {
  try {
    internalUpdateShipment(
      await orderClient.addRecipient({ shipmentId, details }),
      dispatch,
      shipmentId,
    );
  } catch (e) {
    throw new Error(e.message || e);
  }
}

async function updateShipmentCustomsBroker({ dispatch, shipmentId, details }) {
  try {
    internalUpdateShipment(
      await orderClient.addCustomsBroker({ shipmentId, details }),
      dispatch,
      shipmentId,
    );
  } catch (e) {
    throw new Error(e.message || e);
  }
}

async function updateCustomsClearance({ dispatch, shipmentId, customsClearance }) {
  try {
    internalUpdateShipment(
      await orderClient.updateCustomsClearance({ shipmentId, customsClearance }),
      dispatch,
      shipmentId,
    );
  } catch (e) {
    throw new Error(e.message || e);
  }
}

async function updateRegulationAndCompliance({ dispatch, shipmentId, details }) {
  try {
    internalUpdateShipment(
      await orderClient.updateRegulationAndCompliance(
        {
          shipmentId,
          compliance: {
            details,
          },
        },
      ),
      dispatch,
      shipmentId,
    );
  } catch (e) {
    throw new Error(e.message || e);
  }
}

async function updateItemProductDetails({ dispatch, shipmentId, details }) {
  try {
    internalUpdateShipment(
      await orderClient.updateItemProductDetails(
        {
          shipment: {
            shipmentId,
            product: {
              details: {
                items: details.items,
                isProductCompleted: !!details.isProductCompleted,
              },
            },
          },
          itemImage: details.itemImage,
        },
      ),
      dispatch,
      shipmentId,
    );
  } catch (e) {
    throw new Error(e.message || e);
  }
}

async function updatePackagingForShipment({ dispatch, shipmentId, details }) {
  try {
    const packagingDetailsResponse = await orderClient
      .updatePackagingDetails({ shipmentId, packaging: details });
    internalUpdateShipment(
      packagingDetailsResponse,
      dispatch,
      shipmentId,
    );
    return true;
  } catch (e) {
    throw new Error(e.message || e);
  }
}

async function updateCarrierForShipment({
  dispatch, shipmentId, details,
}) {
  try {
    internalUpdateShipment(
      await orderClient.updateCarrierDetails({
        shipmentId,
        carrier: details,
      }),
      dispatch,
      shipmentId,
    );
    return true;
  } catch (e) {
    throw new Error(e.message || e);
  }
}

async function updateBillingPayment({
  dispatch, shipmentId, billingKey, details,
}) {
  try {
    internalUpdateShipment(
      await orderClient.updateBillingPayment({ shipmentId, billingKey, payload: details }),
      dispatch,
      shipmentId,
    );
  } catch (e) {
    throw new Error(e.message || e);
  }
}

async function updateConfirmationForShipment({ dispatch, shipmentId }) {
  let result = '';
  try {
    result = await orderClient.submitShipment(shipmentId);
    internalUpdateShipment(result,
      dispatch,
      shipmentId,
      true);
  } catch (e) {
    throw new Error(e.message || e);
  }
  return result;
}

async function updateConfirmationForShipmentIpd({ dispatch, shipmentId, expectedShipDate }) {
  try {
    internalUpdateShipment(
      await orderClient.submitShipmentIpd(shipmentId, expectedShipDate),
      dispatch,
      shipmentId,
      true,
    );
  } catch (e) {
    throw new Error(e.message || e);
  }
}

async function uploadShippingDocument({
  dispatch, shipmentId, document, documentType, packageId, documentId, trackingNumber,
}) {
  try {
    internalUpdateShipment(
      await orderClient.uploadDocument({
        shipmentId, document, documentType, packageId, documentId, trackingNumber,
      }),
      dispatch,
      shipmentId,
    );
  } catch (e) {
    throw new Error(e.message || e);
  }
}

async function deleteShippingDocument({
  dispatch, shipmentId, documentId, packageId, documentType,
}) {
  try {
    internalUpdateShipment(
      await orderClient.deleteDocument({
        shipmentId, documentType, packageId, documentId,
      }),
      dispatch,
      shipmentId,
    );
  } catch (e) {
    throw new Error(e.message || e);
  }
}

async function signShippingDocument({
  dispatch, shipmentId, documentId, packageId, signature, documentType,
}) {
  try {
    internalUpdateShipment(
      await orderClient.signDocument({
        shipmentId, signature, packageId, documentId, documentType,
      }),
      dispatch,
      shipmentId,
    );
  } catch (e) {
    throw new Error(e.message || e);
  }
}

async function submitDocuments({ dispatch, shipmentId, details }) {
  try {
    internalUpdateShipment(
      await orderClient.submitDocuments({
        shipmentId,
      }),
      dispatch,
      shipmentId,
    );
  } catch (e) {
    throw new Error(e.message || e);
  }
}

async function updateSchedulePickup({ dispatch, shipmentId, details }) {
  try {
    internalUpdateShipment(
      await orderClient.updateSchedulePickup({ shipmentId, payload: details }),
      dispatch,
      shipmentId,
    );
  } catch (e) {
    throw new Error(e.message || e);
  }
}

async function cancelSchedulePickup({ dispatch, shipmentId }) {
  try {
    internalUpdateShipment(
      await orderClient.cancelSchedulePickup({ shipmentId }),
      dispatch,
      shipmentId,
    );
  } catch (e) {
    throw new Error(e.message || e);
  }
}

async function updateAESDetails({ dispatch, shipmentId, details }) {
  try {
    internalUpdateShipment(
      await orderClient.updateAESDetails({ shipmentId, payload: details }),
      dispatch,
      shipmentId,
    );
  } catch (e) {
    throw new Error(e.message || e);
  }
}

async function updateExportCompliance({ dispatch, shipmentId, details }) {
  try {
    internalUpdateShipment(
      await orderClient.updateExportControl({ shipmentId, payload: details }),
      dispatch,
      shipmentId,
    );
  } catch (e) {
    throw new Error(e.message || e);
  }
}

function reschedulePickup({ dispatch, shipmentId, shipment }) {
  dispatch({
    type: UPDATE_SHIPMENT,
    shipmentId,
    update: { ...shipment },
  });
}

function useSingleOrderState() {
  const context = React.useContext(SingleOrderStateContext);
  if (context === undefined) {
    throw new Error(
      'useSingleOrderState must be used within a SingleOrderDataProvider',
    );
  }
  return context;
}

function useSingleOrderDispatch() {
  const context = React.useContext(SingleOrderDispatchContext);
  if (context === undefined) {
    throw new Error(
      'useSingleOrderDispatch must be used within a OrderDataProvider',
    );
  }
  return context;
}

export {
  SingleOrderStateContext,
  SingleOrderDataProvider,
  useSingleOrderState,
  useSingleOrderDispatch,
  loadSingleOrder,
  updateCustomsClearance,
  updateShipmentOrigin,
  updateRegulationAndCompliance,
  updateItemProductDetails,
  updateAESDetails,
  updateExportCompliance,
  updateShipmentDestination,
  updateShipmentCustomsBroker,
  updatePackagingForShipment,
  updateCarrierForShipment,
  updateConfirmationForShipment,
  updateConfirmationForShipmentIpd,
  updateBillingPayment,
  addShipmentToOrder,
  cancelShipmentFromOrder,
  updateSchedulePickup,
  cancelSchedulePickup,
  reschedulePickup,
  createSingleOrder,
  uploadShippingDocument,
  deleteShippingDocument,
  signShippingDocument,
  submitDocuments,
};
