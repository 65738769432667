import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MuiTextField from '@material-ui/core/TextField';
import { fieldToTextField } from 'formik-material-ui';
import * as colors from '../styles/colors';

const styles = {
  cssLabel: {
    color: colors.textGrey,
    fontWeight: 500,
  },
  cssFocused: {
    color: colors.textGrey,
  },
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: colors.textGrey,
    },
    '&:hover $notchedOutline': {
      borderColor: colors.textGrey,
    },
    color: colors.textGrey,
    backgroundColor: 'rgba(255, 255, 255, 0.15)',
    borderRadius: 5,
  },
  notchedOutline: {},
  disableChromeAutofill: {
    '-webkit-transition-delay': '9999s',
    ' -webkit-text-fill-color': 'white !important',
    ' -webkit-transition': 'background-color 9999s ease-out',
    color: 'white',
    background: 'transparent !important',
  },
};

const useStyles = makeStyles(styles);

export default function LoginTextField(props) {
  const classes = useStyles();
  const propsWithFormik = fieldToTextField(props);
  return (
    <MuiTextField
      className={classes.textfield}
      {...propsWithFormik}
      onChange={(e) => {
        if (propsWithFormik.value !== e.target.value) {
          propsWithFormik.onChange(e);
        }
      }}
      InputLabelProps={{
        classes: {
          root: classes.cssLabel,
          focused: classes.cssFocused,
        },

      }}
      InputProps={{
        classes: {
          root: `${classes.cssOutlinedInput}`,
          focused: `${classes.cssFocused}`,
          notchedOutline: `${classes.notchedOutline}`,
          hover: `${classes.disableChromeAutofill}`,
          input: `${classes.disableChromeAutofill}`,
        },
      }}
    />
  );
}
