import React, { memo, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as colors from '../../../../../styles/colors';
import {
  FORM_FIELDS_CHEMICAL_HAZMAT,
  FORM_LABELS,
  SHIPMENT_QUANTITY_BULK_VALUE,
  SHIPMENT_QUANTITY_EXCEPTED_VALUE,
  SHIPMENT_QUANTITY_LIMITED_VALUE,
  SHIPMENT_QUANTITY_NAME_KEY,
  TRANSPORTATION_OPTIONS_NAME_KEY,
  TRANSPORTATION_OPTION_GROUND_VALUE,
  TRANSPORTATION_OPTION_PASSENGER_CARGO_VALUE,
} from '../../../itemDetailsConstants';
import { useItemProductDetailsFormStyles } from '../../../itemDetailsStyles';
import {
  getIataMaxWeight,
  getTransportationWeight,
  isMaterialForbidden,
  validateChemicalTransportationQuantity,
} from '../../../itemDetailsUtil';

const useStyles = makeStyles(() => ({
  formControlContainer: {
    marginTop: '1rem',
    marginBottom: '1rem',
  },
  formControl: {
    width: '100%',
  },
  radio: {
    color: colors.white,
    '&:hover': {
      backgroundColor: 'transparent ',
    },
    '&.Mui-disabled': {
      color: colors.disabledGrey,
    },
    paddingLeft: 0,
  },
  radioLabel: {
    color: colors.white,
    marginLeft: 0,
    marginRight: '5px',
  },
  maxQuantityText: {
    color: colors.white,
    fontSize: '0.875rem',
    '&.disabled': {
      color: colors.disabledGrey,
    },
  },
}));

function BiologicalTransportationOptions({
  itemKey,
  formikProps,
  options,
  disabled,
}) {
  const formClasses = useItemProductDetailsFormStyles();
  const classes = useStyles();
  const transportationMode = formikProps?.values[TRANSPORTATION_OPTIONS_NAME_KEY];
  const isExceptedOrLimited = transportationMode === SHIPMENT_QUANTITY_EXCEPTED_VALUE
    || transportationMode === SHIPMENT_QUANTITY_LIMITED_VALUE;
  const packingInstructions = useMemo(() => get(formikProps?.values, FORM_FIELDS_CHEMICAL_HAZMAT.packingInstructions.key, ''),
    [formikProps?.values[FORM_FIELDS_CHEMICAL_HAZMAT.packingInstructions.key]]);
  const packingInstructionsLabel = !isEmpty(packingInstructions) && itemKey === TRANSPORTATION_OPTIONS_NAME_KEY
    ? `(Packing Instructions: ${packingInstructions})`
    : '';

  useEffect(() => {
    if (transportationMode === SHIPMENT_QUANTITY_BULK_VALUE) {
      setTransportationMode(TRANSPORTATION_OPTION_PASSENGER_CARGO_VALUE);
    }
  }, [transportationMode]);

  const setTransportationMode = (selectedValue) => {
    formikProps.setFieldValue(
      TRANSPORTATION_OPTIONS_NAME_KEY,
      selectedValue,
    );
  };
  const isOptionChecked = (option) => {
    const isBulk = !isExceptedOrLimited
      && itemKey === SHIPMENT_QUANTITY_NAME_KEY
      && option.value === SHIPMENT_QUANTITY_BULK_VALUE;
    const isPassengerCargo = isExceptedOrLimited
      && itemKey === TRANSPORTATION_OPTIONS_NAME_KEY
      && option.value === TRANSPORTATION_OPTION_PASSENGER_CARGO_VALUE;

    if (isBulk || isPassengerCargo) return true;

    return option.value === transportationMode;
  };

  const isShipmentQuantityOptionDisabled = (option) => {
    if (!disabled && option.value === SHIPMENT_QUANTITY_BULK_VALUE) return false;

    return disabled || isMaterialForbidden(formikProps?.values);
  };

  const isTransportationModeOptionDisabled = (option) => disabled
  || (option.value !== TRANSPORTATION_OPTION_GROUND_VALUE && validateChemicalTransportationQuantity(formikProps?.values, option));

  const isOptionDisabled = (option) => (itemKey === TRANSPORTATION_OPTIONS_NAME_KEY
    ? isTransportationModeOptionDisabled(option)
    : isShipmentQuantityOptionDisabled(option));

  return (
    <Grid item direction="column" spacing={1}>
      <Grid item>
        <Typography
          className={formClasses.formFieldHeader}
          color="primary"
        >
          {FORM_LABELS[itemKey]}
          {' '}
          {packingInstructionsLabel}
        </Typography>
      </Grid>
      <Grid className={classes.formControlContainer} item>
        <FormControl className={classes.formControl}>
          <RadioGroup
            name={TRANSPORTATION_OPTIONS_NAME_KEY}
            value={formikProps?.values[TRANSPORTATION_OPTIONS_NAME_KEY]}
            onChange={(e) => {
              const value = e?.target?.value;

              setTransportationMode(value, formikProps);
            }}
          >
            {options.map((option) => {
              const obj = getIataMaxWeight(itemKey, formikProps, option);
              const showQuantity = !isEmpty(obj?.maxNetQuantity);
              const isDisabled = isOptionDisabled(option);

              return (
                <Grid key={option.value} container item alignItems="center">
                  <FormControlLabel
                    className={classes.radioLabel}
                    label={option.label}
                    value={option.value}
                    control={(
                      <Radio
                        className={classes.radio}
                        checked={isOptionChecked(option)}
                        disabled={isDisabled}
                        disableRipple
                      />
                    )}
                  />
                  {showQuantity && (
                    <Typography className={`${classes.maxQuantityText} ${isDisabled ? 'disabled' : ''}`}>
                      (Max net Qnty:
                      {' '}
                      {obj?.maxNetQuantity}
                      {' '}
                      {obj?.maxNetQuantityUnit}
                      )
                    </Typography>
                  )}
                </Grid>
              );
            })}
          </RadioGroup>
        </FormControl>
      </Grid>
    </Grid>
  );
}

BiologicalTransportationOptions.defaultProps = {
  disabled: false,
};

BiologicalTransportationOptions.propTypes = {
  itemKey: PropTypes.oneOf([TRANSPORTATION_OPTIONS_NAME_KEY, SHIPMENT_QUANTITY_NAME_KEY]).isRequired,
  formikProps: PropTypes.objectOf(PropTypes.any).isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
  disabled: PropTypes.bool,
};

export default memo(BiologicalTransportationOptions);
