import React from 'react';
import {
  Grid, CircularProgress,
} from '@material-ui/core';
import { Form } from 'formik';
import PropTypes from 'prop-types';
import remove from 'lodash/remove';
import NewOrderNextButtonClear from '../common/NewOrderNextButtonClear';
import NewOrderNextButton from '../common/NewOrderNextButton';
import { useSingleOrderState } from '../../context/singleOrderContext';
import NewOrderErrorDialog from '../common/NewOrderErrorDialog';
import ConfirmationModal from '../common/ConfirmationModal';
import DisplayItems from './DisplayItems';
import ItemForm from './ItemForm';
import { useItemProductDetailsFormStyles } from './itemDetailsStyles';
import { isProductPaneRequired } from '../../utils/helpers';
import { useUserState } from '../../context/userContext';
import { ITEM_ADD } from './itemDetailsConstants';
import { HAZMAT_BATTERIES, HAZMAT_CHEMICAL } from '../../clientConstants';
import PanelInfo from '../common/PanelInfo';

export default function ItemProductDetailsForm({
  openNextPanel, hideFields,
  submitFormToApi, shipmentId,
}) {
  const orderState = useSingleOrderState();
  const { training } = useUserState();
  const classes = useItemProductDetailsFormStyles();
  const [error, setError] = React.useState(null);
  const [itemToEdit, setItemToEdit] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = React.useState({
    action: '',
    show: false,
  });

  const hiddenFields = (hideFields || []).map(({ fieldName }) => fieldName);
  const {
    shippingType, freightType, hazmatOption, account,
  } = orderState.order;
  const shipment = orderState.shipments[shipmentId];
  const items = shipment?.product?.details?.items || [];
  const initialHideForm = (items && items.length > 0) || (shippingType === 'domestic' && freightType !== 'hazmat');

  const [showItemForm, setShowItemForm] = React.useState(!initialHideForm);

  const handleCompletePanel = async () => {
    if (isProductPaneRequired({ shippingType, freightType })) {
      if (!items || items.length === 0) {
        if (freightType === 'hazmat') {
          setError('Hazmat shipments require at least 1 product');
        } else {
          setError('International shipments require at least 1 product');
        }
        return;
      }
    }

    try {
      await submitFormToApi({ items, isProductCompleted: true }, false);
      openNextPanel();
    } catch (e) {
      setError(e);
    }
  };

  React.useEffect(() => {
    if ((items && items.length === 0) && shippingType === 'international') setShowItemForm(true);
  }, [items]);

  const handleItemDelete = async (itemToDelete) => {
    const newItems = [...items];

    if (itemToDelete.isDeleteAvailable) {
      remove(newItems, (item) => item.itemId === itemToDelete.itemId);

      try {
        await submitFormToApi(
          { items: newItems }, true,
        );
      } catch (e) {
        console.log(e);
      }
    } else {
      setError('You cannot delete the current product as it has been added to packaging. You can edit the product instead.');
    }
  };

  const handleItemEdit = (item) => {
    setShowItemForm(false);
    setItemToEdit(item);
    setShowItemForm(true);
  };

  return (

    <Grid container className={classes.panelContainer} spacing={3}>
      <Grid item style={{ width: '100%' }}>
        <Form style={{ width: '100%' }}>
          <Grid item container direction="column" spacing={2}>
            <Grid item container>
              <Grid item container className={classes.innerFormContainer} spacing={4}>
                {items && items.length > 0
                  && ((hazmatOption === HAZMAT_CHEMICAL && !showItemForm) || hazmatOption !== HAZMAT_CHEMICAL)
                  && (
                  <DisplayItems
                    hiddenFields={hiddenFields}
                    items={items}
                    freightType={freightType}
                    hazmatOption={hazmatOption}
                    training={training}
                    handleItemDelete={handleItemDelete}
                    handleItemEdit={handleItemEdit}
                  />
                  )}
                {showItemForm
                  ? (
                    <ItemForm
                      shipmentId={shipmentId}
                      hiddenFields={hiddenFields}
                      submitFormToApi={submitFormToApi}
                      itemToEdit={itemToEdit}
                      training={training}
                      account={account}
                      showItemForm={showItemForm}
                      setShowConfirmDialog={setShowConfirmDialog}
                      setIsLoading={setIsLoading}
                      setShowItemForm={setShowItemForm}
                      setItemToEdit={setItemToEdit}
                      setError={setError}
                    />
                  )
                  : (
                    <Grid item container>
                      <NewOrderNextButtonClear
                        disabled={isLoading || (hazmatOption === HAZMAT_BATTERIES && items.length > 0)}
                        onClick={() => setShowItemForm(true)}
                      >
                        + Add Item
                      </NewOrderNextButtonClear>
                    </Grid>
                  )}
                {isLoading && <Grid item><CircularProgress color="secondary" /></Grid>}
              </Grid>
            </Grid>
            {shippingType === 'domestic' && freightType !== 'hazmat' && (
            <Grid item container>
              <PanelInfo message="Adding products is optional for domestic shipments." />
            </Grid>
            )}
            <Grid item container>
              <NewOrderNextButton
                disabled={showItemForm}
                onClick={handleCompletePanel}
              >
                Next
              </NewOrderNextButton>
            </Grid>
          </Grid>
        </Form>
      </Grid>
      <NewOrderErrorDialog
        open={!!error}
        errorContent={error}
        onClose={() => setError(null)}
      />
      <ConfirmationModal
        open={showConfirmDialog.show}
        onProceed={() => setShowConfirmDialog({
          action: '',
          show: false,
        })}
        proceedLabel="OK"
        message={`Item ${showConfirmDialog.action === ITEM_ADD ? 'added' : 'updated'} successfully!`}
        noCancel
      />
    </Grid>
  );
}

ItemProductDetailsForm.propTypes = {
  shipmentId: PropTypes.string.isRequired,
  setSelectedForms: PropTypes.func.isRequired,
  submitFormToApi: PropTypes.func.isRequired,
};
