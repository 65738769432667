import React, {
  memo, useCallback, useEffect, useState,
} from 'react';
import isEmpty from 'lodash/isEmpty';
import { CSVLink } from 'react-csv';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Chip,
  CircularProgress, InputAdornment, TextField, Typography,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import FilterIcon from '@material-ui/icons/FilterList';
import SearchIcon from '@material-ui/icons/Search';
import PublicOutlinedIcon from '@material-ui/icons/PublicOutlined';
import * as colors from '../styles/colors';
import Navbar from '../components/Navbar';
import { Page } from '../components/common';
import FilterModal from '../components/FilterModal';
import HazmatIcon from '../components/common/icons/HazmatIcon';
import ShipmentCard from '../components/ShipmentCard';
import { loadAllShipments } from '../utils/shipmentClient';
import { useUserState } from '../context/userContext';
import statusHelp from '../utils/status';
import { formatShipmentFilterChipText } from '../utils/helpers';
import { DEFAULT_FETCH_OPTIONS, SHIPMENT_TRACKING_TAB } from '../clientConstants';
import TrackingModal from '../components/TrackingModal';
import {
  loadFreightTypeOptions, loadOrderPurposes, useMiscDispatch, useMiscState,
} from '../context/miscDataContext';

const INITIAL_TRACKING_MODAL_STATE = {
  anchorEl: null,
  shipmentId: null,
  trackingNumber: null,
  iotTrackingNumber: null,
  openTab: SHIPMENT_TRACKING_TAB,
};

const INITIAL_FILTER_VALUES = {
  status: statusHelp.filterableStatuses.reduce((obj, key) => (
    {
      ...obj,
      [key]: false,
    }
  ), {}),
  freightType: null,
  purpose: null,
  startDate: null,
  endDate: null,
};

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: colors.background,
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  headerContainer: {
    paddingBottom: '10px',
    borderBottom: '1px solid rgba(255,255,255,.2)',
  },
  page: {
    padding: '99px 20px 20px 20px',
    height: 'calc(100vh - 64px)',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
  },
  headerLeftContainer: {
    display: 'flex',
    width: 'auto',
    alignItems: 'center',
  },
  headerLeftContainerText: {
    width: 'auto',
    alignItems: 'center',
  },
  headerIcon: {
    color: colors.white,
    width: 'auto',
    height: 40,
    padding: 0,

  },
  headerText: {
    color: colors.white,
    fontSize: 24,
    fontWeight: 500,
    marginLeft: '10px',
  },
  textField: {
    width: 370,
    background: colors.darkInputFieldBackground,
    marginLeft: '1.5rem',
  },
  labelRoot: {
    color: colors.white,
    fontSize: 16,
    fontWeight: 500,
    background: colors.darkInputFieldBackground,
  },
  inputRoot: {
    background: colors.darkInputFieldBackground,
  },
  endAdornmentRoot: {
    margin: 0,
    '&:not(.MuiInputAdornment-hiddenLabel)': {
      margin: '0px !important',
    },
  },
  filterBar: {
    padding: '5px 0',
  },
  filterButtonContainer: {
    width: '100%',
    display: 'flex',
    alignSelf: 'center',
    justifyContent: 'flex-end',
  },
  filterIcon: {
    color: colors.white,
    fontSize: '30px',
  },
  tableContainer: {
    flexGrow: 2,
    overflow: 'hidden',
    position: 'relative',
  },
  noResultsIconContainer: {
    fontSize: 160,
    display: 'flex',
    background: colors.newOrderFormBackground,
    borderRadius: 130,
    height: 260,
    width: 260,
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 50,
  },
  noResultsIconRoot: {
    fontSize: 160,
    color: '#80808C',
  },
  noResultsText: {
    color: colors.tableGreyText,
    fontSize: 14,
  },
  noResultsTextContainer: {
    marginTop: 20,
  },
}));

const CSV_COLUMNS_HEADER = [
  {
    key: 'shipmentId',
    label: 'Shipment ID',
  },
  {
    key: 'trackingNumbers',
    label: 'Tracking Numbers',
  },
  {
    key: 'masterTrackingNumber',
    label: 'Master Tracking Number',
  },
  {
    key: 'expectedDeliveryDateTime',
    label: 'Expected Delivery Date',
  },
  {
    key: 'shippingType',
    label: 'Type',
  },
  {
    key: 'freightType',
    label: 'Freight Type',
  },
  {
    key: 'hazmatType',
    label: 'Hazmat class',
  },
  {
    key: 'numPackages',
    label: 'Total Packages',
  },
  {
    key: 'packageWeight',
    label: 'Weight',
  },
  {
    key: 'originAddressName',
    label: "Sender's Name",
  },
  {
    key: 'originAddressLine1',
    label: "Sender's Address Line 1",
  },
  {
    key: 'originAddressLine2',
    label: "Sender's Address Line 2",
  },
  {
    key: 'originAddressState',
    label: "Sender's State",
  },
  {
    key: 'originAddressCity',
    label: "Sender's City",
  },
  {
    key: 'originAddressZip',
    label: "Sender's ZIP",
  },
  {
    key: 'originAddressCountry',
    label: "Sender's Country",
  },
  {
    key: 'originAddressPhone',
    label: "Sender's Phone",
  },
  {
    key: 'originAddressEmail',
    label: "Sender's Email",
  },
  {
    key: 'recipientAddressName',
    label: "Recipient's Name",
  },
  {
    key: 'recipientAddressLine1',
    label: "Recipient's Address Line 1",
  },
  {
    key: 'recipientAddressLine2',
    label: "Recipient's Address Line 2",
  },
  {
    key: 'recipientAddressState',
    label: "Recipient's State",
  },
  {
    key: 'recipientAddressCity',
    label: "Recipient's City",
  },
  {
    key: 'recipientAddressZip',
    label: "Recipient's ZIP",
  },
  {
    key: 'recipientAddressCountry',
    label: "Recipient's Country",
  },
  {
    key: 'recipientAddressPhone',
    label: "Recipient's Phone",
  },
  {
    key: 'recipientAddressEmail',
    label: "Recipient's Email",
  },
  {
    key: 'pickupDropoff',
    label: 'Pickup/Drop',
  },
  {
    key: 'expectedPickupDateTime',
    label: 'Expected Pickup Time',
  },
  {
    key: 'recipient',
    label: 'Recipient',
  },
  {
    key: 'collaborators',
    label: 'Collaborators',
  },
  {
    key: 'carrier',
    label: 'Carrier',
  },
  {
    key: 'listPrice',
    label: 'List Price',
  },
  {
    key: 'amount',
    label: 'Amount',
  },
  {
    key: 'status',
    label: 'Status',
  },
  {
    key: 'updated',
    label: 'Updated',
  },
  {
    key: 'costCodes',
    label: 'Cost Codes',
  },
];

const AllShipments = () => {
  const classes = useStyles();
  const lastCardRef = React.useRef(null);
  const { accountType } = useUserState();
  const { orderPurposes, freightTypeOptions } = useMiscState();
  const miscDispatch = useMiscDispatch();
  const [search, setSearch] = useState('');
  const [filterState, setFilterState] = React.useState(INITIAL_FILTER_VALUES);
  const [filterOpen, setFilterOpen] = React.useState(null);
  const [currentTrackingTab, setCurrentTrackingTab] = React.useState(SHIPMENT_TRACKING_TAB);
  const [trackingModalState, setTrackingModalState] = React.useState(INITIAL_TRACKING_MODAL_STATE);
  const [state, setAllState] = useState({
    items: [],
    params: {
      ...DEFAULT_FETCH_OPTIONS,
      filters: null,
    },
    loading: false,
  });
  const [csvShipmentsState, setCSVShipmentsState] = React.useState({
    data: [],
    loading: false,
  });

  const csvLinkButton = React.useRef(null);
  useEffect(() => {
    if (!orderPurposes) loadOrderPurposes(miscDispatch);
    if (!freightTypeOptions) loadFreightTypeOptions(miscDispatch);

    getAllShipments();
  }, []);

  useEffect(() => {
    if (orderPurposes && freightTypeOptions) {
      setFilterState({
        ...filterState,
        purpose: reduceOptions(orderPurposes),
        freightType: reduceOptions(freightTypeOptions),
      });
    }
  }, [orderPurposes, freightTypeOptions]);

  useEffect(() => {
    if (!isEmpty(state.items) && search.length !== 0 && search.length >= 2) {
      getAllShipments({ ...DEFAULT_FETCH_OPTIONS, filters: { ...state.params.filters, filter: search } });
    }
  }, [search]);

  useEffect(() => {
    window.addEventListener('scroll', onWindowScroll);

    return () => {
      window.removeEventListener('scroll', onWindowScroll);
    };
  }, [state.loading]);
  React.useEffect(() => {
    if (!isEmpty(csvShipmentsState.data) && csvLinkButton && csvLinkButton.current) {
      csvLinkButton.current.link.click();
    }
  }, [csvShipmentsState.data]);

  const reduceOptions = (options) => options.reduce((obj, value) => ({
    ...obj,
    [value.type]: false,
  }), {});

  const getActiveFilters = (obj, [key, value]) => {
    let newValue = value;

    if (typeof value === 'object' && value) {
      newValue = Object.keys(value).filter((item) => (value[item]));
    }

    return !isEmpty(newValue) ? {
      ...obj,
      [key]: newValue,
    } : obj;
  };

  const getAllShipments = (options = DEFAULT_FETCH_OPTIONS, isScrolling = false) => {
    setAllState({
      ...state,
      loading: true,
    });

    const newOptions = {
      ...state.params,
      ...options,
      offset: isScrolling && !isEmpty(state.items) ? state.params.limit + state.params.offset : 0,
    };

    loadAllShipments(newOptions).then((data) => {
      setAllState({
        items: isScrolling ? state.items.concat(data) : data,
        params: newOptions,
        loading: false,
      });
    }).catch((e) => {
      setAllState({
        ...state,
        loading: false,
      });
    });
  };

  const onWindowScroll = useCallback(() => {
    const windowScroll = window.scrollY + window.innerHeight;
    const elementLocation = (lastCardRef.current
        && (lastCardRef.current.offsetTop + lastCardRef.current.clientHeight + 64)) || Infinity;

    if ((windowScroll >= elementLocation) && !state.loading) {
      lastCardRef.current = null;

      getAllShipments(state.params, true);
    }
  }, [state.loading]);

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const handleChipDelete = (key) => {
    const newFilters = state.params.filters;

    delete newFilters[key];

    getAllShipments({
      ...state.params,
      filters: newFilters,
    });
  };

  const handleSubmit = () => {
    getAllShipments({
      ...state.params,
      filters: {
        ...state.params.filters,
        ...Object.entries(filterState).reduce(
          (obj, [key, value]) => getActiveFilters(obj, [key, value]), {},
        ),
      },
    });

    setFilterOpen(null);
  };

  const handleFilterClear = () => {
    getAllShipments({
      ...state.params,
      filters: {
        filter: search,
      },
    });

    setFilterState(INITIAL_FILTER_VALUES);
  };

  const handleOpenTrackingModal = ({
    shipmentId, trackingNumber, anchorEl, iotTrackingNumber, openTab,
  }) => {
    setTrackingModalState({
      shipmentId, trackingNumber, anchorEl, iotTrackingNumber, openTab,
    });

    setCurrentTrackingTab(openTab);
  };

  const handleTrackingClose = () => {
    setTrackingModalState(INITIAL_TRACKING_MODAL_STATE);
  };
  const handleCSV = () => {
    setCSVShipmentsState({
      data: [],
      loading: true,
    });

    loadAllShipments({
      ...state.params, type: 'export', limit: null, offset: null,
    }).then((response) => {
      setCSVShipmentsState(
        {
          data: response,
          loading: false,
        },
      );
    }).catch((e) => {
      setCSVShipmentsState({
        data: [],
        loading: false,
      });
    });
  };
  return (
    <div className={classes.root}>
      <Navbar />
      <Page>
        <Grid className={classes.headerContainer} container justify="space-between" alignItems="center">
          <Grid className={classes.headerLeftContainer} item>
            <Grid className={classes.headerLeftContainerText} container item>
              <PublicOutlinedIcon className={classes.headerIcon} />
              <Typography className={classes.headerText}>All Shipments</Typography>
            </Grid>
            <Grid item>
              <TextField
                id="all-outlined-basic"
                className={classes.textField}
                classes={{ root: classes.textFieldRoot }}
                label="Search All shipments"
                margin="normal"
                onChange={handleSearch}
                InputProps={{
                  disableUnderline: true,
                  classes: {
                    root: classes.inputRoot,
                    focused: classes.inputRoot,
                  },
                  endAdornment: (
                    <InputAdornment
                      position="start"
                      classes={{
                        root: classes.endAdornmentRoot,
                        filled: classes.endAdornmentRoot,
                        positionStart: classes.endAdornmentRoot,
                      }}
                    >
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{ classes: { root: classes.labelRoot } }}
                variant="filled"
              />
            </Grid>
          </Grid>
          <Grid item>
            <Button
              className={classes.exportButton}
              color="secondary"
              variant="contained"
              onClick={handleCSV}
            >
              {csvShipmentsState.loading ? <CircularProgress style={{ width: 25, height: 25 }} /> : 'Export'}
            </Button>
            <CSVLink
              ref={csvLinkButton}
              headers={CSV_COLUMNS_HEADER}
              data={csvShipmentsState.data}
              filename={`all-shipments-${new Date().toISOString()}.csv`}
            />
          </Grid>
        </Grid>
        <Grid item container className={classes.filterBar} justify="space-between">
          <FilterModal
            accountType={accountType}
            anchorEl={filterOpen}
            handleClose={() => setFilterOpen(null)}
            filterState={filterState}
            orderPurposeOptions={orderPurposes}
            freightTypeOptions={freightTypeOptions}
            setFilterState={setFilterState}
            onFilterClear={handleFilterClear}
            submitFilters={handleSubmit}
          />
          <Grid item container className={classes.activeFiltersWrap}>
            {state.params.filters && Object.entries(state.params.filters).map(
              ([filterType, filterValues]) => (filterType !== 'filter' && (
              <Grid
                item
                className={classes.activeFilterChip}
                key={`active-filter-ship-grid-${filterType}`}
              >
                <Typography
                  key={`active-filter-ship-typography-${filterType}`}
                  className={classes.activeFilterChipText}
                >
                  <Chip
                    key={`active-filter-ship-typography-${filterType}`}
                    variant="outlined"
                    className={classes.activeFilterChipText}
                    color="primary"
                    label={formatShipmentFilterChipText(filterType, filterValues)}
                    onDelete={() => handleChipDelete(filterType)}
                  />
                </Typography>
              </Grid>
              )),
            )}
          </Grid>
          <Grid item className={classes.filterButtonContainer}>
            <Button
              onClick={(e) => setFilterOpen(e.currentTarget)}
              data-testid="filter-all-shipments-button"
            >
              <FilterIcon className={classes.filterIcon} />
            </Button>
          </Grid>
        </Grid>
        <Grid className={classes.tableContainer} container item justify="center">
          {!isEmpty(state.items)
            ? (state.items.map((shipment, idx, arr) => (
              <ShipmentCard
                key={`shipment-tile-${shipment.orderId}-${shipment.shipmentId}`}
                shipment={shipment}
                classes={classes}
                openTrackingModal={handleOpenTrackingModal}
                lastCardRef={idx === arr.length - 1 ? lastCardRef : undefined}
                buttonText="VIEW"
                showDuplicate={false}
              />
            )))
            : (
              !state.loading
                && (
                  <Grid item container direction="column" alignItems="center">
                    <Grid item className={classes.noResultsIconContainer}>
                      <HazmatIcon className={classes.noResultsIconRoot} />
                    </Grid>
                    <Grid item className={classes.noResultsTextContainer}>
                      <Typography className={classes.noResultsText}>
                        No shipments found!
                      </Typography>
                    </Grid>
                  </Grid>
                )
            )}
          {state.loading && <CircularProgress />}
        </Grid>
      </Page>
      <TrackingModal
        handleClose={handleTrackingClose}
        id={`tracking-${trackingModalState.shipmentId || ''}`}
        trackingModalState={trackingModalState}
        currentTab={currentTrackingTab}
        setCurrentTab={setCurrentTrackingTab}
      />
    </div>
  );
};

export default memo(AllShipments);
