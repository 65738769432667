/* eslint-disable max-len */
import React, { useState } from 'react';
import get from 'lodash/get';
import has from 'lodash/has';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import {
  Grid, CircularProgress, Radio, RadioGroup, Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { CustomTextArea } from './common/InputComponents';
import { NEW_ORDER_CONFIRMATION_STYLE, NEW_ORDER_SCHEDULE_PICKUP_STYLE } from '../styles/style';
import * as colors from '../styles/colors';
import { useSingleOrderState } from '../context/singleOrderContext';
import { useUserState } from '../context/userContext';
import NewOrderNextButton from './common/NewOrderNextButton';
import { loadExportControl } from '../utils/miscClient';
import ComplianceCheckResults from './ComplianceCheckResults';
import { disableSubmitOnEnterKey } from '../utils/helpers';

const styles = {
  panelContainer: {
    background: colors.newOrderFormBackground,
  },
  innerFormContainer: {
    padding: 15,
    margin: '10px 0',
    background: colors.darkBlueBackground,
  },
  formFieldHeader: {
    fontWeight: 500,
    fontSize: '14px',
  },
  formFieldSubHeader: {
    fontSize: '14px',
    fontWeight: 500,
  },
  alertModal: {
    height: 'auto',
    width: 650,
    maxWidth: 'none',
    background: colors.newOrderFormBackground,
    padding: '35px 25px 10px 25px',
    borderRadius: 8,
    position: 'relative',
  },
  closeIcon: {
    position: 'absolute',
    top: 10,
    right: 10,
  },
  dialogActionsContainer: {
    paddingBottom: 24,
    width: '100%',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  fileFormButton: {
    display: 'none',
  },
  button: {
    marginTop: 15,
    marginBottom: 15,
    paddingLeft: '50px',
    paddingRight: '50px',
  },
};

const useStyles = makeStyles(styles);

const init = () => ({

});

const validationSchema = Yup.object().shape({

});

const ComplianceCheckForm = ({
  readOnly,
  noShowNext,
  isComplete,
  shipmentId,
  openNextPanel,
  shipment, submitFormToApi, isFailureWithNoTask, loading,
}) => {
  const exportCompliance = shipment?.exportCompliance?.details
    && Object.keys(shipment.exportCompliance.details).some((key) => key.endsWith('Check')) ? shipment.exportCompliance : undefined;
  const isAssignedTo = !!(exportCompliance?.task?.isAssignedTo);
  const hasECOComments = !!(exportCompliance?.details?.ecoComments);
  const getECOComments = exportCompliance?.details?.ecoComments;
  const hasEHSComments = !!(exportCompliance?.details?.ehsComments);
  const getEHSComments = exportCompliance?.details?.ehsComments;
  // eslint-disable-next-line react/prop-types
  const account = shipment?.account;

  const c = NEW_ORDER_SCHEDULE_PICKUP_STYLE();

  const [isLoading, setIsLoading] = useState(false);

  const classes = useStyles();

  const onSubmit = async () => {
    const payload = {
      type: 'load',
    };

    try {
      setIsLoading(true);
      await submitFormToApi(payload);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    if (loading !== undefined) {
      setIsLoading(loading);
    }
  }, [loading]);
  React.useEffect(() => {
    if (!exportCompliance) onSubmit();
  }, [exportCompliance]);
  return (
    <Grid container className={classes.panelContainer} spacing={3}>
      <Grid item style={{ width: '100%' }}>
        {has(exportCompliance, 'details.success') ? (
          <ComplianceCheckResults
            shipmentId={shipmentId}
            submitFormToApi={submitFormToApi}
            openNextPanel={openNextPanel}
            readOnly={readOnly}
            isComplete={isComplete}
            noShowNext={noShowNext}
            isFailureWithNoTask={isFailureWithNoTask}
            account={account}
          />
        ) : (
          <Formik
            initialValues={init()}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {() => (
              <Form
                style={{ width: '100%' }}
                onKeyDown={disableSubmitOnEnterKey}
              >
                {hasECOComments && (
                  <Grid
                    style={{ paddingTop: 10, paddingBottom: 10 }}
                    container
                    xs={12}
                    direction="column"
                  >
                    <Grid style={{ paddingBottom: 4 }} item>
                      <Typography
                        style={{ size: 16, color: 'white', fontWeight: 500 }}
                      >
                        Comments:
                      </Typography>
                    </Grid>
                    <Grid item>
                      <CustomTextArea
                        disabled={!isAssignedTo}
                        name="ecoComments"
                        placeholder={getECOComments}
                        classes={c}
                      />
                    </Grid>
                  </Grid>
                )}
                {hasEHSComments && (
                  <Grid
                    style={{ paddingTop: 10, paddingBottom: 10 }}
                    container
                    xs={12}
                    direction="column"
                  >
                    <Grid style={{ paddingBottom: 4 }} item>
                      <Typography
                        style={{ size: 16, color: 'white', fontWeight: 500 }}
                      >
                        Comments:
                      </Typography>
                    </Grid>
                    <Grid item>
                      <CustomTextArea
                        disabled={!isAssignedTo}
                        name="ehsComments"
                        placeholder={getEHSComments}
                        classes={c}
                      />
                    </Grid>
                  </Grid>
                )}
              </Form>
            )}
          </Formik>
        )}

        {isLoading && (
          <Grid
            item
            container
            justify="center"
            classes={{ root: classes.miscLoadingContainer }}
          >
            <CircularProgress color="secondary" />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

ComplianceCheckForm.propTypes = {
  shipmentId: PropTypes.string.isRequired,
  setSelectedForms: PropTypes.func.isRequired,
  submitFormToApi: PropTypes.func.isRequired,
};

export default ComplianceCheckForm;
