import React from 'react';
import WarningIcon from '@material-ui/icons/WarningOutlined';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const styles = {
  color: {
    color: '#F1C21B',
  },
  root: {
    marginRight: 5,
    marginTop: 5,
  },
};

const useStyles = makeStyles(styles);

export default function StyledWarningIcon({classes}) {

  const internalClasses = useStyles();

  return (
    <WarningIcon className={`${classes} ${internalClasses.color}`} />
  );
}

StyledWarningIcon.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
};

StyledWarningIcon.defaultProps = {

};
