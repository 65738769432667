import React from 'react';
import PropTypes from 'prop-types';
import NewOrderShipmentModule from './common/NewOrderShipmentModule';
import RegulationAndComplianceForm from './RegulationAndComplianceForm';
import {
  useMiscDispatch, useMiscState,
  loadTermsOfSale, loadPartyTerms,
} from '../context/miscDataContext';
import BlockedPanelDisplay from './common/PanelWarning';


function getLabel(type, options) {
  const label = options.find((obj) => obj.value === type);
  return label ? label.label : '';
}

function regulationAndComplianceDetails(compliance, shipmentTermsOfSale) {
  const termsOfSaleLabel = shipmentTermsOfSale ? getLabel(compliance?.termsOfSale?.type, shipmentTermsOfSale) : null;
  return `${termsOfSaleLabel}`;
}
export default function RegulationAndCompliancePane({
  shipment,
  isComplete,
  isReadyToBeCompleted,
  blockingMessage,
  ...shipmentProps
}) {

  const {
    shipmentTermsOfSale,
    shipmentPartyTerms,
  } = useMiscState();

  const miscDispatch = useMiscDispatch();

  React.useEffect(() => {
    loadTermsOfSale(miscDispatch);
    loadPartyTerms(miscDispatch);
  }, [miscDispatch]);

  const labelDetails = (isComplete && shipment) ? regulationAndComplianceDetails(
    shipment?.compliance?.details,
    shipmentTermsOfSale,
  ) : '';

  return (
    <NewOrderShipmentModule
      labelDetails={labelDetails}
      isComplete={isComplete}
      isReadyToBeCompleted={isReadyToBeCompleted}
      shipment={shipment}
      blockingMessage={blockingMessage}
      {...shipmentProps}
    >
      {(isReadyToBeCompleted) ? ((renderProps) => (
          <RegulationAndComplianceForm
            shipment={shipment}
            isComplete={isComplete}
            isReadyToBeCompleted={isReadyToBeCompleted}
            shipmentTermsOfSale={shipmentTermsOfSale}
            shipmentPartyTerms={shipmentPartyTerms}
            {...shipmentProps}
            {...renderProps}
          />
        )): (
          <BlockedPanelDisplay message={blockingMessage} />
      )}
    </NewOrderShipmentModule>
  );
}

RegulationAndCompliancePane.propTypes = {
  label: PropTypes.string.isRequired,
  parentFormName: PropTypes.string.isRequired,
  formName: PropTypes.string.isRequired,
  selectedForms: PropTypes.objectOf(PropTypes.string).isRequired,
  shipmentId: PropTypes.string.isRequired,
  setSelectedForms: PropTypes.func.isRequired,
};
