import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Typography,
  IconButton,
  Tooltip,
  CircularProgress,
} from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
import { css } from 'emotion';
import EditIcon from '@material-ui/icons/Edit';
import {
  Delete24 as DeleteIcon,
  Information16 as InfoIcon,
} from '@carbon/icons-react';
import { makeStyles } from '@material-ui/core/styles';
import { Field, Formik, FieldArray } from 'formik';
import { pickBy } from 'lodash';
import * as colors from '../../styles/colors';
import NewOrderErrorDialog from './NewOrderErrorDialog';
import { NEW_ORDER_BILLING_PAYMENT_STYLE } from '../../styles/style';
import * as billingUtil from '../../utils/billingUtil';

import {
  CustomText,
  CustomSearchWithDropdown,
  CustomSearch,
} from './InputComponents';
import { loadBillCodes } from '../../utils/paymentClient';

import {
  getEmptyAllocation, getTypography,
} from '../BillingPayment/util';
import NewOrderNextButton from './NewOrderNextButton';
import NewOrderNextButtonClear from './NewOrderNextButtonClear';
import {
  updateCostCodeProfile,
  usePaymentDispatch,
} from '../../context/paymentContext';

const costCodePanelStyles = {
  container: {
    color: colors.white,
    backgroundColor: colors.darkBlueBackground,
    padding: '12px 18px',
    borderRadius: '8px',
    margin: '12px',
  },
  headerText: {
    fontSize: '13px',
    fontWeight: '500',
    letterSpacing: '0.4px',
    textTransform: 'uppercase',
  },
  tooltip: {
    background: colors.tooltipBackground,
    fontSize: 12,
  },
  bodyText: {
    color: colors.textLightGrey,
    fontSize: 14,
    padding: '2px 0',
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  deleteIcon: {
    fill: 'white',
  },
  editPaymentContainer: {
    padding: '15px 15px',
    background: colors.darkBlueBackground,
  },
  preferredPaymentSelected: {
    color: 'gold',
  },
  preferredPaymentUnselected: {
    color: colors.textGrey,
  },
  preferredPaymentButton: {
    minWidth: 0,
  },
};

const useCostCodePanelStyles = makeStyles(costCodePanelStyles);

export default function CostCodePanel(props) {
  const {
    costCodeProfile,
    costAllocation,
    editing,
    setEditing,
    makeDefaultCostCodeProfile,
    deleteCostCodeProfile,
  } = props;
  const {
    billingCode, isDefault, costCodeId, profileName,
  } = costCodeProfile;

  const [error, setError] = React.useState(null);
  const formikRef = React.useRef();

  const classes = useCostCodePanelStyles();
  const COMPONENT_ID = 'billing-payment-form';
  const inputClass = NEW_ORDER_BILLING_PAYMENT_STYLE();
  const dispatch = usePaymentDispatch();
  function getInitialValues(ca) {
    const initialBillCodes = [costCodeProfile?.billCodes];

    if (ca && ca.billCodes && initialBillCodes.length <= 0) {
      initialBillCodes.push(getEmptyAllocation(ca.billCodes));
    }

    const initialValues = {
      costCodeId,
      isDefault: costCodeProfile?.isDefault || false,
      isActive: costCodeProfile?.isActive || true,
      billCodes: initialBillCodes,
      profileName: costCodeProfile?.profileName,
    };
    return initialValues;
  }
  async function onSubmit(values, { setSubmitting }) {
    // eslint-disable-next-line no-shadow
    const { costCodeId } = values;
    const formattedBillCodes = values.billCodes.map((bca) => pickBy(
      bca,
      (val, key) => (typeof val === 'string' && key !== 'key') || !!val.code,
    ));
    const payload = { ...values, billCodes: formattedBillCodes[0] };
    try {
      console.log('update');
      await updateCostCodeProfile({ dispatch, costCodeId, payload });
    } catch (e) {
      setError(e.message);
      setSubmitting(false);
      return;
    }
    setSubmitting(false);
    setEditing(null);
  }

  return (
    <>
      {editing !== costCodeId && (
        <Grid item key={costCodeId} className={classes.container}>
          <Grid
            item
            container
            direction="row"
            className={css`
              height: 64px;
              padding-top: 24px;
              align-items: center;
            `}
          >
            <Typography
              className={css`
                color: ${colors.white};
              `}
              id={`${COMPONENT_ID}-cost-allocation-summary-${costCodeId}`}
            >
              {'Cost Code Profile Name:  '}
            </Typography>
            <Typography
              className={css`
                color: ${colors.textLightGrey};
                margin-left: 5px;
              `}
              id={`${COMPONENT_ID}-cost-allocation-det-${costCodeId}`}
            >
              {` ${profileName}`}
            </Typography>
            <Grid item container xs={6} alignItems="center" justify="flex-end">
              <Grid
                item
                container
                style={{ width: 'auto' }}
                spacing={2}
                wrap="nowrap"
              >
                {isDefault ? (
                  <Grid item container spacing={2} wrap="nowrap">
                    <Grid item>
                      <Tooltip
                        title="Default Profile"
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <StarIcon style={{ color: 'gold' }} />
                      </Tooltip>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid item container spacing={2} wrap="nowrap">
                    <Grid item>
                      <Tooltip
                        title="Make Default Profile"
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <IconButton style={{ padding: 0 }} onClick={() => { makeDefaultCostCodeProfile(costCodeId); }}>
                          <StarIcon
                            style={{
                              color: 'rgba(255, 255, 255, 0.1)',
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                )}
                <Grid item>
                  <IconButton
                    style={{ padding: 0 }}
                    disableFocusRipple
                    onClick={() => setEditing((prev) => costCodeId)}
                  >
                    <EditIcon />
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton
                    style={{ padding: 0 }}
                    disableFocusRipple
                    onClick={() => { deleteCostCodeProfile(costCodeId); }}
                  >
                    <DeleteIcon className={classes.deleteIcon} />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            container
            wrap="nowrap"
            className={css`
              padding-bottom: 20px;
              margin-top: 15px;
            `}
          >
            <Grid
              item
              container
              direction="column"
              className={css`
                padding-right: 22px;
              `}
            >
              {costAllocation?.billCodes?.map(({ code, description }) => {
                const content = code in costCodeProfile.billCodes
                  && costCodeProfile?.billCodes[code].code
                  ? `${costCodeProfile.billCodes[code].code}${
                        costAllocation?.isDescriptionAllowed
                          ? ` - ${costCodeProfile.billCodes[code].description}`
                          : ''
                  }`
                  : '';
                return (
                  <Grid container direction="row">
                    <Grid
                      xs={4}
                      item
                      key={`allocation-summary-field-${costCodeId}-${code}`}
                    >
                      {getTypography(
                        `allocation-summary-${costCodeId}-${code}`,
                        description,
                        css`
                          color: ${colors.white};
                          font-size: 15px;
                        `,
                      )}
                    </Grid>
                    <Grid
                      key={`allocation-summary-description-${costCodeId}-${code}`}
                      item
                      xs={8}
                      className={css`
                        color: ${colors.textLightGrey};
                      `}
                    >
                      <Typography
                        className={css`
                          font-size: 15px;
                        `}
                      >
                        {content || <i>not specified</i>}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      )}
      {editing === costCodeId && (
        <Grid container direction="column" item>
          <Formik
            ref={formikRef}
            initialValues={getInitialValues(costAllocation)}
            validationSchema={billingUtil.getCostCodeProfileSchema(
              costAllocation,
            )}
            onSubmit={onSubmit}
          >
            {(formikProps) => (
              <>
                <Grid container direction="column">
                  <Grid container direction="column" spacing={1}>
                    <Grid item xs={6}>
                      <Field
                        component={CustomText}
                        name="profileName"
                        label="Cost Code Profile Name"
                      />
                    </Grid>
                    {costAllocation?.billCodes?.map((bc) => (
                      <CustomCostCodeField
                        billCode={bc}
                        allocationNumber={0}
                        isDescriptionAllowed
                      />
                    ))}
                  </Grid>

                  <Grid
                    container
                    item
                    style={{ width: '250px', marginTop: 10 }}
                    justify="space-between"
                  >
                    <Grid item>
                      <NewOrderNextButtonClear
                        disabled={formikProps.isSubmitting}
                        onClick={() => setEditing(null)}
                      >
                        Cancel
                      </NewOrderNextButtonClear>
                    </Grid>
                    <Grid item>
                      <NewOrderNextButton
                        disabled={formikProps.isSubmitting}
                        onClick={formikProps.submitForm}
                      >
                        Submit
                      </NewOrderNextButton>
                    </Grid>
                  </Grid>
                  {formikProps.isSubmitting && (
                    <Grid item style={{ padding: '10px 0' }}>
                      <CircularProgress color="secondary" />
                    </Grid>
                  )}
                </Grid>
              </>
            )}
          </Formik>
        </Grid>
      )}
      <NewOrderErrorDialog
        open={!!error}
        errorContent={error}
        onClose={() => setError(null)}
      />
    </>
  );
}

CostCodePanel.propTypes = {
  costCodeProfile: PropTypes.objectOf(PropTypes.any).isRequired,
  deleteCostCodeProfile: PropTypes.func.isRequired,
  makeDefaultCostCodeProfile: PropTypes.func.isRequired,
  editing: PropTypes.string,
  setEditing: PropTypes.func.isRequired,
};

CostCodePanel.defaultProps = {
  editing: null,
};

function CustomCostCodeField(props) {
  const { billCode, allocationNumber, isDescriptionAllowed } = props;

  const [cache, setCache] = React.useState({});
  const [filteredCostCodeOptions, setFilteredCostCodeOptions] = React.useState(
    [],
  );

  const localFilter = (text) => {
    const filterRegex = new RegExp(text, 'i');
    return (d) => d.value.match(filterRegex) || d.label.match(filterRegex);
  };

  const updateFilter = (text) => {
    if (!text || text.length < 3) return setFilteredCostCodeOptions([]);
    const cacheKey = billCode.code + text;
    if (cache[cacheKey]) {
      setFilteredCostCodeOptions(cache[cacheKey].filter(localFilter(text)));
      return;
    }
    loadBillCodes(billCode.code, text).then((data) => {
      const dropdownList = data
        ? data.map((o) => ({
          ...o,
          value: o.code,
          label: isDescriptionAllowed
            ? `${o.code} - ${o.description || ''}`
            : o.code,
        }))
        : [];
      setFilteredCostCodeOptions(dropdownList);
      setCache((prev) => ({ ...prev, [text]: dropdownList }));
    });
  };

  const onSelect = (val) => {
    setFilteredCostCodeOptions([]);
  };

  return (
    <Grid item container>
      <Grid
        container
        item
        key={billCode.code}
        justify="space-between"
        spacing={3}
      >
        {console.log(billCode)}
        <Grid item xs={6}>
          <Field
            name={`billCodes[${allocationNumber}].${billCode.code}.code`}
            component={CustomSearchWithDropdown}
            label={`${billCode.description}${
              billCode.required ? '' : ' (optional)'
            }`}
            onSelect={onSelect}
            callback={updateFilter}
            dropdownList={filteredCostCodeOptions}
            dependentFields={
              isDescriptionAllowed
                ? [
                  {
                    name: `billCodes[${allocationNumber}].${billCode.code}.description`,
                    key: 'description',
                  },
                ]
                : []
            }
            inputProps={
              billCode.disabled
                ? { readOnly: true, disableUnderline: true, ...(billCode["length"] && {maxLength: parseInt(billCode["length"])})}
                : {...(billCode.length && {maxLength: parseInt(billCode.length)})}
            }
          />
        </Grid>
        {isDescriptionAllowed && (
          <Grid item xs={6}>
            <Field
              name={`billCodes[${allocationNumber}].${billCode.code}.description`}
              component={CustomSearch}
              inputProps={{ readOnly: true, disableUnderline: true }}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

CustomCostCodeField.propTypes = {
  billCode: PropTypes.objectOf(
    PropTypes.shape({
      code: PropTypes.string.isRequired,
      billCode: PropTypes.objectOf(
        PropTypes.shape({
          code: PropTypes.string.isRequired,
          description: PropTypes.string.isRequired,
        }),
      ),
    }),
  ).isRequired,
  allocationNumber: PropTypes.number.isRequired,
  isDescriptionAllowed: PropTypes.bool.isRequired,
};
