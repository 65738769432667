/* eslint-disable react/prop-types */
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import {
  AppBar,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Tabs,
  Tab,
  Grid,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import NewOrderTabPanel from './NewOrderTabPanel';
import NewOrderNextButton from './NewOrderNextButton';
import NewOrderNextButtonClear from './NewOrderNextButtonClear';
import { NewOrderAddressListItemByEmail } from './NewOrderAddressListItem';
import {
  CustomDatePicker,
  CustomContactSearch,
  CustomRecieveComponent,
} from './InputComponents';
import {
  useContactsState,
  useContactsDispatch,
  loadFilteredContacts,
} from '../../context/contactsContext';

import ScrollWindow from './ScrollWindow';

/**
 * ##################################
 *          GLOBAL VARIABLES
 * ##################################
 */

const SELECT_FROM_CONTACTS_TAB_VALUE = 'one';
const ENTER_EMAIL_TAB_VALUE = 'two';
const TASK_ASSIGN_TABS = [
  { id: 0, value: 'one', label: 'Select from Contacts' },
  { id: 1, value: 'two', label: 'Enter Email' },
];
const DEFAULT_FORM_VALUES = {
  dueDate: moment().add(1, 'd').format('YYYYMMDD'),
  email: '',
  filter: '',
  selectedContact: '',
};

/**
 * ##################################
 *          CUSTOM COMPONENT
 * ##################################
 */

function CustomAppBar({
  selectedTab, isDisabled, classes, onChange,
}) {
  const {
    appBar,
    appBarIndicator,
    appBarTab,
    appBarTabDisabled,
    appBarTabSelected,
  } = classes;

  return (
    <AppBar position="static" classes={{ root: appBar }}>
      <Tabs
        textColor="primary"
        value={selectedTab}
        onChange={onChange}
        classes={{ indicator: appBarIndicator }}
      >
        {TASK_ASSIGN_TABS.map((tab) => (
          <Tab
            key={`assign-task-tab-${tab.label}`}
            value={tab.value}
            label={tab.label}
            wrapped
            disabled={isDisabled}
            classes={{
              root: appBarTab,
              disabled: appBarTabDisabled,
              selected: appBarTabSelected,
            }}
          />
        ))}
      </Tabs>
    </AppBar>
  );
}

CustomAppBar.propTypes = {
  selectedTab: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    appBar: PropTypes.string.isRequired,
    appBarIndicator: PropTypes.string.isRequired,
    appBarTab: PropTypes.string.isRequired,
    appBarTabDisabled: PropTypes.string.isRequired,
    appBarTabSelected: PropTypes.string.isRequired,
  }).isRequired,
};

function CustomSelectContactTab({
  shipmentId, panelName, selectedTab, classes,
}) {
  const [searchCache, setSearchCache] = useState([]);

  const contactsDispatch = useContactsDispatch();

  /*          Callbacks           */

  function getFilteredContacts(filter) {
    if (filter.length < 3) return;
    const minChars = filter.substring(0, 3).toLowerCase();

    if (searchCache.includes(minChars)) return;

    searchCache.push(minChars);
    setSearchCache(searchCache);

    loadFilteredContacts({
      dispatch: contactsDispatch,
      filter: minChars,
      shipmentId,
      taskType: panelName,
    });
  }

  /*            Render Components            */

  const searchField = (
    <Grid item>
      <CustomContactSearch
        name="filter"
        label="Search for a teammate"
        classes={classes}
        callback={getFilteredContacts}
      />
    </Grid>
  );

  const resultField = (
    <Grid
      item
      classes={{ root: classes.dialogAssignTaskContentResultsContainer }}
    >
      <CustomRecieveComponent
        component={CustomListField}
        name="selectedContact"
        callback={() => {}}
        classes={classes}
      />
    </Grid>
  );

  return (
    <NewOrderTabPanel
      index={SELECT_FROM_CONTACTS_TAB_VALUE}
      value={selectedTab}
      classes={classes}
    >
      <Grid container direction="column" style={{ marginBottom: '-15px' }}>
        {searchField}
        {resultField}
      </Grid>
    </NewOrderTabPanel>
  );
}

CustomSelectContactTab.propTypes = {
  selectedTab: PropTypes.string.isRequired,
  classes: PropTypes.shape({
    dialogAssignTaskContentResultsContainer: PropTypes.string.isRequired,
  }).isRequired,
};

function CustomEnterEmailTab({ selectedTab, classes }) {
  return (
    <NewOrderTabPanel
      index={ENTER_EMAIL_TAB_VALUE}
      value={selectedTab}
      classes={classes}
    >
      <Grid container direction="column">
        <Grid item>
          <CustomContactSearch
            name="email"
            label="Enter email"
            classes={classes}
            callback={() => {}}
          />
        </Grid>
      </Grid>
    </NewOrderTabPanel>
  );
}

CustomEnterEmailTab.propTypes = {
  selectedTab: PropTypes.string.isRequired,
  classes: PropTypes.objectOf(PropTypes.string.isRequired).isRequired,
};

function CustomDueDate({
  values,
  isDisabled,
  classes,
  minDueDate,
  maxDueDate,
  setValues,
}) {
  const {
    dialogAssignTaskContentDueDateContainer,
    dialogAssignTaskLabelDueDate,
  } = classes;
  /*          Callbacks           */

  function onDateChange(e) {
    if (!e) return;

    const year = e.getFullYear();
    const month = e.getMonth() + 1;
    const day = e.getDate();

    const yrMoDay = `${year}${month < 10 ? `0${month}` : month}${
      day < 10 ? `0${day}` : day
    }`;

    setValues({
      ...values,
      dueDate: yrMoDay,
    });
  }

  return (
    <Grid
      container
      direction="column"
      classes={{ root: dialogAssignTaskContentDueDateContainer }}
    >
      <Grid item>
        <Typography classes={{ root: dialogAssignTaskLabelDueDate }}>
          Due Date
        </Typography>
      </Grid>
      <Grid item>
        <CustomDatePicker
          name="dueDate"
          disabled={isDisabled}
          onChange={onDateChange}
          value={moment(values.dueDate, 'YYYYMMDD').format('MM/DD/YYYY')}
          classes={classes}
          minDate={minDueDate}
          maxDate={
            moment(values.dueDate).isSameOrBefore(maxDueDate, 'day')
              ? maxDueDate
              : null
          }
        />
      </Grid>
    </Grid>
  );
}

CustomDueDate.propTypes = {
  values: PropTypes.shape({
    dueDate: PropTypes.string.isRequired,
  }).isRequired,
  isDisabled: PropTypes.bool.isRequired,
  setValues: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    dialogAssignTaskContentDueDateContainer: PropTypes.string.isRequired,
    dialogAssignTaskLabelDueDate: PropTypes.string.isRequired,
  }).isRequired,
};

function CustomMessageContent({ inviteEmail, isSubmitting, classes }) {
  const {
    dialogAssignTaskContentMessageContainer,
    dialogAssignTaskLabelErrorMessage,
    dialogAssignTaskIconError,
  } = classes;

  let message = null;

  if (!inviteEmail && isSubmitting) {
    message = (
      <Grid item>
        <CircularProgress color="secondary" />
      </Grid>
    );
  } else if (inviteEmail) {
    message = (
      <Grid
        item
        container
        wrap="nowrap"
        alignItems="flex-start"
        classes={{ root: dialogAssignTaskContentMessageContainer }}
      >
        <Grid item>
          <ErrorIcon classes={{ root: dialogAssignTaskIconError }} />
        </Grid>
        <Grid item>
          <Typography
            display="inline"
            classes={{ root: dialogAssignTaskLabelErrorMessage }}
          >
            {`This email is not in the eShip system. Only enrolled users can collaborate on shipments.
            Do you want to send an enrollment invitation to this email?`}
          </Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container justify="center">
      {message}
    </Grid>
  );
}

CustomMessageContent.propTypes = {
  inviteEmail: PropTypes.string,
  isSubmitting: PropTypes.bool.isRequired,
  classes: PropTypes.shape({
    dialogAssignTaskContentMessageContainer: PropTypes.string.isRequired,
    dialogAssignTaskLabelErrorMessage: PropTypes.string.isRequired,
    dialogAssignTaskIconError: PropTypes.string.isRequired,
  }).isRequired,
};

CustomMessageContent.defaultProps = {
  inviteEmail: null,
};

/*          UTIL            */

function CustomListField({
  form, field, callback, classes,
}) {
  const { filter, selectedContact } = form.values;

  const content = filter.length > 2 ? (
    <CustomFilterList
      fieldName={field.name}
      filter={filter}
      selectedContact={selectedContact}
      onChange={(e) => { form.setFieldValue(field.name, e.target.value); }}
      callback={callback}
      classes={classes}
      disabled={form.isSubmitting}
    />
  ) : null;

  return <ScrollWindow>{content}</ScrollWindow>;
}

CustomListField.propTypes = {
  form: PropTypes.shape({
    isSubmitting: PropTypes.bool.isRequired,
    values: PropTypes.shape({
      filter: PropTypes.string,
      selectedContact: PropTypes.string,
    }).isRequired,
  }).isRequired,
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  callback: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    dialogAssignTaskScrollbarInnerRoot: PropTypes.string.isRequired,
    dialogAssignTaskScrollbarInnerVertical: PropTypes.string.isRequired,
  }).isRequired,
};

function CustomFilterList({
  fieldName,
  filter,
  selectedContact,
  onChange,
  callback,
  disabled,
  classes,
}) {
  const contacts = useContactsState().contacts || [];
  const filteredContacts = contacts.filter((contact) => {
    const values = Object.keys(contact).map((key) => {
      const value = contact[key];
      return (
        typeof value === 'string'
        && value.toLowerCase().includes(filter.toLowerCase())
      );
    });

    return values.includes(true);
  });

  return (
    <RadioGroup onChange={onChange}>
      {filteredContacts.length === 0 ? (
        <Typography classes={{ root: classes.noResults }}>
          No existing contacts
        </Typography>
      ) : (
        buildContactList(
          fieldName,
          selectedContact,
          filteredContacts,
          disabled,
          callback,
        )
      )}
    </RadioGroup>
  );
}

CustomFilterList.propTypes = {
  fieldName: PropTypes.string.isRequired,
  filter: PropTypes.string.isRequired,
  selectedContact: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  callback: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  classes: PropTypes.shape({
    noResults: PropTypes.string.isRequired,
  }).isRequired,
};

CustomFilterList.defaultProps = {
  selectedContact: '',
};

/**
 * ######################
 *          UTIL
 * ######################
 */

function getValidationSchema(selectedTab) {
  const schemaShape = {};

  if (selectedTab === SELECT_FROM_CONTACTS_TAB_VALUE) {
    schemaShape.selectedContact = Yup.string().required('Required');
  } else if (selectedTab === ENTER_EMAIL_TAB_VALUE) {
    schemaShape.email = Yup.string()
      .email('Invalid email address')
      .required('Required');
  }

  return Yup.object().shape(schemaShape);
}

function buildContactList(
  fieldName,
  selectedContact,
  contacts,
  disabled,
  callback,
) {
  return contacts.map((contact) => (
    <Grid key={`contacts-listItem-${contact.email}`} item>
      {console.log(selectedContact)}
      <NewOrderAddressListItemByEmail
        fieldName={fieldName}
        id={contact.email}
        contact={contact}
        isSelected={selectedContact === contact.email}
        allowEdit={false}
        disabled={disabled}
        onClick={() => callback(contact)}
      />
    </Grid>
  ));
}

function getRootClass(
  selectedTab,
  inviteEmail,
  inviteSent,
  isSubmitting,
  classes,
) {
  const {
    dialogAssignTaskContentSuccessOuterContainer,
    dialogAssignTaskScrollbarOuterRoot,
    dialogAssignTaskScrollbarOuterInvite,
    dialogAssignTaskScrollbarOuterLoading,
    dialogAssignTaskScrollbarOuterRootAlt,
    dialogAssignTaskScrollbarOuterInviteAlt,
    dialogAssignTaskScrollbarOuterLoadingAlt,
  } = classes;

  if (inviteSent) return dialogAssignTaskContentSuccessOuterContainer;

  if (selectedTab === SELECT_FROM_CONTACTS_TAB_VALUE) {
    if (!inviteEmail && isSubmitting) { return dialogAssignTaskScrollbarOuterLoading; }

    return inviteEmail
      ? dialogAssignTaskScrollbarOuterInvite
      : dialogAssignTaskScrollbarOuterRoot;
  }

  if (selectedTab === ENTER_EMAIL_TAB_VALUE) {
    if (!inviteEmail && isSubmitting) { return dialogAssignTaskScrollbarOuterLoadingAlt; }

    return inviteEmail
      ? dialogAssignTaskScrollbarOuterInviteAlt
      : dialogAssignTaskScrollbarOuterRootAlt;
  }
  return undefined;
}

function internalOnSubmitSFCTab(filter, selectedContact, contacts) {
  if (!filter) return null;

  const filteredContacts = contacts.filter((filteredC) => {
    const contactVals = Object.keys(filteredC).map((key) => {
      const value = filteredC[key];
      return (
        typeof value === 'string'
        && value.toLowerCase().includes(filter.toLowerCase())
      );
    });

    return (
      contactVals.includes(true) && filteredC.email === selectedContact
    );
  });

  if (filteredContacts.length !== 1) return null;

  return filteredContacts[0].email;
}

/**
 * #################################
 *          EXPORT FUNCTION
 * #################################
 */

const formatExpiresAtDateTime = (dueDate) => moment(
  moment(dueDate)
    .format()
    .replace(
      /^(\d{4}-\d{2}-\d{2}T)(.*)(-\d{2}:\d{2})$/,
      (match, p1, p2, p3) => [p1, '23:59:59', p3].join(''),
    ),
)
  .utc()
  .format();

export default function AssignmentModal({
  label,
  panelName,
  shipmentId,
  open,
  classes,
  assignTask,
  onInviteSent,
  onClose,
  minDueDate,
  maxDueDate,
}) {
  const [inviteEmail, setInviteEmail] = useState(null);
  const [inviteSent, setInviteSent] = useState(false);
  const [selectedTab, setSelectedTab] = useState(
    SELECT_FROM_CONTACTS_TAB_VALUE,
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [update, setUpdate] = useState(null);

  const { contacts } = useContactsState();

  const {
    dialogAssignTaskContentRoot,
    dialogAssignTaskContentSuccessContainer,
    dialogAssignTaskContentOuterContainer,
    dialogAssignTaskContentInviteContainer,

    dialogAssignTaskLabelSuccessMessage,

    dialogAssignTaskContentHeaderContainer,
    dialogAssignTaskContentActionsContainer,
    dialogAssignTaskLabelHeader,
    dialogAssignTaskLabelAppbarHeader,

    dialogAssignTaskIconSuccess,
  } = classes;

  /*          CALLBACKS           */

  function onTabChange(e, newValue) {
    setSelectedTab(newValue);
  }

  function onInternalOnClose({ result }) {
    resetDialog();

    const applyResult = result || update;
    if (applyResult) onInviteSent(applyResult);
    onClose();
  }

  function resetDialog() {
    if (formikRef.current) formikRef.current.setSubmitting(false);

    setInviteSent(false);
    setIsSubmitting(false);
    setInviteEmail(null);
    setUpdate(null);
  }

  /*          FORMIK PROPS            */

  const formikRef = useRef();
  const validateOnBlur = false;
  const validationSchema = getValidationSchema(selectedTab);

  function getInitialValues(minDueDate) {
    const initialValues = {
      ...DEFAULT_FORM_VALUES,
      dueDate: moment(DEFAULT_FORM_VALUES.dueDate)
        .min(minDueDate)
        .format('YYYYMMDD'),
    };
    return initialValues;
  }

  function onInternalSubmitForm() {
    if (formikRef.current) formikRef.current.submitForm();
  }

  async function onSubmit(data, { setSubmitting, setValues }) {
    setIsSubmitting(true);

    if (inviteEmail) setInviteEmail(null); // trigger processing state

    const {
      dueDate, email, filter, selectedContact,
    } = data;

    const reqBody = {
      expiresAt: formatExpiresAtDateTime(dueDate),
      ...(inviteEmail && { sendInvite: inviteEmail }),
    };

    if (selectedTab === SELECT_FROM_CONTACTS_TAB_VALUE) {
      const contactEmail = internalOnSubmitSFCTab(
        filter,
        selectedContact,
        contacts,
      );

      if (!contactEmail) {
        resetDialog();
        return;
      }
      reqBody.email = contactEmail;
    } else if (selectedTab === ENTER_EMAIL_TAB_VALUE) {
      reqBody.email = email;
    } else {
      resetDialog();
      return;
    }

    const assignTaskResponse = await assignTask(reqBody);

    if (!assignTaskResponse) {
      resetDialog();
      return;
    }

    if (inviteEmail) {
      setInviteEmail(reqBody.email);
      setInviteSent(true);
      setUpdate(assignTaskResponse);
      return;
    }

    if (assignTaskResponse.sendInvite) {
      setInviteEmail(reqBody.email);
    } else if (assignTaskResponse) {
      onInternalOnClose({ result: assignTaskResponse });
    }
  }

  function render(renderProps) {
    const { values, setValues } = renderProps;

    return (
      <Form>
        <CustomSelectContactTab
          panelName={panelName}
          shipmentId={shipmentId}
          selectedTab={selectedTab}
          classes={classes}
        />
        <CustomEnterEmailTab selectedTab={selectedTab} classes={classes} />
        <CustomDueDate
          values={values}
          isDisabled={isSubmitting}
          setValues={setValues}
          classes={classes}
          maxDueDate={maxDueDate && new Date(maxDueDate)}
          minDueDate={minDueDate && new Date(minDueDate)}
        />
      </Form>
    );
  }

  /*            RENDER COMPONENTS            */

  const dialogContentHeader = (
    <>
      <Grid
        container
        direction="column"
        classes={{ root: dialogAssignTaskContentHeaderContainer }}
      >
        <Typography
          variant="h6"
          classes={{ root: dialogAssignTaskLabelHeader }}
        >
          {`Assign Task: ${label}`}
        </Typography>
      </Grid>
      {selectedTab === SELECT_FROM_CONTACTS_TAB_VALUE ? (
        <Typography classes={{ root: dialogAssignTaskLabelAppbarHeader }}>
          Select Assignee
        </Typography>
      ) : null}
    </>
  );

  const inviteDialogContent = (
    <DialogContentText
      component="div"
      classes={{ root: dialogAssignTaskContentInviteContainer }}
    >
      {dialogContentHeader}
      <CustomAppBar
        selectedTab={selectedTab}
        onChange={onTabChange}
        isDisabled={isSubmitting}
        classes={classes}
      />
      <Formik
        ref={formikRef}
        validateOnBlur={validateOnBlur}
        validationSchema={validationSchema}
        initialValues={getInitialValues(minDueDate)}
        onSubmit={onSubmit}
        render={render}
      />
      <CustomMessageContent
        inviteEmail={inviteEmail}
        isSubmitting={isSubmitting}
        classes={classes}
      />
    </DialogContentText>
  );

  const inviteDialogActions = (
    <>
      <Grid item>
        <NewOrderNextButtonClear
          onClick={inviteEmail ? resetDialog : onClose}
          disabled={isSubmitting && !inviteEmail}
        >
          Cancel
        </NewOrderNextButtonClear>
      </Grid>
      <Grid item>
        <NewOrderNextButton
          onClick={onInternalSubmitForm}
          disabled={isSubmitting && !inviteEmail}
        >
          {inviteEmail ? 'Yes, Invite' : 'Assign'}
        </NewOrderNextButton>
      </Grid>
    </>
  );

  const inviteSentDialogContent = (
    <DialogContentText component="div">
      <Grid
        container
        direction="column"
        alignContent="center"
        alignItems="center"
      >
        <Grid item>
          <CheckCircleOutlineIcon
            classes={{ root: dialogAssignTaskIconSuccess }}
          />
        </Grid>
        <Grid item classes={{ root: dialogAssignTaskContentSuccessContainer }}>
          <Typography classes={{ root: dialogAssignTaskLabelSuccessMessage }}>
            {`An invitation to enroll in the eShip system has been sent to ${inviteEmail}.
            Once they enroll, they will receive a task assignment notification.`}
          </Typography>
        </Grid>
      </Grid>
    </DialogContentText>
  );

  const inviteSentDialogActions = (
    <>
      <Grid item>
        <NewOrderNextButton onClick={onInternalOnClose}>
          Done
        </NewOrderNextButton>
      </Grid>
    </>
  );

  return (
    <Dialog
      aria-labelledby="simple-dialog-title"
      open={open}
      onClose={onInternalOnClose}
      classes={{ paper: dialogAssignTaskContentRoot }}
    >
      <Grid
        classes={{
          root: getRootClass(
            selectedTab,
            inviteEmail,
            inviteSent,
            isSubmitting,
            classes,
          ),
        }}
      >
        <ScrollWindow>
          <>
            <DialogContent
              classes={{ root: dialogAssignTaskContentOuterContainer }}
            >
              {inviteSent ? inviteSentDialogContent : inviteDialogContent}
            </DialogContent>
            <DialogActions
              classes={{ root: dialogAssignTaskContentActionsContainer }}
            >
              <Grid container spacing={4} justify="center">
                {inviteSent ? inviteSentDialogActions : inviteDialogActions}
              </Grid>
            </DialogActions>
          </>
        </ScrollWindow>
      </Grid>
    </Dialog>
  );
}

AssignmentModal.propTypes = {
  label: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  assignTask: PropTypes.func.isRequired,
  onInviteSent: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    dialogAssignTaskContentRoot: PropTypes.string.isRequired,
    dialogAssignTaskContentSuccessContainer: PropTypes.string.isRequired,
    dialogAssignTaskContentOuterContainer: PropTypes.string.isRequired,
    dialogAssignTaskContentInviteContainer: PropTypes.string.isRequired,
    dialogAssignTaskLabelSuccessMessage: PropTypes.string.isRequired,
    dialogAssignTaskContentHeaderContainer: PropTypes.string.isRequired,
    dialogAssignTaskContentActionsContainer: PropTypes.string.isRequired,
    dialogAssignTaskLabelHeader: PropTypes.string.isRequired,
    dialogAssignTaskLabelAppbarHeader: PropTypes.string.isRequired,
    dialogAssignTaskIconSuccess: PropTypes.string.isRequired,
  }).isRequired,
};
