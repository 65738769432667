import React from 'react';
import { Formik, Form } from 'formik';
import { isEqual } from 'lodash';
import { inDevelopment } from '../clientConstants';
import { loadTimeZonesUserTypes } from './miscClient';

// eslint-disable-next-line import/prefer-default-export
export function useLogger([state, dispatch]) {
  const actionRef = React.useRef();

  const newDispatchRef = React.useRef((action) => {
    actionRef.current = action;
    dispatch(action);
  });

  React.useEffect(() => {
    const action = actionRef.current;

    if (action && inDevelopment()) {
      console.group('Dispatch');
      console.log('Action:', action);
      console.log('State:', state);
      console.groupEnd();
    }
  }, [state]);

  return [state, newDispatchRef.current];
}

export const withSubForm = (SubForm, validationSchema, subFormProps) => ({
  field, form, fieldProps,
}) => {
  const [initialValues] = React.useState(field.value);
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={form.onSubmit}
      render={(props) => (
        <SubForm
          {...props}
          setFieldValue={form.setFieldValue}
          name={field.name}
          {...subFormProps}
        />
      )}
    />
  );
};

const isEmpty = (obj) => Object.values(obj).length <= 0;

export const useValues = (name, props) => {
  const [savedValues, setSavedValues] = React.useState(props.values);

  React.useEffect(() => {
    if (!isEmpty(props.errors)) {
      props.setFieldError(name, 'SubFormError');
      return;
    }
    if (!isEqual(props.values, savedValues)) {
      props.setFieldValue(name, props.values);
      setSavedValues(props.values);
    }
  }, [name, props.values]);
};

export const useLoadTimeZonesUserTypes = (loggedUserType, fields, setIsError, setErrorContent) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [timeZones, setTimeZones] = React.useState([]);
  const [userTypes, setUserTypes] = React.useState([]);

  React.useEffect(() => {
    loadTimeZonesUserTypes(loggedUserType, fields).then((responses) => {
      setTimeZones(responses[0].data);

      if (responses[1]) {
        setUserTypes(responses[1]);
      }

      setIsLoading(false);
    }).catch((err) => {
      if (setIsError) setIsError(true);
      if (setErrorContent) setErrorContent(err);
      setIsLoading(false);
    });
  }, [loggedUserType]);

  return {
    isLoading,
    timeZones,
    userTypes,
  };
};
