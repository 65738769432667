import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import {
  Grid, Typography,
} from '@material-ui/core';

import NewOrderCustomTextField from '../../common/NewOrderCustomTextField';
import { FORM_FIELDS, FORM_LABELS } from '../itemDetailsConstants';
import { isFieldEditable } from '../itemDetailsUtil';
import { useItemProductDetailsFormStyles } from '../itemDetailsStyles';

export default function Description({ editableFields, isNewChemical }) {
  const classes = useItemProductDetailsFormStyles();

  return (
    <Grid item container direction="column" spacing={1}>
      <Grid item>
        <Typography color="primary" className={classes.formFieldHeader}>{FORM_LABELS.description}</Typography>
      </Grid>
      <Grid item container>
        <Grid item xs={12}>
          <Field
            name={FORM_FIELDS.description.key}
            labe={FORM_FIELDS.description.label}
            multiline
            rowsMax="6"
            rows="4"
            component={NewOrderCustomTextField}
            type="text"
            style={{ width: '100%' }}
            disabled={!isNewChemical && isFieldEditable(FORM_FIELDS.description.key, editableFields)}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

Description.defaultProps = {
  isNewChemical: false,
};

Description.propTypes = {
  editableFields: PropTypes.arrayOf(PropTypes.string).isRequired,
  isNewChemical: PropTypes.bool,
};
