import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Dialog,
  IconButton,
  Tabs,
  Tab,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import NewOrderTabPanel from '../../common/NewOrderTabPanel';
import SelectableAddressBook from '../../SelectableAddressBook';
import UploadAddresses from './UploadAddresses';

const SELECT_FROM_ADDRESS_BOOK = 1;
const UPLOAD_ADDRESSES = 2;

const TABS = [
  {
    name: 'select-addresses',
    label: 'select from address book',
    value: SELECT_FROM_ADDRESS_BOOK,
  },
  {
    name: 'bulk-upload-addresses',
    label: 'upload a file',
    value: UPLOAD_ADDRESSES,
  },
];

function NewOrderMultipleAddressDialog({
  classes,
  open,
  shippingType,
  selectedAddress,
  setOpen,
  setSelectedAddress,
  toggleOpen,
}) {
  const [selectedTab, setSelectedTab] = useState(SELECT_FROM_ADDRESS_BOOK);

  return (
    <Dialog
      open={open}
      onClose={toggleOpen}
      color="primary"
      classes={{
        paper: classes.dialogPaper,
        root: classes.root,
      }}
    >
      <Grid
        container
        className={classes.dialogInnerContainer}
        spacing={1}
      >
        <IconButton
          className={classes.closeIcon}
          onClick={() => setOpen(false)}
        >
          <CloseIcon color="primary" />
        </IconButton>
        <Tabs
          classess={{ indicator: classes.appBarIndicator }}
          textColor="primary"
          value={selectedTab}
          onChange={(e, value) => setSelectedTab(value)}
        >
          {TABS.map((tab) => (
            <Tab
              key={`multiple-addresses-dialg-tab-${tab.name}`}
              classes={{
                root: classes.appBarTab,
                disabled: classes.disabled,
                selected: classes.selected,
              }}
              label={tab.label}
              value={tab.value}
              wrapped
            />
          ))}
        </Tabs>
        <NewOrderTabPanel
          classes={classes}
          index={SELECT_FROM_ADDRESS_BOOK}
          value={selectedTab}
        >
          <SelectableAddressBook
            selectedAddress={selectedAddress}
            setSelectedAddress={setSelectedAddress}
            closeModal={toggleOpen}
            originAddressId={origin?.address?.addressId}
            shippingType={shippingType}
          />
        </NewOrderTabPanel>
        <NewOrderTabPanel
          classes={classes}
          index={UPLOAD_ADDRESSES}
          value={selectedTab}
        >
          <UploadAddresses
            classes={classes}
            toggleOpen={toggleOpen}
            setSelectedAddress={setSelectedAddress}
          />
        </NewOrderTabPanel>
      </Grid>
    </Dialog>
  );
}

NewOrderMultipleAddressDialog.propTypes = {
  classes: PropTypes.shape(PropTypes.object).isRequired,
  open: PropTypes.bool.isRequired,
  shippingType: PropTypes.string.isRequired,
  selectedAddress: PropTypes.arrayOf(PropTypes.object).isRequired,
  origin: PropTypes.shape({
    address: PropTypes.shape(PropTypes.object),
    updateContacts: PropTypes.bool,
  }).isRequired,
  setOpen: PropTypes.func.isRequired,
  setSelectedAddress: PropTypes.func.isRequired,
  toggleOpen: PropTypes.func.isRequired,
};

export default memo(NewOrderMultipleAddressDialog);
