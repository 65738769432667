import React from 'react';
import {
  Switch,
  Route,
  Redirect,
  useRouteMatch,
} from 'react-router-dom';
import { css } from 'emotion';
import LoginForm from './components/LoginForm';
import SignUpForm from './components/SignUpForm';
import EsgVideo from './assets/login-video-v.mp4';
import ForgotPasswordPage from './pages/ForgotPassword';
import ForgotPasswordEmailConfirmationPage from './pages/ForgotPasswordEmailConfirmation';
import ResetPasswordConfirmationPage from './pages/ResetPasswordConfirmation';
import AccountSetupConfirmationPage from './pages/AccountSetupConfirmation';
import AccountConfirmationErrorPage from './pages/AccountConfirmationError';
import ResetPasswordErrorPage from './pages/ResetPasswordError';
import CreateNewPasswordPage from './pages/CreateNewPassword';
import AdminLogin from './pages/AdminLogin';

// TODO: Do we need to have separate admin and non-admin login components?
export default function UnauthenticatedApp() {
  return (
    <Switch>
      <Route path="/login" component={ScreensWithVisualization} />
      <Route exact path="/admin" component={AdminLogin} />
      <Route exact path="/reset_password" component={CreateNewPasswordPage} />
      <Route exact path="/reset-password-confirmation" component={ResetPasswordConfirmationPage} />
      <Route exact path="/account_confirmation" component={AccountSetupConfirmationPage} />
      <Route exact path="/account-confirmation-error" component={AccountConfirmationErrorPage} />
      <Route exact path="/reset-password-error" component={ResetPasswordErrorPage} />
      <Route render={() => <Redirect to="/login" />} />
    </Switch>
  );
}

function ScreensWithVisualization() {
  const { path } = useRouteMatch();

  const classes = {
    video: css`
    position: fixed;
    min-width: 100%;
    min-height: 100%;
 &:before {
content:"";
  position: absolute;
  top:0;
  right:0;
  left:0;
  bottom:0;
  z-index:1;
      background-color: rgba(0, 0, 0, 0.6);
  
   
}
    `,
    loginFormContainer: css`
      padding-top: 320px;
      padding-left: 100px;
      width: 620px;
     
    `,

  };
  return (
    <div className={classes.home}>
      <div className={classes.video}>
        <video autoPlay loop muted>
          <source src={EsgVideo} type="video/mp4" />
        </video>
      </div>
      <div className={classes.loginFormContainer}>
        <Switch>
          <Route exact path={path} component={LoginForm} />
          <Route exact path={`${path}/forgot-password`} component={ForgotPasswordPage} />
          <Route exact path={`${path}/forgot-password-confirmation`} component={ForgotPasswordEmailConfirmationPage} />
          <Route exact path={`${path}/register`} component={SignUpForm} />
          <Route render={() => <Redirect to="/login" />} />
        </Switch>
      </div>
    </div>
  );
}
