import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import PanelWarning from '../../../../common/PanelWarning';
import {
  FORM_FIELDS_LITHIUM_BATTERY,
  LITHIUM_BATTERY_CONTAINED_KEY,
} from '../../../itemDetailsConstants';
import { getLithiumBatterySumWeight } from '../../../itemDetailsUtil';

const BatteryContainedWarning = ({ values }) => <PanelWarning message={`The shipment contains lithium batteries weighing ${getLithiumBatterySumWeight(values)}${get(values, `${LITHIUM_BATTERY_CONTAINED_KEY}.${FORM_FIELDS_LITHIUM_BATTERY.weightUnit.key}`, '')}`} />;

BatteryContainedWarning.propTypes = {
  values: PropTypes.shape(PropTypes.object).isRequired,
};

export default BatteryContainedWarning;
