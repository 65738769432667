import React, {
  memo, useContext, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import has from 'lodash/has';
import {
  Button,
  Card,
  CircularProgress,
  Dialog, DialogContent, DialogTitle, Grid, Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Form, Formik } from 'formik';
import * as colors from '../styles/colors';
import { updateUser, useUserDispatch } from '../context/userContext';
import { USER_PROFILE_FIELDS, USER_TYPE_MAPPING } from '../clientConstants';
import ProfileTrainingTable from './common/ProfileTrainingTable';
import ProfileManagementFormFields from './common/ProfileManagementFormFields';
import ScrollWindow from './common/ScrollWindow';
import ProfileManagementReadOnlyForm from './ProfileManagementReadOnlyForm';
import { isAdmin } from '../utils/helpers';
import AccountContext from '../context/accountContext';

const COMMON_BUTTON_STYLES = {
  minWidth: 114,
  height: 36,
  fontWeight: 'bold',
  borderRadius: 8,
};

const useStyles = makeStyles(() => ({
  dialogTitle: {
    paddingLeft: 30,
    marginBottom: 15,
  },
  dialogPaper: {
    background: colors.newOrderFormBackground,
    width: 650,
    padding: '20px 0',
  },
  dialogTitleText: {
    fontSize: 25,
    color: colors.white,
    fontWeight: 500,
  },
  dialogContent: {
    height: '100vh',
    maxHeight: 850,
    overflowY: 'hidden',
    padding: 0,
  },
  dialogContentCard: {
    backgroundColor: colors.darkBlueBackground,
    padding: '20px 15px 0',
  },
  innerDialogContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '0 30px',
  },
  profileManagementFormOuterContainer: {
    backgroundColor: 'transparent !important',
    padding: '0 !important',
  },
  profileManagementFormInnerContainer: {
    padding: '0 !important',
  },
  header: {
    fontSize: 20,
    fontWeight: 500,
    color: colors.white,
    letterSpacing: '0.25px',
    marginBottom: '1rem',
  },
  buttonsContainer: {
    width: 270,
    margin: '30px 0px',
  },
  primaryButton: {
    ...COMMON_BUTTON_STYLES,
    backgroundColor: colors.buttonBackgroundBlue,
    color: colors.white,
  },
  secondaryButton: {
    ...COMMON_BUTTON_STYLES,
    border: `1px solid ${colors.buttonBackgroundBlue}`,
    color: colors.secondaryButton,
  },
  progress: {
    marginTop: '15px',
  },
}));

const EditUserModal = ({
  open, loggedUser, user, training, timeZones, userTypes, onUserUpdate, onClose,
}) => {
  const classes = useStyles();
  const userDispatch = useUserDispatch();
  const account = useContext(AccountContext);
  const [isUpdating, setIsUpdating] = useState(false);
  const [userTraining, setUserTrainings] = useState(null);

  useEffect(() => {
    if (has(user, 'training')) setUserTrainings(user.training);
  }, [user]);

  const handleClose = () => {
    onClose(-1, null);
  };

  const handleDateChange = (newTrainings) => {
    setUserTrainings(newTrainings);
  };

  const handleSubmit = (values) => {
    setIsUpdating(true);

    updateUser({
      dispatch: userDispatch,
      currentUserId: loggedUser.userId,
      updatedUserData: {
        userId: user.userId,
        ...values,
        training: userTraining,
      },
    })
      .then(() => {
        setIsUpdating(false);

        onUserUpdate({ ...user, ...values, training: userTraining });

        handleClose();
      }).catch(() => {
        setIsUpdating(false);
      });
  };

  return (
    <Dialog open={open} classes={{ paper: classes.dialogPaper }} onClose={handleClose}>
      <DialogTitle className={classes.dialogTitle}>
        <Typography className={classes.dialogTitleText}>
          Edit User
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <ScrollWindow>
          <div
            direction="column"
            className={classes.innerDialogContentContainer}
          >
            <Formik
              validationSchema={Yup.object().shape({
                name: Yup.string().required('Required'),
                department: Yup.string(),
                userType: Yup.string().required('Required'),
                timeZone: Yup.object().required('Required'),
              })}
              initialValues={user ? {
                name: user.name ? user.name : '',
                department: user.department ? user.department : '',
                userType: user.userType ? user.userType : '',
                timeZone: user.timeZone ? user.timeZone : '',
                groupType: user.groupType ? user.groupType : '',
                isDefaultECO: user.isDefaultECO ? user.isDefaultECO : false,
                isDefaultEHS: user.isDefaultEHS ? user.isDefaultEHS : false,
              } : null}
              onSubmit={handleSubmit}
            >
              {({ values, isSubmitting }) => (
                <>
                  <Form className={classes.dialogContentCard}>
                    <Typography className={classes.header}>Profile</Typography>
                    {isAdmin(loggedUser.userType) ? (
                      <ProfileManagementFormFields
                        loggedUserType={loggedUser.userType}
                        fields={USER_PROFILE_FIELDS}
                        values={values}
                        timeZones={timeZones}
                        userTypes={userTypes}
                        isLoading={false}
                        isSubmitting={isSubmitting}
                        isUserECOGroup={account.info?.eco?.isUserGroup || false}
                        isUserEHSGroup={account.info?.ehs?.isUserGroup || false}
                      />
                    ) : (
                      <ProfileManagementReadOnlyForm
                        userState={{
                          ...user,
                          timeZone: user.timeZone
                            ? user.timeZone.text
                            : '',
                          userType: user.userType ? USER_TYPE_MAPPING[user.userType] : '',
                        }}
                        fields={USER_PROFILE_FIELDS}
                        numFieldsPerColumn={2}
                        isEditable={false}
                      />
                    )}
                  </Form>
                  <Card className={classes.dialogContentCard}>
                    <ProfileTrainingTable
                      user={user}
                      training={training}
                      isReadOnly={isUpdating}
                      onDateChange={handleDateChange}
                    />
                  </Card>
                  <Grid container justify="center">
                    {isUpdating ? <CircularProgress color="secondary" className={classes.progress} /> : (
                      <Grid item container justify="space-between" className={classes.buttonsContainer}>
                        <Button
                          className={classes.secondaryButton}
                          onClick={handleClose}
                        >
                          Cancel
                        </Button>
                        <Button
                          className={classes.primaryButton}
                          onClick={() => handleSubmit(values)}
                        >
                          Update
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </>
              )}
            </Formik>
          </div>
        </ScrollWindow>
      </DialogContent>
    </Dialog>
  );
};

EditUserModal.defaultProps = {
  open: false,
  loggedUser: null,
  user: null,
  training: null,
  timeZones: [],
  userTypes: [],
};

EditUserModal.propTypes = {
  open: PropTypes.bool,
  loggedUser: PropTypes.shape(PropTypes.object),
  user: PropTypes.shape(PropTypes.object),
  training: PropTypes.shape(PropTypes.object),
  timeZones: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
  })),
  userTypes: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string,
    description: PropTypes.string,
  })),
  onUserUpdate: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default memo(EditUserModal);
