import { withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import * as colors from '../../styles/colors';

export default withStyles({
  root: {
    width: 200,
    height: 36,
    background: colors.buttonBackgroundBlue,
    fontWeight: 'bold',
    color: colors.white,
    borderRadius: 8,
  }
})(Button);
