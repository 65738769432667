import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import { base64ToURL } from '../../utils/documentsUtil';

const styles = {
  previewContainer: {
    width: '80%',
    height: '80%',
  },
};

const useStyles = makeStyles(styles);

function ShippingDocumentPreview(props) {
  const { document } = props;
  const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
  const docURL = base64ToURL(document);

  const src = `${docURL}${isChrome ? '#toolbar=0' : ''}`;

  return (
    <embed src={src} width="100%" height="100%" />
  );
}

ShippingDocumentPreview.propTypes = {
  document: PropTypes.objectOf(PropTypes.any).isRequired,
};

function ShippingDocumentPreviewURL(props) {
  const { document } = props;
  const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

  const src = `${document}${isChrome ? '#toolbar=0' : ''}`;

  return <embed src={src} width="100%" height="100%" />;
}

ShippingDocumentPreviewURL.propTypes = {
  document: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default function DocumentPreview(props) {
  const {
    open, onClose, doc, url,
  } = props;

  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      fullScreen
      classes={{ paper: classes.previewContainer }}
    >
      {doc && !url && <ShippingDocumentPreview document={doc} />}
      {doc && url && <ShippingDocumentPreviewURL document={doc} />}
    </Dialog>
  );
}

DocumentPreview.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  doc: PropTypes.string,
  url: PropTypes.bool,
};

DocumentPreview.defaultProps = {
  open: false,
  doc: null,
  url: false,
};
