/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Route, Link } from 'react-router-dom';
import { css } from 'emotion';
import {
  Grid, Typography, Button, IconButton, Tooltip,
} from '@material-ui/core/';
import AlarmOutlinedIcon from '@material-ui/icons/AlarmOutlined';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import OutlinedTruckIcon from '@material-ui/icons/LocalShippingOutlined';
import AddToPhotosOutlinedIcon from '@material-ui/icons/AddToPhotosOutlined';
import * as colors from '../styles/colors';
import ConfirmationModal from './common/ConfirmationModal';
import RedirectButton from './common/RedirectButton';
import ErrorDialog from './common/NewOrderErrorDialog';
import UserAvatar from './common/UserAvatar';
import {
  determineStatusClass, generateShipmentCardMessage, formatDateLong, formatMonetaryAmount,
} from '../utils/helpers';
import statusHelp from '../utils/status';
import { SHIPMENT_TRACKING_TAB, IOT_TRACKING_TAB } from '../clientConstants';
import iotIcon from './images/rss.svg';
import * as shipmentClient from '../utils/shipmentClient';

const useStyles = makeStyles((theme) => ({
  centeredContainer: {
    alignItems: 'center',
  },
  tile: {
    backgroundColor: colors.shipmentCardBackground,
    padding: '8px',
    borderRadius: '10px',
    minHeight: '96px',
    marginBottom: '10px',
    width: '100%',
    position: 'relative',
  },
  shipmentHeading: {
    color: '#B5B9CC',
    fontSize: '12px',
    fontWeight: 500,
    marginTop: '10px',
    marginBottom: '7px',
  },
  shipmentIdHeader: {
    color: 'rgba(255,255,255,0.6)',
    letterSpacing: 0.4,
  },
  shipmentInfoHeader: {
    letterSpacing: 2,
  },
  link: {
    textDecoration: 'none',
    '&:visited': {
      textDecoration: 'none',
    },
  },
  trackShipment: {
    color: colors.secondaryButton,
    fontSize: '14px',
    fontWeight: '500',
    textTransform: 'none',
  },
  trackingButton: {
    padding: '0px 0px 0px 0px',
    color: '#94CCFB',
  },
  statusMessage: {
    paddingLeft: 8,
  },
  shipmentInformation: {
    color: 'white',
    fontSize: '14px',
  },
  allCapsWide: {
    textTransform: 'uppercase',
    letterSpacing: '1.2',
  },
  shipmentButton: {
    width: '137px',
    borderRadius: '5px',
  },
  boldHeading: {
    // fontWeight: 600,
  },
  onTimeHeading: {
    color: '#A7F4B4',
  },
  delayedHeading: {
    color: '#FED599',
  },
  incompleteHeading: {
    color: '#FF9FB4',
  },
  deliveredHeading: {
    color: '#B5B9CC',
  },
  buttonDiv: {
    alignItems: 'center',
    display: 'inherit',
  },
  onTimeBorder: {
    borderLeft: '3px solid #A7F4B4',
  },
  delayedBorder: {
    borderLeft: '3px solid #FED599',
  },
  incompleteBorder: {
    borderLeft: '3px solid #FF9FB4',
  },
  shipmentCardColumn: {
    padding: '0 2px',
    height: '100%',
  },
  shipmentCardButton: {
    alignItems: 'center',
    textAlign: 'center',
    height: '100%',
  },
  duplicateIconContainer: {
    alignSelf: 'flex-start',
  },
  duplicateIcon: {
    // position: 'relative',
    // top: '8px',
    // right: '8px',
    transform: 'rotate(180deg)',
    padding: '0',
  },
  tooltip: {
    background: 'transparent',
    fontSize: 12,
  },
}));

// Util functions
function getMainTrackingNumber(shipment) {
  if (statusHelp.isCancelled(shipment.status)) return null;
  if (shipment.masterTrackingNumber) return shipment.masterTrackingNumber;
  if (shipment.trackingNumbers && shipment.trackingNumbers.length) {
    return shipment.trackingNumbers[0];
  }
  return null;
}

function getButtonTextByStatus(status) {
  return (status.toUpperCase() === 'CREATED') ? 'EDIT' : 'VIEW';
}

function trimShipmentId(shipmentId) {
  return shipmentId.split('-').pop();
}

function ViewShipmentButton(props) {
  const { label, shipmentId } = props;

  const hovered = css`
  background:${colors.mediumBlueBackground}
  `;

  const buttonStyle = css({
    fontWeight: 'bold',
    margin: 'auto',
    maxWidth: ' 98px',
    color: `${colors.secondaryButton}`,
    borderRadius: '8px',
    backgroundColor: `${colors.shipmentCardBackground}`,
    border: `1px solid ${colors.buttonBackgroundBlue}`,
    boxShadow: 'none',
    ':hover': hovered,
    width: '100%',
  });
  return (
    <Route render={({ history }) => (
      <Button
        className={buttonStyle}
        variant="contained"
        onClick={() => {
          history.push(`/order/${shipmentId}`, { shipment: shipmentId });
        }}
      >
        {label}
      </Button>
    )}
    />
  );
}

ViewShipmentButton.propTypes = {
  label: PropTypes.string.isRequired,
  shipmentId: PropTypes.string.isRequired,
};

const StyledWarningIcon = withStyles({
  root: { color: colors.warningYellow },
})(ReportProblemOutlinedIcon);
export function ShipmentStatusIcon(props) {
  const { status, colorClass } = props;
  switch (status.toUpperCase()) {
    case 'SCHEDULED FOR PICKUP':
      return <AlarmOutlinedIcon className={colorClass} />;
    case 'IN-TRANSIT':
      return <AlarmOutlinedIcon className={colorClass} />;
    case 'DELAYED':
      return <ErrorOutlineOutlinedIcon className={colorClass} />;
    case 'CREATED':
      return <ErrorOutlineOutlinedIcon className={colorClass} />;
    case 'CANCELLED':
      return <ErrorOutlineOutlinedIcon className={colorClass} />;
    case 'COMPLETED':
      return <CheckCircleOutlineOutlinedIcon className={colorClass} />;
    case 'DELIVERED':
      return <CheckCircleOutlineOutlinedIcon className={colorClass} />;
    case 'COMPLIANCE FAILURE':
      return <StyledWarningIcon className={colorClass} />;

    default:
      return <InfoOutlinedIcon />;
  }
}

ShipmentStatusIcon.propTypes = {
  status: PropTypes.string.isRequired,
  colorClass: PropTypes.string,
};
ShipmentStatusIcon.defaultProps = {
  colorClass: '',
};

function displayTracking(status) {
  return !(/compliance failure/i.test(status));
}

export default function ShipmentCard(props) {
  const {
    shipment, openTrackingModal, lastCardRef, buttonText, showDuplicate, account,
  } = props;
  const classes = useStyles();
  const {
    collaborators, updated,
    recipient, iconType,
    carrier, amount, currencyCode,
    status, shipmentId, orderId, iot, iotTrackingNumber,
  } = shipment;

  const [duplicating, setDuplicating] = React.useState(null);
  const [error, setError] = React.useState(null);
  const confirmDuplicateModalOpen = duplicating === 'confirm';
  const openNewShipmentModalOpen = duplicating && (duplicating !== 'confirm');

  // Adds dynamic css for class heading
  const statusColorClass = determineStatusClass(shipment);
  const statusHeadingClass = [
    classes.shipmentInformation,
    classes.boldHeading,
    classes[statusColorClass.statusClass],
  ].join(' ');
  const recipientHeadingClass = [
    classes.shipmentInformation,
    classes.boldHeading,
  ].join(' ');

  const mainTrackingNumber = getMainTrackingNumber(shipment);
  const viewButtonLabel = buttonText || getButtonTextByStatus(status);

  async function onDuplicateShipment(currentShipmentId) {
    const newOrder = await shipmentClient.duplicateShipment({ shipmentId: currentShipmentId })
      .catch(() => {
        setError(JSON.stringify({ error: 'Error duplicating shipment. Please try again' }));
      });
    try {
      const newShipmentId = newOrder.shipments[0].shipmentId;
      setDuplicating(newShipmentId);
    } catch (e) {
      setError(JSON.stringify({ error: 'Something went wrong. Please refresh the page.' }));
    }
  }

  function openNewShipment() {
    setDuplicating(null);
  }

  return (
    <Grid
      container
      className={classes.tile}
      justify="space-between"
      ref={lastCardRef || null}
      data-testid="shipmentcard"
    >
      <Grid item xs={account === 'worldemblem' ? 2 : 3} className={classes.shipmentCardColumn}>
        <Grid item className={classes.shipmentHeading}>
          <span className={`${classes.shipmentIdHeader}`}>{`${orderId}`}</span>
          <span className={`${classes.shipmentIdHeader}`}>
            {`-${trimShipmentId(shipmentId)}`}
          </span>
        </Grid>
        <Grid item className={classes.centeredContainer}>
          <Grid container spacing={1}>
            <Grid item xs={2}>
              <ShipmentStatusIcon
                status={status}
                colorClass={classes[statusColorClass.statusClass]}
              />
            </Grid>
            <Grid item xs={10} className={classes.statusMessage}>
              <Typography className={statusHeadingClass}>
                {`${generateShipmentCardMessage(shipment)}`}
              </Typography>
              {mainTrackingNumber && displayTracking(status) && (
                <Grid container item spacing={1}>
                  <Grid item>
                    <Typography className={classes.trackShipment}>
                      Track shipments
                    </Typography>
                  </Grid>
                  <Grid item>
                    <IconButton
                      onClick={(e) => {
                        openTrackingModal({
                          shipmentId,
                          trackingNumber: mainTrackingNumber,
                          anchorEl: e.currentTarget,
                          iotTrackingNumber: iot ? iotTrackingNumber : false,
                          openTab: SHIPMENT_TRACKING_TAB,
                        });
                      }}
                      classes={{ root: classes.trackingButton }}
                    >
                      <OutlinedTruckIcon style={{ fontSize: '1rem' }} />
                    </IconButton>
                  </Grid>
                  {iot && (
                    <Grid item>
                      <IconButton
                        onClick={(e) => {
                          openTrackingModal({
                            shipmentId,
                            trackingNumber: mainTrackingNumber,
                            anchorEl: e.currentTarget,
                            iotTrackingNumber: iot ? iotTrackingNumber : false,
                            openTab: IOT_TRACKING_TAB,
                          });
                        }}
                        classes={{ root: classes.trackingButton }}
                      >
                        <img
                          src={iotIcon}
                          alt="iot"
                          style={{ width: '1rem', height: '1rem' }}
                        />
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
              )}
              {collaborators && collaborators.length > 0 && (
                <Grid item container justify="flex-start" spacing={1}>
                  {collaborators.map(({ imageUrl, title, name }) => (
                    <Grid item key={imageUrl}>
                      <UserAvatar
                        alt={
                          name
                            ? name
                              .split(' ')
                              .map((str) => str[0])
                              .join('')
                            : ''
                        }
                        src={imageUrl}
                        title={title}
                        size="small"
                      />
                    </Grid>
                  ))}
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={5}>
        <Grid container style={{ height: '100%' }}>
          <Grid item xs={4} className={classes.shipmentCardColumn}>
            <Grid item>
              <Typography
                className={`${classes.shipmentHeading} ${classes.shipmentInfoHeader}`}
              >
                RECIPIENT
              </Typography>
            </Grid>
            <Grid item>
              {(Array.isArray(recipient) && recipient.length < 11) ? (
                <Tooltip
                  placement="bottom"
                  // eslint-disable-next-line react/no-array-index-key
                  title={<div style={{ background: 'rgba(100,100,100,0.9)', padding: '2px', borderRadius: '3px' }}>{recipient.map((m, i) => <Typography style={{ fontSize: '12px', paddingBottom: '2px' }} key={i}>{m}</Typography>)}</div>}
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Typography className={recipientHeadingClass}>
                    {`${recipient.length} recipient(s)` }
                  </Typography>
                </Tooltip>
              ) : ((Array.isArray(recipient) && recipient.length > 10)
                ? (
                  <Typography className={recipientHeadingClass}>
                    {`${recipient.length} recipient(s)` }
                  </Typography>
                ) : (
                  <Typography className={recipientHeadingClass}>
                    {recipient}
                  </Typography>
                ))}
            </Grid>
          </Grid>
          <Grid item xs={5} className={classes.shipmentCardColumn}>
            <Grid item>
              <Typography
                className={`${classes.shipmentHeading} ${classes.shipmentInfoHeader}`}
              >
                CARRIER
              </Typography>
            </Grid>
            <Grid container item>
              {iconType && (
                <Grid item xs={2}>
                  <img
                    alt="transportation mode"
                    src={require(`./images/modeOfTransitIcons/${iconType}.svg`)}
                  />
                </Grid>
              )}
              <Grid item xs={8}>
                <Typography className={classes.shipmentInformation}>
                  {carrier}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3} className={classes.shipmentCardColumn}>
            <Grid item>
              <Typography
                className={`${classes.shipmentHeading} ${classes.shipmentInfoHeader}`}
              >
                AMOUNT
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={recipientHeadingClass}>
                {formatMonetaryAmount(currencyCode, amount) || 'N/A'}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {account === 'worldemblem'
      && (
      <Grid item xs={2}>
        <Grid container style={{ height: '100%' }}>
          {shipment?.binName
          && (
          <Grid item xs={7} className={classes.shipmentCardColumn}>
            <Grid item>
              <Typography
                className={`${classes.shipmentHeading} ${classes.shipmentInfoHeader}`}
              >
                BIN NAME
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                className={`${classes.shipmentInformation} ${classes.allCapsWide}`}
              >
                {shipment?.binName}
              </Typography>
            </Grid>
          </Grid>
)}
        </Grid>

      </Grid>
      )}
      <Grid item xs={account === 'worldemblem' ? 3 : 4}>
        <Grid container style={{ height: '100%' }}>
          <Grid item xs={7} className={classes.shipmentCardColumn}>
            <Grid item>
              <Typography
                className={`${classes.shipmentHeading} ${classes.shipmentInfoHeader}`}
              >
                UPDATED
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                className={`${classes.shipmentInformation} ${classes.allCapsWide}`}
              >
                {updated && formatDateLong(updated)}
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            item
            xs={5}
            className={`${classes.buttonDiv} ${classes.shipmentCardButton}`}
            spacing={1}
          >
            <Grid item xs={10}>
              <ViewShipmentButton
                label={viewButtonLabel}
                shipmentId={shipmentId}
              />
            </Grid>
            {showDuplicate && (
              <Grid item xs={2} className={classes.duplicateIconContainer}>
                <Tooltip
                  title="Duplicate shipment"
                  classes={{ tooltip: classes.tooltip }}
                  placement="left"
                  enterDelay={800}
                >
                  <IconButton
                    className={classes.duplicateIcon}
                    onClick={() => setDuplicating('confirm')}
                  >
                    <AddToPhotosOutlinedIcon color="primary" fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>

      <ConfirmationModal
        open={confirmDuplicateModalOpen}
        onProceed={() => onDuplicateShipment(shipmentId)}
        onCancel={() => setDuplicating(null)}
        proceedLabel="Yes, duplicate"
        message={(
          <span>
            Are you sure you would like to duplicate shipment
            {' '}
            {`${shipmentId}?`}
          </span>
        )}
      />
      <ConfirmationModal
        open={!!openNewShipmentModalOpen}
        onProceed={() => openNewShipment(duplicating)}
        onCancel={() => setDuplicating(null)}
        proceedLabel={`Open ${duplicating || ''}`}
        cancelLabel="No, close"
        message={(
          <span>
            Shipment
            {' '}
            {duplicating}
            {' '}
            created from
            {' '}
            {shipmentId}
          </span>
        )}
        proceedButtonComponent={RedirectButton}
        proceedButtonProps={{
          url: `/order/${duplicating}`,
          label: `Open ${duplicating || ''}`,
        }}
      />
      <ErrorDialog
        open={!!error}
        onClose={() => setError(null)}
        errorContent={error}
      />
    </Grid>
  );
}

ShipmentCard.propTypes = {
  shipment: PropTypes.shape({
    status: PropTypes.string,
    shipmentId: PropTypes.string,
    recipient: PropTypes.string,
    carrier: PropTypes.string,
    updated: PropTypes.string,
    assignedTo: PropTypes.string,
    taskType: PropTypes.string,
    expectedDeliveryDateTime: PropTypes.string,
    actualDeliveryDateTime: PropTypes.string,
    actualShipDateTime: PropTypes.string,
    expectedPickupDateTime: PropTypes.string,
    iot: PropTypes.bool,
    iotTrackingNumber: PropTypes.string,
    orderId: PropTypes.string,
    iconType: PropTypes.string,
    currencyCode: PropTypes.string,
    amount: PropTypes.string,
    collaborators: PropTypes.arrayOf(PropTypes.shape({
      imageUrl: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })),
    isPendingTask: PropTypes.bool,
  }).isRequired,
  openTrackingModal: PropTypes.func.isRequired,
  lastCardRef: PropTypes.objectOf(PropTypes.any),
  buttonText: PropTypes.string,
  showDuplicate: PropTypes.bool,
};

ShipmentCard.defaultProps = {
  lastCardRef: null,
  buttonText: '',
  showDuplicate: true,
};
