import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import { Field } from 'formik';
import {
  Grid, InputAdornment, MenuItem, IconButton, Tooltip,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import LookupIcon from '@material-ui/icons/Launch';
import { DebounceInput } from 'react-debounce-input';
import { NEW_ORDER_INPUT_LABEL_PROPS, NEW_ORDER_INPUT_MULTILINE_PROPS } from '../../../../../styles/style';
import { CustomSearchWithDropdown, CustomTextArea } from '../../../../common/InputComponents';
import CustomSelectTextField from '../../../../common/CustomSelectTextField';
import NewOrderCustomTextField from '../../../../common/NewOrderCustomTextField';
import PanelWarning from '../../../../common/PanelWarning';
import {
  blockWeightAndDimensionInputs, showUserRequiredTrainingWarning,
} from '../../../itemDetailsUtil';
import { SEARCH_INPUT_MIN_LENGTH } from '../../../itemDetailsConstants';
import { loadTrainings } from '../../../../../utils/accountClient';
import * as colors from '../../../../../styles/colors';

function HazmatBiological({
  formikProps,
  fields,
  hazmatClassesTypeList,
  filteredItemNameOptions,
  classType,
  training,
  hasInternational,
  isOtherClassType,
  onSelect,
  onSearch,
}) {
  const inputClasses = NEW_ORDER_INPUT_MULTILINE_PROPS();
  const inputLabelClasses = NEW_ORDER_INPUT_LABEL_PROPS();
  const [accountTraining, setAccountTraining] = useState([]);

  useEffect(() => {
    if (isEmpty(accountTraining)) {
      loadTrainings().then((data) => {
        setAccountTraining(map(data, (value, key) => ({
          key,
          value: value.description,
        })));
      }).catch(() => {
        console.log('There is a problem loading trainings.');
      });
    }
  }, []);

  const resetHazmatFields = () => {
    formikProps.setFieldValue('itemName', '');
    formikProps.setFieldValue('iataDetails', {
      class: '',
      maxQuantityCargo: '',
      maxQuantityPR: '',
      maxQuantityPassenger: '',
      packagingInstructions: '',
      properShippingName: '',
      unNo: '',
    });
    formikProps.setFieldValue('shipClass', '');
    formikProps.setFieldValue('harmonizedCode', '');
    formikProps.setFieldValue('eccn', '');
    formikProps.setFieldValue('requiredLicenses', '');
    formikProps.setFieldValue('aphisImportTransPermit', false);
    formikProps.setFieldValue('cdcSelectAgent', false);
    formikProps.setFieldValue('doaSelectAgent', false);
    formikProps.setFieldValue('ppqSelectAgent', false);
    formikProps.setFieldValue('usdaImportTransPermit', false);
  };

  return (
    <>
      <Grid item container spacing={1}>
        <Grid item xs={4}>
          <Field
            name={fields.classType.key}
            label={fields.classType.label}
            component={CustomSelectTextField}
            customOnChange={() => {
              resetHazmatFields();
            }}
            type="text"
            style={{ width: '100%' }}
          >
            {hazmatClassesTypeList.map((option) => (
              <MenuItem key={option?.itemCategory} value={option?.itemCategory}>
                {option?.itemCategory}
              </MenuItem>
            ))}
          </Field>
        </Grid>
        <Grid item xs={4}>
          {isOtherClassType ? (
            <Field
              name={fields.classTypeName.key}
              label={fields.classTypeName.label}
              component={NewOrderCustomTextField}
              type="text"
              style={{ width: '100%' }}
            />
          )
            : (
              <DebounceInput
                name={fields.classTypeName.key}
                label={fields.classTypeName.label}
                value={formikProps?.values[fields.classTypeName.key]}
                minLength={SEARCH_INPUT_MIN_LENGTH}
                debounceTimeout={500}
                form={formikProps}
                field={{ name: fields.classTypeName.key }}
                dropdownList={filteredItemNameOptions}
                inputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                    >
                      <SearchIcon style={{ color: !classType ? colors.textLightGrey : colors.white }} color="primary" />
                    </InputAdornment>
                  ),
                  inputProps: { autocomplete: 'off' },
                }}
                element={CustomSearchWithDropdown}
                disabled={!classType}
                onSelect={onSelect}
                onChange={onSearch}
              />
            )}
        </Grid>
        <Grid item xs={4}>
          <Field
            name={fields.shipClass.key}
            label={fields.shipClass.label}
            component={CustomSelectTextField}
            type="text"
            style={{ width: '100%' }}
            disabled={!isOtherClassType}
          >
            {accountTraining.map((option) => (
              <MenuItem key={option?.key} value={option?.value}>
                {option?.value}
              </MenuItem>
            ))}
          </Field>
        </Grid>
      </Grid>
      <Grid item container spacing={1}>
        {!hasInternational && (
        <Grid item xs={4}>
          <Field
            name={fields.harmonizedCode.key}
            label={fields.harmonizedCode.label}
            component={NewOrderCustomTextField}
            type="text"
            style={{ width: '100%' }}
            disabled={!formikProps.values.shipClass && !isOtherClassType}
            InputProps={{
              endAdornment:
  <InputAdornment position="end">
    <Tooltip title="Harmonized code lookup" placement="bottom">
      <IconButton
        aria-label="Harmonized code lookup"
        onClick={() => window.open('https://uscensus.prod.3ceonline.com/#!#%2Fp=0', '_blank')}
        edge="end"
      >
        <LookupIcon />
      </IconButton>
    </Tooltip>
  </InputAdornment>,

            }}
          />
        </Grid>
        )}
        {!hasInternational && (
        <Grid item xs={4}>
          <Field
            name={fields.eccn.key}
            label={fields.eccn.label}
            component={NewOrderCustomTextField}
            type="text"
            style={{ width: '100%' }}
            disabled={!formikProps.values.shipClass && !isOtherClassType}
          />
        </Grid>
        )}
        {(formikProps.values?.iataDetails?.unNo || isOtherClassType) && (
        <Grid item xs={4}>
          <Field
            name={fields.unNo.key}
            label={fields.unNo.label}
            component={NewOrderCustomTextField}
            blockValue={blockWeightAndDimensionInputs}
            type="text"
            style={{ width: '100%' }}
            disabled={!isOtherClassType}
          />
        </Grid>
        )}
      </Grid>
      <Grid item container spacing={1}>
        <Grid item xs={8}>
          <Field
            name={fields.shippingName.key}
            label={fields.shippingName.label}
            component={NewOrderCustomTextField}
            type="text"
            style={{ width: '100%' }}
            disabled={!isOtherClassType}
          />
        </Grid>
      </Grid>
      {formikProps.values.requiredLicenses && (
      <Grid item container spacing={1}>
        <Grid item xs={8}>
          <CustomTextArea
            disabled
            name={fields.requiredLicenses.key}
            label={fields.requiredLicenses.label}
            classes={inputLabelClasses}
            inputLabelClasses={inputLabelClasses}
            inputClass={inputClasses}
            rows={5}
          />
        </Grid>
      </Grid>
      )}
      {/* {showUserRequiredTrainingWarning(formikProps.values[fields.shipClass.key], training) && !isOtherClassType && (
      <Grid item>
        <PanelWarning message={`You don't have the required training to ship ${formikProps.values[fields.shipClass.key]}`} />
      </Grid>
      )} */}
    </>
  );
}

HazmatBiological.defaultProps = {
  hazmatClassesTypeList: [],
  filteredItemNameOptions: [],
  training: null,
  hasInternational: false,
  isOtherClassType: false,
};

HazmatBiological.propTypes = {
  formikProps: PropTypes.objectOf(PropTypes.any).isRequired,
  fields: PropTypes.shape(PropTypes.object).isRequired,
  hazmatClassesTypeList: PropTypes.arrayOf(PropTypes.any),
  filteredItemNameOptions: PropTypes.arrayOf(PropTypes.any),
  classType: PropTypes.string.isRequired,
  training: PropTypes.shape(PropTypes.object),
  hasInternational: PropTypes.bool,
  isOtherClassType: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
};

export default memo(HazmatBiological);
