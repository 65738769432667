import React from 'react';
import { AuthProvider } from './authContext';
import { MiscDataProvider } from './miscDataContext';
import { OrderDataProvider } from './orderDataContext';
import { ContactsDataProvider } from './contactsContext';
import { TaskDataProvider } from './taskDataContext';
import { SingleOrderDataProvider } from './singleOrderContext';
import { ShipmentDataProvider } from './shipmentDataContext';
import { NotificationDataProvider } from './notificationContext';
import { UserDataProvider } from './userContext';
import { PaymentDataProvider } from './paymentContext';
import { MaterialsDataProvider } from './materialsContext';
import { BinDataProvider } from './binDataContext';
import { SingleBinDataProvider } from './singleBinContext';
import SerialProvider from './serialContext';
import { ShipmentManifestProvider } from './shipmentManifestContext';
import { IpdShipmentProvider } from './ipdShipmentContext';

import USBProvider from './usbContext';

function UnauthenticatedProviders({ children }) {
  return (
    <AuthProvider>
      {children}
    </AuthProvider>
  );
}

function AuthenticatedProviders({ children }) {
  return (
    <UserDataProvider>
      <NotificationDataProvider>
        <PaymentDataProvider>
          <MiscDataProvider>
            <OrderDataProvider>
              <ContactsDataProvider>
                <ShipmentDataProvider>
                  <BinDataProvider>
                    <TaskDataProvider>
                      <SingleOrderDataProvider>
                        <SingleBinDataProvider>
                          <ShipmentManifestProvider>
                            <IpdShipmentProvider>
                              <MaterialsDataProvider>
                                <SerialProvider>
                                  <USBProvider>
                                    {children}
                                  </USBProvider>
                                </SerialProvider>
                              </MaterialsDataProvider>
                            </IpdShipmentProvider>
                          </ShipmentManifestProvider>
                        </SingleBinDataProvider>
                      </SingleOrderDataProvider>
                    </TaskDataProvider>
                  </BinDataProvider>
                </ShipmentDataProvider>
              </ContactsDataProvider>
            </OrderDataProvider>
          </MiscDataProvider>
        </PaymentDataProvider>
      </NotificationDataProvider>
    </UserDataProvider>
  );
}

function AppProviders({ children }) {
  return (
    <UnauthenticatedProviders>
      <AuthenticatedProviders>
        {children}
      </AuthenticatedProviders>
    </UnauthenticatedProviders>
  );
}

export { AppProviders, AuthenticatedProviders, UnauthenticatedProviders };
