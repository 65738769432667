import {
  cloneDeep, get, sortBy, unset,
} from 'lodash';
import * as Yup from 'yup';
import {
  combineAddress,
  deconstructAddress,
  createAddressArray,
} from './helpers';
import {
  FREIGHT_TYPES,
  LB,
  KG,
  KG_FREIGHT_LIMIT,
  LB_FREIGHT_LIMIT,
  DEFAULT_KG_LIMIT,
  DEFAULT_LB_LIMIT,
} from '../components/ItemProductDetails/itemDetailsConstants';

import { COUNTRIES_THAT_REQUIRE_CUSTOMS_VALUE } from '../clientConstants';

function validateChemicalShipmentsValue(value, context, packages, isChemicalHazmat) {
  if (!isChemicalHazmat) return true;

  const key = 'packages';
  const path = context.path.includes(key) ? context.path.replace(key, '') : context.path;
  const originalValue = parseFloat(get(packages, path, 0));
  const formattedValue = parseFloat(value);

  return formattedValue < originalValue ? context.createError({
    message: `Value for this field cannot be less than ${originalValue}`,
  }) : true;
}

const packageDimensionsSchema = Yup.object().shape({
  unitOfMeasurement: Yup.string().required('Required'),
  length: Yup.mixed()
    .test('is number', 'Length must be a number', (l) => !isNaN(Number(l)))
    .test('Greater than zero', 'Length must be greater than zero', (l) => (!isNaN(Number(l)) ? Number(l) > 0 : true))
    .test(
      'One decimal place max',
      'Length must not include more than two decimal places',
      (a) => !a.match(/\.[0-9]{3}/),
    )
    // .test('Leading zero', 'Leading zero not allowed', (a) => !a.match(/^0+/))
    .test('Input exists', 'Required', (l) => !!l),
  width: Yup.mixed()
    .test('is number', 'Length must be a number', (w) => !isNaN(Number(w)))
    .test('Greater than zero', 'Width must be greater than zero', (w) => (!isNaN(Number(w)) ? Number(w) > 0 : true))
    .test(
      'One decimal place max',
      'Width must not include more than two decimal places',
      (a) => !a.match(/\.[0-9]{3}/),
    )
    // .test('Leading zero', 'Leading zero not allowed', (a) => !a.match(/^0+/))
    .test('Input exists', 'Required', (w) => !!w),
  height: Yup.mixed()
    .test('is number', 'Length must be a number', (h) => !isNaN(Number(h)))
    .test('Greater than zero', 'Height must be greater than zero', (h) => (!isNaN(Number(h)) ? Number(h) > 0 : true))
    .test(
      'One decimal place max',
      'Height must not include more than two decimal places',
      (a) => !a.match(/\.[0-9]{3}/),
    )
    // .test('Leading zero', 'Leading zero not allowed', (a) => !a.match(/^0+/))
    .test('Input exists', 'Required', (h) => !!h),
});

const getPackageMonetaryValueSchema = (freightType, packages = [], isChemicalHazmat) => {
  let maxValue = Number.MAX_SAFE_INTEGER;
  if (FREIGHT_TYPES.document === freightType) {
    maxValue = 100;
  }
  if (FREIGHT_TYPES.freight === freightType) {
    maxValue = 50000;
  }
  if (FREIGHT_TYPES.product === freightType) {
    maxValue = 50000;
  }

  return Yup.object().shape({
    amount: Yup.mixed()
      .test('is number', 'Amount must be a number', (l) => !isNaN(Number(l)))
      .test(
        'Less than max value',
        `Amount for a ${freightType} order cannot be greater than ${maxValue}`,
        (l) => (!isNaN(Number(l)) ? Number(l) <= maxValue : true),
      )
      .test('Input exists', 'Required', (l) => !!l)
      .test(
        'No more than two decimals',
        'Amount must be rounded to 2 decimal points',
        (a) => !a || !a.match(/\.[0-9]{3,}/),
      )
      .test(
        'Less than original value',
        'Amount cannot be less',
        (value, context) => validateChemicalShipmentsValue(
          value,
          context,
          packages,
          isChemicalHazmat,
        ),
      ),

    currency: Yup.string().required('Required'),
  });
};

const smallWeightSchema = Yup.mixed()
  .test('is number', 'Weight must be a number', (l) => !isNaN(Number(l)))
  .test('Greater than zero', 'Weight cannot be negative', (l) => (!isNaN(Number(l)) ? Number(l) >= 0 : true))
  // .test('Leading zero', 'Leading zero not allowed', (a) => !a.match(/^0+/))
  .test('no-weight', 'Required', function isWeight(value) {
    const { weight } = this.parent;
    if (!weight && !value) return false;
    return true;
  })
  .test(
    'weight limit',
    'Exceeds weight limit (16oz or 1000gm)',
    function weightLimit(value) {
      if (!value) return true;
      const unit = this.parent.unitOfMeasurement;
      switch (unit) {
        case LB:
          return value < 16;
        case KG:
          return value <= 1000;
        default:
          break;
      }
    },
  )
  .nullable(true)
  .typeError('Must be a number');

function getWeightSchema(freightType, packages, isChemicalHazmat, items) {
  const lbWeightLimit = freightType === FREIGHT_TYPES.freight ? LB_FREIGHT_LIMIT : DEFAULT_LB_LIMIT;
  const kgWeightLimit = freightType === FREIGHT_TYPES.freight ? KG_FREIGHT_LIMIT : DEFAULT_KG_LIMIT;
  return Yup.object().shape({
    unitOfMeasurement: Yup.string().required('Required'),
    weightSmall: smallWeightSchema,
    weight: Yup.number()
      .min(0, 'Value must be 0 or greater')
      .test('no-small-weight', 'Required', function isWeight(value) {
        const { weightSmall } = this.parent;
        if (!weightSmall && !value) return false;
        if (weightSmall == 0 && value == 0) return false;
        return true;
      })
      .test(
        'weight limit',
        `Exceeds weight limit (${lbWeightLimit}lb or ${kgWeightLimit}kg)`,
        function weightLimit(value, context) {
          const { weightSmall } = this.parent;
          const remainder = parseFloat(weightSmall) ? 1 : 0;
          if (!value) return true;
          const unit = this.parent.unitOfMeasurement;
          switch (unit) {
            case LB:
              return value <= lbWeightLimit - remainder;
            case KG:
              return value <= kgWeightLimit - remainder;
            default:
              break;
          }
        },
      )
      .test(
        'check item weight',
        function weightLimit(value, context) {
          const { weightSmall } = this.parent;
          const remainder = parseFloat(weightSmall) ? 1 : 0;
          if (!value) return true;
          if (!items) return true;
          if (items && items?.length === 0) return true;
          if (!context?.from[1]?.value?.items) return true;
          const mappedItem = context?.from[1]?.value?.items.map((i) => i.id);
          const packageItems = items?.filter((i, idx) => mappedItem.includes(i.itemId));
          const itemList = context?.from[1]?.value?.items;
          if (packageItems?.length === 0) return true;

          const kgItemWeightLimit = parseFloat(
            packageItems.reduce(
              (acc, obj) => acc
                + (obj.totalWeightLB / obj.units?.noOfUnits ?? 1)
                  * itemList.find((i) => i.id === obj.itemId)?.quantity,
              0.0,
            ),
          );
          const lbItemWeightLimit = parseFloat(
            packageItems.reduce(
              (acc, obj) => acc
                + (obj.totalWeightLB / obj.units?.noOfUnits ?? 1)
                  * itemList.find((i) => i.id === obj.itemId)?.quantity,
              0.0,
            ),
          );
          const unit = this.parent.unitOfMeasurement;
          switch (unit) {
            case LB:
              return value >= lbItemWeightLimit - remainder
                ? true
                : context.createError({
                  path: context.path,
                  message:
                      `Value must be more than total item weight(${lbItemWeightLimit - remainder} ${unit}) in package`,
                });
            case KG:
              return value >= kgItemWeightLimit - remainder
                ? true
                : context.createError({
                  path: context.path,
                  message: `Value must be more than total item weight(${kgItemWeightLimit - remainder} ${unit}) in package`,
                });
            default:
              return true;
          }
        },
      )
      .nullable(true)
      .typeError('Number required'),
  });
}
function getDryIceWeightSchema() {
  const lbWeightLimit = DEFAULT_LB_LIMIT;
  const kgWeightLimit = DEFAULT_KG_LIMIT;

  return Yup.object().shape({
    unitOfMeasurement: Yup.string().test(
      'no-weight',
      'Required',
      function isWeight(value) {
        const { isRefrigerant } = this.from[1].value;
        const { weight } = this.parent;
        if (isRefrigerant && !weight && !value) return false;
        return true;
      },
    ),
    weight: Yup.number()
      .positive('Weight must be a positive number.')
      .test(
        'no-weight',
        'Required',
        function isWeight(value) {
          const { isRefrigerant } = this.from[1].value;

          if (!isRefrigerant) return true;

          return Boolean(isRefrigerant && value);
        },
      )
      .test(
        'weight limit',
        'Exceeds weight limit',
        function weightLimit(value, context) {
          const { iataDetails, isRefrigerant } = context.from[1].value;

          if (!isRefrigerant || !value) return true;

          const kgLimit = iataDetails?.maxWeight.kilograms;
          const unit = this.parent.unitOfMeasurement;

          switch (unit) {
            case LB:
              return value <= kgLimit * 2.2
                ? true
                : this.createError({
                  message: `Exceeds weight limit of ${kgLimit * 2.2} LB`,
                });
            case KG:
              return value <= kgLimit
                ? true
                : this.createError({
                  message: `Exceeds weight limit of ${kgLimit} KG`,
                });
            default:
              break;
          }
        },
      )
      .nullable(true)
      .transform(emptyStringToNull),
  });
}
// helper for yup transform function
function emptyStringToNull(value, originalValue) {
  if (typeof originalValue === 'string' && originalValue === '') {
    return null;
  }
  return value;
}
function getWeightSchemaNoSmall(isFreight = false) {
  const lbWeightLimit = isFreight === FREIGHT_TYPES.freight ? LB_FREIGHT_LIMIT : DEFAULT_LB_LIMIT;
  const kgWeightLimit = isFreight === FREIGHT_TYPES.freight ? KG_FREIGHT_LIMIT : DEFAULT_KG_LIMIT;
  return Yup.object().shape({
    unitOfMeasurement: Yup.string().required('Required'),
    weight: Yup.number()
      .min(0, 'Value must be 0 or greater')
      .test(
        'weight limit',
        `Exceeds weight limit (${lbWeightLimit}lb or ${kgWeightLimit}kg)`,
        function weightLimit(value) {
          if (!value) return true;
          const unit = this.parent.unitOfMeasurement;
          switch (unit) {
            case LB:
              return value <= lbWeightLimit;
            case KG:
              return value <= kgWeightLimit;
            default:
              break;
          }
        },
      )
      .nullable(true)
      .typeError('Number required'),
  });
}
const clientIotSchema = Yup.object().shape({
  deviceId: Yup.string().notRequired(),
  vendorName: Yup.string().notRequired(),
  triggers: Yup.array()
    .of(
      Yup.object().shape({
        enabled: Yup.bool().notRequired(),
        value: Yup.mixed().when(
          ['triggerType', 'enabled'],
          (triggerType, enabled, schema) => (triggerType === 'shipmentStopped' && enabled
            ? schema
              .required('Required')
              .test(
                'Is valid value (greater than 0) ?',
                'Required',
                (v) => !!v,
              )
            : schema),
        ),
        triggerType: Yup.string().required('Missing trigger type'),
        address: Yup.object().when('operator', (operator, schema) => (operator
          ? schema
            .shape({
              name: Yup.string().required('Required'),
              addressLine1: Yup.string()
                .required('Required')
                .test(
                  'Can be deconstructed into object',
                  'Address is not complete',
                  (a) => createAddressArray(a).length >= 4,
                ),
              // addressLine2: Yup.string().notRequired(),
              // city: Yup.string().required('Required'),
              // state: Yup.string().required('Required'),
              country: Yup.string().required('Required'),
            })
            .required()
          : schema.notRequired())),
        operator: Yup.string()
          .oneOf(['VALUE_LESS_THAN', 'VALUE_GREATER_THAN', ''])
          .notRequired(),
      }),
    )
    .notRequired(),
});

export function getValidationSchema(
  freightType,
  hiddenFields,
  account = 'test',
  packages,
  isMultipleAddress = false,
  isChemicalHazmat = false,
  items = [],
  isBiologicalHazmat = false,
  shippingType,
) {
  if (account === 'worldemblem') {
    const valSchema = Yup.object().shape({
      purpose: Yup.string(),
      otherPurpose: Yup.string().when('purpose', {
        is: (v) => v?.toLowerCase() === 'other',
        then: Yup.string().required('Required'),
      }),

      ...(!hiddenFields.includes('shipmentFreightType') && {
        shipmentFreightType: Yup.string().required('Required'),
      }),
      // shipmentFreightType: Yup.string().test('Success', 'Required', function (shipmentFreightType) {
      //   return !hiddenFields.includes('shipmentFreightType') && !!shipmentFreightType;
      // }),
      packages: Yup.array()
        .of(
          Yup.object().shape({
            packagingType: Yup.string().required('Required'),
            carrierLabel: Yup.string()
              .required('Required')
              .typeError('Required'),
            packagingName: Yup.string()
              .required('Required')
              .typeError('Required'),
            packagingTypeCode: Yup.string().required('Required'),
            dimensions: packageDimensionsSchema,
            ...(!hiddenFields.includes('declaredValue') && {
              declaredValue: getPackageMonetaryValueSchema(freightType),
            }),
            ...(!hiddenFields.includes('insuredValue') && {
              insuredValue: getPackageMonetaryValueSchema(freightType),
            }),
            ...(!hiddenFields.includes('customsValue') && {
              customsValue: getPackageMonetaryValueSchema(freightType),
            }),
            packageWeight: getWeightSchemaNoSmall(freightType),
            referenceNumber1: Yup.string().required('Required'),
          }),
        )
        .required('Required'),
    });
    return valSchema;
  }

  return Yup.object().shape({
    purpose: Yup.string()
    .when([], {
       is: () => (account === 'nationwide' && (isBiologicalHazmat ||isChemicalHazmat) && shippingType === 'international'),
       then:Yup.string().required('Required'),
       otherwise: Yup.string()
     }),
    otherPurpose: Yup.string().when('purpose', {
      is: (v) => v?.toLowerCase() === 'other',
      then: Yup.string().required('Required'),
    }),

    ...(!hiddenFields.includes('shipmentFreightType') && {
      shipmentFreightType: Yup.string().required('Required'),
    }),
    // shipmentFreightType: Yup.string().test('Success', 'Required', function (shipmentFreightType) {
    //   return !hiddenFields.includes('shipmentFreightType') && !!shipmentFreightType;
    // }),
    ...(isMultipleAddress && {
      carrierName: Yup.string().required('Required'),
      serviceCode: Yup.string().required('Required'),
    }),
    packages: Yup.array()
      .of(
        Yup.object().shape({
          packagingType: Yup.string().required('Required'),
          carrierLabel: Yup.string().required('Required').typeError('Required'),
          packagingName: Yup.string()
            .required('Required')
            .typeError('Required'),
          packagingTypeCode: Yup.string().required('Required'),
          dimensions: packageDimensionsSchema,
          ...(!hiddenFields.includes('declaredValue') && {
            declaredValue: getPackageMonetaryValueSchema(freightType, packages, isChemicalHazmat),
          }),
          ...(!hiddenFields.includes('insuredValue') && {
            insuredValue: getPackageMonetaryValueSchema(freightType, packages, isChemicalHazmat),
          }),
          ...(!hiddenFields.includes('customsValue') && {
            customsValue: getPackageMonetaryValueSchema(freightType, packages, isChemicalHazmat),
          }),
          ...(hiddenFields.includes('material') && {
            isDangerousGoods: Yup.bool(),
            isRefrigerant: Yup.bool(),
            refrigerantType: Yup.string().test(
              'refrigerant check ',
              'Required',
              function refCheck(value) {
                const { isRefrigerant } = this.parent;
                if (isRefrigerant && !value) {
                  return false;
                }
                return true;
              },
            ),
            isMTARequired: Yup.bool(),
            isSponseredResearch: Yup.bool(),
            sponseredResearchNumber: Yup.string().test(
              'SP check ',
              'Required',
              function refCheck(value) {
                const { isSponseredResearch } = this.parent;
                if (isSponseredResearch && !value) {
                  return false;
                }
                return true;
              },
            ),
            dryIceWeight: getDryIceWeightSchema(),
            dangerousGoodsType: Yup.string().when('isDangerousGoods', {
              is: true,
              then: (schema) => schema.required('Required'),
              otherwise: (schema) => schema.notRequired(),
            }),
          }),
          packageWeight: getWeightSchema(freightType, packages, isChemicalHazmat, items),
          nonRMSContents: Yup.string(),
          iot: clientIotSchema,
        }),
      )
      .required('Required'),
  });
}

export const triggerRanges = {
  batteryPower: {
    min: 0,
    max: 100,
  },
  temperature: {
    min: -22,
    max: 176,
  },
  humidity: {
    min: 0,
    max: 100,
  },
  pressure: {
    min: 0,
    max: 100,
  },
};

export const clientTriggers = {
  shock: {
    order: 0,
    type: 'checkbox',
    initialState: {
      triggerType: 'shock',
      enabled: false,
    },
  },
  departure: {
    order: 1,
    type: 'checkbox',
    initialState: {
      triggerType: 'departure',
      enabled: false,
    },
  },
  arrival: {
    order: 2,
    type: 'checkbox',
    initialState: {
      triggerType: 'arrival',
      enabled: false,
    },
  },
  shipmentStopped: {
    order: 3,
    type: 'checkbox',
    initialState: {
      triggerType: 'shipmentStopped',
      enabled: false,
      value: 0,
    },
  },
  connectivity: {
    order: 4,
    type: 'select',
    initialState: {
      triggerType: 'connectivity',
      value: 0,
    },
  },
  batteryPower: {
    order: 5,
    type: 'range-slider',
    initialState: {
      triggerType: 'batteryPower',
      value: [
        triggerRanges.batteryPower.min - 1,
        triggerRanges.batteryPower.max + 1,
      ],
    },
  },
  temperature: {
    order: 6,
    type: 'range-slider',
    initialState: {
      triggerType: 'temperature',
      value: [
        triggerRanges.temperature.min - 1,
        triggerRanges.temperature.max + 1,
      ],
    },
  },
  humidity: {
    order: 7,
    type: 'range-slider',
    initialState: {
      triggerType: 'humidity',
      value: [triggerRanges.humidity.min - 1, triggerRanges.humidity.max + 1],
    },
  },
  // pressure: {
  //   order: 8,
  //   type: 'range-slider',
  //   initialState: {
  //     triggerType: 'pressure',
  //     value: [triggerRanges.pressure.min - 1, triggerRanges.pressure.max + 1],
  //   },
  // },
  perimeter: {
    order: 8,
    type: 'special',
    initialState: {
      triggerType: 'perimeter',
      address: {
        name: '',
        addressLine1: '',
        // addressLine2: '',
        // city: '',
        // state: '',
        // zip: '',
        country: 'US',
      },
      shape: 'radius',
      radius: '0',
      operator: '',
    },
  },
};

export const initialTriggerState = sortBy(
  Object.values(clientTriggers),
  'order',
).map((t) => t.initialState);

function formatIotTriggersForClient(triggers) {
  const formattedTriggers = cloneDeep(initialTriggerState);
  triggers.forEach((trigger) => {
    const clientTrigger = clientTriggers[trigger.triggerType];
    if (clientTrigger.type === 'range-slider') {
      let valueIndex;
      if (trigger.operator === 'VALUE_GREATER_THAN') {
        valueIndex = 1;
      }
      if (trigger.operator === 'VALUE_LESS_THAN') {
        valueIndex = 0;
      }
      if (
        formattedTriggers[clientTrigger.order]
        && formattedTriggers[clientTrigger.order].value
      ) {
        formattedTriggers[clientTrigger.order].value[valueIndex] = trigger.value;
        return;
      }
      return;
    }

    if (trigger.triggerType === 'perimeter') {
      // eslint-disable-next-line no-param-reassign
      trigger.shape = 'radius';
      // eslint-disable-next-line no-param-reassign
      trigger.address.addressLine1 = combineAddress(trigger.address);
    }

    formattedTriggers[clientTrigger.order] = trigger;
  });

  for (let i = 0; i < formattedTriggers.length; i += 1) {
    if (!formattedTriggers[i]) {
      formattedTriggers[i] = initialTriggerState[i];
    }
  }

  return formattedTriggers;
}

function formatIotTriggersFromClient(triggers) {
  const formattedTriggers = [];
  triggers.forEach((trigger) => {
    const clientTrigger = clientTriggers[trigger.triggerType];

    if (clientTrigger.type === 'select' && !trigger.value) return;

    if (clientTrigger.type === 'checkbox' && !trigger.enabled) return;

    if (clientTrigger.type === 'range-slider') {
      if (trigger.value[0] >= triggerRanges[trigger.triggerType].min) {
        formattedTriggers.push({
          triggerType: trigger.triggerType,
          operator: 'VALUE_LESS_THAN',
          value: trigger.value[0],
        });
      }

      if (trigger.value[1] <= triggerRanges[trigger.triggerType].max) {
        formattedTriggers.push({
          triggerType: trigger.triggerType,
          operator: 'VALUE_GREATER_THAN',
          value: trigger.value[1],
        });
      }
      return;
    }

    if (trigger.triggerType === 'perimeter') {
      if (!trigger.operator) return;
      // eslint-disable-next-line no-param-reassign
      delete trigger.shape;
      // eslint-disable-next-line no-param-reassign
      trigger.unit = 'km';
      // eslint-disable-next-line no-param-reassign
      trigger.address = {
        ...trigger.address,
        ...deconstructAddress(trigger.address.addressLine1),
      };
    }
    formattedTriggers.push(trigger);
  });
  return formattedTriggers;
}

function formatPackageForClient(pkg, itemsArrayFromProductDetails) {
  const formattedPkg = cloneDeep(pkg);
  if ('iot' in formattedPkg && 'triggers' in formattedPkg.iot) {
    formattedPkg.iot.triggers = formatIotTriggersForClient(
      formattedPkg.iot.triggers,
    );
  }

  const items = formatItemsForClientState(
    itemsArrayFromProductDetails,
  );

  return { ...formattedPkg, items };
}

function flatten(arr) {
  return arr.reduce((flat, toFlatten) => flat.concat(toFlatten.items), []);
}

const convertToArray = (obj) => Object.keys(obj).map((itemId) => obj[itemId]);

// gets all the info from individual packages
export const generateArrayOfAllItemsInShipment = (packages) => convertToArray(flatten(packages));
export const formatItemsForClientInitialValues = (
  itemsArrayFromPackagingPane,
  itemsArrayFromProductPane,
) => {
  const itemsObj = itemsArrayFromPackagingPane.reduce(
    (obj, item) => ({
      ...obj,
      [item.itemId]: {
        id: item?.itemId,
        name: item?.itemName,
        quantity: item?.units?.noOfUnits,
        unitOfMeasurement: item?.units?.unitOfMeasurement,
        unitValue: item?.units?.unitValue,
      },
    }),
    {},
  );
  const returnObj = getFormInitialValuesFromProductPane(
    itemsObj,
    itemsArrayFromProductPane,
  );
  // const returnObj = setFieldsFromProductPane(itemsObj, itemsArrayFromProductPane);
  return returnObj;
};

export const formatItemsForClientState = (
  itemsArrayFromProductPane,
) => itemsArrayFromProductPane?.map(
  (item, idx) => ({
    id: item?.itemId,
    itemKey: `${item?.itemId}-${idx}`,
    name: item?.itemName,
    maxQuantity: item?.units?.noOfUnits,
    quantity: item?.units?.noOfUnits,
    itemWeight: item?.itemWeight,
    unitOfMeasurement: item?.units?.unitOfMeasurement,
    unitValue: item?.units?.unitValue,
  }),
);

function getFormInitialValuesFromProductPane(
  itemsObj,
  itemsArrFromProductPane = [],
) {
  const formattedItemsObj = cloneDeep(itemsObj);
  itemsArrFromProductPane.forEach((item) => {
    if (itemsObj[item.itemId]) {
      formattedItemsObj[item.itemId] = {
        ...itemsObj[item.itemId],
      };
    } else {
      formattedItemsObj[item.itemId] = {
        id: item?.itemId,
        name: item?.itemName,
        quantity: 0,
        unitOfMeasurement: item?.units?.unitOfMeasurement,
        unitValue: item?.units?.unitValue,
      };
    }
  });
  return formattedItemsObj;
}

export function formatPackageToApi(packages) {
  const allPkgs = cloneDeep(packages);

  for (let i = 0; i < allPkgs.length; i += 1) {
    if (!allPkgs[i].isRefrigerant) {
      delete allPkgs[i].dryIceWeight;
      delete allPkgs[i].iataDetails;
      delete allPkgs[i].refrigerantType;
    }
    if (!allPkgs[i].isDangerousGoods) {
      delete allPkgs[i].dangerousGoodsType;
    }
  }
  return allPkgs;
}

export function formatPackageFromClient(pkg) {
  const formattedPkg = cloneDeep(pkg);
  if (
    'iot' in formattedPkg
    && (!formattedPkg.iot.deviceId || !formattedPkg.iot.vendorName)
  ) {
    unset(formattedPkg, 'iot');
  }
  if ('iot' in formattedPkg && 'triggers' in formattedPkg.iot) {
    formattedPkg.iot.triggers = formatIotTriggersFromClient(
      formattedPkg.iot.triggers,
    );
  }
  unset(formattedPkg, 'dimensions.isDimensionsEnabled');
  unset(formattedPkg, 'packageWeight.isWeightEnabled');
  unset(formattedPkg, 'customsValue.minimum');
  unset(formattedPkg, 'declaredValue.minumumCurrency');
  unset(formattedPkg, 'key');
  const formattedPkgWithItemsArr = {
    ...formattedPkg,
    items: formatItemsArrayForSubmit(formattedPkg.items),
  };
  unset(formattedPkg, 'dryIcePackagingNumber');
  delete formattedPkg?.dryIcePackagingNumber;
  delete formattedPkg?.packageProfile;
  return formattedPkgWithItemsArr;
}

export function formatPackagingDetailsForClient(
  details,
  itemsArrayFromProductDetails,
) {
  return {
    ...details,
    packages: details.packages.map((pkg) => formatPackageForClient(pkg, itemsArrayFromProductDetails)),
  };
}

export function formatPackagingDetailsFromClient(details) {
  return {
    details: {
      ...details,
      packages: details.packages.map((pkg) => formatPackageFromClient(pkg)),
    },
  };
}

export function isIotEnabled(pkg) {
  if ('iot' in pkg && pkg.iot.deviceId && pkg.iot.vendorName) {
    return true;
  }
  return false;
}

export function formatItemsArrayForSubmit(items) {
  return items?.filter((i) => !isNaN(parseInt(i.quantity, 10)) && parseInt(i.quantity, 10) !== 0).map((item) => ({
    units: {
      unitValue: {
        currency: 'USD',
        amount: item?.unitValue?.amount,
      },
      noOfUnits: parseInt(item.quantity, 10).toString(),
      unitOfMeasurement: item.unitOfMeasurement,
    },
    itemId: item.id,
    itemName: item.name,
  }));
}

export function convertWeight(weight, unitOfMeasurement) {
  const decapitalizedUnitOfMeasurement = unitOfMeasurement.toLowerCase();
  let convertedWeight;
  switch (decapitalizedUnitOfMeasurement) {
    case 'lb':
      // return kg
      convertedWeight = weight / 2.2046;
      break;
    case 'kg':
      // return lbs
      convertedWeight = weight * 2.2046;
      break;
    case 'oz':
      // return g
      convertedWeight = weight * 28.3495;
      break;
    case 'g':
      // return oz
      convertedWeight = weight / 28.3495;
      break;
    default:
      return weight;
  }

  // round to 2 decimal places
  return Math.round((convertedWeight + Number.EPSILON) * 100) / 100;
}

export function consolidateWeight({ weightSmall, unitOfMeasurement, weight }) {
  const weightSmallInt = weightSmall ? parseFloat(weightSmall) : 0;
  const weightInt = weight ? parseFloat(weight) : 0;
  const decapitalizedUnitOfMeasurement = unitOfMeasurement.toLowerCase();
  let consolidatedWeight;
  switch (decapitalizedUnitOfMeasurement) {
    case 'lb':
      consolidatedWeight = weightInt + weightSmallInt / 16;
      break;
    case 'kg':
      consolidatedWeight = weightInt + weightSmallInt / 1000;
      break;
    default:
      consolidatedWeight = weightInt;
  }

  return { weight: consolidatedWeight, unitOfMeasurement };
}

export function breakUpWeight({ weight, unitOfMeasurement }) {
  const decapitalizedUnitOfMeasurement = unitOfMeasurement.toLowerCase();
  const newWeight = Math.trunc(weight);
  const leftoverWeight = weight - newWeight;
  switch (decapitalizedUnitOfMeasurement) {
    case 'lb':
      return {
        weight: newWeight,
        weightSmall: leftoverWeight * 16,
        unitOfMeasurement,
      };
    case 'kg':
      return {
        weight: newWeight,
        weightSmall: leftoverWeight * 1000,
        unitOfMeasurement,
      };
    default:
      return {
        weight: newWeight,
        weightSmall: 0,
        unitOfMeasurement,
      };
  }
}

export const getDefaultMonetaryValues = (
  freightType,
  country,
  itemsAvailable,
  hiddenFields = [],
) => {
  // TODO: We will update this code in the future once the rules have been finalized
  const customsAmount = COUNTRIES_THAT_REQUIRE_CUSTOMS_VALUE.includes(country)
    ? '100.00'
    : '100.00';

  // Using a switch statement here bc expecting additional cases needed for other freightTypes
  switch (freightType) {
    case FREIGHT_TYPES.document:
      return {
        ...(!hiddenFields.includes('declaredValue') && {
          declaredValue: {
            amount: '0.00',
            currency: 'USD',
            minimum: '0',
          },
        }),

        ...(!hiddenFields.includes('customsValue') && {
          customsValue: {
            amount: '0.00',
            currency: 'USD',
          },
        }),

        ...(!hiddenFields.includes('insuredValue') && {
          insuredValue: {
            amount: '1.00',
            currency: 'USD',
          },
        }),
      };
    default:
      if (itemsAvailable) {
        return {
          ...(!hiddenFields.includes('declaredValue') && {
            declaredValue: {
              amount: '0.00',
              currency: 'USD',
              minimum: '0',
            },
          }),

          ...(!hiddenFields.includes('customsValue') && {
            customsValue: {
              amount: '0.00',
              currency: 'USD',
            },
          }),

          ...(!hiddenFields.includes('insuredValue') && {
            insuredValue: {
              amount: '1.00',
              currency: 'USD',
            },
          }),
        };
      }
      return {
        ...(!hiddenFields.includes('declaredValue') && {
          declaredValue: {
            amount: '100.00',
            currency: 'USD',
            minimum: '0',
          },
        }),

        ...(!hiddenFields.includes('customsValue') && {
          customsValue: {
            amount: customsAmount,
            currency: 'USD',
          },
        }),

        ...(!hiddenFields.includes('insuredValue') && {
          insuredValue: {
            amount: '1.00',
            currency: 'USD',
          },
        }),
      };
  }
};
