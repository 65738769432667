import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  AppBar, Tab, Tabs, Typography,
} from '@material-ui/core';
import get from 'lodash/get';
import has from 'lodash/has';
import DefaultSenderTab from '../DefaultSenderTab';
import SenderPickupOptions from './SenderPickupOptions';
import NewOrderTabPanel from '../common/NewOrderTabPanel';
import NewOrderErrorDialog from '../common/NewOrderErrorDialog';
import NewOrderSenderRecipientAddress from './NewOrderSenderRecipientAddress';
import NewOrderSenderRecipientContacts from './NewOrderSenderRecipientContacts';
import { useSingleOrderState } from '../../context/singleOrderContext';
import { useContactsState, useContactsDispatch, updateContactsState } from '../../context/contactsContext';
import { SENDER_FORM_TABS_WITH_IDS } from '../../clientConstants';
import { NEW_ORDER_TABS_STYLE, NEW_ORDER_SENDER_RECIPIENT_STYLE } from '../../styles/style';
import { sanitizeAddress } from '../../utils/helpers';

/**
 * ##################################
 *          GLOBAL VARIABLES
 * ##################################
 */

const DEFAULT_SENDER_TAB_VALUE = 'one';
const SELECT_FROM_CONTACTS_TAB_VALUE = 'two';
const ENTER_ADDRESS_TAB_VALUE = 'three';
const ORIGIN = 'origin';
const PICKUPFROM = 'pickupFrom';

/**
 * ##################################
 *          CUSTOM COMPONENT
 * ##################################
 */

function CustomAppBar(props) {
  const { selectedTab, classes } = props;
  const { onChange } = props;

  const { appBar, tabIndicator, tab } = classes;

  return (
    <AppBar position="static" className={appBar}>
      <Tabs
        classes={{ indicator: tabIndicator }}
        textColor="primary"
        value={selectedTab}
        onChange={onChange}
        variant="fullWidth"
      >
        {
         SENDER_FORM_TABS_WITH_IDS.map((tabData, index) => {
           const { name, value, label } = tabData;

           return (
             <Tab
               {...{ value, label }}
               key={`sender-tabs-${name}`}
               className={tab}
               wrapped
             />
           );
         })
                }
      </Tabs>
    </AppBar>
  );
}

CustomAppBar.propTypes = {
  selectedTab: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    appBar: PropTypes.string.isRequired,
    tabIndicator: PropTypes.string.isRequired,
    tab: PropTypes.string.isRequired,
  }).isRequired,
};

function CustomDefaultSender({
  selectedTab, defaultSender = null,
  pickupOptions, classes,
  onSetSender, onEditContact,
}) {
  return (
    <NewOrderTabPanel
      index={DEFAULT_SENDER_TAB_VALUE}
      value={selectedTab}
      classes={classes}
    >
      {(defaultSender)
        ? (
          <DefaultSenderTab
            defaultSender={defaultSender}
            tabValue={DEFAULT_SENDER_TAB_VALUE}
            pickupOptions={pickupOptions}
            handleSubmit={onSetSender}
            handleEdit={onEditContact}
            classes={classes}
          />
        )
        : (
          <Typography className={classes.noResults}>
            Select a Default Sender from Address Book
          </Typography>
        )}
    </NewOrderTabPanel>
  );
}

CustomDefaultSender.propTypes = {
  selectedTab: PropTypes.string.isRequired,
  defaultSender: PropTypes.objectOf(PropTypes.any),
  pickupOptions: PropTypes.string.isRequired,
  onSetSender: PropTypes.func.isRequired,
  onEditContact: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    noResults: PropTypes.string.isRequired,
  }).isRequired,
};

CustomDefaultSender.defaultProps = {
  defaultSender: null,
};

function CustomContacts(props) {
  const { selectedTab, shipmentOrigin, ...other } = props;
  const { onSetSender, onEditContact } = props;
  const { classes } = other;

  return (
    <NewOrderTabPanel
      index={SELECT_FROM_CONTACTS_TAB_VALUE}
      value={selectedTab}
      classes={classes}
    >
      <NewOrderSenderRecipientContacts
        {...other}
        contact={shipmentOrigin}
        tabValue={SELECT_FROM_CONTACTS_TAB_VALUE}
        handleSubmit={onSetSender}
        handleEdit={onEditContact}
      />
    </NewOrderTabPanel>
  );
}

CustomContacts.propTypes = {
  selectedTab: PropTypes.string.isRequired,
  onSetSender: PropTypes.func.isRequired,
  onEditContact: PropTypes.func.isRequired,
  shipmentOrigin: PropTypes.objectOf(PropTypes.any),
};

CustomContacts.defaultProps = {
  shipmentOrigin: null,
};

function CustomEnterAddress(props) {
  const { selectedTab, shipmentOrigin, ...other } = props;
  const { onSetSender } = props;
  const { classes } = other;

  return (
    <NewOrderTabPanel
      index={ENTER_ADDRESS_TAB_VALUE}
      value={selectedTab}
      classes={classes}
    >
      <NewOrderSenderRecipientAddress
        {...other}
        contact={shipmentOrigin}
        tabValue={ENTER_ADDRESS_TAB_VALUE}
        handleSubmit={onSetSender}
      />
    </NewOrderTabPanel>
  );
}

CustomEnterAddress.propTypes = {
  shipmentOrigin: PropTypes.objectOf(PropTypes.any).isRequired,
  selectedTab: PropTypes.string.isRequired,
  onSetSender: PropTypes.func.isRequired,
};

function CustomPickupOptions(props) {
  return (
    <SenderPickupOptions {...props} />
  );
}

/**
 * #########################
 *          UTIL
 * #########################
 */

function getOrigin(shipment) {
  return get(shipment, `${ORIGIN}`, null);
}

function getPickupFrom(shipment) {
  return get(shipment, `${PICKUPFROM}`, null);
}

function isSenderSelected(shipment) {
  return has(shipment, `${ORIGIN}.address`, false);
}

function isPanelAssigned(shipment) {
  return has(shipment, `${ORIGIN}.task`, false);
}

function buildAddressObject(address) {
  return sanitizeAddress(address);
}

function isSenderAnExistingContact(shipment) {
  return has(shipment, `${ORIGIN}.address.addressId`, false);
}

// used to determine which tab should be opened
// if the pane has already been filled out
// logic inside each tab will display the pickup options
// if the tab returned here is equal to the selectedTab
function setPickupOptionsPage(defaultSender, shipment) {
  // if there is no address, don't display pickup options
  if (!isSenderSelected(shipment)) return null;

  // if sender has been selected but is not part of user's address book, display the third tab
  if (isSenderSelected(shipment) && !isSenderAnExistingContact(shipment)) {
    return ENTER_ADDRESS_TAB_VALUE;
  }

  // If the selected sender is not the default sender, display second tab
  if (!defaultSender || get(defaultSender, 'addressId') !== get(shipment, `${ORIGIN}.address.addressId`)) {
    return SELECT_FROM_CONTACTS_TAB_VALUE;
  }

  // opens first tab by default
  return DEFAULT_SENDER_TAB_VALUE;
}

/**
 * #################################
 *          EXPORT FUNCTION
 * #################################
 */

export default function SenderTabs({
  selectedForms, shipmentId, submitFormToApi, openNextPanel
}) {
  const [selectedTab, setSelectedTab] = useState(DEFAULT_SENDER_TAB_VALUE);
  const [editAddress, setEditAddress] = useState({});
  const [resetSearchCache, setResetSearchCache] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorContent, setErrorContent] = useState(null);

  const contactsState = useContactsState();
  const contactsDispatch = useContactsDispatch();
  const shipment = useSingleOrderState().shipments[shipmentId];

  const shipmentOrigin = getOrigin(shipment);
  const shipmentPickupFrom = getPickupFrom(shipment);

  const classes = NEW_ORDER_TABS_STYLE();

  const { defaultSender } = contactsState;

  let pickupOptions = null;

  try {
    pickupOptions = setPickupOptionsPage(defaultSender, shipment);
  } catch (e) {
    // bypass errors
  }

  const commonProps = {
    shipmentOrigin,
    pickupOptions,
    selectedTab,
    onSetSender,
    onEditContact,
    account: shipment?.account,
  };

  useEffect(() => {
    // if this panel has not been filled out
    if (!shipmentOrigin) return;

    // if this panel has been assigned via collaboration and not filled out yet
    // open first tab and don't display pickup options
    if (isPanelAssigned(shipment) && !isSenderSelected(shipment)) {
      return setSelectedTab(DEFAULT_SENDER_TAB_VALUE);
    }

    // if the panel has been filled out, figure out which tab to open
    // assumes that pickup options should be visible
    setSelectedTab(setPickupOptionsPage(defaultSender, shipment));
  }, [shipmentOrigin, defaultSender]);

  /*          CALLBACKS           */

  function onTabChange(e, newValue) {
    setSelectedTab(newValue);
  }

  function onEditContact(address) {
    setEditAddress({ ...address, updateContacts: true });
    setSelectedTab(ENTER_ADDRESS_TAB_VALUE);
  }

  async function onSetSender(origin) {
    const { updateContacts } = origin;

    const originAddress = buildAddressObject(origin);

    const originDetails = {
      updateContacts: updateContacts || false,
      address: originAddress,
    };

    const payload = {
      origin: originDetails,
    };

    try {
      await submitFormToApi(payload, true);
    } catch (e) {
      return;
    }
    if (!updateContacts) return;

    const { addressId, isDefault } = origin;
    if (addressId) {
      try {
        updateContactsState({
          dispatch: contactsDispatch,
          contact: {
            ...originAddress,
            ...(isDefault) && { isDefault },
          },
        });
      } catch (e) {
        setErrorContent(e.message);
        setIsError(true);
      }
    } else {
      setResetSearchCache(true);
    }
  }

  async function onSetPickupDropoff(values) {
    const { type, ...other } = values;

    const pickupDropoff = (type === '') ? 'dropOff' : 'pickup';
    let pickupFrom = null;

    if (pickupDropoff === 'pickup') {
      pickupFrom = { type };
      pickupFrom.address = (type === 'sender')
        ? buildAddressObject(shipmentOrigin.address)
        : buildAddressObject({
          ...other,
          name: shipmentOrigin.address.name,
          phone: shipmentOrigin.address.phone,
        });
    }

    const payload = {
      pickupDropoff,
      ...(pickupFrom) && { pickupFrom },
    };

    try {
      await submitFormToApi(payload);
      openNextPanel();
    } catch (e) {
      //
    }
  }

  return (
    <>
      <div className={classes.root}>
        <CustomAppBar
          {...commonProps}
          onChange={onTabChange}
          classes={{
            appBar: classes.appBar,
            tabIndicator: classes.tabIndicator,
            tab: classes.tab,
            tabSelected: classes.tabSelected,
          }}
        />
        <CustomDefaultSender
          {...commonProps}
          defaultSender={defaultSender}
          // classes={{
          //   noResults: classes.noResults,
          // }}
          classes={NEW_ORDER_SENDER_RECIPIENT_STYLE()}
        />
        <CustomContacts
          {...commonProps}
          selectedForms={selectedForms}
          resetSearchCache={resetSearchCache}
          setResetSearchCache={setResetSearchCache}
          classes={NEW_ORDER_SENDER_RECIPIENT_STYLE()}
        />
        <CustomEnterAddress
          {...commonProps}
          selectedForms={selectedForms}
          editAddress={editAddress}
          classes={NEW_ORDER_SENDER_RECIPIENT_STYLE()}
        />
        <CustomPickupOptions
          {...commonProps}
          shipmentPickupFrom={shipmentPickupFrom}
          onSetPickupDropoff={onSetPickupDropoff}
          freightType={shipment?.freightType}
        />
      </div>
      <NewOrderErrorDialog
        open={isError}
        errorContent={errorContent}
        classes={NEW_ORDER_SENDER_RECIPIENT_STYLE()}
        onClose={() => {
          setIsError(false);
          setErrorContent(null);
        }}
      />
    </>
  );
}

SenderTabs.propTypes = {
  shipmentId: PropTypes.string.isRequired,
  selectedForms: PropTypes.shape({
    parent: PropTypes.string.isRequired,
    child: PropTypes.string.isRequired,
  }).isRequired,
  submitFormToApi: PropTypes.func.isRequired,
};
