import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid, Typography, Button, CircularProgress,
} from '@material-ui/core';
import { css } from 'emotion';
import AssignmentIcon from '@material-ui/icons/AssignmentTurnedInOutlined';
import * as colors from '../../styles/colors';
import { formatDateLong, formatDateShort } from '../../utils/helpers';
import BlockedPanelDisplay from './PanelWarning';
import UserAvatar from './UserAvatar';

export function TaskAssignedToUser({
  task, preReqsComplete, TaskIcon, children,
  blockingMessage,
}) {
  const {
    createdAt, expiresAt, createdBy,
  } = task;
  const { fullName, account, imageUrl } = createdBy;
  const userInitials = fullName.split(' ').map((name) => (name[0])).join('');

  const classes = {
    taskAssignedText: css`
        color:${colors.white};
        font-size:14px;
    `,
    taskAssignedWrap: css`
        border: 1px solid ${colors.lightBackgroundGrey};
        border-radius: 5px;
        background: ${colors.darkBlueBackground};
        color: ${colors.white};
        padding: 30px 20px;
    `,
    nameChip: css`
        padding:5px 10px;
        border: 1px solid ${colors.secondaryButton};
        background: ${colors.newOrderFormBackground};
        border-radius: calc(.5em + 10px);
    `,
    iconRoot: css`
        height: 30px;
        width: 30px;
    `,
    icon: css`
        margin: 0 15px
    `,
    createdDate: css`
        font-weight:500
    `,
    dueDate: css`
      font-size:14px;
      color:${colors.yellow};
      font-weight:500;
    `,
    dueDateWrap: css`
      margin-top:5px;
    `,
    errorIconRoot: css`
      color: ${colors.errorRed}
    `,
    errorIcon: css`
      margin-right: 20px
    `,
    capitalize: css`
      text-transform: capitalize;
    `,
  };
  return (
    <Grid style={{ zIndex: 1 }} item container direction="column">
      {!preReqsComplete && (
        <BlockedPanelDisplay message={blockingMessage} />
      )}
      <Grid item container className={classes.taskAssignedWrap} justify="center" alignItems="center">
        <Grid item container wrap="nowrap">
          <Grid item>
            {TaskIcon ? <TaskIcon className={classes.icon} classes={{ root: classes.iconRoot }} /> : <AssignmentIcon className={classes.icon} classes={{ root: classes.iconRoot }} /> }
          </Grid>
          <Grid item container direction="column">
            <Grid item container alignItems="center">
              <Grid item className={classes.nameChip}>
                {`${fullName}`}
              </Grid>
              {account && (
                <>
                  <Typography className={classes.taskAssignedText}>
                    &nbsp;
                    from
                    &nbsp;
                  </Typography>
                  <Grid item className={classes.nameChip}>
                    <Typography className={classes.capitalize}>
                      {`${account}`}
                    </Typography>
                  </Grid>
                </>
              )}
              <Typography className={classes.taskAssignedText}>
                &nbsp;
                assigned this to you on
              </Typography>
              <Typography className={classes.taskAssignedText}>
              &nbsp;
                <span className={classes.createdDate}>{`${formatDateLong(createdAt)}`}</span>
              </Typography>
            </Grid>
            <Typography className={`${classes.taskAssignedText} ${classes.dueDateWrap}`}>
              Due Date:
              {' '}
              <span className={classes.dueDate}>
                {`${formatDateShort(expiresAt)}`}
              </span>
            </Typography>
          </Grid>
        </Grid>
        {children && (
        <Grid item container>
          {children}
        </Grid>
        )}
        <Grid item container justify="flex-end">
          <UserAvatar src={imageUrl} alt={userInitials} />
        </Grid>
      </Grid>
    </Grid>
  );
}

TaskAssignedToUser.propTypes = {
  task: PropTypes.shape({
    assignedTo: PropTypes.shape({
      email: PropTypes.string,
      userId: PropTypes.string,
      account: PropTypes.string,
      fullName: PropTypes.string,
      imageUrl: PropTypes.string,
    }).isRequired,
    createdBy: PropTypes.shape({
      email: PropTypes.string,
      userId: PropTypes.string,
      account: PropTypes.string,
      fullName: PropTypes.string,
      imageUrl: PropTypes.string,
    }).isRequired,
    expiresAt: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
  }).isRequired,
  TaskIcon: PropTypes.func,
  preReqsComplete: PropTypes.bool.isRequired,
  children: PropTypes.element,
};

TaskAssignedToUser.defaultProps = {
  children: null,
  TaskIcon: null,
};

export function TaskAssignedByUser(props) {
  const {
    task, revokeTask, TaskIcon, disableRevoke, rerunCompliance, disableRerun,
  } = props;
  const {
    assignedTo, createdAt, expiresAt,
  } = task;

  const [revoking, setRevoking] = React.useState(false);
  const [rerun, setRerun] = React.useState(false);

  const onRevokeTask = async () => {
    setRevoking(true);
    await revokeTask().catch(() => setRevoking(false));
    setRevoking(false);
  };
  const onRerun = async () => {
    const payload = {
      type: 'load',
    };

    try {
      setRerun(true);
      await rerunCompliance(payload);
      setRerun(false);
    } catch (e) {
      setRerun(false);
    }
  };

  const { fullName, account, imageUrl } = assignedTo;

  const userInitials = fullName.split(' ').map((name) => (name[0])).join('');

  const classes = {
    taskAssignedText: css`
        color:${colors.white};
        font-size:14px;
    `,
    taskAssignedWrap: css`
        border: 1px solid ${colors.lightBackgroundGrey};
        border-radius: 5px;
        background: ${colors.darkBlueBackground};
        color: ${colors.white};
        padding: 30px 20px;
    `,
    nameChip: css`
        padding:5px 10px;
        border: 1px solid ${colors.secondaryButton};
        background: ${colors.newOrderFormBackground};
        border-radius: calc(.5em + 10px);
    `,
    iconRoot: css`
        height: 30px;
        width: 30px;
    `,
    icon: css`
        margin: 0 15px
    `,
    createdDate: css`
        font-weight:500
    `,
    dueDate: css`
      font-size:14px;
      color:${colors.yellow};
      font-weight:500;
    `,
    dueDateWrap: css`
      margin-top:5px;
    `,
    errorIconRoot: css`
      color: ${colors.errorRed}
    `,
    errorIcon: css`
      margin-right: 20px
    `,
    revokeButton: css`
      padding: 8px 18px;
      border-radius: 8px;
      border: 1px solid ${colors.actionButtonText};
      margin-top:10px;
    `,
    revokeButtonText: css`
      color: ${colors.secondaryButton};
      font-weight: 700;
      text-transform: uppercase;
      font-size: 14px;
      letter-spacing: 1.2px
    `,
    revokingText: css`
      color: ${colors.white}
    `,
    revoking: css`
      margin-top:10px
    `,
    capitalize: css`
    text-transform: capitalize;
  `,
  };
  return (
    <Grid container justify="center" alignItems="center" direction="column">
      <Grid
        item
        container
        className={classes.taskAssignedWrap}
        justify="center"
        alignItems="center"
      >
        <Grid item container wrap="nowrap">
          <Grid item>
            {TaskIcon ? (
              <TaskIcon
                className={classes.icon}
                classes={{ root: classes.iconRoot }}
              />
            ) : (
              <AssignmentIcon
                className={classes.icon}
                classes={{ root: classes.iconRoot }}
              />
            )}
          </Grid>
          <Grid item container direction="column">
            <Grid item container alignItems="center">
              <Typography className={classes.taskAssignedText}>
                This task was assigned to &nbsp;
              </Typography>
              <Grid item className={classes.nameChip}>
                {`${fullName}`}
              </Grid>
              {account && (
                <>
                  <Typography className={classes.taskAssignedText}>
                    &nbsp; from &nbsp;
                  </Typography>
                  <Grid item className={classes.nameChip}>
                    <Typography className={classes.capitalize}>
                      {`${account}`}
                    </Typography>
                  </Grid>
                </>
              )}
              <Typography className={classes.taskAssignedText}>
                &nbsp; on
              </Typography>
              <Typography className={classes.taskAssignedText}>
                &nbsp;
                <span className={classes.createdDate}>
                  {`${formatDateLong(
                    createdAt,
                  )}`}

                </span>
              </Typography>
            </Grid>
            <Typography
              className={`${classes.taskAssignedText} ${classes.dueDateWrap}`}
            >
              Due Date:
              {' '}
              <span className={classes.dueDate}>
                {`${formatDateShort(expiresAt)}`}
              </span>
            </Typography>
          </Grid>
        </Grid>
        <Grid item container justify="flex-end">
          <UserAvatar src={imageUrl} alt={userInitials} />
        </Grid>
      </Grid>
      <Grid container direction="row" justify="space-between">
        {rerun ? (
          <Grid
            item
            container
            direction="column"
            alignItems="center"
            className={classes.revoking}
          >
            <CircularProgress color="secondary" />
            <Typography className={classes.revokingText}>
              Rerunning Compliance...
            </Typography>
          </Grid>
        ) : (
          !disableRerun && (
            <Grid item>
              <Button
                className={classes.revokeButton}
                onClick={onRerun}
                disabled={revoking || rerun}
              >
                <Typography className={classes.revokeButtonText}>
                  Resubmit
                </Typography>
              </Button>
            </Grid>
          )
        )}
        {revoking ? (
          <Grid
            item
            container
            direction="column"
            alignItems="center"
            className={classes.revoking}
          >
            <CircularProgress color="secondary" />
            <Typography className={classes.revokingText}>
              Revoking task...
            </Typography>
          </Grid>
        ) : (
          !disableRevoke && (
            <Grid item>
              <Button
                className={classes.revokeButton}
                onClick={onRevokeTask}
                disabled={revoking || rerun}
              >
                <Typography className={classes.revokeButtonText}>
                  REVOKE TASK
                </Typography>
              </Button>
            </Grid>
          )
        )}
      </Grid>
    </Grid>
  );
}

TaskAssignedByUser.propTypes = {
  task: PropTypes.shape({
    assignedTo: PropTypes.shape({
      email: PropTypes.string,
      userId: PropTypes.string,
      account: PropTypes.string,
      fullName: PropTypes.string,
      imageUrl: PropTypes.string,
    }).isRequired,
    createdBy: PropTypes.shape({
      email: PropTypes.string,
      userId: PropTypes.string,
      account: PropTypes.string,
      fullName: PropTypes.string,
      imageUrl: PropTypes.string,
    }).isRequired,
    expiresAt: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
  }).isRequired,
  revokeTask: PropTypes.func.isRequired,
  TaskIcon: PropTypes.func,
  disableRevoke: PropTypes.bool.isRequired,
  rerunCompliance: PropTypes.func.isRequired,
  disableRerun: PropTypes.bool.isRequired,
};

TaskAssignedByUser.defaultProps = {
  TaskIcon: null,
};
