import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { MenuItem, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Field } from 'formik';
import { PhoneNumberFormik } from '../../components/common/PhoneNumber';
import { CustomCheckbox } from '../../components/common/InputComponents';
import CustomSelectTextField from '../../components/common/CustomSelectTextField';
import NewOrderCustomTextField from '../../components/common/NewOrderCustomTextField';
import NotificationTypes from './Notifications/Types';
import CarrierTypes from './Carriers/Types';
import * as colors from '../../styles/colors';
import {
  FIELD_TYPE_BOOL,
  FIELD_TYPE_DROPDOWN,
  FIELD_TYPE_EMAIL,
  FIELD_TYPE_LINK,
  FIELD_TYPE_PHONE,
  FIELD_TYPE_TEXT,
} from '../../clientConstants';
import { loadCountryStates, useMiscDispatch } from '../../context/miscDataContext';
import {
  AVAILABLE_CARRIERS_FIELD_NAME, AVAILABLE_CARRIERS_FIELD_TYPE, NOTIFICATION_TYPE_FIELD_NAME, PRICING_MODEL_FIELD_NAME,
} from './constants';

const useStyles = makeStyles({
  header: {
    color: colors.white,
    fontSize: '16px',
    fontWeight: 500,
    letterSpacing: '0.25px',
    textTransform: 'capitalize',
    margin: '32px 0',
  },
  formItem: {
    marginBottom: '24px',
  },
  inputField: {
    width: '100%',
  },
  loading: {
    marginBottom: '24px',
  },
});

const useCustomCheckboxStyles = makeStyles({
  root: {
    '&$focused': {
      color: colors.white,
    },
    '&$disabled': {
      color: colors.textDarkGrey,
    },
    '&$error': {
      color: colors.white,
    },
    color: colors.white,
    fontWeight: 425,
  },
  checkboxLabel: {
    color: colors.textLightGrey,
    fontSize: 14.75,
    fontWeight: 425,
  },
  checkboxPrimary: {
    '&$disabled': {
      color: colors.white,
    },
    color: colors.white,
  },
  disabled: {},
});

const AccountManagementFormFields = ({
  fields,
  values,
  countries,
  states,
  notificationTypes,
  carriers,
  pricingModels,
  currencyUnits,
  isSubmitting,
  setFieldValue,
  setFieldTouched,
}) => {
  const miscDispatch = useMiscDispatch();
  const classes = useStyles();
  const customCheckboxStyles = useCustomCheckboxStyles();

  useEffect(() => {
    if (!isEmpty(values.country) && !isEmpty(countries)) {
      loadCountryStates(miscDispatch, values.country);
    }
  }, [values?.country]);

  const isSubtitle = (type) => isEmpty(type);
  const isBoolFieldType = (type) => type === FIELD_TYPE_BOOL;
  const isPhoneFieldType = (type) => type === FIELD_TYPE_PHONE;
  const isDropdownFieldType = (type) => type === FIELD_TYPE_DROPDOWN;
  const isNotificationFieldType = (type) => type === NOTIFICATION_TYPE_FIELD_NAME;
  const isCarrierFieldType = (type) => type === AVAILABLE_CARRIERS_FIELD_TYPE;

  return (
    fields.map((field, index) => (
      <Grid key={`field-grid-${isSubtitle(field.type) ? index : field.name}`} className={classes.formItem} item xs={field.name.includes('fee') || field.name.includes('currency') ? 6 : 12}>
        {isSubtitle(field.type) && (
        <Typography className={classes.header}>{field.label}</Typography>
        )}
        {isBoolFieldType(field.type) && (
          <CustomCheckbox
            name={field.name}
            label={field.label}
            disabled={isSubmitting}
            classes={
              customCheckboxStyles
            }
          />
        )}
        {isPhoneFieldType(field.type) && (
          <PhoneNumberFormik
            name={field.name}
            label={field.label}
            countryName={field.countryName || 'US'}
          />
        )}
        {isDropdownFieldType(field.type) && field.name === 'country' && (
          <Field
            component={CustomSelectTextField}
            name={field.name}
            type="text"
            label={field.label}
            disabled={isSubmitting}
            className={classes.inputField}
            InputLabelProps={{ for: field.name }}
            InputProps={{ id: field.name }}
          >
            {
              !isEmpty(countries) && countries.map((country) => (
                <MenuItem value={country.countryCode}>
                  {country.description}
                </MenuItem>
              ))
            }
          </Field>
        )}
        {isDropdownFieldType(field.type) && field.name === 'state' && (
          <Field
            component={CustomSelectTextField}
            name={field.name}
            type="text"
            label={field.label}
            disabled={isSubmitting}
            className={classes.inputField}
            InputLabelProps={{ for: field.name }}
            InputProps={{ id: field.name }}
          >
            {
              !isEmpty(states) && states[values.country] && states[values.country].map((state) => (
                <MenuItem value={state.stateCode}>
                  {state.description}
                </MenuItem>
              ))
            }
          </Field>
        )}
        {isDropdownFieldType(field.type) && field.name === PRICING_MODEL_FIELD_NAME && (
        <Field
          component={CustomSelectTextField}
          name={field.name}
          type="text"
          label={field.label}
          disabled={isSubmitting}
          className={classes.inputField}
          InputLabelProps={{ for: field.name }}
          InputProps={{ id: field.name }}
        >
          {
            !isEmpty(pricingModels) && pricingModels.map((pricingModel) => (
              <MenuItem value={pricingModel.type}>
                {pricingModel.description}
              </MenuItem>
            ))
          }
        </Field>
        )}
        {isDropdownFieldType(field.type) && field.name.includes('currency') && (
        <Field
          component={CustomSelectTextField}
          name={field.name}
          type="text"
          label={field.label}
          style={{ width: '45%', marginLeft: '12px' }}
          disabled={isSubmitting}
          className={classes.inputField}
          InputLabelProps={{ for: field.name }}
          InputProps={{ id: field.name }}
        >
          {
            !isEmpty(currencyUnits) && currencyUnits.map((unit) => (
              <MenuItem value={unit.type}>
                {unit.description}
              </MenuItem>
            ))
          }
        </Field>
        )}
        {isNotificationFieldType(field.type) && (
          <NotificationTypes
            notificationKey={field.name}
            notificationTypes={notificationTypes}
            values={values}
            isEditing
            setFieldTouched={setFieldTouched}
          />
        )}
        {isCarrierFieldType(field.type) && (
        <CarrierTypes
          carriers={carriers}
          accountAvailableCarriers={get(values, AVAILABLE_CARRIERS_FIELD_NAME, null)}
          isEditing
        />
        )}
        {
        !isSubtitle(field.type)
        && !isNotificationFieldType(field.type)
        && !isCarrierFieldType(field.type)
        && !isBoolFieldType(field.type)
        && !isDropdownFieldType(field.type)
        && !isPhoneFieldType(field.type) && (
          <Field
            className={classes.inputField}
            disabled={isSubmitting}
            type="text"
            name={field.name}
            label={field.label}
            component={NewOrderCustomTextField}
            InputLabelProps={{ for: field.name }}
            InputProps={{ id: field.name }}
          />
        )
}
      </Grid>
    )));
};

AccountManagementFormFields.defaultProps = {
  countries: [],
  states: null,
  notificationTypes: null,
  carriers: [],
  pricingModels: [],
  currencyUnits: [],
  setFieldValue: null,
  setFieldTouched: null,
};

AccountManagementFormFields.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.oneOf([
      FIELD_TYPE_TEXT,
      FIELD_TYPE_DROPDOWN,
      FIELD_TYPE_BOOL,
      FIELD_TYPE_PHONE,
      FIELD_TYPE_LINK,
      FIELD_TYPE_EMAIL,
    ]),
  })).isRequired,
  values: PropTypes.shape(PropTypes.object).isRequired,
  countries: PropTypes.arrayOf(PropTypes.shape(PropTypes.object)),
  states: PropTypes.shape(PropTypes.object),
  notificationTypes: PropTypes.shape({
    email: PropTypes.arrayOf(PropTypes.shape({
      type: PropTypes.string,
      description: PropTypes.string,
    })),
    sms: PropTypes.arrayOf(PropTypes.shape({
      type: PropTypes.string,
      description: PropTypes.string,
    })),
  }),
  carriers: PropTypes.arrayOf(
    PropTypes.shape({
      carrierCode: PropTypes.string.isRequired,
      displayName: PropTypes.string.isRequired,
      isDomestic: PropTypes.bool.isRequired,
      isInternational: PropTypes.bool.isRequired,
      isHazmat: PropTypes.bool.isRequired,
    }),
  ),
  pricingModels: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string,
    description: PropTypes.string,
  })),
  currencyUnits: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string,
    description: PropTypes.string,
  })),
  isSubmitting: PropTypes.bool.isRequired,
  setFieldValue: PropTypes.func,
  setFieldTouched: PropTypes.func,
};

export default memo(AccountManagementFormFields);
