import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import React from 'react';
import axios from 'axios';
import { Button, Grid, Typography } from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import LoginTextField from '../components/LoginTextField';
import * as colors from '../styles/colors';

const styles = {
  root: {
    height: '100%',
  },
  title: {
    color: colors.white,
    fontSize: 24,
    fontWeight: 500,
    paddingBottom: 22,
  },
  subHeader: {
    color: 'rgba(255,255,255,0.87)',
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0.15,
  },
  formRoot: {
    flexGrow: 1,
    height: 'auto',
  },
  contentContainer: {
    paddingTop: 25,
  },
  gridItem: {
    zIndex: 200,
  },
  button: {
    fontWeight: 'bold',
    borderRadius: 8,
    backgroundColor: '#2A79D4',
    width: '10%',
    minWidth: 96,
  },
  formContainer: {
    marginTop: 20,
  },
  textField: {
    width: '37%',
    minWidth: 380,
    minHeight: 76,
    paddingBottom: 5,
  },
};

const useStyles = makeStyles(styles);

export default function ForgotPassword() {
  const classes = useStyles();
  const subHeaderContent = "It's okay, we all forget from time to time. Enter your email associated with your account and we'll send you an email shortly.";
  const [redirectToConfirmationScreen, setRedirectToConfirmationScreen] = React.useState(false);

  // submit forgot password request then redirect to /login
  async function handleSubmit(values) {
    try {
      const options = {
        method: 'post',
        url: '/forgot-password',
        data: {
          email: values.email,
          language: 'en',
        },
        mode: 'no-cors',
      };

      await axios(options);
      setRedirectToConfirmationScreen(true);
    } catch (err) {
      alert(err.response.data);
    }
  }

  return (
    <div className={classes.root}>
      {
        redirectToConfirmationScreen && <Redirect push to="/login/forgot-password-confirmation" />
      }
      <Grid
        container
        className={classes.contentContainer}
        justify="flex-start"
        direction="column"
      >
        <Grid className={classes.gridItem} item>
          <Typography className={classes.title}>Forgot your password?</Typography>
        </Grid>
        <Grid className={classes.gridItem} item>
          <Typography className={classes.subHeader}>
            {subHeaderContent}
          </Typography>
        </Grid>
        <Grid
          container
          item
          xs={12}
          className={classes.formContainer}
          justify="flex-start"
          direction="column"
        >
          <Formik
            validateOnChange
            validateOnBlur={false}
            initialValues={{
              email: '',
            }}
            onSubmit={(values, { setSubmitting }) => {
              handleSubmit(values);
              setSubmitting(false);
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email('Invalid email')
                .required('Required'),
            })}
            render={({ submitForm, isSubmitting }) => (
              <div className={classes.formRoot}>
                <Form>
                  <Grid container item xs={12}>
                    <Grid item xs={12}>
                      <Field
                        className={classes.textField}
                        name="email"
                        type="email"
                        variant="outlined"
                        label="Email"
                        component={LoginTextField}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        className={classes.button}
                        color="secondary"
                        variant="contained"
                        disabled={isSubmitting}
                        onClick={submitForm}
                      >
                        Send
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              </div>
            )}
          />
        </Grid>
      </Grid>
    </div>
  );
}
