import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Slide,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Field, Form, Formik } from 'formik';
import * as colors from '../styles/colors';
import ScrollWindow from './common/ScrollWindow';
import NewOrderCustomTextField from './common/NewOrderCustomTextField';
import {
  APHIS_IMPORT_TRANS_PERMIT_LICENSE_KEY,
  CDC_SELECT_AGENT_LICENSE_KEY,
  DOA_SELECT_AGENT_LICENSE_KEY,
  FORM_FIELDS,
  FORM_FIELDS_HAZMAT,
  PPQ_SELECT_AGENT_LICENSE_KEY,
  USDA_IMPORT_TRANS_PERMIT_LICENSE_KEY,
} from './ItemProductDetails/itemDetailsConstants';
import CustomSelectTextField from './common/CustomSelectTextField';
import { useItemProductDetailsFormStyles } from './ItemProductDetails/itemDetailsStyles';
import { addMaterial, editMaterial } from '../utils/materialsClient';
import { CustomCheckbox } from './common/InputComponents';
import { HAZMAT_BIOLOGICAL } from '../clientConstants';

const STATE_FIELD = 'state';

const EXTRA_FIELDS = {
  shipClassMat: {
    key: 'shipClassMat',
    label: 'Ship Mat Class',
  },
  eccnMat: {
    key: 'eccnMat',
    label: 'ECCN No. II',
  },
  active: {
    key: 'isActive',
    label: 'Active',
  },
};

const RADIO_BUTTONS = [
  {
    fieldName: STATE_FIELD,
    label: 'State',
  },
  {
    fieldName: CDC_SELECT_AGENT_LICENSE_KEY,
    label: 'CDC Select Agent',
  },
  {
    fieldName: DOA_SELECT_AGENT_LICENSE_KEY,
    label: 'DOA Select Agent',
  },
  {
    fieldName: PPQ_SELECT_AGENT_LICENSE_KEY,
    label: 'PPQ Select Agent',
  },
  {
    fieldName: USDA_IMPORT_TRANS_PERMIT_LICENSE_KEY,
    label: 'USDA Import Trans Permit',
  },
  {
    fieldName: APHIS_IMPORT_TRANS_PERMIT_LICENSE_KEY,
    label: 'APHIS Import Trans Permit',
  },
];

const RADIO_BUTTON_OPTIONS = [
  {
    label: 'Yes',
    value: true,
  },
  {
    label: 'No',
    value: false,
  },
];

const COMMON_BUTTON_STYLES = {
  minWidth: 114,
  height: 36,
  fontWeight: 'bold',
  borderRadius: 8,
};

const useStyles = makeStyles(() => ({
  dialogTitle: {
    paddingLeft: 30,
    marginBottom: 15,
  },
  paperFullScreen: {
    width: '40%',
    height: '92%',
    position: 'absolute',
    right: 0,
    backgroundColor: '#1E202E',
    marginTop: '90px',
  },
  dialogTitleText: {
    fontSize: 25,
    color: colors.white,
    fontWeight: 500,
  },
  dialogContent: {
    padding: '0 0',
    overflow: 'hidden',
    height: 'inherit',
  },
  dialogContentCard: {
    backgroundColor: colors.darkBlueBackground,
    padding: '20px 15px',
  },
  innerDialogContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '0 30px',
  },
  profileManagementFormOuterContainer: {
    backgroundColor: 'transparent !important',
    padding: '0 !important',
  },
  profileManagementFormInnerContainer: {
    padding: '0 !important',
  },
  header: {
    fontSize: 20,
    fontWeight: 500,
    color: colors.white,
    letterSpacing: '0.25px',
    marginBottom: '1rem',
  },
  fieldContainer: {
    marginBottom: '12px',
  },
  radio: {
    root: {
      '&$disabled': {
        color: colors.textDarkGrey,
      },
      color: colors.white,
    },
  },
  disabledText: {
    color: colors.disabledGrey,
  },
  buttonsContainer: {
    width: 270,
    margin: '30px 0px',
  },
  primaryButton: {
    ...COMMON_BUTTON_STYLES,
    backgroundColor: colors.buttonBackgroundBlue,
    color: colors.white,
  },
  secondaryButton: {
    ...COMMON_BUTTON_STYLES,
    border: `1px solid ${colors.buttonBackgroundBlue}`,
    color: colors.secondaryButton,
  },
  progress: {
    marginTop: '15px',
  },
}));

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="left" ref={ref} {...props} />
));

const BiologicalMaterialModal = ({
  open,
  material,
  categories,
  shipClasses,
  onSubmit,
  onClose,
}) => {
  const classes = useStyles();
  const productDetailsClasses = useItemProductDetailsFormStyles();
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState('');

  const handleClose = () => {
    setError('');
    onClose(-1, null);
  };

  const materialAction = () => (material ? editMaterial : addMaterial);

  const handleSubmit = (values) => {
    setIsSaving(true);
    setError('');
    const isNewMaterial = !material;
    const payload = isNewMaterial ? values : { ...material, ...values };
    const fn = materialAction();

    fn(payload, undefined, HAZMAT_BIOLOGICAL)
      .then((response) => {
        setIsSaving(false);

        onSubmit(response, isNewMaterial);

        handleClose();
      })
      .catch((e) => {
        let errorMessage = null;
        try {
          errorMessage = JSON.parse(e.message).error;
        } catch (err) {
          errorMessage = `Unexpected error: ${e}`;
        }

        setIsSaving(false);
        setError(errorMessage);
      });
  };

  return (
    <Dialog
      open={open}
      classes={{ paperFullScreen: classes.paperFullScreen }}
      TransitionComponent={Transition}
      scroll="paper"
      fullScreen
      disablePortal
      onClose={handleClose}
    >
      <DialogTitle className={classes.dialogTitle}>
        <Typography className={classes.dialogTitleText}>
          {isEmpty(material) ? 'Add' : 'Edit'}
          {' '}
          Biological Material
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <ScrollWindow>
          <div
            direction="column"
            className={classes.innerDialogContentContainer}
          >
            <Formik
              validationSchema={Yup.object().shape({
                [FORM_FIELDS_HAZMAT.itemName.key]:
                  Yup.string().required('Required'),
                [FORM_FIELDS_HAZMAT.itemCategory.key]:
                  Yup.string().required('Required'),
                [FORM_FIELDS_HAZMAT.shipClass.key]:
                  Yup.string().required('Required'),
                [EXTRA_FIELDS.shipClassMat.key]:
                  Yup.string().required('Required'),
                [FORM_FIELDS_HAZMAT.eccnNo.key]:
                  Yup.string().required('Required'),
                [EXTRA_FIELDS.eccnMat.key]: Yup.string().required('Required'),
                [FORM_FIELDS_HAZMAT.harmonizedCode.key]:
                  Yup.string().required('Required'),
                [STATE_FIELD]: Yup.bool().required('Required'),
                [CDC_SELECT_AGENT_LICENSE_KEY]: Yup.bool().required('Required'),
                [DOA_SELECT_AGENT_LICENSE_KEY]: Yup.bool().required('Required'),
                [PPQ_SELECT_AGENT_LICENSE_KEY]: Yup.bool().required('Required'),
                [USDA_IMPORT_TRANS_PERMIT_LICENSE_KEY]:
                  Yup.bool().required('Required'),
                [APHIS_IMPORT_TRANS_PERMIT_LICENSE_KEY]:
                  Yup.bool().required('Required'),
              })}
              initialValues={{
                [FORM_FIELDS_HAZMAT.itemId.key]: get(
                  material,
                  FORM_FIELDS.itemId.key,
                  '',
                ),
                [FORM_FIELDS_HAZMAT.itemName.key]: get(
                  material,
                  FORM_FIELDS_HAZMAT.itemName.key,
                  '',
                ),
                [FORM_FIELDS_HAZMAT.itemCategory.key]: get(
                  material,
                  FORM_FIELDS_HAZMAT.itemCategory.key,
                  '',
                ),
                [FORM_FIELDS_HAZMAT.shipClass.key]: get(
                  material,
                  FORM_FIELDS_HAZMAT.shipClass.key,
                  '',
                ),
                [EXTRA_FIELDS.shipClassMat.key]: get(
                  material,
                  EXTRA_FIELDS.shipClassMat.key,
                  '',
                ),
                [FORM_FIELDS_HAZMAT.eccnNo.key]: get(
                  material,
                  FORM_FIELDS_HAZMAT.eccnNo.key,
                  '',
                ),
                [EXTRA_FIELDS.eccnMat.key]: get(
                  material,
                  EXTRA_FIELDS.eccnMat.key,
                  '',
                ),
                [FORM_FIELDS_HAZMAT.harmonizedCode.key]: get(
                  material,
                  FORM_FIELDS_HAZMAT.harmonizedCode.key,
                  '',
                ),
                [STATE_FIELD]: get(material, STATE_FIELD, false),
                [EXTRA_FIELDS.active.key]: get(
                  material,
                  EXTRA_FIELDS.active.key,
                  true,
                ),
                [CDC_SELECT_AGENT_LICENSE_KEY]: get(
                  material,
                  CDC_SELECT_AGENT_LICENSE_KEY,
                  false,
                ),
                [DOA_SELECT_AGENT_LICENSE_KEY]: get(
                  material,
                  DOA_SELECT_AGENT_LICENSE_KEY,
                  false,
                ),
                [PPQ_SELECT_AGENT_LICENSE_KEY]: get(
                  material,
                  PPQ_SELECT_AGENT_LICENSE_KEY,
                  false,
                ),
                [USDA_IMPORT_TRANS_PERMIT_LICENSE_KEY]: get(
                  material,
                  USDA_IMPORT_TRANS_PERMIT_LICENSE_KEY,
                  false,
                ),
                [APHIS_IMPORT_TRANS_PERMIT_LICENSE_KEY]: get(
                  material,
                  APHIS_IMPORT_TRANS_PERMIT_LICENSE_KEY,
                  false,
                ),
              }}
              onSubmit={handleSubmit}
              enableReinitialize
            >
              {(formikProps) => (
                <>
                  <Form className={classes.dialogContentCard}>
                    <Grid className={classes.fieldContainer} item>
                      <Field
                        type="text"
                        style={{ width: '100%' }}
                        name={FORM_FIELDS.itemId.key}
                        label={FORM_FIELDS.itemId.label}
                        component={NewOrderCustomTextField}
                        disabled
                      />
                    </Grid>
                    <Grid className={classes.fieldContainer} item>
                      <Field
                        type="text"
                        style={{ width: '100%' }}
                        name={FORM_FIELDS_HAZMAT.itemName.key}
                        label={FORM_FIELDS_HAZMAT.itemName.label}
                        component={NewOrderCustomTextField}
                      />
                    </Grid>
                    <Grid className={classes.fieldContainer} item>
                      <Field
                        type="text"
                        style={{ width: '100%' }}
                        name={FORM_FIELDS_HAZMAT.itemCategory.key}
                        label={FORM_FIELDS_HAZMAT.itemCategory.label}
                        component={CustomSelectTextField}
                      >
                        {categories
                          && categories.map((option) => (
                            <MenuItem
                              key={option.itemCategory}
                              value={option.itemCategory}
                            >
                              {option.itemCategory}
                            </MenuItem>
                          ))}
                      </Field>
                    </Grid>
                    <Grid className={classes.fieldContainer} item>
                      <Field
                        type="text"
                        style={{ width: '100%' }}
                        name={FORM_FIELDS_HAZMAT.shipClass.key}
                        label={FORM_FIELDS_HAZMAT.shipClass.label}
                        component={CustomSelectTextField}
                      >
                        {shipClasses
                          && shipClasses.map((option) => (
                            <MenuItem
                              key={option.key}
                              value={option.description}
                            >
                              {option.description}
                            </MenuItem>
                          ))}
                      </Field>
                    </Grid>
                    <Grid className={classes.fieldContainer} item>
                      <Field
                        type="text"
                        style={{ width: '100%' }}
                        name={EXTRA_FIELDS.shipClassMat.key}
                        label={EXTRA_FIELDS.shipClassMat.label}
                        component={CustomSelectTextField}
                      >
                        {shipClasses
                          && shipClasses.map((option) => (
                            <MenuItem
                              key={option.key}
                              value={option.description}
                            >
                              {option.description}
                            </MenuItem>
                          ))}
                      </Field>
                    </Grid>
                    <Grid
                      className={classes.fieldContainer}
                      container
                      item
                      spacing={2}
                    >
                      <Grid item xs={6}>
                        <Field
                          type="text"
                          style={{ width: '100%' }}
                          name={FORM_FIELDS_HAZMAT.eccnNo.key}
                          label={FORM_FIELDS_HAZMAT.eccnNo.label}
                          component={NewOrderCustomTextField}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          type="text"
                          style={{ width: '100%' }}
                          name={EXTRA_FIELDS.eccnMat.key}
                          label={EXTRA_FIELDS.eccnMat.label}
                          component={NewOrderCustomTextField}
                        />
                      </Grid>
                    </Grid>
                    <Grid className={classes.fieldContainer} item>
                      <Field
                        type="text"
                        style={{ width: '100%' }}
                        name={FORM_FIELDS_HAZMAT.harmonizedCode.key}
                        label={FORM_FIELDS_HAZMAT.harmonizedCode.label}
                        component={NewOrderCustomTextField}
                      />
                    </Grid>
                    {RADIO_BUTTONS.map((button) => (
                      <Grid
                        className={classes.fieldContainer}
                        container
                        item
                        alignItems="center"
                      >
                        <Grid item xs={4}>
                          <Typography
                            color="primary"
                            className={productDetailsClasses.formFieldHeader}
                          >
                            {button.label}
                          </Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <Grid container item>
                            <RadioGroup
                              key={`${button.fieldName}`}
                              aria-label={`${button.label}}`}
                              name={button.fieldName}
                              value={formikProps?.values[button.fieldName]}
                              row
                              onChange={(e) => {
                                formikProps.setFieldValue(
                                  button.fieldName,
                                  e.target.value === 'true',
                                );
                              }}
                            >
                              {RADIO_BUTTON_OPTIONS.map((option) => (

                                <FormControlLabel
                                  style={{ padding: '0 10px' }}
                                  value={option.value}
                                  control={<Radio className={classes.radio} />}
                                  label={(
                                    <Typography color="primary">
                                      {option.label}
                                    </Typography>
                                  )}
                                />
                              ))}
                            </RadioGroup>
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}
                    <Grid className={classes.fieldContainer} item>
                      <CustomCheckbox
                        name={EXTRA_FIELDS.active.key}
                        label={(
                          <Typography
                            color="primary"
                            className={classes.eventCheckboxLabel}
                          >
                            Set as active
                          </Typography>
                        )}
                      />
                    </Grid>
                    <Grid className={classes.fieldContainer} item>
                      <Typography>
                        The information entered in the table is not validated to
                        be accurate. It is the sole responsibility of the user
                        to ensure all applicable final documentation, marks and
                        labels created from this information by the software
                        meets all applicable regulatory requirements. By saving
                        this new material and adding it to your table, you are
                        acknowledging the above statement.
                      </Typography>
                    </Grid>
                    {!isEmpty(error) && (
                      <Grid className={classes.fieldContainer} container item>
                        <Typography color="error">{error}</Typography>
                      </Grid>
                    )}
                  </Form>
                  <Grid container justify="center">
                    {isSaving ? (
                      <CircularProgress
                        color="secondary"
                        className={classes.progress}
                      />
                    ) : (
                      <Grid
                        item
                        container
                        justify="space-between"
                        className={classes.buttonsContainer}
                      >
                        <Button
                          className={classes.secondaryButton}
                          onClick={handleClose}
                        >
                          Cancel
                        </Button>
                        <Button
                          className={classes.primaryButton}
                          onClick={() => formikProps.submitForm()}
                        >
                          Submit
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </>
              )}
            </Formik>
          </div>
        </ScrollWindow>
      </DialogContent>
    </Dialog>
  );
};

BiologicalMaterialModal.defaultProps = {
  open: false,
  material: null,
  categories: [],
  shipClasses: [],
};

BiologicalMaterialModal.propTypes = {
  open: PropTypes.bool,
  material: PropTypes.object,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      itemCategory: PropTypes.string,
    }),
  ),
  shipClasses: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      description: PropTypes.string,
    }),
  ),
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default memo(BiologicalMaterialModal);
