import has from 'lodash/has';
import moment from 'moment';
import client from './apiClient';
import { dummySingleOrder } from './orderClient';
import { DUMMY_FETCH_ALL_TASKS_DATA, DUMMY_FETCH_ALL_TASK_TYPES } from '../dummyData';

function loadAllTasks(options = null) {
  let params = {};

  if (['test', 'development'].includes(process.env.NODE_ENV)) {
    return new Promise((resolve) => {
      setTimeout(() => {
        if (options) {
          const lowerCaseFilter = has(options.filters, 'filter') && options.filters.filter.length >= 2
            ? options.filters.filter.toLowerCase()
            : '';

          resolve(DUMMY_FETCH_ALL_TASKS_DATA
            .filter((item) => item.shipmentId.toLowerCase().includes(lowerCaseFilter))
            .slice(options.offset, options.offset + options.limit));
        } else resolve(DUMMY_FETCH_ALL_TASKS_DATA);
      }, 500);
    });
  }

  if (has(options, 'limit')) params.limit = options.limit;
  if (has(options, 'offset')) params.offset = options.offset;
  if (has(options, 'filters')) params = { ...params, ...options.filters };

  return client('/data/dashboard/task', {
    method: 'get',
    params: params || {},
  });
}

function loadAllTaskTypes() {
  if (['test', 'development'].includes(process.env.NODE_ENV)) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(DUMMY_FETCH_ALL_TASK_TYPES);
      }, 500);
    });
  }

  return client('/data/shipment/task/taskType', { method: 'get' });
}

async function assignDocTask(payload) {
  const { shipmentId, sendInvite } = payload;
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    const assignedShipment = { ...dummySingleOrder.shipments[shipmentId] };
    const response = {
      info: 'Shipment saved successfully',
      shipment: assignedShipment,
    };
    return new Promise((resolve) => {
      setTimeout(() => {
        if (sendInvite) {
          resolve(response);
        }
        // resolve({
        //   sendInvite: true,
        // });
        resolve(response);
      }, 1500);
    });
  }

  return client('/data/shipment/task/assign', { method: 'post', data: payload });
}

async function revokeDocTask(payload) {
  const { shipmentId } = payload;
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    const assignedShipment = { ...dummySingleOrder.shipments[shipmentId] };
    const response = {
      info: 'Shipment saved successfully',
      shipment: assignedShipment,
    };
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(response);
      }, 1500);
    });
  }

  return client('/data/shipment/task/revoke', { method: 'post', data: payload });
}

function assignTask({
  email, expiresAt, shipmentId, sendInvite, taskType,
}) {
  const body = {
    assignedTo: {
      email,
      ...(sendInvite ? { sendInvite } : {}),
    },
    taskType,
    expiresAt,
    shipmentId,

  };
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    const assignedShipment = { ...dummySingleOrder.shipments[shipmentId] };
    assignedShipment[body.taskType] = { ...assignedShipment[body.taskType] };
    const dummyTask = {
      lateAt: '2019-11-28T18:13:00Z',
      status: 'Created',
      createdAt: moment.utc(Date.now()).format(),
      createdBy: {
        email: 'user.shipper@account1.com',
        userId: '1000000000',
        account: 'account1',
        fullName: 'user account1 shipper',
      },
      assignedTo: {
        email: body.assignedTo.email,
        userId: '1000000000',
        account: 'account2',
        fullName: 'Assigned Shipper',
      },
      isAssignedTo: false,
      expiresAt,
    };
    assignedShipment[body.taskType].task = dummyTask;

    const possibleResponses = [
      { info: 'Shipment Saved Successfully', shipment: assignedShipment },
      { sendInvite: true },
    ];
    return Promise.resolve(sendInvite
      ? possibleResponses[0]
      : possibleResponses[Math.min(Math.floor(Math.random() * 10), 1)]);
  }
  return client('/data/shipment/task/assign', { method: 'post', data: body });
}

function acceptTask({ ...data }) {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          info: 'Task Accepted Successfully',
          tasks: DUMMY_FETCH_ALL_TASKS_DATA.map((task) => (task.shipmentId === data.shipmentId
            ? { ...task, status: 'In-Progress' }
            : task)),
        });
      }, 1000);
    });
  }
  return client('/data/shipment/task/accept', { method: 'post', data });
}

function rejectTask({ ...data }) {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          info: 'Task Rejected Successfully',
          tasks: DUMMY_FETCH_ALL_TASKS_DATA.filter((task) => task.shipmentId !== data.shipmentId),
        });
      }, 1000);
    });
  }

  return client('/data/shipment/task/reject', { method: 'post', data });
}

function revokeTask({ shipmentId, taskType }) {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    const returnShipment = {
      ...dummySingleOrder.shipments[shipmentId],
      [taskType]: { ...dummySingleOrder.shipments[shipmentId][taskType] },
    };
    returnShipment[taskType].task = { ...returnShipment[taskType].task, status: 'Revoked' };

    return { info: 'Successfully saved shipment', shipment: returnShipment };
  }
  return client('/data/shipment/task/revoke', { method: 'post', data: { shipmentId, taskType } });
}

export default {
  loadAllTasks,
  loadAllTaskTypes,
  assignTask,
  acceptTask,
  rejectTask,
  revokeTask,
  assignDocTask,
  revokeDocTask,
};
