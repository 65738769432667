import React from 'react';
import { Grid, CircularProgress, Typography, Button } from '@material-ui/core';
import { css } from 'emotion';
import * as colors from '../styles/colors';

export function RerunComplianceButton(props) {
  const classes = {
    revokeButton: css`
      padding: 8px 18px;
      border-radius: 8px;
      border: 1px solid ${colors.actionButtonText};
      margin-top:10px;
    `,
    revokeButtonText: css`
      color: ${colors.secondaryButton};
      font-weight: 700;
      text-transform: uppercase;
      font-size: 14px;
      letter-spacing: 1.2px
    `,
    revokingText: css`
      color: ${colors.white}
    `,
  };
  const [rerun, setRerun] = React.useState(false);
  const {
     rerunCompliance
  } = props;
  const onRerun = async () => {
    const payload = {
      type: 'load',
    };

    try {
      setRerun(true);
      await rerunCompliance(payload);
      setRerun(false);
    } catch (e) {
      setRerun(false);
    }
  };
  return <Grid container direction="row" justify="space-between">
    {rerun ? (
      <Grid
        item
        container
        direction="column"
        alignItems="center"
      >
        <CircularProgress color="secondary" />
        <Typography
        >
          Rerunning Compliance...
        </Typography>
      </Grid>
    ) : (
      (
        <Grid item>
          <Button
            className={classes.revokeButton}
            onClick={onRerun}
            disabled= {rerun}
          >
            <Typography className={classes.revokeButtonText}>
              Resubmit
            </Typography>
          </Button>
        </Grid>
      )
    )}
  </Grid>;
}
