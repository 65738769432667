import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { IconButton } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Logo from '../../assets/verizeal-logo';
import * as colors from '../../styles/colors';

const StyledButton = withStyles({
  root: {
    margin: 'auto',
    float: 'left',
    backgroundColor: 'transparent',
    boxShadow: 'unset',
    color: colors.white,
    '&:hover': {
      backgroundColor: 'transparent',
      boxShadow: 'unset',
    },
  },
})(IconButton);

export default function EsgLogoButton() {
  return (
    <Route render={({ history }) => (
      <StyledButton
        disableRipple
        variant="contained"
        onClick={() => { history.push('/home'); }}
      >
        <Logo
          style={{
            width: 160,
            height: 30,
            verticalAlign: 'bottom',
          }}
        />
      </StyledButton>
    )}
    />
  );
}
