import { makeStyles } from '@material-ui/core/styles';
import * as colors from './colors';

export function getDialogStyle(theme) {
  const { dialog } = theme;

  return {
    dialogRoot: dialog.root,
    dialogContentContainer: dialog.content.container,
    dialogActionsContainer: dialog.actions.container,
    dialogLoadingContainer: dialog.loading.container,
  };
}

function getAppBarStyle(appBar, misc) {
  return {
    appBar: appBar.root,
    appBarIndicator: appBar.indicator,
    appBarTab: appBar.tab,
    appBarTabDisabled: misc.empty,
    appBarTabSelected: misc.empty,
  };
}

export const NEW_ORDER_PANE_STYLE = makeStyles(() => ({
  root: {
    width: '100%',
  },
  expansionPanel: {
    backgroundColor: colors.mediumBlueBackground,
  },
  expansionPanelLabel: {
    color: colors.white,
    fontSize: 19,
    fontWeight: 500,
  },
  expansionIcon: {
    color: colors.white,
  },
  expansionSummaryHeader: {
    '&>:first-of-type': {
      margin: 0,
    },
    '&.Mui-expanded': {
      minHeight: 'initial',
    },
  },
  expansionPanelDetails: {
    padding: '0px 24px 16px',
  },
  noResults: {
    color: colors.white,
    letterSpacing: '.03em',
    fontStyle: 'italic',
  },
}));

export const NEW_ORDER_TABS_STYLE = makeStyles(() => ({
  root: {
    flexGrow: 1,
    backgroundColor: colors.mediumBlueBackground,
    maxWidth: '100%',
    paddingRight: '130px',
  },
  appBar: {
    boxShadow: 'none',
    backgroundColor: colors.mediumBlueBackground,

  },
  appBarHeaderContainer: {
    '&>:last-child': {
      paddingBottom: 15,
    },
  },
  appBarHeaderText: {
    color: colors.white,
    fontWeight: 500,
  },
  appBarHeaderSpecial: {
    color: colors.lightBlue,
    fontSize: 24,
    fontWeight: 500,
  },
  tab: {
    color: colors.textLightGrey,
    fontSize: 14,
    borderBottom: `1px solid ${colors.inputFieldBackground}`,
    padding: 0,
  },
  tabSelected: {
    color: colors.white,
  },
  tabIndicator: {
    backgroundColor: colors.lightBlue,
  },
  noResults: {
    color: colors.white,
    letterSpacing: '.03em',
    fontStyle: 'italic',
  },
  divider: {
    backgroundColor: colors.darkBlueBackground,
  },
  processComplete: {
    color: colors.white,
  },
}));

export const NEW_ORDER_INPUT_LABEL_PROPS = makeStyles((theme) => ({
  root: theme.inputLabel.root,
  disabled: {},
  focused: {},
  error: {},
}));

export const NEW_ORDER_INPUT_PROPS = makeStyles((theme) => ({
  root: theme.input.root,
  focused: {},
  disabled: {},
}));

export const NEW_ORDER_INPUT_MULTILINE_PROPS = makeStyles((theme) => ({
  root: theme.multiline.root,
  focused: {},
  disabled: {},
}));

export const NEW_ORDER_MODULE_STYLE = makeStyles((theme) => {
  const {
    misc, input, inputLabel, dialog, shipmentModule,
  } = theme;
  const {
    disabledModule, expansionPanel, appBar,
  } = shipmentModule;
  const { root, summary, details } = expansionPanel;

  const miscStyle = {
    noResults: misc.noResults,
  };

  const inputStyle = {
    inputLabelRoot: inputLabel.root,
    inputRoot: input.root,
    searchField: input.searchField,
    datePicker: input.assignTaskDatePicker,
    focused: misc.empty,
    disabled: misc.empty,
    selected: misc.empty,
  };

  const dialogStyle = {
    dialogAssignTaskContentRoot: dialog.assignTask.content.root,
    dialogAssignTaskContentSuccessContainer: dialog.assignTask.content.successContainer,
    dialogAssignTaskContentSuccessOuterContainer: dialog.assignTask.content.successOuterContainer,
    dialogAssignTaskContentMessageContainer: dialog.assignTask.content.messageContainer,
    dialogAssignTaskContentResultsContainer: dialog.assignTask.content.resultsContainer,
    dialogAssignTaskContentDueDateContainer: dialog.assignTask.content.dueDateContainer,
    dialogAssignTaskContentOuterContainer: dialog.assignTask.content.outerContainer,
    dialogAssignTaskContentInviteContainer: dialog.assignTask.content.inviteContainer,
    dialogAssignTaskContentHeaderContainer: dialog.assignTask.content.headerContainer,
    dialogAssignTaskContentActionsContainer: dialog.assignTask.content.actionsContainer,

    dialogAssignTaskLabelErrorMessage: dialog.assignTask.label.errorMessage,
    dialogAssignTaskLabelSuccessMessage: dialog.assignTask.label.successMessage,
    dialogAssignTaskLabelDueDate: dialog.assignTask.label.dueDate,
    dialogAssignTaskLabelHeader: misc.colors.white,
    dialogAssignTaskLabelAppbarHeader: dialog.assignTask.label.appbarHeader,

    dialogAssignTaskScrollbarOuterRoot: dialog.assignTask.scrollbar.outer.root,
    dialogAssignTaskScrollbarOuterRootAlt: dialog.assignTask.scrollbar.outer.rootAlt,
    dialogAssignTaskScrollbarOuterInvite: dialog.assignTask.scrollbar.outer.invite,
    dialogAssignTaskScrollbarOuterInviteAlt: dialog.assignTask.scrollbar.outer.inviteAlt,
    dialogAssignTaskScrollbarOuterLoading: dialog.assignTask.scrollbar.outer.loading,
    dialogAssignTaskScrollbarOuterLoadingAlt: dialog.assignTask.scrollbar.outer.loadingAlt,
    dialogAssignTaskScrollbarOuterVertical: dialog.assignTask.scrollbar.outer.vertical,
    dialogAssignTaskScrollbarInnerRoot: dialog.assignTask.scrollbar.inner.root,
    dialogAssignTaskScrollbarInnerVertical: dialog.assignTask.scrollbar.inner.vertical,

    dialogAssignTaskIconError: dialog.assignTask.icon.error,
    dialogAssignTaskIconSuccess: dialog.assignTask.icon.success,

    tabPanel: dialog.assignTask.content.tabPanelContainer,
  };

  return {
    ...miscStyle,
    ...inputStyle,
    ...dialogStyle,
    expansionPanel: root,
    expansionPanelSummary: summary.root,
    expansionPSExpanded: summary.expanded,
    expansionPSTitleContainer: summary.titleContainer,
    expansionPSTitle: summary.title,
    expansionPSSubTitle: summary.subTitle,
    expansionPSContent: summary.content,
    expansionPanelDetails: details.root,
    disabledModule,
    taskButton: summary.task.button,
    taskButtonText: summary.task.text,
    ...getAppBarStyle(appBar, misc),
    ...getDialogStyle(theme),
  };
});

export const NEW_ORDER_NAVIGATION_BAR_STYLE = makeStyles((theme) => {
  const { misc, navigationBar } = theme;
  const { base, scrollbar } = navigationBar;

  const baseStyle = {
    baseButton: base.button,
  };

  const miscStyle = { miscEmpty: misc.empty };

  const scrollBarStyle = {
    scrollbarsRoot: scrollbar.root,
    scrollbarsTrackHorizontal: scrollbar.trackHorizontal,
    scrollbarsVertical: misc.scrollbar.verticalScroll,
  };

  return {
    ...baseStyle,
    ...miscStyle,
    ...scrollBarStyle,
  };
});

export const NEW_ORDER_SENDER_RECIPIENT_STYLE = makeStyles((theme) => {
  const {
    shipmentModule, input, inputLabel, misc,
  } = theme;
  const { senderRecipient, appBar } = shipmentModule;
  const {
    scrollbars, results, contentOuterContainer, content, buttonContainer, enterAddress,
  } = senderRecipient;

  const defaultSenderStyle = {
    miscLoadingContainer: misc.loadingContainer,
  };

  return {
    inputLabelRoot: inputLabel.root,
    checkboxLabel: inputLabel.checkboxLabel,
    checkboxPrimary: inputLabel.checkboxPrimary,
    inputRoot: input.root,
    searchField: input.searchField,
    focused: input.focused,
    disabled: input.disabled,
    selected: input.selected,
    error: input.error,
    scrollbarsRoot: scrollbars.root,
    scrollbarsVertical: misc.scrollbar.verticalScroll,
    noResults: results.noResults,
    contentOuterContainer,
    contentContainer: content.container,
    contentSearch: content.search,
    contentResults: content.results,
    buttonContainer,
    addressHeader: enterAddress.header,
    addressHeaderItem: enterAddress.item,
    addressSectionHeader: enterAddress.sectionHeader,
    addressHeaderTitle: enterAddress.title,
    addressHelpText: enterAddress.helpText,
    expandMoreIconPrimary: input.expandMoreIcon.root,
    expandMoreIconDisabled: input.expandMoreIcon.disabled,
    ...getAppBarStyle(appBar, misc),
    ...getDialogStyle(theme),
    ...defaultSenderStyle,
  };
});

export const NEW_ORDER_PACKAGING_STYLE = makeStyles((theme) => ({
  ...getDialogStyle(theme),
}));

export const NEW_ORDER_SHIPPING_DETAILS_STYLE = makeStyles((theme) => ({
  ...getDialogStyle(theme),
}));

export const NEW_ORDER_BILLING_PAYMENT_STYLE = makeStyles((theme) => {
  const {
    shipmentModule, input, inputLabel, misc,
  } = theme;
  const {
    base, form, submitted, checkIcon, radio,
    creditCardPayment, billDefaultCarrier, billOtherCarrier,
  } = shipmentModule.billingPayment;

  const baseStyle = {
    baseContentHeaderContainer: base.content.headerContainer,
    baseContentFooterContainer: base.content.footerContainer,
    baseContentPopperContainer: base.content.popperContainer,
    baseContentToolTipContainer: base.content.toolTipContainer,
    baseLabelHeaderText: base.label.headerText,
    baseLabelPriceText: base.label.priceText,
    baseItem: base.item,
  };

  const creditCardPaymentStyle = {
    creditCardContentProcessedContainer: creditCardPayment.content.processedContainer,
    creditCardContentProcessingContainer: creditCardPayment.content.processingContainer,
    creditCardContentLoadingContainer: creditCardPayment.content.loadingContainer,
    creditCardContentDefaultAddressContainer: creditCardPayment.content.defaultAddressContainer,
    creditCardContentNoDefaultSenderContainer: creditCardPayment.content.noDefaultSenderContainer,
    creditCardContentAddressFormContainer: creditCardPayment.content.addressFormContainer,
    creditCardLabelNoDefaultSender: creditCardPayment.label.noDefaultSender,

    creditCardLabelProcessingText: creditCardPayment.label.processingText,
  };

  const billDefaultCarrierStyle = {
    billDefaultCarrierContentContainer: billDefaultCarrier.content.container,
    billDefaultCarrierContentSummaryHeaderPercentContainer:
      billDefaultCarrier.content.summary.header.percentContainer,
    billDefaultCarrierContentSummaryDetailContainer:
      billDefaultCarrier.content.summary.detail.container,
    billDefaultCarrierContentSummaryDetailTitleContainer:
      billDefaultCarrier.content.summary.detail.titleContainer,
    billDefaultCarrierContentSummaryDescriptionContainer:
      billDefaultCarrier.content.summary.detail.descriptionContainer,

    billDefaultCarrierLabelHeader: billDefaultCarrier.label.header,
    billDefaultCarrierLabelSectionHeader: billDefaultCarrier.label.sectionHeader,
    billDefaultCarrierLabelSummaryPercent: billDefaultCarrier.label.summaryPercent,
    billDefaultCarrierLabelSummaryTitle: billDefaultCarrier.label.summaryTitle,
    billDefaultCarrierLabelDropDownListItem: billDefaultCarrier.label.dropDownListItem,

    billDefaultCarrierInputContainerRoot: billDefaultCarrier.input.container.root,
    billDefaultCarrierInputContainerHalf: billDefaultCarrier.input.container.half,
    billDefaultCarrierInputContainerDropDown: billDefaultCarrier.input.container.dropDown,
    billDefaultCarrierInputContainerDropDownList: billDefaultCarrier.input.container.dropDownList,
    billDefaultCarrierInputContainerDropDownListItem:
      billDefaultCarrier.input.container.dropDownListItem,
    input: billDefaultCarrier.input.container.percent,

    billDefaultCarrierIconEditContainer: billDefaultCarrier.icon.edit.container,
    billDefaultCarrierIconEditDisabled: billDefaultCarrier.icon.edit.disabled,
    billDefaultCarrierIconDeleteContainer: billDefaultCarrier.icon.delete.container,

    scrollbarsVertical: misc.scrollbar.verticalScroll,
    scrollbarsTrackHorizontal: billDefaultCarrier.scrollbar.trackHorizontal,
  };

  const billOtherCarrierStyle = {
    billOtherCarrierContentContainer: billOtherCarrier.content.container,
    billOtherCarrierLabelHeader: billOtherCarrier.label.header,
    billOtherCarrierInputContainerRoot: billOtherCarrier.input.container.root,
  };

  return {
    form,
    breakline: misc.breakline,
    radioPrimary: radio.primary,
    radioLabel: radio.label,
    ccDropInUI: creditCardPayment.dropInUI,
    addressBlock: creditCardPayment.address,
    addressSectionHeader: creditCardPayment.sectionHeader,
    checkboxLabel: inputLabel.checkboxLabel,
    checkboxPrimary: inputLabel.checkboxPrimary,
    inputLabelRoot: inputLabel.root,
    percentField: input.percentField,
    inputRoot: input.root,
    focused: input.focused,
    disabled: input.disabled,
    selected: input.selected,
    error: input.error,
    submittedText: submitted.text,
    styledCheckCircle: checkIcon,
    expandMoreIconPrimary: input.expandMoreIcon.root,
    expandMoreIconDisabled: input.expandMoreIcon.disabled,
    ...getDialogStyle(theme),
    ...baseStyle,
    ...creditCardPaymentStyle,
    ...billDefaultCarrierStyle,
    ...billOtherCarrierStyle,
    miscEmpty: misc.empty,
  };
});

export const NEW_ORDER_CONFIRMATION_STYLE = makeStyles((theme) => ({
  ...getDialogStyle(theme),
}));

export const NEW_ORDER_SHIPPING_DOCUMENTS_STYLE = makeStyles((theme) => {
  const { misc, shipmentModule } = theme;

  const { base } = shipmentModule.shippingDocuments;

  const miscStyle = {
    miscEmpty: misc.empty,
  };

  const baseStyle = {
    baseIconDownload: base.icon.download,
    baseContentDocumentsContainer: base.content.documentsContainer,
    baseContentDocumentContainer: base.content.documentContainer,
    baseContentImageContainer: base.content.imageContainer,
    baseContentTrackingContainer: base.content.trackingContainer,
    baseLabelDocumentTitle: base.label.documentTitle,
    baseLabelToolTip: base.label.tooltip,
    baseLabelNoDocuments: base.label.noDocuments,
  };

  return {
    ...miscStyle,
    ...baseStyle,
  };
});

export const NEW_ORDER_SCHEDULE_PICKUP_STYLE = makeStyles((theme) => {
  const {
    shipmentModule, input, inputLabel, misc,
  } = theme;

  const {
    base, schedule, scheduled, scheduleCancelled,
  } = shipmentModule.schedulePickup;

  const inputStyle = {
    inputLabelRoot: inputLabel.root,
    inputRoot: input.root,
    dateContainer: input.dateContainer,
    datePickerOpen: input.datePickerOpen,
    datePicker: input.datePicker,
    timeSelect: input.timeSelect,
    textArea: input.textArea,
    focused: input.focused,
    disabled: input.disabled,
    noLabel: input.noLabel,
    expandMoreIconPrimary: input.expandMoreIcon.root,
    expandMoreIconDisabled: input.expandMoreIcon.disabled,
  };

  const miscStyle = {
    miscColorsWhite: misc.colors.white,
    miscEmpty: misc.empty,
    miscHyphen: misc.hyphen,
  };

  const baseStyle = {
    baseContentFormContainer: base.content.formContainer,
    baseContentDateTimeContainer: base.content.dateTimeContainer,
    baseContentMapContainer: base.content.mapContainer,
    baseLabelSectionHeader: base.label.sectionHeader,
    baseLabelSectionSubHeader: base.label.sectionSubHeader,
    baseIconClockIcon: base.icon.clockIcon,
  };

  const scheduleStyle = {
    scheduleContentProcessingContainer: schedule.content.processingContainer,
    scheduleContentLoadingContainer: schedule.content.loadingContainer,
  };

  const scheduledStyle = {
    scheduledLabelMessage: scheduled.label.message,
    scheduledIconAlarm: scheduled.icon.alarm,
  };

  const scheduleCancelledStyle = {
    scheduleCancelledContentFooterContainer: scheduleCancelled.content.footerContainer,
    scheduleCancelledContentMessageContainer: scheduleCancelled.content.messageContainer,
    scheduleCancelledLabelMessage: scheduleCancelled.label.message,
    scheduleCancelledIconError: scheduleCancelled.icon.error,
  };

  return {
    ...inputStyle,
    ...getDialogStyle(theme),
    ...miscStyle,
    ...baseStyle,
    ...scheduleStyle,
    ...scheduledStyle,
    ...scheduleCancelledStyle,
  };
});
