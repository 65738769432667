import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { Grid } from '@material-ui/core';
import ReadOnlyContainer from '../../components/common/ReadOnlyContainer';
import { ACCOUNT_MANAGEMENT } from './constants';
import NewOrderCustomTextField from '../../components/common/NewOrderCustomTextField';
import { FIELD_TYPE_BOOL } from '../../clientConstants';
import ReadOnlyCheckbox from '../../components/common/ReadOnlyCheckbox';
import Form from './Form';
import { getFormFields } from './util';

const CostAllocation = ({
  formikRef, itemKey, values, isEditing, onEdit, onSubmit,
}) => {
  const obj = ACCOUNT_MANAGEMENT[itemKey];
  const [fields, setFields] = useState(obj.fields || []);

  useEffect(() => {
    setFields(getFormFields(itemKey, fields, values));
  }, []);

  return (
    <ReadOnlyContainer title={obj.title} setIsReadOnly={() => onEdit(itemKey)}>
      {isEditing ? (
        <Form
          ref={formikRef}
          itemKey={itemKey}
          initialValues={values}
          validationSchema={obj.validationSchema}
          fields={obj.fields}
          onEdit={onEdit}
          onSubmit={onSubmit}
        />
      )
        : fields.map((field) => (
          <Grid key={field.name} item xs={field?.fullWidth ? 12 : 6}>
            {field.type === FIELD_TYPE_BOOL ? (
              <ReadOnlyCheckbox
                type={field.type}
                name={field.name}
                label={field.label}
                value={get(values, field.name, false)}
              />
            ) : (
              <NewOrderCustomTextField
                type={field.type}
                style={{ width: '100%' }}
                field={{
                  name: field.name,
                  label: field.label,
                  value: get(values, field.name, ''),
                }}
                form={{
                  touched: false,
                }}
                disabled
              />
            )}
          </Grid>
        ))}
    </ReadOnlyContainer>
  );
};

CostAllocation.defaultProps = {
  values: null,
  isEditing: false,
};

CostAllocation.propTypes = {
  formikRef: PropTypes.shape(PropTypes.object).isRequired,
  itemKey: PropTypes.string.isRequired,
  values: PropTypes.shape({
    instructions: PropTypes.string,
    maxAllocations: PropTypes.string,
    splitAllocation: PropTypes.bool,
    isDescriptionAllowed: PropTypes.bool,
  }),

  isEditing: PropTypes.bool,
  onEdit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default memo(CostAllocation);
