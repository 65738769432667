import React from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import {
  Typography,
  TextField,
  CircularProgress,
  InputAdornment,
  Chip,
} from '@material-ui/core';
import isEmpty from 'lodash/isEmpty';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import FilterIcon from '@material-ui/icons/FilterList';

import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import SearchIcon from '@material-ui/icons/Search';
import shortId from 'shortid';
import { CSVLink } from 'react-csv';
import ShippingManifestCard from '../components/ShippingManifestCard';
import { Page } from '../components/common';
import { inDevelopment, SHIPMENT_TRACKING_TAB } from '../clientConstants';
import * as colors from '../styles/colors';
import TrackingModal from '../components/TrackingModal';
import statusHelp from '../utils/status';
import {
  formatDateLong,
  formatDateShort,
  getPurposeDisplay,
  splitCamelCase,
} from '../utils/helpers';

import {
  useShipmentManifestState,
  useShipmentManifestDispatch,
  getShipmentManifest,
} from '../context/shipmentManifestContext';
import { loadShipmentManifest } from '../utils/shipmentClient';

import {
  useUserDispatch,
  useUserState,
  loadUser,
} from '../context/userContext';
import Navbar from '../components/Navbar';
import FilterManifestModal from '../components/FilterManifestModal';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: colors.background,
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  iconContainer: {
    marginRight: '5px',
    color: 'white',
  },
  listHeadingDiv: {
    marginTop: '10px',
    height: '60px',
    borderBottom: '1px solid rgba(255,255,255,.2)',
    padding: '0 0 10px 0',
    width: '100%',
  },
  shipmentsHeading: {
    fontSize: 24,
    color: colors.white,
  },
  centeredContainer: {
    alignItems: 'center',
  },
  viewAllButton: {
    fontSize: '14px',
    color: '#94CCFB',
    letterSpacing: 1.25,
  },
  pageDiv: {
    // overflow: 'scroll',
    // height: '100vh',
    // backgroundColor: '#151721',
  },
  select: {
    color: colors.secondaryButton,
    marginLeft: '15px',
    fontSize: '24px',
    border: 'none',
    letterSpacing: 1.2,
    fontWeight: 500,
  },
  filterBar: {
    padding: '5px 0',
  },
  filterIcon: {
    color: colors.white,
    fontSize: '30px',
  },
  activeFilterChip: {
    background: colors.background,
    height: 'auto',
    padding: 5,
    color: colors.white,
    border: `1px solid ${colors.darkBackgroundGrey}`,
    borderRadius: 5,
    fontSize: 15,
    margin: '5px 15px 0px 0px',
  },
  activeFilterChipText: {
    color: colors.white,
    fontSize: 15,
    textTransform: 'capitalize',
  },
  activeFiltersWrap: {
    alignItems: 'center',
    height: '100%',
    maxWidth: 'calc(100% - 64px)',
  },
  searchFieldWrap: {
    height: 45,
    borderRadius: 5,
    background: colors.darkInputFieldBackground,
    marginLeft: '20px',
    width: 300,
    color: colors.white,
  },
  searchField: {
    width: 370,
    background: colors.darkInputFieldBackground,
    color: colors.white,
    '& label': {
      color: colors.white,
      fontWeight: 500,
    },
    overflow: 'hidden',
    margin: 0,
    height: '100%',
  },
  searchFieldRoot: {
    color: colors.white,
  },
  inputRoot: {
    background: colors.darkInputFieldBackground,
    color: colors.white,
  },
  searchFieldLabelRoot: {
    color: colors.white,
    fontSize: 16,
    fontWeight: 500,
    background: colors.darkInputFieldBackground,
  },
  noResultsIconContainer: {
    fontSize: 160,
    display: 'flex',
    background: colors.newOrderFormBackground,
    borderRadius: 130,
    height: 260,
    width: 260,
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 50,
  },
  noResultsIconRoot: {
    color: '#80808C',
  },
  noResultsText: {
    color: colors.tableGreyText,
    fontSize: 14,
  },
  noResultsTextContainer: {
    marginTop: 20,
  },
  endAdornmentRoot: {
    margin: 0,
    '&:not(.MuiInputAdornment-hiddenLabel)': {
      margin: '0px !important',
    },
  },
  filterButtonContainer: {
    justifySelf: 'flex-end',
    display: 'flex',
    alignSelf: 'center',
  },
}));

function formatFilterChipText(filterType, filterValues) {
  const label = `${splitCamelCase(filterType)}: `;
  // if filterType is 'startDate' or 'endDate'
  if (filterType === 'startDate' || filterType === 'endDate') {
    return label + formatDateLong(filterValues).toUpperCase();
  }

  // if filterValues is an array
  if (filterValues instanceof Array) {
    if (filterType === 'purpose') {
      return label + filterValues.map(getPurposeDisplay).join(', ');
    }
  } else if (filterType === 'purpose') {
    return label + getPurposeDisplay(filterValues);
  } else if (['location', 'carrier', 'service'].includes(filterType)) {
    return label + filterValues;
  }

  return label + filterValues.join(', ');
}

export function LandingPage(props) {
  const { history } = props;
  const classes = useStyles();
  return (
    <Grid container spacing={1} className={classes.pageDiv}>
      <MyShipments history={history} />
    </Grid>
  );
}

LandingPage.propTypes = {
  history: PropTypes.object.isRequired,
};

const headers = [
  { label: 'Location', key: 'location' },
  { label: 'Ship date', key: 'shipDate' },
  { label: 'Delivery#', key: 'deliveryNo' },
  { label: 'Type', key: 'type' },
  { label: 'Company', key: 'company' },
  { label: 'City', key: 'city' },
  { label: 'State', key: 'state' },
  { label: 'Country', key: 'country' },
  { label: 'Zip Code', key: 'zipCode' },
  { label: 'Carrier', key: 'carrier' },
  { label: 'Service', key: 'service' },
  { label: 'Weight', key: 'weight' },
  { label: 'Freight', key: 'freight' },
  { label: 'Tracking#', key: 'tracking' },
  { label: 'Status', key: 'status' },
  { label: 'Delivery Date', key: 'deliveryDateTime' },
  { label: 'Cancel Date', key: 'cancelDate' },
  { label: 'Cancel Time', key: 'cancelTime' },
  { label: 'Created By', key: 'createdBy' },
  { label: 'Packages', key: 'packageNumber' },
  { label: 'Contact', key: 'receiptContactName' },
  { label: 'PONo', key: 'poNo' },
  { label: 'Sales Order Number', key: 'salesOrderId' },
  { label: 'Scanned Date', key: 'scanDateTime' },
  { label: 'UOM ', key: 'uom' },
  { label: 'Dimensions', key: 'dimension' },
  { label: 'IPD Tracking', key: 'ipd.tracking' },
];

export function MyShipments(props) {
  const classes = useStyles();
  const shipmentState = useShipmentManifestState();
  const shipmentDispatch = useShipmentManifestDispatch();
  const userDispatch = useUserDispatch();
  const [filterOpen, setFilterOpen] = React.useState(null);
  const { filterableStatuses } = statusHelp;
  const initialStatusFilterState = filterableStatuses.reduce(
    (obj, key) => ({
      ...obj,
      [key]: !![
        'Completed',
        'In-Transit',
        'Delivered'].includes(key),
    }),
    {},
  );
  const initialFilterState = {
    location: null,
    status: initialStatusFilterState,
    startDate: null,
    endDate: null,
    carrier: null,
    service: null,
  };
  const [searchField, setSearchField] = React.useState('');
  const [trackingModalState, setTrackingModalState] = React.useState({
    anchorEl: null,
    shipmentId: null,
    trackingNumber: null,
    iotTrackingNumber: null,
    openTab: SHIPMENT_TRACKING_TAB,
  });
  const [loading, setLoading] = React.useState(false);
  const [filterState, setFilterState] = React.useState(initialFilterState);
  const [currentTrackingTab, setCurrentTrackingTab] = React.useState(
    SHIPMENT_TRACKING_TAB,
  );

  const [fetchShipmentOptions, setFetchShipmentOptions] = React.useState({
    limit: 10,
    offset: 0,
    allLoaded: false,
  });
  const [activeFilters, setActiveFilters] = React.useState({
    status:
  [
    'Completed',
    'In-Transit',
    'Delivered'],
  });

  const { accountType } = useUserState();
  const [newFilterState, setNewFilterState] = React.useState(null);
  const [csvShipmentsState, setCSVShipmentsState] = React.useState({
    data: [],
    loading: false,
  });
  const lastCardRef = React.useRef(null);
  const firstUpdate = React.useRef(true);

  const onWindowScroll = React.useCallback(() => {
    const windowScroll = window.scrollY + window.innerHeight;
    const elementLocation = (lastCardRef.current
        && lastCardRef.current.offsetTop
          + lastCardRef.current.clientHeight
    )
      || Infinity;

    if (windowScroll >= elementLocation && !loading) {
      lastCardRef.current = null;
      loadNewShipments();
    }
  });
  React.useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    submitFilters();
  }, [newFilterState]);
  const submitFilters = () => {
    const filters = Object.keys(filterState).reduce((obj, cat) => {
      if (!filterState[cat]) return obj;
      if (typeof filterState[cat] === 'string') {
        return { ...obj, [cat]: filterState[cat] };
      }
      const catFilters = Object.keys(filterState[cat]).filter(
        (f) => filterState[cat][f],
      );
      if (catFilters.length) return { ...obj, [cat]: catFilters };
      return obj;
    }, {});
    setActiveFilters(filters);
    setFetchShipmentOptions({ offset: 0, limit: 10 });
    refreshShipments({ filters, offset: 0, limit: 10 });
  };
  const handleChipDelete = (filterType, filterValues) => {
    const filter = { ...filterState };
    delete filter[filterType];
    const nfs = { ...initialFilterState, ...filter };
    setFilterState(nfs);
    setNewFilterState(nfs);
    submitFilters();
  };
  const loadNewShipments = () => refreshShipments({});

  const refreshShipments = ({ offset, limit, filters }) => {
    const requestFilters = filters || { ...activeFilters };
    const requestOffset = offset !== undefined ? offset : fetchShipmentOptions.offset;
    const requestLimit = limit || fetchShipmentOptions.limit;
    setLoading(true);
    return getShipmentManifest(
      shipmentDispatch,
      requestFilters,
      requestOffset,
      requestLimit,
    ).then((data) => {
      const allLoaded = data.length < requestLimit;
      if (allLoaded) lastCardRef.current = null;
      setFetchShipmentOptions((prev) => ({
        ...prev,
        offset: requestLimit + requestOffset,
        allLoaded,
      }));
      setLoading(false);
      if (filterOpen) setFilterOpen(null);
    });
  };

  const openTrackingModal = ({
    shipmentId,
    trackingNumber,
    anchorEl,
    iotTrackingNumber,
    openTab,
  }) => {
    setTrackingModalState({
      shipmentId,
      trackingNumber,
      anchorEl,
      iotTrackingNumber,
      openTab,
    });
    setCurrentTrackingTab(openTab);
  };

  const updateSearchFilter = (search) => {
    setSearchField(search);
    if (search.length > 2) {
      setFetchShipmentOptions({ offset: 0, limit: 10 });
      setActiveFilters((prev) => ({ ...prev, filter: search }));
      refreshShipments({
        filters: {
          ...activeFilters,
          filter: search,
        },
        offset: 0,
        limit: 10,
      });
    } else if (activeFilters.filter) {
      setActiveFilters((prev) => {
        const newFilters = { ...prev };
        delete newFilters.filter;
        return newFilters;
      });
      setFetchShipmentOptions({ offset: 0, limit: 10 });
      const filters = { ...activeFilters };
      delete filters.filter;
      refreshShipments({ filters, offset: 0, limit: 10 });
    }
  };

  const handleTrackingClose = () => {
    setTrackingModalState({
      shipmentId: null,
      trackingNumber: null,
      anchorEl: null,
      iotTrackingNumber: null,
      openTab: SHIPMENT_TRACKING_TAB,
    });
  };
  const csvLinkButton = React.useRef(null);

  React.useEffect(() => {
    inDevelopment() && console.log('calling loadShipments from workspace');

    if (!accountType) loadUser(userDispatch);

    refreshShipments({}).catch(() => setLoading(false));
  }, []);

  React.useEffect(() => {
    if (!loading) {
      onWindowScroll();
    }
  }, [loading]);
  React.useEffect(() => {
    if (!isEmpty(csvShipmentsState.data) && csvLinkButton && csvLinkButton.current) {
      csvLinkButton.current.link.click();
    }
  }, [csvShipmentsState.data]);

  React.useEffect(() => {
    window.addEventListener('scroll', onWindowScroll);
    return () => {
      window.removeEventListener('scroll', onWindowScroll);
    };
  });

  const handleCSV = () => {
    setCSVShipmentsState({
      data: [],
      loading: true,
    });

    loadShipmentManifest({ limit: null, offset: null, filters: activeFilters }).then((response) => {
      setCSVShipmentsState(
        {
          data: response.map((shipment) => ({
            ...shipment,
          })),
          loading: false,
        },
      );
    }).catch((e) => {
      setCSVShipmentsState({
        data: [],
        loading: false,
      });
    });
  };
  return (
    <Grid container direction="column">
      <TrackingModal
        handleClose={handleTrackingClose}
        id={`tracking-${trackingModalState.shipmentId || ''}`}
        trackingModalState={trackingModalState}
        currentTab={currentTrackingTab}
        setCurrentTab={setCurrentTrackingTab}
      />
      <div className={classes.listHeadingDiv}>
        <Grid container justify="space-between" alignItems="center">
          <Grid item className={classes.centeredContainer}>
            <Grid container alignItems="center">
              <div className={classes.iconContainer}>
                <LocalShippingIcon />
              </div>
              <Grid item>
                <Grid container>
                  <Typography className={classes.shipmentsHeading}>
                    Shipments
                  </Typography>
                </Grid>
              </Grid>
              <Grid item className={classes.searchFieldWrap}>
                <TextField
                  id="filled-basic"
                  className={classes.searchField}
                  classes={{ root: classes.searchFieldRoot }}
                  label="Search Shipments"
                  margin="normal"
                  onChange={(e) => updateSearchFilter(e.target.value)}
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      root: classes.inputRoot,
                      focused: classes.inputRoot,
                    },
                    endAdornment: (
                      <InputAdornment
                        position="start"
                        classes={{
                          root: classes.endAdornmentRoot,
                          filled: classes.endAdornmentRoot,
                          positionStart: classes.endAdornmentRoot,
                        }}
                      >
                        <SearchIcon color="primary" />
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{ root: classes.searchFieldLabelRoot }}
                  variant="filled"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Button
              className={classes.exportButton}
              color="secondary"
              variant="contained"
              onClick={handleCSV}
              style={{ letterSpacing: 1.25 }}
            >
              {csvShipmentsState.loading ? <CircularProgress style={{ width: 25, height: 25 }} /> : 'Export'}
            </Button>
            <CSVLink
              ref={csvLinkButton}
              headers={headers}
              data={csvShipmentsState.data}
              filename={`shipment-manifest-${new Date().toISOString()}.csv`}
            />
          </Grid>
        </Grid>
      </div>
      <Grid
        item
        container
        className={classes.filterBar}
        justify="space-between"
      >
        <FilterManifestModal
          {...{ accountType }}
          anchorEl={filterOpen}
          handleClose={() => setFilterOpen(null)}
          filterState={filterState}
          setFilterState={setFilterState}
          submitFilters={submitFilters}
        />
        <Grid item container className={classes.activeFiltersWrap}>
          {Object.entries(activeFilters).map(
            ([filterType, filterValues]) => filterType !== 'filter' && (
            <Grid
              item
              className={classes.activeFilterChip}
              key={`active-filter-ship-grid-${filterType}`}
            >
              <Chip
                key={`active-filter-ship-typography-${filterType}`}
                variant="outlined"
                className={classes.activeFilterChipText}
                color="primary"
                label={formatFilterChipText(filterType, filterValues)}
                onDelete={() => handleChipDelete(filterType, filterValues)}
              />
            </Grid>
            ),
          )}
        </Grid>
        <Grid item className={classes.filterButtonContainer}>
          <Button
            onClick={(e) => setFilterOpen(e.currentTarget)}
            data-testid="filter-shipments-button"
          >
            <FilterIcon className={classes.filterIcon} />
          </Button>
        </Grid>
      </Grid>
      <Grid item container direction="column" alignItems="center">
        {shipmentState.shipments && shipmentState.shipments.length
          ? shipmentState.shipments.map((shipment, idx, sArray) => (
            <ShippingManifestCard
              key={`shipment-tile-${
                shipment.InvoiceNo
              }-${shortId.generate()}`}
              shipment={shipment}
              classes={classes}
              openTrackingModal={openTrackingModal}
              lastCardRef={
                  idx === sArray.length - 1 && !fetchShipmentOptions.allLoaded
                    ? lastCardRef
                    : undefined
                }
            />
          ))
          : !loading && (
          <Grid
            item
            container
            direction="column"
            alignItems="center"
            className={classes.noResults}
          >
            <Grid item className={classes.noResultsIconContainer}>
              <LocalShippingIcon
                fontSize="inherit"
                classes={{ root: classes.noResultsIconRoot }}
              />
            </Grid>
            <Grid item className={classes.noResultsTextContainer}>
              <Typography className={classes.noResultsText}>
                No shipments found!
              </Typography>
            </Grid>
          </Grid>
          )}
        {loading && <CircularProgress />}
      </Grid>
    </Grid>
  );
}

export default function ProtectedPage(props) {
  const classes = useStyles();
  const { account } = useUserState();

  return (
    <div className={classes.root}>
      <Navbar />
      <Page account={account}>
        <LandingPage history={props.history} />
      </Page>
    </div>
  );
}
