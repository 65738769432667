import React from 'react';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { css } from 'emotion';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import * as colors from '../styles/colors';
import ReadOnlyTextField from './common/ReadOnlyTextField';
import ReadOnlyCheckbox from './common/ReadOnlyCheckbox';

export default function ProfileManagementReadOnlyForm(props) {
  const classes = {
    header: css`
    color: ${colors.white};
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0.25px;
    `,
  };

  const {
    numFieldsPerColumn, fields, setIsReadOnly, title, userState, isEditable, showTimeZoneNewLine,
  } = props;

  // these fields generate textboxes
  const textfields = fields.filter((field) => (showTimeZoneNewLine
    ? field.name !== 'timeZone' && field.type !== 'bool'
    : field.type !== 'bool'
  ));
  // These fields generate checkboxes
  const boolfields = fields.filter((field) => field.type === 'bool');
  // timezone field needs to a wider width, so needs to sit below other textfields
  const timeZoneField = showTimeZoneNewLine ? fields.find((field) => field.name === 'timeZone') : undefined;

  return (

    <Grid container spacing={4}>
      <Grid xs={12} item container justify="space-between" alignItems="center">
        <Grid item>
          <Typography className={classes.header}>{title}</Typography>
        </Grid>
        {isEditable && (
        <Grid item>
          <IconButton onClick={() => setIsReadOnly(false)} data-testid="profile-management-edit-button">
            <EditIcon />
          </IconButton>
        </Grid>
        )}
      </Grid>
      <Grid item container direction="column" xs={6}>
        {userState && textfields.slice(0, numFieldsPerColumn).map((field) => (
          <Grid item key={`read-only-grid-${field.name}`}>
            <ReadOnlyTextField
              type={field.type}
              iconName={field.name}
              name={field.name}
              secondaryLabel={field.readOnlyLabel}
              label={field.label}
              value={userState[field.name]}
            />
          </Grid>
        ))}
      </Grid>
      <Grid item container direction="column" xs={6}>
        {userState && textfields.slice(numFieldsPerColumn).map((field) => (
          <Grid item key={`read-only-grid-${field.name}`}>
            <ReadOnlyTextField
              type={field.type}
              iconName={field.name}
              name={field.name}
              secondaryLabel={field.readOnlyLabel}
              label={field.label}
              value={userState[field.name]}
            />
          </Grid>
        ))}
      </Grid>
      {showTimeZoneNewLine && userState && timeZoneField && (
        <Grid item container direction="column">
          <Grid item>
            <ReadOnlyTextField
              type={timeZoneField.type}
              iconName={timeZoneField.name}
              name={timeZoneField.name}
              secondaryLabel={timeZoneField.readOnlyLabel}
              label={timeZoneField.label}
              value={userState[timeZoneField.name]}
            />
          </Grid>
        </Grid>
      )}
      <Grid item container direction="column">
        {userState && boolfields.map((field) => (
          <Grid item key={`read-only-grid-${field.name}`}>
            <ReadOnlyCheckbox
              name={field.name}
              label={field.label}
              value={userState[field.name] || false}
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}

ProfileManagementReadOnlyForm.defaultProps = {
  isEditable: true,
  showTimeZoneNewLine: true,
};

ProfileManagementReadOnlyForm.propTypes = {
  userState: PropTypes.shape({
    userId: PropTypes.string,
    user: PropTypes.shape({
      email: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      mobilePhone: PropTypes.string.isRequired,
      workPhone: PropTypes.string,
      linkedin: PropTypes.string,
      twitter: PropTypes.string,
      facebook: PropTypes.string,
    }),
  }).isRequired,
  setIsReadOnly: PropTypes.func.isRequired,
  numFieldsPerColumn: PropTypes.number.isRequired,
  fields: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    isRequired: PropTypes.bool.isRequired,
  })).isRequired,
  isEditable: PropTypes.bool,
  showTimeZoneNewLine: PropTypes.bool,
};
