import React from 'react';
import * as _ from 'lodash';
import PropTypes from 'prop-types';
import { Grid, IconButton, Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { Delete16 as DeleteIcon } from '@carbon/icons-react';
import { has } from 'lodash';
import { useDisplayItemsStyles } from './itemDetailsStyles';
import {
  DISPLAY_BATTERY_CONTAINED_FIELDS,
  FORM_FIELDS_BATTERIES_CONTAINED_OR_PACKED,
  FORM_FIELDS_HAZMAT, FORM_FIELDS_LITHIUM_BATTERY, FREIGHT_TYPES, LITHIUM_BATTERY_CONTAINED_KEY,
} from './itemDetailsConstants';
import {
  doesProductHaveLithiumBattery,
  getDisplayFields, getLicenseText, isBiologicalHazmatOtherClassType, isRestrictedMaterial, showUserRequiredBatteryTrainingWarning, showUserRequiredChemicalTrainingWarning, showUserRequiredTrainingWarning,
} from './itemDetailsUtil';
import PanelWarning from '../common/PanelWarning';
import BatteryContainedWarning from './Item/Hazmat/BatteriesContainedOrPacked/Warning';
import { HAZMAT_BATTERIES, HAZMAT_CHEMICAL } from '../../clientConstants';

const FREIGHT_TYPES_LIST = Object.keys(FREIGHT_TYPES);

export default function DisplayItems({
  items,
  freightType,
  hazmatOption,
  training,
  hiddenFields = [],
  showLithiumBatteryBanner,
  showOnlyLithiumBattery,
  handleItemDelete,
  handleItemEdit,
}) {
  return (
    <Grid item container spacing={1}>
      {items && items.map((item) => {
        const newItem = {
          ...item,
          [FORM_FIELDS_HAZMAT.requiredLicenses.key]: getLicenseText(item),
        };

        return (
          <DisplayItem
            key={item.itemId}
            item={newItem}
            freightType={freightType}
            hazmatOption={hazmatOption}
            training={training}
            handleItemDelete={handleItemDelete}
            handleItemEdit={handleItemEdit}
            hiddenFields={hiddenFields}
            showLithiumBatteryBanner={showLithiumBatteryBanner}
            showOnlyLithiumBattery={showOnlyLithiumBattery}
          />
        );
      })}
    </Grid>
  );
}

DisplayItems.propTypes = {
  items: PropTypes.objectOf(PropTypes.any).isRequired,
  freightType: PropTypes.oneOf(FREIGHT_TYPES_LIST).isRequired,
  hazmatOption: PropTypes.string,
  training: PropTypes.shape(PropTypes.object),
  showLithiumBatteryBanner: PropTypes.bool,
  showOnlyLithiumBattery: PropTypes.bool,
  handleItemDelete: PropTypes.func,
  handleItemEdit: PropTypes.func,
};

DisplayItems.defaultProps = {
  hazmatOption: '',
  training: null,
  handleItemDelete: null,
  handleItemEdit: null,
  showLithiumBatteryBanner: true,
  showOnlyLithiumBattery: false,
};

function DisplayItem({
  key,
  item,
  freightType,
  hazmatOption,
  hiddenFields,
  training,
  showLithiumBatteryBanner,
  showOnlyLithiumBattery,
  handleItemDelete,
  handleItemEdit,
}) {
  const classes = useDisplayItemsStyles();
  const hasLithiumBattery = _.has(item, LITHIUM_BATTERY_CONTAINED_KEY) || showOnlyLithiumBattery;
  const display = getDisplayFields(hazmatOption, hiddenFields.includes('material'), !hiddenFields.includes('international'), hiddenFields.includes('dutiesAndTaxes'));
  const isHazmatFreightType = freightType === FREIGHT_TYPES.hazmat;
  const shipClass = isHazmatFreightType && _.has(item, FORM_FIELDS_HAZMAT.shipClass.key)
    ? item[FORM_FIELDS_HAZMAT.shipClass.key]
    : '';

  const getKeyWithPrefix = (fieldKey, prefix) => (_.isEmpty(prefix) ? fieldKey : `${prefix}.${fieldKey}`);

  const renderDisplayRow = (field, prefix) => {
    let value = null;

    if (field.values) {
      value = _.join(
        field.values.map((path) => {
          const newPath = getKeyWithPrefix(path, prefix);
          return _.get(item, newPath) || '0';
        }), field.seperator,
      );
    } else {
      const fieldKey = getKeyWithPrefix(field.value, prefix);
      value = _.get(item, fieldKey);
    }

    const postFixKey = getKeyWithPrefix(field.postFix, prefix);
    let postFix = field.postFix ? ` ${_.get(item, postFixKey)}` : '';
    postFix = field.toUpper ? _.toUpper(postFix) : postFix;

    value = field.format ? field.format(value) : value;
    const valueField = `${value}${postFix}`;

    return (
      value && value !== '0x0x0' && (
        <Grid item container>
          <Grid item xs={4}>
            <Typography className={classes.fieldText}>
              {field.label}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography
              className={
                field.label === FORM_FIELDS_HAZMAT.requiredLicenses.label
                  ? classes.fieldLongText
                  : classes.fieldText
              }
              style={{ textTransform: 'capitalize' }}
            >
              {valueField}
            </Typography>
          </Grid>
        </Grid>
      )
    );
  };

  return (
    <Grid item key={key} container xs={12} style={{ display: 'block' }}>
      <Grid item container alignItems="center">
        <Grid item>
          <Typography color="primary" className={classes.itemHeaderText}>
            {item.itemName}
          </Typography>
        </Grid>
        {handleItemEdit && (
          <Grid item style={{ display: 'flex' }}>
            <IconButton onClick={() => handleItemEdit(item)}>
              <EditIcon className={classes.editIcon} />
            </IconButton>
          </Grid>
        )}
        {handleItemDelete && (
          <Grid item style={{ display: 'flex' }}>
            <IconButton onClick={() => handleItemDelete(item)}>
              <DeleteIcon className={classes.deleteIcon} />
            </IconButton>
          </Grid>
        )}
      </Grid>
      {item.itemUrl && !isHazmatFreightType && (
        <Grid item>
          <img src={item.itemUrl} style={{ width: '80px' }} alt="" />
        </Grid>
      )}
      {!showOnlyLithiumBattery && display.map((field) => renderDisplayRow(field))}
      {/* {item.isCargo && (
        <Grid item>
          <PanelWarning message="Item will be shipped as a Cargo Shipment." />
        </Grid>
      )} */}
      {/* {showUserRequiredTrainingWarning(shipClass, training)
        && !isBiologicalHazmatOtherClassType(item) && (
          <Grid item>
            <PanelWarning
              message={`You don't have the required training to ship ${shipClass}`}
            />
          </Grid>
      )} */}
      {/* {isRestrictedMaterial(item) && (
      <Grid item>
        <PanelWarning message="Restricted material - Requires ECO Approval." />
      </Grid>
      )} */}
      {/* {hazmatOption === HAZMAT_CHEMICAL
        && showUserRequiredChemicalTrainingWarning(training) && (
          <Grid item>
            <PanelWarning
              message={`You don't have the required training to ship ${item.itemName}`}
            />
          </Grid>
      )} */}
      {/* {hazmatOption === HAZMAT_BATTERIES
        && showUserRequiredBatteryTrainingWarning(training) && (
          <Grid item>
            <PanelWarning
              message={`You don't have the required training to ship ${item.itemName}`}
            />
          </Grid>
      )} */}
      {showLithiumBatteryBanner && doesProductHaveLithiumBattery(LITHIUM_BATTERY_CONTAINED_KEY, item) && (
        <Grid container item>
          <BatteryContainedWarning values={item} />
        </Grid>
      )}
      {/* Display here lithium battery fields */}
      <Grid container item style={{ marginTop: '10px' }}>
        {hasLithiumBattery && (
          <>
            <Grid item style={{ margin: '20px 0' }}>
              <Typography color="primary" className={classes.itemHeaderText}>
                {has(item, `${LITHIUM_BATTERY_CONTAINED_KEY}.properShipName`) ? item[LITHIUM_BATTERY_CONTAINED_KEY].properShipName : ''}
              </Typography>
            </Grid>
            {DISPLAY_BATTERY_CONTAINED_FIELDS.map((field) => renderDisplayRow(field, showOnlyLithiumBattery ? '' : LITHIUM_BATTERY_CONTAINED_KEY))}
          </>
        )}
      </Grid>
      {/* {hasLithiumBattery && _.has(item, `${LITHIUM_BATTERY_CONTAINED_KEY}.${FORM_FIELDS_LITHIUM_BATTERY.trainingRequired.key}`)
      && item[LITHIUM_BATTERY_CONTAINED_KEY][FORM_FIELDS_LITHIUM_BATTERY.trainingRequired.key] === 'Y'
      && showUserRequiredBatteryTrainingWarning(training) && (
      <Grid item>
        <PanelWarning
          message={`You don't have the required training to ship ${item[LITHIUM_BATTERY_CONTAINED_KEY].properShipName}`}
        />
      </Grid>
      )} */}
    </Grid>
  );
}

DisplayItem.propTypes = {
  key: PropTypes.string.isRequired,
  item: PropTypes.objectOf(PropTypes.any).isRequired,
  freightType: PropTypes.oneOf(FREIGHT_TYPES_LIST).isRequired,
  hazmatOption: PropTypes.string.isRequired,
  training: PropTypes.shape(PropTypes.object),
  showLithiumBatteryBanner: PropTypes.bool,
  showOnlyLithiumBattery: PropTypes.bool,
  handleItemDelete: PropTypes.func,
  handleItemEdit: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  hiddenFields: PropTypes.arrayOf(PropTypes.any),
};

DisplayItem.defaultProps = {
  training: null,
  showLithiumBatteryBanner: true,
  showOnlyLithiumBattery: false,
  handleItemDelete: null,
  handleItemEdit: null,
};
