import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  pick, assign, isEmpty, get,
} from 'lodash';
import { FastField, Field, getIn } from 'formik';
import { Delete24 as DeleteIcon } from '@carbon/icons-react';
import {
  Button, Grid, Typography, IconButton, Radio, RadioGroup, FormControl, FormControlLabel, FormLabel,
} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LinkIcon from '@material-ui/icons/OpenInNew';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import { useHotkeys } from 'react-hotkeys-hook';
import { InfoOutlined } from '@material-ui/icons';
import dryIceData from '../../../assets/docs/dryice.json';
import { LENGTH_UNIT_OF_MEASUREMENTS_WITH_IDS } from '../../../clientConstants';
import * as colors from '../../../styles/colors';
import * as miscClient from '../../../utils/miscClient';
import CustomSelectField from '../../common/CustomSelectField';
import CustomSelectTextField from '../../common/CustomSelectTextField';
import NewOrderCustomTextField from '../../common/NewOrderCustomTextField';
import DocumentPreview from '../../common/DocumentPreview';
import AutocompleteWithIncrementInput from '../../common/AutocompleteWithIncrementInput';
import { clientTriggers, isIotEnabled } from '../../../utils/packagingUtil';
import { useMaterialsState } from '../../../context/materialsContext';
import { useUSB } from '../../../context/usbContext';
import { useUserState } from '../../../context/userContext';
import {
  WEIGHT_SYSTEM_MAPPING,
  IMPERIAL,
  METRIC,
  LB,
  KG,
  OZ,
  GRAM,
} from '../../ItemProductDetails/itemDetailsConstants';
import IotForm from '../IotForm';
import { singlePackagePropTypes, stripPrefixFromFieldName, transformAccountName } from '../utils';
import ControlledCheckbox from '../ControlledCheckbox';
import PackagingImage from '../PackagingImage';
import OptionalField from './OptionalField';
import PanelWarning from '../../common/PanelWarning';
import cumta from '../../images/cumta.docx';

const iotIcon = require('../../images/iot.svg');

/**
 * ##################################
 *          GLOBAL VARIABLES
 * ##################################
 */

const styles = {
  expansionPanel: {
    backgroundColor: colors.mediumBlueBackground,
    boxShadow: 'unset',
    '&.Mui-expanded': {
      margin: 0,
    },
  },
  expansionPanelSummaryRoot: {
    '&.Mui-expanded': {
      minHeight: '48px',
    },
    padding: '0 0',
  },
  expansionIcon: {
    color: colors.textGrey,
  },
  expansionPanelSummary: {
    '&.Mui-expanded': {
      margin: 'initial',
      minHeight: '36px',
    },
    marginTop: 0,
    marginBottom: 0,
  },
  formSectionHeader: {
    paddingTop: 24,
    fontSize: 14,
    color: colors.white,
  },
  formSectionTitle: {
    paddingTop: 0,
    fontSize: 20,
    fontWeight: 500,
    color: colors.white,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  smallSelectFieldContainer: {
    maxWidth: 70,
    marginRight: 16,
  },
  smallSelectField: {
    '&.Mui-disabled': {},
    '&>div:first-of-type': {
      '&.Mui-disabled': {},
    },
    minWidth: 70,
    width: '100%',
  },
  standardFormTextfield: {
    width: 415,
  },
  textAreaField: {
    width: 350,
  },
  textAreaFieldWe: {
    width: 250,
  },
  packageDetailsContainer: {
    flexDirection: 'column',
    maxWidth: '1300px',
  },
  detailsFormWrap: {
    padding: '0 15px 15px 15px',
    background: colors.darkBlueBackground,
    margin: '10px 0 0 0',
  },
  deleteIcon: {
    fill: 'white',
    '&.disabled': {
      fill: colors.disabledGrey,
    },
  },
  itemDivider: {
    margin: '0 0',
    border: `1px solid ${colors.darkBlueBackground}`,
  },
  referenceField: {
    width: 200,
  },
  shipmentExpansionPanelDetailsRoot: {
    padding: '0 0',
  },
};

function getAcceptableValueToSet(val, defaultValue, transform) {
  const valToSet = transform ? transform(val) : val;
  if (valToSet) return valToSet;
  if (typeof valToSet === 'boolean') return valToSet;
  return defaultValue;
}

const useStyles = makeStyles(styles);

function PackageDetails({
  hiddenFields,
  packageNumber,
  numberOfPackages,
  deletePackage,
  setFieldValue,
  thisPackage,
  disabled,
  expanded,
  setExpanded,
  expandedPackage,
  toggleApplyToAllPackages,
  formikRef,
  productItems,
  packageWeight,
  packageCustomsValue,
  totalItemsForShipment,
  setTotalItemsForShipment,
  weightUnitOfMeasurementForPackage,
  account,
  submitValues,
  setValues,
  values,
  formik,
  carrierServiceCode,
  serviceTypeDropdownData,
  freightType,
  shippingType,
  isChemicalHazmat,
  isBattery,
  isLithiumBatteryContainedOrPacked,
}) {
  const classes = useStyles();
  const weightUnit = packageWeight?.unitOfMeasurement;
  const weightUnitSmall = packageWeight?.unitOfMeasurementSmall;
  const [weightSystem, setWeightSystem] = React.useState(
    WEIGHT_SYSTEM_MAPPING[weightUnit],
  );
  const myRef = React.useRef(null);
  const executeScroll = async () => {
    await timeout(1000);
    myRef.current.scrollIntoView({ behavior: 'smooth' });
  };
  const disableDelete = disabled || numberOfPackages === 1;

  React.useEffect(() => {
    const newWeightSystem = WEIGHT_SYSTEM_MAPPING[weightUnit];
    const newSmallWeightSystem = WEIGHT_SYSTEM_MAPPING[weightUnitSmall];
    if (newWeightSystem !== weightSystem) {
      setWeightSystem(WEIGHT_SYSTEM_MAPPING[weightUnit]);
    }
    if (newSmallWeightSystem !== weightSystem) {
      setWeightSystem(WEIGHT_SYSTEM_MAPPING[weightUnitSmall]);
    }
  }, [weightUnit, weightUnitSmall]);

  React.useEffect(() => {
    switch (weightSystem) {
      case IMPERIAL:
        setFieldValue(fieldNames.weightUnitOfMeasurement, LB);
        setFieldValue(fieldNames.weightUnitOfMeasurementSmall, OZ);
        break;
      case METRIC:
        setFieldValue(fieldNames.weightUnitOfMeasurement, KG);
        setFieldValue(fieldNames.weightUnitOfMeasurementSmall, GRAM);
        break;
      default:
        break;
    }
  }, [weightSystem]);

  const { weightUnits, smallWeightUnits } = useMaterialsState();
  const [packagingDropdownData, setPackagingDropdownData] = React.useState({
    packageTypes: [],
    currencies: [],
    weightUnits: [],
  });
  const [packageTypes, setPackageTypes] = React.useState([]);
  const [packageProfiles, setPackageProfiles] = React.useState([]);

  const [refrigerentTypes, setRefrigerantTypes] = React.useState([]);
  const [dangerousGoodsTypes, setDangerousGoodTypes] = React.useState([]);
  const [iotVendors, setIotVendors] = React.useState([]);
  const [packageProviders, setPackageProviders] = React.useState([]);
  const [packageNames, setPackageNames] = React.useState([]);
  const [preview, setPreview] = React.useState(null);
  const [isUpdatedByScale, setIsUpdatedByScale] = React.useState(false);
  const iataDetailsSkeleton = {
    properShippingName: '',
    unNo: '',
    class: '',
    packagingInstructions: '',
    documentURL: '',
    maxWeight: {
      liters: '',
      kilograms: '',
      grams: '',
      milliliters: '',
    },
    maxWeightPR: {
      liters: '',
      kilograms: '',
      grams: '',
      milliliters: '',
    },
    maxWeightCargo: {
      liters: '',
      kilograms: '',
      grams: '',
      milliliters: '',
    },
    maxWeightPassenger: {
      liters: '',
      kilograms: '',
      grams: '',
      milliliters: '',
    },
  };
  const { connect, disconnect, getWeight } = useUSB();

  const userState = useUserState();
  useEffect(() => {
    if (['worldemblem'].includes(transformAccountName(account) || (transformAccountName(account) === 'clemson' && userState.isMailRoom))) {
      onLoad();
      return () => {
        disconnect();
      };
    }
  }, []);
  useEffect(() => {
    // code to run after render goes here
    if (account === 'worldemblem') {
      executeScroll();
    }
  }, [myRef.current]);
  useEffect(() => {
    // code to run after render goes here
    if (isUpdatedByScale) {
      if (['worldemblem'].includes(transformAccountName(account))) {
        console.log('update scale value');
        setValues({ ...values, notShowNext: true });
      } else {
        setIsUpdatedByScale(false);
      }
    }
  }, [isUpdatedByScale]);

  async function submit() {
    await submitValues();
    await timeout(100);
    setIsUpdatedByScale(false);
  }

  useEffect(() => {
    // code to run after render goes here
    if (values?.notShowNext && isUpdatedByScale) {
      console.log('update scale value');
      submit();
    }
  }, [values]);
  useEffect(() => {
    // code to run after render goes here
    if (!thisPackage?.isRefrigerant) {
      setFieldValue(fieldNames.refrigerantType, '');
      setFieldValue(fieldNames.refrigerantWeight, '');
      setFieldValue(fieldNames.refrigerantWeightUnitOfMeasurement, 'LB');

      setFieldValue(fieldNames.iataDetails, iataDetailsSkeleton);
    }
  }, [thisPackage?.isRefrigerant]);
  useHotkeys(
    'f9',
    async () => {
      if (['worldemblem', 'clemson'].includes(transformAccountName(account))) {
        try {
          const result = getWeight();
          if (result) {
            if (thisPackage?.packageWeight?.unitOfMeasurement === KG) {
              result.lbs /= 2.2;
              result.lbs = parseFloat(result.lbs).toFixed(2);
            }
            updateWeightFromScale(result);
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
    { enableOnTags: ['INPUT', 'TEXTAREA', 'SELECT'] },
  );
  function timeout(d) {
    return new Promise((res) => setTimeout(res, d));
  }
  function openDocumentPreview(document) {
    setPreview(document);
  }
  async function onLoad() {
    await connect();
  }
  async function updateWeightFromScale(value) {
    const weight = value?.lbs;
    const weightSmall = value?.oz;

    changePackagingField({
      fieldName: 'weight',
      fieldValue: weight,
      applyToAllPackagesField: true,
    });
    changePackagingField({
      fieldName: 'weightSmall',
      fieldValue: weightSmall,
      applyToAllPackagesField: true,
    });
    await timeout(200);

    if (!expandedPackage) {
      setFieldValue(
        fieldNames.weight,
        weight,
      );
      setFieldValue(
        fieldNames.weightSmall,
        weightSmall,
      );
    } else {
      setFieldValue(
        `packages[${expandedPackage}].packageWeight.weight`,
        weight,
      );
      setFieldValue(
        `packages[${packageNumber}].packageWeight.weightSmall`,
        weightSmall,
      );
    }
    await timeout(200);
    setIsUpdatedByScale(true);
  }
  function findMatchingPackagingObjects(
    allObjects,
    thisObject,
    specificValueKey,
  ) {
    const selected = allObjects.find(
      (t) => t.carrierLabel === thisObject.carrierLabel
        && t.name === thisObject.packagingName
        && t.type === thisObject.packagingType,
    );
    return selected;
  }

  const fieldNames = {
    width: `packages[${packageNumber - 1}].dimensions.width`,
    length: `packages[${packageNumber - 1}].dimensions.length`,
    height: `packages[${packageNumber - 1}].dimensions.height`,
    dimensionsUnitOfMeasurement: `packages[${packageNumber - 1
    }].dimensions.unitOfMeasurement`,
    isDimensionsEnabled: `packages[${packageNumber - 1
    }].dimensions.isDimensionsEnabled`,
    packagingTypeCode: `packages[${packageNumber - 1}].packagingTypeCode`,
    packagingName: `packages[${packageNumber - 1}].packagingName`,
    carrierLabel: `packages[${packageNumber - 1}].carrierLabel`,
    packagingType: `packages[${packageNumber - 1}].packagingType`,
    weight: `packages[${packageNumber - 1}].packageWeight.weight`,
    weightSmall: `packages[${packageNumber - 1}].packageWeight.weightSmall`,
    isWeightEnabled: `packages[${packageNumber - 1
    }].packageWeight.isWeightEnabled`,
    iotVendor: `packages[${packageNumber - 1}].iot.vendorName`,
    iotDeviceId: `packages[${packageNumber - 1}].iot.deviceId`,
    weightUnitOfMeasurement: `packages[${packageNumber - 1
    }].packageWeight.unitOfMeasurement`,
    weightUnitOfMeasurementSmall: `packages[${packageNumber - 1
    }].packageWeight.unitOfMeasurementSmall`,
    amount: `packages[${packageNumber - 1}].declaredValue.amount`,
    currency: `packages[${packageNumber - 1}].declaredValue.currency`,
    nonRMSContents: `packages[${packageNumber - 1}].nonRMSContents`,
    referenceNumber1: `packages[${packageNumber - 1}].referenceNumber1`,
    referenceNumber2: `packages[${packageNumber - 1}].referenceNumber2`,
    isFragile: `packages[${packageNumber - 1}].isFragile`,
    isOverpack: `packages[${packageNumber - 1}].isOverpack`,
    isRefrigerant: `packages[${packageNumber - 1}].isRefrigerant`,
    refrigerantType: `packages[${packageNumber - 1}].refrigerantType`,
    refrigerantWeight: `packages[${packageNumber - 1}].dryIceWeight.weight`,
    refrigerantWeightUnitOfMeasurement: `packages[${packageNumber - 1
    }].dryIceWeight.unitOfMeasurement`,
    isDangerousGoods: `packages[${packageNumber - 1}].isDangerousGoods`,
    dryIcePackagingNumber: `packages[${packageNumber - 1
    }].iataDetails.packagingInstructions`,
    iataDetails: `packages[${packageNumber - 1}].iataDetails`,
    dangerousGoodsType: `packages[${packageNumber - 1}].dangerousGoodsType`,
    monetaryValue: `packages[${packageNumber - 1}].monetaryValue`,
    insuredValue: `packages[${packageNumber - 1}].insuredValue.amount`,
    insuredValueCurrency: `packages[${packageNumber - 1}].insuredValue.currency`,
    customsValue: `packages[${packageNumber - 1}].customsValue.amount`,
    minimumCustomsValue: `packages[${packageNumber - 1}].customsValue.minimum`,
    isOversized: `packages[${packageNumber - 1}].isOversized`,
    isResearchMaterial: `packages[${packageNumber - 1}].isResearchMaterial`,
    isMTARequired: `packages[${packageNumber - 1}].isMTARequired`,
    isSponseredResearch: `packages[${packageNumber - 1}].isSponseredResearch`,
    sponseredResearchNumber: `packages[${packageNumber - 1}].sponseredResearchNumber`,
    iotTriggers: `packages[${packageNumber - 1}].iot.triggers`,
    iotShockTrigger: `packages[${packageNumber - 1}].iot.triggers[${clientTriggers.shock.order
    }].enabled`,
    iotDepartureTrigger: `packages[${packageNumber - 1}].iot.triggers[${clientTriggers.departure.order
    }].enabled`,
    iotArrivalTrigger: `packages[${packageNumber - 1}].iot.triggers[${clientTriggers.arrival.order
    }].enabled`,
    iotShipmentStoppedTriggerEnabled: `packages[${packageNumber - 1
    }].iot.triggers[${clientTriggers.shipmentStopped.order}].enabled`,
    iotShipmentStoppedTriggerValue: `packages[${packageNumber - 1
    }].iot.triggers[${clientTriggers.shipmentStopped.order}].value`,
    iotConnectivityTrigger: `packages[${packageNumber - 1}].iot.triggers[${clientTriggers.connectivity.order
    }].value`,
    iotBatteryPowerTrigger: `packages[${packageNumber - 1}].iot.triggers[${clientTriggers.batteryPower.order
    }].value`,
    iotTemperatureTrigger: `packages[${packageNumber - 1}].iot.triggers[${clientTriggers.temperature.order
    }].value`,
    iotHumidityTrigger: `packages[${packageNumber - 1}].iot.triggers[${clientTriggers.humidity.order
    }].value`,
    iotPerimeterAddressName: `packages[${packageNumber - 1}].iot.triggers[${clientTriggers.perimeter.order
    }].address.name`,
    iotPerimeterAddressLine1: `packages[${packageNumber - 1}].iot.triggers[${clientTriggers.perimeter.order
    }].address.addressLine1`,
    iotPerimeterAddressLine2: `packages[${packageNumber - 1}].iot.triggers[${clientTriggers.perimeter.order
    }].address.addressLine2`,
    iotPerimeterAddressCity: `packages[${packageNumber - 1}].iot.triggers[${clientTriggers.perimeter.order
    }].address.city`,
    iotPerimeterAddressState: `packages[${packageNumber - 1}].iot.triggers[${clientTriggers.perimeter.order
    }].address.state`,
    iotPerimeterAddressZip: `packages[${packageNumber - 1}].iot.triggers[${clientTriggers.perimeter.order
    }].address.zip`,
    iotPerimeterAddressCountry: `packages[${packageNumber - 1}].iot.triggers[${clientTriggers.perimeter.order
    }].address.country`,
    iotPerimeterOperator: `packages[${packageNumber - 1}].iot.triggers[${clientTriggers.perimeter.order
    }].operator`,
    iotPerimeterShape: `packages[${packageNumber - 1}].iot.triggers[${clientTriggers.perimeter.order
    }].shape`,
    iotPerimeterRadius: `packages[${packageNumber - 1}].iot.triggers[${clientTriggers.perimeter.order
    }].radius`,
    packagingProfile: `packages[${packageNumber - 1}].profile`,
    carrierService: 'carrierService',
  };

  function rerenderDependentDropdowns(
    allPackages,
    { packagingType, carrierLabel },
  ) {
    const selectedType = packagingType;
    const carrier = carrierLabel;
    const newPackageProviders = allPackages
      .reduce((providerArr, option) => {
        if (
          !providerArr.find((el) => el.carrierLabel === option.carrierLabel)
        ) {
          if (selectedType && option.type === selectedType) {
            providerArr.push(option);
          }
        }
        return providerArr;
      }, [])
      .map((carrierObj) => ({
        value: carrierObj.carrierLabel,
        label: carrierObj.carrierLabel,
      }));

    const newPackageNames = allPackages
      .reduce((nameArr, option) => {
        if (
          carrier
          && option.carrierLabel === carrier
          && selectedType === option.type
        ) {
          if (!nameArr.find((el) => el.name === option.name)) {
            nameArr.push(option);
          }
        }
        return nameArr;
      }, [])
      .map((nameObj) => ({ value: nameObj.name, label: nameObj.name }));
    setPackageProviders(newPackageProviders);
    setPackageNames(newPackageNames);
  }

  React.useEffect(() => {
    miscClient.loadIotVendors().then(setIotVendors);
    miscClient.loadPackagingDropdownData(isBattery ? '' : freightType).then((data) => {
      setPackagingDropdownData(data);
      setPackageTypes(
        Object.keys(
          data.packageTypes.reduce((optionsObj, c) => {
            // eslint-disable-next-line no-param-reassign
            if (!(c.name in optionsObj)) optionsObj[c.type] = true;
            return optionsObj;
          }, {}),
        ).map((type, idx) => ({ value: type, label: type, id: idx })),
      );
    });
    miscClient
      .loadPackageProfiles(isBattery ? '' : freightType)
      .then((data) => {
        setPackageProfiles(data);
      });
    if (hiddenFields.includes('material') || isLithiumBatteryContainedOrPacked) {
      miscClient.loadRefrigerantTypes().then((data) => {
        setRefrigerantTypes(
          data?.map((v, idx) => ({
            ...v, value: v.type, label: v.description, id: idx,
          })),
        );
      });
      miscClient.loadDangerousGoodTypes().then((data) => {
        setDangerousGoodTypes(
          data?.map((v, idx) => ({
            ...v,
            value: v.type,
            label: v.description,
            id: idx,
          })),
        );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  React.useEffect(() => {
    setFieldValue(fieldNames.carrierService, carrierServiceCode);
  }, [serviceTypeDropdownData]);
  React.useEffect(() => {
    const basePackagingObject = findMatchingPackagingObjects(
      packagingDropdownData.packageTypes,
      thisPackage,
    );

    setFieldValue(
      fieldNames.isDimensionsEnabled,
      !!(basePackagingObject && basePackagingObject.isDimensionsEnabled),
    );
    setFieldValue(
      fieldNames.isWeightEnabled,
      !!(basePackagingObject && basePackagingObject.isWeightEnabled),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [packagingDropdownData.packageTypes]);

  React.useEffect(() => {
    rerenderDependentDropdowns(packagingDropdownData.packageTypes, {
      packagingType: thisPackage.packagingType,
      carrierLabel: thisPackage.carrierLabel,
    });
  }, [
    packagingDropdownData.packageTypes,
    thisPackage.packagingType,
    thisPackage.carrierLabel,
    thisPackage,
  ]);

  React.useEffect(() => {
    if (!thisPackage.carrierLabel) setFieldValue(fieldNames.packagingName, '');
  }, [fieldNames.packagingName, setFieldValue, thisPackage.carrierLabel]);

  function changeDependentFieldOnChange({
    fieldValue,
    fieldName,
    dependentFieldNames,
    prereqFieldName = null,
  }) {
    if (prereqFieldName && !thisPackage[prereqFieldName]) return false;
    if (thisPackage[fieldName] !== fieldValue && dependentFieldNames) {
      dependentFieldNames.forEach((dependentFieldName) => {
        if (thisPackage[dependentFieldName]) {
          setFieldValue(fieldNames[dependentFieldName], '');
        }
      });
    }
  }

  function changePackagingField({
    fieldName,
    fieldValue,
    dependentFieldNames,
    prereqFieldName,
    applyToAllPackagesField,
  }) {
    if (applyToAllPackagesField) {
      toggleApplyToAllPackages();
    }

    if (dependentFieldNames) {
      changeDependentFieldOnChange({
        fieldValue,
        fieldName,
        dependentFieldNames,
        prereqFieldName,
      });
    }
  }
  function changePackagingInstructions(value) {
    const instructions = { ...refrigerentTypes.find((r) => r.value === value) };
    delete instructions.value;
    delete instructions.label;
    delete instructions.id;
    delete instructions.documentURL;
    delete instructions.type;
    delete instructions.description;

    setFieldValue(
      fieldNames.iataDetails,
      instructions,
    );
  }
  // These are the fields set when the packaging is selected
  const getControlledSelectionFields = (type) => [
    {
      fieldName: fieldNames.width,
      objectName: 'width',
      defaultValue: '0',
    },
    {
      fieldName: fieldNames.length,
      objectName: 'length',
      defaultValue: '0',
    },
    {
      fieldName: fieldNames.height,
      objectName: 'height',
      defaultValue: '0',
    },
    {
      fieldName: fieldNames.dimensionsUnitOfMeasurement,
      objectName: 'unitOfMeasurement',
    },
    {
      fieldName: fieldNames.packagingTypeCode,
      objectName: 'packageTypeCode',
    },
    {
      fieldName: fieldNames.weight,
      objectName: 'weight',
    },
    {
      fieldName: fieldNames.weightUnitOfMeasurement,
      objectName: 'weightUnitOfMeasurement',
    },
    {
      objectName: 'isWeightEnabled',
      fieldName: fieldNames.isWeightEnabled,
      defaultValue: false,
    },
    {
      objectName: 'isDimensionsEnabled',
      fieldName: fieldNames.isDimensionsEnabled,
      defaultValue: false,
    },
  ];

  const blockWeightAndDimensionInputs = (val) => val.match(/\.[0-9]{3,}/);

  function handleChange() {
    setExpanded();
  }

  function updateControlledField(matchObj) {
    if (!isEmpty(matchObj)) {
      assign(
        matchObj,
        pick(
          thisPackage,
          ['carrierLabel', 'packagingType', 'packagingName'].filter(
            (key) => !(key in matchObj),
          ),
        ),
      );
      const selected = packagingDropdownData.packageTypes.find(
        (t) => t.carrierLabel === matchObj.carrierLabel
          && t.name === matchObj.packagingName
          && t.type === matchObj.packagingType,
      );
      getControlledSelectionFields(thisPackage.packagingType).forEach(
        (updateObj) => {
          const valueToSet = getAcceptableValueToSet(
            selected[updateObj.objectName],
            updateObj.defaultValue,
            updateObj.transform,
          );
          if (valueToSet !== undefined) {
            if (updateObj.fieldName) {
              setFieldValue(updateObj.fieldName, valueToSet);
            }
          }
        },
      );
    }
  }
  async function changePackagingProfile(value) {
    const packageProfile = packageProfiles.find((pp) => pp.profileName === value);
    changePackagingField({
      fieldName: 'packagingType',
      fieldValue: packageProfile.packagingType,
      dependentFieldNames: ['carrierLabel', 'packagingName'],
    });
    setFieldValue(fieldNames.packagingType, packageProfile.packagingType);
    setFieldValue(fieldNames.carrierLabel, packageProfile.carrierLabel);
    setFieldValue(fieldNames.packagingName, packageProfile.packagingName);
    await timeout(300);
    updateControlledField({
      packagingName: packageProfile.packagingName,
      packagingType: packageProfile.packagingType,
      carrierLabel: packageProfile.carrierLabel,
    });
    if (packageProfile?.length) {
      setFieldValue(fieldNames.length, packageProfile.length);
    }
    if (packageProfile?.width) {
      setFieldValue(fieldNames.width, packageProfile.width);
    }
    if (packageProfile?.height) {
      setFieldValue(fieldNames.height, packageProfile.height);
    }
  }
  function clearPackagingProfile() {
    setFieldValue(fieldNames.packagingProfile, '');
  }
  function changeCarrierService() {
    setFieldValue(fieldNames.carrierService, '');
  }

  return (
    <>
      {/* {account === 'worldemblem' && <AutoSaveFields values={values} setValues={setValues} formik={formik} />} */}
      <hr className={classes.itemDivider} />
      <ExpansionPanel
        expanded={expanded}
        className={classes.expansionPanel}
        onChange={handleChange}
        TransitionProps={{ unmountOnExit: true }}
      >
        <ExpansionPanelSummary
          classes={{
            root: classes.expansionPanelSummaryRoot,
            content: classes.expansionPanelSummary,
          }}
          expandIcon={(
            <ExpandMoreIcon
              color="primary"
              classes={{ colorPrimary: classes.expansionIcon }}
            />
          )}
          aria-controls="panel1a-content"
          id="panel1a-header"
          IconButtonProps={{
            disableRipple: true,
            disableFocusRipple: true,
          }}
        >
          <Grid container justify="space-between">
            <Grid item>
              <Typography className={classes.formSectionTitle}>
                {`Package ${packageNumber} of ${numberOfPackages} Details`}
                &nbsp; &nbsp;
                {isIotEnabled(thisPackage) && <img src={iotIcon} alt="" />}
              </Typography>
            </Grid>
            <Grid item>
              <Button
                disabled={disableDelete}
                onClick={(e) => {
                  e.stopPropagation();
                  deletePackage();
                }}
              >
                <DeleteIcon
                  className={`${classes.deleteIcon} ${disableDelete ? 'disabled' : ''
                  }`}
                />
              </Button>
            </Grid>
          </Grid>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails
          classes={{ root: classes.shipmentExpansionPanelDetailsRoot }}
        >
          <Grid
            container
            spacing={0}
            className={classes.packageDetailsContainer}
          >
            <Grid container className={classes.detailsFormWrap}>
              <Grid container item justify="space-between" alignItems="center">
                <Grid
                  item
                  container
                  direction="column"
                  spacing={2}
                  style={{ width: '80%' }}
                >
                  <Grid container justify="space-between" item spacing={2}>
                    {!hiddenFields.includes('items') && (
                      <>
                        <Grid item>
                          <Typography className={classes.formSectionHeader}>
                            Items
                          </Typography>
                        </Grid>
                        <Grid item xs={12} style={{ width: '100%' }}>
                          <AutocompleteWithIncrementInput
                            packageNumber={packageNumber}
                            itemsInThisPackage={productItems}
                            itemsInAllPackages={totalItemsForShipment}
                            setItemsInAllPackages={setTotalItemsForShipment}
                            setFieldValue={setFieldValue}
                            packageWeight={packageWeight}
                            packageCustomsValue={packageCustomsValue}
                            weightUnitOfMeasurementForPackage={
                              weightUnitOfMeasurementForPackage
                            }
                            account={account}
                            isChemicalHazmat={isChemicalHazmat}
                          />
                        </Grid>
                      </>
                    )}
                    <Grid
                      ref={myRef}
                      item
                      style={{
                        width: '100%',
                        ...(hiddenFields.includes('items') && {
                          paddingTop: '24px',
                        }),
                      }}
                    >
                      <Field
                        component={CustomSelectTextField}
                        style={{ width: '100%' }}
                        type="text"
                        name={fieldNames.packagingProfile}
                        label="Package Profile"
                        className={classes.standardFormTextfield}
                        data-testid="packaging-type-input"
                        customOnChange={(e) => {
                          changePackagingProfile(e.target.value);
                        }}
                      >
                        {packageProfiles.map((type) => (
                          <MenuItem
                            key={type.label}
                            value={type.profileName}
                            data-testid="package-type-menu-item"
                          >
                            {type.label}
                          </MenuItem>
                        ))}
                      </Field>
                    </Grid>
                    <Grid
                      item
                      style={{
                        width: '48%',
                        ...(hiddenFields.includes('items') && {
                          paddingTop: '24px',
                        }),
                      }}
                    >
                      <Field
                        component={CustomSelectTextField}
                        style={{ width: '100%' }}
                        type="text"
                        name={fieldNames.packagingType}
                        label="Package Type"
                        className={classes.standardFormTextfield}
                        data-testid="packaging-type-input"
                        customOnChange={(e) => {
                          changePackagingField({
                            fieldName: 'packagingType',
                            fieldValue: e.target.value,
                            dependentFieldNames: [
                              'carrierLabel',
                              'packagingName',
                            ],
                            applyToAllPackagesField: true,
                          });
                          if (account === 'worldemblem') {
                            clearPackagingProfile();
                          }
                        }}
                      >
                        {packageTypes.map((type) => (
                          <MenuItem
                            key={type.label}
                            value={type.value}
                            data-testid="package-type-menu-item"
                          >
                            {type.label}
                          </MenuItem>
                        ))}
                      </Field>
                    </Grid>
                    <Grid
                      item
                      style={{
                        width: '48%',
                        ...(hiddenFields.includes('items') && {
                          paddingTop: '24px',
                        }),
                      }}
                    >
                      <OptionalField
                        style={{ width: '100%' }}
                        component={CustomSelectTextField}
                        type="text"
                        name={fieldNames.carrierLabel}
                        label="Package Provider"
                        className={classes.standardFormTextfield}
                        prereqName="packagingType"
                        data-testid="package-provider-input"
                        currentPackage={thisPackage}
                        customOnChange={(e) => {
                          changePackagingField({
                            fieldName: 'carrierLabel',
                            fieldValue: e.target.value,
                            dependentFieldNames: ['packagingName'],
                            prereqFieldName: 'packagingType',
                            applyToAllPackagesField: true,
                          });
                          if (account === 'worldemblem') {
                            clearPackagingProfile();
                          }
                        }}
                      >
                        {packageProviders.map((type) => (
                          <MenuItem
                            key={type.label}
                            data-testid="package-provider-menu-item"
                            value={type.value}
                          >
                            {type.label}
                          </MenuItem>
                        ))}
                      </OptionalField>
                    </Grid>

                    <Grid
                      item
                      style={{
                        width: '48%',
                        ...(hiddenFields.includes('items') && {
                          paddingTop: '24px',
                        }),
                      }}
                    >
                      <OptionalField
                        style={{ width: '100%' }}
                        component={CustomSelectTextField}
                        type="text"
                        name={fieldNames.packagingName}
                        label="Package Name"
                        className={classes.standardFormTextfield}
                        prereqName="carrierLabel"
                        data-testid="package-name-input"
                        currentPackage={thisPackage}
                        customOnChange={(e) => {
                          updateControlledField({
                            packagingName: e.target.value,
                          });
                          changePackagingField({
                            fieldName: 'packagingName',
                            fieldValue: e.target.value,
                            prereqFieldName: 'carrierLabel',
                            applyToAllPackagesField: true,
                          });
                          if (account === 'worldemblem') {
                            clearPackagingProfile();
                          }
                        }}
                      >
                        {packageNames.map((type) => (
                          <MenuItem
                            key={type.label}
                            value={type.value}
                            data-testid="package-name-menu-item"
                          >
                            {type.label}
                          </MenuItem>
                        ))}
                      </OptionalField>
                    </Grid>
                    <Grid
                      item
                      style={{
                        width: '48%',
                        ...(hiddenFields.includes('items') && {
                          paddingTop: '24px',
                        }),
                      }}
                    >
                      {account === 'worldemblem' && (
                        <Field
                          component={CustomSelectTextField}
                          style={{ width: '100%' }}
                          type="text"
                          name={fieldNames.carrierService}
                          label="Carrier Service "
                          className={classes.standardFormTextfield}
                          data-testid="packaging-type-input"
                          customOnChange={(e) => {
                            changeCarrierService(e.target.value);
                          }}
                        >
                          {serviceTypeDropdownData.map((type) => (
                            <MenuItem
                              key={type.serviceTypeCode}
                              value={type.serviceTypeCode}
                              data-testid="package-type-menu-item"
                            >
                              {`${type.carrierLabel} ${type.description}`}
                            </MenuItem>
                          ))}
                        </Field>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  style={{
                    width: '20%',
                    ...(account === 'worldemblem' && {
                      alignSelf: 'flex-end',
                    }),
                    ...(hiddenFields.includes('items') && {
                      paddingTop: '24px',
                    }),
                  }}
                >
                  {thisPackage.packagingTypeCode
                    && thisPackage.packagingName && (
                      <PackagingImage
                        packagingName={thisPackage.packagingName}
                        packagingTypeCode={thisPackage.packagingTypeCode}
                      />
                  )}
                </Grid>
              </Grid>
              <Grid item container spacing={2}>
                <Grid item container direction="column" spacing={2} xs={6}>
                  <Grid item>
                    <Typography className={classes.formSectionHeader}>
                      Enter package dimensions
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    container
                    spacing={2}
                    data-testid="package-dimension-inputs"
                  >
                    <Grid item xs={2}>
                      <Field
                        component={NewOrderCustomTextField}
                        type="text"
                        name={fieldNames.length}
                        label="Length"
                        disabled={
                          disabled
                          || !getIn(
                            thisPackage,
                            stripPrefixFromFieldName(
                              fieldNames.isDimensionsEnabled,
                              /packages\[\d+\]\./,
                            ),
                          )
                        }
                        inputProps={{ maxLength: 7 }}
                        blockValue={blockWeightAndDimensionInputs}
                        customOnChange={(e) => {
                          changePackagingField({
                            fieldName: 'length',
                            fieldValue: e.target.value,
                            applyToAllPackagesField: true,
                          });
                        }}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Field
                        component={NewOrderCustomTextField}
                        type="text"
                        name={fieldNames.width}
                        label="Width"
                        disabled={
                          disabled
                          || !getIn(
                            thisPackage,
                            stripPrefixFromFieldName(
                              fieldNames.isDimensionsEnabled,
                              /packages\[\d+\]\./,
                            ),
                          )
                        }
                        inputProps={{ maxLength: 7 }}
                        blockValue={blockWeightAndDimensionInputs}
                        customOnChange={(e) => {
                          changePackagingField({
                            fieldName: 'width',
                            fieldValue: e.target.value,
                            applyToAllPackagesField: true,
                          });
                        }}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Field
                        component={NewOrderCustomTextField}
                        type="text"
                        name={fieldNames.height}
                        label="Height"
                        disabled={
                          disabled
                          || !getIn(
                            thisPackage,
                            stripPrefixFromFieldName(
                              fieldNames.isDimensionsEnabled,
                              /packages\[\d+\]\./,
                            ),
                          )
                        }
                        inputProps={{ maxLength: 7 }}
                        blockValue={blockWeightAndDimensionInputs}
                        customOnChange={(e) => {
                          changePackagingField({
                            fieldName: 'height',
                            fieldValue: e.target.value,
                            applyToAllPackagesField: true,
                          });
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      className={classes.smallSelectFieldContainer}
                    >
                      <Field
                        component={CustomSelectTextField}
                        name={fieldNames.dimensionsUnitOfMeasurement}
                        type="text"
                        label="Unit"
                        disabled={
                          disabled
                          || !getIn(
                            thisPackage,
                            stripPrefixFromFieldName(
                              fieldNames.isDimensionsEnabled,
                              /packages\[\d+\]\./,
                            ),
                          )
                        }
                        className={classes.smallSelectField}
                        inputProps={{ maxLength: 7 }}
                        blockValue={blockWeightAndDimensionInputs}
                        customOnChange={(e) => {
                          changePackagingField({
                            fieldName: 'dimensionsUnitOfMeasurement',
                            fieldValue: e.target.value,
                            applyToAllPackagesField: true,
                          });
                        }}
                      >
                        {LENGTH_UNIT_OF_MEASUREMENTS_WITH_IDS.map((type) => (
                          <MenuItem key={type.id} value={type.value}>
                            {type.label}
                          </MenuItem>
                        ))}
                      </Field>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item container direction="column" spacing={2} xs={6}>
                  <Grid item>
                    <Typography className={classes.formSectionHeader}>
                      Enter weight
                    </Typography>
                  </Grid>
                  <Grid item container spacing={2}>
                    <Grid item xs={3}>
                      <Field
                        component={NewOrderCustomTextField}
                        type="text"
                        name={fieldNames.weight}
                        label="Weight"
                        inputProps={{ maxLength: 7 }}
                        blockValue={blockWeightAndDimensionInputs}
                        disabled={disabled}
                        style={{ width: '100%' }}
                        customOnChange={(e) => {
                          changePackagingField({
                            fieldName: 'weight',
                            fieldValue: e.target.value,
                            applyToAllPackagesField: true,
                          });
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      className={classes.smallSelectFieldContainer}
                    >
                      <Field
                        component={CustomSelectTextField}
                        type="text"
                        name={fieldNames.weightUnitOfMeasurement}
                        label="Unit"
                        className={classes.smallSelectField}
                        disabled={disabled}
                        customOnChange={(e) => {
                          changePackagingField({
                            fieldName: 'weightUnitOfMeasurement',
                            fieldValue: e.target.value,
                            applyToAllPackagesField: true,
                          });
                        }}
                      >
                        {weightUnits?.map((type) => (
                          <MenuItem key={type.id} value={type.weightCode}>
                            {type.weightCode}
                          </MenuItem>
                        ))}
                      </Field>
                    </Grid>
                    {account !== 'worldemblem' && (
                      <Grid item xs={3}>
                        <Field
                          component={NewOrderCustomTextField}
                          type="text"
                          name={fieldNames.weightSmall}
                          label="Weight"
                          inputProps={{ maxLength: 7 }}
                          blockValue={blockWeightAndDimensionInputs}
                          disabled={disabled}
                          style={{ width: '100%' }}
                          customOnChange={(e) => {
                            changePackagingField({
                              fieldName: 'weightSmall',
                              fieldValue: e.target.value,
                              applyToAllPackagesField: true,
                            });
                          }}
                        />
                      </Grid>
                    )}
                    {account !== 'worldemblem' && (
                      <Grid
                        item
                        xs={2}
                        className={classes.smallSelectFieldContainer}
                      >
                        <Field
                          component={CustomSelectTextField}
                          type="text"
                          name={fieldNames.weightUnitOfMeasurementSmall}
                          label="Unit"
                          className={classes.smallSelectField}
                          disabled={disabled}
                          customOnChange={(e) => {
                            changePackagingField({
                              fieldName: 'weightUnitOfMeasurementSmall',
                              fieldValue: e.target.value,
                              applyToAllPackagesField: true,
                            });
                          }}
                        >
                          {smallWeightUnits?.map((type) => (
                            <MenuItem key={type.id} value={type.weightCode}>
                              {type.weightCode}
                            </MenuItem>
                          ))}
                        </Field>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item container spacing={1}>
                <Grid item container direction="column" spacing={2}>
                  <Grid item>
                    <Typography className={classes.formSectionHeader}>
                      Monetary value
                    </Typography>
                  </Grid>
                  <Grid item container spacing={2}>
                    {!hiddenFields.includes('declaredValue') && (
                      <>
                        <Grid item xs={3}>
                          <FastField
                            component={NewOrderCustomTextField}
                            type="text"
                            name={fieldNames.amount}
                            label="Declared Value"
                            style={{ width: '100%' }}
                            customOnChange={(e) => {
                              changePackagingField({
                                fieldName: 'amount',
                                fieldValue: e.target.value,
                                applyToAllPackagesField: true,
                              });
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          className={classes.smallSelectFieldContainer}
                        >
                          <Field
                            component={CustomSelectField}
                            type="text"
                            name={fieldNames.currency}
                            label=""
                            className={classes.smallSelectField}
                            disabled={disabled}
                          >
                            {packagingDropdownData.currencies.map((type) => (
                              <MenuItem key={type.id} value={type.currencyCode}>
                                {type.currencyCode}
                              </MenuItem>
                            ))}
                          </Field>
                        </Grid>
                      </>
                    )}

                    {!hiddenFields.includes('insuredValue') && (
                      <>
                        <Grid item xs={3}>
                          <Field
                            component={NewOrderCustomTextField}
                            type="text"
                            name={fieldNames.insuredValue}
                            label="Insurance Value"
                            style={{ width: '100%' }}
                            customOnChange={(e) => {
                              changePackagingField({
                                fieldName: 'insuredValue',
                                fieldValue: e.target.value,
                                applyToAllPackagesField: true,
                              });
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          className={classes.smallSelectFieldContainer}
                        >
                          <Field
                            component={CustomSelectField}
                            type="text"
                            name={fieldNames.insuredValueCurrency}
                            label=""
                            className={classes.smallSelectField}
                            disabled={disabled}
                          >
                            {packagingDropdownData.currencies.map((type) => (
                              <MenuItem key={type.id} value={type.currencyCode}>
                                {type.currencyCode}
                              </MenuItem>
                            ))}
                          </Field>
                        </Grid>
                      </>
                    )}
                  </Grid>
                  <Grid item container spacing={2}>
                    {!hiddenFields.includes('customsValue') && (
                      <>
                        <Grid item xs={3}>
                          <FastField
                            component={NewOrderCustomTextField}
                            type="text"
                            name={fieldNames.customsValue}
                            label="Customs Value"
                            validate={(val) => {
                              if (
                                !Number.isNaN(val)
                                && Number(val)
                                < Number(
                                  getIn(
                                    thisPackage,
                                    stripPrefixFromFieldName(
                                      fieldNames.amount,
                                      /packages\[\d\]\./,
                                    ),
                                  ),
                                )
                              ) {
                                return `Amount cannot be less than declared value of $${getIn(
                                  thisPackage,
                                  stripPrefixFromFieldName(
                                    fieldNames.amount,
                                    /packages\[\d\]\./,
                                  ),
                                )}`;
                              }
                              if (
                                !Number.isNaN(val)
                                && Number(val)
                                < Number(
                                  getIn(
                                    thisPackage,
                                    stripPrefixFromFieldName(
                                      fieldNames.minimumCustomsValue,
                                      /packages\[\d\]\./,
                                    ),
                                  ),
                                )
                              ) {
                                return `Amount cannot be less than value of items in package: $${getIn(
                                  thisPackage,
                                  stripPrefixFromFieldName(
                                    fieldNames.minimumCustomsValue,
                                    /packages\[\d\]\./,
                                  ),
                                )}`;
                              }
                              return undefined;
                            }}
                            style={{ width: '100%' }}
                            customOnChange={(e) => {
                              changePackagingField({
                                fieldName: 'customsValue',
                                fieldValue: e.target.value,
                                applyToAllPackagesField: true,
                              });
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          className={classes.smallSelectFieldContainer}
                        >
                          <Field
                            component={CustomSelectField}
                            type="text"
                            name={fieldNames.currency}
                            label=""
                            className={classes.smallSelectField}
                            disabled={disabled}
                          >
                            {packagingDropdownData.currencies.map((type) => (
                              <MenuItem key={type.id} value={type.currencyCode}>
                                {type.currencyCode}
                              </MenuItem>
                            ))}
                          </Field>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Grid>
                {account !== 'worldemblem' && (
                  <Grid container>
                    <Grid item container direction="column" xs={6} spacing={2}>
                      <Grid item>
                        <Typography className={classes.formSectionHeader}>
                          Package Contents
                        </Typography>
                      </Grid>
                      <Grid item>
                        <FastField
                          component={NewOrderCustomTextField}
                          type="text"
                          name={fieldNames.nonRMSContents}
                          label=""
                          className={classes.textAreaField}
                          multiline
                          rowsMax="4"
                          rows="2"
                          data-testid="package-contents"
                          customOnChange={(e) => {
                            changePackagingField({
                              fieldName: 'nonRMSContents',
                              fieldValue: e.target.value,
                              applyToAllPackagesField: true,
                            });
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid item container direction="column" xs={6} spacing={2}>
                      <Grid item>
                        <Typography className={classes.formSectionHeader}>
                          Reference Numbers
                        </Typography>
                      </Grid>
                      <Grid container item direction="row" spacing={2} justifyContent="space-between">
                        <Grid item xs={5}>
                          <FastField
                            component={NewOrderCustomTextField}
                            type="text"
                            name={fieldNames.referenceNumber1}
                            label="Reference #1"
                            className={classes.referenceField}
                            inputProps={{ maxLength: 30 }}
                          />
                        </Grid>
                        <Grid item xs={5}>
                          <FastField
                            component={NewOrderCustomTextField}
                            type="text"
                            name={fieldNames.referenceNumber2}
                            label="Reference #2"
                            className={classes.referenceField}
                            inputProps={{ maxLength: 30 }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
              <Grid spacing={2}>
                {account === 'worldemblem' && (
                  <Grid container item direction="row">
                    <Grid item container direction="column" xs={4} spacing={2}>
                      <Grid item>
                        <Typography className={classes.formSectionHeader}>
                          Package Contents
                        </Typography>
                      </Grid>
                      <Grid item>
                        <FastField
                          component={NewOrderCustomTextField}
                          type="text"
                          name={fieldNames.nonRMSContents}
                          label=""
                          className={classes.textAreaFieldWe}
                          multiline
                          rowsMax="4"
                          rows="2"
                          data-testid="package-contents"
                          customOnChange={(e) => {
                            changePackagingField({
                              fieldName: 'nonRMSContents',
                              fieldValue: e.target.value,
                              applyToAllPackagesField: true,
                            });
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid item container direction="column" xs={8} spacing={2}>
                      <Grid item>
                        <Typography className={classes.formSectionHeader}>
                          Reference Numbers
                        </Typography>
                      </Grid>
                      <Grid container item direction="row" spacing={2}>
                        <Grid item xs={6}>
                          <FastField
                            component={NewOrderCustomTextField}
                            type="text"
                            name={fieldNames.referenceNumber1}
                            label="Reference #1"
                            className={classes.referenceField}
                            inputProps={{ maxLength: 30 }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <FastField
                            component={NewOrderCustomTextField}
                            type="text"
                            name={fieldNames.referenceNumber2}
                            label="Reference #2"
                            className={classes.referenceField}
                            inputProps={{ maxLength: 30 }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                <Grid container>
                  {!hiddenFields.includes('isOverpack') && (
                    <Grid item>
                      <Field
                        component={ControlledCheckbox}
                        name={fieldNames.isOverpack}
                        label={(
                          <Typography
                            color="primary"
                            style={{
                              fontSize: '14px',
                              color: 'white',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            Overpack
                          </Typography>
                        )}
                      />
                    </Grid>
                  )}
                  {!hiddenFields.includes('isFragile') && (
                    <Grid item>
                      <Field
                        component={ControlledCheckbox}
                        name={fieldNames.isFragile}
                        label={(
                          <Typography
                            color="primary"
                            style={{
                              fontSize: '14px',
                              color: 'white',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            Package will contain fragile items
                          </Typography>
                        )}
                      />
                    </Grid>
                  )}
                  {!hiddenFields.includes('isOversized') && (
                    <Grid item>
                      <Field
                        component={ControlledCheckbox}
                        name={fieldNames.isOversized}
                        label={(
                          <Typography
                            color="primary"
                            style={{
                              fontSize: '14px',
                              color: 'white',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            Package will contain an over-sized, odd-sized, or
                            exceptionally heavy item
                          </Typography>
                        )}
                      />
                    </Grid>
                  )}
                </Grid>
                {!hiddenFields.includes('isRefrigerant')
                  && hiddenFields.includes('material') && (
                    <Grid item>
                      <Field
                        component={ControlledCheckbox}
                        name={fieldNames.isRefrigerant}
                        label={(
                          <Typography
                            color="primary"
                            style={{
                              fontSize: '14px',
                              color: 'white',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            Package will contain refrigerant
                          </Typography>
                        )}
                      />
                    </Grid>
                )}
                {!hiddenFields.includes('isRefrigerant')
                  && hiddenFields.includes('material')
                  && formikRef.current?.state?.values?.packages[packageNumber - 1]
                    ?.isRefrigerant && (
                    <>
                      <Grid item container spacing={2} direction="row">
                        <Grid item xs={4}>
                          <Field
                            component={CustomSelectTextField}
                            style={{ width: '100%' }}
                            type="text"
                            name={fieldNames.refrigerantType}
                            label="Refrigerent Type"
                            className={classes.standardFormTextfield}
                            data-testid="packaging-type-input"
                            customOnChange={(e) => {
                              changePackagingField({
                                fieldName: 'refrigerantType',
                                fieldValue: e.target.value,
                                dependentFieldNames: [],
                                applyToAllPackagesField: true,
                              });
                              changePackagingInstructions(e.target.value);
                            }}
                          >
                            {refrigerentTypes.map((type) => (
                              <MenuItem
                                key={type.label}
                                value={type.value}
                                data-testid="package-type-menu-item"
                              >
                                {type.label}
                              </MenuItem>
                            ))}
                          </Field>
                        </Grid>
                        <Grid item xs={3}>
                          <Field
                            component={NewOrderCustomTextField}
                            type="text"
                            name={fieldNames.refrigerantWeight}
                            label="Weight"
                            inputProps={{ maxLength: 7 }}
                            blockValue={blockWeightAndDimensionInputs}
                            disabled={disabled}
                            style={{ width: '100%' }}
                            customOnChange={(e) => {
                              changePackagingField({
                                fieldName: 'refrigerantWeight',
                                fieldValue: e.target.value,
                                applyToAllPackagesField: true,
                              });
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          className={classes.smallSelectFieldContainer}
                        >
                          <Field
                            component={CustomSelectTextField}
                            type="text"
                            name={fieldNames.refrigerantWeightUnitOfMeasurement}
                            label="Unit"
                            className={classes.smallSelectField}
                            disabled={disabled}
                            customOnChange={(e) => {
                              changePackagingField({
                                fieldName: 'refrigerantWeightUnitOfMeasurement',
                                fieldValue: e.target.value,
                                applyToAllPackagesField: true,
                              });
                            }}
                          >
                            {weightUnits?.map((type) => (
                              <MenuItem key={type.id} value={type.weightCode}>
                                {type.weightCode}
                              </MenuItem>
                            ))}
                          </Field>
                        </Grid>
                      </Grid>
                      <Grid item container direction="column" spacing={1}>
                        <Grid
                          item
                          container
                          direction="row"
                          alignItems="center"
                        >
                          <Typography
                            color="primary"
                            className={classes.formFieldHeader}
                          >
                            {`${refrigerentTypes.find(
                              (r) => r.value
                                === formikRef.current?.state?.values?.packages[
                                  packageNumber - 1
                                ]?.refrigerantType,
                            )?.label
                              ? refrigerentTypes.find(
                                (r) => r.value
                                  === formikRef.current?.state?.values
                                    ?.packages[packageNumber - 1]
                                    ?.refrigerantType,
                              )?.label
                              : ''
                            } Packaging Instructions`}
                          </Typography>
                          {/* <IconButton
                            color="secondary"
                            onClick={() => openDocumentPreview(dryIceData.document)}
                          >
                            <LinkIcon />
                          </IconButton> */}
                        </Grid>
                        <Grid item container spacing={1}>
                          <Grid item xs={4}>
                            <Field
                              label="Packaging Number"
                              name={fieldNames.dryIcePackagingNumber}
                              component={NewOrderCustomTextField}
                              type="text"
                              style={{ width: '100%' }}
                              disabled
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                )}
                <Grid item>
                  {!hiddenFields.includes('isDangerousGoods')
                    && (hiddenFields.includes('material')
                      || isLithiumBatteryContainedOrPacked)
                    && formikRef?.current?.state?.values?.packages[
                      packageNumber - 1
                    ]?.isDangerousGoods && (
                      <Field
                        component={ControlledCheckbox}
                        name={fieldNames.isDangerousGoods}
                        disabled={
                          formikRef?.current?.state?.values?.packages?.length
                            ? formikRef?.current?.state?.values?.packages[
                              packageNumber - 1
                            ]?.isDangerousGoods
                            && !formikRef?.current?.state?.touched
                              ?.isDangerousGoods
                            : false
                        }
                        label={(
                          <Typography
                            color="primary"
                            style={{
                              fontSize: '14px',
                              color: 'white',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            Package will contain dangerous goods
                          </Typography>
                        )}
                      />
                  )}
                </Grid>
                {!hiddenFields.includes('isDangerousGoods')
                  && (hiddenFields.includes('material')
                    || isLithiumBatteryContainedOrPacked)
                  && formikRef?.current?.state?.values?.packages[packageNumber - 1]
                    ?.isDangerousGoods && (
                    <Grid
                      item
                      style={{
                        width: '60%',
                        ...(hiddenFields.includes('items') && {
                          paddingTop: '24px',
                        }),
                      }}
                    >
                      <Field
                        component={CustomSelectTextField}
                        style={{ width: '100%' }}
                        type="text"
                        name={fieldNames.dangerousGoodsType}
                        label="Dangerous Goods Type"
                        className={classes.standardFormTextfield}
                        data-testid="packaging-type-input"
                        customOnChange={(e) => {
                          changePackagingField({
                            fieldName: 'dangerousGoodsType',
                            fieldValue: e.target.value,
                            dependentFieldNames: [],
                            applyToAllPackagesField: true,
                          });
                        }}
                      >
                        {dangerousGoodsTypes.map((type) => (
                          <MenuItem
                            key={type.label}
                            value={type.value}
                            data-testid="package-type-menu-item"
                          >
                            {type.label}
                          </MenuItem>
                        ))}
                      </Field>
                    </Grid>
                )}
                {['clemson'].includes(account) && (
                  <Grid item>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">
                        <Grid item container spacing={2}>
                          <Grid
                            item
                            container
                            direction="row"
                            alignItems="center"
                          >
                            <Typography
                              color="primary"
                              className={classes.formSectionHeader}
                            >
                              Will these materials be used for research?
                            </Typography>

                          </Grid>
                        </Grid>
                      </FormLabel>
                      <RadioGroup
                        aria-label="Research Material"
                        name={fieldNames.isResearchMaterial}
                        value={get(formikRef, `current.state.values.packages[${packageNumber - 1}].isResearchMaterial`, false) ? 'yes' : 'no'}
                        row
                        onChange={(e) => {
                          const value = e.target.value === 'yes';
                          setFieldValue(fieldNames.isResearchMaterial, value);
                        }}
                      >
                        {[
                          { value: 'yes', label: 'Yes' },
                          { value: 'no', label: 'No' },
                        ].map((option) => (
                          <FormControlLabel
                            key={option.value}
                            style={{ padding: '0 10px' }}
                            value={option.value}
                            control={<Radio style={{ color: colors.white }} />}
                            label={(
                              <Typography color="primary">
                                {option.label}
                              </Typography>
                            )}
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>

                  </Grid>
                )}
                {['umass', 'umasschan'].includes(account) && shippingType === 'international' && (
                  <Grid item>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">
                        <Grid item container spacing={2}>
                          <Grid
                            item
                            container
                            direction="row"
                            alignItems="center"
                          >
                            <Typography
                              color="primary"
                              className={classes.formSectionHeader}
                            >
                              Do you have an MTA or other executed agreement governing the transfer of tangible research materials ?
                            </Typography>
                          </Grid>
                        </Grid>
                      </FormLabel>
                      <RadioGroup
                        aria-label="Research Material"
                        name={fieldNames.isResearchMaterial}
                        value={get(formikRef, `current.state.values.packages[${packageNumber - 1}].isResearchMaterial`, false) ? 'yes' : 'no'}
                        row
                        onChange={(e) => {
                          const value = e.target.value === 'yes';
                          setFieldValue(fieldNames.isResearchMaterial, value);
                        }}
                      >
                        {[
                          { value: 'yes', label: 'Yes' },
                          { value: 'no', label: 'No' },
                        ].map((option) => (
                          <FormControlLabel
                            key={option.value}
                            style={{ padding: '0 10px' }}
                            value={option.value}
                            control={<Radio style={{ color: colors.white }} />}
                            label={(
                              <Typography color="primary">
                                {option.label}
                              </Typography>
                            )}
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>

                  </Grid>
                )}
                {['clemson'].includes(
                  transformAccountName(account),
                ) && formikRef?.current?.state?.values?.packages[packageNumber - 1]
                  ?.isResearchMaterial && (
                    <Grid style={{
                      display: 'flex',
                      flexDirection: 'row',
                      border: `1px solid ${colors.lightBackgroundGrey}`,
                      borderRadius: '5px',
                      color: colors.white,
                      padding: '15px 30px',
                      flexShrink: 0,
                    }}
                    >
                      <InfoOutlined style={{ fontSize: '32px', paddingRight: '10px' }} />
                      <Typography style={{ fontSize: '14px' }}>
                        You may need a Material Transfer Agreement (MTA) prior to shipping. Please complete this
                        {' '}
                        <a
                          href="https://clemson.ca1.qualtrics.com/jfe/form/SV_ehQD8YBV7059ZjM"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          intake form

                        </a>
                        {' '}
                        and a representative from the Office of Sponsored Programs will be in contact with you. Please do not ship any research material until authorized by the Office of Sponsored Programs.
                      </Typography>
                    </Grid>

                )}
                {['nwestern'].includes(
                  transformAccountName(account),
                ) && (
                <Grid item>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">
                      <Grid item container spacing={2}>
                        <Grid
                          item
                          container
                          direction="row"
                          alignItems="center"
                        >
                          <Typography
                            color="primary"
                            className={classes.formSectionHeader}
                          >
                            Does your shipment have a Material Transfer Agreement?
                          </Typography>

                        </Grid>
                      </Grid>
                    </FormLabel>
                    <RadioGroup
                      aria-label="Material Transfer Agreement"
                      name={fieldNames.isMTARequired}
                      value={get(formikRef, `current.state.values.packages[${packageNumber - 1}].isMTARequired`, false) ? 'yes' : 'no'}
                      row
                      onChange={(e) => {
                        const value = e.target.value === 'yes';

                        setFieldValue(fieldNames.isMTARequired, value);
                      }}
                    >
                      {[
                        { value: 'yes', label: 'Yes' },
                        { value: 'no', label: 'No' },
                      ].map((option) => (
                        <FormControlLabel
                          key={option.value}
                          style={{ padding: '0 10px' }}
                          value={option.value}
                          control={<Radio style={{ color: colors.white }} />}
                          label={(
                            <Typography color="primary">
                              {option.label}
                            </Typography>
                              )}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>

                </Grid>
                )}
                {formikRef?.current?.state?.values?.packages[packageNumber - 1]
                  ?.isMTARequired
                  && (
                    <Grid style={{
                      display: 'flex',
                      flexDirection: 'row',
                      border: `1px solid ${colors.lightBackgroundGrey}`,
                      borderRadius: '5px',
                      color: colors.white,
                      padding: '15px 30px',
                      flexShrink: 0,
                    }}
                    >
                      <InfoOutlined style={{ fontSize: '32px', paddingRight: '10px' }} />
                      <Typography style={{ fontSize: '14px' }}>
                        A Material Transfer Agreement (MTA) is a written
                        contract used to govern the transfer of research material between organizations.
                        For more information on determining if you need an MTA, please see this
                        {'  '}
                        <a
                          href="https://sponsoredresearch.northwestern.edu/agreements/mta.html"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          link

                        </a>
                        {'. '}
                      </Typography>
                    </Grid>
                  )}
                {['nwestern'].includes(
                  transformAccountName(account),
                ) && (
                <Grid item>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">
                      <Grid item container spacing={2}>
                        <Grid
                          item
                          container
                          direction="row"
                          alignItems="center"
                        >
                          <Typography
                            color="primary"
                            className={classes.formSectionHeader}
                          >
                            Is your shipment related to a Sponsored Research project?
                          </Typography>

                        </Grid>
                      </Grid>
                    </FormLabel>
                    <RadioGroup
                      aria-label="Sponsored Research project"
                      name={fieldNames.isSponseredResearch}
                      value={get(formikRef, `current.state.values.packages[${packageNumber - 1}].isSponseredResearch`, false) ? 'yes' : 'no'}
                      row
                      onChange={(e) => {
                        const value = e.target.value === 'yes';
                        setFieldValue(fieldNames.isSponseredResearch, value);
                      }}
                    >
                      {[
                        { value: 'yes', label: 'Yes' },
                        { value: 'no', label: 'No' },
                      ].map((option) => (
                        <FormControlLabel
                          key={option.value}
                          style={{ padding: '0 10px' }}
                          value={option.value}
                          control={<Radio style={{ color: colors.white }} />}
                          label={(
                            <Typography color="primary">
                              {option.label}
                            </Typography>
                              )}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>

                </Grid>
                )}
                {formikRef?.current?.state?.values?.packages[packageNumber - 1]
                  ?.isSponseredResearch && (
                    <Grid item container direction="column" spacing={2}>
                      <Grid item>
                        <FastField
                          component={NewOrderCustomTextField}
                          type="text"
                          name={fieldNames.sponseredResearchNumber}
                          label="SP Number"
                          className={classes.referenceField}
                          inputProps={{ maxLength: 50 }}
                        />
                      </Grid>
                    </Grid>
                  )}
                {/* {account !== 'worldemblem' && (
                  <Grid item container direction="column" spacing={2}>
                    <Grid item>
                      <Typography className={classes.formSectionHeader}>
                        Reference Numbers
                      </Typography>
                    </Grid>
                    <Grid item container direction="row" xs={12}>
                      <Grid item xs={6}>
                        <FastField
                          component={NewOrderCustomTextField}
                          type="text"
                          name={fieldNames.referenceNumber1}
                          label="Reference #1"
                          className={classes.referenceField}
                          inputProps={{ maxLength: 30 }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FastField
                          component={NewOrderCustomTextField}
                          type="text"
                          name={fieldNames.referenceNumber2}
                          label="Reference #2"
                          className={classes.referenceField}
                          inputProps={{ maxLength: 30 }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )} */}
                {!hiddenFields.includes('iot') && (
                  <IotForm
                    fieldNames={fieldNames}
                    currentPackage={thisPackage}
                    changeDependentField={changeDependentFieldOnChange}
                    formikRef={formikRef}
                    iotVendors={iotVendors}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
          <DocumentPreview
            open={!!preview}
            onClose={() => setPreview(null)}
            doc={preview}
          />
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </>
  );
}

PackageDetails.propTypes = {
  hiddenFields: PropTypes.arrayOf(PropTypes.string).isRequired,
  packageNumber: PropTypes.number.isRequired,
  numberOfPackages: PropTypes.number.isRequired,
  deletePackage: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  thisPackage: singlePackagePropTypes.isRequired,
  disabled: PropTypes.bool.isRequired,
  expanded: PropTypes.bool.isRequired,
  setExpanded: PropTypes.func.isRequired,
  toggleApplyToAllPackages: PropTypes.func.isRequired,
  formikRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]).isRequired,
  packageWeight: PropTypes.number.isRequired,
  weightUnitOfMeasurementForPackage: PropTypes.string.isRequired,
  packageCustomsValue: PropTypes.shape({
    amount: PropTypes.string.isRequired,
    currency: PropTypes.string.isRequired,
  }).isRequired,
  setTotalItemsForShipment: PropTypes.func.isRequired,
  totalItemsForShipment: PropTypes.number.isRequired,
  productItems: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      quantity: PropTypes.number.isRequired,
    }),
  ).isRequired,
  freightType: PropTypes.string.isRequired,
  shippingType: PropTypes.string.isRequired,
  isChemicalHazmat: PropTypes.bool.isRequired,
  isLithiumBatteryContainedOrPacked: PropTypes.bool.isRequired,
};

export default memo(PackageDetails);
