/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field, Formik } from 'formik';
import {
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  MenuItem,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import { useHistory } from 'react-router-dom';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import BusinessOutlinedIcon from '@material-ui/icons/BusinessOutlined';
import PersonPinOutlinedIcon from '@material-ui/icons/PersonPinOutlined';
import AttachMoneyOutlinedIcon from '@material-ui/icons/AttachMoneyOutlined';
import LibraryAddOutlinedIcon from '@material-ui/icons/LibraryAddOutlined';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';
import AirportShuttleOutlinedIcon from '@material-ui/icons/AirportShuttleOutlined';
import FlightOutlinedIcon from '@material-ui/icons/FlightOutlined';
import LocalHospitalOutlinedIcon from '@material-ui/icons/LocalHospitalOutlined';
import CardTravelOutlinedIcon from '@material-ui/icons/CardTravelOutlined';
import EcoOutlinedIcon from '@material-ui/icons/EcoOutlined';

import { makeStyles } from '@material-ui/core/styles';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import PublicOutlinedIcon from '@material-ui/icons/PublicOutlined';
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined';
import CheckIcon from '@material-ui/icons/Check';
import * as Yup from 'yup';
import * as _ from 'lodash';
import StyledCheckCircleIcon from './common/StyledCheckCircleIcon';
import { useSingleOrderDispatch, createSingleOrder } from '../context/singleOrderContext';
import * as colors from '../styles/colors';
import CustomSelectTextField from './common/CustomSelectTextField';
import NewOrderNextButton from './common/NewOrderNextButton';
import NewOrderNextButtonClear from './common/NewOrderNextButtonClear';
import { CustomCheckbox } from './common/InputComponents';
import HazmatIcon from './common/icons/HazmatIcon';
import {
  loadFreightTypeOptions,
  loadHazmatClassOptions,
  loadProfiles,
  loadAdditionalServices,
  useMiscDispatch,
  useMiscState,
  loadHazmatOptions,
  loadOrderPurposes,
} from '../context/miscDataContext';
import { useUserDispatch, useUserState, loadUser } from '../context/userContext';

import { HAZMAT, HAZMAT_CHEMICAL, NEW_ORDER_PATH } from '../clientConstants';
import Class1Icon from './common/icons/Class1Icon';
import Class2Icon from './common/icons/Class2Icon';
import Class5Icon from './common/icons/Class5Icon';
import Class8Icon from './common/icons/Class8Icon';
import Class7Icon from './common/icons/Class7Icon';
import Class6Icon from './common/icons/Class6Icon';
import Class9Icon from './common/icons/Class9Icon';
import ToxinIcon from './common/icons/ToxinIcon';
import BatteryIcon from './common/icons/BatteryIcon';
import RadioactiveIcon from './common/icons/RadioactiveIcon';
import ChemicalIcon from './common/icons/ChemicalIcon';
import UserProhibitedAckDialog from './UserProhibitedAckDialog';

const moment = require('moment');

const ORDER_OPTIONS = {
  work: {
    type: 'work',
    label: 'Business',
  },
  personal: {
    type: 'personal',
    label: 'Personal',
  },
  international: {
    type: 'international',
    label: 'International',
  },
  domestic: {
    type: 'domestic',
    label: 'Domestic',
  },
  freight: {
    type: 'freight',
    label: 'Freight',
  },
  product: {
    type: 'product',
    label: 'Product',
  },
  document: {
    type: 'document',
    label: 'Document',
  },
  heavyItem: {
    type: 'heavyItem',
    label: 'Heavy Item',
  },
  largeItem: {
    type: 'largeItem',
    label: 'Large Item',
  },
  healthCareItem: {
    type: 'healthCareItem',
    label: 'Health Care Item',
  },
  customsBroker: {
    type: 'customsBroker',
    label: 'Customs Broker',
  },
  dutiesTaxes: {
    type: 'dutiesTaxes',
    label: 'Duties & Taxes',
  },
  exportCompliance: {
    type: 'exportCompliance',
    label: 'Export/Import Compliance',
  },
  ehsCompliance: {
    type: 'ehsCompliance',
    label: 'EHS Compliance',
  },
  hazmat: {
    type: 'hazmat',
    label: 'Hazmat',
  },
  biological: {
    type: 'biological',
    label: 'Biological',
  },
  toxins: {
    type: 'toxins',
    label: 'Toxins',
  },
  chemical: {
    type: HAZMAT_CHEMICAL,
    label: 'Chemical',
  },
  radioactive: {
    type: 'radioactive',
    label: 'Radioactive',
  },
  batteries: {
    type: 'batteries',
    label: 'Li Batteries/Cells',
  },
  batteriesWithEquipment: {
    type: 'batteriesWithEquipment',
    label: 'Li Cells/Batteries within or packed with Equipment',
  },
  class1: {
    type: 'class1',
    label: 'Class 1',
  },
  class2: {
    type: 'class2',
    label: 'Class 2',
  },
  class3: {
    type: 'class3',
    label: 'Class 3',
  },
  class4: {
    type: 'class4',
    label: 'Class 4',
  },
  class5: {
    type: 'class5',
    label: 'Class 5',
  },
  class6: {
    type: 'class6',
    label: 'Class 6',
  },
  class7: {
    type: 'class7',
    label: 'Class 7',
  },
  class8: {
    type: 'class8',
    label: 'Class 8',
  },
  class9: {
    type: 'class9',
    label: 'Class 9',
  },
};

const FORM_OPTIONS = {
  purpose: {
    name: 'purpose',
    label: 'Is this order for work or personal use?',
  },
  shippingType: {
    values: [{ ...ORDER_OPTIONS.international }, { ...ORDER_OPTIONS.domestic }],
    name: 'shippingType',
    label: 'Is this shipment international or domestic?',
  },
  freightType: {
    name: 'freightType',
    label: 'Freight Type',
  },
  hazmatOption: {
    name: 'hazmatOption',
    label: 'Hazmat Option',
  },
  hazmatClass: {
    name: 'hazmatType',
    label: 'Hazmat Class',
  },
  ackHazmat: {
    name: 'ackHazmat',
    label: 'I Agree to the above requirements',
  },
  orderType: {
    name: 'orderType',
    // eslint-disable-next-line max-len
    label:
      'I hereby acknowledge that the order does not contain any product/article prohibited from shipping',
  },
  additionalServices: {
    values: [],
    name: 'additionalServices',
    label: 'Do you need additional Services?',
  },
};

const Icons = {
  [ORDER_OPTIONS.work.type]: BusinessOutlinedIcon,
  [ORDER_OPTIONS.personal.type]: HomeOutlinedIcon,
  [ORDER_OPTIONS.international.type]: PublicOutlinedIcon,
  [ORDER_OPTIONS.domestic.type]: RoomOutlinedIcon,
  [ORDER_OPTIONS.freight.type]: AirportShuttleOutlinedIcon,
  [ORDER_OPTIONS.product.type]: CardTravelOutlinedIcon,
  [ORDER_OPTIONS.document.type]: DescriptionOutlinedIcon,
  [ORDER_OPTIONS.heavyItem.type]: LocalShippingOutlinedIcon,
  [ORDER_OPTIONS.largeItem.type]: FlightOutlinedIcon,
  [ORDER_OPTIONS.healthCareItem.type]: LocalHospitalOutlinedIcon,
  [ORDER_OPTIONS.customsBroker.type]: PersonPinOutlinedIcon,
  [ORDER_OPTIONS.dutiesTaxes.type]: AttachMoneyOutlinedIcon,
  [ORDER_OPTIONS.exportCompliance.type]: LibraryAddOutlinedIcon,
  [ORDER_OPTIONS.ehsCompliance.type]: EcoOutlinedIcon,
  [ORDER_OPTIONS.hazmat.type]: HazmatIcon,
  [ORDER_OPTIONS.biological.type]: HazmatIcon,
  [ORDER_OPTIONS.toxins.type]: ToxinIcon,
  [ORDER_OPTIONS.chemical.type]: ChemicalIcon,
  [ORDER_OPTIONS.radioactive.type]: RadioactiveIcon,
  [ORDER_OPTIONS.batteries.type]: BatteryIcon,
  [ORDER_OPTIONS.batteriesWithEquipment.type]: BatteryIcon,
  [ORDER_OPTIONS.class1.type]: Class1Icon,
  [ORDER_OPTIONS.class2.type]: Class2Icon,
  [ORDER_OPTIONS.class3.type]: Class2Icon,
  [ORDER_OPTIONS.class4.type]: Class2Icon,
  [ORDER_OPTIONS.class5.type]: Class5Icon,
  [ORDER_OPTIONS.class6.type]: Class6Icon,
  [ORDER_OPTIONS.class7.type]: Class7Icon,
  [ORDER_OPTIONS.class8.type]: Class8Icon,
  [ORDER_OPTIONS.class9.type]: Class9Icon,
};
const MODAL_DETAILS = {
  listHeader: 'Prohibited Articles for Shipping',
  leftListItems: [
    'Ammunition (except as expressly provided in the "UPS Tariff/Terms and Conditions of Service - United States" beginning on page 136)',
    'Bank bills, notes, or currency (other than coin)',
    'Corpses, cremated, or disinterred remains',
    'Postage stamps',
  ],
  rightListItems: [
    'Fireworks',
    'Hazardous waste',
    'Ivory',
    'Marijuana, including marijuana intended for medicinal use',
    'Shark fins',
  ],
  title: 'Prohibited Items',
  isWarningIcon: true,
  bodyText: [
    'It is the shipper´s responsibility to comply with US government regulations or laws applicable in each country. Shipments are subject to inspection and possible delay by customs or representatives of other government authorities.',
    'Carriers will not transport any goods which are prohibited by law or regulation of any federal, state or local government in the origin or destination countries or which may breach any applicable export, import or other laws or endanger the safety of our employees, agents and partners or the means of transportation or, which in our opinion, soil, paint or otherwise damage other goods or equipment or which are economically or operationally impractical to transport.',
  ],
  footer: {
    text: 'Ref: ',
    url: 'https://www.ups.com/us/en/help-center/shipping-support/prohibited-items.page',
  },
};
const HAZMAT_DETAILS = {
  introText: 'I am a researcher/clinical staff member with in-depth knowledge of the material being shipped.',
  listHeader: 'It is my responsibility to properly:',
  listItems: [
    'And completely identify the material in eShipGlobal',
    'Classify the material being shipped',
    'Package the material',
    'Document the shipment',
    'Label the package',
    'If requested, completely and thoroughly review any export materials provided',
  ],
};

const PROHIBITED_ITEMS = [
  ...MODAL_DETAILS.leftListItems,
  ...MODAL_DETAILS.rightListItems,
];
const HAZMAT_ITEMS = [
  HAZMAT_DETAILS.introText,
  HAZMAT_DETAILS.listHeader,
  ...HAZMAT_DETAILS.listItems,

];

const styles = {
  root: {},
  expansionPanel: {
    backgroundColor: colors.darkBlueBackground,
    width: '100%',
  },
  expansionPanelChild: {
    backgroundColor: colors.mediumBlueBackground,
  },
  expansionPanelLabel: {
    color: colors.white,
    fontSize: 24,
    fontWeight: 500,
  },
  expansionIcon: {
    color: colors.white,
  },
  expansionPanelHeadingText: {
    color: colors.white,
    fontSize: 24,
    width: 750,
  },
  orderSummaryText: {
    color: colors.white,
    fontSize: 20,
    fontWeight: 500,
    width: 750,
  },
  expansionPanelQuestionText: {
    color: colors.white,
    fontSize: 18,
  },
  expansionPanelIcon: {
    height: 125,
    width: 125,
    color: colors.white,
    float: 'right',
  },
  expansionPanelButton: {
    '&.Mui-disabled': {
      color: 'rgba(0,0,0,.30)',
      border: '1px solid rgba(42, 121, 212, 0.5)',
    },
    minWidth: 85,
    fontWeight: 600,
    marginRight: 20,
    textTransform: 'capitalize',
  },
  expansionSummaryContent: {
    padding: '25px 0',
  },
  informationIcon: {
    color: '#94CCFB',
    height: 18,
    width: 18,
    marginLeft: 5,
  },
  iconContainer: {
    marginLeft: 8,
    marginTop: 'auto',
  },
  summaryContainer: {
    paddingLeft: 20,
  },
  buttonContainer: {
    marginTop: 25,
  },
  detailsContainer: {
    width: '60vw',
  },
  orderDetailsContainer: {
    width: '100%',
  },
  orderBasicsHeaderLabel: {
    fontSize: '19px',
    fontWeight: 500,
  },
  inputLabelText: {
    fontWeight: 700,
  },
  signatureImg: {
    margin: '1.5rem 0',
  },
};

const useStyles = makeStyles(styles);

function UserSignature({ freightType, signature }) {
  const classes = useStyles();

  return (
    <>
      {!_.isEmpty(signature) && freightType === 'hazmat' && (
        <Grid direction="column" container item>
          <Typography color="primary" fontWeight="700" className={classes.inputLabelText}>
            Signature
          </Typography>
          <img
            className={classes.signatureImg}
            src={signature}
            alt="User signature"
            width={250}
            height={100}
          />
        </Grid>
      )}
    </>
  );
}

UserSignature.propTypes = {
  freightType: PropTypes.string.isRequired,
  signature: PropTypes.string.isRequired,
};

function SummaryPanel({
  date,
  purpose,
  creator,
  orderId,
  shippingType,
  freightType,
  hazmatOption,
  hazmatType,
  additionalServices,
  signature,
}) {
  const { hazmatOptions } = useMiscState();
  const { hazmatClassOptions } = useMiscState();
  const miscDispatch = useMiscDispatch();
  const classes = useStyles();
  const hazmatClasses = _.isArray(hazmatType) ? hazmatType : [hazmatType];

  React.useEffect(() => {
    if (hazmatClassOptions.length === 0) loadHazmatClassOptions(miscDispatch);
    if (hazmatOptions && hazmatOptions.length === 0) loadHazmatOptions(miscDispatch);
  }, [miscDispatch]);

  return (
    <Grid container>
      <Grid item container xs={12} className={classes.summaryContainer}>
        {orderId && (
          <Grid item>
            <Typography className={classes.orderSummaryText}>
              {`Order ${orderId}`}
            </Typography>
          </Grid>
        )}
        <Grid item>
          <Typography className={classes.orderSummaryText}>
            {`Opened ${date} by ${creator}`}
          </Typography>
        </Grid>
        <Grid
          item
          spacing={3}
          container
          style={{ marginTop: '12px', width: '100%' }}
        >
          {purpose && (
            <Grid item xs={4} style={{ width: 'auto', marginRight: 0 }}>
              <CustomToggleButton
                value={purpose}
                label={ORDER_OPTIONS[purpose] && ORDER_OPTIONS[purpose].label}
                hidden={false}
                selected
              />
            </Grid>
          )}
          {shippingType && (
            <Grid item xs={4} style={{ width: 'auto', marginRight: 0 }}>
              <CustomToggleButton
                value={shippingType}
                label={
                  ORDER_OPTIONS[shippingType]
                  && ORDER_OPTIONS[shippingType].label
                }
                hidden={false}
                selected
              />
            </Grid>
          )}
          {freightType && (
            <Grid item xs={4} style={{ width: 'auto', marginRight: 0 }}>
              <CustomToggleButton
                value={freightType}
                label={
                  ORDER_OPTIONS[freightType] && ORDER_OPTIONS[freightType].label
                }
                hidden={false}
                selected
              />
            </Grid>
          )}
          {!_.isEmpty(hazmatOption) && (
            <Grid item xs={4} style={{ width: 'auto', marginRight: 0 }}>
              <CustomToggleButton
                value={hazmatOption}
                label={
                  ORDER_OPTIONS[hazmatOption] && ORDER_OPTIONS[hazmatOption].label
                }
                hidden={false}
                selected
              />
            </Grid>
          )}
          {/* {!_.isEmpty(hazmatOption) && !_.isEmpty(hazmatType) && hazmatClasses.map((hazmatClass) => (
            <Grid key={hazmatClass} item xs={4} style={{ width: 'auto', marginRight: 0 }}>
              <CustomToggleButton
                value={hazmatClass}
                label={
                  ORDER_OPTIONS[hazmatClass] && ORDER_OPTIONS[hazmatClass].label
                }
                sublabel={hazmatClassOptions?.find((h) => h.type === hazmatClass)?.sublabel}
                hidden={false}
                selected
              />
            </Grid>
          ))} */}
          {(additionalServices || []).map((additionalService) => (
            <Grid item xs={4} style={{ width: 'auto', marginRight: 0 }}>
              <CustomToggleButton
                value={additionalService}
                label={
                  ORDER_OPTIONS[additionalService]
                  && ORDER_OPTIONS[additionalService].label
                }
                hidden={false}
                selected
              />
            </Grid>
          ))}
        </Grid>
        <Grid item style={{ marginTop: '2rem' }}>
          <UserSignature freightType={freightType} signature={signature} />
        </Grid>
      </Grid>
    </Grid>
  );
}

SummaryPanel.propTypes = {
  date: PropTypes.string.isRequired,
  orderId: PropTypes.string,
  shippingType: PropTypes.string.isRequired,
  freightType: PropTypes.string.isRequired,
  hazmatOption: PropTypes.string,
  hazmatType: PropTypes.arrayOf(PropTypes.string),
  purpose: PropTypes.string.isRequired,
  additionalServices: PropTypes.arrayOf(PropTypes.string),
  creator: PropTypes.string.isRequired,
  signature: PropTypes.string.isRequired,
};

SummaryPanel.defaultProps = {
  additionalServices: [],
  orderId: '',
  hazmatOption: '',
  hazmatType: [],
};

function formatDateShort(date) {
  const momentDate = moment(date);
  return momentDate.format('DD MMM YYYY');
}
export default function OrderBasicsExpandablePane({
  selectedForms,
  accountType = 'large',
  isExistingOrder,
  setSelectedForms,
  existingDate,
  existingOrderType,
  order,
  userFullName,
}) {
  const userState = useUserState();
  const date = formatDateShort(new Date());
  const creator = userFullName || 'you';
  const signature = userState && _.has(userState, 'signature') ? userState.signature : '';

  const isAckProhibited = userState && _.has(userState, 'isAckProhibited') ? userState.isAckProhibited : false;

  const classes = useStyles();
  const formName = 'order basics';

  function handleChange() {
    if (selectedForms.parent === formName) {
      setSelectedForms({ parent: '', child: '' });
    } else {
      setSelectedForms({ parent: formName, child: '' });
    }
  }

  return (
    <div className={classes.root}>
      <ExpansionPanel
        expanded={selectedForms.parent === formName}
        className={classes.expansionPanel}
        onChange={handleChange}
      >
        <ExpansionPanelSummary
          expandIcon={(
            <ExpandMoreIcon
              color="primary"
              classes={{ colorPrimary: classes.expansionIcon }}
            />
          )}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Grid item container>
            <Typography className={classes.expansionPanelLabel}>
              Order Basics
            </Typography>
            <Grid item className={classes.iconContainer}>
              {isExistingOrder ? (
                <StyledCheckCircleIcon isValidated isGreenWhenValid />
              ) : null}
            </Grid>
            <Grid item className={classes.detailsSubContainer}>
              <Typography className={classes.detailsLabel} />
            </Grid>
          </Grid>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          {isExistingOrder ? (
            <Grid item className={classes.orderSummaryContainer}>
              <SummaryPanel
                purpose={order.purpose}
                orderType={isExistingOrder ? existingOrderType : ''}
                date={isExistingOrder ? existingDate : date}
                freightType={order.freightType}
                shippingType={order.shippingType}
                additionalServices={order.additionalServices?.map(
                  ({ serviceType }) => serviceType,
                )}
                hazmatOption={order?.hazmatOption}
                hazmatType={order?.hazmatType}
                orderId={order.orderId}
                classes={classes}
                isExistingOrder={isExistingOrder}
                creator={order.createdBy.fullName}
                accountType={accountType}
                signature={_.has(order, 'signature') ? order.signature : signature}
              />
            </Grid>
          ) : (
            <Grid item className={classes.orderDetailsContainer}>
              <OrderBasics creator={creator} date={date} signature={signature} isAckProhibited={isAckProhibited} />
            </Grid>
          )}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
}

OrderBasicsExpandablePane.propTypes = {
  selectedForms: PropTypes.objectOf(PropTypes.string).isRequired,
  isExistingOrder: PropTypes.bool.isRequired,
  accountType: PropTypes.string,
  setSelectedForms: PropTypes.func.isRequired,
  existingOrderType: PropTypes.string.isRequired,
  existingDate: PropTypes.string.isRequired,
  userFullName: PropTypes.string,
  order: PropTypes.shape({
    purpose: PropTypes.string,
    freightType: PropTypes.string,
    shippingType: PropTypes.string,
    orderId: PropTypes.string,
    createdBy: PropTypes.shape({
      fullName: PropTypes.string,
    }),
    hazmatOption: PropTypes.string,
    hazmatType: PropTypes.oneOf([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
    additionalServices: PropTypes.arrayOf(PropTypes.string),
    signature: PropTypes.string,
  }),
};

OrderBasicsExpandablePane.defaultProps = {
  accountType: 'large',
  order: {},
  userFullName: '',
};

const orderBasicsStyles = {
  orderDetailsContainer: {
    background: colors.newOrderFormBackground,
    width: '100%',
    padding: '10px 24px',
  },
  orderSummaryContainer: {
    width: '100%',
    padding: '10px 24px',
  },
  orderBasicsHeaderLabel: {
    fontSize: '19px',
    fontWeight: 500,
  },
  buttonContainer: {
    padding: '20px 0 !important',
  },
  formContainer:
    { background: colors.newOrderFormBackground, width: '100%', padding: '10px 24px' },
  summaryContainer: {
    width: '100%',
  },
};

const useOrderBasicsStyles = makeStyles(orderBasicsStyles);

function OrderBasics({
  creator, date, signature, isAckProhibited,
}) {
  const singleOrderDispatch = useSingleOrderDispatch();
  const classes = useOrderBasicsStyles();
  const history = useHistory();

  const {
    services,
    profiles,
    orderPurposes,
  } = useMiscState();
  const miscDispatch = useMiscDispatch();
  const [showConfirmPage, setShowConfirmPage] = React.useState(false);
  const [showErrors, setShowErrors] = React.useState(false);
  const defaultProfile = profiles?.find(({ isDefault }) => !!isDefault);

  const validationSchema = Yup.object().shape({
    purpose: Yup.string().required('Required'),
    shippingType: Yup.string().required('Required'),
    freightType: Yup.string().required('Required'),
    hazmatOption: Yup.string().when('freightType', {
      is: (freightType) => freightType === 'hazmat',
      then: (schema) => schema.required('required'),
      otherwise: (schema) => schema,
    }),
    hazmatType: Yup.array().of(Yup.string()).when('freightType', {
      is: (freightType) => freightType === 'hazmat', // just an e.g. you can return a function
      then: (schema) => schema.min(1).required('required'),
      otherwise: (schema) => schema,
    }),
    orderType: Yup.boolean().oneOf([true], 'Must select check'),
    ackHazmat: Yup.boolean().when('freightType', {
      is: (freightType) => freightType === 'hazmat',
      then: Yup.boolean().oneOf([true], 'Must select check'),
      otherwise: Yup.boolean(),
    }),
    prohibitedItems: Yup.string().required('Required'),
    hazmatItems: Yup.string().when('freightType', {
      is: (freightType) => freightType === 'hazmat',
      then: Yup.string().required('required'),
      otherwise: Yup.string(),
    }),
  });

  async function onSubmit(values) {
    const requestObj = {
      ...values,
      orderType: isAckProhibited ? 'NON-RMS' : 'RMS',
      signature,
    };
    delete requestObj.profile;
    requestObj.additionalServices = (requestObj.additionalServices || []).map((serviceType) => ({ serviceType }));

    const res = await createSingleOrder({ dispatch: singleOrderDispatch, orderBasics: requestObj });
    history.replace(`${NEW_ORDER_PATH}/${res.order.orderId}`, { newOrder: true });
  }

  React.useEffect(() => {
    if (!orderPurposes) loadOrderPurposes(miscDispatch);
    loadFreightTypeOptions(miscDispatch);
    loadProfiles(miscDispatch);
    loadAdditionalServices(miscDispatch);
  }, [miscDispatch]);

  const getInitialValues = () => ({
    purpose: defaultProfile?.purpose || '',
    shippingType: defaultProfile?.shippingType || '',
    freightType: defaultProfile?.freightType || '',
    hazmatOption: defaultProfile?.hazmatOption || '',
    hazmatType: defaultProfile?.hazmatType || [],
    orderType: !!(defaultProfile?.ackNotProhited),
    ackHazmat: !!(defaultProfile?.ackHazmat),
    additionalServices: defaultProfile?.additionalServices?.map(({ serviceType }) => serviceType) || [],
    prohibitedItems: PROHIBITED_ITEMS.join(','),
    hazmatItems: HAZMAT_ITEMS.join(','),
    profile: defaultProfile?.profileName || 'Select Profile',
  });

  return (
    <Formik
      enableReinitialize
      initialValues={getInitialValues()}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formikProps) => (
        <Grid
          item
          container
          direction="column"
          spacing={1}
          className={
            showConfirmPage ? classes.summaryContainer : classes.formContainer
          }
        >
          {showConfirmPage ? (
            <SummaryPanel
              purpose={formikProps.values.purpose}
              shippingType={formikProps.values.shippingType}
              freightType={formikProps.values.freightType}
              hazmatOption={formikProps.values.hazmatOption}
              hazmatType={formikProps.values.hazmatType}
              additionalServices={formikProps.values.additionalServices}
              creator={creator}
              date={date}
              signature={signature}
            />
          ) : (
            <Grid item container spacing={2} direction="column">
              <Grid item>
                <Typography
                  className={classes.orderBasicsHeaderLabel}
                  color="primary"
                >
                  {`Hello, ${creator}. Just a quick question or two to get you started.`}
                </Typography>
              </Grid>
              <Grid item>
                {services && profiles ? (
                  <OrderBasicsForm
                    orderPurposes={orderPurposes}
                    services={services}
                    profiles={profiles}
                    signature={signature}
                    isAckProhibited={isAckProhibited}
                    setFieldValue={formikProps.setFieldValue}
                    values={formikProps.values}
                    disabled={formikProps.isSubmitting}
                    errors={
                      showErrors && formikProps.errors ? formikProps.errors : {}
                    }
                  />
                ) : (
                  <Grid item style={{ margin: 'auto' }}>
                    <CircularProgress color="secondary" />
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}
          <Grid
            item
            container
            justify="center"
            className={classes.buttonContainer}
          >
            <Grid item>
              {!showConfirmPage ? (
                <NewOrderNextButton
                  onClick={() => formikProps.validateForm().then((err) => {
                    if (_.isEmpty(err)) setShowConfirmPage(true);
                    else setShowErrors(true);
                  })}
                  data-testid="submit-button"
                >
                  next
                </NewOrderNextButton>
              ) : (
                <ShowConfirmPageButtons
                  isSubmitting={formikProps.isSubmitting}
                  isValid={formikProps.isValid}
                  submitForm={formikProps.submitForm}
                  setShowConfirmPage={setShowConfirmPage}
                  formikProps={formikProps}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
    </Formik>
  );
}

OrderBasics.propTypes = {
  creator: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  signature: PropTypes.string.isRequired,
};

function ShowConfirmPageButtons(props) {
  const {
    isSubmitting, submitForm, setShowConfirmPage, formikProps,
  } = props;
  return (
    isSubmitting
      ? <CircularProgress color="secondary" />
      : (
        <Grid item container alignItems="center" spacing={4}>
          <Grid item>
            <NewOrderNextButtonClear
              onClick={() => {
                formikProps.resetForm(formikProps.values);
                setShowConfirmPage(false);
              }}
              data-testid="submit-button"
            >
              Back
            </NewOrderNextButtonClear>
          </Grid>
          <Grid item>
            <NewOrderNextButton
              onClick={submitForm}
              data-testid="submit-button"
            >
              Next
            </NewOrderNextButton>
          </Grid>

        </Grid>
      ));
}

ShowConfirmPageButtons.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  formikProps: PropTypes.objectOf(PropTypes.any).isRequired,
  submitForm: PropTypes.func.isRequired,
  setShowConfirmPage: PropTypes.func.isRequired,
};

const orderBasicsFormStyles = {
  contentContainer: {
    background: colors.darkBlueBackground,
    height: '100%',
  },
  toggleComponentContainer: {
    paddingLeft: '25px',
    paddingRight: '25px',
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  prohibitedItemsContainer: {
    padding: '0px 25px 55px 55px',
  },
  icon: {
    height: '40px',
    width: 'auto',
    color: colors.white,
    padding: 0,
  },
  disclaimerText: {
    fontSize: '.9rem',
  },
  disclaimerTextHeader: {
    fontSize: '.9rem',
    fontWeight: 500,
  },
  checkBoxError: {
    color: colors.danger,
    fontSize: 14.75,
    fontWeight: 425,
  },
};
const useOrderBasicsFormStyles = makeStyles(orderBasicsFormStyles);

function OrderBasicsForm({
  setFieldValue, values, disabled, errors,
  services, profiles, orderPurposes, signature, isAckProhibited,
}) {
  const {
    freightTypeOptions,
    hazmatOptions,
    hazmatClassOptions,
  } = useMiscState();
  const miscDispatch = useMiscDispatch();
  const [hazmatClasses, setHazmatClasses] = useState([]);
  const userState = useUserState();

  React.useEffect(() => {
    loadFreightTypeOptions(miscDispatch);
    loadHazmatOptions(miscDispatch);
    loadHazmatClassOptions(miscDispatch);
  }, [miscDispatch]);

  React.useEffect(() => {
    if (!_.isEmpty(hazmatOptions) && !_.isEmpty(values.hazmatOption)) {
      const option = hazmatOptions.find(
        (item) => item.type === values.hazmatOption,
      ) || null;

      setHazmatClasses(hazmatClassOptions.map((item) => {
        const hazmatClass = option?.hazmatClasses.find((o) => item.type === o);

        return { ...item, disabled: _.isEmpty(hazmatClass) };
      }));

      setFieldValue('hazmatType', option?.hazmatClasses);
    }
  }, [hazmatOptions, values.hazmatOption]);

  React.useEffect(() => {
    const freightType = _.get(values, FORM_OPTIONS.freightType.name, '');
    const hazmatOption = _.get(values, FORM_OPTIONS.hazmatOption.name, '');
    const hazmatType = _.get(values, FORM_OPTIONS.hazmatClass.name, []);

    if (!_.isEmpty(freightType) && !_.isEmpty(hazmatOption)
      && !_.isEmpty(hazmatType) && freightType !== HAZMAT) {
      setFieldValue(FORM_OPTIONS.hazmatOption.name, '');
      setFieldValue(FORM_OPTIONS.hazmatClass.name, []);
    }
  }, [values.freightType]);

  React.useEffect(() => {
    if (orderPurposes && orderPurposes.length === 1) setFieldValue('purpose', orderPurposes[0].type);
  }, [orderPurposes]);

  const classes = useOrderBasicsFormStyles();

  const purposeError = errors[FORM_OPTIONS.purpose.name];
  const shippingError = errors[FORM_OPTIONS.shippingType.name];
  const freightError = errors[FORM_OPTIONS.freightType.name];
  const hazmatOptionError = errors[FORM_OPTIONS.hazmatOption.name];
  const hazmatClassError = errors[FORM_OPTIONS.hazmatClass.name];
  const orderTypeError = errors[FORM_OPTIONS.orderType.name];
  const ackHazmatError = errors[FORM_OPTIONS.ackHazmat.name];

  const additionalServiceError = errors[FORM_OPTIONS.additionalServices.name];

  const getAdditionalServices = () => (services?.[values?.purpose]?.[values?.shippingType]?.[values?.freightType]?.additionalServices || []).map(({ serviceType }) => ORDER_OPTIONS?.[serviceType])?.filter((s) => !!s);

  const additionalServices = getAdditionalServices();

  const onProfileChange = (name) => {
    const profile = profiles.find(({ profileName }) => profileName === name);
    setFieldValue('purpose', profile?.purpose || '');
    setFieldValue('shippingType', profile?.shippingType || '');
    setFieldValue('freightType', profile?.freightType || '');
    setFieldValue('hazmatOption', profile?.hazmatOption || '');
    setFieldValue('hazmatType', profile?.hazmatType || []);
    // setFieldValue('orderType', !!(profile?.ackNotProhited));
    setFieldValue('ackHazmat', !!profile?.ackHazmat);
    setFieldValue('additionalServices', profile?.additionalServices?.map(({ serviceType }) => serviceType) || []);
  };

  return (
    <>
      <Grid
        item
        container
        direction="column"
        className={classes.contentContainer}
        wrap="nowrap"
      >
        {!isAckProhibited && <UserProhibitedAckDialog portal isOpened isOrderBasics />}
        {(userState.useProfileSignature && !signature) && <UserProhibitedAckDialog portal isOpened isOrderBasics signature />}

        <Grid item container className={classes.toggleComponentContainer}>
          <Grid item container direction="column" spacing={1} wrap="nowrap">
            <Grid item>
              <Typography color="primary" className={classes.inputLabelText}>
                Profiles
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Field
                component={CustomSelectTextField}
                style={{ width: '100%' }}
                type="text"
                name="profile"
                label="Select Profile"
                data-testid="profile-input"
                customOnChange={(e) => onProfileChange(e?.target?.value)}
              >
                {profiles.map(({ profileName }) => (
                  <MenuItem key={profileName} value={profileName}>
                    {profileName}
                  </MenuItem>
                ))}
              </Field>
            </Grid>
          </Grid>
        </Grid>
        {orderPurposes && orderPurposes.length > 1 && (
          <Grid item className={classes.toggleComponentContainer}>
            <OrderBasicsFormToggleComponent
              setFieldValue={setFieldValue}
              label={FORM_OPTIONS.purpose.label}
              value={values.purpose}
              disabled={disabled}
              name={FORM_OPTIONS.purpose.name}
              options={orderPurposes}
              error={purposeError}
              onChange={(value) => {
                const newAdditionalServices = (
                  services?.[value]?.[values?.shippingType]?.[
                    values?.freightType
                  ]?.additionalServices || []
                )
                  .map(({ serviceName }) => ORDER_OPTIONS?.[serviceName])
                  ?.map(({ type }) => type) || [];
                setFieldValue('additionalServices', newAdditionalServices || []);
              }}
            />
          </Grid>
        )}
        <Grid item className={classes.toggleComponentContainer}>
          <OrderBasicsFormToggleComponent
            setFieldValue={setFieldValue}
            label={FORM_OPTIONS.shippingType.label}
            value={values.shippingType}
            disabled={disabled}
            name={FORM_OPTIONS.shippingType.name}
            options={FORM_OPTIONS.shippingType.values}
            error={shippingError}
            onChange={(value) => {
              const newAdditionalServices = (
                services?.[values?.purpose]?.[value]?.[values?.freightType]
                  ?.additionalServices || []
              )
                .map(({ serviceType }) => ORDER_OPTIONS?.[serviceType])
                ?.map(({ type }) => type) || [];
              setFieldValue('additionalServices', newAdditionalServices || []);
            }}
          />
        </Grid>
        <Grid item className={classes.toggleComponentContainer}>
          <OrderBasicsFormToggleComponent
            setFieldValue={setFieldValue}
            label={FORM_OPTIONS.freightType.label}
            value={values.freightType}
            disabled={disabled}
            name={FORM_OPTIONS.freightType.name}
            options={freightTypeOptions}
            error={freightError}
            onChange={(value) => {
              const newAdditionalServices = (
                services?.[values?.purpose]?.[values?.shippingType]?.[value]
                  ?.additionalServices || []
              )
                .map(({ serviceType }) => ORDER_OPTIONS?.[serviceType])
                ?.map(({ type }) => type) || [];

              setFieldValue('additionalServices', newAdditionalServices || []);
            }}
          />
        </Grid>
        {values.freightType === 'hazmat' && (
          <Grid item className={classes.toggleComponentContainer}>
            <OrderBasicsFormToggleComponent
              setFieldValue={setFieldValue}
              label={FORM_OPTIONS.hazmatOption.label}
              value={values.hazmatOption}
              disabled={disabled}
              name={FORM_OPTIONS.hazmatOption.name}
              options={hazmatOptions}
              error={hazmatOptionError}
              onChange={(value) => {
                const newAdditionalServices = (
                  services?.[values?.purpose]?.[values?.shippingType]?.[values?.freightType]
                    ?.additionalServices || []
                )
                  .map(({ serviceType }) => ORDER_OPTIONS?.[serviceType])
                  ?.map(({ type }) => type) || [];

                setFieldValue('additionalServices', newAdditionalServices || []);
              }}
            />
          </Grid>
        )}
        {/* {values.hazmatOption && values.freightType === 'hazmat' && (
          <Grid item className={classes.toggleComponentContainer}>
            <OrderBasicsFormToggleComponent
              setFieldValue={setFieldValue}
              label={FORM_OPTIONS.hazmatClass.label}
              value={values.hazmatType}
              disabled={disabled}
              name={FORM_OPTIONS.hazmatClass.name}
              options={hazmatClasses}
              error={hazmatClassError}
              multiSelect
            />
          </Grid>
        )} */}
        {additionalServices.length > 0 && (
          <Grid item className={classes.toggleComponentContainer}>
            <OrderBasicsFormToggleComponent
              setFieldValue={setFieldValue}
              label={FORM_OPTIONS.additionalServices.label}
              value={values.additionalServices}
              disabled={disabled}
              name={FORM_OPTIONS.additionalServices.name}
              options={additionalServices}
              error={additionalServiceError}
              multiSelect
            />
          </Grid>
        )}
        {values.freightType === 'hazmat' && (
          <>
            <Grid item className={classes.prohibitedItemsContainer} style={{ paddingBottom: '5px', paddingTop: '20px' }}>
              <HazmatAgreementLabel />
            </Grid>
            <Grid item className={classes.toggleComponentContainer} container>
              <Grid
                item
                style={{ maxWidth: '97%' }}
                data-testid="hazmat-checkbox-container"
              >
                <CustomCheckbox
                  name={FORM_OPTIONS.ackHazmat.name}
                  label={
                    ackHazmatError ? (
                      <Typography className={classes.checkBoxError}>
                        {`${FORM_OPTIONS.ackHazmat.label}*`}
                      </Typography>
                    ) : (
                      FORM_OPTIONS.ackHazmat.label
                    )
                  }
                />
              </Grid>
            </Grid>
          </>
        )}
        {/* <ProhibitedAck orderTypeError={orderTypeError} /> */}
        {values.ackHazmat && (
          <Grid item className={classes.prohibitedItemsContainer}>
            <UserSignature freightType={values.freightType} signature={signature} />
          </Grid>
        )}
      </Grid>
    </>
  );
}

OrderBasicsForm.defaultProps = {
  orderPurposes: [],
  profiles: [],
  services: undefined,
};

OrderBasicsForm.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.shape({
    purpose: PropTypes.string.isRequired,
    freightType: PropTypes.string.isRequired,
    shippingType: PropTypes.string.isRequired,
    hazmatOption: PropTypes.string,
    hazmatType: PropTypes.arrayOf(PropTypes.string),
    orderType: PropTypes.bool.isRequired,
    additionalServices: PropTypes.arrayOf(PropTypes.string),
    ackHazmat: PropTypes.bool,
  }).isRequired,
  orderPurposes: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string,
    description: PropTypes.string,
  })),
  profiles: PropTypes.arrayOf(PropTypes.shape(PropTypes.object)),
  services: PropTypes.shape(PropTypes.any),
  signature: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
};

function HazmatAgreementLabel(props) {
  const classes = useOrderBasicsFormToggleComponentStyles();
  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <Typography color="primary" className={classes.hazmatHeadText}>
          Hazmat Certification
        </Typography>
      </Grid>
      <Grid item>
        <Typography color="primary" className={classes.hazmatItemText}>
          Check the 'I Agree..' box below to agree to the following:
        </Typography>
      </Grid>
      <Grid item>
        <Typography className={classes.hazmatItemText}>
          {HAZMAT_DETAILS.introText}
        </Typography>
      </Grid>
      <Grid item>
        <Typography className={classes.hazmatItemText}>
          {HAZMAT_DETAILS.listHeader}
        </Typography>
        <Typography className={classes.prohibitedItemText}>
          <Grid item container spacing={2} style={{ margin: 0 }}>
            <Grid item xs={12}>
              <ul>
                {HAZMAT_DETAILS.listItems.map((item) => (
                  <li key={item}>{item}</li>
                ))}
              </ul>
            </Grid>
          </Grid>
        </Typography>
      </Grid>
    </Grid>
  );
}
const useOrderBasicsFormToggleComponentStyle = {
  icon: {
    height: '60px',
    width: 'auto',
    color: colors.white,
    padding: 0,
  },
  iconChecked: {
    height: '60px',
    width: 'auto',
    color: colors.lightSkyBlue,
    padding: 0,
  },
  radio: {
    '&:hover': {
      backgroundColor: 'transparent ',
    },
    paddingLeft: 0,
  },
  radioLabel: {
    color: colors.white,
    width: '100%',
  },
  formControlRoot: {
    alignItems: 'center',
    background: colors.newOrderFormBackground,
    margin: '0px',
    padding: '0px 20px',
    width: '100%',
  },
  inputLabelContainer: {
    paddingBottom: '12px',
  },
  inputLabelText: {
    fontWeight: 500,
  },

  checkedControlRoot: {
    alignItems: 'center',
    background: '#39455C',
    margin: '0px',
    paddingRight: '5px',
    paddingLeft: '20px',
    width: '100%',
  },
  prohibitedItemText: {
    fontSize: 14.75,
    fontWeight: 425,
    color: colors.textLightGrey,
  },
  hazmatItemText: {
    fontSize: 14.75,
    fontWeight: 400,
    color: colors.textLightGrey,
  },
  hazmatHeadText: {
    fontSize: 15,
    fontWeight: 500,
    color: colors.textLightGrey,
  },
};

export const useOrderBasicsFormToggleComponentStyles = makeStyles(useOrderBasicsFormToggleComponentStyle);

// common component
function OrderBasicsFormToggleComponent({
  setFieldValue,
  label,
  name,
  value,
  options,
  disabled,
  error,
  multiSelect,
  onChange,
}) {
  const classes = useOrderBasicsFormToggleComponentStyles();
  // const [selectedButton, setSelectedButton] = React.useState(value);
  const [showMore, setShowMore] = React.useState(false);

  const isSelected = (option) => {
    if (multiSelect) {
      return (value || []).includes(option?.type);
    }

    return option?.type === value;
  };

  return (
    <Grid container direction="column">
      <Grid item className={classes.inputLabelContainer}>
        <Typography color="primary" className={classes.inputLabelText} style={{ color: error ? colors.danger : colors.white }}>{`${label}${error ? '*' : ''}`}</Typography>
      </Grid>
      <Grid container>
        <FormControl style={{ width: '100%' }}>
          <RadioGroup
            name={name}
            value={value}
            onChange={(e) => {
              let v = e?.target?.value;

              if (multiSelect) {
                if ((value || []).includes(v)) {
                  v = (value || []).filter((s) => s !== v);
                } else {
                  v = (value || []).concat([v]);
                }
              }

              setFieldValue(name, v);
              onChange && onChange(v, name);
            }}
          >
            <Grid item container spacing={2}>
              {options
                ? options.map((option, index) => {
                  const hidden = (!showMore && index > 8);
                  return (
                    <Grid item xs={4} key={option?.type} style={{ width: 'auto', marginRight: 0, height: hidden ? 0 : 'inherit' }}>
                      <CustomToggleButton
                        value={option?.type}
                        label={option?.label || option?.description}
                        sublabel={option?.sublabel}
                        Icon={option?.icon}
                        hidden={hidden}
                        disabled={disabled
                          || option?.disabled
                          || option?.type === 'radioactive'
                          || option?.type === 'toxins'}
                        selected={isSelected(option)}
                      />
                    </Grid>
                  );
                })
                : (
                  <Grid item style={{ margin: 'auto' }}>
                    <CircularProgress color="secondary" />
                  </Grid>
                )}
            </Grid>
          </RadioGroup>
        </FormControl>
      </Grid>
      {options && options.length > 9 && (
        <Grid item container justify="center" style={{ paddingTop: '8px' }}>
          <NewOrderNextButtonClear onClick={() => setShowMore(!showMore)}>{showMore ? 'Show Less' : 'Show More'}</NewOrderNextButtonClear>
        </Grid>
      )}
    </Grid>
  );
}

OrderBasicsFormToggleComponent.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.shape(PropTypes.object)]).isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    icon: PropTypes.element,
  })),
  disabled: PropTypes.bool.isRequired,
  values: PropTypes.shape({
    purpose: PropTypes.string,
    freightType: PropTypes.string,
    shippingType: PropTypes.string,
    orderType: PropTypes.bool,
  }).isRequired,
  error: PropTypes.bool,
  multiSelect: PropTypes.bool,
  onChange: PropTypes.func,
};

OrderBasicsFormToggleComponent.defaultProps = {
  multiSelect: false,
  onChange: null,
  error: false,
  options: [],
};

function CustomToggleButton({
  label, value, selected, hidden, disabled, sublabel,
}) {
  const classes = useOrderBasicsFormToggleComponentStyles();
  const Icon = Icons[value] || (() => (<></>));

  return (
    <FormControlLabel
      style={{
        width: hidden ? 0 : '100%',
        visibility: hidden ? 'hidden' : 'visible',
        height: hidden ? 0 : '100%',
      }}
      control={(
        <Radio
          disableRipple
          icon={
            <Icon className={selected ? classes.iconChecked : classes.icon} />
          }
          checkedIcon={<Icon className={classes.iconChecked} />}
          className={classes.radio}
          disabled={disabled}
          inputProps={{
            'data-testid': `radio-button-${value}`,
          }}
        />
      )}
      label={
        selected ? (
          <Grid
            item
            container
            justify="space-between"
            alignItems="center"
            wrap="nowrap"
            style={{ width: '100%' }}
          >
            <Grid container item style={{ paddingRight: 0 }} direction="column" wrap="nowrap">
              <Typography
                style={{
                  color: selected ? colors.lightSkyBlue : colors.white,
                  maxWidth: 15,
                }}
              >
                {label}
              </Typography>
              {sublabel && (
                <Typography
                  style={{
                    color: selected ? colors.lightSkyBlue : colors.white,
                    fontSize: 12,
                  }}
                >
                  {sublabel}
                </Typography>
              )}
            </Grid>
            <Grid
              item
              style={{
                display: 'flex',
                marginBottom: '25px',
                padding: 0,
              }}
            >
              <CheckIcon
                style={{
                  color: colors.white,
                  height: '30px',
                  width: 'auto',
                  padding: '0',
                  paddingRight: '5px',
                }}
              />
            </Grid>
          </Grid>
        ) : (
          <Grid
            item
            container
            justify="space-between"
            alignItems="center"
            wrap="nowrap"
            style={{ width: '100%' }}
          >
            <Grid container item style={{ paddingRight: 0 }} direction="column" wrap="nowrap">

              <Typography>{label}</Typography>
              {sublabel && (
                <Typography
                  style={{
                    fontSize: 12,
                  }}
                >
                  {sublabel}
                </Typography>
              )}
            </Grid>
          </Grid>
        )
      }
      value={value}
      classes={{
        label: classes.radioLabel,
        root: selected ? classes.checkedControlRoot : classes.formControlRoot,
        icon: classes.contentContainer,
      }}
    />
  );
}

CustomToggleButton.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  hidden: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  sublabel: PropTypes.string,
};

CustomToggleButton.defaultProps = {
  disabled: false,
  sublabel: null,
};
