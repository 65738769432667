import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import {
  Grid, Typography, FormControl, FormLabel, Radio, RadioGroup, FormControlLabel,
} from '@material-ui/core';
import * as colors from '../../../../styles/colors';
import { useItemProductDetailsFormStyles } from '../../itemDetailsStyles';
import {
  EMPTY_LITHIUM_BATTERY_CONTAINED_OR_PACKED_ITEM_OBJECT,
  FORM_FIELDS,
  LITHIUM_BATTERY_PACKED_KEY,
  SHIPPING_LITHIUM_BATTERIES_KEY,
} from '../../itemDetailsConstants';

const OPTIONS = [
  {
    label: 'Yes',
    value: true,
  },
  {
    label: 'No',
    value: false,
  },
];

export default function ShippingLithiumBatteries({
  formikProps,
  hiddenFields,
  isBatteryContained,
  setShowBatteryPackedForm,
}) {
  const classes = useItemProductDetailsFormStyles();

  useEffect(() => {
    const shippingLithiumBatteries = get(formikProps?.values, SHIPPING_LITHIUM_BATTERIES_KEY, false);
    setShowBatteryPackedForm(shippingLithiumBatteries);

    if (!shippingLithiumBatteries) {
      setShowBatteryPackedForm(false);

      if (isBatteryContained) formikProps.setFieldValue(LITHIUM_BATTERY_PACKED_KEY, EMPTY_LITHIUM_BATTERY_CONTAINED_OR_PACKED_ITEM_OBJECT);
    }
  }, [formikProps?.values[SHIPPING_LITHIUM_BATTERIES_KEY]]);

  return (
    <>
      {!hiddenFields.includes('material') && !isEmpty(formikProps.values[FORM_FIELDS.itemName.key]) ? (
        <Grid item>
          <FormControl component="fieldset">
            <FormLabel component="legend">
              <Grid item container spacing={2}>
                <Grid item>
                  <Typography color="primary" className={classes.formFieldSubHeader}>
                    {`Are you shipping any ${isBatteryContained ? 'additional' : ''} lithium cell / battery with this product?`}
                  </Typography>
                </Grid>
              </Grid>
            </FormLabel>
            <RadioGroup
              aria-label="Lithium batteries"
              name={SHIPPING_LITHIUM_BATTERIES_KEY}
              value={formikProps?.values[SHIPPING_LITHIUM_BATTERIES_KEY]}
              row
              onChange={(e) => {
                const value = e.target.value === 'true';

                formikProps.setFieldValue(SHIPPING_LITHIUM_BATTERIES_KEY, value);
              }}
            >
              {OPTIONS.map((option) => (
                <FormControlLabel
                  key={option.value}
                  style={{ padding: '0 10px' }}
                  value={option.value}
                  control={<Radio style={{ color: colors.white }} />}
                  label={<Typography color="primary">{option.label}</Typography>}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Grid>
      ) : null}
    </>
  );
}

ShippingLithiumBatteries.propTypes = {
  formikProps: PropTypes.objectOf(PropTypes.any).isRequired,
  hiddenFields: PropTypes.arrayOf(PropTypes.string).isRequired,
  isBatteryContained: PropTypes.bool.isRequired,
  setShowBatteryPackedForm: PropTypes.func.isRequired,
};
