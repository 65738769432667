import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Grid, Typography,
} from '@material-ui/core';
import { css } from 'emotion';
import * as miscClient from '../../utils/miscClient';
import * as colors from '../../styles/colors';
import {
  useBinState,
  useBinDispatch,
  loadAllBins,
  updateBinPriorityBulk,
} from '../../context/binDataContext';
import ErrorModalWE from './ErrorModalWE';

export default function UserReset(props) {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [errorValue, setErrorValue] = React.useState(null);
  const binDispatch = useBinDispatch();
  const binState = useBinState();
  const { isReadOnly, setIsReadOnly } = props;

  const resetUsers = async () => {
    try {
      setLoading(true);
      await miscClient.resetUsers();
      setError('Reset User(s) login State completed successfully');
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <Grid container spacing={4}>
        <Grid
          xs={12}
          item
          container
          justify="space-between"
          alignItems="center"
          spacing={2}
        >
          <Grid item>
            <Typography
              className={css`
                color: ${colors.white};
                font-size: 20px;
                font-weight: 500;
                letter-spacing: 0.25px;
              `}
            >
              Reset Users' Login State
            </Typography>
          </Grid>
          <Grid item>
            <Button
              className={css`
                font-weight: bold;
                margin: auto;
                padding: 6px 16px;
                color: ${colors.white};
                border-radius: 8px;
                white-space: nowrap;
              `}
              color="secondary"
              variant="contained"
              disabled={loading}
              onClick={async () => {
                await resetUsers();
              }}
            >
              Reset Users
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <ErrorModalWE
        open={!!error}
        onProceed={() => {
          setError(null);
          setErrorValue(null);
        }}
        noCancel
        proceedLabel="OK"
        message={<span>{error}</span>}
        strongMessage={errorValue}
        onActionClick={() => {}}
      />
    </>
  );
}

UserReset.propTypes = {
  isReadOnly: PropTypes.bool.isRequired,
  setIsReadOnly: PropTypes.func.isRequired,
};
