import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel, Radio } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  radio: {
    color: 'white',
  },
}));

function ControlledRadio(props) {
  const classes = useStyles();
  const {
    label, value,
  } = props;

  return (
    <FormControlLabel
      control={<Radio color="primary" classes={{ root: classes.radio }} />}
      label={label}
      value={value}
      labelPlacement="end"
    />
  );
}

ControlledRadio.defaultProps = {
  label: '',
  value: '',
};

ControlledRadio.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
};

export default memo(ControlledRadio);
