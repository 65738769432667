import React from 'react';
import ReactDOM from 'react-dom';
import { MuiThemeProvider } from '@material-ui/core/styles';
import App from './App';
import { UnauthenticatedProviders } from './context';
import { newOrderTheme } from './styles/eSGNextGenTheme';

const rootElement = document.getElementById('root');
if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(
    <UnauthenticatedProviders>
      <App />
    </UnauthenticatedProviders>,
    rootElement,
  );
} else {
  ReactDOM.render(
    <UnauthenticatedProviders>
      <MuiThemeProvider theme={newOrderTheme}>
        <App />
      </MuiThemeProvider>
    </UnauthenticatedProviders>,
    rootElement,
  );
}
