const statusMap = {
  NEW: 'Created',
  SUBMITTED: 'Completed',
  SCHEDULED: 'Scheduled for Pickup',
  IN_PROGRESS: 'In-Transit',
  COMPLETED: 'Delivered',
  CANCELLED: 'Cancelled',
  PICKUP_CANCELLED: 'Completed. Pickup cancelled',
  COMPLIANCE_FAILURE: 'Compliance Failure',
};

const taskStatusMap = {
  CREATED: 'Created',
  IN_PROGRESS: 'In-Progress',
  COMPLETED: 'Completed',
  REJECTED: 'Rejected',
  REVOKED: 'Revoked',
  EXPIRED: 'Expired',
  CANCELLED: 'Cancelled',
};

const reverseStatusMap = Object.entries(statusMap)
  .reduce((map, [key, val]) => ({ ...map, [val]: key }), {});

const statuses = [
  'Created',
  'Completed',
  'Scheduled for Pickup',
  'In-Transit',
  'Delivered',
  'Cancelled',
  'Completed. Pickup cancelled',
  'Compliance Failure',
];

const statusMapLowerCase = Object.keys(statusMap)
  .reduce((sMap, key) => {
    // eslint-disable-next-line no-param-reassign
    sMap[key] = statusMap[key].toLowerCase();
    return sMap;
  }, {});

const lowerCaseStatuses = statuses.map((s) => s.toLowerCase());

// eslint-disable-next-line max-len
const statusFormatter = (statusFunction) => (status, ...args) => statusFunction(status?.toLowerCase(), ...args);

const readOnly = lowerCaseStatuses.slice(1);
const isReadOnly = statusFormatter(
  (status) => readOnly.includes(status),
);

const cancelled = statusMapLowerCase.CANCELLED;
const isCancelled = statusFormatter(
  (status) => status === cancelled,
);

// TODO: Verify this logic
const isCompleted = statusFormatter(
  (status) => isReadOnly(status) && !isCancelled(status) && !isComplianceFailure(status),
);

const isComplianceFailure = statusFormatter(
  (status) => status === statusMapLowerCase.COMPLIANCE_FAILURE,
);

const canBeCancelled = statusFormatter(
  (status, isCreatedBy = true) => {
    const canCancel = ![cancelled, statusMapLowerCase.IN_PROGRESS, statusMapLowerCase.COMPLETED]
      .includes(status) && isCreatedBy;
    return canCancel;
  },
);

const schedulePickup = [
  statusMapLowerCase.SUBMITTED,
  statusMapLowerCase.SCHEDULED,
  statusMapLowerCase.PICKUP_CANCELLED,
];

const wasReceivedByCarrierStatuses = [
  statusMapLowerCase.IN_PROGRESS,
  statusMapLowerCase.COMPLETED,
];
const canSchedulePickup = statusFormatter(
  (status) => schedulePickup.includes(status),
);

const scheduled = [statusMapLowerCase.SCHEDULED, statusMapLowerCase.PICKUP_CANCELLED];
const hasBeenScheduled = statusFormatter(
  (status) => scheduled.includes(status),
);

const isScheduled = statusFormatter(
  (status) => status === statusMapLowerCase.SCHEDULED,
);

const wasReceivedByCarrier = statusFormatter(
  (status) => wasReceivedByCarrierStatuses.includes(status),
);

const isPickupCancelled = statusFormatter(
  (status) => statusMapLowerCase.PICKUP_CANCELLED === status,
);

const filterableStatuses = statuses.slice(0, 6);

export default {
  reverseStatusMap,
  statusMap,
  taskStatusMap,
  statuses,
  isReadOnly,
  canSchedulePickup,
  hasBeenScheduled,
  isPickupCancelled,
  isCompleted,
  isScheduled,
  isCancelled,
  isComplianceFailure,
  canBeCancelled,
  filterableStatuses,
  wasReceivedByCarrier,
};
