import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core/';

export default function TabPanel(props) {
  const {
    children, value, index, classes,
  } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      classes={{ root: classes.tabPanel }}
    >
      <Box>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.objectOf(PropTypes.any).isRequired,
  value: PropTypes.string.isRequired,
  index: PropTypes.string.isRequired,
  classes: PropTypes.shape({
    tabPanel: PropTypes.string,
  }).isRequired,
};
