/* eslint-disable no-nested-ternary */
import React, { memo, useEffect, useState } from 'react';
import {
  Switch,
  Route,
  Redirect,
  useLocation,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { setUser, useUserDispatch, useUserState } from './context/userContext';
import HealthCheck from './components/HealthCheck';
import NewOrderPage from './pages/NewOrder';
import NewShipOrderPage from './pages/NewShipOrder';
import ProfileManagementPage from './pages/ProfileManagement';
import MyShipmentsPage from './pages/MyShipments';
import AddressBookPage from './pages/AddressBook';
import HomePage from './pages/Workspace';
import BinDetailsPage from './pages/BinDetails';
import BinGroupPage from './pages/BinGroup';
import ShippingManifestPage from './pages/ShippingManifest';
import IPDShipmentPage from './pages/IPDShipment';
import MyTasksPage from './pages/MyTasks';
import ManageUsers from './pages/ManageUsers';
import AccountManagementPage from './pages/AccountManagement';
import MaterialDashboard from './pages/MaterialDashboard';
import HazmatShipments from './pages/HazmatShipments';
import AllShipments from './pages/AllShipments';
import InternationalShipments from './pages/InternationalShipments';
import BiologicalDashboard from './pages/BiologicalDashboard';
import './styles/starRating.css';
import './styles/autofill.css';
import {
  MY_TASKS_PATH,
  ADDRESS_BOOK_PATH,
  MANAGE_USERS_PATH,
  MY_SHIPMENTS_PATH,
  NEW_ORDER_PATH,
  PROFILE_MANAGEMENT_PATH,
  HAZMAT_PATH,
  INTERNATIONAL_PATH,
  ACCOUNT_MANAGEMENT_PATH,
  MATERIAL_DASHBOARD_PATH,
  BIOLOGICAL_DASHBOARD_PATH,
  ALL_PATH,
} from './clientConstants';
import GetParameterConstraints from './components/GetParameterConstraints';

const Delayed = ({ children, waitBeforeShow = 500 }) => {
  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsShown(true);
    }, waitBeforeShow);
    return () => clearTimeout(timer);
  }, [waitBeforeShow]);

  return isShown ? children : null;
};
function AuthenticatedApp({ profile, isEmptyDefaultAddress }) {
  const userDispatch = useUserDispatch();
  const userState = useUserState();
  const location = useLocation();
  useEffect(() => {
    if (profile) setUser(userDispatch, profile);
  }, [profile]);
  return (
    <>

      <Switch>
        <Route path="/home" component={HomePage} />
        <Route
          path={PROFILE_MANAGEMENT_PATH}
          component={ProfileManagementPage}
        />
        <Route
          path={ACCOUNT_MANAGEMENT_PATH}
          component={AccountManagementPage}
        />
        <Route
          path={['/order/:id', `${NEW_ORDER_PATH}/:id`, NEW_ORDER_PATH]}
          component={NewOrderPage}
        />
        <Route
          path={['/shiporder/:id', '/new-shiporder/:id', '/new-shiporder']}
          component={NewShipOrderPage}
        />

        <Route path={MY_SHIPMENTS_PATH} component={MyShipmentsPage} />
        <Route path={ADDRESS_BOOK_PATH} component={AddressBookPage} />
        <Route path="/bin-details" component={BinDetailsPage} />
        <Route path="/shipping-manifest" component={ShippingManifestPage} />
        <Route path="/ipd-shipment" component={IPDShipmentPage} />
        <Route path="/bin-group" component={BinGroupPage} />
        <Route path="/health-check" component={HealthCheck} />
        <Route path={MY_TASKS_PATH} component={MyTasksPage} />
        <Route path={HAZMAT_PATH} component={HazmatShipments} />
        <Route path={INTERNATIONAL_PATH} component={InternationalShipments} />
        <Route path={ALL_PATH} component={AllShipments} />
        <Route path={MANAGE_USERS_PATH} component={ManageUsers} />
        <Route path={MATERIAL_DASHBOARD_PATH} component={MaterialDashboard} />
        <Route path={BIOLOGICAL_DASHBOARD_PATH} component={BiologicalDashboard} />
        <Route
          path="/"
          // eslint-disable-next-line arrow-body-style
          render={() => {
            return userState?.account === 'worldemblem' ? (
              // eslint-disable-next-line react/jsx-indent
              <Redirect to="/bin-group" />
            ) : (
              <Redirect to="/home" />
            );
          }}
        />
        <Route render={() => (<Redirect to="/home" />)} />
      </Switch>
      <GetParameterConstraints />
      {isEmptyDefaultAddress ? (
        <Delayed>
          <Redirect from={`${location.pathname}`} to={`${location.pathname}?constraint=user-profile`} />
        </Delayed>
      )
        : profile && isEmptyDefaultAddress !== undefined && !isEmptyDefaultAddress && userState.userId !== undefined && (userState?.user?.isAckProhibited === undefined && userState.isAckProhibited === undefined) ? (
          <Delayed>
            <Redirect from={`${location.pathname}`} to={`${location.pathname}?constraint=ack-prohibited`} />
          </Delayed>
        )
          : (
            <Delayed>
              <Redirect from={`${location.pathname}?constraint=user-prohibited`} to={`${location.pathname}`} />
            </Delayed>
          )}

    </>
  );
}

AuthenticatedApp.propTypes = {
  profile: PropTypes.object.isRequired,
  isEmptyDefaultAddress: PropTypes.bool.isRequired,
};

export default memo(AuthenticatedApp);
