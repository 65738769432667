import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Avatar } from '@material-ui/core';

const sizes = {
  small: 30,
  medium: 50,
  large: 80,
  xl: 150,
  xxl: 300,
};

const fontSizes = {
  small: 12,
  medium: 20,
  large: 20,
  xl: 72,
  xxl: 72,
};

const StyledAvatar = withStyles({
  root: {
    backgroundColor: '#313547',
    color: '#B4B9CE',
    height: ({ size }) => sizes[size],
    width: ({ size }) => sizes[size],
    fontSize: ({ size }) => fontSizes[size],
  },
  img: {
    height: 'auto',
  },
})(Avatar);

export default function UserAvatar(props) {
  const {
    src, alt, isError, setIsError, hasControlledErrorState, title, size,
  } = props;
  const [isManualError, setIsManualError] = React.useState(false);

  React.useEffect(() => {
    hasControlledErrorState
      ? setIsError(false)
      : setIsManualError(false);
  }, [src]);

  function handleError(newState) {
    hasControlledErrorState
      ? setIsError(newState)
      : setIsManualError(newState);
  }

  function hasEitherControlledOrNonControlledError() {
    return (isError || isManualError);
  }

  return (
    <StyledAvatar
      src={!hasEitherControlledOrNonControlledError() && src}
      alt={alt}
      onError={() => handleError(true)}
      title={title}
      size={size}
    >
      {alt}
    </StyledAvatar>
  );
}

UserAvatar.propTypes = {
  src: PropTypes.string,
  title: PropTypes.string,
  alt: PropTypes.string.isRequired,
  hasControlledErrorState: PropTypes.bool,
  isError: PropTypes.bool,
  setIsError: PropTypes.func,
  size: PropTypes.oneOf(Object.keys(sizes)),
};

UserAvatar.defaultProps = {
  src: '',
  title: null,
  hasControlledErrorState: false,
  isError: false,
  setIsError: () => {},
  size: 'medium',
};
