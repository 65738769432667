import React from 'react';
import * as binClient from '../utils/binClient';
import { useLogger } from '../utils/hooks';

const binState = { salesOrders: {} };
const SingleBinStateContext = React.createContext();
const SingleBinDispatchContext = React.createContext();

// OrderReducer Action Types
const LOAD_SINGLE_BIN = 'LOAD SINGLE BIN';
const UPDATE_BIN_STATUS = 'UPDATE BIN STATUS';
// Reducer
function singleBinReducer(state, action) {
  switch (action.type) {
    case LOAD_SINGLE_BIN: {
      return { ...action.singleBinData };
    }
    case UPDATE_BIN_STATUS: {
      const { binId, update } = action;
      const updatedBin = { ...state.bin, [binId]: { ...update } };
      return { ...state, bin: { ...updatedBin } };
    }

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

// Provider
function SingleBinDataProvider(props) {
  const [state, dispatch] = useLogger(
    React.useReducer(singleBinReducer, binState),
  );

  return (
    <SingleBinStateContext.Provider value={state} {...props}>
      <SingleBinDispatchContext.Provider
        value={dispatch}
        {...props}
      />
    </SingleBinStateContext.Provider>
  );
}

// Exported functions
function loadSingleBin({ dispatch, packingSlipId }) {
  if (!packingSlipId) {
    return Promise.resolve(dispatch({
      type: LOAD_SINGLE_BIN,
      singleBinData: { salesOrders: [] },
    }));
  }

  return binClient.loadSingleBinDetails({ packingSlipId }).then((data) => {
    dispatch({ type: LOAD_SINGLE_BIN, singleBinData: data.response });
    console.log(data.response);
    return data.response;
  }).catch((e) => {
    dispatch({ type: LOAD_SINGLE_BIN, singleBinData: { salesOrders: [] } });
    console.log(e);

    return e.message;
  });
}

// Exported functions
function scanBin({ dispatch, binName }) {
  if (!binName) {
    return;
  }

  return binClient.scanBin({ binName }).then((data) => {
    dispatch({ type: LOAD_SINGLE_BIN, singleBinData: data.response });
    console.log(data.response);
    return data.response;
  }).catch((e) => {
    console.log(e);
    return e.message;
  });
}
function unScanPackingSlip() {
  return binClient.unScanPackingSlip().then((data) => data.response).catch((e) => e.message);
}
function clearBinState({ dispatch }) {
  return dispatch({ type: LOAD_SINGLE_BIN, singleBinData: { salesOrders: [] } });
}
// Exported functions
function deleteSalesOrder({ dispatch, salesOrderId }) {
  if (!salesOrderId) {
    return;
  }

  return binClient.deleteSalesOrder({ salesOrderId }).then((data) => {
    console.log(data.response);
    return data.response;
  }).catch((e) => {
    console.log(e);
    return e.message;
  });
}

function updateBinStatus({ dispatch, binId, bin }) {
  dispatch({
    type: UPDATE_BIN_STATUS,
    binId,
    update: { ...bin },
  });
}

function useSingleBinState() {
  const context = React.useContext(SingleBinStateContext);
  if (context === undefined) {
    throw new Error(
      'useSingleBinState must be used within a SingleBinDataProvider',
    );
  }
  return context;
}

function useSingleBinDispatch() {
  const context = React.useContext(SingleBinDispatchContext);
  if (context === undefined) {
    throw new Error(
      'useSingleBinDispatch must be used within a OrderDataProvider',
    );
  }
  return context;
}

export {
  SingleBinDataProvider,
  useSingleBinState,
  useSingleBinDispatch,
  scanBin,
  deleteSalesOrder,
  loadSingleBin,
  updateBinStatus,
  clearBinState,
  unScanPackingSlip,
};
