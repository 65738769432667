import { roundNumber } from '../../utils/shipmentClient';

export const BIOLOGICAL_HAZMAT_OTHER_CLASS_TYPE = 'Other';

export const CDC_SELECT_AGENT_LICENSE_KEY = 'cdcSelectAgent';
export const DOA_SELECT_AGENT_LICENSE_KEY = 'doaSelectAgent';
export const PPQ_SELECT_AGENT_LICENSE_KEY = 'ppqSelectAgent';
export const USDA_IMPORT_TRANS_PERMIT_LICENSE_KEY = 'usdaImportTransPermit';
export const APHIS_IMPORT_TRANS_PERMIT_LICENSE_KEY = 'aphisImportTransPermit';
export const SEARCH_INPUT_MIN_LENGTH = 2;
export const ITEM_WEIGHT_KEY = 'itemWeight';
export const INNER_WEIGHT_KEY = 'innerWeight';
export const TOTAL_VALUE_KEY = 'totalValue';
export const LITHIUM_BATTERY_CONTAINED_KEY = 'lithiumBattery';
export const LITHIUM_BATTERY_PACKED_KEY = 'lithiumBatteryPacked';
export const SHIPMENT_QUANTITY_NAME_KEY = 'shipmentQuantity';
export const SHIPMENT_QUANTITY_EXCEPTED_VALUE = 'excepted';
export const SHIPMENT_QUANTITY_LIMITED_VALUE = 'limited';
export const SHIPMENT_QUANTITY_BULK_VALUE = 'bulk';
export const TRANSPORTATION_OPTIONS_NAME_KEY = 'transportationMode';
export const TRANSPORTATION_OPTION_PASSENGER_CARGO_VALUE = 'passengerCargo';
export const TRANSPORTATION_OPTION_CARGO_VALUE = 'cargo';
export const TRANSPORTATION_OPTION_GROUND_VALUE = 'ground';
export const EQ_CODE_FORBIDDEN = 'E0';
export const PACKING_INSTRUCTIONS_FORBIDDEN = 'Forbidden';

export const LICENSE_KEYS = [
  CDC_SELECT_AGENT_LICENSE_KEY,
  DOA_SELECT_AGENT_LICENSE_KEY,
  PPQ_SELECT_AGENT_LICENSE_KEY,
  USDA_IMPORT_TRANS_PERMIT_LICENSE_KEY,
  APHIS_IMPORT_TRANS_PERMIT_LICENSE_KEY,
];

export const LICENSES_MAPPING = {
  [CDC_SELECT_AGENT_LICENSE_KEY]: 'STATE_CDC_SELECT_AGENT',
  [DOA_SELECT_AGENT_LICENSE_KEY]: 'DOA_SELECT_AGENT',
  [PPQ_SELECT_AGENT_LICENSE_KEY]: 'PPQ_SELECT_AGENT',
  [USDA_IMPORT_TRANS_PERMIT_LICENSE_KEY]: 'USDA_IMPORT_TRANS_PERMIT',
  [APHIS_IMPORT_TRANS_PERMIT_LICENSE_KEY]: 'APHIS_IMPORT_TRANS_PERMIT',
};

export const SHIPPING_LITHIUM_BATTERIES_KEY = 'isShippingLithiumBatteries';

export const FORM_FIELDS = {
  itemUrl: {
    key: 'itemUrl',
    label: 'Image',
  },
  itemImage: {
    key: 'itemImage',
  },
  itemName: {
    key: 'itemName',
    label: 'Product Name',
    editable: true,
  },
  itemId: {
    key: 'itemId',
    label: 'Product ID',
  },
  itemCategory: {
    key: 'itemCategory',
    label: 'Product Category',
  },
  sku: {
    key: 'sku',
    label: 'SKU',
    editable: true,
  },
  binNumber: {
    key: 'binNumber',
    label: 'Bin Number',
    editable: true,
  },
  dimensionsUnit: {
    key: 'itemDimensions.unitOfMeasurement',
    label: 'unit',
    editable: true,
  },
  dimensionsLength: {
    key: 'itemDimensions.length',
    label: 'Length',
    editable: true,
  },
  dimensionsWidth: {
    key: 'itemDimensions.width',
    label: 'Width',
    editable: true,
  },
  dimensionsHeight: {
    key: 'itemDimensions.height',
    label: 'Height',
    editable: true,
  },
  unitUnit: {
    key: 'units.unitOfMeasurement',
    label: 'Unit of measurement',
    editable: true,
  },
  unitValue: {
    key: 'units.noOfUnits',
    label: 'No. of Units',
    editable: true,
  },
  weight: {
    key: 'itemWeight.weight',
    label: 'Weight',
    editable: true,
  },
  weightUnit: {
    key: 'itemWeight.unitOfMeasurement',
    label: 'unit',
    editable: true,
  },
  smallWeight: {
    key: 'itemWeight.weightSmall',
    label: 'Weight',
    editable: true,
  },
  smallWeightUnit: {
    key: 'itemWeight.unitOfMeasurementSmall',
    label: 'unit',
    editable: true,
  },
  currencyCode: {
    key: 'units.unitValue.currency',
    label: 'USD',
    editable: true,
  },
  merchantCost: {
    key: 'units.unitValue.amount',
    label: 'Unit Value',
    editable: true,
  },
  description: {
    key: 'description',
    label: '',
    editable: true,
  },
  harmonizedCode: {
    key: 'harmonizedCode',
    label: 'Harmonized Code',
    editable: true,
  },
  eccnNo: {
    key: 'eccn',
    label: 'ECCN',
    editable: true,
  },
  isLicensePermitRequired: {
    key: 'isLicensePermitRequired',
    label: 'License Certification Acknowledgement',
    editable: true,
  },
  licenseType: {
    key: 'licenseType',
    label: 'License/Permit Type',
    editable: true,
  },
  licenseNumber: {
    key: 'licenseNumber',
    label: 'Number',
    editable: true,
  },
  countryOfManufacture: {
    key: 'countryOfManufacture',
    label: 'Country',
    editable: true,
  },
  freightClass: {
    key: 'freightClass',
    label: 'Class',
    editable: true,
  },
  nmfcCodeNumber: {
    key: 'nmfcCodeNumber',
    label: 'NMFC Code Number',
    editable: true,
  },
  productValue: {
    key: 'totalValue.amount',
    label: 'Product value',
    editable: true,
  },
  totalValueUnit: {
    key: 'totalValue.currency',
    editable: true,
  },
  totalWeight: {
    key: 'totalWeight.weight',
    label: 'Total weight',
    editable: true,
  },
  totalSmallWeight: {
    key: 'totalWeight.weightSmall',
    label: 'Total weight',
    editable: true,
  },
  totalWeightUnit: {
    key: 'totalWeight.unitOfMeasurement',
    label: 'lbs',
    editable: true,
  },
  totalSmallWeightUnit: {
    key: 'totalWeight.unitOfMeasurementSmall',
    label: 'oz',
    editable: true,
  },
  tariffCode: {
    key: 'tariffCode',
    label: 'Tariff code (%)',
    editable: true,
  },
  taxCode: {
    key: 'taxCode',
    label: 'Tax code (%)',
    editable: true,
  },
  isMaterialCheckRequired: {
    key: 'isMaterialCheckRequired',
  },
  [SHIPPING_LITHIUM_BATTERIES_KEY]: {
    key: SHIPPING_LITHIUM_BATTERIES_KEY,
  },
  isPreservative: {
    key: 'isPreservative',
    editable: true,
  },
  preservative: {
    key: 'preservative',
    label: 'Preservative',
    editable: true,
  },
  preservativeWeight: {
    key: 'preservativeWeight.weight',
    editable: true,
  },
  preservativeWeightUnit: {
    key: 'preservativeWeight.unitOfMeasurement',
    editable: true,
  },
  preservativeOuterWeight: {
    key: 'preservativeOuterWeight.weight',
    editable: true,
  },
  preservativeOuterWeightUnit: {
    key: 'preservativeOuterWeight.unitOfMeasurement',
    editable: true,
  },
};

export const FORM_FIELDS_HAZMAT = {
  itemUrl: {
    key: 'itemUrl',
    label: 'Image',
  },
  itemImage: {
    key: 'itemImage',
  },
  itemName: {
    key: 'itemName',
    label: 'Product Name',
  },
  itemId: {
    key: 'itemId',
    label: 'Product ID',
  },
  itemCategory: {
    key: 'itemCategory',
    label: 'Product Category',
  },
  sku: {
    key: 'sku',
    label: 'SKU',
  },
  binNumber: {
    key: 'binNumber',
    label: 'Bin Number',
  },
  dimensionsUnit: {
    key: 'itemDimensions.unitOfMeasurement',
    label: 'unit',
  },
  dimensionsLength: {
    key: 'itemDimensions.length',
    label: 'Length',
  },
  dimensionsWidth: {
    key: 'itemDimensions.width',
    label: 'Width',
  },
  dimensionsHeight: {
    key: 'itemDimensions.height',
    label: 'Height',
  },
  unitUnit: {
    key: 'units.unitOfMeasurement',
    label: 'Unit of measurement',
  },
  unitValue: {
    key: 'units.noOfUnits',
    label: 'No. of Units',
  },
  weight: {
    key: 'itemWeight.weight',
    label: 'Weight',
  },
  weightUnit: {
    key: 'itemWeight.unitOfMeasurement',
    label: 'unit',
  },
  smallWeight: {
    key: 'itemWeight.weightSmall',
    label: 'Weight',
  },
  smallWeightUnit: {
    key: 'itemWeight.unitOfMeasurementSmall',
    label: 'unit',
  },
  currencyCode: {
    key: 'units.unitValue.currency',
    label: 'USD',
  },
  merchantCost: {
    key: 'units.unitValue.amount',
    label: 'Unit Value',
  },
  description: {
    key: 'description',
    label: '',
  },
  harmonizedCode: {
    key: 'harmonizedCode',
    label: 'Harmonized Code',
    editable: true,
  },
  eccnNo: {
    key: 'eccn',
    label: 'ECCN',
  },
  isLicensePermitRequired: {
    key: 'isLicensePermitRequired',
    label: 'License Certification Acknowledgement',
  },
  licenseType: {
    key: 'licenseType',
    label: 'License/Permit Type',
  },
  licenseNumber: {
    key: 'licenseNumber',
    label: 'Number',
  },
  countryOfManufacture: {
    key: 'countryOfManufacture',
    label: 'Country',
  },
  freightClass: {
    key: 'freightClass',
    label: 'Class',
  },
  nmfcCodeNumber: {
    key: 'nmfcCodeNumber',
    label: 'NMFC Code Number',
  },
  productValue: {
    key: 'totalValue.amount',
    label: 'Product value',
  },
  totalValueUnit: {
    key: 'totalValue.currency',
  },
  totalWeight: {
    key: 'totalWeight.weight',
    label: 'Total weight',
  },
  totalSmallWeight: {
    key: 'totalWeight.weightSmall',
    label: 'Total weight',
  },
  totalWeightUnit: {
    key: 'totalWeight.unitOfMeasurement',
    label: 'lbs',
  },
  totalSmallWeightUnit: {
    key: 'totalWeight.unitOfMeasurementSmall',
    label: 'oz',
  },
  tariffCode: {
    key: 'tariffCode',
    label: 'Tariff code (%)',
  },
  taxCode: {
    key: 'taxCode',
    label: 'Tax code (%)',
  },
  classType: {
    key: 'itemCategory',
    label: 'Class Type',
  },
  classTypeName: {
    key: 'itemName',
    label: 'Class Type Name',
  },
  shipClass: {
    key: 'shipClass',
    label: 'Ship Class',
  },
  eccn: {
    key: 'eccn',
    label: 'ECCN No.',
  },
  unNo: {
    key: 'iataDetails.unNo',
    label: 'UN No.',
  },
  shippingName: {
    key: 'iataDetails.properShippingName',
    label: 'Shipping Name',
  },
  requiredLicenses: {
    key: 'requiredLicenses',
    label: 'Required Licenses',
  },
  [CDC_SELECT_AGENT_LICENSE_KEY]: {
    key: CDC_SELECT_AGENT_LICENSE_KEY,
    label: LICENSES_MAPPING[CDC_SELECT_AGENT_LICENSE_KEY],
  },
  [DOA_SELECT_AGENT_LICENSE_KEY]: {
    key: DOA_SELECT_AGENT_LICENSE_KEY,
    label: LICENSES_MAPPING[DOA_SELECT_AGENT_LICENSE_KEY],
  },
  [PPQ_SELECT_AGENT_LICENSE_KEY]: {
    key: PPQ_SELECT_AGENT_LICENSE_KEY,
    label: LICENSES_MAPPING[PPQ_SELECT_AGENT_LICENSE_KEY],
  },
  [USDA_IMPORT_TRANS_PERMIT_LICENSE_KEY]: {
    key: USDA_IMPORT_TRANS_PERMIT_LICENSE_KEY,
    label: LICENSES_MAPPING[USDA_IMPORT_TRANS_PERMIT_LICENSE_KEY],
  },
  [APHIS_IMPORT_TRANS_PERMIT_LICENSE_KEY]: {
    key: APHIS_IMPORT_TRANS_PERMIT_LICENSE_KEY,
    label: LICENSES_MAPPING[APHIS_IMPORT_TRANS_PERMIT_LICENSE_KEY],
  },
  packagingNumber: {
    key: 'iataDetails.packagingInstructions',
    label: 'Number',
  },
  packagingGroup: {
    key: 'packingGroup',
    label: 'Group',
  },
  iataDetails: {
    key: 'iataDetails',
    label: 'IATA Details',
  },
  transportationOption: {
    key: 'transportationMode',
    label: 'Transportation Option',
  },
  isPreservative: {
    key: 'isPreservative',
  },
  preservative: {
    key: 'preservative',
    label: 'Preservative',
  },
  preservativeWeight: {
    key: 'preservativeWeight.weight',
  },
  preservativeWeightUnit: {
    key: 'preservativeWeight.unitOfMeasurement',
  },
  preservativeOuterWeight: {
    key: 'preservativeOuterWeight.weight',
  },
  preservativeOuterWeightUnit: {
    key: 'preservativeOuterWeight.unitOfMeasurement',
  },
};

export const FORM_FIELDS_CHEMICAL_HAZMAT = {
  itemId: {
    key: 'itemId',
    label: 'Product ID',
  },
  itemUrl: {
    key: 'itemUrl',
    label: 'Item URL',
  },
  eqCode: {
    key: 'eqCode',
  },
  materialName: {
    key: 'itemName',
    label: 'Item Name',
  },
  unNo: {
    key: 'unNo',
    label: 'UN No.',
  },
  [FORM_FIELDS.description.key]: {
    key: FORM_FIELDS.description.key,
    label: FORM_FIELDS.description.label,
  },
  properShippingName: {
    key: 'properShipName',
    label: 'Proper Shipping Name',
  },
  technicalName: {
    key: 'technicalName',
    label: 'Technical Name',
  },
  classDivision: {
    key: 'classDivision',
    label: 'Class or Division',
  },
  packingGroup: {
    key: 'packingGroup',
    label: 'Packing Group',
  },
  labels: {
    key: 'hazmatLabels',
    label: 'Labels',
  },
  packingInstructions: {
    key: 'packingInstr',
    label: 'Packing Instructions',
  },
  specialProvisions: {
    key: 'specialProvisions',
    label: 'Special Provisions',
  },
  authorization: {
    key: 'authorization',
    label: 'Authorization',
  },
  specialPermits: {
    key: 'specialPermits',
    label: 'Special Permit',
  },
  innerPackaging: {
    key: 'innerPackaging',
    label: 'Inner Packaging',
  },
  [INNER_WEIGHT_KEY]: {
    key: `${INNER_WEIGHT_KEY}.weight`,
    label: 'Weight',
  },
  innerWeightUnit: {
    key: `${INNER_WEIGHT_KEY}.unitOfMeasurement`,
    label: 'Unit',
  },
  innerSmallWeight: {
    key: `${INNER_WEIGHT_KEY}.weightSmall`,
    label: 'Weight',
  },
  innerSmallWeightUnit: {
    key: `${INNER_WEIGHT_KEY}.unitOfMeasurementSmall`,
    label: 'Unit',
  },
  [ITEM_WEIGHT_KEY]: {
    key: `${ITEM_WEIGHT_KEY}.weight`,
    label: 'Weight',
  },
  totalWeightUnit: {
    key: `${ITEM_WEIGHT_KEY}.unitOfMeasurement`,
    label: 'Unit',
  },
  totalSmallWeight: {
    key: `${ITEM_WEIGHT_KEY}.weightSmall`,
    label: 'Weight',
  },
  totalSmallWeightUnit: {
    key: `${ITEM_WEIGHT_KEY}.unitOfMeasurementSmall`,
    label: 'Unit',
  },
  netValue: {
    key: `${TOTAL_VALUE_KEY}.amount`,
    label: 'Net Value (Min. 1)',
  },
  totalValueUnit: {
    key: `${TOTAL_VALUE_KEY}.currency`,
  },
  countryOfManufacture: {
    key: 'countryOfManufacture',
    label: 'Country',
  },
  tariffCode: {
    key: 'tariffCode',
    label: 'Tariff code (%)',
  },
  taxCode: {
    key: 'taxCode',
    label: 'Tax code (%)',
  },
  cargo: {
    key: 'cargo',
    label: 'Cargo',
  },
  limited: {
    key: 'limited',
    label: 'Limited',
  },
  passengerCargo: {
    key: 'passengerCargo',
    label: 'Passenger cargo',
  },
  excepted: {
    key: 'excepted',
    label: 'Excepted',
  },
  technicalNameRequired: {
    key: 'technicalNameRequired',
  },
};

export const FORM_FIELDS_BATTERIES = {
  itemId: {
    key: 'itemId',
    label: 'Product ID',
  },
  itemUrl: {
    key: 'itemUrl',
    label: 'Item URL',
  },
  materialName: {
    key: 'itemName',
    label: 'Item Name',
  },
  unNo: {
    key: 'unNo',
    label: 'UN No.',
  },
  [FORM_FIELDS.description.key]: {
    key: FORM_FIELDS.description.key,
    label: FORM_FIELDS.description.label,
  },
  properShippingName: {
    key: 'properShipName',
    label: 'Proper Shipping Name',
  },
  batteryCapacity: {
    key: 'batteryCapacity',
    label: 'Battery Capacity',
  },
  batteryCapacityUnit: {
    key: 'batteryCapacityUnit',
    label: 'Unit',
  },
  classDivision: {
    key: 'classDivision',
    label: 'Class or Division',
  },
  packingGroup: {
    key: 'packingGroup',
    label: 'Packing Group',
  },
  labels: {
    key: 'hazmatLabels',
    label: 'Labels',
  },
  packingInstructions: {
    key: 'packingInstr',
    label: 'Packing Instructions',
  },
  units: {
    key: 'units.noOfUnits',
    label: 'No. Of Units',
  },
  authorization: {
    key: 'authorization',
    label: 'Authorization',
  },
  innerPackaging: {
    key: 'innerPackaging',
    label: 'Inner Packaging',
  },
  [ITEM_WEIGHT_KEY]: {
    key: `${ITEM_WEIGHT_KEY}.weight`,
    label: 'Weight',
  },
  totalWeightUnit: {
    key: `${ITEM_WEIGHT_KEY}.unitOfMeasurement`,
    label: 'Unit',
  },
  totalSmallWeight: {
    key: `${ITEM_WEIGHT_KEY}.weightSmall`,
    label: 'Weight',
  },
  totalSmallWeightUnit: {
    key: `${ITEM_WEIGHT_KEY}.unitOfMeasurementSmall`,
    label: 'Unit',
  },
  netValue: {
    key: `${TOTAL_VALUE_KEY}.amount`,
    label: 'Net Value (Min. 1)',
  },
  totalValueUnit: {
    key: `${TOTAL_VALUE_KEY}.currency`,
    label: 'Currency',
  },
  countryOfManufacture: {
    key: 'countryOfManufacture',
    label: 'Country',
  },
  tariffCode: {
    key: 'tariffCode',
    label: 'Tariff code (%)',
  },
  taxCode: {
    key: 'taxCode',
    label: 'Tax code (%)',
  },
  cargo: {
    key: 'cargo',
    label: 'Cargo',
  },
  passengerCargo: {
    key: 'passengerCargo',
    label: 'Passenger cargo',
  },
  trainingRequired: {
    key: 'trainingRequired',
  },
  noOfUnits: {
    key: 'noOfUnits',
  },
};

export const FORM_FIELDS_LITHIUM_BATTERY = {
  itemId: {
    key: 'itemId',
    label: 'Product ID',
  },
  materialName: {
    key: 'itemName',
    label: 'Item Name',
  },
  unNo: {
    key: 'unNo',
    label: 'UN No.',
  },
  properShippingName: {
    key: 'properShipName',
    label: 'Proper Shipping Name',
  },
  batteryCapacity: {
    key: 'batteryCapacity',
    label: 'Battery Capacity',
  },
  classDivision: {
    key: 'classDivision',
    label: 'Class or Division',
  },
  packingGroup: {
    key: 'packingGroup',
    label: 'Packing Group',
  },
  labels: {
    key: 'hazmatLabels',
    label: 'Labels',
  },
  packingInstructions: {
    key: 'packingInstr',
    label: 'Packing Instructions',
  },
  units: {
    key: 'noOfUnits',
    label: 'No. Of Units',
  },
  authorization: {
    key: 'authorization',
    label: 'Authorization',
  },
  weight: {
    key: 'weight',
    label: 'Weight',
  },
  weightUnit: {
    key: 'weightUnit',
    label: 'Unit',
  },
  [TRANSPORTATION_OPTION_PASSENGER_CARGO_VALUE]: {
    key: TRANSPORTATION_OPTION_PASSENGER_CARGO_VALUE,
    label: 'Passenger cargo',
  },
  [TRANSPORTATION_OPTION_CARGO_VALUE]: {
    key: TRANSPORTATION_OPTION_CARGO_VALUE,
    label: 'Cargo',
  },
  [TRANSPORTATION_OPTION_GROUND_VALUE]: {
    key: TRANSPORTATION_OPTION_GROUND_VALUE,
    label: 'Ground',
  },
  trainingRequired: {
    key: 'trainingRequired',
  },
};

export const FORM_FIELDS_BATTERIES_CONTAINED_OR_PACKED = {
  ...FORM_FIELDS,
  [LITHIUM_BATTERY_CONTAINED_KEY]: FORM_FIELDS_LITHIUM_BATTERY,
  [LITHIUM_BATTERY_PACKED_KEY]: FORM_FIELDS_LITHIUM_BATTERY,
};

export const SHIPPING_TYPES = {
  international: 'international',
  domestic: 'domestic',
};

export const FREIGHT_TYPES = {
  freight: 'freight',
  document: 'document',
  product: 'product',
  hazmat: 'hazmat',
};

export const PRODUCT_CATEGORY_OPTIONS = [
  {
    key: 'electronic',
    label: 'Electronic',
  },
  {
    key: 'clothing',
    label: 'Clothing',
  },
  {
    key: 'toys',
    label: 'Toys',
  },
];

export const DIMENSION_UNIT_OPTIONS = [
  {
    key: 'in',
    label: 'IN',
  },
  {
    key: 'cm',
    label: 'CM',
  },
];

export const WEIGHT_UNIT_OPTIONS = [
  {
    key: 'kg',
    label: 'KG',
  },
  {
    key: 'lb',
    label: 'LB',
  },
];

export const UNIT_UNIT_OPTIONS = [
  {
    key: 'carton',
    label: 'Carton',
  },
  {
    key: 'bundle',
    label: 'Bundle',
  },
];

export const CURRENCY_OPTIONS = [
  {
    key: 'USD',
    label: 'USD',
  },
];

export const COUNTRY_OPTIONS = [
  {
    key: 'china',
    label: 'China',
  },
  {
    key: 'usa',
    label: 'United States',
  },
];

export const FORM_LABELS = {
  product: 'Product',
  productDimensions: 'Product Dimensions',
  productWeight: 'Product Weight',
  productMeasurements: 'Unit Details',
  packagingInstructions: 'Packaging Instructions',
  calculated: 'Calculated',
  productValue: 'Product Value',
  description: 'Description',
  codes: 'Codes',
  licencePermitNumber: 'License/Permit Number',
  countryOfManufacture: 'Country of Manufacture',
  freightOnly: 'Freight Only',
  tariffCode: 'Tariff Code',
  taxCode: 'Tax Code',
  innerReceptacle: 'Maximum Quantity Per Inner Receptacle',
  totalQuantity: 'Total Quantity in Package',
  [SHIPMENT_QUANTITY_NAME_KEY]: 'Shipment Quantities',
  [TRANSPORTATION_OPTIONS_NAME_KEY]: 'Transportation Options',
};

export const EMPTY_CHEMICAL_ITEM_OBJECT = {
  [FORM_FIELDS_CHEMICAL_HAZMAT.itemId.key]: '',
  [FORM_FIELDS_CHEMICAL_HAZMAT.itemUrl.key]: '',
  [[FORM_FIELDS_CHEMICAL_HAZMAT.unNo.key]]: '',
  [FORM_FIELDS.description.key]: '',
  [FORM_FIELDS_CHEMICAL_HAZMAT.properShippingName.key]: '',
  [FORM_FIELDS_CHEMICAL_HAZMAT.technicalName.key]: '',
  [FORM_FIELDS_CHEMICAL_HAZMAT.classDivision.key]: '',
  [FORM_FIELDS_CHEMICAL_HAZMAT.packingGroup.key]: '',
  [FORM_FIELDS_CHEMICAL_HAZMAT.labels.key]: '',
  [FORM_FIELDS_CHEMICAL_HAZMAT.packingInstructions.key]: '',
  [FORM_FIELDS_CHEMICAL_HAZMAT.specialProvisions.key]: '',
  [FORM_FIELDS_CHEMICAL_HAZMAT.authorization.key]: '',
  [FORM_FIELDS_CHEMICAL_HAZMAT.specialPermits.key]: '',
  [FORM_FIELDS_CHEMICAL_HAZMAT.innerPackaging.key]: '',
  [INNER_WEIGHT_KEY]: {
    weight: '',
    unitOfMeasurement: '',
    weightSmall: '',
    unitOfMeasurementSmall: '',
  },
  [ITEM_WEIGHT_KEY]: {
    weight: '',
    unitOfMeasurement: '',
    weightSmall: '',
    unitOfMeasurementSmall: '',
  },
  totalValue: {
    amount: '',
    currency: CURRENCY_OPTIONS[0].key,
  },
  [TRANSPORTATION_OPTIONS_NAME_KEY]: '',
  harmonizedCode: '',
  isLicensePermitRequired: 'no',
  [FORM_FIELDS_CHEMICAL_HAZMAT.countryOfManufacture.key]: '',
  [FORM_FIELDS_CHEMICAL_HAZMAT.tariffCode.key]: '',
  [FORM_FIELDS_CHEMICAL_HAZMAT.taxCode.key]: '',
  totalWeightKG: '',
  totalWeightLB: '',
};

export const EMPTY_BATTERY_ITEM_OBJECT = {
  [FORM_FIELDS_BATTERIES.itemId.key]: '',
  [FORM_FIELDS_BATTERIES.itemUrl.key]: '',
  [[FORM_FIELDS_BATTERIES.itemId.key]]: '',
  [[FORM_FIELDS_BATTERIES.unNo.key]]: '',
  [FORM_FIELDS.description.key]: '',
  [FORM_FIELDS_BATTERIES.properShippingName.key]: '',
  [FORM_FIELDS_BATTERIES.classDivision.key]: '',
  [FORM_FIELDS_BATTERIES.packingGroup.key]: '',
  [FORM_FIELDS_BATTERIES.labels.key]: '',
  [FORM_FIELDS_BATTERIES.cargo.key]: '',
  [FORM_FIELDS_BATTERIES.packingInstructions.key]: '',
  [FORM_FIELDS_BATTERIES.authorization.key]: '',
  [ITEM_WEIGHT_KEY]: {
    weight: '',
    unitOfMeasurement: '',
    weightSmall: '',
    unitOfMeasurementSmall: '',
  },
  noOfUnits: '',
  totalValue: {
    amount: '',
    currency: CURRENCY_OPTIONS[0].key,
  },
  units: {
    unitValue: {
      amount: '',
      currency: CURRENCY_OPTIONS[0].key,
    },
    noOfUnits: '',
    unitOfMeasurement: '',
  },
  [TRANSPORTATION_OPTIONS_NAME_KEY]: '',
  harmonizedCode: '',
  isLicensePermitRequired: 'no',
  [FORM_FIELDS_BATTERIES.countryOfManufacture.key]: '',
  [FORM_FIELDS_BATTERIES.tariffCode.key]: '',
  [FORM_FIELDS_BATTERIES.taxCode.key]: '',
  totalWeightKG: '',
  totalWeightLB: '',
};

export const EMPTY_LITHIUM_BATTERY_CONTAINED_OR_PACKED_ITEM_OBJECT = {
  [FORM_FIELDS_LITHIUM_BATTERY.itemId.key]: '',
  [FORM_FIELDS_LITHIUM_BATTERY.unNo.key]: '',
  [FORM_FIELDS_LITHIUM_BATTERY.properShippingName.key]: '',
  [FORM_FIELDS_LITHIUM_BATTERY.classDivision.key]: '',
  [FORM_FIELDS_LITHIUM_BATTERY.packingGroup.key]: '',
  [FORM_FIELDS_LITHIUM_BATTERY.labels.key]: '',
  [FORM_FIELDS_LITHIUM_BATTERY.cargo.key]: '',
  [FORM_FIELDS_LITHIUM_BATTERY.packingInstructions.key]: '',
  [FORM_FIELDS_LITHIUM_BATTERY.authorization.key]: '',
  [FORM_FIELDS_LITHIUM_BATTERY.weight.key]: '',
  [FORM_FIELDS_LITHIUM_BATTERY.weightUnit.key]: 'KG',
  [FORM_FIELDS_LITHIUM_BATTERY.units.key]: '',
};

export const EMPTY_ITEM_OBJECT = {
  sku: '',
  binNumber: '',
  itemName: '',
  itemId: '',
  itemCategory: '',
  itemUrl: '',
  units: {
    unitValue: {
      amount: '',
      currency: CURRENCY_OPTIONS[0].key,
    },
    noOfUnits: '',
    unitOfMeasurement: '',
  },
  itemWeight: {
    weight: '',
    unitOfMeasurement: WEIGHT_UNIT_OPTIONS[0].key,
    weightSmall: '',
    unitOfMeasurementSmall: 'oz',
  },
  itemDimensions: {
    unitOfMeasurement: DIMENSION_UNIT_OPTIONS[0].key,
    length: '',
    width: '',
    height: '',
  },
  totalWeight: {
    weight: '',
    unitOfMeasurement: '',
    weightSmall: 0,
    unitOfMeasurementSmall: '',
  },
  totalValue: {
    amount: '',
    currency: '',
  },
  description: '',
  harmonizedCode: '',
  eccn: '',
  isLicensePermitRequired: 'no',
  licenseType: '',
  licenseNumber: '',
  countryOfManufacture: '',
  freightClass: '',
  nmfcCodeNumber: '',
  itemImage: '',
  tariffCode: '',
  taxCode: '',
  [SHIPPING_LITHIUM_BATTERIES_KEY]: false,
};

export const DISPLAY_FIELDS = [
  {
    label: FORM_FIELDS.itemId.label,
    value: FORM_FIELDS.itemId.key,
  },
  {
    label: FORM_FIELDS.binNumber.label,
    value: FORM_FIELDS.binNumber.key,
  },
  {
    label: FORM_FIELDS.sku.label,
    value: FORM_FIELDS.sku.key,
  },
  {
    label: FORM_FIELDS.itemCategory.label,
    value: FORM_FIELDS.itemCategory.key,
  },
  {
    label: 'Unit Type',
    value: FORM_FIELDS.unitUnit.key,
  },
  {
    label: 'Units',
    value: FORM_FIELDS.unitValue.key,
  },
  {
    label: 'Length, Width, Height',
    values: [
      FORM_FIELDS.dimensionsLength.key,
      FORM_FIELDS.dimensionsWidth.key,
      FORM_FIELDS.dimensionsHeight.key,
    ],
    seperator: 'x',
    postFix: FORM_FIELDS.dimensionsUnit.key,
  },
  {
    label: FORM_FIELDS.weight.label,
    values: [
      FORM_FIELDS.weight.key,
      FORM_FIELDS.weightUnit.key,
      FORM_FIELDS.smallWeight.key,
    ],
    seperator: ' ',
    postFix: FORM_FIELDS.smallWeightUnit.key,
    toUpper: true,
  },
  {
    label: 'Total Weight',
    values: [
      FORM_FIELDS.totalWeight.key,
      FORM_FIELDS.totalWeightUnit.key,
      FORM_FIELDS.totalSmallWeight.key,
    ],
    seperator: ' ',
    postFix: FORM_FIELDS.totalSmallWeightUnit.key,
    toUpper: true,
  },
  {
    label: 'Value',
    value: FORM_FIELDS.merchantCost.key,
    postFix: FORM_FIELDS.currencyCode.key,
  },
  {
    label: 'Total Value',
    value: FORM_FIELDS.productValue.key,
    postFix: FORM_FIELDS.totalValueUnit.key,
  },
  {
    label: 'Description',
    value: FORM_FIELDS.description.key,
  },
  {
    label: 'Harmonized',
    value: FORM_FIELDS.harmonizedCode.key,
  },
  {
    label: 'ECCN',
    value: FORM_FIELDS.eccnNo.key,
  },
  {
    label: FORM_FIELDS.nmfcCodeNumber.label,
    value: FORM_FIELDS.nmfcCodeNumber.key,
  },
  {
    label: FORM_FIELDS.freightClass.label,
    value: FORM_FIELDS.freightClass.key,
  },
  {
    label: FORM_FIELDS.isLicensePermitRequired.label,
    value: FORM_FIELDS.isLicensePermitRequired.key,
  },
  {
    label: 'License',
    value: FORM_FIELDS.licenseType.key,
  },
  {
    label: 'License #',
    value: FORM_FIELDS.licenseNumber.key,
  },
  {
    label: 'Country',
    value: FORM_FIELDS.countryOfManufacture.key,
  },
  {
    label: FORM_FIELDS.tariffCode.label,
    value: FORM_FIELDS.tariffCode.key,
  },
  {
    label: FORM_FIELDS.taxCode.label,
    value: FORM_FIELDS.taxCode.key,
  },
];

export const DISPLAY_BIOLOGICAL_HAZMAT_FIELDS = [
  {
    label: FORM_FIELDS_HAZMAT.itemId.label,
    value: FORM_FIELDS_HAZMAT.itemId.key,
  },
  {
    label: FORM_FIELDS_HAZMAT.classType.label,
    value: FORM_FIELDS_HAZMAT.classType.key,
  },
  {
    label: FORM_FIELDS_HAZMAT.classTypeName.label,
    value: FORM_FIELDS_HAZMAT.classTypeName.key,
  },
  {
    label: FORM_FIELDS_HAZMAT.sku.label,
    value: FORM_FIELDS_HAZMAT.sku.key,
  },
  {
    label: 'Packaging Instructions',
    value: FORM_FIELDS_HAZMAT.packagingNumber.key,
  },
  {
    label: `Packaging ${FORM_FIELDS_HAZMAT.packagingGroup.label}`,
    value: FORM_FIELDS_HAZMAT.packagingGroup.key,
  },
  {
    label: FORM_FIELDS_HAZMAT.requiredLicenses.label,
    value: FORM_FIELDS_HAZMAT.requiredLicenses.key,
  },
  {
    label: 'Unit Type',
    value: FORM_FIELDS_HAZMAT.unitUnit.key,
  },
  {
    label: 'Units',
    value: FORM_FIELDS_HAZMAT.unitValue.key,
  },
  {
    label: FORM_FIELDS_HAZMAT.weight.label,
    values: [
      FORM_FIELDS_HAZMAT.weight.key,
      FORM_FIELDS_HAZMAT.weightUnit.key,
      FORM_FIELDS_HAZMAT.smallWeight.key,
    ],
    seperator: ' ',
    postFix: FORM_FIELDS_HAZMAT.smallWeightUnit.key,
    toUpper: true,
  },
  {
    label: 'Total Weight',
    value: FORM_FIELDS_HAZMAT.totalWeight.key,
    postFix: FORM_FIELDS_HAZMAT.totalWeightUnit.key,
  },
  {
    label: 'Value',
    value: FORM_FIELDS_HAZMAT.merchantCost.key,
    postFix: FORM_FIELDS_HAZMAT.currencyCode.key,
  },
  {
    label: 'Total Value',
    value: FORM_FIELDS_HAZMAT.productValue.key,
    postFix: FORM_FIELDS_HAZMAT.totalValueUnit.key,
  },
  {
    label: 'Description',
    value: FORM_FIELDS_HAZMAT.description.key,
  },
  {
    label: 'Harmonized',
    value: FORM_FIELDS_HAZMAT.harmonizedCode.key,
  },
  {
    label: 'ECCN',
    value: FORM_FIELDS_HAZMAT.eccnNo.key,
  },
  {
    label: FORM_FIELDS_HAZMAT.shipClass.label,
    value: FORM_FIELDS_HAZMAT.shipClass.key,
  },
  {
    label: FORM_FIELDS_HAZMAT.unNo.label,
    value: FORM_FIELDS_HAZMAT.unNo.key,
  },
  {
    label: FORM_FIELDS_HAZMAT.shippingName.label,
    value: FORM_FIELDS_HAZMAT.shippingName.key,
  },
  {
    label: FORM_FIELDS_HAZMAT.transportationOption.label,
    value: FORM_FIELDS_HAZMAT.transportationOption.key,
  },
  {
    label: 'Q Ratio',
    value: 'qRatio',
    format: (value) => (value ? roundNumber(value, 4) : ''),
  },
  {
    label: 'Preservative',
    value: FORM_FIELDS.preservative.key,
  },
  {
    label: 'Prservative Volume',
    value: FORM_FIELDS.preservativeWeight.key,
    postFix: FORM_FIELDS.preservativeWeightUnit.key,
    toUpper: true,
  },
  {
    label: 'Prservative Outer Volume',
    value: FORM_FIELDS.preservativeOuterWeight.key,
    postFix: FORM_FIELDS.preservativeOuterWeightUnit.key,
    toUpper: true,
  },
  {
    label: FORM_FIELDS_HAZMAT.nmfcCodeNumber.label,
    value: FORM_FIELDS_HAZMAT.nmfcCodeNumber.key,
  },
  {
    label: FORM_FIELDS_HAZMAT.freightClass.label,
    value: FORM_FIELDS_HAZMAT.freightClass.key,
  },
  {
    label: FORM_FIELDS_HAZMAT.isLicensePermitRequired.label,
    value: FORM_FIELDS_HAZMAT.isLicensePermitRequired.key,
  },
  {
    label: 'License',
    value: FORM_FIELDS_HAZMAT.licenseType.key,
  },
  {
    label: 'License #',
    value: FORM_FIELDS_HAZMAT.licenseNumber.key,
  },
  {
    label: 'Country',
    value: FORM_FIELDS_HAZMAT.countryOfManufacture.key,
  },
  {
    label: FORM_FIELDS_HAZMAT.tariffCode.label,
    value: FORM_FIELDS_HAZMAT.tariffCode.key,
  },
  {
    label: FORM_FIELDS_HAZMAT.taxCode.label,
    value: FORM_FIELDS_HAZMAT.taxCode.key,
  },
];

export const DISPLAY_CHEMICAL_HAZMAT_FIELDS = [
  {
    label: FORM_FIELDS_CHEMICAL_HAZMAT.unNo.label,
    value: FORM_FIELDS_CHEMICAL_HAZMAT.unNo.key,
  },
  {
    label: FORM_FIELDS_CHEMICAL_HAZMAT.itemId.label,
    value: FORM_FIELDS_CHEMICAL_HAZMAT.itemId.key,
  },
  {
    label: FORM_FIELDS_CHEMICAL_HAZMAT.materialName.label,
    value: FORM_FIELDS_CHEMICAL_HAZMAT.materialName.key,
  },
  {
    label: FORM_FIELDS_CHEMICAL_HAZMAT.properShippingName.label,
    value: FORM_FIELDS_CHEMICAL_HAZMAT.properShippingName.key,
  },
  {
    label: FORM_FIELDS_CHEMICAL_HAZMAT.classDivision.label,
    value: FORM_FIELDS_CHEMICAL_HAZMAT.classDivision.key,
  },
  {
    label: FORM_FIELDS_CHEMICAL_HAZMAT.packingGroup.label,
    value: FORM_FIELDS_CHEMICAL_HAZMAT.packingGroup.key,
  },
  {
    label: FORM_FIELDS_CHEMICAL_HAZMAT.packingInstructions.label,
    value: FORM_FIELDS_CHEMICAL_HAZMAT.packingInstructions.key,
  },
  {
    label: FORM_FIELDS_CHEMICAL_HAZMAT.innerPackaging.label,
    value: FORM_FIELDS_CHEMICAL_HAZMAT.innerPackaging.key,
  },
  {
    label: FORM_FIELDS_CHEMICAL_HAZMAT.classDivision.label,
    value: FORM_FIELDS_CHEMICAL_HAZMAT.classDivision.key,
  },
  {
    label: 'Inner Weight',
    values: [
      FORM_FIELDS_CHEMICAL_HAZMAT[INNER_WEIGHT_KEY].key,
      FORM_FIELDS_CHEMICAL_HAZMAT.innerWeightUnit.key,
      FORM_FIELDS_CHEMICAL_HAZMAT.innerSmallWeight.key,
    ],
    seperator: ' ',
    postFix: FORM_FIELDS_CHEMICAL_HAZMAT.innerSmallWeightUnit.key,
    toUpper: true,
  },
  {
    label: 'Total Weight',
    values: [
      FORM_FIELDS_CHEMICAL_HAZMAT[ITEM_WEIGHT_KEY].key,
      FORM_FIELDS_CHEMICAL_HAZMAT.totalWeightUnit.key,
      FORM_FIELDS_CHEMICAL_HAZMAT.totalSmallWeight.key,
    ],
    seperator: ' ',
    postFix: FORM_FIELDS_CHEMICAL_HAZMAT.totalSmallWeightUnit.key,
    toUpper: true,
  },
  {
    label: 'Total Value',
    value: FORM_FIELDS_CHEMICAL_HAZMAT.netValue.key,
    postFix: FORM_FIELDS_CHEMICAL_HAZMAT.totalValueUnit.key,
  },
  {
    label: 'Q Ratio',
    value: 'qRatio',
    format: (value) => (value ? roundNumber(value, 4) : ''),
  },
  {
    label: 'Country',
    value: FORM_FIELDS_CHEMICAL_HAZMAT.countryOfManufacture.key,
  },
  {
    label: FORM_FIELDS_CHEMICAL_HAZMAT.tariffCode.label,
    value: FORM_FIELDS_CHEMICAL_HAZMAT.tariffCode.key,
  },
  {
    label: FORM_FIELDS_CHEMICAL_HAZMAT.taxCode.label,
    value: FORM_FIELDS_CHEMICAL_HAZMAT.taxCode.key,
  },
  {
    label: 'Transportation Mode',
    value: TRANSPORTATION_OPTIONS_NAME_KEY,
    format: (value) => (value ? TRANSPORTATION_OPTIONS_TABLE[value] : ''),
  },
];

export const DISPLAY_BATTERY_FIELDS = [
  {
    label: FORM_FIELDS_BATTERIES.unNo.label,
    value: FORM_FIELDS_BATTERIES.unNo.key,
  },
  {
    label: FORM_FIELDS_BATTERIES.itemId.label,
    value: FORM_FIELDS_BATTERIES.itemId.key,
  },
  {
    label: FORM_FIELDS_BATTERIES.materialName.label,
    value: FORM_FIELDS_BATTERIES.materialName.key,
  },
  {
    label: FORM_FIELDS_BATTERIES.properShippingName.label,
    value: FORM_FIELDS_BATTERIES.properShippingName.key,
  },
  {
    label: FORM_FIELDS_BATTERIES.classDivision.label,
    value: FORM_FIELDS_BATTERIES.classDivision.key,
  },
  {
    label: FORM_FIELDS_BATTERIES.packingInstructions.label,
    value: FORM_FIELDS_BATTERIES.packingInstructions.key,
  },
  {
    label: 'Units',
    value: FORM_FIELDS_HAZMAT.unitValue.key,
  },
  {
    label: 'Total Weight',
    values: [
      FORM_FIELDS_BATTERIES[ITEM_WEIGHT_KEY].key,
      FORM_FIELDS_BATTERIES.totalWeightUnit.key,
      FORM_FIELDS_BATTERIES.totalSmallWeight.key,
    ],
    seperator: ' ',
    postFix: FORM_FIELDS_BATTERIES.totalSmallWeightUnit.key,
    toUpper: true,
  },
  {
    label: 'Total Value',
    value: FORM_FIELDS_BATTERIES.netValue.key,
    postFix: FORM_FIELDS_BATTERIES.totalValueUnit.key,
  },
  {
    label: 'Country',
    value: FORM_FIELDS_BATTERIES.countryOfManufacture.key,
  },
  {
    label: FORM_FIELDS_BATTERIES.tariffCode.label,
    value: FORM_FIELDS_BATTERIES.tariffCode.key,
  },
  {
    label: FORM_FIELDS_BATTERIES.taxCode.label,
    value: FORM_FIELDS_BATTERIES.taxCode.key,
  },
  {
    label: 'Transportation Mode',
    value: TRANSPORTATION_OPTIONS_NAME_KEY,
    format: (value) => (value ? TRANSPORTATION_OPTIONS_TABLE[value] : ''),
  },
];

export const DISPLAY_BATTERY_CONTAINED_FIELDS = [
  {
    label: FORM_FIELDS_BATTERIES_CONTAINED_OR_PACKED[LITHIUM_BATTERY_CONTAINED_KEY].unNo.label,
    value: FORM_FIELDS_BATTERIES_CONTAINED_OR_PACKED[LITHIUM_BATTERY_CONTAINED_KEY].unNo.key,
  },
  {
    label: FORM_FIELDS_BATTERIES_CONTAINED_OR_PACKED[LITHIUM_BATTERY_CONTAINED_KEY].classDivision.label,
    value: FORM_FIELDS_BATTERIES_CONTAINED_OR_PACKED[LITHIUM_BATTERY_CONTAINED_KEY].classDivision.key,
  },
  {
    label: FORM_FIELDS_BATTERIES_CONTAINED_OR_PACKED[LITHIUM_BATTERY_CONTAINED_KEY].packingInstructions.label,
    value: FORM_FIELDS_BATTERIES_CONTAINED_OR_PACKED[LITHIUM_BATTERY_CONTAINED_KEY].packingInstructions.key,
  },
  {
    label: 'Units',
    value: FORM_FIELDS_BATTERIES_CONTAINED_OR_PACKED[LITHIUM_BATTERY_CONTAINED_KEY].units.key,
  },
  {
    label: FORM_FIELDS_BATTERIES_CONTAINED_OR_PACKED[LITHIUM_BATTERY_CONTAINED_KEY].weight.label,
    values: [
      FORM_FIELDS_BATTERIES_CONTAINED_OR_PACKED[LITHIUM_BATTERY_CONTAINED_KEY].weight.key,
    ],
    seperator: ' ',
    postFix: FORM_FIELDS_BATTERIES_CONTAINED_OR_PACKED[LITHIUM_BATTERY_CONTAINED_KEY].weightUnit.key,
    toUpper: true,
  },
  {
    label: 'Transportation Mode',
    value: TRANSPORTATION_OPTIONS_NAME_KEY,
    format: (value) => (value ? TRANSPORTATION_OPTIONS_TABLE[value] : ''),
  },
];

export const LB = 'LB';

export const KG = 'KG';

export const L = 'L';

export const OZ = 'oz';

export const GRAM = 'gm';

export const ML = 'ml';

export const METRIC = 'metric';
export const IMPERIAL = 'imperial';
export const LITERS = 'liters';

export const KG_FREIGHT_LIMIT = 6803;
export const LB_FREIGHT_LIMIT = 15000;
export const L_FREIGHT_LIMIT = 15000;

export const DEFAULT_KG_LIMIT = 68;
export const DEFAULT_LB_LIMIT = 150;
export const DEFAULT_L_LIMIT = 68;

const OZ_RANGE_LIMIT = 16;
const GM_RANGE_LIMIT = 1000;
const ML_RANGE_LIMIT = 1000;

export const SMALL_WEIGHT_LIMIT_MAPPING = {
  [METRIC]: GM_RANGE_LIMIT,
  [IMPERIAL]: OZ_RANGE_LIMIT,
  [LITERS]: ML_RANGE_LIMIT,
};

export const WEIGHT_SYSTEM_MAPPING = {
  [KG]: METRIC,
  [GRAM]: METRIC,
  [LB]: IMPERIAL,
  [OZ]: IMPERIAL,
  [L]: LITERS,
  [ML]: LITERS,
};

export const ITEM_ADD = 'add';
export const ITEM_UPDATE = 'update';

export const CHEMICAL_INNER_PACKAGING_OPTIONS = [{
  label: 'Glass',
  value: 'glass',
}, {
  label: 'Plastic',
  value: 'plastic',
}, {
  label: 'Metal',
  value: 'metal',
}, {
  label: 'Fibre',
  value: 'fibre',
}, {
  label: 'Paper Bag',
  value: 'paper bag',
}, {
  label: 'Plastic Bag',
  value: 'plastic bag',
}];

export const SHIPMENT_QUANTITIES_OPTIONS = [
  {
    label: 'Excepted',
    value: SHIPMENT_QUANTITY_EXCEPTED_VALUE,
  },
  {
    label: 'Limited',
    value: SHIPMENT_QUANTITY_LIMITED_VALUE,
  },
  {
    label: 'Bulk',
    value: SHIPMENT_QUANTITY_BULK_VALUE,
  },
];

export const TRANSPORTATION_OPTIONS = [
  {
    label: 'Passenger or Cargo air carrier',
    value: TRANSPORTATION_OPTION_PASSENGER_CARGO_VALUE,
  },
  {
    label: 'Cargo air only',
    value: TRANSPORTATION_OPTION_CARGO_VALUE,
  },
  {
    label: 'Ground only',
    value: TRANSPORTATION_OPTION_GROUND_VALUE,
  },
];
export const BIOLOGICAL_TRANSPORTATION_OPTIONS = [
  {
    label: 'Passenger or Cargo air carrier',
    value: TRANSPORTATION_OPTION_PASSENGER_CARGO_VALUE,
  },
  {
    label: 'Cargo air only',
    value: TRANSPORTATION_OPTION_CARGO_VALUE,
  },
];
export const TRANSPORTATION_OPTIONS_BATTERY = [
  {
    label: 'Cargo air',
    value: TRANSPORTATION_OPTION_CARGO_VALUE,
  },
  {
    label: 'Ground',
    value: TRANSPORTATION_OPTION_GROUND_VALUE,
  },
];

export const TRANSPORTATION_OPTIONS_BATTERY_CONTAINED_OR_PACKED = [
  {
    label: 'Passenger air',
    value: TRANSPORTATION_OPTION_PASSENGER_CARGO_VALUE,
  },
  {
    label: 'Cargo air',
    value: TRANSPORTATION_OPTION_CARGO_VALUE,
  },
  {
    label: 'Ground',
    value: TRANSPORTATION_OPTION_GROUND_VALUE,
  },
];

export const TRANSPORTATION_OPTIONS_TABLE = {
  [SHIPMENT_QUANTITY_EXCEPTED_VALUE]: 'PAC',
  [SHIPMENT_QUANTITY_LIMITED_VALUE]: 'PAC',
  [TRANSPORTATION_OPTION_PASSENGER_CARGO_VALUE]: 'PAC',
  [TRANSPORTATION_OPTION_CARGO_VALUE]: 'Cargo',
  [TRANSPORTATION_OPTION_GROUND_VALUE]: 'Ground',
};

export const CHEMICAL_MEASUREMENT_UNIT_OPTIONS = [
  {
    label: L,
    weightCode: L,
  },
  {
    label: KG,
    weightCode: KG,
  },
];

export const CHEMICAL_SMALL_MEASUREMENT_UNIT_OPTIONS = [
  {
    unit: L,
    label: ML,
    weightCode: ML,
  },
  {
    unit: KG,
    label: GRAM,
    weightCode: GRAM,
  },
];

export const BATTERY_MEASUREMENT_UNIT_OPTIONS = [
  {
    label: KG,
    weightCode: KG,
  },
  {
    label: LB,
    weightCode: LB,
  },
];

export const BATTERY_SMALL_MEASUREMENT_UNIT_OPTIONS = [
  {
    unit: KG,
    label: GRAM,
    weightCode: GRAM,
  },
  {
    unit: LB,
    label: OZ,
    weightCode: OZ,
  },
];

export const BATTERY_CONTAINED_OR_PACKED_MEASUREMENT_UNIT_OPTIONS = [
  {
    label: KG,
    weightCode: KG,
  },
];
