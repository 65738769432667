import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import has from 'lodash/has';
import * as Yup from 'yup';
import { CircularProgress, Grid, Typography } from '@material-ui/core';
import * as PostalCodes from 'postal-codes-js';
import parsePhoneNumber from 'libphonenumber-js';
import AddressForm from '../common/AddressForm';
import NewOrderNextButton from '../common/NewOrderNextButton';
import { CustomCheckbox } from '../common/InputComponents';
import { useSingleOrderState } from '../../context/singleOrderContext';
import * as colors from '../../styles/colors';
import { EURO_COUNTRIES } from '../../clientConstants';

/**
 * ##################################
 *          GLOBAL VARIABLES
 * ##################################
 */

const getInitialFormValues = (isDomestic, isRecipient) => ({
  name: '',
  companyName: '',
  email: '',
  phone: '',
  country: isDomestic ? 'US' : '',
  addressLine1: '',
  addressLine2: '',
  addressLine3: '',
  city: '',
  state: '',
  zip: '',
  updateContacts: false,
  residentialDelivery: false,
  phoneCountry: isDomestic ? 'US' : '',
  ...(isRecipient && { eoriNumber: '' }),
});

/**
 * ##################################
 *          CUSTOM COMPONENT
 * ##################################
 */

function CustomAddressBlock(props) {
  const { classes, disabled, ...other } = props;
  const { inputLabelHeader, country, isRecipient } = other;

  if (disabled) {
    other.disabled = ['name', 'companyName', 'email', 'phone',
      'country', 'addressLine1', 'addressLine2', 'addressLine3', 'city', 'state', 'zip'];
  }

  return (
    <>
      <Grid
        item
        container
        direction="column"
        classes={{
          root: classes.addressHeader,
          item: classes.addressHeaderItem,
        }}
      >
        <Grid item>
          <Typography classes={{ root: classes.addressHeaderTitle }}>
            {`${inputLabelHeader} Information`}
          </Typography>
        </Grid>
        <Grid item>
          <Typography classes={{ root: classes.addressHelpText }}>
            All fields required unless indicated.
          </Typography>
        </Grid>
      </Grid>
      <AddressForm
        fields={['name']}
        other={{ ...other, classes }}
      />
      <AddressForm
        fields={['companyName', 'email', 'phone']}
        other={{ ...other, classes }}
      />
      <Grid item container direction="column" spacing={1}>
        <Grid item>
          <Typography className={classes.addressSectionHeader}>{`${inputLabelHeader} Address`}</Typography>
        </Grid>
        <Grid item>
          <AddressForm
            fields={['country', 'addressLine1']}
            other={{ ...other, classes }}
          />
        </Grid>
      </Grid>
      <AddressForm
        fields={['addressLine2', 'addressLine3', 'city']}
        other={{ ...other, classes }}
      />
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <AddressForm
            fields={['state']}
            other={{ ...other, classes }}
          />
        </Grid>
        <Grid item xs={6}>
          <AddressForm
            fields={['zip']}
            other={{ ...other, classes }}
          />
        </Grid>
        <Grid item xs={6}>
          {isRecipient
        && EURO_COUNTRIES.includes(country) && (
        <AddressForm
          fields={['eoriNumber']}
          other={{ ...other, classes }}
        />
          )}
        </Grid>
      </Grid>
    </>
  );
}

CustomAddressBlock.propTypes = {
  disabled: PropTypes.bool.isRequired,
  classes: PropTypes.shape({
    addressHeader: PropTypes.string.isRequired,
    addressHeaderItem: PropTypes.string.isRequired,
    addressHeaderTitle: PropTypes.string.isRequired,
    addressSectionHeader: PropTypes.string.isRequired,
    addressHelpText: PropTypes.string.isRequired,
  }).isRequired,
};

/**
 * ######################
 *          UTIL
 * ######################
 */

const isDisabled = (isSubmitting, hideSetSenderBtn, values, contact, moduleName) => {
  if (moduleName === 'sender') {
    return (isSubmitting
      || (hideSetSenderBtn && contact
        && compareValues(values, {
          ...contact.address,
          updateContacts: contact.updateContacts,
        })));
  }

  // if in the process of submitting form, disable the button
  if (moduleName === 'recipient') return isSubmitting;

  return false;
};

const getSaveContactsText = (values) => ((values.addressId) ? 'Update Contact Address' : 'Save to Address Book');

const getButtonText = (moduleName) => ((moduleName === 'sender') ? 'Set Sender' : 'Next');

const compareValues = (values, originValues) => {
  const changedValues = Object.keys(values).filter((key) => {
    if (typeof originValues[key] === 'undefined') return null;

    return values[key] !== originValues[key];
  });

  return (changedValues.length === 0);
};

/**
 * #################################
 *          EXPORT FUNCTION
 * #################################
 */

export default function NewOrderSenderRecipientAddress({
  selectedForms, pickupOptions, tabValue,
  contact, editAddress, classes,
  labelHeader, handleSubmit, account, isRecipient,
}) {
  const orderState = useSingleOrderState();
  const isDomestic = orderState?.order?.shippingType === 'domestic';
  const initialValues = getInitialFormValues(isDomestic, isRecipient);
  const inputLabelHeader = labelHeader || `${selectedForms.child.charAt(0).toUpperCase()}${selectedForms.child.slice(1)}`;
  const hideSetSenderBtn = (pickupOptions === tabValue);

  useEffect(() => {
    if (Object.keys(editAddress).length === 0) return;

    formikRef.current.resetForm({ ...initialValues, ...editAddress });
  }, [editAddress]);

  useEffect(() => {
    if (selectedForms.child === '' || selectedForms.child === 'recipient' || selectedForms.child == 'customsBroker') {
      if (has(contact, 'address.addressId')) {
        formikRef.current.resetForm(initialValues);
        return;
      }
    } else if (!hideSetSenderBtn && selectedForms.child !== 'customsBroker') {
      formikRef.current.resetForm(initialValues);
      return;
    }

    const { name } = formikRef.current.state.values; // May cause accidental resets.

    if (name === '' && contact) {
      formikRef.current.resetForm({
        ...initialValues,
        ...contact.address,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contact, pickupOptions]);

  /*          FORMIK PROPS            */

  const formikRef = useRef();
  const validateOnBlur = false;
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    ...(!account === 'worldemblem' && {
      email: Yup.string().email('Invalid email address').required('Required'),
    }),
    ...(account === 'worldemblem' && {
      email: Yup.string().email('Invalid email address'),
    }),
    phone: Yup.string()
      .test({
        name: 'is-valid-phone',
        message: 'Invalid phone number',
        test(value) {
          const parsedPhoneNumber = parsePhoneNumber(value || '');
          return parsedPhoneNumber && parsedPhoneNumber.isValid();
        },
      })
      .required('Required'),
    phoneCountry: Yup.string().required('Required'),
    addressLine1: Yup.string().required('Required'),
    city: Yup.string().required('Required'),
    state: Yup.string().required('Required'),
    zip: Yup.string()
      .test({
        name: 'is-valid-zip',
        message: 'Invalid postal code',
        test(value) {
          const { parent } = this;
          return PostalCodes.validate(parent.country, value) === true;
        },
      })
      .required('Required'),
    country: Yup.string().required('Required'),
    eoriNumber: Yup.string().test({
      name: 'is-required',
      message: 'Required',
      test(value) {
        const { parent } = this;
        // return !(isRecipient && EURO_COUNTRIES.includes(parent.country) && !value);
        return true;
      },
    }).test({
      name: 'is-valid',
      message: 'Invalid',
      test(value) {
        let match = true;
        const { parent } = this;
        if (isRecipient && EURO_COUNTRIES.includes(parent.country)) {
          match = /^[A-Za-z]{2}[a-zA-Z0-9]+$/.test(value);
        }
        return match;
      },
    }),
  });

  async function onSubmit(values, { setSubmitting }) {
    const valuesCopy = { ...values };

    const { updateContacts, addressId } = valuesCopy;
    if (!updateContacts && addressId) delete valuesCopy.addressId;
    if (selectedForms.child !== 'recipient') delete valuesCopy.residentialDelivery;

    await handleSubmit(valuesCopy);
    setSubmitting(false);
  }

  function render(renderProps) {
    const { values } = renderProps;
    const { isSubmitting, submitForm } = renderProps;
    const selectedFormChild = selectedForms.child;
    return (
      <Form>
        <Grid container direction="column">
          <Grid item container direction="column" style={{ background: colors.darkBlueBackground, padding: '0 20px', marginBottom: '10px' }}>
            <CustomAddressBlock
              formikRef={formikRef}
              inputLabelHeader={inputLabelHeader}
              overRideOptional={['phone']}
              classes={classes}
              country={values.country}
              disabled={isSubmitting}
              isRecipient={isRecipient}
            />
            <Grid item container direction="column">
              {(selectedForms.child === 'recipient')
                ? (
                  <Grid item>
                    <CustomCheckbox
                      name="residentialDelivery"
                      label="This is a residence"
                      classes={classes}
                      disabled={isSubmitting}
                    />
                  </Grid>
                )
                : null}
              <Grid item>
                <CustomCheckbox
                  name="updateContacts"
                  label={getSaveContactsText(values)}
                  classes={classes}
                  disabled={isSubmitting}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <NewOrderNextButton
              // disabled
              disabled={
                  isDisabled(isSubmitting, hideSetSenderBtn, values, contact, selectedFormChild)
                }
              onClick={submitForm}
            >
              {getButtonText(selectedFormChild)}
            </NewOrderNextButton>
          </Grid>
          {(isSubmitting)
            ? (
              <Grid item classes={{ root: classes.miscLoadingContainer }}>
                <CircularProgress color="secondary" />
              </Grid>
            )
            : null}
        </Grid>
      </Form>
    );
  }

  return (
    <Formik
      ref={formikRef}
      validateOnBlur={validateOnBlur}
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={render}
    />
  );
}

NewOrderSenderRecipientAddress.propTypes = {
  selectedForms: PropTypes.shape({
    child: PropTypes.string.isRequired,
  }).isRequired,
  tabValue: PropTypes.string,
  contact: PropTypes.shape({
    address: PropTypes.shape({
      addressId: PropTypes.string,
    }).isRequired,
  }),
  editAddress: PropTypes.objectOf(PropTypes.any),
  pickupOptions: PropTypes.string,
  labelHeader: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  isRecipient: PropTypes.bool,
  classes: PropTypes.objectOf(PropTypes.string.isRequired).isRequired,
};

NewOrderSenderRecipientAddress.defaultProps = {
  tabValue: null,
  pickupOptions: null,
  labelHeader: null,
  contact: null,
  editAddress: null,
  isRecipient: false,
};
