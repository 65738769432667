import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import {
  Grid, Popover, Typography, Button, Checkbox, InputLabel,
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import * as colors from '../styles/colors';
import statusHelp from '../utils/status';

const BASE_BUTTON_STYLES = {
  fontWeight: 'bold',
  padding: '6px 16px',
  borderRadius: '5px',
};

const ACCOUNT_TYPE_LARGE = 'large';
const ACCOUNT_TYPE_INDIVIDUAL = 'individual';
const ORDER_PURPOSE_KEY = 'purpose';
const FREIGHT_TYPE_KEY = 'freightType';

const muiCheckboxStyles = {
  root: {
    color: colors.white,
    '&$checked': {
      color: colors.secondaryButton,
    },
  },
  checked: {
    color: colors.secondaryButton,
  },
};

const checkboxStyles = makeStyles(() => ({
  labelText: { color: colors.white },
}));

const StyledCheckbox = withStyles(muiCheckboxStyles)(Checkbox);

const FilterCheckbox = (props) => {
  const classes = checkboxStyles();
  const {
    label, checked, setFilter, value,
  } = props;
  return (
    <InputLabel>
      <StyledCheckbox onClick={(e) => setFilter(value)} checked={checked} />
      <Typography className={classes.labelText} display="inline">
        {label}
      </Typography>
    </InputLabel>
  );
};

const useStyles = makeStyles(() => ({
  filterModal: {
    background: colors.mediumBlueBackground,
    width: 380,
    padding: '20px 25px',
    color: colors.white,
    borderRadius: '8px',
  },
  sectionHeader: {
    padding: '0 0 15px 0',
    fontSize: 16,
    fontWeight: '500',
  },
  modalHeaderSection: {
    fontWeight: '500',
    padding: '20px 0 12px 0',
    width: '100%',
  },
  modalHeader: {
    fontSize: 18,
  },
  filterModalContent: {
    background: colors.darkBlueBackground,
    padding: '10px 20px',
  },
  filterModalSection: {
    fontSize: 18,
    width: '100%',
    padding: '20px 0',
  },
  special: {
    color: colors.textLightGrey,
    fontWeight: 500,
  },
  header: {
    fontWeight: 500,
  },
  sectionStatus: {
    // minHeight: 130,
  },
  sectionDate: {
    // minHeight: 150,
  },
  sectionSubmit: {
    // minHeight: 50,
  },
  sectionType: {
  },
  submitButtonText: {
    textTransform: 'capitalize',
    color: colors.white,
  },
  inputLabelText: {
    color: colors.white,
  },
  paper: {
    background: colors.mediumBlueBackground,
    borderRadius: 8,
  },
  datePicker: {
    width: '50%',
    color: colors.white,
  },
  datePickerRoot: {
    color: colors.white,
    '& label': {
      color: colors.white,
    },
  },
  inputRootLeft: {
    color: colors.white,
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: colors.lightBackgroundGrey,
      borderRight: 'none',
      borderRadius: '5px 0 0 5px',
    },
    '&:hover, &:focus': {
      '& .MuiOutlinedInput-notchedOutline': {
        border: `2px solid ${colors.white}`,
        borderRadius: '5px 5px 5px 5px',
      },
    },
  },
  inputRootRight: {
    color: colors.white,
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: colors.lightBackgroundGrey,
      borderLeft: 'none',
      borderRadius: '0 5px 5px 0',
    },
    '&:hover': {
      '& .MuiOutlinedInput-notchedOutline': {
        border: `2px solid ${colors.white}`,
        borderRadius: '5px 5px 5px 5px',
      },
    },
  },
  inputAdornmentRoot: {
    '& $button': {
      color: colors.white,
      padding: 0,
    },
  },
  disableInputAdornment: {
    '& $button': {
      color: colors.white,
      padding: 0,
    },
    // display: 'none',
  },
  arrow: {
    color: colors.lightBackgroundGrey,
  },
  button: {
    ...BASE_BUTTON_STYLES,
    color: colors.white,
    whiteSpace: 'nowrap',
    '&:disabled': {
      color: colors.mediumBlueBackground,
    },
  },
  secondaryButton: {
    ...BASE_BUTTON_STYLES,
    border: `1px solid ${colors.buttonBackgroundBlue}`,
    color: colors.secondaryButton,
    marginLeft: '1rem',
  },
}));

export default function FilterModal({
  anchorEl,
  handleClose,
  filterState,
  setFilterState,
  submitFilters,
  accountType = ACCOUNT_TYPE_LARGE,
  orderPurposeOptions,
  freightTypeOptions,
  onFilterClear,
}) {
  const classes = useStyles();

  const { filterableStatuses } = statusHelp;

  const changeFilter = (cat) => (key) => {
    setFilterState((prev) => (
      {
        ...prev,
        [cat]: {
          ...prev[cat],
          [key]: !prev[cat][key],
        },
      }));
  };

  const changeSimpleFilter = (cat, value) => setFilterState((prev) => (
    {
      ...prev, [cat]: value,
    }
  ));

  const statusFilters = filterableStatuses.map((status) => ({
    label: status,
    value: status,
    category: 'status',
  }));

  return (
    <Popover
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      classes={{ paper: classes.paper }}
      data-testid="filter-shipments-modal"
    >
      <Grid container className={classes.filterModal} direction="column">
        <Grid item container className={`${classes.modalHeaderSection}`}>
          <Typography className={`${classes.modalHeader}`}>Filter Shipments</Typography>
        </Grid>
        <Grid item container className={classes.filterModalContent}>
          {orderPurposeOptions && orderPurposeOptions.length > 1 && (
            <Grid className={`${classes.filterModalSection} ${classes.sectionType}`} item container direction="column" justify="flex-start" wrap="nowrap">
              <Grid item>
                <Typography className={`${classes.sectionHeader}`}>Shipment Type</Typography>
              </Grid>
              <Grid item>
                <Grid item container alignItems="center" alignContent="flex-start" direction="row">
                  {!isEmpty(filterState.purpose) && orderPurposeOptions
                    .filter(() => (accountType === ACCOUNT_TYPE_LARGE
                      || (accountType === ACCOUNT_TYPE_INDIVIDUAL)
                    ))
                    .map((option) => {
                      const { description, type } = option;

                      return (
                        <Grid item xs={6} key={`filter-checkbox-grid-${type}-${ORDER_PURPOSE_KEY}`}>
                          <FilterCheckbox
                            label={description}
                            value={type}
                            setFilter={changeFilter(ORDER_PURPOSE_KEY)}
                            checked={filterState[ORDER_PURPOSE_KEY][type]}
                          />
                        </Grid>
                      );
                    })}
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid className={`${classes.filterModalSection} ${classes.sectionType}`} item container direction="column" justify="flex-start" wrap="nowrap">
            <Grid item>
              <Typography className={`${classes.sectionHeader}`}>Freight Type</Typography>
            </Grid>
            <Grid item container alignItems="center" alignContent="flex-start" direction="row">
              {!isEmpty(filterState.freightType) && freightTypeOptions.map((option) => {
                const { type, label } = option;

                return (
                  <Grid item xs={6} key={`freight-type-filter-grid-${type}`}>
                    <FilterCheckbox label={label} value={type} setFilter={changeFilter(FREIGHT_TYPE_KEY)} checked={filterState[FREIGHT_TYPE_KEY][type]} />
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
          <Grid className={`${classes.filterModalSection} ${classes.sectionType}`} item container direction="column" justify="flex-start" wrap="nowrap">
            <Grid item>
              <Typography className={`${classes.sectionHeader}`}>Status</Typography>
            </Grid>
            <Grid item>
              <Grid item container alignItems="center" alignContent="flex-start" direction="row">
                {statusFilters.map((status) => {
                  const { label, value, category } = status;
                  const formattedLabel = label.replace(/\s[^$]*$/, '...');
                  return (
                    <Grid item xs={6} key={`status-filter-grid-${label}-${value}`}>
                      <FilterCheckbox label={formattedLabel} value={value} setFilter={changeFilter(category)} checked={filterState[category][value]} />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
          <Grid className={`${classes.filterModalSection} ${classes.sectionDate}`} item>
            <Typography className={`${classes.sectionHeader}`}>Initiation Date </Typography>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid item container>
                <KeyboardDatePicker
                  autoOk
                  variant="inline"
                  inputVariant="outlined"
                  label="Start Date"
                  format="dd MMM yyyy"
                  maxDate={filterState.endDate && moment(filterState.endDate).toDate()}
                  value={filterState.startDate && moment(filterState.startDate).toDate()}
                  onChange={(d) => {
                    const formattedDate = moment(d).format('YYYY-MM-DD');
                    const dateToSet = (formattedDate !== 'Invalid date' && formattedDate) || null;
                    changeSimpleFilter('startDate', dateToSet);
                  }}
                  InputAdornmentProps={{
                    position: 'start',
                    classes: {
                      root: classes.inputAdornmentRoot,
                    },
                  }}
                  className={classes.datePicker}
                  classes={{
                    root: classes.datePickerRoot,
                  }}
                  InputProps={{
                    classes: {
                      root: classes.inputRootLeft,
                    },
                  }}
                  rightArrowButtonProps={{ classes: { root: classes.arrow } }}
                  leftArrowButtonProps={{ classes: { root: classes.arrow } }}
                />
                <KeyboardDatePicker
                  autoOk
                  variant="inline"
                  inputVariant="outlined"
                  label="End Date"
                  format="dd MMM yyyy"
                  value={filterState.endDate && moment(filterState.endDate).toDate()}
                  minDate={filterState.startDate && moment(filterState.startDate).toDate()}
                  onChange={(d) => {
                    const formattedDate = moment(d).format('YYYY-MM-DD');
                    const dateToSet = (formattedDate !== 'Invalid date' && formattedDate) || null;
                    changeSimpleFilter('endDate', dateToSet);
                  }}
                  InputAdornmentProps={{
                    position: 'start',
                    classes: {
                      root: classes.disableInputAdornment,
                    },
                  }}
                  className={classes.datePicker}
                  classes={{
                    root: classes.datePickerRoot,
                  }}
                  InputProps={{
                    classes: {
                      root: classes.inputRootRight,
                    },
                  }}
                />
              </Grid>

            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
        <Grid className={`${classes.filterModalSection} ${classes.sectionSubmit}`} container justify="center" alignContent="center">
          <Button className={classes.button} variant="contained" color="secondary" onClick={submitFilters}>
            Filter
          </Button>
          <Button
            className={classes.secondaryButton}
            onClick={() => {
              onFilterClear();
            }}
          >
            Clear
          </Button>
        </Grid>
      </Grid>
    </Popover>
  );
}

FilterModal.propTypes = {
  accountType: PropTypes.string,
  orderPurposeOptions: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string,
    description: PropTypes.string,
  })),
  freightTypeOptions: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string,
    label: PropTypes.string,
  })),
  onFilterClear: PropTypes.func.isRequired,
};

FilterModal.defaultProps = {
  accountType: ACCOUNT_TYPE_LARGE,
  orderPurposeOptions: [],
  freightTypeOptions: [],
};
