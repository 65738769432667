import React from 'react';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import * as Yup from 'yup';
import * as pdfjs from 'pdfjs-dist/build/pdf';
import { VerbosityLevel } from 'pdfjs-dist';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import * as colors from '../../styles/colors';
import NewOrderNextButton from './NewOrderNextButton';
import NewOrderNextButtonClear from './NewOrderNextButtonClear';
import ScrollWindow from './ScrollWindow';
import ErrorDialog from './NewOrderErrorDialog';
import { base64ToArrayBuffer } from '../../utils/documentsUtil';
import { useSingleOrderDispatch, signShippingDocument } from '../../context/singleOrderContext';
import { TaskAssignedToUser } from './TaskAssigned';
import ConfirmationModal from './ConfirmationModal';
import SignIcon from '../images/SignIcon';
import SignDocumentCanvas from './SignDocumentCanvas';

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const signDocumentStyles = {
  dialogContainer: {
    width: 800,
    height: '100vh',
    overflow: 'hidden',
    background: 'transparent',
    maxHeight: 'calc(100vh - 20px)',
  },
  contentContainer: {
    background: colors.newOrderFormBackground,
    width: '100%',
    padding: '30px 25px',
    borderRadius: 8,
    flexWrap: 'nowrap',
    overflow: 'hidden',
    minHeight: '100%',
  },
  closeIcon: {
    position: 'absolute',
    top: 10,
    right: 10,
    '&:hover': {
      background: 'inherit',
    },
  },
  header: {
    fontSize: 24,
    fontWeight: 700,
  },
  subHeader: {
    fontSize: 20,
    fontWeight: 700,
  },
  subHeaderContainer: {
    padding: '4px 0',
  },
  description: {
    fontSize: 16,
    fontWeight: 500,
  },
  divider: {
    // borderBottom: `1px solid ${colors.textDarkGrey}`,
    margin: '5px 0 5px 0',
  },
  radioLabel: {
    color: colors.white,
  },
  radio: {
    color: colors.white,
    padding: 0,
    marginRight: 8,
  },
  labelRoot: {
    marginLeft: 0,
  },
  uploadFileButton: {
    width: '100%',
    height: '50px',
    background: 'rgb(54,57,69)',
    boxShadow: '0 1px 5px -2px black',
  },
  uploadFileButtonLabel: {
    textTransform: 'none',
    color: colors.white,
    fontWeight: 500,
    paddingLeft: '5px',

  },
  uplaodFileButtonIcon: {
    color: 'rgba(255,255,255,0.6)',
    display: 'flex',
  },
  previewContainer: {
    background: colors.darkBlueBackground,
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  previewContainerActive: {
    background: 'transparent',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  submitButtonsContainer: {
    paddingTop: 0,
    justifyContent: 'center',
    height: 62,
  },
  canvas: {
    width: '100%',
  },
  thumbVertical: {
    background: 'rgba(0,0,0,0.5) !important',
  },
  inputSectionContainer: {
    height: '300px',
    overflow: 'hidden',
  },
};

const useSignDocumentStyles = makeStyles(signDocumentStyles);

function getInitialValues(values = {}) {
  return {
    ...values,
  };
}

export default function SignDocument(props) {
  const {
    signObject, open, onClose, shipmentId,
  } = props;

  const {
    documentType,
    documentName,
    packageId,
    documentId,
    document,
    signature,
    displayName,
    task,
  } = (signObject || {});

  const signatureRef = React.useRef(null);
  const canvasRef = React.useRef(null);
  const dispatch = useSingleOrderDispatch();
  const [error, setError] = React.useState(null);
  const [internalOpen, setInternalOpen] = React.useState(false);
  const [submitTask, setSubmitTask] = React.useState(null);
  const [signatureHasContent, setSignatureHasContent] = React.useState(false);

  React.useEffect(() => {
    if (signObject) {
      setInternalOpen(true);
    }
  }, [signObject]);

  const classes = useSignDocumentStyles();

  const handleSignatureEnd = (current) => {
    if (current) setSignatureHasContent(true);
  };

  async function onSubmit(_values, { setSubmitting }) {
    if (!signatureRef.current) return;

    signatureRef.current.off();
    const canvas = signatureRef.current.getCanvas();
    const context = canvas.getContext('2d');
    context.save();
    context.fillStyle = 'black';
    context.globalCompositeOperation = 'source-in';
    context.fillRect(0, 0, canvas.width, canvas.height);

    try {
      await signShippingDocument({
        dispatch,
        shipmentId,
        documentId,
        packageId,
        signature: signatureRef.current.getTrimmedCanvas().toDataURL('image/png'),
        documentType,
      });
      setInternalOpen(false);
    } catch (e) {
      setError(JSON.stringify({ error: e.message }));
      context.fillStyle = 'white';
      context.fillRect(0, 0, canvas.width, canvas.height);
    }

    context.restore();
    signatureRef.current.on();
    setSubmitting(false);
  }

  async function handlePreview(arrayBuffer) {
    const doc = await pdfjs.getDocument({ data: arrayBuffer, verbosity: VerbosityLevel.ERRORS }).promise;
    const page = await doc.getPage(1);
    const scale = 2;
    const viewport = page.getViewport({ scale });

    const canvas = canvasRef.current;
    if (!canvas) return;
    const context = canvas.getContext('2d');

    canvas.style.height = canvas.height;
    canvas.style.width = canvas.width;
    canvas.height = viewport.height;
    canvas.width = viewport.width;

    // Render PDF page into canvas context
    const renderContext = {
      canvasContext: context,
      viewport,
    };
    const renderTask = page.render(renderContext);
    renderTask.promise.then(() => {
      //
    });
  }

  React.useEffect(() => {
    if (open && signObject) {
      handlePreview(base64ToArrayBuffer(document));
    }
    setSignatureHasContent(false);
  }, [signObject, open]);

  React.useEffect(() => {
    if (signatureRef && signature) {
      if (typeof signature === 'string') {
        signatureRef.fromDataURL(signature);
      }
      if (Array.isArray(signature)) {
        signatureRef.fromData(signature);
      }
      setSignatureHasContent(true);
    }
  }, [signObject, signatureRef.current]);

  const validationSchema = Yup.object().shape({});

  return (
    <>

      <Dialog
        open={internalOpen}
        onClose={() => {
          setInternalOpen(false);
        }}
        onExited={() => {
          onClose();
        }}
        classes={{ paper: classes.dialogContainer }}
        disableBackdropClick
        disableEscapeKeyDown
      >
        <IconButton
          className={classes.closeIcon}
          onClick={() => {
            setInternalOpen(false);
          }}
          disableFocusRipple
          disableRipple
          disableTouchRipple
        >
          <CloseIcon color="primary" />
        </IconButton>
        <Formik
          initialValues={getInitialValues()}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {
            (formikProps) => (
              <ScrollWindow classes={{ thumbVertical: classes.thumbVertical }}>
                <Grid container className={classes.contentContainer} direction="column" spacing={2}>
                  <Grid item container direction="column">
                    <Grid item>
                      <Typography color="primary" className={classes.header}>
                        Signature
                      </Typography>
                    </Grid>
                    <Grid item className={classes.subHeaderContainer}>
                      <Typography color="primary" className={classes.subHeader}>
                        {displayName || documentName || ''}
                      </Typography>
                    </Grid>
                  </Grid>
                  {/* <Grid item container className={classes.divider} /> */}
                  {task
                    ? (
                      <Grid item container>
                        <TaskAssignedToUser
                          task={task}
                          preReqsComplete
                          TaskIcon={SignIcon}
                        >
                          <Grid item container style={{ paddingTop: 10 }}>
                            <SignDocumentCanvas
                              ref={signatureRef}
                              onSignatureEnd={handleSignatureEnd}
                            />
                          </Grid>
                        </TaskAssignedToUser>
                      </Grid>
                    )
                    : (
                      <SignDocumentCanvas
                        ref={signatureRef}
                        onSignatureEnd={handleSignatureEnd}
                      />
                    )}
                  <Grid
                    item
                    container
                    style={{ flexGrow: 1 }}
                    justify="center"
                    className={classes.inputSectionContainer}
                  >
                    <Grid
                      item
                      container
                      direction="column"
                      wrap="nowrap"
                      xs={8}
                    >
                      <Grid item container style={{ overflow: 'hidden', flexGrow: 1, height: '100%' }}>
                        <Grid item className={`${document ? classes.previewContainerActive : classes.previewContainer}`}>
                          <ScrollWindow>
                            <canvas id="preview-canvas" ref={canvasRef} className={classes.canvas} />
                          </ScrollWindow>
                        </Grid>
                      </Grid>

                    </Grid>
                  </Grid>

                  <Grid
                    item
                    container
                    className={classes.submitButtonsContainer}
                    spacing={2}
                  >
                    {formikProps.isSubmitting ? (
                      <Grid container item justify="center" alignItems="center" style={{ height: '100%' }}>
                        <CircularProgress
                          color="secondary"
                          size={(signDocumentStyles.submitButtonsContainer.height - (signDocumentStyles.submitButtonsContainer.paddingTop || 0)) / 1.414}
                        />
                      </Grid>
                    ) : (
                      <>
                        <Grid item>
                          <NewOrderNextButtonClear
                            disabled={formikProps.isSubmitting}
                            onClick={() => setInternalOpen(false)}
                          >
                            Cancel
                          </NewOrderNextButtonClear>
                        </Grid>
                        <Grid item>
                          <NewOrderNextButton
                            disabled={!signatureHasContent || formikProps.isSubmitting}
                            onClick={() => {
                              if (task) {
                                setSubmitTask(true);
                              } else {
                                formikProps.submitForm();
                              }
                            }}
                          >
                            Save
                          </NewOrderNextButton>
                        </Grid>
                        <ConfirmationModal
                          onProceed={() => {
                            setSubmitTask(null);
                            formikProps.submitForm();
                          }}
                          onCancel={() => setSubmitTask(null)}
                          message="Please confirm your submission. Once you submit, this action will be returned to the initiator and you will no longer be able to make changes."
                          open={!!submitTask}
                        />
                      </>
                    )}
                  </Grid>
                </Grid>
              </ScrollWindow>
            )
          }
        </Formik>
      </Dialog>
      <ErrorDialog
        onClose={() => setError(null)}
        open={!!error}
        errorContent={error}
      />
    </>
  );
}

SignDocument.propTypes = {
  signObject: PropTypes.objectOf(PropTypes.any).isRequired,
  open: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.object]),
  onClose: PropTypes.func,
  shipmentId: PropTypes.string.isRequired,
};

SignDocument.defaultProps = {
  open: false,
  onClose: null,
};
