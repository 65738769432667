/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { fieldToTextField } from 'formik-material-ui';
import Visibility from '@material-ui/icons/VisibilityOutlined';
import VisibilityOff from '@material-ui/icons/VisibilityOffOutlined';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import * as colors from '../styles/colors';

const styles = {
  visibilityIcon: {
    color: colors.textGrey,
  },
  cssLabel: {
    color: colors.textGrey,
    fontWeight: 500,
  },
  cssFocused: {
    color: colors.textGrey,
  },
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: colors.textGrey,
    },
    '&:hover $notchedOutline': {
      borderColor: colors.textGrey,
    },
    color: 'white',
    backgroundColor: 'rgba(255, 255, 255, 0.15)',
    borderRadius: 5,
    borderColor: colors.textGrey,
  },
  notchedOutline: {
  },
  disableChromeAutofill: {
    '-webkit-transition-delay': '9999s',
    ' -webkit-text-fill-color': 'white !important',
    ' -webkit-transition': 'background-color 9999s ease-out',
    color: 'white',
    background: 'transparent !important',
    borderColor: colors.textGrey,
  },
};

const useStyles = makeStyles(styles);

export default function PasswordField(props) {
  const classes = useStyles();
  const [showPassword, setShowPassword] = React.useState(false);

  function handleClickShowPassword() {
    return setShowPassword((val) => !val);
  }

  return (
    <TextField
      {...fieldToTextField(props)}
      id="filled-adornment-password"
      variant="outlined"
      type={showPassword ? 'text' : 'password'}
      label={props.label}
      InputLabelProps={{
        classes: {
          root: classes.cssLabel,
          focused: classes.cssFocused,
        },

      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              edge="end"
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              color="primary"
              classes={{ colorPrimary: classes.visibilityIcon }}
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
        classes: {
          root: classes.cssOutlinedInput,
          focused: classes.cssFocused,
          notchedOutline: classes.notchedOutline,
          hover: `${classes.disableChromeAutofill}`,
          input: `${classes.disableChromeAutofill}`,
        },
      }}
    />
  );
}
