import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'emotion';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { CircularProgress } from '@material-ui/core';
import * as miscClient from '../utils/miscClient';
import Logo from '../assets/verizeal-logo';
import * as colors from '../styles/colors';

const StyledDialog = withStyles({
  paper: {
    backgroundColor: '#3B3F53',
    minWidth: 484,
    minHeight: 243,
  },
})(Dialog);

export default function AboutDialog(props) {
  const {
    open, handleClose,
  } = props;

  const [versionAndBuild, setVersionAndBuild] = React.useState({ build: null, version: null });

  React.useEffect(() => {
    if (open) {
      miscClient.loadAboutData().then((fetchedData) => {
        setVersionAndBuild({ build: fetchedData.build, version: fetchedData.version });
      });
    }
  }, [open]);

  const classes = {
    logo: css`
      width: 360px;
      padding-top: 20px;
      padding-bottom: 20px;
    `,
    text: css`
      color: #FFFFFF;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0.5px;
      line-height: 28px;
      text-align: center;
    `,
  };

  return (
    <StyledDialog open={open} onClose={handleClose} aria-labelledby="about-dialog-title">
      <Grid container justify="flex-end">
        <IconButton
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </Grid>
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
      >
        <Grid item className={classes.logo}>
          <Logo />
        </Grid>
        { (versionAndBuild.version || versionAndBuild.build)
          ? (
            <>
              <Grid item>
                <Typography className={classes.text}>
                  {
                  versionAndBuild.version
                    ? `Version ${versionAndBuild.version || ''}`
                    : ''
                  }
                </Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.text}>
                  {
                  versionAndBuild.build
                    ? `Build ${versionAndBuild.build || ''}`
                    : ''
                  }
                </Typography>
              </Grid>
            </>
          )
          : (
            <Grid item>
              <CircularProgress />
            </Grid>
          )}
      </Grid>
    </StyledDialog>
  );
}

AboutDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
