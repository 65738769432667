import React, { memo, useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button, InputAdornment, TextField, Typography,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Laptop from '@material-ui/icons/Laptop';
import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';
import Navbar from '../components/Navbar';
import { Page } from '../components/common';
import * as colors from '../styles/colors';
import MaterialsTable from '../components/MaterialsTable';
import MaterialModal from '../components/MaterialModal';
import { DEFAULT_FETCH_OPTIONS } from '../clientConstants';
import { deleteMaterial, loadAllMaterials } from '../utils/materialsClient';
import { useMaterialsState } from '../context/materialsContext';
import { loadCountries, useMiscDispatch, useMiscState } from '../context/miscDataContext';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: colors.background,
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  page: {
    padding: '99px 20px 20px 20px',
    height: 'calc(100vh - 64px)',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
  },
  headerLeftContainer: {
    display: 'flex',
    width: 'auto',
    alignItems: 'center',
  },
  headerLeftContainerText: {
    width: 'auto',
    alignItems: 'center',
  },
  headerText: {
    color: colors.white,
    fontSize: 24,
    fontWeight: 500,
    marginLeft: '10px',
  },
  textField: {
    width: 370,
    background: colors.darkInputFieldBackground,
    marginLeft: '1.5rem',
  },
  labelRoot: {
    color: colors.white,
    fontSize: 16,
    fontWeight: 500,
    background: colors.darkInputFieldBackground,
  },
  inputRoot: {
    background: colors.darkInputFieldBackground,
  },
  endAdornmentRoot: {
    margin: 0,
    '&:not(.MuiInputAdornment-hiddenLabel)': {
      margin: '0px !important',
    },
  },
  addButton: {
    width: 200,
    background: colors.newContactButtonBackground,
    height: 40,
    borderRadius: 10,
    padding: '0 20px',
  },
  addButtonText: {
    fontWeight: 700,
    color: colors.white,
    fontSize: 14,
    letterSpacing: 1.2,
  },
  tableContainer: {
    flexGrow: 2,
    padding: '20px 0',
    overflow: 'hidden',
    position: 'relative',
  },
}));

const MaterialDashboard = () => {
  const classes = useStyles();
  const [filter, setFilter] = useState('');
  const {
    productCategories,
    dimensionUnits,
    weightUnits,
    smallWeightUnits,
    currencyUnits,
  } = useMaterialsState();
  const miscDispatch = useMiscDispatch();
  const { countries } = useMiscState();
  const [materialsState, setMaterialState] = useState({
    items: [],
    params: {
      ...DEFAULT_FETCH_OPTIONS,
      filter,
    },
    loading: true,
  });
  const [modalState, setModalState] = useState({
    show: false,
    material: null,
  });

  const handleSearch = (e) => {
    setFilter(e.target.value);
  };

  const handleModal = (material) => {
    setModalState({ show: !modalState.show, material });
  };

  const handleMaterialSubmit = (material, isNew) => {
    if (isNew) {
      setMaterialState(
        {
          ...materialsState,
          items: materialsState.items.concat(material),
        },
      );
    } else {
      setMaterialState(
        {
          ...materialsState,
          items: materialsState.items.map((item) => (item.itemId === material.itemId
            ? { ...item, ...material }
            : item
          )),
        },
      );
    }
  };

  const handleDeleteMaterial = (id) => {
    deleteMaterial(id).then(() => {
      setMaterialState(
        {
          ...materialsState,
          items: materialsState.items.map((item) => (item.itemId === id
            ? { ...item, isActive: false }
            : item
          )),
        },
      );
    }).catch((err) => {
      console.error('There was problem while deleting the material', err);
    });
  };

  const fetchMaterials = (options = DEFAULT_FETCH_OPTIONS) => {
    loadAllMaterials(options).then((data) => {
      if (!data) return;

      setMaterialState({
        items: options.offset === 0 ? data : materialsState.items.concat(data),
        params: {
          ...materialsState.params,
          ...options,
          offset: options.offset,
        },
        loading: false,
      });
    }).catch(() => {
      console.log('There is a problem loading materials');

      setMaterialState({
        items: materialsState.items,
        loading: false,
      });
    });
  };

  useEffect(() => {
    if (isEmpty(countries)) loadCountries(miscDispatch);
  }, []);

  useEffect(() => {
    if (materialsState.items.length > 0 && filter.length !== 0 && filter.length >= 2) {
      fetchMaterials({ ...DEFAULT_FETCH_OPTIONS, offset: 0, filter });
    } else fetchMaterials({ ...DEFAULT_FETCH_OPTIONS, offset: 0 });
  }, [filter]);

  return (
    <div className={classes.root}>
      <Navbar />
      <Page className={classes.page}>
        <Grid className={classes.headerContainer} container alignItems="center" justify="space-between">
          <Grid className={classes.headerLeftContainer} item>
            <Grid className={classes.headerLeftContainerText} container item>
              <Laptop color="primary" height="2em" />
              <Typography className={classes.headerText}>Materials</Typography>
            </Grid>
            <Grid item>
              <TextField
                id="outlined-basic"
                className={classes.textField}
                classes={{ root: classes.textFieldRoot }}
                label="Search Materials"
                margin="normal"
                onChange={handleSearch}
                InputProps={{
                  disableUnderline: true,
                  classes: {
                    root: classes.inputRoot,
                    focused: classes.inputRoot,
                  },
                  endAdornment: (
                    <InputAdornment
                      position="start"
                      classes={{
                        root: classes.endAdornmentRoot,
                        filled: classes.endAdornmentRoot,
                        positionStart: classes.endAdornmentRoot,
                      }}
                    >
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{ classes: { root: classes.labelRoot } }}
                variant="filled"
              />
            </Grid>
          </Grid>
          <Grid item className={classes.headerRightContainer}>
            <Button className={classes.addButton} onClick={() => handleModal(null)}>
              <Grid container justify="space-around" alignItems="center">
                <AddIcon color="primary" />
                <Typography className={classes.addButtonText} display="inline">
                  New Material
                </Typography>
              </Grid>
            </Button>
          </Grid>
        </Grid>
        <Grid className={classes.tableContainer} container item>
          <MaterialsTable
            materials={materialsState.items}
            params={materialsState.params}
            isLoading={materialsState.loading}
            onFetchMaterials={fetchMaterials}
            onDeleteMaterial={handleDeleteMaterial}
            onShowModal={handleModal}
          />
        </Grid>
      </Page>
      <MaterialModal
        open={modalState.show}
        material={modalState.material}
        categories={productCategories}
        dimensionUnits={dimensionUnits}
        weightUnits={weightUnits}
        smallWeightUnits={smallWeightUnits}
        currencyUnits={currencyUnits}
        countries={countries}
        onSubmit={handleMaterialSubmit}
        onClose={handleModal}
      />
    </div>
  );
};

export default memo(MaterialDashboard);
