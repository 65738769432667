import React, {
  memo, useRef,
} from 'react';
import PropTypes from 'prop-types';
import has from 'lodash/has';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button, CircularProgress, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Check from '@material-ui/icons/Check';
import Close from '@material-ui/icons/Close';
import * as colors from '../styles/colors';
import ScrollWindow from './common/ScrollWindow';
import { FORM_FIELDS_HAZMAT } from './ItemProductDetails/itemDetailsConstants';
import { DEFAULT_FETCH_OPTIONS } from '../clientConstants';

const ACTIONS_KEY = 'actions';

const BASE_TABLE_CELL_STYLES = {
  display: 'flex',
  flexGrow: 0,
  alignItems: 'center',
};

const COLUMNS = [
  {
    key: '',
    width: '5%',
    render: ({ isActive }) => (isActive ? <Check htmlColor={colors.limeGreem} /> : <Close color="error" />),
  },
  {
    key: FORM_FIELDS_HAZMAT.itemName.key,
    header: FORM_FIELDS_HAZMAT.itemName.label,
    width: '30%',
  },
  {
    key: FORM_FIELDS_HAZMAT.itemCategory.key,
    header: FORM_FIELDS_HAZMAT.itemCategory.label,
    width: '20%',
  },
  {
    key: FORM_FIELDS_HAZMAT.shipClass.key,
    header: FORM_FIELDS_HAZMAT.shipClass.label,
    width: '10%',
  },
  {
    key: FORM_FIELDS_HAZMAT.harmonizedCode.key,
    header: FORM_FIELDS_HAZMAT.harmonizedCode.label,
    width: '15%',
  },
  {
    key: FORM_FIELDS_HAZMAT.eccnNo.key,
    header: FORM_FIELDS_HAZMAT.eccnNo.label,
    width: '10%',
  },
  {
    key: ACTIONS_KEY,
    header: '',
    width: '10%',
  },
];

const useStyles = makeStyles(() => ({
  table: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 'auto',
    flexGrow: '2',
    background: colors.tableBackground,
    overflow: 'hidden',
  },
  tableHead: {
    width: '100%',
    background: colors.tableBackground,
    padding: '0 20px',
  },
  tableBody: {
    position: 'relative',
    height: '100%',
  },
  tableScrollView: {
    padding: '0 20px',
  },
  row: {
    display: 'flex',
    justifyContent: 'center',
  },
  cell: {
    flexGrow: '1',
    border: 'none',
    borderBottom: `1px solid ${colors.tableGreyBorder}`,
  },
  cellText: {
    color: colors.tableGreyText,
    fontSize: 12,
  },
  tableHeaderCellText: {
    textTransform: 'uppercase',
    letterSpacing: 1.3,
    fontSize: 12,
    fontWeight: 500,
  },
  tableHeaderCell: {
    padding: '20px 0 20px 10px',
  },
  tableBodyCellText: {
    fontSize: 14,
  },
  tableBodyCell: {
    padding: '20px 0 20px 10px',
    border: 'none',
  },
  tooltip: {
    background: colors.tooltipBackground,
    fontSize: 12,
  },
  tableActionButton: {
    borderRadius: '8px',
    boxShadow: 'none',
    in: 'auto',
    color: `${colors.tableGreyText}`,
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
  },
  tableActionIcon: {
    width: '18px',
    height: '18px',
  },
}));

const BiologicalTable = ({
  materials, params, isLoading, onFetchMaterials, onDeleteMaterial, onShowModal,
}) => {
  const classes = useStyles();
  const lastMaterialRef = useRef(null);

  const isTableScrolledToBottom = (table, cardRef) => {
    const tableHeight = table
      ? table.clientHeight + 1
      : 0;
    const tableScrollTop = table
      ? table.scrollTop
      : 0;
    const tableScroll = Math.round(tableScrollTop + tableHeight);
    const lastContactOffset = (cardRef && cardRef.current)
      ? cardRef.current.offsetTop + cardRef.current.clientHeight
      : Infinity;

    return tableScroll >= lastContactOffset;
  };

  const onTableScroll = (e) => {
    const shouldLoadMore = isTableScrolledToBottom(e.target, lastMaterialRef);

    if (shouldLoadMore && !isLoading) {
      lastMaterialRef.current = null;
      onFetchMaterials({ ...params, offset: params.offset + DEFAULT_FETCH_OPTIONS.limit });
    }
  };

  return (
    <Table className={classes.table}>
      <TableHead
        className={classes.tableHead}
      >
        <TableRow className={classes.row}>
          {COLUMNS.map((col) => (
            <TableCell
              className={`${classes.cell} ${classes.tableHeaderCell}`}
              align="left"
              key={col.key}
              style={{
                ...BASE_TABLE_CELL_STYLES,
                width: col.width,
              }}
            >
              <Typography className={`${classes.cellText} ${classes.tableHeaderCellText}`}>
                {col.header}
              </Typography>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody
        className={classes.tableBody}
      >
        <ScrollWindow
          classes={{
            view: classes.tableScrollView,
          }}
          onScroll={onTableScroll}
        >
          {materials.map((material, idx, arr) => (
            <TableRow
              className={classes.row}
              key={material.itemId}
              data-testid="user-row"
              ref={idx >= arr.length - 1 ? lastMaterialRef : undefined}
            >
              {COLUMNS.map((col) => (
                <TableCell
                  className={`${classes.cell} ${classes.tableBodyCell}`}
                  style={{ ...BASE_TABLE_CELL_STYLES, width: col.width }}
                  key={`${material.itemId} - ${col.key}`}
                >
                  {col.key === ACTIONS_KEY
                    ? (
                      <>
                        <Tooltip title="Edit" classes={{ tooltip: classes.tooltip }}>
                          <Button
                            className={classes.tableActionButton}
                            onClick={() => onShowModal(material)}
                          >
                            <EditIcon className={classes.tableActionIcon} />
                          </Button>
                        </Tooltip>
                        <Tooltip title="Delete" classes={{ tooltip: classes.tooltip }}>
                          <Button
                            className={classes.tableActionButton}
                            disabled={!material.isActive}
                            onClick={() => onDeleteMaterial(material.itemId)}
                          >
                            <DeleteIcon className={classes.tableActionIcon} />
                          </Button>
                        </Tooltip>
                      </>
                    ) : (
                      <Typography
                        className={`${classes.cellText} ${classes.tableBodyCellText}`}
                      >
                        {has(col, 'render') ? col.render(material) : material[col.key]}
                      </Typography>
                    ) }
                </TableCell>
              ))}
            </TableRow>
          ))}
          {isLoading && (
          <TableRow
            className={classes.row}
          >
            <TableCell
              className={classes.tableBodyCellActionButton}
              style={{ border: 'none' }}
            >
              <CircularProgress color="secondary" className={classes.progress} />
            </TableCell>
          </TableRow>
          )}
        </ScrollWindow>
      </TableBody>
    </Table>
  );
};

BiologicalTable.defaultProps = {
  materials: [],
};

BiologicalTable.propTypes = {
  materials: PropTypes.arrayOf(PropTypes.object),
  params: PropTypes.shape({
    limit: PropTypes.number.isRequired,
    offset: PropTypes.number.isRequired,
    filter: PropTypes.string.isRequired,
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  onFetchMaterials: PropTypes.func.isRequired,
  onDeleteMaterial: PropTypes.func.isRequired,
  onShowModal: PropTypes.func.isRequired,
};

export default memo(BiologicalTable);
