import React from 'react';
import { Grid } from '@material-ui/core';
import ComplianceCheckForm from './ComplianceCheckForm';
import NewOrderShipmentModule from './common/NewOrderShipmentModule';
import BlockedPanelDisplay from './common/PanelWarning';
import {
  hasExportCompliance,
} from '../utils/helpers';

const ComplianceCheckPanel = ({
  shipment,
  isComplete,
  isSelected,
  isReadyToBeCompleted,
  isComplianceFailure,
  blockingMessage,
  ...shipmentProps
}) => {
  const isCompliance = hasExportCompliance(shipment);
  const exportCompliance = shipment?.exportCompliance;
  const exportComplianceSuccess = !!(exportCompliance?.details?.success);
  const ecoApproved = exportCompliance?.details?.isECOApproved
    && exportCompliance?.details?.ecoComments;
  const ehsApproved = exportCompliance?.details?.isEHSApproved
    && exportCompliance?.details?.ehsComments;

  // eslint-disable-next-line no-nested-ternary
  let labelDetails = !isSelected && (isCompliance ? (!exportComplianceSuccess ? 'Failure' : 'Passed') : '');
  labelDetails += ehsApproved || ecoApproved
    // eslint-disable-next-line no-nested-ternary
    ? ` - ${ecoApproved ? 'ECO' : ''} ${ehsApproved ? ecoApproved ? ',EHS' : 'EHS' : ''} Approved`
    : '';
  return (
    <NewOrderShipmentModule
      labelDetails={labelDetails}
      isComplete={isComplete}
      isSelected={isSelected}
      isReadyToBeCompleted={isReadyToBeCompleted}
      isComplianceFailure={isComplianceFailure}
      shipment={shipment}
      blockingMessage={blockingMessage}
      {...shipmentProps}
    >
      {(isReadyToBeCompleted) ? ((renderProps) => (
        <Grid direction="column">
          <ComplianceCheckForm
            shipment={shipment}
            isComplete={isComplete}
            isReadyToBeCompleted={isReadyToBeCompleted}
            isSelected={isSelected}
            isComplianceFailure={isComplianceFailure}
            {...shipmentProps}
            {...renderProps}
            isFailureWithNoTask={renderProps?.isFailureWithNoTask}
          />
        </Grid>
      )) : (
        <BlockedPanelDisplay message={blockingMessage} />
      )}
    </NewOrderShipmentModule>
  );
};

ComplianceCheckPanel.propTypes = {

};
export default ComplianceCheckPanel;
