import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { Field } from 'formik';
import { Grid, MenuItem, Typography } from '@material-ui/core';
import NewOrderCustomTextField from '../../common/NewOrderCustomTextField';
import CustomSelectTextField from '../../common/CustomSelectTextField';
import { FORM_LABELS, FORM_FIELDS } from '../itemDetailsConstants';
import { useItemProductDetailsFormStyles } from '../itemDetailsStyles';
import { isFieldEditable, blockWeightAndDimensionInputs } from '../itemDetailsUtil';

export default function ProductDimensions(props) {
  const { editableFields, formikProps, dimensionUnits } = props;
  const dimensionsUnit = get(formikProps.values, FORM_FIELDS.dimensionsUnit.key);
  const classes = useItemProductDetailsFormStyles();
  return (
    <Grid item container direction="column" spacing={1}>
      <Grid item>
        <Typography color="primary" className={classes.formFieldHeader}>
          {FORM_LABELS.productDimensions}
        </Typography>
      </Grid>
      <Grid item container spacing={1}>
        <Grid item xs={2}>
          <Field
            name={FORM_FIELDS.dimensionsUnit.key}
            label={dimensionsUnit ? '' : FORM_FIELDS.dimensionsUnit.label}
            component={CustomSelectTextField}
            type="text"
            style={{ width: '100%' }}
            disabled={isFieldEditable(FORM_FIELDS.dimensionsUnit.key, editableFields)}
          >
            {dimensionUnits.map((option) => (
              <MenuItem
                key={option.dimensionCode}
                value={option.dimensionCode}
              >
                {option.dimensionCode}
              </MenuItem>
            ))}
          </Field>
        </Grid>
        <Grid item xs={3}>
          <Field
            name={FORM_FIELDS.dimensionsLength.key}
            label={FORM_FIELDS.dimensionsLength.label}
            component={NewOrderCustomTextField}
            blockValue={blockWeightAndDimensionInputs}
            type="text"
            style={{ width: '100%' }}
            disabled={isFieldEditable(FORM_FIELDS.dimensionsLength.key, editableFields)}
          />
        </Grid>
        <Grid item xs={3}>
          <Field
            name={FORM_FIELDS.dimensionsWidth.key}
            label={FORM_FIELDS.dimensionsWidth.label}
            component={NewOrderCustomTextField}
            blockValue={blockWeightAndDimensionInputs}
            type="text"
            style={{ width: '100%' }}
            disabled={isFieldEditable(FORM_FIELDS.dimensionsWidth.key, editableFields)}
          />
        </Grid>
        <Grid item xs={3}>
          <Field
            name={FORM_FIELDS.dimensionsHeight.key}
            label={FORM_FIELDS.dimensionsHeight.label}
            component={NewOrderCustomTextField}
            blockValue={blockWeightAndDimensionInputs}
            type="text"
            style={{ width: '100%' }}
            disabled={isFieldEditable(FORM_FIELDS.dimensionsHeight.key, editableFields)}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

ProductDimensions.propTypes = {
  editableFields: PropTypes.arrayOf(PropTypes.string).isRequired,
  formikProps: PropTypes.objectOf(PropTypes.any).isRequired,
  dimensionUnits: PropTypes.arrayOf(PropTypes.any).isRequired,
};
