import React, { memo } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { Grid } from '@material-ui/core';
import ReadOnlyContainer from '../../../components/common/ReadOnlyContainer';
import { ACCOUNT_MANAGEMENT, NOTIFICATION_TYPE_FIELD_NAME } from '../constants';
import ReadOnlyCheckbox from '../../../components/common/ReadOnlyCheckbox';
import Form from '../Form';
import Types from './Types';
import { FIELD_TYPE_BOOL } from '../../../clientConstants';

const Notifications = ({
  formikRef, itemKey, notificationTypes, values, isEditing, onEdit, onSubmit,
}) => {
  const obj = ACCOUNT_MANAGEMENT[itemKey];

  return (
    <ReadOnlyContainer title={obj.title} setIsReadOnly={() => onEdit(itemKey)}>
      {isEditing ? (
        <Form
          ref={formikRef}
          itemKey={itemKey}
          initialValues={values}
          validationSchema={obj.validationSchema}
          fields={obj.fields}
          notificationTypes={notificationTypes}
          onEdit={onEdit}
          onSubmit={onSubmit}
        />
      )
        : obj.fields.map((field) => (
          <Grid key={field.name} item xs={field?.fullWidth ? 12 : 6}>
            {field.type === FIELD_TYPE_BOOL && (
              <ReadOnlyCheckbox
                type={field.type}
                name={field.name}
                label={field.label}
                value={get(values, field.name, false)}
              />
            )}
            {field.type === NOTIFICATION_TYPE_FIELD_NAME && (
              <Types
                notificationKey={field.name}
                notificationTypes={notificationTypes}
                values={values}
                isEditing={isEditing}
              />
            )}
          </Grid>
        ))}
    </ReadOnlyContainer>
  );
};

Notifications.defaultProps = {
  notificationTypes: null,
  values: null,
  isEditing: false,
};

Notifications.propTypes = {
  formikRef: PropTypes.shape(PropTypes.object).isRequired,
  itemKey: PropTypes.string.isRequired,
  notificationTypes: PropTypes.shape({
    email: PropTypes.arrayOf(PropTypes.shape({
      type: PropTypes.string,
      description: PropTypes.string,
    })),
    sms: PropTypes.arrayOf(PropTypes.shape({
      type: PropTypes.string,
      description: PropTypes.string,
    })),
  }),
  values: PropTypes.shape({
    email: PropTypes.shape({
      enabled: PropTypes.bool,
      notificationType: PropTypes.arrayOf(PropTypes.string),
    }),
    sms: PropTypes.shape({
      enabled: PropTypes.bool,
      notificationType: PropTypes.arrayOf(PropTypes.string),
    }),
  }),
  isEditing: PropTypes.bool,
  onEdit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default memo(Notifications);
