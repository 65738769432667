import React from 'react';

function convertToScale(d, scale) {
  return d.replace(/([0-9]+\.{0,1}[0-9]*)/g, (match, p1) => (scale * Number(p1)).toFixed(2));
}

export default function DeleteIcon(props) {
  const { style, color } = props;
  const ratio = 20 / 24;
  const height = (style && style.height) || 24;
  const width = (height && Math.floor(ratio * height)) || 20;
  const scale = height / 24;
  const oldD1 = 'M9,9 L10.5,9 L10.5,18 L9,18 L9,9 Z M13.5,9 L15,9 L15,18 L13.5,18 L13.5,9 Z M3,4.5 L3,6 L4.5,6 L4.5,21 C4.5,21.8284271 5.17157288,22.5 6,22.5 L18,22.5 C18.8284271,22.5 19.5,21.8284271 19.5,21 L19.5,6 L21,6 L21,4.5 L3,4.5 Z M6,21 L6,6 L18,6 L18,21 L6,21 Z M9,1.5 L15,1.5 L15,3 L9,3 L9,1.5 Z';
  const oldD2 = 'M8.5,8.5 L11,8.5 L11,18.5 L8.5,18.5 L8.5,8.5 Z M13,8.5 L15.5,8.5 L15.5,18.5 L13,18.5 L13,8.5 Z M20,6.5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,6.5 L2.5,6.5 L2.5,4 L21.5,4 L21.5,6.5 L20,6.5 Z M6.5,20.5 L17.5,20.5 L17.5,6.5 L6.5,6.5 L6.5,20.5 Z M8.5,1 L15.5,1 L15.5,3.5 L8.5,3.5 L8.5,1 Z';
  const d1 = convertToScale(oldD1, scale);
  const d2 = convertToScale(oldD2, scale);
  return (
    <svg width={`${width}px`} height={`${height}px`} viewBox={`0 0 ${width} ${height}`} version="1.1" xmlns="http://www.w3.org/2000/svg" {...({ xmlnsXlink: 'http://www.w3.org/1999/xlink' })}>
      <title>@3xTrash</title>
      <desc>Created with Sketch.</desc>
      <defs>
        <path d={d1} id="path-1" />
      </defs>
      <g id="3-12---Basic-Order---Main-Flow" stroke="none" strokeWidth="0" fill={color || 'white'} fillRule="evenodd">
        <g id="4.0.1_BSO_Sender_DefaultSender" transform="translate(-1038.000000, -142.000000)">
          <g id="Shipment-Panel---Open" transform="translate(332.000000, 124.000000)">
            <g id="Shipment-Header" transform="translate(20.000000, 16.000000)">
              <g id={`icon/formatting/delete/${height}`} transform="translate(684.000000, 2.000000)">
                <mask id="mask-2" fill={color || 'white'}>
                  <use {...({ xlinkHref: '#path-1' })} />
                </mask>
                <path strokeOpacity="0" stroke="#000000" strokeWidth="0" d={d2} />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
