import React from 'react';
import * as braintree from 'braintree-web';
import PropTypes from 'prop-types';
import { Grid, MenuItem } from '@material-ui/core';
import { Field } from 'formik';
import * as _ from 'lodash';
import { CustomReadOnlyTextField, CustomText } from './InputComponents';
import CustomSelectTextField from './CustomSelectTextField';

const ownershipTypeOptions = [
  {
    label: 'Business',
    value: 'business',
  },
  {
    label: 'Personal',
    value: 'personal',
  },
];

const accountTypeOptions = [
  {
    label: 'Checking',
    value: 'checking',
  },
  {
    label: 'Savings',
    value: 'savings',
  },
];

export default function ACHForm(props) {
  const {
    clientInstance, setUSBankAccountInstance, lockPayment, updateExistingPayment,
  } = props;

  React.useEffect(() => {
    if (!clientInstance) return;
    braintree.usBankAccount.create({
      client: clientInstance,
    }).then((usBankAccountInstance) => {
      setUSBankAccountInstance(usBankAccountInstance);
    }).catch((err) => {
      // console.log(err);
    });
  }, [clientInstance]);

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item container spacing={2}>
        <Grid item xs={6}>
          <Field
            component={CustomSelectTextField}
            name="bankAccountDetails.ownerShipType"
            label="Ownership type"
            style={{ width: '100%' }}
            disabled={lockPayment}
          >
            {ownershipTypeOptions.map((type) => (
              <MenuItem
                key={type.label}
                value={type.value}
              >
                {type.label}
              </MenuItem>
            ))}
          </Field>

        </Grid>
        <Grid item xs={6}>
          <Field
            component={CustomSelectTextField}
            name="bankAccountDetails.accountType"
            label="Account type"
            style={{ width: '100%' }}
            disabled={lockPayment}
          >
            {accountTypeOptions.map((type) => (
              <MenuItem
                key={type.label}
                value={type.value}
              >
                {type.label}
              </MenuItem>
            ))}
          </Field>
        </Grid>
      </Grid>
      <Field>
        {(formikProps) => {
          const { form } = formikProps;
          const { values } = form;
          switch (_.get(values, 'bankAccountDetails.ownerShipType')) {
            case 'personal':
              return (
                <Grid item container spacing={2}>
                  <Grid item xs={6}>
                    <CustomText
                      name="bankAccountDetails.firstName"
                      label="First name"
                      disabled={lockPayment}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <CustomText
                      name="bankAccountDetails.lastName"
                      label="Last name"
                      disabled={lockPayment}
                    />
                  </Grid>
                </Grid>
              );
            case 'business':
              return (
                <Grid item>
                  <CustomText
                    name="bankAccountDetails.businessName"
                    label="Business name"
                    disabled={lockPayment}
                  />
                </Grid>
              );
            default:
              return null;
          }
        }}
      </Field>
      <Grid item>
        {
          !updateExistingPayment
            ? (
              <CustomText
                name="bankAccountDetails.accountNumber"
                label="Account number"
                disabled={lockPayment}
                blockValue={(val) => !val.match(/^\d{0,12}$/)}
                autocomplete="off"
                inputProps={{ autoComplete: 'off' }}
              />
            ) : (
              <Field
                name="bankAccountDetails.lastFour"
              >
                {
                  ({ field }) => (
                    <CustomReadOnlyTextField
                      value={`Account ending in ${field.value}`}
                      label="Account number"
                    />
                  )
                }
              </Field>
            )
}
      </Grid>
      <Grid item>
        <CustomText
          name="bankAccountDetails.routingNumber"
          label="Routing number"
          disabled={lockPayment}
          blockValue={(val) => !val.match(/^\d{0,9}$/)}
          inputProps={{ autoComplete: 'off' }}
        />
      </Grid>
    </Grid>
  );
}

ACHForm.propTypes = {
  clientInstance: PropTypes.objectOf(PropTypes.any),
  setUSBankAccountInstance: PropTypes.func.isRequired,
  lockPayment: PropTypes.bool,
  updateExistingPayment: PropTypes.bool,
};

ACHForm.defaultProps = {
  clientInstance: null,
  lockPayment: false,
  updateExistingPayment: false,
};
