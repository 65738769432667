import React from 'react';
import {
  Divider, Grid, Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { formatMonetaryAmount } from '../../utils/helpers';
import * as colors from '../../styles/colors';
import ShowMore from '../common/ShowMore';

const styles = {
  priceBreakdownContainer: {
    padding: '4px 0',
  },
  label: {
    fontSize: 12,
    textTransform: 'uppercase',
    color: colors.textLightGrey,
    fontWeight: 700,
    lineHeight: '16px',
  },
  textContainer: {
    textAlign: 'end',
  },
  charge: {
    fontSize: 14,
    color: colors.white,
    fontWeight: 400,
    lineHeight: '19px',
  },
  total: {
    fontSize: 18,
    color: colors.white,
    fontWeight: 400,
    lineHeight: '21px',
  },
  background: {
    padding: '12px 32px 18px',
    backgroundColor: colors.darkBlueBackground,
  },
  divider: {
    marginTop: 5,
    marginBottom: 5,
    width: '100%',
    backgroundColor: colors.textLightGrey,
  },
  dividerTotal: {
    marginTop: 20,
  },
};

const useStyles = makeStyles(styles);

const labels = {
  billing: 'Freight',
  billingCustoms: 'Customs',
  billingTaxes: 'Taxes',
};
export default function BillingCharges({
  charges = [], total, currencyCode = 'USD',
}) {
  const classes = useStyles();
  return (
    <Grid container direction="column" className={classes.background} xs={6}>
      <Grid container direction="column" spacing={2}>
        {charges.map(({ chargeType, itemizedCharges = [] }) => (
          <Grid item container>
            <Grid item><Typography color="primary" className={classes.label}>{labels[chargeType]}</Typography></Grid>
            <Divider className={classes.divider} />
            <ShowMore>
              {itemizedCharges.map(({ price, description }) => (
                <Grid className={classes.priceBreakdownContainer} item container spacing={1}>
                  <Grid item xs={10}>
                    <Typography className={classes.charge}>
                      {description}
                    </Typography>
                  </Grid>
                  <Grid className={classes.textContainer} item xs={2}><Typography className={classes.charge}>{formatMonetaryAmount(currencyCode, price || 0.00)}</Typography></Grid>
                </Grid>
              ))}
            </ShowMore>
          </Grid>
        ))}
        <Grid item container spacing={1} className={classes.dividerTotal}>
          <Grid item><Typography className={classes.total}>Estimated Total Charges:</Typography></Grid>
          <Grid item><Typography color="primary" className={classes.total}>{formatMonetaryAmount(currencyCode, total || 0.00)}</Typography></Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

BillingCharges.propTypes = {

};
