import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import { CircularProgress } from '@material-ui/core';
import { TrashCan16 } from '@carbon/icons-react';
import * as colors from '../styles/colors';
import FedexLogo from './images/FEDEX_trimmed.png';
import UPSLogo from './images/UPS_transparent.png';
import MNXLogo from './images/MNX.png';
import USPSLogo from './images/USPS.png';
import DHLLogo from './images/DHL.png';
import { formatMonetaryAmount } from '../utils/helpers';

function formatDateString(date) {
  return date
    ? moment.utc(date).format('ddd, MMM DD, h A')
    : 'N/A';
}

function findRateById(rates, id) {
  const ratesArr = rates.filter((rate) => rate.id === id);
  return ratesArr[0];
}

function setDefaultRowsPerPage(numberOfRows) {
  if (numberOfRows < 25) return numberOfRows;
  return 25;
}

const getCarrierLogo = (carrierName) => {
  const logos = {
    FEDEX: FedexLogo,
    UPS: UPSLogo,
    MNX: MNXLogo,
    NGL: MNXLogo,
    USPS: USPSLogo,
    DHL: DHLLogo,
  };
  if (carrierName in logos) { return (props) => <img {...props} src={logos[carrierName]} alt="" />; }
  return (props) => <span {...props}>{carrierName}</span>;
};

const StyledRadio = withStyles({
  root: {
    '&$disabled': {
      color: colors.textDarkGrey,
    },
    color: colors.white,
  },
  colorSecondary: {
    color: colors.white,
    '&$checked': {
      color: colors.white,
    },
  },
  disabled: {},
})(Radio);

const StyledTableCell = withStyles({
  root: {
    color: colors.white,
    fontSize: 16,
    letterSpacing: 0.5,
    border: 'none',
  },
})(TableCell);

const StyledTablePagination = withStyles({
  root: {
    color: colors.white,
    backgroundColor: colors.darkBlueBackground,
  },
  selectIcon: {
    color: colors.white,
    backgroundColor: colors.darkBlueBackground,
  },
})(TablePagination);

function desc(a, b, orderBy) {
  if (!a[orderBy]) return -1;
  if (!b[orderBy]) return 1;
  if ((Number(b[orderBy]) || b[orderBy]) < (Number(a[orderBy]) || a[orderBy])) {
    return -1;
  }
  if ((Number(b[orderBy]) || b[orderBy]) > (Number(a[orderBy]) || a[orderBy])) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc'
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

const headCells = [
  {
    id: 'pon',
    numeric: false,
    disablePadding: false,
    label: 'Item #/Cart',
  },
  {
    id: 'itemNumber',
    numeric: false,
    disablePadding: false,
    label: 'Line #',
  },
  {
    id: 'description',
    numeric: false,
    disablePadding: false,
    label: 'Description',
  },
  {
    id: 'units.noOfUnits', numeric: false, disablePadding: false, label: 'Total Quantity',
  },
  {
    id: 'units.noOfUnits',
    numeric: false,
    disablePadding: false,
    label: 'Balance Quantity',
  },
  {
    id: 'units.noOfUnits',
    numeric: false,
    disablePadding: false,
    label: 'Partial Quantity',
  },
  {
    id: 'salesOrderId',
    numeric: false,
    disablePadding: false,
    label: 'Sales Order',
  },
];

const isDateHeader = (headerId) => headerId === 'orderDueDate';
export function PackageStatusIcon(props) {
  const { status, colorClass } = props;
  const classes = useStyles();
  switch (status.toUpperCase()) {
    case 'BINASSIGNED':
      return <div className={`${colorClass} ${classes.green}`} />;
    case 'UNASSIGNED':
      return <div className={`${colorClass} ${classes.red}`} />;
    case 'CLOSE':
      return <div className={colorClass} />;
    default:
      return <div className={colorClass} />;
  }
}
PackageStatusIcon.propTypes = {
  status: PropTypes.string.isRequired,
  colorClass: PropTypes.string,
};
PackageStatusIcon.defaultProps = {
  colorClass: '',
};
function ProductPaneTableHead(props) {
  const {
    classes, order, orderBy, onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <StyledTableCell padding="checkbox" />
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align={
              // eslint-disable-next-line no-nested-ternary
              isDateHeader(headCell.id)
                ? 'left'
                : headCell.numeric
                  ? 'left'
                  : 'right'
            }
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              className={classes.columnTitle}
              active={orderBy === headCell.id}
              direction={order}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

ProductPaneTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    backgroundColor: colors.darkBlueBackground,
    color: colors.white,
    flexDirection: 'column',
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  listPriceCell: {
    textDecoration: 'line-through',
  },
  yourPriceCell: {
    fontWeight: 900,
    color: colors.textGreen,
  },
  carrierImage: {
    height: 'auto',
    width: 'auto',
    maxHeight: '25px',
    maxWidth: '100px',
    margin: '2px 0',
  },
  progress: {
    width: '100%',
  },
  progressWrap: {
    display: 'flex',
    width: '100%',
    flexGrow: '1',
    justifyContent: 'center',
    alignItems: 'flex-start',
    backgroundColor: colors.darkBlueBackground,
    padding: '80px 0',
    minHeight: '250px',
  },
  bareCell: {
    border: 'none',
    width: '100%',
  },
  round: {
    width: '1.5rem',
    height: '1.5rem',
    borderRadius: '1rem',
    backgroundColor: 'yellow',
    marginLeft: 'auto',
  },
  green: {
    backgroundColor: 'green',
  },
  red: {
    backgroundColor: 'red',
  },
  removeIcon: {
    marginTop: '0.2rem',
    width: '90%',
    cursor: 'pointer',
  },
}));

export default function ProductPaneTable(props) {
  const classes = useStyles();
  const {
    rows,
  } = props;

  const StyledRow = {
    tableRowDark: withStyles({
      root: {
        backgroundColor: 'rgba(0,0,0,.24)',
        '&:hover': {
          backgroundColor: 'rgba(0,0,0,.35)',
        },
      },
      selected: {
        backgroundColor: 'rgba(0,0,0,.35) !important',
      },
    })(TableRow),
    tableRowLight: withStyles({
      root: {
        backgroundColor: 'rgba(0,0,0,.12)',
        '&:hover': {
          backgroundColor: 'rgba(0,0,0,.35)',
        },
      },
      selected: {
        backgroundColor: 'rgba(0,0,0,.35) !important',
      },
    })(TableRow),
  };
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('carrier');
  const [selected, setSelected] = React.useState(null);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(setDefaultRowsPerPage(rows?.length));

  function findRowMatch(rowArray, rate) {
    if (!rate) return null;
    const match = rowArray.find((row) => row.carrierName === rate.carrierName
      && row.serviceCode === rate.serviceCode);
    return match ? match.id : null;
  }

  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }

  // If the rows change, set the number of rows to display the max amount
  React.useEffect(() => {
    setRowsPerPage(setDefaultRowsPerPage(rows?.length));
  }, [rows]);

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <div className={classes.tableWrapper}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="small"
          >
            <ProductPaneTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              numSelected={selected ? 1 : 0}
            />
            <TableBody>
              {stableSort(rows, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  const Row = StyledRow[index % 2 ? 'tableRowLight' : 'tableRowDark'];

                  return (
                    <Row
                      role="checkbox"
                      aria-checked={row.id === selected}
                      tabIndex={-1}
                      key={row.id}
                      selected={row.id === selected}
                      classes={{
                        root:
                              classes[index % 2 ? 'tableRowLight' : 'tableRowDark'],
                      }}
                      data-testid="carrier-rate"
                    >
                      <StyledTableCell padding="checkbox" />
                      <StyledTableCell align="right">
                        {row.itemId}
                      </StyledTableCell>
                      <StyledTableCell
                        align="right"
                      >
                        {row.itemNumber}
                      </StyledTableCell>
                      <StyledTableCell
                        align="right"
                      >
                        {row.description}
                      </StyledTableCell>
                      <StyledTableCell
                        align="right"
                      >
                        {row?.units?.noOfUnits}
                      </StyledTableCell>
                      <StyledTableCell
                        align="right"
                      >
                        {row?.units?.noOfUnits}
                      </StyledTableCell>
                      <StyledTableCell
                        align="right"
                      >
                        {' '}
                      </StyledTableCell>
                      <StyledTableCell
                        align="right"
                      >
                        {row.salesOrderId}
                      </StyledTableCell>
                    </Row>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 33.0167 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>

          </Table>
        </div>
        <StyledTablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'previous page',
          }}
          nextIconButtonProps={{
            'aria-label': 'next page',
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          SelectProps={{ SelectDisplayProps: { 'data-testid': 'carrier-rates-pagination' } }}
        />
      </Paper>
    </div>
  );
}

ProductPaneTable.propTypes = {
  rows: PropTypes.array.isRequired,
};
