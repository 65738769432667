import React from 'react';
import { useLogger } from '../utils/hooks';
import * as paymentClient from '../utils/paymentClient';

const PaymentContext = React.createContext();
const PaymentDispatch = React.createContext();

const LOAD_PAYMENT_DATA = 'LOAD_PAYMENT_DATA';
const UPDATE_PAYMENT_METHODS = 'UPDATE_PAYMENT_METHODS';
const ADD_PAYMENT_METHOD = 'ADD_PAYMENT_METHOD';

function paymentReducer(state, action) {
  const { data, type } = action;
  switch (type) {
    case LOAD_PAYMENT_DATA:
      return { ...state, ...data };
    case UPDATE_PAYMENT_METHODS:
      return { ...state, savedPaymentMethods: { ...data } };
    case ADD_PAYMENT_METHOD:
      return { ...state, savedPaymentMethods: { ...state, ...data } };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
}

function PaymentDataProvider(props) {
  const [state, dispatch] = useLogger(
    React.useReducer(paymentReducer, {}),
  );

  React.useEffect(() => {
    Promise.all([paymentClient.loadSavedPaymentOptions(), paymentClient.loadAccountCostAllocation(), paymentClient.loadUserCostCodeProfile()])
      .then(([savedPaymentMethods, costAllocation, costCodeProfile]) => {
        dispatch({ type: LOAD_PAYMENT_DATA, data: { savedPaymentMethods, costAllocation, costCodeProfile } });
      });
  }, []);

  return (
    <PaymentContext.Provider value={state} {...props}>
      <PaymentDispatch.Provider
        value={dispatch}
        {...props}
      />
    </PaymentContext.Provider>
  );
}

async function loadSavedPaymentMethods(dispatch) {
  let savedPaymentMethods;
  try {
    savedPaymentMethods = await paymentClient.loadSavedPaymentOptions();
  } catch (e) {
    throw new Error(e.message || e);
  }
  dispatch({ type: LOAD_PAYMENT_DATA, data: { savedPaymentMethods } });
}

function usePaymentState() {
  const context = React.useContext(PaymentContext);
  if (context === undefined) {
    throw new Error('usePaymentState must be used within a PaymentContextProvider');
  }
  return context;
}

function usePaymentDispatch() {
  const context = React.useContext(PaymentDispatch);
  if (context === undefined) {
    throw new Error('usePaymentDispatch must be used within a PaymentDispatchProvider');
  }
  return context;
}

async function updatePaymentMethod({ dispatch, paymentToken, payload }) {
  try {
    await paymentClient.updatePaymentMethod(paymentToken, payload);
    loadSavedPaymentMethods(dispatch);
  } catch (e) {
    throw new Error(e.message || e);
  }
}

async function addPaymentMethod({ dispatch, payload }) {
  try {
    await paymentClient.addPaymentMethod(payload);
    loadSavedPaymentMethods(dispatch);
  } catch (e) {
    throw new Error(e.message || e);
  }
}

async function deletePaymentMethod({ dispatch, paymentToken }) {
  try {
    await paymentClient.deletePaymentMethod(paymentToken);
    loadSavedPaymentMethods(dispatch);
  } catch (e) {
    throw new Error(e.message || e);
  }
}

async function setDefaultPayment({ dispatch, paymentToken }) {
  try {
    await paymentClient.setDefaultPayment(paymentToken);
    await loadSavedPaymentMethods(dispatch);
  } catch (e) {
    throw new Error(e.message || e);
  }
}

async function loadSavedCostCodeProfile(dispatch) {
  let costCodeProfile;
  try {
    costCodeProfile = await paymentClient.loadUserCostCodeProfile();
  } catch (e) {
    throw new Error(e.message || e);
  }
  dispatch({ type: LOAD_PAYMENT_DATA, data: { costCodeProfile } });
}

async function updateCostCodeProfile({ dispatch, costCodeId, payload }) {
  try {
    await paymentClient.updateCostCodeProfile(costCodeId, payload);
    loadSavedCostCodeProfile(dispatch);
  } catch (e) {
    throw new Error(e.message || e);
  }
}

async function addCostCodeProfile({ dispatch, payload }) {
  try {
    await paymentClient.addCostCodeProfile(payload);
    loadSavedCostCodeProfile(dispatch);
  } catch (e) {
    throw new Error(e.message || e);
  }
}

async function deleteCostCodeProfile({ dispatch, costCodeId }) {
  try {
    await paymentClient.deleteCostCodeProfile(costCodeId);
    loadSavedCostCodeProfile(dispatch);
  } catch (e) {
    throw new Error(e.message || e);
  }
}

async function makeDefaultCostCodeProfile({ dispatch, costCodeId }) {
  try {
    await paymentClient.setDefaultCostCodeProfile(costCodeId);
    await loadSavedCostCodeProfile(dispatch);
  } catch (e) {
    throw new Error(e.message || e);
  }
}

export {
  PaymentDataProvider,
  usePaymentDispatch,
  usePaymentState,
  updatePaymentMethod,
  addPaymentMethod,
  deletePaymentMethod,
  loadSavedPaymentMethods,
  setDefaultPayment,
  updateCostCodeProfile,
  addCostCodeProfile,
  deleteCostCodeProfile,
  makeDefaultCostCodeProfile,
  loadSavedCostCodeProfile,
};
