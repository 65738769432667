import React from 'react';
import PropTypes from 'prop-types';
import MuiTextField from '@material-ui/core/TextField';
import { fieldToTextField } from 'formik-material-ui';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { css } from 'emotion';
import { NEW_ORDER_INPUT_LABEL_PROPS, NEW_ORDER_INPUT_PROPS } from '../../styles/style';
import * as colors from '../../styles/colors';

export default function CustomFilterTextField(props) {
  const {
    customOnChange, blockValue, renderValue, disabled, ...other
  } = props;
  const propsWithFormik = fieldToTextField(other);
  const classes = {
    inputLabel: {
      root: css({
        '&$focused': {
          color: colors.white,
        },
        '&$disabled': {
          color: colors.textDarkGrey,
        },
        '&$error': {
          color: colors.white,
        },
        color: colors.white,
        fontWeight: 425,
      }),
      disabled: {
      },
      focused: {},
      error: {},
    },
    input: {
      root: css({
        '&$focused': {
          color: colors.white,
        },
        '&$disabled': {
          color: colors.white,
        },
        '&:before': {
          borderBottom: 0,
        },
        '&:after': {
          borderBottom: `2px solid ${colors.primaryBlue}`,
        },
        '&&&&:hover:before': {
          borderBottom: 0,
        },
        '&>div:nth-of-type(2)': {
          '&>button:first-of-type': {
            color: colors.textLightGrey,
          },
        },
        height: 56,
        color: colors.white,
        background: colors.inputFieldBackground,
        width: '100%',
      }),
      disabled: {
      },
      focused: {},
    },
    selectWithLabel: {
      icon: css({
        color: colors.white,
      }),
    },
    selectWithoutLabel: {
      icon: css({
        color: colors.white,
      }),
      root: css({
        paddingTop: 0,
        paddingBottom: 0,
      }),
    },
  };
  return (
    <MuiTextField
      {...propsWithFormik}
      onChange={(e) => {
        if (propsWithFormik.value !== e.target.value
          && (!blockValue || !blockValue(e.target.value))) {
          if (propsWithFormik.overrideOnChange) {
            propsWithFormik.overrideOnChange(e);
            return;
          }
          if (customOnChange) { customOnChange(e); }
          propsWithFormik.onChange(e);
        }
      }}
      variant="filled"
      disabled={disabled || propsWithFormik.disabled}
      InputLabelProps={{
        ...(propsWithFormik.InputLabelProps || {}),
        classes: NEW_ORDER_INPUT_LABEL_PROPS(),
      }}
      InputProps={{
        ...(propsWithFormik.InputProps || {}),
        fullWidth: true,
        classes: NEW_ORDER_INPUT_PROPS(),
      }}
    />
  );
}

CustomFilterTextField.propTypes = {
  customOnChange: PropTypes.func,
  blockValue: PropTypes.func,
};

CustomFilterTextField.defaultProps = {
  customOnChange: undefined,
  blockValue: undefined,
};
