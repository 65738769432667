import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import client from './apiClient';
import {
  DUMMY_FETCH_MATERIAL_QUERY,
  DUMMY_LOAD_PRODUCT_CATEGORIES,
  DUMMY_LOAD_DIMENSION_UNITS,
  DUMMY_LOAD_WEIGHT_UNITS,
  DUMMY_LOAD_SMALL_WEIGHT_UNITS,
  DUMMY_CURRENCY_UNITS,
  DUMMY_PRODUCT_UNITS,
  DUMMY_FREIGHT_ITEM_CLASSES,
  DUMMY_FETCH_BIOLOGICAL_MATERIAL_CLASS_TYPE_QUERY,
  DUMMY_FETCH_BIOLOGICAL_MATERIAL_CLASS_DETAILS_QUERY,
  DUMMY_FETCH_CHEMICAL_MATERIAL_CLASS_DETAILS_QUERY,
  DUMMY_FETCH_BATTERY_MATERIAL_CLASS_TYPE_QUERY,
  DUMMY_FETCH_BATTERIES_CONTAINED_OR_PACKED_MATERIAL_CLASS_TYPE_QUERY,
  DUMMY_LOAD_PRESERVATIVES,
} from '../dummyData';
import {
  DEFAULT_FETCH_OPTIONS, HAZMAT_BATTERIES, HAZMAT_BATTERIES_CONTAINED, HAZMAT_BATTERIES_CONTAINED_OR_PACKED, HAZMAT_BATTERIES_PACKED, HAZMAT_BIOLOGICAL, HAZMAT_CHEMICAL,
} from '../clientConstants';

function getFormData(material, itemImage) {
  const formData = new FormData();
  formData.append('item', JSON.stringify(material));
  if (!isNil(itemImage)) formData.append('itemImage', itemImage);

  return formData;
}

function loadAllMaterials(options = DEFAULT_FETCH_OPTIONS) {
  const {
    name, limit, offset, filter,
  } = options;

  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    const lowerCaseFilter = filter && filter.length >= 2 ? filter.toLowerCase() : '';

    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(DUMMY_FETCH_MATERIAL_QUERY
          .filter((item) => item.itemName.toLowerCase().includes(lowerCaseFilter))
          .slice(offset, offset + limit));
      }, 500);
    });
  }

  return client('/data/material/all', {
    method: 'get',
    params: {
      name,
      limit,
      offset,
      filter,
    },
  });
}

function loadMaterials(filter, type, isActive) {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(DUMMY_FETCH_MATERIAL_QUERY);
      }, 100);
    });
  }

  return client(`/data/material?filter=${filter}&type=${type}&isActive=${isActive}`, { method: 'get' });
}

function loadHazmatOptionCategories(category) {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(DUMMY_FETCH_BIOLOGICAL_MATERIAL_CLASS_TYPE_QUERY);
      }, 100);
    });
  }

  return client(`/data/material/${category}/category`, {
    method: 'get',
  });
}

function loadHazmatClassTypeDetails(name, filter, classType, isActive = true) {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    let data = DUMMY_FETCH_BIOLOGICAL_MATERIAL_CLASS_DETAILS_QUERY;

    if (name === HAZMAT_CHEMICAL) data = DUMMY_FETCH_CHEMICAL_MATERIAL_CLASS_DETAILS_QUERY;
    else if (name === HAZMAT_BATTERIES) data = DUMMY_FETCH_BATTERY_MATERIAL_CLASS_TYPE_QUERY;
    else if (name === HAZMAT_BATTERIES_CONTAINED_OR_PACKED) data = DUMMY_FETCH_BATTERIES_CONTAINED_OR_PACKED_MATERIAL_CLASS_TYPE_QUERY;
    else if (name === HAZMAT_BATTERIES_PACKED) {
      data = DUMMY_FETCH_BATTERIES_CONTAINED_OR_PACKED_MATERIAL_CLASS_TYPE_QUERY.filter(
        (item) => item.itemName.toLowerCase().includes('packed'),
      );
    }
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(data);
      }, 100);
    });
  }

  const params = {
    name,
    filter,
    isActive,
  };

  if (classType && name === HAZMAT_CHEMICAL) params.itemClass = JSON.stringify(classType);
  else if (name === HAZMAT_BATTERIES_CONTAINED) params.name = HAZMAT_BATTERIES_CONTAINED;
  else if (name === HAZMAT_BATTERIES_PACKED) params.name = HAZMAT_BATTERIES_PACKED;
  else if (classType) params.category = classType;

  return client('/data/material', {
    method: 'get',
    params,
  });
}

function loadProductCategories() {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(DUMMY_LOAD_PRODUCT_CATEGORIES);
      }, 100);
    });
  }
  return client('/data/master?name=productCategory', { method: 'get' });
}

function loadDimensionUnits() {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(DUMMY_LOAD_DIMENSION_UNITS);
      }, 100);
    });
  }
  return client('/data/master?name=dimensionUnits', { method: 'get' });
}

function loadWeightUnits() {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(DUMMY_LOAD_WEIGHT_UNITS);
      }, 100);
    });
  }
  return client('/data/master?name=weightUnits', { method: 'get' });
}
function loadHazmatWeightUnits() {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(DUMMY_LOAD_WEIGHT_UNITS);
      }, 100);
    });
  }
  return client('/data/master?name=weightUnits&freightType=hazmat', {
    method: 'get',
  });
}
function loadSmallWeightUnits() {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(DUMMY_LOAD_SMALL_WEIGHT_UNITS);
      }, 100);
    });
  }
  return client('/data/master?name=weightSmallUnits', { method: 'get' });
}
function loadHazmatSmallWeightUnits() {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(DUMMY_LOAD_SMALL_WEIGHT_UNITS);
      }, 100);
    });
  }
  return client('/data/master?name=weightSmallUnits&freightType=hazmat', {
    method: 'get',
  });
}

function loadPreservatives() {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(DUMMY_LOAD_PRESERVATIVES);
      }, 100);
    });
  }
  return client('/data/master?name=preservative', {
    method: 'get',
  });
}

function loadProductUnits() {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(DUMMY_PRODUCT_UNITS);
      }, 100);
    });
  }
  return client('/data/master?name=productUnit', { method: 'get' });
}

function loadCurrencyUnits() {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(DUMMY_CURRENCY_UNITS);
      }, 100);
    });
  }
  return client('/data/master?name=currency', { method: 'get' });
}

function loadFreightClasses() {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(DUMMY_FREIGHT_ITEM_CLASSES);
      }, 100);
    });
  }
  return client('/data/master?name=freightClass', { method: 'get' });
}

function addMaterial(material, itemImage, name) {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(material);
      }, 500);
    });
  }

  return client('/data/material/',
    {
      headers: {
        'content-type': 'form-data',
      },
      method: 'post',
      data: getFormData(material, itemImage),
      params: {
        name,
      },
    });
}

function editMaterial(updatedMaterial, itemImage, name) {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return new Promise((res, rej) => {
      setTimeout(() => {
        res(updatedMaterial);
      }, 500);
    });
  }

  return client(`/data/material/${updatedMaterial.itemId}`, {
    headers: {
      'content-type': 'form-data',
    },
    method: 'put',
    data: getFormData(updatedMaterial, itemImage),
    params: {
      name,
    },
  });
}

function deleteMaterial(materialId, name = '') {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve([{ materialId }]);
      }, 500);
    });
  }

  let url = `/data/material/${materialId}`;

  if (!isEmpty(name)) url = `${url}?name=${name}`;

  return client(url, { method: 'delete' });
}

export {
  loadMaterials,
  loadAllMaterials,
  loadProductCategories,
  loadDimensionUnits,
  loadWeightUnits,
  loadProductUnits,
  loadCurrencyUnits,
  loadFreightClasses,
  loadSmallWeightUnits,
  loadPreservatives,
  loadHazmatOptionCategories,
  loadHazmatClassTypeDetails,
  loadHazmatWeightUnits,
  loadHazmatSmallWeightUnits,
  addMaterial,
  editMaterial,
  deleteMaterial,
};
