import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { Grid, MenuItem } from '@material-ui/core';
import { Field } from 'formik';
import NewOrderCustomTextField from '../../common/NewOrderCustomTextField';
import CustomSelectTextField from '../../common/CustomSelectTextField';
import {
  FORM_FIELDS,
} from '../itemDetailsConstants';
import { blockWeightAndDimensionInputs, isFieldEditable } from '../itemDetailsUtil';

export default function ProductUnitCost({
  editableFields,
  formikProps,
  currencyUnits,
  columnsWidth,
}) {
  const currencyCode = get(formikProps.values, FORM_FIELDS.currencyCode.key);

  return (
    <>
      <Grid item xs={columnsWidth.unitValue}>
        <Field
          name={FORM_FIELDS.merchantCost.key}
          label={FORM_FIELDS.merchantCost.label}
          component={NewOrderCustomTextField}
          blockValue={blockWeightAndDimensionInputs}
          type="text"
          style={{ width: '100%' }}
          disabled={isFieldEditable(FORM_FIELDS.merchantCost.key, editableFields)}
        />
      </Grid>
      <Grid item xs={columnsWidth.currencyCode}>
        <Field
          name={FORM_FIELDS.currencyCode.key}
          label={currencyCode ? '' : FORM_FIELDS.currencyCode.label}
          component={CustomSelectTextField}
          type="text"
          style={{ width: '100%' }}
          disabled={isFieldEditable(FORM_FIELDS.currencyCode.key, editableFields)}
        >
          {currencyUnits.map((option) => (
            <MenuItem
              key={option.currencyCode}
              value={option.currencyCode}
            >
              {option.currencyCode}
            </MenuItem>
          ))}
        </Field>
      </Grid>
    </>
  );
}

ProductUnitCost.defaultProps = {
  columnsWidth: {
    unitValue: 3,
    currencyCode: 2,
  },
};

ProductUnitCost.propTypes = {
  editableFields: PropTypes.arrayOf(PropTypes.string).isRequired,
  formikProps: PropTypes.objectOf(PropTypes.any).isRequired,
  currencyUnits: PropTypes.arrayOf(PropTypes.any).isRequired,
  columnsWidth: PropTypes.shape({
    unitValue: PropTypes.number,
    currencyCode: PropTypes.number,
  }),
};
