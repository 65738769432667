import { POINT_CONVERSION_COMPRESSED } from 'constants';
import client from './apiClient';
import { DUMMY_CONTACTS, DUMMY_DEFAULT_SENDER } from '../dummyData';

function load(options = { limit: 100, offset: 0, filter: '' }) {
  const { limit, offset, filter } = options;
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    const lowerCaseFilter = filter && filter.toLowerCase();
    return new Promise((res, rej) => {
      setTimeout(() => {
        res(DUMMY_CONTACTS
          .filter((contact) => contact.name.toLowerCase()
            .includes(lowerCaseFilter))
          .slice(offset, offset + limit));
      }, 500);
    });
  }
  return client('/data/contacts', {
    method: 'get',
    query: {
      limit,
      offset,
      filter,
    },
    params: {
      limit,
      offset,
      filter,
    },
  });
}

function loadFilteredContacts(filter, shipmentId = '', taskType = '') {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    const lowerCaseFilter = filter && filter.toLowerCase();

    return filter
      ? new Promise((res, rej) => {
        setTimeout(() => {
          res(DUMMY_CONTACTS
            // eslint-disable-next-line max-len
            .filter((contact) => contact.name.toLowerCase().includes(lowerCaseFilter.toLowerCase())));
        }, 500);
      })
      : new Promise((res, rej) => {
        setTimeout(() => {
          res(DUMMY_CONTACTS);
        }, 500);
      });
  }
  return client(`/data/address?addressType=user&shipmentId=${shipmentId}&taskType=${taskType}&filter=${filter}`, { method: 'get' });
}

function edit(updatedContact) {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    const alteredContacts = [updatedContact];
    if (updatedContact.isDefault) {
      const previousDefault = { ...DUMMY_CONTACTS.find((c) => c.isDefault) };
      delete previousDefault.isDefault;
      alteredContacts.push(previousDefault);
    }
    return new Promise((res, rej) => {
      setTimeout(() => {
        res(alteredContacts);
      }, 500);
    });
  }
  return client('/data/address/user', { method: 'put', data: updatedContact });
}

function add(contact) {
  const newContact = { ...contact };

  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return new Promise((res, rej) => {
      setTimeout(() => {
        res(DUMMY_CONTACTS.concat([newContact]));
      }, 500);
    });
  }
  return client('/data/address/user', { method: 'post', data: newContact });
}

function setDefault(id) {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return new Promise((res, rej) => {
      setTimeout(() => {
      }, 500);
    });
  }
  return client(`/data/address/default/${id}`, { method: 'post' });
}

function addBulk(file, isAddAddress = false) {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return new Promise((res) => {
      setTimeout(() => {
        res('processed');
      }, 500);
    });
  }

  const formData = new FormData();
  formData.append('addressCsv', file);
  formData.append('isAddAddress', isAddAddress);

  return client('/data/address/user/bulk', {
    method: 'post',
    data: formData,
    headers: {
      'content-type': 'form-data',
    },
  });
}

function remove(addressId) {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return new Promise((res, rej) => {
      setTimeout(() => {
        res([{ addressId }]);
      }, 500);
    });
  }
  return client(`/data/address/user/${addressId}`, { method: 'delete' });
}
function loadInitContacts() {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return Promise.resolve(DUMMY_CONTACTS.slice(0, 10));
  }
  return client('/data/address?addressType=user&limit=10', { method: 'get' });
}

function loadDefaultSender() {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return Promise.resolve(DUMMY_DEFAULT_SENDER);
  }
  return client('/data/address/default', { method: 'get' });
}

export {
  load, loadInitContacts, loadDefaultSender, loadFilteredContacts, edit, add, addBulk, remove, setDefault,
};
