import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import StyledCheckCircleIcon from './StyledCheckCircleIcon';
import * as colors from '../../styles/colors';

const NEW_ORDER_PANE_LABEL_STYLE = makeStyles(() => ({
  label: {
    color: colors.white,
    fontSize: 19,
    fontWeight: 500,
  },
  detailsContainer: {
    width: 'initial',
    flexGrow: 1,
  },
  detailsSubContainer: {
    float: 'right',
    flexGrow: 1,
  },
  iconContainer: {
    marginLeft: 8,
  },
  detailsLabel: {
    color: colors.textLightGrey,
    fontSize: 14.5,
    fontWeight: 500,
    paddingTop: 5.5,
    width: '100%',
    textAlign: 'right',
    letterSpacing: '0.02em',
  },
}));

export default function NewOrderExpansionPaneLabel(props) {
  const classes = NEW_ORDER_PANE_LABEL_STYLE();
  const { label, details, complete } = props;

  function buildLabelDetails(details) {
    if (details === '') return null;

    return (
      <Grid item container justify="space-between" className={classes.detailsContainer}>
        <Grid item className={classes.iconContainer}>
          {(complete)
            ? (
              <StyledCheckCircleIcon
                isValidated
                isGreenWhenValid
              />
            )
            : null}
        </Grid>
        <Grid item className={classes.detailsSubContainer}>
          <Typography className={classes.detailsLabel}>{details}</Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container>
      <Grid item>
        <Typography className={classes.label}>{label}</Typography>
      </Grid>
      {buildLabelDetails(details)}
    </Grid>
  );
}
