import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import * as colors from '../../styles/colors';

const useStyles = makeStyles({
  scrollbarsRoot: {
    maxHeight: 390,
  },
  scrollbarsTrackHorizontal: {
    right: 2,
    bottom: 2,
    left: 2,
    borderRadius: 3,
  },
  scrollbarsVertical: {
    width: 4,
    borderRadius: 25,
    backgroundColor: colors.inputFieldBackground,
  },
});

export default function CustomScrollbar(props) {
  const { dropDownList } = props;

  const { scrollbarsRoot, scrollbarsTrackHorizontal, scrollbarsVertical } = useStyles();

  function renderTrackHorizontal(renderTHProps) {
    return (
      <div {...renderTHProps} className={scrollbarsTrackHorizontal} />
    );
  }

  function renderThumbVertical(renderTVprops) {
    return (
      <div {...renderTVprops} className={scrollbarsVertical} />
    );
  }

  return (
    <Scrollbars
      // style={{ height: 'invalid' }} // Invalid input required to load hight from class
      autoHeight
      renderTrackHorizontal={renderTrackHorizontal}
      renderThumbVertical={renderThumbVertical}
      className={scrollbarsRoot} // 'class' throws error-warn, 'className' required
    >
      {dropDownList}
    </Scrollbars>
  );
}

CustomScrollbar.propTypes = {
  dropDownList: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]),
  classes: PropTypes.shape({
    scrollbarsRoot: PropTypes.string,
    scrollbarsTrackHorizontal: PropTypes.string,
    scrollbarsVertical: PropTypes.string.isRequired,
  }).isRequired,
};

CustomScrollbar.defaultProps = {
  dropDownList: null,
};
