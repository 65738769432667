/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import has from 'lodash/has';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Route, Link } from 'react-router-dom';
import { css } from 'emotion';
import {
  Grid, Typography, Button, IconButton, Tooltip, Checkbox, Link as MuiLink,
} from '@material-ui/core/';
import AlarmOutlinedIcon from '@material-ui/icons/AlarmOutlined';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import moment from 'moment';
import * as colors from '../styles/colors';
import ConfirmationModal from './common/ConfirmationModal';
import ErrorDialog from './common/NewOrderErrorDialog';

const useStyles = makeStyles((theme) => ({
  centeredContainer: {
    alignItems: 'center',
  },
  tile: {
    backgroundColor: colors.shipmentCardBackground,
    padding: '8px',
    borderRadius: '10px',
    minHeight: '96px',
    marginBottom: '10px',
    width: '100%',
    position: 'relative',
  },
  shipmentHeading: {
    color: '#B5B9CC',
    fontSize: '12px',
    fontWeight: 500,
    marginTop: '10px',
    marginBottom: '7px',
  },
  shipmentIdHeader: {
    color: 'rgba(255,255,255,0.6)',
    letterSpacing: 0.4,
  },
  shipmentInfoHeader: {
    letterSpacing: 2,
  },
  link: {
    textDecoration: 'none',
    '&:visited': {
      textDecoration: 'none',
    },
  },
  trackShipment: {
    color: colors.secondaryButton,
    fontSize: '14px',
    fontWeight: '500',
    textTransform: 'none',
  },
  trackingButton: {
    padding: '0px 0px 0px 0px',
    color: '#94CCFB',
  },
  statusMessage: {
    paddingLeft: 8,
  },
  shipmentInformation: {
    color: 'white',
    fontSize: '14px',
  },
  allCapsWide: {
    textTransform: 'uppercase',
    letterSpacing: '1.2',
  },
  shipmentButton: {
    width: '137px',
    borderRadius: '5px',
  },
  boldHeading: {
    // fontWeight: 600,
  },
  onTimeHeading: {
    color: '#A7F4B4',
  },
  delayedHeading: {
    color: '#FED599',
  },
  incompleteHeading: {
    color: '#FF9FB4',
  },
  deliveredHeading: {
    color: '#B5B9CC',
  },
  buttonDiv: {
    alignItems: 'center',
    display: 'inherit',
  },
  onTimeBorder: {
    borderLeft: '3px solid #A7F4B4',
  },
  delayedBorder: {
    borderLeft: '3px solid #FED599',
  },
  incompleteBorder: {
    borderLeft: '3px solid #FF9FB4',
  },
  shipmentCardColumn: {
    padding: '0 2px',
    height: '100%',
  },
  shipmentCardButton: {
    alignItems: 'center',
    textAlign: 'center',
    height: '100%',
  },
  duplicateIconContainer: {
    alignSelf: 'flex-start',
  },
  duplicateIcon: {
    // position: 'relative',
    // top: '8px',
    // right: '8px',
    transform: 'rotate(180deg)',
    padding: '0',
  },
  tooltip: {
    background: 'transparent',
    fontSize: 12,
  },
  round: {
    width: '1.5rem',
    height: '1.5rem',
    borderRadius: '1rem',
    backgroundColor: 'yellow',
    marginTop: '1.5rem',
  },
  checkbox: {
    color: 'white',
    marginTop: '1rem',

  },
}));

// Util functions

function getButtonTextByStatus(status) {
  return (status.toUpperCase() === 'CREATED') ? 'EDIT' : 'VIEW';
}

function trimShipmentId(shipmentId) {
  return shipmentId.split('-').pop();
}

function CloseBinButton(props) {
  const {
    label, binId, binStatus, handleCloseClick, getDueDate, isClose,
  } = props;

  const hovered = css`
  background:${colors.mediumBlueBackground}
  `;
  const disabled = css({ color: '#3B3F51' });
  const buttonStyle = css({
    fontWeight: 'bold',
    margin: 'auto',
    maxWidth: ' 148px',
    color: `${colors.secondaryButton}`,
    borderRadius: '8px',
    backgroundColor: `${colors.shipmentCardBackground}`,
    border: `1px solid ${colors.buttonBackgroundBlue}`,
    boxShadow: 'none',
    ':hover': hovered,
    ':disabled': disabled,
    width: '100%',
  });
  return (
    <Route
      render={({ history }) => (
        <Button
          className={buttonStyle}
          variant="contained"
          disabled={
             binStatus?.toUpperCase() === 'OPEN'
            || binStatus.toUpperCase() === 'READY'
            || !isClose
            // binStatus.toUpperCase() === 'OPEN'
            // || binStatus.toUpperCase() === 'READY'
            // || moment(getDueDate()).isAfter(moment().format('MM/DD/YYYY'))
          }
          onClick={() => handleCloseClick(binId)}
        >
          {label}
        </Button>
      )}
    />
  );
}

CloseBinButton.propTypes = {
  label: PropTypes.string.isRequired,
  binId: PropTypes.string.isRequired,
  binStatus: PropTypes.string.isRequired,
  handleCloseClick: PropTypes.func.isRequired,
  getDueDate: PropTypes.func.isRequired,
};

function ResetBinButton(props) {
  const {
    label, binId, binStatus, handleResetClick,
  } = props;

  const hovered = css`
    background: ${colors.mediumBlueBackground};
  `;
  const disabled = css({ color: '#3B3F51' });
  const buttonStyle = css({
    fontWeight: 'bold',
    margin: 'auto',
    maxWidth: ' 148px',
    color: `${colors.secondaryButton}`,
    borderRadius: '8px',
    backgroundColor: `${colors.shipmentCardBackground}`,
    // border: `1px solid ${colors.red}`,
    boxShadow: 'none',
    ':hover': hovered,
    ':disabled': disabled,
    width: '100%',
  });
  return (
    <Route
      render={({ history }) => (
        <Button
          className={buttonStyle}
          variant="contained"
          disabled={
            binStatus.toUpperCase() === 'OPEN'
            || binStatus.toUpperCase() === 'READY'
          }
          onClick={() => handleResetClick(binId)}
        >
          {label}
        </Button>
      )}
    />
  );
}

ResetBinButton.propTypes = {
  label: PropTypes.string.isRequired,
  binId: PropTypes.string.isRequired,
  binStatus: PropTypes.string.isRequired,
  handleResetClick: PropTypes.func.isRequired,
};

const StyledWarningIcon = withStyles({
  root: { color: colors.warningYellow },
})(ReportProblemOutlinedIcon);
export function ShipmentStatusIcon(props) {
  const { status, colorClass } = props;
  switch (status.toUpperCase()) {
    case 'SCHEDULED FOR PICKUP':
      return <AlarmOutlinedIcon className={colorClass} />;
    case 'IN-TRANSIT':
      return <AlarmOutlinedIcon className={colorClass} />;
    case 'DELAYED':
      return <ErrorOutlineOutlinedIcon className={colorClass} />;
    case 'CREATED':
      return <ErrorOutlineOutlinedIcon className={colorClass} />;
    case 'CANCELLED':
      return <ErrorOutlineOutlinedIcon className={colorClass} />;
    case 'COMPLETED':
      return <CheckCircleOutlineOutlinedIcon className={colorClass} />;
    case 'DELIVERED':
      return <CheckCircleOutlineOutlinedIcon className={colorClass} />;
    case 'COMPLIANCE FAILURE':
      return <StyledWarningIcon className={colorClass} />;

    default:
      return <InfoOutlinedIcon />;
  }
}

ShipmentStatusIcon.propTypes = {
  status: PropTypes.string.isRequired,
  colorClass: PropTypes.string,
};
ShipmentStatusIcon.defaultProps = {
  colorClass: '',
};

export function BinStatusIcon(props) {
  const { status, colorClass } = props;
  switch (status?.toUpperCase()) {
    case 'OPEN':
      return <div className={colorClass} style={{ background: 'blue' }} />;
    case 'IN-USE':
      return <div className={colorClass} style={{ background: 'yellow' }} />;
    case 'READY':
      return <div className={colorClass} style={{ background: 'lightgreen' }} />;
    default:
      return <div className={colorClass} />;
  }
}
BinStatusIcon.propTypes = {
  status: PropTypes.string.isRequired,
  colorClass: PropTypes.string,
};
BinStatusIcon.defaultProps = {
  colorClass: '',
};
function displayTracking(status) {
  return !(/compliance failure/i.test(status));
}

export default function BinRow(props) {
  const {
    bin, openSalesOrderModal, lastCardRef, isCheck, handleCloseClick, handleSelectClick, handleResetClick,
  } = props;
  const classes = useStyles();
  const {
    collaborators, binId, binName, status, location, company, zipCode, salesOrders,
    recipient, iconType,
    carrier, amount, currencyCode,
    orderId, iot, iotTrackingNumber, dueDate,
  } = bin;

  const [close, setClose] = React.useState(null);
  const [reset, setReset] = React.useState(null);

  const [error, setError] = React.useState(null);
  const confirmCloseOpen = close === 'confirm';
  const confirmResetOpen = reset === 'confirm';

  // Adds dynamic css for class heading
  const statusColorClass = 'onTimeHeading';
  const statusHeadingClass = [
    classes.shipmentInformation,
    classes.boldHeading,
    classes[statusColorClass.statusClass],
  ].join(' ');
  const recipientHeadingClass = [
    classes.shipmentInformation,
    classes.boldHeading,
  ].join(' ');

  const mainTrackingNumber = 'dummy';
  const viewButtonLabel = 'close';
  const resetButtonLabel = 'Reset';

  const handleCloseButtonClick = () => {
    handleCloseClick(binName);
    setClose(null);
  };
  const handleResetButtonClick = () => {
    handleResetClick(binName);
    setReset(null);
  };
  const getDueDate = () => {
    if (dueDate) {
      return moment(dueDate).format('MM/DD/YYYY');
    }
    return moment().format('MM/DD/YYYY');
  };
  return (
    <Grid
      container
      className={classes.tile}
      justify="space-between"
      ref={lastCardRef || null}
      data-testid="binrow"
    >
      <Grid item xs={1} className={classes.shipmentCardColumn}>
        <Grid
          justify="space-around"
          direction="row"
          container
          classname={classes.centeredContainer}
        >
          <Grid item>
            <Checkbox
              id={binName}
              color="secondary"
              disabled={
                 status?.toUpperCase() === 'OPEN'
                 || status.toUpperCase() === 'READY'
                 || !(salesOrders[0].isCloseBin)
                // status?.toUpperCase() === 'OPEN'
                // || status.toUpperCase() === 'READY'
                // || moment(getDueDate()).isAfter(moment().format('MM/DD/YYYY'))
              }
              className={classes.checkbox}
              onChange={handleSelectClick}
              checked={isCheck.includes(binName)}
            />
          </Grid>
          <Grid item>
            <BinStatusIcon status={status} colorClass={classes.round} />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={6}>
        <Grid container style={{ height: '100%' }}>
          <Grid item xs={1} className={classes.shipmentCardColumn}>
            <Grid item>
              <Typography
                className={`${classes.shipmentHeading} ${classes.shipmentInfoHeader}`}
              >
                STATUS
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={recipientHeadingClass}>
                {status}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={3} className={classes.shipmentCardColumn}>
            <Grid item>
              <Typography
                className={`${classes.shipmentHeading} ${classes.shipmentInfoHeader}`}
              >
                LOCATION/PLANT
              </Typography>
            </Grid>
            <Grid container item>
              <Typography className={classes.shipmentInformation}>
                {location}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={1} className={classes.shipmentCardColumn}>
            <Grid item>
              <Typography
                className={`${classes.shipmentHeading} ${classes.shipmentInfoHeader}`}
              >
                BIN#
              </Typography>
            </Grid>
            <Grid item>
              {salesOrders?.length ? (
                <Typography className={recipientHeadingClass}>
                  <MuiLink
                    component="button"
                    variant="body2"
                    style={{ color: colors.secondaryButton }}
                    onClick={(e) => {
                      openSalesOrderModal({
                        binId: binName,
                        salesOrders,
                        anchorEl: e.currentTarget,
                      });
                    }}
                  >
                    {binName}
                  </MuiLink>
                </Typography>
              ) : (
                <Typography className={recipientHeadingClass}>
                  {binName}
                  {' '}
                </Typography>
              )}
            </Grid>
          </Grid>
          <Grid item xs={2} className={classes.shipmentCardColumn}>
            <Grid item>
              <Typography
                className={`${classes.shipmentHeading} ${classes.shipmentInfoHeader}`}
              >
                CARRIER
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={recipientHeadingClass}>
                {carrier}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={2} className={classes.shipmentCardColumn}>
            <Grid item>
              <Typography
                className={`${classes.shipmentHeading} ${classes.shipmentInfoHeader}`}
              >
                DUE DATE
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={recipientHeadingClass}>
                {dueDate}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={3} className={classes.shipmentCardColumn}>
            <Grid item>
              <Typography
                className={`${classes.shipmentHeading} ${classes.shipmentInfoHeader}`}
              >
                COMPANY
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={recipientHeadingClass}>
                {company}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={5}>
        <Grid container style={{ height: '100%' }}>
          <Grid item xs={2} className={classes.shipmentCardColumn}>
            <Grid item>
              <Typography
                className={`${classes.shipmentHeading} ${classes.shipmentInfoHeader}`}
              >
                ZIPCODE
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                className={`${classes.shipmentInformation} ${classes.allCapsWide}`}
              >
                {zipCode}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={3} className={classes.shipmentCardColumn}>
            <Grid item>
              <Typography
                className={`${classes.shipmentHeading} ${classes.shipmentInfoHeader}`}
              >
                CONSOLIDATED
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                className={`${classes.shipmentInformation} ${classes.allCapsWide}`}
              >
                {has(salesOrders[0], 'isConsolidated') ? salesOrders[0].isConsolidated ? 'Yes' : 'No' : '-'}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={3} className={classes.shipmentCardColumn}>
            <Grid item>
              <Typography
                className={`${classes.shipmentHeading} ${classes.shipmentInfoHeader}`}
              >
                COMPLETED
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                className={`${classes.shipmentInformation} ${classes.allCapsWide}`}
              >
                {has(salesOrders[0], 'isConsolidatedComplete') ? salesOrders[0].isConsolidatedComplete ? 'Yes' : 'No' : '-'}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={2}
            className={`${classes.buttonDiv} ${classes.shipmentCardButton}`}
            spacing={1}
          >
            <Grid item xs={12}>
              <CloseBinButton
                label={viewButtonLabel}
                binId={binId}
                binStatus={status}
                handleCloseClick={() => setClose('confirm')}
                getDueDate={getDueDate}
                isClose={salesOrders[0]?.isCloseBin}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={2}
            className={`${classes.buttonDiv} ${classes.shipmentCardButton}`}
            spacing={1}
          >
            <Grid item xs={12}>
              <ResetBinButton
                label={resetButtonLabel}
                binId={binId}
                binStatus={status}
                handleResetClick={() => setReset('confirm')}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <ConfirmationModal
        open={confirmCloseOpen}
        onProceed={() => handleCloseButtonClick(binId)}
        onCancel={() => setClose(null)}
        proceedLabel="Yes, Close"
        message={(
          <span>
            Are you sure you would like to close the bin
            {' '}
            {`${binName}?`}
          </span>
        )}
      />
      <ConfirmationModal
        open={confirmResetOpen}
        onProceed={() => handleResetButtonClick(binId)}
        onCancel={() => setReset(null)}
        proceedLabel="Yes, Reset"
        message={(
          <span>
            This action will remove all sales order from the bin and set the bin
            back to open state. Are you sure you would like to reset the bin
            {' '}
            {`${binName}?`}
          </span>
        )}
      />
      <ErrorDialog
        open={!!error}
        onClose={() => setError(null)}
        errorContent={error}
      />
    </Grid>
  );
}

BinRow.propTypes = {
  bin: PropTypes.shape({
    binStatus: PropTypes.string,
    binId: PropTypes.string,
    recipient: PropTypes.string,
    carrier: PropTypes.string,
    updated: PropTypes.string,
    assignedTo: PropTypes.string,
    taskType: PropTypes.string,
    expectedDeliveryDateTime: PropTypes.string,
    actualDeliveryDateTime: PropTypes.string,
    actualShipDateTime: PropTypes.string,
    expectedPickupDateTime: PropTypes.string,
    iot: PropTypes.bool,
    iotTrackingNumber: PropTypes.string,
    orderId: PropTypes.string,
    iconType: PropTypes.string,
    currencyCode: PropTypes.string,
    amount: PropTypes.string,
    collaborators: PropTypes.arrayOf(
      PropTypes.shape({
        imageUrl: PropTypes.string.isRequired,
        alt: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ),
    isPendingTask: PropTypes.bool,
  }).isRequired,
  lastCardRef: PropTypes.objectOf(PropTypes.any),
  isCheck: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleSelectClick: PropTypes.func.isRequired,
  handleResetClick: PropTypes.func.isRequired,
};

BinRow.defaultProps = {
  lastCardRef: null,
};
