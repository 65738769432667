/* eslint-disable no-prototype-builtins */
import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import * as _ from 'lodash';
import NewOrderNextButtonClear from '../common/NewOrderNextButtonClear';
import { useSingleOrderState } from '../../context/singleOrderContext';
import DocumentPreview from '../common/DocumentPreview';
import {
  useMiscState, useMiscDispatch, loadLicensePermitTypes,
} from '../../context/miscDataContext';
import { useItemProductDetailsFormStyles } from './itemDetailsStyles';
import {
  sanitizeItemObject,
  buildItemSchema,
  prepareItemPayload,
  isCargoShipment,
  removeNullValues,
  isBatteryContainedOrPacked,
  isLithiumBatteryObjectEmpty,
  omitLithiumBatteryFields,
  getLithiumBatterySumWeight,
} from './itemDetailsUtil';
import {
  FORM_FIELDS,
  EMPTY_ITEM_OBJECT,
  L,
  ML,
  ITEM_ADD,
  ITEM_UPDATE,
  FORM_FIELDS_HAZMAT,
  EMPTY_CHEMICAL_ITEM_OBJECT,
  FORM_FIELDS_CHEMICAL_HAZMAT,
  TOTAL_VALUE_KEY,
  TRANSPORTATION_OPTIONS_NAME_KEY,
  ITEM_WEIGHT_KEY,
  INNER_WEIGHT_KEY,
  EMPTY_BATTERY_ITEM_OBJECT,
  FORM_FIELDS_BATTERIES,
  LITHIUM_BATTERY_CONTAINED_KEY,
  FORM_FIELDS_LITHIUM_BATTERY,
  LITHIUM_BATTERY_PACKED_KEY,
  FREIGHT_TYPES,
  EMPTY_LITHIUM_BATTERY_CONTAINED_OR_PACKED_ITEM_OBJECT,
  TRANSPORTATION_OPTION_PASSENGER_CARGO_VALUE,
} from './itemDetailsConstants';
import {
  useMaterialsState,
} from '../../context/materialsContext';
import CargoDialog from './CargoDialog';
import Product from './Item/Product';
import ProductDimensions from './Item/Dimensions';
import ProductWeight from './Item/Weight';
import ProductMeasurements from './Item/Measurements';
import ProductHazmatItem from './Item/Hazmat';
import Calculated from './Item/Calculated';
import Description from './Item/Description';
import Codes from './Item/Codes';
import LicenseDetermination from './Item/License/Determination';
import CountryOfManufacture from './Item/CountryOfManufacture';
import TariffCode from './Item/TariffCode';
import TaxCode from './Item/TaxCode';
import FreightOnly from './Item/FreightOnly';
import {
  HAZMAT_BIOLOGICAL, HAZMAT_CHEMICAL, HAZMAT_BATTERIES, HAZMAT_BATTERIES_CONTAINED_OR_PACKED, HAZMAT_TOXINS,
} from '../../clientConstants';
import ChemicalTable from './ChemicalTable';
import ShippingLithiumBatteries from './Item/Product/ShippingLithiumBaterries';
import HazmatBatteriesContained from './Item/Hazmat/BatteriesContainedOrPacked/BatteriesContained';
import BiologicalTable from './BiologicalTable';
import ExportControl from './Item/ExportControl';
import Preservatives from './Item/Preservatives';

export default function ItemForm({
  submitFormToApi, shipmentId, showItemForm, setShowItemForm,
  itemToEdit, setItemToEdit, setIsLoading, setShowConfirmDialog,
  hiddenFields, training, setError, account,
}) {
  const classes = useItemProductDetailsFormStyles();
  const {
    productCategories,
    dimensionUnits,
    weightUnits,
    smallWeightUnits,
    productUnits,
    currencyUnits,
    freightClasses,
    hazmatWeightUnits,
    hazmatSmallWeightUnits,
    preservatives,
  } = useMaterialsState();
  const miscDispatch = useMiscDispatch();

  const [triggerResetForm, setTriggerResetForm] = React.useState(false);
  const [cargo, setCargo] = React.useState({
    open: false,
    classType: '',
    maxWeightPassenger: '',
    isCargo: false,
  });
  const { licensePermitTypes, countries } = useMiscState();
  const orderState = useSingleOrderState();

  const [editableFields, setEditableFields] = React.useState([]);
  const [customImage, setCustomImage] = React.useState(null);
  const [itemFromCatalog, setItemFromCatalog] = React.useState(null);
  const [isCustomItem, setIsCustomItem] = React.useState(false);
  const [preview, setPreview] = React.useState(null);
  const [isBatteryContained, setIsBatteryContained] = useState(false);
  const [showBatteryPackedForm, setShowBatteryPackedForm] = useState(false);
  const { shippingType, freightType } = orderState.order;
  const shipment = orderState.shipments[shipmentId];
  const items = shipment?.product?.details?.items;
  const isChemicalHazmat = orderState.order.hazmatOption === HAZMAT_CHEMICAL || orderState.order.hazmatOption === HAZMAT_TOXINS;
  const isBattery = orderState.order.hazmatOption === HAZMAT_BATTERIES;
  const isBiologicalHazmat = orderState.order.hazmatOption === HAZMAT_BIOLOGICAL;

  React.useEffect(() => {
    loadLicensePermitTypes(miscDispatch);
  }, [miscDispatch]);

  React.useEffect(() => {
    if (itemToEdit) {
      setItemFromCatalog(null);
      setShowItemForm(true);
      setCustomImage(null);
      setItemFromCatalog(itemToEdit);
      setIsCustomItem(false);
      setTriggerResetForm(true);
    }
  }, [itemToEdit]);

  const getEmptyInitialValues = () => {
    if (isChemicalHazmat) {
      return EMPTY_CHEMICAL_ITEM_OBJECT;
    }
    if (isBattery) {
      return EMPTY_BATTERY_ITEM_OBJECT;
    }

    if (freightType === FREIGHT_TYPES.product || orderState.order.hazmatOption === HAZMAT_BATTERIES_CONTAINED_OR_PACKED) {
      return {
        ...EMPTY_ITEM_OBJECT,
        [LITHIUM_BATTERY_CONTAINED_KEY]: EMPTY_LITHIUM_BATTERY_CONTAINED_OR_PACKED_ITEM_OBJECT,
        [LITHIUM_BATTERY_PACKED_KEY]: EMPTY_LITHIUM_BATTERY_CONTAINED_OR_PACKED_ITEM_OBJECT,
      };
    }

    return EMPTY_ITEM_OBJECT;
  };

  const getInitialValues = () => {
    const initialValues = getEmptyInitialValues();

    if (!isChemicalHazmat && !isBattery && !isBatteryContained) {
      initialValues.shippingType = shippingType;
      initialValues.itemDimensions.unitOfMeasurement = dimensionUnits[0].dimensionCode;
      initialValues.itemWeight.unitOfMeasurement = shipment?.carrier?.details.hasOwnProperty('ipd')
        ? weightUnits[1].weightCode
        : weightUnits[0].weightCode;
      initialValues.itemWeight.unitOfMeasurementSmall = shipment?.carrier?.details.hasOwnProperty('ipd')
        ? smallWeightUnits[1].weightCode
        : smallWeightUnits[0].weightCode;
      initialValues.units.unitValue.currency = currencyUnits[0].currencyCode;
      initialValues.totalWeight.unitOfMeasurement = shipment?.carrier?.details.hasOwnProperty('ipd')
        ? weightUnits[1].weightCode
        : weightUnits[0].weightCode;
      initialValues.totalWeight.unitOfMeasurementSmall = shipment?.carrier?.details.hasOwnProperty('ipd')
        ? smallWeightUnits[1].weightCode
        : smallWeightUnits[0].weightCode;
      initialValues.totalValue.currency = currencyUnits[0].currencyCode;

      if (hiddenFields.includes('material')) {
        initialValues.shipClass = '';
        initialValues.itemWeight.unitOfMeasurement = L;
        initialValues.itemWeight.unitOfMeasurementSmall = ML;
        initialValues.totalWeight.unitOfMeasurement = L;
        initialValues.totalWeight.unitOfMeasurementSmall = ML;
        initialValues.units.noOfUnits = '1';
        initialValues.requiredLicenses = '';
        initialValues.aphisImportTransPermit = false;
        initialValues.cdcSelectAgent = false;
        initialValues.doaSelectAgent = false;
        initialValues.ppqSelectAgent = false;
        initialValues.usdaImportTransPermit = false;
        initialValues.isPreservative = false;
        initialValues.preservative = '';
        initialValues.preservativeWeight = {
          weight: '',
          unitOfMeasurement: '',
          weightSmall: '',
          unitOfMeasurementSmall: '',
        };
        initialValues.preservativeWeight.unitOfMeasurement = ML;
        initialValues.preservativeOuterWeight = {
          weight: '',
          unitOfMeasurement: '',
          weightSmall: '',
          unitOfMeasurementSmall: '',
        };
        initialValues.preservativeOuterWeight.unitOfMeasurement = ML;
        initialValues.iataDetails = {
          properShippingName: '',
          unNo: '',
          class: '',
          packagingInstructions: '',
          documentURL: '',
          maxWeight: {
            liters: '',
            kilograms: '',
            grams: '',
            milliliters: '',
          },
          maxWeightPR: {
            liters: '',
            kilograms: '',
            grams: '',
            milliliters: '',
          },
          maxWeightCargo: {
            liters: '',
            kilograms: '',
            grams: '',
            milliliters: '',
          },
          maxWeightPassenger: {
            liters: '',
            kilograms: '',
            grams: '',
            milliliters: '',
          },
        };
        const doesShipmentHasItems = items && items.length >= 1;
        const transportationMode = doesShipmentHasItems ? items[0][TRANSPORTATION_OPTIONS_NAME_KEY] : TRANSPORTATION_OPTION_PASSENGER_CARGO_VALUE;
        initialValues[TRANSPORTATION_OPTIONS_NAME_KEY] = transportationMode;
        initialValues.disableTransportationOptions = !!doesShipmentHasItems;
      }
    } else if (isChemicalHazmat) {
      const doesShipmentHasItems = items && items.length >= 1;
      const transportationMode = doesShipmentHasItems ? items[0][TRANSPORTATION_OPTIONS_NAME_KEY] : TRANSPORTATION_OPTION_PASSENGER_CARGO_VALUE;
      initialValues[TRANSPORTATION_OPTIONS_NAME_KEY] = transportationMode;
      initialValues.disableTransportationOptions = !!doesShipmentHasItems;
    }

    return initialValues;
  };

  const triggerReset = (formikProps) => {
    formikProps.resetForm();
    setTriggerResetForm(false);
  };

  const onSubmit = async (values, { setSubmitting, resetForm }, hideForm = true) => {
    setIsLoading(true);
    let itemObject = sanitizeItemObject(values);
    let action = ITEM_ADD;

    if (hiddenFields.includes('international')) {
      delete itemObject[FORM_FIELDS.harmonizedCode.key];
      delete itemObject[FORM_FIELDS.eccnNo.key];
      delete itemObject[FORM_FIELDS.countryOfManufacture.key];

      if (hiddenFields.includes('dutiesAndTaxes')) {
        delete itemObject[FORM_FIELDS.tariffCode.key];
        delete itemObject[FORM_FIELDS.taxCode.key];
      }
    }

    if (hiddenFields.includes('licenseDetermination')) {
      delete itemObject.isLicensePermitRequired;
      delete itemObject[FORM_FIELDS.licenseType.key];
      delete itemObject[FORM_FIELDS.licenseNumber.key];
    } else if (itemObject.isLicensePermitRequired !== 'yes') {
      delete itemObject[FORM_FIELDS.licenseType.key];
      delete itemObject[FORM_FIELDS.licenseNumber.key];
    }

    if (hiddenFields.includes('freightOnly')) {
      delete itemObject[FORM_FIELDS.freightClass.key];
      delete itemObject[FORM_FIELDS.nmfcCodeNumber.key];
    }
    if (hiddenFields.includes('material')) {
      delete itemObject?.iataDetails?.maxQuantity;
      delete itemObject?.iataDetails?.maxQuantityPR;
      delete itemObject?.iataDetails?.maxQuantityCargo;
      delete itemObject?.iataDetails?.maxQuantityPassenger;
      delete itemObject[FORM_FIELDS_HAZMAT.requiredLicenses.key];
      if (_.has(itemObject, 'disableTransportationOptions')) delete itemObject.disableTransportationOptions;
    }

    if (isChemicalHazmat) {
      delete itemObject[FORM_FIELDS_CHEMICAL_HAZMAT.cargo.key];
      delete itemObject[FORM_FIELDS_CHEMICAL_HAZMAT.excepted.key];
      delete itemObject[FORM_FIELDS_CHEMICAL_HAZMAT.limited.key];
      delete itemObject[FORM_FIELDS_CHEMICAL_HAZMAT.passengerCargo.key];
      delete itemObject[FORM_FIELDS_CHEMICAL_HAZMAT.technicalNameRequired.key];
      if (_.has(itemObject, 'disableTransportationOptions')) delete itemObject.disableTransportationOptions;
      if (_.has(itemObject, ITEM_WEIGHT_KEY)) itemObject[ITEM_WEIGHT_KEY] = removeNullValues(itemObject[ITEM_WEIGHT_KEY]);
      if (_.has(itemObject, INNER_WEIGHT_KEY)) itemObject[INNER_WEIGHT_KEY] = removeNullValues(itemObject[INNER_WEIGHT_KEY]);
      if (_.has(itemObject, TOTAL_VALUE_KEY)) itemObject[TOTAL_VALUE_KEY] = removeNullValues(itemObject[TOTAL_VALUE_KEY]);

      itemObject.units = {
        unitValue: itemObject[TOTAL_VALUE_KEY],
        noOfUnits: '1',
        unitOfMeasurement: 'bundle',
      };

      itemObject.totalWeightKG = '';
      itemObject.totalWeightLB = '';
    }
    if (isBattery) {
      delete itemObject[FORM_FIELDS_BATTERIES.cargo.key];
      delete itemObject[FORM_FIELDS_BATTERIES.passengerCargo.key];
      delete itemObject[FORM_FIELDS_BATTERIES.batteryCapacity.key];
      delete itemObject[FORM_FIELDS_BATTERIES.noOfUnits.key];

      if (_.has(itemObject, 'disableTransportationOptions')) { delete itemObject.disableTransportationOptions; }
      if (_.has(itemObject, ITEM_WEIGHT_KEY)) {
        itemObject[ITEM_WEIGHT_KEY] = removeNullValues(
          itemObject[ITEM_WEIGHT_KEY],
        );
      }
      if (_.has(itemObject, INNER_WEIGHT_KEY)) {
        itemObject[INNER_WEIGHT_KEY] = removeNullValues(
          itemObject[INNER_WEIGHT_KEY],
        );
      }
      if (_.has(itemObject, TOTAL_VALUE_KEY)) {
        itemObject[TOTAL_VALUE_KEY] = removeNullValues(
          itemObject[TOTAL_VALUE_KEY],
        );
      }

      itemObject.units = {
        unitValue: itemObject[TOTAL_VALUE_KEY],
        noOfUnits: itemObject.units.noOfUnits,
        unitOfMeasurement: 'bundle',
      };

      itemObject.totalWeightKG = '';
      itemObject.totalWeightLB = '';
    }

    if (isBatteryContainedOrPacked(itemObject)) {
      if (_.has(itemObject, LITHIUM_BATTERY_CONTAINED_KEY) && !isLithiumBatteryObjectEmpty(LITHIUM_BATTERY_CONTAINED_KEY, itemObject)) {
        itemObject = {
          ...itemObject,
          [LITHIUM_BATTERY_CONTAINED_KEY]: {
            [TRANSPORTATION_OPTIONS_NAME_KEY]: itemObject[TRANSPORTATION_OPTIONS_NAME_KEY],
            ...itemObject[LITHIUM_BATTERY_CONTAINED_KEY],
          },
        };
      }

      if (_.has(itemObject, LITHIUM_BATTERY_PACKED_KEY) && !isLithiumBatteryObjectEmpty(LITHIUM_BATTERY_PACKED_KEY, itemObject)) {
        const containedUnits = parseInt(itemObject[LITHIUM_BATTERY_CONTAINED_KEY][FORM_FIELDS_LITHIUM_BATTERY.units.key], 10);
        const packedUnits = parseInt(itemObject[LITHIUM_BATTERY_PACKED_KEY][FORM_FIELDS_LITHIUM_BATTERY.units.key], 10);

        itemObject = {
          ...itemObject,
          [LITHIUM_BATTERY_CONTAINED_KEY]: {
            ...itemObject[LITHIUM_BATTERY_CONTAINED_KEY],
            ...itemObject[LITHIUM_BATTERY_PACKED_KEY],
            [FORM_FIELDS_LITHIUM_BATTERY.weight.key]: getLithiumBatterySumWeight(itemObject).toString(),
            [FORM_FIELDS_LITHIUM_BATTERY.units.key]: (containedUnits + packedUnits).toString(),
          },
        };

        delete itemObject[LITHIUM_BATTERY_PACKED_KEY];
      }

      if (!isLithiumBatteryObjectEmpty(LITHIUM_BATTERY_CONTAINED_KEY, itemObject)) {
        itemObject[LITHIUM_BATTERY_CONTAINED_KEY] = omitLithiumBatteryFields(itemObject[LITHIUM_BATTERY_CONTAINED_KEY]);
      }

      delete itemObject[TRANSPORTATION_OPTIONS_NAME_KEY];
    }

    const newItems = items && items.length > 0 ? [...items] : [];

    if (itemToEdit) _.remove(newItems, (item) => item.itemId === itemToEdit.itemId);

    const payload = prepareItemPayload(itemObject, newItems);

    try {
      await submitFormToApi(payload, true);

      if (itemToEdit) {
        action = ITEM_UPDATE;
        setItemToEdit(null);
      }

      resetForm();
      setEditableFields([]);
      setCustomImage(null);
      setItemFromCatalog(null);
      setIsCustomItem(false);
      setShowConfirmDialog({
        action,
        show: true,
      });

      if (hideForm) setShowItemForm(false);
    } catch (e) {
      //
      console.log(e);
    } finally {
      setSubmitting(false);
      setIsLoading(false);
    }
  };

  function openDocumentPreview(document) {
    setPreview(document);
  }

  const handleCancel = (resetForm) => {
    setItemToEdit(null);
    resetForm();
    setEditableFields([]);
    setCustomImage(null);
    setItemFromCatalog(null);
    setIsCustomItem(false);
    setShowItemForm(false);
  };

  const handleCloseCargoDialog = (formikProps, isCargo) => {
    setCargo({
      open: false,
      isCargo,
    });

    if (isCargo) onSubmit({ ...formikProps.values, isCargo }, formikProps);
  };

  const getProductHazmat = (formikProps) => (
    <ProductHazmatItem
      formikProps={formikProps}
      freightType={orderState.order.freightType}
      hazmatOption={orderState.order.hazmatOption}
      hazmatClasses={orderState.order.hazmatType}
      training={training}
      itemToEdit={itemToEdit}
      itemFromCatalog={itemFromCatalog}
      editableFields={editableFields}
      hiddenFields={hiddenFields}
      items={items}
      currencyUnits={currencyUnits}
      isChemicalHazmat={isChemicalHazmat}
      setEditableFields={setEditableFields}
      setError={setError}
      openDocumentPreview={openDocumentPreview}
      isBattery={isBattery}
      isBatteryContained={isBatteryContained}
      setIsBatteryContained={setIsBatteryContained}
      showBatteryPackedForm={showBatteryPackedForm}
    />
  );

  const getProduct = (formikProps) => {
    if (hiddenFields.includes('material')) return getProductHazmat(formikProps);

    return (
      <Product
        formikProps={formikProps}
        setEditableFields={setEditableFields}
        editableFields={editableFields}
        productCategories={productCategories}
        customImage={customImage}
        setCustomImage={setCustomImage}
        itemFromCatalog={itemFromCatalog}
        setItemFromCatalog={setItemFromCatalog}
        isCustomItem={isCustomItem}
        setIsCustomItem={setIsCustomItem}
        itemToEdit={itemToEdit}
        isBatteryContained={isBatteryContained}
        setIsBatteryContained={setIsBatteryContained}
        account={account}
      />
    );
  };

  return (
    <>
      {isChemicalHazmat && !_.isEmpty(items) && showItemForm && (
        <ChemicalTable items={items} />
      )}
      {isBiologicalHazmat && !_.isEmpty(items) && showItemForm && (
        <BiologicalTable items={items} />
      )}

      <Formik
        initialValues={getInitialValues()}
        validationSchema={buildItemSchema(
          freightType,
          hiddenFields,
          isChemicalHazmat,
          isBiologicalHazmat,
          isBattery,
          isBatteryContained,
        )}
        onSubmit={(values, { setSubmitting, resetForm }, hideForm) => {
          const iataDetails = values[FORM_FIELDS_HAZMAT.iataDetails.key];
          const weight = _.get(values, FORM_FIELDS_HAZMAT.weight.key, 0);
          const smallWeight = _.get(
            values,
            FORM_FIELDS_HAZMAT.smallWeight.key,
            0,
          );
          const unit = _.get(values, FORM_FIELDS_HAZMAT.weightUnit.key, '');
          const isBatteryWithEquipment = isBatteryContainedOrPacked(values);
          const isCargo = !isChemicalHazmat && !isBattery && !isBatteryWithEquipment
            ? isCargoShipment(iataDetails, weight, smallWeight, unit)
            : false;

          // if (hiddenFields.includes('material') && isCargo) {
          //   setCargo({
          //     open: isCargo,
          //     classType: values[FORM_FIELDS_HAZMAT.itemCategory.key],
          //     maxWeightPassenger: iataDetails.maxWeightPassenger.liters,
          //     isCargo,
          //   });
          // } else {
          onSubmit(values, { setSubmitting, resetForm }, hideForm);
          // }
        }}
      >
        {(formikProps) => (
          <>
            {triggerResetForm && triggerReset(formikProps)}
            {getProduct(formikProps)}
            {!isChemicalHazmat && !isBattery && (
              <ProductWeight
                formikProps={formikProps}
                editableFields={editableFields}
                weightUnits={
                  hiddenFields.includes('material')
                    ? hazmatWeightUnits
                    : weightUnits
                }
                smallWeightUnits={
                  hiddenFields.includes('material')
                    ? hazmatSmallWeightUnits
                    : smallWeightUnits
                }
                weight={FORM_FIELDS.weight}
                weightUnit={FORM_FIELDS.weightUnit}
                smallWeight={FORM_FIELDS.smallWeight}
                smallWeightUnit={FORM_FIELDS.smallWeightUnit}
              />
            )}
            {!isChemicalHazmat && !isBattery && (
              <ProductMeasurements
                formikProps={formikProps}
                editableFields={editableFields}
                productUnits={productUnits}
                currencyUnits={currencyUnits}
                hiddenFields={hiddenFields}
              />
            )}
            {!hiddenFields.includes('material') && (
              <ProductDimensions
                formikProps={formikProps}
                editableFields={editableFields}
                dimensionUnits={dimensionUnits}
              />
            )}
            {!isChemicalHazmat && !isBattery && (
              <Calculated
                formikProps={formikProps}
                currencyUnits={currencyUnits}
                weightUnits={
                  hiddenFields.includes('material')
                    ? hazmatWeightUnits
                    : weightUnits
                }
                smallWeightUnits={
                  hiddenFields.includes('material')
                    ? hazmatSmallWeightUnits
                    : smallWeightUnits
                }
              />
            )}
            {!isChemicalHazmat && !isBattery && (
              <Description editableFields={editableFields} />
            )}
            {isBiologicalHazmat && (
              <Preservatives
                editableFields={editableFields}
                formikProps={formikProps}
                preservativeTypes={preservatives}
                weightUnits={
                hiddenFields.includes('material')
                  ? hazmatSmallWeightUnits
                  : smallWeightUnits
              }
              />
            )}
            {!hiddenFields.includes('international')
              && !hiddenFields.includes('material') && (
                <Codes
                  hiddenFields={hiddenFields}
                  editableFields={editableFields}
                />
            )}

            {!hiddenFields.includes('licenseDetermination') && (
              <LicenseDetermination
                classes={classes}
                formikProps={formikProps}
                editableFields={editableFields}
                licensePermitTypes={licensePermitTypes}
              />
            )}
            {!hiddenFields.includes('international') && account === 'nwestern' && (
            <ExportControl
              formikProps={formikProps}
              account={account}
            />
            )}

            {!hiddenFields.includes('international') && (
              <CountryOfManufacture
                formikProps={formikProps}
                editableFields={editableFields}
                countries={countries}
              />
            )}

            {!hiddenFields.includes('freightOnly') && (
              <FreightOnly
                editableFields={editableFields}
                freightClasses={freightClasses}
              />
            )}
            {!hiddenFields.includes('international')
              && !hiddenFields.includes('dutiesAndTaxes') && (
                <Grid item container spacing={2}>
                  <Grid item>
                    <TariffCode editableFields={editableFields} isRequired />
                  </Grid>

                  <Grid item>
                    <TaxCode editableFields={editableFields} isRequired />
                  </Grid>
                </Grid>
            )}
            {/* Show battery contained */}
            {isBatteryContained && (
              <HazmatBatteriesContained
                formikProps={formikProps}
                editableFields={editableFields}
              />
            )}
            {account !== 'worldemblem'
            && (
            <ShippingLithiumBatteries
              formikProps={formikProps}
              hiddenFields={hiddenFields}
              isBatteryContained={isBatteryContained}
              setIsBatteryContained={setIsBatteryContained}
              setShowBatteryPackedForm={setShowBatteryPackedForm}
            />
            )}
            {/* Show battery packed */}
            {showBatteryPackedForm && getProductHazmat(formikProps)}
            <Grid
              className={classes.buttonContainer}
              justifyContent={isChemicalHazmat || isBiologicalHazmat ? 'space-between' : 'flex-start'}
              spacing={2}
              container
              item
            >
              <Grid item>
                <NewOrderNextButtonClear onClick={formikProps.submitForm}>
                  {itemToEdit ? 'Update Item' : 'Save item'}
                </NewOrderNextButtonClear>
              </Grid>
              {(itemToEdit || isChemicalHazmat || isBiologicalHazmat) && (
                <Grid item>
                  <NewOrderNextButtonClear
                    onClick={() => handleCancel(formikProps.resetForm)}
                  >
                    Cancel
                  </NewOrderNextButtonClear>
                </Grid>
              )}
            </Grid>
            <DocumentPreview
              open={!!preview}
              onClose={() => setPreview(null)}
              doc={preview}
            />
            {/* <CargoDialog
              open={cargo.open}
              classType={cargo.classType}
              maxWeightPassenger={cargo.maxWeightPassenger}
              onContinue={() => {
                handleCloseCargoDialog(formikProps, true);
              }}
              onCancel={() => {
                handleCloseCargoDialog(formikProps, false);
              }}
            /> */}
          </>
        )}
      </Formik>
    </>
  );
}

ItemForm.propTypes = {
  shipmentId: PropTypes.string.isRequired,
  submitFormToApi: PropTypes.func.isRequired,
  showItemForm: PropTypes.bool.isRequired,
  setShowItemForm: PropTypes.func.isRequired,
  itemToEdit: PropTypes.objectOf(PropTypes.any),
  setItemToEdit: PropTypes.func.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  setShowConfirmDialog: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  training: PropTypes.shape(PropTypes.object),
  hiddenFields: PropTypes.arrayOf(PropTypes.string).isRequired,
  account: PropTypes.string.isRequired,
};

ItemForm.defaultProps = {
  itemToEdit: null,
  training: null,
};
