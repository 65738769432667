import React from 'react';
import { Typography } from '@material-ui/core';
import shortid from 'shortid';

import {
  PAYMENT_CREDITCARD_VALUE,
  PAYMENT_PAYPAL_VALUE,
  PAYMENT_ACH_VALUE,
  PAYMENT_TYPE,
  PAYMENT_OPTIONS,
  PAYMENT_OPTIONS_NATIONWIDE,
  CREDIT_CARD_FIELDS,
  PAYMENT_COSTCODES_VALUE,
  COMPONENT_ID,
  BILLING_OPTIONS,
  BILLING_BDCA_VALUE,
  BILLING_BOCA_VALUE,
  NEW_CARD_OPTION,
} from './constants';

export function isProviderPayment(billingDetails) {
  return (billingDetails && [
    PAYMENT_CREDITCARD_VALUE,
    PAYMENT_PAYPAL_VALUE,
    PAYMENT_ACH_VALUE].includes(billingDetails[PAYMENT_TYPE]));
}

export function isCreditCardPayment(details) {
  return (!!details && internalCheckType(details, PAYMENT_TYPE, PAYMENT_CREDITCARD_VALUE));
}

function internalCheckType(obj, type, val) {
  return obj[type] === val;
}

export function getPaymentOptions(accountName, accountType, orderPurpose, { paymentOptions }) {
  const options = accountName === 'nationwide' ? PAYMENT_OPTIONS_NATIONWIDE : PAYMENT_OPTIONS;
  return options
    .filter((option) => {
      const { type, purpose, accountFlag } = option;
      return (type.includes(accountType) && purpose.includes(orderPurpose)
          && (!paymentOptions || (paymentOptions && paymentOptions[accountFlag])));
    });
}

export function formatDefaultSender(defaultSender) {
  const defaultSenderBillingProps = CREDIT_CARD_FIELDS
    .reduce((acc, fieldName) => {
      if (!defaultSender[fieldName]) return acc;
      return { ...acc, [fieldName]: defaultSender[fieldName] };
    }, {});
  return defaultSenderBillingProps;
}

export function hasPaymentCostCodes(options) {
  return options.map((item) => item.value).includes(PAYMENT_COSTCODES_VALUE);
}

export function hasPaymentCreditCard(options) {
  return options.map((item) => item.value).includes(PAYMENT_CREDITCARD_VALUE);
}

export function hasPaymentPaypal(options) {
  return options.map((item) => item.value).includes(PAYMENT_PAYPAL_VALUE);
}

export function hasPaymentACH(options) {
  return options.map((item) => item.value).includes(PAYMENT_ACH_VALUE);
}

export function toggleStyleDisplay(isShown) {
  return (isShown) ? 'initial' : 'none';
}

export function getTypography(id, string, classes) {
  return <Typography id={`${COMPONENT_ID}-${id}`} className={classes}>{string}</Typography>;
}

export function getEmptyAllocation(billCodes) {
  const key = shortid.generate();
  return {
    allocation: '100',
    ...billCodes.reduce((acc, bc) => ({
      ...acc,
      ...(bc.billCode
        ? { [bc.code]: { code: bc.billCode.code, description: bc.billCode.description } }
        : { [bc.code]: {} }
      )
      ,
    }), {}),
    key,
  };
}

export function getBillingOptions(isCreatedBy, accountType, purpose) {
  if (!isCreatedBy) return BILLING_OPTIONS.filter((option) => option.value === BILLING_BDCA_VALUE);

  return BILLING_OPTIONS
    .filter((option) => option.type.includes(accountType) && option.purpose.includes(purpose));
}

export function hasBillOtherCarrier(options) {
  return (options.filter((option) => option.value === BILLING_BOCA_VALUE).length === 1);
}

export function getPaymentToken(billingDetails, defaultPaymentToken) {
  if (!billingDetails?.creditCardDetails) return defaultPaymentToken;

  const billingPaymentToken = billingDetails?.paymentToken;
  const paymentToken = billingDetails?.paymentTokenDetails?.paymentMethodToken;

  return paymentToken || billingPaymentToken || NEW_CARD_OPTION.value;
}
