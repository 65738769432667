import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Field, getIn } from 'formik';
import { Grid } from '@material-ui/core';
import CustomSelectTextField from '../../common/CustomSelectTextField';

function ConditionalField({
  currentPackage,
  providers,
  ReplacementComponent,
  ...props
}) {
  const cEval = !providers || providers.reduce((a, p) => {
    const pVal = getIn(currentPackage, p.name);
    const pEval = p.check
      ? p.check(pVal)
      : pVal;
    return a && pEval;
  }, true);

  return (
    <Grid>
      {cEval ? (
        <Field
          component={CustomSelectTextField}
          {...props}
        />
      ) : ReplacementComponent && (
      <Field
        {...props}
        component={ReplacementComponent}
      />
      )}
    </Grid>
  );
}

ConditionalField.propTypes = {
  currentPackage: PropTypes.objectOf(PropTypes.any).isRequired,
  providers: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    check: PropTypes.func,
  })),
  ReplacementComponent: PropTypes.node,
};

ConditionalField.defaultProps = {
  providers: [],
  ReplacementComponent: null,
};

export default memo(ConditionalField);
