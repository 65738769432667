import React from 'react';
import { Formik, Field } from 'formik';
import PropTypes from 'prop-types';
import {
  Dialog, DialogActions, Grid, IconButton, MenuItem, Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import ScrollWindow from './common/ScrollWindow';
import * as colors from '../styles/colors';
import CustomSelectTextField from './common/CustomSelectTextField';
import AssignmentComponent from './common/AssignmentComponent';
import NewOrderNextButton from './common/NewOrderNextButton';
import NewOrderNextButtonClear from './common/NewOrderNextButtonClear';
import { useContactsState } from '../context/contactsContext';
import { NEW_ORDER_MODULE_STYLE } from '../styles/style';
import { assignDocTask } from '../context/taskDataContext';
import { useSingleOrderDispatch } from '../context/singleOrderContext';

import {
  SELECT_FROM_CONTACTS_TAB_VALUE,
  ENTER_EMAIL_TAB_VALUE,
  getMinDueDate,
  formatExpiresAtDateTime,
  getAssignDocSchema,
  sanitizeAssignmentObject,
  internalOnSubmitSFCTab,
} from '../utils/assignmentUtil';
import NewOrderErrorDialog from './common/NewOrderErrorDialog';

const assignDocumentTaskStyles = {
  dialogContactContainer: {
    width: 700,
    height: '100%',
    overflow: 'hidden',
    background: 'transparent',
    maxHeight: 'calc(100% - 30px)',
  },
  dialogEmailContainer: {
    width: 700,
    height: 580,
    overflow: 'hidden',
    background: 'transparent',
  },
  contentContainer: {
    background: colors.newOrderFormBackground,
    width: '100%',
    padding: '30px 25px',
    borderRadius: 8,
    flexWrap: 'nowrap',
    overflow: 'hidden',
    minHeight: '100%',
  },
  thumbVertical: {
    background: 'rgba(0,0,0,0.5) !important',
  },
  header: {
    fontSize: 24,
    fontWeight: 700,
  },
  closeIcon: {
    position: 'absolute',
    top: 10,
    right: 10,
    '&:hover': {
      background: 'inherit',
    },
  },
  subHeaderContainer: {
    padding: '4px 0',
  },
  subHeader: {
    fontSize: 20,
    fontWeight: 700,
  },
  divider: {
    borderBottom: `1px solid ${colors.textDarkGrey}`,
    margin: '14px 0 14px 0',
  },
};

const useAssignDocumentTaskStyles = makeStyles(assignDocumentTaskStyles);

function getInitialValues(minDue) {
  return {
    dueDate: moment().add(1, 'd')
      .min(minDue)
      .format('YYYYMMDD'),
    email: '',
    filter: '',
    selectedContact: '',
    addressId: '',
    taskType: 'document',
    documentTaskType: '',
  };
}

export default function AssignDocumentTask(props) {
  const {
    assignTaskObj, open, onClose, shipmentId,
  } = props;

  const classes = { ...NEW_ORDER_MODULE_STYLE(), ...useAssignDocumentTaskStyles() };
  const {
    dialogAssignTaskContentActionsContainer,
  } = classes;

  const { contacts } = useContactsState();
  const [selectedTab, setSelectedTab] = React.useState(SELECT_FROM_CONTACTS_TAB_VALUE);
  const [inviteEmail, setInviteEmail] = React.useState(null);
  const [inviteSent, setInviteSent] = React.useState(false);
  const [update, setUpdate] = React.useState(null);
  const [error, setError] = React.useState(null);

  const formikRef = React.useRef();
  const minDueDate = getMinDueDate();
  const dispatch = useSingleOrderDispatch();

  const {
    documentName,
    displayName,
    packageId,
    documentId,
    taskOptions,
    documentType,
  } = (assignTaskObj || {});

  function onInternalSubmitForm() {
    if (formikRef.current) formikRef.current.submitForm();
  }

  function onTabChange(e, newValue) {
    formikRef.current && formikRef.current.resetForm({
      ...getInitialValues(minDueDate),
      dueDate: formikRef.current.state.values.dueDate,
      documentTaskType: formikRef.current.state.values.documentTaskType,
    });
    setSelectedTab(newValue);
  }

  function resetDialog() {
    if (formikRef.current) formikRef.current.setSubmitting(false);
    setInviteSent(false);
    setInviteEmail(null);
    setSelectedTab(SELECT_FROM_CONTACTS_TAB_VALUE);
    setUpdate(null);
  }

  function onInternalOnClose() {
    resetDialog();
    onClose();
  }

  async function onSubmit(data, { setSubmitting, setValues }) {
    const {
      dueDate, email, filter, selectedContact, documentTaskType, taskType,
    } = data;

    const body = {
      expiresAt: formatExpiresAtDateTime(dueDate),
      assignedTo: {
        email,
        ...(inviteEmail) && { sendInvite: inviteEmail },
      },
      documentId,
      taskType,
      documentTaskType,
      packageId: packageId || null,
      shipmentId,
      documentType,
    };
    setInviteEmail(null);

    if (selectedTab === SELECT_FROM_CONTACTS_TAB_VALUE) {
      const contactEmail = internalOnSubmitSFCTab(filter, selectedContact, contacts);

      if (!contactEmail) {
        resetDialog();
        return;
      }
      body.assignedTo.email = contactEmail;
    } else if (selectedTab === ENTER_EMAIL_TAB_VALUE) {
      body.assignedTo.email = email;
    } else {
      resetDialog();
      return;
    }
    const assignmentObject = sanitizeAssignmentObject(body);
    let assignTaskResponse;
    try {
      assignTaskResponse = await assignDocTask({ dispatch, ...assignmentObject });
    } catch (ex) {
      setError(ex.message);
    }

    if (!assignTaskResponse) {
      resetDialog();
      return;
    }

    if (body.sendInvite) {
      setInviteSent(true);
      setUpdate(assignTaskResponse);
      setSubmitting(false);
      return;
    }

    if (assignTaskResponse.sendInvite) {
      setInviteEmail(assignmentObject.assignedTo.email);
    } else if (assignTaskResponse) {
      onInternalOnClose();
    }
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{
        paper: selectedTab === ENTER_EMAIL_TAB_VALUE
          ? classes.dialogEmailContainer
          : classes.dialogContactContainer,
      }}
      disableBackdropClick
      disableEscapeKeyDown
    >
      <IconButton
        className={classes.closeIcon}
        onClick={onClose}
        disableFocusRipple
        disableRipple
        disableTouchRipple
      >
        <CloseIcon color="primary" />
      </IconButton>
      <Formik
        ref={formikRef}
        initialValues={getInitialValues(minDueDate)}
        validationSchema={getAssignDocSchema(selectedTab)}
        onSubmit={onSubmit}
      >
        {(formikProps) => (
          <ScrollWindow classes={{ thumbVertical: classes.thumbVertical }}>
            <Grid container className={classes.contentContainer} direction="column">
              <Grid item container direction="column">
                <Grid item>
                  <Typography color="primary" className={classes.header}>
                    Assign Task
                  </Typography>
                </Grid>
                <Grid item className={classes.subHeaderContainer}>
                  <Typography color="primary" className={classes.subHeader}>
                    {displayName || documentName || ''}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container className={classes.divider} />
              { !inviteSent
              && (
              <Grid item>
                <Field
                  component={CustomSelectTextField}
                  style={{ width: '100%' }}
                  type="text"
                  label="Task Type"
                  name="documentTaskType"
                >
                  {taskOptions && taskOptions.map((task) => (
                    <MenuItem
                      key={task.description}
                      value={task.type}
                    >
                      {task.description}
                    </MenuItem>
                  ))}
                </Field>
              </Grid>
              )}

              <Grid item style={{ marginTop: 20 }}>
                <AssignmentComponent
                  maxDueDate={null}
                  minDueDate={minDueDate}
                  onTabChange={onTabChange}
                  selectedTab={selectedTab}
                  isSubmitting={formikProps.isSubmitting}
                  inviteSent={inviteSent}
                  inviteEmail={inviteEmail}
                />
              </Grid>
              <DialogActions classes={{ root: dialogAssignTaskContentActionsContainer }}>
                <Grid item container spacing={1} justify="center" alignItems="center">
                  {
                  inviteSent ? (
                    <Grid item>
                      <NewOrderNextButtonClear
                        onClick={onInternalOnClose}
                      >
                        Done
                      </NewOrderNextButtonClear>
                    </Grid>
                  )
                    : (
                      <>
                        <Grid item>
                          <NewOrderNextButtonClear
                            onClick={(inviteEmail) ? resetDialog : onClose}
                            disabled={formikProps.isSubmitting && !inviteEmail}
                          >
                            Cancel
                          </NewOrderNextButtonClear>
                        </Grid>
                        <Grid item>
                          <NewOrderNextButton
                            onClick={onInternalSubmitForm}
                            disabled={formikProps.isSubmitting && !inviteEmail}
                          >
                            {(inviteEmail) ? 'Yes, Invite' : 'Assign'}
                          </NewOrderNextButton>
                        </Grid>
                      </>
                    )
                }

                </Grid>
              </DialogActions>

            </Grid>
          </ScrollWindow>
        )}
      </Formik>
      <NewOrderErrorDialog
        open={!!error}
        errorContent={error}
        onClose={() => setError(null)}
      />
    </Dialog>
  );
}
AssignDocumentTask.propTypes = {
  assignTaskObj: PropTypes.objectOf(PropTypes.any).isRequired,
  open: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.object]),
  onClose: PropTypes.func,
  shipmentId: PropTypes.string.isRequired,
};

AssignDocumentTask.defaultProps = {
  open: false,
  onClose: null,
};
