import React from 'react';
import PropTypes from 'prop-types';
import { AutoSizer } from 'react-virtualized';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import {
  TextField, Paper, MenuItem, Chip, Grid, IconButton, FormControl, FormGroup,
  Typography, Popper, Grow,
} from '@material-ui/core';

import CancelIcon from '@material-ui/icons/Cancel';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import Downshift from 'downshift';
import { NEW_ORDER_INPUT_LABEL_PROPS, NEW_ORDER_INPUT_PROPS } from '../../styles/style';
import * as colors from '../../styles/colors';
import { convertWeight, breakUpWeight, consolidateWeight } from '../../utils/packagingUtil';
import { toNumber } from 'lodash';

function ifNegativeReturnZero(num) {
  return num <= 0 ? 0 : num;
}

function weightCalculations({
  packageWeight, packageWeightSmall, packageUnitOfMeasurement,
  itemWeight, itemWeightSmall, itemUnitOfMeasurement, type, itemQuantity,
}) {
  const totalPackageWeight = consolidateWeight({
    weightSmall: packageWeightSmall,
    weight: packageWeight,
    unitOfMeasurement: packageUnitOfMeasurement,
  });

  const totalItemWeight = consolidateWeight({
    weightSmall: itemWeightSmall,
    weight: itemWeight,
    unitOfMeasurement: itemUnitOfMeasurement,
  });

  let newPackageWeight;
  switch (type) {
    case INCREMENT_ITEM:
      newPackageWeight = (ifNegativeReturnZero(totalPackageWeight.weight + totalItemWeight.weight));
      break;
    case DECREMENT_ITEM:
      newPackageWeight = (ifNegativeReturnZero(totalPackageWeight.weight - totalItemWeight.weight));
      break;
    case REMOVE_ITEM:
      newPackageWeight = ifNegativeReturnZero(totalPackageWeight.weight - (totalItemWeight.weight * itemQuantity));
      break;
    default: newPackageWeight = totalPackageWeight.weight;
  }

  // returns { weight, weightSmall, unitOfMeasurement }
  return breakUpWeight({ weight: newPackageWeight, unitOfMeasurement: packageUnitOfMeasurement });
}

const INCREMENT_ITEM = 0;
const DECREMENT_ITEM = 1;
const REMOVE_ITEM = 2;
const SET_ITEM = 3;

const styles = (theme) => ({
  paper: {
    maxHeight: '150px',
    overflowY: 'auto',
  },
});

const StyledTextField = withStyles({
  root: {
    color: colors.white,
  },
})(TextField);

function CustomInput(props) {
  const inputStyle = {
    height: 'auto',
    flexWrap: 'wrap',
  };
  const {
    InputProps, itemsInThisPackageArray, onChangeItemQuantity, isChemicalHazmat,
  } = props;

  const noItems = itemsInThisPackageArray.length === 0;
  const noItemsSelected = !Object.values(itemsInThisPackageArray).filter((item) => item.quantity > 0)?.length;
  return (
    <StyledTextField
      fullWidth
      variant="filled"
      disabled={noItems}
      label={
        noItemsSelected ? 'Select package items' : ''
      }
      InputProps={{
        fullWidth: true,
        startAdornment: <ChipList
          onChangeItemQuantity={onChangeItemQuantity}
          itemsInThisPackageArray={itemsInThisPackageArray}
          isChemicalHazmat={isChemicalHazmat}
        />,
        classes: NEW_ORDER_INPUT_PROPS(),
        style: inputStyle,
        ...InputProps,
      }}
      InputLabelProps={{
        shring: 'true',
        classes: NEW_ORDER_INPUT_LABEL_PROPS(),
      }}
    />
  );
}

CustomInput.propTypes = {
  InputProps: PropTypes.object.isRequired,
  itemsInThisPackageArray: PropTypes.any.isRequired,
  onChangeItemQuantity: PropTypes.func.isRequired,
  isChemicalHazmat: PropTypes.bool.isRequired,
};

const useChipListStyles = makeStyles({
  chipContainer: {
    backgroundColor: 'transparent',
    display: 'inline-block',
    marginBottom: 10,
    color: 'white',
  },
  chip: {
    color: 'white',
  },
});

function pluralizeString(str) {
  const regex = /s$/;
  return regex.test(str) ? str : `${str}s`;
}
function ChipList(props) {
  const { itemsInThisPackageArray, onChangeItemQuantity, isChemicalHazmat } = props;
  const classes = useChipListStyles();

  const handleDeleteItem = (itemKey) => {
    onChangeItemQuantity(itemKey, REMOVE_ITEM);
  };

  return (
    <>
      {itemsInThisPackageArray && itemsInThisPackageArray.filter((item) => item.quantity > 0)
        .map((item) => (
          <Chip
            key={item.itemKey}
            variant="outlined"
            className={classes.chip}
            label={`${item.name}, ${item.quantity} ${item.quantity > 1 ? pluralizeString(item.unitOfMeasurement) : item.unitOfMeasurement}`}
            deleteIcon={<CancelIcon />}
            onDelete={isChemicalHazmat ? null : () => handleDeleteItem(item.itemKey)}
            color="primary"
            style={{ margin: 3 }}
          />
        ))}
    </>
  );
}

ChipList.propTypes = {
  itemsInThisPackageArray: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    quantity: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
  onChangeItemQuantity: PropTypes.func.isRequired,
  isChemicalHazmat: PropTypes.bool.isRequired,
};

const useSuggestionInputStyles = makeStyles({
  root: {
    color: '#FFFFFF',
    width: '38px',
    textAlign: 'center',
  },

});
function Suggestion(props) {
  const {
    item,
    itemProps,
    onChangeItemQuantity,
    quantityOfItemInThisPackage,
  } = props;

  const {
    maxQuantity, quantity: quantityInAllPackages, unitOfMeasurement, itemWeight,
  } = item;
  const { unitOfMeasurementSmall, weight, weightSmall } = itemWeight;
  const availableQuantity = maxQuantity - quantityInAllPackages;
  const classes = useSuggestionInputStyles();
  const smallWeightStr = weightSmall ? ` ${weightSmall} ${unitOfMeasurementSmall.toLowerCase()}` : '';
  return (
    <MenuItem
      key={item.itemKey}
      component="div"
      style={{ backgroundColor: '#626477', color: colors.white }}
      button={false}
    >
      <Grid container alignItems="center" justify="space-between">
        <Grid item container direction="column" xs={6} alignItems="flex-start">
          <Grid item>
            <Typography style={{ fontWeight: 425 }}>{item.name}</Typography>
          </Grid>
          <Grid item>
            <Typography style={{ fontSize: '0.8125rem' }}>
              {`${availableQuantity} of ${maxQuantity} ${pluralizeString(unitOfMeasurement)} unassigned, ${weight} ${item.itemWeight?.unitOfMeasurement.toLowerCase()}${smallWeightStr} per unit`}
            </Typography>
          </Grid>
        </Grid>
        <Grid item alignItems="center" container xs={6} justify="flex-end">
          <Grid item>
            <IconButton
              {...itemProps}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onChangeItemQuantity(item.itemKey, INCREMENT_ITEM);
              }}
              disabled={availableQuantity <= 0}
            >
              <AddIcon />
            </IconButton>
          </Grid>
          <Grid item>
            <TextField
              size="small"
              onChange={(e) => {
                const onlyNums = toNumber(e.target.value.replace(/[^0-9]/g, ''));
                if (onlyNums <= (availableQuantity + toNumber(quantityOfItemInThisPackage)) || onlyNums < 0) {
                  onChangeItemQuantity(item.itemKey, SET_ITEM, onlyNums);
                }
              }}
              inputProps={{ min: 0, style: { textAlign: 'center' } }}
              value={quantityOfItemInThisPackage}
              InputLabelProps={{
                classes: NEW_ORDER_INPUT_LABEL_PROPS(),
              }}
              // eslint-disable-next-line react/jsx-no-duplicate-props
              InputProps={{
                classes: { ...classes },
              }}
            />
            {/* <Typography style={{ fontWeight: 425 }}>{quantityOfItemInThisPackage}</Typography> */}
          </Grid>
          <Grid item>
            <IconButton
              {...itemProps}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onChangeItemQuantity(item.itemKey, DECREMENT_ITEM);
              }}
              disabled={((availableQuantity >= maxQuantity) || (quantityOfItemInThisPackage <= 0))}
            >
              <RemoveIcon />
            </IconButton>
          </Grid>

        </Grid>
      </Grid>
    </MenuItem>
  );
}

Suggestion.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    itemKey: PropTypes.string.isRequired,
    quantity: PropTypes.number.isRequired,
    maxQuantity: PropTypes.string.isRequired,
    itemWeight: PropTypes.shape({
      weight: PropTypes.string.isRequired,
      unitOfMeasurement: PropTypes.string.isRequired,
      weightSmall: PropTypes.string.isRequired,
      unitOfMeasurementSmall: PropTypes.string.isRequired,
    }).isRequired,
    unitOfMeasurement: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  itemProps: PropTypes.object.isRequired,
  onChangeItemQuantity: PropTypes.func.isRequired,
  quantityOfItemInThisPackage: PropTypes.number.isRequired,
};

Suggestion.defaultProps = {
};
const getSuggestions = (inputValue, itemList) => itemList.filter(
  (item) => item?.name?.toLowerCase().includes(inputValue?.toLowerCase()),
);

function MultiChipSelect(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const {
    classes, itemsInThisPackageArray,
    onChangeItemQuantity,
    itemsInAllPackages,
    itemsInAllPackagesArray,
    itemsInThisPackage,
    isChemicalHazmat,
    ...rest
  } = props;

  return (
    <Downshift {...rest}>
      {({
        getInputProps,
        inputValue,
        toggleMenu,
        getMenuProps,
        getItemProps,
        isOpen,
      }) => (
        <div>
          <CustomInput
            onChangeItemQuantity={onChangeItemQuantity}
            itemsInThisPackageArray={itemsInThisPackageArray}
            isChemicalHazmat={isChemicalHazmat}
            InputProps={{
              ...getInputProps({
                onClick: (event) => {
                  setAnchorEl(event.currentTarget);
                  toggleMenu();
                },
              }),
            }}
          />

          <AutoSizer>
            {({ width }) => (
              <>
                {(isOpen && anchorEl) ? (
                  <Popper
                    anchorEl={anchorEl}
                    open={isOpen}
                    style={{ zIndex: 1300, width: anchorEl?.current?.clientWidth || 613 }}
                    placement="bottom-start"
                    modifiers={{
                      preventOverflow: { enabled: false },
                      hide: { enabled: false },
                    }}
                  >
                    <div {...getMenuProps({}, { suppressRefError: true })}>
                      <Grow in={isOpen}>
                        <Paper
                          style={{ width }}
                        >
                          {getSuggestions(inputValue, itemsInAllPackagesArray).map((item, index) => (
                            <Suggestion
                              key={`${item.itemKey}-suggestion`}
                              item={item}
                              itemProps={getItemProps({
                                item: item.name,
                              })}
                              onChangeItemQuantity={onChangeItemQuantity}
                              quantityOfItemInThisPackage={itemsInThisPackage[index]?.quantity}
                            />
                          ))}
                        </Paper>
                      </Grow>
                    </div>
                  </Popper>
                )
                  : null}
              </>
            )}
          </AutoSizer>
        </div>
      )}
    </Downshift>
  );
}

const StyledMultiChipSelect = withStyles(styles)(MultiChipSelect);

MultiChipSelect.propTypes = {
  onChangeItemQuantity: PropTypes.func.isRequired,
  itemsInThisPackageArray: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    quantity: PropTypes.number.isRequired,
    unitOfMeasurement: PropTypes.string.isRequired,
  })).isRequired,
  itemsInAllPackagesArray: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    quantity: PropTypes.string.isRequired,
    unitOfMeasurement: PropTypes.string.isRequired,
    maxQuantity: PropTypes.string.isRequired,
    quantity: PropTypes.number.isRequired,
    itemWeight: PropTypes.shape({
      noOfUnits: PropTypes.string.isRequired,
      unitOfMeasurement: PropTypes.string.isRequired,
    }),
  })).isRequired,
  itemsInAllPackages: PropTypes.objectOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    maxQuantity: PropTypes.number.isRequired,
    quantity: PropTypes.number.isRequired,
    itemWeight: PropTypes.number.isRequired,
    unitOfMeasurement: PropTypes.string.isRequired,
  })).isRequired,
  isChemicalHazmat: PropTypes.bool.isRequired,
};

function getNewCustomsValue({
  currentItemQuantityInPackage, type, itemUnitValue, currentPackageCustomsValue,
}) {
  switch (type) {
    case INCREMENT_ITEM:
      return currentPackageCustomsValue + itemUnitValue;
    case DECREMENT_ITEM:
      return ifNegativeReturnZero(currentPackageCustomsValue - itemUnitValue);
    case REMOVE_ITEM:
      return ifNegativeReturnZero(currentPackageCustomsValue - (itemUnitValue * currentItemQuantityInPackage));
    default: return currentPackageCustomsValue;
  }
}
export default function AutocompleteWithIncrementInput(props) {
  const {
    itemsInThisPackage, setFieldValue, packageNumber, packageWeight,
    itemsInAllPackages,
    setItemsInAllPackages,
    weightUnitOfMeasurementForPackage,
    packageCustomsValue,
    account,
    isChemicalHazmat,
  } = props;

  const itemsInThisPackageArray = itemsInThisPackage;
  const itemsInAllPackagesArray = itemsInAllPackages;

  function getNewQuantityOfItem({
    currentShipmentQuantity, currentPackageQuantity, type, val = null,
  }) {
    switch (type) {
      case INCREMENT_ITEM:
        return {
          newQuantityOfItemInPackage: currentPackageQuantity + 1,
          newQuantityOfItemInShipment: currentShipmentQuantity + 1,
        };
      case DECREMENT_ITEM:
        return {
          newQuantityOfItemInPackage: currentPackageQuantity - 1,
          newQuantityOfItemInShipment: currentShipmentQuantity - 1,
        };
      case REMOVE_ITEM:
        return {
          newQuantityOfItemInPackage: 0,
          newQuantityOfItemInShipment: currentShipmentQuantity - currentPackageQuantity,
        };
      case SET_ITEM:
        return {
          newQuantityOfItemInPackage: val,
          newQuantityOfItemInShipment: (currentShipmentQuantity - currentPackageQuantity) + val,
        };
      default: return {
        newQuantityOfItemInPackage: currentPackageQuantity,
        newQuantityOfItemInShipment: currentShipmentQuantity,
      };
    }
  }

  function getNewWeight({
    itemInAllPackage, currentPackageWeight, currentQuantity, itemId, type,
  }) {
    const {
      weight, unitOfMeasurement, weightSmall,
    } = currentPackageWeight;

    const itemWeight = parseFloat(itemInAllPackage?.itemWeight?.weight) || 0;
    const itemWeightSmall = parseFloat(itemInAllPackage?.itemWeight?.weightSmall) || 0;
    const itemWeightUnitOfMeasurement = itemInAllPackage?.itemWeight?.unitOfMeasurement;
    const itemWeightUnitOfMeasurementSmall = itemInAllPackage?.itemWeight?.unitOfMeasurementSmall;

    const standardizedItemWeight = itemWeightUnitOfMeasurement.toLowerCase() === weightUnitOfMeasurementForPackage.toLowerCase()
      ? itemWeight
      : convertWeight(itemWeight, itemWeightUnitOfMeasurement);

    const standardizedItemWeightSmall = itemWeightUnitOfMeasurement.toLowerCase() === weightUnitOfMeasurementForPackage.toLowerCase()
      ? itemWeightSmall
      : convertWeight(itemWeightSmall, itemWeightUnitOfMeasurementSmall);

    return weightCalculations({
      packageWeight: weight,
      packageWeightSmall: weightSmall,
      packageUnitOfMeasurement: unitOfMeasurement,
      itemWeight: standardizedItemWeight,
      itemWeightSmall: standardizedItemWeightSmall,
      itemUnitOfMeasurement: unitOfMeasurement,
      type,
      itemQuantity: currentQuantity,
    });
  }

  function onChangeItemQuantity(itemKey, type, val = null) {
    let idxItemInThisPackage = itemsInThisPackage.findIndex((item) => item.itemKey === itemKey);
    let itemInThisPackage = itemsInThisPackage[idxItemInThisPackage];
    const idxItemInAllPackage = itemsInAllPackages.findIndex((item) => item.itemKey === itemKey);
    const itemInAllPackage = itemsInAllPackages[idxItemInAllPackage];
    // update quantity
    const currentPackageQuantity = parseInt(itemInThisPackage?.quantity, 10) || 0;
    const currentShipmentQuantity = parseInt(itemInAllPackage?.quantity, 10) || 0;
    const itemUnitValue = parseFloat(itemInAllPackage.unitValue?.amount) || 0;
    const currentPackageCustomsValue = parseFloat(packageCustomsValue?.amount) || 0;

    const { newQuantityOfItemInPackage, newQuantityOfItemInShipment } = getNewQuantityOfItem(
      {
        currentPackageQuantity, currentShipmentQuantity, type, val,
      },
    );
    let newItemObj = { ...itemsInThisPackage[idxItemInThisPackage], quantity: newQuantityOfItemInPackage };
    if (idxItemInThisPackage === -1) {
      newItemObj = { ...itemsInAllPackages[idxItemInAllPackage], quantity: newQuantityOfItemInPackage };
      idxItemInThisPackage = idxItemInAllPackage;
      itemInThisPackage = itemInAllPackage;
    }
    // update weight
    const { weight, weightSmall } = getNewWeight({
      itemInAllPackage, itemKey, type, currentQuantity: currentPackageQuantity, currentPackageWeight: packageWeight,
    });

    const newCustomsValue = getNewCustomsValue({
      itemUnitValue, currentPackageCustomsValue, type, currentItemQuantityInPackage: currentPackageQuantity,
    });

    const newInsuredValue = account === 'worldemblem' ? 0 : (newCustomsValue * 1.1);

    // set weight and quantity
    setFieldValue(`packages[${packageNumber - 1}].items.${idxItemInThisPackage}`, newItemObj);
    setFieldValue(`packages[${packageNumber - 1}].packageWeight.weight`, weight.toFixed(2));
    setFieldValue(`packages[${packageNumber - 1}].packageWeight.weightSmall`, weightSmall.toFixed(2));
    setFieldValue(`packages[${packageNumber - 1}].declaredValue.amount`, newCustomsValue.toFixed(2));
    setFieldValue(`packages[${packageNumber - 1}].customsValue.amount`, newCustomsValue.toFixed(2));
    setFieldValue(`packages[${packageNumber - 1}].customsValue.minimum`, newCustomsValue.toFixed(2));
    setFieldValue(`packages[${packageNumber - 1}].insuredValue.amount`, newInsuredValue.toFixed(2));

    const newItems = itemsInAllPackages;
    newItems[idxItemInAllPackage] = {
      ...newItems[idxItemInAllPackage],
      quantity: newQuantityOfItemInShipment,
    };

    setItemsInAllPackages(newItems);
  }

  return (
    <FormGroup>
      <FormControl>
        <StyledMultiChipSelect
          itemsInThisPackageArray={itemsInThisPackageArray}
          onChangeItemQuantity={onChangeItemQuantity}
          itemsInAllPackages={itemsInAllPackages}
          itemsInAllPackagesArray={itemsInAllPackagesArray}
          itemsInThisPackage={itemsInThisPackage}
          isChemicalHazmat={isChemicalHazmat}
        />
      </FormControl>
    </FormGroup>
  );
}

AutocompleteWithIncrementInput.propTypes = {
  packageWeight: PropTypes.number.isRequired,
  weightUnitOfMeasurementForPackage: PropTypes.string.isRequired,
  packageCustomsValue: PropTypes.shape({
    amount: PropTypes.string.isRequired,
    currency: PropTypes.number.isRequired,
  }).isRequired,
  itemsInThisPackage: PropTypes.objectOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    quantity: PropTypes.number.isRequired,
  })).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  packageNumber: PropTypes.number.isRequired,
  itemsInAllPackages: PropTypes.objectOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    maxQuantity: PropTypes.number.isRequired,
    quantity: PropTypes.number.isRequired,
    itemWeight: PropTypes.number.isRequired,
    unitOfMeasurement: PropTypes.string.isRequired,
  })).isRequired,
  setItemsInAllPackages: PropTypes.func.isRequired,
  isChemicalHazmat: PropTypes.bool.isRequired,
};
