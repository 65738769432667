import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import BlockedPanelDisplay from './common/PanelWarning';
import SchedulePickupForm from './SchedulePickupForm';
import NewOrderShipmentModule from './common/NewOrderShipmentModule';


function getLabelDetails(shipment, isComplete) {
  if (!isComplete || !shipment.pickupFrom) return '';

  const { pickupDateInfo, pickupConfirmationNumber } = shipment.pickupFrom;
  if (!pickupConfirmationNumber || !pickupDateInfo) return '';

  const { readyTime, closeTime, pickupDate } = pickupDateInfo;
  if (!readyTime || !closeTime || !pickupDate) return '';

  const date = moment(pickupDate, 'YYYYMMDD').format('DD MMM YYYY');

  return `${pickupConfirmationNumber} | Pickup Scheduled: ${convertTime(readyTime)} - ${convertTime(closeTime)} | ${date}`;
}

function convertTime(time) {
  const hrs = parseInt(time.substring(0, 2), 10);
  const meridiem = (hrs < 12) ? 'AM' : 'PM';

  return `${(hrs < 12) ? hrs : hrs - 12}:${time.substring(2)} ${meridiem}`;
}

export default function SchedulePickupPane({
  shipment,
  isComplete,
  isReadyToBeCompleted,
  blockingMessage,
  ...shipmentProps
}) {

  const labelDetails = getLabelDetails(shipment, isComplete);

  return (
    <NewOrderShipmentModule
      labelDetails={labelDetails}
      isComplete={isComplete}
      shipment={shipment}
      blockingMessage={blockingMessage}
      {...shipmentProps}
    >
      {(isReadyToBeCompleted) ? ((renderProps) => (
          <SchedulePickupForm
            shipment={shipment}
            isComplete={isComplete}
            isReadyToBeCompleted={isReadyToBeCompleted}
            {...shipmentProps}
            {...renderProps}
          />
        )): (
          <BlockedPanelDisplay message={blockingMessage} />
      )}
    </NewOrderShipmentModule>
  );
}

// SchedulePickupPane.propTypes = {
//   label: PropTypes.string.isRequired,
//   shipmentId: PropTypes.string.isRequired,
//   selectedForms: PropTypes.objectOf(PropTypes.string.isRequired).isRequired,
//   setSelectedForms: PropTypes.func.isRequired,
//   formName: PropTypes.string.isRequired,
//   parentFormName: PropTypes.string.isRequired,
//   submitFormToApi: PropTypes.func.isRequired,
// };
