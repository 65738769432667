import React from 'react';
import * as userClient from '../utils/userClient';
import { useLogger } from '../utils/hooks';

const userState = { isProfilePictureError: false };
const UserStateContext = React.createContext();
const UserDispatchContext = React.createContext();

// Action Types
const SET_USER = 'SET USER';
const LOAD_USER = 'LOAD USER';
const UPDATE_USER = 'UPDATE USER';
const UPDATE_PROFILE_PHOTO = 'UPDATE PROFILE PICTURE';
const SET_PROFILE_PICTURE_ERROR = 'SET PROFILE PICTURE ERROR';

// Reducer
function userReducer(state, action) {
  switch (action.type) {
    case SET_USER: {
      return { ...state, ...action.userData };
    }
    case LOAD_USER: {
      return { ...state, ...action.userData };
    }
    case UPDATE_USER: {
      const { updatedUserData } = action;
      return { ...state, ...updatedUserData };
    }
    case UPDATE_PROFILE_PHOTO: {
      const { updatedUserData } = action;
      return { ...state, ...updatedUserData };
    }
    case SET_PROFILE_PICTURE_ERROR: {
      const { isError } = action;
      return { ...state, isProfilePictureError: isError };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

// Provider
function UserDataProvider(props) {
  const [state, dispatch] = useLogger(
    React.useReducer(userReducer, userState),
  );

  return (
    <UserStateContext.Provider value={state} {...props}>
      <UserDispatchContext.Provider
        value={dispatch}
        {...props}
      />
    </UserStateContext.Provider>
  );
}

// Exported functions
async function setUser(dispatch, userData) {
  dispatch({ type: SET_USER, userData });
}

async function loadUser(dispatch) {
  let validResponse;
  try {
    validResponse = await userClient.loadUser();
  } catch (e) {
    throw new Error(e.message || e);
  }
  dispatch({ type: LOAD_USER, userData: validResponse });
  return validResponse;
}

async function updateUser({ dispatch, currentUserId, updatedUserData }) {
  let validResponse;
  try {
    validResponse = await userClient.updateUser(updatedUserData);
  } catch (e) {
    throw new Error(e.message || e);
  }

  if (currentUserId === updatedUserData.userId) {
    dispatch({ type: UPDATE_USER, updatedUserData: validResponse });
  }
}

function setProfilePictureError({ dispatch, isError }) {
  dispatch({ type: SET_PROFILE_PICTURE_ERROR, isError });
}

async function removeProfilePicture(dispatch) {
  let validResponse;
  try {
    validResponse = await userClient.removeProfilePicture();
  } catch (e) {
    throw new Error(e.message || e);
  }
  dispatch({ type: UPDATE_PROFILE_PHOTO, updatedUserData: validResponse });
}

async function uploadProfilePicture({
  dispatch, document, documentId,
}) {
  let validResponse;
  try {
    validResponse = await userClient.uploadProfilePicture({
      document, documentId,
    });
  } catch (e) {
    throw new Error(e.message || e);
  }
  dispatch({ type: UPDATE_PROFILE_PHOTO, updatedUserData: validResponse });
}

function useUserState() {
  const context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error(
      'useUserState must be used within a UserDataProvider',
    );
  }
  return context;
}

function useUserDispatch() {
  const context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error(
      'useUserDispatch must be used within a OrderDataProvider',
    );
  }
  return context;
}

export {
  UserDataProvider,
  useUserState,
  useUserDispatch,
  setUser,
  loadUser,
  updateUser,
  uploadProfilePicture,
  removeProfilePicture,
  setProfilePictureError,
};
