/* eslint-disable react/prop-types */
import React from 'react';
import Verizeal from './verizeal.png';

export default function Logo(props) {
  const ratio = 1339 / 212;
  const { style } = props;
  const height = (style && style.height);
  const width = height && Math.floor(ratio * height);
  return (
    <>
      <img src={Verizeal} width={width} height={height} alt="verizeal logo" />
    </>
  );
}
