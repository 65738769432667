import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import has from 'lodash/has';
import get from 'lodash/get';
import { Form, Formik } from 'formik';
import {
  CircularProgress, List, Grid, RadioGroup, Typography,
} from '@material-ui/core';
import {
  useContactsState, useContactsDispatch, loadFilteredContacts,
} from '../../context/contactsContext';
import NewOrderAddressListItem from '../common/NewOrderAddressListItem';
import NewOrderNextButton from '../common/NewOrderNextButton';
import { CustomContactSearch, CustomRecieveComponent } from '../common/InputComponents';
import ScrollWindow from '../common/ScrollWindow';

/**
 * ##################################
 *          GLOBAL VARIABLES
 * ##################################
 */

const DEFAULT_FORM_VALUES = {
  filter: '',
  selectedContact: '',
};

/**
 * ##################################
 *          CUSTOM COMPONENT
 * ##################################
 */

function CustomListField(props) {
  const {
    form, field, disabled, callback, classes,
  } = props;

  const { filter, selectedContact } = form.values;

  const content = (filter.length > 2)
    ? (
      <CustomFilterList
        fieldName={field.name}
        filter={filter}
        selectedContact={selectedContact}
        disabled={disabled}
        onChange={(e) => field.onChange(e)}
        callback={callback}
        classes={classes}
      />
    )
    : null;

  const { scrollbarsRoot } = classes;

  return (
    <ScrollWindow classes={{ root: scrollbarsRoot }}>
      {content}
    </ScrollWindow>
  );
}

CustomListField.propTypes = {
  form: PropTypes.shape({
    values: PropTypes.shape({
      filter: PropTypes.string,
      selectedContact: PropTypes.string,
    }).isRequired,
  }).isRequired,
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  disabled: PropTypes.bool.isRequired,
  callback: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    scrollbarsRoot: PropTypes.string.isRequired,
  }).isRequired,
};

function CustomFilterList(props) {
  const {
    fieldName, filter, selectedContact, disabled, onChange, callback, classes,
  } = props;

  const contacts = useContactsState().contacts || [];
  const filteredContacts = contacts.filter((contact) => {
    const values = Object.keys(contact).map((key) => {
      const value = contact[key];
      return typeof value === 'string' && value.toLowerCase().includes(filter.toLowerCase());
    });

    return values.includes(true);
  });

  return (
    <RadioGroup onChange={onChange} name={fieldName}>
      {(filteredContacts.length === 0)
        ? <Typography classes={{ root: classes.noResults }}>No existing contacts</Typography>
        : buildContactList(fieldName, selectedContact, filteredContacts, disabled, callback)}
    </RadioGroup>
  );
}

CustomFilterList.propTypes = {
  fieldName: PropTypes.string.isRequired,
  filter: PropTypes.string.isRequired,
  selectedContact: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  callback: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    noResults: PropTypes.string.isRequired,
  }).isRequired,
};

CustomFilterList.defaultProps = {
  selectedContact: '',
};

/**
 * ######################
 *          UTIL
 * ######################
 */

function getButtonText(moduleName) {
  return (moduleName === 'sender') ? 'Set Sender' : 'Next';
}

function isDisabled(isSubmitting, hideSetSenderBtn, contact, values) {
  return (isSubmitting
      || !values.selectedContact || (hideSetSenderBtn && contact && contact.address.addressId === values.selectedContact)
  );
}

function buildContactList(fieldName, selectedContact, contacts, disabled, callback) {
  return (
    contacts.map((contact) => (
      <Grid key={`contacts-listItem-${contact.addressId}`} item>
        <NewOrderAddressListItem
          fieldName={fieldName}
          id={contact.addressId}
          contact={contact}
          isSelected={(selectedContact === contact.addressId)}
          disabled={disabled}
          onClick={() => callback(contact)}
        />
      </Grid>
    ))
  );
}

/**
 * #################################
 *          EXPORT FUNCTION
 * #################################
 */

export default function NewOrderSenderRecipientContacts(props) {
  const {
    selectedForms, tabValue, contact, pickupOptions, resetSearchCache, classes,
  } = props;
  const { handleSubmit, handleEdit, setResetSearchCache } = props;

  const formikRef = useRef();
  const contactsDispatch = useContactsDispatch();
  const contactsState = useContactsState();
  const [searchCache, setSearchCache] = useState([]);
  const [fromContacts, setFromContacts] = useState(false);

  const { contacts } = contactsState;
  const { defaultSender } = contactsState;
  const hideSetSenderBtn = (pickupOptions === tabValue);

  useEffect(() => {
    if (!resetSearchCache) return;

    const minChars = contact.address.name.substring(0, 3).toLowerCase();

    loadFilteredContacts({ dispatch: contactsDispatch, filter: minChars });
    setResetSearchCache(false);
  }, [resetSearchCache]);

  useEffect(() => {
    const selectedFormChild = selectedForms.child;

    if (formikRef.current === undefined) return;

    const { resetForm } = formikRef.current;

    function HandleInitialLoad() {
      const addressId = get(contact, 'address.addressId', null);
      if (!addressId
        || (get(defaultSender, addressId) === addressId)) {
        resetForm(DEFAULT_FORM_VALUES);
        return;
      }

      loadContact();
    }

    function HandleSender() {
      const addressId = get(contact, 'address.addressId', null);
      if (!addressId
        || (get(defaultSender, addressId) === addressId)) {
        resetForm(DEFAULT_FORM_VALUES);
        return;
      }

      loadContact();
    }

    function HandleRecipient() {
      if (!has(contact, 'address.addressId')) {
        resetForm(DEFAULT_FORM_VALUES);
        return;
      }

      loadContact();
    }

    function loadContact() {
      const { name, addressId } = contact?.address;

      if (fromContacts) {
        setFromContacts(false);
        return;
      }

      resetForm({
        filter: name,
        selectedContact: addressId,
      });

      getFilteredContacts(name);
    }

    if (selectedFormChild === '') HandleInitialLoad();
    else if (selectedFormChild === 'sender') HandleSender();
    else if (selectedFormChild === 'recipient') HandleRecipient();
    else if (selectedFormChild === 'customsBroker') HandleRecipient();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contact, pickupOptions]);

  function onEdit(editContact) {
    handleEdit(editContact);
  }

  function getFilteredContacts(filter) {
    if (filter.length < 3) return;

    const minChars = filter.substring(0, 3).toLowerCase();

    if (searchCache.includes(minChars)) return;

    searchCache.push(minChars);
    setSearchCache(searchCache);

    loadFilteredContacts({ dispatch: contactsDispatch, filter: minChars });
  }

  /*          FORMIK PROPS            */

  const validateOnBlur = false;

  function getInitialValues() {
    return DEFAULT_FORM_VALUES;
  }

  async function onSubmit(values, { setSubmitting }) {
    const { filter, selectedContact } = values;

    if (selectedContact.length === 0) {
      setSubmitting(false);
      return;
    }

    const filteredContacts = contacts.filter((filteredC) => {
      const contactVals = Object.keys(filteredC).map((key) => {
        const value = filteredC[key];
        return typeof value === 'string' && value.toLowerCase().includes(filter.toLowerCase());
      });

      return (contactVals.includes(true) && filteredC.addressId === selectedContact);
    });

    if (filteredContacts.length !== 1) {
      setSubmitting(false);
      return;
    }

    setFromContacts(true);
    await handleSubmit(filteredContacts[0]);
    setSubmitting(false);
  }

  function render(renderProps) {
    const { isSubmitting, submitForm, values } = renderProps;
    const selectedFormChild = selectedForms.child;

    return (
      <Form
        className={classes.contentOuterContainer} // 'class' throws error-warn, 'className' required
        name="contacts-form"
      >
        <Grid container direction="column">
          <Grid item container direction="column" classes={{ root: classes.contentContainer }}>
            <Grid item classes={{ root: classes.contentSearch }}>
              <CustomContactSearch
                name="filter"
                label="Search for a contact"
                classes={classes}
                callback={getFilteredContacts}
                disabled={isSubmitting}
              />
            </Grid>
            <Grid item classes={{ root: classes.contentResults }}>
              <CustomRecieveComponent
                component={CustomListField}
                name="selectedContact"
                disabled={isSubmitting}
                callback={onEdit}
                classes={classes}
              />
            </Grid>
          </Grid>
          <Grid item classes={{ root: classes.buttonContainer }}>
            <NewOrderNextButton
              disabled={isDisabled(isSubmitting, hideSetSenderBtn, contact, values)}
              data-testid={`${
                selectedFormChild.toLowerCase() === 'recipient'
                  ? 'recipient-next-button'
                  : 'set-sender-button'
              }`}
              onClick={submitForm}
            >
              {getButtonText(selectedFormChild)}
            </NewOrderNextButton>
          </Grid>
          {(isSubmitting)
            ? (
              <Grid item classes={{ root: classes.miscLoadingContainer }}>
                <CircularProgress color="secondary" />
              </Grid>
            )
            : null}
        </Grid>
      </Form>
    );
  }

  return (
    <Formik
      ref={formikRef}
      validateOnBlur={validateOnBlur}
      initialValues={getInitialValues()}
      onSubmit={onSubmit}
      render={render}
    />
  );
}

NewOrderSenderRecipientContacts.propTypes = {
  selectedForms: PropTypes.shape({
    parent: PropTypes.string.isRequired,
    child: PropTypes.string.isRequired,
  }).isRequired,
  tabValue: PropTypes.string,
  contact: PropTypes.shape({
    address: PropTypes.shape({
      addressId: PropTypes.string,
      name: PropTypes.string.isRequired,
    }).isRequired,
  }),
  pickupOptions: PropTypes.string,
  resetSearchCache: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired,
  setResetSearchCache: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    contentOuterContainer: PropTypes.string.isRequired,
    contentContainer: PropTypes.string.isRequired,
    contentSearch: PropTypes.string.isRequired,
    contentResults: PropTypes.string.isRequired,
    buttonContainer: PropTypes.string.isRequired,
    miscLoadingContainer: PropTypes.string.isRequired,
  }).isRequired,
};

NewOrderSenderRecipientContacts.defaultProps = {
  tabValue: null,
  pickupOptions: null,
  contact: null,
};
