import * as Yup from 'yup';
import * as _ from 'lodash';
import { sanitizeAddress } from './helpers';

const commonBillingSchemaPostPayment = {
  amount: Yup.string().required('Required'),
  billingType: Yup.string().oneOf(['billDefaultAccount', 'billOtherAccount']).required('Required'),
  paymentType: Yup.string().oneOf(['costCode', 'creditCard', 'payPal', 'ach', 'internal']),
};

const commonBillingSchemaPrePayment = {
  amount: Yup.string().required('Required'),
  billingType: Yup.string().oneOf(['billDefaultAccount', 'billOtherAccount']).required('Required'),
  paymentType: Yup.string().oneOf(['costCode', 'creditCard', 'payPal', 'ach', 'internal']).default('creditCard'),
};

const savedPaymentOptionSchema = Yup.object().shape({
  paymentToken: Yup.string().required('Payment token Required'),
});

export const creditCardSchemaPreTokenization = Yup.object().shape({
  billingAddress: Yup.object().shape({
    city: Yup.string().required('Required'),
    state: Yup.string().required('Required'),
    country: Yup.string().required('Required'),
    addressLine1: Yup.string().required('Required'),
    addressLine2: Yup.string(),
    addressLine3: Yup.string(),
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    zip: Yup.string().required('Required'),
  }).required('Required'),
  creditCardDetails: Yup.object().shape({
    ownerShipType: Yup.string().required('Required'),
  }).default(undefined),
  // isSavePayment: Yup.bool().required('Required'),
});

export const creditCardSchemaPostTokenization = Yup.object().shape({
  billingAddress: Yup.object().shape({
    addressLine1: Yup.string().required('Required'),
    addressLine2: Yup.string(),
    addressLine3: Yup.string(),
    city: Yup.string().required('Required'),
    state: Yup.string().required('Required'),
    country: Yup.string().required('Required'),
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    zip: Yup.string().required('Required'),
  }).required('Required'),
  creditCardDetails: Yup.object().shape({
    lastFour: Yup.string().required('Required'),
    ownerShipType: Yup.string().required('Required'),
  }).required('Required'),
  paymentNonce: Yup.string().required('Required'),
  // isSavePayment: Yup.bool().required('Required'),
});

export const achSchemaPreTokenization = Yup.object().shape({
  billingAddress: Yup.object().shape({
    city: Yup.string().required('Required'),
    state: Yup.string().required('Required'),
    country: Yup.string().required('Required'),
    addressLine1: Yup.string().required('Required'),
    addressLine2: Yup.string(),
    addressLine3: Yup.string(),
    zip: Yup.string().required('Required'),
  }).default('Required'),
  bankAccountDetails: Yup.object().shape({
    accountNumber: Yup.string().required('Required'),
    routingNumber: Yup.string().required('Required'),
    accountType: Yup.string().required('Required'),
    ownerShipType: Yup.string().required('Required'),
    firstName: Yup.mixed().when('ownerShipType', (ownerShipType) => {
      if (ownerShipType === 'personal') {
        return Yup.string().required('Required');
      }
      return Yup.mixed().nullable(true);
    }),
    lastName: Yup.mixed().when('ownerShipType', (ownerShipType) => {
      if (ownerShipType === 'personal') {
        return Yup.string().required('Required');
      }
      return Yup.mixed().nullable(true);
    }),
    businessName: Yup.mixed().when('ownerShipType', (ownerShipType) => {
      if (ownerShipType === 'business') {
        return Yup.string().required('Required');
      }
      return Yup.mixed().nullable(true);
    }),
  }).required('Required'),
  // isSavePayment: Yup.bool().required('Required'),
});

export const achSchemaPostTokenization = Yup.object().shape({
  billingAddress: Yup.object(({
    city: Yup.string().required('Required'),
    state: Yup.string().required('Required'),
    country: Yup.string().required('Required'),
    addressLine1: Yup.string().required('Required'),
    addressLine2: Yup.string(),
    addressLine3: Yup.string(),
    zip: Yup.string().required('Required'),
    // firstName: Yup.string(),
    // lastName: Yup.string(),
    // companyName: Yup.string(),
  }))
    .when('bankAccountDetails.ownerShipType', (val, schema) => {
      if (val === 'business') {
        return schema.shape({
          companyName: Yup.string().required('Required'),
        });
      }
      if (val === 'personal') {
        return schema.shape({
          firstName: Yup.string().required('Required'),
          lastName: Yup.string().required('Required'),
        });
      }
    })
    .required('Required'),
  bankAccountDetails: Yup.object().shape({
    lastFour: Yup.string().required('Required'),
    ownerShipType: Yup.string().required('Required'),
  }).required('Required'),
  paymentNonce: Yup.string().required('Required'),
  // isSavePayment: Yup.bool().required('Required'),
});

const paypalSchemaPreTokenization = Yup.object().shape({});
const paypalSchemaPostTokenization = Yup.object().shape({
  paymentNonce: Yup.string().required('Required'),
});

const emptyBillingObj = {
  billingAddress: {},
  creditCardDetails: {},
  amount: '',
  billingType: '',
  paymentType: '',
  billCodes: [],
  bankAccountDetails: {},
};

function transformIncomingBillingObj(billingFromApi) {
  return null;
}

function getBillCodeSchemaFromCostAllocation(costAllocation) {
  const { billCodes, isDescriptionAllowed } = costAllocation;
  const schemaObj = billCodes.reduce((acc, billCode) => {
    const regexPattern = billCode.regex.replace(/(^\/)|(\/\w*$)/g, '');
    const regexFlags = billCode.regex.replace(/^\/[^/]*\//, '');
    const regex = new RegExp(regexPattern, regexFlags);

    const yupObj = Yup.object().shape({
      code: Yup.string()
        .test({
          name: 'valid format', message: `"${billCode.code}" is not properly formatted`, test: (val) => (val ? val.match(regex) : true), params: { regex },
        })
        .test({
          name: 'description exists',
          message: 'Invalid code',
          test() {
            return (isDescriptionAllowed && billCode.required)
              ? this.parent.description
              : true;
          },
        })
        .test('Required', 'Required', (val) => (billCode.required ? val : true)),
      description: Yup.string(),
    })
      .default(undefined);
    return {
      ...acc,
      [billCode.code]: billCode.required
        ? yupObj.required('Required')
        : yupObj.notRequired(),
    };
  }, {});

  return Yup.array().of(Yup.object().shape({
    ...schemaObj,
    allocation: Yup.string()
      .required('Required'),
  })

    .test({
      name: 'isSumCorrect',
      message: 'Allocations must sum to 100',
      test() {
        const { path } = this;
        const isSumCorrect = this.parent.reduce((sum, bc) => sum + Number(bc.allocation), 0) === 100;

        if (!isSumCorrect) {
          return this.createError({
            message: 'Allocations must sum to 100',
            path: `${path}.allocation`,
          });
        }

        return true;
      },
    }).test({
      name: 'isCorrectValue',
      message: 'Allocations must be a number and must not be 0 or over 100',
      test(val) {
        const { path } = this;

        if (Number.isNaN(Number(val.allocation)) || Number(val.allocation) <= 0 || Number(val.allocation) > 100) {
          return this.createError({
            message: 'Allocations must be a number and must not be 0 or over 100',
            path: `${path}.allocation`,
          });
        }

        return true;
      },
    }));
}

function getBillCodeSchemaFromCostCodeProfile(costAllocation) {
  const { billCodes, isDescriptionAllowed } = costAllocation;
  const schemaObj = billCodes.reduce((acc, billCode) => {
    const regexPattern = billCode.regex.replace(/(^\/)|(\/\w*$)/g, '');
    const regexFlags = billCode.regex.replace(/^\/[^/]*\//, '');
    const regex = new RegExp(regexPattern, regexFlags);

    const yupObj = Yup.object()
      .shape({
        code: Yup.string()
          .test({
            name: 'valid format',
            message: `"${billCode.code}" is not properly formatted`,
            test: (val) => (val ? val.match(regex) : true),
            params: { regex },
          })
          .test({
            name: 'description exists',
            message: 'Invalid code',
            test() {
              return isDescriptionAllowed && billCode.required
                ? this.parent.description
                : true;
            },
          })
          .test('Required', 'Required', (val) => (billCode.required ? val : true)),
        description: Yup.string(),
      })
      .default(undefined);
    return {
      ...acc,
      [billCode.code]: billCode.required
        ? yupObj.required('Required')
        : yupObj.notRequired(),
    };
  }, {});

  return Yup.array().of(
    Yup.object()
      .shape({
        ...schemaObj,
      }),

  );
}
function getCostCodeProfileSchema(costAllocation) {
  const billCodeSchema = getBillCodeSchemaFromCostCodeProfile(costAllocation);
  const schema = Yup.object({
    billCodes: billCodeSchema.required('Required'),
    profileName: Yup.string().required('Required'),
    costCodeId: Yup.string(),
    isDefault: Yup.bool().required('Required'),

  });

  return schema;
}

function getBillingSchema(costAllocation, { postPayment = false } = {}) {
  const billCodeSchema = getBillCodeSchemaFromCostAllocation(costAllocation);
  const schema = Yup.object(postPayment
    ? { ...commonBillingSchemaPostPayment }
    : { ...commonBillingSchemaPrePayment })
    .when(['.paymentType', '.paymentToken'], (paymentType, paymentToken, curSchema) => {
      if (paymentType !== 'costCode'
      && paymentToken) {
        return curSchema.shape({
          ...(savedPaymentOptionSchema.fields),
        });
      }
      switch (paymentType) {
        case 'costCode':
          return curSchema.shape({
            billCodes: billCodeSchema.required('Required'),
            isSavePayment: Yup.bool().required('Required'),
          });
        case 'creditCard':
          if (postPayment) {
            return curSchema.shape({
              ...(creditCardSchemaPostTokenization.fields),
            });
          }
          return curSchema.shape({
            ...(creditCardSchemaPreTokenization.fields),
          });
        case 'ach':
          if (postPayment) {
            return curSchema.shape({
              ...(achSchemaPostTokenization.fields),
            });
          }
          return curSchema.shape({
            ...(achSchemaPreTokenization.fields),
          });
        case 'payPal':
          if (postPayment) {
            return curSchema.shape({
              ...(paypalSchemaPostTokenization.fields),
            });
          }
          return curSchema.shape({
            ...(paypalSchemaPreTokenization.fields),
          });
        default:
          return curSchema;
      }
    })
    .when('.billingType', (val, curSchema) => {
      if (val === 'billOtherAccount') {
        return curSchema.shape({
          accountNumber: Yup.string().required('Required'),
          country: Yup.string().required('Required'),
          zip: Yup.string().required('Required'),
        });
      }
      return curSchema;
    });

  return schema;
}

function formatBillingForSubmission(billingDetails) {
  const formattedBillingDetails = _.cloneDeep(billingDetails);
  formattedBillingDetails.billingAddress = sanitizeAddress(formattedBillingDetails.billingAddress);
  if (formattedBillingDetails.paymentType === 'costCode') {
    const { billCodes } = formattedBillingDetails;
    formattedBillingDetails.billCodes = billCodes
      .map((bca) => _.pickBy(bca, (val, key) => (typeof val === 'string' && key !== 'key') || !!val.code));
    formattedBillingDetails.paymentToken = '';
  }
  if (formattedBillingDetails.paymentType === 'creditCard') {
    //
  }
  if (formattedBillingDetails.paymentType === 'payPal') {
    //
  }
  if (formattedBillingDetails.paymentType === 'ach') {
    const { bankAccountDetails = {} } = formattedBillingDetails;
    if (bankAccountDetails.ownerShipType === 'business') {
      formattedBillingDetails.billingAddress.companyName = bankAccountDetails.businessName;
    }
    if (bankAccountDetails.ownerShipType === 'personal') {
      formattedBillingDetails.billingAddress.firstName = bankAccountDetails.firstName;
      formattedBillingDetails.billingAddress.lastName = bankAccountDetails.lastName;
    }
  }
  if (formattedBillingDetails.billingType !== 'billOtherAccount') {
    //
  }
  return formattedBillingDetails;
}

export {
  emptyBillingObj,
  getBillCodeSchemaFromCostAllocation,
  transformIncomingBillingObj,
  getBillingSchema,
  formatBillingForSubmission,
  getCostCodeProfileSchema,
};
