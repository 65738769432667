import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { Grid, Typography } from '@material-ui/core';
import NewOrderCustomTextField from '../../common/NewOrderCustomTextField';
import { FORM_FIELDS, FORM_LABELS } from '../itemDetailsConstants';
import { blockWeightAndDimensionInputs, isFieldEditable } from '../itemDetailsUtil';
import { useItemProductDetailsFormStyles } from '../itemDetailsStyles';

export default function TariffCode({ editableFields, isRequired }) {
  const classes = useItemProductDetailsFormStyles();

  return (
    <Grid item container direction="column" spacing={1}>
      <Grid item>
        <Typography color="primary" className={classes.formFieldHeader}>{FORM_LABELS.tariffCode}</Typography>
      </Grid>
      <Grid item container spacing={1}>
        <Grid item>
          <Field
            name={FORM_FIELDS.tariffCode.key}
            label={FORM_FIELDS.tariffCode.label}
            component={NewOrderCustomTextField}
            blockValue={blockWeightAndDimensionInputs}
            type="text"
            style={{ width: '100%' }}
            disabled={isFieldEditable(FORM_FIELDS.tariffCode.key, editableFields)}
            validate={(val) => {
              if (!(val) && isRequired) {
                return 'Required';
              }
              return undefined;
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

TariffCode.propTypes = {
  editableFields: PropTypes.arrayOf(PropTypes.string).isRequired,
  isRequired: PropTypes.bool.isRequired,
};
