import React from 'react';
import { Form, Formik } from 'formik';
import {
  Grid,
  FormControl, FormControlLabel, FormLabel,
  Radio, RadioGroup, Typography,
  CircularProgress,
} from '@material-ui/core/';
import * as Yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';
import * as colors from '../../styles/colors';
import NewOrderNextButton from '../common/NewOrderNextButton';
import CustomsBrokerTabs from './CustomsBrokerTabs';
import { disableSubmitOnEnterKey } from '../../utils/helpers';

const styles = {
  innerFormContainer: {
    padding: 15,
    margin: '10px 0',
    background: colors.darkBlueBackground,
  },
  formFieldHeader: {
    fontWeight: 500,
    fontSize: '14px',
  },
  miscLoadingContainer: {
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingTop: 24,
  },
};

const useStyles = makeStyles(styles);

export default function CustomsBroker({
  submitFormToApi, shipment, onChange,
  openNextPanel,
  ...rest
}) {
  const isCustomsBrokerAvailable = shipment?.customsBroker?.isCustomsBrokerAvailable || false;
  
  const init = () => ({

  });

  const validationSchema = Yup.object().shape({

  });

  const classes = useStyles();

  const options = [
    {
      value: 'yes',
      label: 'Yes, I want to add a Customs Broker',
    },
    {
      value: 'no',
      label: 'No, I don’t to add a Customs Broker',
    },
  ];

  const [selected, setSelected] = React.useState(
    (() => {
      switch (isCustomsBrokerAvailable) {
        case true:
          return options[0].value;
        case false:
          return options[1].value;
        default: 
          return null;
      }
    })()
  );
  
  const onSubmit = async (values, {errors, setSubmitting }) => {
    const customsBrokerDetails = {
      isCustomsBrokerAvailable: false,
    };

    try {
      await submitFormToApi(customsBrokerDetails);
      openNextPanel();
    } catch (e) {

    }
    setSubmitting(false);
  };

  return (
    <Grid item container direction="column">
      <Grid item container direction="column" spacing={2}>
        <Grid item>
          <FormControl component="fieldset">
            <FormLabel component="legend">
              <Grid item container alignItems="center" spacing={2}>
                <Grid item>
                  <Typography color="primary" className={classes.formFieldHeader}>Do you want to add a Customs Broker?</Typography>
                </Grid>
              </Grid>
            </FormLabel>
            <RadioGroup
                aria-label="customsBrokerRequired"
                name="customsBrokerRequired"
                value={selected}
                onChange={(e) => {
                  setSelected(e.target.value);
                  // onChange && onChange(selected);
                }}
              >
                {options.map((option) => (
                  <FormControlLabel
                    key={option.value}
                    style={{ padding: '0 10px' }}
                    value={option.value}
                    control={<Radio style={{ color: colors.white }} />}
                    label={<Typography color="primary">{option.label}</Typography>}
                  />
                ))}
              </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
      {(() => {
        switch (selected) {
          case 'yes':
            return (
              <CustomsBrokerTabs
                submitFormToApi={submitFormToApi}
                shipment={shipment}
                openNextPanel={openNextPanel}
                {...rest}
              />
            );
          case 'no':
            return (
              <Formik
                initialValues={init()}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {(formikProps) => (
                  <Form style={{ width: '97%' }} onKeyDown={disableSubmitOnEnterKey}>
                    <Grid item container direction="column" spacing={2}>
                      <Grid item container alignItems="center" spacing={4} className={classes.innerFormContainer}>
                        <Grid item>
                          <Typography color="primary" className={classes.formFieldHeader}>The Shipping Company will assign a Customs Broker at additional fee</Typography>
                        </Grid>
                      </Grid>
                      <Grid item container justify="flex-start">
                        <Grid item>
                          <NewOrderNextButton
                            className={classes.button}
                            disabled={formikProps.isSubmitting}
                            onClick={() => {
                              formikProps.submitForm();
                            }}
                          >
                            Next
                          </NewOrderNextButton>
                        </Grid>
                      </Grid>
                      {formikProps.isSubmitting && (
                        <Grid item container justify="center" classes={{ root: classes.miscLoadingContainer }}>
                          <CircularProgress color="secondary" />
                        </Grid>
                      )}
                    </Grid>
                  </Form>
                )}
              </Formik>
            );
          default:
            return null;
        }
      })()}
    </Grid>
  );
};

