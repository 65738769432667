import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import StyledCheckbox from './StyledCheckbox';
import * as colors from '../../styles/colors';

const useStyles = makeStyles(() => ({
  label: {
    color: colors.textLightGrey,
    fontSize: '14.75px',
    fontWeight: 425,
  },
}));

function ReadOnlyCheckbox({ label, value }) {
  const classes = useStyles();

  return (
    <FormControlLabel
      className={classes.label}
      control={(
        <StyledCheckbox
          color="default"
          checked={value}
          disabled
        />
      )}
      label={label}
    />
  );
}

ReadOnlyCheckbox.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
};

export default memo(ReadOnlyCheckbox);
