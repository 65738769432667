import React from 'react';
import {
  Grid, Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import { css } from 'emotion';
import { getName } from 'country-list';
import { get } from 'lodash';
import {
  CustomText, CustomCheckbox, CustomRadio,
} from '../common/InputComponents';
import ACHForm from '../common/ACHForm';
import AddressForm from '../common/AddressForm';
import * as colors from '../../styles/colors';

import { USE_DEFAULT_ADDRESS, ACH_BILLING_FIELDS, NEW_ACCOUNT_OPTION } from './constants';

const ACH_DETAILS = [
  { id: 0, name: 'bankAccountDetails.lastFour', label: 'Account description' },
];

const styles = {
  savedCardDetails: {
    fontSize: '14px',
    padding: '2px 0',
  },
};
const useStyles = makeStyles(styles);

export default function CustomACH(props) {
  const {
    clientInstance, setUSBankAccountInstance, formikRef,
    lockPayment, defaultSender, savedAccounts,
  } = props;

  const classes = useStyles();

  function getSavedAccountDisplay(account, selectedPaymentToken) {
    return (
      <Grid container direction="column" style={{ marginTop: 9 }}>
        <Grid item container spacing={1} wrap="nowrap">
          <Grid item container alignItems="center">
            <Typography style={{ fontWeight: '500', fontSize: '14px', paddingLeft: 2 }}>
              {`${account.bankAccountDetails.bankName} account ending in ${account.bankAccountDetails.lastFour}`}
            </Typography>
          </Grid>
        </Grid>
        {selectedPaymentToken === account.paymentToken
        && account.billingAddress
          ? (
            <Grid item container direction="column" style={{ marginBottom: 5, marginTop: 10 }}>
              <Typography color="primary" style={{ fontWeight: '500', fontSize: '16px' }}>
                Billing Address
              </Typography>
              <Grid item>
                <Typography className={classes.savedCardDetails}>
                  {account.billingAddress.firstName}
                  {' '}
                  {account.billingAddress.lastName}
                </Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.savedCardDetails}>
                  {account.billingAddress.addressLine1}
                </Typography>
              </Grid>
              {account.billingAddress.addressLine2 && (
              <Grid item>
                <Typography className={classes.savedCardDetails}>
                  {account.billingAddress.addressLine2}
                </Typography>
              </Grid>
              )}
              {account.billingAddress.addressLine3 && (
              <Grid item>
                <Typography className={classes.savedCardDetails}>
                  {account.billingAddress.addressLine3}
                </Typography>
              </Grid>
              )}
              <Grid item>
                <Typography className={classes.savedCardDetails}>
                  {account.billingAddress.city}
                  {', '}
                  {account.billingAddress.state}
                  {' '}
                  {account.billingAddress.zip}
                </Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.savedCardDetails}>
                  {getName(account.billingAddress.country) || account.billingAddress.country}
                </Typography>
              </Grid>
              {account.billingAddress.phone && (
              <Grid item>
                <Typography className={classes.savedCardDetails}>
                  {account.billingAddress.phone}
                </Typography>
              </Grid>
              )}
            </Grid>
          ) : null}
      </Grid>
    );
  }

  const savedAccountsPanel = (savedAccounts && savedAccounts.length > 0)
    ? (
      <Grid item container direction="column">
        <Typography
          id="saved-bank-accounts"
          className={css`
              color: ${colors.white};
              font-weight: 500;
              font-size: 18px;
              margin-bottom: 15px;
          `}
        >
          Saved Bank Accounts
        </Typography>
        <Field>
          {
          ({ form }) => (
            <CustomRadio
              id="paymentToken"
              disabled={lockPayment}
              classes={{
                radioLabel: {
                  root: css`
                align-items: flex-start
              `,
                },
              }}
              options={savedAccounts.map((account) => (
                {
                  id: account.paymentToken,
                  label: getSavedAccountDisplay(account, form.values.paymentToken),
                  value: account.paymentToken,
                }
              ))
                .concat([{
                  id: NEW_ACCOUNT_OPTION.id,
                  label: <Grid style={{ marginTop: 9 }}>{NEW_ACCOUNT_OPTION.label}</Grid>,
                  value: NEW_ACCOUNT_OPTION.value,
                }])}
            />
          )
          }
        </Field>
      </Grid>
    )
    : null;

  return (
    <Grid container direction="column" spacing={2}>
      {savedAccountsPanel}
      <Field>
        {({ form }) => ((form.values.paymentToken === NEW_ACCOUNT_OPTION.value) ? (
          <>
            <Grid item>
              <Typography color="primary" style={{ fontSize: 18, fontWeight: '500', marginBottom: 15 }}>
                Bank Account Details
              </Typography>
            </Grid>
            {lockPayment ? ACH_DETAILS.map((details) => {
              const { id, name, label } = details;
              return (
                <Grid item key={`ach-details-${id}`}>
                  <CustomText
                    name={name}
                    label={label}
                    type="text"
                    disabled
                  />
                </Grid>
              );
            })
              : (

                <ACHForm
                  clientInstance={clientInstance}
                  setUSBankAccountInstance={setUSBankAccountInstance}
                  lockPayment={lockPayment}
                />

              )}
            <Grid item>
              <Typography
                color="primary"
                style={{
                  fontSize: 18, fontWeight: '500', marginBottom: 15, marginTop: 20,
                }}
              >
                Billing Address
              </Typography>
            </Grid>
            {defaultSender
              ? (
                <CustomCheckbox
                  name={USE_DEFAULT_ADDRESS}
                  label={'Use my profile\'s default address'}
                  disabled={lockPayment}
                />
              ) : null}
            <Grid item>
              <Field>
                {({ form }) => {
                  const { setFieldValue, values } = form;
                  const changeUseDefaultAddress = () => {
                    if (values[USE_DEFAULT_ADDRESS]) {
                      setFieldValue(USE_DEFAULT_ADDRESS, false);
                    }
                  };
                  const upperFields = ACH_BILLING_FIELDS.filter((field) => !['state', 'zip'].includes(field));
                  return (
                    <>
                      <AddressForm
                        fields={upperFields}
                        fieldNames={ACH_BILLING_FIELDS
                          .reduce((acc, field) => ({ ...acc, [field]: `billingAddress.${field}` }), {})}
                        callback={changeUseDefaultAddress}
                        other={{
                          formikRef,
                          classes: {},
                          ...(lockPayment && { disabled: upperFields.map((field) => `billingAddress.${field}`) }),
                        }}
                      />
                      <Grid container spacing={3}>
                        <Grid item xs={6}>
                          <AddressForm
                            fields={['state']}
                            fieldNames={{ state: 'billingAddress.state' }}
                            other={{
                              formikRef,
                              classes,
                              ...(lockPayment && { disabled: ['billingAddress.state'] }),
                              country: get(form.values, 'billingAddress.country'),
                            }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <AddressForm
                            fields={['zip']}
                            fieldNames={{ zip: 'billingAddress.zip' }}
                            other={{
                              formikRef,
                              classes,
                              ...(lockPayment && { disabled: ['billingAddress.zip'] }),
                            }}
                          />
                        </Grid>
                      </Grid>
                    </>
                  );
                }}
              </Field>
            </Grid>
            <Grid item>
              <CustomCheckbox
                name="isSavePayment"
                label="Save account to profile"
                disabled={lockPayment}
              />
            </Grid>
          </>
        ) : null)}
      </Field>
    </Grid>
  );
}

CustomACH.propTypes = {
  clientInstance: PropTypes.objectOf(PropTypes.any).isRequired,
  setUSBankAccountInstance: PropTypes.func.isRequired,
  formikRef: PropTypes.objectOf(PropTypes.any).isRequired,
  lockPayment: PropTypes.bool,
  defaultSender: PropTypes.objectOf(PropTypes.any),
  savedAccounts: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
};
CustomACH.defaultProps = {
  lockPayment: false,
  defaultSender: null,
  savedAccounts: [],
};
