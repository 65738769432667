/* eslint-disable max-len */
import React from 'react';
import get from 'lodash/get';
import round from 'lodash/round';
import replace from 'lodash/replace';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useSingleOrderState } from '../context/singleOrderContext';
import { useUserState } from '../context/userContext';

const AESFillingFormFields = React.forwardRef(({
  shipmentId, dhs,
}, ref) => {
  const user = useUserState();
  const orderState = useSingleOrderState();
  const shipment = get(orderState, `shipments[${shipmentId}]`, {});
  
  const products = get(shipment, 'product.details.items', []);

  // File form fields
  const EMAIL = get(shipment, 'origin.address.email');
  const COD = get(shipment, 'destination.address.country')
  const ST = get(shipment, 'origin.address.state');
  const EDA = moment(get(shipment, 'carrier.details.expectedShipDate')).format('MM/DD/YYYY');
  const SCAC = get(shipment, 'carrier.details.iataCode');
  const VN = get(shipment, 'carrier.details.carrierName');
  const [MOT] = get(shipment, 'carrier.details.modeOfTransport', '').split('-');
  
  
  window.replace = replace;
  const AD0_1 = get(user, 'accountAddress.companyName');
  const AD0_2 = get(user, 'accountAddress.id');
  const AD0_3 = get(user, 'accountAddress.idNumberType', 'E');
  const AD0_4 = get(user, 'accountAddress.addressLine1');
  const AD0_5 = get(user, 'accountAddress.addressLine2');
  const AD0_6 = get(user, 'accountAddress.city');
  const AD0_7 = get(user, 'accountAddress.state');
  const AD0_8 = get(user, 'accountAddress.zip');
  const [AD0_9, AD0_11] = get(user, 'accountAddress.name', ' ').split(' ');
  const AD0_12 = get(user, 'accountAddress.phone');

  const AD1_3 = get(shipment, 'destination.address.companyName');
  const AD1_5 = get(shipment, 'destination.address.name');
  const AD1_7 = get(shipment, 'destination.address.phone');
  const AD1_8 = get(shipment, 'destination.address.addressLine1');
  const AD1_9 = get(shipment, 'destination.address.addressLine2');
  const AD1_10 = get(shipment, 'destination.address.city');
  const AD1_11 = get(shipment, 'destination.address.state');
  const AD1_12 = get(shipment, 'destination.address.country');
  const AD1_13 = get(shipment, 'destination.address.zip');
  const AD1_14 = 'O';

  const AD4_3 = get(shipment, 'customsBroker.address.companyName', '');
  const AD4_5 = get(shipment, 'customsBroker.address.name', '');
  const AD4_7 = get(shipment, 'customsBroker.address.phone', '');
  const AD4_8 = get(shipment, 'customsBroker.address.addressLine1', '');
  const AD4_9 = get(shipment, 'customsBroker.address.addressLine2', '');
  const AD4_10 = get(shipment, 'customsBroker.address.city', '');
  const AD4_11 = get(shipment, 'customsBroker.address.state', '');
  const AD4_12 = get(shipment, 'customsBroker.address.country', '');
  const AD4_13 = get(shipment, 'customsBroker.address.zip', '');

  const [RCC, HAZ, RT] = Array(3).fill('NO');

  return (
    <form target="_blank" action={`${dhs}/nace/aes/aesdirect-ui/secured/createWeblinkFiling`} method="post">
      <input type="hidden" name="EMAIL" value={EMAIL} />
      <input type="hidden" name="COD" value={COD} />
      <input type="hidden" name="SRN" value={shipmentId} />
      <input type="hidden" name="ST" value={ST} />
      <input type="hidden" name="EDA" value={EDA} />
      <input type="hidden" name="SCAC" value={SCAC} />
      <input type="hidden" name="VN" value={VN} />s
      <input type="hidden" name="MOT" value={MOT} />

      <input type="hidden" name="AD0_1" value={AD0_1} />

      <input type="hidden" name="AD0_1" value={AD0_1} />
      <input type="hidden" name="AD0_2" value={AD0_2} />
      <input type="hidden" name="AD0_3" value={AD0_3} />
      <input type="hidden" name="AD0_4" value={AD0_4} />
      <input type="hidden" name="AD0_5" value={AD0_5} />
      <input type="hidden" name="AD0_6" value={AD0_6} />
      <input type="hidden" name="AD0_7" value={AD0_7} />
      <input type="hidden" name="AD0_8" value={AD0_8} />
      <input type="hidden" name="AD0_9" value={AD0_9} />
      <input type="hidden" name="AD0_11" value={AD0_11} />
      <input type="hidden" name="AD0_12" value={AD0_12} />

      <input type="hidden" name="AD1_3" value={AD1_3} />
      <input type="hidden" name="AD1_5" value={AD1_5} />
      <input type="hidden" name="AD1_7" value={AD1_7} />
      <input type="hidden" name="AD1_8" value={AD1_8} />
      <input type="hidden" name="AD1_9" value={AD1_9} />
      <input type="hidden" name="AD1_10" value={AD1_10} />
      <input type="hidden" name="AD1_11" value={AD1_11} />
      <input type="hidden" name="AD1_12" value={AD1_12} />
      <input type="hidden" name="AD1_13" value={AD1_13} />
      <input type="hidden" name="AD1_14" value={AD1_14} />

      <input type="hidden" name="AD4_3" value={AD4_3} />
      <input type="hidden" name="AD4_5" value={AD4_5} />
      <input type="hidden" name="AD4_7" value={AD4_7} />
      <input type="hidden" name="AD4_8" value={AD4_8} />
      <input type="hidden" name="AD4_9" value={AD4_9} />
      <input type="hidden" name="AD4_10" value={AD4_10} />
      <input type="hidden" name="AD4_11" value={AD4_11} />
      <input type="hidden" name="AD4_13" value={AD4_13} />
      <input type="hidden" name="AD4_12" value={AD4_12} />

      <input type="hidden" name="RCC" value={RCC} />
      <input type="hidden" name="HAZ" value={HAZ} />
      <input type="hidden" name="RT" value={RT} />

      {products.map((item, index) => {
        const i = index + 1;
        return (
          <React.Fragment key={i}>
            <input type="hidden" name={`isLine${i}`} value={`Y`} />
            <input type="hidden" name={`IT${i}_1`} value={`OS`} />
            <input type="hidden" name={`IT${i}_21`} value={get(item, 'countryOfManufacture') === 'US' ? 'D' : 'F'} />
            <input type="hidden" name={`IT${i}_2`} value={get(item, 'totalValue.amount')} />
            <input type="hidden" name={`IT${i}_7`} value={round(get(item, 'totalWeightKG'))} />
            <input type="hidden" name={`IT${i}_8`} value="" />
            <input type="hidden" name={`IT${i}_20`} value={get(item, 'eccn')} />
            <input type="hidden" name={`IT${i}_12`} value={get(item, 'description')} />
            <input type="hidden" name={`IT${i}_3`} value={get(item, 'units.unitOfMeasurement')} />
            <input type="hidden" name={`IT${i}_4`} value={get(item, 'units.noOfUnits')} />
            <input type="hidden" name={`IT${i}_13`} value={replace(get(item, 'harmonizedCode', ''), /\./g, '')} />
          </React.Fragment>
        );
      })}

      <button type="submit" ref={ref} style={{display: 'none'}}>File Submit</button>
    </form>                
  );
});

AESFillingFormFields.propTypes = {
  shipmentId: PropTypes.string.isRequired,
  dhs: PropTypes.string.isRequired,
};

export default AESFillingFormFields;
