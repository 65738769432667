import React from 'react';
import { Grid } from '@material-ui/core';
import { Field } from 'formik';
import PropTypes from 'prop-types';

import PaypalDropIn from '../common/PaypalDropIn';

export default function CustomPaypal(props) {
  const { clientInstance } = props;
  return (
    <Grid container>
      <Field>
        {({ form }) => (
          <PaypalDropIn
            amount={form.values.amount}
            currencyCode={form.values.currencyCode}
            submit={form.submitForm}
            setFieldValue={form.setFieldValue}
            clientInstance={clientInstance}
          />
        )}
      </Field>
    </Grid>
  );
}

CustomPaypal.propTypes = {
  clientInstance: PropTypes.objectOf(PropTypes.any).isRequired,
};
