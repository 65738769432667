import client from './apiClient';
import {
  DUMMY_FETCH_ACCOUNT_COST_ALLOCATION,
  DUMMY_FETCH_BILL_CODES,
  DUMMY_FETCH_PAYMENT_RECEIPT,
  DUMMY_FETCH_SAVED_PAYMENT_OPTIONS,
  DUMMY_FETCH_USER_COST_CODE,
} from '../dummyData';

function loadAccountCostAllocation() {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return Promise.resolve(DUMMY_FETCH_ACCOUNT_COST_ALLOCATION);
    // return new Promise((resolve) => {
    //   setTimeout(() => {
    //     resolve(DUMMY_FETCH_ACCOUNT_COST_ALLOCATION);
    //   }, 3000);
    // });
  }
  return client('/data/costallocation', { method: 'get' });
}

function loadUserCostCodeProfile() {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return Promise.resolve(DUMMY_FETCH_USER_COST_CODE);
    // return new Promise((resolve) => {
    //   setTimeout(() => {
    //     resolve(DUMMY_FETCH_ACCOUNT_COST_ALLOCATION);
    //   }, 3000);
    // });
  }
  return client('/data/costcodeprofile', { method: 'get' });
}

function loadBillCodes(billCodeType, filter) {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    const billCodes = DUMMY_FETCH_BILL_CODES[billCodeType] || [];
    return Promise.resolve(billCodes
      .filter((billCode) => (billCode.code.toLowerCase().includes(filter.toLowerCase())
        || (!!billCode.description
            && billCode.description.toLowerCase().includes(filter.toLowerCase())))));
  }
  return client(`/data/payment/billcode?billCodeType=${billCodeType}&filter=${filter}`, { method: 'get' });
}

function loadSavedPaymentOptions() {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return Promise.resolve(DUMMY_FETCH_SAVED_PAYMENT_OPTIONS);
    // return new Promise((resolve) => {
    //   setTimeout(() => {
    //     resolve(DUMMY_FETCH_SAVED_PAYMENT_OPTIONS);
    //   }, 3000);
    // });
  }
  return client('/data/payment/paymentMethods', { method: 'get' });
}

async function updatePaymentMethod(paymentToken, payload) {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(null);
      }, 1000);
    });
  }
  return client(`/data/payment/paymentMethod/${paymentToken}`, { method: 'put', data: payload });
}
async function addPaymentMethod(payload) {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(null);
      }, 1000);
    });
  }
  return client('/data/payment/paymentMethod', { method: 'post', data: payload });
}

async function deletePaymentMethod(paymentToken) {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(null);
      }, 1000);
    });
  }
  return client(`/data/payment/paymentMethod/${paymentToken}`, { method: 'delete' });
}

async function getPaymentReceipt(shipmentId, transactionId) {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(DUMMY_FETCH_PAYMENT_RECEIPT);
      }, 2000);
    }).then(({ paymentReceipt }) => paymentReceipt);
  }
  return client('/data/payment/paymentReceipt', { method: 'post', data: { shipmentId, transactionId } })
    .then(({ paymentReceipt }) => paymentReceipt);
}

async function setDefaultPayment(paymentToken) {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(null);
      }, 1000);
    });
  }
  return client(`/data/payment/paymentMethod/default/${paymentToken}`, { method: 'put' });
}

async function updateCostCodeProfile(costCodeId, payload) {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(null);
      }, 1000);
    });
  }
  return client(`/data/costcodeprofile/${costCodeId}`, {
    method: 'put',
    data: payload,
  });
}
async function addCostCodeProfile(payload) {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(null);
      }, 1000);
    });
  }
  return client('/data/costcodeprofile', {
    method: 'post',
    data: payload,
  });
}

async function deleteCostCodeProfile(costCodeId) {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(null);
      }, 1000);
    });
  }
  return client(`/data/costcodeprofile/${costCodeId}`, {
    method: 'delete',
  });
}

async function setDefaultCostCodeProfile(costCodeId) {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(null);
      }, 1000);
    });
  }
  return client(`/data/costcodeprofile/default/${costCodeId}`, {
    method: 'put',
  });
}
export {
  loadAccountCostAllocation,
  loadUserCostCodeProfile,
  loadBillCodes,
  loadSavedPaymentOptions,
  updatePaymentMethod,
  addPaymentMethod,
  deletePaymentMethod,
  getPaymentReceipt,
  setDefaultPayment,
  updateCostCodeProfile,
  addCostCodeProfile,
  deleteCostCodeProfile,
  setDefaultCostCodeProfile,
};
