import React, { useState } from 'react';
import {
  BrowserRouter as Router,
} from 'react-router-dom';
import { CssBaseline, CircularProgress } from '@material-ui/core';
import UnauthenticatedApp from './UnauthenticatedApp';
import AuthenticatedApp from './AuthenticatedApp';
import { GlobalStyle } from './components/common';
import { useAuth } from './context/authContext';
import * as colors from './styles/colors';
import { AuthenticatedProviders } from './context';
import AccountContext from './context/accountContext';
import { loadAccount, updateAccount } from './utils/accountClient';

function FullPageSpinner() {
  return (
    <div
      style={{
        background: colors.background,
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress color="secondary" data-testid="progressbar" />
    </div>
  );
}

export default function App() {
  const { user } = useAuth();
  const [account, setAccount] = useState({
    info: null,
    isLoading: true,
    update: (acc, key, updatedInfo) => {
      setAccount({
        ...account,
        isLoading: false,
        info: {
          ...acc.info,
          ...updatedInfo,
        },
      });

      return updateAccount(key, updatedInfo);
    },
  });
  const authenticated = user.hasLoaded && user.isAuthenticated && !user.loading;

  React.useEffect(() => {
    const script1 = document.createElement('script');
    script1.type = 'text/javascript';
    script1.src = '/browserprint-zebra.js';
    script1.async = true;
    document.body.appendChild(script1);
    const script2 = document.createElement('script');
    script2.type = 'text/javascript';
    script2.src = '/browserprint.js';
    script2.async = true;
    document.body.appendChild(script2);
  }, []);

  React.useEffect(() => {
    if (authenticated) {
      loadAccount().then((response) => {
        setAccount({
          ...account,
          isLoading: false,
          info: response,
        });
      }).catch((e) => {
        console.log('Error getting account', e);

        setAccount({
          ...account,
          isLoading: false,
          info: null,
        });
      });
    }
  }, [user]);

  return (
    <Router>
      <CssBaseline />
      <GlobalStyle />
      {user.loading && <FullPageSpinner />}
      {authenticated && (
        <AccountContext.Provider value={account}>
          <AuthenticatedProviders>
            <AuthenticatedApp profile={user.profile} isEmptyDefaultAddress={user.isAuthenticated ? user?.isEmptyDefaultAddress : false} />
          </AuthenticatedProviders>
        </AccountContext.Provider>
      )}
      {!user.isAuthenticated && !user.loading && <UnauthenticatedApp />}
    </Router>
  );
}
