import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Route, Link } from 'react-router-dom';
import { css } from 'emotion';
import {
  Grid,
  Typography,
  Button,
  IconButton,
  Tooltip,
  Link as MuiLink,
} from '@material-ui/core/';
import AlarmOutlinedIcon from '@material-ui/icons/AlarmOutlined';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import OutlinedTruckIcon from '@material-ui/icons/LocalShippingOutlined';
import AddToPhotosOutlinedIcon from '@material-ui/icons/AddToPhotosOutlined';
import * as colors from '../styles/colors';
import ConfirmationModal from './common/ConfirmationModal';
import RedirectButton from './common/RedirectButton';
import ErrorDialog from './common/NewOrderErrorDialog';
import UserAvatar from './common/UserAvatar';
import {
  determineStatusClass,
  generateShipmentCardMessage,
  formatDateLong,
  formatMonetaryAmount,
} from '../utils/helpers';
import statusHelp from '../utils/status';
import { SHIPMENT_TRACKING_TAB, IOT_TRACKING_TAB } from '../clientConstants';
import iotIcon from './images/rss.svg';
import * as shipmentClient from '../utils/shipmentClient';

const useStyles = makeStyles((theme) => ({
  centeredContainer: {
    alignItems: 'center',
  },
  tile: {
    backgroundColor: colors.shipmentCardBackground,
    padding: '8px',
    borderRadius: '10px',
    minHeight: '96px',
    marginBottom: '10px',
    width: '100%',
    position: 'relative',
  },
  shipmentHeading: {
    color: '#B5B9CC',
    fontSize: '12px',
    fontWeight: 500,
    marginTop: '10px',
    marginBottom: '7px',
  },
  shipmentIdHeader: {
    color: 'rgba(255,255,255,0.6)',
    letterSpacing: 0.4,
  },
  shipmentInfoHeader: {
    letterSpacing: 2,
  },
  link: {
    textDecoration: 'none',
    '&:visited': {
      textDecoration: 'none',
    },
  },
  trackShipment: {
    color: colors.secondaryButton,
    fontSize: '14px',
    fontWeight: '500',
    textTransform: 'none',
  },
  trackingButton: {
    padding: '0px 0px 0px 0px',
    color: '#94CCFB',
  },
  statusMessage: {
    paddingLeft: 8,
  },
  shipmentInformation: {
    color: 'white',
    fontSize: '14px',
  },
  allCapsWide: {
    textTransform: 'uppercase',
    letterSpacing: '1.2',
  },
  shipmentButton: {
    width: '137px',
    borderRadius: '5px',
  },
  boldHeading: {
    // fontWeight: 600,
  },
  onTimeHeading: {
    color: '#A7F4B4',
  },
  delayedHeading: {
    color: '#FED599',
  },
  incompleteHeading: {
    color: '#FF9FB4',
  },
  deliveredHeading: {
    color: '#B5B9CC',
  },
  buttonDiv: {
    alignItems: 'center',
    display: 'inherit',
  },
  onTimeBorder: {
    borderLeft: '3px solid #A7F4B4',
  },
  delayedBorder: {
    borderLeft: '3px solid #FED599',
  },
  incompleteBorder: {
    borderLeft: '3px solid #FF9FB4',
  },
  shipmentCardColumn: {
    padding: '0 2px',
    height: '100%',
  },
  shipmentCardButton: {
    alignItems: 'center',
    textAlign: 'center',
    height: '100%',
  },
  duplicateIconContainer: {
    alignSelf: 'flex-start',
  },
  duplicateIcon: {
    // position: 'relative',
    // top: '8px',
    // right: '8px',
    transform: 'rotate(180deg)',
    padding: '0',
  },
  tooltip: {
    background: 'transparent',
    fontSize: 12,
  },
}));

// Util functions
function getMainTrackingNumber(shipment) {
  if (statusHelp.isCancelled(shipment.status)) return null;
  if (shipment.masterTrackingNumber) return shipment.masterTrackingNumber;
  if (shipment.trackingNumbers && shipment.trackingNumbers.length) {
    return shipment.trackingNumbers[0];
  }
  return null;
}

// function getButtonTextByStatus(status) {
//   return (status.toUpperCase() === 'CREATED') ? 'EDIT' : 'VIEW';
// }

function trimShipmentId(shipmentId) {
  return shipmentId.split('-').pop();
}

function ViewShipmentButton(props) {
  const { label, shipmentId } = props;

  const hovered = css`
    background: ${colors.mediumBlueBackground};
  `;

  const buttonStyle = css({
    fontWeight: 'bold',
    margin: 'auto',
    maxWidth: ' 98px',
    color: `${colors.secondaryButton}`,
    borderRadius: '8px',
    backgroundColor: `${colors.shipmentCardBackground}`,
    border: `1px solid ${colors.buttonBackgroundBlue}`,
    boxShadow: 'none',
    ':hover': hovered,
    width: '100%',
  });
  return (
    <Route
      render={({ history }) => (
        <Button
          className={buttonStyle}
          variant="contained"
          onClick={() => {
            history.push(`/order/${shipmentId}`, { shipment: shipmentId });
          }}
        >
          {label}
        </Button>
      )}
    />
  );
}

ViewShipmentButton.propTypes = {
  label: PropTypes.string.isRequired,
  shipmentId: PropTypes.string.isRequired,
};

const StyledWarningIcon = withStyles({
  root: { color: colors.warningYellow },
})(ReportProblemOutlinedIcon);
export function ShipmentStatusIcon(props) {
  const { status, colorClass } = props;
  switch (status.toUpperCase()) {
    case 'SCHEDULED FOR PICKUP':
      return <AlarmOutlinedIcon className={colorClass} />;
    case 'IN-TRANSIT':
      return <AlarmOutlinedIcon className={colorClass} />;
    case 'DELAYED':
      return <ErrorOutlineOutlinedIcon className={colorClass} />;
    case 'CREATED':
      return <ErrorOutlineOutlinedIcon className={colorClass} />;
    case 'CANCELLED':
      return <ErrorOutlineOutlinedIcon className={colorClass} />;
    case 'COMPLETED':
      return <CheckCircleOutlineOutlinedIcon className={colorClass} />;
    case 'DELIVERED':
      return <CheckCircleOutlineOutlinedIcon className={colorClass} />;
    case 'COMPLIANCE FAILURE':
      return <StyledWarningIcon className={colorClass} />;

    default:
      return <InfoOutlinedIcon />;
  }
}

ShipmentStatusIcon.propTypes = {
  status: PropTypes.string.isRequired,
  colorClass: PropTypes.string,
};
ShipmentStatusIcon.defaultProps = {
  colorClass: '',
};

function displayTracking(status) {
  return !/compliance failure/i.test(status);
}

export default function ShippingManifestCard(props) {
  const { shipment, openTrackingModal, lastCardRef } = props;
  const classes = useStyles();
  const {
    cancelDate,
    carrier,
    city,
    company,
    deliveryDateTime,
    deliveryNo,
    dimension,
    freight,
    invoiceNo,
    location,
    packageNumber,
    poNo,
    receiptContactName,
    service,
    shipDate,
    state,
    status,
    tracking,
    uom,
    weight,
    zipCode,
    createdBy,
    shipmentId, orderId, iot, iotTrackingNumber,
  } = shipment;

  const [duplicating, setDuplicating] = React.useState(null);
  const [error, setError] = React.useState(null);
  const confirmDuplicateModalOpen = duplicating === 'confirm';
  const openNewShipmentModalOpen = duplicating && duplicating !== 'confirm';

  // Adds dynamic css for class heading
  const statusColorClass = determineStatusClass(shipment);
  const statusHeadingClass = [
    classes.shipmentInformation,
    classes.boldHeading,
    classes[statusColorClass.statusClass],
  ].join(' ');
  const recipientHeadingClass = [
    classes.shipmentInformation,
    classes.boldHeading,
  ].join(' ');

  const mainTrackingNumber = getMainTrackingNumber(shipment);
  // const viewButtonLabel = getButtonTextByStatus(status);

  async function onDuplicateShipment(currentShipmentId) {
    const newOrder = await shipmentClient
      .duplicateShipment({ shipmentId: currentShipmentId })
      .catch(() => {
        setError(
          JSON.stringify({
            error: 'Error duplicating shipment. Please try again',
          }),
        );
      });
    try {
      const newShipmentId = newOrder.shipments[0].shipmentId;
      setDuplicating(newShipmentId);
    } catch (e) {
      setError(
        JSON.stringify({
          error: 'Something went wrong. Please refresh the page.',
        }),
      );
    }
  }

  function openNewShipment() {
    setDuplicating(null);
  }

  return (
    <Grid
      container
      className={classes.tile}
      justify="space-between"
      ref={lastCardRef || null}
      data-testid="shipmentcard"
    >
      {/* <Grid item xs={1} className={classes.shipmentCardColumn}>
        <Grid item className={classes.shipmentHeading}>
          <span className={`${classes.shipmentIdHeader}`}>{`${orderId}`}</span>
          <span className={`${classes.shipmentIdHeader}`}>
            {`-${trimShipmentId(shipmentId)}`}
          </span>
        </Grid>
        <Grid item className={classes.centeredContainer}>
          <Grid container spacing={1}>
            <Grid item xs={2}>
              <ShipmentStatusIcon
                status={status}
                colorClass={classes[statusColorClass.statusClass]}
              />
            </Grid>
            <Grid item xs={10} className={classes.statusMessage}>
              <Typography className={statusHeadingClass}>
                {`${generateShipmentCardMessage(shipment)}`}
              </Typography>
              {mainTrackingNumber && displayTracking(status) && (
              <Grid container item spacing={1}>
                <Grid item>
                  <Typography className={classes.trackShipment}>
                    Track shipments
                  </Typography>
                </Grid>
                <Grid item>
                  <IconButton
                    onClick={(e) => {
                      openTrackingModal({
                        shipmentId,
                        trackingNumber: mainTrackingNumber,
                        anchorEl: e.currentTarget,
                        iotTrackingNumber: iot ? iotTrackingNumber : false,
                        openTab: SHIPMENT_TRACKING_TAB,
                      });
                    }}
                    classes={{ root: classes.trackingButton }}
                  >
                    <OutlinedTruckIcon style={{ fontSize: '1rem' }} />
                  </IconButton>
                </Grid>
                  { iot
                  && (
                    <Grid item>
                      <IconButton
                        onClick={(e) => {
                          openTrackingModal({
                            shipmentId,
                            trackingNumber: mainTrackingNumber,
                            anchorEl: e.currentTarget,
                            iotTrackingNumber: iot ? iotTrackingNumber : false,
                            openTab: IOT_TRACKING_TAB,
                          });
                        }}
                        classes={{ root: classes.trackingButton }}
                      >
                        <img src={iotIcon} alt="iot" style={{ width: '1rem', height: '1rem' }} />
                      </IconButton>
                    </Grid>
                  )}
              </Grid>
              )}
              { collaborators && collaborators.length > 0
                    && (
                      <Grid item container justify="flex-start" spacing={1}>
                        {
                          collaborators.map(({ imageUrl, title, name }) => (
                            <Grid item key={imageUrl}>
                              <UserAvatar
                                alt={
                                  name ? name.split(' ').map((str) => (str[0])).join('') : ''
                                }
                                src={imageUrl}
                                title={title}
                                size="small"
                              />
                            </Grid>
                          ))
                        }
                      </Grid>
                    )}
            </Grid>
          </Grid>
        </Grid>
      </Grid> */}

      <Grid item xs={4}>
        <Grid container style={{ height: '100%' }}>
          <Grid item xs={3} className={classes.shipmentCardColumn}>
            <Grid item>
              <Typography
                className={`${classes.shipmentHeading} ${classes.shipmentInfoHeader}`}

              >
                STATUS
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={recipientHeadingClass} style={{ color: 'lightgreen' }}>
                {status}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={4} className={classes.shipmentCardColumn}>
            <Grid item>
              <Typography
                className={`${classes.shipmentHeading} ${classes.shipmentInfoHeader}`}
              >
                DELIVERY/SHIPMENT#
              </Typography>
            </Grid>
            <Grid container item>

              <Grid item xs={8}>
                <Typography className={classes.shipmentInformation}>
                  {deliveryNo}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={5} className={classes.shipmentCardColumn}>
            <Grid item>
              <Typography
                className={`${classes.shipmentHeading} ${classes.shipmentInfoHeader}`}
              >
                COMPANY
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={recipientHeadingClass}>
                {company}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={3}>
        <Grid container style={{ height: '100%' }}>
          <Grid item xs={8} className={classes.shipmentCardColumn}>
            <Grid item>
              <Typography
                className={`${classes.shipmentHeading} ${classes.shipmentInfoHeader}`}
              >
                CONTACT
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                className={`${classes.shipmentInformation} ${classes.allCapsWide}`}
              >
                {receiptContactName}
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={4} className={classes.shipmentCardColumn}>
            <Grid item>
              <Typography
                className={`${classes.shipmentHeading} ${classes.shipmentInfoHeader}`}
              >
                CITY
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                className={`${classes.shipmentInformation} ${classes.allCapsWide}`}
              >
                {city}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={3}>
        <Grid container style={{ height: '100%' }}>
          <Grid item xs={6} className={classes.shipmentCardColumn}>
            <Grid item>
              <Typography
                className={`${classes.shipmentHeading} ${classes.shipmentInfoHeader}`}
              >
                STATE
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                className={`${classes.shipmentInformation} ${classes.allCapsWide}`}
              >
                {state}
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={6} className={classes.shipmentCardColumn}>
            <Grid item>
              <Typography
                className={`${classes.shipmentHeading} ${classes.shipmentInfoHeader}`}
              >
                TRACKING/PRO#
              </Typography>
            </Grid>
            <Grid item>
              <MuiLink
                component="button"
                variant="body2"
                style={{ color: colors.secondaryButton }}
                onClick={(e) => {
                  openTrackingModal({
                    shipmentId,
                    trackingNumber: tracking,
                    anchorEl: e.currentTarget,
                    iotTrackingNumber: null,
                    openTab: SHIPMENT_TRACKING_TAB,
                  });
                }}
              >
                {tracking}
              </MuiLink>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={2}>
        <Grid container style={{ height: '100%' }}>
          <Grid item xs={6} className={classes.shipmentCardColumn}>
            <Grid item>
              <Typography
                className={`${classes.shipmentHeading} ${classes.shipmentInfoHeader}`}
              >
                CARRIER
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                className={`${classes.shipmentInformation} ${classes.allCapsWide}`}
              >
                {carrier}
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={6} className={classes.shipmentCardColumn}>
            <Grid item>
              <Typography
                className={`${classes.shipmentHeading} ${classes.shipmentInfoHeader}`}
              >
                SERVICE
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                className={`${classes.shipmentInformation} ${classes.allCapsWide}`}
              >
                {service}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <ErrorDialog
        open={!!error}
        onClose={() => setError(null)}
        errorContent={error}
      />
    </Grid>
  );
}

ShippingManifestCard.propTypes = {
  shipment: PropTypes.shape({
    status: PropTypes.string,
    shipmentId: PropTypes.string,
    recipient: PropTypes.string,
    carrier: PropTypes.string,
    updated: PropTypes.string,
    assignedTo: PropTypes.string,
    taskType: PropTypes.string,
    expectedDeliveryDateTime: PropTypes.string,
    actualDeliveryDateTime: PropTypes.string,
    actualShipDateTime: PropTypes.string,
    expectedPickupDateTime: PropTypes.string,
    iot: PropTypes.bool,
    iotTrackingNumber: PropTypes.string,
    orderId: PropTypes.string,
    iconType: PropTypes.string,
    currencyCode: PropTypes.string,
    amount: PropTypes.string,
    collaborators: PropTypes.arrayOf(
      PropTypes.shape({
        imageUrl: PropTypes.string.isRequired,
        alt: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ),
    isPendingTask: PropTypes.bool,
  }).isRequired,
  openTrackingModal: PropTypes.func.isRequired,
  lastCardRef: PropTypes.objectOf(PropTypes.any),
};

ShippingManifestCard.defaultProps = {
  lastCardRef: null,
};
