import React, { memo } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import { Grid, Typography } from '@material-ui/core';
import _ from 'lodash';
import { loadHazmatOptionCategories, loadHazmatClassTypeDetails } from '../../../../utils/materialsClient';
import {
  FORM_LABELS, FORM_FIELDS_HAZMAT,
  FORM_FIELDS_CHEMICAL_HAZMAT,
  FORM_FIELDS,
  SEARCH_INPUT_MIN_LENGTH,
  TRANSPORTATION_OPTIONS_NAME_KEY,
  FORM_FIELDS_BATTERIES,
  LITHIUM_BATTERY_CONTAINED_KEY,
  FORM_FIELDS_LITHIUM_BATTERY,
  SHIPPING_LITHIUM_BATTERIES_KEY,
  LITHIUM_BATTERY_PACKED_KEY,
  BIOLOGICAL_TRANSPORTATION_OPTIONS,
} from '../../itemDetailsConstants';
import { useItemProductDetailsFormStyles } from '../../itemDetailsStyles';
import { HAZMAT_BATTERIES_CONTAINED_OR_PACKED, HAZMAT_BIOLOGICAL } from '../../../../clientConstants';
import HazmatBiological from './Biological';
import HazmatChemical from './Chemical';
import HazmatBatteries from './Batteries';
import HazmatBatteriesContainedOrPacked from './BatteriesContainedOrPacked';

import {
  getClassTypeDetailsHazmatOption,
  getFormFields,
  getLicenseText, getLithiumContainedOrPackedKey, isBiologicalHazmatOtherClassType, isChemicalProductIneligible, setDefaultChemicalShipmentWeight,
} from '../../itemDetailsUtil';
import PackagingInstructions from '../PackagingInstructions';
import BiologicalTransportationOptions from './Biological/BiologicalTransportationOptions';

function HazmatItem({
  formikProps,
  freightType,
  hazmatOption,
  hazmatClasses,
  training,
  itemToEdit,
  itemFromCatalog,
  editableFields,
  hiddenFields,
  currencyUnits,
  isChemicalHazmat,
  isBattery,
  isBatteryContained,
  setIsBatteryContained,
  showBatteryPackedForm,
  setEditableFields,
  setError,
  openDocumentPreview,
}) {
  const [hazmatClassesTypeList, setHazmatClassesTypeList] = React.useState([]);
  const [filteredItemNameOptions, setFilteredItemNameOptions] = React.useState([]);
  const [searchResult, setSearchResult] = React.useState([]);
  const formFields = getFormFields(freightType, hazmatOption, isBatteryContained);
  const classType = isChemicalHazmat || isBattery || isBatteryContained
    ? null
    : get(formikProps.values, FORM_FIELDS_HAZMAT.classType.key);
  const isOtherClassType = hazmatOption === HAZMAT_BIOLOGICAL
  && isBiologicalHazmatOtherClassType(formikProps?.values);
  const classes = useItemProductDetailsFormStyles();
  const disableTransportationOptions = get(formikProps?.values, 'disableTransportationOptions', false);
  React.useEffect(() => {
    if (isChemicalHazmat && itemToEdit && filteredItemNameOptions.length > 0) {
      const unNoKey = FORM_FIELDS_CHEMICAL_HAZMAT.unNo.key;
      const itemNameKey = FORM_FIELDS_CHEMICAL_HAZMAT.materialName.key;
      const packingGroupKey = FORM_FIELDS_CHEMICAL_HAZMAT.packingGroup.key;

      const selectedMaterial = filteredItemNameOptions.find((item) => item[unNoKey] === itemToEdit[unNoKey]
        && item[packingGroupKey] === itemToEdit[packingGroupKey]
        && item[itemNameKey] === itemToEdit[itemNameKey]);

      handleHazmatClassTypeNameSelect(selectedMaterial);
    }
  }, [isChemicalHazmat, filteredItemNameOptions]);

  React.useEffect(() => {
    if (!isChemicalHazmat && !isBattery && !isBatteryContained) {
      loadHazmatOptionCategories(hazmatOption).then((data) => {
        if (data && data.length) {
          setHazmatClassesTypeList(data);
        }
      });
    }

    if (itemToEdit) {
      if (isChemicalHazmat) fetchMaterials(itemToEdit[FORM_FIELDS_CHEMICAL_HAZMAT.materialName.key]);
      if (isBattery) fetchMaterials(itemToEdit[FORM_FIELDS_BATTERIES.materialName.key]);

      const editables = [];

      Object.keys(formFields).forEach(((key) => {
        editables.push(formFields[key].key);
      }));

      setEditableFields(editables);

      if (itemToEdit?.itemCategory && hazmatOption === HAZMAT_BIOLOGICAL) {
        setFormValues(itemToEdit);
        formikProps.setFieldValue('requiredLicenses', getLicenseText(itemToEdit));
        setFilteredItemNameOptions([]);
        formikProps.validateForm();
      }
    }
  }, [itemToEdit]);

  React.useEffect(() => {
    if (itemFromCatalog) {
      setObjectValues(itemFromCatalog);
      if (!itemToEdit) checkEmptyFields(itemFromCatalog);
    } else if (!showBatteryPackedForm) {
      formikProps.resetForm();
    }
  }, [itemToEdit, itemFromCatalog]);

  React.useEffect(() => {
    if (isOtherClassType) {
      setEditableFields(map(FORM_FIELDS_HAZMAT, (f) => f.key));

      formikProps.setFieldValue(`${FORM_FIELDS_HAZMAT.iataDetails.key}.class`, '6.2');

      // This is to trigger all form validation as soon as user selects class type 'Other'
      formikProps.submitForm();
    }
  }, [isOtherClassType]);

  const fetchMaterials = (value) => {
    setFilteredItemNameOptions([]);

    loadHazmatClassTypeDetails(
      getClassTypeDetailsHazmatOption(formikProps.values, freightType, hazmatOption, isBatteryContained),
      value,
      // eslint-disable-next-line no-nested-ternary
      isChemicalHazmat ? hazmatClasses : (isBattery || isBatteryContained ? '' : classType),
    ).then((data) => {
      const itemName = FORM_FIELDS.itemName.key;

      const dropdownList = data
        ? data.map((o) => ({
          ...o,
          value: o[itemName],
          label: getLabel(o),
        }))
        : [];
      if (isBattery) {
        setSearchResult(dropdownList);
        const filteredSearch = _.uniqBy(dropdownList, 'itemName');
        setFilteredItemNameOptions(filteredSearch);
      } else if (isBatteryContained
      && _.has(formikProps.values, SHIPPING_LITHIUM_BATTERIES_KEY)
      && formikProps.values[SHIPPING_LITHIUM_BATTERIES_KEY]
      && dropdownList.length > 0) {
        const containedBatteryCapacity = formikProps.values[LITHIUM_BATTERY_CONTAINED_KEY][FORM_FIELDS_LITHIUM_BATTERY.batteryCapacity.key];

        if (containedBatteryCapacity) {
          setFilteredItemNameOptions(dropdownList.filter((item) => item[FORM_FIELDS_LITHIUM_BATTERY.batteryCapacity.key] === containedBatteryCapacity));
        } else setFilteredItemNameOptions(dropdownList);
      } else {
        setFilteredItemNameOptions(dropdownList);
      }
    });
  };

  const setObjectValues = (val) => {
    Object.keys(formFields).forEach((key) => {
      const formKey = formFields[key].key;
      const value = get(val, formFields[key].key);
      if (value) formikProps.setFieldValue(formKey, value);
    });
  };

  const setFormValues = (val) => {
    Object.keys(val).forEach((key) => {
      const formKey = key;
      const value = get(val, formKey);
      if (value) formikProps.setFieldValue(formKey, value);
    });
  };

  const getLabel = (object) => {
    const itemName = FORM_FIELDS.itemName.key;
    if (isChemicalHazmat) return renderChemicalMenuItemLabel(object);
    if (isBattery || isBatteryContained) return renderBatteryMenuItemLabel(object);
    return object[itemName];
  };
  const checkEmptyFields = (val) => {
    const editables = [];
    Object.keys(formFields).forEach((key) => {
      const formikKey = formFields[key]?.key;
      const isEditable = !!formFields[key]?.editable;
      const value = get(val, formikKey);
      if (isNil(value) || value === '' || isEditable) {
        formikProps.setFieldTouched(formikKey);
        editables.push(formikKey);
      }
    });

    setEditableFields(editables);
  };

  const renderChemicalMenuItemLabel = (item) => {
    const itemName = item[FORM_FIELDS.itemName.key];
    const unNo = item[FORM_FIELDS_CHEMICAL_HAZMAT.unNo.key];
    const packingGroupField = FORM_FIELDS_CHEMICAL_HAZMAT.packingGroup;
    const packingGroupLabel = packingGroupField.label;
    const packingGroupValue = item[packingGroupField.key];

    return (
      <Grid container>
        <Grid container item xs={12}>
          {`${unNo} - ${itemName}`}
        </Grid>
        {!isEmpty(packingGroupValue) && (
          <Grid item>
            <Typography style={{ fontSize: '12px' }}>
              {`${packingGroupLabel} - ${packingGroupValue}`}
            </Typography>
          </Grid>
        )}
      </Grid>
    );
  };
  const renderBatteryMenuItemLabel = (item) => {
    const itemName = item[FORM_FIELDS.itemName.key];
    const unNo = item[FORM_FIELDS_CHEMICAL_HAZMAT.unNo.key];
    return (
      <Grid container>
        <Grid container item xs={12}>
          {`${unNo} - ${itemName}`}
        </Grid>
      </Grid>
    );
  };
  const handleHazmatClassTypeNameSearch = (e) => {
    const { value } = e.target;

    if (!value && value.length === 0) {
      setFilteredItemNameOptions([]);
      return;
    }
    if (!value || value.length < SEARCH_INPUT_MIN_LENGTH) return;

    fetchMaterials(value);
  };

  const handleHazmatClassTypeNameSelect = async (val) => {
    setFilteredItemNameOptions([]);

    if (isChemicalHazmat) {
      if (isChemicalProductIneligible(val, formikProps?.values)) {
        setError(`This product is ineligible to ship as ${formikProps?.values[TRANSPORTATION_OPTIONS_NAME_KEY]}.`);
        return;
      }

      setDefaultChemicalShipmentWeight(val, formikProps.values, formikProps.setFieldValue);
    }

    setObjectValues(val);

    if (val) { checkEmptyFields(); }

    formikProps.setFieldValue('requiredLicenses', getLicenseText(val));
    formikProps.setFieldTouched('iataDetails', false);

    await formikProps.validateForm();
  };

  const renderHazmat = () => {
    if (isChemicalHazmat) {
      return (
        <HazmatChemical
          formikProps={formikProps}
          fields={formFields}
          editableFields={editableFields}
          setEditableFields={setEditableFields}
          filteredItemNameOptions={filteredItemNameOptions}
          currencyUnits={currencyUnits}
          onSelect={handleHazmatClassTypeNameSelect}
          onSearch={handleHazmatClassTypeNameSearch}
        />
      );
    }

    if (isBattery) {
      return (
        <HazmatBatteries
          formikProps={formikProps}
          fields={formFields}
          editableFields={editableFields}
          filteredItemNameOptions={filteredItemNameOptions}
          currencyUnits={currencyUnits}
          searchResult={searchResult}
          onSelect={handleHazmatClassTypeNameSelect}
          onSearch={handleHazmatClassTypeNameSearch}
        />
      );
    }

    if (showBatteryPackedForm) {
      return (
        <HazmatBatteriesContainedOrPacked
          formikProps={formikProps}
          fields={formFields}
          editableFields={editableFields}
          filteredItemNameOptions={filteredItemNameOptions}
          itemToEdit={itemToEdit}
          isBatteryContained={isBatteryContained}
          setIsBatteryContained={setIsBatteryContained}
          setFilteredItemNameOptions={setFilteredItemNameOptions}
          onSearch={handleHazmatClassTypeNameSearch}
        />
      );
    }

    return (
      <>
        <HazmatBiological
          formikProps={formikProps}
          fields={formFields}
          hazmatClassesTypeList={hazmatClassesTypeList}
          filteredItemNameOptions={filteredItemNameOptions}
          classType={classType}
          training={training}
          hasInternational={hiddenFields.includes('international')}
          isOtherClassType={isOtherClassType}
          onSelect={handleHazmatClassTypeNameSelect}
          onSearch={handleHazmatClassTypeNameSearch}
        />
        {(formikProps.values?.iataDetails?.packagingInstructions
          || isOtherClassType) && (
            <PackagingInstructions
              isOtherClassType={isOtherClassType}
              openDocumentPreview={openDocumentPreview}
            />
        )}
        <BiologicalTransportationOptions
          itemKey={TRANSPORTATION_OPTIONS_NAME_KEY}
          formikProps={formikProps}
          options={BIOLOGICAL_TRANSPORTATION_OPTIONS}
          disabled={disableTransportationOptions}

        />
      </>
    );
  };

  return (
    <Grid item container direction="column" spacing={1}>
      <Grid item>
        <Typography color="primary" className={classes.formFieldHeader}>
          {FORM_LABELS.product}
        </Typography>
      </Grid>
      {renderHazmat()}
    </Grid>
  );
}

HazmatItem.defaultProps = {
  itemToEdit: null,
  itemFromCatalog: null,
  training: null,
  currencyUnits: [],
  isChemicalHazmat: false,
  isBattery: false,
  isBatteryContained: false,
  showBatteryPackedForm: false,
};

HazmatItem.propTypes = {
  formikProps: PropTypes.objectOf(PropTypes.any).isRequired,
  freightType: PropTypes.string.isRequired,
  hazmatOption: PropTypes.string.isRequired,
  hazmatClasses: PropTypes.arrayOf(PropTypes.string).isRequired,
  training: PropTypes.shape(PropTypes.object),
  itemToEdit: PropTypes.shape(PropTypes.object),
  itemFromCatalog: PropTypes.shape(PropTypes.object),
  editableFields: PropTypes.arrayOf(PropTypes.string).isRequired,
  hiddenFields: PropTypes.arrayOf(PropTypes.string).isRequired,
  currencyUnits: PropTypes.arrayOf(PropTypes.shape({
    currencyCode: PropTypes.string,
  })),
  isChemicalHazmat: PropTypes.bool,
  isBattery: PropTypes.bool,
  isBatteryContained: PropTypes.bool,
  setIsBatteryContained: PropTypes.func.isRequired,
  showBatteryPackedForm: PropTypes.bool,
  setEditableFields: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  openDocumentPreview: PropTypes.func.isRequired,
};

export default memo(HazmatItem);
