import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, DialogActions, DialogContent, DialogContentText,
  Grid, Typography, CircularProgress, FormControl, Radio, RadioGroup, FormControlLabel,
} from '@material-ui/core';
import WarningIcon from '@material-ui/icons/WarningRounded';
import { css } from 'emotion';
import NewOrderNextButton from './NewOrderNextButton';
import * as colors from '../../styles/colors';

const DISREGARD = 'disregard';
const ACCEPT = 'accept';

export default function CancelCopyShipmentModal(props) {
  const {
    open, message, onProceed, onCancel, proceedLabel, proceedButtonComponent, proceedButtonProps,
  } = props;

  const [loading, setLoading] = React.useState(false);
  const [messageState, setMessageState] = React.useState(message);
  const ProceedButtonComponent = proceedButtonComponent || NewOrderNextButton;
  const [cancelCopyShipment, setCancelCopyShipment] = React.useState(null);

  React.useEffect(() => {
    if (message) {
      setMessageState(message);
    }
  }, [message]);

  React.useEffect(() => {
    setCancelCopyShipment(null);
  }, [open]);

  const dialogRoot = css({
    backgroundColor: colors.mediumBlueBackground,
    overflowY: 'initial',
    borderRadius: 10,
  });
  const dialogContentContainer = css({ marginBottom: 'initial' });
  const dialogActionsContainer = css({
    padding: '0 40px 24px 40px', width: '100%', justifyContent: 'center', flexDirection: 'column',
  });

  const classes = {
    dialogRoot,
    dialogContentContainer,
    dialogActionsContainer,
    dialogContent: css({
      minWidth: 500,
      paddingLeft: 40,
      paddingRight: 40,
    }),
    buttonsContainer: css({
      paddingTop: '15px',
      width: '100%',
    }),
    dialogText: css({
      fontWeight: 500,
      lineHeight: '2em',
    }),
    progress: css({
      marginTop: 20,
    }),
    buttonText: css({
      whiteSpace: 'nowrap',
      padding: '0 10px',
    }),
    radioLabel: css({
      color: colors.white,
      paddingTop: 9,
    }),
    formControlRoot: css({
      alignItems: 'flex-start',
    }),
    radio: css({
      color: colors.white,
    }),
    warningIconContainer: css({
      width: 'fit-content',
      color: colors.warningYellow,
    }),
    warningIcon: css({
      height: '40px',
      width: 'auto',
    }),
  };

  const onClick = async () => {
    setLoading(true);
    try {
      if (cancelCopyShipment === DISREGARD) {
        setLoading(false);
        return onCancel();
      }
      await onProceed(cancelCopyShipment);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const dialogContent = (
    <DialogContent className={classes.dialogContent}>
      <DialogContentText
        id="alert-error-dialog"
        component="div"
        classes={{ root: dialogContentContainer }}
      >
        <Grid item container spacing={2} wrap="nowrap" alignItems="center">
          <Grid item container className={classes.warningIconContainer}>
            <WarningIcon className={classes.warningIcon} />
          </Grid>
          <Grid item>
            <Typography color="primary" className={classes.dialogText}>
              {messageState}
            </Typography>
          </Grid>
        </Grid>
      </DialogContentText>
    </DialogContent>
  );

  const dialogActions = (
    <DialogActions classes={{ root: dialogActionsContainer }}>
      <Grid container direction="column">
        <Grid container>
          <FormControl color="primary">
            <RadioGroup name="cancelCopyShipment" value={cancelCopyShipment} onChange={(val) => setCancelCopyShipment(val.target.value)}>
              <FormControlLabel
                control={<Radio className={classes.radio} />}
                label="Disregard the changes and ship the shipment as-is"
                value={DISREGARD}
                classes={{ label: classes.radioLabel, root: classes.formControlRoot }}
              />
              <FormControlLabel
                control={<Radio className={classes.radio} />}
                label="Cancel this shipment and accept a full refund. A new order will be initiated with your changes. This option will require you to select a new shipping rate and to complete payment and confirmation."
                value={ACCEPT}
                classes={{ label: classes.radioLabel, root: classes.formControlRoot }}
              />
            </RadioGroup>
          </FormControl>

        </Grid>
        <Grid
          container
          className={classes.buttonsContainer}
          spacing={2}
          justify="flex-end"
          wrap="nowrap"
        >
          {/* <Grid item>
          <NewOrderNextButtonClear disabled={loading} onClick={onCancel}>{cancelLabel || 'No, cancel'}</NewOrderNextButtonClear>
        </Grid> */}
          <Grid item>
            <ProceedButtonComponent
              className={classes.buttonText}
              disabled={loading || !cancelCopyShipment}
              onClick={onClick}
              {...proceedButtonProps}
            >
              {proceedLabel || 'Submit'}
            </ProceedButtonComponent>
          </Grid>
        </Grid>

        {loading && (
        <Grid item style={{ alignSelf: 'center' }}>
          <CircularProgress color="secondary" className={classes.progress} />
        </Grid>
        )}
      </Grid>
    </DialogActions>
  );

  return (
    <Dialog
      open={open}
      onClose={(e, reason) => {
        setCancelCopyShipment(null);
        onCancel();
      }}
      disableBackdropClick
      disableEscapeKeyDown
      classes={{ paper: dialogRoot }}
      transitionDuration={{ exit: 0, enter: 100, appear: 100 }}
    >
      {dialogContent}
      {dialogActions}
    </Dialog>
  );
}

CancelCopyShipmentModal.propTypes = {
  open: PropTypes.bool,
  message: PropTypes.string,
  onProceed: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  proceedLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  cancelLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  proceedButtonComponent: PropTypes.node,
  proceedButtonProps: PropTypes.objectOf(PropTypes.any),
};

CancelCopyShipmentModal.defaultProps = {
  open: false,
  message: null,
  proceedLabel: '',
  cancelLabel: '',
  proceedButtonComponent: null,
  proceedButtonProps: null,
};
