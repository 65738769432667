import moment from 'moment';
import { omit } from 'lodash';
import { formNameMap as allForms } from './helpers';
import { NEW_ORDER_TAB_STRINGS } from '../clientConstants';

const {
  sender,
  recipient,
  regulationAndCompliance,
  billingAndPayment, billingAndPaymentCustoms, billingAndPaymentTaxes, 
  packagingAndContents, itemAndProductDetails, shippingDetails, schedulePickup,
  aes, exportCompliance, customsBroker,
} = NEW_ORDER_TAB_STRINGS;

const formNameMap = omit(allForms, 'shippingDocuments');

const taskOrder = [
  sender,
  recipient,
  customsBroker,
  regulationAndCompliance,
  itemAndProductDetails,
  packagingAndContents,
  shippingDetails,
  exportCompliance,
  billingAndPayment,
  billingAndPaymentCustoms,
  billingAndPaymentTaxes,
  aes,
  schedulePickup,
];

const reverseFormNameMap = Object
  .entries(formNameMap)
  .reduce((obj, [key, val]) => ({ ...obj, [val]: key }), {});

const getTaskDueDates = (shipment) => taskOrder.map((form) => shipment[form]
&& shipment[form].task
&& shipment[form].task.expiresAt);

const isTaskInProgress = (task) => !!(
  task && (task.status === 'In-Progress')
);

const isTaskAssigned = (task) => !!(
  task
    && task.status !== 'Completed'
    && task.status !== 'Revoked'
    && task.status !== 'Rejected'
    && task.status !== 'Expired'
);

const getAssignedTaskFromForm = (shipment, formName) => {
  const form = formNameMap[formName] || formName;
  if (!shipment) {
    return null;
  }
  return (shipment[form]
    && isTaskAssigned(shipment[form].task)
    ? shipment[form].task
    : null);
};
const getMaxDueDate = (shipment, formName) => {
  let maxDueDate;
  for (let i = taskOrder.length - 1; i >= 0; i -= 1) {
    const currentForm = taskOrder[i];
    if (formNameMap[formName] === taskOrder[i]) {
      break;
    }
    const newMaxDueDate = getAssignedTaskFromForm(shipment, currentForm) && shipment[currentForm].task.expiresAt;

    if (newMaxDueDate) maxDueDate = newMaxDueDate;
  }
  return maxDueDate && moment(maxDueDate).subtract(1, 'd').format();
};

const getMinDueDate = (shipment, formName) => {
  let minDueDate;
  for (let i = 0; i < taskOrder.length; i += 1) {
    const currentForm = taskOrder[i];
    if (formNameMap[formName] === taskOrder[i]) {
      break;
    }
    const newMinDueDate = getAssignedTaskFromForm(shipment, currentForm) && shipment[currentForm].task.expiresAt;
    if (newMinDueDate) minDueDate = newMinDueDate;
  }
  return minDueDate && moment(minDueDate)
    .add(1, 'd')
    .format();
};

const isAssignedTo = (shipment, formName) => {
  const form = formNameMap[formName];
  return shipment[form]
    && shipment[form].task
    && shipment[form].task.isAssignedTo;
};

export default {
  getAssignedTaskFromForm,
  formNameMap,
  isAssignedTo,
  reverseFormNameMap,
  getTaskDueDates,
  getMinDueDate,
  getMaxDueDate,
  isTaskAssigned,
  isTaskInProgress,
};
