import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import { Grid } from '@material-ui/core';
import ReadOnlyContainer from '../../components/common/ReadOnlyContainer';
import { ACCOUNT_MANAGEMENT } from './constants';
import ReadOnlyCheckbox from '../../components/common/ReadOnlyCheckbox';
import Form from './Form';
import { FIELD_TYPE_BOOL } from '../../clientConstants';

const PaymentOptions = ({
  itemKey, values, isEditing, onEdit, onSubmit,
}) => {
  const [checkboxes, setCheckboxes] = useState([]);
  const obj = ACCOUNT_MANAGEMENT[itemKey];

  useEffect(() => {
    if (!isEmpty(values)) {
      setCheckboxes(map(values, (t, key) => ({
        type: FIELD_TYPE_BOOL,
        name: key,
        label: t.description,
      })));
    }
  }, []);

  return (
    <ReadOnlyContainer title={obj.title} isEditable={false}>
      {isEditing ? (
        <Form
          itemKey={itemKey}
          initialValues={values}
          validationSchema={obj.validationSchema}
          fields={checkboxes}
          onEdit={onEdit}
          onSubmit={onSubmit}
        />
      ) : checkboxes.map((option) => (
        <Grid key={option.name} item xs={12}>
          <ReadOnlyCheckbox
            name={option.name}
            label={option.label}
            value={get(values, option.name, false)}
          />
        </Grid>
      ))}
    </ReadOnlyContainer>
  );
};

PaymentOptions.defaultProps = {
  values: null,
  isEditing: false,
};

PaymentOptions.propTypes = {
  itemKey: PropTypes.string.isRequired,
  values: PropTypes.object,
  isEditing: PropTypes.bool,
  onEdit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default memo(PaymentOptions);
