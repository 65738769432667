/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import pick from 'lodash/pick';
import shortId from 'shortid';
import {
  FieldArray, Field, Form, Formik,
} from 'formik';
import moment from 'moment';
import {
  Button, CircularProgress, FormControlLabel, Grid, Typography,
} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useSingleOrderState } from '../../../context/singleOrderContext';
import PackageDetails from '../Details';
import * as colors from '../../../styles/colors';
import { NEW_ORDER_PACKAGING_STYLE } from '../../../styles/style';
import { StyledInput, StyledSelect } from '../../common/CustomSelectField';
import CustomSelectTextField from '../../common/CustomSelectTextField';
import NewOrderErrorDialog from '../../common/NewOrderErrorDialog';
import * as miscClient from '../../../utils/miscClient';
import StyledCheckbox from '../../common/StyledCheckbox';
import {
  formatPackagingDetailsForClient,
  formatPackagingDetailsFromClient,
  formatItemsForClientState,
  generateArrayOfAllItemsInShipment,
  getValidationSchema,
  getDefaultMonetaryValues,
  formatPackageToApi,
} from '../../../utils/packagingUtil';
import {
  CARRIERS, HAZMAT_BATTERIES, HAZMAT_BATTERIES_CONTAINED_OR_PACKED, HAZMAT_BIOLOGICAL, HAZMAT_CHEMICAL, HAZMAT_TOXINS, SERVICES,
} from '../../../clientConstants';
import PurposeOfShipment from './PurposeOfShipment';
import FreightType from './FreightType';
import { FREIGHT_TYPES } from '../../ItemProductDetails/itemDetailsConstants';

const DEFAULT_INITIAL_VALUES = {
  packagingType: '',
  packagingTypeCode: 'PT001',
  dimensions: {
    width: '',
    height: '',
    length: '',
    unitOfMeasurement: 'IN',
  },
  packageWeight: {
    weight: '',
    unitOfMeasurement: 'LB',
    weightSmall: '',
    unitOfMeasurementSmall: 'oz',
  },
  dryIceWeight: {
    weight: '',
    unitOfMeasurement: 'LB',
  },
  nonRMSContents: '',
  carrierLabel: '',
  packagingName: '',
  key: shortId.generate(),
  referenceNumber1: '',
  referenceNumber2: '',
  isMTARequired: false,
  isSponseredResearch: false,
  sponseredResearchNumber: '',
  iot: {
    deviceId: '',
    vendorName: 'tive',
  },
  items: [],
  isFragile: false,
  isOversized: false,
  isOverpack: false,
  isRefrigerant: false,
  isDangerousGoods: false,
};

const useStyles = makeStyles(() => ({
  applyToAll: {
    '&>span:first-of-type': {
      '&.Mui-disabled': {
        color: colors.textLightGrey,
      },
    },
    '&>span:last-child': {
      color: colors.white,
    },
    color: colors.white,
  },
  queryContainer: {
    flexDirection: 'column',
  },
  buttonContainer: {
    paddingBottom: 24,
  },
  button: {
    '&.Mui-disabled': {
      background: colors.buttonBackgroundBlue,
    },
    width: 114,
    height: 36,
    background: colors.buttonBackgroundBlue,
  },
  formSectionHeader: {
    paddingTop: 24,
    fontSize: 16,
    color: colors.white,
  },
  smallSelectFieldContainer: {
    maxWidth: 70,
    marginRight: 16,
  },
  smallSelectField: {
    '&.Mui-disabled': {
      backgroundColor: 'rgba(0, 0, 0, 0.30)',
    },
    '&>div:first-of-type': {
      '&.Mui-disabled': {
        backgroundColor: 'rgba(0, 0, 0, 0)',
      },
    },
    minWidth: 70,
    width: '100%',
  },
  miscLoadingContainer: {
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingTop: 24,
  },
}));

const createInitialValuesForItems = (array) => array.reduce((obj, item) => ({
  ...obj,
  [item.itemId]: {
    id: item?.itemId,
    name: item?.itemName,
    quantity: 0,
    unitOfMeasurement: item?.units?.unitOfMeasurement,
    unitValue: item?.unitValue,
  },
}), {});

const formatTotalItemsState = (array) => array.map((item, idx) => ({
  id: item?.itemId,
  itemKey: `${item?.itemId}-${idx}`,
  name: item?.itemName,
  maxQuantity: item?.units?.noOfUnits,
  quantity: item?.units?.noOfUnits,
  itemWeight: item?.itemWeight,
  unitOfMeasurement: item?.units?.unitOfMeasurement,
  unitValue: item?.units?.unitValue,

}));

const getDefaultInitialValues = ({
  currentPackages,
  applyToAllPackages,
  applyToAllPackagesFields = [],
  basePackage = DEFAULT_INITIAL_VALUES,
  itemsArray = [],
  freightType,
  destinationCountry,
  hiddenFields,
}) => {
  if (!currentPackages || !applyToAllPackages || currentPackages.length <= 0) {
    const itemsObj = Array.isArray(itemsArray)
      ? createInitialValuesForItems(itemsArray)
      : {};
    let hazmatItems = {};
    if (hiddenFields.includes('material')) {
      hazmatItems = {
        isRefrigerant: false,
        refrigerantType: '',
        isDangerousGoods: false,
        iataDetails: {
          properShippingName: '',
          unNo: '',
          class: '',
          packagingInstructions: '',
          documentURL: '',
          maxWeight: {
            liters: '',
            kilograms: '',
            grams: '',
            milliliters: '',
          },
          maxWeightPR: {
            liters: '',
            kilograms: '',
            grams: '',
            milliliters: '',
          },
          maxWeightCargo: {
            liters: '',
            kilograms: '',
            grams: '',
            milliliters: '',
          },
          maxWeightPassenger: {
            liters: '',
            kilograms: '',
            grams: '',
            milliliters: '',
          },
        },
        dangerousGoodsType: '',
      };
    }

    return {
      ...DEFAULT_INITIAL_VALUES,
      ...hazmatItems,
      ...getDefaultMonetaryValues(
        freightType,
        destinationCountry,
        Array.isArray(itemsArray) ? (itemsArray.length > 0) : false,
        hiddenFields,
      ),
      items: itemsArray,
    };
  }
  return { ...basePackage, ...pick(currentPackages[0], applyToAllPackagesFields) };
};

function hasExistingPackagingState(orderState, shipmentId) {
  return (
    orderState
    && orderState.shipments
    && orderState.shipments[shipmentId]
    && orderState.shipments[shipmentId].packaging
    && orderState.shipments[shipmentId].packaging.details
  );
}

function PackagingForm({
  openNextPanel, shipment, shipmentId,
  submitFormToApi, hideFields,
  shipmentPurposeOptions,
  shipmentFreightTypes, packFormRef,
}) {
  const classes = useStyles();
  const orderState = useSingleOrderState();
  const hiddenFields = (hideFields || []).map(({ fieldName }) => fieldName);
  const freightType = orderState?.order?.freightType;
  const shippingType = orderState?.order?.shippingType;
  const destinationCountry = shipment?.destination?.address?.country || 'US';
  const productItemsArray = shipment?.product?.details?.items || [];
  const account = shipment?.account;
  // eslint-disable-next-line react/prop-types
  const isMultipleAddress = shipment?.destination?.addresses?.length > 0;
  const {
    packages: packagesArray = [],
    purpose = '',
    otherPurpose = '',
    shipmentFreightType = '',
  } = shipment?.packaging?.details || {};
  const items = shipment?.product?.details?.items ?? [];
  const isBiologicalHazmat = orderState?.order?.hazmatOption === HAZMAT_BIOLOGICAL;
  const isChemicalHazmat = orderState?.order?.hazmatOption === HAZMAT_CHEMICAL || orderState?.order?.hazmatOption === HAZMAT_TOXINS;
  const isBattery = orderState?.order?.hazmatOption === HAZMAT_BATTERIES;
  const isLithiumBatteryContainedOrPacked = freightType === FREIGHT_TYPES.product || orderState.order.hazmatOption === HAZMAT_BATTERIES_CONTAINED_OR_PACKED;

  const [totalItemsForShipmentState, setTotalItemsForShipmentState] = React.useState(
    hasExistingPackagingState(orderState, shipmentId)
      ? formatItemsForClientState(productItemsArray)
      : formatTotalItemsState(productItemsArray),
  );
  const [serviceTypeDropdownData, setServiceTypeDropdownData] = React.useState([]);
  const [serviceDropDownData, setServiceDropDownData] = React.useState([]);
  const [packageState, setPackageState] = React.useState(
    {
      applyToAllPackages: (hasExistingPackagingState(orderState, shipmentId)
        ? (orderState.shipments[shipmentId].packaging.details.applyToAllPackages || false)
        : false),
      numberOfPackages: (hasExistingPackagingState(orderState, shipmentId)
        ? (orderState.shipments[shipmentId].packaging.details.numberOfPackages || 1)
        : 1),
    },
  );
  const [isError, setIsError] = React.useState(false);
  const [errorContent, setErrorContent] = React.useState(null);
  const [expandedPackage, setExpandedPackage] = React.useState(0);
  const formikRef = React.useRef(null);
  const applyToAllPackagesFields = [
    'declaredValue',
    'insuredValue',
    'customsValue',
    'packageWeight',
    'packagingName',
    'carrierLabel',
    'packagingType',
    'packagingTypeCode',
    'dimensions',
    'isDimensionsEnabled',
    'nonRMSContents',
    'referenceNumber1',
    'referenceNumber2',
  ];

  React.useEffect(() => {
    if (account === 'worldemblem') {
      miscClient.loadServiceTypesDropdown(shipment?.shippingType).then((data) => {
        setServiceTypeDropdownData(data);
      });
    }
    if (isMultipleAddress) {
      if (shipment?.packaging?.details?.carrierName) {
        setServiceDropDownData(
          SERVICES.filter(
            (a) => a.carrier === shipment?.packaging?.details?.carrierName,
          ),
        );
      }
    }

    if (packFormRef && packFormRef.current) {
      packFormRef.current.submitForm();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleApplyCheckboxChange({
    applyToAllPackages, arrayHelpers, currentPackages,
  }) {
    if (applyToAllPackages) {
      currentPackages.forEach((pkg, i) => {
        if (i === 0) return;
        arrayHelpers.replace(i, applyToAllPackages
          ? { ...pkg, ...pick(currentPackages[0], applyToAllPackagesFields) }
          : pkg);
      });
    }

    setPackageState((prev) => ({ ...prev, applyToAllPackages }));
  }

  function getNumberOfPackages() {
    // move to configuration
    if (account === 'worldemblem') {
      return 200;
    }
    return 10;
  }

  function updateNumberOfPackages({
    applyToAllPackages, arrayHelpers, currentPackages, newLength,
  }) {
    const difference = Math.abs(newLength - currentPackages.length);
    if (currentPackages.length < newLength) {
      for (let i = 0; i < difference; i += 1) {
        const uniqueId = shortId.generate();
        const newPackage = {
          ...getDefaultInitialValues({
            currentPackages,
            applyToAllPackages,
            applyToAllPackagesFields,
            itemsArray: productItemsArray,
            freightType,
            destinationCountry,
            hiddenFields,
          }),
          key: uniqueId,
        };
        if (account === 'worldemblem') {
          delete newPackage.iot;
        }
        arrayHelpers.push(newPackage);
      }
    } else if (currentPackages.length > newLength) {
      for (let i = 0; i < difference; i += 1) {
        arrayHelpers.pop();
      }
    }
    setPackageState((prev) => ({ ...prev, numberOfPackages: newLength }));
  }

  function getInitialValues() {
    const { numberOfPackages, applyToAllPackages } = packageState;
    const carrierService = '';
    let carrier = {};

    if (isMultipleAddress) {
      carrier = {
        carrierName: '',
        serviceCode: '',
      };
    }

    if (hasExistingPackagingState(orderState, shipmentId)) {
      const details = orderState.shipments[shipmentId]?.packaging?.details;
      const items = orderState.shipments[shipmentId]?.product?.details?.items;
      const defaultInitialValues = getDefaultInitialValues({
        currentPackages: packageState.packages,
        applyToAllPackages,
        itemsArray: productItemsArray,
        freightType,
        purpose,
        otherPurpose,
        shipmentFreightType,
        destinationCountry,
        hiddenFields,
      });
      const pkgs = applyToAllPackages
        ? [{ ...defaultInitialValues, ...details?.packages[0] }]
        : [...Array(numberOfPackages).keys()].map(
          (_, i) => ({ ...defaultInitialValues, ...details?.packages[i] }),
        );
      const pdetails = {
        purpose,
        carrierService,
        otherPurpose,
        ...carrier,
        shipmentFreightType,
        ...details,
        packages: pkgs,
      };

      return formatPackagingDetailsForClient(
        {

          ...pdetails,
        },
        items,
      );
    }

    const defaultInitialValues = getDefaultInitialValues({
      currentPackages: packageState.packages,
      applyToAllPackages,
      itemsArray: productItemsArray,
      freightType,
      purpose,
      otherPurpose,
      shipmentFreightType,
      destinationCountry,
      hiddenFields,
    });

    const packages = applyToAllPackages
      ? [{ ...defaultInitialValues }]
      : [...Array(numberOfPackages).keys()].map((_, i) => defaultInitialValues);

    return {
      purpose,
      carrierService,
      ...carrier,
      otherPurpose,
      shipmentFreightType,
      packages,
    };
  }

  const onSubmit = async (values, { setSubmitting }) => {
    try {
      const packagingDetails = formatPackagingDetailsFromClient({
        ...{
          purpose: values.purpose,
          ...(values.purpose?.toLowerCase() === 'other' && {
            otherPurpose: values.otherPurpose,
          }),
        },
        ...(isMultipleAddress && {
          carrierName: values.carrierName,
          serviceCode: values.serviceCode,
        }),
        ...(!hiddenFields.includes('shipmentFreightType') && {
          shipmentFreightType: values.shipmentFreightType,
        }),
        ...packageState,
        packages: hiddenFields.includes('material')
          ? values.packages
          : formatPackageToApi(values.packages),
      });
      if (values.notShowNext && shipment?.account === 'worldemblem') {
        // call carrier Api fro worldemblem
        if (values.carrierService) {
          const carrier = serviceTypeDropdownData.filter((service) => service.serviceTypeCode === values.carrierService)[0];
          const carrierPayload = {
            details: {
              expectedShipDate: moment().format('YYYY-MM-DD'),
              serviceCode: carrier.serviceTypeCode,
              carrierName: carrier.carrierCode,
              serviceName: carrier.description,
              shippingExtras: {},
            },
          };
          packagingDetails.carrier = carrierPayload;
        }
      }

      await submitFormToApi(packagingDetails);
      if (!values?.notShowNext) { openNextPanel(); }
    } catch (e) {
      //
      console.log(e);
    }
    setSubmitting(false);
  };

  const deletePackage = (packageIndex, arrayHelpers, values) => {
    if (values.packages.length <= 1) return;
    arrayHelpers.remove(packageIndex);
    setPackageState((prev) => ({ ...prev, numberOfPackages: prev.numberOfPackages - 1 }));
  };

  function render(renderProps) {
    const { isSubmitting, errors, values } = renderProps;
    return (
      <Form style={{ width: '100%' }}>
        <Grid container direction="column">
          <Grid container spacing={2} className={classes.queryContainer}>
            <Grid item>
              <FieldArray
                name="packages"
                render={(arrayHelpers) => (
                  <Grid
                    container
                    spacing={3}
                    className={classes.queryContainer}
                  >
                    <Grid
                      container
                      spacing={2}
                      style={{ padding: '0px 20px 0px 20px ' }}
                      justifyContent="space-around"
                    >
                      <Grid container direction="column" spacing={2} item xs={5}>
                        <Grid item>
                          <Typography className={classes.formSectionHeader}>
                            How many packages will be included in this shipment?
                          </Typography>
                        </Grid>
                        <Grid item container spacing={3}>
                          <Grid item className={classes.smallSelectFieldContainer}>
                            <StyledSelect
                              className={classes.smallSelectField}
                              IconComponent={ExpandMoreIcon}
                              input={<StyledInput disabled={isSubmitting} />}
                              variant="filled"
                              value={packageState.numberOfPackages}
                              onChange={(e) => {
                                updateNumberOfPackages({
                                  currentPackages: renderProps.values.packages,
                                  newLength: e.target.value,
                                  defaultPackage: getDefaultInitialValues({
                                    currentPackages: renderProps.values.packages,
                                    applyToAllPackages:
                                  packageState.applyToAllPackages,
                                    itemsArray: productItemsArray,
                                    freightType,
                                    destinationCountry,
                                    hiddenFields,
                                  }),
                                  applyToAllPackages:
                                packageState.applyToAllPackages,
                                  arrayHelpers,
                                });
                              }}
                              inputProps={{
                                name: 'numberOfPackages',
                                disabled: isChemicalHazmat || isBiologicalHazmat,
                              }}
                            >
                              {[...Array(getNumberOfPackages()).keys()].map((i) => (
                                <MenuItem key={`package-num-${i}`} value={i + 1}>
                                  {i + 1}
                                </MenuItem>
                              ))}
                            </StyledSelect>
                          </Grid>
                          <Grid item>
                            <FormControlLabel
                              className={classes.applyToAll}
                              control={(
                                <StyledCheckbox
                                  color="primary"
                                  checked={packageState.applyToAllPackages}
                                  onChange={(e) => handleApplyCheckboxChange({
                                    currentPackages: renderProps.values.packages,
                                    arrayHelpers,
                                    applyToAllPackages: e.target.checked,
                                  })}
                                  value="applyToAllPackages"
                                  disabled={isSubmitting || isChemicalHazmat}
                                />
                          )}
                              label="All packages are same size, weight, contents, and value"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={7}>
                        <PurposeOfShipment
                          classes={classes}
                          shipmentPurposeOptions={shipmentPurposeOptions}
                          purpose={renderProps.values.purpose}
                        />
                        {!hiddenFields.includes('shipmentFreightType') && (
                        <FreightType
                          classes={classes}
                          shipmentFreightTypes={shipmentFreightTypes}
                        />
                        )}
                      </Grid>
                    </Grid>
                    {isMultipleAddress && (
                      <Grid container item spacing={3} justify="space-between">
                        <Grid item style={{ width: '48%' }}>
                          <Field
                            component={CustomSelectTextField}
                            style={{ width: '100%' }}
                            type="text"
                            name="carrierName"
                            label="Carrier Name"
                            className={classes.standardFormTextfield}
                            data-testid="packaging-type-input"
                            customOnChange={(e) => {
                              if (e.target.value !== values?.carrierName) {
                                renderProps.setFieldValue('serviceCode', '');
                              }
                              setServiceDropDownData(
                                SERVICES.filter(
                                  (a) => a.carrier === e.target.value,
                                ),
                              );
                            }}
                          >
                            {CARRIERS.map((type) => (
                              <MenuItem
                                key={type.label}
                                value={type.value}
                                data-testid="carrier-name-menu-item"
                              >
                                {type.label}
                              </MenuItem>
                            ))}
                          </Field>
                        </Grid>
                        <Grid item style={{ width: '48%' }}>
                          <Field
                            component={CustomSelectTextField}
                            style={{ width: '100%' }}
                            type="text"
                            name="serviceCode"
                            label="Service"
                            className={classes.standardFormTextfield}
                            data-testid="packaging-type-input"
                            customOnChange={(e) => { }}
                          >
                            {serviceDropDownData.map((type) => (
                              <MenuItem
                                key={type.label}
                                value={type.value}
                                data-testid="service-code-menu-item"
                              >
                                {type.label}
                              </MenuItem>
                            ))}
                          </Field>
                        </Grid>
                      </Grid>
                    )}
                    <Grid item container spacing={4} direction="column">
                      {renderProps.values.packages.map((currPackage, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <Grid item key={`${currPackage.key} - ${index}`}>
                          <PackageDetails
                            hiddenFields={hiddenFields}
                            packageNumber={index + 1}
                            numberOfPackages={packageState.numberOfPackages}
                            deletePackage={() => deletePackage(
                              index,
                              arrayHelpers,
                              renderProps.values,
                            )}
                            thisPackage={renderProps.values.packages[index]}
                            setFieldValue={renderProps.setFieldValue}
                            disabled={isSubmitting}
                            expanded={expandedPackage === index}
                            expandedPackage={expandedPackage}
                            setExpanded={() => setExpandedPackage((prev) => (prev === index ? null : index))}
                            toggleApplyToAllPackages={() => handleApplyCheckboxChange({
                              currentPackages: renderProps.values.packages,
                              arrayHelpers,
                              applyToAllPackages:
                                renderProps.values.packages.length > 1
                                  ? false
                                  : packageState.applyToAllPackages,
                            })}
                            formikRef={packFormRef}
                            productItems={
                              renderProps.values.packages[index].items
                            }
                            totalItemsForShipment={totalItemsForShipmentState}
                            setTotalItemsForShipment={
                              setTotalItemsForShipmentState
                            }
                            packageWeight={
                              renderProps.values.packages[index].packageWeight
                            }
                            packageCustomsValue={
                              renderProps.values.packages[index].customsValue
                            }
                            weightUnitOfMeasurementForPackage={
                              renderProps.values.packages[index].packageWeight
                                ?.unitOfMeasurement
                            }
                            account={shipment.account}
                            submitValues={renderProps.submitForm}
                            setValues={renderProps.setValues}
                            values={renderProps.values}
                            formik={renderProps}
                            // eslint-disable-next-line react/prop-types
                            carrierServiceCode={
                              shipment?.carrier?.details?.serviceCode
                            }
                            serviceTypeDropdownData={serviceTypeDropdownData}
                            freightType={freightType}
                            shippingType={shippingType}
                            isChemicalHazmat={isChemicalHazmat}
                            isBattery={isBattery}
                            isLithiumBatteryContainedOrPacked={isLithiumBatteryContainedOrPacked}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                )}
              />
            </Grid>
            <Grid item className={classes.buttonContainer}>
              <Button
                className={classes.button}
                variant="contained"
                color="secondary"
                disabled={isSubmitting}
                onClick={renderProps.submitForm}
                data-testid="packaging-form-next-button"
              >
                Next
              </Button>
            </Grid>
          </Grid>
          {isSubmitting ? (
            <Grid item classes={{ root: classes.miscLoadingContainer }}>
              <CircularProgress color="secondary" />
            </Grid>
          ) : null}
        </Grid>
      </Form>
    );
  }

  return (
    <>
      <Formik
        initialValues={getInitialValues()}
        validationSchema={getValidationSchema(
          freightType,
          hiddenFields,
          account,
          packagesArray,
          isMultipleAddress,
          isChemicalHazmat,
          items,
          isBiologicalHazmat,
          shippingType,
        )}
        onSubmit={onSubmit}
        render={render}
        ref={packFormRef}
      />
      <NewOrderErrorDialog
        open={isError}
        errorContent={errorContent}
        classes={NEW_ORDER_PACKAGING_STYLE()}
        onClose={() => {
          setIsError(false);
          setErrorContent(null);
        }}
      />
    </>
  );
}

PackagingForm.propTypes = {
  shipmentId: PropTypes.string.isRequired,
  submitFormToApi: PropTypes.func.isRequired,
};

export default memo(PackagingForm);
