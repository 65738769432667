import React from 'react';
import PropTypes from 'prop-types';
import CustomsBroker from './CustomsBroker';
import NewOrderShipmentModule from '../common/NewOrderShipmentModule';
import BlockedPanelDisplay from '../common/PanelWarning';

const getLabelDetails = (shipment) => {
  const customsBroker = shipment?.customsBroker;
  if (!customsBroker) return '';

  if (!customsBroker.isCustomsBrokerAvailable) return 'No Custom Broker Assigned';

  const customsBrokerAddress = customsBroker?.address;
  if (!customsBrokerAddress) return 'No Custom Broker Assigned';

  const {
    name, addressLine1, city, state, zip, country,
  } = customsBrokerAddress;

  return `${name} | ${addressLine1} | ${city} | ${state} | ${zip} | ${country}`;
};

const CustomsBrokerPane = ({
  shipment,
  isReadyToBeCompleted,
  blockingMessage,
  ...shipmentProps
}) => (
  <NewOrderShipmentModule
    labelDetails={getLabelDetails(shipment)}
    shipment={shipment}
    isReadyToBeCompleted={isReadyToBeCompleted}
    blockingMessage={blockingMessage}
    {...shipmentProps}
  >
    {(isReadyToBeCompleted) ? ((renderProps) => (
      <CustomsBroker
        shipment={shipment}
        isReadyToBeCompleted={isReadyToBeCompleted}
        {...shipmentProps}
        {...renderProps}
      />
    )) : (
      <BlockedPanelDisplay message={blockingMessage} />
    )}
  </NewOrderShipmentModule>
);

CustomsBrokerPane.propTypes = {
  shipment: PropTypes.shape().isRequired,
  isReadyToBeCompleted: PropTypes.bool.isRequired,
};

export default CustomsBrokerPane;
