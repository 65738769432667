import React from 'react';
import * as notificationsClient from '../utils/notificationsClient';
import { useLogger } from '../utils/hooks';

const notificationState = { notifications: [] };
const NotificationStateContext = React.createContext();
const NotificationDispatchContext = React.createContext();

// notificationReducer Action Types
const LOAD_NOTIFICATIONS = 'LOAD NOTIFICATIONS';
const CLEAR_SINGLE_NOTIFICATION = 'CLEAR SINGLE NOTIFICATION';
const CLEAR_ALL_NOTIFICATIONS = 'CLEAR ALL NOTIFICATIONS';

function filterOutNotification(notifications, notificationId) {
  return notifications.filter((notification) => notification.notificationId !== notificationId);
}

function notificationReducer(state, action) {
  const { notifications } = action;
  switch (action.type) {
    case LOAD_NOTIFICATIONS: {
      return {
        notifications,
      };
    }
    case CLEAR_SINGLE_NOTIFICATION: {
      const { notificationId } = action;
      const newNotifications = filterOutNotification(state.notifications, notificationId);
      return {
        notifications: newNotifications,
      };
    }
    case CLEAR_ALL_NOTIFICATIONS: {
      return {
        notifications: [],
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function NotificationDataProvider(props) {
  const [state, dispatch] = useLogger(
    React.useReducer(notificationReducer, notificationState || { notifications: null }),
  );

  return (
    <NotificationStateContext.Provider value={state} {...props}>
      <NotificationDispatchContext.Provider
        value={dispatch}
        {...props}
      />
    </NotificationStateContext.Provider>
  );
}

function loadNotifications(dispatch, logout) {
  return notificationsClient.fetchUnreadNotifications().then((data) => {
    dispatch({ type: LOAD_NOTIFICATIONS, notifications: data });
    return data;
  });
}

function clearSingleNotification(dispatch, notificationId) {
  return notificationsClient.markSingleNotificationAsRead(notificationId).then((data) => {
    dispatch({ type: CLEAR_SINGLE_NOTIFICATION, notificationId });
    return data;
  });
}
function clearAllNotifications(dispatch) {
  return notificationsClient.markAllNotificationsAsRead().then((data) => {
    dispatch({ type: CLEAR_ALL_NOTIFICATIONS });
    return data;
  });
}

function useNotificationState() {
  const context = React.useContext(NotificationStateContext);
  if (context === undefined) {
    throw new Error(
      'useNotificationState must be used within a NotificationDataProvider',
    );
  }
  return context;
}

function useNotificationDispatch() {
  const context = React.useContext(NotificationDispatchContext);
  if (context === undefined) {
    throw new Error(
      'useNotificationDispatch must be used within a NotificationDataProvider',
    );
  }
  return context;
}

export {
  NotificationDataProvider,
  useNotificationState,
  useNotificationDispatch,
  loadNotifications,
  clearSingleNotification,
  clearAllNotifications,
};
