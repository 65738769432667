import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';
import * as colors from '../../styles/colors';

const styles = {
  text: {
    color: colors.white,
    fontSize: 16,
  },
  container: {
    background: colors.darkBlueBackground,
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    maxHeight: '350px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

const useStyles = makeStyles(styles);
export default function FileDropZone(props) {
  const {
    setFormikFieldValue, setError, handlePreview,
  } = props;

  const classes = useStyles();
  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
    handleUpload(acceptedFiles[0]);
  }, []);

  function handleUpload(file) {
    if (file) {
      handlePreview(file)
        .then(() => {
          setFormikFieldValue('document', file);
        })
        .catch((ex) => {
          setError(JSON.stringify({ error: ex.message }));
          return false;
        });
    }
  }
  const {
    getRootProps, getInputProps, isDragActive, fileRejections,
  } = useDropzone({ onDrop, maxFiles: 1 });

  return (
    <Grid container className={classes.container} {...getRootProps()}>
      <Grid item>
        <input {...getInputProps()} />
        {
        isDragActive
          ? <Typography className={classes.text}>Drop the file here...</Typography>
          : <Typography className={classes.text}>Drag file here to upload</Typography>
      }
      </Grid>
      { (fileRejections && fileRejections.length > 0) && (
        <Grid
          item
          container
          direction="column"
          justify="flex-end"
          alignItems="flex-start"
          style={{ color: colors.errorRed, padding: 15 }}
        >
          <Grid item>
            <Typography style={{ fontSize: 18, fontWeight: 500 }}>
              Rejected Files
            </Typography>
          </Grid>
            {fileRejections.map(({ file, errors }) => (
              <Grid item key={file.path} style={{ overflow: 'auto', width: '100%' }}>
                <Typography style={{ fontSize: 14 }}>
                  {`${file.name}`}
                </Typography>
                {errors.map((e) => (
                  <Typography style={{ fontSize: 12 }}>
                    {`- ${e.message}`}
                  </Typography>
                ))}
              </Grid>
            ))}
        </Grid>
      )}
    </Grid>
  );
}

FileDropZone.propTypes = {
  setFormikFieldValue: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  handlePreview: PropTypes.func.isRequired,
};
