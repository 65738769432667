import React from 'react';
import {
  Grid, Typography, FormControlLabel, Tooltip,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import SendOutlinedIcon from '@material-ui/icons/SendOutlined';
import { makeStyles } from '@material-ui/styles';
import * as colors from '../../styles/colors';
import StyledCheckbox from './StyledCheckbox';

const documentCheckListStyles = {
  titleText: {
    fontWeight: '500px',
    fontSize: 18,
    height: '1em',
  },
  subTitle: {
    color: colors.textLightGrey,
    height: '1em',
  },
  checkListLabel: {
    color: `${colors.white} !important`,
    fontSize: 14,
    paddingLeft: '10px',
  },
  checkBoxDisabled: {
    color: `${colors.white} !important`,
  },
  checkBox: {
    marginLeft: 0,
  },
  checkListItemsContainer: {
    overflowX: 'auto',
  },
};

const useDocumentCheckListStyles = makeStyles(documentCheckListStyles);

function CheckListItems(props) {
  const { subList } = props;
  const classes = useDocumentCheckListStyles();
  return (
    subList ? subList.map((item) => (
      <Grid container item key={item.documentName}>
        <Grid item>
          <FormControlLabel
            control={(<StyledCheckbox style={{ padding: '0px' }} />)}
            checked={item.checked}
            disabled
            label={item.documentName}
            classes={
            {
              label: classes.checkListLabel,
              disabled: classes.checkBoxDisabled,
              root: classes.checkBox,
            }
          }
          />
        </Grid>
        {item.isDocumentSubmitted && (
        <Grid item>
          <Tooltip title="Sent electronically">
            <SendOutlinedIcon
              style={{
                transform: 'rotate(-40deg)', fill: 'white', width: 18, height: 18,
              }}
              color="secondary"
            />
          </Tooltip>
        </Grid>
        )}
      </Grid>
    )) : null
  );
}

export default function DocumentCheckList(props) {
  const {
    checkList, title, maxHeight, includeSubtitles,
  } = props;

  const classes = useDocumentCheckListStyles();

  if (!checkList || Object.keys(checkList).length <= 0) return null;

  return (
    <Grid container direction="column" item spacing={1}>
      <Grid item>
        <Typography color="primary" className={classes.titleText}>{title || ''}</Typography>
      </Grid>
      <Grid item container className={classes.checkListItemsContainer} spacing={1}>
        {checkList.outside && (
        <Grid item>
          <Typography className={classes.subTitle}>
            {(!includeSubtitles || includeSubtitles.outside) ? 'Outside' : '' }
          </Typography>
        </Grid>
        )}
        {checkList.inside && !checkList.outside && (
        <Grid item>
          <Typography className={classes.subTitle}>
            {(!includeSubtitles || includeSubtitles.inside) ? 'Inside' : ''}
          </Typography>
        </Grid>
        )}
        <Grid item container direction="column" spacing={1} style={{ maxHeight: maxHeight || 'none' }}>
          {checkList.outside && (
          <>
            <CheckListItems subList={checkList.outside} />
            {(!includeSubtitles || includeSubtitles.inside) && checkList.inside && (
            <Grid
              item
              style={{ padding: 'calc((62px - 1em) / 2) 4px' }}
            >
              <Typography className={classes.subTitle}>Inside</Typography>
            </Grid>
            )}
          </>
          )}
          <CheckListItems subList={checkList.inside} />
        </Grid>
      </Grid>
    </Grid>
  );
}

DocumentCheckList.propTypes = {
  checkList: PropTypes.objectOf(PropTypes.any).isRequired,
  title: PropTypes.string,
  maxHeight: PropTypes.number,
  includeSubtitles: PropTypes.objectOf(PropTypes.any),
};

DocumentCheckList.defaultProps = {
  title: '',
  maxHeight: null,
  includeSubtitles: {},
};
