/* eslint-disable no-useless-return */
/* eslint-disable no-prototype-builtins */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import {
  Grid, ExpansionPanel, ExpansionPanelSummary, Typography, Button, IconButton, ExpansionPanelDetails, Tooltip, CircularProgress,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import PrintIcon from '@material-ui/icons/PrintOutlined';
import AssignTaskIcon from '@material-ui/icons/AssignmentOutlined';
import RevokeTaskIcon from '@material-ui/icons/AssignmentReturnedOutlined';
import Upload from '@material-ui/icons/PublishOutlined';
import Edit from '@material-ui/icons/EditOutlined';
import Delete from '@material-ui/icons/DeleteOutlined';
import HistoryIcon from '@material-ui/icons/HistoryOutlined';
import OutlinedTruckIcon from '@material-ui/icons/LocalShippingOutlined';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { flatten } from 'lodash';
import TaskAlertIcon from '@material-ui/icons/ReportProblemOutlined';
import * as colors from '../styles/colors';
import TrackingModal from './TrackingModal';
import { SHIPMENT_TRACKING_TAB, IOT_TRACKING_TAB } from '../clientConstants';
import IotIcon from './images/rss-white.svg';
import {
  combineDocuments, createAndPrintPDF, isModuleComplete, sortDocuments, printThermal,
} from '../utils/documentsUtil';
import DocumentCheckList from './common/DocumentCheckList';
import UploadDocument from './common/UploadDocument';
import ConfirmationModal from './common/ConfirmationModal';
import AssignDocumentTask from './AssignDocumentTask';
import {
  deleteShippingDocument, updateCustomsClearance, updateShipmentConfirm, useSingleOrderDispatch,
} from '../context/singleOrderContext';
import { revokeDocTask } from '../context/taskDataContext';
import NewOrderErrorDialog from './common/NewOrderErrorDialog';
import SignDocument from './common/SignDocument';
import SignDocumentExisting from './common/SignDocumentExisting';
import SignIcon from './images/SignIcon';
import DocumentPreview from './common/DocumentPreview';
import taskHelp from '../utils/taskStatus';
import StatusChip from './common/StatusChip';
import CustomModal from './common/CustomModal';
import { TaskAssignedByUser, TaskAssignedToUser } from './common/TaskAssigned';
import { getPackageNumber, isESGAdmin } from '../utils/helpers';
import DocumentHistory from './common/DocumentHistory';
import NewOrderNextButton from './common/NewOrderNextButton';
import { useUserState } from '../context/userContext';
import { FREIGHT_TYPES } from './ItemProductDetails/itemDetailsConstants';

const DEFAULT_TRACKING_MODAL_STATE = {
  shipmentId: null,
  trackingNumber: null,
  anchorEl: null,
  type: null,
  iotTrackingNumber: null,
};

const ACTION_TYPES = {
  printAll: 'printAll',
  print: 'print',
  upload: 'upload',
  trackShipment: 'trackShipment',
  edit: 'edit',
  delete: 'delete',
  assignTask: 'assign',
  revokeTask: 'revoke',
  trackIoT: 'trackIoT',
  signature: 'signature',
  history: 'history',
};

const actionsMenuStyles = {
  actionsMenuContainer: {
    // background: 'rgba(0, 0, 0, .1)',
    borderRadius: '4px',
    minWidth: 130,
  },
  actionsMenuTooltip: {
    background: colors.mediumBlueBackground,
    padding: '0',
    borderRadius: '4px',
    boxShadow: '0px 0px 9px -5px black',
  },
  actionsPopover: {
    background: colors.mediumBlueBackground,
    height: 'fit-content',
    overflowY: 'hidden',
    maxHeight: 'none',
  },
  actionButtonContainer: {
    // height: '1.5em',
    fontSize: 14,
  },
  actionButtonText: {
    fontSize: 'inherit',
    textTransform: 'none',
  },
  actionIconContainer: {
    height: 'calc(1.5rem + 16px)',
  },
  button: {
    padding: 0,
  },
};

const useActionsMenuStyles = makeStyles(actionsMenuStyles);

const ActionIcons = {
  [ACTION_TYPES.printAll]: PrintIcon,
  [ACTION_TYPES.print]: PrintIcon,
  [ACTION_TYPES.upload]: Upload,
  [ACTION_TYPES.trackShipment]: OutlinedTruckIcon,
  [ACTION_TYPES.edit]: Edit,
  [ACTION_TYPES.delete]: Delete,
  [ACTION_TYPES.assignTask]: AssignTaskIcon,
  [ACTION_TYPES.revokeTask]: RevokeTaskIcon,
  [ACTION_TYPES.trackIoT]: () => <img src={IotIcon} style={{ height: '1.5rem' }} alt="" />,
  [ACTION_TYPES.signature]: SignIcon,
  [ACTION_TYPES.history]: HistoryIcon,
};

function ActionsMenu(props) {
  const { actions, onAction, onOpen } = props;
  const classes = useActionsMenuStyles();
  const [open, setOpen] = React.useState(false);

  const onActionClick = (type) => {
    onAction(type);
    setOpen(() => false);
  };

  React.useEffect(() => {
    onOpen(open);
  }, [open]);

  return (
    <Tooltip
      open={open}
      classes={{ tooltip: classes.actionsMenuTooltip }}
      interactive
      placement="left-start"
      onClick={(e) => e.stopPropagation()}
      onOpen={(e) => {
        setOpen(() => true);
        if (open
          || !e.currentTarget
          || !e.currentTarget.getAttribute
          || e.currentTarget.getAttribute('role') === 'tooltip') return;
      }}
      onClose={() => {
        setOpen(() => false);
      }}
      TransitionProps={{
        timeout: {
          exit: 0,
          enter: 200,
          appear: 200,
        },
      }}
      enterNextDelay={100}
      title={(
        <Grid
          container
          direction="column"
          className={classes.actionsMenuContainer}
        >
          {actions && actions.map((action) => {
            const ActionIcon = ActionIcons[action.type] || (() => (<></>));
            return (
              <Grid
                item
                key={action.type}
                container
                className={classes.actionButtonContainer}
              >
                <Grid item xs={12}>
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      onActionClick(action.type);
                    }}
                    fullWidth
                  >
                    <Grid item container spacing={2}>
                      <Grid item className={classes.actionIconContainer}>
                        <ActionIcon
                          color="primary"
                        />
                      </Grid>
                      <Grid item>
                        <Typography color="primary" className={classes.actionButtonText}>
                          {action.description}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Button>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
     )}
    >
      <MoreHoriz
        color="primary"
        className={classes.button}
      />
    </Tooltip>
  );
}
ActionsMenu.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.objectOf({
    type: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  })).isRequired,
  onAction: PropTypes.func.isRequired,
  onOpen: PropTypes.func,
};

ActionsMenu.defaultProps = {
  onOpen: () => {},
};

const expansionListStyles = {
  expansionPanel: {
    background: colors.darkBlueBackground,
    boxSizing: 'border-box',
  },
  expansionPanelLight: {
    background: `rgba(${colors.darkBlueBackgroundRGB},.6)`,
  },
  expansionPanelNormal: {
  },
  expansionPanelSelected: {
    outline: `1px solid ${colors.lightSkyBlue}`,
    zIndex: '10',
  },
  expansionPanelUnselected: {
    border: 'none',
    outline: 'none',
  },
  buttonContainer: {
    height: '1.5rem',
  },
  title: {
    fontWeight: '500',
    fontSize: 18,
  },
  subTitle: {
    fontSize: 14,
    color: colors.tableGreyText,
    paddingTop: '4px',
  },
  annotation: {
    fontSize: 12,
    color: colors.textLightGrey,
    fontWeight: 500,
    // paddingBottom: '4px',
  },
  taskIconContainer: {
    display: 'flex',
    color: colors.warningYellow,
  },
};

const useExpansionListStyles = makeStyles(expansionListStyles);

function TaskPending(props) {
  const { taskCount } = props;
  const classes = useExpansionListStyles();
  return (
    <Grid item>
      <Grid item container alignItems="center" spacing={1}>
        <Grid
          item
          className={classes.taskIconContainer}
        >
          <TaskAlertIcon />
        </Grid>
        <Grid item>
          <Typography color="primary" className={classes.annotation}>
            {`${taskCount} ${taskCount > 1 ? 'tasks' : 'task'} pending`}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

TaskPending.propTypes = {
  taskCount: PropTypes.number.isRequired,
};
function ShippingDocumentsExpansionList(props) {
  const classes = useExpansionListStyles();
  const {
    title, subTitle, children, annotation, actions, onAction, defaultExpanded, color, printDocuments, taskCount, disabled,
  } = props;

  const [selected, setSelected] = React.useState(false);

  return (
    <ExpansionPanel
      defaultExpanded={defaultExpanded}
      className={`${classes.expansionPanel} ${selected ? classes.expansionPanelSelected : classes.expansionPanelUnselected} ${color === 'light' ? classes.expansionPanelLight : classes.expansionPanelNormal}`}
      square
    >
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon color="primary" />}>
        <Grid container justify="space-between" alignItems="center">
          <Grid container item direction="column" xs={10}>
            {annotation && (
            <Grid item container spacing={1} alignItems="center">
              <Grid item>
                <Typography color="primary" className={classes.annotation}>
                  {annotation}
                </Typography>
              </Grid>
              {
                taskCount > 0 && (
                <TaskPending taskCount={taskCount} />
                )
              }
            </Grid>
            )}
            <Grid item container spacing={1} alignItems="center">
              <Grid item>
                <Typography color="primary" className={classes.title}>
                  {title}
                </Typography>
              </Grid>
              {
                !annotation && taskCount > 0 && (
                  <TaskPending taskCount={taskCount} />
                )
              }
            </Grid>
            {subTitle && (
            <Grid item>
              <Typography color="primary" className={classes.subTitle}>
                {subTitle}
              </Typography>
            </Grid>
            )}
          </Grid>
          <Grid item className={classes.buttonContainer}>
            {(actions && actions.length > 0) && (
            <ActionsMenu
              actions={disabled ? null : actions}
              onAction={onAction}
              onOpen={setSelected}
            />
            )}
          </Grid>
          {(printDocuments && printDocuments.length > 0) && (
          <Grid item className={classes.buttonContainer}>
            <IconButton
              disableFocusRipple
              onClick={(e) => {
                e.stopPropagation();
                combineDocuments(printDocuments);
              }}
              style={{ padding: '0' }}
            >
              <PrintIcon color="primary" />
            </IconButton>
          </Grid>
          )}
        </Grid>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        {children}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
}

ShippingDocumentsExpansionList.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
  subTitle: PropTypes.string,
  annotation: PropTypes.string,
  actions: PropTypes.arrayOf(PropTypes.any).isRequired,
  onAction: PropTypes.func.isRequired,
  defaultExpanded: PropTypes.bool,
  color: PropTypes.string,
  printDocuments: PropTypes.arrayOf(PropTypes.any),
  taskCount: PropTypes.number,
  disabled: PropTypes.bool,
};

ShippingDocumentsExpansionList.defaultProps = {
  children: null,
  subTitle: null,
  annotation: null,
  defaultExpanded: false,
  color: 'normal',
  printDocuments: [],
  taskCount: 0,
  disabled: false,
};

const shippingDocumentListItemStyles = {
  documentText: {
    textTransform: 'uppercase',
    color: colors.lightSkyBlue,
    fontWeight: 600,
    letterSpacing: '1.2px',
  },
  taskPreviewButton: {
    padding: 0,
  },
  documentPreviewButton: {
    padding: 0,
  },
  listItemContainerNoIcon: {
    padding: '0 30px',
  },
  listItemContainerWithIcon: {
    paddingRight: '30px',
  },
  listItemMenuOpen: {
    // background: 'rgba(0, 0, 0, .15)',
    outline: `1px solid ${colors.lightSkyBlue}`,
  },
  listItemMenuClosed: {
    outline: 'none',
  },
  signIcon: {
    height: 24,
  },
  customModal: {
    height: 'auto',
    width: 650,
    maxWidth: 'none',
  },
};

const useShippingDocumentListItemStyles = makeStyles(shippingDocumentListItemStyles);

function ShippingDocumentListItem(props) {
  const {
    onDocumentClick, document, onAction, disabled,
  } = props;

  const classes = useShippingDocumentListItemStyles();
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [taskPreview, setTaskPreview] = React.useState(null);

  const onOpen = (open) => {
    setMenuOpen(open);
  };

  // eslint-disable-next-line max-len
  const isDocumentEmpty = !document.document;
  console.log(`Document panel${JSON.stringify(document)}`);
  const displayUploadIcon = !!(document?.actions?.find((action) => action.type === ACTION_TYPES.upload) && isDocumentEmpty) || !!(document.task && document.task.documentTaskType === ACTION_TYPES.upload && document.task.isAssignedTo && taskHelp.isTaskInProgress(document.task));
  const displaySignIcon = !displayUploadIcon && (!!(document?.actions?.find((action) => action.type === ACTION_TYPES.signature) && !document.isSigned) || !!(document.task && document.task.documentTaskType === ACTION_TYPES.signature && document.task.isAssignedTo && taskHelp.isTaskInProgress(document.task)));

  return (
    <Grid
      item
      xs={12}
      className={`${menuOpen ? classes.listItemMenuOpen : classes.listItemMenuClosed}`}
    >
      <Grid
        container
        className={
          (displaySignIcon || displayUploadIcon)
            ? classes.listItemContainerWithIcon
            : classes.listItemContainerNoIcon
        }
        alignItems="center"
        wrap="nowrap"
      >
        {displayUploadIcon && (
        <Grid item style={{ width: '24px', marginRight: '6px', display: 'flex' }}>
          <IconButton
            disableFocusRipple
            onClick={(e) => {
              onAction(ACTION_TYPES.upload);
            }}
            style={{ padding: '0' }}
            disabled={disabled}
          >
            <Upload color="primary" />
          </IconButton>
        </Grid>
        )}
        {displaySignIcon && (
        <Grid item style={{ width: '24px', marginRight: '6px', display: 'flex' }}>
          <IconButton
            disableFocusRipple
            onClick={(e) => {
              onAction(ACTION_TYPES.signature);
            }}
            style={{ padding: '0' }}
            disabled={disabled}
          >
            <SignIcon className={classes.signIcon} />
          </IconButton>
        </Grid>
        )}
        <Grid item container justify="space-between">
          <Grid item>
            <Grid item container spacing={1}>
              <Grid item>
                <Button
                  disableRipple
                  disableFocusRipple
                  disableTouchRipple
                  onClick={onDocumentClick}
                  className={classes.documentPreviewButton}
                  disabled={isDocumentEmpty || document?.isThermalLabel}
                >
                  <Typography className={classes.documentText}>
                    {document.documentName}
                  </Typography>
                </Button>
              </Grid>
              {document.task && taskHelp.isTaskAssigned(document.task)
              && (
                <Grid item style={{ display: 'flex' }}>
                  <Button
                    disableRipple
                    disableFocusRipple
                    disableTouchRipple
                    onClick={() => setTaskPreview(document.task)}
                    className={classes.taskPreviewButton}
                  >
                    <Grid item style={{ fontSize: 12, display: 'flex', alignItems: 'center' }}>
                      <StatusChip type="task" status={document.task.status} />
                    </Grid>
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item style={{ display: 'flex' }}>
            <ActionsMenu
              actions={disabled ? null : document.actions}
              onAction={onAction}
              onOpen={onOpen}
            />
          </Grid>
          <CustomModal
            title="Task Pending"
            subtitle={(
              <Grid item container spacing={2} alignItems="center">
                <Grid item>
                  <span>{document.displayName || document.documentName}</span>
                </Grid>
                <Grid item style={{ fontSize: 12 }}>
                  <StatusChip type="task" status={taskPreview && taskPreview.status} />
                </Grid>
              </Grid>
            )}
            open={!!taskPreview}
            onClose={() => setTaskPreview(null)}
            classes={{ root: classes.customModal }}
            enableBackdropClick
            enableEscapeKeyDown
          >
            {taskPreview
            && (
            <Grid item container style={{ marginTop: 10 }}>
              {taskPreview.isAssignedTo
                ? (
                  <TaskAssignedToUser
                    task={taskPreview}
                    preReqsComplete
                    TaskIcon={ActionIcons[document.task.documentTaskType]}
                  />
                ) : (
                  <TaskAssignedByUser
                    task={taskPreview}
                    TaskIcon={ActionIcons[document.task.documentTaskType]}
                    disableRevoke
                    disableRerun
                  />
                )}
            </Grid>
            )}
          </CustomModal>
        </Grid>
      </Grid>
    </Grid>
  );
}

ShippingDocumentListItem.propTypes = {
  onDocumentClick: PropTypes.func.isRequired,
  document: PropTypes.objectOf(PropTypes.any).isRequired,
  onAction: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

ShippingDocumentListItem.defaultProps = {
  disabled: false,
};

const shippingDocumentsStyles = {
  documentsContainer: {
    padding: '0 0',
  },
  checkListContainer: {
    // flexGrow: 1,
    flexWrap: 'wrap',
    // maxHeight: '250px',
  },
  divider: {
    borderBottom: '1px solid #3C404E',
  },
  customModal: {
    height: 'auto',
    width: 650,
    maxWidth: 'none',
  },
  taskIconContainer: {
    display: 'flex',
    color: colors.warningYellow,
  },
  errorHeaderText: {
    fontWeight: 500,
  },
  errorBodyText: {
    fontSize: 14,
    fontWeight: 400,
  },
  errorsContainer: {
    padding: '25px 80px 0px 80px',
  },
  errorContainer: {
    padding: '10px 45px',
    background: `rgba(${colors.darkBlueBackgroundRGB},.6)`,
  },
  submitButtonContainer: {
    padding: '25px 0 0 0',
  },
};

const useShippingDocumentsStyles = makeStyles(shippingDocumentsStyles);

export default function ShippingDocuments({
  shipment, submitFormToApi,
  setSelectedForms, isSelected,
  openNextPanel,
}) {
  const classes = useShippingDocumentsStyles();
  const panelRef = React.createRef(null);
  const {
    documentation, shipmentId, isCreatedBy, isEHS, isECO,
  } = shipment;
  const {
    packages, documents, actions, errors,
  } = documentation;

  const { isCustomsBroker = false } = shipment;
  const isCustomsCleared = !!shipment?.customsClearance?.details?.isCustomsCleared;

  const trackingAnchorRef = React.useRef(null);
  const [trackingModalState, setTrackingModalState] = React.useState(DEFAULT_TRACKING_MODAL_STATE);
  const [currentTrackingTab, setCurrentTrackingTab] = React.useState(SHIPMENT_TRACKING_TAB);
  const [preview, setPreview] = React.useState(null);
  const [upload, setUpload] = React.useState(null);
  const [signature, setSignature] = React.useState(null);
  const [deleteDoc, setDeleteDoc] = React.useState(null);
  const [assignDocTask, setAssignDocTask] = React.useState(null);
  const [revokingDocTask, setRevokingDocTask] = React.useState(null);
  const [documentHistory, setDocumentHistory] = React.useState(null);
  const [devices, setDevices] = React.useState([]);
  const [printer, setPrinter] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [shipmentCompleteModal, setShipmentCompleteModal] = React.useState(false);

  const { useProfileSignature, userType } = useUserState();

  const dispatch = useSingleOrderDispatch();
  const esgAdmin = isESGAdmin(userType);
  const isFreightAdmin = esgAdmin && shipment.freightType === FREIGHT_TYPES.freight;

  React.useEffect(() => {
    window.BrowserPrint.getLocalDevices((deviceList) => {
      setDevices(deviceList.printer);
    }, (err) => {
      console.log(err);
    });
    window.BrowserPrint.getDefaultDevice('printer', (device) => {
      setPrinter(device);
    });
  }, []);

  React.useEffect(() => {
    if (isSelected) {
      panelRef.current.scrollIntoView();
    }
  }, [isSelected]);

  React.useEffect(() => {
    if (shipment.account === 'worldemblem' && shipment?.confirmed && printer) {
      console.log('printing');
      if (shipment?.documentation?.packages?.length) { initialPrint(); }
    }
  }, [shipment?.documentation?.packages?.length, printer]);
  const handleTrackingClose = () => {
    setTrackingModalState({
      shipmentId: null, trackingNumber: null, anchorEl: null, iotTrackingNumber: null,
    });
    setCurrentTrackingTab(SHIPMENT_TRACKING_TAB);
  };

  function handlePrintAll(documentList) {
    const thermalDocuments = documentList.filter((document) => document.isThermalLabel === true);
    thermalDocuments.forEach((doc) => {
      const printdoc = Buffer.from(doc.document, 'base64').toString();
      // console.log(`printing: ${doc.documentName}`);
      printThermal(printer, printdoc);
    });
    const otherDocuments = documentList.filter((document) => document?.isThermalLabel !== true);
    if (otherDocuments.length) {
      combineDocuments(otherDocuments);
    }
  }
  function handlePrint(document) {
    if (document?.isThermalLabel) {
      printThermal(printer, Buffer.from(document.document, 'base64').toString());
    } else {
      createAndPrintPDF(document.document);
    }
  }
  function handleTrackShipment(doc) {
    const { trackingNumber, iotTrackingNumber } = doc;
    const docShipmentId = doc.shipmentId;
    const newTrackingModalState = {
      shipmentId: docShipmentId || shipmentId,
      trackingNumber,
      anchorEl: trackingAnchorRef.current,
      iotTrackingNumber,
    };

    setTrackingModalState(newTrackingModalState);
  }
  async function initialPrint() {
    console.log('Inside initial print');
    // eslint-disable-next-line no-shadow
    const docs = shipment?.documentation?.packages.reduce((a, { documents }) => [...a, ...documents], []);
    const otherDocs = shipment?.documentation?.documents ? shipment?.documentation?.documents : [];
    handlePrintAll(docs.concat(otherDocs));

    await updateShipmentConfirm(shipment, dispatch, shipment.shipmentId);
  }
  async function handleDeleteDocument(doc) {
    try {
      await deleteShippingDocument({ dispatch, shipmentId, ...doc });
    } catch (e) {
      setError(JSON.stringify({ error: e.message }));
    }
    setDeleteDoc(null);
  }

  async function handleRevokeDocTask(doc) {
    const {
      documentId, packageId, documentType, task,
    } = doc;
    const { documentTaskType } = task;
    const taskType = 'document';

    try {
      await revokeDocTask({
        dispatch, shipmentId, documentId, packageId, documentType, documentTaskType, taskType,
      });
    } catch (e) {
      setError(JSON.stringify({ error: e.message }));
    }
    setRevokingDocTask(null);
  }

  const onDocumentListAction = (type, documentObj) => {
    switch (type) {
      case ACTION_TYPES.printAll:
        handlePrintAll(documentObj.documents);
        break;
      case ACTION_TYPES.trackShipment:
        handleTrackShipment(documentObj);
        break;
      case ACTION_TYPES.upload:
        setUpload({
          documentType: documentObj.documentType,
          documentName: documentObj.documentName,
          packageId: documentObj.packageId,
          documentId: documentObj.documentId,
          uploadOptions: documentObj.uploads,
          displayName: documentObj.displayName,
          nonRMSContents: documentObj.nonRMSContents,
          documents: documentObj.documents,
        });
        break;
      default:
        break;
    }
  };

  const onDocumentItemAction = (type, document) => {
    switch (type) {
      case ACTION_TYPES.print:
        handlePrint(document);
        break;
      case ACTION_TYPES.trackShipment:
        handleTrackShipment(document);
        break;
      case ACTION_TYPES.trackIoT:
        setCurrentTrackingTab(IOT_TRACKING_TAB);
        handleTrackShipment(document);
        break;
      case ACTION_TYPES.upload:
        setUpload({
          documentType: document.documentType,
          documentName: document.documentName,
          packageId: document.packageId,
          documentId: document.documentId,
          uploadOptions: document.uploads,
          displayName: document.displayName,
          nonRMSContents: document.nonRMSContents,
          task: (document.task && document.task.isAssignedTo && document.task.documentTaskType === ACTION_TYPES.upload)
            ? document.task
            : null,
        });
        break;
      case ACTION_TYPES.signature:
        setSignature({
          documentType: document.documentType,
          documentName: document.documentName,
          packageId: document.packageId,
          documentId: document.documentId,
          document: document.document,
          signature: document.signature,
          displayName: document.displayName,
          task: (document.task && document.task.isAssignedTo && document.task.documentTaskType === ACTION_TYPES.signature)
            ? document.task
            : null,
        });
        break;
      case ACTION_TYPES.delete:
        setDeleteDoc({
          documentType: document.documentType,
          documentName: document.documentName,
          packageId: document.packageId,
          documentId: document.documentId,
        });
        break;
      case ACTION_TYPES.assignTask:
        setAssignDocTask({
          documentType: document.documentType,
          documentName: document.documentName,
          displayName: document.displayName,
          packageId: document.packageId,
          documentId: document.documentId,
          document: document.document,
          taskOptions: document.actions.filter((action) => [ACTION_TYPES.upload, ACTION_TYPES.signature].includes(action.type)),
        });
        break;
      case ACTION_TYPES.revokeTask:
        setRevokingDocTask({
          documentType: document.documentType,
          documentName: document.documentName,
          displayName: document.displayName,
          packageId: document.packageId,
          documentId: document.documentId,
          task: document.task,
        });
        break;
      case ACTION_TYPES.history:
        setDocumentHistory({
          documentId: document.documentId,
          documentName: document.documentName,
          documentType: document.documentType,
          packageId: document.packageId,
          displayName: document.displayName,
        });
        break;
      default:
        break;
    }
  };

  function openDocumentPreview(document) {
    setPreview(document);
  }

  function formatDocumentChecklist(checklists, { maxDocuments = 7, formatTitle = (checklist, index, arr) => '' }) {
    const formattedChecklists = checklists.map((checklist, idx, arr) => {
      const colArr = [];
      const { inside, outside } = checklist;
      const outsideRemaining = outside ? [...outside] : [];
      const insideRemaining = inside ? [...inside] : [];
      if (outsideRemaining.length <= 0 && insideRemaining <= 0) {
        return null;
      }
      const col1 = {
        ...checklist,
        title: formatTitle(checklist, idx, arr),
        includeSubtitles: {},
        inside: null,
        outside: null,
      };
      colArr.push(col1);
      for (let i = 0; i < colArr.length; i += 1) {
        let max = maxDocuments;
        if (outsideRemaining.length > 0) {
          if (i <= 0 || !colArr[i - 1].outside) {
            colArr[i].includeSubtitles.outside = true;
          }
          colArr[i].outside = outsideRemaining.splice(0, max);
        }

        const outsideLength = colArr[i].outside ? colArr[i].outside.length : 0;
        if (insideRemaining.length > 0 && outsideLength < max - 2) {
          if (i <= 0 || !colArr[i - 1].inside) {
            colArr[i].includeSubtitles.inside = true;
            if (outsideLength > 0) max -= 2;
          }
          colArr[i].inside = insideRemaining.splice(0, max - outsideLength);
        }

        if (insideRemaining.length > 0 || outsideRemaining.length > 0) {
          colArr.push({
            ...checklist,
            includeSubtitles: {},
            inside: null,
            outside: null,
          });
        }
      }
      return colArr;
    }).filter((cl) => cl);
    return flatten(formattedChecklists);
  }

  async function onSubmit() {
    setIsSubmitting(true);
    await submitFormToApi({});
    if (shipment.pickupDropoff === 'pickup') {
      openNextPanel();
    } else {
      setShipmentCompleteModal(true);
    }
    setIsSubmitting(false);
  }

  async function onSubmitCustomsBroker() {
    setIsSubmitting(true);

    const customsClearance = {
      details: {
        isCustomsCleared: true,
      },
    };
    await updateCustomsClearance({ dispatch, shipmentId, customsClearance });
    setIsSubmitting(false);
  }

  const hasShippingCheckList = documentation.checkList && !isChecklistEmpty(documentation.checkList.shipment);
  const hasPackageCheckList = documentation.checkList.packages && !isChecklistEmpty(documentation.checkList.packages);
  const hasOutstandingTasks = errors && (errors.length > 0);

  return (
    <Grid container direction="column" ref={trackingAnchorRef}>
      <div ref={panelRef} />
      {(hasShippingCheckList || hasPackageCheckList) && (
        <Grid item style={{ display: 'contents' }}>
          <ShippingDocumentsExpansionList
            printDocuments={
              documentation.checkList.document && [
                { document: documentation.checkList.document },
              ]
            }
            title="Checklist"
            defaultExpanded
          >
            <Grid item container direction="column" spacing={2}>
              <Grid item container spacing={3} justify="flex-start">
                {hasShippingCheckList
                  && formatDocumentChecklist([documentation.checkList.shipment], {
                    formatTitle: () => 'For shipment',
                  }).map((checkList, index, arr) => (
                    <Grid
                      item
                      key={`checklist-grid-${index}`}
                      className={`${classes.checkListContainer} ${
                        index / 3 < Math.floor((arr.length - 1) / 3)
                          ? classes.divider
                          : ''
                      }`}
                      includeSubtitles={checkList.includeSubtitles}
                      xs={5}
                    >
                      <DocumentCheckList
                        checkList={checkList}
                        title={checkList.title ? 'For Shipment' : null}
                        maxHeight="400px"
                        includeSubtitles={checkList.includeSubtitles}
                      />
                    </Grid>
                  ))}
              </Grid>
              {hasPackageCheckList && hasShippingCheckList && (
                <Grid
                  item
                  container
                  className={classes.divider}
                  style={{ marginBottom: 10 }}
                />
              )}
              <Grid item container spacing={3} justify="flex-start">
                {hasPackageCheckList
                  && formatDocumentChecklist(documentation.checkList.packages, {
                    formatTitle: (_checklist, idx, arr) => `Package ${idx + 1} of ${arr.length}`,
                  }).map((checkList, index, arr) => (
                    <Grid
                      item
                      key={checkList.nonRMSContents}
                      className={`${classes.checkListContainer} ${
                        index / 3 < Math.floor((arr.length - 1) / 3)
                          ? classes.divider
                          : ''
                      }`}
                      xs={4}
                    >
                      <DocumentCheckList
                        checkList={checkList}
                        title={checkList.title}
                        maxHeight="400px"
                        includeSubtitles={checkList.includeSubtitles}
                      />
                    </Grid>
                  ))}
              </Grid>
            </Grid>
          </ShippingDocumentsExpansionList>
        </Grid>
      )}
      {documents && documents.length > 0 && (
        <Grid item style={{ display: 'contents' }}>
          <ShippingDocumentsExpansionList
            defaultExpanded
            title="For Shipment"
            actions={actions}
            onAction={(type) => onDocumentListAction(type, documentation)}
            color={
              hasPackageCheckList || hasShippingCheckList ? 'light' : 'normal'
            }
            taskCount={documents.reduce(
              (a, c) => (getAssignedTasksFromDocument(c) ? a + 1 : a),
              0,
            )}
            disabled={isSubmitting}
          >
            <Grid
              item
              container
              className={classes.documentsContainer}
              spacing={2}
            >
              {documents.sort(sortDocuments).map((doc) => (
                <ShippingDocumentListItem
                  document={doc}
                  onDocumentClick={() => openDocumentPreview(doc)}
                  onAction={(type) => onDocumentItemAction(type, doc)}
                  disabled={isSubmitting}
                />
              ))}
            </Grid>
          </ShippingDocumentsExpansionList>
        </Grid>
      )}
      <Grid item container direction="column" wrap="nowrap">
        {packages
          .filter((pkg) => pkg.documents && pkg.documents.length > 0)
          .map(
            (pkg, index) => pkg.documents
              && pkg.documents.length > 0 && (
                <Grid
                  item
                  key={pkg.nonRMSContents}
                  style={{ display: 'contents' }}
                >
                  <ShippingDocumentsExpansionList
                    defaultExpanded
                    title={pkg.nonRMSContents}
                    subTitle={pkg.packagingName}
                    annotation={`Package ${getPackageNumber(
                      pkg.packageId,
                    )} of ${packages.length}`}
                    actions={pkg.actions}
                    onAction={(type) => onDocumentListAction(type, pkg)}
                    color={
                      (index
                        + (documents && documents.length > 0 ? 1 : 0)
                        + (hasShippingCheckList || hasPackageCheckList ? 1 : 0))
                        % 2
                      === 0
                        ? 'normal'
                        : 'light'
                    }
                    taskCount={pkg.documents.reduce(
                      (a, c) => (getAssignedTasksFromDocument(c) ? a + 1 : a),
                      0,
                    )}
                    disabled={isSubmitting}
                  >
                    <Grid
                      item
                      container
                      className={classes.documentsContainer}
                      spacing={2}
                    >
                      {pkg.documents.sort(sortDocuments).map((doc) => (
                        <ShippingDocumentListItem
                          document={doc}
                          onDocumentClick={() => openDocumentPreview(doc)}
                          onAction={(type) => onDocumentItemAction(type, doc)}
                          disabled={isSubmitting}
                        />
                      ))}
                    </Grid>
                  </ShippingDocumentsExpansionList>
                </Grid>
            ),
          )}
      </Grid>
      {(isCreatedBy || isEHS || isECO) && (
        <>
          {hasOutstandingTasks && (
            <Grid item>
              <Grid className={classes.errorsContainer} container>
                <Grid item container direction="column" spacing={2}>
                  <Grid item container justify="center">
                    <Grid item>
                      <Typography
                        color="primary"
                        className={classes.errorHeaderText}
                      >
                        Please confirm all of the information above is correct
                        before submitting.
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item spacing={2} container direction="column">
                    {errors.map((errDesc) => (
                      <Grid item>
                        <Grid container className={classes.errorContainer}>
                          <Grid item container wrap="nowrap" spacing={1}>
                            <Grid item className={classes.taskIconContainer}>
                              <TaskAlertIcon />
                            </Grid>
                            <Grid item>
                              <Typography
                                color="primary"
                                className={classes.errorBodyText}
                              >
                                {errDesc}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}

          {!isModuleComplete(shipment)
            && (shipment.hasOwnProperty('isDocumentsGenerated')
              ? shipment?.isDocumentsGenerated
              : true) && (
              <Grid item>
                <Grid
                  container
                  className={classes.submitButtonContainer}
                  justify="center"
                >
                  <Grid item>
                    {
                      // eslint-disable-next-line no-nested-ternary
                      isSubmitting ? (
                        <CircularProgress color="secondary" />
                      ) : shipment.account !== 'worldemblem' ? (
                        <NewOrderNextButton
                          disabled={hasOutstandingTasks}
                          onClick={onSubmit}
                        >
                          Send Paperwork
                        </NewOrderNextButton>
                      ) : (
                        <> </>
                      )
                    }
                  </Grid>
                </Grid>
              </Grid>
          )}
        </>
      )}
      {shipment.hasOwnProperty('isDocumentsGenerated')
        && !shipment?.isDocumentsGenerated && (
          <Grid item>
            <Typography color="primary" className={classes.errorBodyText}>
              Documents are being generated. Please check back after some time
              when documents are available.
            </Typography>
          </Grid>
      )}
      {isCustomsBroker && !isCustomsCleared && (
        <Grid item>
          <Grid
            container
            className={classes.submitButtonContainer}
            justify="center"
          >
            <Grid item>
              {isSubmitting ? (
                <CircularProgress color="secondary" />
              ) : (
                <NewOrderNextButton onClick={onSubmitCustomsBroker}>
                  Clear Shipment for Customs
                </NewOrderNextButton>
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
      <DocumentPreview
        open={!!preview}
        onClose={() => setPreview(null)}
        doc={preview ? preview.document : null}
      />
      <UploadDocument
        open={!!upload}
        onClose={() => setUpload(null)}
        classes={{ paper: classes.uploadContainer }}
        uploadObject={upload}
        shipmentId={shipmentId}
        isFreightAdmin={isFreightAdmin}
      />
      {useProfileSignature ? (
        <SignDocumentExisting
          open={!!signature}
          onClose={() => setSignature(null)}
          classes={{ paper: classes.signatureContainer }}
          signObject={signature}
          shipmentId={shipmentId}
        />
      ) : (
        <SignDocument
          open={!!signature}
          onClose={() => setSignature(null)}
          classes={{ paper: classes.signatureContainer }}
          signObject={signature}
          shipmentId={shipmentId}
        />
      )}
      <AssignDocumentTask
        open={!!assignDocTask}
        onClose={() => setAssignDocTask(null)}
        classes={{ paper: classes.assignTaskContainer }}
        assignTaskObj={assignDocTask}
        shipmentId={shipmentId}
      />
      <TrackingModal
        id={`tracking-${trackingModalState.shipmentId || ''}`}
        handleClose={handleTrackingClose}
        trackingModalState={trackingModalState}
        currentTab={currentTrackingTab}
        setCurrentTab={setCurrentTrackingTab}
        shippingDocumentsPanel
      />
      <ConfirmationModal
        open={!!deleteDoc}
        message={
          deleteDoc
            ? `Are you sure you want to delete ${deleteDoc.documentName}?`
            : undefined
        }
        onProceed={() => handleDeleteDocument(deleteDoc)}
        onCancel={() => setDeleteDoc(null)}
      />
      <ConfirmationModal
        open={!!revokingDocTask}
        message={formatRevokeMessage(revokingDocTask)}
        onProceed={() => handleRevokeDocTask(revokingDocTask)}
        onCancel={() => setRevokingDocTask(null)}
        proceedLabel="Yes, revoke"
      />
      <ConfirmationModal
        open={shipmentCompleteModal}
        message="Your shipment documentation is complete. You can drop-off the shipment at the nearest shipping station"
        onProceed={() => {
          setShipmentCompleteModal(false);
          // setSelectedForms((prev) => ({ ...prev, child: '' }));
          openNextPanel();
        }}
        proceedLabel="OK"
        noCancel
      />
      {documentHistory && (
        <Grid item container style={{ marginTop: 10 }}>
          <DocumentHistory
            documentId={documentHistory && documentHistory.documentId}
            open={!!documentHistory}
            subtitle={documentHistory && documentHistory.documentName}
            onClose={() => setDocumentHistory(null)}
          />
        </Grid>
      )}
      <NewOrderErrorDialog
        errorContent={error}
        onClose={() => setError(null)}
        open={!!error}
      />
    </Grid>
  );
}

ShippingDocuments.propTypes = {
  shipment: PropTypes.objectOf({
    documentation: PropTypes.objectOf({
      packages: PropTypes.arrayOf(PropTypes.any),
      documents: PropTypes.arrayOf(PropTypes.any),
    }),
    shipmentId: PropTypes.string,
  }),

  submitFormToApi: PropTypes.func.isRequired,
  setSelectedForms: PropTypes.func.isRequired,
};

ShippingDocuments.defaultProps = {
  shipment: {
    documentation: {
      packages: [],
      documents: [],
    },
    shipmentId: '',
  },
};

function isChecklistEmpty(checklist) {
  const checklistKeys = ['inside', 'outside'];
  if (Array.isArray(checklist)) {
    return !(checklist && checklist
      .find((cl) => cl && Object.keys(cl)
        .find((key) => (checklistKeys.includes(key) && cl[key] && cl[key].length > 0))));
  }
  if (typeof checklist === 'object') {
    return !(checklist && Object.keys(checklist)
      .find((key) => (checklistKeys.includes(key) && checklist[key] && checklist[key].length > 0)));
  }
  return true;
}

function getAssignedTasksFromDocument(doc) {
  return doc.task && taskHelp.isTaskAssigned(doc.task)
    ? doc.task
    : null;
}

function formatRevokeMessage(doc) {
  if (!doc) return undefined;
  let base = 'Are you sure you would like to revoke ';
  if (doc.task && doc.task.documentTaskType) {
    base += `"${doc.task.documentTaskType}" task `;
  } else {
    base += 'task ';
  }
  if (doc.task && doc.task.assignedTo && doc.task.assignedTo.fullName) {
    base += `from ${doc.task.assignedTo.fullName} `;
  }
  base += `on ${doc.documentName}?`;
  return base;
}
