import React, { memo } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { Grid } from '@material-ui/core';
import ReadOnlyContainer from '../../components/common/ReadOnlyContainer';
import { ACCOUNT_MANAGEMENT } from './constants';
import ReadOnlyTextField from '../../components/common/ReadOnlyTextField';
import Form from './Form';

const AccountAddress = ({
  formikRef, itemKey, values, countries, states, isEditing, onEdit, onSubmit,
}) => {
  const obj = ACCOUNT_MANAGEMENT[itemKey];

  return (
    <ReadOnlyContainer title={obj.title} setIsReadOnly={() => onEdit(itemKey)}>
      {isEditing ? (
        <Form
          ref={formikRef}
          itemKey={itemKey}
          initialValues={values}
          validationSchema={obj.validationSchema}
          fields={obj.fields}
          countries={countries}
          states={states}
          onEdit={onEdit}
          onSubmit={onSubmit}
        />
      ) : obj.fields.map((field) => (
        <Grid key={field.name} item xs={field?.fullWidth ? 12 : 6}>
          <ReadOnlyTextField
            type={field.type}
            iconName={field.iconName}
            name={field.name}
            label={field.label}
            value={get(values, field.name, '')}
          />
        </Grid>
      ))}
    </ReadOnlyContainer>
  );
};

AccountAddress.defaultProps = {
  values: null,
  countries: [],
  states: null,
  isEditing: false,
};

AccountAddress.propTypes = {
  formikRef: PropTypes.shape(PropTypes.object).isRequired,
  itemKey: PropTypes.string.isRequired,
  values: PropTypes.shape({
    name: PropTypes.string,
    companyName: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    addressLine1: PropTypes.string,
    addressLine2: PropTypes.string,
    country: PropTypes.string,
    state: PropTypes.string,
    city: PropTypes.string,
    zip: PropTypes.string,
  }),
  countries: PropTypes.arrayOf(PropTypes.object),
  states: PropTypes.object,
  isEditing: PropTypes.bool,
  onEdit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default memo(AccountAddress);
