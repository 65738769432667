import React from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import {
  Typography,
  TextField,
  CircularProgress,
  InputAdornment,
  Chip,
} from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import FilterIcon from '@material-ui/icons/FilterList';

import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import SearchIcon from '@material-ui/icons/Search';
import shortId from 'shortid';
import { Inbox } from '@material-ui/icons';
import moment from 'moment';
import { Page } from '../components/common';
import ErrorDialog from '../components/common/NewOrderErrorDialog';

import { inDevelopment, SHIPMENT_TRACKING_TAB } from '../clientConstants';
import * as colors from '../styles/colors';
import SalesOrderModal from '../components/SalesOrderModal';
import statusHelp from '../utils/status';
import {
  formatDateShort,
  getPurposeDisplay,
  splitCamelCase,
} from '../utils/helpers';

import { useShipmentState } from '../context/shipmentDataContext';
import {
  useBinState,
  useBinDispatch,
  loadRecentBins,
  updateBinStatus,
  closeBins,
  resetBins,
} from '../context/binDataContext';
import {
  useSingleBinDispatch,
  deleteSalesOrder,
} from '../context/singleBinContext';
import {
  useUserDispatch,
  useUserState,
  loadUser,
} from '../context/userContext';
import Navbar from '../components/Navbar';
import FilterOrderModal from '../components/FilterOrderModal';
import BinRow from '../components/BinRow';
import ConfirmationModal from '../components/common/ConfirmationModal';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: colors.background,
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  iconContainer: {
    marginRight: '5px',
    color: 'white',
  },
  listHeadingDiv: {
    marginTop: '10px',
    height: '60px',
    borderBottom: '1px solid rgba(255,255,255,.2)',
    padding: '0 0 10px 0',
    width: '100%',
  },
  shipmentsHeading: {
    fontSize: 24,
    color: colors.white,
  },
  centeredContainer: {
    alignItems: 'center',
  },
  viewAllButton: {
    fontSize: '14px',
    color: '#94CCFB',
    letterSpacing: 1.25,
  },
  pageDiv: {
    // overflow: 'scroll',
    // height: '100vh',
    // backgroundColor: '#151721',
  },
  select: {
    color: colors.secondaryButton,
    marginLeft: '15px',
    fontSize: '24px',
    border: 'none',
    letterSpacing: 1.2,
    fontWeight: 500,
  },
  filterBar: {
    padding: '5px 0',
  },
  filterIcon: {
    color: colors.white,
    fontSize: '30px',
  },
  activeFilterChip: {
    background: colors.background,
    height: 'auto',
    padding: 5,
    color: colors.white,
    border: `1px solid ${colors.darkBackgroundGrey}`,
    borderRadius: 5,
    fontSize: 15,
    margin: '5px 15px 0px 0px',
  },
  activeFilterChipText: {
    color: colors.white,
    fontSize: 15,
    // textTransform: 'capitalize',
  },
  activeFiltersWrap: {
    alignItems: 'center',
    height: '100%',
    maxWidth: 'calc(100% - 64px)',
  },
  searchFieldWrap: {
    height: 45,
    borderRadius: 5,
    background: colors.darkInputFieldBackground,
    marginLeft: '20px',
    width: 300,
    color: colors.white,
  },
  searchField: {
    width: 370,
    background: colors.darkInputFieldBackground,
    color: colors.white,
    '& label': {
      color: colors.white,
      fontWeight: 500,
    },
    overflow: 'hidden',
    margin: 0,
    height: '100%',
  },
  searchFieldRoot: {
    color: colors.white,
  },
  inputRoot: {
    background: colors.darkInputFieldBackground,
    color: colors.white,
  },
  searchFieldLabelRoot: {
    color: colors.white,
    fontSize: 16,
    fontWeight: 500,
    background: colors.darkInputFieldBackground,
  },
  noResultsIconContainer: {
    fontSize: 160,
    display: 'flex',
    background: colors.newOrderFormBackground,
    borderRadius: 130,
    height: 260,
    width: 260,
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 50,
  },
  noResultsIconRoot: {
    color: '#80808C',
  },
  noResultsText: {
    color: colors.tableGreyText,
    fontSize: 14,
  },
  noResultsTextContainer: {
    marginTop: 20,
  },
  endAdornmentRoot: {
    margin: 0,
    '&:not(.MuiInputAdornment-hiddenLabel)': {
      margin: '0px !important',
    },
  },
  filterButtonContainer: {
    justifySelf: 'flex-end',
    display: 'flex',
    alignSelf: 'center',
  },
  checkbox: {
    color: 'white',
  },
  formControlLabel: {
    color: 'white',
    marginLeft: '1.9rem',
  },
  updateButton: {
    fontWeight: 'bold',
    margin: 'auto',
    padding: '6px 16px',
    color: colors.white,
    borderRadius: '5px',
    whiteSpace: 'nowrap',
    '&:disabled': {
      color: colors.mediumBlueBackground,
    },
  },
}));

function formatFilterChipText(filterType, filterValues) {
  const label = `${splitCamelCase(filterType)}: `;
  // if filterType is 'startDate' or 'endDate'
  if (filterType === 'startDate' || filterType === 'endDate') {
    return label + formatDateShort(filterValues).toUpperCase();
  }

  // if filterValues is an array
  if (filterValues instanceof Array) {
    if (filterType === 'purpose') {
      return label + filterValues.map(getPurposeDisplay).join(', ');
    }
  } else if (['location', 'carrier', 'company', 'zip'].includes(filterType)) {
    return label + filterValues;
  }

  return label + filterValues.join(', ');
}

export function LandingPage(props) {
  const { history } = props;
  const classes = useStyles();
  return (
    <Grid container spacing={1} className={classes.pageDiv}>
      <BinDetails history={history} />
    </Grid>
  );
}

LandingPage.propTypes = {
  history: PropTypes.object.isRequired,
};

export function BinDetails(props) {
  const classes = useStyles();
  const shipmentState = useShipmentState();
  const binState = useBinState();
  const binDispatch = useBinDispatch();
  const userDispatch = useUserDispatch();
  const singleBinDispatch = useSingleBinDispatch();
  const [filterOpen, setFilterOpen] = React.useState(null);
  const [selectAll, setSelectAll] = React.useState(false);
  const { filterableStatuses } = statusHelp;
  const initialStatusFilterState = filterableStatuses.reduce(
    (obj, key) => ({ ...obj, [key]: false }),
    {},
  );
  const initialFilterState = {
    location: null,
    status: initialStatusFilterState,
    company: null,
    zip: null,
    startDate: null,
    endDate: null,
  };
  const [searchField, setSearchField] = React.useState('');
  const [salesOrderModalState, setSalesOrderModalState] = React.useState({
    anchorEl: null,
    binId: null,
    salesOrder: [],
  });
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);

  const [filterState, setFilterState] = React.useState(initialFilterState);
  const [newFilterState, setNewFilterState] = React.useState(null);

  const [fetchShipmentOptions, setFetchShipmentOptions] = React.useState({
    limit: 500,
    offset: 0,
    allLoaded: false,
  });
  const [activeFilters, setActiveFilters] = React.useState({});
  const [isCheck, setIsCheck] = React.useState([]);
  const [isCheckAll, setIsCheckAll] = React.useState(false);
  const [close, setClose] = React.useState(null);
  const firstUpdate = React.useRef(true);

  const confirmCloseOpen = close === 'confirm';

  const { accountType } = useUserState();

  const lastCardRef = React.useRef(null);

  const onWindowScroll = React.useCallback(() => {
    const windowScroll = window.scrollY + window.innerHeight;
    const elementLocation = (lastCardRef.current
        && lastCardRef.current.offsetTop
          + lastCardRef.current.clientHeight
          + 64)
      || Infinity;

    if (windowScroll >= elementLocation && !loading) {
      lastCardRef.current = null;
      loadNewShipments();
    }
  });
  React.useEffect(() => {
    inDevelopment() && console.log('calling loadShipments from workspace');

    if (!accountType) loadUser(userDispatch);

    refreshShipments({}).catch(() => setLoading(false));
  }, []);
  React.useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    submitFilters();
  }, [newFilterState]);
  const getDueDate = (dueDate) => {
    if (dueDate) {
      return moment(dueDate).format('MM/DD/YYYY');
    }
    return moment().format('MM/DD/YYYY');
  };
  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(
      binState.bins.filter((b) => !!b.salesOrders[0]?.isCloseBin).map((li) => li.binName),
    );
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  const handleSelectClick = (e) => {
    const { id, checked } = e.target;
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
  };
  const handleCloseClick = (binName) => {
    const payload = {};
    payload.binNames = [binName];
    payload.status = 'ready';
    return closeBins(binDispatch, payload)
      .then((data) => {
        const filters = { ...activeFilters };
        delete filters.filter;
        refreshShipments({ filters, offset: 0 });
      })
      .catch((e) => {
        setError(JSON.stringify({ error: `Error closing bin status. ${e}` }));
      });
  };
  const handleResetClick = (binName) => {
    const payload = {};
    payload.binNames = [binName];
    payload.status = 'open';
    return resetBins(binDispatch, payload)
      .then((data) => {
        const filters = { ...activeFilters };
        delete filters.filter;
        refreshShipments({ filters, offset: 0 });
      })
      .catch((e) => {
        setError(JSON.stringify({ error: `Error closing bin status. ${e}` }));
      });
  };
  const submitFilters = () => {
    console.log(filterState);
    const filters = Object.keys(filterState).reduce((obj, cat) => {
      if (!filterState[cat]) return obj;
      if (typeof filterState[cat] === 'string') {
        return { ...obj, [cat]: filterState[cat] };
      }
      const catFilters = Object.keys(filterState[cat]).filter(
        (f) => filterState[cat][f],
      );
      if (catFilters.length) return { ...obj, [cat]: catFilters };
      return obj;
    }, {});
    setActiveFilters(filters);
    setFetchShipmentOptions({ offset: 0, limit: 500 });
    refreshShipments({ filters, offset: 0 });
  };

  const handleChipDelete = (filterType, filterValues) => {
    const filter = { ...filterState };
    delete filter[filterType];
    const nfs = { ...initialFilterState, ...filter };
    setFilterState(nfs);
    setNewFilterState(nfs);
    submitFilters();
  };
  const loadNewShipments = () => refreshShipments({});

  const refreshShipments = ({ offset, limit, filters }) => {
    const requestFilters = filters || { ...activeFilters };
    const requestOffset = offset !== undefined ? offset : fetchShipmentOptions.offset;
    const requestLimit = limit || fetchShipmentOptions.limit;
    setLoading(true);
    return loadRecentBins(
      binDispatch,
      requestFilters,
      requestOffset,
      requestLimit,
    ).then((data) => {
      const allLoaded = data.length < requestLimit;
      if (allLoaded) lastCardRef.current = null;
      setFetchShipmentOptions((prev) => ({
        ...prev,
        offset: requestLimit + requestOffset,
        allLoaded,
      }));
      setLoading(false);
      if (filterOpen) setFilterOpen(null);
    });
  };

  const openSalesOrderModal = ({ binId, anchorEl, salesOrders }) => {
    setSalesOrderModalState({
      binId,
      anchorEl,
      salesOrders,
    });
  };

  const updateSearchFilter = (search) => {
    setSearchField(search);
    if (search.length > 2) {
      setFetchShipmentOptions({ offset: 0, limit: 500 });
      setActiveFilters((prev) => ({ ...prev, filter: search }));
      refreshShipments({
        filters: {
          ...activeFilters,
          filter: search,
        },
        offset: 0,
        limit: 500,
      });
    } else if (activeFilters.filter) {
      setActiveFilters((prev) => {
        const newFilters = { ...prev };
        delete newFilters.filter;
        return newFilters;
      });
      setFetchShipmentOptions({ offset: 0, limit: 500 });
      const filters = { ...activeFilters };
      delete filters.filter;
      refreshShipments({ filters, offset: 0 });
    }
  };

  React.useEffect(() => {
    if (!loading) {
      onWindowScroll();
    }
  }, [loading]);

  // React.useEffect(() => {
  //   window.addEventListener('scroll', onWindowScroll);
  //   return () => {
  //     window.removeEventListener('scroll', onWindowScroll);
  //   };
  // });
  const handleSalesOrderClose = () => {
    setSalesOrderModalState({
      binId: null,
      anchorEl: null,
      salesOrders: [],
    });
  };
  const handleUpdateClick = () => {
    const payload = {};
    payload.binNames = isCheck;
    payload.status = 'ready';
    return closeBins(binDispatch, payload)
      .then((data) => {
        const filters = { ...activeFilters };
        delete filters.filter;
        refreshShipments({ filters, offset: 0 });
        if (isCheckAll) {
          setIsCheck([]);
        }
      })
      .catch((e) => {
        setError(JSON.stringify({ error: `Error updating bin status. ${e}` }));
      });
  };
  const handleCloseButtonClick = () => {
    handleUpdateClick();
    setClose(null);
  };
  const handleSalesOrderDeleteClick = (row) => {
    deleteSalesOrder({
      dispatch: singleBinDispatch,
      salesOrderId: row.salesOrderId,
    })
      .then((data) => {
        console.log(`response: ${JSON.stringify(data)}`);
        handleSalesOrderClose();
        refreshShipments({ filters: activeFilters, offset: 0 });
        if (data?.error) {
          setError(
            JSON.stringify({
              error: `Error removing sales Order. ${JSON.parse(data).error}`,
            }),
          );
        }
      })
      .catch((e) => {
        setError(JSON.stringify({ error: `Error removing sales Order. ${e}` }));
      });
  };
  return (
    <Grid container direction="column">
      <SalesOrderModal
        handleClose={handleSalesOrderClose}
        id={`tracking-${salesOrderModalState.binId || ''}`}
        salesOrderModalState={salesOrderModalState}
        onDeleteClick={handleSalesOrderDeleteClick}
      />
      <div className={classes.listHeadingDiv}>
        <Grid container justify="space-between" alignItems="center">
          <Grid item className={classes.centeredContainer}>
            <Grid container alignItems="center">
              <div className={classes.iconContainer}>
                <Inbox />
              </div>
              <Grid item>
                <Grid container>
                  <Typography className={classes.shipmentsHeading}>
                    Orders
                  </Typography>
                </Grid>
              </Grid>
              <Grid item className={classes.searchFieldWrap}>
                <TextField
                  id="filled-basic"
                  className={classes.searchField}
                  classes={{ root: classes.searchFieldRoot }}
                  label="Search Orders"
                  margin="normal"
                  onChange={(e) => updateSearchFilter(e.target.value)}
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      root: classes.inputRoot,
                      focused: classes.inputRoot,
                    },
                    endAdornment: (
                      <InputAdornment
                        position="start"
                        classes={{
                          root: classes.endAdornmentRoot,
                          filled: classes.endAdornmentRoot,
                          positionStart: classes.endAdornmentRoot,
                        }}
                      >
                        <SearchIcon color="primary" />
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{ root: classes.searchFieldLabelRoot }}
                  variant="filled"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Button
              className={classes.updateButton}
              color="secondary"
              variant="contained"
              onClick={() => setClose('confirm')}
              style={{ letterSpacing: 1.25 }}
              disabled={!isCheck.length}
            >
              Update Status
            </Button>
          </Grid>
        </Grid>
      </div>
      <Grid
        item
        container
        className={classes.filterBar}
        justify="space-between"
      >
        <FilterOrderModal
          {...{ accountType }}
          anchorEl={filterOpen}
          handleClose={() => setFilterOpen(null)}
          filterState={filterState}
          setFilterState={setFilterState}
          submitFilters={submitFilters}
        />
        <Grid item container className={classes.activeFiltersWrap}>
          {Object.entries(activeFilters).map(
            ([filterType, filterValues]) => filterType !== 'filter' && (
            <Grid
              item
              className={classes.activeFilterChip}
              key={`active-filter-ship-grid-${filterType}`}
            >
              <Chip
                key={`active-filter-ship-typography-${filterType}`}
                variant="outlined"
                className={classes.activeFilterChipText}
                color="primary"
                label={formatFilterChipText(filterType, filterValues)}
                onDelete={() => handleChipDelete(filterType, filterValues)}
              />
            </Grid>
            ),
          )}
        </Grid>
      </Grid>
      <Grid container justify="space-between">
        <Grid item>
          <FormControlLabel
            // eslint-disable-next-line max-len
            control={(
              <Checkbox
                checked={isCheckAll}
                color="secondary"
                name="selectall"
                onChange={handleSelectAll}
                className={classes.checkbox}
              />
            )}
            className={classes.formControlLabel}
            label="Select All"
          />
        </Grid>
        <Grid item className={classes.filterButtonContainer}>
          <Button
            onClick={(e) => setFilterOpen(e.currentTarget)}
            data-testid="filter-shipments-button"
          >
            <FilterIcon className={classes.filterIcon} />
          </Button>
        </Grid>
      </Grid>
      <Grid item container direction="column" alignItems="center">
        {binState.bins && binState.bins.length
          ? binState.bins.map((bin, idx, sArray) => (
            <BinRow
              key={`shipment-tile-${bin.binId}-${shortId.generate()}`}
              bin={bin}
              classes={classes}
              openSalesOrderModal={openSalesOrderModal}
              isCheck={isCheck}
              handleSelectClick={handleSelectClick}
              handleCloseClick={handleCloseClick}
              handleResetClick={handleResetClick}
              lastCardRef={
                  idx === sArray.length - 1 && !fetchShipmentOptions.allLoaded
                    ? lastCardRef
                    : undefined
                }
            />
          ))
          : !loading && (
          <Grid
            item
            container
            direction="column"
            alignItems="center"
            className={classes.noResults}
          >
            <Grid item className={classes.noResultsIconContainer}>
              <LocalShippingIcon
                fontSize="inherit"
                classes={{ root: classes.noResultsIconRoot }}
              />
            </Grid>
            <Grid item className={classes.noResultsTextContainer}>
              <Typography className={classes.noResultsText}>
                No Orders found!
              </Typography>
            </Grid>
          </Grid>
          )}
        {loading && <CircularProgress />}
      </Grid>
      <ConfirmationModal
        open={confirmCloseOpen}
        onProceed={() => handleCloseButtonClick()}
        onCancel={() => setClose(null)}
        proceedLabel="Yes, Update"
        message={(
          <span>
            {`Are you sure you would like to update status to close for selected bins ${isCheck.join(
              ',',
            )} ?`}
          </span>
        )}
      />
      <ErrorDialog
        open={!!error}
        onClose={() => setError(null)}
        errorContent={error}
      />
    </Grid>
  );
}

export default function ProtectedPage(props) {
  const classes = useStyles();
  const { account } = useUserState();

  const { history } = props;

  return (
    <div className={classes.root}>
      <Navbar />
      <Page account={account}>
        <LandingPage history={history} />
      </Page>
    </div>
  );
}
