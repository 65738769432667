/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import LodashOrderBy from 'lodash/orderBy';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import OutlinedTruckIcon from '@material-ui/icons/LocalShippingOutlined';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import CrossCircleOutline from '@material-ui/icons/ErrorOutlineOutlined';

import { CircularProgress } from '@material-ui/core';
import * as colors from '../styles/colors';
import { SHIPMENT_TRACKING_TAB } from '../clientConstants';

function setDefaultRowsPerPage(numberOfRows) {
 // if (numberOfRows < 25) return numberOfRows;
  return 5;
}

const StyledTableCell = withStyles({
  root: {
    color: (props) => props.color ?? colors.white,
    fontSize: 16,
    letterSpacing: 0.5,
    border: 'none',
  },
})(TableCell);

const StyledTablePagination = withStyles({
  root: {
    color: colors.white,
    backgroundColor: colors.darkBlueBackground,
  },
  selectIcon: {
    color: colors.white,
    backgroundColor: colors.darkBlueBackground,
  },
})(TablePagination);

function desc(a, b, orderBy) {
  if (!a[orderBy]) return -1;
  if (!b[orderBy]) return 1;
  if ((Number(b[orderBy]) || b[orderBy]) < (Number(a[orderBy]) || a[orderBy])) {
    return -1;
  }
  if ((Number(b[orderBy]) || b[orderBy]) > (Number(a[orderBy]) || a[orderBy])) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc'
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

const headCellsRates = [
  {
    id: 'address', numeric: false, disablePadding: false, label: 'Address',
  },
  {
    id: 'message',
    numeric: false,
    disablePadding: false,
    label: 'Message',
  },
  {
    id: 'isSuccess',
    numeric: false,
    disablePadding: false,
    label: 'Status',
  },
];

const headCellsConfirm = [
  {
    id: 'address',
    numeric: false,
    disablePadding: false,
    label: 'Address',
    cellStyle: { width: '25%', flexGrow: '0', minHeight: '50px' },
  },
  {
    id: 'isSuccess',
    numeric: false,
    disablePadding: false,
    label: 'Status',
    cellStyle: { width: '15%', flexGrow: '0', minHeight: '50px' },
  },
  {
    id: 'trackingNumbers',
    numeric: false,
    disablePadding: false,
    label: 'Tracking',
    cellStyle: { width: '20%', flexGrow: '0', minHeight: '50px' },
  },
  {
    id: 'price',
    numeric: true,
    disablePadding: false,
    label: 'Price',
    cellStyle: { width: '5%', flexGrow: '0', minHeight: '50px' },
  },
];
const isDateHeader = (headerId) => headerId === 'expectedDeliveryDateTime';

function AddressRatesTableHead(props) {
  const {
    classes, order, orderBy, onRequestSort, confirmed,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const headCells = confirmed ? headCellsConfirm : headCellsRates;

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align={
              // eslint-disable-next-line no-nested-ternary
              isDateHeader(headCell.id)
                ? 'left'
                : headCell.numeric
                  ? 'right'
                  : 'left'
            }
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
            style={headCell.cellStyle}
          >
            <TableSortLabel
              className={classes.columnTitle}
              active={orderBy === headCell.id}
              direction={order}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

AddressRatesTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  confirmed: PropTypes.bool,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(2),
    overflowY: 'auto',
    maxHeight:'60vh'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    backgroundColor: colors.darkBlueBackground,
    color: colors.white,
    flexDirection: 'column',
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  listPriceCell: {
    textDecoration: 'line-through',
  },
  yourPriceCell: {
    fontWeight: 900,
    color: colors.textGreen,
  },
  carrierImage: {
    height: 'auto',
    width: 'auto',
    maxHeight: '25px',
    maxWidth: '100px',
    margin: '2px 0',
  },
  progress: {
    width: '100%',
  },
  progressWrap: {
    display: 'flex',
    width: '100%',
    flexGrow: '1',
    justifyContent: 'center',
    alignItems: 'flex-start',
    backgroundColor: colors.darkBlueBackground,
    padding: '80px 0',
    minHeight: '250px',
  },
  bareCell: {
    border: 'none',
    width: '100%',
  },
  trackShipment: {
    color: colors.secondaryButton,
    fontSize: '14px',
    fontWeight: '500',
    textTransform: 'none',
  },
  trackingButton: {
    padding: '0px 0px 0px 0px',
    color: '#94CCFB',
  },
  addressBlock: {
    color: colors.textLightGrey,
    minHeight: 23,
    fontSize: 14,
  },
  messageBlock: {
    color: colors.badgeBubbleRed,
    minHeight: 23,
    fontSize: 14,
  },
  tooltip: {
    background: 'transparent',
    fontSize: 12,
  },
}));

export default function AddressRatesTable(props) {
  const classes = useStyles();
  const {
    rows,
    currentRate,
    changeSelectedRate,
    disabled,
    ratesLoading,
    confirmed,
    openTrackingModal,
    shipmentId,
  } = props;

  const StyledRow = {
    tableRowDark: withStyles({
      root: {
        backgroundColor: 'rgba(0,0,0,.24)',
        '&:hover': {
          backgroundColor: 'rgba(0,0,0,.35)',
        },
      },
      selected: {
        backgroundColor: 'rgba(0,0,0,.35) !important',
      },
    })(TableRow),
    tableRowLight: withStyles({
      root: {
        backgroundColor: 'rgba(0,0,0,.12)',
        '&:hover': {
          backgroundColor: 'rgba(0,0,0,.35)',
        },
      },
      selected: {
        backgroundColor: 'rgba(0,0,0,.35) !important',
      },
    })(TableRow),
  };
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('carrier');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(setDefaultRowsPerPage(rows?.length));
  const [orderedRows, setOrderedRows] = React.useState(rows);

  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }

  // If the rows change, set the number of rows to display the max amount
  React.useEffect(() => {
    setOrderedRows(LodashOrderBy(rows, ['isSuccess'], ['asc']));
    setRowsPerPage(setDefaultRowsPerPage(rows?.length));
  }, [rows]);

  
  // If the rate in context, or the rows change, set selected to the matching row

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <div className={classes.tableWrapper}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="small"
          >
            <AddressRatesTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              confirmed={confirmed}
            />
            {ratesLoading ? (
              null
            ) : (
              <TableBody>
                {stableSort(orderedRows, getSorting(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const Row = StyledRow[index % 2 ? 'tableRowLight' : 'tableRowDark'];

                    return (
                      <Row
                        tabIndex={-1}
                        key={index}
                        classes={{
                          root: classes[
                            index % 2 ? 'tableRowLight' : 'tableRowDark'
                          ],
                        }}
                        data-testid='carrier-rate'>
                        <StyledTableCell align='left'>
                          <Grid item>
                            <Typography className={classes.addressBlock}>
                              <b
                                style={{
                                  color: 'white',
                                }}>
                                {row.address.name}
                              </b>
                            </Typography>
                            <Typography className={classes.addressBlock}>
                              {` ${row.address.address.addressLine1}${
                                row.address.address.addressLine2
                                  ? `, ${row.address.address.addressLine2}`
                                  : ''
                              }${
                                row.address.address.addressLine3
                                  ? `, ${row.address.address.addressLine3}`
                                  : ''
                              } `}
                            </Typography>
                            <Typography className={classes.addressBlock}>
                              {`${row.address.address.city}, ${row.address.address.stateProvinceCode} ${row.address.address.postalCode} ${row.address.address.countryCode} `}
                            </Typography>
                          </Grid>
                        </StyledTableCell>
                        {!confirmed && (
                          <StyledTableCell align='left'>
                            <Typography className={classes.messageBlock}>
                              {row.message}
                            </Typography>
                          </StyledTableCell>
                        )}
                        <StyledTableCell
                          color={
                            row.isSuccess ? colors.white : colors.failedRed
                          }
                          align='left'>
                          {row.isSuccess ? (
                            <CheckCircleOutline
                              style={{ color: colors.checkValidGreen }}
                            />
                          ) : (
                            <Tooltip
                              placement='right'
                              title={
                                <div
                                  style={{
                                    background: 'rgba(100,100,100,0.8)',
                                    padding: '2px',
                                    borderRadius: '3px',
                                  }}>
                                    <Typography
                                      style={{
                                        fontSize: '12px',
                                        paddingBottom: '2px',
                                      }}
                                     >
                                      {row.message}
                                    </Typography>
                                </div>
                              }
                              classes={{ tooltip: classes.tooltip }}>
                              <CrossCircleOutline
                                style={{ color: colors.failedRed }}
                              />
                            </Tooltip>
                          )}
                        </StyledTableCell>
                        {confirmed && (
                          <StyledTableCell align='left'>
                            {row.isSuccess && (
                              <Grid container item spacing={1}>
                                <Grid item>
                                  <Typography className={classes.trackShipment}>
                                    Track shipments
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <IconButton
                                    onClick={(e) => {
                                      openTrackingModal({
                                        shipmentId,
                                        trackingNumber: row.trackingNumbers[0],
                                        anchorEl: e.currentTarget,
                                        iotTrackingNumber: false,
                                        openTab: SHIPMENT_TRACKING_TAB,
                                      });
                                    }}
                                    classes={{ root: classes.trackingButton }}>
                                    <OutlinedTruckIcon
                                      style={{ fontSize: '1rem' }}
                                    />
                                  </IconButton>
                                </Grid>
                              </Grid>
                            )}
                          </StyledTableCell>
                        )}
                        {confirmed && (
                          <StyledTableCell align='right'>
                            {row.isSuccess && (
                              <Tooltip
                                placement='right'
                                // eslint-disable-next-line react/no-array-index-key
                                title={
                                  <div
                                    style={{
                                      background: 'rgba(100,100,100,0.8)',
                                      padding: '2px',
                                      borderRadius: '3px',
                                    }}>
                                    {row?.itemizedCharges?.map((m, i) => (
                                      <Typography
                                        style={{
                                          fontSize: '12px',
                                          paddingBottom: '2px',
                                        }}
                                        key={i}>
                                        {`${m.description}: ${
                                          m.currencyCode === 'USD' ? '$' : ''
                                        }${m.price}`}
                                      </Typography>
                                    ))}
                                  </div>
                                }
                                classes={{ tooltip: classes.tooltip }}>
                                <Typography>{`$${row.price}`}</Typography>
                              </Tooltip>
                            )}
                          </StyledTableCell>
                        )}
                      </Row>
                    );
                  })}
                {emptyRows > 0 && (
                <TableRow style={{ height: 33.0167 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
                )}
              </TableBody>
            )}
          </Table>
          {ratesLoading && (
            <Grid container className={classes.progressWrap}>
              <CircularProgress className={classes.progress} color="secondary" />
            </Grid>
          )}
        </div>
        <StyledTablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'previous page',
          }}
          nextIconButtonProps={{
            'aria-label': 'next page',
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          SelectProps={{ SelectDisplayProps: { 'data-testid': 'carrier-rates-pagination' } }}
        />
      </Paper>
    </div>
  );
}

AddressRatesTable.propTypes = {
  rows: PropTypes.array.isRequired,
  disabled: PropTypes.bool.isRequired,
};
