import React, {
  memo, useEffect, useRef,
} from 'react';
import PropTypes from 'prop-types';
import has from 'lodash/has';
import { Form, Formik } from 'formik';
import {
  Button,
  Typography,
  Grid,
  CircularProgress,
} from '@material-ui/core';
import NewOrderNextButton from '../../common/NewOrderNextButton';
import { useMiscState } from '../../../context/miscDataContext';
import NewOrderAddressBlock from './Block';
import Dialog from './Dialog';

const DEFAULT_FORM_VALUES = {
  selectedContacts: [],
};

function NewOrderMultipleAddress({
  classes,
  open,
  selectedTab,
  shippingType,
  selectedAddress,
  origin,
  contact,
  setOpen,
  setSelectedAddress,
  toggleOpen,
  handleSubmit,
}) {
  const miscState = useMiscState();
  const formikRefMulti = useRef();

  useEffect(() => {
    if (formikRefMulti.current === undefined) return;

    const { resetForm } = formikRefMulti.current;

    function handleRecipient() {
      if (!has(contact, 'addresses')) {
        resetForm(DEFAULT_FORM_VALUES);
        return;
      }

      loadContact();
    }

    function loadContact() {
      resetForm({
        selectedContacts: contact?.addresses,
      });
      setSelectedAddress(contact?.addresses || []);
    }

    handleRecipient();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contact]);

  useEffect(() => {
    if (formikRefMulti.current === undefined) return;
    const { setFieldValue } = formikRefMulti.current;
    setFieldValue('selectedContacts', selectedAddress);
  }, [selectedAddress]);
  const validateOnBlur = false;

  function getInitialValues() {
    return DEFAULT_FORM_VALUES;
  }

  async function onSubmit(values, { setSubmitting }) {
    const { selectedContacts } = values;
    if (selectedContacts.length === 0) {
      setSubmitting(false);
      return;
    }
    await handleSubmit(selectedContacts);
    setSubmitting(false);
  }

  function removeAll() {
    setSelectedAddress([]);
  }

  function removeAddress(addressId) {
    const newAddress = selectedAddress.filter((a) => a.addressId !== addressId);
    setSelectedAddress(newAddress);
  }

  const render = (renderProps) => {
    const { isSubmitting, submitForm } = renderProps;

    return (
      <Form className={classes.contentOuterContainer} name="contacts-form">
        <Button color="secondary" onClick={toggleOpen} style={{ padding: '5px', margin: '5px' }}>
          Add/Edit Addresses
        </Button>
        <Grid
          style={{ marginLeft: '10px', marginRight: '10px' }}
          justify="space-between"
          direction="row"
          container
        >
          <Grid item>
            <Typography className={classes.typography}>
              {`Total Addresses selected: ${selectedAddress.length}`}
            </Typography>
          </Grid>
          <Grid item>
            <Button
              color="secondary"
              onClick={() => { removeAll(); }}
              disabled={selectedAddress.length === 0}
            >
              Remove all
            </Button>
          </Grid>
        </Grid>
        <Grid className={classes.container}>
          {selectedAddress.length > 0 && selectedAddress.map(
            ({
              addressId,
              name,
              email,
              phone,
              addressLine1,
              addressLine2,
              addressLine3,
              city,
              state,
              zip,
              country,
            }) => (
              <NewOrderAddressBlock
                key={addressId}
                addressId={addressId}
                classes={classes}
                countries={miscState.countries}
                name={name}
                email={email}
                phone={phone}
                addressLine1={addressLine1}
                addressLine2={addressLine2}
                addressLine3={addressLine3}
                city={city}
                state={state}
                zip={zip}
                country={country}
                removeAddress={removeAddress}
              />
            ),
          )}
          {selectedAddress.length === 0 && (
          <Typography
            variant="title"
            style={{
              fontStyle: 'italic',
              fontSize: '18px',
              padding: '15px',
            }}
          >
            No Address Selected
          </Typography>
          )}
        </Grid>
        <Grid item classes={{ root: classes.buttonContainer }}>
          <NewOrderNextButton
            disabled={isSubmitting}
            onClick={submitForm}
          >
            Next
          </NewOrderNextButton>
        </Grid>
        {isSubmitting ? (
          <Grid
            item
            classes={{
              root: classes.miscLoadingContainer,
            }}
          >
            <CircularProgress color="secondary" />
          </Grid>
        ) : null}
      </Form>
    );
  };

  return (
    <>
      <Formik
        ref={formikRefMulti}
        validateOnBlur={validateOnBlur}
        initialValues={getInitialValues()}
        onSubmit={onSubmit}
        render={render}
      />
      <Dialog
        classes={classes}
        open={selectedTab === 'three' && open}
        shippingType={shippingType}
        selectedAddress={selectedAddress}
        setOpen={setOpen}
        setSelectedAddress={setSelectedAddress}
        toggleOpen={toggleOpen}
      />
    </>
  );
}

NewOrderMultipleAddress.defaultProps = {
  selectedAddress: [],
  origin: undefined,
  contact: undefined,
};

NewOrderMultipleAddress.propTypes = {
  classes: PropTypes.shape(PropTypes.object).isRequired,
  open: PropTypes.bool.isRequired,
  selectedTab: PropTypes.string.isRequired,
  shippingType: PropTypes.string.isRequired,
  selectedAddress: PropTypes.arrayOf(PropTypes.object),
  origin: PropTypes.shape({
    address: PropTypes.shape(PropTypes.object),
    updateContacts: PropTypes.bool,
  }),
  contact: PropTypes.shape({
    addresses: PropTypes.arrayOf(PropTypes.object),
  }),
  setOpen: PropTypes.func.isRequired,
  setSelectedAddress: PropTypes.func.isRequired,
  toggleOpen: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default memo(NewOrderMultipleAddress);
