import React from 'react';
import PropTypes from 'prop-types';
import {
  CircularProgress, Dialog, DialogActions, Grid,
} from '@material-ui/core';
import NewOrderNextButton from './NewOrderNextButton';

export default function NewOrderConfirmDialog(props) {
  const {
    open, dialogContent, cancelText, disabled = false, classes,
  } = props;
  const { onClose, onConfirm } = props;

  const { dialogRoot, dialogActionsContainer, dialogLoadingContainer } = classes;

  const dialogActions = (
    <DialogActions classes={{ root: dialogActionsContainer }}>
      <Grid container direction="column">
        <Grid
          item
          container
          spacing={4}
          justify="center"
        >
          <Grid item>
            <NewOrderNextButton
              onClick={onClose}
              disabled={disabled}
            >
            No
            </NewOrderNextButton>
          </Grid>
          <Grid item>
            <NewOrderNextButton
              disabled={disabled}
              onClick={onConfirm}
              autoFocus
            >
              {cancelText}
            </NewOrderNextButton>
          </Grid>
        </Grid>
        {disabled && (
        <Grid
          item
          justify="center"
          classes={{ root: dialogLoadingContainer }}
        >
          <CircularProgress color="secondary" />
        </Grid>
        )}
      </Grid>
    </DialogActions>
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{ paper: dialogRoot }}
    >
      {dialogContent}
      {dialogActions}
    </Dialog>
  );
}

NewOrderConfirmDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  dialogContent: PropTypes.objectOf(PropTypes.any).isRequired,
  cancelText: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    dialogRoot: PropTypes.string.isRequired,
    dialogActionsContainer: PropTypes.string.isRequired,
    dialogLoadingContainer: PropTypes.string.isRequired,
  }).isRequired,
};

NewOrderConfirmDialog.defaultProps = {
  disabled: false,
};
