import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import { has } from 'lodash';
import client from './apiClient';
import {
  DUMMY_FETCH_ACCOUNT_DATA,
  DUMMY_FETCH_ACCOUNT_TRAININGS,
  DUMMY_FETCH_ALL_CARRIER,
  DUMMY_FETCH_ALL_WAREHOUSE,
  DUMMY_FETCH_PAYMENT_OPTIONS,
  DUMMY_FETCH_EHS_GROUP_TYPES,
  DUMMY_FETCH_ECO_GROUP_TYPES,
} from '../dummyData';
import { ECO, EHS } from '../clientConstants';
import {
  AVAILABLE_CARRIERS_FIELD_NAME, CARRIERS_KEY, COST_ALLOCATION_KEY, PAYMENT_KEY,
} from '../pages/AccountManagement/constants';

function formatPricingList(pricingListObj) {
  const arr = [];
  const pickValues = ['fee', 'currency'];

  if (isEmpty(pricingListObj)) return arr;

  for (const [key, value] of Object.entries(pricingListObj)) {
    arr.push({ shippingType: key, ...pick(value, pickValues) });

    if (has(value, 'hazmat')) {
      arr.push({ shippingType: key, freightType: 'hazmat', ...pick(value.hazmat, pickValues) });
    }
  }

  return arr;
}

function updateAccountFn(key, updatedAccountData) {
  return client(`/data/account/profile/${key}`, {
    method: 'put',
    data: updatedAccountData,
  });
}

function updateAccountPricingFn(updatedAccountData) {
  return client('/data/account/pricing', {
    method: 'put',
    data: updatedAccountData,
  });
}

function updateAccountPayment(accountPayment) {
  const payment = get(accountPayment, PAYMENT_KEY, null);
  const pricingList = formatPricingList(get(accountPayment, 'pricingList', null));
  const promises = [];

  if (!isEmpty(payment)) promises.push(updateAccountFn(PAYMENT_KEY, { [PAYMENT_KEY]: payment }));
  if (!isEmpty(pricingList)) promises.push(updateAccountPricingFn(pricingList));

  return Promise.all(promises);
}

export function loadWarehouseDropdown() {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return Promise.resolve(DUMMY_FETCH_ALL_WAREHOUSE);
  }
  return client('/data/account/warehouse', { method: 'get' });
}

export function loadCarrierDropdown() {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return Promise.resolve(DUMMY_FETCH_ALL_CARRIER);
  }
  return client('/data/account/carrier', { method: 'get' });
}

export function loadTrainings() {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return Promise.resolve(DUMMY_FETCH_ACCOUNT_TRAININGS);
  }
  return client('/data/account/trainings', { method: 'get' });
}

export function loadPaymentOptions() {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return Promise.resolve(DUMMY_FETCH_PAYMENT_OPTIONS);
  }

  // return client('/data/account/trainings', { method: 'get' });
  return Promise.resolve(DUMMY_FETCH_PAYMENT_OPTIONS);
}

export function loadGroupTypes(userType) {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    if (userType === ECO) return Promise.resolve(DUMMY_FETCH_ECO_GROUP_TYPES);
    if (userType === EHS) return Promise.resolve(DUMMY_FETCH_EHS_GROUP_TYPES);

    return Promise.resolve([]);
  }

  return client('/data/account/groupType', {
    method: 'get',
    params: {
      userType,
    },
  });
}

export function loadAccount() {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return Promise.resolve(DUMMY_FETCH_ACCOUNT_DATA);
  }
  return client('/data/account', { method: 'get' });
}

export function updateAccount(key, updatedAccountData) {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return Promise.resolve(updatedAccountData);
  }

  if (key === PAYMENT_KEY) return updateAccountPayment(updatedAccountData);

  return updateAccountFn(
    key === COST_ALLOCATION_KEY ? 'costallocation' : key,
    key === CARRIERS_KEY ? pick(updatedAccountData[key], AVAILABLE_CARRIERS_FIELD_NAME) : updatedAccountData,
  );
}
export function updateAccountIpdCarrier(carrierName) {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return Promise.resolve({});
  }
  return client('/data/carrier/ipd', { method: 'post', data: { carrierName } });
}
