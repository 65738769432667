import React from 'react';
import {
  SvgIcon,
} from '@material-ui/core';

const Class6Icon = (props) => (
  <SvgIcon {...props}>
    <path
      d="M 11,0 C 10.681982,0 10.365035,0.12120962 10.12197,0.36352202 L 0.36299044,10.122407 c -0.48398725,0.484732 -0.48398725,1.270454 0,1.755186 L 10.12197,21.636478 C 10.365035,21.87879 10.681982,22 11,22 c 0.318018,0 0.634965,-0.12121 0.87803,-0.363522 l 9.75898,-9.758885 c 0.483987,-0.484732 0.483987,-1.270454 0,-1.755186 L 11.87803,0.36352202 C 11.634965,0.12120962 11.318018,0 11,0 Z M 11,1.2878 20.711866,11 11,20.712307 1.2870632,11 11,1.2878"

    />
    <path
      d="m 7.1282449,10.340685 c 0.9773004,-0.5982297 2.2173958,-0.279763 2.8379714,0.702372 l 0.3092317,-0.189044 c -0.01583,-0.05861 -0.02533,-0.120473 -0.02533,-0.18457 0,-0.308735 0.201581,-0.566909 0.472819,-0.636486 V 9.6544213 C 9.6094893,9.5751113 8.730341,8.5965579 8.730341,7.4003225 c 0,-1.0741954 0.7092297,-1.9726588 1.660145,-2.2024195 V 5.088168 C 8.9424771,5.3314637 7.8353616,6.6601421 7.8353616,8.2631022 c 0,0.1982157 0.01688,0.3921827 0.0496,0.5805557 C 7.7150426,8.9079779 7.5482886,8.9890756 7.385757,9.0881846 6.0760055,9.8896643 5.5440838,11.570032 6.0686175,13.020639 l 0.08971,-0.05492 c -0.2870686,-0.987504 0.09182,-2.087988 0.9699134,-2.62503"

    />
    <path
      d="m 14.351407,9.0881846 c -0.161477,-0.09911 -0.329285,-0.1802077 -0.499205,-0.2445267 0.03272,-0.188373 0.0496,-0.38234 0.0496,-0.5805557 0,-1.6029601 -1.10606,-2.9316385 -2.55407,-3.1749342 v 0.109735 c 0.950916,0.2297607 1.660145,1.1282241 1.660145,2.2024195 0,1.1962354 -0.880204,2.1747898 -1.993652,2.2540988 v 0.3785357 c 0.271238,0.06958 0.47282,0.327751 0.47282,0.636486 0,0.0641 -0.0084,0.125955 -0.02533,0.18457 l 0.309232,0.189044 c 0.621632,-0.982135 1.860672,-1.3006017 2.839029,-0.702372 0.877038,0.537042 1.256983,1.637526 0.968858,2.62503 l 0.08971,0.05492 c 0.52559,-1.450607 -0.0074,-3.1309747 -1.31714,-3.9324544"

    />
    <path
      d="m 12.470682,14.267324 c -0.977301,-0.598118 -1.337193,-1.895029 -0.845376,-2.956585 l -0.309232,-0.189156 c -0.112928,0.12506 -0.271238,0.203475 -0.44749,0.203475 -0.176252,0 -0.334562,-0.07841 -0.446435,-0.203475 l -0.309232,0.189156 c 0.491816,1.061556 0.131925,2.358467 -0.8453763,2.956585 -0.8780924,0.537042 -1.9672658,0.335022 -2.6300575,-0.422721 l -0.08971,0.05492 c 0.9224204,1.207198 2.5614565,1.558887 3.8712088,0.757519 0.162532,-0.09922 0.312399,-0.212312 0.449601,-0.336365 0.138257,0.124053 0.288124,0.237144 0.4496,0.336365 1.309753,0.801368 2.948791,0.449679 3.871211,-0.757519 l -0.08865,-0.05492 c -0.663847,0.757743 -1.753021,0.959763 -2.630059,0.422721"

    />
    <path
      d="m 8.1509273,10.343034 c -0.01161,0.107275 -0.01794,0.216003 -0.01794,0.326409 0,1.189637 0.6765117,2.21137 1.643258,2.658476 0.107651,-0.188373 0.184695,-0.394196 0.2279667,-0.608521 -0.7387803,-0.350682 -1.253815,-1.13639 -1.253815,-2.049955 0,-0.07808 0.0032,-0.15515 0.01055,-0.231103 C 8.564642,10.371 8.3577833,10.33867 8.1509253,10.34303"

    />
    <path
      d="m 11.734012,12.719398 c 0.04327,0.214325 0.120316,0.420148 0.226912,0.608521 0.967802,-0.447106 1.643259,-1.468839 1.643259,-2.658476 0,-0.110406 -0.0063,-0.219134 -0.01689,-0.326409 -0.207914,-0.0044 -0.413718,0.02796 -0.611078,0.09531 0.0074,0.07595 0.01161,0.153025 0.01161,0.231103 0,0.913565 -0.515036,1.699161 -1.253816,2.049955"

    />
    <path
      d="m 9.6263773,8.8507049 c 0.349337,-0.268353 0.7778297,-0.427083 1.2422067,-0.427083 0.464376,0 0.893924,0.15873 1.242207,0.427083 0.154088,-0.146873 0.283902,-0.320481 0.384166,-0.5131037 -0.454878,-0.356611 -1.017407,-0.5678037 -1.626373,-0.5678037 -0.608967,0 -1.1714947,0.2111927 -1.6253173,0.5676917 0.100263,0.1927347 0.2300776,0.3663427 0.3831106,0.5132157"

    />

  </SvgIcon>
);

export default Class6Icon;
