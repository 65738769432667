import React from 'react';
import PropTypes from 'prop-types';
import shortId from 'shortid';
import isEmpty from 'lodash/isEmpty';
import { CSVLink } from 'react-csv';
import Button from '@material-ui/core/Button';
import {
  Typography, TextField, CircularProgress, InputAdornment, Chip,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import FilterIcon from '@material-ui/icons/FilterList';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import SearchIcon from '@material-ui/icons/Search';
import { debounce, throttle } from 'lodash';
import * as colors from '../styles/colors';
import ShipmentCard from '../components/ShipmentCard';
import { Page } from '../components/common';
import RedirectToNewOrderButton from '../components/common/RedirectToNewOrderButton';
import TrackingModal from '../components/TrackingModal';
import Navbar from '../components/Navbar';
import FilterModal from '../components/FilterModal';
import { inDevelopment, SHIPMENT_TRACKING_TAB } from '../clientConstants';
import statusHelp from '../utils/status';
import { formatDateLong, formatMonetaryAmount, formatShipmentFilterChipText } from '../utils/helpers';
import {
  useShipmentState,
  useShipmentDispatch,
  loadRecentShipments,
} from '../context/shipmentDataContext';
import { useUserDispatch, useUserState, loadUser } from '../context/userContext';
import { loadShipments, loadShipmentExport } from '../utils/shipmentClient';
import {
  loadFreightTypeOptions, loadOrderPurposes, useMiscDispatch, useMiscState,
} from '../context/miscDataContext';

const initialStatusFilterState = statusHelp.filterableStatuses.reduce((obj, key) => (
  {
    ...obj,
    [key]: false,
  }
), {});

const CSV_COLUMNS_HEADER = [
  {
    key: 'shipmentId',
    label: 'Shipment ID',
  },
  {
    key: 'trackingNumbers',
    label: 'Tracking Numbers',
  },
  {
    key: 'masterTrackingNumber',
    label: 'Master Tracking Number',
  },
  {
    key: 'expectedDeliveryDateTime',
    label: 'Expected Delivery Date',
  },
  {
    key: 'shippingType',
    label: 'Type',
  },
  {
    key: 'freightType',
    label: 'Freight Type',
  },
  {
    key: 'hazmatType',
    label: 'Hazmat class',
  },
  {
    key: 'numPackages',
    label: 'Total Packages',
  },
  {
    key: 'packageWeight',
    label: 'Weight',
  },
  {
    key: 'originAddressName',
    label: "Sender's Name",
  },
  {
    key: 'originCompanyName',
    label: "Sender's Company Name",
  },
  {
    key: 'originAddressLine1',
    label: "Sender's Address Line 1",
  },
  {
    key: 'originAddressLine2',
    label: "Sender's Address Line 2",
  },
  {
    key: 'originAddressState',
    label: "Sender's State",
  },
  {
    key: 'originAddressCity',
    label: "Sender's City",
  },
  {
    key: 'originAddressZip',
    label: "Sender's ZIP",
  },
  {
    key: 'originAddressCountry',
    label: "Sender's Country",
  },
  {
    key: 'originAddressPhone',
    label: "Sender's Phone",
  },
  {
    key: 'originAddressEmail',
    label: "Sender's Email",
  },
  {
    key: 'recipientAddressName',
    label: "Recipient's Name",
  },
  {
    key: 'recipientCompanyName',
    label: "Recipient's Company Name",
  },
  {
    key: 'recipientAddressLine1',
    label: "Recipient's Address Line 1",
  },
  {
    key: 'recipientAddressLine2',
    label: "Recipient's Address Line 2",
  },
  {
    key: 'recipientAddressState',
    label: "Recipient's State",
  },
  {
    key: 'recipientAddressCity',
    label: "Recipient's City",
  },
  {
    key: 'recipientAddressZip',
    label: "Recipient's ZIP",
  },
  {
    key: 'recipientAddressCountry',
    label: "Recipient's Country",
  },
  {
    key: 'recipientAddressPhone',
    label: "Recipient's Phone",
  },
  {
    key: 'recipientAddressEmail',
    label: "Recipient's Email",
  },
  {
    key: 'pickupDropoff',
    label: 'Pickup/Drop',
  },
  {
    key: 'expectedPickupDateTime',
    label: 'Expected Pickup Time',
  },
  {
    key: 'recipient',
    label: 'Recipient',
  },
  {
    key: 'collaborators',
    label: 'Collaborators',
  },
  {
    key: 'carrier',
    label: 'Carrier',
  },
  {
    key: 'listPrice',
    label: 'List Price',
  },
  {
    key: 'amount',
    label: 'Amount',
  },
  {
    key: 'status',
    label: 'Status',
  },
  {
    key: 'updated',
    label: 'Updated',
  },
  {
    key: 'costCodes',
    label: 'Cost Codes',
  },
];

const INITIAL_FILTER_VALUES = {
  status: initialStatusFilterState,
  freightType: null,
  purpose: null,
  startDate: null,
  endDate: null,
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: colors.background,
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  iconContainer: {
    marginRight: '5px',
    color: 'white',
  },
  listHeadingDiv: {
    marginTop: '10px',
    height: '60px',
    borderBottom: '1px solid rgba(255,255,255,.2)',
    padding: '0 0 10px 0',
    width: '100%',
  },
  shipmentsHeading: {
    fontSize: 24,
    color: colors.white,
  },
  centeredContainer: {
    alignItems: 'center',
  },
  exportButton: {
    minWidth: 100,
    letterSpacing: 1.25,
    borderRadius: 8,
    marginRight: 16,
  },
  viewAllButton: {
    fontSize: '14px',
    color: '#94CCFB',
    letterSpacing: 1.25,
  },
  pageDiv: {
    // overflow: 'scroll',
    // height: '100vh',
    // backgroundColor: '#151721',
  },
  select: {
    color: colors.secondaryButton,
    marginLeft: '15px',
    fontSize: '24px',
    border: 'none',
    letterSpacing: 1.2,
    fontWeight: 500,
  },
  filterBar: {
    padding: '5px 0',
  },
  filterIcon: {
    color: colors.white,
    fontSize: '30px',
  },
  activeFilterChip: {
    background: colors.background,
    height: 'auto',
    padding: 5,
    color: colors.white,
    border: `1px solid ${colors.darkBackgroundGrey}`,
    borderRadius: 5,
    fontSize: 15,
    margin: '5px 15px 0px 0px',
  },
  activeFilterChipText: {
    color: colors.white,
    fontSize: 15,
    textTransform: 'capitalize',
  },
  activeFiltersWrap: {
    alignItems: 'center',
    height: '100%',
    maxWidth: 'calc(100% - 64px)',
  },
  searchFieldWrap: {
    height: 45,
    borderRadius: 5,
    background: colors.darkInputFieldBackground,
    marginLeft: '20px',
    width: 300,
    color: colors.white,
  },
  searchField: {
    width: 370,
    background: colors.darkInputFieldBackground,
    color: colors.white,
    '& label': {
      color: colors.white,
      fontWeight: 500,
    },
    overflow: 'hidden',
    margin: 0,
    height: '100%',
  },
  searchFieldRoot: {
    color: colors.white,
  },
  inputRoot: {
    background: colors.darkInputFieldBackground,
    color: colors.white,
  },
  searchFieldLabelRoot: {
    color: colors.white,
    fontSize: 16,
    fontWeight: 500,
    background: colors.darkInputFieldBackground,
  },
  noResultsIconContainer: {
    fontSize: 160,
    display: 'flex',
    background: colors.newOrderFormBackground,
    borderRadius: 130,
    height: 260,
    width: 260,
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 50,
  },
  noResultsIconRoot: {
    color: '#80808C',
  },
  noResultsText: {
    color: colors.tableGreyText,
    fontSize: 14,
  },
  noResultsTextContainer: {
    marginTop: 20,
  },
  endAdornmentRoot: {
    margin: 0,
    '&:not(.MuiInputAdornment-hiddenLabel)': {
      margin: '0px !important',
    },
  },
  filterButtonContainer: {
    justifySelf: 'flex-end',
    display: 'flex',
    alignSelf: 'center',
  },
}));

export function LandingPage(props) {
  const { history } = props;
  const classes = useStyles();
  return (
    <Grid container spacing={1} className={classes.pageDiv}>
      <MyShipments history={history} />
    </Grid>
  );
}

LandingPage.propTypes = {
  history: PropTypes.object.isRequired,
};

export function MyShipments(props) {
  const classes = useStyles();
  const shipmentState = useShipmentState();
  const shipmentDispatch = useShipmentDispatch();
  const userDispatch = useUserDispatch();
  const userState = useUserState();
  const { orderPurposes, freightTypeOptions } = useMiscState();
  const miscDispatch = useMiscDispatch();
  const [filterOpen, setFilterOpen] = React.useState(null);
  const [searchField, setSearchField] = React.useState('');
  const [trackingModalState, setTrackingModalState] = React.useState({
    anchorEl: null,
    shipmentId: null,
    trackingNumber: null,
    iotTrackingNumber: null,
    openTab: SHIPMENT_TRACKING_TAB,
  });
  const [loading, setLoading] = React.useState(false);
  const [filterState, setFilterState] = React.useState(INITIAL_FILTER_VALUES);
  const [currentTrackingTab, setCurrentTrackingTab] = React.useState(SHIPMENT_TRACKING_TAB);
  const [fetchShipmentOptions, setFetchShipmentOptions] = React.useState({
    limit: 10,
    offset: 0,
    allLoaded: false,
  });
  const [csvShipmentsState, setCSVShipmentsState] = React.useState({
    data: [],
    loading: false,
  });
  const [activeFilters, setActiveFilters] = React.useState({});

  const { accountType, account } = useUserState();

  const csvLinkButton = React.useRef(null);
  const lastCardRef = React.useRef(null);
  const debouncedSearch = React.useRef(
    // eslint-disable-next-line no-use-before-define
    debounce(updateSearchFilter, 600),
  ).current;

  const onWindowScroll = React.useCallback(() => {
    const windowScroll = window.scrollY + window.innerHeight;
    const elementLocation = (lastCardRef.current
      && (lastCardRef.current.offsetTop + lastCardRef.current.clientHeight)) || Infinity;

    if ((windowScroll >= elementLocation) && !loading) {
      lastCardRef.current = null;
      const func = throttle(loadNewShipments, 1800);
      func();
    }
  });

  const submitFilters = () => {
    const filters = Object.keys(filterState).reduce((obj, cat) => {
      if (!filterState[cat]) return obj;
      if (typeof filterState[cat] === 'string') {
        return { ...obj, [cat]: filterState[cat] };
      }
      const catFilters = Object.keys(filterState[cat]).filter((f) => filterState[cat][f]);
      if (catFilters.length) return { ...obj, [cat]: catFilters };
      return obj;
    }, {});
    setActiveFilters(filters);
    setFetchShipmentOptions({ offset: 0, limit: 10 });
    refreshShipments({ filters, offset: 0, limit: 10 });
  };

  const handleChipDelete = (key) => {
    const newFilters = activeFilters;

    delete newFilters[key];

    setActiveFilters(newFilters);
    setFetchShipmentOptions({ offset: 0, limit: 10 });
    refreshShipments({
      offset: 0,
      limit: 10,
      filters: newFilters,
    });
  };

  const handleFilterClear = () => {
    setActiveFilters({});
    setFilterState(INITIAL_FILTER_VALUES);
    refreshShipments({
      ...fetchShipmentOptions,
      filters: {
        filter: searchField,
      },
    });
  };

  const loadNewShipments = () => refreshShipments({});

  const refreshShipments = ({ offset, limit, filters }) => {
    const requestFilters = filters || { ...activeFilters };
    const requestOffset = offset !== undefined ? offset : fetchShipmentOptions.offset;
    const requestLimit = limit || fetchShipmentOptions.limit;
    setLoading(true);
    return loadRecentShipments(shipmentDispatch, requestFilters, requestOffset, requestLimit)
      .then((data) => {
        const allLoaded = data.length < requestLimit;
        if (allLoaded) lastCardRef.current = null;
        setFetchShipmentOptions((prev) => ({
          ...prev,
          offset: requestLimit + requestOffset,
          allLoaded,
        }));
        setLoading(false);
        if (filterOpen) setFilterOpen(null);
      });
  };

  const openTrackingModal = ({
    shipmentId, trackingNumber, anchorEl, iotTrackingNumber, openTab,
  }) => {
    setTrackingModalState({
      shipmentId, trackingNumber, anchorEl, iotTrackingNumber, openTab,
    });
    setCurrentTrackingTab(openTab);
  };

  function updateSearchFilter(search) {
    setSearchField(search);
    if (search.length > 2) {
      setFetchShipmentOptions({ offset: 0, limit: 10 });
      setActiveFilters((prev) => ({ ...prev, filter: search }));
      refreshShipments({
        filters: {
          ...activeFilters, filter: search,
        },
        offset: 0,
        limit: 10,
      });
    } else if (activeFilters.filter) {
      setActiveFilters((prev) => {
        const newFilters = { ...prev };
        delete newFilters.filter;
        return newFilters;
      });
      setFetchShipmentOptions({ offset: 0, limit: 10 });
      const filters = { ...activeFilters };
      delete filters.filter;
      refreshShipments({ filters, offset: 0, limit: 10 });
    }
  }

  const handleTrackingClose = () => {
    setTrackingModalState({
      shipmentId: null,
      trackingNumber: null,
      anchorEl: null,
      iotTrackingNumber: null,
      openTab: SHIPMENT_TRACKING_TAB,
    });
  };

  const getButtonText = (status, collab) => {
    if (status === 'Created' && collab.length > 1) {
      return 'Edit';
    }
    return null;
  };

  const handleCSV = () => {
    setCSVShipmentsState({
      data: [],
      loading: true,
    });

    loadShipmentExport({ limit: null, offset: null, filters: activeFilters }).then((response) => {
      setCSVShipmentsState(
        {
          data: response.map((shipment) => ({
            ...shipment,
            collaborators: shipment.collaborators && shipment.collaborators.map((collaborator) => collaborator.name).join(', '),
            amount: formatMonetaryAmount(shipment.currencyCode, shipment.amount) || 'N/A',
            listPrice: formatMonetaryAmount(shipment.currencyCode, shipment.listPrice) || 'N/A',
            expectedDeliveryDateTime: formatDateLong(shipment.expectedDeliveryDateTime),
            updated: formatDateLong(shipment.updated),
            trackingNumbers: shipment.trackingNumbers ? shipment.trackingNumbers.join(', ') : '',
          })),
          loading: false,
        },
      );
    }).catch((e) => {
      setCSVShipmentsState({
        data: [],
        loading: false,
      });
    });
  };

  const reduceOptions = (options) => options.reduce((obj, value) => ({
    ...obj,
    [value.type]: false,
  }), {});

  React.useEffect(() => {
    inDevelopment() && console.log('calling loadShipments from workspace');

    if (!accountType) loadUser(userDispatch);

    refreshShipments({}).catch(() => setLoading(false));
  }, []);

  React.useEffect(() => {
    if (!loading) {
      onWindowScroll();
    }
  }, [loading]);

  React.useEffect(() => {
    if (!isEmpty(csvShipmentsState.data) && csvLinkButton && csvLinkButton.current) {
      csvLinkButton.current.link.click();
    }
  }, [csvShipmentsState.data]);

  React.useEffect(() => {
    if (orderPurposes && freightTypeOptions) {
      setFilterState({
        ...filterState,
        purpose: reduceOptions(orderPurposes),
        freightType: reduceOptions(freightTypeOptions),
      });
    }
  }, [orderPurposes, freightTypeOptions]);

  React.useEffect(() => {
    window.addEventListener('scroll', onWindowScroll);
    return () => {
      window.removeEventListener('scroll', onWindowScroll);
    };
  });
  React.useEffect(() => {
    if (!orderPurposes) loadOrderPurposes(miscDispatch);
    if (!freightTypeOptions) loadFreightTypeOptions(miscDispatch);
  }, []);
  React.useEffect(() => () => {
    debouncedSearch.cancel();
  }, [debouncedSearch]);

  function doSearch(criteria) {
    if (criteria.length === 0) {
      updateSearchFilter(criteria);
    } else {
      debouncedSearch(criteria);
    }
  }
  return (
    <Grid container direction="column">
      <TrackingModal
        handleClose={handleTrackingClose}
        id={`tracking-${trackingModalState.shipmentId || ''}`}
        trackingModalState={trackingModalState}
        currentTab={currentTrackingTab}
        setCurrentTab={setCurrentTrackingTab}
      />
      <div className={classes.listHeadingDiv}>
        <Grid container justify="space-between" alignItems="center">
          <Grid item className={classes.centeredContainer}>
            <Grid container alignItems="center">
              <div className={classes.iconContainer}>
                <LocalShippingIcon />
              </div>
              <Grid item>
                <Grid container>
                  <Typography className={classes.shipmentsHeading}>
                    My Shipments
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                className={classes.searchFieldWrap}
              >
                <TextField
                  id="filled-basic"
                  className={classes.searchField}
                  classes={{ root: classes.searchFieldRoot }}
                  label="Search Shipments"
                  margin="normal"
                  onChange={(e) => doSearch(e.target.value)}
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      root: classes.inputRoot,
                      focused: classes.inputRoot,
                    },
                    endAdornment: (
                      <InputAdornment
                        position="start"
                        classes={{
                          root: classes.endAdornmentRoot,
                          filled: classes.endAdornmentRoot,
                          positionStart: classes.endAdornmentRoot,
                        }}
                      >
                        <SearchIcon color="primary" />
                      </InputAdornment>),
                  }}
                  InputLabelProps={{ root: classes.searchFieldLabelRoot }}
                  variant="filled"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Button
              className={classes.exportButton}
              color="secondary"
              variant="contained"
              onClick={handleCSV}
            >
              {csvShipmentsState.loading ? <CircularProgress style={{ width: 25, height: 25 }} /> : 'Export'}
            </Button>
            <CSVLink
              ref={csvLinkButton}
              headers={CSV_COLUMNS_HEADER}
              data={csvShipmentsState.data}
              filename={`my-shipments-${new Date().toISOString()}.csv`}
            />
            <RedirectToNewOrderButton
              label="New Shipping Order"
              userHasSignature={(userState && !userState.useProfileSignature) || (userState && !isEmpty(userState.signature))}
              isAckProhibited={userState.isAckProhibited}
            />
          </Grid>
        </Grid>
      </div>
      <Grid item container className={classes.filterBar} justify="space-between">
        <FilterModal
          {...{ accountType }}
          anchorEl={filterOpen}
          handleClose={() => setFilterOpen(null)}
          filterState={filterState}
          orderPurposeOptions={orderPurposes}
          freightTypeOptions={freightTypeOptions}
          setFilterState={setFilterState}
          submitFilters={submitFilters}
          onFilterClear={handleFilterClear}
        />
        <Grid item container className={classes.activeFiltersWrap}>
          {
              Object.entries(activeFilters).map(([filterType, filterValues]) => (
                filterType !== 'filter'
                && (
                <Grid
                  item
                  className={classes.activeFilterChip}
                  key={`active-filter-ship-grid-${filterType}`}
                >
                  <Typography
                    key={`active-filter-ship-typography-${filterType}`}
                    className={classes.activeFilterChipText}
                  >
                    <Chip
                      key={`active-filter-ship-typography-${filterType}`}
                      variant="outlined"
                      className={classes.activeFilterChipText}
                      color="primary"
                      label={formatShipmentFilterChipText(filterType, filterValues)}
                      onDelete={() => handleChipDelete(filterType)}
                    />
                  </Typography>
                </Grid>
                )
              ))
            }
        </Grid>
        <Grid item className={classes.filterButtonContainer}>
          <Button
            onClick={(e) => setFilterOpen(e.currentTarget)}
            data-testid="filter-shipments-button"
          >
            <FilterIcon className={classes.filterIcon} />
          </Button>
        </Grid>
      </Grid>
      <Grid item container direction="column" alignItems="center">
        {shipmentState.shipments && shipmentState.shipments.length
          ? (shipmentState.shipments.map((shipment, idx, sArray) => (
            <ShipmentCard
              key={`shipment-tile-${shipment.shipmentId}-${shortId.generate()}`}
              shipment={shipment}
              classes={classes}
              buttonText={getButtonText(shipment.status, shipment.collaborators)}
              account={account}
              openTrackingModal={openTrackingModal}
              lastCardRef={(idx === sArray.length - 1 && !fetchShipmentOptions.allLoaded) ? lastCardRef : undefined}
            />
          )))
          : (
            !loading
            && (
            <Grid item container direction="column" alignItems="center" className={classes.noResults}>
              <Grid item className={classes.noResultsIconContainer}>
                <LocalShippingIcon fontSize="inherit" classes={{ root: classes.noResultsIconRoot }} />
              </Grid>
              <Grid item className={classes.noResultsTextContainer}>
                <Typography className={classes.noResultsText}>
                  No shipments found!
                </Typography>
              </Grid>
            </Grid>
            )
          )}
        {loading && <CircularProgress />}
      </Grid>
    </Grid>
  );
}

export default function ProtectedPage(props) {
  const classes = useStyles();
  const { account } = useUserState();

  return (
    <div
      className={classes.root}
    >
      <Navbar />
      <Page account={account}>
        <LandingPage history={props.history} />
      </Page>
    </div>
  );
}
