import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import * as colors from '../../styles/colors';

export default withStyles({
  root: {
    color: colors.white,
    '&$checked': { color: colors.white },
    '&$disabled': { color: colors.white },
  },
  disabled: {},
  checked: {},
})(Checkbox);
