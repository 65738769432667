import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, Grid, IconButton, Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import ScrollWindow from './ScrollWindow';
import * as colors from '../../styles/colors';

const customModalStyles = {
  paper: {
    width: 700,
    height: '100%',
    overflow: 'hidden',
    background: 'transparent',
    maxHeight: 'calc(100% - 30px)',
  },
  scrollingContentContainer: {
    background: colors.newOrderFormBackground,
    width: '100%',
    padding: '30px 25px',
    borderRadius: 8,
    flexWrap: 'nowrap',
    overflow: 'hidden',
    minHeight: '100%',
  },
  contentContainer: {
    background: colors.newOrderFormBackground,
    width: '100%',
    padding: '30px 25px',
    borderRadius: 8,
    flexWrap: 'nowrap',
    height: 'fit-content',
  },
  thumbVertical: {
    background: 'rgba(0,0,0,0.5) !important',
  },
  header: {
    fontSize: 24,
    fontWeight: 700,
  },
  closeIcon: {
    position: 'absolute',
    top: 10,
    right: 10,
    '&:hover': {
      background: 'inherit',
    },
    zIndex: 10,
  },
  subHeaderContainer: {
    padding: '4px 0',
  },
  subHeader: {
    fontSize: 20,
    fontWeight: 700,
    color: colors.white,
  },
  divider: {
    height: '1px',
    width: '100%',
    '&:after': {
      content: '""',
      height: '1px',
      background: colors.textDarkGrey,
      width: '100%',
    },
  },
};

const useCustomModalStyles = makeStyles(customModalStyles);

export default function CustomModal(props) {
  const {
    title, open, onClose, subtitle,
    divider, children, classes, enableBackdropClick, enableEscapeKeyDown,
    scrollingContent, TitleIcon,
  } = props;

  const localClasses = useCustomModalStyles();

  const [localOpenState, setLocalOpenState] = React.useState(false);

  React.useEffect(() => {
    if (open) {
      setLocalOpenState(true);
    }
  }, [open]);

  const localOnClose = () => setLocalOpenState(false);

  const content = (
    <>
      <Grid item container direction="column">
        <Grid item container spacing={1} alignItems="flex-end">
          {TitleIcon && <Grid item><TitleIcon /></Grid>}
          <Grid item>
            <Typography color="primary" className={localClasses.header}>
              {title}
            </Typography>
          </Grid>
        </Grid>
        {subtitle
            && (
            <Grid item className={localClasses.subHeaderContainer}>
              <div className={localClasses.subHeader}>
                {subtitle}
              </div>
            </Grid>
            )}
      </Grid>
      { divider && <Grid item container className={localClasses.divider} />}
      <Grid item container direction="column">
        {children}
      </Grid>
    </>
  );
  return (
    <Dialog
      open={localOpenState}
      onClose={localOnClose}
      classes={{ paper: `${localClasses.paper} ${classes.root}` }}
      disableBackdropClick={!enableBackdropClick}
      disableEscapeKeyDown={!enableEscapeKeyDown}
      onExited={onClose}
    >
      <IconButton
        className={localClasses.closeIcon}
        onClick={localOnClose}
        disableFocusRipple
        disableRipple
        disableTouchRipple
      >
        <CloseIcon color="primary" />
      </IconButton>
      {scrollingContent ? (
        <ScrollWindow classes={{ thumbVertical: localClasses.thumbVertical }}>
          <Grid container className={localClasses.scrollingContentContainer} direction="column">
            {content}
          </Grid>
        </ScrollWindow>
      )
        : (
          <Grid container className={localClasses.contentContainer} direction="column">
            {content}
          </Grid>
        )}
    </Dialog>
  );
}
CustomModal.propTypes = {
  open: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.object]),
  onClose: PropTypes.func,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.object]),
  divider: PropTypes.bool,
  children: PropTypes.node,
  classes: PropTypes.objectOf(PropTypes.string),
  enableBackdropClick: PropTypes.bool,
  enableEscapeKeyDown: PropTypes.bool,
  scrollingContent: PropTypes.bool,
  TitleIcon: PropTypes.object,
};

CustomModal.defaultProps = {
  open: false,
  onClose: null,
  subtitle: '',
  divider: false,
  children: null,
  classes: {},
  enableBackdropClick: false,
  enableEscapeKeyDown: false,
  scrollingContent: false,
  TitleIcon: null,
};
