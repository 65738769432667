import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { Button, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import * as colors from '../../styles/colors';

const StyledButton = withStyles({
  root: {
    margin: 'auto',
    minWidth: 110,
    float: 'left',
    backgroundColor: 'transparent',
    boxShadow: 'unset',
    color: colors.white,
    '&:hover': {
      backgroundColor: 'transparent',
      boxShadow: 'unset',
    },
    padding: 12,
  },
  label: {
    height: 30,
  },
})(Button);

const SelectedTypography = withStyles({
  root: {
    fontWeight: 'bold',
  },
})(Typography);

const UnselectedTypography = withStyles({
  root: {
    fontWeight: 'bold',
    color: 'rgba(255,255,255,0.6)',
  },
})(Typography);

function isCurrentPath(path, currPath) {
  return path === currPath;
}

export default function NavigateToLoginButton(props) {
  const { path, label, onClick } = props;
  return (
    <Route render={({ match, history }) => (
      <StyledButton
        disableRipple
        variant="contained"
        onClick={(e) => {
          if (onClick) onClick(e);
          else history.push(path);
        }}
      >
        {
          isCurrentPath(path, match.path)
            ? <SelectedTypography>{label}</SelectedTypography>
            : <UnselectedTypography>{label}</UnselectedTypography>
        }
      </StyledButton>
    )}
    />
  );
}

NavigateToLoginButton.defaultProps = {
  onClick: null,
};

NavigateToLoginButton.propTypes = {
  path: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};
