import shortId from 'shortid';

export const GOOGLE_MAPS_API_KEY = new Promise((resolve, reject) => {
  resolve(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);
});

export const CARRIER = 'carrier';
export const SESSION_EXPIRED = 'Session expired. Please login.';

export function inDevelopment() {
  return ['development'].includes(process.env.NODE_ENV);
}

export function inDevelopmentOrTest() {
  return ['development', 'test'].includes(process.env.NODE_ENV);
}

export const COUNTRIES_THAT_REQUIRE_CUSTOMS_VALUE = [
  'RU',
];

export const WAREHOUSES_LIST = [
  'World Emblem - ca',
  'World Emblem - canada',
  'World Emblem - fl',
  'World Emblem - ga',
  'World Emblem - Mexico',
  'World Emblem - il',
  'World Emblem - tx',
];
// The string here should match the taskType
export const NEW_ORDER_TAB_STRINGS = {
  sender: 'origin',
  recipient: 'destination',
  customsBroker: 'customsBroker',
  regulationAndCompliance: 'compliance',
  itemAndProductDetails: 'product',
  packagingAndContents: 'packaging',
  shippingDetails: 'carrier',
  billingAndPayment: 'billing',
  billingAndPaymentCustoms: 'billingCustoms',
  billingAndPaymentTaxes: 'billingTaxes',
  schedulePickup: 'pickupFrom',
  shippingDocuments: 'document',
  aes: 'aesFiling',
  exportCompliance: 'exportCompliance',
};

const SENDER_FORM_TABS_WITHOUT_IDS = [
  {
    label: 'Default Sender', index: 0, value: 'one', name: 'default-sender',
  },
  {
    label: 'Select From Contacts',
    index: 1,
    value: 'two',
    name: 'select-from-contacts',
  },
  {
    label: 'Enter Address',
    index: 2,
    value: 'three',
    name: 'enter-new-address',
  },
];

export const SENDER_FORM_TABS_WITH_IDS = SENDER_FORM_TABS_WITHOUT_IDS.map(
  (tab) => {
    const id = `sender-tab-${shortId.generate()}`;
    return { id, ...tab };
  },
);

const RECIPIENT_FORM_TABS_WITHOUT_IDS = [
  {
    label: 'Select From Contacts',
    index: 0,
    value: 'one',
    name: 'select-from-contacts',
  },
  {
    label: 'Enter Address',
    index: 1,
    value: 'two',
    name: 'enter-new-address',
  },
];
const RECIPIENT_FORM_TABS_MULTIPLE_WITHOUT_IDS = [
  {
    label: 'Select From Contacts',
    index: 0,
    value: 'one',
    name: 'select-from-contacts',
  },
  {
    label: 'Enter Address',
    index: 1,
    value: 'two',
    name: 'enter-new-address',
  },
  {
    label: 'Multiple Addresses',
    index: 2,
    value: 'three',
    name: 'select-multiple-addresses',
  },
];

export const RECIPIENT_FORM_TABS_WITH_IDS = RECIPIENT_FORM_TABS_WITHOUT_IDS.map(
  (tab) => {
    const id = `recipient-tab-${shortId.generate()}`;
    return { id, ...tab };
  },
);

export const RECIPIENT_FORM_TABS_MULTIPLE_WITH_IDS = RECIPIENT_FORM_TABS_MULTIPLE_WITHOUT_IDS.map(
  (tab) => {
    const id = `recipient-tab-${shortId.generate()}`;
    return { id, ...tab };
  },
);
const PACKAGE_TYPES_WITHOUT_IDS = [
  { value: 'Box', code: '001', label: 'Box' },
  { value: 'Another type', code: '002', label: 'Another Type' },
];

export const PACKAGE_TYPES_WITH_IDS = PACKAGE_TYPES_WITHOUT_IDS.map((type) => {
  const id = `package-type-list-${shortId.generate()}`;
  return { id, ...type };
});

const LENGTH_UNIT_OF_MEASUREMENTS_WITHOUT_IDS = [
  { value: 'IN', label: 'IN' },
  { value: 'CM', label: 'CM' },
];

const CURRENCIES_WITHOUT_IDS = [
  { value: 'USD', label: 'USD' },
  { value: 'EUR', label: 'EUR' },
];

export const LENGTH_UNIT_OF_MEASUREMENTS_WITH_IDS = LENGTH_UNIT_OF_MEASUREMENTS_WITHOUT_IDS.map(
  (unit) => {
    const id = `length-unit-of-measure-list-${shortId.generate()}`;
    return { id, ...unit };
  },
);

export const SHIPMENT_TRACKING_TAB = 0;
export const IOT_TRACKING_TAB = 1;

export const MY_TASKS_PATH = '/my-tasks';
export const MY_TASKS_LABEL = 'MY TASKS';
export const MY_SHIPMENTS_PATH = '/my-shipments';
export const MY_SHIPMENTS_LABEL = 'MY SHIPMENTS';
export const ADDRESS_BOOK_PATH = '/address-book';
export const ADDRESS_BOOK_LABEL = 'ADDRESS BOOK';
export const ADMINISTRATION_PATH = '/administration';
export const ADMINISTRATION_LABEL = 'ADMIN';
export const NEW_ORDER_PATH = '/new-order';
export const PROFILE_MANAGEMENT_PATH = '/profile-management';
export const MANAGE_USERS_PATH = '/manage-users';
export const MANAGE_USERS_LABEL = 'Manage Users';
export const HAZMAT_PATH = '/hazmat-shipments';
export const HAZMAT_LABEL = 'Hazmat Shipments';
export const INTERNATIONAL_PATH = '/international-shipments';
export const INTERNATIONAL_LABEL = 'International Shipments';
export const ALL_PATH = '/all-shipments';
export const ALL_LABEL = 'All Shipments';
export const ACCOUNT_MANAGEMENT_PATH = '/account-management';
export const ACCOUNT_MANAGEMENT_LABEL = 'Manage Account';
export const MATERIAL_DASHBOARD_PATH = '/material-dashboard';
export const MATERIAL_DASHBOARD_LABEL = 'Material Dashboard';
export const BIOLOGICAL_DASHBOARD_PATH = '/biological-dashboard';
export const BIOLOGICAL_DASHBOARD_LABEL = 'Biological Materials Dashboard';

export const FIELD_TYPE_TEXT = 'text';
export const FIELD_TYPE_DROPDOWN = 'dropdown';
export const FIELD_TYPE_BOOL = 'bool';
export const FIELD_TYPE_PHONE = 'phone';
export const FIELD_TYPE_LINK = 'link';
export const FIELD_TYPE_EMAIL = 'email';
export const FIELD_TYPE_TEXTAREA = 'textarea';

export const USER_PROFILE_GROUP_TYPE_FIELD = 'groupType';

export const USER_PROFILE_FIELDS = [
  {
    name: 'name',
    label: 'Full Name',
    type: FIELD_TYPE_TEXT,
    isRequired: true,
  },
  {
    name: 'department',
    label: 'Department',
    type: FIELD_TYPE_TEXT,
    isRequired: false,
  },
  {
    name: 'userType',
    label: 'User Type',
    type: FIELD_TYPE_DROPDOWN,
    isRequired: false,
  },
  {
    name: 'timeZone',
    label: 'Time Zone',
    type: FIELD_TYPE_DROPDOWN,
    itemsKey: 'timeZones',
    isRequired: false,
  },
  {
    name: USER_PROFILE_GROUP_TYPE_FIELD,
    label: 'Group Type',
    type: FIELD_TYPE_DROPDOWN,
    itemsKey: 'groupTypes',
    isRequired: false,
  },
];

export const HAZMAT = 'hazmat';
export const HAZMAT_CHEMICAL = 'chemical';
export const HAZMAT_BIOLOGICAL = 'biological';
export const HAZMAT_BATTERIES = 'batteries';
export const HAZMAT_TOXINS = 'toxins';
export const HAZMAT_BATTERIES_CONTAINED_OR_PACKED = 'batteriesWithEquipment';
export const HAZMAT_BATTERIES_CONTAINED = 'batteriesWithEquipmentContained';
export const HAZMAT_BATTERIES_PACKED = 'batteriesWithEquipmentPacked';
export const EHS_COMPLIANCE = 'ehsCompliance';

export const DEFAULT_FETCH_OPTIONS = { limit: 25, offset: 0 };

export const SIGNATURE_SECTION_ID = 'signature-section';
export const SCROLL_TO_SIGNATURE = 'scrollToSignature';

export const ECO = 'eco';
export const EHS = 'ehs';
export const ESG_ADMIN = 'esgAdmin';
export const CLIENT_ADMIN = 'clientAdmin';
export const SHIPPER = 'shipper';
export const ADMIN_USERS = [ESG_ADMIN, CLIENT_ADMIN];
export const EXPORT_COMPLIANCE = 'exportCompliance';

export const USER_TYPE_MAPPING = {
  [ESG_ADMIN]: 'Administrator',
  [CLIENT_ADMIN]: 'Administrator',
  [EHS]: 'EHS',
  [ECO]: 'ECO',
  [SHIPPER]: 'Shipper',
};

export const ESG_SESSION_AUTH = 'esg-session';

// ICONS NAME
export const ICON_EMAIL = 'email';
export const ICON_WORK_PHONE = 'workPhone';
export const ICON_MOBILE_PHONE = 'mobilePhone';
export const ICON_LINKEDIN = 'linkedin';
export const ICON_TWITTER = 'twitter';
export const ICON_FACEBOOK = 'facebook';
export const ICON_DEPARTMENT = 'department';
export const ICON_NAME = 'name';
export const ICON_LANGUAGE = 'language';
export const ICON_TITLE = 'title';
export const ICON_TIMEZONE = 'timeZone';
export const ICON_LINK = 'link';

export const CARRIERS = [
  { value: 'fedex', label: 'FedEx' },
  { value: 'ups', label: 'UPS' },
  { value: 'lso', label: 'LSO' },
  { value: 'usps', label: 'USPS' },
  { value: 'dhl', label: 'DHL' },
];

export const SERVICES = [
  { value: 'ST0001', label: 'FedEx Ground', carrier: 'fedex' },
  { value: 'ST0003', label: 'Standard', carrier: 'ups' },
  { value: 'ST0004', label: 'Ground', carrier: 'ups' },
  { value: 'ST0005', label: 'Ground', carrier: 'lso' },
  { value: 'ST0006', label: 'First-Class Mail', carrier: 'usps' },
  { value: 'ST0201', label: '2 Day', carrier: 'fedex' },
  { value: 'ST0202', label: '2nd Day Air', carrier: 'ups' },
  { value: 'ST0203', label: '2-day Priority', carrier: 'lso' },
  { value: 'ST0301', label: '2 Day AM Delivery', carrier: 'fedex' },
  { value: 'ST0302', label: '2nd Day Air A.M.', carrier: 'ups' },
  { value: 'ST0401', label: 'Priority Overnight', carrier: 'fedex' },
  { value: 'ST0101', label: 'Express Saver', carrier: 'fedex' },
  { value: 'ST0102', label: '3 Day Select', carrier: 'ups' },
  { value: 'ST0103', label: 'Priority Mail', carrier: 'usps' },
  { value: 'ST0402', label: 'Next Day Air', carrier: 'ups' },
  { value: 'ST0403', label: 'Priority Next Day', carrier: 'lso' },
  { value: 'ST0501', label: 'Standard Overnight', carrier: 'fedex' },
  { value: 'ST0502', label: 'Next Day Air Saver', carrier: 'ups' },
  { value: 'ST0503', label: 'Economy Next Day', carrier: 'lso' },
  { value: 'ST0601', label: 'First Overnight', carrier: 'fedex' },
  { value: 'ST0602', label: 'Next Day Air Early', carrier: 'ups' },
  { value: 'ST0603', label: 'Early Next Day', carrier: 'lso' },
  { value: 'ST0604', label: 'Priority Mail Express', carrier: 'usps' },
  { value: 'ST0605', label: 'Express Domestic', carrier: 'dhl' },
];

export const SHIPPING_TYPE_DOMESTIC = 'domestic';
export const SHIPPING_TYPE_INTERNATIONAL = 'international';

export const FREIGHT_TYPE_PRODUCT = 'product';
export const FREIGHT_TYPE_DOCUMENT = 'document';
export const FREIGHT_TYPE_HAZMAT = 'hazmat';

export const GET_PAGE_PARAMS = {
  constraint: 'constraint',
};

export const GET_PARAM_DETAILS = {
  constraint: {
    userProfile: 'user-profile',
    ackProhibited: 'ack-prohibited',
  },
};

export const CUSTOM_FREIGHT_OPTION = 'custom';

export const EURO_COUNTRIES = ['AT',
  'BE',
  'BG',
  'HR',
  'CY',
  'CZ',
  'DK',
  'EE',
  'FI',
  'FR',
  'DE',
  'GR',
  'HU',
  'IE',
  'IT',
  'LV',
  'LT',
  'LU',
  'MT',
  'NL',
  'PL',
  'PT',
  'RO',
  'SK',
  'SI',
  'ES',
  'SE',
  'NO',
  'CH'];
