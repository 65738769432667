import React from 'react';
import {
  SvgIcon,
} from '@material-ui/core';

const Class9Icon = (props) => (
  <SvgIcon {...props}>
    <path
      d="M 11,0 C 10.681982,0 10.365035,0.12120962 10.12197,0.36352202 L 0.36299044,10.122407 c -0.48398725,0.484732 -0.48398725,1.270454 0,1.755186 L 10.12197,21.636478 C 10.365035,21.87879 10.681982,22 11,22 c 0.318018,0 0.634965,-0.12121 0.87803,-0.363522 l 9.75898,-9.758885 c 0.483987,-0.484732 0.483987,-1.270454 0,-1.755186 L 11.87803,0.36352202 C 11.634965,0.12120962 11.318018,0 11,0 Z M 11,1.2878 20.711866,11 11,20.712307 1.2870632,11 11,1.2878"

    />
    <path
      d="M 17.377402,10.782502 V 7.9314162 L 16.108983,6.6716943 v 4.1108077 h 1.268419"

    />
    <path
      d="M 20.249499,10.782502 18.980086,9.5226823 v 1.2598197 z"

    />
    <path
      d="M 14.505304,10.782502 V 5.0803263 L 13.236886,3.8206059 v 6.9618961 h 1.268418"

    />
    <path
      d="M 8.7621052,10.782502 V 3.8206059 L 7.4926928,5.0803263 v 5.7021757 h 1.2694124"

    />
    <path
      d="M 11.634201,10.782502 V 2.2293388 L 11.30491,1.9023328 c -0.169123,-0.16731 -0.441707,-0.16731 -0.61083,0 l -0.329292,0.327006 v 8.5531632 h 1.269413"

    />
    <path
      d="M 3.0179156,9.5226823 1.7494989,10.782502 h 1.2684167 z"

    />
    <path
      d="M 5.8900109,10.782502 V 6.6716943 L 4.6215921,7.9314162 v 2.8510858 h 1.2684188"

    />
    <path
      d="m 11.633411,14.452988 c 0,-0.346868 -0.08058,-0.61574 -0.239459,-0.807059 -0.161139,-0.191106 -0.346887,-0.286849 -0.557258,-0.286849 -0.199179,0 -0.36703,0.07845 -0.49907,0.235295 -0.132051,0.156886 -0.196951,0.41375 -0.196951,0.770617 0,0.361973 0.0716,0.627736 0.214836,0.797278 0.143231,0.16953 0.324508,0.25441 0.539355,0.25441 0.208132,0 0.382691,-0.08176 0.525923,-0.244852 0.143231,-0.163096 0.212608,-0.402877 0.212608,-0.71884 z m -2.4483459,2.34806 1.0473729,-0.114655 c 0.02465,0.211513 0.09176,0.368412 0.199178,0.470193 0.109669,0.101967 0.250655,0.153084 0.427457,0.153084 0.223789,0 0.414026,-0.101966 0.570683,-0.305964 0.156661,-0.203999 0.257366,-0.627291 0.299888,-1.269695 -0.270799,0.313538 -0.610971,0.470417 -1.020517,0.470417 -0.443123,0 -0.8258167,-0.170206 -1.1480833,-0.510634 -0.3222853,-0.340186 -0.4834011,-0.783277 -0.4834011,-1.328798 0,-0.568627 0.1700918,-1.026822 0.5102541,-1.374799 0.3401721,-0.347961 0.7721043,-0.521962 1.2980293,-0.521962 0.572922,0 1.042897,0.219748 1.409925,0.659512 0.36703,0.439969 0.550542,1.163254 0.550542,2.170297 0,1.024821 -0.190248,1.764323 -0.572921,2.218071 -0.382692,0.453749 -0.879525,0.68062 -1.494968,0.68062 -0.440884,0 -0.7989604,-0.116432 -1.0697568,-0.349743 -0.273033,-0.233311 -0.4475923,-0.58196 -0.5236843,-1.045928"

    />
    <path
      d="M 20.202741,10.794256 H 1.7952628 v -0.0236 H 20.202741 v 0.0236"

    />
  </SvgIcon>
);

export default Class9Icon;
