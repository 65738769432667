import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { Grid } from '@material-ui/core';
import CustomSelectTextField from '../../common/CustomSelectTextField';
import { singlePackagePropTypes } from '../utils';

const OptionalField = ({
  currentPackage,
  name,
  prereqName,
  prereqValueKey = null,
  ...props
}) => (
  <Grid>
    {(prereqValueKey && currentPackage[prereqName]
      ? currentPackage[prereqName][prereqValueKey]
      : currentPackage[prereqName]) && (
        <Field component={CustomSelectTextField} name={name} {...props} />
    )}
  </Grid>
);

OptionalField.propTypes = {
  currentPackage: singlePackagePropTypes.isRequired,
  name: PropTypes.string.isRequired,
  prereqName: PropTypes.string.isRequired,
  prereqValueKey: PropTypes.string,
};

OptionalField.defaultProps = {
  prereqValueKey: null,
};

export default memo(OptionalField);
