/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Button } from '@material-ui/core';
import { Delete24 as DeleteIcon } from '@carbon/icons-react';
import {
  useSingleOrderState,
  updatePackagingForShipment,
  updateCarrierForShipment, updateBillingPayment,
  updateShipmentDestination, updateShipmentCustomsBroker,
  updateShipmentOrigin, updateRegulationAndCompliance,
  updateItemProductDetails, submitDocuments, updateAESDetails, updateExportCompliance, updateSchedulePickup,
} from '../context/singleOrderContext';
import SenderPane from './SenderAndRecipientPanes/SenderPane';
import RecipientPane from './RecipientPane';
import { CustomsBrokerPane } from './CustomsBroker';
import PackagingPane from './PackagingPane/PackagingPane';
import ShippingDetailsPane from './ShippingDetailsPane';
import BillingPaymentPane from './BillingPaymentPane';
import AESFillingPanel from './AESFillingPanel';
import ComplianceCheckPanel from './ComplianceCheckPanel';
import ConfirmationPane from './ConfirmationPane';
import ShippingDocumentsPane from './ShippingDocumentsPane';
import SchedulePickupPane from './SchedulePickupPane';
import RegulationAndCompliancePane from './RegulationAndCompliancePane';
import ItemProductDetailsPane from './ItemProductDetails/ItemProductDetailsPane';
import ItemProductDetailsPaneWE from './ItemProductDetails/ItemProductDetailsPaneWE';
import * as colors from '../styles/colors';
import { NEW_ORDER_MODULE_STYLE } from '../styles/style';
import statusHelp from '../utils/status';
import StatusChip from './common/StatusChip';

const panelExtension = (account, isAutoProduct) => ({
  sender: {
    component: SenderPane,
    updateFn: (args) => updateShipmentOrigin(args),
  },
  recipient: {
    component: RecipientPane,
    updateFn: (args) => updateShipmentDestination(args),
  },
  customsBroker: {
    component: CustomsBrokerPane,
    updateFn: (args) => updateShipmentCustomsBroker(args),
  },
  compliance: {
    component: RegulationAndCompliancePane,
    updateFn: (args) => updateRegulationAndCompliance(args),
  },
  product: {
    component: account === 'worldemblem' && isAutoProduct ? ItemProductDetailsPaneWE : ItemProductDetailsPane,
    updateFn: (args) => updateItemProductDetails(args),
  },
  packaging: {
    component: PackagingPane,
    updateFn: (args) => updatePackagingForShipment(args),
  },
  carrier: {
    component: ShippingDetailsPane,
    updateFn: (args) => updateCarrierForShipment(args),
  },
  exportCompliance: {
    component: ComplianceCheckPanel,
    updateFn: (args) => updateExportCompliance(args),
  },
  billing: {
    component: BillingPaymentPane,
    updateFn: (args) => updateBillingPayment(args),
  },
  billingCustoms: {
    component: BillingPaymentPane,
    updateFn: (args) => updateBillingPayment(args),
  },
  billingTaxes: {
    component: BillingPaymentPane,
    updateFn: (args) => updateBillingPayment(args),
  },
  aesFiling: {
    component: AESFillingPanel,
    updateFn: (args) => updateAESDetails(args),
  },
  confirmation: {
    component: ConfirmationPane,
    updateFn: (args) => (args),
  },
  shippingDocuments: {
    component: ShippingDocumentsPane,
    updateFn: (args) => submitDocuments(args),
  },
  pickupFrom: {
    component: SchedulePickupPane,
    updateFn: (args) => updateSchedulePickup(args),
  },
});

const styles = {
  root: {
    width: '100%',
  },
  expansionPanel: {
    backgroundColor: colors.darkBlueBackground,
  },
  expansionPanelChild: {
    backgroundColor: colors.mediumBlueBackground,
  },
  expansionPanelLabel: {
    color: colors.white,
    fontSize: 24,
    fontWeight: 500,
  },
  expansionPanelLabelEDI: {
    color: colors.red,
    fontSize: 30,
    fontWeight: 600,
    marginLeft: '3rem',
  },
  expansionPanelLabelLocal: {
    color: colors.red,
    fontSize: 30,
    fontWeight: 600,
    marginLeft: '3rem',
  },
  floatingLabelEDI: {
    color: 'crimson',
    fontSize: 40,
    fontWeight: 600,
    position: 'fixed',
    top: '170px',
    right: '7em',
    zIndex: 1500,
  },
  floatingLabelLocal: {
    color: 'crimson',
    fontSize: 40,
    fontWeight: 600,
    position: 'fixed',
    top: '170px',
    right: '7em',
    zIndex: 1500,
  },
  expansionIcon: {
    color: colors.white,
  },
  shipmentBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  status: {
    color: '#ff07ee',
    fontWeight: '700',
    fontSize: '18px',
    textTransform: 'capitalize',
  },
  deleteIcon: {
    fill: 'white',
  },
  statusContainer: {
    height: '100%',
  },
};

const scrollToSection = (pageHistory, searchString, divRef, scrollAdjestment = 0) => {
  if (divRef.current && pageHistory.location.search.includes(searchString)) {
    window.scrollTo(0, Number(divRef.current.offsetTop) + Number(scrollAdjestment));
  }
};
const useStyles = makeStyles(styles);
/**
 * ######################
 *          UTIL
 * ######################
 */
const ShipmentFormExpandablePane = ({
  shipmentData,
  shipmentNumber,
  selectedForms,
  setSelectedForms,
  cancelShipment,
  shipmentRef,
}) => {
  const classes = useStyles();
  const packFormRef = React.useRef(null);
  const panelRefs = React.useRef([]);

  useEffect(() => {
    // eslint-disable-next-line react/prop-types
    if (shipmentData.account === 'worldemblem' && shipmentData.status !== 'Completed') {
      if (shipmentData?.billing?.task && shipmentData?.billing?.task?.status !== 'Completed') {
        setSelectedForms({ parent: `${shipmentData.shipmentId}`, child: 'billing' });
      } else {
        setSelectedForms({ parent: `${shipmentData.shipmentId}`, child: 'packaging' });
      }
    }
  }, []);
  const panelClasses = NEW_ORDER_MODULE_STYLE();

  const {
    shipmentId, status, isCreatedBy,
  } = shipmentData || {};
  const parentFormName = `${shipmentId}`;

  const shipment = useSingleOrderState().shipments[shipmentId];

  const panels = (shipment?.navigation.panels || shipment?.navigation || []).map(({ name, ...other }) => {
    // eslint-disable-next-line no-nested-ternary
    const nameModified = (name === 'origin') ? 'sender' : (name === 'destination') ? 'recipient' : name;
    const extension = panelExtension(shipment.account, !!shipment?.salesOrders)[nameModified];
    if (!extension) return null;
    return {
      name: nameModified,
      ...other,
      ...extension,
    };
  }).filter((p) => !!p);

  const onChange = () => {
    if (selectedForms.parent === parentFormName) {
      setSelectedForms({ parent: '', child: '' });
    } else {
      setSelectedForms({ parent: parentFormName, child: '' });
    }
  };

  panelRefs.current = panels.map((item, index) => panelRefs.current[index] || React.createRef());

  return (
    <div className={classes.root} ref={shipmentRef}>
      {shipment?.isEdi && (
        <Typography className={classes.floatingLabelEDI}>
          EDI Shipment
        </Typography>
      )}
      {shipment?.carrier?.details?.carrierName === 'local' && (
        <Typography className={classes.floatingLabelLocal}>
          LOCAL
        </Typography>
      )}
      {shipment?.carrier?.details?.carrierName === 'deltadash' && (
        <Typography className={classes.floatingLabelLocal}>
          DELTA DASH
        </Typography>
      )}
      <ExpansionPanel
        expanded={selectedForms.parent === parentFormName}
        className={classes.expansionPanel}
        onChange={onChange}
        TransitionProps={{ unmountOnExit: true }}
      >
        <ExpansionPanelSummary
          expandIcon={(
            <ExpandMoreIcon
              color="primary"
              classes={{ colorPrimary: classes.expansionIcon }}
            />
          )}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Grid container justify="space-between">
            <Grid item className={classes.shipmentBox}>
              <Typography className={classes.expansionPanelLabel}>
                {`Shipment ${shipmentNumber} - ${shipmentId}`}
              </Typography>
              {/* {shipment.isEdi && (
                <Typography className={classes.expansionPanelLabelEDI}>
                  EDI Shipment
                </Typography>
              )} */}
            </Grid>
            <Grid item>
              <Grid
                container
                alignItems="center"
                className={classes.statusContainer}
              >
                <Typography
                  classes={{ root: panelClasses.expansionPSSubTitle }}
                >
                  status:
                  {' '}
                  <StatusChip status={status?.toLowerCase()} type="shipment" />
                </Typography>
                {statusHelp.canBeCancelled(status, isCreatedBy) && (
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      cancelShipment(shipmentId);
                    }}
                  >
                    <DeleteIcon className={classes.deleteIcon} />
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Grid container direction="column" spacing={1}>
            {panels.map(
              (
                {
                  name,
                  key,
                  component,
                  updateFn,
                  label,
                  taskType,
                  isCompleted,
                  isReadyToBeCompleted,
                  isWarning,
                  isAssignTaskAvailable,
                  isDisabled,
                  isReadOnly,
                  panelMessage,
                  hideFields,
                },
                index,
              ) => {
                if (!component) return null;
                const isSelected = selectedForms.parent === parentFormName
                  && selectedForms.child === name;
                const nextPanel = panels[index + 1];

                return (
                  <Grid
                    item
                    ref={panelRefs.current[index]}
                    // eslint-disable-next-line react/no-array-index-key
                    key={`panel-${name}-${index}`}
                    parentFormName
                    data-testid={`panel-${name}-${index}`}
                    style={{ width: '100%' }}
                  >
                    {React.createElement(component, {
                      index,
                      name: key,
                      formName: name,
                      parentFormName,
                      label,
                      selectedForms,
                      setSelectedForms,
                      shipmentId,
                      shipment,
                      nextPanel,
                      isSelected,
                      isComplianceFailure: isWarning,
                      isComplete: isCompleted,
                      isAssignTaskAvailable,
                      isReadOnly,
                      isDisabled,
                      readOnly: shipment?.status === 'Completed',
                      isReadyToBeCompleted,
                      preReqsComplete: isReadyToBeCompleted,
                      taskType,
                      onChange: (event, expanded) => {
                        setSelectedForms((prev) => ({
                          ...prev,
                          child: prev.child === name ? '' : name,
                        }));
                        if ((name === 'packaging' || name === 'carrier' || name === 'billing' || name === 'documentation') && expanded) {
                          setTimeout(() => window.scrollTo({
                            top: panelRefs.current[index].current.offsetTop + 50,
                            behavior: 'smooth',
                          }), 600);
                        }
                      },
                      openNextPanel: () => {
                        setSelectedForms({
                          ...selectedForms,
                          child: nextPanel?.name || '',
                        });

                        if ((nextPanel?.name === 'packaging' || nextPanel?.name === 'carrier' || name === 'billing' || name === 'documentation')) {
                          setTimeout(() => window.scrollTo({
                            top: panelRefs.current[index].current.offsetTop + 50,
                            behavior: 'smooth',
                          }), 600);
                        }
                      },
                      updateFn,
                      hideFields,
                      panels,
                      packFormRef,
                      blockingMessage: panelMessage,
                    })}
                  </Grid>
                );
              },
            )}
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
};

ShipmentFormExpandablePane.propTypes = {
  selectedForms: PropTypes.shape({
    child: PropTypes.string.isRequired,
    parent: PropTypes.string.isRequired,
  }).isRequired,
  setSelectedForms: PropTypes.func.isRequired,
  shipmentNumber: PropTypes.number.isRequired,
  shipmentData: PropTypes.shape({
    shipmentId: PropTypes.string,
    status: PropTypes.string,
    isCreatedBy: PropTypes.bool,
  }).isRequired,
  cancelShipment: PropTypes.func.isRequired,
  shipmentRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.elementType }),
  ]),
};

ShipmentFormExpandablePane.defaultProps = {
  shipmentRef: null,
};

export default ShipmentFormExpandablePane;
