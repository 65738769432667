import client from './apiClient';
import {
  DUMMY_FETCH_USERS_DATA,
  DUMMY_FETCH_USER_DATA,
  DUMMY_UPLOAD_PROFILE_PICTURE_RESPONSE,
} from '../dummyData';
import {
  DEFAULT_FETCH_OPTIONS,
} from '../clientConstants';

export function loadUser() {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return Promise.resolve(DUMMY_FETCH_USER_DATA);
  }
  return client('/data/user', { method: 'get' });
}

export function loadUsers(options = DEFAULT_FETCH_OPTIONS) {
  const { limit, offset, filter } = options;

  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    const lowerCaseFilter = filter && filter.length >= 2 ? filter.toLowerCase() : '';

    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(DUMMY_FETCH_USERS_DATA
          .filter((item) => item.user.name.toLowerCase().includes(lowerCaseFilter))
          .slice(offset, offset + limit));
      }, 500);
    });
  }

  return client('/data/user/all', {
    method: 'get',
    params: {
      limit,
      offset,
      filter,
    },
  });
}

export function loadDefaultAddress() {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({});
      }, 500);
    });
  }

  return client('/data/user/address/default', {
    method: 'get',
  });
}

export function updateUser(updatedUserData) {
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return Promise.resolve({ ...DUMMY_FETCH_USER_DATA, ...updatedUserData });
  }

  return client('/data/user', { method: 'post', data: updatedUserData });
}

export function uploadProfilePicture({
  document,
}) {
  const formData = new FormData();
  formData.append('profilePicture', document);
  const options = {
    method: 'post',
    data: formData,
    headers: {
      'content-type': 'form-data',
    },
  };

  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          ...DUMMY_FETCH_USER_DATA,
          ...{
            imageUrl: DUMMY_UPLOAD_PROFILE_PICTURE_RESPONSE?.imageUrl,
            imageOriginalUrl: DUMMY_UPLOAD_PROFILE_PICTURE_RESPONSE?.imageOriginalUrl,
          },
        });
      }, 1000);
    });
  }

  return client('/data/user/upload', options);
}

export function removeProfilePicture() {
  const options = {
    method: 'delete',
  };

  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          ...DUMMY_FETCH_USER_DATA,
          ...{
            imageUrl: null,
            imageOriginalUrl: null,
          },
        });
      }, 1000);
    });
  }

  return client('/data/user/picture', options);
}
