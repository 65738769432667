import React from 'react';
import * as addressBookClient from '../utils/addressBookClient';
import { useLogger } from '../utils/hooks';

const contactState = { contacts: null, defaultSender: null };
const ContactsStateContext = React.createContext();
const ContactsDispatchContext = React.createContext();

// ContactsReducer Action Types
const LOAD_CONTACTS = 'LOAD CONTACTS';
const LOAD_DEFAULT_CONTACT = 'LOAD DEFAULT CONTACT';
const UPDATE_CONTACTS_STATE = 'UPDATE_CONTACTS_STATE';
const LOAD_INIT_CONTACTS = 'LOAD_INIT_CONTACTS';
const LOAD_FILTERED_CONTACTS = 'LOAD_FILTERED_CONTACTS';

function contactReducer(state, action) {
  switch (action.type) {
    case LOAD_CONTACTS: {
      return { ...state, contacts: action.contacts };
    }
    case LOAD_DEFAULT_CONTACT: {
      return { ...state, defaultSender: action.defaultSender };
    }
    case UPDATE_CONTACTS_STATE: {
      const { contacts, defaultSender } = state;
      const editContact = action.contact;

      const updates = {
        ...(contacts)
          && {
            contacts: state.contacts.map((contact) => ((contact.addressId === editContact.addressId)
              ? editContact
              : contact)),
          },
        ...(defaultSender && editContact.isDefault)
          && { defaultSender: { ...defaultSender, ...editContact } },
      };

      return { ...state, ...updates };
    }
    case LOAD_INIT_CONTACTS: {
      return { ...state, contacts: action.contacts };
    }
    case LOAD_FILTERED_CONTACTS: {
      const contacts = (state.contacts)
        ? state.contacts.concat(action.contacts)
        : action.contacts;
      const uniqueContacts = contacts
        .filter((contact, index, arr) => arr
          .map((obj) => obj.addressId).indexOf(contact.addressId) === index);

      return { ...state, contacts: uniqueContacts };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function ContactsDataProvider(props) {
  const [state, dispatch] = useLogger(React.useReducer(contactReducer,
    contactState || { contacts: null, defaultSender: null }));

  return (
    <ContactsStateContext.Provider value={state} {...props}>
      <ContactsDispatchContext.Provider value={dispatch} {...props} />
    </ContactsStateContext.Provider>
  );
}

function loadContacts(dispatch) {
  return addressBookClient.load().then((data) => {
    dispatch({ type: LOAD_CONTACTS, contacts: data });
    return data;
  });
}

function loadDefaultSender(dispatch) {
  return addressBookClient.loadDefaultSender().then((data) => {
    if (data && data.addressId) {
      dispatch({ type: LOAD_DEFAULT_CONTACT, defaultSender: data });
    }
  });
}

function updateContactsState({ dispatch, contact }) {
  dispatch({ type: UPDATE_CONTACTS_STATE, contact });
}

function loadInitContacts(dispatch) {
  return addressBookClient.loadInitContacts().then((data) => {
    dispatch({ type: LOAD_INIT_CONTACTS, contacts: data });
  });
}

function loadFilteredContacts({
  dispatch, filter, shipmentId = '', taskType = '',
}) {
  return addressBookClient.loadFilteredContacts(filter, shipmentId, taskType).then((data) => {
    dispatch({ type: LOAD_FILTERED_CONTACTS, contacts: data });
  });
}

function useContactsState() {
  const context = React.useContext(ContactsStateContext);
  if (context === undefined) {
    throw new Error('useContactsState must be used within a ContactsDataProvider');
  }
  return context;
}

function useContactsDispatch() {
  const context = React.useContext(ContactsDispatchContext);
  if (context === undefined) {
    throw new Error('useContactsDispatch must be used within a ContactsDataProvider');
  }
  return context;
}

export {
  ContactsDataProvider, useContactsState, useContactsDispatch,
  loadDefaultSender, updateContactsState,
  loadContacts, loadInitContacts, loadFilteredContacts,
};
