import React from 'react';
import { css } from 'emotion';
import {
  Grid,
  Typography,
} from '@material-ui/core';
import { Form, Formik } from 'formik';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as Yup from 'yup';
import * as PostalCodes from 'postal-codes-js';
import * as colors from '../styles/colors';
import NewOrderNextButton from './common/NewOrderNextButton';
import NewOrderNextButtonClear from './common/NewOrderNextButtonClear';
import AddressForm from './common/AddressForm';
import {
  NEW_ORDER_SENDER_RECIPIENT_STYLE,
} from '../styles/style';

const validationSchema = Yup.object().shape({

  addressLine1: Yup.string().required('Required'),
  city: Yup.string().required('Required'),
  state: Yup.string().required('Required'),
  zip: Yup.string()
    .test({
      name: 'is-valid-zip',
      message: 'Invalid postal code',
      test(value) {
        const { parent } = this;
        return PostalCodes.validate(parent.country, value) === true;
      },
    })
    .required('Required'),
  country: Yup.string().required('Required'),
});
export function ContactForm(props) {
  const classes = NEW_ORDER_SENDER_RECIPIENT_STYLE();
  const layoutClasses = {
    addressHeader: css({
      marginTop: 10,
    }),
    addressHeaderText: css({
      color: 'white',
      fontWeight: 500,
    }),
    inputsContainer: css({
      background: colors.newOrderModuleContentBackGround,
      padding: '10px 20px',
    }),
  };
  const { ...other } = props;
  return (
    <Grid
      container
      direction="column"
      className={layoutClasses.inputsContainer}
    >
      <Grid>
        <AddressForm
          fields={['name', 'companyName', 'email', 'phone']}
          other={{
            ...other,
            classes,
            overRideOptional: ['phone'],
          }}
        />
      </Grid>
      <Grid className={layoutClasses.addressHeader}>
        <Typography className={layoutClasses.addressHeaderText}>
          Address
        </Typography>
      </Grid>
      <Grid>
        <AddressForm
          fields={[
            'addressLine1',
            'addressLine2',
            'addressLine3',
          ]}
          other={{
            ...other,
            classes,
          }}
        />
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <AddressForm
              fields={['country']}
              other={{
                ...other,
                classes,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <AddressForm
              fields={['city']}
              other={{
                ...other,
                classes,
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <AddressForm
              fields={['state']}
              other={{
                ...other,
                classes,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <AddressForm
              fields={['zip']}
              other={{
                ...other,
                classes,
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default function DefaultAddress(props) {
  const {
    values, addDefaultAddress, updateDefaultAddress, type, setReadOnly, onClickLogout,
  } = props;

  const formikRef = React.useRef(null);

  const DEFAULT_FORM_VALUES = {
    country: '',
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    city: '',
    state: '',
    zip: '',
  };

  const classes = {
    dialogTitle: css({
      padding: '0 0',
      marginBottom: 15,
    }),
    dialogPaper: css({
      background: colors.newOrderFormBackground,
      width: 600,
      padding: '20px 30px',
      height: 'calc(100% - 96px)',
    }),
    dialogTitleText: css({
      fontSize: 18,
      color: colors.white,
      fontWeight: 500,
    }),
    dialogContent: css({
      padding: '0 0',
      overflow: 'hidden',
      height: 'inherit',
    }),
    submitButton: css({}),
    buttonsContainer: css({
      width: 270,
      margin: '20px 0px',
    }),
    innerDialogContentContainer: css({
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
      height: '100%',
      width: '100%',
    }),
    form: css({
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '100%',
      overflow: 'hidden',
    }),
    formInnerContainer: css({
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '100%',
      overflow: 'hidden',
      flexWrap: 'nowrap',
      alignItems: 'center',
    }),
    contactFormInnerContainer: css({
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      // overflow: 'scroll',
      height: '100%',
    }),
    progress: css({
      marginTop: '15px',
    }),
    paperFullScreen: css({
      width: '40%',
      height: '92%',
      position: 'absolute',
      right: 0,
      backgroundColor: '#1E202E',
      marginTop: '90px',
    }),
  };

  async function onSubmit(contact, { setSubmitting, resetForm }) {
    setSubmitting(true);
    if (type === 'EDIT') {
      await updateDefaultAddress(contact);
    } else {
      await addDefaultAddress(contact);
    }
    setSubmitting(false);
  }

  function render(renderProps) {
    const { values, submitForm, isSubmitting } = renderProps;
    return (
      <Form style={{ width: '100%' }}>
        {type === 'ADD' ? (
          <Typography className={classes.dialogTitleText}>
            Please confirm your default sender's address to continue..
          </Typography>
        ) : (
          null
        )}
        <Grid
          direction="column"
          className={classes.innerDialogContentContainer}
        >
          <Grid
            container
            direction="column"
            alignItems="center"
            className={classes.formInnerContainer}
          >
            <Grid
              item
              container
              className={classes.contactFormInnerContainer}
            >
              <ContactForm
                country={values.country}
                disabled={isSubmitting
                  ? [
                    'name',
                    'companyName',
                    'email',
                    'phone',
                    'country',
                    'addressLine1',
                    'addressLine2',
                    'addressLine3',
                    'city',
                    'state',
                    'zip',
                  ]
                  : []}
                formikRef={formikRef}
              />
            </Grid>
            {isSubmitting ? (
              <CircularProgress
                color="secondary"
                className={classes.progress}
              />
            ) : (
              <Grid
                item
                container
                justify="space-between"
                className={classes.buttonsContainer}
              >
                { type === 'EDIT' ? (
                  <NewOrderNextButtonClear
                    disabled={isSubmitting}
                    className={classes.submitButton}
                    onClick={setReadOnly}
                  >
                    Cancel
                  </NewOrderNextButtonClear>
                )
                  : (
                    <NewOrderNextButtonClear
                      disabled={isSubmitting}
                      className={classes.submitButton}
                      onClick={onClickLogout}
                    >
                      Log out
                    </NewOrderNextButtonClear>
                  ) }
                <NewOrderNextButton
                  disabled={isSubmitting}
                  className={classes.submitButton}
                  onClick={submitForm}
                >
                  Save
                </NewOrderNextButton>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Form>
    );
  }

  return (
    <Formik
      render={render}
      validateOnBlur={false}
      initialValues={values || DEFAULT_FORM_VALUES}
      onSubmit={onSubmit}
      ref={formikRef}
      validationSchema={validationSchema}
      enableReinitialize
    />

  );
}
