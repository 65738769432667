import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import {
  Grid, Typography, MenuItem,
} from '@material-ui/core';
import CustomSelectTextField from '../../common/CustomSelectTextField';
import NewOrderCustomTextField from '../../common/NewOrderCustomTextField';
import { FORM_FIELDS, FORM_LABELS } from '../itemDetailsConstants';
import { isFieldEditable } from '../itemDetailsUtil';
import { useItemProductDetailsFormStyles } from '../itemDetailsStyles';

export default function FreightOnly({ editableFields, freightClasses }) {
  const classes = useItemProductDetailsFormStyles();

  return (
    <Grid item container direction="column" spacing={1}>
      <Grid item>
        <Typography color="primary" className={classes.formFieldHeader}>{FORM_LABELS.freightOnly}</Typography>
      </Grid>
      <Grid item container spacing={1}>
        <Grid item xs={5}>
          <Field
            name={FORM_FIELDS.freightClass.key}
            label={FORM_FIELDS.freightClass.label}
            component={CustomSelectTextField}
            type="text"
            style={{ width: '100%' }}
            disabled={isFieldEditable(FORM_FIELDS.freightClass.key, editableFields)}
          >
            {freightClasses && freightClasses.map((option) => (
              <MenuItem
                key={option.type}
                value={option.type}
              >
                {option.type}
              </MenuItem>
            ))}
          </Field>
        </Grid>
        <Grid item xs={5}>
          <Field
            name={FORM_FIELDS.nmfcCodeNumber.key}
            label={FORM_FIELDS.nmfcCodeNumber.label}
            component={NewOrderCustomTextField}
            type="text"
            style={{ width: '100%' }}
            disabled={isFieldEditable(FORM_FIELDS.nmfcCodeNumber.key, editableFields)}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

FreightOnly.propTypes = {
  editableFields: PropTypes.arrayOf(PropTypes.string).isRequired,
  freightClasses: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
};
