import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import PropTypes from 'prop-types';
import { css } from 'emotion';
import * as colors from '../../styles/colors';

export default function ScrollWindow({
  children, classes, style, autoHide, ...others
}) {
  const [scrollTop, setScrollTop] = React.useState(0);
  const [thumbHeight, setThumbHeight] = React.useState(50);

  function handleUpdate(values) {
    const { top, clientHeight, scrollHeight } = values;
    const newThumbHeight = Math.round((clientHeight / scrollHeight) * clientHeight);
    const newScrollTop = Math.round(top * (clientHeight - newThumbHeight));
    setScrollTop(newScrollTop);
    setThumbHeight(newThumbHeight);
  }

  const defaultClasses = {
    trackHorizontal: css({
      display: 'none',
    }),
    thumbHorizontal: css({
      display: 'none',
    }),
    trackVertical: css({
      width: 4,
      borderRadius: 25,
      backgroundColor: 'transparent',
      right: 2,
      display: 'block',
      height: '100%',
      top: '0',
    }),
    thumbVertical: css({
      width: 4,
      borderRadius: 25,
      backgroundColor: colors.newOrderFormBackground,
      transform: 'none',
    }),
    view: css({
      '&::-webkit-scrollbar': {
        width: 0,
        height: 0,
      },
      scrollbarWidth: 'none', /* Firefox */
      '-ms-overflow-style': 'none', /* Internet Explorer 10+ */
      maxWidth: '100%',
      maxHeight: '100%',

    }),
    root: css({
      maxWidth: '100%',
      maxHeight: '100%',
    }),
  };

  return (
    <Scrollbars
      className={`${defaultClasses.root} ${classes.root || ''}`}
      autoHeight={false}
      style={{
        ...((classes.root)
          ? { ...style, height: 'invalid' }
          : { ...style }),
      }}
      renderTrackHorizontal={(trhProps) => (
        <div
          {...trhProps}
          className={`track-horizontal ${defaultClasses.trackHorizontal} ${classes.trackHorizontal || ''}`}
        />
      )}
      renderTrackVertical={(trvProps) => (
        <div
          {...trvProps}
          className={`track-vertical ${defaultClasses.trackVertical} ${classes.trackVertical || ''}`}
          style={{
            ...(trvProps.style
              ? { ...trvProps.style }
              : {}),
            display: 'block',
          }}
        />
      )}
      renderThumbHorizontal={(thhProps) => (
        <div
          {...thhProps}
          className={`thumb-horizontal ${defaultClasses.thumbHorizontal} ${classes.thumbHorizontal || ''}`}
        />
      )}
      renderThumbVertical={(thvProps) => (
        <div
          {...thvProps}
          className={`thumb-vertical ${defaultClasses.thumbVertical} ${classes.thumbVertical || ''}`}
          style={{
            ...(thvProps.style
              ? { ...thvProps.style }
              : {}),
            transform: `translateY(${scrollTop}px)`,
            height: thumbHeight,
          }}
        />
      )}
      renderView={(vProps) => (
        <div
          {...vProps}
          className={`${defaultClasses.view} ${classes.view || ''}`}
        />
      )}
      {...others}
      autoHide={autoHide}
      onUpdate={handleUpdate}
    >
      {children}
    </Scrollbars>
  );
}

ScrollWindow.defaultProps = {
  children: null,
  style: {},
  classes: {},
  autoHide: true,
};

ScrollWindow.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
    PropTypes.node,
  ]),
  style: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  classes: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  autoHide: PropTypes.bool,
};
