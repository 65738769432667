import React from 'react';
import PropTypes from 'prop-types';
import LicenseCertification from './Certification';
import LicensePermitNumber from './PermitNumber';

export default function LicenseDetermination({
  classes,
  formikProps,
  editableFields,
  licensePermitTypes,
}) {
  return (
    <>
      <LicenseCertification
        classes={classes}
        formikProps={formikProps}
      />
      {
        formikProps?.values?.isLicensePermitRequired === 'yes' && (
          <LicensePermitNumber
            formikProps={formikProps}
            editableFields={editableFields}
            licensePermitTypes={licensePermitTypes}
          />
        )
      }
    </>
  );
}

LicenseDetermination.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
  editableFields: PropTypes.arrayOf(PropTypes.string).isRequired,
  formikProps: PropTypes.objectOf(PropTypes.any).isRequired,
  licensePermitTypes: PropTypes.arrayOf(PropTypes.any).isRequired,
};
