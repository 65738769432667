import React from 'react';
import * as miscClient from '../utils/miscClient';
import { useLogger } from '../utils/hooks';

const miscState = {
  countries: [], states: {}, costAllocation: null, hazmatClassOptions: [],
};
const MiscStateContext = React.createContext();
const MiscDispatchContext = React.createContext();

const LOAD_SERVICES = 'LOAD SERVICES';
const LOAD_PROFILES = 'LOAD PROFILES';
const LOAD_ORDER_PURPOSE_OPTIONS = 'LOAD ORDER PURPOSE OPTIONS';
const LOAD_COUNTRIES = 'LOAD COUNTRIES';
const LOAD_COUNTRY_STATE = 'LOAD COUNTRY STATE';
const LOAD_ACCOUNT_COST_ALLOCATION = 'LOAD ACCOUNT COST ALLOCATION';
const LOAD_FREIGHT_TYPE_OPTIONS = 'LOAD FREIGHT TYPE OPTIONS';
const LOAD_HAZMAT_OPTIONS = 'LOAD HAZMAT OPTIONS';
const LOAD_HAZMAT_CLASS_OPTIONS = 'LOAD HAZMAT CLASS OPTIONS';
const LOAD_SHIPMENT_FREIGHT_TYPE_OPTIONS = 'LOAD SHIPMENT FREIGHT TYPE OPTIONS';
const LOAD_TERMS_OF_SALE = 'LOAD TERMS OF SALE';
const LOAD_PARTY_TERMS = 'LOAD PARTY TERMS';
const LOAD_SHIPMENT_PURPOSE_OPTIONS = 'LOAD SHIPMENT PURPOSE OPTIONS';
const LOAD_SHIPMENT_LICENSE_TYPES = 'LOAD SHIPMENT LICENSE TYPES';

function miscReducer(state, action) {
  switch (action.type) {
    case LOAD_SERVICES: {
      return { ...state, services: action.data };
    }
    case LOAD_PROFILES: {
      return { ...state, profiles: action.data };
    }
    case LOAD_ORDER_PURPOSE_OPTIONS: {
      return { ...state, orderPurposes: action.data };
    }
    case LOAD_COUNTRIES: {
      return { ...state, countries: action.data };
    }
    case LOAD_COUNTRY_STATE: {
      return { ...state, states: { ...state.states, ...action.data } };
    }
    case LOAD_ACCOUNT_COST_ALLOCATION: {
      return { ...state, costAllocation: action.data };
    }
    case LOAD_FREIGHT_TYPE_OPTIONS: {
      return { ...state, freightTypeOptions: action.data };
    }
    case LOAD_HAZMAT_OPTIONS: {
      return { ...state, hazmatOptions: action.data };
    }
    case LOAD_HAZMAT_CLASS_OPTIONS: {
      return { ...state, hazmatClassOptions: action.data };
    }
    case LOAD_SHIPMENT_FREIGHT_TYPE_OPTIONS: {
      return { ...state, shipmentFreightTypes: action.data };
    }
    case LOAD_TERMS_OF_SALE: {
      return { ...state, shipmentTermsOfSale: action.data };
    }
    case LOAD_PARTY_TERMS: {
      return { ...state, shipmentPartyTerms: action.data };
    }
    case LOAD_SHIPMENT_PURPOSE_OPTIONS: {
      return { ...state, shipmentPurposeOptions: action.data };
    }
    case LOAD_SHIPMENT_LICENSE_TYPES: {
      return { ...state, licensePermitTypes: action.data };
    }
    default: {
      throw new Error(`Unhandled action type in miscReducer: ${action.type}`);
    }
  }
}

function MiscDataProvider(props) {
  const [state, dispatch] = useLogger(
    React.useReducer(miscReducer, miscState || { countries: [], states: {}, costAllocation: null }),
  );
  React.useEffect(() => {
    loadCountries(dispatch);
  }, [dispatch]);

  React.useEffect(() => {
    loadCountries(dispatch);
  }, []);

  return (
    <MiscStateContext.Provider value={state} {...props}>
      <MiscDispatchContext.Provider value={dispatch} {...props} />
    </MiscStateContext.Provider>
  );
}

function useMiscState() {
  const context = React.useContext(MiscStateContext);
  if (context === undefined) throw new Error('useMiscState must be used within a MiscDataProvider');

  return context;
}

function useMiscDispatch() {
  const context = React.useContext(MiscDispatchContext);
  if (context === undefined) throw new Error('useMiscDispatch must be used within a MiscDataProvider');

  return context;
}

async function loadCountries(dispatch) {
  try {
    const data = await miscClient.loadCountries();
    dispatch({ type: LOAD_COUNTRIES, data });
  } catch (e) {
    console.error(loadCountries, e.message);
  }
}

async function loadAdditionalServices(dispatch) {
  try {
    const data = await miscClient.loadAdditionalServices();
    dispatch({ type: LOAD_SERVICES, data });
  } catch (e) {
    console.error(loadAdditionalServices, e.message);
  }
}

async function loadProfiles(dispatch) {
  try {
    const data = await miscClient.loadProfiles();
    dispatch({ type: LOAD_PROFILES, data });
  } catch (e) {
    console.error(loadProfiles, e.message);
  }
}

async function loadOrderPurposes(dispatch) {
  try {
    const data = await miscClient.loadOrderPurposes();
    dispatch({ type: LOAD_ORDER_PURPOSE_OPTIONS, data });
  } catch (e) {
    console.error(loadOrderPurposes, e.message);
  }
}

async function loadFreightTypeOptions(dispatch) {
  try {
    const data = await miscClient.loadFreightTypeOptions();
    const reformattedData = data.map((obj) => ({ type: obj.freightType, label: obj.description }));
    dispatch(({ type: LOAD_FREIGHT_TYPE_OPTIONS, data: reformattedData }));
  } catch (e) {
    console.error(loadFreightTypeOptions, e.message);
  }
}

async function loadHazmatOptions(dispatch) {
  try {
    const data = await miscClient.loadHazmatOptions();

    const reformattedData = data.map((obj) => ({
      type: obj.hazmatOption,
      label: obj.description,
      hazmatClasses: obj.hazmatClasses,
    }));

    dispatch({ type: LOAD_HAZMAT_OPTIONS, data: reformattedData });
  } catch (e) {
    console.error(loadHazmatOptions, e.message);
  }
}

async function loadHazmatClassOptions(dispatch) {
  try {
    const data = await miscClient.loadHazmatClassOptions();
    const reformattedData = data.map((obj) => ({
      type: obj.hazmatType,
      label: obj.description,
      sublabel: obj.hazmatClass,
    }));
    dispatch({ type: LOAD_HAZMAT_CLASS_OPTIONS, data: reformattedData });
  } catch (e) {
    console.error(loadFreightTypeOptions, e.message);
  }
}

async function loadShipmentFreightTypes(dispatch) {
  try {
    const data = await miscClient.loadShipmentFreightTypes();
    const reformattedData = data.map((obj) => ({ value: obj.freightType, label: obj.description }));
    dispatch(({ type: LOAD_SHIPMENT_FREIGHT_TYPE_OPTIONS, data: reformattedData }));
  } catch (e) {
    console.error(loadShipmentFreightTypes, e.message);
  }
}

async function loadTermsOfSale(dispatch) {
  try {
    const data = await miscClient.loadTermsOfSale();
    const reformattedData = data.map((obj) => ({ value: obj.type, label: obj.description, terms: obj.terms }));
    dispatch(({ type: LOAD_TERMS_OF_SALE, data: reformattedData }));
  } catch (e) {
    console.error(loadTermsOfSale, e.message);
  }
}

async function loadPartyTerms(dispatch) {
  try {
    const data = await miscClient.loadPartyTerms();
    const reformattedData = data.map((obj) => ({ value: obj.type, label: obj.description }));
    dispatch(({ type: LOAD_PARTY_TERMS, data: reformattedData }));
  } catch (e) {
    console.error(loadPartyTerms, e.message);
  }
}

async function loadShipmentPurposeOptions(dispatch) {
  try {
    const data = await miscClient.loadShipmentPurposeOptions();
    const reformattedData = data.map((obj) => ({ value: obj.type, label: obj.description }));
    dispatch(({ type: LOAD_SHIPMENT_PURPOSE_OPTIONS, data: reformattedData }));
  } catch (e) {
    console.error(loadShipmentPurposeOptions, e.message);
  }
}

async function loadLicensePermitTypes(dispatch) {
  try {
    const data = await miscClient.loadLicensePermitTypes();
    const reformattedData = data.map((obj) => ({ value: obj.type, label: obj.description }));
    dispatch(({ type: LOAD_SHIPMENT_LICENSE_TYPES, data: reformattedData }));
  } catch (e) {
    console.error(loadLicensePermitTypes, e.message);
  }
}

async function loadCountryStates(dispatch, country) {
  try {
    const data = await miscClient.loadCountryStates(country);
    const formattedData = {};
    formattedData[country] = data;

    dispatch({ type: LOAD_COUNTRY_STATE, data: formattedData });
  } catch (e) {
    console.error(loadCountryStates, e.message);
  }
}

function getCountryDescription(countries, countryCode) {
  if (!countries) return countryCode;

  const filter = countries.filter((country) => (country.countryCode === countryCode));

  return (filter.length === 1) ? filter[0].description : countryCode;
}

export {
  MiscDataProvider,
  useMiscState,
  useMiscDispatch,
  loadCountries,
  loadCountryStates,
  getCountryDescription,
  loadFreightTypeOptions,
  loadShipmentPurposeOptions,
  loadShipmentFreightTypes,
  loadLicensePermitTypes,
  loadTermsOfSale,
  loadPartyTerms,
  loadAdditionalServices,
  loadProfiles,
  loadHazmatOptions,
  loadHazmatClassOptions,
  loadOrderPurposes,
};
