/* eslint-disable no-underscore-dangle */
import shortId from 'shortid';
import moment from 'moment';
import {
  has, omitBy, isEmpty,
} from 'lodash';
import {
  NEW_ORDER_TAB_STRINGS, CARRIER, ADMIN_USERS, EHS, ECO, ESG_ADMIN,
} from '../clientConstants';

const {
  sender, recipient, regulationAndCompliance,
  billingAndPayment, billingAndPaymentCustoms, billingAndPaymentTaxes,
  packagingAndContents, itemAndProductDetails, shippingDetails,
  schedulePickup, aes, exportCompliance, customsBroker,
} = NEW_ORDER_TAB_STRINGS;

// eslint-disable-next-line max-len
export const addKeyFieldToArrayElements = (arr) => arr.map((el) => ({ ...el, key: shortId.generate() }));

export function isInternational(shipmentObj) {
  return shipmentObj?.shippingType === 'international';
}

export const getMatchingObjectFromArray = (arrayOfObjects, fieldName, fieldValue) => {
  const result = arrayOfObjects.find((obj) => obj[fieldName] === fieldValue);

  return result;
};

const generateDateMonthString = (date) => moment(date).format('MMM DD');

export function isCustomsBrokerAvailable(shipmentObj) {
  return !!(shipmentObj?.customsBroker?.isCustomsBrokerAvailable);
}

export function hasExportCompliance(shipmentObj) {
  return has(shipmentObj, 'exportCompliance.details');
}

export function isProductPaneRequired({ shippingType, freightType }) {
  return (!(/document/i.test(freightType)) && (/international/i.test(shippingType))) || (/hazmat/i.test(freightType));
}

// If you are adding collaboration to a new panel,
// you need to add that panel in this object
export const formNameMap = {
  packaging: packagingAndContents,
  billing: billingAndPayment,
  billingCustoms: billingAndPaymentCustoms,
  billingTaxes: billingAndPaymentTaxes,
  carrier: shippingDetails,
  sender,
  recipient,
  customsBroker,
  shippingDocuments: 'document',
  compliance: regulationAndCompliance,
  product: itemAndProductDetails,
  pickupFrom: schedulePickup,
  aesFiling: aes,
  exportCompliance,
};

// this is used to map the name of each form panel
// to the taskType string set by the backend
export const formNameToTaskType = {
  packaging: packagingAndContents,
  billing: billingAndPayment,
  billingCustoms: billingAndPaymentCustoms,
  billingTaxes: billingAndPaymentTaxes,
  carrier: shippingDetails,
  sender,
  recipient,
  customsBroker,
  shippingDocuments: 'document',
  compliance: regulationAndCompliance,
  product: itemAndProductDetails,
  pickupFrom: 'pickupfrom',
  aesFiling: 'aesfiling',
  exportCompliance: 'exportcompliance',
  shippingDocuments: 'customsClearance',
};

export const reverseTaskTypeToFormName = Object
  .entries(formNameToTaskType)
  .reduce((obj, [key, val]) => ({ ...obj, [val]: key }), {});

// export const reverseFormNameMap = Object
//   .entries(formNameMap)
//   .reduce((obj, [key, val]) => ({ ...obj, [val]: key }), {});

// eslint-disable-next-line max-len
export const isShipmentLate = (expectedDeliveryDate) => moment(expectedDeliveryDate).local() < moment.now();

export const generateShipmentCardMessage = (shipment) => {
  const { status, isPendingTask, assignedTo } = shipment;

  if (status === 'Created' && isPendingTask) {
    return 'Incomplete. Pending task.';
  }
  // Incomplete shipment (with assigned task);
  if (status === 'Created'
    && assignedTo) {
    return `Created - Incomplete. Pending ${shipment.taskType.toLowerCase()} task.\nAssigned to ${shipment.assignedTo}.`;
  }

  if (shipment?.status === 'Created' && shipment?.collaborators?.length > 1) {
    return 'Created - Incomplete. Shipment Approved.\nClick Edit to continue';
  }
  // Incomplete shipment (without assigned task);
  if (shipment?.status === 'Created') {
    return 'Created - Incomplete.';
  }

  // In-progress
  // if (shipment.status === 'In-Transit') {
  //   return `${isShipmentLate(shipment.expectedDeliveryDateTime) ? 'Behind schedule' : 'On time'}.
  //   Shipped ${generateDateMonthString(shipment.actualShipDateTime)}.
  //   Expected delivery ${generateDateMonthString(shipment.expectedDeliveryDateTime)}.`;
  // }

  if (shipment.status === 'In-Transit') {
    return `In-Transit. Expected delivery ${generateDateMonthString(shipment.expectedDeliveryDateTime)}.`;
  }

  // Scheduled for pickup
  if (shipment.status === 'Scheduled For Pickup'
    && shipment.expectedPickupDateTime) {
    return `Scheduled for pickup ${generateDateMonthString(shipment.expectedPickupDateTime)}.
    Expected delivery ${generateDateMonthString(shipment.expectedDeliveryDateTime)}.`;
  }

  // Submitted shipment
  if (shipment.status === 'Completed') {
    return `Completed. Expected delivery ${generateDateMonthString(shipment.expectedDeliveryDateTime)}.`;
  }

  // Completed (deliverec) shipment
  if (shipment.status === 'Delivered') {
    return `Delivered ${generateDateMonthString(shipment.actualDeliveryDateTime)}.`;
  }

  // Default - display the shipment status
  return `${shipment.status.charAt(0)}${shipment.status.slice(1).toLowerCase()}`;
};

export function determineStatusClass(shipment) {
  switch ((shipment.status && shipment.status.toUpperCase()) || '') {
    case 'COMPLETED':
      return { statusClass: 'onTimeHeading', borderClass: 'onTimeBorder' };
    case 'IN-TRANSIT':
      return isShipmentLate(shipment.expectedDeliveryDateTime)
        ? { statusClass: 'delayedHeading', borderClass: 'delayedBorder' }
        : { statusClass: 'onTimeHeading', borderClass: 'onTimeHeading' };
    case 'SCHEDULED FOR PICKUP':
      return { statusClass: 'delayedHeading', borderClass: 'delayedBorder' };
    // case 'DELAYED':
    //   return { statusClass: 'delayedHeading', borderClass: 'delayedBorder' };
    case 'CREATED':
      return {
        statusClass: 'incompleteHeading',
        borderClass: 'incompleteBorder',
      };
    case 'COMPLIANCE FAILURE':
      return {
        statusClass: 'incompleteHeading',
        borderClass: 'incompleteBorder',
      };
    case 'DELIVERED':
      return {
        statusClass: 'deliveredHeading',
        borderClass: 'deliveredBorder',
      };
    default:
      return {
        statusClass: '',
        borderClass: '',
      };
  }
}

export const getPackageNumber = (id) => {
  const numArr = id.split('-');
  const num = numArr[numArr.length - 1];
  const formattedNum = `${Number(num)}`;
  return formattedNum;
};

export function formatDateLong(date) {
  const momentDate = moment(date);
  return momentDate.format('DD MMM YYYY, h:mm A');
}

export function formatDateShort(date) {
  const momentDate = moment(date);
  return momentDate.format('DD MMM YYYY');
}

export function getCurrencySymbol(currency) {
  switch (currency) {
    case 'USD': {
      return '$';
    }
    default: return '';
  }
}

export function formatMonetaryAmount(currencyCode = '', amount = '') {
  const currencyCodeMap = {
    USD: '$',
  };
  const currencySymbol = currencyCodeMap[currencyCode] || '';
  return `${currencySymbol}${amount}`;
}

export function getPurposeDisplay(purpose) {
  const purposeMap = {
    work: 'Business',
    personal: 'Personal',
  };

  return purposeMap[purpose.toLowerCase()] || '';
}

export class PromiseOverwriter {
  constructor(fn) {
    this._stopPromise = '';
    this._currentPromise = '';
    if (fn) this._fn = fn;
    if (fn) {
      return (...args) => {
        const promiseInstance = this;
        const prom = this._fn;
        const returnPromise = new Promise((resolve, _) => {
          const promiseId = shortId();
          promiseInstance.currentPromise = promiseId;
          prom(...args).then((data) => {
            const curPromise = promiseInstance.currentPromise;
            if (promiseId === curPromise) {
              resolve(data);
            }
            resolve(null);
          });
        }).catch((err) => {
          console.log(err);
        });
        return returnPromise;
      };
    }
  }

  set currentPromise(id) {
    this._currentPromise = id;
  }

  get currentPromise() {
    return this._currentPromise;
  }

  overwrite(fn) {
    const promiseInstance = this;
    const prom = fn;
    const returnPromise = new Promise((resolve, _) => {
      const promiseId = shortId();
      promiseInstance.currentPromise = promiseId;
      prom.then((data) => {
        const curPromise = promiseInstance.currentPromise;
        if (promiseId === curPromise) {
          resolve(data);
        }
        resolve(null);
      });
    }).catch((err) => {
      console.log(err);
    });
    return returnPromise;
  }
}

export function combineAddress(addressObj = {}) {
  return `${[
    addressObj.addressLine1,
    addressObj.addressLine2,
    addressObj.addressLine3,
    addressObj.city,
    addressObj.state,
  ]
    .filter((v) => !!v)
    .join(', ')}${addressObj.zip ? ` ${addressObj.zip}` : ''}`;
}

export function stripAddress(addressStr = '') {
  return addressStr.replace(/,.*/, '');
}

export function createAddressArray(addressStr = '') {
  const addressArr = addressStr.split(/,\s*/);
  const stateZip = addressArr.length > 2 ? addressArr[addressArr.length - 1] : '';

  const zipRegex = /\d\d\d\d\d/;
  const zipMatch = stateZip.match(zipRegex);

  if (zipMatch) {
    const stateZipWithoutZip = stateZip.replace(zipRegex, '').trim();
    if (stateZipWithoutZip) {
      addressArr.push(zipMatch[0]);
      addressArr[addressArr.length - 2] = stateZip.replace(zipRegex, '').trim();
    }
  }
  return addressArr;
}

export const splitCamelCase = (str) => str
  .replace(/([a-z])([A-Z])/g, (match, p1, p2) => [p1, p2].join(' '))
  .replace(/^([a-z])/, (_, p1) => p1.toUpperCase());

export function deconstructAddress(addressStr = '') {
  const addressArr = createAddressArray(addressStr);

  const order = [
    'addressLine1',
  ].slice(0, Math.max(0, addressArr.length))
    .concat([
      'addressLine2',
      'addressLine3',
    ].slice(0, Math.max(0, addressArr.length - 4)))
    .concat([
      'city',
      'state',
      'zip',
    ].slice(0, Math.max(0, addressArr.length - 1)));

  const emptyAddressObj = {
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    city: '',
    state: '',
    zip: '',
  };

  return order.reduce((acc, v, i) => ({
    ...acc,
    [v]: addressArr[i],
  }), emptyAddressObj);
}

export function sanitizeAddress(address) {
  if (address.name) {
    // eslint-disable-next-line no-param-reassign
    address.name = address?.name?.replace(/\d+([,.]\d+)?/g, '');
  }
  const sanitizedAddress = omitBy(address, (val) => isEmpty(val) && !(typeof val === 'boolean'));
  delete sanitizedAddress.latitude;
  delete sanitizedAddress.longitude;
  delete sanitizedAddress.updateContacts;
  delete sanitizedAddress.isActive;
  delete sanitizedAddress.isAccountAddress;
  delete sanitizedAddress.address;
  return sanitizedAddress;
}

export function sanitizeAddresses(addresses) {
  const sanitizedAddresses = addresses.map((address) => {
    if (address.name) {
      // eslint-disable-next-line no-param-reassign
      address.name = address?.name?.replace(/\d+([,.]\d+)?/g, '');
    }
    const sanitizedAddress = omitBy(
      address,
      (val) => isEmpty(val) && !(typeof val === 'boolean'),
    );
    delete sanitizedAddress.latitude;
    delete sanitizedAddress.longitude;
    delete sanitizedAddress.updateContacts;
    delete sanitizedAddress.isActive;
    delete sanitizedAddress.isAccountAddress;
    delete sanitizedAddress.address;
    return sanitizedAddress;
  });
  return sanitizedAddresses;
}

export function disableSubmitOnEnterKey(keyEvent) {
  if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
    keyEvent.preventDefault();
  }
}

export function getLabel(type, options) {
  if (Array.isArray(options)) {
    const label = options.find((obj) => obj?.value === type);
    return label?.label || '';
  }
  return '';
}

export function allowCollaborationOnExportComplianceCheckPane(shipment) {
  return has(shipment, 'exportCompliance')
    && !shipment?.exportCompliance?.details?.overrideExportCompliance
    && !shipment?.exportCompliance?.details?.isECOApproved
    && !shipment?.exportCompliance?.details?.success;
}

export function hasCarrierOnShipment(shipment) {
  return has(shipment, `${CARRIER}.details`);
}

export function allowBillingPayment(shipment) {
  // if export compliance check pane is shown, check to see if it has been completed
  // otherwise, return true
  const exportComplianceCheckPaneComplete = true;
  // isComplianceCheckShown(shipment)
  //   ? (shipment?.exportCompliance?.details?.overrideExportCompliance
  //   || shipment?.exportCompliance?.details?.isECOApproved
  //   || shipment?.exportCompliance?.details?.success)
  //   : true;

  return exportComplianceCheckPaneComplete && hasCarrierOnShipment(shipment);
}

export function getBillingPaymentLabel(label, charges = []) {
  const labels = {
    billing: 'Freight',
    billingCustoms: 'Customs',
    billingTaxes: 'Taxes',
  };
  const labelCharges = charges.map(({ chargeType }) => labels[chargeType]).join(', ');
  return `${label}${(labelCharges) ? ` - ${labelCharges}` : ''}`;
}

export function formatShipmentFilterChipText(filterType, filterValues) {
  const label = `${splitCamelCase(filterType)}: `;

  // if filterType is 'startDate' or 'endDate'
  if (filterType === 'startDate' || filterType === 'endDate') {
    return label + formatDateShort(filterValues).toUpperCase();
  }

  const isArray = filterValues instanceof Array;
  const isPurposeType = filterType === 'purpose';
  let purposeValue = '';

  if (isArray && isPurposeType) purposeValue = filterValues.map(getPurposeDisplay).join(', ');
  else if (isPurposeType) purposeValue = getPurposeDisplay(filterValues);

  const otherValue = isArray && !isPurposeType ? filterValues.join(', ') : filterValues;
  const value = isPurposeType ? purposeValue : otherValue;

  return label + value;
}

export function isAdmin(userType) {
  return ADMIN_USERS.includes(userType) || ['development', 'test'].includes(process.env.NODE_ENV);
}

export function isESGAdmin(userType) {
  return userType === ESG_ADMIN || ['development', 'test'].includes(process.env.NODE_ENV);
}

export function isAdminOrEHS(userType) {
  return isAdmin(userType) || userType === EHS;
}

export function isAdminOrECO(userType) {
  return isAdmin(userType) || userType === ECO;
}

export function isEHS(userType) {
  return userType === EHS || ['development', 'test'].includes(process.env.NODE_ENV);
}

export function isECO(userType) {
  return userType === ECO || ['development', 'test'].includes(process.env.NODE_ENV);
}
