/* global clearTimeout */
import { useState, useEffect, useMemo } from 'react';

import { GET_PAGE_PARAMS } from '../clientConstants';
import useGetParameter from './useGetParameter';

let timeout;

export default () => {
  const constraintName = useGetParameter(GET_PAGE_PARAMS.constraint);
  const [mountedConstraint, setMountedConstraint] = useState(constraintName);
  useEffect(() => {
    if (constraintName) {
      timeout && clearTimeout(timeout);
      setMountedConstraint(constraintName);
    } else {
      timeout = setTimeout(() => {
        setMountedConstraint(null);
      }, 300);
    }
  }, [constraintName]);

  useEffect(() => () => {
    timeout && clearTimeout(timeout);
  }, []);

  const isOpened = useMemo(() => Boolean(constraintName), [constraintName]);

  return {
    mountedConstraint,
    isOpened,
  };
};
