import React from 'react';
import { Grid, InputLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as braintree from 'braintree-web';
import PropTypes from 'prop-types';
import * as colors from '../../styles/colors';
import { CustomReadOnlyTextField } from './InputComponents';

const visaImage = require('../images/visa.svg');
const mastercardImage = require('../images/mastercard.svg');
const amexImage = require('../images/amex.svg');
const dinersClubImage = require('../images/diners-club.svg');
const discoverImage = require('../images/discover.svg');
const jcbImage = require('../images/jcb.svg');
const maestroImage = require('../images/maestro.svg');
const unionpayImage = require('../images/unionpay.svg');

const containerStyles = {
  base: {
    backgroundColor: '#323546!important',
    height: 56,
    borderRadius: '4px 4px 0 0',
    '&:hover': {
      backgroundColor: '#1E202E',
      transition: 'background-color .1s',
    },
    position: 'relative',
    boxSizing: 'border-box',
    transition: 'font-size .1s, background-color .1s',
    colorScheme: 'auto',
  },
  invalid: {},
  valid: {},
  focused: {
    backgroundColor: '#1f2230',
  },
  input: {
    height: '100%',
    position: 'absolute',
    width: '100%',
  },
  adornment: {
    height: '100%',
    width: 'auto',
    position: 'absolute',
    right: '12px',
  },
  disabled: {
    backgroundColor: '#151721',
  },
};

const labelStyles = () => ({
  notEmpty: {
    '& span': {
      transform: 'translate(calc(-.25 * 50%), calc(-.25 * 50% - 8px)) scale(0.75) !important',
      transition: 'transform .1s',
    },
  },
  base: {
    position: 'absolute',
    top: '0px',
    left: '0px',
    color: 'inherit',
    fontSize: '16px',
    lineHeight: '1',
    height: '100%',
    width: '100%',
    fontWeight: '500',
    '& span': {
      position: 'absolute',
      left: '13px',
      top: '18px',
      transform: 'translate(0, 0) scale(1)',
      transition: 'transform .1s',
    },
    cursor: 'text',
    '&:after': {
      content: '""',
      height: '2px',
      width: '100%',
      background: '#2B79D3',
      transform: 'scaleX(0)',
      position: 'absolute',
      bottom: '0px',
      left: '0px',
      transition: 'transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
    },
  },
  focused: {
    '& span': {
      transform: 'translate(calc(-.25 * 50%), calc(-.25 * 50% - 8px)) scale(0.75) !important',
      transition: 'transform .1s',
    },
    '&:after': {
      content: '""',
      height: '2px',
      width: '100%',
      background: '#2B79D3',
      position: 'absolute',
      bottom: '0px',
      left: '0px',
      transform: 'scaleX(1)',
      transition: 'transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
    },
  },
  invalid: {
    color: '#F44335',
    '&:after': {
      content: '""',
      height: '2px',
      width: '100%',
      background: '#F44335 !important',
      position: 'absolute',
      bottom: '0px',
      left: '0px',
      transform: 'scaleX(1)',
    },
  },
  valid: {
    color: colors.white,
  },
});

const instanceStyles = {
  input: {
    color: colors.white,
    'font-size': '16px',
    'font-family': 'Helvetica',
    'font-weight': '500',
    'letter-spacing': '.1px',
    'padding-left': '12px',
    'padding-bottom': '7px',
    'padding-top': '25px',
    width: '100%',
  },
};

const useContainerStyles = makeStyles(containerStyles);
const useLabelStyles = makeStyles(labelStyles);
const useInstanceStyles = makeStyles(instanceStyles);

export default function CreditCardHostedForm(props) {
  const {
    setHostedFieldsInstance,
    clientInstance,
    disabled,
    updateExistingPayment,
    fieldOptions = {},
  } = props;

  const [hostedFieldsInstance, setLocalHostedFieldsInstance] = React.useState();

  const [card, setCard] = React.useState(null);
  const instanceClasses = useInstanceStyles();

  const onCardTypeChange = (e) => {
    const { cards } = e;
    if (cards.length === 1) {
      const { type, niceType } = cards[0];
      setCard({ type, niceType });
    } else {
      setCard(null);
    }
  };

  React.useEffect(() => {
    if (!clientInstance) return;
    const options = {
      client: clientInstance,
      styles: {
        input: instanceClasses.input,
      },
      fields: {
        number: {
          selector: '#card-number',
          placeholder: '',
          internalLabel: 'Card Number',
          ...fieldOptions.number,
        },
        cvv: {
          selector: '#card-cvv',
          placeholder: '',
          internalLabel: 'CVV',
          ...fieldOptions.cvv,
        },
        expirationDate: {
          selector: '#card-exp-date',
          placeholder: '',
          internalLabel: 'Exp Date',
          ...fieldOptions.expirationDate,
        },
        cardholderName: {
          selector: '#card-name',
          placeholder: '',
          internalLabel: 'Cardholder Name',
          ...fieldOptions.cardholderName,
        },
        postalCode: {
          selector: '#card-postal',
          placeholder: '',
          internalLabel: 'Postal Code',
          ...fieldOptions.postalCode,
        },
      },
    };

    if (updateExistingPayment) {
      delete options.fields.number;
    }

    braintree.hostedFields.create(options).then((instance) => {
      // Use the Hosted Fields instance here to tokenize a card
      setLocalHostedFieldsInstance(instance);
      setHostedFieldsInstance(instance);
      instance.on('cardTypeChange', onCardTypeChange);
    }).catch((err) => {
      // Handle error in component creation
    });
  }, [clientInstance]);

  return (
    // SUGGESTION:  style={{ maxWidth: '600px' }}
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <HostedField
          label="Cardholder Name"
          id="card-name"
          field="cardholderName"
          hostedFieldsInstance={hostedFieldsInstance}
          disabled={disabled}
        />
      </Grid>
      {!updateExistingPayment
        ? (
          <Grid container item style={{ position: 'relative' }}>
            <HostedField
              label="Card Number"
              id="card-number"
              field="number"
              hostedFieldsInstance={hostedFieldsInstance}
              adornment={<CreditCardImage {...card} />}
              disabled={disabled}
            />
          </Grid>
        ) : (
          <Grid item container>
            <CustomReadOnlyTextField
              value={fieldOptions.number && fieldOptions.number.prefill}
              label="Card number"
            />
          </Grid>
        )}
      <Grid container item spacing={2} wrap="nowrap">
        <Grid item xs={5}>
          <HostedField
            label="Expiration Date"
            id="card-exp-date"
            field="expirationDate"
            hostedFieldsInstance={hostedFieldsInstance}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={2}>
          <HostedField
            label="CVV"
            id="card-cvv"
            field="cvv"
            hostedFieldsInstance={hostedFieldsInstance}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={5}>
          <HostedField
            label="Zip/Postal Code"
            id="card-postal"
            field="postalCode"
            hostedFieldsInstance={hostedFieldsInstance}
            disabled={disabled}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

CreditCardHostedForm.propTypes = {
  setHostedFieldsInstance: PropTypes.func,
  disabled: PropTypes.bool,
  updateExistingPayment: PropTypes.bool,
  clientInstance: PropTypes.objectOf(PropTypes.any),
  fieldOptions: PropTypes.objectOf({
    number: PropTypes.any,
    cardholderName: PropTypes.any,
    expirationDate: PropTypes.any,
    cvv: PropTypes.any,
    postalCode: PropTypes.any,
  }),
};
CreditCardHostedForm.defaultProps = {
  setHostedFieldsInstance: () => {},
  disabled: false,
  updateExistingPayment: false,
  clientInstance: null,
  fieldOptions: {},
};

function CreditCardImage(props) {
  const {
    type, niceType,
  } = props;
  const cardImages = {
    visa: visaImage,
    'master-card': mastercardImage,
    'american-express': amexImage,
    'diners-club': dinersClubImage,
    discover: discoverImage,
    jcb: jcbImage,
    maestro: maestroImage,
    unionpay: unionpayImage,
  };
  return (
    type && (
      <Grid style={{
        height: '40px',
        width: '83px',
        borderRadius: '3px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        background: 'white',
      }}
      >
        <img
          src={cardImages[type]}
          style={{
            height: '55px', color: 'white', maxWidth: 'max-content',
          }}
          alt={niceType || ''}
        />
      </Grid>

    )
  );
}

CreditCardImage.propTypes = {
  type: PropTypes.string,
  niceType: PropTypes.string,
};
CreditCardImage.defaultProps = {
  type: null,
  niceType: null,
};

function HostedField(props) {
  const {
    id, hostedFieldsInstance, label, field, adornment, disabled,
  } = props;
  const containerClasses = useContainerStyles();
  const labelClasses = useLabelStyles();

  const [containerActiveClasses, setContainerActiveClasses] = React.useState([containerClasses.valid]);
  const [labelActiveClasses, setLabelActiveClasses] = React.useState([labelClasses.valid]);

  React.useEffect(() => {
    if (hostedFieldsInstance) {
      if (disabled) {
        hostedFieldsInstance.setAttribute({
          field,
          attribute: 'disabled',
          value: 'true',
        }, () => {
          setContainerActiveClasses((prev) => prev.filter((c) => (c !== containerClasses.disabled)).concat([containerClasses.disabled]));
        });
      } else {
        hostedFieldsInstance.removeAttribute({
          field,
          attribute: 'disabled',
        }, () => {
          setContainerActiveClasses((prev) => prev.filter((c) => (c !== containerClasses.disabled)));
        });
      }
    }
  }, [disabled, hostedFieldsInstance]);

  const onBlur = (e) => {
    const { emittedBy } = e;
    if (emittedBy !== field) return;
    const { isValid } = e.fields[emittedBy];
    if (!isValid) {
      setContainerActiveClasses((prev) => prev
        .filter((c) => (c !== containerClasses.valid)
          && (c !== containerClasses.focused)
          && (c !== containerClasses.invalid))
        .concat([containerClasses.invalid]));
      setLabelActiveClasses((prev) => prev
        .filter((c) => (c !== labelClasses.valid)
          && (c !== labelClasses.focused)
          && (c !== labelClasses.invalid))
        .concat([labelClasses.invalid]));
    } else {
      setContainerActiveClasses((prev) => prev
        .filter((c) => c !== containerClasses.focused));
      setLabelActiveClasses((prev) => prev
        .filter((c) => c !== labelClasses.focused));
    }
  };

  const onValidityChange = (e) => {
    const { emittedBy } = e;
    if (emittedBy !== field) return;
    const { isValid } = e.fields[emittedBy];
    if (isValid) {
      setContainerActiveClasses((prev) => prev
        .filter((c) => (c !== containerClasses.invalid) && (c !== containerClasses.valid))
        .concat([containerClasses.valid]));
      setLabelActiveClasses((prev) => prev
        .filter((c) => (c !== labelClasses.invalid) && (c !== labelClasses.valid))
        .concat([labelClasses.valid]));
    }
  };

  const onFocus = (e) => {
    const { emittedBy } = e;
    if (emittedBy !== field) return;
    setContainerActiveClasses((prev) => [...prev, containerClasses.focused]);
    setLabelActiveClasses((prev) => [...prev, labelClasses.focused]);
  };

  const onEmpty = (e) => {
    const { emittedBy } = e;
    if (emittedBy !== field) return;
    setLabelActiveClasses((prev) => prev
      .filter((c) => c !== labelClasses.notEmpty));
  };

  const onNotEmpty = (e) => {
    const { emittedBy } = e;
    if (emittedBy !== field) return;
    setLabelActiveClasses((prev) => [...prev, labelClasses.notEmpty]);
  };

  React.useEffect(() => {
    if (!hostedFieldsInstance) return;
    hostedFieldsInstance.on('blur', onBlur);
    hostedFieldsInstance.on('validityChange', onValidityChange);
    hostedFieldsInstance.on('focus', onFocus);
    hostedFieldsInstance.on('empty', onEmpty);
    hostedFieldsInstance.on('notEmpty', onNotEmpty);
  }, [hostedFieldsInstance]);

  return (
    <Grid
      className={`${containerClasses.base} ${containerActiveClasses.join(' ')}`}
      direction="row"
      container
      wrap="nowrap"
    >
      <InputLabel className={`${labelClasses.base} ${labelActiveClasses.join(' ')}`}>
        <span>
          {label}
        </span>
      </InputLabel>
      <Grid
        id={id}
        className={containerClasses.input}
        item
      />
      <Grid
        item
        container
        direction="column"
        className={containerClasses.adornment}
        justify="center"
      >
        {adornment}
      </Grid>
    </Grid>
  );
}

HostedField.propTypes = {
  id: PropTypes.string.isRequired,
  hostedFieldsInstance: PropTypes.objectOf(PropTypes.any).isRequired,
  label: PropTypes.string,
  field: PropTypes.string.isRequired,
  adornment: PropTypes.element.isRequired,
  disabled: PropTypes.bool,
};
HostedField.defaultProps = {
  label: '',
  disabled: false,
};
