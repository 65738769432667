import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import isNaN from 'lodash/isNaN';
import add from 'date-fns/add';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import * as colors from '../../styles/colors';
import NewOrderNextButton from '../common/NewOrderNextButton';
import CustomFreightFields, { CUSTOM_FREIGHT_FIELDS } from './Fields';
import { CUSTOM_FREIGHT_OPTION } from '../../clientConstants';

const useStyles = makeStyles(() => ({
  base: {
    borderRadius: '4px',
    padding: '10px 20px 20px',
    margin: '16px 0',
    backgroundColor: colors.darkBlueBackground,
  },
  header: {
    color: colors.white,
    fontSize: '16px',
    fontWeight: 500,
    letterSpacing: '0.25px',
    textTransform: 'capitalize',
    margin: '16px 0 32px',
  },
  inputsContainer: {
    marginBottom: 20,
  },
  inputsRow: {
    marginBottom: 10,
  },
}));

const EMPTY_FREIGHT_SERVICE = {
  carrierName: CUSTOM_FREIGHT_OPTION,
  serviceName: '',
  listPrice: '',
  myPrice: '',
  currencyCode: 'USD',
  totalDiscount: '0.00',
  expectedDeliveryDateTime: '',
};

const getNewFreightService = () => ({
  ...EMPTY_FREIGHT_SERVICE,
  serviceCode: `ST${Math.floor(Math.random() * 10000).toString()}`,
});

const CustomFreightOptions = ({ onChange }) => {
  const classes = useStyles();
  const [freightServices, setFreightServices] = useState([getNewFreightService()]);
  const [errors, setErrors] = useState([]);

  const validate = (itemIdx, name, value) => {
    let errorMsg = '';

    if (name === CUSTOM_FREIGHT_FIELDS.cost.name || name === CUSTOM_FREIGHT_FIELDS.markupCost.name || name === CUSTOM_FREIGHT_FIELDS.transitTimeDays.name) {
      const parsedValue = parseFloat(value);

      if (isNaN(parsedValue)) errorMsg = 'Must be a number';
      if (parsedValue === 0) errorMsg = 'Must be greater than 0';
      if (parsedValue < 0) errorMsg = 'Must be positive number';

      if (name === CUSTOM_FREIGHT_FIELDS.markupCost.name) {
        const parsedCost = parseFloat(freightServices[itemIdx].listPrice);
        if (parsedCost && parsedValue < parsedCost) errorMsg = 'Must be greater than or Equal to Actual Cost';
      }
    }

    if (isEmpty(value)) errorMsg = 'Required';

    return errorMsg;
  };

  const hasError = (err) => {
    let e = false;

    if (isEmpty(err)) return e;

    err.forEach((item) => {
      if (!isEmpty(item)) e = true;
    });

    return e;
  };

  const getErrors = (itemIdx, name, value) => {
    const errorMsg = validate(itemIdx, name, value);
    const newErrors = [...errors];

    if (isEmpty(errorMsg)) {
      if (!isEmpty(newErrors[itemIdx])) delete newErrors[itemIdx][name];
    } else if (isEmpty(newErrors[itemIdx])) {
      newErrors[itemIdx] = {
        [name]: errorMsg,
      };
    } else newErrors[itemIdx][name] = errorMsg;

    return newErrors;
  };

  const initializeErrors = () => {
    const newErrors = [...errors];
    const keys = Object.keys(CUSTOM_FREIGHT_FIELDS);
    const newError = {};

    keys.forEach((k) => {
      const fieldName = CUSTOM_FREIGHT_FIELDS[k].name;

      if (fieldName !== CUSTOM_FREIGHT_FIELDS.currencyCode.name) {
        newError[fieldName] = validate(fieldName, '');
      }
    });

    setErrors([...newErrors, newError]);
  };

  const getFieldData = (e) => {
    const { name, value } = e.target;
    const idx = name.indexOf('-');
    const itemCode = name.substring(idx + 1, name.length);
    const itemIdx = freightServices.findIndex((service) => service.serviceCode === itemCode);
    const newName = name.replace(`-${itemCode}`, '');

    return {
      itemIdx,
      name: newName,
      value,
    };
  };

  const getFreightServices = (itemIdx, name, value) => {
    const isTransitTime = name === CUSTOM_FREIGHT_FIELDS.transitTimeDays.name;

    const newItems = [...freightServices];
    const currentItem = { ...EMPTY_FREIGHT_SERVICE, ...newItems[itemIdx] };

    if (isTransitTime) {
      const d = new Date();
      const expectedDelivery = add(new Date(d.getFullYear(), d.getMonth(), d.getDate(), 21), {
        days: parseInt(value, 10),
      });
      currentItem.expectedDeliveryDateTime = moment(expectedDelivery).utcOffset(0, true).format('YYYY-MM-DDTHH:mm:ss');
    } else currentItem[name] = value;

    if (name === CUSTOM_FREIGHT_FIELDS.cost.name) {
      const price = parseFloat(value).toFixed(2).toString();
      currentItem[CUSTOM_FREIGHT_FIELDS.cost.name] = price;
    }
    if (name === CUSTOM_FREIGHT_FIELDS.markupCost.name) {
      const price = parseFloat(value).toFixed(2).toString();
      currentItem[CUSTOM_FREIGHT_FIELDS.markupCost.name] = price;
    }

    newItems[itemIdx] = currentItem;

    return newItems;
  };

  const getFields = (serviceCode, idx) => (
    <CustomFreightFields
      key={serviceCode}
      itemCode={serviceCode}
      disableDelete={idx === 0 && freightServices.length === 1}
      errors={errors[idx]}
      onChange={handleChange}
      onRemoveRow={handleDeleteRow}
    />
  );

  const handleChange = (e) => {
    const { itemIdx, name, value } = getFieldData(e);
    const newFreightServices = getFreightServices(itemIdx, name, value);
    let newErrors = getErrors(itemIdx, name, value);

    setFreightServices(newFreightServices);
    setErrors(newErrors);
    if (name === CUSTOM_FREIGHT_FIELDS.cost.name) {
      newErrors = getErrors(itemIdx, CUSTOM_FREIGHT_FIELDS.markupCost.name, freightServices[itemIdx].myPrice);
      setErrors(newErrors);
    }
    if (onChange) onChange(newFreightServices, hasError(newErrors));
  };

  const handleAddFields = () => {
    initializeErrors();
    setFreightServices(
      [
        ...freightServices,
        getNewFreightService(),
      ],
    );
  };

  const handleDeleteRow = (itemCode) => {
    const itemIdx = freightServices.findIndex((service) => service.serviceCode === itemCode);
    setFreightServices(freightServices.filter((r, i) => i !== itemIdx));
    setErrors(errors.filter((e, i) => i !== itemIdx));
  };

  useEffect(() => {
    initializeErrors();
  }, []);

  return (
    <Grid className={classes.base} container>
      <Grid item xs={12}>
        <Typography className={classes.header}>Custom Freight Options</Typography>
      </Grid>
      <Grid className={classes.inputsContainer} container spacing={1}>
        {freightServices.map((service, idx) => getFields(service.serviceCode, idx))}
      </Grid>
      <Grid item>
        <NewOrderNextButton onClick={handleAddFields}>
          Add Custom Freight Option
        </NewOrderNextButton>
      </Grid>
    </Grid>
  );
};

CustomFreightOptions.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default CustomFreightOptions;
