import React from 'react';
import * as orderClient from '../utils/orderClient';
import { useLogger } from '../utils/hooks';

const orderState = { orders: null };
const OrderStateContext = React.createContext();
const OrderDispatchContext = React.createContext();

// OrderReducer Action Types
const LOAD_ORDERS = 'LOAD ORDERS';

function orderReducer(state, action) {
  switch (action.type) {
    case LOAD_ORDERS: {
      return { ...state, orders: action.orders };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function OrderDataProvider(props) {
  const [state, dispatch] = useLogger(
    React.useReducer(orderReducer, orderState || { orders: null }),
  );

  return (
    <OrderStateContext.Provider value={state} {...props}>
      <OrderDispatchContext.Provider
        value={dispatch}
        {...props}
      >
      </OrderDispatchContext.Provider>
    </OrderStateContext.Provider>
  );
}

function loadOrders(dispatch) {
  return orderClient.loadAllOrders().then((data) => {
    dispatch({ type: LOAD_ORDERS, orders: data });
    return data;
  });
}

function useOrderState() {
  const context = React.useContext(OrderStateContext);
  if (context === undefined) {
    throw new Error('useOrderState must be used within a OrderDataProvider');
  }
  return context;
}

function useOrderDispatch() {
  const context = React.useContext(OrderDispatchContext);
  if (context === undefined) {
    throw new Error('useOrderDispatch must be used within a OrderDataProvider');
  }
  return context;
}

export {
  OrderDataProvider, useOrderState, useOrderDispatch, loadOrders,
};
