import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { Grid, MenuItem, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as colors from '../../../styles/colors';
import ReadOnlyContainer from '../../../components/common/ReadOnlyContainer';
import {
  ACCOUNT_MANAGEMENT, CREDIT_CARD_FEE_FIELD_NAME, PAYMENT_KEY,
} from '../constants';
import NewOrderCustomTextField from '../../../components/common/NewOrderCustomTextField';
import Form from '../Form';
import { FIELD_TYPE_BOOL, FIELD_TYPE_DROPDOWN, FIELD_TYPE_TEXT } from '../../../clientConstants';
import ReadOnlyCheckbox from '../../../components/common/ReadOnlyCheckbox';
import CustomSelectTextField from '../../../components/common/CustomSelectTextField';

const useStyles = makeStyles(() => ({
  header: {
    color: colors.white,
    fontSize: '16px',
    fontWeight: 500,
    letterSpacing: '0.25px',
    textTransform: 'capitalize',
    margin: '32px 0',
  },
}));

const Payment = ({
  itemKey, values, paymentOptions, dropdownOptions, isEditing, onEdit, onSubmit,
}) => {
  const classes = useStyles();
  const obj = ACCOUNT_MANAGEMENT[itemKey];
  const [fields, setFields] = useState(obj.fields);

  useEffect(() => {
    const addPaymentOptionsFields = isEmpty(fields.filter((field) => field.name.includes('paymentOptions')));

    if (!isEmpty(paymentOptions) && addPaymentOptionsFields) {
      const paymentOptionsFields = paymentOptions.map((option) => (
        {
          type: FIELD_TYPE_BOOL,
          name: `${PAYMENT_KEY}.paymentOptions.${option.key}`,
          label: option.label,
        }));

      const findLastPaymentIdx = obj.fields.findIndex((field) => field.name === CREDIT_CARD_FEE_FIELD_NAME);

      fields.splice(findLastPaymentIdx + 2, 0, ...paymentOptionsFields);
    }
  }, [paymentOptions]);

  return (
    <ReadOnlyContainer title={obj.title} setIsReadOnly={() => onEdit(itemKey)}>
      {isEditing ? (
        <Form
          itemKey={itemKey}
          initialValues={values}
          validationSchema={obj.validationSchema}
          fields={fields}
          pricingModels={get(dropdownOptions, 'pricingModels', [])}
          currencyUnits={get(dropdownOptions, 'currencyUnits', [])}
          onEdit={onEdit}
          onSubmit={onSubmit}
        />
      ) : fields.map((field) => (
        <Grid key={field.name} item xs={field?.fullWidth ? 12 : 6}>
          {isEmpty(field.type) && (
            <Typography className={classes.header}>{field.label}</Typography>
          )}
          {field.type === FIELD_TYPE_BOOL && (
            <ReadOnlyCheckbox
              type={field.type}
              name={field.name}
              label={field.label}
              value={get(values, field.name, false)}
            />
          )}
          {field.type === FIELD_TYPE_TEXT && (
            <NewOrderCustomTextField
              type={field.type}
              style={{ width: '100%' }}
              field={{
                name: field.name,
                label: field.label,
                value: get(values, field.name, ''),

              }}
              form={{
                touched: false,
              }}
              disabled
            />
          )}
          {field.type === FIELD_TYPE_DROPDOWN && (
            <CustomSelectTextField
              type="text"
              style={{ width: field.name.includes('currency') ? '45%' : '100%' }}
              field={{
                name: field.name,
                label: field.label,
                value: get(values, field.name, ''),

              }}
              form={{
                touched: false,
              }}
              disabled
            >
              {dropdownOptions[field.dropdownKey] && dropdownOptions[field.dropdownKey].map((option) => (
                <MenuItem
                  key={option.type}
                  value={option.type}
                >
                  {option.description}
                </MenuItem>
              ))}
            </CustomSelectTextField>
          )}
        </Grid>
      ))}
    </ReadOnlyContainer>
  );
};

Payment.defaultProps = {
  values: null,
  paymentOptions: [],
  dropdownOptions: {
    pricingModels: [],
    currencyUnits: [],
  },
  isEditing: false,
};

Payment.propTypes = {
  itemKey: PropTypes.string.isRequired,
  values: PropTypes.shape({
    payment: PropTypes.shape(PropTypes.object),
    pricingList: PropTypes.shape(PropTypes.object),
  }),
  paymentOptions: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string,
    description: PropTypes.string,
  })),
  dropdownOptions: PropTypes.shape({
    pricingModels: PropTypes.arrayOf(PropTypes.shape({
      type: PropTypes.string,
      description: PropTypes.string,
    })),
    currencyUnits: PropTypes.arrayOf(PropTypes.shape({
      countryCode: PropTypes.string,
      currencyCode: PropTypes.string,
      description: PropTypes.string,
    })),
  }),
  isEditing: PropTypes.bool,
  onEdit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default memo(Payment);
