import React from 'react';
import PropTypes from 'prop-types';
import RecipientTabs from './RecipientTabs';
import NewOrderShipmentModule from './common/NewOrderShipmentModule';
import BlockedPanelDisplay from './common/PanelWarning';

const getLabelDetails = (shipment) => {
  const address = shipment?.destination?.address;
  const addresses = shipment?.destination?.addresses;
  if (addresses) {
    return `Total Addresses : ${addresses?.length}`;
  }
  if (!address) return '';
  const {
    name, addressLine1, city, state, zip, country,
  } = address;
  return `${name} | ${addressLine1} | ${city} | ${state} | ${zip} | ${country}`;
};

const RecipientPane = ({
  shipment,
  isReadyToBeCompleted,
  blockingMessage,
  ...shipmentProps
}) => (
  <NewOrderShipmentModule
    labelDetails={getLabelDetails(shipment)}
    shipment={shipment}
    blockingMessage={blockingMessage}
    {...shipmentProps}
  >
    {(isReadyToBeCompleted) ? ((renderProps) => (
      <RecipientTabs
        shipment={shipment}
        isReadyToBeCompleted={isReadyToBeCompleted}
        {...shipmentProps}
        {...renderProps}
      />
    )) : (
      <BlockedPanelDisplay message={blockingMessage} />
    )}
  </NewOrderShipmentModule>
);

RecipientPane.propTypes = {
  shipment: PropTypes.shape().isRequired,
  isReadyToBeCompleted: PropTypes.bool.isRequired,
};

export default RecipientPane;
