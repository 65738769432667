import React, { useEffect } from 'react';
import * as braintree from 'braintree-web';
import { Grid } from '@material-ui/core';
import { css } from 'emotion';
import PropTypes from 'prop-types';

import { Field } from 'formik';
import CustomCreditCard from './CustomCreditCard';
import CustomCostCodesDev from './CustomCostCodesDev';
import CustomPaypal from './CustomPaypal';
import CustomACH from './CustomACH';

import { useContactsState } from '../../context/contactsContext';
import { useUserState } from '../../context/userContext';
import { getBraintreeApiKey } from '../../utils/apiKeyClient';
import * as colors from '../../styles/colors';
import { CustomRadio } from '../common/InputComponents';
import { usePaymentState } from '../../context/paymentContext';

import {
  isProviderPayment,
  getPaymentOptions,
  formatDefaultSender,
  hasPaymentCostCodes,
  hasPaymentCreditCard,
  hasPaymentPaypal,
  hasPaymentACH,
  toggleStyleDisplay,
  getTypography,
  getEmptyAllocation,
} from './util';

import {
  EMPTY_BILLING_ADDRESS,
  PAYMENT_TYPE,
  PAYMENT_CREDITCARD_VALUE,
  PAYMENT_COSTCODES_VALUE,
  PAYMENT_PAYPAL_VALUE,
  PAYMENT_ACH_VALUE,
} from './constants';

export default function CustomPayment(props) {
  const {
    formikProps, costAllocation, formikRef,
    accountType, purpose, billingDetails = null, classes,
    useDefaultAddress, selectedPaymentOption,
    setHostedFieldsInstance, setUSBankAccountInstance, costCodeProfile, accountName
  } = props;
  const {
    isSubmitting,
    setFieldValue,
  } = formikProps;

  const lockPayment = isProviderPayment(billingDetails);

  const { defaultSender } = useContactsState();
  const userState = useUserState();
  const options = getPaymentOptions(accountName, accountType, purpose, userState);

  const isDisabled = isSubmitting || (isProviderPayment(billingDetails));

  const [clientInstance, setClientInstance] = React.useState(null);

  const { savedPaymentMethods } = usePaymentState();

  React.useEffect(() => {
    getBraintreeApiKey().then(async (key) => {
      const instance = await braintree.client.create({
        authorization: key,
      }).catch(() => null);
      setClientInstance(instance);
    });
  }, []);

  // INSERT/REMOVE DEFAULT ADDRESS ON FORM VALUE
  useEffect(() => {
    if (!defaultSender || (lockPayment)) return;
    if (useDefaultAddress) {
      setFieldValue('billingAddress', {
        ...EMPTY_BILLING_ADDRESS,
        ...formatDefaultSender(defaultSender),
      });
    }
  }, [useDefaultAddress]);

  const hasCostCodesOption = hasPaymentCostCodes(options);
  const hasCreditCardOption = hasPaymentCreditCard(options);
  const hasPaypalOption = hasPaymentPaypal(options);
  const hasACHOption = hasPaymentACH(options);
  async function updateCostCodeFromProfile(profileName, idx) {
    if (profileName) {
      const profile = costCodeProfile?.find((p) => p.profileName === profileName);
      await formikProps.setFieldValue(
        `billCodes[${idx}]`,
        getEmptyAllocation(costAllocation.billCodes),
      );
      await formikProps.setFieldValue(`billCodes[${idx}]`, { ...profile.billCodes, allocation: formikRef.current.state.values.billCodes[idx].allocation });
    } else {
      formikProps.setFieldValue(`billCodes[${idx}]`, getEmptyAllocation(costAllocation.billCodes));
      formikProps.setFieldValue('profileName', '');
    }
  }
  function getContainer(child, isShown) {
    return (
      <Grid
        item
        style={{ display: toggleStyleDisplay(isShown) }}
        className={css`
            padding: 0px 32px 24px !important;
            background-color: ${colors.darkBlueBackground}
          `}
      >
        {child}
      </Grid>
    );
  }

  return (
    <Grid item container direction="column">
      <Grid
        item
        className={css`
            padding-top: 18px;
            padding-bottom: 18px;
          `}
      >
        {getTypography('payment-header', 'Payment', css`
            color: ${colors.white};
            font-weight: 500;
          `)}
      </Grid>
      <Grid
        item
        className={css`
            padding: 12px 32px 18px !important;
            background-color: ${colors.darkBlueBackground};
          `}
      >
        <Field>
          {({ form }) => options.map((option) => (
            <CustomRadio
              {...{ options: [option], classes }}
              key={`temporary-single-custom-radio-${option.value}`}
              id={PAYMENT_TYPE}
              disabled={isDisabled}
              callback={(value) => {
                setFieldValue(PAYMENT_TYPE, value);
              }}
            />
          ))}
        </Field>
      </Grid>
      {(hasCreditCardOption)
        ? getContainer(
          <CustomCreditCard
            defaultSender={defaultSender}
            billingDetails={billingDetails}
            lockPayment={lockPayment}
            setHostedFieldsInstance={setHostedFieldsInstance}
            classes={classes}
            formikRef={formikRef}
            isSubmitting={isSubmitting}
            clientInstance={clientInstance}
            savedCards={savedPaymentMethods && savedPaymentMethods.creditCards}
            isDelayedPayment={userState.isDelayedPayment}
          />,
          selectedPaymentOption === PAYMENT_CREDITCARD_VALUE,
        )
        : null}
      {(hasCostCodesOption) ? getContainer(
        <CustomCostCodesDev
          costAllocation={costAllocation}
          costCodeProfile={costCodeProfile}
          updateFormState={updateCostCodeFromProfile}
          hasBilling={!!billingDetails}
          accountName={accountName}
        />,
        selectedPaymentOption === PAYMENT_COSTCODES_VALUE,
      ) : null}
      {(hasPaypalOption && !isProviderPayment(billingDetails) && !isSubmitting) ? getContainer(
        <CustomPaypal
          clientInstance={clientInstance}
        />,
        selectedPaymentOption === PAYMENT_PAYPAL_VALUE,
      ) : null}
      {(hasACHOption) ? getContainer(
        <CustomACH
          clientInstance={clientInstance}
          setUSBankAccountInstance={setUSBankAccountInstance}
          formikRef={formikRef}
          defaultSender={defaultSender}
          lockPayment={lockPayment}
          savedAccounts={savedPaymentMethods && savedPaymentMethods.bankAccounts}
        />,
        selectedPaymentOption === PAYMENT_ACH_VALUE,
      ) : null}
    </Grid>
  );
}

CustomPayment.propTypes = {
  formikProps: PropTypes.shape({
    isSubmitting: PropTypes.bool.isRequired,
    setFieldValue: PropTypes.func.isRequired,
  }).isRequired,
  accountType: PropTypes.string.isRequired,
  purpose: PropTypes.string.isRequired,
  billingDetails: PropTypes.shape({
    billingType: PropTypes.string.isRequired,
    paymentType: PropTypes.string.isRequired,
    accountNumber: PropTypes.string,
    country: PropTypes.string,
    zip: PropTypes.string,
    billCodes: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]).isRequired).isRequired),
    amount: PropTypes.string.isRequired,
    creditCardDetails: PropTypes.shape({
      lastFour: PropTypes.string.isRequired,
    }),
    billingAddress: PropTypes.objectOf(PropTypes.string.isRequired),
  }),
  getSavedAllocations: PropTypes.func.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  costAllocation: PropTypes.shape({
    billCodes: PropTypes.arrayOf(PropTypes.any).isRequired,
    instructions: PropTypes.arrayOf(PropTypes.string).isRequired,
    maxAllocations: PropTypes.number.isRequired,
    isDescriptionAllowed: PropTypes.bool.isRequired,
  }).isRequired,
  formikRef: PropTypes.shape({ current: PropTypes.any }).isRequired,
  useDefaultAddress: PropTypes.bool.isRequired,
  selectedPaymentOption: PropTypes.string.isRequired,
  setBrainTreeCCInstance: PropTypes.func.isRequired,
  setHostedFieldsInstance: PropTypes.func.isRequired,
  setUSBankAccountInstance: PropTypes.func.isRequired,
};

CustomPayment.defaultProps = {
  billingDetails: null,
};
