import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid, Popover, Typography, Button, Checkbox, InputLabel, MenuItem,
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import moment from 'moment';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';

import * as colors from '../styles/colors';
import statusHelp from '../utils/status';
import CustomSelectTextField from './common/CustomSelectTextField';
import { loadCarrierDropdown, loadWarehouseDropdown } from '../utils/accountClient';

const ACCOUNT_TYPE_LARGE = 'large';
const ACCOUNT_TYPE_INDIVIDUAL = 'individual';
const muiCheckboxStyles = {
  root: {
    color: colors.white,
    fontSize: 12,
    '&$checked': {
      color: colors.secondaryButton,
    },
  },
  checked: {
    color: colors.secondaryButton,
  },
};

const checkboxStyles = makeStyles(() => ({
  labelText: { color: colors.white, fontSize: 14 },
}));

const StyledCheckbox = withStyles(muiCheckboxStyles)(Checkbox);

const FilterCheckbox = (props) => {
  const classes = checkboxStyles();
  const {
    label, checked, setFilter, value,
  } = props;
  return (
    <InputLabel>
      <StyledCheckbox onClick={(e) => setFilter(value)} checked={checked} />
      <Typography className={classes.labelText} display="inline">
        {label}
      </Typography>
    </InputLabel>
  );
};

const useStyles = makeStyles(() => ({
  filterModal: {
    background: colors.mediumBlueBackground,
    width: 450,
    padding: '20px 25px',
    color: colors.white,
    borderRadius: '8px',
  },
  filterModalContent: {
    background: colors.darkBlueBackground,
    padding: '10px 20px',
  },
  sectionHeader: {
    padding: '0 0 5px 0',
    fontSize: 16,
    fontWeight: '500',
  },
  modalHeaderSection: {
    fontWeight: '500',
    padding: '20px 0 12px 0',
    width: '100%',
  },
  modalHeader: {
    fontSize: 18,
  },
  filterModalSection: {
    fontSize: 16,
    width: '100%',
    padding: '10px 0',
  },
  special: {
    color: colors.textLightGrey,
    fontWeight: 500,
  },
  header: {
    fontWeight: 500,
  },
  sectionStatus: {
    // minHeight: 130,
  },
  sectionDate: {
    // minHeight: 150,
  },
  sectionSubmit: {
    // minHeight: 50,
    padding: '10px 0',
  },
  sectionType: {
  },
  submitButtonText: {
    textTransform: 'capitalize',
    color: colors.white,
  },
  inputLabelText: {
    color: colors.white,
  },
  paper: {
    background: colors.mediumBlueBackground,
    borderRadius: 8,
  },
  datePicker: {
    width: '50%',
    color: colors.white,
  },
  datePickerRoot: {
    color: colors.white,
    '& label': {
      color: colors.white,
    },
  },
  inputRootLeft: {
    color: colors.white,
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: colors.lightBackgroundGrey,
      borderRight: 'none',
      borderRadius: '5px 0 0 5px',
    },
    '&:hover, &:focus': {
      '& .MuiOutlinedInput-notchedOutline': {
        border: `2px solid ${colors.white}`,
        borderRadius: '5px 5px 5px 5px',
      },
    },
  },
  inputRootRight: {
    color: colors.white,
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: colors.lightBackgroundGrey,
      borderLeft: 'none',
      borderRadius: '0 5px 5px 0',
    },
    '&:hover': {
      '& .MuiOutlinedInput-notchedOutline': {
        border: `2px solid ${colors.white}`,
        borderRadius: '5px 5px 5px 5px',
      },
    },
  },
  inputAdornmentRoot: {
    '& $button': {
      color: colors.white,
      padding: 0,
    },
  },
  disableInputAdornment: {
    '& $button': {
      color: colors.white,
      padding: 0,
    },
    // display: 'none',
  },
  arrow: {
    color: colors.lightBackgroundGrey,
  },
  formControl: {
    width: '100%',
    color: 'white',
    background: colors.mediumBlueBackground,
  },
  white: {
    color: 'white',
    padding: '5px',
  },
  button: {
    fontWeight: 'bold',
    padding: '6px 16px',
    color: colors.white,
    borderRadius: '5px',
    whiteSpace: 'nowrap',
    '&:disabled': {
      color: colors.mediumBlueBackground,
    },

  },
}));

export default function FilterManifestModal(props) {
  const classes = useStyles();
  const {
    anchorEl, handleClose, filterState, setFilterState, submitFilters, accountType = ACCOUNT_TYPE_LARGE,
  } = props;

  const { filterableStatuses } = statusHelp;
  const [warehouseList, setWarehouseList] = React.useState([]);
  const [carrierList, setCarrierList] = React.useState([]);

  React.useEffect(() => {
    loadWarehouseDropdown().then((fetchedData) => {
      setWarehouseList(fetchedData);
    });
    loadCarrierDropdown().then((fetchedData) => {
      setCarrierList(fetchedData);
    });
  }, []);

  const changeFilter = (cat) => (status) => {
    setFilterState((prev) => (
      {
        ...prev,
        [cat]: {
          ...prev[cat],
          [status]: !prev[cat][status],
        },
      }));
  };

  const changeSimpleFilter = (cat, value) => setFilterState((prev) => (
    {
      ...prev, [cat]: value,
    }
  ));

  const statusFilters = filterableStatuses.map((status) => ({
    label: status,
    value: status,
    category: 'status',
  }));
  const [age, setAge] = React.useState('');
  const [open, setOpen] = React.useState(false);

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const handleSelectClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const purposeFilters = [
    {
      label: 'Business',
      value: 'work',
      category: 'purpose',
    },
    {
      label: 'Personal',
      value: 'personal',
      category: 'purpose',
    },
  ];
  const getInitialValues = () => ({
    location: '',
    status: '',
    carrier: '',
    company: '',

  });
  const validationSchema = Yup.object().shape({
    location: Yup.string(),
    status: Yup.string(),
    carrier: Yup.string(),
    company: Yup.string(),
  });

  async function onSubmit(values) {
    const requestObj = {
      ...values,
      orderType: values.orderType ? 'NON-RMS' : 'RMS',
    };
  }
  return (
    <Popover
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      classes={{ paper: classes.paper }}
      data-testid="filter-shipments-modal"
    >
      <Formik
        enableReinitialize
        initialValues={getInitialValues()}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(formikProps) => (
          <Grid container className={classes.filterModal} direction="column">
            <Grid item container className={`${classes.modalHeaderSection}`}>
              <Typography className={`${classes.modalHeader}`}>Filter Shipments</Typography>
            </Grid>
            <Grid item container className={classes.filterModalContent}>
              <Grid className={`${classes.filterModalSection} ${classes.sectionType}`} item container direction="column" justify="flex-start" wrap="nowrap">
                <Grid item xs={12}>
                  <Field
                    component={CustomSelectTextField}
                    style={{ width: '100%' }}
                    type="text"
                    name="location"
                    label="Location/Plant"
                    data-testid="location-input"
                    customOnChange={(e) => { changeSimpleFilter('location', e.target.value); }}
                  >
                    {
                  warehouseList.map((wh) => (
                    <MenuItem
                      key={wh.siteName}
                      value={wh.siteName}
                    >
                      {wh.siteName}
                    </MenuItem>
                  ))
                }
                  </Field>
                </Grid>
              </Grid>
              <Grid className={`${classes.filterModalSection} ${classes.sectionType}`} item container direction="column" justify="flex-start" wrap="nowrap">
                <Grid item>
                  <Typography className={`${classes.sectionHeader}`}>Status</Typography>
                </Grid>
                <Grid item container alignItems="center" justify="flex-start" direction="row">
                  {statusFilters.map((status) => {
                    const { label, value, category } = status;
                    const formattedLabel = label.replace(/\s[^$]*$/, '...');
                    return (
                      <Grid item xs={4} key={`status-filter-grid-${label}-${value}`}>
                        <FilterCheckbox label={formattedLabel} value={value} setFilter={changeFilter(category)} checked={filterState[category][value]} />
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>

              <Grid className={`${classes.filterModalSection} ${classes.sectionDate}`} item>
                <Typography className={`${classes.sectionHeader}`}> Ship Date</Typography>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid item container>
                    <KeyboardDatePicker
                      autoOk
                      variant="inline"
                      inputVariant="outlined"
                      label="Start Date"
                      format="dd MMM yyyy"
                      maxDate={filterState.endDate && moment(filterState.endDate).toDate()}
                      value={filterState.startDate && moment(filterState.startDate).toDate()}
                      onChange={(d) => {
                        const formattedDate = moment(d).format('YYYY-MM-DD h:m:s a');
                        const dateToSet = (formattedDate !== 'Invalid date' && formattedDate) || null;
                        changeSimpleFilter('startDate', dateToSet);
                      }}
                      InputAdornmentProps={{
                        position: 'start',
                        classes: {
                          root: classes.inputAdornmentRoot,
                        },
                      }}
                      className={classes.datePicker}
                      classes={{
                        root: classes.datePickerRoot,
                      }}
                      InputProps={{
                        classes: {
                          root: classes.inputRootLeft,
                        },
                      }}
                      rightArrowButtonProps={{ classes: { root: classes.arrow } }}
                      leftArrowButtonProps={{ classes: { root: classes.arrow } }}
                    />
                    <KeyboardDatePicker
                      autoOk
                      variant="inline"
                      inputVariant="outlined"
                      label="End Date"
                      format="dd MMM yyyy"
                      value={filterState.endDate && moment(filterState.endDate).toDate()}
                      minDate={filterState.startDate && moment(filterState.startDate).toDate()}
                      onChange={(d) => {
                        const formattedDate = moment(d).format('YYYY-MM-DD h:m:s a');
                        const dateToSet = (formattedDate !== 'Invalid date' && formattedDate) || null;
                        changeSimpleFilter('endDate', dateToSet);
                      }}
                      InputAdornmentProps={{
                        position: 'start',
                        classes: {
                          root: classes.disableInputAdornment,
                        },
                      }}
                      className={classes.datePicker}
                      classes={{
                        root: classes.datePickerRoot,
                      }}
                      InputProps={{
                        classes: {
                          root: classes.inputRootRight,
                        },
                      }}
                    />
                  </Grid>

                </MuiPickersUtilsProvider>
              </Grid>
              <Grid className={`${classes.filterModalSection} ${classes.sectionDate}`} item>
                <Typography className={`${classes.sectionHeader}`}> Ship Time</Typography>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid item container>
                    <KeyboardTimePicker
                      autoOk
                      variant="inline"
                      inputVariant="outlined"
                      label="Start Time"
                      maxDate={filterState.endDate && moment(filterState.endDate).toDate()}
                      value={filterState.startDate && moment(filterState.startDate).toDate()}
                      onChange={(d) => {
                        const formattedDate = moment(d).format('YYYY-MM-DD h:m:s a');
                        const dateToSet = (formattedDate !== 'Invalid date' && formattedDate) || null;
                        changeSimpleFilter('startDate', dateToSet);
                      }}
                      InputAdornmentProps={{
                        position: 'start',
                        classes: {
                          root: classes.inputAdornmentRoot,
                        },
                      }}
                      className={classes.datePicker}
                      classes={{
                        root: classes.datePickerRoot,
                      }}
                      InputProps={{
                        classes: {
                          root: classes.inputRootLeft,
                        },
                      }}
                      rightArrowButtonProps={{ classes: { root: classes.arrow } }}
                      leftArrowButtonProps={{ classes: { root: classes.arrow } }}
                    />
                    <KeyboardTimePicker
                      autoOk
                      variant="inline"
                      inputVariant="outlined"
                      label="End Time"
                      value={filterState.endDate && moment(filterState.endDate).toDate()}
                      minDate={filterState.startDate && moment(filterState.startDate).toDate()}
                      onChange={(d) => {
                        const formattedDate = moment(d).format('YYYY-MM-DD h:m:s a');
                        const dateToSet = (formattedDate !== 'Invalid date' && formattedDate) || null;
                        changeSimpleFilter('endDate', dateToSet);
                      }}
                      InputAdornmentProps={{
                        position: 'start',
                        classes: {
                          root: classes.disableInputAdornment,
                        },
                      }}
                      className={classes.datePicker}
                      classes={{
                        root: classes.datePickerRoot,
                      }}
                      InputProps={{
                        classes: {
                          root: classes.inputRootRight,
                        },
                      }}
                    />
                  </Grid>

                </MuiPickersUtilsProvider>
              </Grid>
              {/* <Grid className={`${classes.filterModalSection} ${classes.sectionType}`} item container direction="column" justify="flex-start" wrap="nowrap">
                <Grid item xs={12}>
                  <Field
                    component={CustomFilterTextField}
                    value = {filterState.po}
                    style={{ width: '100%' }}
                    type="text"
                    name="PO"
                    label="PO#"
                    data-testid="carrier-input"
                    customOnChange={(e) => { changeSimpleFilter('po', e.target.value); }}
                  />
                </Grid>
              </Grid>
              <Grid className={`${classes.filterModalSection} ${classes.sectionType}`} item container direction="column" justify="flex-start" wrap="nowrap">
                <Grid item xs={12}>
                  <Field
                    component={CustomFilterTextField}
                    value = {filterState.company}
                    style={{ width: '100%' }}
                    type="text"
                    name="company"
                    label="Company Name"
                    data-testid="carrier-input"
                    customOnChange={(e) => { changeSimpleFilter('company', e.target.value); }}
                  />
                </Grid>
              </Grid>
               */}
              <Grid className={`${classes.filterModalSection} ${classes.sectionType}`} item container direction="column" justify="flex-start" wrap="nowrap">
                <Grid item xs={12}>
                  <Field
                    component={CustomSelectTextField}
                    style={{ width: '100%' }}
                    type="text"
                    name="carrier"
                    label="Carrier"
                    data-testid="carrier-input"
                    customOnChange={(e) => { changeSimpleFilter('carrier', e.target.value); }}
                  >
                    {
                  [...new Map(carrierList.map((c) => ({ carrierCode: c.carrierCode, carrierLabel: c.carrierLabel })).map((item) => [item.carrierCode, item])).values()].map((carrier) => (
                    <MenuItem
                      key={carrier.carrierLabel}
                      value={carrier.carrierLabel}
                    >
                      {carrier.carrierLabel}
                    </MenuItem>
                  ))
                }
                  </Field>
                </Grid>
              </Grid>
              <Grid className={`${classes.filterModalSection} ${classes.sectionType}`} item container direction="column" justify="flex-start" wrap="nowrap">
                <Grid item xs={12}>
                  <Field
                    component={CustomSelectTextField}
                    style={{ width: '100%' }}
                    type="text"
                    name="service"
                    label="Service"
                    data-testid="carrier-input"
                    customOnChange={(e) => { changeSimpleFilter('service', e.target.value); }}
                  >
                    {
                  // eslint-disable-next-line react/prop-types
                  [...new Set(carrierList.filter((cl) => cl.carrierLabel === filterState.carrier).map((carrier) => carrier.description))].map((service) => (
                    <MenuItem
                      key={service}
                      value={service}
                    >
                      {service}
                    </MenuItem>
                  ))
                }
                  </Field>
                </Grid>
              </Grid>
            </Grid>
            <Grid className={` ${classes.sectionSubmit}`} container>
              <Grid container justify="center" alignContent="center">
                <Button variant="contained" color="secondary" className={classes.button} onClick={submitFilters}>
                  <Typography className={classes.submitButtonText}>
                    Submit
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Formik>
    </Popover>
  );
}

FilterManifestModal.propTypes = {
  accountType: PropTypes.string,
};

FilterManifestModal.defaultProps = {
  accountType: ACCOUNT_TYPE_LARGE,
};
