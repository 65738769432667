/* eslint-disable no-unused-expressions */
/* eslint-disable no-plusplus */
/* eslint-disable react/prop-types */
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
// RESOURCES:
// https://web.dev/serial/
// https://reillyeon.github.io/serial/#onconnect-attribute-0
// https://codelabs.developers.google.com/codelabs/web-serial

// export type PortState = "closed" | "closing" | "open" | "opening";

// export type SerialMessage = {
//     value: string;
//     timestamp: number;
//   };

//   type SerialMessageCallback = (message: SerialMessage) => void;

// export interface SerialContextValue {
//     canUseSerial: boolean;
//     hasTriedAutoconnect: boolean;
//     portState: PortState;
//     connect(): Promise<boolean>;
//     disconnect(): void;
//     subscribe(callback: SerialMessageCallback): () => void;
//   }
export const SerialContext = createContext({
  canUseSerial: false,
  hasTriedAutoconnect: false,
  connect: () => Promise.resolve(false),
  disconnect: () => {},
  portState: 'closed',
  subscribe: () => () => {},
});

export const useSerial = () => useContext(SerialContext);

//  interface SerialProviderProps {}
const SerialProvider = ({
  children,
}) => {
  const [canUseSerial] = useState(() => 'serial' in navigator);

  const [portState, setPortState] = useState('closed');
  const [hasTriedAutoconnect, setHasTriedAutoconnect] = useState(false);
  const [hasManuallyDisconnected, setHasManuallyDisconnected] = useState(false);

  const portRef = useRef(null);
  const readerRef = useRef(null);
  const readerClosedPromiseRef = useRef(Promise.resolve());

  const currentSubscriberIdRef = useRef(0);
  const subscribersRef = useRef(new Map());
  /**
     * Subscribes a callback function to the message event.
     *
     * @param callback the callback function to subscribe
     * @returns an unsubscribe function
     */
  const subscribe = async (callback) => {
    const id = currentSubscriberIdRef.current;
    subscribersRef.current.set(id, callback);
    currentSubscriberIdRef.current++;
    await readUntilClosed(portRef.current);
    return () => {
      subscribersRef.current.delete(id);
    };
  };

  /**
     * Reads from the given port until it's been closed.
     *
     * @param port the port to read from
     */
  const readUntilClosed = async (port) => {
    const keepReading = true;
    if (port.readable) {
    // eslint-disable-next-line no-undef
      const textDecoder = new TextDecoderStream();
      const readableStreamClosed = port.readable.pipeTo(textDecoder.writable);
      readerRef.current = textDecoder.readable.getReader();
      const writer = port.writable.getWriter();

      try {
        const data = new Uint8Array([87, 13]); // W ENTER
        await writer.write(data);
        // eslint-disable-next-line no-await-in-loop
        const { value, done } = await readerRef.current.read();
        const timestamp = Date.now();
        console.log(value);
        Array.from(subscribersRef.current).forEach(([name, callback]) => {
          callback({ value, timestamp });
        });
      } catch (error) {
        console.error(error);
      } finally {
        await readerRef.current.cancel();
        await readerRef.current.releaseLock();
        await writer.releaseLock();
      }

      await readableStreamClosed.catch(() => {}); // Ignore the error
    }
  };

  /**
     * Attempts to open the given port.
     */
  const openPort = async (port) => {
    try {
      try {
        await port.open({ baudRate: 9600 });
      } catch (e) {
        await port.close();
        await port.open({ baudRate: 9600 });
      }
      portRef.current = port;
      setPortState('open');
      setHasManuallyDisconnected(false);
    } catch (error) {
      setPortState('closed');
      console.error('Could not open port');
    }
  };

  const manualConnectToPort = async () => {
    if (canUseSerial && portState === 'closed') {
      setPortState('opening');
      const filters = [
        // Can identify the vendor and product IDs by plugging in the device and visiting: chrome://device-log/
        // the IDs will be labeled `vid` and `pid`, respectively
        // METTLER TOLEDO
        // {
        //   usbVendorId: 1659,
        //   usbProductId: 8963,
        // },
      ];
      try {
        const port = await navigator.serial.requestPort({ filters });
        await openPort(port);
        return true;
      } catch (error) {
        setPortState('closed');
        console.error('User did not select port');
      }
    }
    return false;
  };

  const autoConnectToPort = async () => {
    if (canUseSerial && portState === 'closed') {
      setPortState('opening');
      const availablePorts = await navigator.serial.getPorts();
      if (availablePorts.length) {
        const port = availablePorts[0];
        await openPort(port);
        return true;
      }
      setPortState('closed');

      setHasTriedAutoconnect(true);
    }
    return false;
  };

  const manualDisconnectFromPort = async () => {
    if (canUseSerial && portState === 'open') {
      const port = portRef.current;
      if (port) {
        setPortState('closing');

        // Cancel any reading from port
        await readerRef.current?.cancel();
        await readerClosedPromiseRef.current;
        readerRef.current = null;

        // Close and nullify the port
        await port.close();
        portRef.current = null;

        // Update port state
        setHasManuallyDisconnected(true);
        setHasTriedAutoconnect(false);
        setPortState('closed');
      }
    }
  };

  /**
     * Event handler for when the port is disconnected unexpectedly.
     */
  const onPortDisconnect = async () => {
    // Wait for the reader to finish it's current loop
    await readerClosedPromiseRef.current;
    // Update state
    readerRef.current = null;
    readerClosedPromiseRef.current = Promise.resolve();
    portRef.current = null;
    setHasTriedAutoconnect(false);
    setPortState('closed');
  };
  const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
  // Handles attaching the reader and disconnect listener when the port is open
  useEffect(() => {
    const aborted = { current: false };
    // Attach a listener for when the device is disconnected
    if (isChrome) {
      navigator.serial.addEventListener('disconnect', onPortDisconnect);

      return (async () => {
        aborted.current = true;
        await readerRef.current?.cancel();
        navigator.serial.removeEventListener('disconnect', onPortDisconnect);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portState]);

  useEffect(() => {
    const port = portRef.current;
    if (portState === 'open' && port) {
      // When the port is open, read until closed
      const aborted = { current: false };
       readerRef.current?.cancel();
       readUntilClosed(port);
    }
  }, [subscribersRef.current]);

  // Tries to auto-connect to a port, if possible
  useEffect(() => {
    if (
      canUseSerial
        && !hasManuallyDisconnected
        && !hasTriedAutoconnect
        && portState === 'closed'
    ) {
      autoConnectToPort();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canUseSerial, hasManuallyDisconnected, hasTriedAutoconnect, portState]);

  return (
    <SerialContext.Provider
      value={{
        canUseSerial,
        hasTriedAutoconnect,
        subscribe,
        portState,
        connect: manualConnectToPort,
        disconnect: manualDisconnectFromPort,
      }}
    >
      {children}
    </SerialContext.Provider>
  );
};

export default SerialProvider;
