import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
import { Field } from 'formik';
import {
  Grid, InputAdornment, MenuItem, Link as MuiLink,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import { DebounceInput } from 'react-debounce-input';
import * as colors from '../../../../../styles/colors';
import { CustomSearchWithDropdown } from '../../../../common/InputComponents';
import CustomSelectTextField from '../../../../common/CustomSelectTextField';
import NewOrderCustomTextField from '../../../../common/NewOrderCustomTextField';
import ProductWeight from '../../Weight';
import {
  CHEMICAL_INNER_PACKAGING_OPTIONS,
  CHEMICAL_MEASUREMENT_UNIT_OPTIONS,
  CHEMICAL_SMALL_MEASUREMENT_UNIT_OPTIONS,
  EMPTY_CHEMICAL_ITEM_OBJECT,
  FORM_FIELDS_CHEMICAL_HAZMAT,
  FORM_LABELS,
  ITEM_WEIGHT_KEY,
  SEARCH_INPUT_MIN_LENGTH,
  SHIPMENT_QUANTITIES_OPTIONS,
  SHIPMENT_QUANTITY_NAME_KEY,
  TRANSPORTATION_OPTIONS,
  TRANSPORTATION_OPTIONS_NAME_KEY,
  TRANSPORTATION_OPTION_PASSENGER_CARGO_VALUE,
} from '../../../itemDetailsConstants';
import TransportationOptions from './TransportationOptions';
import Description from '../../Description';
import {
  getDefaultChemicalShipmentWeight,
  getQuantityInnerValues,
  setPackingIntructions,
} from '../../../itemDetailsUtil';

const useStyles = makeStyles(() => ({
  productWeightContainer: {
    marginTop: '1rem',
  },
  netValue: {
    marginTop: '1.5rem',
  },
}));

function HazmatChemical({
  formikProps,
  fields,
  editableFields,
  setEditableFields,
  filteredItemNameOptions,
  currencyUnits,
  onSelect,
  onSearch,
}) {
  const classes = useStyles();
  const [innerPackagingOptions, setInnerPackagingOptions] = useState([]);
  const [newChemical, setNewChemical] = useState(false);
  const [chemicalWeightUnits, setChemicalWeightUnits] = useState({
    units: CHEMICAL_MEASUREMENT_UNIT_OPTIONS,
    smallUnits: CHEMICAL_SMALL_MEASUREMENT_UNIT_OPTIONS,
  });
  const disableTransportationOptions = get(formikProps?.values, 'disableTransportationOptions', false);

  useEffect(() => {
    const unit = get(formikProps.values, 'cargo.maxNetQuantityUnit', null);

    if (unit) {
      setChemicalWeightUnits({
        units: CHEMICAL_MEASUREMENT_UNIT_OPTIONS.filter((u) => u.weightCode === unit),
        smallUnits: CHEMICAL_SMALL_MEASUREMENT_UNIT_OPTIONS.filter((u) => u.unit === unit),
      });
    }
  }, [formikProps.values[FORM_FIELDS_CHEMICAL_HAZMAT.materialName.key]]);

  useEffect(() => {
    const transportationMode = get(formikProps.values, TRANSPORTATION_OPTIONS_NAME_KEY, null);

    if (transportationMode) {
      const values = getQuantityInnerValues(transportationMode, formikProps?.values);

      setInnerPackagingOptions(isEmpty(values)
        ? CHEMICAL_INNER_PACKAGING_OPTIONS
        : CHEMICAL_INNER_PACKAGING_OPTIONS.filter(
          (v) => values.findIndex(
            (item) => item.innerMaterial === v.value,
          ) > -1,
        ));
    }
  }, [formikProps?.values[TRANSPORTATION_OPTIONS_NAME_KEY], formikProps?.values.itemName]);

  const handleProductChange = (item) => {
    const { key } = fields.materialName;
    const weightObj = getDefaultChemicalShipmentWeight(item, formikProps.values);
    const initialValues = { ...EMPTY_CHEMICAL_ITEM_OBJECT, ...weightObj };
    const transportationMode = has(formikProps?.values, TRANSPORTATION_OPTIONS_NAME_KEY) && !isEmpty(formikProps?.values[TRANSPORTATION_OPTIONS_NAME_KEY])
      ? formikProps?.values[TRANSPORTATION_OPTIONS_NAME_KEY]
      : TRANSPORTATION_OPTION_PASSENGER_CARGO_VALUE;

    if (disableTransportationOptions) initialValues[TRANSPORTATION_OPTIONS_NAME_KEY] = transportationMode;

    formikProps.resetForm(initialValues);
    formikProps.setFieldValue(key, item[key]);

    if (!disableTransportationOptions) {
      formikProps.setFieldValue(TRANSPORTATION_OPTIONS_NAME_KEY, TRANSPORTATION_OPTION_PASSENGER_CARGO_VALUE);
    }

    setPackingIntructions(transportationMode, item, formikProps?.setFieldValue);

    onSelect(item);
  };
  function setNew() {
    setNewChemical(true);
    setEditableFields([...editableFields, 'countryOfManufacture']);
  }
  return (
    <>
      <Grid item container spacing={1}>
        {!newChemical && (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <DebounceInput
              name={fields.materialName.key}
              label={fields.materialName.label}
              value={formikProps?.values[fields.materialName.key]}
              minLength={SEARCH_INPUT_MIN_LENGTH}
              debounceTimeout={300}
              form={formikProps}
              field={{ name: fields.materialName.key }}
              dropdownList={filteredItemNameOptions}
              inputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon color="primary" />
                  </InputAdornment>
                ),
                inputProps: { autocomplete: 'off' },
              }}
              element={CustomSearchWithDropdown}
              onSelect={handleProductChange}
              onChange={onSearch}
            />
          </Grid>
          <Grid item xs={12}>
            <MuiLink
              component="button"
              variant="body2"
              style={{ color: colors.secondaryButton }}
              onClick={(e) => {
                setNew();
              }}
            >
              Click here if you are unable to find the chemical through search
            </MuiLink>
          </Grid>
          <Grid item xs={4}>
            <Field
              name={fields.unNo.key}
              label={fields.unNo.label}
              component={NewOrderCustomTextField}
              type="text"
              style={{ width: '100%' }}
              disabled
            />
          </Grid>
          <Grid item xs={4}>
            <Field
              name={fields.classDivision.key}
              label={fields.classDivision.label}
              component={NewOrderCustomTextField}
              type="text"
              style={{ width: '100%' }}
              disabled
            />
          </Grid>
          <Grid item xs={4}>
            <Field
              name={fields.packingGroup.key}
              label={fields.packingGroup.label}
              component={NewOrderCustomTextField}
              type="text"
              style={{ width: '100%' }}
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              name={fields.properShippingName.key}
              label={fields.properShippingName.label}
              component={NewOrderCustomTextField}
              type="text"
              style={{ width: '100%' }}
              disabled
            />
          </Grid>
          <Grid item xs={4}>
            <Field
              name={fields.labels.key}
              label={fields.labels.label}
              component={NewOrderCustomTextField}
              type="text"
              style={{ width: '100%' }}
              disabled
            />
          </Grid>
          <Grid item xs={4}>
            <Field
              name={fields.packingInstructions.key}
              label={fields.packingInstructions.label}
              component={NewOrderCustomTextField}
              type="text"
              style={{ width: '100%' }}
              disabled
            />
          </Grid>
          <Grid item xs={4}>
            <Field
              name={fields.specialProvisions.key}
              label={fields.specialProvisions.label}
              component={NewOrderCustomTextField}
              type="text"
              style={{ width: '100%' }}
              disabled
            />
          </Grid>
          <Description editableFields={editableFields} />
          <Grid item xs={12}>
            <Field
              name={fields.technicalName.key}
              label={fields.technicalName.label}
              component={NewOrderCustomTextField}
              type="text"
              style={{ width: '100%' }}
              disabled={!formikProps.values?.technicalNameRequired}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              name={fields.authorization.key}
              label={fields.authorization.label}
              component={NewOrderCustomTextField}
              type="text"
              style={{ width: '100%' }}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              name={fields.specialPermits.key}
              label={fields.specialPermits.label}
              component={NewOrderCustomTextField}
              type="text"
              style={{ width: '100%' }}
            />
          </Grid>
          <Grid className={classes.productWeightContainer} item xs={12}>
            <TransportationOptions
              itemKey={SHIPMENT_QUANTITY_NAME_KEY}
              formikProps={formikProps}
              options={SHIPMENT_QUANTITIES_OPTIONS}
              disabled={disableTransportationOptions}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid item xs={6}>
              <Field
                style={{ width: '100%', paddingRight: '2px' }}
                name={fields.innerPackaging.key}
                label={fields.innerPackaging.label}
                component={CustomSelectTextField}
                customOnChange={(e) => {
                  formikProps.setFieldValue(e.target.name, e.target.value);
                }}
                type="text"
              >
                {innerPackagingOptions.map((option) => (
                  <MenuItem key={option?.value} value={option?.value}>
                    {option?.label}
                  </MenuItem>
                ))}
              </Field>
            </Grid>
          </Grid>
        </Grid>
        )}
        {newChemical && (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Field
              name={fields.materialName.key}
              label={fields.materialName.label}
              component={NewOrderCustomTextField}
              type="text"
              style={{ width: '100%' }}
            />
          </Grid>
          <Description editableFields={editableFields} isNewChemical={newChemical} />
          <Grid item xs={12}>
            <Grid item xs={6}>
              <Field
                style={{ width: '100%', paddingRight: '2px' }}
                name={fields.innerPackaging.key}
                label={fields.innerPackaging.label}
                component={CustomSelectTextField}
                customOnChange={(e) => {
                  formikProps.setFieldValue(e.target.name, e.target.value);
                }}
                type="text"
              >
                {innerPackagingOptions.map((option) => (
                  <MenuItem key={option?.value} value={option?.value}>
                    {option?.label}
                  </MenuItem>
                ))}
              </Field>
            </Grid>
          </Grid>
        </Grid>
        )}
        <Grid className={classes.productWeightContainer} item xs={12}>
          <ProductWeight
            label={FORM_LABELS.innerReceptacle}
            formikProps={formikProps}
            editableFields={editableFields}
            weightUnits={chemicalWeightUnits.units}
            smallWeightUnits={chemicalWeightUnits.smallUnits}
            weight={FORM_FIELDS_CHEMICAL_HAZMAT.innerWeight}
            weightUnit={FORM_FIELDS_CHEMICAL_HAZMAT.innerWeightUnit}
            smallWeight={FORM_FIELDS_CHEMICAL_HAZMAT.innerSmallWeight}
            smallWeightUnit={FORM_FIELDS_CHEMICAL_HAZMAT.innerSmallWeightUnit}
            isNewChemical={newChemical}
          />
        </Grid>
        <Grid className={classes.productWeightContainer} item xs={12}>
          <ProductWeight
            label={FORM_LABELS.totalQuantity}
            formikProps={formikProps}
            editableFields={editableFields}
            weightUnits={chemicalWeightUnits.units}
            smallWeightUnits={chemicalWeightUnits.smallUnits}
            weight={FORM_FIELDS_CHEMICAL_HAZMAT[ITEM_WEIGHT_KEY]}
            weightUnit={FORM_FIELDS_CHEMICAL_HAZMAT.totalWeightUnit}
            smallWeight={FORM_FIELDS_CHEMICAL_HAZMAT.totalSmallWeight}
            smallWeightUnit={FORM_FIELDS_CHEMICAL_HAZMAT.totalSmallWeightUnit}
            isNewChemical={newChemical}
          />
        </Grid>
        <Grid className={classes.netValue} container item xs={12}>
          <Grid item xs={6}>
            <Field
              style={{ width: '100%' }}
              name={fields.netValue.key}
              label={fields.netValue.label}
              component={NewOrderCustomTextField}
              type="text"
            />
          </Grid>
          <Grid item xs={2}>
            <Field
              style={{ width: '100%', paddingLeft: '8px' }}
              name={FORM_FIELDS_CHEMICAL_HAZMAT.totalValueUnit.key}
              label={get(formikProps?.values, FORM_FIELDS_CHEMICAL_HAZMAT.totalValueUnit.key, '')}
              component={CustomSelectTextField}
              type="text"
            >
              {currencyUnits.map((option) => (
                <MenuItem
                  key={option.currencyCode}
                  value={option.currencyCode}
                >
                  {option.currencyCode}
                </MenuItem>
              ))}
            </Field>
          </Grid>
        </Grid>
        { !newChemical
        && (
        <Grid className={classes.productWeightContainer} item xs={12}>
          <TransportationOptions
            itemKey={TRANSPORTATION_OPTIONS_NAME_KEY}
            formikProps={formikProps}
            options={TRANSPORTATION_OPTIONS}
            disabled={disableTransportationOptions}
          />

        </Grid>
        )}
      </Grid>
    </>
  );
}

HazmatChemical.defaultProps = {
  filteredItemNameOptions: [],
  currencyUnits: [],
};

HazmatChemical.propTypes = {
  formikProps: PropTypes.objectOf(PropTypes.any).isRequired,
  fields: PropTypes.shape(PropTypes.object).isRequired,
  editableFields: PropTypes.arrayOf(PropTypes.string).isRequired,
  setEditableFields: PropTypes.func.isRequired,
  filteredItemNameOptions: PropTypes.arrayOf(PropTypes.any),
  currencyUnits: PropTypes.arrayOf(PropTypes.shape({
    currencyCode: PropTypes.string,
  })),
  onSelect: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
};

export default memo(HazmatChemical);
