import PropTypes from 'prop-types';

export const packageProfiles = [
  {
    value: 'fedexpak',
    label: 'FedEx Pak',
    packagingType: 'Pak',
    carrierLabel: 'Fedex',
    packagingName: 'Pak',
  },
  {
    value: 'upspak',
    label: 'UPS Pak',
    packagingType: 'Pak',
    carrierLabel: 'UPS',
    packagingName: 'Express Pak',
  },
  {
    value: 'fedexenvelope',
    label: 'FedEx Envelope With Built-in Pouch',
    packagingType: 'Envelope',
    carrierLabel: 'Fedex',
    packagingName: 'Envelope With Built-in Pouch ',
  },
  {
    value: 'upsenvelope',
    label: 'UPS Window Envelope',
    packagingType: 'Envelope',
    carrierLabel: 'UPS',
    packagingName: 'Window Envelope',
  },
  {
    value: 'custom',
    label: 'Custom',
    packagingType: 'Custom',
    carrierLabel: 'Custom',
    packagingName: 'Custom Box',
  },
  {
    value: 'box19137',
    label: 'BOX 19x13x7',
    packagingType: 'Custom',
    carrierLabel: 'Custom',
    packagingName: 'Custom Box',
    length: '19',
    width: '13',
    height: '7',
  },
  {
    value: 'box201410',
    label: 'BOX 20x14x10',
    packagingType: 'Custom',
    carrierLabel: 'Custom',
    packagingName: 'Custom Box',
    length: '20',
    width: '14',
    height: '10',
  },
  {
    value: 'box1296',
    label: 'BOX 12x9x6',
    packagingType: 'Custom',
    carrierLabel: 'Custom',
    packagingName: 'Custom Box',
    length: '12',
    width: '9',
    height: '6',
  },
  {
    value: 'box252019',
    label: 'BOX 25x20x19',
    packagingType: 'Custom',
    carrierLabel: 'Custom',
    packagingName: 'Custom Box',
    length: '25',
    width: '20',
    height: '19',
  },
  {
    value: 'box582239',
    label: 'CAJA # 31',
    packagingType: 'Custom',
    carrierLabel: 'Custom',
    packagingName: 'Custom Box',
    length: '58',
    width: '22',
    height: '39',
  },
  {
    value: 'box583139',
    label: 'CAJA # 32',
    packagingType: 'Custom',
    carrierLabel: 'Custom',
    packagingName: 'Custom Box',
    length: '58',
    width: '31',
    height: '39',
  },
  {
    value: 'box584139',
    label: 'CAJA # 33',
    packagingType: 'Custom',
    carrierLabel: 'Custom',
    packagingName: 'Custom Box',
    length: '58',
    width: '41',
    height: '39',
  },
  {
    value: 'box391639',
    label: 'CAJA # 34',
    packagingType: 'Custom',
    carrierLabel: 'Custom',
    packagingName: 'Custom Box',
    length: '39',
    width: '16',
    height: '39',
  },
  {
    value: 'box322419',
    label: 'CAJA # 38',
    packagingType: 'Custom',
    carrierLabel: 'Custom',
    packagingName: 'Custom Box',
    length: '32',
    width: '24',
    height: '19',
  },
  {
    value: 'box323523',
    label: 'CAJA # 39',
    packagingType: 'Custom',
    carrierLabel: 'Custom',
    packagingName: 'Custom Box',
    length: '32',
    width: '35',
    height: '23',
  },
];
export const singlePackagePropTypes = PropTypes.shape({
  packagingTypeCode: PropTypes.string.isRequired,
  packagingName: PropTypes.string.isRequired,
  dimensions: PropTypes.shape({
    width: PropTypes.string.isRequired,
    length: PropTypes.string.isRequired,
    height: PropTypes.string.isRequired,
    unitOfMeasurement: PropTypes.string.isRequired,
  }).isRequired,
  declaredValue: PropTypes.shape({
    amount: PropTypes.string.isRequired,
    currency: PropTypes.string.isRequired,
  }).isRequired,
  customsValue: PropTypes.shape({
    amount: PropTypes.string.isRequired,
    currency: PropTypes.string.isRequired,
  }).isRequired,
  insuredValue: PropTypes.shape({
    amount: PropTypes.string.isRequired,
    currency: PropTypes.string.isRequired,
  }).isRequired,
  packageWeight: PropTypes.shape({
    weight: PropTypes.string.isRequired,
    unitOfMeasurement: PropTypes.string.isRequired,
    unitOfMeasurementSmall: PropTypes.string.isRequired,
    weightSmall: PropTypes.string.isRequired,
  }).isRequired,
  carrierLabel: PropTypes.string.isRequired,
  packagingType: PropTypes.string.isRequired,
  nonRMSContents: PropTypes.string.isRequired,
  iot: PropTypes.shape({
    deviceId: PropTypes.string.isRequired,
    vendorName: PropTypes.string.isRequired,
    triggers: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  }),
  isFragile: PropTypes.bool,
  isOversized: PropTypes.bool,
});

export function stripPrefixFromFieldName(fieldName, prefix) {
  return fieldName.replace(prefix, '');
}

export const transformAccountName = (accountName) => {
  switch (accountName) {
    case 'account1':
    case 'yale':
      return 'yale';
    case 'account2':
      return 'clemson';
    case 'utsw':
      return 'utsw';
    case 'collaborators':
      return 'consumer';
    case 'account3':
    case 'winstead':
      return 'winstead';
    case 'demo':
      return 'demo';
    case 'worldemblem':
      return 'worldemblem';
    case 'nwestern':
      return 'nwestern';
    case 'nwes':
      return 'nwestern';
    case 'northwestern':
      return 'nwestern';
    default:
      return accountName;
  }
};
