import React, {
  memo, useRef,
} from 'react';
import PropTypes from 'prop-types';
import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button, CircularProgress, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { map } from 'lodash';
import { format } from 'date-fns';
import * as colors from '../styles/colors';
import ScrollWindow from './common/ScrollWindow';
import UserAvatar from './common/UserAvatar';
import { DEFAULT_FETCH_OPTIONS, USER_TYPE_MAPPING } from '../clientConstants';

const ACTIONS_KEY = 'actions';

const BASE_TABLE_CELL_STYLES = {
  display: 'flex',
  flexGrow: 0,
  alignItems: 'center',
};

const COLUMNS = [
  {
    key: 'imageUrl',
    header: '',
    width: '10%',
    render: (user) => (
      <UserAvatar
        src={`${user.imageUrl}?${new Date()}`}
        alt={user.name ? user.name.split(' ').map((str) => (str[0])).join('') : ''}
      />
    ),
  },
  {
    key: 'name',
    header: 'Name',
    width: '20%',
  },
  {
    key: 'email',
    header: 'Email',
    width: '35%',
  },
  {
    key: 'userType',
    header: 'Role',
    width: '15%',
    render: ({ userType, groupType }) => {
      const userGroupType = !isEmpty(groupType)
        ? `(${groupType.charAt(0).toUpperCase() + groupType.slice(1)})`
        : '';

      return `${USER_TYPE_MAPPING[userType]} ${userGroupType}`;
    },
  },
  {
    key: 'training',
    header: 'Trainings',
    width: '20%',
    render: ({ training: userTraining }, training) => {
      if (!userTraining && !training) return '';

      return map(userTraining, (value, key) => {
        const currentTraining = has(training, key) ? training[key] : null;

        return currentTraining && !isEmpty(value.expiresAt) && format(new Date(), 'MM-dd-yyyy') < value.expiresAt
          ? currentTraining.description
          : '';
      })
        .filter((item) => !isEmpty(item))
        .join(', ');
    },
  },
  {
    key: ACTIONS_KEY,
    header: '',
    width: '10%',
  },
];

const useStyles = makeStyles(() => ({
  table: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 'auto',
    flexGrow: '2',
    background: colors.tableBackground,
    overflow: 'hidden',
  },
  tableHead: {
    width: '100%',
    background: colors.tableBackground,
    padding: '0 20px',
  },
  tableBody: {
    position: 'relative',
    height: '100%',
  },
  tableScrollView: {
    padding: '0 20px',
  },
  row: {
    display: 'flex',
    justifyContent: 'center',
  },
  cell: {
    flexGrow: '1',
    border: 'none',
    borderBottom: `1px solid ${colors.tableGreyBorder}`,
  },
  cellText: {
    color: colors.tableGreyText,
    fontSize: 12,
  },
  tableHeaderCellText: {
    textTransform: 'uppercase',
    letterSpacing: 1.3,
    fontSize: 12,
    fontWeight: 500,
  },
  tableHeaderCell: {
    padding: '20px 0 20px 10px',
  },
  tableBodyCellText: {
    fontSize: 14,
  },
  tableBodyCell: {
    padding: '20px 0 20px 10px',
    border: 'none',
  },
  tooltip: {
    background: colors.tooltipBackground,
    fontSize: 12,
  },
  tableActionButton: {
    borderRadius: '8px',
    boxShadow: 'none',
    in: 'auto',
    color: `${colors.tableGreyText}`,
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
  },
  tableActionIcon: {
    width: '18px',
    height: '18px',
  },
}));

const UsersTable = ({
  users, params, training, isLoading, onFetchUsers, onShowModal,
}) => {
  const classes = useStyles();
  const lastUserRef = useRef(null);

  const isTableScrolledToBottom = (table, cardRef) => {
    const tableHeight = table
      ? table.clientHeight
      : 0;
    const tableScrollTop = table
      ? table.scrollTop
      : 0;
    const tableScroll = Math.round(tableScrollTop + tableHeight);
    const lastContactOffset = (cardRef && cardRef.current)
      ? cardRef.current.offsetTop + cardRef.current.clientHeight
      : Infinity;

    return tableScroll >= lastContactOffset;
  };

  const onTableScroll = (e) => {
    const shouldLoadMore = isTableScrolledToBottom(e.target, lastUserRef);

    if (shouldLoadMore && !isLoading) {
      lastUserRef.current = null;
      onFetchUsers({ ...params, offset: params.offset + DEFAULT_FETCH_OPTIONS.limit });
    }
  };

  return (
    <Table className={classes.table}>
      <TableHead
        className={classes.tableHead}
      >
        <TableRow className={classes.row}>
          {COLUMNS.map((col) => (

            <TableCell
              className={`${classes.cell} ${classes.tableHeaderCell}`}
              align="left"
              key={col.key}
              style={{
                ...BASE_TABLE_CELL_STYLES,
                width: col.width,
              }}
            >
              <Typography className={`${classes.cellText} ${classes.tableHeaderCellText}`}>
                {col.header}
              </Typography>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody
        className={classes.tableBody}
      >
        <ScrollWindow
          classes={{
            view: classes.tableScrollView,
          }}
          onScroll={onTableScroll}
        >
          {users.map((row, idx, arr) => (
            <TableRow
              className={classes.row}
              key={row.userID}
              data-testid="user-row"
              ref={idx >= arr.length - 1 ? lastUserRef : undefined}
            >
              {COLUMNS.map((col) => (
                <TableCell
                  className={`${classes.cell} ${classes.tableBodyCell}`}
                  style={{ ...BASE_TABLE_CELL_STYLES, width: col.width }}
                  key={`${row.userID} - ${col.key}`}
                >
                  {col.key === ACTIONS_KEY
                    ? (
                      <Tooltip title="Edit" classes={{ tooltip: classes.tooltip }}>
                        <Button
                          className={classes.tableActionButton}
                          onClick={() => onShowModal({ ...row.user, userId: row.userId })}
                        >
                          <EditIcon className={classes.tableActionIcon} />
                        </Button>

                      </Tooltip>
                    ) : (
                      <Typography
                        className={`${classes.cellText} ${classes.tableBodyCellText}`}
                      >
                        {has(col, 'render') ? col.render(row.user, training) : row.user[col.key]}
                      </Typography>
                    ) }
                </TableCell>
              ))}
            </TableRow>
          ))}
          {isLoading && (
          <TableRow
            className={classes.row}
          >
            <TableCell
              className={classes.tableBodyCellActionButton}
              style={{ border: 'none' }}
            >
              <CircularProgress color="secondary" className={classes.progress} />
            </TableCell>
          </TableRow>
          )}
        </ScrollWindow>
      </TableBody>
    </Table>
  );
};

UsersTable.defaultProps = {
  training: null,
};

UsersTable.propTypes = {
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
  training: PropTypes.shape(PropTypes.object),
  params: PropTypes.shape({
    limit: PropTypes.number.isRequired,
    offset: PropTypes.number.isRequired,
    filter: PropTypes.string.isRequired,
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  onFetchUsers: PropTypes.func.isRequired,
  onShowModal: PropTypes.func.isRequired,
};

export default memo(UsersTable);
