import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { css } from 'emotion';

export default function NavigationButton(props) {
  const { path, label } = props;
  return (
    <Route render={({ history }) => (
      <Button
        className={css`
          font-weight: bold;
          border-radius: 16px;
          margin: auto;
          min-width: 110px;
        `}
        color="secondary"
        variant="contained"
        onClick={() => { history.push(path); }}
      >
        {label}
      </Button>
    )}
    />
  );
}

NavigationButton.propTypes = {
  path: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};
